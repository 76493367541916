var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alys-card" }, [
    _c("div", { staticClass: "alys-card__header" }, [
      _c("div", { staticClass: "alys-card__title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ])
    ]),
    _vm.$slots.toolbar
      ? _c("div", { staticClass: "alys-card__toolbar" }, [_vm._t("toolbar")], 2)
      : _vm._e(),
    _c("div", { staticClass: "alys-card__body" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }