var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reward-drawer", class: { "is-top": _vm.prize_focus } },
    [
      _vm.prize_focus
        ? _c("div", { staticClass: "reward-drawer__mask" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "reward-drawer__box", class: { show: _vm.prize_focus } },
        [
          _c("img", {
            staticClass: "reward-drawer__close",
            attrs: {
              src:
                "https://image.01lb.com.cn//uploads/wecom/22/0615/1655280271yZ62ZE6O.png"
            }
          }),
          _vm._m(0),
          _vm.reward_prize_type === 1
            ? [
                _c("div", { staticClass: "reward-drawer__body" }, [
                  _c(
                    "div",
                    { staticClass: "reward-drawer__content" },
                    [
                      _c("div", { staticClass: "reward-drawer__sub" }, [
                        _vm._v("兑换规则")
                      ]),
                      _c("el-input", {
                        staticClass: "reward-drawer__detail",
                        attrs: {
                          type: "textarea",
                          value: _vm.diy_content,
                          autosize: "",
                          resize: "none",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "reward-drawer__img" }, [
                    _c("img", { attrs: { src: _vm.diy_img, alt: "" } })
                  ])
                ])
              ]
            : _vm._e(),
          _vm.reward_prize_type === 2
            ? [
                _c("div", { staticClass: "reward-drawer__body" }, [
                  _c(
                    "div",
                    { staticClass: "reward-drawer__content" },
                    [
                      _c("div", { staticClass: "reward-drawer__sub" }, [
                        _vm._v("兑换规则")
                      ]),
                      _c("el-input", {
                        staticClass: "reward-drawer__detail",
                        attrs: {
                          type: "textarea",
                          value: _vm.code_rule,
                          autosize: "",
                          resize: "none",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "reward-drawer__code" }, [
                  _vm._v("SJFHEAML")
                ]),
                _c("div", { staticClass: "reward-drawer__btn" }, [
                  _vm._v("点击复制兑换码")
                ])
              ]
            : _vm._e(),
          _vm.reward_prize_type === 3
            ? [
                _c("div", { staticClass: "reward-drawer__body" }, [
                  _c("div", {
                    staticClass: "reward-drawer__detail",
                    domProps: { innerHTML: _vm._s(_vm.form_rule) }
                  })
                ]),
                _vm._m(1)
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reward-drawer__title" }, [
      _c("span", { staticClass: "reward-drawer__title--text" }, [
        _vm._v("奖品详情")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reward-drawer__btn" }, [
      _vm._v(" 去填写表单，领取奖品 "),
      _c("i", { staticClass: "el-icon-d-arrow-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }