<template>
  <el-dialog
    title="重新发送兑换码"
    :visible.sync="codeVisable"
    class="q-dialog"
    width="680px"
    :append-to-body="true"
  >
    <el-empty
      class="flex flex-column"
      v-show="!tableData || !tableData.length"
      :image-size="100"
      description="暂无可用兑换码"
    >
      <el-button
        type="primary"
        size="small"
        round
        @click="$router.push('/material/code/list')"
        style="margin: 16px 0"
      >
        追加兑换码</el-button
      >
      <div>
        <el-button type="text" size="medium" @click="fn_getCodeDetail"
          >已经追加？刷新</el-button
        >
      </div>
    </el-empty>
    <layout-table
      v-show="tableData && tableData.length"
      :pageTotal="total"
      @pageChange="pageChange"
      :loading="loading"
      :page="page"
      :searchForm="searchForm"
      @getList="fn_getData"
      @resetList="fn_getData"
    >
      <!-- searchBar -->
      <template #searchBar>
        <el-form-item label="兑换码：">
          <el-input
            v-model="searchForm.title"
            placeholder="请输入兑换码"
            @clear="fn_getCodeDetail"
            clearable
          ></el-input>
        </el-form-item>
      </template>

      <!-- table -->
      <el-table
        :data="tableData"
        style="width: 100%"
        height="400"
        ref="multipleTable"
      >
        <el-table-column
          label="序号"
          type="index"
          fixed
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="title" label="兑换码"></el-table-column>
        <el-table-column label="操作" width="180" class-name="operation-cell">
          <template slot-scope="scope">
            <el-button type="text" @click="fn_changeState(scope.row.id)"
              >标记已发送</el-button
            >
          </template>
        </el-table-column>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>
    </layout-table>
  </el-dialog>
</template>

<script>
import layoutTable from '@/components/layout/lyTable'
import { reward } from '../http'
export default {
  props: {
    rewardId: {
      type: [Number, null],
      default: null
    },
    userId: {
      type: [Number, null],
      default: null
    }
  },
  data () {
    return {
      empatyCodeImg: require('@assets/svg/empty_code.svg'),
      codeVisable: false,
      loading: false,
      tableData: [],
      searchForm: {
        code_id: null,
        send_status: 1
      },
      page: 1,
      per_page: 10,
      total: 0,
      msgBox: null
    }
  },

  watch: {
    codeVisable (val) {
      if (val) {
        this.loading = true
        this.searchForm.code_id = +this.rewardId
        this.fn_getCodeDetail()
      }
    }
  },
  methods: {
    fn_getData (type) {
      if (type) {
        this.searchForm = {
          code_id: null,
          send_status: 1
        }
      }
      this.page = 1
      this.fn_getCodeDetail()
    },
    // 获取兑换码列表
    async fn_getCodeDetail () {
      const data = await this.axios.post('taskCodeDetail', {
        page: this.page,
        per_page: this.per_page,
        ...this.searchForm
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      }

      this.tableData = data.list
      this.total = data.total
      this.loading = false
    },

    // 分页监听
    pageChange (val) {
      this.page = val
      this.fn_getCodeDetail()
    },

    // 标记已发送
    async fn_changeState (id) {
      const data = await reward.resend({
        reward_id: this.userId,
        reward_value_id: id
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_openMsg('info', data.msg)
        return false
      }

      this.fn_openMsg('success', '发送成功')
      this.codeVisable = false
      this.$emit('success')
    },

    fn_openMsg (type, text) {
      if (this.msgBox) {
        this.msgBox.close()
      }
      this.msgBox = this.$message[type](text)
    },

    // 跳转兑换码列表
    fn_jumpToCode () {
      const routeData = this.$router.resolve({
        path: '/material/code/list'
      })
      window.open(routeData.href, '_blank')
    }
  },
  components: {
    layoutTable
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ly-table {
  padding: 0;

  .ly-search {
    box-shadow: unset;
  }

  .el-form-item {
    display: inline-block;
  }

  .fixedPagination {
    position: unset;
    box-shadow: unset;
    width: 100% !important;
  }

  .ly-table__main {
    padding-top: 0;
  }

  .ly-table-main {
    margin-bottom: 24px;
  }
}

.empty {
  &-code {
    height: 400px;
    display: flex;
    align-items: center;
    padding-top: 80px;
    flex-direction: column;

    .q-info {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }
}
</style>
