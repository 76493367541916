var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rank bg" }, [
    _c("div", { staticClass: "title" }, [_vm._v("邀请排行榜")]),
    _c(
      "div",
      { staticClass: "rank-top" },
      _vm._l(_vm.topList, function(item, index) {
        return _c("div", { key: index, staticClass: "rank-top__item" }, [
          _c("img", {
            staticClass: "rank-top__bg",
            class: ["index_" + index],
            attrs: { src: _vm.topBgList[index], alt: "" }
          }),
          _c("img", {
            staticClass: "rank-top__avatar",
            class: ["index_" + index],
            attrs: { src: item.avatar, alt: "" }
          }),
          _c("div", { staticClass: "rank-top__name n-color" }, [
            _vm._v(_vm._s(item.name))
          ]),
          _c("div", { staticClass: "rank-top__invite i-color" }, [
            _c("span", { staticClass: "count" }, [_vm._v(_vm._s(item.invite))]),
            _vm._v(" "),
            _c("span", { staticClass: "text-small" }, [_vm._v("人")])
          ])
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "rank-list" },
      _vm._l(_vm.rankList.slice(3, 10), function(item, index) {
        return _c("div", { key: index, staticClass: "rank-list__item" }, [
          _c("div", { staticClass: "rank-list__left" }, [
            _c("div", { staticClass: "rank-list__index n-color" }, [
              _vm._v(_vm._s(index + 3 + 1))
            ]),
            _c("img", {
              staticClass: "rank-list__avatar",
              attrs: { src: item.avatar, alt: "" }
            }),
            _c("div", { staticClass: "rank-list__name n-color" }, [
              _vm._v(_vm._s(item.name))
            ])
          ]),
          _c("div", { staticClass: "rank-list__invite i-color" }, [
            _c("span", { staticClass: "count" }, [_vm._v(_vm._s(item.invite))]),
            _vm._v(" "),
            _c("span", { staticClass: "text-small" }, [_vm._v("人")])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }