<!--
 * @Author: zhan
 * @Date: 2022-08-09 14:51:49
 * @LastEditTime: 2022-09-01 11:34:56
 * @LastEditors: zhan
-->
<template>
  <ly-form ref="lyFormRef" class="act-form" :formObj="formObj" :form="formData" :rules="rules" @setData="fn_setData"
    v-loading="cardLoading">
  </ly-form>
</template>

<script>
export default {
  data () {
    return {
      cardLoading: true,
      formData: {
        btn_title: '',
        cover_title: '',
        rule: ''
      },
      rules: {
        btn_title: [
          { required: true, message: '请填写活动按钮文字', trigger: 'change' }
        ],
        cover_title: [
          { required: true, message: '请填写封面标题', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请填写活动规则', trigger: 'change' }
        ]
      },
      base_welcome_msg: '1、点击预约直播，即可成功预约直播间预告，开播前将进行开播提醒哦~\n2、请在活动时间内参与活动，如有任何问题请联系主办方。'
    }
  },

  computed: {
    formObj () {
      return {
        labelWidth: '160px',
        item: [
          {
            type: 'text',
            label: '活动按钮',
            prop: 'btn_title',
            attr: {
              maxlength: '8',
              showWordLimit: true,
              placeholder: '请输入邀请按钮文字'
            }
          },
          {
            type: 'text',
            label: '封面标题',
            prop: 'cover_title',
            attr: {
              maxlength: '14',
              showWordLimit: true
            }
          },
          {
            type: 'textEditor',
            label: '活动规则',
            prop: 'rule',
            attr: {
              recoverContent: this.base_welcome_msg
            },
            focus: (e) => {
              this.fn_setStore('is_rule_focus', e)
            }
          }
        ]
      }
    }
  },

  watch: {
    'formData.cover_title' (val) {
      this.fn_setStore('cover_title', val)
    },
    'formData.btn_title' (val) {
      this.fn_setStore('btn_title', val)
    },
    'formData.rule' (val) {
      this.fn_setStore('rule', val)
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        this.formData = data
      } else {
        this.formData.btn_title = '参与活动'
        this.formData.cover_title = '预约直播精彩好礼0元领'
        this.formData.rule = this.base_welcome_msg
      }

      this.$nextTick(() => {
        this.cardLoading = false
      })
    },

    fn_setData (val, name) {
      this.formData[name] = val
      this.$refs.lyFormRef.verify(name)
    },

    fn_setStore (name, val) {
      this.$store.commit('reserveTask/SET_DATA', {
        name,
        val
      })
    },

    async verify (draft) {
      let formValid = true
      if (!draft) {
        formValid = await this.$refs.lyFormRef.verify()
      }

      if (!formValid) return false

      return this.formData
    }
  }
}
</script>

<style lang="scss" scoped>
.act-form {
  ::v-deep {

    .el-input {
      max-width: 320px;
    }

    .ly-editor {
      width: 485px;
    }
  }
}
</style>
