<!--
 * @Author: zhanln
 * @Date: 2022-03-24 16:37:08
 * @LastEditTime: 2022-04-19 14:26:36
 * @LastEditors: zhanln
 * @Description: 视频号 - 展示卡片
-->

<template>
  <div class="vlog">
    <div class="vlog-cover">
      <el-image :src="info.thumburl_1" lazy class="mcard-img">
        <img
          src="@/assets/images/vlog_default.png"
          class="mcard-imgerror"
          slot="error"
          alt=""
        />
      </el-image>
      <i class="iconfont icon-play"></i>
    </div>
    <div class="vlog-title">
      <img :src="info.icon" class="vlog-icon" alt="" />
      <span>
        {{ info.title }}
      </span>
    </div>
    <div class="vlog-desc">
      {{ info.des }}
    </div>
    <div class="vlog-footer">
      <i class="iconfont icon-shipinhao q-warning"></i>视频号
    </div>
  </div>
</template>

<script>
export default {
  name: 'vlogCard',
  props: ['info']
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
.vlog {
  width: 246px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dfe1e8;
  box-sizing: border-box;

  &-cover {
    position: relative;
    height: 213px;
    overflow: hidden;

    img {
      width: 100%;
      max-width: 100%;
      filter: brightness(90%);
    }

    .icon-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 40px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    margin: 12px 12px 4px;
    font-size: 12px;
    line-height: 1;
    span {
      flex: 1;
      @include ellipsis;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
  }

  &-desc {
    line-height: 1.6;
    margin: 0 12px 12px;
    @include ellipsis;
  }

  &-footer {
    margin: 0 12px;
    border-top: 1px solid #eaebf2;
    line-height: 26px;
    color: #b4bbcc;

    i {
      margin-right: 4px;
    }
  }
}
</style>
