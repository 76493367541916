var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    class: ["btn-reload", _vm.reload ? "active" : ""],
                    staticStyle: { "margin-right": "16px" },
                    attrs: {
                      round: "",
                      size: "medium",
                      type: "primary",
                      disabled: _vm.reload
                    },
                    on: { click: _vm.fn_reload }
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-data" }),
                    _vm._v(_vm._s(_vm.reload ? "同步中..." : "同步"))
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "name_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-start" },
                  [
                    row.avatar_url
                      ? _c("el-image", {
                          staticClass: "ly-table__main-avatar",
                          attrs: {
                            lazy: "",
                            src: row.avatar_url,
                            alt: "",
                            fit: "cover"
                          }
                        })
                      : _c("el-image", {
                          staticClass: "ly-table__main-avatar",
                          attrs: {
                            lazy: "",
                            src: "../../../assets/images/default_avatar.png",
                            alt: "",
                            fit: "cover"
                          }
                        }),
                    _c("div", { staticStyle: { flex: "1" } }, [
                      _vm._v(" " + _vm._s(row.name) + " "),
                      row.corp_name
                        ? _c("span", { staticClass: "q-warning" }, [
                            _vm._v("@" + _vm._s(row.corp_name))
                          ])
                        : _c("span", { staticClass: "q-success" }, [
                            _vm._v("@微信")
                          ])
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "wecom_user_name_slot",
            fn: function(ref) {
              var row = ref.row
              return [_c("ly-drow", { attrs: { list: row.wecom_user_name } })]
            }
          },
          {
            key: "tag_names_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-drow", { attrs: { list: row.tag_names, dep: "" } })
              ]
            }
          },
          {
            key: "user_state_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                !row.wecom_user_name
                  ? _c(
                      "ly-status",
                      { attrs: { type: "danger", icon: "icon-close-fill" } },
                      [_vm._v("已流失")]
                    )
                  : _c(
                      "ly-status",
                      { attrs: { type: "success", icon: "el-icon-success" } },
                      [_vm._v("已添加")]
                    )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.jumpToDetail(row)
                      }
                    }
                  },
                  [_vm._v("详情")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }