<!--
 * @Author: zhanln
 * @Date: 2022-03-30 15:58:40
 * @LastEditTime: 2022-04-19 15:46:32
 * @LastEditors: zhanln
 * @Description: 素材列表展示卡片
-->

<template>
  <div :class="['mcard-item rd-8', grid]">
    <div class="mcard-body">
      <slot></slot>
      <el-checkbox
        v-model="data.checked"
        class="mcard-checkbox"
        size="medium"
        @change="fn_select($event, index)"
      ></el-checkbox>
    </div>
    <div class="mcard-footer">
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
        content="数据功能正在开发中"
        popper-class="q-tooltip"
      >
        <i class="iconfont icon-shuju is-disable"></i>
      </el-tooltip>
      <i class="iconfont icon-bianji" @click="$emit('edit', data)"></i>
      <i class="iconfont icon-delete" @click="fn_remove(data)"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mCard',
  props: ['data', 'grid', 'index'],
  methods: {
    // 选中
    fn_select (check, index) {
      this.$emit('selected', { check, index })
    },

    fn_remove (data) {
      this.$lyConfirm({
        title: '确认要删除素材吗？',
        text: '素材删除后将无法找回, 是否继续?',
        confirmButtonText: '删除'
      }).then(() => {
        this.$emit('remove', [data.material_id])
      }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.mcard {
  &-item {
    padding: 10px 10px 0;
    box-shadow: 0px 6px 12px 0px rgba(145, 158, 171, 0.12);
    border: 1px solid #eaebf2;
    box-sizing: border-box;
    margin: 0 6px 16px 6px;

    &.g-3 {
      width: calc((100% - 32px) / 3);
      &:nth-child(3n + 0) {
        margin-right: 0;
      }
    }

    &.g-4 {
      width: calc((100% - 44px) / 4);
      &:nth-child(4n + 0) {
        margin-right: 0;
      }
    }
  }

  &-body {
    position: relative;
  }

  &-checkbox {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;
  }

  &-footer {
    border-top: 1px solid #eaebf2;
    display: flex;
    align-items: center;
    line-height: 36px;
    justify-content: flex-end;
    color: #868e9d;

    i {
      margin-right: 12px;

      &.is-disable {
        color: #a2a9b8;
      }

      &:hover:not(.is-disable) {
        cursor: pointer;
      }

      &:hover:not(.is-disable, .icon-delete) {
        color: $--color-primary;
      }
    }

    .icon-delete:hover {
      color: $--color-danger;
    }
  }
}
</style>
