<template>
  <div class="msgBubbles" :class="{ isMe: msgData.user.identity === 'me' }">
    <div class="useMsg">
      <img
        :src="
          msgData.user.avatar ||
          'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
        "
        alt=""
      />
      <div class="name">{{ msgData.user.name }}</div>
      <span v-if="msgData.msg.sender_suffix">{{
        msgData.msg.sender_suffix
      }}</span>
      <div class="time">{{ msgData.msg.time }}</div>
    </div>
    <div class="bubbles">
      <div
        class="msg"
        v-if="msgData.msg.type === 'text'"
        v-emoji="msgData.msg.content"
      ></div>
      <!-- 图片消息条 -->
      <div class="msg img" v-else-if="msgData.msg.type === 'img'">
        <el-image
          style="max-height: 260px; max-width: 160px;"
          fit="scale-down"
          :src="msgData.msg.content"
          :preview-src-list="[msgData.msg.content]"
        >
          <div slot="placeholder" class="img-slot" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)"></div>
        </el-image>
      </div>
      <!-- 文件消息条 -->
      <div class="msg file" v-else-if="msgData.msg.type === 'file'">
        <div class="left">
          <div class="fileName">
            {{ msgData.msg.title }}
          </div>
          <div class="size">{{ msgData.msg.size }}</div>
        </div>
        <img :src="extIcon" />
      </div>
      <!-- 语音消息条 -->
      <div
        class="msg voice"
        :class="{ cehui: msgData.msg.status === 4 }"
        v-else-if="msgData.msg.type === 'voice'"
        @click="handleClickVoice(msgData)"
      >
        <img
          src="@/assets/images/icon/icon_voice_l.svg"
          alt=""
          v-show="!isPlay"
        />
        <img
          src="@/assets/images/icon/play/play_l.gif"
          alt=""
          v-show="isPlay"
        />
        <span v-show="msgData.msg.url">{{ msgData.msg.voice_time }}''</span>
        <audio hidden="true" ref="audio"></audio>
        <!-- 右键菜单 -->
      </div>
      <!-- 小程序消息条 -->
      <div
        class="msg applet"
        :class="{ cehui: msgData.msg.status === 4 }"
        v-else-if="msgData.msg.type === 'applet'"
      >
        <div class="top">
          <img :src="msgData.msg.logo || '@/assets/images/applet.png'" alt=""  v-if="msgData.msg.logo"/>
          <img src="@/assets/images/applet.png" alt="" v-else />
          <span class="name">{{ msgData.msg.name }}</span>
        </div>
        <div class="title">{{ msgData.msg.title }}</div>
        <img :src="msgData.msg.cover_url" alt="" class="applet_img" />
        <div class="bottom">
          <img src="@/assets/images/icon/icon_link.svg" alt="" />
          <span class="text">小程序</span>
        </div>
      </div>
      <!-- 视频消息条 -->
      <div
        class="msg video"
        v-else-if="msgData.msg.type === 'video'"
      >

        <img :src="msgData.msg.cover_url" alt="" class="video_cover">
        <img
          src="@/assets/images/icon/icon_videoStop.svg"
          class="video_stop"
          @click="playVideo(msgData.msg)"
        />
      </div>
      <!-- 视频号消息条 -->
      <div
        class="msg video-account"
        :class="{ cehui: msgData.msg.status === 4 }"
        v-else-if="msgData.msg.type === 'videoAccount'"
      >
        <div class="top">
          <el-image :src="msgData.msg.cover_url" lazy class="video_img">
            <template #error>
              <img src="@/assets/images/vlog_default.png" alt="" />
            </template>
          </el-image>
        </div>
        <div class="text_content">
          <div class="mid">
            <img :src="msgData.msg.avatar" alt="" />
            <span class="name">{{ msgData.msg.name }}</span>
          </div>
          <div class="bottom">
            <img src="@/assets/images/icon/icon_vlog.svg" alt="" width="14" />
            <span class="text">视频号</span>
          </div>
        </div>
      </div>
      <!-- 个人名片 -->
      <div
        class="msg businessCard"
        v-else-if="msgData.msg.type === 'businessCard'"
      >
        <div class="content">
          <img :src="msgData.msg.avatar" alt="" />
          <p class="name">{{ msgData.msg.name }}</p>
        </div>
        <span>个人名片</span>
      </div>
      <!-- 图文链接 -->
      <div
        class="msg graphic"
        :class="{ cehui: msgData.msg.status === 4 }"
        v-else-if="msgData.msg.type === 'graphic'"
        @click="handleGraphic(msgData.msg.href)"
      >
        <div class="left">
          <p class="des">{{ msgData.msg.title }}</p>
          <div class="title">{{ msgData.msg.desc }}</div>
        </div>

        <el-image :src="msgData.msg.cover_url" lazy style="line-height: 1; font-size: 0; width: 64px; height: 64px;">
          <img slot="error" src="https://image.01lb.com.cn//uploads/wecom/22/0507/1651905734wMI905Dp.png" alt="" />
        </el-image>
      </div>
      <div v-else class="msg" style="background: #dfe1e8">
        暂不支持的消息类型，可在手机上查看
      </div>
      <div class="bubbles_icon">
        <div
          class="success"
          v-if="msgData.msg.status !== 3 && msgData.msg.status !== 1"
        >
          <span
            v-if="
              [
                'img',
                'file',
                'video'
              ].includes(msgData.msg.type) && msgData.msg.status !== 1
            "
            @click="handleClickMsg(msgData)"
          >
            <img src="@/assets/images/icon/icon_download.svg" alt="" />
            <img src="@/assets/images/icon/icon_download_a.svg" alt="" />
          </span>

          <span
            v-if="textType"
            :class="'copy_' + msgData.msg.id"
            :data-clipboard-text="msgData.msg.content"
            @click="fn_clip(msgData.msg.id)"
          >
            <img src="@/assets/images/icon/icon_copy.svg" alt="" />
            <img src="@/assets/images/icon/icon_copy_a.svg" alt="" />
          </span>
        </div>
        <!-- <span
          v-if="msgData.msg.status === 3"
          @click="handleClickMsg('reload', msgData)"
        >
          <img src="@/assets/images/icon/icon_reload.svg" alt="" />
          <img src="@/assets/images/icon/icon_reload_a.svg" alt="" />
        </span> -->
      </div>
      <div class="err" v-if="msgData.msg.status === 3">消息发送失败</div>
    </div>
    <el-dialog :visible.sync="videoVisible" width="800px"
      append-to-body>
      <video controls autoplay ref="video" class="video-player"></video>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import emoji from '@/components/emoji/emoji.js'

export default {
  directives: {
    emoji
  },
  props: {
    msgData: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      loading: true,
      isPlay: false,
      videoVisible: false
    }
  },

  computed: {
    isShowWx () {
      return this.msgData.user.identity === 'me' || this.msgData.user === 'one'
    },
    extIcon () {
      return this.msgData.msg.file_suffix ? require(`@/assets/images/fileImg/${this.msgData.msg.file_suffix}.png`) : ''
    },
    textType () {
      const { msgData } = this
      return msgData.msg.type === 'text'
    }
  },
  methods: {
    // 复制内容
    fn_clip (id) {
      const clipboard = new Clipboard(`.copy_${id}`)
      clipboard.on('success', () => {
        clipboard.destroy()
        this.fn_lyMsg('success', '复制成功')
      })
      clipboard.on('error', (e) => {
        this.fn_lyMsg('info', '复制失败，请手动复制')
        console.error('E:', e)
      })
    },

    handleClickMsg (data) {
      const _this = this
      const x = new XMLHttpRequest()
      x.open('GET', data.msg.content || data.msg.url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = data.msg.title || _this.$day(+new Date()).format('YYYY-MM-DD HH-mm-ss')
        a.click()
      }
      x.send()
    },

    async playVideo ({ url }) {
      this.videoVisible = true
      await this.$nextTick()
      this.$refs.video.src = url
    },

    handleClickVoice (msgData) {
      let time = null
      if (this.$refs.audio.paused) {
        // 如果未播放，暴露播放事件
        // 重置到0毫秒，播放
        this.$refs.audio.src = msgData.msg.url
        this.$refs.audio.currentTime = 0
        this.$refs.audio.play()
        this.isPlay = true
        time = setTimeout(() => {
          this.isPlay = false
        }, parseInt(msgData.msg.voice_time * 1000))
      } else {
        // 粘贴
        this.voiceReset()
        clearTimeout(time)
      }
    },

    voiceReset () {
      if (this.$refs.audio) {
        this.$refs.audio.pause()
        this.isPlay = false
      }
    },

    handleGraphic (href) {
      window.open(href)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins.scss";
.msgBubbles {
  // width: calc(100vw - 550px);
  margin-top: 12px;
  box-sizing: border-box;
  .bubbles_icon {
    display: none;
    align-items: center;
    align-self: end;
    position: relative;
    bottom: 18px;
    .success {
      display: flex;
      align-items: center;
      height: 100%;
    }
    span {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      img {
        width: 16px;
        height: 16px;
        &:last-of-type {
          display: none;
        }
      }
      &:hover {
        img {
          display: none;
          &:last-of-type {
            display: block;
          }
        }
      }
    }
  }
  .err {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff4842;
    position: absolute;
    bottom: -25px;
    right: 50px;
  }
  &.isMe {
    .useMsg {
      flex-direction: row-reverse;
      .name {
        margin-left: 4px;
        margin-right: 8px;
      }
    }
    .bubbles {
      flex-direction: row-reverse;
      position: relative;

      &_icon {
        margin-left: 0px;
        &:first-of-type {
          margin-left: 0px;
          margin-right: 12px;
        }
        margin-right: 8px;
        cursor: pointer;
      }
      .msg {
        &.cehui {
          &::before {
            content: "";
            position: absolute;
            right: 130px;
            left: inherit;
            bottom: -30px;
            display: inline-block;
            background: url("~@/assets/images/icon/icon_cehui.svg") no-repeat;
            width: 20px;
            height: 20px;
          }
          &::after {
            content: "此消息已撤回";
            position: absolute;
            right: 30px;
            left: inherit;
            bottom: -30px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b4bbcc;
            text-indent: 20px;
            width: 120px;
          }
        }
        border-radius: 8px 0px 8px 8px;
        margin-right: 48px;
        margin-left: 0px;
        background: #338bff21;
        position: static;

        .loading {
          position: absolute;
          right: -30px;
          left: inherit;
          bottom: 0;
        }
      }
    }
  }
  .useMsg {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .name {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #212b36;
      margin-left: 8px;
      margin-right: 4px;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00c780;
    }
    .time {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #b4bbcc;
      margin: 0 12px;
    }
  }
  .bubbles {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 30px;
    &:hover {
      .bubbles_icon {
        display: flex;
      }
    }

    &_icon {
      &:first-of-type {
        margin-left: 12px;
      }
      margin-left: 8px;
      cursor: pointer;
    }
    .msg {
      max-width: 400px;
      padding: 16px;
      background: #f0f1f8;
      border-radius: 0px 8px 8px 8px;
      margin-left: 48px;
      position: relative;
      &.cehui {
        background: #dfe1e8;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -30px;
          display: inline-block;
          background: url("~@/assets/images/icon/icon_cehui.svg") no-repeat;
          width: 20px;
          height: 20px;
        }
        &::after {
          content: "此消息已撤回";
          position: absolute;
          left: 0;
          bottom: -30px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4bbcc;
          text-indent: 20px;
          width: 120px;
        }
      }
      .loading {
        position: absolute;
        left: -30px;
        bottom: 0;
      }
      &.img {
        background: transparent;
        padding: 0;
        img {
          border-radius: 8px 8px 8px 8px;
        }
        .img-slot {
          height: 100px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f7f8fc;
        }
      }
      &.file {
        background: #fff;
        border: 1px solid #eaebf2;
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;
        align-items: center;
        .fileName {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #212b36;
          width: 148px;
          @include ellip;
          margin-bottom: 8px;
        }
        .size {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4bbcc;
        }
        img {
          width: 48px;
          height: 48px;
        }
      }

      &.video-account {
        width: 246px;
        background: #ffffff;
        // border-radius: 0px 8px 8px 8px;
        border: 1px solid #eaebf2;
        padding: 0;
        position: relative;
        .top {
          position: relative;
          line-height: 1;
          font-size: 0;
          height: 213px;
          overflow: hidden;
          .video_img {

            img {
              height: 100%;
              width: 100%;
              position: relative;
              object-fit: cover;
              &::after {
                position: absolute;
                width: 100%;
                height: 100%;
                content: "";
                background: #f7f8fc;
                top: 0;
                left: 0;
              }
            }
          }
          .icon {
            position: absolute;
            left: calc((100% - 40px) / 2);
            top: calc((100% - 40px) / 2);
          }
        }
        .text_content {
          padding: 0 12px;
          padding-bottom: 0px;
        }
        .mid {
          display: flex;
          align-items: center;
          margin-top: 8px;
          img {
            width: 24px;
            height: 24px;
            border-radius: 24px;
            margin-right: 8px;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #212b36;
          }
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #212b36;
          margin-top: 8px;
          padding-bottom: 12px;
          border-bottom: 1px solid #eaebf2;
          @include ellip;
        }
        .bottom {
          display: flex;
          align-items: center;
          padding: 4px 0;
          .text {
            margin-left: 2px;
          }
        }
      }

      &.voice {
        width: 148px;
        height: 52px;
        background: #f0f1f8;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: relative;
        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #b4bbcc;
          position: absolute;
          bottom: 0;
          left: 102%;
          width: 50px;
        }
      }
      &.s_program {
        width: 246px;
        background: #ffffff;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #eaebf2;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 12px;
        padding-bottom: 0;
        .top {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          .name {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b4bbcc;
          }
        }
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #212b36;
          padding: 4px 0;
        }
        .s_program_img {
          width: 100%;
          // height:167px ;
        }
        .bottom {
          display: flex;
          align-items: center;
          padding: 4px 0;
          margin-top: 4px;
          border-top: 1px solid #eaebf2;
          .text {
            margin-left: 2px;
          }
        }
      }

      &.applet {
        width: 246px;
        background: #ffffff;
        // border-radius: 0px 8px 8px 8px;
        border: 1px solid #eaebf2;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 12px;
        padding-bottom: 0;
        .top {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border-radius: 24px;
          }
          .name {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b4bbcc;
            @include ellip;
          }
        }
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #212b36;
          padding: 4px 0;
          @include ellip;
        }
        .applet_img {
          width: 100%;
          min-height: 165.5px;
          position: relative;
          &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            background: #f7f8fc;
            top: 0;
            left: 0;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          padding: 4px 0;
          margin-top: 4px;
          border-top: 1px solid #eaebf2;
          .text {
            margin-left: 2px;
          }
        }
      }
      &.video {
        position: relative;
        background: #ffffff;
        padding: 0;
        line-height: 1;
        .video_cover {
          min-width: 70px;
          height: 164px;
        }
        .video_stop {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          z-index: 99;
        }
        .top {
          position: relative;
          .video_img {
            width: 100%;
          }
          .icon {
            position: absolute;
            left: calc((100% - 40px) / 2);
            top: calc((100% - 40px) / 2);
          }
        }
        .text_content {
          padding: 0 12px;
          padding-bottom: 0px;
        }
        .mid {
          display: flex;
          align-items: center;
          margin-top: 8px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #212b36;
          }
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #212b36;
          margin-top: 8px;
          padding-bottom: 12px;
          border-bottom: 1px solid #eaebf2;
          @include ellip;
        }
        .bottom {
          display: flex;
          align-items: center;
          padding: 4px 0;
          .text {
            margin-left: 2px;
          }
        }
      }
      &.businessCard {
        width: 246px;
        height: 96px;
        background: #ffffff;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #eaebf2;
        box-sizing: border-box;
        padding: 12px;
        padding-bottom: 0;

        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #eaebf2;
          padding-bottom: 10px;
          img {
            width: 48px;
            height: 48px;
          }
          .name {
            @include ellip;
            width: 155px;
          }
        }
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4bbcc;
          padding: 4px;
        }
      }
      &.graphic {
        width: 298px;
        background: #ffffff;
        border: 1px solid #eaebf2;
        box-sizing: border-box;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          overflow: hidden;
          .title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b4bbcc;
            @include ellip;
          }
          .des {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #212b36;
            line-height: 20px;
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            word-break: break-all;
            margin: 0;
          }
        }
        img {
          width: 64px;
          height: 64px;
        }
      }
    }
  }
}

.el-dialog {
  height: 500px;
  background: #212b36;
  position: relative;
}
.el-dialog__header {
  display: none;
}
.el-dialog__body {
  padding: 0;
}
.video-player {
  max-height:500px;
  max-width: 100%;
  object-fit: fill;
  display: block;
  margin: 0 auto;
}
.video-close {
  position: absolute;
  right: -24px;
  top: -24px;
  font-size: 16px;
  color: #fff;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
</style>
