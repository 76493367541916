<template>
  <div class="form-style white">
    <div class="normalTable-form-container" style="position: static">
      <div class="mobile">
        <div class="mobile-topbar">
          <div>9:41</div>
          <div class="flex">
            <img src="~@assets/svg/Cellular_Connection.svg" alt="" />
            <img src="~@assets/svg/Wifi.svg" alt="" />
            <img src="~@assets/images/ic_power.png" alt="" />
          </div>
        </div>
        <div class="mobile-titlebar">
          <i class="el-icon-close" style="font-size: 16px"></i>
          <div class="flex flex-column">
            {{ formName || "我是表单名称" }}
          </div>
          <i class="el-icon-more"></i>
        </div>
      </div>
      <div
        class="normalTable-form-body"
        style="margin-bottom: 0; margin-top: 0"
      >
        <el-scrollbar style="height: 500px">
          <div class="form-header" style="margin: 0 auto">
            <img src="@/assets/images/form_header.png" />
          </div>
          <!-- </div> -->
          <div class="normalTable-form-list">
            <div class="form-infos">
              <img src="@/assets/images/form_infos.png" />
              <span class="form-infos-text">填写须知</span>
            </div>
            <div class="form-info-remarks">
              为了保证奖品信息的准确性，请认真填写！
            </div>

            <el-form
              label-suffix=":"
              :model="models"
              :rules="rules"
              label-position="top"
            >
              <template v-for="(item, ind) in data.list">
                <form-item
                  :key="item.key"
                  :models.sync="models"
                  :rules="rules"
                  :normalTable="item"
                  :formIndex="ind"
                >
                </form-item>
              </template>
            </el-form>
          </div>
        </el-scrollbar>
        <!-- <div class="submitButton">
          <el-button type="primary"> 确认提交 </el-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import formItem from './formItem'

export default {
  components: {
    formItem
  },
  props: ['data', 'formName'],
  data () {
    return {
      models: {},
      rules: {}
    }
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.models = { ...this.models, ...val }
      }
    }
  }
}
</script>

<style lang="scss">
// @import '../styles/cover.scss';
</style>
