<!--
 * @Author: zhanln
 * @Date: 2021-08-20 18:17:01
 * @LastEditTime: 2022-01-05 18:51:27
 * @LastEditors: zhanln
 * @Description: 编辑活码
-->

<template>
  <act-layout :cards="cards" ref="cards" @setKey="fn_setKey">
    <!-- 表单区域 -->
    <template #basic>
      <view-basic ref="basic"></view-basic>
    </template>
    <template #cus>
      <view-cus ref="cus"></view-cus>
    </template>
    <template #qrlink>
      <view-qrlink ref="qrlink"></view-qrlink>
    </template>

    <!-- 预览区域 -->
    <template #mobile>
      <mobi-basic
        v-if="
          tabKey === 'basic' ||
          tabKey === 'cus' ||
          (tabKey === 'qrlink' && !linkOpen)
        "
      ></mobi-basic>
      <mobi-link v-if="tabKey === 'qrlink' && linkOpen"></mobi-link>
    </template>

    <!-- footer -->
    <template #footer>
      <el-button
        type="primary"
        round
        icon="iconfont icon-send-fill"
        :disabled="watting || (isEnd && !isCopy)"
        :loading="btnLoading"
        @click="fn_submit"
        >{{ isEnd && !isCopy ? "活码已失效" : "确定提交" }}</el-button
      >
    </template>
  </act-layout>
</template>

<script>
// layout
import actLayout from '@/components/actLayout'
// form
import Basic from './activity/basic'
import Cus from './activity/cus'
import Qrlink from './activity/qrLink'
// mobile
import mobiBasic from './mobile/basic'
import mobiLink from './mobile/qrLink'
// plugin
import mapTag from '@/common/mapTag'
// api
import { list, activity } from './http.js'
export default {
  name: 'edit',

  data () {
    return {
      cards: [
        {
          label: '基本信息',
          name: 'basic',
          view: ['basic']
        },
        {
          label: '用户设置',
          name: 'cus',
          view: ['cus']
        },
        {
          label: '链接设置',
          name: 'qrlink',
          view: ['qrlink']
        }
      ],
      tabKey: '',
      COMM_HTTP: activity,
      COMM_LIST: list,
      watting: true,
      isCopy: false,
      isEdit: false,
      isEnd: false,
      subForm: {},
      isGetTag: false,
      btnLoading: false,
      backComfirm: true,
      actType: 1
    }
  },

  computed: {
    linkOpen () {
      return this.$store.getters['qrcode/GET_LINK'].link_open
    }
  },

  created () {
    this.fn_resetForm()
  },

  async mounted () {
    // 修改
    const id = this.$route.query.id
    if (id) {
      this.actId = id
      this.isEdit = true
      this.isCopy = this.$route.query.type === 'copy'
      document.title = this.isCopy ? '复制个人活码' : '修改个人活码'
      this.$store.commit('_SET', {
        'global.firstTitle': document.title
      })
      this.$store.state.qrcode.isEdit = true
      // 回显配置
      await this.getForm(id)
    } else {
      this.$store.commit('qrcode/SET_READY')
      this.watting = false
    }
  },

  methods: {

    // 设置导航
    fn_setKey (key) {
      this.tabKey = key
    },

    // 获取详情
    async getForm (id) {
      const data = await this.COMM_LIST.detail({ id })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.backComfirm = false
        return false
      }
      this.isEnd = !!data.expireTime && data.expireStatus
      this.fn_setForm(data)
      setTimeout(() => {
        this.$store.commit('qrcode/SET_READY')
        this.watting = false
        if (this.isCopy) {
          this.$message.success(`已复制个人活码 ${data.liveCodeName} 设置的内容`)
        }
      }, 200)
    },

    // 回显表单
    fn_setForm (form) {
      this.fn_setBasic(form)
      this.fn_setCus(form)
      this.fn_setLink(form)
    },

    // 回显 - 活动信息
    fn_setBasic (form) {
      const wecom_user_ids = form.users.map(item => {
        return {
          id: item.userId,
          name: item.name
        }
      })

      const basic = {
        title: this.isCopy ? form.liveCodeName + '-复制' : form.liveCodeName,
        group_id: form.groupId,
        wecom_user_ids,
        wel_words: mapTag(form.welcomeContent),
        attachments: this.fn_setAttach(form.attachments),
        expire_type: 1
      }

      // 开启失效时间
      if (form.expireTime) {
        basic.expire_type = 2
        basic.expire_time = form.expireTime
      }

      for (const key in basic) {
        this.fn_setLocalForm('basic', basic[key], key)
      }
    },

    // 回显 - 附件
    fn_setAttach (data) {
      const attach = []
      for (let i = 0; i < data.length; i++) {
        // 图片
        if (data[i].messageType === 1) {
          attach.push({
            msg_type: 2002,
            msg_content: data[i].imageUrl,
            temp: 'image_' + i
          })
        }

        // 链接
        if (data[i].messageType === 2) {
          attach.push({
            msg_type: 2005,
            title: data[i].linkTitle,
            href: data[i].linkUrl,
            desc: data[i].linkDsc,
            msg_content: data[i].linkPicUrl,
            temp: 'link_' + i
          })
        }

        // 小程序
        if (data[i].messageType === 3) {
          attach.push({
            msg_type: 2013,
            title: data[i].title,
            path: data[i].page,
            id: data[i].appid,
            msg_content: data[i].picMediaUrl,
            temp: 'applet_' + i
          })
        }
      }
      return attach
    },

    // 回显 - 用户设置
    fn_setCus (form) {
      const tags = form.tags.length > 0 ? form.tags.map(item => {
        return {
          tagName: item.name,
          tagId: item.tagId
        }
      }) : []

      const cus = {
        tag_open: form.tagStatus,
        name_suffix_open: form.remarkStatus,
        tags
      }

      // 开启昵称备注
      if (form.remarkStatus) {
        cus.name_suffix = form.nicknameRemark
      }

      for (const key in cus) {
        this.fn_setLocalForm('cus', cus[key], key)
      }
    },

    // 回显 - 链接设置
    fn_setLink (form) {
      let linkObj = {}
      if (form.linkStatus) {
        const linkData = form.link
        linkObj = {
          link_type: linkData.linkType,
          sevice_open: form.contactStatus
        }

        // 开启联系主办方
        if (form.contactStatus) {
          linkObj.sevice_ids = [linkData.contactUserId]
        }

        if (linkData.linkType === 1) {
          linkObj.guide = linkData.linkContent
        }

        // 自定义风格
        if (linkData.linkType === 2) {
          linkObj.link_bg = [{ url: linkData.linkImage }]
          linkObj.qrcode_width = linkData.qrcodeWidth
          linkObj.qrcode_height = linkData.qrcodeHeight
          linkObj.qrcode_x = linkData.qrcodeLeft
          linkObj.qrcode_y = linkData.qrcodeTop

          linkObj.unreal_expire_open = form.virtualStatus

          if (form.virtualStatus) {
            linkObj.unreal_expire_text = linkData.virtualContent
            const { d, h, m, s } = this.fn_setExpire(linkData.virtualTime)
            linkObj.unreal_expire_day = d
            linkObj.unreal_expire_hour = h
            linkObj.unreal_expire_minute = m
            linkObj.unreal_expire_second = s
          }
        }
      }

      const link = {
        link_open: form.linkStatus,
        ...linkObj
      }

      for (const key in link) {
        this.fn_setLocalForm('link', link[key], key)
      }
    },

    // 回显 - 虚拟剩余时间
    fn_setExpire (data) {
      const eTime = [86400, 3600, 60, 0]
      const time = [0, 0, 0, 0]

      let _data = data
      for (let i = 0; i < eTime.length; i++) {
        if (i === 3) {
          time[i] = _data
        } else {
          if (_data >= eTime[i]) {
            time[i] = Math.floor(_data / eTime[i])
            _data = _data % eTime[i]
          }
        }
      }

      const { 0: d, 1: h, 2: m, 3: s } = time
      return { d, h, m, s }
    },

    // 回显配置
    fn_setLocalForm (form, val, type) {
      this.$store.commit('qrcode/SET_FORM', {
        form: form,
        name: type,
        val: val
      })
    },

    // 重置
    fn_resetForm () {
      this.$store.commit('qrcode/RESET')
    },

    // 提交
    fn_submit () {
      this.btnLoading = true
      const _verify = this.fn_verify()
      if (!_verify) {
        this.btnLoading = false
        return false
      }
      this.btnLoading = false
      this.fn_initForm()
      this.fn_submitForm()
    },

    // 验证各表单
    fn_verify () {
      if (!this.$refs.basic.verify()) {
        this.fn_formScroll(this.$refs.cards.$refs.basic[0].$el)
        return false
      }
      if (!this.$refs.cus.verify()) {
        this.fn_formScroll(this.$refs.cards.$refs.cus[0].$el)
        return false
      }
      if (!this.$refs.qrlink.verify()) {
        this.fn_formScroll(this.$refs.cards.$refs.qrlink[0].$el)
        return false
      }
      return true
    },

    // 滚动到报错位置
    fn_formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    // 处理需要提交的数据
    fn_initForm () {
      // 活动信息
      Object.assign(this.subForm, this.fn_initBasic())
      // 用户设置
      Object.assign(this.subForm, this.fn_initCus())
      // 链接设置
      const { linkForm, link } = this.fn_initLink()
      Object.assign(this.subForm, linkForm)
      this.subForm.link = link
    },

    // 提交 - 活动信息
    fn_initBasic () {
      const basic = this.$store.getters['qrcode/GET_BASIC']
      const basicForm = {
        liveCodeName: basic.title,
        groupId: basic.group_id,
        users: basic.wecom_user_ids.map(item => {
          const obj = {
            userId: +item.id,
            name: item.name
          }
          return obj
        }),
        attachments: [],
        welcomeContent: mapTag(basic.wel_words, false)
      }

      if (basic.expire_type === 2) {
        basicForm.expireTime = basic.expire_time
      } else {
        basicForm.expireTime = null
      }

      if (basic.attachments.length > 0) {
        basicForm.attachments = this.fn_initAttach(basic.attachments)
      }

      return basicForm
    },

    // 提交 - 附件
    fn_initAttach (data) {
      const attach = []
      for (let i = 0; i < data.length; i++) {
        // 图片
        if (data[i].msg_type === 2002) {
          attach.push({
            messageType: 1,
            imageUrl: data[i].msg_content
          })
        }

        // 链接
        if (data[i].msg_type === 2005) {
          attach.push({
            messageType: 2,
            linkTitle: data[i].title,
            linkUrl: data[i].href,
            linkDsc: data[i].desc,
            linkPicUrl: data[i].msg_content
          })
        }

        // 小程序
        if (data[i].msg_type === 2013) {
          attach.push({
            messageType: 3,
            title: data[i].title,
            page: data[i].path,
            appid: data[i].id,
            picMediaUrl: data[i].msg_content
          })
        }
      }
      return attach
    },

    // 提交 - 用户设置
    fn_initCus () {
      const cus = this.$store.getters['qrcode/GET_CUS']
      const tags = cus.tags.map(item => {
        return {
          name: item.tagName,
          tagId: item.tagId
        }
      })
      const cusForm = {
        tagStatus: cus.tag_open,
        remarkStatus: cus.name_suffix_open,
        tags
      }

      if (cus.name_suffix_open) {
        cusForm.nicknameRemark = cus.name_suffix
      }
      return cusForm
    },

    // 提交 - 链接
    fn_initLink () {
      const localLink = this.$store.getters['qrcode/GET_LINK']
      const linkForm = {
        linkStatus: localLink.link_open,
        contactStatus: 0
      }
      let link = {}

      // 如果开启了链接
      if (localLink.link_open) {
        linkForm.contactStatus = localLink.sevice_open
        link = {
          linkType: +localLink.link_type
        }

        // 简约风格
        if (localLink.link_type === 1) {
          link.linkContent = localLink.guide
        }

        // 自定义
        if (localLink.link_type === 2) {
          linkForm.virtualStatus = localLink.unreal_expire_open
          link.linkImage = localLink.link_bg[0].url
          link.qrcodeWidth = localLink.qrcode_width
          link.qrcodeHeight = localLink.qrcode_height
          link.qrcodeLeft = localLink.qrcode_x
          link.qrcodeTop = localLink.qrcode_y

          if (localLink.unreal_expire_open) {
            link.virtualContent = localLink.unreal_expire_text
            link.virtualTime = this.fn_initExpire(localLink)
          }
        }

        // 如果开启了联系主办方
        if (localLink.sevice_open) {
          link.contactUserId = +localLink.sevice_ids[0].id || +localLink.sevice_ids[0]
        }
      }

      return { linkForm, link }
    },

    // 提交 - 虚拟剩余时间
    fn_initExpire (data) {
      const d = data.unreal_expire_day * 86400
      const h = data.unreal_expire_hour * 3600
      const m = data.unreal_expire_minute * 60
      const s = data.unreal_expire_second

      return d + h + m + s
    },

    // 提交活动
    async fn_submitForm () {
      this.btnLoading = true
      // 修改
      if (this.isEdit && !this.isCopy) {
        const data = await this.COMM_HTTP.update({
          id: this.actId,
          form: this.subForm
        })
        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          this.btnLoading = false
          return false
        }

        this.fn_lyMsg('success', '修改活码成功！')
        this.backComfirm = false
        this.$router.push('list')
      } else {
        const data = await this.COMM_HTTP.save(this.subForm)
        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          this.btnLoading = false
          return false
        }

        this.fn_lyMsg('success', '提交成功！')
        this.backComfirm = false
        this.$router.push('list')
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          this.fn_resetForm()
          next()
        }).catch(() => { })
      } else {
        this.fn_resetForm()
        next()
      }
    }
  },

  components: {
    actLayout,
    viewBasic: Basic,
    viewCus: Cus,
    viewQrlink: Qrlink,
    mobiBasic,
    mobiLink
  }
}
</script>
