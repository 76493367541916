<!--
 * @Author: zhanln
 * @Date: 2022-03-03 16:57:57
 * @LastEditTime: 2022-04-12 20:18:23
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <el-tabs v-model="tabName" class="agr-record__step">
    <el-tab-pane label="私聊" name="private">
      <private-record
        :cusList="cusList"
        v-if="tabName === 'private'"
      ></private-record>
    </el-tab-pane>
    <el-tab-pane label="群聊" name="group">
      <group-record
        :cusList="cusList"
        v-if="tabName === 'group'"
      ></group-record>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { agrs } from './http.js'
import privateRecord from './privateRecord'
import groupRecord from './groupRecord'
export default {
  name: 'record',

  components: {
    privateRecord,
    groupRecord
  },
  data () {
    return {
      pageLoading: true,
      tabName: 'private',
      cusList: []
    }
  },
  async mounted () {
    this.fn_getCusList()
  },

  methods: {
    // 搜索栏， 客服列表
    async fn_getCusList () {
      const res = await agrs.selectList()
      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }
      const result = res || []
      // const list = []
      result.map(e => {
        this.cusList.push({
          label: e.account,
          value: e.id
        })
      })
      this.pageLoading = false
      // return list
    }
  }
}
</script>

<style lang="scss" scoped>
.agr-record__step {
  flex: 1;
  overflow-y: scroll;
  &::v-deep {
    .el-tabs__header {
      padding: 0 40px;
    }
  }
  ::v-deep {
    .xl-ly-search {
      margin: 0 20px 20px;
    }
    .ly-table-main {
      margin: 0 20px 80px;
      padding-top: 20px;
    }
  }
}
.page-warp {
  padding-top: 1px;
  height: calc(100% - 100px);
}
</style>
