import axios from '@/common/ajax.js'

// 消息任务列表
export const fsend = {
  reqQuery: params => axios.post('/wecom/robot/group/message-task/list', params),
  reqDelete: params => axios.post('/wecom/robot/group/message-task/delete', params)
}

// 发送详情
export const sendDetail = {
  reqQuery: params => axios.post('/wecom/robot/group/message-task/detail', params),
  retry: params => axios.post('/wecom/robot/group/message-task/retry', params)
}
// 选择群聊
export const group = {
  robotlist: params => axios.get('/wecom/robot/group/work/static', { params }), // 机器人列表
  allTopic: params => axios.get('/wecom/robot/group/topic', { params }) // 所有分组
}

// 选择群聊-按云端账号选择
export const selectAccount = {
  robotlist: params => axios.get('/wecom/robot/group/work/static', { params }), // 机器人列表
  robotGroup: params => axios.get('/wecom/robot/group', { params }) // 机器人管理的群
}

// 选择群聊-按群组选择
export const selectGroup = {
  allGroup: params => axios.get('/wecom/robot/group', { params }), // 所有群
  groupList: params => axios.get('/wecom/robot/group/topic', { params }), // 分组列表
  groups: params => axios.get('/wecom/robot/group', { params }) // 分组内群列表
}

export const common = {
  messageSend: params => axios.post('/wecom/robot/group/mass-message-send', params), // 创建无限制群发任务
  noticeSend: params => axios.post('/wecom/robot/group/mass-notice-send', params), // 创建公告
  messageUpdate: params => axios.post('/wecom/robot/group/mass-message-send/update', params), // 修改无限制群发任务
  noticeUpdate: params => axios.post('/wecom/robot/group/mass-notice-send/update', params), // 修改公告
  messageRecord: params => axios.post('/wecom/robot/chat/message-record', params), // 获取好友聊天消息
  urlInfo: params => axios.get('/wecom/system/urlInfo', { params }), // 通过url获取信息
  containsMini: params => axios.get('/wecom/robot/chat/contains-mini-program', { params }), // 有小程序消息的好友
  messageDetail: params => axios.post('/wecom/robot/group/mass-message-send/detail', params), // 查询群任务详情
  noticeDetail: params => axios.post('/wecom/robot/group/mass-notice-send/detail', params) // 查询公告任务详情
}
