<!--
 * @Author: zhanln
 * @Date: 2021-10-18 17:32:50
 * @LastEditTime: 2022-02-22 11:14:18
 * @LastEditors: zhanln
 * @Description: 新增群聊
-->

<template>
  <ly-dialog
    title="新增群聊"
    :visible.sync="visible"
    :setting="dialogSetting"
    btnSize="medium"
    class="add-dialog"
    @confirm="fn_confirm"
  >
    <div class="add-group">
      <ly-table
        class="act-list"
        ref="lyTable"
        :data="tableData"
        :option="tableOpt"
        :page="page"
        :page-size="per_page"
        :total="total"
        @searchChange="searchChange"
        @sizeChange="handleSizeChange"
        @sortChange="handleSortChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelectionChange"
      >
        <!-- 列插槽 -->
        <template #countHeaderSlot>
          <span style="margin-right: 4px">自动切群人数</span>
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            popper-class="q-tooltip"
          >
            <div slot="content">
              每个群入群人数达到「自动切换人数」后，自动切换下个群，<br />
              当群的总人数达到190人后，不再参与分配。
            </div>
            <i class="icon-info-fill iconfont"></i>
          </el-tooltip>
        </template>
        <template #countSlot="{ row }">
          <el-input-number
            v-model="row.max_account"
            :class="['number-small', !row.max_account ? 'error' : '']"
            controls-position="right"
            :min="1"
            :max="190"
            size="small"
          ></el-input-number>
        </template>
      </ly-table>
    </div>
  </ly-dialog>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import lyDialog from '@/components/global/lyDialog'
import { groupManage } from '../http.js'
export default {
  name: 'addGroup',
  mixins: [lyTable],

  props: {
    activityId: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      COMM_HTTP: groupManage,
      visible: false,
      dialogSetting: {
        width: '1000px',
        'close-on-click-modal': false,
        'close-on-press-escape': false,
        cancleText: '上一步'
      },
      uploadSetting: {
        limit: null,
        'show-file-list': false
      },
      tableData: []
    }
  },

  computed: {
    tableOpt () {
      return {
        layout: 'hide',
        operation: 'hide',
        column: [
          {
            label: '群名称',
            prop: 'name',
            formatter: (row, column, cellValue, index) => {
              return cellValue || '群聊'
            }
          },
          {
            label: '自动切群人数',
            prop: 'max_account',
            slot: 'countSlot',
            headerSlot: 'countHeaderSlot'
          }
        ]
      }
    }
  },

  methods: {

    queryData () { },

    // 修改群码
    fn_changeCode (img, row) {
      row.qrcode_url = img
      row.code_error = false
    },

    // 确认
    async fn_confirm () {
      const data = this.tableData
      let count = 0
      for (let i = 0; i < data.length; i++) {
        if (!data[i].max_account) {
          count++
        }
      }

      if (count > 0) {
        return this.fn_lyMsg('info', '请填写切群人数!')
      }

      const subData = data.map(item => {
        const obj = {
          id: item.id,
          max_account: item.max_account,
          sort: item.sort
        }
        return obj
      })

      this.fn_submit(subData)
    },

    // 提交
    async fn_submit (data) {
      const res = await this.COMM_HTTP.add({
        activity_id: this.activityId,
        data
      })
      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.fn_lyMsg('success', '新增成功！')
      this.$emit('addSuccess')
    }
  },

  components: {
    lyDialog
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.group {
  &-upload {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
    }
    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }

      &:hover {
        .tips-opacity {
          opacity: 1;
        }
      }
    }

    &__icon {
      width: 70px;
      height: 70px;
      line-height: 70px;
    }

    &__tips {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      opacity: 0;
      transition: opacity 0.3s;
      line-height: 72px;

      i {
        color: #fff;
      }
    }
  }
}

.add-dialog {
  ::v-deep {
    .el-input-number {
      &.error .el-input__inner {
        border-color: $--color-danger;
      }
    }

    .ly-dialog__body {
      min-height: 475px;
      overflow: hidden;
    }
  }
}

.add-group {
  max-height: 475px;
  display: flex;
  .xl-ly-table {
    flex: 1;
    overflow: hidden;
    ::v-deep {
      .el-table {
        display: flex;
        flex-direction: column;
        .el-table__header-wrapper {
          overflow: unset;
        }
        .el-table__body-wrapper {
          overflow-x: hidden;
          overflow-y: scroll;
        }
      }
    }
  }
}
.number-small {
  ::v-deep {
    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}
</style>
