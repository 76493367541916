<!--
 * @Author: zhanln
 * @Date: 2021-07-28 16:05:06
 * @LastEditTime: 2021-12-28 11:39:31
 * @LastEditors: zhanln
 * @Description: 其他设置
-->
<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>
    <div class="edit-card__content" v-loading="cardLoading">
      <el-form
        :model="otherForm"
        :rules="rules"
        ref="otherFormRef"
        label-width="125px"
        style="width: 60%; min-width: 560px"
        size="medium"
      >
        <el-form-item label="活动标题" prop="show_title">
          <el-input
            maxlength="15"
            show-word-limit
            v-model="otherForm.show_title"
            @change="fn_setForm($event, 'show_title')"
          ></el-input>
        </el-form-item>

        <el-form-item label="活动页首图" required :error="bannerError">
          <el-radio-group
            v-model="otherForm.banner_type"
            @change="fn_setForm($event, 'banner_type')"
          >
            <el-radio :label="1">默认</el-radio>
            <el-radio :label="2">自定义</el-radio>
          </el-radio-group>
          <div
            class="flex"
            v-if="otherForm.banner_type === 2"
            style="margin-top: 16px"
          >
            <el-upload
              action="#"
              accept=".png, .jpg"
              :http-request="requestQiniu"
              :on-remove="removePoster"
              list-type="picture-card"
              :limit="1"
              ref="uploadFile"
              :file-list="otherForm.banner"
              :class="[
                'upload',
                otherForm.banner.length === 0 ? '' : 'upload-empty',
              ]"
            >
              <div class="default">
                <i class="iconfont icon-picture"></i>
                <span>上传首图</span>
              </div>
            </el-upload>
            <div
              class="q-info text-small"
              style="line-height: 22px; margin-left: 24px"
            >
              首图设计须知：<br />
              (1) 建议尺寸：宽度750px，分辨率72 <br />
              (2) 支持JPG、PNG格式，图片小于2MB
            </div>
          </div>
        </el-form-item>

        <el-form-item
          label="封面标题"
          prop="banner_title"
          style="width: 60%; min-width: 560px"
          v-if="otherForm.banner_type === 1"
        >
          <el-input
            type="textarea"
            resize="none"
            maxlength="16"
            show-word-limit
            v-model="otherForm.banner_title"
            @change="fn_setForm($event, 'banner_title')"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="活动页底色"
          v-if="otherForm.banner_type === 2"
          prop="bg_color"
        >
          <div class="banner-color">
            <el-color-picker
              v-model="otherForm.bg_color"
              :predefine="colorList"
              @change="fn_setForm($event, 'bg_color')"
            ></el-color-picker>
          </div>
        </el-form-item>

        <el-form-item label="活动规则" required :error="ruleError">
          <ly-editor
            title="活动规则"
            id="otherRule"
            :content="otherForm.rule"
            :inseMenu="['用户昵称']"
            :recoverContent="ruleInit"
            :error="ruleError !== ''"
            :maxLength="2000"
            @getContent="fn_getRule"
          ></ly-editor>
        </el-form-item>

        <el-form-item
          prop="customer_service_user"
          class="basic-form__item"
          :error="idError"
        >
          <template slot="label"
            ><span class="q-danger">*</span> 主办方客服
            <el-tooltip
              class="item"
              effect="light"
              placement="right"
              popper-class="q-tooltip is-light"
            >
              <div slot="content">
                <div style="margin: 24px 24px 16px; width: 424px">
                  <img
                    src="https://image.01lb.com.cn//uploads/wecom/21/0809/1628501487xb97X7Wi.jpg"
                    width="424"
                    alt=""
                  />
                </div>
              </div>
              <i class="iconfont icon-info-fill"></i
            ></el-tooltip>
          </template>
          <ly-emp-btn
            btnText="选择客服"
            :max="100"
            :isReal="true"
            :datafilter="true"
            :checkList="otherForm.customer_service_user"
            @getEmpId="fn_getEmpId"
          ></ly-emp-btn>
        </el-form-item>

        <el-form-item
          required
          :error="cusWordError"
          label="客服欢迎语"
          class="basic-form__item"
        >
          <ly-editor
            title="客服欢迎语"
            id="basicCustomerWel"
            :content="otherForm.customer_wel_come_msg"
            :recoverContent="customer_initHtml"
            :error="cusWordError !== ''"
            emoji
            :insetMenu="['用户昵称']"
            @getContent="fn_getCusWelWords"
          ></ly-editor>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import lyEditor from '@/components/lyEditor'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
export default {
  name: 'way',

  props: {
    title: {
      type: String,
      defualt: () => {
        return ''
      }
    }
  },
  mixins: [qiniuUpload],

  data () {
    return {
      cardLoading: true,
      otherForm: {
        show_title: '邀请加入群聊',
        banner_type: 1,
        banner: [],
        banner_title: '加入专属福利群\n0元领奖品',
        rule: '1、邀请3人即可获得奖品。\n2、任务完成后，用户所在群发放奖品链接，点击即可领取。用户也可自行保存活动链接，实时了解任务进度。\n3、若邀请进群的用户为老用户或该用户已为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户入群助力无法获得奖品。\n5、好友退群后助力无效。',
        bg_color: '#f4503a',
        customer_service_user: null,
        customer_wel_come_msg:
          '#用户昵称# 你好，欢迎参加本次活动，我是你的专属小助手，有任何活动相关问题都可以咨询我哦~'
      },
      rules: {
        show_title: [
          { required: true, message: '请输入活动标题', trigger: 'change' }
        ],
        banner_title: [
          { required: true, message: '请输入封面标题', trigger: 'change' }
        ],
        bg_color: [
          { required: true, message: '请选择活动页底色', trigger: 'change' }
        ]
      },
      ruleError: '',
      ruleInit: '1、邀请3人即可获得奖品。\n2、任务完成后，用户所在群发放奖品链接，点击即可领取。用户也可自行保存活动链接，实时了解任务进度。\n3、若邀请进群的用户为老用户或该用户已为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户入群助力无法获得奖品。\n5、好友退群后助力无效。',
      bannerError: '',
      colorList: ['#f4503a', '#ffa500'],
      idError: '',
      customer_initHtml:
        '#用户昵称# 你好，欢迎参加本次活动，我是你的专属小助手，有任何活动相关问题都可以咨询我哦~',
      cusWordError: ''
    }
  },

  computed: {
    isReady () {
      return this.$store.getters['groupfission/GET_READY']
    }
  },

  watch: {
    isReady (val) {
      if (val) {
        const isEdit = this.$store.getters['groupfission/GET_TYPE']
        if (isEdit) {
          this.otherForm = this.$store.getters['groupfission/GET_OTHER']
        }
        this.cardLoading = false
      }
    },
    'otherForm.banner_type' (val) {
      if (val === 1) {
        this.bannerError = ''
      }
    },
    'otherForm.rule' (val) {
      this.ruleError = val ? '' : '请输入活动规则'
    },
    'otherForm.customer_wel_come_msg' (val) {
      this.cusWordError = val ? '' : '请输入客服欢迎语'
    }
  },

  methods: {

    // 欢迎语
    fn_getCusWelWords (content) {
      if (this.isReady) {
        this.otherForm.customer_wel_come_msg = content
        this.fn_setForm(content, 'customer_wel_come_msg')
      }
    },

    // 客户账号
    fn_getEmpId (ids) {
      if (ids && ids.length > 0) {
        this.idError = ''
        this.otherForm.customer_service_user = ids
        this.fn_setForm(ids, 'customer_service_user')
      } else {
        this.idError = '请选择客服'
        this.otherForm.customer_service_user = null
        this.fn_setForm(null, 'customer_service_user')
      }
    },

    // 上传banner
    async requestQiniu (opts) {
      this.otherForm.banner = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.otherForm.banner = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.fn_setForm(this.otherForm.banner, 'banner')
          },
          null,
          {
            type: ['jpg', 'png'],
            size: '2MB'
          }
        )
      }

      this.bannerError = ''
    },

    // 移除背景
    removePoster () {
      this.otherForm.banner = []
      this.bannerError = '请上传活动页首图'
      this.fn_setForm([], 'banner')
    },

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: 'other',
        name: type,
        val: val
      })
    },

    // 活动规则
    fn_getRule (content) {
      this.otherForm.rule = content
      this.fn_setForm(content, 'rule')
    },

    // 校验
    verify () {
      let isVerify = true
      const form = this.otherForm

      this.$refs.otherFormRef.validate((valid) => {
        if (valid) {
          isVerify = true
        } else {
          isVerify = false
        }
      })

      if (form.banner_type === 2 && form.banner.length === 0) {
        this.bannerError = '请上传活动页首图'
        isVerify = false
      }

      if (form.rule === '') {
        this.ruleError = '请输入活动规则'
        isVerify = false
      }

      if (!form.customer_service_user) {
        this.idError = '请选择客服'
        isVerify = false
      }

      if (this.cusWordError) {
        isVerify = false
      }

      return isVerify
    }
  },

  components: {
    lyEditor
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
@import "@/assets/scss/var.scss";

.upload {
  line-height: 1;
  ::v-deep .el-upload-list__item {
    margin: 0;
    transition: none !important;
  }

  &-header {
    background-color: #f7f8fc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #dfe1e8;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);

    &-inset {
      background-color: #f5f5f5;
      border-right: 2px;
      font-size: 12px;
      padding: 0 8px;
      height: 24px;
      line-height: 24px;
      margin-right: 16px;
      cursor: pointer;
      border-radius: 2px;

      &:hover {
        color: $--color-primary;
      }
    }
  }

  &-inset {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;
    background: #f2f5fe;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: $--transition;
    font-size: 12px;

    &:hover {
      background-color: $--color-primary;
      color: #fff;
    }

    &.default {
      background-color: transparent;
      color: $--color-text-regular;
      padding: 0;

      &:hover {
        color: $--color-primary;
      }
    }

    & + & {
      margin-left: 12px;
    }
  }

  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 200px !important;
  }
}

.q-bg.is-default {
  border-radius: 8px;
  border: none;
  padding: 16px 20px;
  margin-bottom: 12px;
}
.upload-empty {
  line-height: 1;
  ::v-deep .el-upload--picture-card {
    display: none;
  }
  ::v-deep .el-upload-list__item {
    margin: 0;
  }
}
</style>
