var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "inner is-dark detail" },
    [
      _c("div", { staticClass: "detail-base ly-card is-margin" }, [
        _c("div", { staticClass: "detail-base__header flex flex-between" }, [
          _c("div", { staticClass: "ly-table__main-title" }, [
            _vm._v("员工信息")
          ]),
          _c(
            "div",
            {
              staticClass: "ly-link is-dark",
              on: { click: _vm.jumpToAnalyse }
            },
            [
              _vm._v(" 查看完整客户数据"),
              _c("i", { staticClass: "ri-arrow-right-s-line" })
            ]
          )
        ]),
        _c("div", { staticClass: "flex flex-between" }, [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "detail-base__avatar" },
              [
                _c("el-avatar", {
                  attrs: {
                    size: 64,
                    src: _vm.info.avatar_url || _vm.defaultAvatar
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "flex flex-column flex-start" }, [
              _c(
                "div",
                {
                  staticClass: "flex",
                  staticStyle: { "margin-bottom": "8px" }
                },
                [
                  _c("span", { staticClass: "detail-base__name" }, [
                    _vm._v(_vm._s(_vm.info.name || "-"))
                  ]),
                  _vm.info.role
                    ? [
                        _vm.info.role === "管理员"
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "warning" } },
                              [_vm._v(_vm._s(_vm.info.role))]
                            )
                          : _c(
                              "el-tag",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v(_vm._s(_vm.info.role))]
                            )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c("div", { staticClass: "q-info" }, [
                _vm._v("别名：" + _vm._s(_vm.info.alias || "-"))
              ])
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "flex flex-column" }, [
              _c("div", { staticClass: "q-info" }, [_vm._v("今日新增客户")]),
              _c("div", { staticClass: "detail-num" }, [
                _vm._v(_vm._s(_vm.info.today_add))
              ])
            ]),
            _c("div", { staticClass: "ly-gap is-vertical" }),
            _c("div", { staticClass: "flex flex-column" }, [
              _c("div", { staticClass: "q-info" }, [_vm._v("今日流失客户")]),
              _c("div", { staticClass: "detail-num" }, [
                _vm._v(_vm._s(_vm.info.today_del))
              ])
            ]),
            _c("div", { staticClass: "ly-gap is-vertical" }),
            _c("div", { staticClass: "flex flex-column" }, [
              _c("div", { staticClass: "q-info" }, [_vm._v("客户总数")]),
              _c("div", { staticClass: "detail-num" }, [
                _vm._v(_vm._s(_vm.info.total))
              ])
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "ly-card is-margin" },
        [
          _c(
            "div",
            {
              staticClass: "ly-table__main-header flex flex-between",
              staticStyle: { "margin-bottom": "24px" }
            },
            [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "ly-table__main-title" }, [
                  _vm._v("聊天数据")
                ]),
                _c(
                  "div",
                  { staticClass: "ly-search" },
                  [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-form-inline",
                        attrs: {
                          inline: true,
                          model: _vm.searchForm,
                          size: "medium"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { staticStyle: { "margin-bottom": "0" } },
                          [
                            _c(
                              "el-button-group",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        _vm.btnState === "day" ? "primary" : ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getAnalyse("day")
                                      }
                                    }
                                  },
                                  [_vm._v("昨日")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        _vm.btnState === "week" ? "primary" : ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getAnalyse("week")
                                      }
                                    }
                                  },
                                  [_vm._v("近7日")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        _vm.btnState === "month"
                                          ? "primary"
                                          : ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getAnalyse("month")
                                      }
                                    }
                                  },
                                  [_vm._v("近30日")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: { label: "自定义：" }
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "260px" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "~",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.pickerOptions
                              },
                              on: { change: _vm.changeTime },
                              model: {
                                value: _vm.dates,
                                callback: function($$v) {
                                  _vm.dates = $$v
                                },
                                expression: "dates"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "ly-link is-dark",
                  on: { click: _vm.jumpToAnalyseCus }
                },
                [
                  _vm._v(" 查看完整聊天数据"),
                  _c("i", { staticClass: "ri-arrow-right-s-line" })
                ]
              )
            ]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            _vm._l(_vm.cardData, function(cItem, cIndex) {
              return _c("el-col", { key: cIndex, attrs: { span: 6 } }, [
                _c("div", { staticClass: "ly-card is-gray" }, [
                  _c("div", { staticClass: "q-info" }, [
                    _vm._v(_vm._s(cItem.title))
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "detail-num" }, [
                      _vm._v(_vm._s(cItem.count))
                    ]),
                    _c("span", { staticClass: "q-info" }, [
                      _vm._v(_vm._s(cItem.sub))
                    ])
                  ])
                ])
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "layout-table",
        {
          attrs: { pageTotal: _vm.total, loading: _vm.loading, page: _vm.page },
          on: { pageChange: _vm.pageChange }
        },
        [
          _c(
            "div",
            { staticClass: "ly-table__main-header flex flex-between" },
            [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "ly-table__main-title" }, [
                  _vm._v("客户列表")
                ]),
                _c("span", [_vm._v("共" + _vm._s(_vm.total) + "个客户")]),
                _c("span", { staticClass: "ly-gap is-vertical" }),
                _vm._v(
                  " " +
                    _vm._s(_vm.added) +
                    "个已添加，" +
                    _vm._s(_vm.deled) +
                    "个已删除 "
                )
              ])
            ]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData },
              on: { "sort-change": _vm.tableSort }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "avatar, name, corp_name", label: "全部客户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "flex" }, [
                          scope.row.avatar_url
                            ? _c("img", {
                                staticClass: "ly-table__main-avatar",
                                attrs: { src: scope.row.avatar_url, alt: "" }
                              })
                            : _c("img", {
                                staticClass: "ly-table__main-avatar",
                                attrs: {
                                  src: require("../../../assets/images/default_avatar.png"),
                                  alt: ""
                                }
                              }),
                          _c("div", [
                            _vm._v(" " + _vm._s(scope.row.name) + " "),
                            scope.row.corp_name
                              ? _c("span", { staticClass: "q-warning" }, [
                                  _vm._v("@" + _vm._s(scope.row.corp_name))
                                ])
                              : _c("span", { staticClass: "q-success" }, [
                                  _vm._v("@微信")
                                ])
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "tags", label: "客户标签" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("ly-drow", {
                          attrs: { list: scope.row.tags, dep: "" }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "客户状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 0
                          ? _c("ly-status", { attrs: { type: "success" } }, [
                              _vm._v("已添加")
                            ])
                          : scope.row.status === 1
                          ? _c("ly-status", { attrs: { type: "danger" } }, [
                              _vm._v("已删除")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "added_at",
                  label: "添加时间",
                  sortable: "custom"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "ly-table__main-time" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.util.timeTofmt(
                                  scope.row.added_at,
                                  "yyyy-MM-dd hh:mm"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "180",
                  "class-name": "operation-cell"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.jumpToCus(scope.row)
                              }
                            }
                          },
                          [_vm._v("客户详情")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "ly-table-empty",
                  attrs: { slot: "empty" },
                  slot: "empty"
                },
                [
                  _vm._t("empty", [
                    _c("img", {
                      attrs: {
                        src: require("@assets/svg/default/no_data.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" 暂无数据 ")
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }