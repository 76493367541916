<!--
 * @Descripttion: 用户详情页面
 * @version: 1.0.0
-->
<template>
  <el-dialog
    v-if="visible"
    title="详情"
    :visible.sync="visible"
    width="500"
    class="has-table"
    @close="$emit('close')"
  >
    <div class="user-info">
      <el-avatar :size="54" :src="userInfo.avatar"></el-avatar>
      <div class="info">
        <span class="name">{{ userInfo.nickname || '-'}}</span>
      </div>
    </div>
    <!-- <el-tag v-for="item in this.userInfo.tag_name || []" :key="item">{{ item }}</el-tag> -->
    <el-tabs v-model="activeName" type="card" class="ly-tabs">
      <el-tab-pane label="TA的邀请" name="help">
        <detail-help v-bind="userInfo"/>
      </el-tab-pane>
      <el-tab-pane label="奖品记录" name="records">
        <detail-record v-bind="userInfo"/>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import detailHelp from './detailHelp'
import detailRecord from './detailRecord'
export default {
  components: {
    detailHelp, detailRecord
  },
  data () {
    return {
      visible: false,
      userInfo: {},
      activeName: 'help'
    }
  }
}
</script>
<style lang="scss" scoped>
.user-info{
  margin-bottom: 20px;
  display: flex;
  .info{
    margin-left: 12px;
    .name {
      display: block;
      line-height: 1;
      font-size: 18px;
      line-height: 54px;
    }
  }
  .el-avatar{
    background: unset;
  }
}
.el-tag{
  margin-right: 10px;
  padding: 0 20px;
}
.el-tabs{
  margin-top: 15px;
}
::v-deep{
  .el-tabs__header{
    margin-bottom: 0
  }
  .el-tabs__item{
    background: rgba(0,0,0,0.02);
  }
  .el-tabs__item.is-active {
    background: #fff;
  }
  .el-form-item{
    margin-bottom: 16px;
  }
  .xl-ly-search{
    box-shadow: unset;
    margin-bottom: 0;
    padding-bottom: 0;
    .el-form-item{
      margin-bottom: 0;
    }
  }
  .xl-ly-setting .handler-left, .xl-ly-setting .handler-right{
    margin-bottom: 16px;
  }
}
</style>
