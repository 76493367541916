<template>
  <div class="welcome-word-edit">
    <div class="main-area ly-shadow">
      <div class="form-area">
        <el-scrollbar>
          <div style="padding-right: 15px">
            <div class="top-tips">
              <p>
                1. 用户在企微官方后台设置了欢迎语，不会推送此处设置的欢迎语。<br />
                2.
                用户通过渠道码添加成员，不会推送此处设置的欢迎语，只推送在渠道活码处设置的欢迎语<br />
                3. 同一成员在此设置多条欢迎语，只推送最近更新的欢迎语
              </p>
            </div>
            <el-form ref="form" size="medium" :model="form" label-width="120px" :rules="rules">
              <el-form-item label="欢迎语名称：" prop="welcomeContentName">
                <el-input maxlength="10" show-word-limit v-model="form.welcomeContentName" placeholder="名称不会向用户展示">
                </el-input>
              </el-form-item>
              <el-form-item label="使用成员：" prop="userList">
                <ly-emp-btn btnText="添加成员账号" :max="100" :isReal="true" :datafilter="true" :checkList="checkProp"
                  @getEmpIdArr="handleGetEmpId"></ly-emp-btn>
              </el-form-item>
              <el-form-item ref="task_content" label="欢迎语内容：" prop="attachmentList">
                <div class="desc">最多支持发送10条消息，其中文本消息限1条</div>
                <div class="msg-list">
                  <msg-template v-for="(item, i) in form.attachmentList"
                    :title="`消息${i + 1}：${getMsgType(item.messageType)}`" :class="`type${item.messageType}`" :msg="item"
                    :key="item.temp" :disabled="getDisabledStatus(i)" @change="handleAction" />
                </div>
                <el-button v-if="this.form.attachmentList.length < 10" type="primary" round icon="iconfont icon-plus"
                  @click="handleAddContent" style="margin-top: 16px">添加消息</el-button>
                <add-content ref="addContent" :hasText="hasText" @push="handlePushMsg" />
              </el-form-item>
            </el-form>
          </div>
        </el-scrollbar>
      </div>
      <div class="h5-area">
        <el-scrollbar>
          <mobile :msg="form.attachmentList" />
        </el-scrollbar>
      </div>
    </div>
    <div class="submit-area">
      <el-button type="primary" round icon="iconfont icon-save" @click="handleSubmit">保存欢迎语</el-button>
    </div>
  </div>
</template>

<script>
import mobile from '@/components/Mobile.vue'
import msgTemplate from './components/msgTemplate.vue'
import addContent from './components/addContent.vue'
import { editApi } from './http'
import mapTag from '@/common/mapTag'
export default {
  components: {
    mobile, msgTemplate, addContent
  },
  data () {
    return {
      form: {
        welcomeContentName: '',
        welcomeContent: '',
        userList: [],
        attachmentList: []
      },
      rules: {
        welcomeContentName: [
          { required: true, message: '请填写欢迎语名称' }
        ],
        userList: [
          { required: true, message: '请选择使用成员' }
        ],
        attachmentList: [
          { required: true, message: '请添加欢迎语内容', trigger: 'blur' }
        ]
      },
      backComfirm: true
    }
  },
  computed: {
    hasText () {
      return this.form.attachmentList.some(e => {
        return e.messageType === 4
      })
    },
    checkProp () {
      return this.form.userList.map(e => e.userId)
    },
    editID () {
      return this.$route.query.id
    }
  },
  mounted () {
    window.addEventListener('beforeunload', this.fn_beforeunload, false)
    if (this.editID) {
      document.title = '修改欢迎语'
      this.$store.commit('_SET', {
        'global.firstTitle': '修改欢迎语'
      })
      this.getWecomeDetail(this.editID)
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.fn_beforeunload, false)
  },
  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          next()
        }).catch(() => { })
      } else {
        next()
      }
    }
  },
  methods: {
    // 页面刷新
    fn_beforeunload (e) {
      e = e || window.event
      if (e) {
        e.returnValue = ''
      }
      return ''
    },
    /**
     * @description: 获取详情
     * @param {*}
     * @return {*}
     */
    getWecomeDetail (id) {
      editApi.detail(id).then(res => {
        this.form = res
        const temp = Date.now()
        this.form.attachmentList.map((e, i) => {
          this.form.attachmentList[i] = { ...e, temp: temp + i }
          if (e.messageType === 4) {
            this.form.attachmentList[i].welcomeContent = mapTag(this.form.attachmentList[i].welcomeContent)
          }
        })
      })
    },
    handleGetEmpId (ids) {
      this.form.userList = ids.map(({ id, name }) => {
        return {
          userId: id,
          userName: name
        }
      })
      this.$refs.form.validateField('userList')
    },
    handleAddContent () {
      if (this.form.attachmentList.length >= 10) {
        this.$message.error('最多发送10条消息')
        return false
      }
      this.$refs.addContent.visible = true
    },
    // 新增消息回调
    handlePushMsg (msg) {
      if (msg.temp) {
        for (let i = 0; i < this.form.attachmentList.length; i++) {
          if (this.form.attachmentList[i].temp === msg.temp) {
            this.form.attachmentList.splice(i, 1, msg)
            break
          }
        }
      } else {
        this.form.attachmentList.push({
          temp: Date.now(), // 增加唯一标识用于调整顺序
          ...msg
        })
      }
      this.$refs.form.validateField('attachmentList')
    },
    getDisabledStatus (i) {
      if (this.form.attachmentList.length === 1) {
        return ['up', 'down']
      }
      if (i === 0) {
        return ['up']
      } else if (i === this.form.attachmentList.length - 1) {
        return ['down']
      } else {
        return []
      }
    },
    getMsgType (code) {
      let type = ''
      switch (code) {
        case 4:
          type = '文本'
          break
        case 1:
          type = '图片'
          break
        case 2:
          type = '链接'
          break
        case 3:
          type = '小程序'
          break
      }
      return type
    },
    handleAction (action, msg) {
      switch (action) {
        case 'edit':
          this.handleMsgEdit(msg)
          break
        case 'up':
          this.handleMsgUp(msg)
          break
        case 'down':
          this.handleMsgDown(msg)
          break
        case 'remove':
          this.handleMsgRemove(msg)
          break
      }
    },
    // 修改消息
    handleMsgEdit (msg) {
      this.$refs.addContent.handleEdit(msg)
    },
    // 上移
    handleMsgUp ({ temp }) {
      this.form.attachmentList.some((e, i) => {
        if (i !== 0 && e.temp === temp) {
          const item = this.form.attachmentList.splice(i, 1)[0]
          this.$nextTick(() => {
            this.form.attachmentList.splice(i - 1, 0, item)
          })
        }
      })
    },
    // 下移
    handleMsgDown ({ temp }) {
      this.form.attachmentList.some((e, i) => {
        if (e.temp === temp) {
          const item = this.form.attachmentList.splice(i, 1)[0]
          this.$nextTick(() => {
            this.form.attachmentList.splice(i + 1, 0, item)
          })
        }
      })
    },
    // 移除消息
    handleMsgRemove ({ temp }) {
      this.form.attachmentList = this.form.attachmentList.filter(e => {
        return e.temp !== temp
      })
    },
    handleSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const method = this.editID ? 'update' : 'create'
          const params = this.util.extend(true, {}, this.form)
          params.attachmentList.map((e, i) => {
            if (e.messageType === 4) {
              e.welcomeContent = mapTag(e.welcomeContent, false)
            }
          })
          editApi[method](params).then(res => {
            if (res) {
              this.$message.error(res.msg)
            } else {
              this.$message.success(`欢迎语${this.editID ? '修改' : '新增'}成功`)
              this.backComfirm = false
              this.$router.push({ name: '欢迎语列表', params: { refresh: true } })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-word-edit {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background: #f7f8fc;

  .main-area {
    flex: 1;
    display: flex;
    margin: 20px;
    height: calc(100% - 56px);
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
  }

  .el-scrollbar {
    height: 100%;
  }

  .form-area {
    background: #fff;
    padding: 20px 20px 40px 20px;
    width: 700px;
  }
}

.top-tips {
  border-radius: 8px;
  background: #ebf3ff;
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: space-around;
  margin-bottom: 20px;

  p {
    margin: 0;
    line-height: 24px;
  }
}

.desc {
  padding: 0 10px;
  line-height: 45px;
  background: #f7f8fc;
  border-radius: 8px;
  color: #b4bbcc;
}

.el-form {
  width: 560px;
}

.h5-area {
  flex: 1;
  height: 100%;
  background: #fff;
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;
  border-radius: 0 16px 16px 0;

  .el-scrollbar {
    ::v-deep {
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }
}

.submit-area {
  height: 56px;
  line-height: 56px;
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 5px 2px #f3f3f3;
}
</style>
