<!--
 * @Descripttion: 添加托管群弹窗
 * @version:
 * @Author: lw
 * @Date: 2021-05-13 16:31:31
 * @LastEditTime: 2022-03-02 11:38:09
-->
<template>
  <el-dialog
    title="添加托管的群"
    custom-class="add-management"
    :visible.sync="visible"
    @open="handleOpen"
    width="800px"
  >
    <div class="temp-border">
      <div class="left">
        <div class="title">
          <span>云端账号</span>
          <span>已托管数量</span>
        </div>
        <div class="list">
          <el-input
            v-model.trim="robotFilter"
            size="medium"
            class="list-search"
            clearable
            suffix-icon="iconfont icon-search"
            @input="fn_searchRobot"
            placeholder="请输入云端账号"
          ></el-input>
          <template v-if="robotlist.length === 0">
            <div class="el-table__empty-block">
              <span class="el-table__empty-text">暂无数据</span>
            </div>
          </template>
          <template v-else>
            <div class="list-block">
              <div
                v-for="item in robotlistFilter"
                @click="handleItemClick(item)"
                :key="item.id"
                class="list-item"
                :class="{ active: currentRobot.id === item.id }"
              >
                <div class="temp">
                  <el-avatar :size="32" :src="item.avatar"></el-avatar>
                  <span class="nick">{{ item.name }}</span>
                  <span class="online" v-if="item.status === 1">(在线)</span>
                  <span class="offline" v-else>(离线)</span>
                </div>
                <span>{{ `${item.work_group_total || 0}/30` }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="right">
        <div class="title">
          未被托管的群<span>(已选{{ selected.length }})</span>
        </div>
        <ly-table
          ref="lyTable"
          :data="tableData"
          :option="tableOpt"
          :page="page"
          :pageSize="per_page"
          :total="total"
          @searchChange="searchChange"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
          @selectionChange="handleSelectionChange"
        >
          <template #handler>
            <div class="search-form">
              <label>群名称：</label>
              <el-input
                size="medium"
                v-model="name"
                placeholder="请输入群名称"
                @input="queryData"
                clearable
              ></el-input>
            </div>
          </template>
          <template #handlerright>
            <el-button
              round
              type="primary"
              @click="handleSync"
              :disabled="!currentRobot.id"
              icon="icon-data iconfont"
              :loading="reload"
              >{{ reload ? "同步中..." : "同步" }}</el-button
            >
            <el-tooltip
              class="item"
              effect="dark"
              content="如果未找到相关的群聊，请在群上发 一条消息，再尝试同步数据。"
              placement="top-end"
            >
              <i
                class="iconfont icon-warning-fill"
                style="margin: 0 20px 0 10px; font-size: 16px"
              ></i>
            </el-tooltip>
          </template>

          <template #name_slot="{ row }">
            <div class="flex flex-start">
              <div class="ly-table-tearm">
                <i class="iconfont icon-weixin"></i>
              </div>
              <span style="margin-right: 8px; flex: 1">{{
                row.name ? row.name : "群聊"
              }}</span>
            </div>
          </template>
        </ly-table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        round
        type="primary"
        :disabled="!selected.length"
        size="medium"
        @click="handleAddManage"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { addManagement } from '../http.js'
import { debounce } from 'throttle-debounce'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: addManagement,
      IMMEDIATE: false,
      visible: false,
      name: '',
      robotlist: [],
      currentRobot: {}, // 左侧选中的机器人
      getList: null,
      robotFilter: '',
      reload: false,
      serial_no: null,
      reloadTime: null,
      loginlast: false
    }
  },
  computed: {
    robotlistFilter () {
      if (this.robotFilter === '') {
        return this.robotlist
      } else {
        return this.robotlist.filter(item => item.name.indexOf(this.robotFilter) > -1)
      }
    },
    tableOpt () {
      return {
        layout: 'total, prev, pager, next',
        showSelection: true,
        operation: 'hide',
        levitate: false,
        column: [
          {
            label: '群名称',
            prop: 'name',
            slot: 'name_slot'
          },
          {
            label: '群成员数',
            prop: 'user_total',
            width: 120
          }
        ]
      }
    }
  },
  destroyed () {
    if (this.reloadTime) {
      clearTimeout(this.reloadTime)
    }
  },
  methods: {
    handleOpen () {
      this.getRobotlist()
    },
    async getRobotlist () {
      const res = await this.COMM_HTTP.robotlist()
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }
      if (res.list.length > 0) {
        this.robotlist = res.list.map(item => {
          return item
        })
        this.currentRobot = this.robotlist[0]
        this.queryData()
        this.getList = debounce(300, this.queryData)
      }
    },
    handleItemClick (item) {
      if (item.id !== this.currentRobot.id) {
        this.$refs.lyTable.$refs.elTable.clearSelection()
        this.currentRobot = item
        this.queryData()
      }
    },
    async handleSync () {
      this.reload = true
      const res = await this.COMM_HTTP.robotSync({
        robot_id: this.currentRobot.id
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        this.reload = false
        return
      }

      this.serial_no = res.serial_no

      this.fn_getLoginStatus()
    },

    async fn_getLoginStatus () {
      const data = await this.axios.get('loginStatus', {
        params: { serial_no: this.serial_no }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.reload = false
        return
      }

      if (data.status === 0) {
        this.reloadTime = setTimeout(() => {
          this.fn_getLoginStatus()
        }, 3000)
      } else {
        if (!this.loginlast) {
          this.loginlast = true
          this.reloadTime = setTimeout(() => {
            this.fn_getLoginStatus()
          }, 5000)
        } else {
          this.reloadTime = setTimeout(() => {
            this.fn_lyMsg('success', '群数据已同步！')
            clearTimeout(this.reloadTime)
            this.reload = false
            this.reloadId = null
            this.page = 1
            this.name = ''
            this.getList()
          }, 5000)
        }
      }
    },
    async handleAddManage () {
      // 离线
      if (this.currentRobot.status !== 1) {
        this.$message.warning('当前账号处于离线状态，无法托管群聊')
        return false
      }

      // 已托管30个群
      if (this.currentRobot.work_group_total >= 30) {
        this.$message.warning('当前账号可托管的群数以达到30，请使用其他的账号进行托管')
        return false
      }

      // 已托管 + 选择超过30个群
      if (30 - this.currentRobot.work_group_total < this.selected.length) {
        this.$message.warning('当前选择的群已超过云端账号剩余可托管的群数')
      } else {
        const groupId = this.selected.map(e => {
          return e.id
        })
        const res = await this.COMM_HTTP.doWork({
          group_ids: groupId,
          robot_id: this.currentRobot.id,
          type: 0
        })
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.warning(res.msg)
          return false
        }

        const errorList = []
        for (let i = 0; i < res.list.length; i++) {
          if (res.list[i].code === 250118) {
            errorList.push(res.list[i].group_name)
          }
        }

        if (errorList.length > 0) {
          this.$lyConfirm({
            title: `企微号聊天列表未同步到 ${errorList.join('、')} 的数据，请在群内发送一条消息。`,
            text: '',
            confirmButtonText: '确定',
            showCancelButton: false
          }).then(async () => { }).catch(() => { })
        } else {
          this.$message.success('添加成功！')
          this.visible = false
        }

        this.$refs.lyTable.$refs.elTable.clearSelection()
        this.$emit('change')
      }
    },
    formatterParams (params) {
      return {
        ...params,
        group_name: this.name,
        id: this.currentRobot.id,
        robot_serial_no: this.currentRobot.serial_no
      }
    },
    // 搜索云端账号
    fn_searchRobot (val) {
      console.log(val, this.robotlist)
    }
  }
}
</script>
<style lang="scss" scoped>
.add-management {
  .left,
  .right {
    float: left;
  }
  .left {
    width: 280px;
    background: #f7f8fc;
    height: 50vh;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    border-right: 1px solid #eaebf2;
    .title {
      margin: 0 20px;
      box-sizing: border-box;
      color: #212b36;
      display: flex;
      justify-content: space-between;
      height: 54px;
      line-height: 54px;
    }
  }
  .temp-border {
    border: 1px solid #eaebf2;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
  }
  .list {
    padding: 0 16px 16px;
    flex: 1;
    overflow: hidden;
    .online {
      color: #48d848;
    }
    .offline {
      color: #b4bbcc;
    }
  }
  .list-block {
    height: calc(100% - 38px);
    margin: 0 -16px;
    overflow: auto;
  }
  .list-search {
    margin-bottom: 16px;
  }
  .list-item {
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .temp {
      width: 150px;
      display: flex;
      flex: 1;
      align-items: center;
    }
    .nick {
      margin: 0 5px;
      max-width: 78px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &.active {
      background: #eaebf2;
    }
  }
  .right {
    flex: 1;
    overflow: hidden;
    max-height: 50vh;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    .title {
      color: #212b36;
      height: 54px;
      line-height: 54px;
      padding: 0 22px;
      span {
        color: #2b7cff;
        margin-left: 8px;
      }
    }
    .xl-ly-table {
      flex: 1;
      overflow: hidden;
      ::v-deep {
        .handler-left,
        .handler-right {
          margin-top: 0;
        }
        .ly-table-main {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
        }
        .el-table {
          margin-top: 2px;
          display: flex;
          flex-direction: column;
          .el-table__header-wrapper {
            overflow: unset;
          }
          .el-table__body-wrapper {
            overflow-x: hidden;
            overflow-y: scroll;
          }
        }
        .table-pagination-area {
          height: unset;
        }
      }
    }
    .search-form {
      display: flex;
      align-items: center;
      padding-left: 22px;
      .el-input {
        width: 143px;
      }
    }
    .icon-warning-fill iconfont {
      margin-right: 8px;
      font-size: 18px;
    }
  }
}
::v-deep {
  .el-dialog__body {
    overflow: hidden;
  }
  .handler-left,
  .handler-right {
    margin-bottom: 14px !important;
  }
  .el-table::before {
    content: unset;
  }
}
</style>
