var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drafts" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推广素材",
            visible: _vm.extendVisible,
            width: "900px"
          },
          on: {
            "update:visible": function($event) {
              _vm.extendVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  {
                    staticClass: "previewMobilew",
                    staticStyle: { "margin-right": "20px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: !_vm.isGenerateForm,
                            expression: "!isGenerateForm"
                          }
                        ],
                        staticClass: "previewMobile",
                        staticStyle: { width: "321px" }
                      },
                      [
                        _c("normal-form", {
                          attrs: {
                            data: _vm.normalTableForm,
                            formName: _vm.formTable.name
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("div", [_vm._v("表单链接：")]),
                  _c("div", { staticClass: "activity_url mt-12" }, [
                    _vm._v(_vm._s(_vm.linkUrl))
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-12" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { round: "", size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleCopy()
                            }
                          }
                        },
                        [_vm._v(" 复制链接 ")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "margin-top": "60px" } }, [
                    _vm._v("表单二维码：")
                  ]),
                  _c("el-image", {
                    staticClass: "mt-12",
                    staticStyle: { width: "140px", height: "140px" },
                    attrs: { src: _vm.imageUrl || "@/assets/svg/QRcode.svg" }
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-16" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            round: "",
                            type: "primary",
                            size: "small",
                            icon: "iconfont icon-download"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleDownload(_vm.imageUrl)
                            }
                          }
                        },
                        [_vm._v(" 下载二维码 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }