<!--
 * @Author: zhanln
 * @Date: 2021-11-18 16:17:41
 * @LastEditTime: 2022-09-16 11:49:02
 * @LastEditors: zhanln
 * @Description: 无库存规则
-->

<template>
  <el-form :model="form" label-width="160px" class="act-form" size="medium">
    <el-form-item label="无库存规则：">
      <el-radio-group
        v-model="form.stock_type"
        class="rule-form__radio"
        :disabled="eType === 1"
      >
        <el-radio :label="2"
          >所有阶段库存为0时，活动暂停
          <el-tooltip class="item" placement="top" popper-class="q-tooltip">
            <div slot="content">
              任意一个阶段奖品库存为0时，活动仍然继续，至所有阶段库存被领取完，活动才自动暂停。<br />
              奖品无库存后，此处增加库存，可以在「奖品记录」将奖品重新发放给用户。
            </div>
            <i class="ri-question-line"></i>
          </el-tooltip>
        </el-radio>
        <el-radio :label="1"
          >任意阶段库存为0时，活动暂停
          <el-tooltip class="item" placement="top" popper-class="q-tooltip">
            <div slot="content">
              开启多阶段后，其中任意阶段库存为0时，活动自动暂停，暂停后用户无法参与活动。
            </div>
            <i class="ri-question-line"></i>
          </el-tooltip>
        </el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
// store
import { mapState } from 'vuex'
export default {
  name: 'taskStockRule',
  data () {
    return {
      form: {
        stock_type: 2
      }
    }
  },
  computed: {
    ...mapState({
      eType: state => state.reserveTask.eType
    })
  },

  methods: {
    fn_setForm (data) {
      if (data) {
        this.form = data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rule-form__radio {
  .el-radio {
    display: block;
    line-height: 36px;
  }
}
</style>
