<template>
  <el-form ref="form" inline size="medium">
    <el-form-item>
      <el-radio-group v-model="radioButtonTime" @change="changeButtonTime">
        <el-radio-button label="近7天"></el-radio-button>
        <el-radio-button label="近30天"></el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="自定义：">
      <el-date-picker
        v-model="pickerDate"
        type="daterange"
        range-separator="~"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
        @change="changeTimeOut"
      >
      </el-date-picker>
    </el-form-item>
  </el-form>
</template>

<script>
const TIME_JSON = {
  近7天: 7,
  近30天: 30,
  null: 90
}
export default {
  props: {
    // 开始时间
    startTime: {
      type: String,
      default: '1990-01-01'
    },
    // 是否禁用开始时间日期之前的时间
    isShowDisabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      radioButtonTime: '近7天',
      calcTime: '',
      pickerDate: [],
      pickerMinDate: new Date().getTime(),
      pickerOptions: {
        onPick: time => this.onPick(time),
        disabledDate: time => this.disabledDate(time)
      }
    }
  },
  created () {
    this.initTime()
  },
  watch: {
    startTime () {
      this.initTime()
    }
  },
  methods: {
    // 初始化时间
    initTime () {
      const ds = new Date().getTime()
      const day1 = (TIME_JSON[this.radioButtonTime] - 1) * 24 * 3600 * 1000
      const min1 = new Date(ds - day1).getTime()
      const _start = this.startTime || '2000-01-01'
      const min2 = new Date(_start.replace(/-/g, '/')).getTime()
      const min = Math.max(min1, min2)
      if (!this.radioButtonTime) { this.calcTime = _start } else {
        this.calcTime = this.util.timeTofmt(min)
        this.pickerDate = [new Date(min), new Date()]
      }
      this.pickerOptions.disabledDate(new Date())
      this.emitInfo()
    },
    onPick (obj) {
      this.pickerMinDate = new Date(obj.minDate).getTime()
    },
    // 设置日期不可选
    disabledDate (time) {
      if (this.pickerMinDate && this.isShowDisabled) {
        const day1 = TIME_JSON[this.radioButtonTime] * 24 * 3600 * 1000
        const maxTime = this.pickerMinDate + day1
        const minTime1 = new Date(this.calcTime.replace(/-/g, '/')).getTime()
        const minTime2 = this.pickerMinDate - day1
        const minTime = Math.max(minTime1, minTime2)

        return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now() - 0 * 24 * 3600 * 1000
      } else {
        return time.getTime() > Date.now() - 0 * 24 * 3600 * 1000
      }
    },
    changeTimeOut (value) {
      if (value == null) {
        this.pickerDate = []
        this.radioButtonTime = null
        this.initTime()
      } else {
        this.emitInfo()
      }
    },
    changeButtonTime () {
      this.initTime()
    },
    emitInfo () {
      this.$emit('emitInfo', this.pickerDate)
    }
  }
}
</script>

<style>
</style>
