<!--
 * @Author: zhanln
 * @Date: 2021-10-22 18:28:53
 * @LastEditTime: 2021-10-29 10:59:00
 * @LastEditors: zhanln
 * @Description: 饼图模板，调用 setOption(data) 创建
-->

<template>
  <ly-chart :height="height" :option="pieOption" ref="chartRef"></ly-chart>
</template>

<script>
import lyChart from './index'
import getColors from './colors'
export default {
  name: 'pie',

  props: {
    height: {
      type: String,
      default: '300px'
    },
    isAllCus: { // 为 true 则替换所有传入的option 属性，为 false 则是合并， 默认 false
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => { }
    },
    colorLoop: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    s_option () {
      return this.option
    }
  },

  watch: {
    s_option: {
      handler (val) {
        if (val) {
          this.fn_setting(val)
        }
      },
      immediate: true,
      deep: true
    }
  },

  data () {
    return {
      baseOption: {
        color: [],
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'rgba(2, 15, 32, 0.78)',
          textStyle: {
            color: '#fff'
          }
        },
        series: []
      },
      pieOption: null
    }
  },

  mounted () {
    // 开启显示演示数据
    // this.fn_default()
  },

  methods: {

    // 图表数据处理，先设置 baseOption 再赋值到 pieOption，避免图表多次绘制
    async setOption (obj) {
      if (!obj) return false

      const { series } = obj
      if (series && (series && series.length)) {
        const [data] = series

        if (data || (data && data.length)) {
          const _series = series.map((item, index) => {
            this.baseOption.color = this.fn_initColors(item.data)
            item = this.fn_initStyle(item.data)
            return item
          })
          this.baseOption.series = _series
          this.pieOption = this.baseOption
        }
      }
    },

    // 自定义配置
    fn_setting (val) {
      for (const item in val) {
        // 如果传入的属性不存在 或者 isAllCus: true, 则替换该属性
        if (item !== 'series') {
          if (!this.baseOption[item] || this.isAllCus) {
            this.baseOption[item] = val[item]
          } else {
            Object.assign(this.baseOption[item], val[item])
          }
        }
      }
    },

    // 颜色组
    fn_initColors (data) {
      if (!data) return false
      const colorArr = []
      for (let i = 0; i < data.length; i++) {
        const rgb = getColors(i, true, this.colorLoop)
        colorArr.push(rgb)
      }
      return colorArr
    },

    // 折线样式配置
    fn_initStyle (data) {
      const series = this.option && this.option.series
      // 默认样式
      let obj = {
        type: 'pie',
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 3
        },
        minAngle: 4,
        data: data
      }

      if (series) {
        if (!this.isAllCus) {
          Object.assign(obj, series)
        } else {
          obj = {
            type: 'pie',
            ...series
          }
        }
        return obj
      } else {
        return obj
      }
    },

    // 演示
    fn_default () {
      const obj = {
        series: [
          {
            data: [
              { value: 1048, name: '演示数据1' },
              { value: 735, name: '演示数据2' }
            ]
          }
        ]
      }

      this.setOption(obj)
    }
  },

  components: {
    lyChart
  }
}
</script>
