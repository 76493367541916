var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-card" }, [
    _c("div", { staticClass: "edit-card__header" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.cardLoading,
            expression: "cardLoading"
          }
        ],
        staticClass: "edit-card__content"
      },
      [
        _vm._m(0),
        _c(
          "el-form",
          {
            staticClass: "notice-form",
            attrs: {
              model: _vm.noticeForm,
              "label-width": "125px",
              size: "medium"
            }
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item is-long",
                attrs: { label: "助力成功提醒", error: _vm.helpError }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.fn_setForm($event, "open_help")
                        }
                      },
                      model: {
                        value: _vm.noticeForm.open_help,
                        callback: function($$v) {
                          _vm.$set(_vm.noticeForm, "open_help", $$v)
                        },
                        expression: "noticeForm.open_help"
                      }
                    }),
                    _c("span", { staticStyle: { "margin-left": "8px" } }, [
                      _vm._v(
                        _vm._s(_vm.noticeForm.open_help === 0 ? "关闭" : "开启")
                      )
                    ])
                  ],
                  1
                ),
                _vm.noticeForm.open_help === 1
                  ? _c(
                      "div",
                      { staticClass: "q-bg is-default" },
                      [
                        _vm._v(" 每隔 "),
                        _c("el-input-number", {
                          staticClass: "number-mn",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 60,
                            max: 3600,
                            precision: 0
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setForm($event, "help_send_second")
                            }
                          },
                          model: {
                            value: _vm.noticeForm.help_send_second,
                            callback: function($$v) {
                              _vm.$set(_vm.noticeForm, "help_send_second", $$v)
                            },
                            expression: "noticeForm.help_send_second"
                          }
                        }),
                        _vm._v(" 秒后发送助力提醒 "),
                        _c("b", [_vm._v("或")]),
                        _vm._v(" 群内 "),
                        _c("el-input-number", {
                          staticClass: "number-mn",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 2,
                            max: 50,
                            precision: 0
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setForm($event, "help_send_add")
                            }
                          },
                          model: {
                            value: _vm.noticeForm.help_send_add,
                            callback: function($$v) {
                              _vm.$set(_vm.noticeForm, "help_send_add", $$v)
                            },
                            expression: "noticeForm.help_send_add"
                          }
                        }),
                        _vm._v(" 人获得好友助力发送助力提醒 "),
                        _c(
                          "div",
                          {
                            staticClass: "q-info text-small",
                            staticStyle: { "margin-top": "16px" }
                          },
                          [
                            _c("i", { staticClass: "ri-information-line" }),
                            _vm._v(
                              " 助力提醒时间和助力人数同步计算，将优先触发满足条件的设置项 "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.noticeForm.open_help === 1
                  ? _c("ly-editor", {
                      staticStyle: { width: "435px" },
                      attrs: {
                        title: "助力成功提醒",
                        id: "noticeHelp",
                        insetMenu: ["领奖人数"],
                        content: _vm.noticeForm.help_msg,
                        recoverContent: _vm.helpInit,
                        error: _vm.helpError !== "",
                        emoji: ""
                      },
                      on: { getContent: _vm.fn_getHelpMsg }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item",
                attrs: { label: "奖品推送", error: _vm.rewardError }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.fn_setForm($event, "open_reward")
                        }
                      },
                      model: {
                        value: _vm.noticeForm.open_reward,
                        callback: function($$v) {
                          _vm.$set(_vm.noticeForm, "open_reward", $$v)
                        },
                        expression: "noticeForm.open_reward"
                      }
                    }),
                    _c("span", { staticStyle: { "margin-left": "8px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.noticeForm.open_reward === 0 ? "关闭" : "开启"
                        )
                      )
                    ])
                  ],
                  1
                ),
                _vm.noticeForm.open_reward === 1
                  ? _c(
                      "div",
                      { staticClass: "q-bg is-default" },
                      [
                        _vm._v(" 每隔 "),
                        _c("el-input-number", {
                          staticClass: "number-small number-mn",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 60,
                            max: 3600,
                            precision: 0
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setForm(
                                $event,
                                "reward_send_second"
                              )
                            }
                          },
                          model: {
                            value: _vm.noticeForm.reward_send_second,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.noticeForm,
                                "reward_send_second",
                                $$v
                              )
                            },
                            expression: "noticeForm.reward_send_second"
                          }
                        }),
                        _vm._v(" 秒后发送奖品通知 "),
                        _c("b", [_vm._v("或")]),
                        _vm._v(" 群内 "),
                        _c("el-input-number", {
                          staticClass: "number-small number-mn",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 2,
                            max: 50,
                            precision: 0
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setForm($event, "reward_send_add")
                            }
                          },
                          model: {
                            value: _vm.noticeForm.reward_send_add,
                            callback: function($$v) {
                              _vm.$set(_vm.noticeForm, "reward_send_add", $$v)
                            },
                            expression: "noticeForm.reward_send_add"
                          }
                        }),
                        _vm._v(" 人完成任务发送奖品通知 "),
                        _c(
                          "div",
                          {
                            staticClass: "q-info text-small",
                            staticStyle: { "margin-top": "16px" }
                          },
                          [
                            _c("i", { staticClass: "ri-information-line" }),
                            _vm._v(
                              " 奖品通知时间和完成任务人数同步计算，将优先触发满足条件的设置项 "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.noticeForm.open_reward === 1
                  ? _c("ly-editor", {
                      staticStyle: { width: "435px" },
                      attrs: {
                        title: "助力成功提醒",
                        id: "noticeReward",
                        content: _vm.noticeForm.reward_msg,
                        recoverContent: _vm.rewardInit,
                        error: _vm.rewardError !== "",
                        emoji: ""
                      },
                      on: { getContent: _vm.fn_getRewardMsg }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-small notice" }, [
      _vm._v(
        " 1.群内有成员云端在线，则可以在群内实时发送【助力成功提醒】和【奖品推送】；"
      ),
      _c("br"),
      _vm._v(" 2.如使用云端账号进行消息推送，活动期间请保持云端账号在线状态。 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }