<template>
  <div class="page-warp">
    <layout-table
      :pageTotal="total"
      @pageChange="pageChange"
      :loading="loading"
      :page="page"
      @getList="fn_getData"
      @resetList="fn_getData(true)"
      :searchForm="searchForm"
    >
      <!-- searchBar -->
      <template #searchBar>
        <el-form-item label="员工搜索：">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入员工名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="所属部门：">
          <el-select
            v-model="searchForm.wecom_department_id"
            placeholder="全部"
            ref="wecom_department_select"
            class="emp-select-top"
            clearable
            popper-class="dep-select"
            :popper-append-to-body="false"
            :props="defaultProps"
            @clear="clearDepartment"
          >
            <el-option
              :label="wecom_department_value"
              :value="searchForm.wecom_department_id"
            >
              <el-tree
                :data="depList"
                node-key="id"
                ref="wecom_department_tree"
                @current-change="handleTreeChange"
              >
                <span class="custom-tree-node" slot-scope="{ data }">
                  <span style="line-height: 1">
                    <i class="el-icon-folder" style="margin-right: 5px"></i
                    >{{ data.name }}
                  </span>
                </span>
              </el-tree>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="系统角色：">
          <el-select v-model="searchForm.role_id" placeholder="全部" clearable>
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in searchRoleList"
              :key="index"
            >
              <div class="flex flex-between">
                <span>{{ item.name }}</span>
                <span
                  class="qy-tag qy-tag--primary qy-tag--mini"
                  style="margin-right: 0"
                  v-if="item.type === 1"
                  >官</span
                >
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工状态：">
          <el-select v-model="searchForm.status">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="授权范围：">
          <el-select v-model="searchForm.is_auth_app">
            <el-option
              v-for="item in authOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </template>

      <!-- toolBar -->
      <template #toolBar>
        <div>
          <el-button
            round
            type="primary"
            :class="['btn-reload', reload ? 'active' : '']"
            :disabled="reload"
            style="margin-right: 16px"
            @click="fn_reload"
          >
            <i class="iconfont icon-data"></i
            >{{ reload ? "同步中..." : "同步" }}</el-button
          >共 {{ total }} 个员工
        </div></template
      >

      <!-- table -->
      <el-table :data="tableData" style="width: 100%" @sort-change="tableSort">
        <el-table-column
          prop="avatar, name, alias"
          width="200"
          label="全部员工"
        >
          <template slot-scope="scope">
            <div class="flex flex-start">
              <img
                :src="scope.row.avatar_url"
                alt=""
                class="ly-table__main-avatar"
                @error="imgError(scope.row)"
              />
              <div>
                <div class="ly-table__main-name">{{ scope.row.name }}</div>
                <div class="ly-table__main-nickName" v-if="scope.row.alias">
                  别名：{{ scope.row.alias }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="qrcode_url" label="二维码" align="center">
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              placement="top"
              :close-delay="0"
              v-if="scope.row.qrcode_url"
            >
              <img
                :src="scope.row.qrcode_url"
                alt=""
                v-if="scope.row.qrcode_url"
                width="180"
              />
              <div slot="reference" class="name-wrapper">
                <i
                  class="iconfont icon-Code"
                  v-if="scope.row.qrcode_url"
                  style="font-size: 18px"
                ></i>
              </div>
            </el-popover>
            <template v-else> - </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="wecom_departments"
          width="140"
          label="所属部门"
          align="center"
        >
          <template slot-scope="scope">
            <ly-drow :list="scope.row.wecom_departments" dep></ly-drow>
          </template>
        </el-table-column>
        <el-table-column
          prop="client_count"
          min-width="100"
          label="客户数"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="wecom_groups_count"
          min-width="100"
          sortable="custom"
          label="群聊数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="allow_login,is_auth_app,is_auth_third_app"
          min-width="150"
          label="授权范围"
          align="center"
        >
          <template slot-scope="scope">
            <ly-status v-if="scope.row.is_auth_app === 1" type="success"
              >已授权</ly-status
            >
            <template v-else>
              <template v-if="scope.row.is_auth_app === 0">
                <ly-status type="warning">未授权</ly-status><br />
                <span
                  class="q-primary"
                  style="margin-left: 22px; cursor: pointer"
                  @click="fn_jumpToLink"
                >
                  如何解决？
                </span>
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          min-width="100"
          label="员工状态"
          align="center"
        >
          <template slot-scope="scope">
            <ly-status type="success" v-if="scope.row.status === 1">
              已激活
            </ly-status>
            <ly-status type="danger" v-if="scope.row.status === 2">
              已禁用
            </ly-status>
            <ly-status type="warning" v-if="scope.row.status === 4">
              未激活
            </ly-status>
            <ly-status type="info" v-if="scope.row.status === 5">
              退出企业
            </ly-status>
          </template>
        </el-table-column>
        <el-table-column
          prop="role_name"
          min-width="100"
          label="系统角色"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="120"
          align="right"
          class-name="operation-cell"
        >
          <template slot-scope="scope">
            <div class="flex" style="flex-direction: row-reverse">
              <el-button type="text" @click="jumpToDetail(scope.row)"
                >详情</el-button
              >

              <template v-if="(isAdmin || is_Admin) && scope.row.role_id !== 4">
                <el-divider direction="vertical"></el-divider>
                <el-button @click="openEditRolesDialog(scope.row)" type="text"
                  >权限</el-button
                >
              </template>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>

      <el-dialog
        title="权限设置"
        :visible.sync="editRoleDialogVisable"
        width="480px"
        top="20vh"
        lock-scroll
        :close-on-click-modal="false"
        custom-class="q-dialog"
        @close="fn_coloseRoleEdit"
      >
        <el-form :model="roleForm" ref="roleForm" size="medium">
          <el-form-item
            label="设置角色："
            prop="role_id"
            style="margin-bottom: 0"
          >
            <el-select
              v-model="roleForm.role_id"
              @change="fn_changeRole"
              filterable
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in roleList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            prop="role_id"
            style="margin-top: 24px"
            v-if="selectDep"
            :error="roleDepTips"
          >
            <div style="margin-bottom: 8px">选择部门：</div>
            <div class="rolelist" ref="rolelist">
              <dep-select
                @fn_getCheck="fn_getCheck"
                ref="depSelect"
                checkType="ptoc"
              ></dep-select>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <div></div>
          <div>
            <el-button round @click="editRoleDialogVisable = false"
              >取消</el-button
            >
            <el-button round type="primary" @click="editRoleConfirm()"
              >确定</el-button
            >
          </div>
        </div>
      </el-dialog>
    </layout-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import layoutTable from '@/components/layout/lyTable'
import depSelect from '@/components/DepSelect'
import mx_role from '@/components/mixins/role'
import lyDrow from '@/components/lyDrow'
export default {
  data () {
    return {
      loading: true,
      depList: null,
      searchForm: {
        name: null,
        wecom_department_id: null,
        role_id: null,
        status: -1,
        is_auth_app: 1
      },
      statusOptions: [{
        value: -1,
        label: '全部'
      }, {
        value: 1,
        label: '已激活'
      }, {
        value: 2,
        label: '已禁用'
      }, {
        value: 4,
        label: '未激活'
      }, {
        value: 5,
        label: '退出企业'
      }],
      authOptions: [{
        value: -1,
        label: '全部'
      }, {
        value: 0,
        label: '未授权'
      }, {
        value: 1,
        label: '已授权'
      }],
      searchRoleList: [],
      roleList: [],
      tableData: [],
      page: 1,
      per_page: 10,
      total: 0,
      mineStatus: '',
      wecom_department_value: '',
      editRoleDialogVisable: false,
      roleId: '',
      roleForm: {
        role_id: null,
        user_ids: null
      },
      selectDep: false,
      roleDepTips: '',
      DepCheckList: [],
      setRoleCheckeds: true,
      depRoleId: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      reload: false,
      reloadId: null,
      reloadTime: null
    }
  },
  mixins: [mx_role],
  created () {
    if (this.$route.query.role_id) {
      this.searchForm.role_id = +this.$route.query.role_id
    }
    if (this.$route.query.is_auth_app) {
      this.searchForm.is_auth_app = +this.$route.query.is_auth_app
    }

    this.getRole()
    this.getDep()
    this.getEmpolyeesList()
    this.$router.replace({ query: {} })
  },
  computed: {
    ...mapGetters({
      isAdmin: 'permission/GET_ISADMIN',
      is_Admin: 'permission/GET_IS_ADMIN'
    }),
    hasEmpDetail () { // 是否有详情页面的权限
      return this.$store.getters['permission/GET_PAGE']('/employees/management/detail')
    }
  },
  methods: {
    // 获取角色列表
    async getRole () {
      const data = await this.axios.get('rolesListNoauth', {
        params: {
          page: 1,
          per_page: 9999
        }
      })
      if (data) {
        const _roleList = this.util.extend(true, [], data.data)

        for (let i = 0; i < _roleList.length; i++) {
          if (_roleList[i].name === '超级管理员') {
            _roleList.splice(i, 1)
            break
          }
        }
        this.searchRoleList = data.data
        this.roleList = _roleList
      }
    },
    // 获取部门列表
    async getDep () {
      const { data } = await this.axios.get('wecomDepartmentsNoauth', {
        params: {
          page: 1,
          per_page: 9999
        }
      })
      this.depList = await this.fn_initDepList(data)
    },

    imgError (item) {
      item.avatar_url = 'https://image.01lb.com.cn//uploads/wecom/21/0312/1615552972n48unAxpdefault_avatar.png'
    },

    // 设置部门
    fn_initDepList (data) {
      // 先设置一级部门
      let _allData = this.util.extend(true, [], data)
      const _depList = []

      for (let i = 0; i < _allData.length; i++) {
        if (_allData[i].corp_department_id === 1) {
          _depList.push(_allData[i])
          _allData.splice(i, 1)
          break
        }
      }
      this.fn_setDepLevel(_allData, _depList)

      // 如果存在没匹配上的部门，放到第一级部门下面
      if (_allData.length > 0) {
        _depList[0].children = [...(_depList[0].children || []), ..._allData]
        _allData = []
      }

      return _depList
    },

    // 设置部门层级
    fn_setDepLevel (all, list) {
      for (let i = 0; i < list.length; i++) {
        const _depId = list[i].corp_department_id

        for (let j = 0; j < all.length; j++) {
          const _parentId = all[j].corp_parent_id

          if (_parentId === _depId) {
            if (!list[i].children) {
              list[i].children = []
            }

            list[i].children.push(all[j])
            all.splice(j, 1)
            j--
          }
        }

        if (all.length > 0 && list[i].children) {
          this.fn_setDepLevel(all, list[i].children)
        }
      }
    },

    // 获取员工列表
    async getEmpolyeesList () {
      this.loading = true
      const params = {
        page: this.page,
        per_page: this.per_page,
        status: -1
      }
      Object.assign(params, this.searchForm)
      const data = await this.axios.get('empList', {
        params
      })
      this.total = data.total
      this.tableData = data.data
      this.loading = false
    },

    fn_getData (type) {
      this.page = 1
      if (type) {
        this.searchForm = {
          name: null,
          wecom_department_id: null,
          role_id: null,
          status: -1,
          is_auth_app: 1
        }
        this.clearDepartment()
      }
      this.getEmpolyeesList()
    },

    // 选择部门
    handleTreeChange (data) {
      this.$refs.wecom_department_select.blur()
      if (data.id === this.searchForm.wecom_department_id) return
      this.wecom_department_value = data.name
      this.searchForm.wecom_department_id = data.id
    },
    // 清空部门字段
    clearDepartment () {
      this.wecom_department_value = ''
      this.searchForm.wecom_department_id = ''
    },

    // 排序监听
    tableSort (column) {
      this.page = 1
      if (column.order === null) {
        this.searchForm.order_by = null
        this.searchForm.direction = null
      } else {
        this.searchForm.order_by = column.prop
        this.searchForm.direction = column.order === 'descending' ? 'desc' : 'asc'
      }
      this.getEmpolyeesList()
    },

    // 分页监听
    pageChange (val) {
      this.page = val
      this.getEmpolyeesList()
    },

    // 权限编辑弹框
    openEditRolesDialog (data) {
      this.roleData = data
      const _userId = [data.id]
      this.roleForm.user_ids = _userId
      this.roleForm.role_id = data.role_id
      this.editRoleDialogVisable = true

      if (data.role_mask === 2) {
        this.depRoleId = data.role_id
        this.selectDep = true
        this.fn_getDepCheckList(data.id)
        this.$nextTick(() => {
          this.$refs.rolelist.scrollTop = 0
        })
      } else {
        this.selectDep = false
      }
    },

    // 获取权限为部门的部门选项
    async fn_getDepCheckList (id) {
      this.roleForm.department_ids = id

      const data = await this.axios.get('rolesDepartments', {
        params: {
          user_id: id
        }
      })

      if (data) {
        this.depSelectList = data
        this.$nextTick(() => {
          this.$refs.depSelect.fn_setDataBack(data, true)
        })
      }
    },

    // 设置权限下拉监听
    fn_changeRole (val) {
      // 如果选择了非内置部门主管，弹出部门选择器
      let _mask = null

      for (let i = 0; i < this.roleList.length; i++) {
        if (val === this.roleList[i].id) {
          _mask = this.roleList[i].mask
        }
      }

      if (_mask === 2) {
        this.selectDep = true
        if (this.depRoleId && this.depRoleId === val) {
          this.$nextTick(() => {
            this.$refs.depSelect.fn_setDataBack(this.depSelectList)
          })
        } else {
          this.$nextTick(() => {
            this.$refs.depSelect.fn_initDataBack()
          })
          this.roleForm.department_ids = []
        }
      } else {
        this.selectDep = false
        delete this.roleForm.department_ids
      }
    },

    fn_getCheck (id) {
      this.roleForm.department_ids = id
    },

    // 权限编辑确认框
    async editRoleConfirm () {
      if (this.roleForm.department_ids) {
        if (this.roleForm.department_ids.length === 0) {
          this.roleDepTips = '请选择部门'
          return
        } else {
          this.$refs.depSelect.fn_treeCheck()
        }
      }

      this.roleDepTips = ''

      const data = await this.axios.post('rolesAddUser', this.roleForm)

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        this.editRoleDialogVisable = false
        return false
      }

      this.fn_lyMsg('success', '设置成功')
      this.getEmpolyeesList()
      this.editRoleDialogVisable = false
    },

    // 关闭权限编辑框
    fn_coloseRoleEdit () {
      if (this.selectDep) {
        this.$refs.depSelect.fn_reset()
      }
      this.$store.state.global.depDatas = null
    },

    // 跳转权限列表
    jumpToAuth () {
      this.$router.push('auth')
      this.$emit('getTabIndex', { index: 2 })
    },

    // 跳转详情
    jumpToDetail (item) {
      this.$router.push({
        path: 'detail',
        query: {
          id: item.id
        }
      })
    },

    // 获取授权状态
    async fn_getAuthStatus (type = true) {
      const data = await this.axios.get('authStatus')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$store.commit('_SET', {
        'global.appConfig': data.err_status
      })
    },

    // 同步数据
    async fn_reload () {
      if (!this.reload) {
        await this.fn_getAuthStatus()
      }

      const _appConfig = this.$store.state.global.appConfig

      if (_appConfig === 1 || _appConfig === 2 || _appConfig === 3) {
        this.fn_lyMsg('info', '自建应用异常，数据同步失败')
        return
      }

      this.reload = true
      const data = await this.axios.post('empSync', {
        msg_id: this.reloadId
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        this.reload = false
        return false
      }

      this.reloadId = data.msg_id

      if (data.status === 0) {
        this.fn_lyMsg('success', '数据同步完成！')
        this.reload = false
        this.reloadId = null
        this.fn_getData(true)
        await this.$store.dispatch('permission/GenerateWecomInfo', {
          id: localStorage.getItem('companyId'),
          refresh: true
        })
      } else {
        this.reloadTime = setTimeout(() => {
          this.fn_reload()
        }, 1000)
      }
    },

    // 跳转授权指引
    fn_jumpToLink () {
      window.open('https://www.yuque.com/docs/share/963aa610-ab4f-4333-b3d8-a02160b3dab2 ', '_blank')
    }
  },
  components: {
    layoutTable,
    depSelect,
    lyDrow
  },

  destroyed () {
    if (this.reloadTime) {
      clearTimeout(this.reloadTime)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/gap.scss";
.emp-select-top {
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #454d5b;
  }
}

.rolelist {
  height: 224px;
  overflow: auto;
  background-color: #f7f8fc;
  border-radius: 2px;
  padding: 16px;
}
</style>
