var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading"
        }
      ],
      ref: "alysRef",
      staticClass: "alys"
    },
    [
      _c(
        "div",
        { staticClass: "alys-header alys-wrap" },
        [
          _c(
            "div",
            { staticClass: "alys-search" },
            [
              _c(
                "el-form",
                { attrs: { inline: "", size: "medium" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "8px" },
                      attrs: { label: "选择活动：" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { filterable: "" },
                          on: { change: _vm.fn_changeAct },
                          model: {
                            value: _vm.actId,
                            callback: function($$v) {
                              _vm.actId = $$v
                            },
                            expression: "actId"
                          }
                        },
                        _vm._l(_vm.actList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              staticClass: "ly-tabs tabs-step",
              on: { "tab-click": _vm.fn_setTab },
              model: {
                value: _vm.tabKey,
                callback: function($$v) {
                  _vm.tabKey = $$v
                },
                expression: "tabKey"
              }
            },
            _vm._l(_vm.cards, function(item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.label, name: item.name }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "alys-container alys-wrap" }, [
        _c(
          "div",
          { ref: "scrollRef", staticClass: "alys-scroll" },
          [
            _c("alys-basic", { ref: "basic", attrs: { title: "数据概览" } }),
            _c("alys-trend", {
              ref: "trend",
              attrs: {
                title: "数据趋势",
                actId: _vm.actId,
                actName: _vm.actName,
                actIndex: _vm.actIndex,
                actStartTime: _vm.actStartTime,
                actEndTime: _vm.actEndTime
              },
              on: { getBase: _vm.fn_getBase }
            }),
            _c("alys-steps", {
              ref: "steps",
              attrs: {
                title: "裂变层级分析",
                actId: _vm.actId,
                actName: _vm.actName,
                actIndex: _vm.actIndex
              }
            }),
            _c("alys-channel", {
              ref: "channel",
              attrs: {
                title: "渠道分析",
                actId: _vm.actId,
                actName: _vm.actName,
                actIndex: _vm.actIndex
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }