var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gf-users page-warp" },
    [
      _c(
        "div",
        { staticClass: "select-box" },
        [
          _c("label", { staticStyle: { color: "rgba(0, 0, 0, 0.85)" } }, [
            _vm._v("选择活动：")
          ]),
          _c(
            "el-select",
            {
              attrs: {
                size: "medium",
                filterable: "",
                placeholder: "请选择活动"
              },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.activity_id,
                callback: function($$v) {
                  _vm.activity_id = $$v
                },
                expression: "activity_id"
              }
            },
            _vm._l(_vm.activities, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.title, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      loading: _vm.exportLoading,
                      disabled: !_vm.tableData || _vm.tableData.length === 0,
                      icon: "iconfont icon-download"
                    },
                    on: { click: _vm.handleExport }
                  },
                  [_vm._v("导出数据")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "total_lottery_num_header",
            fn: function() {
              return [
                _c("span", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v("总抽奖次数")
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      "popper-class": "q-tooltip"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("完成助力任务获得的总抽奖次数，包含无门槛次数")
                    ]),
                    _c("i", { staticClass: "iconfont icon-info-fill" })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "lottery_num_header",
            fn: function() {
              return [
                _c("span", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v("中奖次数")
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      "popper-class": "q-tooltip"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " 统计获取奖品的次数，不包含奖品类型为“谢谢参与”的奖品次数 "
                      )
                    ]),
                    _c("i", { staticClass: "iconfont icon-info-fill" })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "join_type_header",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _vm._v(" 参与方式 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: { placement: "top", "popper-class": "q-tooltip" }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "8px" } },
                              [
                                _vm._v(
                                  " 链接进入：用户通过直接点击活动链接参与活动，参与活动后不能再为其他人助力 "
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                " 添加员工：用户通过扫码海报二维码添加员工参与活动，参与活动后不能再为其他人助力 "
                              )
                            ])
                          ]
                        ),
                        _c("i", {
                          staticClass: "iconfont icon-info-fill q-info"
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "nickname",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "flex flex-start" }, [
                  row.avatar
                    ? _c("img", {
                        staticClass: "ly-table__main-avatar",
                        attrs: { src: row.avatar, alt: "" }
                      })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(row.nickname))])
                ])
              ]
            }
          },
          {
            key: "tag_name_status",
            fn: function(ref) {
              var row = ref.row
              return [_c("ly-drow", { attrs: { list: row.tag_name, dep: "" } })]
            }
          },
          {
            key: "invitor_name",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ly-tag",
                  { attrs: { content: row.invitor_name, size: 3, only: "" } },
                  [
                    _c("i", {
                      staticClass: "iconfont icon-superior",
                      attrs: { slot: "left" },
                      slot: "left"
                    })
                  ]
                )
              ]
            }
          },
          {
            key: "step",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "step-box" }, [
                  row.first_finish !== 0
                    ? _c(
                        "div",
                        {
                          staticClass: "item",
                          class: { "is-done": row.first_finish === 1 }
                        },
                        [
                          _vm._v(" 一阶："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                row.first_finish === 1 ? "已完成" : "未完成"
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  row.second_finish !== 0
                    ? _c(
                        "div",
                        {
                          staticClass: "item",
                          class: { "is-done": row.second_finish === 1 }
                        },
                        [
                          _vm._v(" 二阶："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                row.second_finish === 1 ? "已完成" : "未完成"
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  row.third_finish !== 0
                    ? _c(
                        "div",
                        {
                          staticClass: "item",
                          class: { "is-done": row.third_finish === 1 }
                        },
                        [
                          _vm._v(" 三阶："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                row.third_finish === 1 ? "已完成" : "未完成"
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              ]
            }
          },
          {
            key: "channel_id",
            fn: function(ref) {
              var row = ref.row
              return [_vm._v(" " + _vm._s(row.channel_name || "-") + " ")]
            }
          },
          {
            key: "add_user_name",
            fn: function(ref) {
              var row = ref.row
              return [_c("ly-drow", { attrs: { list: row.add_user_name } })]
            }
          },
          {
            key: "add_status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.add_status === 1
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("已添加")
                    ])
                  : row.add_status === 2
                  ? _c("ly-status", { attrs: { type: "danger" } }, [
                      _vm._v("已删除")
                    ])
                  : _c("span", [_vm._v("-")])
              ]
            }
          }
        ])
      }),
      _c("user-detail", { ref: "userDetail" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }