<!--
 * @Author: zhanln
 * @Date: 2021-11-17 16:58:54
 * @LastEditTime: 2022-07-12 18:22:58
 * @LastEditors: zhan
 * @Description: 奖品预览
-->

<template>
  <div class="reward-drawer" :class="{ 'is-top': prize_focus }">
    <div class="reward-drawer__mask" v-if="prize_focus"></div>
    <div class="reward-drawer__box" :class="{ show: prize_focus }">
      <img src="https://image.01lb.com.cn//uploads/wecom/22/0615/1655280271yZ62ZE6O.png" class="reward-drawer__close" />
      <div class="reward-drawer__title">
        <span class="reward-drawer__title--text">奖品详情</span>
      </div>
      <!-- 自定义 -->
      <template v-if="reward_prize_type === 1">
        <div class="reward-drawer__body">
          <div class="reward-drawer__content">
            <div class="reward-drawer__sub">兑换规则</div>
            <el-input type="textarea" :value="diy_content" autosize resize="none" readonly
              class="reward-drawer__detail">
            </el-input>
          </div>
          <div class="reward-drawer__img">
            <img :src="diy_img" alt="" />
          </div>
        </div>
      </template>

      <!-- 兑换码 -->
      <template v-if="reward_prize_type === 2">
        <div class="reward-drawer__body">
          <div class="reward-drawer__content">
            <div class="reward-drawer__sub">兑换规则</div>
            <el-input type="textarea" :value="code_rule" autosize resize="none" readonly class="reward-drawer__detail">
            </el-input>
          </div>
        </div>

        <div class="reward-drawer__code">SJFHEAML</div>
        <div class="reward-drawer__btn">点击复制兑换码</div>
      </template>

      <!-- 表单 -->
      <template v-if="reward_prize_type === 3">
        <div class="reward-drawer__body">
          <div class="reward-drawer__detail" v-html="form_rule"></div>
        </div>
        <div class="reward-drawer__btn">
          去填写表单，领取奖品 <i class="el-icon-d-arrow-right"></i>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// store
import { mapState } from 'vuex'
export default {
  name: 'popPrize',

  data () {
    return {
      defaultPrizeCover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png'
    }
  },

  computed: {
    ...mapState({
      prize_focus: state => state.livelottery.prize_focus,
      reward_prize_type: state => state.livelottery.reward_prize_type,
      diy_content: state => state.livelottery.diy_content,
      current_step: state => state.livelottery.current_step,
      diy_img: state => state.livelottery.diy_img,
      code_rule: state => state.livelottery.code_rule,
      form_rule: state => state.livelottery.form_rule
    }),
    prize_cover () {
      return this.$store.getters['livelottery/GET_INFO'](`reward_cover_${this.current_step}`) || this.defaultPrizeCover
    },
    prize_name () {
      return this.$store.getters['livelottery/GET_INFO'](`reward_title_${this.current_step}`) || '奖品名称'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";

.reward-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  &.is-top {
    z-index: 99;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100%;
    z-index: 1;
    background: url('https://image.01lb.com.cn//uploads/wecom/22/0707/1657162205II76mJqN.png') no-repeat;
    background-size: 100% auto;
    transition: 0.4s ease-in-out;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 50%;
      left: 0%;
      bottom: 0;
      z-index: -1;
    }

    &.show {
      bottom: 0;
    }
  }

  &__body {
    margin: 16px;
  }

  &__content {
    background-color: #FAE8FF;
    border-radius: 12px;
    padding: 14px 12px;
    color: #DC407E;
  }

  &__sub {
    color: #DC407E;
    margin-bottom: 12px;
    text-align: center;
  }

  &__title {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 26px;
    text-align: center;

    &--text {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 48%;
        background-color: #FFF06E;
        width: 4px;
        height: 4px;
        transform: translateX(-50%);
        border-radius: 50%;
        left: -16px;
      }

      &::after {
        position: absolute;
        content: "";
        top: 48%;
        background-color: #FFF06E;
        width: 4px;
        height: 4px;
        transform: translateX(-50%);
        border-radius: 50%;
        right: -21px;
      }
    }
  }

  &__name {
    color: #333;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #E7E7E7;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  &__detail {
    color: #DC407E;
    font-size: 12px;

    ::v-deep .el-textarea__inner {
      border: none;
      border-radius: 0;
      height: auto;
      color: #DC407E;
      max-height: 80px;
      overflow-y: auto;
      padding: 0;
      background: transparent;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    img {
      max-width: 90px;
      max-height: 90px;
      border-radius: 4px;
    }
  }

  &__code {
    background-color: #FAE8FF;
    border-radius: 22px;
    height: 38px;
    line-height: 38px;
    margin: 24px 16px 12px;
    padding: 0 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #D01761;
  }

  &__btn {
    background: linear-gradient(-90deg, #d45feb 0%, #9368fe 100%);
    border-radius: 22px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin: 0 16px 16px;
  }

  &__close {
    position: absolute;
    right: 12px;
    top: -24px;
    width: 24px;
  }
}
</style>
