/*
 * @Author: zhanln
 * @Date: 2022-01-04 18:36:21
 * @LastEditTime: 2022-01-20 15:11:31
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import axios from '@/common/ajax.js'

export const actlist = {
  reqQuery: params => axios.get('/wecom/lottery-activity/list', { params }), // 查询表格数据的接口
  reqDelete: params => axios.post('/wecom/lottery-activity/delete', params), // 删除
  activityStop: params => axios.post('/wecom/lottery-activity/stop', params) // 结束
}

// 活动
export const act = {
  save: params => axios.post('/wecom/lottery-activity/save', params), // 抽奖-创建或修改活动
  show: params => axios.get('/wecom/lottery-activity/show', { params }), // 抽奖-详情
  hasSameName: params => axios.get('/wecom/lottery-activity/has-same-name', { params }) // 抽奖-重名校验
}

export const record = {
  reqQuery: params => axios.get('/wecom/lottery-activity/reward-list', { params }), // 查询表格数据的接口
  reqExport: params => axios.get('/wecom/lottery-activity/reward-export', { params }),
  selectList: params => axios.get('/wecom/lottery-activity/select', { params }), // 活动下拉选项
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`), // 获取单个表单组件
  resend: params => axios.post('/wecom/lottery-activity/resend-red-packet', params), // 重新发放红包
  redDetail: params => axios.get('/wecom/lottery-activity/reward-red-packet-detail', { params }) // 红包明细
}

export const users = {
  reqQuery: params => axios.get('/wecom/lottery-activity/account-list', { params }), // 查询表格数据的接口
  selectList: params => axios.get('/wecom/lottery-activity/select', { params }),
  reqExport: params => axios.get('/wecom/lottery-activity/account-export', { params }),
  channelSel: params => axios.get('/wecom/lottery-activity/channel-list', { params })
}

export const userDetail = {
  reqQuery: params => axios.get('/wecom/lottery-activity/account-help-list', { params }) // 查询表格数据的接口
}

export const drafts = {
  reqQuery: params => axios.get('/wecom/lottery-activity/draft-list', { params }), // 查询表格数据的接口
  reqDelete: params => axios.post('/wecom/lottery-activity/delete', params) // 删除
}

export const popularize = {
  reqQuery: params => axios.get('/wecom/lottery-activity/channel-list', { params }), // 查询表格数据的接口
  channelCreate: params => axios.post('/wecom/lottery-activity/channel-create', params),
  channelDelete: params => axios.post('/wecom/lottery-activity/channel-delete', params),
  channelUpdate: params => axios.post('/wecom/lottery-activity/channel-update', params),
  selectList: params => axios.get('/wecom/lottery-activity/select', { params })
}

// 表单详情
export const fDetails = {
  reqQuery: params => axios.post('/scrm-api/form/business/list', params),
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`) // 获取单个表单组件
}
