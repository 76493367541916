var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "new"
        ? _c("div", { staticClass: "agrForm-title" }, [_vm._v("设置客服账号")])
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "agrForm",
          attrs: { model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客服账号：", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入客服账号名称，支持中文、字母、数字",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.type !== "new" ? "新密码：" : "登录密码：",
                prop: "password"
              }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    _vm.type !== "new" ? "请设置新密码" : "请设置账号登录密码",
                  maxlength: "30",
                  type: "password"
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.password"
                }
              })
            ],
            1
          ),
          _vm.type !== "new"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "验证密码：",
                    prop: "repeatPassword",
                    maxlength: "30"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请再次输入新密码",
                      maxlength: "30",
                      type: "password"
                    },
                    model: {
                      value: _vm.form.repeatPassword,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "repeatPassword",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.repeatPassword"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.type !== "new"
        ? _c(
            "div",
            { staticClass: "agrForm-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.fn_verify }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }