<!--
* 消息模板
-->
<template>
  <div class="msg-temp">
    <div class="title">{{ title }}
      <el-dropdown @command="handleCommand" placement="bottom">
        <i class="el-icon-more"></i>
        <el-dropdown-menu slot="dropdown" class="msg-handler">
          <el-dropdown-item command="edit" :disabled="disabled && disabled.includes('edit')">编辑</el-dropdown-item>
          <el-dropdown-item command="up" :disabled="disabled && disabled.includes('up')" class="up">上移
          </el-dropdown-item>
          <el-dropdown-item command="down" :disabled="disabled && disabled.includes('down')" class="up">下移
          </el-dropdown-item>
          <el-dropdown-item command="remove" :disabled="disabled && disabled.includes('remove')">移除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 文本 -->
    <div class="content flex" v-if="msg.messageType === 4">
      {{ msg.welcomeContent }}
    </div>
    <!-- 图片 -->
    <div class="content flex" v-if="msg.messageType === 1">
      <el-image style="height: 90px" :src="msg.imageUrl" fit="contain"></el-image>
    </div>
    <!-- 链接 -->
    <div class="link" v-if="msg.messageType === 2">
      <div class="link-title">{{ msg.linkTitle }}</div>
      <div class="link-bottom">
        <div class="link-desc">{{ msg.linkDsc }}</div>
        <el-image style="width: 48px;height: 48px" :src="msg.linkPicUrl" fit="contain">
          <div slot="error">
            <img src="@/assets/svg/linkDefault.svg" style="width: 48px; height: 48px">
          </div>
        </el-image>
      </div>
    </div>
    <!-- 小程序 -->
    <div class="applet" v-if="msg.messageType === 3" style="width: 246px;">
      <app-poster :applet="msg" />
    </div>
  </div>
</template>
<script>
import AppPoster from '@/components/AppPoster.vue'
export default {
  props: ['title', 'msg', 'disabled'],
  components: {
    AppPoster
  },
  methods: {
    handleCommand (action) {
      this.$emit('change', action, this.msg)
    }
  }
}
</script>
<style lang="scss" scoped>
.msg-temp {
  margin-bottom: 5px;
}

.title {
  .el-dropdown {
    float: right;

    .el-icon-more {
      font-size: 18px;
      cursor: pointer;
    }
  }
}

.content {
  background: #F7F8FC;
  border: 1px solid #EAEBF2;
  border-radius: 4px;
  padding: 10px 12px;
  line-height: 1.5;

  .el-image {
    border-radius: 4px;
  }

  .file-temp {
    width: 190px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #DFE1E8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;

    .file-title {
      flex: 1;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: #454D5B
    }

    .file-icon {
      width: 30px;
      height: 30px;
      margin-left: 20px;
    }
  }

  ::v-deep {
    .el-image__inner {
      width: unset;
    }
  }
}

.video {
  flex-direction: column;

  video {
    height: 90px;
    display: block;
    margin-bottom: 8px;
    border-radius: 4px;
  }
}

.video-title {
  font-size: 12px;
}

.link {
  width: 245px;
  border: 1px solid #EAEBF2;
  border-radius: 4px;
  line-height: 1.3;
  padding: 10px 12px;

  .link-title {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
  }

  .link-bottom {
    display: flex;
    margin-top: 8px;

    .link-desc {
      flex: 1;
      margin-right: 15px;
      color: #6E788A;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.4;
      word-break: break-word
    }

    .el-image {
      border-radius: 2px;
    }
  }
}

.msg-handler {
  width: 84px;
  margin: 0 !important;
  padding: 0 !important;

  ::v-deep {
    .el-dropdown-menu__item {
      color: #2B7CFF;
      text-align: center;

      &:last-of-type {
        color: #ED5C49;
      }

      &.is-disabled {
        color: #bbb;
      }
    }
  }
}
</style>
