<!--
 * @Author: zhanln
 * @Date: 2022-03-03 16:57:57
 * @LastEditTime: 2022-04-07 17:18:27
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <div class="work ly-shadow">
    <el-scrollbar class="work-scroll">
      <div class="work-wrap">
        <div class="work-title">功能介绍</div>
        <div class="flex flex-between" style="margin-left: 12px">
          <div>
            <div>
              将多个企微号聚合在一个页面上进行管理，可快捷回复和管理聊天消息，无需频繁切换账号。
            </div>
          </div>
          <el-button
            type="primary"
            round
            :disabled="!workUrl"
            @click="fn_jumpToWork"
            >企微工作台 <i class="el-icon-arrow-right"></i
          ></el-button>
        </div>
        <el-image
          class="work-pre"
          style="width: 1140px"
          src="https://image.01lb.com.cn//uploads/wecom/22/0407/16493182174mq1G143.png"
          :preview-src-list="[
            'https://image.01lb.com.cn//uploads/wecom/22/0407/16493182174mq1G143.png',
          ]"
        >
        </el-image>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { agrs } from './http.js'
export default {
  name: 'workBench',
  data () {
    return {
      workUrl: ''
    }
  },
  created () {
    this.fn_getWorkUrl()
  },
  methods: {
    // 获取客服工作台地址
    async fn_getWorkUrl () {
      const data = await agrs.workUrl()
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.workUrl = data.url
    },

    // 跳转客服工作台
    fn_jumpToWork () {
      window.open(this.workUrl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/utils.scss";
.work {
  margin: 20px;
  background-color: #fff;
  border-radius: 16px;
  height: 100%;
  overflow: hidden;

  &-wrap {
    padding: 32px 24px;
  }

  &-scroll {
    height: 100%;
  }

  &-title {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    padding-left: 12px;
    margin-bottom: 24px;

    &::before {
      position: absolute;
      content: "";
      width: 5px;
      height: 12px;
      top: 50%;
      left: 0;
      background-color: $--color-primary;
      border-radius: 2.5px;
      transform: translateY(-50%);
    }
  }

  &-tip {
    align-items: center;
    margin-top: 12px;

    i {
      line-height: 1;
      margin-right: 2px;
    }
  }

  &-pre {
    max-width: 100%;
    margin-top: 16px;
    padding-left: 12px;
    box-sizing: border-box;
  }
}
</style>
