<template>
  <div class="page-warp">
    <layout-table
      :pageTotal="total"
      @pageChange="pageChange"
      :loading="loading"
      :page="page"
      @getList="fn_getData"
      @resetList="fn_getData(true)"
      :searchForm="searchForm"
    >
      <!-- searchBar -->
      <template #searchBar>
        <el-form-item label="群聊搜索：">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入群聊名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="群主：">
          <ly-emp-select
            placeholder="选择群主"
            :showLength="1"
            :checkList="searchForm.wecom_user_ids"
            ref="lyEmpSelectRef"
            @getEmpId="getEmpId"
          ></ly-emp-select>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-date-picker
            v-model="timeModel"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            @change="changeTime"
            style="width: 360px"
          >
          </el-date-picker>
        </el-form-item>
      </template>

      <!-- toolBar -->
      <template #toolBar>
        <div>
          <el-button
            round
            type="primary"
            :class="['btn-reload', reload ? 'active' : '']"
            :disabled="reload"
            style="margin-right: 16px"
            @click="fn_reload"
          >
            <i class="icon qy-icon-reload"></i
            >{{ reload ? "同步中..." : "同步" }}</el-button
          >共 {{ total }} 个群聊, {{ total_user_count }} 个群成员，去重后
          {{ userTotal }} 个群成员
        </div>
      </template>

      <el-table :data="tableData" style="width: 100%" @sort-change="tableSort">
        <el-table-column prop="name" fixed width="200" label="全部群聊">
          <template slot-scope="scope">
            <div class="flex flex-start">
              <div class="ly-table__main-tearm">
                <i class="iconfont icon-weixin"></i>
              </div>
              <span style="margin-right: 8px">{{
                scope.row.name ? scope.row.name : "群聊"
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="user_count" label="群总人数" align="center">
        </el-table-column>
        <el-table-column
          prop="today_added_count"
          label="今日入群"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="today_quited_count"
          label="今日退群"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="owner_name" label="群主" align="center">
          <template slot-scope="scope">
            <ly-tag :content="scope.row.owner_name"></ly-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="group_created_at"
          sortable="custom"
          label="创建时间"
        >
          <template slot-scope="scope">
            <div class="ly-table__main-time">
              {{
                util.timeTofmt(scope.row.group_created_at, "yyyy-MM-dd hh:mm")
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80"
          align="right"
          class-name="operation-cell"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="jumpToDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>
    </layout-table>
  </div>
</template>

<script>
import layoutTable from '@/components/layout/lyTable'
import mx_role from '@/components/mixins/role'
import lyTag from '@/components/lyTag'
export default {
  data () {
    return {
      loading: true,
      searchForm: {
        name: null,
        wecom_user_ids: null,
        begin_at: null,
        end_at: null,
        order_by: 'group_created_at',
        direction: 'desc'
      },
      filterText: '',
      chooseList: '',
      timeModel: [],
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        onPick: (time) => {
          return this.onPick(time)
        }
      },
      tableData: [],
      page: 1,
      per_page: 10,
      total: 0,
      total_user_count: 0,
      userTotal: 0,
      reload: false,
      reloadId: null,
      reloadTime: null
    }
  },
  mixins: [mx_role],
  created () {
    this.getWecomGroups()
  },
  watch: {
    filterText (val) {
      this.$refs.transfer.filter(val)
    }
  },
  methods: {

    fn_getData (type) {
      this.page = 1
      if (type) {
        this.searchForm = {
          name: null,
          wecom_user_ids: null,
          begin_at: null,
          end_at: null,
          order_by: 'group_created_at',
          direction: 'desc'
        }
        this.timeModel = []
        this.$refs.lyEmpSelectRef.selected = []
      }
      this.getWecomGroups()
    },

    getEmpId (ids) {
      this.searchForm.wecom_user_ids = ids && ids.length > 0 ? ids.join(',') : null
    },

    // 获取群列表
    async getWecomGroups () {
      const params = {
        page: this.page,
        per_page: this.per_page,
        ...this.searchForm
      }
      Object.assign(params, this.searchForm)
      const data = await this.axios.get('wecomGroupsV2', {
        params
      })
      this.tableData = data.data
      this.total = data.expand.count
      this.total_user_count = data.expand.total_user_count
      this.userTotal = data.expand.user_count
      this.loading = false
    },

    // 设置时间范围
    disabledDate (time) {
      const timeOptionRange = this.timeOptionRange
      if (timeOptionRange) {
        const secondNum = 30 * 8.64e7
        const _time = timeOptionRange.getTime() + secondNum < new Date().getTime() ? timeOptionRange.getTime() + secondNum : new Date().getTime()
        return time.getTime() >= _time || time.getTime() < timeOptionRange.getTime() - secondNum
      } else {
        return time.getTime() >= new Date()
      }
    },

    // 点击时设置
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },

    // 切换时间
    changeTime (val) {
      this.page = 1
      if (val) {
        this.searchForm.begin_at = val[0]
        this.searchForm.end_at = val[1]
      } else {
        this.searchForm.begin_at = null
        this.searchForm.end_at = null
      }
    },

    // 排序监听
    tableSort (column) {
      this.page = 1
      if (column.order === null) {
        this.searchForm.order_by = 'group_created_at'
        this.searchForm.direction = 'desc'
      } else {
        this.searchForm.order_by = column.prop
        this.searchForm.direction = column.order === 'descending' ? 'desc' : 'asc'
      }
      this.getWecomGroups()
    },

    // 分页监听
    pageChange (val) {
      this.page = val
      this.getWecomGroups()
    },

    // 跳转详情
    jumpToDetail (item) {
      this.$router.push({
        path: 'detail',
        query: {
          id: item.id
        }
      })
    },

    // 获取授权状态
    async fn_getAuthStatus (type = true) {
      const data = await this.axios.get('authStatus')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$store.commit('_SET', {
        'global.appConfig': data.err_status
      })
    },

    // 同步数据
    async fn_reload () {
      if (!this.reload) {
        await this.fn_getAuthStatus()
      }
      const _appConfig = this.$store.state.global.appConfig

      if (_appConfig === 1 || _appConfig === 2 || _appConfig === 3) {
        this.fn_lyMsg('info', '自建应用异常，数据同步失败')
        return
      }
      this.reload = true
      const data = await this.axios.post('groupSync', {
        msg_id: this.reloadId
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        this.reload = false
        return false
      }

      this.reloadId = data.msg_id

      if (data.status === 0) {
        this.fn_lyMsg('success', '数据同步完成！')
        this.reload = false
        this.reloadId = null
        this.fn_getData(true)
      } else {
        this.reloadTime = setTimeout(() => {
          this.fn_reload()
        }, 1000)
      }
    }

  },
  components: {
    layoutTable,
    lyTag
  },

  destroyed () {
    if (this.reloadTime) {
      clearTimeout(this.reloadTime)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ly-table__main-tearm {
    height: 32px;
    width: 32px;
    border-radius: 32px;
    background: $--color-primary;
    text-align: center;
    line-height: 32px;
    color: #fff;
    margin-right: 12px;
  }
}
</style>
