var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drafts" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "has-table",
          attrs: {
            title: "草稿箱",
            visible: _vm.draftsVisible,
            width: "584px"
          },
          on: {
            "update:visible": function($event) {
              _vm.draftsVisible = $event
            },
            open: _vm.getDrafts
          }
        },
        [
          !_vm.$attrs.isEmpty
            ? _c("ly-table", {
                ref: "lyTable",
                attrs: {
                  data: _vm.tableData,
                  option: _vm.tableOpt,
                  page: _vm.page,
                  pageSize: _vm.per_page,
                  total: _vm.total
                },
                on: {
                  events: _vm.handleEvents,
                  searchChange: _vm.searchChange,
                  sizeChange: _vm.handleSizeChange,
                  currentChange: _vm.handleCurrentChange,
                  selectionChange: _vm.handleSelectionChange
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "name",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            { staticClass: "poster-name" },
                            [
                              _c("el-image", {
                                staticClass: "poster",
                                attrs: {
                                  src: row.poster_url || _vm.defaultPoster,
                                  fit: "cover",
                                  lazy: ""
                                }
                              }),
                              _c("span", { staticClass: "aname" }, [
                                _vm._v(_vm._s(row.name || "-"))
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "operation",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "operation-btn",
                              attrs: {
                                type: "text-info",
                                icon: "iconfont icon-edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    "/marketing/redenvelope/edit?id=" + row.id
                                  )
                                }
                              }
                            },
                            [_vm._v("继续编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "operation-btn",
                              attrs: {
                                type: "text-info",
                                icon: "iconfont icon-delete"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete(row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  242491841
                )
              })
            : _c(
                "div",
                { staticClass: "ly-nodata" },
                [
                  _c(
                    "el-empty",
                    {
                      attrs: {
                        image: require("@assets/svg/default/no_drafts.svg"),
                        description: "暂无草稿"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "iconfont icon-plus",
                            round: "",
                            size: "medium"
                          },
                          on: { click: _vm.creatAct }
                        },
                        [_vm._v("创建活动")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }