var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "创建活动",
        visible: _vm.visible,
        "custom-class": "q-dialog",
        width: "900px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-button-group",
        [
          _c(
            "el-button",
            {
              attrs: { type: _vm.type === 1 ? "primary" : "" },
              on: {
                click: function($event) {
                  _vm.type = 1
                }
              }
            },
            [_vm._v("直播裂变")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: _vm.type === 2 ? "primary" : "" },
              on: {
                click: function($event) {
                  _vm.type = 2
                }
              }
            },
            [_vm._v("私域+直播裂变")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "title" }, [_vm._v("裂变路径")]),
      _c(
        "div",
        { staticClass: "step" },
        _vm._l(_vm.type === 1 ? _vm.step : _vm.step2, function(item, index) {
          return _c("div", { key: index, staticClass: "step-item" }, [
            _c("span", { staticClass: "step-name" }, [_vm._v(_vm._s(item))]),
            _c("img", {
              staticClass: "step-arrow",
              attrs: {
                src:
                  "https://image.01lb.com.cn//uploads/wecom/22/0801/165933596115OMWwG6.png",
                alt: ""
              }
            })
          ])
        }),
        0
      ),
      _c("div", { staticClass: "title" }, [_vm._v("裂变页面")]),
      _c("div", { staticClass: "ex-img" }, [
        _vm.type === 1
          ? _c("img", {
              attrs: {
                src:
                  "https://image.01lb.com.cn//uploads/wecom/22/0801/1659337554hMIs42l6.png"
              }
            })
          : _c("img", {
              attrs: {
                src:
                  "https://image.01lb.com.cn//uploads/wecom/22/0908/1662631077dRidDKj8.png"
              }
            })
      ]),
      _c(
        "el-button",
        {
          staticClass: "create-btn",
          attrs: { round: "", type: "primary" },
          on: { click: _vm.fn_created }
        },
        [_vm._v("创建活动")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }