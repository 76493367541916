var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      staticClass: "reward"
    },
    [
      _c(
        "div",
        { staticClass: "reward-tabs" },
        _vm._l(_vm.steps, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "reward-tabs__item",
              class: [
                index === _vm.activeStep ? "is-active" : "",
                item.isDisable && "is-disable"
              ],
              on: {
                click: function($event) {
                  return _vm.fn_changeTab(item, index)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(item.title) + " "),
              _c("span", { staticClass: "circle" }),
              item.status === 0
                ? [_vm._v("未开启")]
                : [
                    item.count
                      ? [_vm._v(" " + _vm._s(item.count) + "人 ")]
                      : _vm._e()
                  ]
            ],
            2
          )
        }),
        0
      ),
      _c("reward-item", {
        ref: "rewardItemRef",
        on: { getForm: _vm.fn_getForm }
      }),
      _c("stock", { ref: "stockRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }