<!--
 * @Descripttion: 活动列表页面
 * @version: 1.0.0
-->
<template>
  <div class="page-warp activity-list">
    <ly-table
      class="act-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
      @sortChange="handleSortChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          size="medium"
          @click="$refs.uploadCodeRef.addVisable = true"
          >追加兑换码</el-button
        >
        <!-- <el-button
          type="primary"
          icon="iconfont icon-plus"
          size="medium"
          @click="$refs.uploadCodeRef.addVisable = true"
          >追加兑换码</el-button
        > -->
        <div class="total-customer">
          共
          <span class="total-num">{{ total }}</span> 条兑换码
          <el-divider direction="vertical"></el-divider>
          已发放
          <span class="q-primary">{{
            listData ? listData.already_user : 0
          }}</span>
          条 <span style="margin-right: 16px"></span>未发放
          <span class="q-primary">{{ listData ? listData.not_user : 0 }}</span>
          条
        </div>
      </template>
      <!-- 列插槽 -->
      <template #user_name_slot="{ row }">
        <div class="flex flex-start" v-if="row.send_status === 2">
          <img
            v-if="row.external_user_avatar"
            :src="row.external_user_avatar"
            alt=""
            class="ly-table__main-avatar"
          />
          <img
            v-else
            src="../../../assets/images/default_avatar.png"
            alt=""
            class="ly-table__main-avatar"
          />
          <div>
            {{ row.nickname ? row.nickname : "-" }}
          </div>
        </div>
        <div v-else>-</div>
      </template>
      <template #status="{ row }">
        <ly-status type="success" v-if="row.send_status === 2"
          >已发放</ly-status
        >
        <ly-status type="danger" v-else>未发放</ly-status>
      </template>
      <template #operation="{ row }">
        <el-button
          type="text"
          @click="activityDel(row.id)"
          v-if="row.send_status === 1"
          @keydown.enter.native.prevent
          >删除</el-button
        >
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
          v-else
        >
          <div slot="content">兑换码已被使用，不可删除</div>
          <span
            class="q-info ly-gap-button ly-disable"
            style="margin-bottom: 5px"
            >删除</span
          >
        </el-tooltip>
      </template>
    </ly-table>
    <!-- 创建、追加兑换码 -->
    <upload-code
      ref="uploadCodeRef"
      @success="queryData"
      :codeItem="codeItem"
    ></upload-code>
  </div>
</template>
<script>
import mx_role from '@/components/mixins/role'
import lyTable from '@/components/mixins/ly-table.js'
import { detail } from '@/pages/apps/code/http.js'
import uploadCode from './components/uploadCode'
export default {
  mixins: [lyTable, mx_role],
  data () {
    return {
      COMM_HTTP: detail,
      activity_id: null,
      useCount: 0,
      notUserCount: 0,
      total: 0,
      codeItem: {
        title: ''
      },
      msgBox: null
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 120,
        showIndex: true,
        column: [
          {
            label: '兑换码',
            prop: 'title',
            showOverflowTooltip: true,
            search: true
          },
          {
            label: '关联活动',
            prop: 'activity_title',
            showOverflowTooltip: true,
            search: true
          },
          {
            label: '客户昵称',
            prop: 'nickname',
            showOverflowTooltip: false,
            search: true,
            slot: 'user_name_slot',
            width: 200
          },
          {
            label: '发送时间',
            prop: 'notice_at',
            sortable: true,
            formatter: (row, column, cellValue, index) => {
              return row.notice_at ? this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm') : '-'
            }
          },
          {
            label: '发送状态',
            prop: 'send_status',
            search: true,
            clearable: 0,
            type: 'select',
            default: 0,
            source: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '未发放',
                value: 1
              },
              {
                label: '已发送',
                value: 2
              }
            ],
            slot: 'status'
          }
        ]
      }
    }
  },
  created () {
    const id = this.$route.query.id
    if (id) {
      this.code_id = id
      this.codeItem = {
        title: this.$route.query.title,
        id: this.$route.query.id
      }
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        code_id: +this.code_id
      }
    },
    activityDel (id, status) {
      this.$lyConfirm({
        title: '确认要删除此兑换码？',
        text: '兑换码删除后将无法找回, 是否继续?',
        confirmButtonText: '删除'
      }).then(async () => {
        this.COMM_HTTP.reqDelete({ id }).then(res => {
          if ((res && res.code) || (res && res.code === 0)) {
            this.$message.error(res.msg)
            return false
          }
          this.fn_openMsg('success', '删除成功！')
          this.queryData()
        })
      }).catch(() => { })
    },
    // 兑换码列表
    async fn_getList () {
      await this.COMM_HTTP.reqQuery()
    },
    // 追加兑换码
    fn_addCode (item) {
      this.$refs.uploadCodeRef.addVisable = true
    },
    fn_openMsg (type, text) {
      if (this.msgBox) {
        this.msgBox.close()
      }
      this.msgBox = this.$message[type](text)
    }
  },
  components: {
    uploadCode
  }
}
</script>
<style lang="scss" scoped>
.xl-ly-table {
  .aname {
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .status {
    position: relative;
    padding-left: 14px;
    i {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: #000;
      position: absolute;
      top: 6px;
      left: 0;
      margin-right: 5px;
    }
  }
}

.act-list {
  ::v-deep .handler-left {
    margin-bottom: 16px;
  }
}
.total-customer {
  margin-top: 16px;
}
</style>
