<template>
  <el-tree
    :data="depList"
    :props="defaultProps"
    show-checkbox
    :default-expanded-keys="defaultOpen"
    v-loading="loading"
    @check="fn_treeCheck"
    @check-change="fn_checkChange"
    node-key="id"
    ref="depTree"
    class="dep-tree"
    :check-strictly="checkType !== ''"
  ></el-tree>
</template>

<script>
export default {
  name: 'DepSelect',

  props: {
    checkType: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      loading: true,
      depList: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      defaultOpen: []
    }
  },

  methods: {

    // 获取部门列表
    async fn_getDepList () {
      const { data } = await this.axios.get('wecomDepartments', {
        params: {
          page: 1,
          per_page: 9999
        }
      })

      if (data) {
        this.depList = await this.fn_initDepList(data)
        this.defaultOpen.push(this.depList[0].id)
        this.$store.commit('_SET', {
          'global.depDatas': this.depList
        })
      }
    },

    // 设置部门
    fn_initDepList (data) {
      // 先设置一级部门
      const _allData = this.util.extend(true, [], data)
      const _depList = []

      for (let i = 0; i < _allData.length; i++) {
        if (_allData[i].corp_department_id === 1) {
          _depList.push(_allData[i])
          _allData.splice(i, 1)
          break
        }
      }

      this.fn_setDepLevel(_allData, _depList)

      return _depList
    },

    // 设置部门层级
    fn_setDepLevel (all, list) {
      for (let i = 0; i < list.length; i++) {
        const _depId = list[i].corp_department_id

        for (let j = 0; j < all.length; j++) {
          const _parentId = all[j].corp_parent_id

          if (_parentId === _depId) {
            if (!list[i].children) {
              list[i].children = []
            }

            list[i].children.push(all[j])
            all.splice(j, 1)
            j--
          }
        }

        if (all.length > 0 && list[i].children) {
          this.fn_setDepLevel(all, list[i].children)
        }
      }
    },

    async fn_initDataBack () {
      if (!this.depList) {
        if (this.$store.state.global.depDatas) {
          this.depList = this.$store.state.global.depDatas
        } else {
          await this.fn_getDepList()
        }
      }
      this.loading = false
    },

    //
    async fn_setDataBack (data) {
      if (this.$store.state.global.depDatas) {
        this.depList = this.$store.state.global.depDatas
      } else {
        await this.fn_getDepList()
      }
      this.fn_setData(data)
      this.loading = false
    },

    // 设置选中项
    fn_setData (data) {
      this.$nextTick(() => {
        for (let i = 0; i < data.length; i++) {
          this.$refs.depTree.setChecked(data[i], true, false)
        }
      })
    },

    // 获取选中项
    fn_treeCheck () {
      const _list = this.$refs.depTree.getCheckedNodes(false, true)
      const _checkList = []
      if (_list.length > 0) {
        for (let i = 0; i < _list.length; i++) {
          _checkList.push(_list[i].id)
        }
      }

      this.$emit('fn_getCheck', _checkList)
    },

    // 节点状态改变，选中父级关联子级，选中子级不关联父级
    fn_checkChange (data, ischeck, haschild) {
      if (data.children) {
        this.fn_setChildCheck(data.children, ischeck)
      }
    },

    // 设置子级
    fn_setChildCheck (data, ischeck) {
      for (let i = 0; i < data.length; i++) {
        this.$refs.depTree.setChecked(data[i].id, ischeck, false)

        if (data[i].children) {
          this.fn_setChildCheck(data[i].children, ischeck)
        }
      }
    },

    // 清空选中项
    fn_reset () {
      this.$refs.depTree.setCheckedKeys([])
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
.dep-tree {
  line-height: initial;
  background-color: transparent;

  ::v-deep .el-tree-node__label {
    @include ellipsis;
  }
}
</style>
