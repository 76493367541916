<!--企微群-群管理-群分析-->
<template>
  <div class="ly-table page-warp">
    <!-- searchBar -->
    <el-form
      :inline="true"
      :model="searchForm"
      class="demo-form-inline"
      size="medium"
    >
      <el-form-item style="margin-left: 0">
        <el-button-group>
          <el-button
            :type="btnState === 'day' ? 'primary' : ''"
            @click="getAnalyse('day')"
            >昨日</el-button
          >
          <el-button
            :type="btnState === 'week' ? 'primary' : ''"
            @click="getAnalyse('week')"
            >近7日</el-button
          >
          <el-button
            :type="btnState === 'month' ? 'primary' : ''"
            @click="getAnalyse('month')"
            >近30日</el-button
          >
        </el-button-group>
      </el-form-item>
      <el-form-item label="自定义：">
        <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="changeTime"
          style="width: 260px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="选择群主：">
        <ly-emp-select
          placeholder="选择群主"
          :showLength="1"
          :checkList="checkProp"
          @getEmpId="getEmpId"
        ></ly-emp-select>
      </el-form-item>
    </el-form>
    <!-- searchBar End -->

    <!-- card -->
    <el-row :gutter="20" style="margin-bottom: 20px">
      <el-col :span="12">
        <div
          class="q-analyse-card"
          :class="cardName === 'new_chat_cnt' ? 'active' : ''"
          @click="changeCard('new_chat_cnt')"
        >
          <el-row :gutter="20" style="flex: 1">
            <el-col
              :span="12"
              style="
                border-right: 1px solid rgba(0, 0, 0, 0.06);
                display: flex;
                justify-content: center;
              "
            >
              <div>
                <div class="title">
                  新增群聊数
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top-start"
                    popper-class="q-tooltip"
                  >
                    <div slot="content">
                      员工新拥有的客户群的数量，来源包括创建群聊、他人转让。
                    </div>
                    <i class="iconfont icon-info-fill"></i>
                  </el-tooltip>
                </div>
                <div class="num">
                  <span class="all">{{ summary.new_chat_cnt.curr }}</span>
                  <!-- <span class="all">{{ summary.new_chat_cnt.curr }}</span> /
                  {{ summary.new_chat_cnt.prev }} -->
                </div>
                <!-- <div class="rate">
                  较上期
                  <template v-if="summary.new_chat_cnt.rate === 0">
                    <span class="rate-num q-info">
                      {{ summary.new_chat_cnt.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template
                    v-else-if="
                      summary.new_chat_cnt.rate.toString().indexOf('-') === -1
                    "
                  >
                    <span class="rate-num q-success">
                      {{ summary.new_chat_cnt.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template v-else>
                    <span class="rate-num q-danger">
                      {{ summary.new_chat_cnt.rate }}%
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                  </template>
                </div> -->
              </div>
            </el-col>
            <el-col :span="12" style="display: flex; justify-content: center">
              <div>
                <div class="title">
                  互动群聊数
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top-start"
                    popper-class="q-tooltip"
                  >
                    <div slot="content">群成员有发过消息的客户群的数量。</div>
                    <i class="iconfont icon-info-fill"></i>
                  </el-tooltip>
                </div>
                <div class="num">
                  <span class="all">{{ summary.chat_has_msg.curr }}</span>
                  <!-- <span class="all">{{ summary.chat_has_msg.curr }}</span> /
                  {{ summary.chat_has_msg.prev }} -->
                </div>
                <!-- <div class="rate">
                  较上期
                  <template v-if="summary.chat_has_msg.rate === 0">
                    <span class="rate-num q-info">
                      {{ summary.chat_has_msg.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template
                    v-else-if="
                      summary.chat_has_msg.rate.toString().indexOf('-') === -1
                    "
                  >
                    <span class="rate-num q-success">
                      {{ summary.chat_has_msg.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template v-else>
                    <span class="rate-num q-danger">
                      {{ summary.chat_has_msg.rate }}%
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                  </template>
                </div> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="12">
        <div
          class="q-analyse-card"
          :class="cardName === 'new_member_cnt' ? 'active' : ''"
          @click="changeCard('new_member_cnt')"
        >
          <el-row :gutter="20" style="flex: 1">
            <el-col
              :span="12"
              style="
                border-right: 1px solid rgba(0, 0, 0, 0.06);
                display: flex;
                justify-content: center;
              "
            >
              <div>
                <div class="title">
                  新增群员数
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top-start"
                    popper-class="q-tooltip"
                  >
                    <div slot="content">
                      员工的客户群中新加入的群成员的数量。
                    </div>
                    <i class="iconfont icon-info-fill"></i>
                  </el-tooltip>
                </div>
                <div class="num">
                  <span class="all">{{ summary.new_member_cnt.curr }}</span>
                  <!-- <span class="all">{{ summary.new_member_cnt.curr }}</span> /
                  {{ summary.new_member_cnt.prev }} -->
                </div>
                <!-- <div class="rate">
                  较上期
                  <template v-if="summary.new_member_cnt.rate === 0">
                    <span class="rate-num q-info">
                      {{ summary.new_member_cnt.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template
                    v-else-if="
                      summary.new_member_cnt.rate.toString().indexOf('-') === -1
                    "
                  >
                    <span class="rate-num q-success">
                      {{ summary.new_member_cnt.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template v-else>
                    <span class="rate-num q-danger">
                      {{ summary.new_member_cnt.rate }}%
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                  </template>
                </div> -->
              </div>
            </el-col>
            <el-col :span="12" style="display: flex; justify-content: center">
              <div>
                <div class="title">
                  互动群员数
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top-start"
                    popper-class="q-tooltip"
                  >
                    <div slot="content">在客户群中发过消息的群成员的数量。</div>
                    <i class="iconfont icon-info-fill"></i>
                  </el-tooltip>
                </div>
                <div class="num">
                  <span class="all">{{ summary.member_has_msg.curr }}</span>
                  <!-- <span class="all">{{ summary.member_has_msg.curr }}</span> /
                  {{ summary.member_has_msg.prev }} -->
                </div>
                <!-- <div class="rate">
                  较上期
                  <template v-if="summary.member_has_msg.rate === 0">
                    <span class="rate-num q-info">
                      {{ summary.member_has_msg.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template
                    v-else-if="
                      summary.member_has_msg.rate.toString().indexOf('-') === -1
                    "
                  >
                    <span class="rate-num q-success">
                      {{ summary.member_has_msg.rate }}%
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </template>
                  <template v-else>
                    <span class="rate-num q-danger">
                      {{ summary.member_has_msg.rate }}%
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                  </template>
                </div> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- card End -->
    <div class="temp-contain">
      <!-- toolBar -->
      <div class="ly-tool">
        <!-- 显示模式 -->
        <div class="alys-card__type">
          <span
            class="alys-card__btn"
            :class="item.value === analyseState ? 'active' : ''"
            v-for="(item, index) in typeList"
            :key="index"
            @click="analyseState = item.value"
          >
            {{ item.label }}
          </span>
        </div>
      </div>
      <!-- toolBar End -->

      <!-- chart -->
      <ly-chart
        :option="chartOption"
        height="400px"
        v-show="analyseState === 'chart' && showEmpty"
      ></ly-chart>
      <div
        v-show="analyseState === 'chart' && !showEmpty"
        class="flex flex-center q-info"
        style="height: 240px"
      >
        暂无分析数据
      </div>
      <!-- chart End -->

      <!-- table -->
      <div class="ly-table__main" v-show="analyseState === 'list'">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="stat_time" label="日期"> </el-table-column>
          <el-table-column
            prop="new_chat_cnt"
            label="新增群聊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="chat_has_msg"
            label="互动群聊数"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="user_total" label="群聊总数" align="center">
          </el-table-column>
          <!-- <el-table-column prop="chat_rate" label="互动群聊占比" align="center">
          </el-table-column> -->
          <el-table-column
            prop="new_member_cnt"
            label="新增群员数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="member_has_msg"
            label="互动群员数"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="member_total" label="群员总数" align="center">
          </el-table-column>
          <!-- <el-table-column
            prop="member_rate"
            label="互动群员占比"
            align="center"
          >
          </el-table-column> -->
          <el-table-column prop="msg_total" label="群聊消息总数" align="center">
          </el-table-column>
          <div slot="empty" class="ly-table-empty">
            <slot name="empty">
              <img src="@assets/svg/default/no_data.svg" alt="" />
              暂无数据
            </slot>
          </div>
        </el-table>
      </div>
      <!-- table End -->
    </div>
  </div>
</template>

<script>
import lyChart from '@/components/lyChart'
export default {
  data () {
    return {
      btnState: 'week',
      dates: [],
      allEmpsData: [],
      filterText: '',
      chooseList: [],
      searchForm: {
        begin_at: '',
        end_at: '',
        user_ids: null
      },
      showType: 1,
      typeList: [
        {
          label: '趋势走势图',
          value: 'chart'
        },
        {
          label: '趋势表格',
          value: 'list'
        }
      ],
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        onPick: (time) => {
          return this.onPick(time)
        }
      },
      showPlaceholder: true,
      cardName: 'new_chat_cnt',
      // curLabel: '发起申请数',
      // preLabel: '上期发起申请数',
      analyseState: 'chart',
      summary: {
        chat_has_msg: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        member_has_msg: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        new_chat_cnt: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        new_member_cnt: {
          curr: 0,
          prev: 0,
          rate: 0
        }
      },
      current: [],
      previous: [],
      summaryCurr: 0,
      chart: null,
      chartOption: {
        color: ['rgb(43, 124, 255)', 'rgb(0, 199, 128)', 'rgb(250, 210, 12)', 'rgb(250, 138, 20)'],
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(0, 0, 0, .8)',
          formatter: function (params) {
            const text = `<div class="flex" style="line-height: 2; padding: 4px 12px; color: #fff;">
                          <div style="margin-right: 24px;">
                            ${params[0].data.time}<br>
                            ${params[1].marker}${params[1].seriesName}：${params[1].data.value}人（增长 ${params[1].data.range}）<br>
                            ${params[0].marker}${params[0].seriesName}：${params[0].data.value}人（增长 ${params[0].data.range}）
                          </div>
                          <div>
                            ${params[2].data.time}<br>
                            ${params[3].marker}${params[3].seriesName}：${params[3].data.value}人<br>
                            ${params[2].marker}${params[2].seriesName}：${params[2].data.value}人
                          </div>
                          </div>`

            return text
          }
        },
        grid: {
          top: '10%',
          bottom: 100,
          left: '2%',
          right: '2%',
          containLabel: true
        },
        legend: {
          icon: 'circle',
          bottom: '20px',
          itemGap: 30
        },
        xAxis: {
          boundaryGap: ['2%', '20%'],
          axisLine: {
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          axisLabel: {
            color: '#6E788A'
          },
          data: []
        },
        yAxis: {
          minInterval: 1,
          name: '人数',
          nameTextStyle: {
            align: 'right'
          },
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          }
        },
        series: [
          {
            name: '互动群聊数',
            type: 'bar',
            barGap: '33%',
            stack: 'one',
            data: [],
            barWidth: 24,
            itemStyle: {
              borderRadius: 12
            }
          },
          {
            name: '新增群聊数',
            type: 'bar',
            barGap: '33%',
            stack: 'one',
            data: [],
            barWidth: 24,
            itemStyle: {
              borderRadius: 12
            }
          },
          {
            name: '上期互动群聊数',
            type: 'bar',
            barGap: '33%',
            stack: 'two',
            data: [],
            barWidth: 24,
            itemStyle: {
              borderRadius: 12
            }
          },
          {
            name: '上期新增群聊数',
            type: 'bar',
            barGap: '33%',
            stack: 'two',
            data: [],
            barWidth: 24,
            itemStyle: {
              borderRadius: 12
            }
          }
        ],
        dataZoom: [
          {
            type: 'slider',
            height: 12,
            showDataShadow: false,
            showDetail: false,
            brushSelect: false,
            xAxisIndex: [0],
            startValue: 0,
            endValue: 14,
            bottom: 70,
            zoomLock: true,
            handleStyle: {
              opacity: 0
            }
          }
        ]
      },
      tableData: [],
      userTotal: null,
      checkProp: '',
      showEmpty: true
    }
  },
  created () {
    const _id = this.$route.query.id
    if (_id) {
      this.searchForm.user_ids = _id
      this.checkProp = [_id]
      this.$router.replace({ query: {} })
    }
    this.getWecomGroupStatistic()
    this.getAnalyse('week')
  },
  computed: {
    emps () {
      if (this.$store.state.global.empolyees) {
        return this.$store.state.global.empolyees
      } else {
        return []
      }
    },
    allEmps () {
      if (this.$store.state.global.allEmps) {
        return this.$store.state.global.allEmps
      } else {
        return 0
      }
    },
    role () {
      if (this.$store.state.global.role) {
        return this.$store.state.global.role
      } else {
        return null
      }
    },
    deps () {
      if (this.$store.state.global.departments) {
        return this.$store.state.global.departments
      } else {
        return null
      }
    }
  },
  watch: {
    filterText (val) {
      this.$refs.transfer.filter(val)
    },
    chooseList () {
      this.chooseList.length === 0 ? this.showPlaceholder = true : this.showPlaceholder = false
    },
    cardName (newVal, oldVal) {
      this.initChartOptions()
    }
  },
  methods: {

    // 设置时间范围
    disabledDate (time) {
      const timeOptionRange = this.timeOptionRange
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _time = _date.getTime()
      if (timeOptionRange) {
        const secondNum = 30 * 8.64e7
        const _maxTime = timeOptionRange.getTime() + secondNum < (_time - 1 * 8.64e7) ? timeOptionRange.getTime() + secondNum : (_time - 1 * 8.64e7)
        const _minTime = timeOptionRange.getTime() - secondNum > (_time - 151 * 8.64e7) ? timeOptionRange.getTime() - secondNum : (_time - 151 * 8.64e7)
        return time.getTime() >= _maxTime || time.getTime() < _minTime
      } else {
        return time.getTime() >= _time - 1 * 8.64e7 || time.getTime() < _time - 151 * 8.64e7
      }
    },

    // 点击时设置
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },

    // 切换时间
    changeTime (val) {
      if (val) {
        this.searchForm.begin_at = this.util.timeTofmt(val[0])
        this.searchForm.end_at = this.util.timeTofmt(val[1])
        this.btnState = ''
        this.getAnalyse()
      } else {
        this.searchForm.begin_at = null
        this.searchForm.end_at = null
        this.getAnalyse('week')
      }
    },

    // 切换卡片
    changeCard (val) {
      this.cardName = val
    },

    getEmpId (ids) {
      this.searchForm.user_ids = ids && ids.length > 0 ? ids.join(',') : null
      this.getAnalyse()
    },

    // 获取统计数据
    async getWecomGroupStatistic () {
      const data = await this.axios.get('wecomGroupStatistic')
      this.userTotal = data.count
    },

    // 获取数据
    async getAnalyse (type) {
      let beginAt, endAt
      if (type) {
        this.btnState = type
        let time = await this.axios.get('getTime')
        time *= 1000
        endAt = this.util.timeTofmt(time - 1 * 8.64e7)
        switch (type) {
          case 'day':
            beginAt = this.util.timeTofmt(time - 2 * 8.64e7)
            break
          case 'week':
            beginAt = this.util.timeTofmt(time - 7 * 8.64e7)
            break
          case 'month':
            beginAt = this.util.timeTofmt(time - 30 * 8.64e7)
            break
        }
        this.$nextTick(() => {
          this.dates = [beginAt, endAt]
        })
        this.searchForm.begin_at = beginAt
        this.searchForm.end_at = endAt
      }

      const params = {
        begin_at: this.searchForm.begin_at,
        end_at: this.searchForm.end_at,
        user_ids: this.searchForm.user_ids
      }
      const data = await this.axios.get('wecomGroupAnalysis', {
        params
      })
      Object.assign(this.summary, data.summary)
      this.current = data.current || []

      // 表格数据(本期)
      const arr = []
      if (this.current) {
        for (let i = 0; i < this.current.length; i++) {
          const stat_time = this.util.timeTofmt(data.current[i].stat_time)
          const user_total = this.userTotal
          const chat_rate = data.current[i].data.chat_has_msg > 0 ? (data.current[i].data.chat_has_msg / this.userTotal * 100).toFixed(2) + '%' : '0%'
          const member_rate = data.current[i].data.member_has_msg > 0 ? (data.current[i].data.chat_has_msg / data.current[i].data.member_total * 100).toFixed(2) + '%' : '0%'
          arr.push({
            stat_time,
            user_total,
            chat_rate,
            member_rate,
            ...data.current[i].data
          })
        }
        this.tableData = arr
        this.previous = data.previous || []
        this.initChartOptions()
      } else {
        this.showEmpty = false
      }
    },

    initChartOptions () {
      const curArr = this.current
      const preArr = this.previous
      const curData = []
      const preData = []
      const curData1 = []
      const preData1 = []
      const labelData = []
      for (let i = 0; i < curArr.length; i++) {
        const _curTime = this.util.timeTofmt(curArr[i].stat_time, 'd')
        const _toolCurTime = this.util.timeTofmt(curArr[i].stat_time, 'M月d日')
        const _toolPreTime = this.util.timeTofmt(preArr[i].stat_time, 'M月d日')
        labelData.push(_curTime + '日')
        if (this.cardName === 'new_chat_cnt') {
          curData.push({
            value: curArr[i].data.new_chat_cnt,
            time: _toolCurTime,
            range: this.cptRange(curArr[i].data.new_chat_cnt, preArr[i].data.new_chat_cnt)
          })
          curData1.push({
            value: curArr[i].data.chat_has_msg,
            time: _toolCurTime,
            range: this.cptRange(curArr[i].data.chat_has_msg, preArr[i].data.chat_has_msg)
          })
          preData.push({
            value: preArr[i].data.new_chat_cnt,
            time: _toolPreTime
          })
          preData1.push({
            value: preArr[i].data.chat_has_msg,
            time: _toolPreTime
          })
        } else {
          curData.push({
            value: curArr[i].data.new_member_cnt,
            time: _toolCurTime,
            range: this.cptRange(curArr[i].data.new_member_cnt, preArr[i].data.new_member_cnt)
          })
          curData1.push({
            value: curArr[i].data.member_has_msg,
            time: _toolCurTime,
            range: this.cptRange(curArr[i].data.member_has_msg, preArr[i].data.member_has_msg)
          })
          preData.push({
            value: preArr[i].data.new_member_cnt,
            time: _toolPreTime
          })
          preData1.push({
            value: preArr[i].data.member_has_msg,
            time: _toolPreTime
          })
        }
      }
      // 配置图表x轴字段
      this.chartOption.xAxis.data = labelData
      if (this.cardName === 'new_chat_cnt') {
        this.chartOption.series[1].name = '新增群聊数'
        this.chartOption.series[0].name = '互动群聊数'
        this.chartOption.series[3].name = '上期新增群聊数'
        this.chartOption.series[2].name = '上期互动群聊数'
      } else {
        this.chartOption.series[1].name = '新增群员数'
        this.chartOption.series[0].name = '互动群员数'
        this.chartOption.series[3].name = '上期新增群员数'
        this.chartOption.series[2].name = '上期互动群员数'
      }
      // 配置图表本期数据
      this.chartOption.series[1].data = curData
      this.chartOption.series[0].data = curData1
      // 配置图表上期数据
      this.chartOption.series[3].data = preData
      this.chartOption.series[2].data = preData1
    },

    // 计算比率
    cptRange (cur, pre) {
      let _range = 0
      const _rangeNum = cur - pre
      if (pre === 0) {
        _range = cur * 100 + '%'
      } else {
        if (cur === 0) {
          _range = -(parseInt(pre * 100)) + '%'
        } else {
          _range = parseInt(_rangeNum / pre * 100) + '%'
        }
      }
      return _range
    }
  },
  components: {
    lyChart
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/table.scss";
@import "@/assets/scss/analyse.scss";
::v-deep {
  .el-form-item__label {
    padding: 0;
  }
}
.temp-contain {
  background: #fff;
  border-radius: 16px;
  box-shadow: $--shadow;
  min-height: calc(100% - 259px);
}
.q-analyse-card {
  .num,
  .rate {
    display: block;
    margin-left: 0;
    text-align: center;
  }
  .num {
    margin: 8px 0 20px;
    color: #6e788a;
    .all {
      font-size: 30px;
      color: #212b36;
    }
  }
  .rate-num {
    display: unset;
    margin-left: 8px;
  }
}
.alys-card__type {
  color: #6e788a;
  margin-bottom: 20px;
  .alys-card__btn {
    margin-right: 24px;
    cursor: pointer;
  }
  .active {
    color: #2b7cff;
  }
}
.ly-table__main {
  padding-top: 0;
  padding-bottom: 20px;
}
</style>
