var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      staticClass: "act-form",
      attrs: { model: _vm.form, "label-width": "160px", size: "medium" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "无库存规则：" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "rule-form__radio",
              model: {
                value: _vm.form.stop_type,
                callback: function($$v) {
                  _vm.$set(_vm.form, "stop_type", $$v)
                },
                expression: "form.stop_type"
              }
            },
            [
              _c(
                "el-radio",
                { attrs: { label: 2, disabled: _vm.isEdit && !_vm.isCopy } },
                [
                  _vm._v("所有阶段库存为0时，活动自动暂停 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { placement: "top", "popper-class": "q-tooltip" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 选择此选项，开启多阶段任务时，其中一个阶段奖品被用户领取完后，"
                          ),
                          _c("br"),
                          _vm._v(
                            "活动仍然继续，直至所有阶段库存被领取完，才自动暂停。"
                          ),
                          _c("br"),
                          _vm._v(
                            "奖品无库存后，此处追加库存，可以在「奖品记录」将奖品重新发放给用户。 "
                          )
                        ]
                      ),
                      _c("i", { staticClass: "ri-question-line" })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-radio",
                { attrs: { label: 1, disabled: _vm.isEdit && !_vm.isCopy } },
                [
                  _vm._v("任意阶段库存为0时，活动自动暂停 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { placement: "top", "popper-class": "q-tooltip" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 选择此选项，开启多阶任务时，其中一个阶段奖品被用户领取完后，活动就自动暂停。 "
                          )
                        ]
                      ),
                      _c("i", { staticClass: "ri-question-line" })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }