<!--
 * @Author: zhan
 * @Date: 2022-05-23 16:20:56
 * @LastEditTime: 2022-06-05 15:32:34
 * @LastEditors: zhan
-->
<template>
  <el-dialog :visible.sync="visible" width="600px" :title="title" class="q-dialog" :close-on-click-modal="false">
    <el-form size="medium" label-width="110px" :rules="rules" ref="formRef" :model="form" @submit.native.prevent>
      <el-form-item label="直播间名称：" prop="title">
        <el-input v-model.trim="form.title" placeholder="请输入直播间名称" maxlength="60" show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item label="开播时间：" prop="open_time">
        <el-date-picker type="datetime" placeholder="请选择开播时间" v-model="form.open_time" :picker-options="endAtOption"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="预告首图：" prop="poster_url">
        <div class="q-info text-small">
          建议尺寸:260*260像素，大小不超过2MB，格式为JPG/GIF/PNG/JPEG
        </div>
        <ly-upload :img="form.poster_url" @getImg="fn_getPoster" tipText="上传海报" accept=".png,.jpg,.jpeg,.gif">
        </ly-upload>
      </el-form-item>
      <el-form-item label="预告二维码：" prop="qrcode_url">
        <div class="q-info text-small">
          视频号预告二维码后进行上传
        </div>
        <ly-upload :img="form.qrcode_url" @getImg="fn_getCode" tipText="上传二维码" cropper></ly-upload>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <el-button type="primary" round @click="fn_subForm">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'addNotice',
  data () {
    return {
      visible: false,
      title: '',
      form: {
        fid: 0,
        title: '',
        open_time: null,
        poster_url: '',
        qrcode_url: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入直播间名称', trigger: 'change' }
        ],
        open_time: [
          { required: true, message: '请选择开播时间', trigger: 'change' }
        ],
        poster_url: [
          { required: true, message: '请上传预告首图', trigger: 'change' }
        ],
        qrcode_url: [
          { required: true, message: '请上传预告二维码', trigger: 'change' }
        ]
      }
    }
  },

  computed: {

    // 结束时间选择器设置：大于当前，如果有购买，则小于购买期限
    endAtOption () {
      return {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },
  methods: {

    // 打开
    fn_open (item, id) {
      this.title = item ? '编辑预告' : '新增预告'
      this.visible = true
      this.$nextTick(() => {
        if (item) {
          const form = this.util.extend(true, {}, item)
          this.form = form
        } else {
          this.form.fid = id
          this.$refs.formRef.resetFields()
        }
      })
    },

    // 海报设置回调
    fn_getPoster (img) {
      this.form.poster_url = img
      this.$refs.formRef.validateField('poster_url')
    },

    // 二维码设置回调
    fn_getCode (img) {
      this.form.qrcode_url = img
      this.$refs.formRef.validateField('qrcode_url')
    },

    // 保存
    fn_subForm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const form = this.util.extend(true, {}, this.form)
          this.$emit('noticeEdit', form)
          this.visible = false
        } else {
          return false
        }
      })
    }
  }
}
</script>
