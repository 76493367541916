<!--
* 选择群聊：已选表格
-->
<template>
  <div class="selected-table">
    <el-radio v-model="type" label="1">按云端账号选择群</el-radio>
    <el-radio v-model="type" label="2">通过群组选择群</el-radio>
    <ly-table
      v-show="showTable"
      v-if="!destroy"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      style="margin-top: 12px"
    >
      <template #handler>
        <el-button
          round
          v-if="type === '1'"
          type="primary"
          @click="$refs.selectAccount.visible = true"
          >选择云端账号</el-button
        >
        <el-button
          round
          v-else
          type="primary"
          @click="$refs.selectGroup.visible = true"
          >选择群分组</el-button
        >
      </template>

      <template #name_slot="{ row }">
        <div class="flex flex-start">
          <el-avatar :size="32" :src="row.avatar"></el-avatar>
          <span style="margin: 0 8px; flex: 1">{{ row.name || "-" }}</span>
        </div>
      </template>

      <template #group_slot="{ row }">
        <div class="flex flex-start">
          <div class="ly-table-tearm">
            <i class="iconfont icon-weixin"></i>
          </div>
          <span style="margin-right: 8px; flex: 1">{{
            row.name ? row.name : "群聊"
          }}</span>
        </div>
      </template>

      <template #operation="{ row, index }">
        <el-button round type="text" @click="handleChose(row, index)"
          >选择群</el-button
        >
        <el-button
          round
          type="text"
          style="color: #ed5c49"
          @click="remove(row, index)"
          >移除</el-button
        >
      </template>
    </ly-table>
    <span class="tips"
      >如果一个群内有多个群发的云端账号，将随机选择一个账号群发。</span
    >
    <select-account
      ref="selectAccount"
      @submit="handleSubmit"
      :initialData="tableData"
    />
    <select-group
      ref="selectGroup"
      @submit="handleSubmit"
      :initialData="tableData"
    />
    <selectGroup-by-account ref="selectGroupByAccount" @submit="handleEdit" />
  </div>
</template>
<script>
import selectAccount from './selectAccount.vue'
import selectGroup from './selectGroup.vue'
import selectGroupByAccount from './selectGroupByAccount.vue'
import { group } from '../http'
export default {
  components: {
    selectAccount,
    selectGroup,
    selectGroupByAccount
  },
  provide () {
    return {
      flag: () => this.flag
    }
  },
  data () {
    return {
      showTable: false,
      destroy: false,
      robotList: null,
      type: '1',
      flag: false,
      variable: {
        label: '已选账号',
        prop: 'name',
        slot: 'name_slot'
      },
      extra_param: {},
      tableData: [],
      index: 0
    }
  },
  computed: {
    tableOpt () {
      return {
        layout: 'hide',
        column: [
          this.variable,
          {
            label: '已选群数',
            prop: 'groupIds',
            align: 'center',
            formatter: (row, column, cellValue, index) => {
              return cellValue.length
            }
          }
        ]
      }
    }
  },
  watch: {
    type (n) {
      this.tableData = []
      this.variable = {
        label: n === '1' ? '已选账号' : '已选群组',
        prop: 'name',
        slot: n === '1' ? 'name_slot' : 'group_slot'
      }
      this.$nextTick(() => {
        this.$refs.lyTable.doLayout()
      })
    },
    extra_param: {
      async handler (n) {
        if (!n.type) {
          return false
        }
        let res
        if (n.type === '1') {
          res = await group.robotlist()
        } else {
          res = await group.allTopic({
            type: 1 // 查询被管理的分组
          })
        }
        if (res && res.code === 0) {
          this.$message.error(res.msg)
          return false
        }
        this.type = n.type
        const list = n.type === '1' ? res.list : res
        n.data.map(e => {
          const id = Object.keys(e)[0] * 1
          list.map(m => {
            if (m.id === id) {
              this.$nextTick(() => {
                this.tableData.push({
                  id: id,
                  name: m.name,
                  avatar: m.avatar,
                  status: m.status,
                  groupIds: e[id]
                })
              })
            }
          })
        })
      }
    }
  },
  mounted () {
    // 解决闪烁的问题
    this.$nextTick(() => {
      this.showTable = true
    })
  },
  methods: {
    handleSubmit (tableData) {
      this.tableData = tableData
      const group_ids = []
      const data = []
      tableData.map(e => {
        group_ids.push(...e.groupIds)
        data.push({ [e.id]: e.groupIds })
      })
      this.$emit('change', { group_ids: Array.from(new Set(group_ids)), extra_param: { type: this.type, data } })
    },
    /**
     * @description: 再次选择群回调
     * @param {*}
     * @return {*}
     */
    handleEdit (item) {
      this.$set(this.tableData, this.index, item)
      const group_ids = []
      const data = []
      this.tableData.map(e => {
        group_ids.push(...e.groupIds)
        data.push({ [e.id]: e.groupIds })
      })
      this.$nextTick(e => {
        this.$emit('change', { group_ids: Array.from(new Set(group_ids)), extra_param: { type: this.type, data } })
      })
    },
    remove ({ id }, i) {
      this.$lyConfirm({
        text: '此操作无法撤销, 是否确定删除?',
        title: '确认要删除吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.type === '1') {
          // this.$refs.selectAccount.handleRemove(id, i)
          this.tableData = this.tableData.filter(e => e.id !== id)
          this.handleSubmit(this.tableData)
        } else {
          this.$refs.selectGroup.handleRemove(id, i)
        }
      }).catch(() => { })
    },
    /**
     * @description: 通过云端账号再次选择群
     * @param {*} row
     * @return {*}
     */
    handleChose (row, index) {
      this.index = index // 缓存修改的index
      if (this.type === '2') {
        this.$refs.selectGroup.visible = true
      } else {
        this.$refs.selectGroupByAccount.accountInfo = row
        this.$refs.selectGroupByAccount.visible = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tips {
  font-size: 12px;
  color: #6e788a;
}
::v-deep {
  .handler-left {
    margin-bottom: 16px !important;
  }
  .el-avatar {
    width: 32px;
  }
  .ly-table-main {
    line-height: 1;
    box-shadow: unset;
    margin-bottom: 0 !important;
  }
}
</style>
