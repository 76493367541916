<!--
 * @Author: zhanln
 * @Date: 2022-03-30 11:51:43
 * @LastEditTime: 2022-04-19 16:40:59
 * @LastEditors: zhanln
 * @Description: 分组
-->

<template>
  <el-dialog
    title="分组"
    :visible.sync="visible"
    width="420px"
    @close="fn_close"
  >
    <div class="group-count">
      已选择（<span class="q-primary">{{ count }}</span
      >）个素材
    </div>
    <el-scrollbar class="group-scroll" v-loading="dataLoading">
      <el-radio-group v-model="group">
        <el-radio
          :label="item.group_id"
          v-for="item in groups"
          :key="item.group_id"
          >{{ item.name || "默认分组" }}</el-radio
        >
      </el-radio-group>
    </el-scrollbar>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        round
        @click="fn_confirm"
        :disabled="dataLoading"
        :loading="btnLoading"
        >确定分组</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { groupApi } from '../http'
export default {
  name: 'changeGroup',

  inject: ['category', 'fn_getData'],

  props: {
    currentGroupId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    }
  },

  data () {
    return {
      groupApi: groupApi,
      visible: false,
      dataLoading: true,
      group: null,
      selected: null,
      groups: [],
      count: 0,
      btnLoading: false
    }
  },

  methods: {

    // 打开设置
    async fn_open (data) {
      this.count = data.length
      this.selected = data
      this.visible = true
      await this.fn_getGroupData()
      this.group = this.currentGroupId === '-1' ? null : this.currentGroupId
    },

    // 分组数据
    async fn_getGroupData (id) {
      const data = await this.groupApi.groupList({
        category: this.category
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.groups = data.list
      this.dataLoading = false
    },

    // 分组提交
    async fn_confirm () {
      if (!this.group) {
        return this.fn_lyMsg('info', '请选择分组！')
      }

      if (this.group === this.currentGroupId) {
        this.visible = false
        return this.fn_lyMsg('success', '分组未改变！')
      }

      this.btnLoading = true
      const params = {
        category: this.category,
        to_group_id: this.group,
        material_ids: this.selected.map(item => {
          return item.material_id || item
        })
      }

      const data = await this.groupApi.transfer(params)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.btnLoading = false
        return false
      }

      this.fn_lyMsg('success', '分组成功！')
      this.btnLoading = false
      this.visible = false
      this.fn_getData(this.currentGroupId, this.type)
      this.$emit('clearSelected')
    },

    // 关闭弹框
    fn_close () {
      this.group = null
      this.groups = []
    }
  }

}
</script>

<style lang="scss" scoped>
.group-count {
  font-size: 16px;
}

.group-scroll {
  height: 280px;
  overflow: hidden;
  padding: 8px 0;
}

.el-radio-group {
  display: block;
}

.el-radio {
  display: flex;
  margin: 14px 0;
  overflow: hidden;

  ::v-deep .el-radio__label {
    flex: 1;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

::v-deep .el-dialog__body {
  padding-bottom: 0;
}
</style>
