<!--
 * @Author: zhanln
 * @Date: 2022-03-04 17:12:08
 * @LastEditTime: 2022-03-17 16:41:56
 * @LastEditors: Please set LastEditors
 * @Description: 接入企微号
-->

<template>
  <div class="robots has-table">
    <ly-table
      class="act-list"
      ref="lyTable"
      v-show="total"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 列插槽 -->
      <template #name="{ row }">
        <div class="flex">
          <el-image
            class="create-avatar"
            :src="row.avatar"
            fit="cover"
          ></el-image>
          <span class="robot-name">
            {{ row.name }}
          </span>
        </div>
      </template>

      <template #status="{ row }">
        <span class="q-success" v-if="row.status === 2">在线</span>
        <span class="q-danger" v-else>离线</span>
      </template>
    </ly-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import lyTable from '@/components/mixins/ly-table.js'
export default {
  name: 'robots',

  mixins: [lyTable],

  data () {
    return {
      IMMEDIATE: false
    }
  },

  computed: {
    ...mapState({
      loadingData: state => state.global.loading > 0 // 大于0时loading
    }),
    tableOpt () {
      return {
        levitate: false,
        operation: 'hide',
        layout: 'hide',
        tableProps: {
          height: 430
        },
        column: [
          {
            label: '企微号',
            prop: 'name',
            slot: 'name'
          },
          {
            label: '状态',
            prop: 'status',
            slot: 'status',
            width: 100
          }
        ]
      }
    }
  },

  methods: {

    // 按需打开弹框
    fn_init (item) {
      const list = item
      this.tableData = list.robot
      this.total = this.tableData.length
    }
  }
}
</script>

<style lang="scss" scoped>
.xl-ly-table {
  ::v-deep .ly-table-main {
    border-radius: 0;
  }
  .robot-name {
    flex: 1;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.create-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
</style>
