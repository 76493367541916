var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-mobile",
    {
      attrs: {
        showWel: false,
        hasFooter: false,
        backIcon: "icon-close",
        sub: "",
        title: _vm.show_title
      }
    },
    [
      _c("div", { staticClass: "reward" }, [
        _c("div", { staticClass: "reward-header" }, [
          _c("div", { staticClass: "reward-header__user" }, [
            _c("div", { staticClass: "avatar" }, [
              _vm.userInfo.avatar_url
                ? _c("img", {
                    attrs: { src: _vm.userInfo.avatar_url, alt: "" }
                  })
                : _c("img", {
                    attrs: {
                      src: require("@assets/images/app_logo.png"),
                      alt: ""
                    }
                  })
            ]),
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.userInfo.nickname || "零一裂变"))
              ]),
              _c("div", { staticClass: "rank" }, [
                _c("img", {
                  attrs: { src: require("@assets/images/ic_rank.png"), alt: "" }
                }),
                _vm._v("排名 1200 ")
              ])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "reward-header__rule",
              on: {
                click: function($event) {
                  _vm.$refs.rewardRuleRef.visible = true
                }
              }
            },
            [_vm._v(" 活动规则 ")]
          )
        ]),
        _c("div", { staticClass: "reward-card" }, [
          _vm.stepsLength > 1
            ? _c(
                "div",
                { staticClass: "reward-tab" },
                [
                  _vm._l(_vm.steps, function(step, index) {
                    return [
                      step.status === 1
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item",
                              class: {
                                current: index === _vm.current,
                                corner: index === _vm.current - 1
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(step.title) + " "),
                              _c("br"),
                              _vm._v(
                                " 邀请" +
                                  _vm._s(_vm.fn_getTarget(index)) +
                                  "人 "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "reward-step",
              class: { "has-radius": _vm.stepsLength === 1 }
            },
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "cover" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.reward_cover || _vm.defaultCover,
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.reward_title || "奖品名称"))
                  ]),
                  _c("div", { staticClass: "desc ellipsis2" }, [
                    _vm._v(" " + _vm._s(_vm.reward_desc || "奖品简介") + " ")
                  ]),
                  _c("div", { staticClass: "total" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.open_stock === 1
                            ? "剩余库存：" + _vm.total
                            : "库存告急"
                        ) +
                        " "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "reward-progress" }, [
                _c("div", { staticClass: "reward-progress__bar" }, [
                  _c(
                    "div",
                    {
                      staticClass: "reward-progress__text",
                      style: { left: _vm.barWidth }
                    },
                    [_vm._v(" 已邀请 " + _vm._s(_vm.inviterCount) + " 人 ")]
                  ),
                  _c("div", {
                    staticClass: "reward-progress__inner",
                    style: { width: _vm.barWidth }
                  })
                ]),
                _c("div", { staticClass: "reward-progress__count" }, [
                  _c("span", [_vm._v(_vm._s(_vm.inviterCount))]),
                  _vm._v("/" + _vm._s(_vm.target_count) + " ")
                ])
              ]),
              _c("div", { staticClass: "reward-unreal" }, [
                _c(
                  "div",
                  { staticClass: "reward-unreal__avatars" },
                  [
                    _vm._l(_vm.avatarList.slice(0, _vm.unreal), function(
                      item,
                      index
                    ) {
                      return _c("img", {
                        key: index,
                        attrs: { src: item, alt: "" }
                      })
                    }),
                    _vm.unreal > 4
                      ? _c("span", { staticClass: "elip" }, [
                          _c("i", { staticClass: "el-icon-more" })
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(" 已有"),
                      _c("b", [_vm._v(_vm._s(_vm.unreal) + "人")]),
                      _vm._v("领取成功 ")
                    ])
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "reward-inviter" }, [
                _vm._v(
                  " 还差 " +
                    _vm._s(_vm.fn_getTarget(_vm.current) - _vm.inviterCount) +
                    " 人，立即邀请好友 "
                )
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "reward-rank" }, [
          _c("img", {
            attrs: { src: require("@assets/images/task_rank.png"), alt: "" }
          })
        ])
      ]),
      _c("reward-detail"),
      _c("reward-rule", { ref: "rewardRuleRef", attrs: { tabKey: _vm.tabKey } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }