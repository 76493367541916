var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c("div", { staticClass: "main-title" }, [_vm._v("数据概览")]),
      !_vm.isShowEmpty
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "el-col-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 昨日新增用户数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " 昨日扫码添加（包含昨日已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "nums" }, [
                    _vm._v(_vm._s(_vm.panelInfo.yesterdayAddUserCount || 0))
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "el-col-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 昨日新增留存率 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " 昨日新增且昨日未删除的用户数/昨日新增用户数 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "nums" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.panelInfo.yesterdayRetentionRate || 0) +
                          " "
                      ),
                      _vm.panelInfo.yesterdayRetentionRate
                        ? [_vm._v("%")]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "el-col-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 今日新增用户数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " 今日扫码添加（包含已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "nums" }, [
                    _vm._v(_vm._s(_vm.panelInfo.todayAddUserCount || 0))
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "el-col-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 今日新增留存率 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v("今日新增且未删除的用户数/今日新增用户数")]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "nums" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.panelInfo.todayRetentionRate || 0) +
                          " "
                      ),
                      _vm.panelInfo.todayRetentionRate
                        ? [_vm._v("%")]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "el-col-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 总用户数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v("留存的用户数")]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "nums" }, [
                    _vm._v(_vm._s(_vm.panelInfo.userCount || 0))
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "el-col-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 总留存率 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v("总用户数/新增用户总数")]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "nums" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.panelInfo.retentionRate || 0) + " "
                      ),
                      _vm.panelInfo.retentionRate ? [_vm._v("%")] : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.isShowEmpty
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticClass: "flex flex-center",
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 }
                },
                [
                  _c("el-empty", {
                    attrs: { "image-size": 200, description: "请选择渠道码" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }