var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "抽奖样式：", prop: "lottery_type" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.form.lottery_type,
                callback: function($$v) {
                  _vm.$set(_vm.form, "lottery_type", $$v)
                },
                expression: "form.lottery_type"
              }
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("方形")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("圆形")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "活动标题：", prop: "show_title" } },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "15",
              "show-word-limit": "",
              placeholder: "请输入活动标题"
            },
            model: {
              value: _vm.form.show_title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "show_title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.show_title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "封面标题：", prop: "cover_title" } },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "14",
              "show-word-limit": "",
              placeholder: "请输入封面标题"
            },
            model: {
              value: _vm.form.cover_title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "cover_title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.cover_title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "活动规则：", prop: "rule" } },
        [
          _c("ly-editor", {
            staticClass: "act-form__editor",
            attrs: {
              title: "活动规则",
              content: _vm.form.rule,
              recoverContent: _vm.base_rule,
              maxLength: 2000
            },
            on: {
              getContent: _vm.fn_getRule,
              focus: _vm.fn_ruleFocus,
              blur: _vm.fn_ruleBlur
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }