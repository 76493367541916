var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: _vm.refName, staticClass: "act-card" }, [
    _c("div", { staticClass: "act-card__header" }, [
      _c("div", { staticClass: "left" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _vm.$slots.right ? _c("div", { staticClass: "right" }) : _vm._e()
    ]),
    _c("div", { staticClass: "act-card__body" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }