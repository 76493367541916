var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "reward-rule" }, [
        _c("div", { staticClass: "reward-rule__mask" }),
        _c("div", { staticClass: "reward-rule__body" }, [
          _c("div", { staticClass: "reward-rule__box" }, [
            _c("div", { staticClass: "reward-rule__title" }, [
              _vm._v("活动规则")
            ]),
            _c(
              "div",
              { staticClass: "reward-rule__content" },
              [
                _c("el-input", {
                  attrs: { type: "textarea", resize: "none", readonly: "" },
                  model: {
                    value: _vm.rule,
                    callback: function($$v) {
                      _vm.rule = $$v
                    },
                    expression: "rule"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "reward-rule__close" }, [
            _c("i", {
              staticClass: "el-icon-circle-close",
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            })
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }