<!--
 * @Author: zhanln
 * @Date: 2022-03-28 18:30:03
 * @LastEditTime: 2022-04-01 18:33:19
 * @LastEditors: zhanln
 * @Description: 素材列表
-->

<template>
  <div class="page-warp">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page-sizes="pageSizes"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
      class="page-tabel"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          type="primary"
          icon="icon-plus iconfont"
          @click="fn_edit"
          >添加视频号直播</el-button
        >
        <el-tooltip
          content="请先选择要分组的素材"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              round
              plain
              icon="iconfont icon-edit"
              size="medium"
              @click="fn_changeGroupBatch"
              :disabled="selected.length === 0"
              >批量分组</el-button
            ></span
          >
        </el-tooltip>
        <el-tooltip
          content="请先选择要删除的素材"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              round
              plain
              icon="iconfont icon-delete"
              size="medium"
              @click="fn_removeBatch"
              :disabled="selected.length === 0"
              >批量删除</el-button
            ></span
          >
        </el-tooltip>
      </template>
      <!-- 列插槽 -->
      <template #cusTable>
        <div class="el-table" v-if="total">
          <div class="q-bg is-primary fx p-12" style="margin: 0 20px 20px">
            <i
              class="iconfont icon-info-fill q-primary mr-8"
              style="line-height: 1.4"
            ></i>
            <div class="flex-1">
              1.
              员工登录云端账号，保持云端在线，将需要的素材视频号直播发送给云端账号中；<br />
              2.
              将发送的素材在聊天记录中添加到素材库中进行保存，保存的素材无封面展示，但不影响正常发送。
            </div>
          </div>
          <div class="mcard fx flex-wrap">
            <m-card
              v-for="(item, index) of tableData"
              :data="item"
              :index="index"
              :key="index"
              grid="g-4"
              @edit="fn_edit"
              @selected="fn_select"
            >
              <div class="mcard-wrap">
                <div class="mb-12 text-ellipsis pr-24">
                  原创原创原创原创原创原创原创原创原创原创原创原创原创原创
                </div>
                <div class="mcard-bg rd-4 mb-12">
                  <div class="mcard-poster">
                    <el-image :src="item.poster_url" lazy class="mcard-img">
                      <img
                        src="@/assets/images/applet.png"
                        class="mcard-imgerror"
                        slot="error"
                        alt=""
                      />
                    </el-image>
                    <div class="mcard-badge"><span></span>直播</div>
                  </div>
                  <div class="mcard-pagename fx flex-align-center">
                    <div class="fx text-ellipsis flex-1 flex-align-center">
                      <img
                        class="mcard-appicon mr-8"
                        :src="item.poster_url"
                        alt=""
                      />
                      <div class="flex-1 text-ellipsis mr-8">
                        原创原创原创原创原创原创原创原创原创原创原创原创原创原创
                      </div>
                    </div>
                    <i class="iconfont icon-shipinhao q-warning mr-4"></i>
                  </div>
                </div>
              </div>
            </m-card>
          </div>
        </div>
        <template v-if="total === 0">
          <div class="el-table__empty-block">
            <div class="el-table__empty-text">
              <div class="ly-table-empty">
                <img src="@assets/svg/default/no_data.svg" alt="" />
                暂无数据
              </div>
            </div>
          </div>
        </template>
      </template>
    </ly-table>
    <edit ref="editRef" @callback="fn_editCallback"></edit>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { meterialApi } from '../http'
import edit from '../edit'
import mCard from './mCard'
export default {
  name: 'liveList',
  mixins: [lyTable],
  components: {
    edit,
    mCard
  },
  data () {
    return {
      COMM_HTTP: meterialApi,
      per_page: 8,
      pageSizes: [8, 16, 32, 40],
      total: null
    }
  },
  computed: {
    tableOpt () {
      return {
        showSelection: true,
        operationWidth: 180,
        layout: 'sizes, prev, pager, next, jumper',
        column: [
          {
            label: '素材名称',
            prop: 'title',
            search: true,
            showOverflowTooltip: true,
            width: 200,
            placeholder: '请输入素材名称'
          }
        ]
      }
    }
  },

  methods: {

    // 添加素材
    fn_edit ({ id }) {
      this.$refs.editRef.fn_open({
        cid: 1,
        type: 'live',
        id: id
      })
    },

    // 删除素材
    fn_remove ({ id }) {
      this.$lyConfirm({
        title: '确认要删除素材吗？',
        text: '素材删除后将无法找回, 是否继续?',
        confirmButtonText: '删除'
      }).then(async () => {
        console.log('id', id)
      }).catch(() => { })
    },

    // 添加文本回调，刷新tab和列表
    fn_editCallback () {
      this.$emit('updataTab', 'live')
    },

    // 批量分组
    fn_changeGroupBatch () {
      console.log('selected', this.selected)
    },

    // 批量删除
    fn_removeBatch () {

    },

    // 设置 or 取消选中
    fn_select ({ check, index }) {
      this.$set(this.tableData, index, {
        ...this.tableData[index],
        checked: check
      })
      const selectIndex = this.selected.indexOf(this.tableData[index].id)
      if (selectIndex === -1) {
        this.selected.push(this.tableData[index].id)
      } else {
        this.selected.splice(selectIndex, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-warp {
  padding: 0;
  overflow: hidden;

  ::v-deep {
    .xl-ly-table {
      height: 100%;
      overflow: hidden;
    }
  }
}
.page-tabel {
  ::v-deep {
    .xl-ly-search,
    .ly-table-main {
      box-shadow: none;
    }
    .handler-left {
      flex: 1;
    }
    .xl-ly-search {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .ly-table-main {
      height: 100%;
      margin-bottom: 40px;
      padding: 0;
    }

    .xl-ly-setting {
      padding: 0 20px;
    }

    .el-table {
      height: calc(100% - 102px);
      overflow: auto;
    }

    .el-pagination {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100% !important;
    }
  }
}

.mcard {
  padding: 0 6px;
  margin: 0 8px;

  &-bg {
    background-color: #f7f8fc;
    overflow: hidden;
  }

  &-header {
    padding: 8px 12px 4px;
  }

  &-appicon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &-pagename {
    padding: 12px;
  }

  &-poster {
    position: relative;
    padding-bottom: calc(100% * 1.05);
    height: 0;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &-img {
    width: 100%;
    text-align: center;
    background: #e6e6e6;
  }

  &-imgerror {
    width: 70% !important;
  }

  &-badge {
    position: absolute;
    background-color: #ff4842;
    left: 10px;
    top: 10px;
    color: #fff;
    padding: 0 12px 0 8px;
    border-radius: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    box-shadow: 0px 2px 4px 0px rgba(#ff4842, 0.6);

    span {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid #fff;
      position: relative;
      margin-right: 4px;

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
