/*
 * @Author: zhanln
 * @Date: 2021-07-26 14:58:55
 * @LastEditTime: 2022-09-06 17:51:10
 * @LastEditors: zhan01lb zhanlienan@01lb.com.cn
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

// 活动
export const activity = {
  save: params => axios.post('/wecom/book-task/save', params),
  saveDraft: params => axios.post('/wecom/book-task/save-draft', params),
  detail: params => axios.get(`/wecom/book-task/show?${stringify(params)}`),
  reqList: () => axios.get('/wecom/book-task/select')
}

// 活动列表
export const list = {
  reqQuery: params => axios.get(`/wecom/book-task/list?${stringify(params)}`),
  changeShow: params => axios.post('/wecom/book-task/change-show', params), // 预约有礼-修改首页展示
  stop: params => axios.post('/wecom/book-task/pause', params), // 预约有礼-暂停活动
  start: params => axios.post('/wecom/book-task/run', params), // 预约有礼-开启活动
  end: params => axios.post('/wecom/book-task/fold-up', params), // 预约有礼-结束活动
  delete: params => axios.post('/wecom/book-task/destroy', params) // 预约有礼-删除活动
}

// 授权信息
export const auth = {
  authMpInfo: params => axios.get('/wecom/open-platform/auth-mp-info')
}

// 草稿箱
export const drafts = {
  reqQuery: params => axios.get(`/wecom/book-task/draft-list?${stringify(params)}`), // 查询表格数据的接口
  reqDelete: params => axios.post('/wecom/book-task/destroy', params) // 删除行数据
}
// 参与用户
export const user = {
  reqQuery: params => axios.get(`/wecom/book-task/account-list?${stringify(params)}`),
  export: params => axios.get(`/wecom/book-task/account-export?${stringify(params)}`),
  channelSel: params => axios.get(`/wecom/book-task/channel-list?${stringify(params)}`) // 参与用户/黑名单 渠道下拉选择项
}

export const popularize = {
  reqQuery: params => axios.get(`/wecom/book-task/channel-list?${stringify(params)}`),
  create: params => axios.post('/wecom/book-task/channel-create', params), // 创建渠道
  update: params => axios.post('/wecom/book-task/channel-update', params), // 更新渠道
  delete: params => axios.post('/wecom/book-task/channel-delete', params) // 删除渠道
}

// 奖品记录
export const reward = {
  reqQuery: params => axios.get(`/wecom/book-task/reward-list?${stringify(params)}`),
  resend: params => axios.post('/wecom/book-task/resend-reward', params), // 重新发放
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`) // 获取单个表单组件
}

export const reserve = {
  reqQuery: params => axios.get(`/wecom/book-task/reserve-list?${stringify(params)}`), // 预约有礼-预约记录列表
  export: params => axios.get(`/wecom/book-task/reserve-export?${stringify(params)}`) // 预约有礼-导出预约记录列表
}

// 活动阶段下拉数据
export const stepsApi = {
  reqQuery: params => axios.get(`/wecom/book-task/step-select?${stringify(params)}`)
}
