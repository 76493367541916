import axios from '@/common/ajax.js'
export const listApi = {
  reqQuery: params => axios.post('/scrm-api/welcome/content/list', params),
  delete: id => axios.delete(`/scrm-api/welcome/content/delete/${id}`) // 删除欢迎语
}

export const editApi = {
  urlInfo: params => axios.get('/wecom/system/urlInfo', { params }), // 通过url获取信息
  detail: id => axios.get(`/scrm-api/welcome/content/${id}`), // 欢迎语详情
  create: params => axios.post('/scrm-api/welcome/content/create', params), // 创建欢迎语
  update: params => axios.put(`/scrm-api/welcome/content/update/${params.id}`, params) // 修改欢迎语
}
