<!--
 * @Author: zhanln
 * @Date: 2021-09-13 11:14:00
 * @LastEditTime: 2021-12-15 17:04:38
 * @LastEditors: Please set LastEditors
 * @Description:
-->

<template>
  <div class="tag" v-loading="pageLoading">
    <!-- 搜索 -->
    <div class="tag-search">
      <!-- 搜索 -->
      <el-form
        inline
        :model="searchForm"
        @submit.native.prevent
        class="tag-search__form ly-shadow"
      >
        <el-form-item label="标签组/标签：">
          <el-input
            v-model="searchForm.filterText"
            clearable
            placeholder="请输入标签组/标签"
            @keyup.enter.native="fn_getFilter"
            @clear="fn_resetList"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            round
            type="primary"
            @click="fn_getFilter"
            icon="iconfont icon-search"
            >查询</el-button
          >
          <el-button round @click="fn_resetList" icon="iconfont icon-data"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table-main ly-shadow">
      <!-- 功能 -->
      <div class="mb-16">
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          @click="fn_addGroup"
          >添加标签组</el-button
        >
        <el-button
          round
          icon="iconfont icon-data"
          @click="fn_tagSync"
          :loading="freshLoading"
          >{{ freshLoading ? "同步中..." : "同步标签" }}</el-button
        >
      </div>
      <!-- 表头 -->
      <div class="tag-header tag-flex">
        <div class="item tag-group">标签组名称</div>
        <div class="item tag-list">标签</div>
        <div class="item tag-tool">操作</div>
      </div>
      <!-- 列表 -->

      <!-- 搜索为空 -->
      <template v-if="searchLen === 0">
        <div class="tag-search__empty" v-if="isCustomerSeach">
          <img src="@assets/svg/default/no_res.svg" alt="" />
          <span>无搜索结果</span>
        </div>
        <div class="tag-search__empty" v-else>
          <img src="@assets/svg/default/no_data.svg" alt="" />
          <span>暂无数据</span>
        </div>
      </template>
      <div class="tag-container" v-else>
        <el-scrollbar class="tag-scroll">
          <template v-for="(g, gkey) in tagsData">
            <div class="tag-container__item tag-flex" :key="gkey" v-if="g.show">
              <div class="item tag-group">{{ g.tagGroupName }}</div>
              <div class="item tag-list">
                <template v-for="(t, tkey) in g.tagList">
                  <div class="qy-tag qy-tag--medium" :key="tkey" v-if="t.show">
                    {{ t.tagName }}
                  </div>
                </template>
              </div>
              <div class="item tag-tool">
                <el-button
                  type="text"
                  icon="iconfont icon-edit"
                  @click="fn_editTag(g)"
                  >编辑</el-button
                >
              </div>
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <!-- 编辑标签 -->
    <tag-edit
      ref="tagEditRef"
      @confirm="fn_confirm"
      @close="fn_confirm"
    ></tag-edit>
  </div>
</template>

<script>
import { tagApi } from './http'
import tagEdit from '@components/wecomTags/edit'
export default {
  data () {
    return {
      COMM_HTTP: tagApi,
      pageLoading: true,
      searchForm: {
        filterText: ''
      },
      msgId: '',
      tagsData: null,
      searchLen: 0,
      freshLoading: false,
      reloadTime: null,
      tagChecked: '390, 391, 392, 440',
      isCustomerSeach: false
    }
  },

  components: {
    tagEdit
  },

  created () {
    this.fn_getListSync()
    setTimeout(() => {
      this.tagChecked = '390'
    }, 1000)
  },

  methods: {

    // 获取标签列表
    async fn_getListSync () {
      this.pageLoading = true
      const data = await this.COMM_HTTP.reqQuery()

      if ((data && data.code) || (data && data.code === 0)) {
        this.pageLoading = false
        this.fn_lyMsg('info', data.msg)
        return false
      }

      if (data.length === 0) {
        this.searchLen = 0
      } else {
        this.tagsData = this.fn_initTags(data)
        this.searchLen = data.length
      }

      this.pageLoading = false
      this.freshLoading = false
    },

    // 数据显示处理
    fn_initTags (data) {
      return data.map(item => {
        item.show = true
        item.tagList = item.tagList.map(item => {
          item.show = true
          return item
        })
        return item
      })
    },

    // 同步
    async fn_tagSync () {
      this.freshLoading = true

      const msgId = this.msgId
      const data = await this.COMM_HTTP.tagSync({
        params: {
          msgId: msgId
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.freshLoading = false
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.msgId = data.msgId

      if (data.status === 0) {
        this.fn_lyMsg('success', '标签同步完成！')
        this.freshLoading = false
        this.msgId = ''
        this.searchForm.filterText = ''
        this.fn_getListSync()
      } else {
        this.reloadTime = setTimeout(() => {
          this.fn_tagSync()
        }, 1000)
      }
    },

    // 模糊查询
    fn_getFilter () {
      this.isCustomerSeach = true
      if (!this.tagsData) return false

      const txt = this.searchForm.filterText

      if (!txt) {
        this.searchLen = this.tagsData.length
        this.fn_resetList()
        return false
      }

      this.searchLen = 0

      for (let i = 0; i < this.tagsData.length; i++) {
        const ITEM = this.tagsData[i]
        const GROUPNAME = ITEM.tagGroupName
        if (GROUPNAME.indexOf(txt) > -1) {
          ITEM.show = true
          ITEM.tagList = ITEM.tagList.map(item => {
            item.show = true
            return item
          })
        } else {
          ITEM.show = false
          let len = 0
          for (let j = 0; j < ITEM.tagList.length; j++) {
            const TAG = ITEM.tagList[j]
            const NAME = TAG.tagName
            if (NAME.indexOf(txt) > -1 || !txt) {
              len++
              TAG.show = true
            } else {
              TAG.show = false
            }
          }

          ITEM.show = len > 0
        }

        if (ITEM.show) {
          this.searchLen++
        }
      }
    },

    // 重置搜索
    fn_resetList () {
      this.searchForm.filterText = ''
      this.tagsData = this.fn_initTags(this.tagsData)
      this.searchLen = this.tagsData.length
    },

    // 添加标签组
    fn_addGroup () {
      this.$refs.tagEditRef.visible = true
    },

    // 编辑
    fn_editTag (g) {
      this.$refs.tagEditRef.type = 'edit'
      const list = this.util.extend(true, {}, g)

      for (const i in list.tagList) {
        list.tagList[i].error = ''
      }
      this.$refs.tagEditRef.form = list
      this.$refs.tagEditRef.visible = true
    },

    // 关闭弹框
    fn_confirm () {
      this.fn_getListSync()
    }
  },

  destroyed () {
    if (this.reloadTime) {
      clearTimeout(this.reloadTime)
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  overflow-y: scroll;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  &-search {
    &__form {
      background: #fff;
      padding: 20px 20px 0 20px;
      border-radius: 16px;
      ::v-deep {
        .el-form-item__label {
          padding: 0;
        }
        .el-form-item {
          margin-right: 20px;
        }
      }
    }

    &__empty {
      display: flex;
      height: 260px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #6E788A;
      img{
        margin-bottom: 8px;
      }
    }
  }
  .table-main {
    background: #fff;
    padding: 20px;
    border-radius: 16px;
    margin: 20px 0 0;
    flex: 1;
    overflow: hidden;
  }
  &-flex {
    display: flex;
    align-items: center;

    .item {
      padding: 0 16px;
      min-height: 48px;
      display: flex;
      align-items: center;
      &:first-of-type {
        padding-left: 24px;
      }
      &:last-of-type {
        padding-right: 24px;
      }
    }
  }

  &-group {
    width: 25%;
    padding-left: 24px;
    color: #212b36;
  }

  &-list {
    flex: 1;
    flex-wrap: wrap;
  }

  &-tool {
    .el-button {
      font-weight: normal;
      padding: 0;
    }
  }

  &-header {
    background-color: #f7f8fc;
    color: #212b36;
    font-weight: 500;
    box-sizing: border-box;
    border-radius: 8px;
  }

  &-container {
    height: calc(100% - 96px);
    overflow: hidden;

    &__item {
      align-items: flex-start;
      border-bottom: 1px solid #eaebf2;
      transition: background-color 0.25s ease;

      .item {
        padding: 16px;
        min-height: auto;
      }

      .tag-list {
        padding-bottom: 8px;
      }

      &:hover {
        background-color: rgba(43, 124, 255, 0.06);
      }
    }
  }

  .tag-scroll {
    height: 100%;
  }

  .qy-tag {
    background-color: #f7f8fc;
    margin-bottom: 8px;
  }
}
</style>
