var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mapAppId === _vm.metaId
    ? _c(
        "div",
        { staticClass: "edit-footer" },
        [
          _c("div", { staticClass: "item left" }, [
            _vm.showTips
              ? _c("div", { staticClass: "buy-tips flex" }, [_vm._m(0)])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "item center" },
            [
              _vm.buyType
                ? [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          value: _vm.showTool,
                          manual: true,
                          placement: "top-end",
                          "popper-class": "is-white radius16"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "246px" },
                            attrs: { slot: "content" },
                            slot: "content"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-center",
                                staticStyle: {
                                  height: "53px",
                                  "margin-bottom": "12px"
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: { "margin-right": "24px" },
                                  attrs: {
                                    src: require("@assets/svg/wallet_icon.svg"),
                                    alt: ""
                                  }
                                }),
                                _c("div", [
                                  _c("b", [_vm._v("温馨提示：")]),
                                  _vm._v(
                                    "您还未购买群裂变，此页面仅供浏览体验呦～ "
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-justify-end" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "cus-mini",
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showTool = false
                                      }
                                    }
                                  },
                                  [_vm._v("知道了")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { round: "", type: "warning" },
                            on: { click: _vm.fn_showPay }
                          },
                          [_vm._v("立即购买")]
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _vm.canSaveDraft
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              icon: "iconfont icon-edit",
                              loading: _vm.btnLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("saveDraft")
                              }
                            }
                          },
                          [_vm._v("保存为草稿")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          round: "",
                          type: "primary",
                          icon: _vm.isEnd ? "" : "iconfont icon-send-fill",
                          loading: _vm.btnLoading,
                          disabled: _vm.isEnd && !_vm.isCopy
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("submit")
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.isEnd && !_vm.isCopy ? "活动已结束" : "发布活动"
                          )
                        )
                      ]
                    )
                  ]
            ],
            2
          ),
          _c("div", { staticClass: "item" }),
          _c("ly-pay", {
            attrs: {
              payDialogVisible: _vm.payDialogVisible,
              title: _vm.payTitle,
              appTitle: "群裂变",
              payType: _vm.payType
            },
            on: {
              "update:payDialogVisible": function($event) {
                _vm.payDialogVisible = $event
              },
              "update:pay-dialog-visible": function($event) {
                _vm.payDialogVisible = $event
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "anim flex" }, [
      _c("img", {
        staticStyle: { "margin-right": "8px" },
        attrs: { src: require("@assets/svg/notice_icon.svg"), alt: "" }
      }),
      _c("b", [_vm._v("温馨提示：")]),
      _vm._v("您还未购买群裂变，此页面仅供浏览体验呦～ ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }