<template>
  <div class="page-warp welcome-word-list">
    <ly-table
      class="act-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :page-size="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          type="primary"
          round
          icon="iconfont icon-plus"
          size="medium"
          @click="$router.push('editWelcome')"
          >新增欢迎语</el-button
        >
      </template>
      <!-- 列插槽 -->
      <template #userIdList="{ row }">
        <ly-drow :list="getUserIdList(row.userList)"></ly-drow>
      </template>
      <template #updateTimeTh>
        <span style="margin-right: 4px">更新时间</span>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">
            最新编辑（创建）时间
          </div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>
      <template #updateTime="{ row }">
        <span>{{ row.updateTime ? row.updateTime.substr(0, 16) : '-' }}</span>
      </template>
      <template #operation="{ row }">
        <el-button type="text" @click="$router.push({ path: 'editWelcome', query: { id: row.id } })">编辑</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" @click="handleDelete(row.id)" @keydown.enter.native.prevent>删除</el-button>
      </template>
    </ly-table>
  </div>
</template>
<script>
import mx_role from '@/components/mixins/role'
import lyTable from '@/components/mixins/ly-table.js'
import lyDrow from '@/components/lyDrow/index.vue'
import { listApi } from './http.js'
export default {
  mixins: [lyTable, mx_role],
  components: {
    lyDrow
  },
  data () {
    return {
      COMM_HTTP: listApi,
      DATA_LOCATION: 'records'
    }
  },
  computed: {
    tableOpt () {
      return {
        column: [
          {
            label: '欢迎语名称',
            prop: 'welcomeContentName',
            search: true,
            placeholder: '请输入欢迎语'
          },
          {
            label: '使用成员',
            prop: 'userIdList',
            slot: 'userIdList',
            search: true,
            key: 'userIdStr',
            type: 'emp'
          },
          {
            label: '更新时间',
            prop: 'updateTime',
            slot: 'updateTime',
            headerSlot: 'updateTimeTh'
          }
        ]
      }
    }
  },
  mounted () {
  },
  methods: {
    /**
     * @description: 格式化成员列表
     * @param {*} arr
     * @return {*}
     */
    getUserIdList (arr) {
      return arr.map(e => e.userName)
    },
    /**
     * @description: 删除回调
     * @param {*} id
     * @return {*}
     */
    handleDelete (id) {
      this.$lyConfirm({
        text: '确定要删除该条欢迎语吗？',
        title: '删除欢迎语'
      }).then(() => {
        this.COMM_HTTP.delete(id).then(res => {
          if (!res) {
            this.$message.success('删除欢迎语成功')
            this.queryData()
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
