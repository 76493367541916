var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-card" }, [
    _c("div", { staticClass: "edit-card__header" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _c("div", { staticClass: "edit-card__content" }, [
      _c("div", { staticClass: "reward-tab" }, [
        _c("div", { staticClass: "reward-tab__header" }, [
          _c(
            "div",
            { staticClass: "reward-tab__menu" },
            _vm._l(_vm.tabs, function(item) {
              return _c(
                "div",
                {
                  key: item.order,
                  class: [
                    "item",
                    item.active ? "active" : "",
                    item.disable ? "is-disable" : ""
                  ],
                  on: {
                    click: function($event) {
                      return _vm.fn_changeTab(item.order, item.disable)
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(item.label) + " "),
                  [
                    _vm._v(
                      " · " + _vm._s(item.status === 1 ? item.count : "未开启")
                    )
                  ]
                ],
                2
              )
            }),
            0
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "reward-pane" },
        [
          _c("reward-form", {
            ref: "rewardCard",
            attrs: { order: _vm.activeOrder }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }