<!--
 * @Author: zhanln
 * @Date: 2021-06-04 21:33:45
 * @LastEditTime: 2022-08-29 14:44:00
 * @LastEditors: zhan
 * @Description: table 内容超出高度显示展开收起
-->

<template>
  <div class="ly-dorw" ref="dorwRef">
    <div class="tag_over" v-if="list && list.length">
      <template v-if="isSort">
        <template v-for="(item, index) in list.slice(0, size)">
          <ly-tag :content="name ? item[name] : item" :key="index" :only="false" :dep="dep"
            :avatar="avatarField ? item[avatarField] : item.avatar_url" :size="textSize"><template slot="right">
              <slot name="right">
                <template v-if="right">
                  <span :class="[
                    'ly-dorw_right',
                    item[right] === 1 ? 'q-success' : 'q-info',
                  ]" v-if="rightType">{{ item[right] === 0 ? "(离线)" : "(在线)" }}</span>
                  <template v-else>{{ right }}</template>
                </template>
              </slot>
            </template></ly-tag>
        </template>
      </template>
      <template v-else>
        <template v-for="(item, index) in list">
          <ly-tag :content="name ? item[name] : item" :key="index" :only="false" :dep="dep"
            :avatar="avatarField ? item[avatarField] : item.avatar_url" :size="textSize"><template slot="right">
              <slot name="right">
                <template v-if="right">
                  <span :class="[
                    'ly-dorw_right',
                    item[right] === 1 ? 'q-success' : 'q-info',
                  ]" v-if="rightType">{{ item[right] === 0 ? "(离线)" : "(在线)" }}</span>
                  <template v-else>{{ right }}</template>
                </template>
              </slot>
            </template></ly-tag>
        </template>
      </template>
      <template v-if="list.length > size">
        <span class="ly-dorw_btn ly-dorw_down" @click="isSort = !isSort">{{ isSort ? "展开" : "收起"
        }}<i class="iconfont icon-up" :style="{ transform: isSort ? 'rotate(180deg)' : 'unset' }"></i></span>
      </template>
    </div>
    <template v-else>{{ empty }}</template>
  </div>
</template>

<script>
import lyTag from '@/components/lyTag'
export default {
  name: 'lyDrow',
  components: {
    lyTag
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 4
    },
    textSize: {
      type: Number,
      default: 6
    },
    dep: {
      type: Boolean,
      default: false
    },
    empty: {
      type: String,
      default: '-'
    },
    right: {
      type: String,
      default: ''
    },
    rightType: {
      type: Boolean,
      default: false
    },
    avatarField: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isSort: true
    }
  },
  watch: {
    isSort (val) {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-dorw {
  &_item {
    overflow: hidden;

    &.show {
      height: auto !important;
    }
  }

  &_btn {
    cursor: pointer;
    color: #454D5B;
    margin-left: 8px;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    margin-bottom: 8px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
  }

  &_right {
    margin-left: 2px;
  }
}
</style>
