var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "msg-temp" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _vm._v(_vm._s(_vm.title) + " "),
        _c(
          "el-dropdown",
          {
            attrs: { placement: "bottom" },
            on: { command: _vm.handleCommand }
          },
          [
            _c("i", { staticClass: "el-icon-more" }),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "msg-handler",
                attrs: { slot: "dropdown" },
                slot: "dropdown"
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    attrs: {
                      command: "edit",
                      disabled: _vm.disabled && _vm.disabled.includes("edit")
                    }
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    staticClass: "up",
                    attrs: {
                      command: "up",
                      disabled: _vm.disabled && _vm.disabled.includes("up")
                    }
                  },
                  [_vm._v("上移 ")]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    staticClass: "up",
                    attrs: {
                      command: "down",
                      disabled: _vm.disabled && _vm.disabled.includes("down")
                    }
                  },
                  [_vm._v("下移 ")]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: {
                      command: "remove",
                      disabled: _vm.disabled && _vm.disabled.includes("remove")
                    }
                  },
                  [_vm._v("移除")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.msg.messageType === 4
      ? _c("div", { staticClass: "content flex" }, [
          _vm._v(" " + _vm._s(_vm.msg.welcomeContent) + " ")
        ])
      : _vm._e(),
    _vm.msg.messageType === 1
      ? _c(
          "div",
          { staticClass: "content flex" },
          [
            _c("el-image", {
              staticStyle: { height: "90px" },
              attrs: { src: _vm.msg.imageUrl, fit: "contain" }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.msg.messageType === 2
      ? _c("div", { staticClass: "link" }, [
          _c("div", { staticClass: "link-title" }, [
            _vm._v(_vm._s(_vm.msg.linkTitle))
          ]),
          _c(
            "div",
            { staticClass: "link-bottom" },
            [
              _c("div", { staticClass: "link-desc" }, [
                _vm._v(_vm._s(_vm.msg.linkDsc))
              ]),
              _c(
                "el-image",
                {
                  staticStyle: { width: "48px", height: "48px" },
                  attrs: { src: _vm.msg.linkPicUrl, fit: "contain" }
                },
                [
                  _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                    _c("img", {
                      staticStyle: { width: "48px", height: "48px" },
                      attrs: { src: require("@/assets/svg/linkDefault.svg") }
                    })
                  ])
                ]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.msg.messageType === 3
      ? _c(
          "div",
          { staticClass: "applet", staticStyle: { width: "246px" } },
          [_c("app-poster", { attrs: { applet: _vm.msg } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }