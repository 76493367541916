<!--
 * @Author: zhanln
 * @Date: 2021-11-18 16:34:53
 * @LastEditTime: 2022-01-06 10:56:42
 * @LastEditors: zhanln
 * @Description: 预览 - 信息通知
-->

<template>
  <ly-mobile :msgList="msgList" :showWel="false"></ly-mobile>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileNotice',

  data () {
    return {
      msgList: [
        {
          type: 'text',
          content: ''
        }
      ]
    }
  },

  computed: {
    ...mapState({
      help_msg: state => state.lottery.help_msg
    })
  },

  watch: {
    help_msg (val) {
      this.msgList[0].content = val
    }
  },

  mounted () {
    this.msgList[0].content = this.$store.state.lottery.help_msg
  }
}
</script>
