<!--
 * @Descripttion: 用户详情红包记录列表
 * @version: 1.0.0
-->
<template>
  <ly-table
    ref="lyTable"
    :data="tableData"
    :option="tableOpt"
    :page="page"
    :pageSize="per_page"
    :total="total"
    @searchChange="searchChange"
    @sizeChange="handleSizeChange"
    @currentChange="handleCurrentChange"
    @selectionChange="handleSelectionChange"
  >
    <template #handler>
      <span class="rd-count"
        >共发放 {{ total }} 个红包<el-divider direction="vertical"></el-divider
        ><span class="fail"
          >失败 <span style="color: #2b7cff">{{ sendFailNum }}</span> 个</span
        ></span
      >
    </template>
    <template #send_code_slot="{ row }">
      <ly-status v-if="row.send_code === 0" type="success">发放成功</ly-status>
      <ly-status v-else type="danger">
        <el-tooltip
          effect="dark"
          :content="
            row.send_code === 1
              ? '当前用户未实名'
              : '异常错误，如果操作重新发放后，也无法正常发放，请联系平台客服'
          "
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        发放失败
      </ly-status>
    </template>
    <template #operation="{ row }">
      <el-button
        type="text"
        :disabled="row.send_code === 0"
        @click="handleReSend(row)"
        >重新发放</el-button
      >
    </template>
  </ly-table>
</template>
<script>
import { userRedRecord } from '@/pages/client/redenvelope/http.js'
import lyTable from '@/components/mixins/ly-table.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: userRedRecord
    }
  },
  computed: {
    tableOpt () {
      return {
        levitate: false,
        layout: 'sizes, prev, pager, next',
        tableProps: {
          height: 360
        },
        column: [
          {
            label: '红包金额',
            prop: 'total_amount'
          },
          {
            label: '红包到账金额',
            prop: 'get_amount'
          },
          {
            label: '服务费',
            prop: 'handing_amount'
          },
          {
            label: '发放时间',
            prop: 'give_date',
            sortable: true
          },
          {
            label: '发放状态',
            prop: 'send_code',
            slot: 'send_code_slot'
          }
        ]
      }
    },
    sendFailNum () {
      return (this.listData && this.listData.expand && this.listData.expand.send_fail_num) || 0
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.$route.query.id,
        external_user_id: this.$attrs.external_user_id
      }
    },
    async handleReSend ({ record_id }) {
      const res = await this.COMM_HTTP.packetSendAgain({ record_id })
      if (res.send_success) {
        this.$message.success('发送成功！')
        this.queryData()
      } else {
        this.$message.error('发送失败！')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .handler-left {
    color: #212b36;
  }
  .el-divider {
    top: -1px;
  }
  .fail {
    font-weight: normal;
  }
  .el-icon-warning {
    font-size: 16px;
    color: #faad14;
  }
}
</style>
