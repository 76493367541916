var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile" }, [
    _c("div", { staticClass: "mobile-topbar" }, [
      _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
      _vm._m(0)
    ]),
    _vm._m(1),
    _c("div", { staticClass: "mobile-container" }, [
      _c("div", { staticClass: "mobile-contact" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "mobile-contact__dialog" },
          [
            _c("el-input", {
              ref: "refRule",
              attrs: {
                type: "textarea",
                autosize: "",
                resize: "none",
                readonly: ""
              },
              model: {
                value: _vm.basic.welcome_msg,
                callback: function($$v) {
                  _vm.$set(_vm.basic, "welcome_msg", $$v)
                },
                expression: "basic.welcome_msg"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mobile-contact" }, [
        _vm._m(3),
        _c("div", { staticClass: "mobile-contact__dialog" }, [
          _c("div", { staticClass: "link-preview" }, [
            _c("div", { staticClass: "ellipsis2 link-preview__title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.basic.link_type === 2
                      ? _vm.basic.link_title
                      : "点击链接立即领取红包👇"
                  ) +
                  " "
              )
            ]),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-between flex-center link-preview__content"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ellipsis2",
                    staticStyle: { flex: "1", "font-size": "14px" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.basic.link_type === 2
                            ? _vm.basic.link_desc
                            : "红包实时到账微信零钱"
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "link-preview__cover" },
                  [
                    _vm.basic.link_type === 2
                      ? [
                          _vm.basic.link_logo.length > 0
                            ? _c("img", {
                                attrs: {
                                  src: _vm.basic.link_logo[0].url,
                                  alt: ""
                                }
                              })
                            : _vm._e()
                        ]
                      : _c("img", {
                          attrs: {
                            src:
                              "https://image.01lb.com.cn//uploads/wecom/21/1008/1633687472W18cbF1A.png",
                            alt: ""
                          }
                        })
                  ],
                  2
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "iconfont icon-a-CellularConnection" }),
      _c("i", { staticClass: "iconfont icon-Wifi" }),
      _c("i", { staticClass: "iconfont icon-Battery" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-titlebar" }, [
      _c("i", { staticClass: "iconfont icon-left1" }),
      _c("div", { staticClass: "flex flex-column" }, [
        _c("span", [_vm._v("张三")]),
        _c("span", { staticClass: "text-small" }, [
          _vm._v("零一裂变（深圳）科技有限公司")
        ])
      ]),
      _c("i", { staticClass: "el-icon-more" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-contact__avatar" }, [
      _c("img", {
        attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-contact__avatar" }, [
      _c("img", {
        attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }