<template>
  <list type="announcement"></list>
</template>

<script>
import list from '../batchMessage/list.vue'
export default {
  components: {
    list
  }
}
</script>
