<!--
 * @Author: zhan
 * @Date: 2022-08-01 11:43:33
 * @LastEditTime: 2022-09-16 19:05:11
 * @LastEditors: zhanln
-->
<template>
  <div v-loading="cardLoading">
    <div class="reward-tab mb-24">
      <div
        :class="['reward-tab__item', activeTab === index ? 'active' : '']"
        v-for="(item, index) of tabList"
        :key="index"
        @click="fn_changeTab(index)"
      >
        {{ item.title }}

        <span class="circle"></span>{{ item.count }}人
        <div class="reward-tab__remove">
          <i
            class="el-icon-remove"
            v-if="canRemoveTab(index)"
            @click.stop="fn_removeTab(index)"
          ></i>
        </div>
      </div>

      <div
        class="reward-tab__item reward-tab__add"
        @click="fn_addTab"
        v-if="canAddTab"
      >
        <i class="el-icon-plus"></i>
      </div>
    </div>

    <reward-item ref="rewardItemRef"></reward-item>

    <!-- 无库存规则 -->
    <stock ref="stockRef"></stock>
  </div>
</template>

<script>
import rewardItem from './item'
import stock from './stock'
// store
import { mapState } from 'vuex'
export default {
  name: 'reward',

  components: {
    rewardItem,
    stock
  },

  inject: ['fn_setStore'],

  data () {
    return {
      cardLoading: true,
      tabList: [],
      activeTab: 0,
      baseReward: {
        id: 0,
        reward_number: 1,
        target_count: 2,
        reward_title: '',
        reward_cover: '',
        virtual_count: 1000,
        reward_type: 1,
        reward_type_id: null,
        reward_detail: '',
        reward_detail_code: '',
        reward_detail_img: '',
        total: 9999,
        show_stock: 1
      },
      rewards: [null]
    }
  },

  computed: {
    ...mapState({
      eType: state => state.reserveTask.eType,
      target_count_0: state => state.reserveTask.target_count_0,
      target_count_1: state => state.reserveTask.target_count_1,
      target_count_2: state => state.reserveTask.target_count_2
    }),
    canRemoveTab () {
      return function (index) {
        return this.eType !== 1 && index === (this.tabList.length - 1) && index !== 0
      }
    },
    canAddTab () {
      return this.eType !== 1 && this.tabList.length < 3
    }
  },

  watch: {
    target_count_0 (val) {
      if (this.tabList[0]) {
        this.tabList[0].count = val
      }
    },
    target_count_1 (val) {
      if (this.tabList[1]) {
        this.tabList[1].count = val
      }
    },
    target_count_2 (val) {
      if (this.tabList[2]) {
        this.tabList[2].count = val
      }
    },
    tabList: {
      handler (val) {
        this.fn_setStore('rewards', val.length)
      }
    }
  },

  methods: {
    fn_setForm (data) {
      if (data) {
        const rewards = this.util.extend(true, [], data.rewards)
        for (let i = 0; i < rewards.length; i++) {
          const item = rewards[i]
          this.fn_setTabs(i, item.target_count)
          this.fn_setStore(`target_count_${i}`, item.target_count)
          this.fn_setStore(`reward_title_${i}`, item.reward_title)
          this.fn_setStore(`reward_cover_${i}`, item.reward_cover)
          this.fn_setStore(`total_${i}`, item.total)
          this.fn_setStore(`show_stock${i}`, item.show_stock)
          this.fn_setStore(`reward_type_${i}`, item.reward_type)
          this.fn_setStore(`virtual_count_${i}`, item.virtual_count)
          if (item.reward_type === 2) {
            item.reward_detail_code = item.reward_detail
            item.reward_detail = ''
            item.reward_type_id = item.reward_type_id * 1
            this.fn_setStore(`reward_detail_code_${i}`, item.reward_detail_code)
          } else {
            this.fn_setStore(`reward_detail_${i}`, item.reward_detail)
          }
          if (this.eType === 2) {
            item.reward_type_id = null
          }
        }
        this.rewards = rewards

        this.$refs.stockRef.fn_setForm(data.stock)
      } else {
        this.fn_setTabs(0, 2)
      }

      this.fn_setItem(0)

      this.$nextTick(() => {
        this.cardLoading = false
      })
    },

    fn_setMock () {
      this.rewards[0] = {
        id: 0,
        reward_number: 1,
        target_count: 3,
        reward_title: '123',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0810/1660115602x3I1uT9i.png',
        virtual_count: 123,
        reward_type: 1,
        reward_type_id: 65,
        reward_detail: '1233',
        reward_detail_img: 'https://image.01lb.com.cn//uploads/wecom/22/0810/1660115602x3I1uT9i.png',
        total: 1234,
        give_num: 100
      }
    },

    async fn_changeTab (index, valid = true, getform = true) {
      if (this.activeTab === index) return

      if (valid) {
        const validItem = await this.$refs.rewardItemRef.verify()

        if (!validItem) return
      }

      if (getform) {
        // 保存当前阶段
        this.fn_getItem()
      }

      // 初始化跳转阶段
      this.fn_setItem(index, valid)

      this.activeTab = index

      this.$store.commit('reserveTask/SET_DATA', {
        name: 'current_step',
        val: index
      })
    },

    fn_getItem () {
      this.rewards[this.activeTab] = this.$refs.rewardItemRef.formData
    },

    fn_setItem (index, valid = false) {
      const reward = this.rewards[index] || { ...this.baseReward }

      // 设置阶段标记及默认任务人数
      if (!this.rewards[index]) {
        reward.reward_number = index + 1
        if (index > 0) {
          reward.target_count = this.rewards[index - 1].target_count === 100 ? 100 : this.rewards[index - 1].target_count + 1
          reward.virtual_count = this.rewards[index - 1].virtual_count
        }
      }
      this.$refs.rewardItemRef.fn_setForm(reward, valid)
    },

    fn_canRemove (index) {
      return this.eType !== 1 && index === (this.tabList.length - 1) && index !== 0
    },

    async fn_addTab () {
      const validItem = await this.$refs.rewardItemRef.verify()

      if (!validItem) return

      const index = this.tabList.length

      this.fn_setTabs(index)

      this.fn_changeTab(index, false)
    },

    fn_setTabs (index, count = 0) {
      const textArr = ['一', '二', '三', '四', '五', '六', '七', '八']
      this.tabList.push({
        title: `${textArr[index]}阶任务`,
        count
      })
    },

    fn_removeTab (index) {
      this.$lyConfirm({
        title: '确定删除此阶段任务吗？',
        text: ''
      }).then(() => {
        this.tabList.splice(index, 1)
        if (this.activeTab === index) {
          this.fn_changeTab(index - 1, false, false)
        }
        this.rewards.splice(index, 1)
        this.$store.commit('reserveTask/RESET_ITEM', index)
      }).catch(() => { })
    },

    async verify () {
      // 校验当前阶段
      const rewardItem = await this.$refs.rewardItemRef.verify()
      if (!rewardItem) {
        return false
      }

      this.fn_getItem()

      const rewards = []
      const validForm = []
      this.rewards.forEach((item, index) => {
        const {
          id,
          reward_number,
          target_count,
          reward_title,
          reward_cover,
          virtual_count,
          reward_type,
          reward_type_id,
          reward_detail,
          reward_detail_code,
          reward_detail_img,
          total,
          show_stock
        } = item

        if ((item.reward_type === 2 && !item.reward_type_id) || (item.total - item.give_num === 0)) {
          validForm.push(index)
        }

        rewards[index] = {
          id,
          reward_number,
          target_count,
          reward_title,
          reward_cover,
          virtual_count,
          reward_type,
          reward_type_id: reward_type === 2 ? reward_type_id + '' : '',
          reward_detail: reward_type === 1 ? reward_detail : reward_detail_code,
          reward_detail_img,
          total,
          show_stock
        }
      })

      if (validForm.length) {
        this.fn_changeTab(validForm[0])
        return false
      }

      return {
        rewards,
        stock: this.$refs.stockRef.form
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/var.scss';

.reward {
  &-tab {
    display: inline-flex;
    border-bottom: 1px solid #dfe1e8;

    &__item {
      position: relative;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 28px;
      background-color: #f7f8fc;
      border-radius: 8px 8px 0 0;
      border: 1px solid #dfe1e8;
      margin-bottom: -1px;
      user-select: none;
      transition: 0.25s;

      .circle {
        display: inline-flex;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #454d5b;
        margin: 0 4px;
      }

      &:not(:first-child) {
        margin-left: 4px;
      }

      &:not(.active, .disable) {
        cursor: pointer;

        &:hover {
          background-color: #fff;
        }
      }

      &.disable {
        cursor: not-allowed;
        background-color: #f7f8fc;
        color: #a2a9b8;

        .circle {
          background-color: #a2a9b8;
        }
      }

      &.active {
        background-color: #fff;
        border-bottom: 1px solid #fff;
        color: $--color-primary;

        .circle {
          background-color: $--color-primary;
        }
      }
    }

    &__remove {
      position: absolute;
      top: -4px;
      right: -4px;
      color: $--color-danger;
      font-size: 16px;
      line-height: 1;
      cursor: pointer;
    }

    &__add {
      font-size: 16px;
      padding: 0 20px;

      &:hover {
        color: $--color-primary;
      }
    }
  }
}
</style>
