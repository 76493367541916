<!--
 * @Author: zhanln
 * @Date: 2021-11-18 16:34:53
 * @LastEditTime: 2022-08-29 14:25:11
 * @LastEditors: zhan
 * @Description: 预览 - 信息通知
-->

<template>
  <ly-mobile :msgList="msgList" :showWel="false"></ly-mobile>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileNotice',

  data () {
    return {
      msgList: [
        {
          type: 'text',
          content: ''
        },
        {
          type: 'image',
          isPoster: true,
          defaultText: '海报图片',
          image: ''
        }
      ]
    }
  },

  computed: {
    ...mapState({
      mode: state => state.reserveTask.mode,
      poster_url: state => state.reserveTask.poster_url,
      welcome_msg: state => state.reserveTask.welcome_msg
    })
  },

  watch: {
    welcome_msg (val) {
      this.msgList[0].content = val
    },
    poster_url (val) {
      this.msgList[1].image = val
    }
  },

  mounted () {
    this.msgList[0].content = this.welcome_msg
    if (this.mode === 1) {
      this.msgList = this.msgList.slice(0, 1)
    } else {
      this.msgList[1].image = this.poster_url
    }
  }
}
</script>
