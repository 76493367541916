var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "normalTable-form-container" }, [
    _c("div", { staticClass: "normalTable-form-body" }, [
      _c("div", { staticClass: "mobile" }, [
        _vm._m(0),
        _c("div", { staticClass: "mobile-titlebar" }, [
          _c("i", {
            staticClass: "el-icon-close",
            staticStyle: { "font-size": "16px" }
          }),
          _c("div", { staticClass: "flex flex-column" }, [
            _vm._v(" " + _vm._s(_vm.formName || "表单名字") + " ")
          ]),
          _c("i", { staticClass: "el-icon-more" })
        ])
      ]),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "normalTable-form-list" },
        [
          _vm._m(2),
          _c("div", { staticClass: "form-info-remarks" }, [
            _vm._v(" 为了保证奖品信息的准确性，请认真填写！ ")
          ]),
          _vm.data.list.length == 0
            ? _c("div", { staticClass: "form-empty" }, [
                _vm._v(" 点击左侧组件创建表单 ")
              ])
            : _vm._e(),
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    on: { end: _vm.MoveEndFn, add: _vm.handlenormalTableAdd },
                    model: {
                      value: _vm.data.list,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "list", $$v)
                      },
                      expression: "data.list"
                    }
                  },
                  "draggable",
                  {
                    group: "formItem",
                    ghostClass: "handleIcon",
                    animation: 200,
                    handle: ".drag-normalTable"
                  },
                  false
                ),
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "normalTable-form-list-c",
                      attrs: { name: "fade", tag: "div" }
                    },
                    [
                      _vm._l(_vm.data.list, function(element, index) {
                        return [
                          element && element.key
                            ? _c("normalTable-form-item", {
                                key: element.key,
                                attrs: {
                                  element: element,
                                  select: _vm.selectnormalTable,
                                  index: index,
                                  data: _vm.data,
                                  selectIndex: _vm.selectIndex
                                },
                                on: {
                                  "update:select": function($event) {
                                    _vm.selectnormalTable = $event
                                  },
                                  "update:selectIndex": function($event) {
                                    _vm.selectIndex = $event
                                  },
                                  "update:select-index": function($event) {
                                    _vm.selectIndex = $event
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-topbar" }, [
      _c("div", [_vm._v("9:41")]),
      _c("div", { staticClass: "flex" }, [
        _c("img", {
          attrs: { src: require("@assets/images/ic_connection.png"), alt: "" }
        }),
        _c("img", {
          attrs: { src: require("@assets/images/ic_wifi.png"), alt: "" }
        }),
        _c("img", {
          attrs: { src: require("@assets/images/ic_power.png"), alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-header" }, [
      _c("img", { attrs: { src: require("@/assets/images/form_header.png") } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-infos" }, [
      _c("img", { attrs: { src: require("@/assets/images/form_infos.png") } }),
      _c("span", { staticClass: "form-infos-text" }, [_vm._v("填写须知")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }