var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-style white" }, [
    _c(
      "div",
      {
        staticClass: "normalTable-form-container",
        staticStyle: { position: "static" }
      },
      [
        _c("div", { staticClass: "mobile" }, [
          _vm._m(0),
          _c("div", { staticClass: "mobile-titlebar" }, [
            _c("i", {
              staticClass: "el-icon-close",
              staticStyle: { "font-size": "16px" }
            }),
            _c("div", { staticClass: "flex flex-column" }, [
              _vm._v(" " + _vm._s(_vm.formName || "我是表单名称") + " ")
            ]),
            _c("i", { staticClass: "el-icon-more" })
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "normalTable-form-body",
            staticStyle: { "margin-bottom": "0", "margin-top": "0" }
          },
          [
            _c("el-scrollbar", { staticStyle: { height: "500px" } }, [
              _c(
                "div",
                {
                  staticClass: "form-header",
                  staticStyle: { margin: "0 auto" }
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/form_header.png") }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "normalTable-form-list" },
                [
                  _c("div", { staticClass: "form-infos" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/images/form_infos.png") }
                    }),
                    _c("span", { staticClass: "form-infos-text" }, [
                      _vm._v("填写须知")
                    ])
                  ]),
                  _c("div", { staticClass: "form-info-remarks" }, [
                    _vm._v(" 为了保证奖品信息的准确性，请认真填写！ ")
                  ]),
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-suffix": ":",
                        model: _vm.models,
                        rules: _vm.rules,
                        "label-position": "top"
                      }
                    },
                    [
                      _vm._l(_vm.data.list, function(item, ind) {
                        return [
                          _c("form-item", {
                            key: item.key,
                            attrs: {
                              models: _vm.models,
                              rules: _vm.rules,
                              normalTable: item,
                              formIndex: ind
                            },
                            on: {
                              "update:models": function($event) {
                                _vm.models = $event
                              }
                            }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-topbar" }, [
      _c("div", [_vm._v("9:41")]),
      _c("div", { staticClass: "flex" }, [
        _c("img", {
          attrs: {
            src: require("@assets/svg/Cellular_Connection.svg"),
            alt: ""
          }
        }),
        _c("img", { attrs: { src: require("@assets/svg/Wifi.svg"), alt: "" } }),
        _c("img", {
          attrs: { src: require("@assets/images/ic_power.png"), alt: "" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }