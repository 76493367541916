import { render, staticRenderFns } from "./activityGroup.vue?vue&type=template&id=61baeb37&scoped=true&"
import script from "./activityGroup.vue?vue&type=script&lang=js&"
export * from "./activityGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61baeb37",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/pc-opt/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61baeb37')) {
      api.createRecord('61baeb37', component.options)
    } else {
      api.reload('61baeb37', component.options)
    }
    module.hot.accept("./activityGroup.vue?vue&type=template&id=61baeb37&scoped=true&", function () {
      api.rerender('61baeb37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/client/groupfission/activityGroup.vue"
export default component.exports