var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popularize page-warp" },
    [
      _c(
        "div",
        { staticClass: "select-box" },
        [
          _c("label", { staticStyle: { color: "rgba(0, 0, 0, 0.85)" } }, [
            _vm._v("选择活动：")
          ]),
          _c(
            "el-select",
            {
              attrs: {
                size: "medium",
                filterable: "",
                placeholder: "请选择活动"
              },
              on: { change: _vm.handleActChange },
              model: {
                value: _vm.activity_id,
                callback: function($$v) {
                  _vm.activity_id = $$v
                },
                expression: "activity_id"
              }
            },
            _vm._l(_vm.activities, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.title, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          events: _vm.handleEvents,
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "iconfont icon-plus"
                    },
                    on: {
                      click: function($event) {
                        _vm.channel_name_disable = false
                        _vm.visible = true
                      }
                    }
                  },
                  [_vm._v("添加渠道")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "link_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "url-box" },
                  [
                    _c("span", { staticClass: "activity_url" }, [
                      _vm._v(_vm._s(row.link_url))
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "16px" },
                        attrs: { round: "", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleCopy(row)
                          }
                        }
                      },
                      [_vm._v("复制")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "qrcode_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "p-qrcode-box" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "200",
                          trigger: "hover"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "poster",
                          attrs: { src: row.poster_url, alt: "" }
                        }),
                        _c(
                          "div",
                          { staticClass: "btn-area" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDownload(row, "poster")
                                  }
                                }
                              },
                              [_vm._v("下载海报")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDownload(row, "qr")
                                  }
                                }
                              },
                              [_vm._v("下载二维码")]
                            )
                          ],
                          1
                        ),
                        _c("i", {
                          staticClass: "iconfont icon-Code",
                          staticStyle: {
                            "font-size": "18px",
                            "margin-right": "10px"
                          },
                          attrs: { slot: "reference" },
                          slot: "reference"
                        })
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleDownload(row, "qr")
                          }
                        }
                      },
                      [_vm._v("下载")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "tags_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-drow", {
                  attrs: { list: _vm.fn_getTagName(row.tags), dep: "" }
                })
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.handleEdit(row)
                      }
                    }
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      disabled: row.status === 2 || row.status === 3
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleDel(row)
                      }
                    }
                  },
                  [_vm._v("删除")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.edit ? "编辑渠道" : "添加渠道",
            visible: _vm.visible,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.popLoading,
                  expression: "popLoading"
                }
              ],
              ref: "form",
              attrs: {
                size: "medium",
                model: _vm.form,
                rules: _vm.rules,
                "show-message": false,
                "label-width": "90px",
                "element-loading-text": _vm.loadingText,
                "element-loading-spinner": "el-icon-loading"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称：", prop: "channel_name" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "20",
                      "show-word-limit": "",
                      disabled: _vm.channel_name_disable,
                      placeholder: "请输入渠道名称"
                    },
                    model: {
                      value: _vm.form.channel_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "channel_name", $$v)
                      },
                      expression: "form.channel_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道标签：" } },
                [
                  _c("tag-select-v2", {
                    staticStyle: { "padding-top": "7px" },
                    attrs: {
                      title: "为本渠道新增用户自动打标签",
                      checked: _vm.form.tags
                    },
                    on: { getTagId: _vm.fn_getAddIds }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "span",
                { staticStyle: { color: "#6e788a", "margin-right": "12px" } },
                [_vm._v("保存后自动生成二维码和链接")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    type: "primary",
                    size: "medium",
                    loading: _vm.popLoading
                  },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }