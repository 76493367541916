<!--
 * @Author: zhanln
 * @Date: 2022-03-25 14:43:59
 * @LastEditTime: 2022-04-18 18:44:18
 * @LastEditors: zhanln
 * @Description: 列表
-->

<template>
  <div class="layout ly-shadow">
    <!-- 分组 -->
    <div class="layout-left">
      <groups
        :data="groupData"
        :current="currentGroupId"
        @change="fn_changeGroup"
        @updataGroups="fn_getData"
      ></groups>
    </div>

    <!-- 素材库 -->
    <div class="layout-right">
      <!-- 素材类型 -->
      <type-tab
        :data="typeList"
        ref="typeTabRef"
        :currentGroupId="currentGroupId"
      ></type-tab>
    </div>
  </div>
</template>

<script>
import groups from './groups.vue'
import typeTab from './tabs.vue'
import { groupApi, meterialApi } from '../http'
export default {
  name: 'list',

  components: {
    groups,
    typeTab
  },

  provide () {
    return {
      fn_changeGroup: this.fn_changeGroup,
      fn_getData: this.fn_getData
    }
  },

  inject: ['category'],

  data () {
    return {
      groupApi: groupApi,
      meterialApi: meterialApi,
      groupData: {
        group: null,
        total: '...'
      },
      currentGroupId: null,
      typeList: [
        {
          label: '文本',
          name: 'text',
          id: 1,
          count: 0
        },
        {
          label: '图片',
          name: 'image',
          id: 2,
          count: 0
        },
        {
          label: '视频',
          name: 'video',
          id: 3,
          count: 0
        },
        {
          label: '文件',
          name: 'file',
          id: 4,
          count: 0
        },
        {
          label: '小程序',
          name: 'applet',
          id: 5,
          count: 0
        },
        {
          label: '文章',
          name: 'article',
          id: 6,
          count: 0
        },
        {
          label: '视频号',
          name: 'vlog',
          id: 7,
          count: 0
        }
        // {
        //   label: '视频号直播',
        //   name: 'live',
        //   id: 8,
        //   count: 0
        // }
      ]
    }
  },

  mounted () {
    this.fn_getData('-1')
  },

  methods: {
    // 分组数据
    async fn_getData (id, type) {
      const data = await this.groupApi.groups({
        category: this.category
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.groupData = data
      if (id !== undefined) {
        this.fn_changeGroup(id, type)
      }
    },

    // 切换分组
    async fn_changeGroup (id, type = 'text') {
      this.currentGroupId = id
      await this.fn_getMeterialStatic(id)
      this.$refs.typeTabRef.fn_getTab(type)
    },

    // 素材分类统计
    async fn_getMeterialStatic (group_id) {
      const data = await this.meterialApi.static({
        category: this.category,
        group_id
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      for (let j = 0; j < this.typeList.length; j++) {
        if (data.length === 0) {
          this.typeList[j].count = 0
        } else {
          this.typeList[j].count = 0
          for (let i = 0; i < data.length; i++) {
            if (data[i].type === this.typeList[j].id) {
              this.typeList[j].count = data[i].count
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.layout {
  height: 100%;
  margin: 20px;
  border-radius: 16px;
  display: flex;
  overflow: hidden;

  &-left {
    width: 240px;
    padding-top: 20px;
  }

  &-right {
    flex: 1;
    background-color: #fff;
    padding-top: 16px;
    width: 100%;
    overflow: hidden;
  }
}
</style>
