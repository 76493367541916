var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-group" },
    [
      _c(
        "div",
        {
          class: [
            "material-group__item",
            _vm.current === "-1" ? "is-active" : ""
          ]
        },
        [
          _c(
            "div",
            {
              staticClass: "name flex-1 text-ellipsis mr-8",
              on: {
                click: function($event) {
                  return _vm.fn_changeGroup("-1")
                }
              }
            },
            [
              _c("span", [_vm._v("全部分组")]),
              _vm._v("（" + _vm._s(_vm.data.total) + "） ")
            ]
          ),
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                trigger: "click",
                disabled: _vm.data.total >= 100,
                "popper-class": "add-group-popover"
              },
              on: {
                show: function($event) {
                  _vm.groupName = ""
                  _vm.groupId = null
                }
              }
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    slot: "reference",
                    effect: "dark",
                    content: "分组上限为100个",
                    disabled: _vm.data.total < 100,
                    placement: "top"
                  },
                  slot: "reference"
                },
                [
                  _c("el-button", {
                    staticClass: "add",
                    attrs: {
                      icon: "iconfont icon-plus",
                      type: "primary",
                      circle: "",
                      size: "mini"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "add-group" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入分组名称",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.groupName,
                      callback: function($$v) {
                        _vm.groupName = $$v
                      },
                      expression: "groupName"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { round: "", size: "small" },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { round: "", size: "small", type: "primary" },
                      on: { click: _vm.handleSave }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-scrollbar",
        { staticClass: "material-group__list" },
        _vm._l(_vm.data.group, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "material-group__item",
                item.group_id === _vm.current ? "is-active" : ""
              ]
            },
            [
              _c(
                "div",
                {
                  staticClass: "name flex-1 text-ellipsis mr-8",
                  on: {
                    click: function($event) {
                      return _vm.fn_changeGroup(item.group_id)
                    }
                  }
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: item.name,
                        disabled: item.name.length < 10,
                        placement: "top-start"
                      }
                    },
                    [
                      _c(
                        "span",
                        [
                          item.name
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.name.length > 10
                                        ? item.name.slice(0, 10) + "..."
                                        : item.name
                                    ) +
                                    " "
                                )
                              ]
                            : [_vm._v("默认分组")]
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              ),
              item.group_id !== "0"
                ? _c(
                    "div",
                    { staticClass: "edit" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-end",
                            trigger: "click",
                            "popper-class": "add-group-popover"
                          }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                slot: "reference",
                                effect: "dark",
                                content: "编辑",
                                placement: "top",
                                enterable: false
                              },
                              slot: "reference"
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-edit",
                                staticStyle: { "margin-right": "8px" },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_handleEdit(item)
                                  }
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "add-group" },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "small",
                                  placeholder: "请输入分组名称",
                                  maxlength: "20",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.groupName,
                                  callback: function($$v) {
                                    _vm.groupName = $$v
                                  },
                                  expression: "groupName"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { round: "", size: "small" },
                                  on: { click: _vm.handleCancel }
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    round: "",
                                    size: "small",
                                    type: "primary"
                                  },
                                  on: { click: _vm.handleSave }
                                },
                                [_vm._v("确定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              item.amount > 0
                                ? "请移除或删除分组下的素材后，再删除分组"
                                : "删除",
                            placement: "top",
                            enterable: false
                          }
                        },
                        [
                          _c("i", {
                            class: [
                              "iconfont",
                              "icon-delete",
                              item.amount > 0 ? "is-disable" : ""
                            ],
                            on: {
                              click: function($event) {
                                return _vm.fn_removeGroup(item)
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }