var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "inner is-dark detail" },
    [
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "detail-base ly-card is-margin" },
          [
            _c(
              "div",
              { staticClass: "flex flex-between detail-base__header" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "detail-base__avatar tearm" }, [
                    _c("i", { staticClass: "iconfont icon-weixin" })
                  ]),
                  _c("div", { staticClass: "flex flex-column flex-start" }, [
                    _c("span", { staticClass: "detail-base__name" }, [
                      _vm._v(
                        _vm._s(_vm.info.name !== "" ? _vm.info.name : "群聊")
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-center",
                        staticStyle: { color: "#6e788a" }
                      },
                      [
                        _vm._v(" 群主："),
                        _c("img", {
                          staticClass: "detail-base__avatar-small",
                          attrs: { src: _vm.info.owner_avatar, alt: "" }
                        }),
                        _vm._v(" " + _vm._s(_vm.info.owner_name) + " "),
                        _vm.isSuperManager
                          ? _c("div", { staticClass: "flex" }, [
                              _c("span", { staticClass: "ly-gap is-vertical" }),
                              _c(
                                "span",
                                {
                                  staticClass: "ly-link",
                                  on: { click: _vm.jumpToAnalyse }
                                },
                                [_vm._v("查看ta的群聊分析")]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              ]
            ),
            _c(
              "el-row",
              { attrs: { gutter: 16 } },
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "is-gray" }, [
                    _c("div", { staticClass: "q-info" }, [_vm._v("今日入群")]),
                    _c("div", [
                      _c("span", { staticClass: "detail-num" }, [
                        _vm._v(_vm._s(_vm.info.today_add))
                      ]),
                      _c("span", { staticClass: "q-info" }, [_vm._v("人")])
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "is-gray" }, [
                    _c("div", { staticClass: "q-info" }, [_vm._v("今日退群")]),
                    _c("div", [
                      _c("span", { staticClass: "detail-num" }, [
                        _vm._v(_vm._s(_vm.info.today_del))
                      ]),
                      _c("span", { staticClass: "q-info" }, [_vm._v("人")])
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "is-gray" }, [
                    _c("div", { staticClass: "q-info" }, [_vm._v("群总人数")]),
                    _c("div", [
                      _c("span", { staticClass: "detail-num" }, [
                        _vm._v(_vm._s(_vm.info.total))
                      ]),
                      _c("span", { staticClass: "q-info" }, [_vm._v("人")])
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "detail-right ly-card is-margin",
            staticStyle: { "min-height": "171px" }
          },
          [
            _c("div", { staticClass: "detail-base__header" }, [
              _c("div", { staticClass: "ly-table__main-title" }, [
                _vm._v("群公告")
              ])
            ]),
            _c(
              "div",
              { staticClass: "detail-base__inner" },
              [
                _vm.info.notice === ""
                  ? [
                      _c("div", { staticClass: "flex flex-center" }, [
                        _c("div", { staticClass: "flex flex-column" }, [
                          _c("img", {
                            staticStyle: {
                              width: "100px",
                              "margin-bottom": "16px"
                            },
                            attrs: {
                              src: require("@assets/svg/default/no_notice.svg"),
                              alt: ""
                            }
                          }),
                          _c("div", { staticClass: "q-info" }, [
                            _vm._v("暂无群公告")
                          ])
                        ])
                      ])
                    ]
                  : [_vm._v(" " + _vm._s(_vm.info.notice) + " ")]
              ],
              2
            )
          ]
        )
      ]),
      _c(
        "layout-table",
        {
          attrs: { pageTotal: _vm.total, loading: _vm.loading, page: _vm.page },
          on: { pageChange: _vm.pageChange }
        },
        [
          _c(
            "div",
            { staticClass: "ly-table__main-header flex flex-between" },
            [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "ly-table__main-title" }, [
                  _vm._v("群成员")
                ]),
                _c("span", [_vm._v("共" + _vm._s(_vm.info.total) + "人")]),
                _c("span", { staticClass: "ly-gap is-vertical" }),
                _vm._v(
                  " 员工 " +
                    _vm._s(_vm.info.wecom_user_total) +
                    " 人，客户 " +
                    _vm._s(_vm.info.wecom_external_user_total) +
                    " 人 "
                )
              ])
            ]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData },
              on: { "sort-change": _vm.tableSort }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name, avatar_url", label: "成员昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "flex" }, [
                          scope.row.avatar_url
                            ? _c("img", {
                                staticClass: "ly-table__main-avatar",
                                attrs: { src: scope.row.avatar_url, alt: "" }
                              })
                            : _c("img", {
                                staticClass: "ly-table__main-avatar",
                                attrs: {
                                  src: require("../../../assets/images/default_avatar.png"),
                                  alt: ""
                                }
                              }),
                          _c(
                            "div",
                            { staticClass: "ly-table__main-name" },
                            [
                              _vm._v(" " + _vm._s(scope.row.name) + " "),
                              scope.row.is_owner
                                ? [
                                    _c("span", { staticClass: "group-tag" }, [
                                      _vm._v("群主")
                                    ])
                                  ]
                                : [
                                    scope.row.corp_name
                                      ? _c(
                                          "span",
                                          { staticClass: "q-warning" },
                                          [
                                            _vm._v(
                                              "@" + _vm._s(scope.row.corp_name)
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "q-success" },
                                          [_vm._v("@微信")]
                                        )
                                  ]
                            ],
                            2
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "join_scene", label: "入群方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.join_scene === 0
                          ? [_vm._v("未知来源")]
                          : _vm._e(),
                        scope.row.join_scene === 1
                          ? [_vm._v("员工直接邀请")]
                          : _vm._e(),
                        scope.row.join_scene === 2
                          ? [_vm._v("员工链接邀请")]
                          : _vm._e(),
                        scope.row.join_scene === 3
                          ? [_vm._v("扫描群二维码")]
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "join_at",
                  label: "入群时间",
                  sortable: "custom"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "ly-table__main-time" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.util.timeTofmt(
                                  scope.row.join_at,
                                  "yyyy-MM-dd hh:mm"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "操作",
                  "class-name": "operation-cell"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type === "wecom_user"
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: !scope.row.view
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpToEmp(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("员工详情")]
                              )
                            ]
                          : scope.row.type === "wecom_external_user"
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: !scope.row.view
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpToCus(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("客户详情")]
                              )
                            ]
                          : scope.row.type === "group_external_user"
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: !scope.row.user_id
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpToCus(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("客户详情")]
                              )
                            ]
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "ly-table-empty",
                  attrs: { slot: "empty" },
                  slot: "empty"
                },
                [
                  _vm._t("empty", [
                    _c("img", {
                      attrs: {
                        src: require("@assets/svg/default/no_data.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" 暂无数据 ")
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }