<template>
  <div v-if="show">
    <el-form label-position="top">
      <el-form-item label="字段名称">
        <el-input v-model="data.name" maxlength="10"></el-input>
      </el-form-item>

      <el-form-item
        label="默认提示语"
        v-if="!data.options.isShowPlaceholder && data.options.placeholder"
      >
        <el-input v-model="data.options.placeholder" maxlength="20"></el-input>
      </el-form-item>

      <el-form-item label="默认提示语" v-if="data.options.descplaceholder">
        <el-input
          v-model="data.options.descplaceholder"
          maxlength="20"
        ></el-input>
      </el-form-item>

      <el-form-item label="选项" v-if="data.options.options">
        <template>
          <template v-if="data.type == 'radio' || data.type == 'select'">
            <el-radio-group v-model="data.options.defaultValue">
              <li
                v-for="(item, index) in data.options.options"
                :key="index"
                class="mb-8"
              >
                <el-radio :label="item.value" style="margin-right: 5px">
                  <el-input
                    :style="{
                      width: '180px'
                    }"
                    size="mini"
                    v-model="item.value"
                  ></el-input>
                </el-radio>
                <i class="drag-item" style="font-size: 16px; margin: 0 5px;"
                  ><i class="iconfont icon-icon_bars"></i
                ></i>
                <el-button
                  @click="handleOptionsRemove(index)"
                  circle
                  plain
                  type="danger"
                  size="mini"
                  icon="el-icon-minus"
                  style="padding: 4px; margin-left: 5px"
                  v-if="data.options.isCanAddOption && index !== 0"
                ></el-button>
              </li>
            </el-radio-group>
          </template>

          <template v-if="data.type == 'checkbox'">
            <el-checkbox-group v-model="data.options.defaultValue">
              <li v-for="(item, index) in data.options.options" :key="index">
                <el-checkbox :label="item.value" style="margin-right: 5px">
                  <el-input
                    :style="{
                      width: '180px'
                    }"
                    size="mini"
                    v-model="item.value"
                  ></el-input>
                </el-checkbox>
                <i class="drag-item" style="font-size: 16px; margin: 0 5px;"
                  ><i class="iconfont icon-icon_bars"></i
                ></i>
                <el-button
                  @click="handleOptionsRemove(index)"
                  circle
                  plain
                  type="danger"
                  size="mini"
                  icon="el-icon-minus"
                  style="padding: 4px; margin-left: 5px"
                  v-if="data.options.isCanAddOption && index !== 0"
                ></el-button>
              </li>
            </el-checkbox-group>
          </template>
          <div style="margin-left: 22px" v-if="data.options.isCanAddOption">
            <el-button type="text" @click="handleAddOption">添加选项</el-button>
          </div>
        </template>
      </el-form-item>

      <el-form-item
        label="默认字符限制"
        v-if="
          (data.type == 'input' || data.type == 'textarea') &&
            data.options.isShowMaxLength
        "
      >
        <el-input
          v-model="data.options.maxLength"
          v-if="data.type == 'input'"
          maxlength="100"
          @blur="maxLengthBlur(data.options)"
        ></el-input>
        <el-input
          v-model="data.options.maxLength"
          v-else="data.type == 'textarea'"
          maxlength="200"
          @blur="maxLengthBlur(data.options)"
        ></el-input>
      </el-form-item>

      <el-form-item label="必填项">
        <el-checkbox v-model="data.options.required">是否必填</el-checkbox>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable
  },
  props: ['data'],
  data () {
    return {
    }
  },
  computed: {
    show () {
      if (this.data && Object.keys(this.data).length > 0) {
        return true
      }
      return false
    }
  },
  methods: {
    maxLengthBlur (options) {
      let _str = options.maxLength + ' '
      if (!/\d/.test(_str) || options.maxLength <= 0) {
        this.$message.error('默认字符限制只能输入正整数')
        options.maxLength = options.valueMin
      } else if (options.maxLength > options.valueMax) {
        this.$message.error(`默认字符限制不能大于${options.valueMax}`)
        options.maxLength = options.valueMax
      }
    },
    handleOptionsRemove (index) {
      this.data.options.options.splice(index, 1)
    },
    handleAddOption () {
      // 提供中文数字
      var cnum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      function rp (n) {
        var s = '';
        n = '' + n; // 数字转为字符串
        for (var i = 0; i < n.length; i++) {
          s += cnum[parseInt(n.charAt(i))];
        }
        return s;
      }

      this.data.options.options.push({
        value: '选项' + rp(this.data.options.options.length + 1)
      })
    },
  }
}
</script>
