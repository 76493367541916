<!--
 * @Author: zhanln
 * @Date: 2021-11-09 16:34:36
 * @LastEditTime: 2021-12-02 16:13:34
 * @LastEditors: Please set LastEditors
 * @Description: 预览- 活动信息
-->

<template>
  <ly-mobile :msgList="msgList"></ly-mobile>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileBasic',

  data () {
    return {
      defaultPoster: require('@/assets/images/poster-default.png'),
      msgList: [{
        type: 'text',
        content: ''
      }, {
        type: 'image',
        isPoster: true,
        defaultText: '海报图片',
        image: ''
      }]
    }
  },

  computed: {
    ...mapState({
      welcom_msg: state => state.task.welcom_msg,
      poster_img: state => state.task.poster_img
    })
  },

  watch: {
    welcom_msg (val) {
      this.msgList[0].content = val
    },
    poster_img (val) {
      this.msgList[1].image = val
    }
  },

  mounted () {
    this.msgList[0].content = this.$store.state.task.welcom_msg
    this.msgList[1].image = this.$store.state.task.poster_img
  }
}
</script>
