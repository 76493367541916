var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择群分组",
        "custom-class": "select-group",
        visible: _vm.visible,
        width: "635px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.getAllGroupList
      }
    },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: {
              size: "medium",
              placeholder: "请输入群组名称",
              "suffix-icon": "el-icon-search"
            },
            model: {
              value: _vm.filterKey,
              callback: function($$v) {
                _vm.filterKey = $$v
              },
              expression: "filterKey"
            }
          }),
          _c("div", { staticClass: "title" }, [
            _vm._v("选择群"),
            _c("span", [_vm._v(" (只展示有群聊的分组)")])
          ]),
          _c("div", { staticClass: "list" }, [
            _c(
              "div",
              { staticClass: "list-block" },
              [
                _c("el-tree", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filteredData.length,
                      expression: "filteredData.length"
                    }
                  ],
                  ref: "tree",
                  attrs: {
                    data: _vm.filteredData,
                    "node-key": "tempId",
                    "show-checkbox": "",
                    props: _vm.props
                  },
                  on: { check: _vm.handleCheckChange },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var node = ref.node
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c("div", { staticClass: "ly-table__main-tearm" }, [
                            _c("i", { staticClass: "icon qy-icon-group" })
                          ]),
                          _c("div", { staticClass: "label-temp" }, [
                            _c("span", { staticClass: "text-ellipsis" }, [
                              _vm._v(_vm._s(node.label))
                            ]),
                            node.disabled
                              ? _c("span", { staticClass: "offline" }, [
                                  _vm._v("(账号离线)")
                                ])
                              : _vm._e()
                          ])
                        ])
                      }
                    }
                  ])
                }),
                _c("el-empty", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.filteredData.length,
                      expression: "!filteredData.length"
                    }
                  ],
                  attrs: { description: "暂无分组数据" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _vm._v(" 已选群聊"),
            _c("span", [_vm._v(" (" + _vm._s(_vm.selectedList.length) + ")")]),
            _c(
              "el-button",
              {
                attrs: { round: "", type: "text" },
                on: { click: _vm.handleClear }
              },
              [_vm._v("清空")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "list" },
          [
            _vm.selectedList.length === 0
              ? [
                  _c("div", { staticClass: "el-table__empty-block" }, [
                    _c("span", { staticClass: "el-table__empty-text" }, [
                      _vm._v("暂无数据")
                    ])
                  ])
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "list-block" },
                    _vm._l(_vm.selectedList, function(item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "list-item" },
                        [
                          _c("div", { staticClass: "temp" }, [
                            _c("span", { staticClass: "nick" }, [
                              _vm._v(_vm._s(item.name))
                            ])
                          ]),
                          _c("i", {
                            staticClass: "el-icon-close",
                            on: {
                              click: function($event) {
                                return _vm.handleItemRemove(item)
                              }
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ]
          ],
          2
        )
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !_vm.selectedList.length,
                size: "medium"
              },
              on: { click: _vm.handleSelectAccount }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }