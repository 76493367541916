var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("act-layout", {
    ref: "cards",
    attrs: { cards: _vm.cards },
    on: { setKey: _vm.fn_setKey },
    scopedSlots: _vm._u([
      {
        key: "basic",
        fn: function() {
          return [_c("view-basic", { ref: "basic" })]
        },
        proxy: true
      },
      {
        key: "cus",
        fn: function() {
          return [_c("view-cus", { ref: "cus" })]
        },
        proxy: true
      },
      {
        key: "qrlink",
        fn: function() {
          return [_c("view-qrlink", { ref: "qrlink" })]
        },
        proxy: true
      },
      {
        key: "mobile",
        fn: function() {
          return [
            _vm.tabKey === "basic" ||
            _vm.tabKey === "cus" ||
            (_vm.tabKey === "qrlink" && !_vm.linkOpen)
              ? _c("mobi-basic")
              : _vm._e(),
            _vm.tabKey === "qrlink" && _vm.linkOpen ? _c("mobi-link") : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  round: "",
                  icon: "iconfont icon-send-fill",
                  disabled: _vm.watting || (_vm.isEnd && !_vm.isCopy),
                  loading: _vm.btnLoading
                },
                on: { click: _vm.fn_submit }
              },
              [
                _vm._v(
                  _vm._s(_vm.isEnd && !_vm.isCopy ? "活码已失效" : "确定提交")
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }