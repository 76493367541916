var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择群",
        "custom-class": "select-group",
        visible: _vm.visible,
        width: "635px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.getListByRobot
      }
    },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "div",
            { staticClass: "temp-title" },
            [
              _vm._v("云端账号： "),
              _c("el-avatar", {
                attrs: { size: 32, src: _vm.accountInfo.avatar }
              }),
              _c("span", { staticClass: "nick text-ellipsis" }, [
                _vm._v(_vm._s(_vm.accountInfo.name))
              ]),
              _vm.accountInfo.status === 1
                ? _c("span", { staticClass: "online" }, [_vm._v("(在线)")])
                : _c("span", { staticClass: "offline" }, [_vm._v("(离线)")])
            ],
            1
          ),
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "medium",
              placeholder: "请输入群组名称",
              "suffix-icon": "el-icon-search"
            },
            model: {
              value: _vm.filterKey,
              callback: function($$v) {
                _vm.filterKey = $$v
              },
              expression: "filterKey"
            }
          }),
          _c("div", { staticClass: "title" }, [_vm._v("选择群")]),
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm.treeData.length === 0
                ? [
                    _c("div", { staticClass: "el-table__empty-block" }, [
                      _c("span", { staticClass: "el-table__empty-text" }, [
                        _vm._v("暂无数据")
                      ])
                    ])
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "list-block" },
                      [
                        _c("el-tree", {
                          ref: "tree",
                          attrs: {
                            data: _vm.filteredData,
                            "node-key": "tempId",
                            "show-checkbox": "",
                            props: _vm.props
                          },
                          on: { check: _vm.handleCheckChange },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var node = ref.node
                                return _c(
                                  "span",
                                  { staticClass: "custom-tree-node" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ly-table__main-tearm" },
                                      [
                                        _c("i", {
                                          staticClass: "icon qy-icon-group"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "label-temp text-ellipsis"
                                      },
                                      [_vm._v(" " + _vm._s(node.label) + " ")]
                                    )
                                  ]
                                )
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _vm._v(" 已选群聊"),
            _c("span", [_vm._v(" (" + _vm._s(_vm.selectedList.length) + ")")]),
            _c(
              "el-button",
              { attrs: { type: "text" }, on: { click: _vm.handleClear } },
              [_vm._v("清空")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "list" },
          [
            _vm.selectedList.length === 0
              ? [
                  _c("div", { staticClass: "el-table__empty-block" }, [
                    _c("span", { staticClass: "el-table__empty-text" }, [
                      _vm._v("暂无数据")
                    ])
                  ])
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "list-block" },
                    _vm._l(_vm.selectedList, function(item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "list-item" },
                        [
                          _c("div", { staticClass: "temp" }, [
                            _c("span", { staticClass: "nick" }, [
                              _vm._v(_vm._s(item.name))
                            ])
                          ]),
                          _c("i", {
                            staticClass: "el-icon-close",
                            on: {
                              click: function($event) {
                                return _vm.handleItemRemove(item)
                              }
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ]
          ],
          2
        )
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !_vm.selected.length,
                size: "medium"
              },
              on: { click: _vm.handleSelectAccount }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }