<!--
 * @Author: zhanln
 * @Date: 2021-11-18 17:37:41
 * @LastEditTime: 2022-01-13 11:45:27
 * @LastEditors: zhanln
 * @Description: 编辑底部
-->

<template>
  <div class="edit-footer">
    <div class="item left">
      <!-- 如果需要购买但是没购买时，显示提示 -->
      <div class="buy-tips flex" v-if="showTips">
        <div class="anim flex">
          <img
            src="~@assets/svg/notice_icon.svg"
            alt=""
            style="margin-right: 8px"
          />
          <b>温馨提示：</b>您还未购买任务宝，此页面仅供浏览体验呦～
        </div>
      </div>
    </div>

    <div class="item center">
      <!-- 需要购买 -->
      <template v-if="buyType">
        <el-tooltip
          :value="showTool"
          :manual="true"
          placement="top-end"
          popper-class="is-white radius16"
        >
          <div slot="content" style="width: 246px">
            <div
              class="flex flex-center"
              style="height: 53px; margin-bottom: 12px"
            >
              <img
                src="~@assets/svg/wallet_icon.svg"
                alt=""
                style="margin-right: 24px"
              />
              <div>
                <b>温馨提示：</b>您还未购买任务宝，此页面仅供浏览体验呦～
              </div>
            </div>
            <div class="flex flex-justify-end">
              <el-button
                class="cus-mini"
                round
                size="mini"
                type="primary"
                @click="showTool = false"
                >知道了</el-button
              >
            </div>
          </div>
          <el-button type="warning" round @click="fn_showPay"
            >立即购买</el-button
          >
        </el-tooltip>
      </template>

      <!-- 不需要购买或已购买 -->
      <template v-else>
        <el-button
          round
          icon="iconfont icon-edit"
          @click="$emit('submit', 'draft')"
          :loading="btnLoading"
          v-if="isDraft || !isEdit || isCopy"
          >保存草稿</el-button
        >
        <el-button
          type="primary"
          round
          :loading="btnLoading"
          :icon="isEnd ? '' : 'iconfont icon-send-fill'"
          @click="$emit('submit')"
          :disabled="isEnd && !isCopy"
          >{{ isEnd && !isCopy ? "活动已结束" : "发布活动" }}</el-button
        >
      </template>
    </div>

    <div class="item"></div>

    <!-- <ly-pay
      :payDialogVisible.sync="payDialogVisible"
      :title="payTitle"
      appTitle="任务宝"
      :payType="payType"
    ></ly-pay> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import lyPay from '@/components/LyPay'
export default {
  name: 'groupActFooter',

  props: {
    btnLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showTool: true
      // payDialogVisible: false,
      // payTitle: '',
      // payType: ''
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.lottery.isEdit,
      isCopy: state => state.lottery.isCopy,
      isEnd: state => state.lottery.isEnd,
      isDraft: state => state.lottery.isDraft
    }),
    metaId () {
      return this.$route.meta.pid
    },
    mapAppId () {
      return this.$store.state.buy.mapAppId
    },
    isSale () {
      return this.$store.state.buy.isSale
    },
    isBought () {
      return this.$store.state.buy.isBought
    },
    buyInfo () {
      const remainDay = this.$store.state.buy.remainDay
      if (remainDay === null) {
        return false
      } else {
        if (remainDay <= 0) {
          return false
        } else {
          return true
        }
      }
    },
    showTips () {
      return false
      // return !this.showTool
    },
    buyType () {
      // 需要购买且未购买，返回true
      // return this.isSale && (!this.isBought || !this.buyInfo)
      return false
    }
  },

  methods: {
    // fn_showPay () {
    //   this.payType = this.isBought ? 'renewal' : 'first'
    //   this.payTitle = this.isBought ? '立即续费' : '购买任务宝'
    //   this.payDialogVisible = true
    // }
  }

  // components: {
  //   lyPay
  // }
}
</script>

<style lang="scss" scoped>
.edit-footer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: 100%;

  .item {
    flex: 1.2 1 0%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .left {
    justify-content: start;
  }

  .center {
    flex: 0.7 1 0%;
  }
}

.buy-tips {
  position: relative;
  height: 56px;
  overflow: hidden;
  width: 100%;

  .anim {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 56px;
    animation: tips_anim 0.5s;
  }
}

@keyframes tips_anim {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0;
  }
}
</style>
