/*
 * @Author: zhanln
 * @Date: 2021-07-26 16:49:06
 * @LastEditTime: 2021-07-26 17:10:49
 * @LastEditors: zhanln
 * @Description: emoji
 */

const wecomEmoji = `😀 😄 😁 😆 😅 🤣 😂 🙂 😉 😊 😇 😍 😘 😗
😚 😙 😋 😛 😜 😝 🤑 🤗 🤔 😐 😏 😬 😌 😔 😪 😴
😎 🤓 😕 😟 🙁 😮 😯 😲 😳
👻 🎄 🎈 🎊 🎏 🎐 🎁 🎗️ 🎰 🎵 🎶
💥 🔥 🌈 ⛅ 👈 👉 👆 🖕 👇 🤝 💪 ⬆️ ➡️ ⬇️ ⬅️
🏆 🏅 🥇 🥈 🥉
`

export default { wecomEmoji }
