var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "icon-plus iconfont"
                    },
                    on: { click: _vm.addActivity }
                  },
                  [_vm._v("创建活动")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "title_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-start" },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "36px",
                        height: "62px",
                        "margin-right": "12px",
                        "border-radius": "4px"
                      },
                      attrs: { src: row.poster_url, lazy: "", fit: "cover" }
                    }),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          row.title.length > 8
                            ? row.title.slice(0, 8) +
                                "<br />" +
                                row.title.slice(8, row.title.length)
                            : row.title
                        )
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "steps_header_slot",
            fn: function() {
              return [
                _c("span", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v("剩余库存")
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      "popper-class": "q-tooltip"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " 活动库存为0时将自动暂停活动，活动暂停后，新添加用"
                      ),
                      _c("br"),
                      _vm._v(
                        "户不再自动发送活动规则和海报，同时不作为本次参与"
                      ),
                      _c("br"),
                      _vm._v(
                        "用户统计，也不统计上级邀请数量。您可以添加库存或"
                      ),
                      _c("br"),
                      _vm._v("者结束活动。 ")
                    ]),
                    _c("i", { staticClass: "iconfont icon-info-fill" })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "steps_slot",
            fn: function(ref) {
              var row = ref.row
              return _vm._l(row.steps, function(item, stepIndex) {
                return _c(
                  "div",
                  {
                    key: stepIndex,
                    class: [item.remain === 0 ? "q-danger" : ""]
                  },
                  [
                    item.step === 1 && item.status === 1
                      ? [
                          _vm._v("一阶：" + _vm._s(item.remain) + " "),
                          item.reward_type === 6 &&
                          item.remain > item.type_remain
                            ? _c("span", { staticClass: "q-danger" }, [
                                _vm._v(
                                  " （兑换码：" +
                                    _vm._s(item.type_remain) +
                                    "） "
                                )
                              ])
                            : _vm._e()
                        ]
                      : item.step === 2 && item.status === 1
                      ? [
                          _vm._v("二阶：" + _vm._s(item.remain) + " "),
                          item.reward_type === 6 &&
                          item.remain > item.type_remain
                            ? _c("span", { staticClass: "q-danger" }, [
                                _vm._v(
                                  " （兑换码：" +
                                    _vm._s(item.type_remain) +
                                    "） "
                                )
                              ])
                            : _vm._e()
                        ]
                      : item.step === 3 && item.status === 1
                      ? [
                          _vm._v("三阶：" + _vm._s(item.remain) + " "),
                          item.reward_type === 6 &&
                          item.remain > item.type_remain
                            ? _c("span", { staticClass: "q-danger" }, [
                                _vm._v(
                                  " （兑换码：" +
                                    _vm._s(item.type_remain) +
                                    "） "
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              })
            }
          },
          {
            key: "status_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.status === 0
                  ? [_c("ly-status", [_vm._v("未开启")])]
                  : row.status === 1
                  ? [
                      _c("ly-status", { attrs: { type: "success" } }, [
                        _vm._v("进行中")
                      ])
                    ]
                  : row.status === 2
                  ? [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c("div", [
                                _vm._v(
                                  " 活动暂停中，新添加用户不再自动发送活动规则和海报，"
                                ),
                                _c("br"),
                                _vm._v(
                                  "同时不作为本次参与用户统计，也不统计上级邀请数量。"
                                ),
                                _c("br"),
                                _vm._v("您可以添加库存或者结束活动。 ")
                              ])
                            ]
                          ),
                          _c("ly-status", { attrs: { type: "warning" } }, [
                            _vm._v("已暂停")
                          ])
                        ],
                        1
                      )
                    ]
                  : row.status === 3
                  ? [_c("ly-status", [_vm._v("未开始")])]
                  : [_c("ly-status", [_vm._v("已结束")])]
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ly-action",
                  { key: Date.now() },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.jumgToAnalysis(row.id)
                          }
                        }
                      },
                      [_vm._v("数据分析")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.jumpToUsers(row.id)
                          }
                        }
                      },
                      [_vm._v("参与用户")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.jumpToReward(row.id)
                          }
                        }
                      },
                      [_vm._v("奖品记录")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          icon: "iconfont icon-send",
                          disabled: row.status !== 1
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleInvite(row.id)
                          }
                        }
                      },
                      [_vm._v(" 群发助手")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-Code" },
                        on: {
                          click: function($event) {
                            return _vm.fn_jumpToPop(row.id)
                          }
                        }
                      },
                      [_vm._v("渠道推广")]
                    ),
                    _c("el-divider"),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.editActivity(row.id)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-copy" },
                        on: {
                          click: function($event) {
                            return _vm.editActivity(row.id, "copy")
                          }
                        }
                      },
                      [_vm._v("复制")]
                    ),
                    row.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "iconfont icon-Code" },
                            on: {
                              click: function($event) {
                                return _vm.upadateActivity(
                                  row.title,
                                  row.id,
                                  "start"
                                )
                              }
                            }
                          },
                          [_vm._v("开始")]
                        )
                      : _vm._e(),
                    row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "iconfont icon-pause"
                            },
                            on: {
                              click: function($event) {
                                return _vm.upadateActivity(
                                  row.title,
                                  row.id,
                                  "stop"
                                )
                              }
                            }
                          },
                          [_vm._v("暂停")]
                        )
                      : _vm._e(),
                    row.status === 1 || row.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "iconfont icon-ending"
                            },
                            on: {
                              click: function($event) {
                                return _vm.upadateActivity(
                                  row.title,
                                  row.id,
                                  "end"
                                )
                              }
                            }
                          },
                          [_vm._v("结束")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-delete" },
                        on: {
                          click: function($event) {
                            return _vm.delActivity(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    _c("el-divider"),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-empty" },
                        on: {
                          click: function($event) {
                            return _vm.handlePosterClear(row.title, row.id)
                          }
                        }
                      },
                      [_vm._v("清空海报缓存 ")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          staticClass: "q-dialog inviter-dialog",
          attrs: {
            visible: _vm.inviterVisible,
            width: "960px",
            title: "群发助手"
          },
          on: {
            "update:visible": function($event) {
              _vm.inviterVisible = $event
            },
            closed: _vm.fn_resetInviter
          }
        },
        [
          _c(
            "div",
            { staticClass: "inviter-dialog__main" },
            [
              _c(
                "el-scrollbar",
                { staticClass: "inviter-dialog__main-left" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "inviterData",
                      staticStyle: { flex: "1", "padding-right": "16px" },
                      attrs: {
                        model: _vm.inviterData,
                        rules: _vm.inviterRules,
                        "label-width": "120px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "wecom_user_ids",
                          attrs: {
                            label: "群发账号",
                            required: "",
                            error: _vm.wecom_user_idsTips
                          }
                        },
                        [
                          _c("ly-emp-select", {
                            ref: "empSelectRef",
                            attrs: {
                              placeholder: "选择群发账号",
                              datafilter: "",
                              showLength: 3
                            },
                            on: { getEmpId: _vm.getEmpId }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "flex flex-between",
                              staticStyle: {
                                "margin-top": "8px",
                                "line-height": "1"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0
                                    },
                                    model: {
                                      value: _vm.inviterData.tag_open,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.inviterData,
                                          "tag_open",
                                          $$v
                                        )
                                      },
                                      expression: "inviterData.tag_open"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "q-info",
                                      staticStyle: {
                                        "margin-left": "12px",
                                        "margin-right": "4px"
                                      }
                                    },
                                    [_vm._v("更多筛选组合 ")]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                        "popper-class": "q-tooltip"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content"
                                        },
                                        [
                                          _vm._v(
                                            " 打开「更多筛选组合」后，将群发给「群发账号」下满足对应条件的好友用户 "
                                          )
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "iconfont icon-info-fill1",
                                        staticStyle: { color: "#b4bbcc" }
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticClass: "q-info" }, [
                                _vm._v(" 将群发给"),
                                _c("span", { staticClass: "q-primary" }, [
                                  _vm._v(" " + _vm._s(_vm.clientSum) + " ")
                                ]),
                                _vm._v("个用户 ")
                              ])
                            ]
                          ),
                          _vm.inviterData.tag_open === 1
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "8px" } },
                                [
                                  _c("tag-select-v2", {
                                    attrs: {
                                      title: "通过标签筛选用户",
                                      checked: _vm.inviterData.tags
                                    },
                                    on: { getTagId: _vm.fn_getFilterIds }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "群发方式", prop: "push_type" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.fn_changePushType },
                              model: {
                                value: _vm.inviterData.push_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.inviterData, "push_type", $$v)
                                },
                                expression: "inviterData.push_type"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("手动推送")
                              ]),
                              _vm.hasCloudBean
                                ? _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("自动推送")
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.inviterData.push_type === 1
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "is-primary flex flex-start text-small"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        flex: "1",
                                        "line-height": "20px",
                                        color: "#6e788a"
                                      }
                                    },
                                    [
                                      _vm.hasCloudBean
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "8px"
                                              }
                                            },
                                            [
                                              _vm._v(" 企微官方推送将使用 "),
                                              _c("b", [_vm._v("1")]),
                                              _vm._v(
                                                " 次「企微群发」次数，每个用户每月只能收到 "
                                              ),
                                              _c("b", [_vm._v("30")]),
                                              _vm._v(" 次企业群发。 "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "q-primary",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.fn_changePushTypeByText(
                                                        2
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "选择“自动推送”突破限制 >"
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        [
                                          _vm._v(
                                            " 消息推送完成后，需要对应的员工手动确认，确认后消息发送给对应用户。 "
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              staticStyle: {
                                                "border-radius": "16px"
                                              },
                                              attrs: {
                                                placement: "top",
                                                "popper-class":
                                                  "q-tooltip is-light"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        margin:
                                                          "24px 24px 16px",
                                                        width: "777px"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src:
                                                            "https://image.01lb.com.cn//uploads/wecom/21/0416/1618577894N96zjbn3.png",
                                                          width: "777",
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "q-primary" },
                                                [
                                                  _vm._v(
                                                    "员工如何确认消息发送？"
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.inviterData.push_type === 2
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      flex: "1",
                                      "line-height": "20px",
                                      color: "#6e788a",
                                      "font-size": "12px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "8px" }
                                      },
                                      [
                                        _vm._v(
                                          ' 1.员工登录云端后，将无群发消息次数限制，能够自动发送"奖品通知"和“助力提醒”。 '
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "8px" }
                                      },
                                      [
                                        _vm._v(
                                          " 2. 选择此方式，未登录云端的员工，将默认使用【手动推送】的方式，且不发送“助力提醒”。 "
                                        )
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        " 3.员工登录云端后，如果员工在电脑登录企业微信，将会把云端登录挤掉线，造成无法自动发送内容，活动期间建议保持员工云端在线登录状态。 "
                                      )
                                    ])
                                  ]
                                ),
                                _c("div", { staticClass: "account" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "account-header flex flex-between"
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          on: {
                                            change: _vm.fn_changeAccountType
                                          },
                                          model: {
                                            value: _vm.accountType,
                                            callback: function($$v) {
                                              _vm.accountType = $$v
                                            },
                                            expression: "accountType"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: 1,
                                                disabled: _vm.accountLoading
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "全部账号(" +
                                                  _vm._s(
                                                    _vm.accountListAll.length
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: 2,
                                                disabled: _vm.accountLoading
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "离线(" +
                                                  _vm._s(
                                                    _vm.accountListOut.length
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "lydebounce",
                                              rawName: "v-lydebounce",
                                              value: [
                                                "button",
                                                _vm.fn_getRobotList
                                              ],
                                              expression:
                                                "['button', fn_getRobotList]"
                                            }
                                          ],
                                          attrs: {
                                            type: "primary",
                                            size: "ly-mini",
                                            disabled: _vm.accountLoading
                                          }
                                        },
                                        [_vm._v("刷新")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "account-list" },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.accountLoading,
                                              expression: "accountLoading"
                                            }
                                          ],
                                          staticClass:
                                            "ly-table is-mini no-border v-middle",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.accountList,
                                            "max-height": "250",
                                            "empty-text": "请选择群发账号"
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "name, avatar",
                                              label: "群发账号"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "ly-table__main-avatar",
                                                            attrs: {
                                                              src:
                                                                scope.row
                                                                  .avatar,
                                                              alt: ""
                                                            }
                                                          }),
                                                          _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ly-table__main-name"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      scope.row
                                                                        .name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              213126197
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "name",
                                              label: "云端登录状态"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          class: [
                                                            !scope.row.status
                                                              ? "q-info"
                                                              : ""
                                                          ]
                                                        },
                                                        [
                                                          _c("span", {
                                                            class: [
                                                              "q-circle",
                                                              scope.row
                                                                .status === 1
                                                                ? "success"
                                                                : ""
                                                            ]
                                                          }),
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.status
                                                                ? "在线"
                                                                : "离线"
                                                            ) + " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1862088925
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "address",
                                              label: "操作",
                                              width: "100",
                                              "class-name": "operation-cell"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            disabled:
                                                              scope.row
                                                                .status === 1
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.fn_jumpCloudPage
                                                          }
                                                        },
                                                        [_vm._v("登录云端 ")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              800623616
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "群发内容", prop: "content" } },
                        [
                          _c("ly-editor", {
                            attrs: {
                              title: "群发内容",
                              id: "prizeWords",
                              content: _vm.inviterData.content,
                              recoverContent: _vm.initHtml,
                              emoji: ""
                            },
                            on: { getContent: _vm.fn_getContent }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "自定义链接", prop: "title" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入链接标题",
                              maxlength: "20",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.inviterData.title,
                              callback: function($$v) {
                                _vm.$set(_vm.inviterData, "title", $$v)
                              },
                              expression: "inviterData.title"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: { "margin-top": "8px" }
                            },
                            [
                              _c("el-input", {
                                staticClass: "inviterDesc",
                                attrs: {
                                  type: "textarea",
                                  resize: "none",
                                  placeholder: "请输入链接摘要"
                                },
                                model: {
                                  value: _vm.inviterData.desc,
                                  callback: function($$v) {
                                    _vm.$set(_vm.inviterData, "desc", $$v)
                                  },
                                  expression: "inviterData.desc"
                                }
                              }),
                              _c(
                                "el-upload",
                                {
                                  class: [
                                    "inviterDescImg",
                                    _vm.inviterData.link_coverList.length === 0
                                      ? ""
                                      : "upload-empty"
                                  ],
                                  attrs: {
                                    action: "#",
                                    accept: ".png, .jpg, .jpeg",
                                    "http-request": _vm.requestCover,
                                    "on-remove": _vm.removeCover,
                                    "list-type": "picture-card",
                                    limit: 1,
                                    "file-list": _vm.inviterData.link_coverList
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-column flex-center",
                                      staticStyle: {
                                        "line-height": "22px",
                                        height: "100%"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-plus"
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "text q-info" },
                                        [_vm._v("链接封面")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "q-info",
                              staticStyle: {
                                "line-height": "1.6",
                                "font-size": "12px",
                                "margin-top": "5px"
                              }
                            },
                            [
                              _vm._v(
                                " 建议尺寸500*500px，JPG、PNG、JPEG格式，图片小于2MB。 "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "24px" },
                              attrs: {
                                type: "primary",
                                round: "",
                                icon: "iconfont icon-send-fill"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitInvite("inviterData")
                                }
                              }
                            },
                            [_vm._v("立即群发")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "inviter-dialog__main-right" }, [
                _c("div", { staticClass: "mobile" }, [
                  _c("div", { staticClass: "mobile-topbar" }, [
                    _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
                    _c("div", { staticClass: "flex" }, [
                      _c("i", {
                        staticClass: "iconfont icon-a-CellularConnection"
                      }),
                      _c("i", { staticClass: "iconfont icon-Wifi" }),
                      _c("i", { staticClass: "iconfont icon-Battery" })
                    ])
                  ]),
                  _c("div", { staticClass: "mobile-titlebar" }, [
                    _c("i", { staticClass: "el-icon-arrow-left" }),
                    _c("div", { staticClass: "flex flex-column" }, [
                      _c("span", [_vm._v("张三")]),
                      _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v("零一裂变（深圳）科技有限公司")
                      ])
                    ]),
                    _c("i", { staticClass: "el-icon-more" })
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mobile-container",
                      staticStyle: { "max-height": "542px", overflow: "auto" }
                    },
                    [
                      _c("div", { staticClass: "mobile-contact" }, [
                        _c("div", { staticClass: "mobile-contact__avatar" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/avatar.jpg"),
                              alt: ""
                            }
                          })
                        ]),
                        _c(
                          "div",
                          { staticClass: "mobile-contact__dialog" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                resize: "none",
                                readonly: ""
                              },
                              model: {
                                value: _vm.inviterData.content,
                                callback: function($$v) {
                                  _vm.$set(_vm.inviterData, "content", $$v)
                                },
                                expression: "inviterData.content"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "mobile-contact" }, [
                        _c("div", { staticClass: "mobile-contact__avatar" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/avatar.jpg"),
                              alt: ""
                            }
                          })
                        ]),
                        _c("div", { staticClass: "mobile-contact__dialog" }, [
                          _c("div", { staticClass: "link-preview" }, [
                            _c(
                              "div",
                              { staticClass: "ellipsis2 link-preview__title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.inviterData.title) + " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                      flex flex-between flex-center\n                      link-preview__content\n                    "
                              },
                              [
                                _c("div", {
                                  staticClass: "ellipsis2",
                                  staticStyle: {
                                    flex: "1",
                                    "font-size": "14px"
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.inviterData.desc.replace(/[\n]/g, "")
                                    )
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "link-preview__cover" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.inviterData.link_cover
                                          ? _vm.inviterData.link_cover
                                          : _vm.defaultCover,
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }