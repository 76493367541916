<!--
 * @Descripttion: 草稿箱
 * @version: 1.0.0
-->
<template>
  <div class="drafts">
    <el-dialog
      title="草稿箱"
      :visible.sync="draftsVisible"
      width="584px"
      class="has-table"
    >
      <ly-table
        ref="lyTable"
        v-show="total"
        :data="tableData"
        :option="tableOpt"
        :page="page"
        :pageSize="per_page"
        :total="total"
        @events="handleEvents"
        @searchChange="searchChange"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelectionChange"
      >
        <template #title="{ row }">
          <div class="poster-name">
            <el-image
              class="poster"
              style="width: 36px; height: 66px"
              :src="row.poster_url"
              fit="cover"
              v-if="row.poster_url"
            ></el-image>
            <el-image
              class="poster"
              style="width: 36px; height: 66px"
              :src="defaultPoster"
              fit="cover"
              v-else
            ></el-image>
            <span class="aname">{{ row.title || "-" }}</span>
          </div>
        </template>
        <template #operation="{ row }">
          <el-button
            class="operation-btn"
            type="text-info"
            icon="iconfont icon-edit"
            @click="$router.push(`/marketing/lottery/edit?id=${row.id}`)"
            >继续编辑</el-button
          >
          <el-button
            class="operation-btn"
            type="text-info"
            icon="iconfont icon-delete"
            @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </ly-table>

      <div v-show="!total" class="ly-nodata">
        <el-empty
          :image="require('@assets/svg/default/no_drafts.svg')"
          description="暂无草稿"
        >
          <el-button
            type="primary"
            icon="iconfont icon-plus"
            round
            size="medium"
            @click="creatAct"
            >创建活动</el-button
          >
        </el-empty>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { drafts } from '../http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: drafts,
      draftsVisible: false,
      per_page: 5,
      defaultPoster: require('@assets/svg/defaultPoster.svg')
    }
  },
  watch: {
    total (n) {
      this.$parent.draft = n
    }
  },
  computed: {
    tableOpt () {
      return {
        levitate: false,
        layout: 'total, prev, pager, next',
        operationWidth: 205,
        column: [
          {
            label: '活动名称',
            prop: 'title',
            slot: 'title'
          },
          {
            label: '保存时间',
            prop: 'save_date',
            width: '160',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          }
        ]
      }
    }
  },
  mounted () { },
  methods: {
    creatAct () {
      this.draftsVisible = false
      this.$router.push('/marketing/lottery/edit')
    },
    handleDelete ({ id }) {
      this.$lyConfirm({
        text: '草稿删除后将无法找回，您确定删除吗？',
        title: '确认删除此草稿吗？'
      }).then(() => {
        this.COMM_HTTP.reqDelete({ id }).then(res => {
          if ((res && res.code) || (res && res.code === 0)) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功！')
          this.queryData()
        })
      }).catch(() => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.xl-ly-table {
  .poster {
    margin-right: 12px;
    border-radius: 4px;
  }
  .aname {
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.nodata {
  height: 544px;
  text-align: center;
  img {
    margin-top: 156px;
  }
  .desc {
    color: #6e788a;
    margin: 5px 0 20px 0;
  }
}
::v-deep {
  .xl-ly-setting {
    margin-bottom: 0 !important;
  }
  .el-table {
    margin-top: 0 !important;
  }
  .el-pagination {
    padding-right: 20px;
  }
}
</style>
