/*
 * @Author: zhanln
 * @Date: 2021-09-17 10:42:29
 * @LastEditTime: 2021-12-29 18:13:38
 * @LastEditors: zhanln
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

export const taskList = {
  reqQuery: params => axios.get(`/wecom/tasks/list/list?${stringify(params)}`)
}
export const user = {
  reqQuery: params => axios.get(`/wecom/task/accounts?${stringify(params)}`),
  export: params => axios.get(`/wecom/task/account-export?${stringify(params)}`),
  channelSel: params => axios.get(`/wecom/task/channel-list?${stringify(params)}`), // 参与用户/黑名单 渠道下拉选择项
  addBlack: params => axios.post('/wecom/task/move-in-black', params), // 加入黑名单
  removeBlack: params => axios.post('/wecom/task/move-out-black', params) // 移除黑名单
}

export const activity = {
  reqList: params => axios.get('/wecom/task/select'),
  checkTitle: params => axios.post('/wecom/task/has-same-name', params)
}

// 奖品记录
export const reward = {
  reqQuery: params => axios.get(`/wecom/task/reward-records?${stringify(params)}`),
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`) // 获取单个表单组件
}

// 表单详情
export const fDetails = {
  reqQuery: params => axios.post('/scrm-api/form/business/list', params),
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`) // 获取单个表单组件
}

// 数据分析
export const analyse = {
  overview: params => axios.get(`/wecom/task/data-overview?${stringify(params)}`), // 数据概览
  level: params => axios.get(`/wecom/task/level-analyze?${stringify(params)}`), // 层级分析
  channel: params => axios.get(`/wecom/task/channel-analyze?${stringify(params)}`) // 层级分析
}

export const popularize = {
  reqQuery: params => axios.get(`/wecom/task/channel-list?${stringify(params)}`)
}
