<!--
 * @Descripttion: 参与用户页面
 * @version: 1.0.0
-->
<template>
  <div class="rd-users">
    <div class="select-box" style="padding: 0 20px">
      <label style="color: #212b36">选择活动：</label>
      <el-select
        size="medium"
        v-model="activity_id"
        @change="handleChange"
        filterable
        placeholder="请选择活动"
      >
        <el-option
          v-for="item in activities"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <el-tabs v-model="activeName" type="card" class="ly-tabs user-list">
      <el-tab-pane label="参与用户" name="users">
        <user-pane
          v-if="activeName === 'users'"
          ref="userPane"
          :activity_id="activity_id"
          :activities="activities"
          type="1"
          :channelOps="channelOps"
          @detail="handleDetail"
        />
      </el-tab-pane>
      <el-tab-pane label="黑名单" name="blacklist">
        <user-pane
          v-if="activeName === 'blacklist'"
          ref="userPane"
          :activity_id="activity_id"
          :activities="activities"
          type="2"
          :channelOps="channelOps"
          @detail="handleDetail"
        />
      </el-tab-pane>
    </el-tabs>
    <userDetail ref="userDetail" @close="$refs.userPane.queryData()" />
  </div>
</template>
<script>
import { user } from '@/pages/client/redenvelope/http.js'
import lyTable from '@/components/mixins/ly-table.js'
import userPane from './components/userPane'
import userDetail from './components/userDetail'
export default {
  components: {
    userPane, userDetail
  },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: user,
      activity_id: '',
      activities: [],
      activeName: '',
      channelOps: [{ label: '全部', value: '' }]
    }
  },
  mounted () {
    this.activity_id = this.$route.query.id * 1
    this.getActsOps()
    this.channelSel()
    this.activeName = 'users'
  },
  methods: {
    // 获取活动选项
    getActsOps () {
      this.COMM_HTTP.selectList().then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.activities = res.data || []
      })
    },
    // 获取渠道选项
    channelSel () {
      this.COMM_HTTP.channelSel({
        activity_id: this.activity_id,
        page: 1,
        per_page: 1000
      }).then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.channelOps = [{ label: '全部', value: '' }]
        const result = res.data || []
        result.map(e => {
          this.channelOps.push({
            label: e.name,
            value: e.id
          })
        })
      })
    },
    handleDetail (row) {
      this.$refs.userDetail.userInfo = row
      this.$refs.userDetail.visible = true
    },
    handleChange () {
      this.$router.replace({ path: this.$route.path, query: { id: this.activity_id } })
      this.$nextTick(() => {
        this.channelSel()
        this.$refs.userPane.queryData()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.rd-users {
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;

  .xl-ly-table {
    width: 100%;
  }

  .ly-tabs::after {
    content: unset;
  }
}
.select-box {
  display: flex;
  align-items: center;
  margin: 20px;
  .el-select {
    width: 353px;
  }
}
.user-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  ::v-deep {
    .el-tabs__header {
      background: #fff;
      padding: 20px 20px 0;
      margin: 0 20px;
      border-radius: 16px 16px 0 0;
      box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24),
        0px 0px 2px 0px rgba(145, 158, 171, 0.24);
    }
    .el-tabs__header {
      position: relative;
      overflow: unset;
      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 40px;
        background: #fff;
        position: absolute;
        bottom: -1px;
      }
      &::before {
        left: 0px;
      }
      &::after {
        right: 0px;
      }
    }
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        min-height: 100%;
        display: flex;
      }
    }
    .ly-table-main {
      margin: 0 20px 80px;
    }
    .xl-ly-search {
      margin: 0 20px 20px;
      border-radius: 0 0 16px 16px;
    }
  }
}
::v-deep {
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: unset;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-left: 1px solid #e4e7ed;
  }
  .el-tabs__item {
    border: 1px solid #e4e7ed;
    height: 40px;
    padding: 0 !important;
    width: 88px;
    text-align: center;
    line-height: 40px;
    margin-right: 4px;
    background: rgba(0, 0, 0, 0.02);
    &.is-active {
      background: #fff;
    }
  }
  .add_account {
    display: inline-block;
    padding: 0 5px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    // height: 22px;
    line-height: 22px;
    margin: 0 5px 5px 0;
  }
  .el-icon-s-custom,
  .qy-icon-organization {
    color: rgb(51 139 255 / 60%);
  }
  .el-icon-success {
    color: #52c41a;
    margin-right: 5px;
  }
  .el-icon-error {
    color: #f55945;
    margin-right: 5px;
  }
}
</style>
