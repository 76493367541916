/*
 * @Author: zhanln
 * @Date: 2021-07-05 18:15:18
 * @LastEditTime: 2021-08-03 15:36:33
 * @LastEditors: zhanln
 * @Description:
 */
// 特殊字符校验
export function checkSpecificKey (str, list) {
  let specialKey = "[`+-@%~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
  if (list) {
    for (let i = 0; i < list.length; i++) {
      specialKey = specialKey.replace(list[i], '')
    }
  }
  for (let i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) !== -1) {
      return false
    }
  }
  return true
}
