<!--
 * @Author: zhanln
 * @Date: 2022-03-28 18:30:03
 * @LastEditTime: 2022-05-07 16:26:24
 * @LastEditors: zhanln
 * @Description: 素材列表
-->

<template>
  <div class="page-warp">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page-sizes="pageSizes"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
      class="page-tabel"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          type="primary"
          icon="icon-plus iconfont"
          @click="fn_edit"
          >添加小程序</el-button
        >
        <el-tooltip
          content="请先选择要分组的素材"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              round
              plain
              icon="iconfont icon-edit"
              size="medium"
              @click="fn_changeGroupBatch"
              :disabled="selected.length === 0"
              >批量分组</el-button
            ></span
          >
        </el-tooltip>
        <el-tooltip
          content="请先选择要删除的素材"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              round
              plain
              icon="iconfont icon-delete"
              size="medium"
              @click="fn_removeBatch"
              :disabled="selected.length === 0"
              >批量删除</el-button
            ></span
          >
        </el-tooltip>
      </template>
      <!-- 列插槽 -->
      <template #cusTable>
        <div class="el-table" v-if="total">
          <div class="mcard fx flex-wrap">
            <m-card
              v-for="(item, index) of tableData"
              :data="item"
              :index="index"
              :key="index"
              grid="g-4"
              @edit="fn_edit"
              @remove="fn_delMeterial"
              @selected="fn_select"
            >
              <div class="mcard-wrap">
                <div class="mb-12 text-ellipsis pr-24">
                  <el-tag
                    effect="plain"
                    :type="item.obtain_method === 1 ? 'success' : 'primary'"
                    size="small"
                    class="rd-12 f-14 mr-8"
                    >{{ item.obtain_method === 1 ? "本地" : "云端" }}</el-tag
                  >
                  {{ item.name }}
                </div>
                <div class="mcard-bg rd-4 mb-12">
                  <div class="mcard-header fx flex-align-center">
                    <el-image
                      :src="item.app_icon"
                      lazy
                      class="mcard-appicon mr-8"
                    >
                      <img
                        src="@/assets/images/applet.png"
                        width="100%"
                        slot="error"
                        alt=""
                      />
                    </el-image>
                    <div class="mcard-appname flex-1 text-ellipsis f-12">
                      {{ item.app_name || "-" }}
                    </div>
                  </div>
                  <div class="mcard-pagename text-ellipsis">
                    {{ item.title || "-" }}
                  </div>
                  <div class="mcard-poster">
                    <el-image :src="item.cover_image" lazy class="mcard-img">
                      <img
                        src="@/assets/images/applet_default.png"
                        class="mcard-imgerror"
                        slot="error"
                        alt=""
                      />
                    </el-image>
                  </div>
                  <div class="mcard-sys">
                    <img src="@/assets/svg/xiaochengxu.svg" />小程序
                  </div>
                </div>
              </div>
            </m-card>
          </div>
        </div>
        <template v-if="total === 0">
          <template v-if="searchCount <= 1">
            <div class="el-table__empty-block">
              <div class="el-table__empty-text">
                <div class="ly-table-empty">
                  <img src="@assets/svg/default/no_data.svg" alt="" />
                  暂无数据
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="el-table__empty-block">
              <div class="el-table__empty-text">
                <div class="ly-table-empty">
                  <img src="@assets/svg/default/no_res.svg" alt="" />
                  无搜索结果
                </div>
              </div>
            </div>
          </template>
        </template>
      </template>
    </ly-table>
    <edit ref="editRef" :currentGroupId="currentGroupId"></edit>
    <change-group
      ref="changeGroupRef"
      :currentGroupId="currentGroupId"
      type="applet"
      @clearSelected="fn_clearSelected"
    ></change-group>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { appletApi, meterialApi } from '../http'
import edit from '../edit'
import mCard from './mCard'
import changeGroup from './changeGroup.vue'
import { Base64 } from 'js-base64'
export default {
  name: 'videoList',
  mixins: [lyTable],
  props: ['currentGroupId'],
  inject: ['category', 'fn_getData'],
  components: {
    edit,
    mCard,
    changeGroup
  },
  data () {
    return {
      COMM_HTTP: appletApi,
      meterialApi: meterialApi,
      per_page: 8,
      pageSizes: [8, 16, 32, 40],
      total: null,
      searchCount: 0
    }
  },

  computed: {
    tableOpt () {
      return {
        showSelection: true,
        operationWidth: 180,
        layout: 'sizes, prev, pager, next, jumper',
        column: [
          {
            label: '素材名称',
            prop: 'name',
            search: true,
            placeholder: '请输入素材名称'
          }
        ]
      }
    }
  },

  watch: {
    tableData (val) {
      this.searchCount++
      if (val.length) {
        for (let i = 0; i < val.length; i++) {
          val[i].checked = this.selected.includes(val[i].material_id)
          if (val[i].obtain_method === 2) {
            const info = JSON.parse(Base64.decode(val[i].content))
            val[i].app_icon = info.weappiconurl
            val[i].app_name = info.title
            val[i].title = info.des_1
          }
        }
      }
    }
  },

  methods: {

    formatterParams (params) {
      return {
        ...params,
        type: 5,
        group_id: this.currentGroupId,
        category: this.category
      }
    },

    // 添加素材
    fn_edit (item) {
      this.$refs.editRef.fn_open({
        cid: this.category,
        type: 'applet',
        material_id: item ? item.material_id : null
      })
    },

    // 调起批量分组弹框
    fn_changeGroupBatch () {
      this.$refs.changeGroupRef.fn_open(this.selected)
    },

    // 批量删除（确认）
    fn_removeBatch () {
      this.$lyConfirm({
        title: '确认要删除此素材吗？',
        text: `共选中${this.selected.length}条素材，是否确认删除?`,
        confirmButtonText: '删除'
      }).then(() => {
        this.fn_delMeterial(this.selected)
      }).catch(() => { })
    },

    // 删除素材
    async fn_delMeterial (material_ids) {
      const data = await this.meterialApi.del({
        category: this.category,
        material_ids
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.fn_lyMsg('success', '素材删除成功！')
      this.fn_getData(this.currentGroupId, 'applet')
    },

    // 设置 or 取消选中
    fn_select ({ check, index }) {
      this.$set(this.tableData, index, {
        ...this.tableData[index],
        checked: check
      })
      const selectIndex = this.selected.indexOf(this.tableData[index].material_id)
      if (selectIndex === -1) {
        this.selected.push(this.tableData[index].material_id)
      } else {
        this.selected.splice(selectIndex, 1)
      }
    },

    // 分组完成，清空选择
    fn_clearSelected () {
      this.selected = []
      for (let i = 0; i < this.tableData.length; i++) {
        this.$set(this.tableData, i, {
          ...this.tableData[i],
          checked: false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-warp {
  padding: 0;
  overflow: hidden;

  ::v-deep {
    .xl-ly-table {
      height: 100%;
      overflow: hidden;
    }
  }
}
.page-tabel {
  ::v-deep {
    .xl-ly-search,
    .ly-table-main {
      box-shadow: none;
    }
    .xl-ly-search {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 1px solid #eaebf2;
      border-radius: 0;
    }
    .ly-table-main {
      height: 100%;
      margin-bottom: 40px;
      padding: 0;
    }

    .xl-ly-setting {
      padding: 0 20px;
    }

    .el-table {
      height: calc(100% - 102px);
      overflow: auto;
    }

    .el-pagination {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100% !important;
    }
  }
}

.mcard {
  padding: 0 6px;
  margin: 0 8px;

  &-bg {
    background-color: #f7f8fc;
    padding: 10px 10px 0;
    line-height: 22px;
  }

  &-appicon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &-appname {
    overflow: hidden;
    color: #b4bbcc;
  }

  &-pagename {
    margin: 4px 0;
  }

  &-poster {
    padding-bottom: calc(100% * 0.75);
    height: 0;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &-img {
    width: 100%;
    text-align: center;
    background: #e6e6e6;
  }

  &-sys {
    color: #b4bbcc;
    display: flex;
    border-top: 1px solid #eaebf2;
    margin-top: 4px;
    padding: 6px 0 6px 2px;

    img {
      margin-right: 2px;
      width: 14px;
    }
  }
}
</style>
