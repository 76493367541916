<template>
  <div class="normalTable-form-container">
    <div class="normalTable-form-body">
      <div class="mobile">
        <div class="mobile-topbar">
          <div>9:41</div>
          <div class="flex">
            <img src="@assets/images/ic_connection.png" alt="" />
            <img src="@assets/images/ic_wifi.png" alt="" />
            <img src="@assets/images/ic_power.png" alt="" />
          </div>
        </div>
        <div class="mobile-titlebar">
          <i class="el-icon-close" style="font-size: 16px"></i>
          <div class="flex flex-column">
            {{ formName || "表单名字" }}
          </div>
          <i class="el-icon-more"></i>
        </div>
      </div>
      <div class="form-header">
        <img src="@/assets/images/form_header.png" />
      </div>

      <div class="normalTable-form-list">
        <div class="form-infos">
          <img src="@/assets/images/form_infos.png" />
          <span class="form-infos-text">填写须知</span>
        </div>
        <div class="form-info-remarks">
          为了保证奖品信息的准确性，请认真填写！
        </div>
        <div v-if="data.list.length == 0" class="form-empty">
          点击左侧组件创建表单
        </div>
        <el-form label-position="top">
          <draggable
            class=""
            v-model="data.list"
            v-bind="{
              group: 'formItem',
              ghostClass: 'handleIcon',
              animation: 200,
              handle: '.drag-normalTable',
            }"
            @end="MoveEndFn"
            @add="handlenormalTableAdd"
          >
            <transition-group
              name="fade"
              tag="div"
              class="normalTable-form-list-c"
            >
              <template v-for="(element, index) in data.list">
                <normalTable-form-item
                  v-if="element && element.key"
                  :key="element.key"
                  :element="element"
                  :select.sync="selectnormalTable"
                  :index="index"
                  :data="data"
                  :selectIndex.sync="selectIndex"
                ></normalTable-form-item>
              </template>
            </transition-group>
          </draggable>
        </el-form>
      </div>
      <!-- <div class="submitButton">
        <el-button type="primary"> 确认提交 </el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import normalTableFormItem from './normalTableFormItem'

export default {
  components: {
    Draggable,
    normalTableFormItem
  },
  props: ['data', 'select', 'formName', 'selectParentIndex'],
  data () {
    return {
      selectnormalTable: this.select,
      selectIndex: -1
    }
  },
  mounted () {
    document.body.ondrop = function (event) {
      let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
      if (isFirefox) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
  },
  methods: {
    MoveEndFn () {
    },
    handleSelectnormalTable (index) {
      this.selectnormalTable = this.data.list[index]
    },
    handlenormalTableAdd (evt) {
      const newIndex = evt.newIndex

      const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 456789)

      const _options = JSON.parse(JSON.stringify(this.data.list[newIndex].options))

      this.$set(this.data.list, newIndex, {
        ...this.data.list[newIndex],
        options: {
          ..._options,
        },
        key,
        model: this.data.list[newIndex].type + '_' + key,
        rules: []
      })
      if (this.data.list[newIndex].type === 'radio' || this.data.list[newIndex].type === 'checkbox' || this.data.list[newIndex].type === 'select') {
        this.$set(this.data.list, newIndex, {
          ...this.data.list[newIndex],
          options: {
            ..._options,
            options: _options.options.map(item => ({
              ...item
            }))
          }
        })
      }


      this.selectIndex = newIndex

      this.selectnormalTable = this.data.list[newIndex]
      this.$store.commit('_SET', {
        'client.formTableComTitle': this.selectnormalTable.comName || '组件名字'
      })
    },
    handlenormalTableColAdd ($event, row, colIndex) {
      const newIndex = $event.newIndex
      const oldIndex = $event.oldIndex
      const item = $event.item

      const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 456789)

      this.$set(row.columns[colIndex].list, newIndex, {
        ...row.columns[colIndex].list[newIndex],
        options: {
          ...row.columns[colIndex].list[newIndex].options,
        },
        key,
        // 绑定键值
        model: row.columns[colIndex].list[newIndex].type + '_' + key,
        rules: []
      })

      if (row.columns[colIndex].list[newIndex].type === 'radio' || row.columns[colIndex].list[newIndex].type === 'checkbox' || row.columns[colIndex].list[newIndex].type === 'select') {
        this.$set(row.columns[colIndex].list, newIndex, {
          ...row.columns[colIndex].list[newIndex],
          options: {
            ...row.columns[colIndex].list[newIndex].options,
            options: row.columns[colIndex].list[newIndex].options.options.map(item => ({
              ...item
            }))
          }
        })
      }

      this.selectnormalTable = row.columns[colIndex].list[newIndex]
    },
    handlenormalTableDelete (index) {
      if (this.data.list.length - 1 === index) {
        if (index === 0) {
          this.selectnormalTable = {}
        } else {
          this.selectnormalTable = this.data.list[index - 1]
        }
      } else {
        this.selectnormalTable = this.data.list[index + 1]
      }

      this.$nextTick(() => {
        this.data.list.splice(index, 1)
      })
    },
    handleClickTareget (index) {
      this.handlenormalTableAdd({
        newIndex: index
      })
    },
  },
  watch: {
    select (val) {
      this.selectnormalTable = val
    },
    selectnormalTable: {
      handler (val) {
        this.$emit('update:select', val)
      },
      deep: true
    },
    selectIndex (val) {
      this.$emit('update:selectParentIndex', val)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
