<!--
 * @Descripttion: 群数据
 * @version:
 * @Author: lw
 * @Date: 2021-05-12 16:38:03
 * @LastEditTime: 2021-12-30 14:31:44
-->
<template>
  <div class="page-warp group">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      @searchChange="searchChange"
    >
      <template #handler>
        <el-button round size="medium" icon="iconfont icon-download" type="primary" @click="fn_exportExcel"
          >导出数据</el-button
        >
      </template>
      <template #operation="{ row }">
        <el-button
          type="text"
          :disabled="row.id === 0 || row.id === 1"
          @click="
            $router.push(
              `/cloudService/groupData/detail?type=${row.id}&name=${row.name}`
            )
          "
          >查看明细数据</el-button
        >
      </template>
    </ly-table>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { groupData } from './http.js'
import XLSX from 'xlsx'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: groupData,
      dynamicColumn: [],
      titleList: [{
        title: '总社群数',
        id: 0
      }, {
        title: '新增客户群',
        id: 1
      }, {
        title: '群总人数（未去重）',
        id: 2
      }, {
        title: '群总人数（已去重）',
        id: 3
      }, {
        title: '新增入群人数',
        id: 4
      }, {
        title: '新增人数流失',
        id: 5
      }, {
        title: '新增流失率',
        id: 6
      }, {
        title: '总流失人数',
        id: 7
      }, {
        title: '总流失率',
        id: 8
      }, {
        title: '活跃人数',
        id: 9
      }, {
        title: '活跃次数',
        id: 10
      }, {
        title: '活跃率',
        id: 11
      }],
      exportTitle: null
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 140,
        layout: 'hide',
        column: [
          {
            label: '选择日期',
            prop: 'day_range',
            key: ['day_start_time', 'day_end_time'],
            search: true,
            hide: true,
            placeholder: '请输入账号名称',
            type: 'daterange',
            default: [new Date(new Date().getTime() - 6 * 8.64e7), new Date()],
            formItemProps: {
              clearable: false,
              'default-time': ['00:00:00', '00:00:00']
            },
            pickerOptions: {
              disabledDate: (time) => {
                if (this.timeOptionRange) {
                  const day1 = 29 * 8.64e7
                  const maxTime = this.timeOptionRange + day1
                  const minTime = this.timeOptionRange - day1
                  return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
                } else {
                  return time.getTime() > Date.now()
                }
              },
              onPick: (time) => {
                if (time.minDate && !time.maxDate) {
                  this.timeOptionRange = new Date(time.minDate).getTime()
                }
                if (time.maxDate) {
                  this.timeOptionRange = null
                }
              }
            }
          },
          {
            label: '选择时段',
            prop: 'hour',
            key: 'hour',
            search: true,
            hide: true,
            placeholder: '全部分组',
            type: 'select',
            source: this.getHourList,
            sourceField: {
              value: 'id',
              label: 'name'
            },
            default: -1,
            clearable: 0
          },
          {
            label: '群分组',
            prop: 'group_topic',
            key: 'group_topic_id',
            search: true,
            hide: true,
            placeholder: '全部分组',
            type: 'select',
            source: this.getTopic,
            sourceField: {
              value: 'id',
              label: 'name'
            },
            default: -1,
            clearable: 0
          },
          {
            label: '群主',
            prop: 'owner',
            key: 'owner_serial_no',
            placeholder: '全部',
            search: true,
            type: 'select',
            source: this.getOwner,
            hide: true,
            sourceField: {
              value: 'member_serial_no',
              label: 'name'
            }
          },
          {
            label: '指标名称',
            prop: 'name',
            fixed: true,
            columnProps: {
              'min-width': 160
            }
          },
          ...this.dynamicColumn
        ]
      }
    },
    excelData () {
      return this.tableData
    }
  },
  methods: {
    // 导出
    fn_exportExcel () {
      const _data = []
      for (let i = 0; i < this.tableData.length; i++) {
        const values = []
        for (const key in this.tableData[i]) {
          if (key !== 'id') {
            values.push(this.tableData[i][key])
          }
        }
        _data[i] = values
      }
      const aoa2 = [this.exportTitle, ..._data]
      const worksheet = XLSX.utils.aoa_to_sheet(aoa2)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, '群数据')
      XLSX.writeFile(workbook, '群数据.xlsx')
    },
    // 设置时间
    fn_setTime (time) {
      const _time = new Date(time * 1000)
      _time.setHours(0)
      _time.setMinutes(0)
      _time.setSeconds(0)
      return parseInt(_time.getTime() / 1000)
    },
    // 选择时段数据
    async getHourList () {
      const list = [
        {
          id: -1,
          name: '00:00  ~  24:00'
        }
      ]

      for (let i = 1; i < 25; i++) {
        const prev = (i - 1) < 10 ? '0' + (i - 1) : (i - 1)
        const next = i < 10 ? '0' + i : i
        list.push({
          id: i - 1,
          name: `${prev}:00  ~  ${next}:00`
        })
      }
      return list
    },
    // 群分组数据
    async getTopic () {
      const res = await this.COMM_HTTP.getTopic()

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }
      return res
    },
    // 群主
    async getOwner () {
      const res = await this.COMM_HTTP.getOwner({
        group_topic_id: -1
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      return res
    },
    // 添加日期、时段默认值
    formatterParams (params) {
      params.day_start_time = this.fn_setTime(params.day_start_time)
      params.day_end_time = this.fn_setTime(params.day_end_time)
      return {
        ...params
      }
    },
    // 群数据
    async queryData () {
      this.loading = true
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: this.page,
        per_page: this.per_page,
        ...this.sort,
        ...this.params
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      this.loading = false
      // 设置 tableData
      const data = this.fn_initData(res)
      this.$nextTick(() => {
        this.tableData = data
        if (this.$refs.lyTable) {
          this.$refs.lyTable.doLayout()
        }
      })
    },

    // 处理数据
    fn_initData (list) {
      this.exportTitle = ['指标名称']
      const data = []
      const dayList = []
      const countList = [[], [], [], [], [], [], [], [], [], [], [], []]
      this.dataFields = {
        指标名称: 'name'
      }
      for (let i = 0; i < this.titleList.length; i++) {
        data.push({
          name: this.titleList[i].title,
          id: this.titleList[i].id
        })
      }
      this.dynamicColumn = []
      // 设置表头
      for (const item in list) {
        this.dynamicColumn.push({
          label: this.$day(list[item].day).format('M/D'),
          prop: list[item].day,
          align: 'center'
        })

        dayList.push(list[item].day)
        countList[0].push(list[item].group_total)
        countList[1].push(list[item].group_add)
        countList[2].push(list[item].group_user_total_deduplication)
        countList[3].push(list[item].group_user_total_duplication)
        countList[4].push(list[item].group_user_add)
        countList[5].push(list[item].group_user_del)
        countList[6].push(list[item].group_user_del_rate === 0 ? list[item].group_user_del_rate : list[item].group_user_del_rate + '%')
        countList[7].push(list[item].group_user_del_total)
        countList[8].push(list[item].group_user_del_total_rate === 0 ? list[item].group_user_del_total_rate : list[item].group_user_del_total_rate + '%')
        countList[9].push(list[item].group_user_live_total)
        countList[10].push(list[item].group_live_total)
        countList[11].push(list[item].group_live_rate === 0 ? list[item].group_live_rate : list[item].group_live_rate + '%')

        for (let i = 0; i < data.length; i++) {
          data[i][list[item].day] = 0
          data[i].id = i
          this.dataFields[list[item].day] = list[item].day
        }
      }
      for (let i = 0; i < dayList.length; i++) {
        this.exportTitle.push(this.$day(dayList[i]).format('M/D'))
        for (let j = 0; j < data.length; j++) {
          data[j][dayList[i]] = countList[j][i]
        }
      }
      return data
    }
  }
  // components: {
  //   downloadExcel
  // }
}
</script>

<style lang="scss" scoped>
.group {
  ::v-deep {
    .ly-table-main{
      padding-bottom: 20px;
      margin-bottom: 0;
      // border-radius: 16px 16px 0 0;
    }
    .el-form-item:last-child {
      .el-form-item__label {
        display: none;
      }
    }
  }
}
</style>
