<template>
  <ly-table
    class="page-warp"
    ref="lyTable"
    :data="tableData"
    :option="tableOpt"
    :page="page"
    :pageSize="per_page"
    :total="total"
    @searchChange="searchChange"
    @sizeChange="handleSizeChange"
    @sortChange="handleSortChange"
    @currentChange="handleCurrentChange"
    @selectionChange="handleSelectionChange"
  >
  </ly-table>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { group } from './http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: group
    }
  },
  computed: {
    tableOpt () {
      return {
        tableKey: 'test',
        operationWidth: 250,
        column: [
          {
            label: '群名称',
            prop: 'group_name',
            search: true,
            slot: 'group_name'
          },
          {
            label: '群主',
            prop: 'group_master'
          },
          {
            label: '云端账号',
            prop: 'yun_account'
          },
          {
            label: '群码',
            prop: 'group_code'
          },
          {
            label: '群码上传日期',
            prop: 'group_name4'
          },
          {
            label: '群总人数',
            prop: 'group_account_num'
          },
          {
            label: '是否参与分配',
            prop: 'share',
            search: true
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
