var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "more-popover" },
    [
      _c(
        "el-popover",
        {
          attrs: { placement: "bottom-start", width: "180", trigger: "click" }
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "list-title" }, [
                _vm._v(_vm._s(_vm.title + " (" + _vm.list.length + ")"))
              ]),
              _c(
                "el-scrollbar",
                { staticClass: "list" },
                _vm._l(_vm.list, function(item, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "item" },
                    [
                      _vm._t(
                        "item",
                        [
                          _c(
                            "span",
                            { key: i, staticClass: "text text-ellipsis" },
                            [_vm._v(_vm._s(item))]
                          )
                        ],
                        { item: item }
                      )
                    ],
                    2
                  )
                }),
                0
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "reference" }, slot: "reference" },
            [_vm._t("reference", [_vm._v(_vm._s(_vm.list.length))])],
            2
          )
        ]
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }