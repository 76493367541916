var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "140px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "抽奖类型：", prop: "lottery_type" }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.form.lottery_type,
                callback: function($$v) {
                  _vm.$set(_vm.form, "lottery_type", $$v)
                },
                expression: "form.lottery_type"
              }
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("定时开奖")]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { placement: "top", content: "功能开发中" }
                },
                [
                  _c("el-radio", { attrs: { label: 2, disabled: "" } }, [
                    _vm._v("即抽即中")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "basic-form__item", attrs: { prop: "end_time" } },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm._v(
                _vm._s(
                  _vm.form.lottery_type === 1 ? "开奖时间" : "活动结束时间"
                ) + " "
              ),
              _vm.form.lottery_type === 1
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        placement: "top",
                        "popper-class": "q-tooltip"
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 开奖时间即活动结束时间，开奖后不允许参与活动 "
                          )
                        ]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-info-fill",
                        staticStyle: { position: "relative", top: "1px" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v("： ")
            ],
            1
          ),
          _c("el-date-picker", {
            attrs: {
              type: "datetime",
              placeholder: "请选择",
              "picker-options": _vm.endDatePickerOptions,
              "value-format": "timestamp",
              disabled: _vm.isEdit
            },
            model: {
              value: _vm.form.end_time,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "end_time",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.end_time"
            }
          })
        ],
        2
      ),
      _c(
        "el-form-item",
        { staticClass: "basic-form__item is-long" },
        [
          _c("template", { slot: "label" }, [
            _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
            _vm._v(" 邀请好友机制：")
          ]),
          _c("div", { staticClass: "q-bg is-default rules" }, [
            _c(
              "div",
              [
                _vm._v(" 每邀请 "),
                _c("el-input-number", {
                  staticClass: "number-small number-mn",
                  attrs: {
                    size: "small",
                    "controls-position": "right",
                    min: 1,
                    max: 100
                  },
                  model: {
                    value: _vm.form.invite_num,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "invite_num", $$v)
                    },
                    expression: "form.invite_num"
                  }
                }),
                _vm._v(" 人获得 "),
                _c("el-input-number", {
                  staticClass: "number-small number-mn",
                  attrs: {
                    size: "small",
                    "controls-position": "right",
                    min: 0.1,
                    max: 100,
                    precision: 2
                  },
                  model: {
                    value: _vm.form.add_rate,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "add_rate", $$v)
                    },
                    expression: "form.add_rate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "q-info text-small",
                staticStyle: { "line-height": "1.6", "margin-top": "8px" }
              },
              [
                _vm._v(" 1.用户中奖概率越高，抽中实物/虚拟奖品的机会越大；"),
                _c("br"),
                _vm._v("2.中奖概率为总值100%。 ")
              ]
            ),
            _c(
              "div",
              [
                _vm._v(" 每个用户中奖概率值限制： "),
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.form.max_type,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "max_type", $$v)
                      },
                      expression: "form.max_type"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("不设限制")
                    ]),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自定义")])
                  ],
                  1
                ),
                _vm.form.max_type === 2
                  ? [
                      _vm._v(" ："),
                      _c("el-input-number", {
                        staticClass: "number-small number-mn",
                        attrs: {
                          size: "small",
                          "controls-position": "right",
                          min: 10,
                          max: 60,
                          precision: 2
                        },
                        model: {
                          value: _vm.form.max_rate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "max_rate", $$v)
                          },
                          expression: "form.max_rate"
                        }
                      })
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        2
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "预约按钮：", prop: "btn_title" }
        },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "8",
              "show-word-limit": "",
              placeholder: "请输入预约按钮文字"
            },
            model: {
              value: _vm.form.btn_title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "btn_title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.btn_title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "活动规则：", prop: "rule" }
        },
        [
          _c("ly-editor", {
            staticClass: "act-form__editor",
            attrs: {
              title: "活动规则",
              id: "rules",
              content: _vm.form.rule,
              recoverContent: _vm.default_rules
            },
            on: {
              getContent: _vm.fn_getRules,
              focus: function($event) {
                _vm.ruleFocus = true
              },
              blur: function($event) {
                _vm.ruleFocus = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }