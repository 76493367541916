/*
 * @Author: zhan
 * @Date: 2022-06-02 10:32:02
 * @LastEditTime: 2022-06-16 12:04:13
 * @LastEditors: zhan
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

export const lnvitation = {
  reqQuery: params => axios.get(`/wecom/book-lottery/account-list?${stringify(params)}`) // 查询表格数据的接口
}

export const prize = {
  reqQuery: (params) => axios.get(`/wecom/book-lottery/reward-list?${stringify(params)}`) // 查询表格数据的接口
}
