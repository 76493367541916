<!--
 * @Author: zhanln
 * @Date: 2021-11-03 16:13:59
 * @LastEditTime: 2022-01-04 14:29:03
 * @LastEditors: zhanln
 * @Description: 活动页设置
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="70px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <!-- 根据用户信息 -->
    <el-form-item>
      <el-checkbox
        v-model="form.open_info"
        :true-label="1"
        :false-label="0"
      ></el-checkbox
      >用户未设置头像、昵称，自动加入黑名单
    </el-form-item>

    <!-- 根据用户信息及邀请人数 -->
    <el-form-item>
      <el-checkbox
        v-model="form.open_over_info"
        :true-label="1"
        :false-label="0"
      ></el-checkbox
      >用户的助力好友超
      <el-form-item prop="over_rate" class="item-inline">
        <el-input-number
          v-model="form.over_rate"
          size="small"
          controls-position="right"
          :min="1"
          :max="100"
          :precision="0"
          class="number-small"
        ></el-input-number>
      </el-form-item>
      %未设置头像、昵称，自动加入黑名单
    </el-form-item>

    <!-- 根据用户邀请速度 -->
    <el-form-item>
      <el-checkbox
        v-model="form.open_invite_limit"
        :true-label="1"
        :false-label="0"
      ></el-checkbox
      >用户在

      <el-form-item prop="invite_second" class="item-inline">
        <el-input-number
          v-model="form.invite_second"
          size="small"
          controls-position="right"
          :min="1"
          :max="100"
          :precision="0"
          class="number-small"
        ></el-input-number>
      </el-form-item>
      秒内邀请超过
      <el-form-item prop="invite_num" class="item-inline">
        <el-input-number
          v-model="form.invite_num"
          size="small"
          controls-position="right"
          :min="0"
          :max="100"
          :precision="0"
          class="number-small"
        ></el-input-number>
      </el-form-item>
      人，自动加入黑名单
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'taskSetting',

  data () {
    const checkOver = (rule, value, callback) => {
      if (this.form.open_over_info && !value) {
        callback(new Error('请设置超出率'))
      } else {
        callback()
      }
    }
    const checkSecond = (rule, value, callback) => {
      if (this.form.open_invite_limit && !value) {
        callback(new Error('请设置时间'))
      } else {
        callback()
      }
    }
    const checkLimit = (rule, value, callback) => {
      if (this.form.open_invite_limit && !value) {
        callback(new Error('请设置人数'))
      } else {
        callback()
      }
    }
    return {
      cardLoading: true,
      form: {
        open_info: 1,
        open_over_info: 1,
        over_rate: 60,
        open_invite_limit: 0,
        invite_second: 1,
        invite_num: 10
      },
      rules: {
        over_rate: [
          { validator: checkOver, required: true, trigger: 'change' }
        ],
        invite_second: [
          { validator: checkSecond, required: true, trigger: 'change' }
        ],
        invite_num: [
          { validator: checkLimit, required: true, trigger: 'change' }
        ]
      },
      base_rule: '1、邀请【邀请好友数】位好友添加企业微信，即可获得奖品。\n2、任务完成后，系统会发放奖品链接，点击即可领取。用户也可自行保存活动链接，实时了解任务进度。\n3、若邀请的用户已为老用户或已为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户助力无法获得奖品。\n5、删除好友后助力无效。'
    }
  },

  watch: {
    'form.open_over_info' (val) {
      this.$refs.formRef.validateField('over_rate')
    },
    'form.over_rate' (val) {
      this.$refs.formRef.validateField('over_rate')
    },
    'form.open_invite_limit' (val) {
      this.$refs.formRef.validateField(['invite_second', 'invite_num'])
    },
    'form.invite_second' (val) {
      this.$refs.formRef.validateField('invite_second')
    },
    'form.invite_num' (val) {
      this.$refs.formRef.validateField('invite_num')
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        this.form = data
      }
      this.cardLoading = false
    },

    // 表单校验，通过则返回 form
    verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      return formValid ? this.form : false
    }
  }
}
</script>

<style lang="scss" scoped>
.act-form {
  .item-inline {
    display: inline-block;
    margin: 0 6px;
  }

  ::v-deep .el-checkbox {
    margin-right: 6px;
  }

  .number-small {
    width: 100px;
    ::v-deep .el-input__inner {
      padding-left: 8px;
      padding-right: 36px;
    }
    ::v-deep {
      .el-input-number__decrease,
      .el-input-number__increase {
        height: 15px;
      }
    }
  }
}
</style>
