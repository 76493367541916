<template>
  <el-dialog
    title="聊天记录"
    :visible.sync="dialogVisible"
    width="1100px"
    destroy-on-close
    @open="handleOpen"
    @close="handleClose"
  >
    <div class="imBox">
      <im :msgList="msgList" ref="im">
        <template #im_header>
          <div class="searchForm">
            <el-form ref="form" :model="form" label-width="75px" :inline="true">
              <el-form-item label="消息内容：">
                <el-input
                  v-model="form.content"
                  placeholder="请输入消息内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="消息类型：">
                <el-select v-model="form.msg_type" placeholder="全部">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in msgOption"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="消息时间：">
                <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="至"
                  format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="timestamp"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="endAtOption"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  round
                  class="btn"
                  icon="iconfont icon-search"
                  @click="handleQuery(1)"
                  >查询</el-button
                >
                <el-button
                  round
                  class="btn"
                  icon="iconfont icon-data"
                  @click="handleReset"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </template>
        <template #im_footer>
          <div class="table-pagination-area ly-table__pagination">
            <el-pagination
              background
              class="fixedPagination"
              layout="total, prev, pager, next, jumper"
              @current-change="handleCurrentChange"
              :page-size="per_page"
              :current-page="page"
              :total="total"
            >
            </el-pagination>
          </div>
        </template>
      </im>
    </div>
  </el-dialog>
</template>

<script>
import Im from './im.vue'
import { recordImList } from '../../http'
const getExt = path => path.substring(path.lastIndexOf('.') + 1)
const msgType = {
  1: 'text',
  2: 'img',
  3: 'voice',
  4: 'video',
  5: 'graphic',
  6: 'businessCard',
  7: 'file',
  8: 'applet',
  9: 'videoAccount',
  10: '',
  11: '',
  12: 'place',
  13: 'system'
}
export default {
  props: {
    selfInfo: {
      type: Object
    },
    chat_id: {
      type: [String, Number]
    }
  },
  data () {
    return {
      dialogVisible: false,
      msgList: [],
      page: 1,
      per_page: 20,
      total: 0,
      form: {
        content: '',
        msg_type: ''
      },
      date: [],
      timeOptionRange: null
    }
  },

  components: {
    Im
  },
  computed: {
    msgOption () {
      const msgListType = {
        1: '文本',
        2: '图片',
        3: '语音',
        4: '视频',
        5: '图文',
        6: '名片',
        7: '文件',
        8: '小程序',
        9: '视频号'
      }
      return Object.keys(msgListType).map((item) => ({
        value: item,
        label: msgListType[item]
      }))
    },
    endAtOption () {
      return {
        onPick: ({ maxDate, minDate }) => {
          return this.onPick({ maxDate, minDate })
        },
        disabledDate: (time) => {
          if (this.timeOptionRange) {
            const secondNum = 30 * 8.64e7
            const optionTime = this.timeOptionRange.getTime()
            const startRange = (optionTime - secondNum) < (this.selfInfo.created_time * 1000 - 1 * 8.64e7) ? (this.selfInfo.created_time * 1000 - 1 * 8.64e7) : (optionTime - secondNum)
            const endRange = (optionTime + secondNum) > (this.selfInfo.last_chat_date * 1000) ? (this.selfInfo.last_chat_date * 1000) : (optionTime + secondNum)
            return time.getTime() < startRange || time.getTime() > this.fn_timeFormat(endRange, 'end') * 1000
          } else {
            return time.getTime() < this.selfInfo.created_time * 1000 - 1 * 8.64e7 || time.getTime() > this.fn_timeFormat(this.selfInfo.last_chat_date * 1000, 'end') * 1000
          }
        }
      }
    }
  },

  methods: {
    // 点击时设置
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },
    async handleQuery (page) {
      if (page) {
        this.page = page
      }
      const start = this.date.length ? this.$day(this.date[0]).unix() : this.fn_getStartTime(this.selfInfo.created_time * 1000, this.selfInfo.last_chat_date * 1000)
      const end = this.date.length ? this.fn_timeFormat(this.date[1], 'end') : this.fn_timeFormat(this.selfInfo.last_chat_date * 1000, 'end')
      const res = await recordImList.reqQuery({
        chat_id: this.chat_id,
        page: this.page,
        per_page: this.per_page,
        ...this.form,
        start,
        end
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      res.list = res.list.map((item, index) => {
        const params = {}
        // 消息类型（1-文本，2-图片，3-语音，4-视频，5-图文链接，6-个人名片，7-文件，8-小程序，9-视频号，10-聊天记录，11-视频号直播间消息，12-位置消息，13-系统消息）
        switch (item.msg_type) {
          case 1:
            params.content = item.text.content
            break
          case 2:
            params.content = item.image.url
            params.file_size = item.image.size_int
            params.file_ext = getExt(item.image.url)
            params.file_origin_name = `${Date.now()}.${getExt(item.image.url)}`
            break
          case 3:
            params.url = item.voice.url
            params.voice_time = item.voice.voice_time
            break
          case 4:
            params.url = item.video.url
            params.video_time = item.video.video_time
            params.cover_url = item.video.cover_url
            params.file_size = item.video.size_int
            params.file_ext = getExt(item.video.url)
            params.file_origin_name = `${Date.now()}.${getExt(item.video.url)}`
            break
          case 5:
            params.title = item.link.title
            params.href = item.link.href
            params.desc = item.link.desc
            params.cover_url = item.link.cover_url
            break
          case 6:
            params.avatar = item.card.avatar
            params.name = item.card.name
            params.company = item.card.company
            break
          case 7:
            params.content = item.file.url
            params.title = item.file.title
            params.size = item.file.size
            params.file_suffix = this.computedExt(getExt(item.file.url))
            break
          case 8:
            params.title = item.applet.title
            params.name = item.applet.name
            params.logo = item.applet.logo
            params.cover_url = item.applet.cover_url
            params.content = item.applet.material
            break
          case 9:
            params.title = item.video_number.title
            params.name = item.video_number.name
            params.avatar = item.video_number.logo
            params.cover_url = item.video_number.cover_url
            params.content = item.video_number.material
            break

          default:
            break
        }
        return {
          msg: {
            id: item.id,
            index: index,
            time: this.$day(item.send_date * 1000).format('YYYY-MM-DD HH:mm:ss'),
            type: msgType[item.msg_type],
            status: item.status,
            sender_suffix: item.sender_suffix,
            ...params
          },
          user: {
            // eslint-disable-next-line eqeqeq
            identity: this.selfInfo.id == item.sender_id ? 'me' : 'you',
            name: item.sender,
            avatar: item.avatar,
            suffix: item.sender_suffix
          }
        }
      })
      this.msgList = res.list
      this.total = res.total
      console.log('msgList', this.msgList)
    },

    fn_timeFormat (time, type) {
      if (type === 'end') {
        return this.$day(this.$day(time).format('YYYY-MM-DD') + ' 23:59:59').valueOf() / 1000
      }
    },

    fn_getStartTime (time, lastTime) {
      // 30 tian
      const _gap = 30 * 8.64e7

      // 如果当前时间减 30 天还大于开始聊天时间，则返回减30天
      if (lastTime - _gap > time) {
        return this.$day(this.$day(lastTime - _gap).format('YYYY-MM-DD') + ' 00:00:00').valueOf() / 1000
      } else {
        return this.$day(this.$day(time).format('YYYY-MM-DD') + ' 00:00:00').valueOf() / 1000
      }
    },
    handleReset () {
      this.form = {
        content: '',
        msg_type: ''
      }
      this.date = []
      this.handleQuery(1)
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleQuery()
      this.$refs.im.messageViewToTop()
    },
    async handleOpen () {
      this.handleQuery()
    },
    handleClose () {
      this.form = {
        content: '',
        msg_type: ''
      }
      this.date = []
      this.timeOptionRange = null
    },
    computedExt (ext) {
      const typeEnum = ['txt', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'xls', 'xlsx']
      return typeEnum.includes(ext) ? ext : 'default'
    }
  }
}
</script>
<style lang="scss" scoped>
.imBox {
  // width: calc(100vw - 70px);
  height: calc(78vh - 85px);
  display: flex;
  .searchForm {
    margin: 0 -20px;
    border-bottom: 1px solid #eaebf2;
    padding: 0 20px;
    ::v-deep {
      .el-form-item__label {
        padding: 0;
      }
      .el-date-editor--daterange.el-input__inner {
        width: 240px !important;
      }
      .el-input--medium .el-input__inner {
        width: 155px;
      }
    }
  }
  .ly-table__pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
