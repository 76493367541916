var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "助力规则：", prop: "rule_check" } },
        [
          _c(
            "div",
            { staticClass: "q-bg is-default rule" },
            [
              _vm._v(" 无门槛抽奖次数"),
              _c("el-input-number", {
                staticClass: "number-small",
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 100,
                  precision: 0,
                  disabled: _vm.allowEdit
                },
                on: {
                  blur: function($event) {
                    return _vm.fn_handleBlur($event, "old_num")
                  }
                },
                model: {
                  value: _vm.form.old_num,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "old_num", $$v)
                  },
                  expression: "form.old_num"
                }
              }),
              _vm._v("次 "),
              _c(
                "div",
                {
                  staticClass: "q-info text-small",
                  staticStyle: { "line-height": "1.6", "margin-top": "12px" }
                },
                [_vm._v(" 企业老客户无需邀请好友即可拥有的抽奖机会 ")]
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _vm._v(" 每邀请"),
                  _c("el-input-number", {
                    staticClass: "number-small no-error",
                    attrs: {
                      "controls-position": "right",
                      min: 1,
                      max: 100,
                      precision: 0,
                      disabled: _vm.allowEdit
                    },
                    on: {
                      blur: function($event) {
                        return _vm.fn_handleBlur($event, "need_invite")
                      }
                    },
                    model: {
                      value: _vm.form.need_invite,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "need_invite", $$v)
                      },
                      expression: "form.need_invite"
                    }
                  }),
                  _vm._v("人，获得"),
                  _c("el-input-number", {
                    staticClass: "number-small no-error",
                    attrs: {
                      "controls-position": "right",
                      min: 1,
                      max: 100,
                      precision: 0,
                      disabled: _vm.allowEdit
                    },
                    on: {
                      blur: function($event) {
                        return _vm.fn_handleBlur($event, "invite_lottery_num")
                      }
                    },
                    model: {
                      value: _vm.form.invite_lottery_num,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "invite_lottery_num", $$v)
                      },
                      expression: "form.invite_lottery_num"
                    }
                  }),
                  _vm._v("次抽奖机会 ")
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "12px" } },
                [
                  _vm._v(" 总抽奖机会不超过"),
                  _c("el-input-number", {
                    staticClass: "number-small",
                    attrs: {
                      "controls-position": "right",
                      min: 1,
                      max: 100,
                      precision: 0,
                      disabled: _vm.allowEdit
                    },
                    on: {
                      blur: function($event) {
                        return _vm.fn_handleBlur($event, "total_num")
                      }
                    },
                    model: {
                      value: _vm.form.total_num,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "total_num", $$v)
                      },
                      expression: "form.total_num"
                    }
                  }),
                  _vm._v("次 ")
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-form-item",
        { attrs: { label: "红包抽奖：", prop: "open_packet" } },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  disabled: _vm.allowEdit
                },
                model: {
                  value: _vm.form.open_packet,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "open_packet", $$v)
                  },
                  expression: "form.open_packet"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "q-info",
                  staticStyle: { "margin-left": "12px" }
                },
                [_vm._v("开启后奖品兑换方式将启用抽中现金红包，立即到账到零钱")]
              )
            ],
            1
          )
        ]
      ),
      _vm.form.open_packet === 1
        ? [
            _c(
              "el-form-item",
              { attrs: { label: "活动金额：", prop: "activity_amount" } },
              [
                _c(
                  "div",
                  [
                    _c("el-input-number", {
                      staticClass: "number-small",
                      staticStyle: { "margin-left": "0" },
                      attrs: {
                        "controls-position": "right",
                        min: 0.31,
                        max: 3000,
                        precision: 2
                      },
                      on: {
                        blur: function($event) {
                          return _vm.fn_handleBlur(
                            $event,
                            "activity_amount",
                            0.31
                          )
                        }
                      },
                      model: {
                        value: _vm.form.activity_amount,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "activity_amount", $$v)
                        },
                        expression: "form.activity_amount"
                      }
                    }),
                    _vm._v("元 "),
                    _c("span", { staticStyle: { "margin-left": "32px" } }, [
                      _vm._v(
                        "账户可用余额：" +
                          _vm._s(_vm.account_amount_fmt) +
                          " 元 "
                      )
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            _vm.$refs.recharge.rechargeVisible = true
                          }
                        }
                      },
                      [_vm._v("立即充值")]
                    )
                  ],
                  1
                ),
                _vm.allowEdit
                  ? _c("div", [
                      _vm._v(" 已发放金额："),
                      _c(
                        "span",
                        {
                          staticClass: "q-primary",
                          staticStyle: { "margin-right": "24px" }
                        },
                        [_vm._v(_vm._s(_vm.form.use_amount))]
                      ),
                      _vm._v(" 剩余活动金额："),
                      _c("span", { staticClass: "q-primary" }, [
                        _vm._v(_vm._s(_vm.remain_amount))
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "q-info text-small",
                    staticStyle: { "line-height": "1.6", "margin-top": "12px" }
                  },
                  [
                    _vm._v(" 1. 活动发布后，暂时从账户内冻结对应的活动金额。"),
                    _c("br"),
                    _vm._v("2. 活动发布后，活动金额支持修改。"),
                    _c("br"),
                    _vm._v(
                      "3. 每次向用户发放红包，平台收取" +
                        _vm._s(
                          !_vm.allowEdit ? _vm.fee_rate : _vm.form.fee_rate
                        ) +
                        "%手续费，手续费不足0.01元时，按0.01元计算。"
                    ),
                    _c("br"),
                    _vm._v("4. 活动金额发放完后，用户会随机抽中任意奖品项。 ")
                  ]
                )
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "中奖金额：", prop: "max_amount" } },
              [
                _c(
                  "div",
                  { staticClass: "q-bg is-default rule" },
                  [
                    _vm._v(" 总抽中红包金额不超过"),
                    _c("el-input-number", {
                      staticClass: "number-small",
                      attrs: {
                        "controls-position": "right",
                        min: 0,
                        max: 50,
                        precision: 2,
                        disabled: _vm.allowEdit
                      },
                      on: {
                        blur: function($event) {
                          return _vm.fn_handleBlur($event, "max_amount", 0)
                        }
                      },
                      model: {
                        value: _vm.form.max_amount,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "max_amount", $$v)
                        },
                        expression: "form.max_amount"
                      }
                    }),
                    _vm._v("元 "),
                    _c(
                      "div",
                      {
                        staticClass: "q-info text-small",
                        staticStyle: {
                          "line-height": "1.6",
                          "margin-top": "12px"
                        }
                      },
                      [
                        _vm._v(
                          " 用户领取金额满足抽中红包金额上限后，用户会随机抽中任意奖品项"
                        ),
                        _c("br"),
                        _vm._v(
                          " 若上限值为0时，则不限制用户抽中的红包发放上限 "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        : _vm._e(),
      _c("rd-recharge", { ref: "recharge", on: { success: _vm.fn_paySuccess } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }