var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c(
        "layout-table",
        {
          attrs: {
            pageTotal: _vm.total,
            loading: _vm.loading,
            page: _vm.page,
            searchForm: _vm.searchForm
          },
          on: {
            pageChange: _vm.pageChange,
            getList: _vm.fn_getData,
            resetList: function($event) {
              return _vm.fn_getData(true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "searchBar",
              fn: function() {
                return [
                  _c(
                    "el-form-item",
                    { attrs: { label: "员工搜索：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入员工名称", clearable: "" },
                        model: {
                          value: _vm.searchForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "name", $$v)
                          },
                          expression: "searchForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属部门：" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "wecom_department_select",
                          staticClass: "emp-select-top",
                          attrs: {
                            placeholder: "全部",
                            clearable: "",
                            "popper-class": "dep-select",
                            "popper-append-to-body": false,
                            props: _vm.defaultProps
                          },
                          on: { clear: _vm.clearDepartment },
                          model: {
                            value: _vm.searchForm.wecom_department_id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchForm,
                                "wecom_department_id",
                                $$v
                              )
                            },
                            expression: "searchForm.wecom_department_id"
                          }
                        },
                        [
                          _c(
                            "el-option",
                            {
                              attrs: {
                                label: _vm.wecom_department_value,
                                value: _vm.searchForm.wecom_department_id
                              }
                            },
                            [
                              _c("el-tree", {
                                ref: "wecom_department_tree",
                                attrs: { data: _vm.depList, "node-key": "id" },
                                on: { "current-change": _vm.handleTreeChange },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var data = ref.data
                                      return _c(
                                        "span",
                                        { staticClass: "custom-tree-node" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "line-height": "1"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-folder",
                                                staticStyle: {
                                                  "margin-right": "5px"
                                                }
                                              }),
                                              _vm._v(_vm._s(data.name) + " ")
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "系统角色：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "全部", clearable: "" },
                          model: {
                            value: _vm.searchForm.role_id,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "role_id", $$v)
                            },
                            expression: "searchForm.role_id"
                          }
                        },
                        _vm._l(_vm.searchRoleList, function(item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: { label: item.name, value: item.id }
                            },
                            [
                              _c("div", { staticClass: "flex flex-between" }, [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                item.type === 1
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "qy-tag qy-tag--primary qy-tag--mini",
                                        staticStyle: { "margin-right": "0" }
                                      },
                                      [_vm._v("官")]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "员工状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.searchForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "status", $$v)
                            },
                            expression: "searchForm.status"
                          }
                        },
                        _vm._l(_vm.statusOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "toolBar",
              fn: function() {
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          class: ["btn-reload", _vm.reload ? "active" : ""],
                          staticStyle: { "margin-right": "16px" },
                          attrs: {
                            round: "",
                            type: "primary",
                            disabled: _vm.reload
                          },
                          on: { click: _vm.fn_reload }
                        },
                        [
                          _c("i", { staticClass: "iconfont icon-data" }),
                          _vm._v(_vm._s(_vm.reload ? "同步中..." : "同步"))
                        ]
                      ),
                      _vm._v("共 " + _vm._s(_vm.total) + " 个员工 ")
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData },
              on: { "sort-change": _vm.tableSort }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "avatar, name, alias",
                  width: "200",
                  label: "全部员工"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "flex flex-start" }, [
                          _c("img", {
                            staticClass: "ly-table__main-avatar",
                            attrs: { src: scope.row.avatar_url, alt: "" },
                            on: {
                              error: function($event) {
                                return _vm.imgError(scope.row)
                              }
                            }
                          }),
                          _c("div", [
                            _c("div", { staticClass: "ly-table__main-name" }, [
                              _vm._v(_vm._s(scope.row.name))
                            ]),
                            scope.row.alias
                              ? _c(
                                  "div",
                                  { staticClass: "ly-table__main-nickName" },
                                  [
                                    _vm._v(
                                      " 别名：" + _vm._s(scope.row.alias) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "qrcode_url", label: "二维码", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.qrcode_url
                          ? _c(
                              "el-popover",
                              {
                                attrs: {
                                  trigger: "hover",
                                  placement: "top",
                                  "close-delay": 0
                                }
                              },
                              [
                                scope.row.qrcode_url
                                  ? _c("img", {
                                      attrs: {
                                        src: scope.row.qrcode_url,
                                        alt: "",
                                        width: "180"
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "name-wrapper",
                                    attrs: { slot: "reference" },
                                    slot: "reference"
                                  },
                                  [
                                    scope.row.qrcode_url
                                      ? _c("i", {
                                          staticClass: "iconfont icon-Code",
                                          staticStyle: { "font-size": "18px" }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          : [_vm._v(" - ")]
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "wecom_departments",
                  width: "140",
                  label: "所属部门",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("ly-drow", {
                          attrs: { list: scope.row.wecom_departments, dep: "" }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "client_count",
                  "min-width": "100",
                  label: "客户数",
                  sortable: "custom",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "wecom_groups_count",
                  "min-width": "100",
                  sortable: "custom",
                  label: "群聊数",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "allow_login,is_auth_app,is_auth_third_app",
                  "min-width": "150",
                  label: "授权范围",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.is_auth_app === 1
                          ? _c("ly-status", { attrs: { type: "success" } }, [
                              _vm._v("已授权")
                            ])
                          : [
                              scope.row.is_auth_app === 0
                                ? [
                                    _c(
                                      "ly-status",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("未授权")]
                                    ),
                                    _c("br"),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "q-primary",
                                        staticStyle: {
                                          "margin-left": "22px",
                                          cursor: "pointer"
                                        },
                                        on: { click: _vm.fn_jumpToLink }
                                      },
                                      [_vm._v(" 如何解决？ ")]
                                    )
                                  ]
                                : _vm._e()
                            ]
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  "min-width": "100",
                  label: "员工状态",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 1
                          ? _c("ly-status", { attrs: { type: "success" } }, [
                              _vm._v(" 已激活 ")
                            ])
                          : _vm._e(),
                        scope.row.status === 2
                          ? _c("ly-status", { attrs: { type: "danger" } }, [
                              _vm._v(" 已禁用 ")
                            ])
                          : _vm._e(),
                        scope.row.status === 4
                          ? _c("ly-status", { attrs: { type: "warning" } }, [
                              _vm._v(" 未激活 ")
                            ])
                          : _vm._e(),
                        scope.row.status === 5
                          ? _c("ly-status", { attrs: { type: "info" } }, [
                              _vm._v(" 退出企业 ")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "role_name",
                  "min-width": "100",
                  label: "系统角色",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "120",
                  align: "right",
                  "class-name": "operation-cell"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: { "flex-direction": "row-reverse" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpToDetail(scope.row)
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            ),
                            (_vm.isAdmin || _vm.is_Admin) &&
                            scope.row.role_id !== 4
                              ? [
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openEditRolesDialog(
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("权限")]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "ly-table-empty",
                  attrs: { slot: "empty" },
                  slot: "empty"
                },
                [
                  _vm._t("empty", [
                    _c("img", {
                      attrs: {
                        src: require("@assets/svg/default/no_data.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" 暂无数据 ")
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "权限设置",
                visible: _vm.editRoleDialogVisable,
                width: "480px",
                top: "20vh",
                "lock-scroll": "",
                "close-on-click-modal": false,
                "custom-class": "q-dialog"
              },
              on: {
                "update:visible": function($event) {
                  _vm.editRoleDialogVisable = $event
                },
                close: _vm.fn_coloseRoleEdit
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "roleForm",
                  attrs: { model: _vm.roleForm, size: "medium" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "设置角色：", prop: "role_id" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          on: { change: _vm.fn_changeRole },
                          model: {
                            value: _vm.roleForm.role_id,
                            callback: function($$v) {
                              _vm.$set(_vm.roleForm, "role_id", $$v)
                            },
                            expression: "roleForm.role_id"
                          }
                        },
                        _vm._l(_vm.roleList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.selectDep
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "24px" },
                          attrs: { prop: "role_id", error: _vm.roleDepTips }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "8px" } },
                            [_vm._v("选择部门：")]
                          ),
                          _c(
                            "div",
                            { ref: "rolelist", staticClass: "rolelist" },
                            [
                              _c("dep-select", {
                                ref: "depSelect",
                                attrs: { checkType: "ptoc" },
                                on: { fn_getCheck: _vm.fn_getCheck }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div"),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { round: "" },
                        on: {
                          click: function($event) {
                            _vm.editRoleDialogVisable = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.editRoleConfirm()
                          }
                        }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }