<!--
 * @Author: zhanln
 * @Date: 2022-03-25 18:46:24
 * @LastEditTime: 2022-04-07 14:33:57
 * @LastEditors: zhanln
 * @Description: 素材类型tab
-->

<template>
  <el-tabs v-model="currentStep" type="card" class="material-tabs">
    <el-tab-pane
      :label="item.label + `（${item.count}）`"
      :name="item.name"
      v-for="item of data"
      :key="item.id"
    >
      <div
        :is="`${item.name}-list`"
        v-if="item.name === currentStep"
        :currentGroupId="currentGroupId"
      ></div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import textList from '../list/text.vue'
import imageList from '../list/image.vue'
import videoList from '../list/video.vue'
import fileList from '../list/file.vue'
import appletList from '../list/applet.vue'
import articleList from '../list/article.vue'
import vlogList from '../list/vlog.vue'
// import liveList from '../list/live.vue'
export default {
  name: 'type-tab',

  props: {
    currentGroupId: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  components: {
    textList,
    imageList,
    videoList,
    fileList,
    appletList,
    articleList,
    vlogList
    // liveList
  },

  data () {
    return {
      currentStep: ''
    }
  },

  methods: {
    // tab数据
    fn_getTab (type) {
      this.currentStep = ''
      this.$nextTick(() => {
        this.currentStep = type
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.material-tabs {
  height: 100%;

  ::v-deep {
    .el-tabs__nav {
      border: none;
    }

    .el-tabs__header {
      margin-bottom: 0;
    }

    .el-tabs__content {
      position: relative;
      height: calc(100% - 40px);
    }

    .el-tabs__item {
      padding: 0 16px;
      margin: 0 4px;
      border: 1px solid #e4e7ed !important;
      border-radius: 8px 8px 0 0;
      background-color: #f7f8fc;

      &:first-child {
        margin-left: 8px;
      }

      &.is-active {
        background-color: #fff;
      }
    }

    .el-tabs__nav-wrap.is-scrollable {
      padding: 0 40px;
    }

    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      padding: 0 12px;
      border: 1px solid #e4e7ed !important;
      border-radius: 8px 8px 0 0;
    }

    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
