<!--
 * @Author: zhanln
 * @Date: 2021-11-03 16:13:59
 * @LastEditTime: 2022-01-12 15:06:01
 * @LastEditors: zhanln
 * @Description: 活动页设置
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="160px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <!-- 抽奖样式 -->
    <el-form-item label="抽奖样式：" prop="lottery_type">
      <el-radio-group v-model="form.lottery_type">
        <el-radio :label="1">方形</el-radio>
        <el-radio :label="2">圆形</el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- 活动标题 -->
    <el-form-item label="活动标题：" prop="show_title">
      <el-input
        class="act-form__input"
        v-model.trim="form.show_title"
        maxlength="15"
        show-word-limit
        placeholder="请输入活动标题"
      ></el-input>
    </el-form-item>

    <!-- 封面标题 -->
    <el-form-item label="封面标题：" prop="cover_title">
      <el-input
        class="act-form__input"
        v-model.trim="form.cover_title"
        maxlength="14"
        show-word-limit
        placeholder="请输入封面标题"
      ></el-input>
    </el-form-item>

    <!-- 活动规则 -->
    <el-form-item label="活动规则：" prop="rule">
      <ly-editor
        class="act-form__editor"
        title="活动规则"
        :content="form.rule"
        :recoverContent="base_rule"
        :maxLength="2000"
        @getContent="fn_getRule"
        @focus="fn_ruleFocus"
        @blur="fn_ruleBlur"
      ></ly-editor>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'actSetting',

  data () {
    return {
      cardLoading: true,
      form: {
        lottery_type: 1,
        show_title: '',
        cover_title: '',
        rule: ''
      },
      rules: {
        lottery_type: [
          { required: true, message: '', trigger: 'change' }
        ],
        show_title: [
          { required: true, message: '请输入活动标题', trigger: 'change' }
        ],
        cover_title: [
          { required: true, message: '请输入封面标题', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ]
      },
      base_rule: '1、邀请新用户添加企业微信为好友，即可获得抽奖机会；\n2、邀请成功后，系统会自动发放到抽奖机会到您的账号，中奖后，请根据提示领取奖品~；\n3、若邀请助力的用户为老用户或已为他们助力，则此次助力无效；\n4、请在活动时间内参与活动，如有任何问题请联系主办方。'
    }
  },

  watch: {
    'form.show_title' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'show_title',
        val: val
      })
    },
    'form.cover_title' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'cover_title',
        val: val
      })
    },
    'form.lottery_type' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'lottery_type',
        val: val
      })
    },
    'form.rule' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'rule',
        val: val
      })
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        this.form = data
      } else {
        this.fn_init()
      }
      this.cardLoading = false
    },

    // 配置默认表单
    fn_init () {
      this.form.show_title = '活动进行中'
      this.form.cover_title = '幸运天天抽'
      this.form.rule = this.base_rule
    },

    // 活动规则回调
    fn_getRule (val) {
      this.form.rule = val
    },

    fn_ruleFocus () {
      this.$store.commit('lottery/SET_INFO', {
        type: 'rule_focus',
        val: true
      })
    },

    fn_ruleBlur () {
      this.$store.commit('lottery/SET_INFO', {
        type: 'rule_focus',
        val: false
      })
    },

    // 表单校验，通过则返回 form
    verify (type) {
      let formValid = true

      if (type) {
        return this.form
      }

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      return formValid ? this.form : false
    }
  }
}
</script>
