<!--
 * @Author: zhanln
 * @Date: 2021-11-03 16:39:39
 * @LastEditTime: 2022-01-11 11:29:41
 * @LastEditors: zhanln
 * @Description: 任务设置
-->

<template>
  <div class="reward" v-loading="cardLoading">
    <div class="reward-tabs">
      <div
        class="reward-tabs__item"
        :class="[
          index === activeStep ? 'is-active' : '',
          item.isDisable && 'is-disable',
        ]"
        v-for="(item, index) in steps"
        :key="index"
        @click="fn_changeTab(item, index)"
      >
        {{ item.title }}
        <span class="circle"></span>
        <template v-if="item.status === 0">未开启</template>
        <template v-else>
          <template v-if="item.count"> {{ item.count }}人 </template>
        </template>
      </div>
    </div>

    <!-- 表单 -->
    <reward-item ref="rewardItemRef" @getForm="fn_getForm"></reward-item>

    <!-- 无库存规则 -->
    <stock ref="stockRef"></stock>
  </div>
</template>

<script>
import rewardItem from './rewardItem'
import stock from './stock'
import { mapState } from 'vuex'
export default {
  name: 'taskReward',

  data () {
    return {
      cardLoading: true,
      activeStep: 0,
      steps: [
        {
          title: '一阶任务',
          count: 0,
          status: 1,
          isDisable: false
        },
        {
          title: '二阶任务',
          count: 0,
          status: 0,
          isDisable: true
        },
        {
          title: '三阶任务',
          count: 0,
          status: 0,
          isDisable: true
        }
      ],
      step_0: null,
      step_1: null,
      step_2: null,
      baseForm: {
        id: 0,
        step: 1,
        status: 0,
        target_count: 2,
        tag_open: 0,
        finish_task_tags: '',
        reward_cover: '',
        reward_title: '',
        reward_desc: '',
        unreal: 1000,
        reward_type: 4,
        reward_detail: '',
        reward_detail_img: '',
        reward_type_id: null,
        total: 9999,
        open_stock: 0,
        code_id: null,
        code_rule: '',
        form_id: null,
        form_rule: ''
      }
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.task.isEdit,
      isCopy: state => state.task.isCopy,
      target_count_0: state => state.task.target_count_0,
      status_1: state => state.task.status_1,
      target_count_1: state => state.task.target_count_1,
      status_2: state => state.task.status_2,
      target_count_2: state => state.task.target_count_2
    })
  },

  watch: {
    target_count_0 (val) {
      this.steps[0].count = val
    },
    target_count_1 (val) {
      this.steps[1].count = val
    },
    target_count_2 (val) {
      this.steps[2].count = val
    },
    status_1 (val) {
      this.steps[1].status = val
    },
    status_2 (val) {
      this.steps[2].status = val
    }
  },

  methods: {

    // 初始化
    fn_setForm (data) {
      if (data) {
        const { steps, stock } = data
        for (let i = 0; i < steps.length; i++) {
          this.steps[i].isDisable = false

          // 复制活动，清空兑换码和表单选项
          if (this.isCopy) {
            steps[i].reward_type_id = null
          }

          this[`step_${i}`] = { ...this.baseForm, ...steps[i] }

          // 存
          this.$store.commit('task/SET_REWARD_INFO', {
            type: 'status',
            step: i,
            val: steps[i].status
          })
          this.$store.commit('task/SET_REWARD_INFO', {
            type: 'target_count',
            step: i,
            val: steps[i].target_count
          })
          this.$store.commit('task/SET_REWARD_INFO', {
            type: 'unreal',
            step: i,
            val: steps[i].unreal
          })
        }

        if (this.isCopy) {
          this.steps[1].isDisable = false
          this.steps[2].isDisable = false
        }
        this.$refs.stockRef.form = stock
      } else {
        this.steps[1].isDisable = false
        this.steps[2].isDisable = false
      }
      this.fn_setItemForm()
      this.cardLoading = false
    },

    // 设置各阶段表单
    fn_setItemForm (step = 0) {
      let form = {}

      // 如果该阶段表单不为 null， 则直接使用
      if (this[`step_${step}`]) {
        form = this[`step_${step}`]
        if (this.isEdit && !this.isCopy && form.use_count === undefined) {
          form.use_count = form.total - form.remain
        }
      } else {
        form = { ...this.baseForm }

        // 阶段
        form.step = step + 1

        // 第一阶默认开启
        form.status = step === 0 ? 1 : 0

        // 如果不是第一阶，默认任务人数 = 上阶任务人数 + 1 <= 100
        if (step > 0) {
          const prevTargetCount = this[`step_${step - 1}`].target_count
          form.target_count = prevTargetCount === 100 ? 100 : prevTargetCount + 1
          const prevUnreal = this[`step_${step - 1}`].unreal
          form.unreal = prevUnreal
        }
      }

      this.$refs.rewardItemRef.fn_setForm(form)
    },

    // 切换阶段
    fn_changeTab (item, index) {
      // 点击当前阶段或者禁用的阶段 return
      if (index === this.activeStep || item.isDisable) return false

      // 判断当前阶段是否已完成
      const rewardItem = this.$refs.rewardItemRef.verify()
      if (!rewardItem) {
        this.fn_lyMsg('info', '请先完成本阶任务的配置！')
        return false
      }

      if (index === 2 && this.status_1 !== 1) {
        this.fn_lyMsg('info', '请先开启二阶任务')
        return false
      }

      // 完成的阶段存储
      this.fn_getForm(rewardItem)

      // 切换阶段
      this.activeStep = index
      this.$store.commit('task/SET_CURRENT_STEP', this.activeStep)
      this.fn_setItemForm(index)
    },

    // 表单组件回调
    fn_getForm (obj) {
      this[`step_${this.activeStep}`] = obj
    },

    // 奖品内容设置
    fn_initReward (data) {
      const form = this.util.extend(true, {}, data)

      if (form.reward_type === 4) {
        form.reward_type_id = null
      }

      // 兑换码、表单，没修改过则原字段返回

      if (form.reward_type === 5) {
        form.reward_type_id = form.form_id ? form.form_id + '' : form.reward_type_id
        form.reward_detail = form.form_rule ? form.form_rule : form.reward_detail
      }

      if (form.reward_type === 6) {
        form.reward_type_id = form.code_id ? form.code_id + '' : form.reward_type_id
        form.reward_detail = form.code_rule ? form.code_rule : form.reward_detail
      }

      delete form.code_id
      delete form.code_rule
      delete form.form_id
      delete form.form_rule

      return form
    },

    // 复制的活动，因为清空了兑换码和表单选择，需再次校验
    fn_validSteps (index) {
      let valid = true

      if (
        this.isCopy &&
        this.activeStep !== index &&
        (
          (this[`step_${index}`].reward_type === 6 && !this[`step_${index}`].code_id) ||
          (this[`step_${index}`].reward_type === 5 && !this[`step_${index}`].form_id)
        )
      ) {
        this.fn_changeTab(this.steps[index], index)
        this.$refs.rewardItemRef.verify()
        valid = false
      }

      return valid
    },

    // 表单校验，通过则返回 form
    verify () {
      let formValid = true

      // 当前阶段校验
      const rewardItem = this.$refs.rewardItemRef.verify()
      if (!rewardItem) {
        formValid = false
      }

      if (!formValid) return false

      this.fn_getForm(rewardItem)

      // 所有阶段组装数组
      const step0 = this.fn_initReward(this.step_0)
      const steps = [step0]

      // 第二阶段校验
      if (this.step_1 && this.step_1.status === 1) {
        const validStep1 = this.fn_validSteps(1)
        if (validStep1) {
          steps.push(this.fn_initReward(this.step_1))
        } else {
          return false
        }
      }

      // 第三阶段校验
      if (this.step_2 && this.step_2.status === 1) {
        const validStep2 = this.fn_validSteps(2)
        if (validStep2) {
          steps.push(this.fn_initReward(this.step_2))
        } else {
          return false
        }
      }

      return {
        steps,
        stock: this.$refs.stockRef.form
      }
    }
  },

  components: {
    rewardItem,
    stock
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.reward {
  &-tabs {
    display: inline-flex;
    border-bottom: 1px solid #dfe1e8;
    margin-bottom: 24px;

    &__item {
      height: 42px;
      display: flex;
      padding: 0 28px;
      align-items: center;
      justify-content: center;
      border: 1px solid #dfe1e8;
      box-sizing: border-box;
      margin-bottom: -1px;
      border-radius: 8px 8px 0 0;
      user-select: none;
      background: #f7f8fc;

      .circle {
        display: inline-flex;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #454d5b;
        margin: 0 4px;
      }

      &:not(.is-active) {
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.is-active {
        color: $--color-primary;
        border-bottom-color: #fff;
        background-color: #fff;
        font-weight: 500;
        .circle {
          background-color: $--color-primary;
        }
      }

      &.is-disable {
        cursor: not-allowed;
        background-color: #f7f8fc;
        color: #eaebf2;

        .circle {
          background-color: #eaebf2;
        }
      }
    }
  }
}

.rule-form {
  border-top: 1px solid #e4e7ed;
  padding-top: 24px;

  &__radio {
    ::v-deep {
      .el-radio {
        display: block;
        line-height: 36px;
      }

      .el-radio__label {
        display: inline-flex;

        i {
          margin-left: 8px;
        }
      }
    }
  }
}

.rule-form {
  border-top: 1px solid #e4e7ed;
  padding-top: 24px;

  &__radio {
    ::v-deep {
      .el-radio {
        display: block;
        line-height: 36px;
      }

      .el-radio__label {
        display: inline-flex;

        i {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
