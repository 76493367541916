<!--
 * @Author: zhan
 * @Date: 2022-05-18 17:14:53
 * @LastEditTime: 2022-07-08 14:21:48
 * @LastEditors: zhan
-->
<template>

  <div class="page-warp">
    <ly-table class="act-list" ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :pageSize="per_page"
      :total="total" @searchChange="searchChange" @sizeChange="handleSizeChange" @sortChange="handleSortChange"
      @currentChange="handleCurrentChange" @selectionChange="handleSelectionChange">
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button round type="primary" icon="iconfont icon-plus" size="medium" @click="fn_createAct"
          :loading="createLoading">创建活动
        </el-button>
      </template>

      <!-- 列插槽 -->
      <template #title_slot="{ row }">
        <div class="flex flex-start">
          <el-image :src="row.poster_url" lazy fit="cover" style="
              width: 36px;
              height: 62px;
              margin-right: 12px;
              border-radius: 4px;
            "></el-image>
          <span style="flex: 1">{{ row.title }}</span>
        </div>
      </template>

      <!-- 活动时间 -->
      <!-- <template #time_slot="{ row }">
        <div>开始：{{ row.start_date }}</div>
        <div>结束：{{ row.end_date }}</div>
      </template> -->

      <template #show_status_header>
        <span style="margin-right: 4px">首页展示</span>
        <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
          <div slot="content">
            开启后，活动将展示在小程序首页进行推广
          </div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>

      <template #status_slot="{ row }">
        <ly-status type="success" v-if="row.status === 1">进行中</ly-status>
        <ly-status type="warning" v-else-if="row.status === 2">未开始</ly-status>
        <ly-status v-else>已结束</ly-status>
      </template>

      <template #show_status_slot="{ row, index }">
        <el-switch v-model="row.show_center" :active-value="1" :inactive-value="2"
          @change="fn_changeShow($event, row.id, index)">
        </el-switch>
      </template>

      <template #operation="{ row }">
        <ly-action :key="Date.now()">
          <el-button type="text" @click="$router.push({ path: 'users', query: { id: row.id } })">参与用户</el-button>
          <el-button type="text" @click="$router.push({ path: 'rewards', query: { id: row.id } })">奖品记录</el-button>
          <el-button type="text" @click="$router.push({ path: 'record', query: { id: row.id } })">预约记录</el-button>
          <el-button type="text" @click="$router.push({ path: 'popularize', query: { id: row.id } })">推广</el-button>
          <el-button type="text" @click="$router.push({
            path: 'edit', query: {
              id: row.id,
              type: 'edit'
            }
          })">编辑</el-button>
          <el-button type="text" @click="fn_delete(row.id)" v-if="row.status !== 1">删除</el-button>
          <el-button type="text" @click="fn_end(row.id, row.title)" v-if="row.status === 1">结束</el-button>
        </ly-action>
      </template>

    </ly-table>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { list, auth } from './http.js'
export default {
  mixins: [lyTable],

  data () {
    return {
      COMM_HTTP: list,
      actTypes: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '进行中',
          value: 1
        },
        {
          label: '未开始',
          value: 2
        },
        {
          label: '已结束',
          value: 3
        }
      ],
      createLoading: false
    }
  },

  computed: {
    tableOpt () {
      return {
        operationWidth: 300,
        column: [
          {
            label: '活动名称',
            prop: 'title',
            slot: 'title_slot',
            search: true,
            columnProps: {
              'min-width': 190
            }
          },
          {
            label: '参与用户数',
            prop: 'account_num',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '预约用户数',
            prop: 'reserve_num',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '活动结束时间',
            prop: 'end_date',
            search: true,
            key: ['start', 'end'],
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            pickerOptions: {
              disabledDate: (time) => {
                return null
              }
            },
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            },
            columnProps: {
              'min-width': 180
            }
          },
          {
            label: '活动状态',
            prop: 'status',
            slot: 'status_slot',
            type: 'select',
            source: this.actTypes,
            search: true,
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '首页展示',
            prop: 'show_status',
            slot: 'show_status_slot',
            headerSlot: 'show_status_header',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          }
        ]
      }
    }
  },

  methods: {

    // 展示状态
    async fn_changeShow (status, book_lottery_id, index) {
      const data = await this.COMM_HTTP.changeShow({
        book_lottery_id,
        status
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        const item = this.tableData[index]
        item.show_center = status === 1 ? 2 : 1
        this.$set(this.tableData, index, item)
        return false
      }
    },

    fn_delete (id) {
      this.$lyConfirm({
        title: '确认要删除这个活动吗？',
        text: '活动删除后将无法找回，您确定删除吗?'
      }).then(async () => {
        const data = await this.COMM_HTTP.destroy({
          id
        })

        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          return false
        }

        this.$message.success('活动删除成功!')
        this.queryData()
      }).catch(() => { })
    },

    fn_end (id, title) {
      this.$lyConfirm({
        title: `确认要结束"${title}"吗？`,
        text: ''
      }).then(async () => {
        const data = await this.COMM_HTTP.end({
          id
        })

        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          return false
        }

        this.$message.success('活动状态设置成功!')
        this.queryData()
      }).catch(() => { })
    },

    async fn_createAct () {
      this.createLoading = true
      const res = await auth.authMpInfo()
      if (res) {
        this.$router.push('edit')
        return true
      }

      this.$lyConfirm({
        title: '提示',
        text: '企业还未完成小程序授权，请完成授权后创建活动',
        showCancelButton: false
      }).then(async () => {
        this.$router.push('/liveSetting/authorization/applet')
      }).catch(() => { })
    }
  }
}
</script>
