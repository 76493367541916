var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "活动标题：", prop: "show_title" } },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "15",
              "show-word-limit": "",
              placeholder: "请输入活动标题"
            },
            model: {
              value: _vm.form.show_title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "show_title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.show_title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "活动规则：", prop: "rule" } },
        [
          _c("ly-editor", {
            staticClass: "act-form__editor",
            attrs: {
              title: "活动规则",
              content: _vm.form.rule,
              recoverContent: _vm.base_rule,
              maxLength: 2000
            },
            on: { getContent: _vm.fn_getRule }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }