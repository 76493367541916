<!--
 * @Descripttion: 数据分析
 * @version:
 * @Author: lw
 * @Date: 2021-04-12 11:11:44
 * @LastEditTime: 2021-12-14 15:06:59
-->
<template>
  <div class="alys" ref="alysRef" v-loading="pageLoading">
    <div class="alys-header alys-wrap">
      <!-- searchBar -->
      <div class="alys-search">
        <el-form inline size="medium">
          <el-form-item label="选择活动：" style="margin-bottom: 8px">
            <el-select
              v-model="actId"
              filterable
              style="width: 320px"
              @change="fn_changeAct"
            >
              <el-option
                v-for="item in actList"
                :key="item.value"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- searchBar end -->

      <!-- tabs -->
      <el-tabs
        v-model="tabKey"
        @tab-click="fn_setTab"
        class="ly-tabs tabs-step"
      >
        <el-tab-pane
          v-for="(item, index) in cards"
          :key="index"
          :label="item.label"
          :name="item.name"
        ></el-tab-pane>
      </el-tabs>
    </div>

    <div class="alys-container alys-wrap">
      <div class="alys-scroll" ref="scrollRef">
        <!-- 数据概览 -->
        <alys-basic title="数据概览" ref="basic"></alys-basic>

        <!-- 数据趋势 -->
        <alys-trend
          title="数据趋势"
          ref="trend"
          :actId="actId"
          :actName="actName"
          :actIndex="actIndex"
          :actStartTime="actStartTime"
          :actEndTime="actEndTime"
          @getBase="fn_getBase"
        ></alys-trend>

        <!-- 裂变层级分析 -->
        <alys-steps
          title="裂变层级分析"
          ref="steps"
          :actId="actId"
          :actName="actName"
          :actIndex="actIndex"
        ></alys-steps>

        <!-- 渠道分析 -->
        <alys-channel
          title="渠道分析"
          ref="channel"
          :actId="actId"
          :actName="actName"
          :actIndex="actIndex"
        ></alys-channel>
      </div>
    </div>
  </div>
</template>
<script>
import alysBasic from './analysis/basic'
import alysTrend from './analysis/trend'
import alysSteps from './analysis/steps'
import alysChannel from './analysis/channel'
import { activity } from './http'
export default {
  data () {
    return {
      pageLoading: true,
      COMM_HTTP: activity,
      actId: null,
      actList: [],
      cards: [
        {
          label: '数据概览',
          name: 'basic'
        },
        {
          label: '裂变层级分析',
          name: 'steps'
        },
        {
          label: '渠道分析',
          name: 'channel'
        }
      ],
      tabKey: 'basic',
      isScroll: true,
      actName: '',
      actIndex: '',
      actStartTime: '',
      actEndTime: ''
    }
  },

  async created () {
    const actId = this.$route.query.id
    if (actId) {
      this.actId = +actId
    }
    await this.taskList()
  },

  async mounted () {
    // main主体滚动监测
    this.$nextTick(() => {
      const main = this.$refs.scrollRef
      main && main.addEventListener('scroll', this.fn_getScroll)
    })
  },

  methods: {

    // 获取活动列表
    async taskList () {
      const data = await this.COMM_HTTP.reqList()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return
      }

      if (data) {
        this.actList = data
        if (!this.actId && this.actList.length) {
          this.actId = this.actList[this.actList.length - 1].id
        }
        this.fn_getName()
      } else {
        this.actList = []
      }
      this.pageLoading = false
    },

    // 获取活动名称
    fn_getName (val) {
      const id = val || this.actId
      if (this.actList.length === 0) return false

      const act = this.actList.filter(item => {
        return item.id === id
      })[0]

      this.actName = act.title
      this.actStartTime = act.start
      this.actEndTime = act.end
      this.actIndex = this.actName + id
    },

    // 切换活动
    fn_changeAct (val) {
      this.fn_getName()
    },

    // 顶部导航监听
    fn_setTab (tab) {
      this.isScroll = false
      const $el = this.$refs[tab.name].$el
      $el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })

      this.tabKey = tab.name

      setTimeout(() => {
        this.isScroll = true
      }, 300)
    },

    // 滚动监听
    fn_getScroll (e) {
      if (!this.isScroll) return false
      const main = e.target || e.srcElement || e.path[0]
      const mainList = this.cards.map(item => item.name)
      if (main === this.$refs.scrollRef) {
        const top = main.scrollTop
        const height = main.offsetHeight
        this.util.each(mainList, (i, v) => {
          if (this.$refs[v]) {
            const obj = this.$refs[v].$el
            const y = obj.offsetTop
            if (top >= y - height * 0.8) {
              this.tabKey = v
            }
          }
        })
      }
    },

    // 数据概览
    fn_getBase (data) {
      this.$refs.basic.items.forEach((item, index) => {
        item.count = data[index]
      })
    }
  },

  // 销毁滚动监听
  destroyed () {
    window.removeEventListener('scroll', this.fn_getScroll)
  },

  components: {
    alysBasic,
    alysTrend,
    alysSteps,
    alysChannel
  }
}
</script>

<style lang="scss" scoped>
.alys {
  height: 100%;
  padding-top: 20px;
  overflow: hidden;

  &-wrap {
    box-sizing: border-box;
  }

  &-header {
    margin: 0 20px 20px;
    background: #fff;
    box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24),
      0px 0px 2px 0px rgba(145, 158, 171, 0.24);
    border-radius: 16px;
    padding: 20px 20px 0 20px;
    overflow: hidden;
    ::v-deep {
      .el-tabs__header {
        margin-bottom: 0;
      }
    }
  }

  &-container {
    height: calc(100% - 123px);
    padding-right: 0;
    padding-bottom: 0;
  }

  &-scroll {
    overflow-y: auto;
    height: 100%;
  }
}
</style>
