/*
 * @Author: zhanln
 * @Date: 2021-07-26 14:58:55
 * @LastEditTime: 2021-10-29 11:14:54
 * @LastEditors: zhanln
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

// 活动
export const activity = {
  save: params => axios.post('/wecom/group-activity/save', params), // 群裂变-创建或修改活动
  groupList: params => axios.get(`/wecom/wecomGroups/batch-get?${stringify(params)}`), // 批量获取群成员信息
  show: params => axios.get(`/wecom/group-activity/show?${stringify(params)}`), // 群裂变-活动详情
  checkTitle: params => axios.post('/wecom/group-activity/has-same-name', params) // 活动名称校验
}

// 活动列表
export const activityList = {
  reqQuery: params => axios.get(`/wecom/group-activity/list?${stringify(params)}`), // 查询表格数据的接口
  currentInfo: () => axios.get('/wecom/group-activity/current-info'),
  activityStop: params => axios.post('/wecom/group-activity/stop', params),
  reqDelete: params => axios.post('/wecom/group-activity/delete', params)
}
// 草稿箱
export const drafts = {
  reqQuery: params => axios.get(`/wecom/group-activity/daft-list?${stringify(params)}`), // 查询表格数据的接口
  reqDelete: params => axios.post('/wecom/group-activity/delete', params) // 删除行数据
}
// 参与用户
export const user = {
  reqQuery: params => axios.get(`/wecom/group-activity/account-list?${stringify(params)}`),
  reqExport: params => axios.get(`/wecom/group-activity/account-export?${stringify(params)}`),
  selectList: params => axios.get(`/wecom/group-activity/group-select?${stringify(params)}`), // 选择活动
  channelSel: params => axios.get(`/wecom/group-activity/channel-list?${stringify({ page: 1, per_page: 1000, ...params })}`), // 参与用户/黑名单 渠道下拉选择项
  boardQuery: params => axios.get(`/wecom/group-activity/account-stc?${stringify(params)}`), // 数据看板
  groupSelect: params => axios.get(`/wecom/group-activity/account-group-select?${stringify(params)}`)// 群下拉数据
}
// 奖品记录
export const record = {
  reqQuery: params => axios.get(`/wecom/group-activity/reward-list?${stringify(params)}`),
  reqExport: params => axios.get(`/wecom/group-activity/reward-export?${stringify(params)}`),
  selectList: params => axios.get(`/wecom/group-activity/group-select?${stringify(params)}`), // 选择活动
  formList: params => axios.post('/scrm-api/form/business/list', params),
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`)
}
// 活动群
export const group = {
  reqQuery: params => axios.get(`/wecom/group-activity/group-list?${stringify(params)}`)
}
// 渠道推广
export const popularize = {
  reqQuery: params => axios.get(`/wecom/group-activity/channel-list?${stringify(params)}`),
  reqDelete: params => axios.post('/wecom/group-activity/delete-channel', params),
  channelCreate: params => axios.post('/wecom/group-activity/create-channel', params),
  channelUpdate: params => axios.post('/wecom/group-activity/update-channel', params),
  selectList: params => axios.get(`/wecom/group-activity/group-select?${stringify(params)}`) // 选择活动
}
// 兑换码
export const exchange = {
  resendReward: params => axios.post('/wecom/group-activity/resend-reward', params), // 奖品重发
  reqQuery: params => axios.post('/wecom/task/code-detail', params) // 未发放兑换码列表
}
// 创建活动选择群
export const groupList = {
  reqQuery: params => axios.get(`/wecom/group-activity/group-sel-list?${stringify(params)}`)
}
// 表单
export const formList = {
  reqQuery: params => axios.post('/scrm-api/form/business/list', params)
}

// 活动群管理
export const groupManage = {
  reqQuery: params => axios.get(`/wecom/group-activity/group-list?${stringify(params)}`),
  actList: params => axios.get('/wecom/group-activity/doing-activity-sel'),
  searchData: params => axios.get(`/wecom/group-activity/group-search?${stringify(params)}`),
  update: params => axios.post('/wecom/group-activity/update-group', params),
  delete: params => axios.post('/wecom/group-activity/delete-group', params),
  add: params => axios.post('/wecom/group-activity/create-group', params),
  ownerList: params => axios.get(`/wecom/robot/group/change-owner-sel?${stringify(params)}`),
  changeOwner: params => axios.post('/wecom/robot/group/change-owner', params)
}

// 数据分析
export const analyse = {
  overview: params => axios.get(`/wecom/group-activity/data-overview?${stringify(params)}`), // 数据概览
  level: params => axios.get(`/wecom/group-activity/level-analyze?${stringify(params)}`), // 层级分析
  channel: params => axios.get(`/wecom/group-activity/channel-analyze?${stringify(params)}`) // 层级分析
}
