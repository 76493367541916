var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["article", _vm.form.source === 2 ? "article-high" : ""] },
    [
      _c(
        "el-scrollbar",
        { staticClass: "article-scroll" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "formRef",
              staticClass: "edit-form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
                size: "medium"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "素材名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "edit-form__input",
                    attrs: {
                      maxlength: "20",
                      "show-word-limit": "",
                      placeholder: "请输入素材名称"
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
                    _vm._v(" 分组：")
                  ]),
                  _c(
                    "el-select",
                    {
                      staticClass: "edit-form__input",
                      model: {
                        value: _vm.form.group_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "group_id", $$v)
                        },
                        expression: "form.group_id"
                      }
                    },
                    _vm._l(_vm.groupList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
                    _vm._v(" 文章来源：")
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.source,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "source", $$v)
                        },
                        expression: "form.source"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("图文转载")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("原创文章")
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "el-form-item",
                { key: "title", attrs: { label: "文章标题：", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "edit-form__input",
                    attrs: {
                      maxlength: "20",
                      "show-word-limit": "",
                      placeholder: "请输入文章标题"
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "title",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _vm.form.source === 1
                ? _c(
                    "el-form-item",
                    {
                      key: "art_link",
                      attrs: { label: "文章链接：", prop: "art_link" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "edit-form__input",
                        attrs: {
                          maxlength: "250",
                          "show-word-limit": "",
                          placeholder: "请输入文章链接"
                        },
                        model: {
                          value: _vm.form.art_link,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "art_link",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.art_link"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "链接摘要：" } },
                [
                  _c("el-input", {
                    staticClass: "edit-form__input",
                    attrs: {
                      maxlength: "170",
                      "show-word-limit": "",
                      placeholder: "请输入链接摘要"
                    },
                    model: {
                      value: _vm.form.art_desc,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "art_desc",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.art_desc"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { key: "cover_image", attrs: { prop: "cover_image" } },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _vm._v("主图 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "light",
                            placement: "right",
                            "popper-class": "q-tooltip is-light"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    margin: "24px 24px 16px",
                                    width: "164px",
                                    background: "#f2f7fe",
                                    padding: "16px 24px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-16",
                                      staticStyle: { "text-align": "center" }
                                    },
                                    [
                                      _vm._v(
                                        " 上传文章主图将会展示在 聊天会话框中 "
                                      )
                                    ]
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "https://image.01lb.com.cn//uploads/wecom/22/0331/16486983611e5D6ZOE.png",
                                      width: "164",
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _c("i", {
                            staticClass: "iconfont icon-info-fill q-info"
                          })
                        ]
                      ),
                      _vm._v("： ")
                    ],
                    1
                  ),
                  _c("div", { staticClass: "q-info text-small" }, [
                    _vm._v(" 支持JPG、PNG格式，图片小于2M，尺寸建议500*500px ")
                  ]),
                  _c("ly-upload", {
                    attrs: {
                      img: _vm.form.cover_image,
                      tipIcon: "el-icon-plus"
                    },
                    on: { getImg: _vm.fn_getPoster }
                  })
                ],
                2
              ),
              _vm.form.source === 2
                ? _c(
                    "el-form-item",
                    {
                      key: "art_content",
                      attrs: { label: "正文：", prop: "art_content" }
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "article-content",
                            _vm.contentError !== "" ? "error" : ""
                          ]
                        },
                        [
                          _c("wang-editor", {
                            staticClass: "wangEditor",
                            attrs: {
                              refName: "article",
                              content: _vm.form.art_content,
                              pasteIgnoreImg: false,
                              uploadImg: "",
                              uploadVideo: "",
                              excludeMenus: ["code"],
                              placeholder: "请输入文章正文",
                              setCont: function(html) {
                                _vm.fn_setDetail(html)
                              }
                            },
                            on: { getText: _vm.fn_getContentText }
                          }),
                          _c(
                            "div",
                            { staticClass: "article-content__footer" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.contentText.length) + "/4000 "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }