<!--
 * @Author: zhanln
 * @Date: 2021-08-23 10:37:18
 * @LastEditTime: 2021-12-31 17:35:27
 * @LastEditors: zhanln
 * @Description: 基本信息
-->

<template>
  <el-form
    :model="basicForm"
    :rules="basicFormRules"
    ref="basicFormRef"
    label-width="125px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <el-form-item label="活码名称" prop="title">
      <el-input
        v-model.trim="basicForm.title"
        maxlength="30"
        show-word-limit
        placeholder="名称不会展示给用户，用于企业记录渠道名称或使用场景"
        style="width: 420px"
        @change="fn_setForm($event, 'title')"
      ></el-input>
    </el-form-item>
    <el-form-item label="所属分组" required>
      <el-select
        v-model="basicForm.group_id"
        filterable
        @change="fn_setForm($event, 'group_id')"
      >
        <el-option
          v-for="item in groupList"
          :key="item.id"
          :label="item.groupName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="涨粉账号" :error="userError">
      <template slot="label"><span class="q-danger">*</span> 涨粉账号</template>
      <ly-emp-btn
        btnText="添加涨粉账号"
        :max="100"
        :isReal="true"
        :datafilter="true"
        :checkList="basicForm.wecom_user_ids_str"
        @getEmpIdArr="fn_getEmpId"
      ></ly-emp-btn>
    </el-form-item>
    <el-form-item label="欢迎语" required :error="wordError">
      <ly-editor
        title="欢迎语"
        id="prizeWords"
        :content="basicForm.wel_words"
        :insetMenu="['用户昵称']"
        :recoverContent="wel_words_base"
        :error="wordError !== ''"
        emoji
        :maxLength="800"
        style="width: 100%; max-width: 560px"
        @getContent="fn_getWelWords"
      ></ly-editor>
      <div class="basic-attach">
        <msg-template
          v-for="(item, i) in basicForm.attachments"
          :title="`附件${i + 1}：${getMsgType(item.msg_type)}`"
          :class="`type${item.msg_type}`"
          :msg="item"
          :key="item.temp"
          @change="handleAction"
        />
        <el-button
          size="small"
          icon="iconfont icon-plus"
          type="primary"
          round
          @click="handleAddContent"
        >
          添加图片/链接/小程序</el-button
        >
      </div>
    </el-form-item>
    <el-form-item label="失效时间" required>
      <el-radio-group
        v-model="basicForm.expire_type"
        @change="fn_setForm($event, 'expire_type')"
      >
        <el-radio :label="1">永不失效</el-radio>
        <el-radio :label="2">自定义</el-radio>
      </el-radio-group>
      <el-form-item
        class="expire_time"
        :error="timeError"
        v-if="basicForm.expire_type === 2"
      >
        <el-date-picker
          type="datetime"
          placeholder="请选择时间"
          v-model.trim="basicForm.expire_time"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm"
          class="basic-time"
          popper-class="no-current"
          :picker-options="exPickerOptions"
          @change="fn_setForm($event, 'expire_time')"
        ></el-date-picker>
      </el-form-item>
    </el-form-item>
    <add-attach ref="addAttachRef" @push="fn_pushAttach"></add-attach>
  </el-form>
</template>

<script>
import * as lyCheck from '@/common/checkSpecificKey'
import lyEditor from '@/components/lyEditor'
import addAttach from '../components/addAttach'
import msgTemplate from '../components/msgTemplate'
import { groupManagement } from '../http.js'
export default {
  components: {
    lyEditor,
    addAttach,
    msgTemplate
  },
  data () {
    const checkName = (rule, value, callback) => {
      if (value !== null && value !== '') {
        if (!lyCheck.checkSpecificKey(value, ['-'])) {
          callback(new Error('名称中请勿包含特殊字符'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入活码名称'))
      }
    }
    return {
      cardLoading: true,
      basicForm: {
        title: '',
        group_id: '0',
        wecom_user_ids: null,
        wecom_user_ids_str: null,
        wel_words: '#用户昵称# 你终于找到我了，有什么问题都可以咨询我哦~',
        attachments: [],
        expire_type: 1,
        expire_time: this.overTimeStamp()
      },
      groupList: [
        {
          id: '0',
          grouName: '默认分组'
        }
      ],
      basicFormRules: {
        title: [
          { validator: checkName, required: true, trigger: 'change' }
        ]
      },
      empError: '',
      wel_words_base: '#用户昵称# 你终于找到我了，有什么问题都可以咨询我哦~',
      wordError: '',
      timeError: '',
      userError: '',
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        selectableRange: '00:00:00 - 23:59:59'
      }
    }
  },

  computed: {
    isReady () {
      return this.$store.getters['qrcode/GET_READY']
    },
    exPickerOptions () {
      const now = new Date()
      const today = new Date().setHours(0, 0, 0, 0)
      const pick = new Date(new Date(this.basicForm.expire_time).setHours(0, 0, 0, 0)).getTime()
      let selectableRange = '00:00:00 - 23:59:59'
      if (pick === today) {
        selectableRange = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} - 23:59:59`
      }
      return {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
        selectableRange
      }
    }
  },

  watch: {
    async isReady (val) {
      if (val) {
        if (this.$store.getters['qrcode/GET_EDIT']) {
          this.basicForm = this.$store.getters['qrcode/GET_BASIC']
          this.basicForm.wecom_user_ids_str = this.basicForm.wecom_user_ids.map(item => item.id)
        }
        await this.fn_getGroup()
        this.cardLoading = false
      }
    },
    'basicForm.expire_type' (val) {
      if (val === 1) {
        this.timeError = ''
      } else {
        if (!this.basicForm.expire_time) {
          this.basicForm.expire_time = this.overTimeStamp()
        }
      }
    },
    'basicForm.wel_words' (val) {
      this.wordError = val ? '' : '请输入好友欢迎语'
    },
    'basicForm.expire_time' (val) {
      if (val) {
        const now = new Date()
        const today = new Date().setHours(0, 0, 0, 0)
        const pick = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime()
        if (pick === today) {
          this.pickerOptions.selectableRange = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} - 23:59:59`
        } else {
          this.pickerOptions.selectableRange = '00:00:00 - 23:59:59'
        }
        if (new Date(val).getTime() < now.getTime()) {
          this.timeError = '需大于当前时间'
        } else {
          this.timeError = ''
        }
      } else {
        this.timeError = '请选择时间'
      }
    }
  },

  methods: {

    // 分组
    async fn_getGroup () {
      const data = await groupManagement.getTopicFilter()
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.btnLoading = false
        return false
      }
      this.groupList = data
    },

    // 初始失效时间
    overTimeStamp () {
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }

      const _maxTimeStamp = new Date(_date).getTime() + (7 * 24 * 60 * 60 * 1000)
      // 设置秒数为0
      const _setSecong = new Date(_maxTimeStamp).setSeconds(0)
      const _time = this.util.timeTofmt(_setSecong, 'yyyy-MM-dd hh:mm:ss')
      this.fn_setForm(_time, 'expire_time')
      return _time
    },

    // 涨粉账号回调
    fn_getEmpId (ids) {
      this.basicForm.wecom_user_ids = ids
      this.basicForm.wecom_user_ids_str = ids.length > 0 ? ids.map(item => item.id) : null
      this.fn_setForm(this.basicForm.wecom_user_ids, 'wecom_user_ids')
      this.$refs.basicFormRef.validateField('wecom_user_ids_str')
      this.fn_checkEmp()
    },

    // 校验涨粉账号
    fn_checkEmp () {
      let valid = true

      const users = this.basicForm.wecom_user_ids_str

      if (!users) {
        this.userError = '请选择涨粉账号'
        valid = false
      } else {
        this.userError = ''
      }

      return valid
    },

    // 欢迎语回调
    fn_getWelWords (content) {
      if (this.isReady) {
        this.basicForm.wel_words = content
        this.fn_setForm(content, 'wel_words')
      }
    },

    // 失效时间限制（当天）
    disabledDate (time) {
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _minTime = _date.getTime() - 24 * 60 * 60 * 1000
      return time.getTime() < _minTime
    },

    handleAddContent () {
      if (this.basicForm.attachments.length >= 9) {
        this.$message.error('最多可添加9条附件消息')
        return false
      }
      this.$refs.addAttachRef.visible = true
    },

    // 添加附件
    fn_pushAttach (msg) {
      if (msg.temp) {
        for (let i = 0; i < this.basicForm.attachments.length; i++) {
          if (this.basicForm.attachments[i].temp === msg.temp) {
            this.basicForm.attachments.splice(i, 1, msg)
            break
          }
        }
      } else {
        this.basicForm.attachments.push({
          temp: Date.now(), // 增加唯一标识用于调整顺序
          ...msg
        })
      }
      this.fn_setForm(this.basicForm.attachments, 'attachments')
    },

    getMsgType (code) {
      let type = ''
      switch (code) {
        case 2001:
          type = '文本'
          break
        case 2002:
          type = '图片'
          break
        case 2004:
          type = '视频'
          break
        case 2005:
          type = '链接'
          break
        case 2010:
          type = '文件'
          break
        case 2013:
          type = '小程序'
          break
      }
      return type
    },

    handleAction (action, msg) {
      switch (action) {
        case 'edit':
          this.handleMsgEdit(msg)
          break
        case 'up':
          this.handleMsgUp(msg)
          break
        case 'down':
          this.handleMsgDown(msg)
          break
        case 'remove':
          this.handleMsgRemove(msg)
          break
      }
    },
    // 修改消息
    handleMsgEdit (msg) {
      this.$refs.addAttachRef.handleEdit(msg)
    },
    // 下移
    handleMsgDown ({ temp }) {
      this.basicForm.attachments.some((e, i) => {
        if (e.temp === temp) {
          const item = this.basicForm.attachments.splice(i, 1)[0]
          this.$nextTick(() => {
            this.basicForm.attachments.splice(i + 1, 0, item)
            this.fn_setForm(this.basicForm.attachments, 'attachments')
          })
        }
      })
    },
    // 上移
    handleMsgUp ({ temp }) {
      this.basicForm.attachments.some((e, i) => {
        if (i !== 0 && e.temp === temp) {
          const item = this.basicForm.attachments.splice(i, 1)[0]
          this.$nextTick(() => {
            this.basicForm.attachments.splice(i - 1, 0, item)
            this.fn_setForm(this.basicForm.attachments, 'attachments')
          })
        }
      })
    },
    // 移除消息
    handleMsgRemove ({ temp }) {
      this.basicForm.attachments = this.basicForm.attachments.filter(e => {
        return e.temp !== temp
      })
      this.fn_setForm(this.basicForm.attachments, 'attachments')
    },

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('qrcode/SET_FORM', {
        form: 'basic',
        name: type,
        val: val
      })
    },

    // 表单验证
    verify () {
      let formValid = true
      const userError = this.fn_checkEmp()

      if (this.wordError !== '' || !userError) {
        formValid = false
      }

      if (this.basicForm.expire_type === 2) {
        if (!this.basicForm.expire_time) {
          this.timeError = '请选择时间'
          formValid = false
        } else if (new Date(this.basicForm.expire_time) < Date.now()) {
          this.timeError = '需大于当前时间'
          formValid = false
        }
      }

      this.$refs.basicFormRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      return formValid
    }
  }
}
</script>

<style lang="scss" scoped>
.basic {
  &-time {
    width: 200px;
    margin-left: 16px;
  }

  &-attach {
    margin-top: 4px;

    .type2002,
    .type2005 {
      width: 271px;
    }

    .type2013 {
      width: 205px;
    }
  }
}

.expire_time {
  display: inline-block;

  ::v-deep .el-form-item__error {
    left: 16px;
  }
}
</style>
