var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile" },
    [
      _c("div", { staticClass: "mobile-topbar" }, [
        _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
        _vm._m(0)
      ]),
      _c("div", { staticClass: "mobile-titlebar" }, [
        _c("i", { staticClass: "el-icon-arrow-left" }),
        _c(
          "div",
          { staticClass: "flex flex-column" },
          [
            _vm.type === "private"
              ? [
                  _c("span", [_vm._v("张三")]),
                  _c("span", { staticClass: "text-small" }, [
                    _vm._v("零一裂变（深圳）科技有限公司")
                  ])
                ]
              : [_c("span", [_vm._v("群名称")])]
          ],
          2
        ),
        _c("i", { staticClass: "el-icon-more" })
      ]),
      _c(
        "el-scrollbar",
        { staticClass: "mobile-container" },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-column q-info text-small",
              staticStyle: { "line-height": "2", "margin-top": "12px" }
            },
            [
              _vm.type === "private"
                ? [
                    _c("span", [
                      _vm._v("您已添加了张三，现在可以开始聊天了。")
                    ]),
                    _c("span", [
                      _vm._v("对方为企业微信用户，"),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v("了解更多。")
                      ])
                    ])
                  ]
                : [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$day(Date.now()).format("YYYY年MM月DD HH:mm:ss")
                        )
                      )
                    ])
                  ]
            ],
            2
          ),
          _vm._l(_vm.msg, function(item) {
            return _c("div", { staticClass: "mobile-contact" }, [
              _c("div", { staticClass: "mobile-contact__avatar" }, [
                _c("img", {
                  attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
                })
              ]),
              _c(
                "div",
                { staticClass: "mobile-contact__dialog" },
                [
                  item.msg_type === 2001
                    ? _c("el-input", {
                        ref: "refRule",
                        refInFor: true,
                        attrs: {
                          type: "textarea",
                          value: _vm.at
                            ? "@所有人 " + item.msg_content
                            : item.msg_content,
                          autosize: "",
                          resize: "none",
                          readonly: ""
                        }
                      })
                    : _vm._e(),
                  item.messageType === 4
                    ? _c("el-input", {
                        ref: "refRule",
                        refInFor: true,
                        attrs: {
                          type: "textarea",
                          value: item.welcomeContent,
                          autosize: "",
                          resize: "none",
                          readonly: ""
                        }
                      })
                    : _vm._e(),
                  item.msg_type === 2002 || item.messageType === 1
                    ? _c("el-image", {
                        staticClass: "type-pic",
                        attrs: {
                          src: item.msg_content || item.imageUrl,
                          fit: "contain"
                        }
                      })
                    : _vm._e(),
                  item.msg_type === 2004
                    ? _c("div", { staticClass: "type-video" }, [
                        _c("video", { attrs: { src: item.href } }),
                        _c("img", {
                          staticClass: "play-btn",
                          attrs: { src: require("@/assets/images/play.png") }
                        })
                      ])
                    : _vm._e(),
                  item.msg_type === 2010
                    ? _c("div", { staticClass: "type-file flex" }, [
                        _c("div", { staticClass: "file-temp" }, [
                          _c("span", { staticClass: "file-title" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _c("img", {
                            staticClass: "file-icon",
                            attrs: { src: require("@/assets/images/doc.png") }
                          })
                        ])
                      ])
                    : _vm._e(),
                  item.msg_type === 2005 || item.messageType === 2
                    ? _c("div", { staticClass: "link" }, [
                        _c("div", { staticClass: "link-title" }, [
                          _vm._v(_vm._s(item.title || item.linkTitle))
                        ]),
                        _c(
                          "div",
                          { staticClass: "link-bottom" },
                          [
                            _c("div", { staticClass: "link-desc" }, [
                              _vm._v(_vm._s(item.desc || item.linkDsc))
                            ]),
                            _c("el-image", {
                              staticStyle: { width: "48px", height: "48px" },
                              attrs: {
                                src: item.msg_content || item.linkPicUrl,
                                fit: "contain"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  item.msg_type === 2013 || item.messageType === 3
                    ? _c(
                        "div",
                        [_c("app-poster", { attrs: { applet: item } })],
                        1
                      )
                    : _vm._e(),
                  !item.msg_type && !item.messageType
                    ? _c("div", { staticClass: "announcement" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("@所有人")
                        ]),
                        _c("div", {
                          directives: [
                            {
                              name: "emoji",
                              rawName: "v-emoji",
                              value: item.msg_content,
                              expression: "item.msg_content"
                            }
                          ]
                        })
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "iconfont icon-a-CellularConnection" }),
      _c("i", { staticClass: "iconfont icon-Wifi" }),
      _c("i", { staticClass: "iconfont icon-Battery" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }