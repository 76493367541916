var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qr-analysis" }, [
    _c(
      "div",
      { staticClass: "qr-analysis-wrap" },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { inline: "", size: "medium" } },
          [
            _c("el-form-item", { attrs: { label: "渠道：" } }, [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeChannelType },
                      model: {
                        value: _vm.channelType,
                        callback: function($$v) {
                          _vm.channelType = $$v
                        },
                        expression: "channelType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "mkdir" } }, [
                        _vm._v("按分组查看")
                      ]),
                      _c("el-radio", { attrs: { label: "file" } }, [
                        _vm._v("按活码查看")
                      ])
                    ],
                    1
                  ),
                  _c("tree-select", {
                    ref: "treeSelect",
                    staticClass: "ml-24",
                    attrs: {
                      treeData: _vm.groupList,
                      max: 100,
                      empTotal: _vm.empTotal,
                      chooseType: _vm.channelType,
                      inputPlaceholder:
                        _vm.channelType == "file"
                          ? "请输入活码"
                          : "请输入分组名称",
                      placeholder:
                        _vm.channelType == "file" ? "请选择活码" : "请选择分组",
                      title:
                        _vm.channelType == "file" ? "请选择活码" : "请选择分组",
                      empAll:
                        _vm.channelType == "file" ? "全部活码" : "全部分组"
                    },
                    on: { getEmpId: _vm.getEmpId }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "navTabs" },
      [
        _c(
          "el-row",
          { attrs: { type: "flex", justify: "space-between" } },
          [
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "ly-tabs tabs-step mb-0",
                    on: { "tab-click": _vm.fn_setTab },
                    model: {
                      value: _vm.navIndex,
                      callback: function($$v) {
                        _vm.navIndex = $$v
                      },
                      expression: "navIndex"
                    }
                  },
                  _vm._l(_vm.tabs, function(item, index) {
                    return _c("el-tab-pane", {
                      key: index,
                      attrs: {
                        label: item.name,
                        name: item.name,
                        index: item.name
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "qr-analysis-panel grey-wrapper",
        attrs: { id: "dataPanel" }
      },
      [_c("analysis-panel", { attrs: { panelInfo: _vm.panelInfo } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "time-wrapper grey-wrapper",
        attrs: { id: "growthTrendPanel" }
      },
      [
        _c("timeSelect", {
          attrs: { startTime: _vm.startTime },
          on: { emitInfo: _vm.timeSelectEmit }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "grey-wrapper card-wrapper" },
      [
        _c("el-card", [
          _c("div", { staticClass: "main-title" }, [_vm._v("增长趋势")]),
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.initMapChart },
                  model: {
                    value: _vm.radiosIndex,
                    callback: function($$v) {
                      _vm.radiosIndex = $$v
                    },
                    expression: "radiosIndex"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v("看渠道增长")
                  ]),
                  _c("el-radio", { attrs: { label: 6 } }, [
                    _vm._v("看指标增长")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-20" },
            [
              _c(
                "el-row",
                { staticClass: "flex flex-between" },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex_item" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.radioButtonChartsChange },
                          model: {
                            value: _vm.radioButtonCharts,
                            callback: function($$v) {
                              _vm.radioButtonCharts = $$v
                            },
                            expression: "radioButtonCharts"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "用户数" } }),
                          _c("el-radio-button", {
                            attrs: { label: "新增用户数" }
                          }),
                          _c("el-radio-button", {
                            attrs: { label: "新增留存率" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex remarksInfo ml-18" },
                        [
                          _vm._v(" 统计说明 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "top",
                                "popper-class": "q-tooltip"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(" 用户数：当天留存的用户数"),
                                  _c("br"),
                                  _vm._v(
                                    " 新增用户数：扫码添加（包含当天已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算；合计：每日新增用户数之和"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 新增留存率：新增且当天未删除的用户数/当天新增用户数；合计：新增且该段时间内未删除的用户数/该段时间内新增用户数 "
                                  )
                                ]
                              ),
                              _c("i", {
                                staticClass: "iconfont icon-info-fill",
                                staticStyle: { color: "#b4bbcc" }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.tableData.length > 0
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            round: "",
                            icon: "iconfont icon-download"
                          },
                          on: { click: _vm.exportExcel }
                        },
                        [_vm._v("导出图表")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("lyChart", {
                attrs: { option: _vm.chartOption, height: "450px" }
              }),
              _vm.tableData.length > 0
                ? [
                    _vm.radiosIndex == 3
                      ? _c(
                          "el-table",
                          {
                            ref: "tableRef",
                            staticClass: "mt-40",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              "max-height": "600",
                              border: "",
                              "show-summary":
                                _vm.radioButtonCharts == "用户数"
                                  ? false
                                  : true,
                              "summary-method": _vm.getSummaries
                            }
                          },
                          [
                            _vm._l(_vm.tableHeader, function(item, ind) {
                              return _c("el-table-column", {
                                key: ind,
                                attrs: {
                                  align: "center",
                                  prop: item.prop,
                                  label: item.label
                                }
                              })
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "ly-table-empty",
                                attrs: { slot: "empty" },
                                slot: "empty"
                              },
                              [
                                _vm._t("empty", [
                                  _c("img", {
                                    attrs: {
                                      src: require("@assets/svg/default/no_data.svg"),
                                      alt: ""
                                    }
                                  }),
                                  _vm._v(" 暂无数据 ")
                                ])
                              ],
                              2
                            )
                          ],
                          2
                        )
                      : _c(
                          "el-table",
                          {
                            ref: "contrastTableRef",
                            staticClass: "mt-40",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.contrastTableData,
                              "show-summary": "",
                              border: "",
                              "max-height": "600",
                              "summary-method": _vm.getContrastSummaries
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "时间", prop: "time" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "用户数", prop: "aliveUserCount" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "新增用户数",
                                prop: "addUserCount"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "新增留存率",
                                prop: "addRetentionRate"
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "ly-table-empty",
                                attrs: { slot: "empty" },
                                slot: "empty"
                              },
                              [
                                _vm._t("empty", [
                                  _c("img", {
                                    attrs: {
                                      src: require("@assets/svg/default/no_data.svg"),
                                      alt: ""
                                    }
                                  }),
                                  _vm._v(" 暂无数据 ")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "grey-wrapper card-wrapper mt-16",
        attrs: { id: "channelPanel" }
      },
      [
        _c(
          "el-card",
          [
            _c(
              "div",
              { staticClass: "flex flex-between mb-40" },
              [
                _c("div", { staticClass: "main-title" }, [_vm._v("渠道对比")]),
                !_vm.contrastEmpty
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          round: "",
                          icon: "iconfont icon-download"
                        },
                        on: { click: _vm.exportContrast }
                      },
                      [_vm._v(" 导出图表")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    !_vm.contrastEmpty
                      ? _c("lyChart", {
                          attrs: { option: _vm.customerData, height: "400px" }
                        })
                      : [
                          _c("div", { staticClass: "emptytitle" }, [
                            _vm._v("用户数")
                          ]),
                          _c("div", { staticClass: "emptyCir" })
                        ]
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    !_vm.contrastEmpty
                      ? _c("lyChart", {
                          attrs: {
                            option: _vm.addCustomerData,
                            height: "400px"
                          }
                        })
                      : [
                          _c("div", { staticClass: "emptytitle" }, [
                            _vm._v("新增用户数")
                          ]),
                          _c("div", { staticClass: "emptyCir" })
                        ]
                  ],
                  2
                )
              ],
              1
            ),
            _vm.channelData.length > 0
              ? _c(
                  "el-table",
                  {
                    ref: "contrastTable",
                    staticClass: "mt-40",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.channelData,
                      "max-height": "600",
                      border: ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "name", label: "渠道" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "aliveUserCount",
                        label: "用户数"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(" 用户数 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      "popper-class": "q-tooltip"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      [_vm._v("留存的用户数")]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont icon-info-fill"
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2544146349
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "addUserCount",
                        label: "新增用户数"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(" 新增用户数 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      "popper-class": "q-tooltip"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      [
                                        _vm._v(
                                          " 扫码添加（包含当天已删除）的用户总数，此前通过活码添加再删除的用户不纳入计算；合计：每日新增用户数之和 "
                                        )
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont icon-info-fill"
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1757434889
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "addRetentionRate",
                        label: "新增留存率"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(" 新增留存率 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      "popper-class": "q-tooltip"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      [
                                        _vm._v(
                                          " 新增且当天未删除的用户数/当天新增用户数；合计：新增且该段时间内未删除的用户数/该段时间内新增用户数 "
                                        )
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont icon-info-fill"
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1330118024
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "ly-table-empty",
                        attrs: { slot: "empty" },
                        slot: "empty"
                      },
                      [
                        _vm._t("empty", [
                          _c("img", {
                            attrs: {
                              src: require("@assets/svg/default/no_data.svg"),
                              alt: ""
                            }
                          }),
                          _vm._v(" 暂无数据 ")
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }