var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "live" }, [
    _c("div", { staticClass: "live-cover" }, [
      _c("img", { attrs: { src: _vm.liveInfo.weappiconurl, alt: "" } })
    ]),
    _c("div", { staticClass: "live-title" }, [
      _c("img", {
        staticClass: "live-icon",
        attrs: { src: _vm.liveInfo.weappiconurl, alt: "" }
      }),
      _c("span", [_vm._v(" " + _vm._s(_vm.liveInfo.title) + " ")]),
      _c("i", { staticClass: "iconfont icon-shipinhao q-warning" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }