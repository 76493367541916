var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-card" }, [
    _c("div", { staticClass: "edit-card__header" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _c(
      "div",
      { staticClass: "edit-card__content" },
      [
        _c(
          "el-form",
          {
            staticStyle: { width: "100%", "min-width": "560px" },
            attrs: {
              model: _vm.brushForm,
              "label-width": "60px",
              size: "medium"
            }
          },
          [
            _c(
              "el-form-item",
              [
                _c("el-checkbox", {
                  attrs: { "true-label": 1, "false-label": 0, disabled: "" },
                  model: {
                    value: _vm.real_name,
                    callback: function($$v) {
                      _vm.real_name = $$v
                    },
                    expression: "real_name"
                  }
                }),
                _vm._v("未实名的用户，自动加入黑名单 ")
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-checkbox", {
                  attrs: { "true-label": 1, "false-label": 0 },
                  on: {
                    change: function($event) {
                      return _vm.fn_setBrush("is_info", $event)
                    }
                  },
                  model: {
                    value: _vm.brushForm.is_info,
                    callback: function($$v) {
                      _vm.$set(_vm.brushForm, "is_info", $$v)
                    },
                    expression: "brushForm.is_info"
                  }
                }),
                _vm._v("用户未设置头像、昵称，自动加入黑名单 ")
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-checkbox", {
                  attrs: { "true-label": 1, "false-label": 0 },
                  on: {
                    change: function($event) {
                      return _vm.fn_setBrush("is_over_info", $event)
                    }
                  },
                  model: {
                    value: _vm.brushForm.is_over_info,
                    callback: function($$v) {
                      _vm.$set(_vm.brushForm, "is_over_info", $$v)
                    },
                    expression: "brushForm.is_over_info"
                  }
                }),
                _vm._v("用户的助力好友超"),
                _c("el-input-number", {
                  staticClass: "number-small",
                  attrs: {
                    size: "small",
                    "controls-position": "right",
                    min: 1,
                    max: 100,
                    precision: 0
                  },
                  on: {
                    change: function($event) {
                      return _vm.fn_setBrush("over_rate", $event)
                    }
                  },
                  model: {
                    value: _vm.brushForm.over_rate,
                    callback: function($$v) {
                      _vm.$set(_vm.brushForm, "over_rate", $$v)
                    },
                    expression: "brushForm.over_rate"
                  }
                }),
                _vm._v(" %未设置头像、昵称，自动加入黑名单 ")
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-checkbox", {
                  attrs: { "true-label": 1, "false-label": 0 },
                  on: {
                    change: function($event) {
                      return _vm.fn_setBrush("is_invite_limit", $event)
                    }
                  },
                  model: {
                    value: _vm.brushForm.is_invite_limit,
                    callback: function($$v) {
                      _vm.$set(_vm.brushForm, "is_invite_limit", $$v)
                    },
                    expression: "brushForm.is_invite_limit"
                  }
                }),
                _vm._v("用户在 "),
                _c("el-input-number", {
                  staticClass: "number-small",
                  attrs: {
                    size: "small",
                    "controls-position": "right",
                    min: 1,
                    max: 100,
                    precision: 0
                  },
                  on: {
                    change: function($event) {
                      return _vm.fn_setBrush("invite_second", $event)
                    }
                  },
                  model: {
                    value: _vm.brushForm.invite_second,
                    callback: function($$v) {
                      _vm.$set(_vm.brushForm, "invite_second", $$v)
                    },
                    expression: "brushForm.invite_second"
                  }
                }),
                _vm._v(" 秒内邀请 "),
                _c("el-input-number", {
                  staticClass: "number-small",
                  attrs: {
                    size: "small",
                    "controls-position": "right",
                    min: 0,
                    max: 100,
                    precision: 0
                  },
                  on: {
                    change: function($event) {
                      return _vm.fn_setBrush("invite_num_limit", $event)
                    }
                  },
                  model: {
                    value: _vm.brushForm.invite_num_limit,
                    callback: function($$v) {
                      _vm.$set(_vm.brushForm, "invite_num_limit", $$v)
                    },
                    expression: "brushForm.invite_num_limit"
                  }
                }),
                _vm._v(" 人，自动加入黑名单 ")
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }