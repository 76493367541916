var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-table", {
    ref: "lyTable",
    attrs: {
      data: _vm.tableData,
      option: _vm.tableOpt,
      page: _vm.page,
      pageSize: _vm.per_page,
      total: _vm.total
    },
    on: {
      searchChange: _vm.searchChange,
      sizeChange: _vm.handleSizeChange,
      sortChange: _vm.handleSortChange,
      currentChange: _vm.handleCurrentChange,
      selectionChange: _vm.handleSelectionChange
    },
    scopedSlots: _vm._u([
      {
        key: "title_slot",
        fn: function(ref) {
          var row = ref.row
          return [
            _c("div", { staticClass: "flex" }, [
              row.avatar
                ? _c("img", {
                    staticClass: "ly-table__main-avatar",
                    attrs: { src: row.avatar, alt: "" }
                  })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(row.nickname))])
            ])
          ]
        }
      },
      {
        key: "status_slot",
        fn: function(ref) {
          var row = ref.row
          return [
            row.status === 1
              ? _c("ly-status", [_vm._v("未预约")])
              : row.status === 2
              ? _c("ly-status", { attrs: { type: "success" } }, [
                  _vm._v("预约成功")
                ])
              : _c("ly-status", { attrs: { type: "warning" } }, [
                  _vm._v("取消预约")
                ])
          ]
        }
      },
      {
        key: "inviter_nickname_slot",
        fn: function(ref) {
          var row = ref.row
          return [
            _c(
              "ly-tag",
              { attrs: { content: row.invitor_nickname, only: "" } },
              [
                _c("i", {
                  staticClass: "iconfont icon-superior",
                  attrs: { slot: "left" },
                  slot: "left"
                })
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }