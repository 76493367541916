var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "reward-rule" }, [
        _c("div", { staticClass: "reward-rule__mask" }),
        _c("div", { staticClass: "reward-rule" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "head" }, [_vm._v("活动规则")]),
              _c("el-input", {
                attrs: { type: "textarea", resize: "none" },
                model: {
                  value: _vm.rule,
                  callback: function($$v) {
                    _vm.rule = $$v
                  },
                  expression: "rule"
                }
              })
            ],
            1
          ),
          _c("i", {
            staticClass: "el-icon-circle-close reward-rule__close",
            on: {
              click: function($event) {
                _vm.visible = false
              }
            }
          })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }