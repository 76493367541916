var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile" }, [
    _c("div", { staticClass: "mobile-topbar" }, [
      _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "mobile-titlebar" }, [
      _c("i", { staticClass: "iconfont icon-close" }),
      _c("div", { staticClass: "flex flex-column" }, [
        _c("span", { staticStyle: { "font-size": "12px" } }, [
          _vm._v(_vm._s(_vm.otherForm.show_title))
        ])
      ]),
      _c("i", { staticClass: "el-icon-more" })
    ]),
    _c(
      "div",
      { class: ["mobile-container", _vm.tabKey === "other" ? "mask" : ""] },
      [
        _c("div", { staticClass: "mobile-container__bg" }),
        _c(
          "div",
          { staticClass: "rede" },
          [
            _c("div", { staticClass: "rede-scroll" }, [
              _c("img", {
                staticClass: "rede-scroll__avatar",
                attrs: { src: _vm.scrollAvatar, alt: "" }
              }),
              _vm._v(" " + _vm._s(_vm.scrollName) + " 获得 xx 元 ")
            ]),
            _vm.otherForm.bg_title
              ? [
                  _c("div", {
                    staticClass: "rede-title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.otherForm.bg_title.length > 8
                          ? _vm.otherForm.bg_title.length > 8
                            ? _vm.otherForm.bg_title.slice(0, 8) +
                              "<br />" +
                              _vm.otherForm.bg_title.slice(
                                8,
                                _vm.otherForm.bg_title.length
                              )
                            : _vm.otherForm.bg_title
                          : _vm.otherForm.bg_title
                      )
                    }
                  })
                ]
              : _c("div", { staticClass: "rede-title" }, [
                  _vm._v("请输入封面标题")
                ]),
            _c("div", { staticClass: "rede-sub" }, [
              _c("span", { staticClass: "left" }, [
                _c("img", { attrs: { src: _vm.subLeftImg, alt: "" } })
              ]),
              _c("span", { staticClass: "rede-sub__text" }, [
                _vm._v(
                  "每邀 " + _vm._s(_vm.redeForm.invite_num) + " 人，拆 1 次红包"
                )
              ]),
              _c("span", { staticClass: "left" }, [
                _c("img", { attrs: { src: _vm.subRightImg, alt: "" } })
              ])
            ]),
            _c("div", { staticClass: "rede-bag" }, [
              _c("div", { staticClass: "rede-bag__average" }, [
                _vm._v(" 人均获得"),
                _c("b", { staticClass: "q-danger" }, [
                  _vm._v(_vm._s(_vm.redeForm.virtual_average))
                ]),
                _vm._v("元，您暂获： ")
              ]),
              _vm._m(1),
              _c("div", { staticClass: "rede-bag__wechat flex flex-center" }, [
                _c("img", { attrs: { src: _vm.wechatDefaultImg, alt: "" } }),
                _vm._v("微信到账 ")
              ]),
              _vm._m(2),
              _c("div", { staticClass: "rede-bag__btn" }, [
                _vm._v(
                  " 还差" + _vm._s(_vm.redeForm.invite_num) + "人～开多1次 "
                )
              ])
            ]),
            _c("div", { staticClass: "rede-rank" }),
            _vm._m(3),
            _vm.tabKey === "other"
              ? _c("div", { staticClass: "rede-dialog flex flex-center" }, [
                  _c(
                    "div",
                    { staticClass: "rede-dialog__container" },
                    [
                      _vm._m(4),
                      _c("el-input", {
                        staticClass: "rede-dialog__body",
                        attrs: {
                          type: "textarea",
                          autosize: "",
                          resize: "none",
                          readonly: ""
                        },
                        model: {
                          value: _vm.otherForm.rule_desc,
                          callback: function($$v) {
                            _vm.$set(_vm.otherForm, "rule_desc", $$v)
                          },
                          expression: "otherForm.rule_desc"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "iconfont icon-a-CellularConnection" }),
      _c("i", { staticClass: "iconfont icon-Wifi" }),
      _c("i", { staticClass: "iconfont icon-Battery" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rede-bag__amount" }, [
      _c("span", { staticClass: "sub" }, [_vm._v("￥")]),
      _vm._v("66.66")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rede-bag__time" }, [
      _vm._v(" 红包翻倍"),
      _c("span", [_vm._v("23")]),
      _vm._v("分"),
      _c("span", [_vm._v("53")]),
      _vm._v("秒"),
      _c("span", [_vm._v("53")]),
      _vm._v("后失效！ ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rede-fixed" }, [
      _c("div", { staticClass: "rede-fixed__block" }, [_vm._v("活动规则")]),
      _c("div", { staticClass: "rede-fixed__block" }, [_vm._v("红包记录")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rede-dialog__header flex flex-center" }, [
      _c("div", { staticClass: "rede-dialog__title" }, [_vm._v("活动规则")]),
      _c("div", { staticClass: "rede-dialog__title-before" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }