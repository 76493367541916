<template>
  <div class="page-warp">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 搜索插槽 -->
      <!-- 分页插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          size="medium"
          type="primary"
          :class="['btn-reload', reload ? 'active' : '']"
          :disabled="reload"
          style="margin-right: 16px"
          @click="fn_reload"
        >
          <i class="iconfont icon-data"></i
          >{{ reload ? "同步中..." : "同步" }}</el-button
        >
      </template>
      <!-- 列插槽 -->
      <template #name_slot="{ row }">
        <div class="flex flex-start">
          <el-image
            v-if="row.avatar_url"
            lazy
            :src="row.avatar_url"
            alt=""
            fit="cover"
            class="ly-table__main-avatar"
          ></el-image>
          <el-image
            v-else
            lazy
            src="../../../assets/images/default_avatar.png"
            alt=""
            fit="cover"
            class="ly-table__main-avatar"
          ></el-image>
          <div style="flex: 1">
            {{ row.name }}
            <span class="q-warning" v-if="row.corp_name"
              >@{{ row.corp_name }}</span
            >
            <span class="q-success" v-else>@微信</span>
          </div>
        </div>
      </template>

      <template #wecom_user_name_slot="{ row }">
        <ly-drow :list="row.wecom_user_name"></ly-drow>
      </template>

      <template #tag_names_slot="{ row }">
        <ly-drow :list="row.tag_names" dep></ly-drow>
      </template>

      <template #user_state_slot="{ row }">
        <ly-status
          v-if="!row.wecom_user_name"
          type="danger"
          icon="icon-close-fill"
          >已流失</ly-status
        >
        <ly-status v-else type="success" icon="el-icon-success"
          >已添加</ly-status
        >
      </template>

      <template #operation="{ row }">
        <el-button type="text" @click="jumpToDetail(row)">详情</el-button>
      </template>
    </ly-table>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { list } from './http.js'
import lyDrow from '@/components/lyDrow'
import util from '@/common/util.js'
export default {
  components: {
    lyDrow
  },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: list,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      statusOptions: [{
        value: -1,
        label: '全部'
      }, {
        value: 1,
        label: '已添加'
      }, {
        value: 0,
        label: '已流失'
      }],
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        onPick: (time) => {
          return this.onPick(time)
        }
      },
      showPlaceholder: true,
      filterText: '',
      chooseList: '',
      roleList: [],
      depList: [],
      tableData: [],
      page: 1,
      per_page: 10,
      total: 0,
      mineStatus: '',
      wecom_department_value: '',
      tags: [],
      value: '',
      reload: false,
      reloadId: null,
      reloadTime: null
    }
  },

  destroyed () {
    if (this.reloadTime) {
      clearTimeout(this.reloadTime)
    }
  },
  watch: {
    filterText (val) {
      this.$refs.transfer.filter(val)
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 100,
        column: [
          {
            label: '客户名称',
            prop: 'name',
            slot: 'name_slot',
            search: true
          },
          {
            label: '所属员工',
            prop: 'wecom_user_name',
            key: 'wecom_user_id',
            slot: 'wecom_user_name_slot',
            search: true,
            type: 'emp'
          },
          {
            label: '企业标签',
            prop: 'tag_names',
            key: 'tag_ids',
            slot: 'tag_names_slot',
            search: true,
            type: 'tag'
          },
          {
            label: '客户状态',
            prop: 'user_state',
            search: true,
            key: 'status',
            type: 'select',
            source: this.statusOptions,
            default: -1,
            slot: 'user_state_slot'
          },
          {
            label: '添加时间',
            prop: 'created_at',
            search: true,
            key: ['begin_at', 'end_at'],
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            sortable: true,
            formatter ({ created_at }) {
              return created_at
                ? util.timeTofmt(created_at, 'yyyy-MM-dd hh:mm')
                : '-'
            }
          }
        ]
      }
    }
  },
  methods: {
    formatterParams (params) {
      return {
        direction: 'desc',
        order_by: 'created_at',
        ...params
      }
    },
    // 设置时间范围
    disabledDate (time) {
      const timeOptionRange = this.timeOptionRange
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _times = _date.getTime()
      if (timeOptionRange) {
        const secondNum = 30 * 8.64e7
        const _time = timeOptionRange.getTime() + secondNum < _times ? timeOptionRange.getTime() + secondNum : _times
        return time.getTime() >= _time || time.getTime() < timeOptionRange.getTime() - secondNum
      } else {
        return time.getTime() >= _times
      }
    },

    // 点击时设置
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },

    // 选择员工
    getEmpId (ids) {
      this.searchForm.wecom_user_id = ids.join(',')
    },

    // 跳转详情
    jumpToDetail (item) {
      this.$router.push({
        path: 'detail',
        query: {
          id: item.id
        }
      })
    },

    // 获取授权状态
    async fn_getAuthStatus (type = true) {
      const data = await this.axios.get('authStatus')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$store.commit('_SET', {
        'global.appConfig': data.err_status
      })
    },

    // 同步数据
    async fn_reload () {
      if (!this.reload) {
        await this.fn_getAuthStatus()
      }
      const _appConfig = this.$store.state.global.appConfig

      if (_appConfig === 1 || _appConfig === 2 || _appConfig === 3) {
        this.fn_lyMsg('info', '自建应用异常，数据同步失败')
        return
      }
      this.reload = true
      const data = await this.axios.post('clientSync', {
        msg_id: this.reloadId
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        this.reload = false
        return false
      }

      this.reloadId = data.msg_id

      if (data.status === 0) {
        this.fn_lyMsg('success', '数据同步完成！')
        this.reload = false
        this.reloadId = null
        this.queryData()
      } else {
        this.reloadTime = setTimeout(() => {
          this.fn_reload()
        }, 1000)
      }
    }
  }
}
</script>
