var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "page-tabel",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          "page-sizes": _vm.pageSizes,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "icon-plus iconfont"
                    },
                    on: { click: _vm.fn_edit }
                  },
                  [_vm._v("添加视频号直播")]
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "tooltip",
                    attrs: {
                      content: "请先选择要分组的素材",
                      effect: "dark",
                      placement: "top",
                      disabled: _vm.selected.length > 0
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              plain: "",
                              icon: "iconfont icon-edit",
                              size: "medium",
                              disabled: _vm.selected.length === 0
                            },
                            on: { click: _vm.fn_changeGroupBatch }
                          },
                          [_vm._v("批量分组")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "tooltip",
                    attrs: {
                      content: "请先选择要删除的素材",
                      effect: "dark",
                      placement: "top",
                      disabled: _vm.selected.length > 0
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              plain: "",
                              icon: "iconfont icon-delete",
                              size: "medium",
                              disabled: _vm.selected.length === 0
                            },
                            on: { click: _vm.fn_removeBatch }
                          },
                          [_vm._v("批量删除")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "cusTable",
            fn: function() {
              return [
                _vm.total
                  ? _c("div", { staticClass: "el-table" }, [
                      _c(
                        "div",
                        {
                          staticClass: "q-bg is-primary fx p-12",
                          staticStyle: { margin: "0 20px 20px" }
                        },
                        [
                          _c("i", {
                            staticClass:
                              "iconfont icon-info-fill q-primary mr-8",
                            staticStyle: { "line-height": "1.4" }
                          }),
                          _c("div", { staticClass: "flex-1" }, [
                            _vm._v(
                              " 1. 员工登录云端账号，保持云端在线，将需要的素材视频号直播发送给云端账号中；"
                            ),
                            _c("br"),
                            _vm._v(
                              " 2. 将发送的素材在聊天记录中添加到素材库中进行保存，保存的素材无封面展示，但不影响正常发送。 "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mcard fx flex-wrap" },
                        _vm._l(_vm.tableData, function(item, index) {
                          return _c(
                            "m-card",
                            {
                              key: index,
                              attrs: { data: item, index: index, grid: "g-4" },
                              on: { edit: _vm.fn_edit, selected: _vm.fn_select }
                            },
                            [
                              _c("div", { staticClass: "mcard-wrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "mb-12 text-ellipsis pr-24" },
                                  [
                                    _vm._v(
                                      " 原创原创原创原创原创原创原创原创原创原创原创原创原创原创 "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mcard-bg rd-4 mb-12" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mcard-poster" },
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticClass: "mcard-img",
                                            attrs: {
                                              src: item.poster_url,
                                              lazy: ""
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "mcard-imgerror",
                                              attrs: {
                                                slot: "error",
                                                src: require("@/assets/images/applet.png"),
                                                alt: ""
                                              },
                                              slot: "error"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "mcard-badge" },
                                          [_c("span"), _vm._v("直播")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mcard-pagename fx flex-align-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fx text-ellipsis flex-1 flex-align-center"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "mcard-appicon mr-8",
                                              attrs: {
                                                src: item.poster_url,
                                                alt: ""
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-1 text-ellipsis mr-8"
                                              },
                                              [
                                                _vm._v(
                                                  " 原创原创原创原创原创原创原创原创原创原创原创原创原创原创 "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-shipinhao q-warning mr-4"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.total === 0
                  ? [
                      _c("div", { staticClass: "el-table__empty-block" }, [
                        _c("div", { staticClass: "el-table__empty-text" }, [
                          _c("div", { staticClass: "ly-table-empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@assets/svg/default/no_data.svg"),
                                alt: ""
                              }
                            }),
                            _vm._v(" 暂无数据 ")
                          ])
                        ])
                      ])
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("edit", { ref: "editRef", on: { callback: _vm.fn_editCallback } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }