<!--
 * @Author: zhanln
 * @Date: 2021-08-23 11:36:06
 * @LastEditTime: 2022-08-11 11:43:39
 * @LastEditors: zhan
 * @Description: 活动编辑页布局（通用）
-->

<template>
  <el-container class="act">
    <el-main>
      <!-- 顶部导航 -->
      <div class="act-tabs">
        <el-tabs v-model="tabKey" @tab-click="fn_setTab">
          <el-tab-pane v-for="(item, index) in cards.filter(item => !item.hidden)" :key="index" :label="item.label"
            :name="item.name"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="act-main">
        <!-- 表单卡片 -->
        <el-scrollbar ref="main" class="act-main__left">
          <template v-for="(item, index) in cards.filter(item => !item.hidden)">
            <act-card :ref="item.name" :title="item.label" :key="index">
              <slot :name="item.name"></slot>
            </act-card>
          </template>
        </el-scrollbar>

        <!-- 预览 -->
        <el-scrollbar class="act-main__right">
          <div class="act-mobile">
            <slot name="mobile"></slot>
          </div>
        </el-scrollbar>
      </div>
    </el-main>

    <!-- 底部浮动条 -->
    <el-footer class="act-footer">
      <slot name="footer"></slot>
    </el-footer>
  </el-container>
</template>

<script>
import actCard from './card'
export default {
  props: {
    cards: {
      type: [Array, null],
      default: () => {
        return null
      }
    },
    lastRef: {
      type: String,
      default: ''
    }
  },
  watch: {
    cards: {
      handler (val) {
        this.tabKey = val[0].name
        this.$emit('setKey', this.tabKey)
      },
      deep: true,
      immediate: true
    },
    tabKey (val) {
      this.$emit('setKey', val)
    }
  },
  components: {
    actCard
  },
  data () {
    return {
      tabKey: ''
    }
  },
  async mounted () {
    window.addEventListener('beforeunload', this.fn_beforeunload, false)

    // main主体滚动监测
    this.$nextTick(() => {
      const main = this.$refs.main.$refs.wrap
      main && main.addEventListener('scroll', this.fn_getScroll)
    })
  },
  methods: {
    // 顶部导航监听
    fn_setTab (tab) {
      const top = this.$refs[tab.name][0].$el.offsetTop
      this.$refs.main.$refs.wrap.scrollTop = top
      this.tabKey = tab.name
      this.initFlag = true
      setTimeout(() => {
        this.initFlag = false
      }, 50)
    },

    // 滚动监听
    fn_getScroll (e) {
      if (this.initFlag) return false
      const main = e.target || e.srcElement || e.path[0]
      const mainList = this.cards.filter(item => !item.hidden).map(item => item.name)
      if (main === this.$refs.main.$refs.wrap) {
        const top = main.scrollTop
        const height = main.offsetHeight

        const $last = this.lastRef && this.$refs[this.lastRef][0].$el

        if ($last && top + height > $last.offsetTop + $last.offsetHeight - 63) {
          this.tabKey = this.lastRef
        } else {
          this.util.each(mainList, (i, v) => {
            const obj = this.$refs[v][0].$el
            const y = obj.offsetTop
            const h = obj.offsetHeight

            if (h < height - 160) {
              if (top > y - 350) {
                this.tabKey = v
              }
            } else {
              if (top > y - 160) {
                this.tabKey = v
              }
            }
          })
        }

        // console.log('this.$refs', this.$refs)
        // const $last = this.$refs
        // const last = $last.$el
        // if (top + height > other.offsetTop + other.offsetHeight - 63) {
        //   console.log('bottm')
        //   // this.tabKey = 'preventbrush'
        // } else {
        //   this.util.each(mainList, (i, v) => {
        //     const obj = this.$refs[v][0].$el
        //     const y = obj.offsetTop
        //     const h = obj.offsetHeight

        //     if (h < height - 160) {
        //       if (top > y - 350) {
        //         this.tabKey = v
        //       }
        //     } else {
        //       if (top > y - 160) {
        //         this.tabKey = v
        //       }
        //     }
        //   })
        // }
      }
    },

    // 页面刷新
    fn_beforeunload (e) {
      e = e || window.event
      if (e) {
        e.returnValue = ''
      }
      return ''
    }
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.fn_beforeunload, false)
  },

  destroyed () {
    window.removeEventListener('scroll', this.fn_getScroll)
  }
}
</script>

<style lang="scss" scoped>
.act {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;

  &-tabs {
    padding: 0 20px;
    margin: 0 20px;

    ::v-deep {
      .el-tabs__active-bar {
        height: 3px;
      }

      .el-tabs__header {
        margin-bottom: 0;
      }
    }
  }

  .el-main {
    padding: 0;
    padding-top: 10px;
    flex: 1;
    overflow: hidden;

    .act-main {
      display: flex;
      background: #fff;
      border-radius: 16px 16px 0 0;
      box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24),
        0px 0px 2px 0px rgba(145, 158, 171, 0.24);
      margin: 0 20px;
      height: calc(100% - 39px);
      overflow: hidden;

      &__left {
        flex: 1;
      }

      &__right {
        width: 462px;
        // height: 100%;
        // background-color: #fff;
        // border-radius: 4px;
        // box-sizing: border-box;
        // overflow: hidden;
        padding: 35px 0 20px 0;
      }
    }
  }

  &-container {
    position: relative;
    height: calc(100% - 64px);
    overflow-y: auto;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
    z-index: 999;
  }

  &-mobile {
    // position: relative;
    // border-radius: 16px;
    // width: 345px;
    // min-height: 613.64px;
    // height: 100%;
    // overflow: hidden;
    // margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep {
  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
    overflow-x: hidden;
  }

  .el-tabs__nav-wrap::after {
    content: unset;
  }
}
</style>
