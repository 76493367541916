<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-17 16:30:51
 * @LastEditTime: 2021-12-30 14:31:39
-->
<template>
  <div class="page-warp key-word">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #temp>
        <div class="top-tips">
          添加关键词后，可以统计群内回复关键词的人数和次数。
        </div>
      </template>
      <template #handler>
        <el-button round type="primary" size="medium" icon="iconfont icon-plus" @click="handleAddKey"
          >添加关键词</el-button
        >
      </template>
    </ly-table>
    <add-word ref="addWordRef" @change="queryData"></add-word>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { keyWords } from './http.js'
import addWord from './components/addWord'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: keyWords
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        operation: [
          {
            label: '查看数据统计',
            handler (row) { _this.$router.push(`/cloudService/groupData/wordsDetail?type=${row.content}`) }
          },
          {
            label: '删除',
            handler (row) {
              _this.fn_removeWord(row)
            }
          }
        ],
        operationWidth: 200,
        column: [
          {
            label: 'temp',
            prop: 'temp',
            hide: true,
            search: true,
            searchSlot: 'temp'
          },
          {
            label: '关键词',
            prop: 'content',
            key: 'key',
            search: true,
            placeholder: '请输入关键词'
          }
        ]
      }
    }
  },
  methods: {
    handleAddKey () {
      this.$refs.addWordRef.visible = true
    },
    // 删除关键词
    fn_removeWord (row) {
      this.$lyConfirm({
        title: '确定要删除此关键词吗？',
        text: '',
        confirmButtonText: '确定'
      }).then(async () => {
        const res = await keyWords.reqDelete({
          id: row.id
        })

        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return false
        }

        this.$message.success('删除成功！')

        this.queryData()
      }).catch(() => { })
    }
  },
  components: {
    addWord
  }
}
</script>
<style lang="scss" scoped>
.key-word {
  .top-tips {
    height: 46px;
    line-height: 46px;
    padding: 0 12px;
    background: #EBF3FF;
    border-radius: 8px;
  }
  ::v-deep {
    .el-form-item:nth-of-type(1) {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      .el-form-item__label {
        display: none;
      }
      .el-form-item__content{
        width: 100%;
      }
    }
  }
}
</style>
