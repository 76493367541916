<!--
 * @Author: zhanln
 * @Date: 2022-03-25 15:22:41
 * @LastEditTime: 2022-04-19 11:11:39
 * @LastEditors: zhanln
 * @Description: 分组
-->

<template>
  <div class="material-group">
    <div :class="['material-group__item', current === '-1' ? 'is-active' : '']">
      <div class="name flex-1 text-ellipsis mr-8" @click="fn_changeGroup('-1')">
        <span>全部分组</span>（{{ data.total }}）
      </div>
      <el-popover
        placement="bottom-end"
        trigger="click"
        :disabled="data.total >= 100"
        popper-class="add-group-popover"
        @show="
          groupName = '';
          groupId = null;
        "
      >
        <el-tooltip
          class="item"
          effect="dark"
          content="分组上限为100个"
          :disabled="data.total < 100"
          placement="top"
          slot="reference"
        >
          <el-button
            icon="iconfont icon-plus"
            type="primary"
            circle
            size="mini"
            class="add"
          ></el-button>
        </el-tooltip>
        <div class="add-group">
          <el-input
            size="small"
            placeholder="请输入分组名称"
            maxlength="20"
            show-word-limit
            v-model="groupName"
          ></el-input>
          <el-button round size="small" @click="handleCancel">取消</el-button>
          <el-button round size="small" type="primary" @click="handleSave"
            >确定</el-button
          >
        </div>
      </el-popover>
    </div>
    <el-scrollbar class="material-group__list">
      <div
        :class="[
          'material-group__item',
          item.group_id === current ? 'is-active' : '',
        ]"
        v-for="(item, index) of data.group"
        :key="index"
      >
        <div
          class="name flex-1 text-ellipsis mr-8"
          @click="fn_changeGroup(item.group_id)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.name"
            :disabled="item.name.length < 10"
            placement="top-start"
          >
            <span>
              <template v-if="item.name">
                {{
                  item.name.length > 10
                    ? item.name.slice(0, 10) + "..."
                    : item.name
                }}
              </template>
              <template v-else>默认分组</template>
            </span>
          </el-tooltip>
        </div>
        <div class="edit" v-if="item.group_id !== '0'">
          <el-popover
            placement="bottom-end"
            trigger="click"
            popper-class="add-group-popover"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top"
              slot="reference"
              :enterable="false"
            >
              <i
                class="iconfont icon-edit"
                style="margin-right: 8px"
                @click="fn_handleEdit(item)"
              ></i>
            </el-tooltip>
            <div class="add-group">
              <el-input
                size="small"
                placeholder="请输入分组名称"
                maxlength="20"
                show-word-limit
                v-model="groupName"
              ></el-input>
              <el-button round size="small" @click="handleCancel"
                >取消</el-button
              >
              <el-button round size="small" type="primary" @click="handleSave"
                >确定</el-button
              >
            </div>
          </el-popover>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              item.amount > 0
                ? '请移除或删除分组下的素材后，再删除分组'
                : '删除'
            "
            placement="top"
            :enterable="false"
          >
            <i
              :class="[
                'iconfont',
                'icon-delete',
                item.amount > 0 ? 'is-disable' : '',
              ]"
              @click="fn_removeGroup(item)"
            ></i>
          </el-tooltip>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { groupApi } from '../http'
export default {
  name: 'groups',

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    current: {
      type: String,
      default: ''
    }
  },

  inject: ['category'],

  data () {
    return {
      groupApi: groupApi,
      groupId: null,
      groupName: ''
    }
  },

  methods: {
    // 切换分组
    fn_changeGroup (id) {
      if (id === this.current) return
      this.$emit('change', id)
    },

    // 编辑
    fn_handleEdit ({ group_id, name }) {
      this.groupId = group_id
      this.groupName = name
    },

    // 删除
    fn_removeGroup ({ group_id, name }) {
      this.$lyConfirm({
        title: `确认要删除分组 ${name} 吗？`,
        text: '分组删除后将无法找回, 是否继续?',
        confirmButtonText: '删除'
      }).then(async () => {
        const data = await this.groupApi.del({
          group_id
        })
        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          return false
        }
        this.fn_lyMsg('info', '分组删除成功！')
        this.$emit('updataGroups', this.current === group_id ? '-1' : undefined)
      }).catch(() => { })
    },

    // 取消编辑
    handleCancel () {
      document.body.click()
    },

    // 创建/修改分组
    async handleSave () {
      if (this.groupName === '') {
        this.fn_lyMsg('info', '分组名称不能为空！')
        return false
      }

      const type = this.groupId ? 'edit' : 'add'

      const params = this.groupId ? {
        group_id: this.groupId,
        name: this.groupName
      } : {
        category: this.category,
        name: this.groupName
      }

      const data = await this.groupApi[type](params)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.fn_lyMsg('success', this.groupId ? '分组修改成功！' : '分组创建成功！')
      this.groupName = ''
      this.groupId = ''
      this.handleCancel()
      this.$emit('updataGroups')
    }
  }
}
</script>

<style lang="scss" scoped>
.material-group {
  height: 100%;
  &__list {
    height: calc(100% - 40px);
    overflow: hidden;
  }

  &__item {
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover,
    &.is-active {
      background-color: #eaebf2;
    }

    &:hover {
      cursor: pointer;

      .edit {
        display: block;
      }
    }

    &.is-active {
      .name {
        color: $--color-primary;
      }
    }

    .edit {
      display: none;
    }

    .name {
      line-height: 40px;
      padding-left: 20px;
      font-weight: 500;
    }

    .add {
      padding: 0;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;

      ::v-deep i {
        margin-right: 0;
      }
    }

    .icon-edit:hover {
      color: $--color-primary;
    }
    .icon-delete:hover {
      color: $--color-danger;
    }

    .iconfont.is-disable {
      color: #b5b5b5;
      cursor: not-allowed;
    }
  }
}
.add-group {
  .el-input {
    width: 220px;
    margin-right: 24px;
  }
}
</style>
<style lang="scss">
.add-group-popover {
  padding: 12px;
  box-shadow: 0px 12px 24px 0px rgba(145, 158, 171, 0.24),
    0px 0px 2px 0px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  .popper__arrow {
    display: none;
  }
}
</style>
