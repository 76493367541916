var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c(
        "el-form",
        { staticStyle: { padding: "0 20px" }, attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            { staticClass: "label-padding", attrs: { label: "选择活动：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: { filterable: "" },
                  on: { change: _vm.fn_changeAct },
                  model: {
                    value: _vm.activityId,
                    callback: function($$v) {
                      _vm.activityId = $$v
                    },
                    expression: "activityId"
                  }
                },
                _vm._l(_vm.activityList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "iconfont icon-plus",
                      round: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.fn_openPop(null)
                      }
                    }
                  },
                  [_vm._v("添加渠道")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "link_url_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(" " + _vm._s(row.link_url) + " "),
                _c(
                  "span",
                  {
                    class: ["copy", "copy_url_" + row.id],
                    attrs: { "data-clipboard-text": row.link_url },
                    on: {
                      click: function($event) {
                        return _vm.fn_copy("copy_url_" + row.id)
                      }
                    }
                  },
                  [_vm._v("复制")]
                )
              ]
            }
          },
          {
            key: "code_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "code-block" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          trigger: "hover",
                          placement: "right-start",
                          "close-delay": 0
                        }
                      },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: row.poster_url,
                              alt: "",
                              width: "250",
                              height: "445"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: {
                                "justify-content": "space-around",
                                "margin-top": "8px"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDownload(row, "poster")
                                    }
                                  }
                                },
                                [_vm._v("下载海报")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDownload(row, "code")
                                    }
                                  }
                                },
                                [_vm._v("下载二维码")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("i", {
                          staticClass: "iconfont icon-Code",
                          staticStyle: {
                            "font-size": "18px",
                            "margin-right": "10px"
                          },
                          attrs: { slot: "reference" },
                          slot: "reference"
                        })
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "copy",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleDownload(row, "code")
                          }
                        }
                      },
                      [_vm._v("下载")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.fn_openPop(row)
                      }
                    }
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      disabled: row.status === 2 || row.status === 3
                    },
                    on: {
                      click: function($event) {
                        return _vm.fn_handleDel(row)
                      }
                    }
                  },
                  [_vm._v("删除 ")]
                )
              ]
            }
          }
        ])
      }),
      _c("edit-channel", {
        ref: "editChannelRef",
        attrs: {
          popForm: _vm.channelItem,
          actId: _vm.activityId,
          type: _vm.editType
        },
        on: { success: _vm.queryData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }