<!--
 * @Author: zhanln
 * @Date: 2021-10-27 10:16:45
 * @LastEditTime: 2021-12-23 17:30:56
 * @LastEditors: Please set LastEditors
 * @Description: 本地导出excel
-->

<template>
  <el-button
    :size="btnSize"
    round
    :type="btnType"
    :disabled="disabled"
    icon="iconfont icon-download"
    :loading="loading"
    @click="fn_exportExcel"
    >{{ btnText }}</el-button
  >
</template>

<script>
import XLSX from 'xlsx'
export default {
  props: {
    btnText: {
      type: String,
      default: '导出'
    },
    btnSize: {
      type: String,
      default: 'medium'
    },
    btnType: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    exportName: {
      type: String,
      default: ''
    },
    exportSheetName: {
      type: String,
      default: ''
    },
    exportData: {
      type: Array,
      default: () => {
        return []
      }
    },
    dateFormat: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    fn_exportExcel () {
      if (this.exportData.length === 0) {
        console.log('导出数据为空！')
        return false
      }
      this.loading = true
      const worksheet = XLSX.utils.aoa_to_sheet(this.exportData)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, this.exportSheetName || this.exportName)
      const fileName = this.dateFormat ? this.exportName + this.$day().format(this.dateFormat) : this.exportName
      XLSX.writeFile(workbook, fileName + '.xlsx')
      this.loading = false
    }
  }
}
</script>
