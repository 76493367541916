var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "活动名称：", prop: "title" } },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "16",
              "show-word-limit": "",
              placeholder: "不对外展示，用户区分活动使用场景"
            },
            model: {
              value: _vm.form.title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "活动结束时间：", prop: "end_at" } },
        [
          _c("el-date-picker", {
            staticClass: "act-form__input",
            attrs: {
              type: "datetime",
              placeholder: "请选择时间",
              clearable: false,
              "picker-options": _vm.endAtOption
            },
            model: {
              value: _vm.form.end_at,
              callback: function($$v) {
                _vm.$set(_vm.form, "end_at", $$v)
              },
              expression: "form.end_at"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { ref: "wecom_user_ids", attrs: { error: _vm.wecom_user_idsTips } },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
              _vm._v(" 涨粉账号 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    placement: "top",
                    "popper-class": "q-tooltip"
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 1.“涨粉账号”会关联“裂变海报”的二维码"),
                    _c("br"),
                    _vm._v(
                      " 2.用户扫描海报二维码后会随机平均添加 “涨粉账号”好友。 "
                    )
                  ]),
                  _c("i", { staticClass: "iconfont icon-info-fill" })
                ]
              ),
              _vm._v("： ")
            ],
            1
          ),
          _c("ly-emp-btn", {
            attrs: {
              btnText: "添加涨粉账号",
              max: 100,
              isReal: true,
              datafilter: true,
              checkList: _vm.form.wecom_user_ids
            },
            on: { getEmpId: _vm.fn_getEmpId }
          })
        ],
        2
      ),
      _c(
        "el-form-item",
        [
          _c(
            "template",
            { slot: "label" },
            [
              _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
              _vm._v(" 二维码失效时间 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    placement: "top",
                    "popper-class": "q-tooltip"
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(
                      " 活动结束后，据失效时间判断新用户是否可以进入活动页 "
                    )
                  ]),
                  _c("i", { staticClass: "iconfont icon-info-fill" })
                ]
              ),
              _vm._v("： ")
            ],
            1
          ),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.form.code_fail_mode,
                callback: function($$v) {
                  _vm.$set(_vm.form, "code_fail_mode", $$v)
                },
                expression: "form.code_fail_mode"
              }
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [
                _vm._v("活动结束后立即失效")
              ]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自定义")])
            ],
            1
          ),
          _vm.form.code_fail_mode === 2
            ? _c(
                "el-form-item",
                {
                  staticClass: "expire_time",
                  attrs: { prop: "code_fail_date", error: _vm.codeFailError }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { "margin-left": "16px" },
                    attrs: {
                      type: "datetime",
                      placeholder: "请选择时间",
                      "popper-class": "expiration-picker",
                      "picker-options": _vm.failDateOption,
                      clearable: false
                    },
                    model: {
                      value: _vm.form.code_fail_date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "code_fail_date", $$v)
                      },
                      expression: "form.code_fail_date"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "welcome_msg" } },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm._v("裂变欢迎语 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "light",
                    placement: "right",
                    "popper-class": "q-tooltip is-light"
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "24px 24px 16px",
                          width: "472px"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "https://image.01lb.com.cn//uploads/wecom/21/0416/1618578068K9S4oyI6.png",
                            width: "472",
                            alt: ""
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "text-bold",
                            staticStyle: { margin: "16px 0 8px" }
                          },
                          [_vm._v(" 欢迎语下发规则提示： ")]
                        ),
                        _c("div", [
                          _vm._v(
                            " 若涨粉账号在【企业微信后台】被配置了欢迎语，这里的欢迎语将会失效，请确保所涨粉账号未被配置欢迎语。 "
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("i", { staticClass: "iconfont icon-info-fill" })
                ]
              ),
              _vm._v("： ")
            ],
            1
          ),
          _c("ly-editor", {
            staticClass: "act-form__editor",
            attrs: {
              title: "欢迎语",
              id: "prizeWords",
              content: _vm.form.welcome_msg,
              insetMenu: ["用户昵称", "活动链接"],
              recoverContent: _vm.base_welcome_msg,
              emoji: ""
            },
            on: { getContent: _vm.fn_getWelWords }
          })
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { label: "自动打标签：" } },
        [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { height: "36px" } },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.form.tag_open,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tag_open", $$v)
                  },
                  expression: "form.tag_open"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "q-info",
                  staticStyle: { "line-height": "20px", "margin-left": "10px" }
                },
                [_vm._v("开启后，自动为本次活动新增用户打标签")]
              )
            ],
            1
          ),
          _vm.form.tag_open === 1
            ? _c("tag-select-v2", {
                attrs: { checked: _vm.form.new_user_tags },
                on: { getTagId: _vm.fn_getAddIds }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }