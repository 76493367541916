<!--
 * @Author: zhanln
 * @Date: 2021-11-09 11:14:31
 * @LastEditTime: 2022-08-04 17:15:52
 * @LastEditors: zhan
 * @Description: 消息通知 - 账号云端列表
-->

<template>
  <div class="account">
    <div class="account-header flex flex-between">
      <el-radio-group v-model="accountType">
        <el-radio :label="1" :disabled="accountLoading">全部账号({{ accountList.length }})</el-radio>
        <el-radio :label="2" :disabled="accountLoading">离线({{ outLineList.length }})</el-radio>
      </el-radio-group>
      <el-button type="primary" size="ly-mini" :disabled="accountLoading" v-lydebounce="['button', fn_getRobotList]">刷新
      </el-button>
    </div>
    <div class="account-list">
      <el-table :data="accountType === 1 ? accountList : outLineList" style="width: 100%" max-height="250"
        class="ly-table is-mini no-border v-middle" v-loading="accountLoading" empty-text="请选择涨粉账号">
        <el-table-column prop="name, avatar" label="涨粉账号">
          <template slot-scope="scope">
            <div class="flex">
              <img :src="scope.row.avatar || defaultAvatar" alt="" class="ly-table__main-avatar" />
              <div>
                <div class="ly-table__main-name">
                  {{ scope.row.name }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="云端登录状态">
          <template slot-scope="scope">
            <div :class="[!scope.row.status ? 'q-info' : '']">
              <span :class="['q-circle', scope.row.status === 1 ? 'success' : '']"></span>{{ scope.row.status ? "在线" :
                  "离线"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" v-if="hasCloudBean">
          <template slot-scope="scope">
            <el-button type="text" :disabled="scope.row.status === 1" @click="fn_jumpCloudPage">登录云端</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// store
import { mapState } from 'vuex'
export default {
  name: 'taskNoticeAccount',

  data () {
    return {
      accountLoading: true,
      accountType: 1,
      total: 0,
      accountList: [],
      outLineList: [],
      isReady: false
    }
  },

  computed: {
    ...mapState({
      wecom_users: state => state.task.wecom_users
    }),
    hasCloudBean () {
      return this.$store.state.permission.hasCloudBean
    }
  },

  watch: {
    wecom_users (val) {
      if (this.isReady) {
        this.fn_getRobotList()
      }
    }
  },

  methods: {
    // 获取账号状态列表
    async fn_getRobotList () {
      this.isReady = true
      // 未选择涨粉账号
      if (!this.wecom_users) {
        this.accountList = []
        this.outLineList = []
        this.accountLoading = false
        return false
      }

      this.accountLoading = true

      const id = this.wecom_users.join(',')
      const data = await this.axios.get('robotListById', {
        params: {
          user_ids: id
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.$message.error(data.msg)
        return false
      }

      this.accountType = 1
      this.accountList = data.list

      // 离线列表
      if (data.list.length) {
        this.outLineList = data.list.filter(item => {
          return item.status === 0
        })
      }

      this.accountLoading = false
    },

    // 跳转云端列表
    fn_jumpCloudPage () {
      const routeData = this.$router.resolve({
        path: '/cloudService/account/list'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.account {
  margin-top: 8px;
  border: 1px solid #EAEBF2;
  border-radius: 5px;
  overflow: hidden;

  &-header {
    height: 44px;
    padding: 0 24px;
  }
}
</style>
