var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scan-login" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "云端登录",
            visible: _vm.visible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "678px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.getBeanBalance,
            closed: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.gloading,
                  expression: "gloading"
                }
              ]
            },
            [
              _c("div", { staticClass: "use-notice" }, [
                _c("h5", { staticClass: "title" }, [
                  _c("img", {
                    attrs: { src: require("@assets/svg/Info.svg") }
                  }),
                  _vm._v("使用须知")
                ]),
                _c("p", { staticClass: "tips-item" }, [
                  _vm._v(
                    " 1.扫描二维码成功登录后，你的企业微信将托管到云端电脑上，手机上方将显示【Windows】或【ipad】已登录。 "
                  )
                ]),
                _c("p", { staticClass: "tips-item" }, [
                  _vm._v(" 2.帐户扫码登录后，建议在"),
                  _c("span", { staticStyle: { color: "#2B7CFF" } }, [
                    _vm._v("8小时")
                  ]),
                  _vm._v("以上再进行群发等操作。 ")
                ]),
                _c("p", { staticClass: "tips-item" }, [
                  _vm._v(
                    " 3.登录云端的员工账号保持24小时在线，请切记不要做以下操作 "
                  )
                ]),
                _c("p", { staticClass: "tips-item indent" }, [
                  _vm._v(" 不要在其他电脑设备上登录托管的企业微信； ")
                ]),
                _c("p", { staticClass: "tips-item indent" }, [
                  _vm._v("不要在企业微信手机端【退出登录】；")
                ]),
                _c(
                  "p",
                  { staticClass: "tips-item indent" },
                  [
                    _vm._v(
                      " 不要在企业微信手机端【切换企业】，否则云端将会自动掉线。 "
                    ),
                    _c("el-popover", { attrs: { trigger: "hover" } }, [
                      _c(
                        "p",
                        {
                          style: { textAlign: "center", margin: "0 0 10px 0" }
                        },
                        [_vm._v(" 扫码添加客服好友 ")]
                      ),
                      _c("img", {
                        staticClass: "qr-code",
                        style: { width: "172px", height: "172px" },
                        attrs: {
                          src: require("@assets/images/contact_me_qr.png"),
                          alt: ""
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "tips-customer-service",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [_vm._v(" 如有疑问，请联系客服 ")]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "balance" }, [
                _c("div", { staticClass: "bean" }, [
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.beanBalance))
                  ]),
                  _vm._v("（当前可用云豆）"),
                  _vm.beanFreeze
                    ? _c("span", { staticStyle: { marginleft: "15px" } }, [
                        _vm._v("已冻结云豆：" + _vm._s(_vm.beanFreeze))
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "rule" }, [
                  _c("h5", { staticClass: "title" }, [
                    _c("img", {
                      attrs: { src: require("@assets/svg/file.svg") }
                    }),
                    _vm._v("扣费规则")
                  ]),
                  _c("p", { staticClass: "tips-item" }, [
                    _vm._v(
                      " 1.一个在线账号每个自然日扣1云豆，每个账号登录即扣费，请确保使用正确账号登录； "
                    )
                  ]),
                  _c("p", { staticClass: "tips-item" }, [
                    _vm._v(
                      " 2.扫描二维码登录成功即扣除云豆，请确保使用本企业微信员工账号扫码登录； "
                    )
                  ]),
                  _c("p", { staticClass: "tips-item" }, [
                    _vm._v(
                      " 3.为了避免重复扣费，生成二维码在没扫码登录前，会暂时冻结1云豆3分钟。 "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "qr-area" }, [
                _c("img", {
                  staticClass: "qr",
                  attrs: {
                    src: _vm.qr || require("@assets/images/qrCode.png"),
                    alt: "qr"
                  }
                }),
                _vm.beanBalance === 0 && !_vm.succ3min
                  ? _c("div", { staticClass: "mask" }, [
                      _c("div", { staticClass: "no-balance" }, [
                        _c("div", [_vm._v("当前余额不足")]),
                        _c("div", [_vm._v("无法生成二维码")]),
                        _c(
                          "span",
                          {
                            staticClass: "recharge-btn",
                            on: { click: _vm.handleRecharge }
                          },
                          [_vm._v("立即充值")]
                        )
                      ])
                    ])
                  : _vm.showBtn
                  ? _c(
                      "div",
                      { staticClass: "mask succ-cont" },
                      [
                        !_vm.isFirst
                          ? _c("img", {
                              staticClass: "succ-img",
                              attrs: {
                                src: require("@assets/svg/paySucc.svg"),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        !_vm.isFirst
                          ? _c("p", { staticClass: "succ-desc" }, [
                              _vm._v("登录成功")
                            ])
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { round: "", type: "primary" },
                            on: { click: _vm.requestLogin }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.isFirst ? "立即扫码登录" : "继续生成二维码"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm.errorType === 2 || _vm.errorType === 3
                  ? _c("div", { staticClass: "mask" }, [
                      _c("div", { staticClass: "invalid" }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.errorType === 3
                                  ? "生成二维码超时"
                                  : "二维码已失效"
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "recharge-btn",
                            on: { click: _vm.requestLogin }
                          },
                          [_vm._v("点击刷新")]
                        )
                      ])
                    ])
                  : _vm.errorType === 0
                  ? _c("div", { staticClass: "mask" }, [
                      _c("div", { staticClass: "not-enough" }, [
                        _c("div", [_vm._v("当前设备不足")]),
                        _c("div", [_vm._v("请联系客服处理")]),
                        _c(
                          "span",
                          {
                            staticClass: "recharge-btn",
                            on: { click: _vm.requestLogin }
                          },
                          [_vm._v("点击刷新")]
                        )
                      ])
                    ])
                  : _vm.errorType === 1
                  ? _c("div", { staticClass: "mask" }, [
                      _c("div", { staticClass: "net-error" }, [
                        _c("div", [_vm._v("当前网络错误")]),
                        _c(
                          "span",
                          {
                            staticClass: "recharge-btn",
                            on: { click: _vm.requestLogin }
                          },
                          [_vm._v("点击刷新")]
                        )
                      ])
                    ])
                  : _vm.loading || _vm.loging
                  ? _c("div", { staticClass: "mask" }, [
                      _c("div", { staticClass: "loading" }, [
                        _c("img", {
                          staticClass: "loading-img",
                          attrs: {
                            src: require("@assets/images/loading_m.png"),
                            alt: "loading"
                          }
                        }),
                        _c("div", { staticClass: "loading-desc" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.loging ? "登录中" : "正在生成二维码") +
                              " "
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("div", { staticClass: "wx" }, [
                  _vm._v(" 请使用"),
                  _c("img", {
                    attrs: {
                      src: require("@assets/svg/enwechat.svg"),
                      alt: "qr"
                    }
                  }),
                  _vm._v("企业微信扫码登录 ")
                ]),
                _c("div", [_vm._v("如有问题，请联系客服处理")])
              ])
            ]
          )
        ]
      ),
      _c("ly-recharge", {
        on: { success: _vm.getBeanBalance },
        model: {
          value: _vm.showRecharge,
          callback: function($$v) {
            _vm.showRecharge = $$v
          },
          expression: "showRecharge"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }