var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "150px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "推送方式：", prop: "reward_push_type" } },
        [
          _vm.hasCloudBean
            ? _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.reward_push_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "reward_push_type", $$v)
                    },
                    expression: "form.reward_push_type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("手动推送")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自动推送")])
                ],
                1
              )
            : _vm._e(),
          _vm.form.reward_push_type === 1
            ? _c(
                "div",
                {
                  staticClass: "q-bg text-small",
                  staticStyle: { margin: "10px 0" }
                },
                [
                  _vm.hasCloudBean
                    ? _c("div", { staticStyle: { "margin-bottom": "8px" } }, [
                        _vm._v(" 由于企微官方对群发限制，每个用户每日只能收到"),
                        _c("b", [_vm._v("1")]),
                        _vm._v("条奖品推送消息。 "),
                        _c(
                          "span",
                          {
                            staticClass: "q-primary",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                _vm.form.reward_push_type = 2
                              }
                            }
                          },
                          [_vm._v("选择“自动推送”突破限制 >")]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm._v(
                        " 消息推送完成后，需要对应的员工手动确认，确认后消息发送给对应用户。 "
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            placement: "top",
                            "popper-class": "q-tooltip is-light"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    margin: "24px 24px 16px",
                                    width: "777px"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "https://image.01lb.com.cn//uploads/wecom/21/0416/1618577894N96zjbn3.png",
                                      width: "777",
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _c("span", { staticClass: "q-primary" }, [
                            _vm._v("员工如何确认消息发送？")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : [
                _c(
                  "div",
                  {
                    staticClass: "q-bg text-small",
                    staticStyle: { "line-height": "1.6", margin: "12px 0" }
                  },
                  [
                    _c("div", { staticStyle: { "margin-bottom": "8px" } }, [
                      _vm._v(
                        ' 1.员工登录云端后，将无群发消息次数限制，能够自动发送"奖品通知"和“助力提醒”。 '
                      )
                    ]),
                    _c("div", { staticStyle: { "margin-bottom": "8px" } }, [
                      _vm._v(
                        " 2. 选择此方式，未登录云端的员工，将默认使用【手动推送】的方式，且不发送“助力提醒”。 "
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        " 3.员工登录云端后，如果员工在电脑登录企业微信，将会把云端登录挤掉线，造成无法自动发送内容，活动期间建议保持员工云端在线登录状态。 "
                      )
                    ])
                  ]
                ),
                _c("account-list", {
                  ref: "accountListRef",
                  staticStyle: { "line-height": "1.6" }
                })
              ]
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { label: "奖品推送：", prop: "reward_notice_text" } },
        [
          _c("ly-editor", {
            staticClass: "act-form__editor",
            attrs: {
              title: "奖品推送",
              id: "notice",
              content: _vm.form.reward_notice_text,
              insetMenu: ["用户昵称", "任务阶段", "奖品名称"],
              recoverContent: _vm.basePushText,
              emoji: ""
            },
            on: { getContent: _vm.fn_getPushText }
          })
        ],
        1
      ),
      _vm.form.reward_push_type === 2
        ? _c(
            "el-form-item",
            { attrs: { label: "助力成功提醒：", prop: "help_notice_text" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "reward_radio",
                  model: {
                    value: _vm.form.help_push_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "help_push_type", $$v)
                    },
                    expression: "form.help_push_type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("每新增一位好友提醒上级")
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("每一阶新增的第一位好友助力成功提醒上级")
                  ])
                ],
                1
              ),
              _c("ly-editor", {
                staticClass: "act-form__editor success-notice",
                attrs: {
                  title: "助力成功提醒",
                  id: "help",
                  content: _vm.form.help_notice_text,
                  insetMenu: [
                    "用户昵称",
                    "助力好友昵称",
                    "已完成任务量",
                    "未完成任务量",
                    "奖品名称"
                  ],
                  recoverContent: _vm.baseHelpText,
                  emoji: ""
                },
                on: { getContent: _vm.fn_getHelpText }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.form.reward_push_type === 2
        ? _c(
            "el-form-item",
            { attrs: { label: "好友取关提醒：", prop: "friend_notice_text" } },
            [
              _c(
                "div",
                { staticClass: "flex", staticStyle: { height: "36px" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.friend_push_type,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "friend_push_type", $$v)
                      },
                      expression: "form.friend_push_type"
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "q-info",
                      staticStyle: {
                        "line-height": "20px",
                        "margin-left": "10px"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.form.friend_push_type === 0 ? "已关闭" : "已开启"
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.form.friend_push_type === 1
                ? _c("ly-editor", {
                    staticClass: "act-form__editor closing-notice",
                    attrs: {
                      title: "好友取关提醒",
                      id: "friend",
                      content: _vm.form.friend_notice_text,
                      insetMenu: [
                        "用户昵称",
                        "助力好友昵称",
                        "已完成任务量",
                        "未完成任务量",
                        "奖品名称",
                        "涨粉账号"
                      ],
                      recoverContent: _vm.baseDelText,
                      emoji: ""
                    },
                    on: { getContent: _vm.fn_getFriendText }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }