var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加托管的群",
        "custom-class": "add-management",
        visible: _vm.visible,
        width: "800px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.handleOpen
      }
    },
    [
      _c("div", { staticClass: "temp-border" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("云端账号")]),
            _c("span", [_vm._v("已托管数量")])
          ]),
          _c(
            "div",
            { staticClass: "list" },
            [
              _c("el-input", {
                staticClass: "list-search",
                attrs: {
                  size: "medium",
                  clearable: "",
                  "suffix-icon": "iconfont icon-search",
                  placeholder: "请输入云端账号"
                },
                on: { input: _vm.fn_searchRobot },
                model: {
                  value: _vm.robotFilter,
                  callback: function($$v) {
                    _vm.robotFilter = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "robotFilter"
                }
              }),
              _vm.robotlist.length === 0
                ? [
                    _c("div", { staticClass: "el-table__empty-block" }, [
                      _c("span", { staticClass: "el-table__empty-text" }, [
                        _vm._v("暂无数据")
                      ])
                    ])
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "list-block" },
                      _vm._l(_vm.robotlistFilter, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "list-item",
                            class: { active: _vm.currentRobot.id === item.id },
                            on: {
                              click: function($event) {
                                return _vm.handleItemClick(item)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "temp" },
                              [
                                _c("el-avatar", {
                                  attrs: { size: 32, src: item.avatar }
                                }),
                                _c("span", { staticClass: "nick" }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                item.status === 1
                                  ? _c("span", { staticClass: "online" }, [
                                      _vm._v("(在线)")
                                    ])
                                  : _c("span", { staticClass: "offline" }, [
                                      _vm._v("(离线)")
                                    ])
                              ],
                              1
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s((item.work_group_total || 0) + "/30")
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
            ],
            2
          )
        ]),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 未被托管的群"),
              _c("span", [_vm._v("(已选" + _vm._s(_vm.selected.length) + ")")])
            ]),
            _c("ly-table", {
              ref: "lyTable",
              attrs: {
                data: _vm.tableData,
                option: _vm.tableOpt,
                page: _vm.page,
                pageSize: _vm.per_page,
                total: _vm.total
              },
              on: {
                searchChange: _vm.searchChange,
                sizeChange: _vm.handleSizeChange,
                currentChange: _vm.handleCurrentChange,
                selectionChange: _vm.handleSelectionChange
              },
              scopedSlots: _vm._u([
                {
                  key: "handler",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "search-form" },
                        [
                          _c("label", [_vm._v("群名称：")]),
                          _c("el-input", {
                            attrs: {
                              size: "medium",
                              placeholder: "请输入群名称",
                              clearable: ""
                            },
                            on: { input: _vm.queryData },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "handlerright",
                  fn: function() {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            round: "",
                            type: "primary",
                            disabled: !_vm.currentRobot.id,
                            icon: "icon-data iconfont",
                            loading: _vm.reload
                          },
                          on: { click: _vm.handleSync }
                        },
                        [_vm._v(_vm._s(_vm.reload ? "同步中..." : "同步"))]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "如果未找到相关的群聊，请在群上发 一条消息，再尝试同步数据。",
                            placement: "top-end"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-warning-fill",
                            staticStyle: {
                              margin: "0 20px 0 10px",
                              "font-size": "16px"
                            }
                          })
                        ]
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "name_slot",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("div", { staticClass: "flex flex-start" }, [
                        _c("div", { staticClass: "ly-table-tearm" }, [
                          _c("i", { staticClass: "iconfont icon-weixin" })
                        ]),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "8px", flex: "1" } },
                          [_vm._v(_vm._s(row.name ? row.name : "群聊"))]
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !_vm.selected.length,
                size: "medium"
              },
              on: { click: _vm.handleAddManage }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }