var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "reward_title",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "avatar-name" }, [
                  _c("img", {
                    staticStyle: { "margin-right": "8px" },
                    attrs: {
                      src: require("../../../../assets/svg/reward.svg"),
                      alt: ""
                    }
                  }),
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(row.reward_title))
                  ])
                ])
              ]
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.reward_type === 3
                  ? [
                      row.status === 1
                        ? _c("ly-status", { attrs: { type: "danger" } }, [
                            _vm._v("未填写")
                          ])
                        : _vm._e(),
                      row.status === 2
                        ? _c("ly-status", { attrs: { type: "success" } }, [
                            _vm._v("已填写")
                          ])
                        : _vm._e(),
                      row.status === 3
                        ? _c("ly-status", { attrs: { type: "warning" } }, [
                            _vm._v("未发放")
                          ])
                        : _vm._e()
                    ]
                  : [
                      row.status !== 3
                        ? _c("ly-status", { attrs: { type: "success" } }, [
                            _vm._v("已发放")
                          ])
                        : [
                            row.reward_type === 2
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      trigger: "hover"
                                    }
                                  },
                                  [
                                    _c(
                                      "ly-status",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "danger"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("未发放")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "resend" },
                                      [
                                        _c("p", { staticClass: "desc" }, [
                                          _c("i", {
                                            staticClass: "el-icon-warning",
                                            staticStyle: { color: "#e6a23c" }
                                          }),
                                          _vm._v("兑换码不足 ")
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              round: "",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleResend(row)
                                              }
                                            }
                                          },
                                          [_vm._v("重新发放")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c("ly-status", { attrs: { type: "danger" } }, [
                                  _vm._v("未发放")
                                ])
                          ]
                    ]
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                row.reward_type === 3
                  ? [
                      row.reward_value
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.getRewardDetail(row)
                                }
                              }
                            },
                            [_vm._v("查看奖品内容")]
                          )
                        : [_vm._v(" - ")]
                    ]
                  : [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.getRewardDetail(row)
                            }
                          }
                        },
                        [_vm._v("查看奖品内容")]
                      )
                    ]
              ]
            }
          }
        ])
      }),
      _c("resend", { ref: "resend", attrs: { recordId: _vm.recordId } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品内容",
            visible: _vm.dialogVisible,
            "append-to-body": "",
            "custom-class": "q-dialog",
            width: "34%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "reward" }, [
            _vm.rewardInfo.reward_type === 1
              ? _c("div", { staticClass: "q-dialog-block q-dialog__img" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.rewardInfo.reward_detail)
                    }
                  }),
                  _c("img", {
                    staticClass: "reward-img",
                    attrs: { src: _vm.rewardInfo.reward_detail_img }
                  })
                ])
              : _vm.rewardInfo.reward_type === 2
              ? _c("div", { staticClass: "q-dialog-block" }, [
                  _c("div", { staticClass: "reward-code" }, [
                    _vm._v(_vm._s(_vm.rewardInfo.reward_value))
                  ])
                ])
              : _vm.rewardInfo.reward_type === 3
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.rewardLoading,
                        expression: "rewardLoading"
                      }
                    ],
                    staticClass: "reward-list"
                  },
                  _vm._l(_vm.rewardDetail, function(i, v) {
                    return _c(
                      "div",
                      { key: v, staticClass: "reward-list__item" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(i.name))
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(_vm._s(i.data))
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }