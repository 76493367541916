/*
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-17 11:27:46
 * @LastEditTime: 2021-05-20 19:23:58
 */
import axios from '@/common/ajax.js'

export const groupData = {
  reqQuery: params => axios.post('/wecom/robot/group/static', params),
  getTopic: (params) => axios.get('/wecom/robot/group/topic', { params }),
  getOwner: (params) => axios.get('/wecom/robot/group/owners', { params })
}

export const dataDetail = {
  reqQuery: params => axios.post('/wecom/robot/group/static/detail', params)
}

export const keyWords = {
  reqQuery: (params) => axios.get('/wecom/robot/group/key', { params }),
  reqAdd: (params) => axios.post('/wecom/robot/group/key', params),
  reqDelete: (params) => axios.delete('/wecom/robot/group/key', { data: params })
}

export const keyWordsDetail = {
  reqQuery: (params) => axios.post('/wecom/robot/group/key/static', params)
  // reqQuery: params => axios.get(`/wecom/keyWordsDetail/test?${stringify(params)}`)
}
