<!--
 * @Author: zhanln
 * @Date: 2021-11-10 16:23:21
 * @LastEditTime: 2021-11-30 17:39:05
 * @LastEditors: zhanln
 * @Description: 预览 - 任务设置
-->

<template>
  <ly-mobile
    :showWel="false"
    :hasFooter="false"
    backIcon="icon-close"
    sub=""
    :title="show_title"
  >
    <div class="reward">
      <!-- header -->
      <div class="reward-header">
        <div class="reward-header__user">
          <div class="avatar">
            <img :src="userInfo.avatar_url" alt="" v-if="userInfo.avatar_url" />
            <img src="@assets/images/app_logo.png" alt="" v-else />
          </div>

          <div class="info">
            <div class="name">{{ userInfo.nickname || "零一裂变" }}</div>
            <div class="rank">
              <img src="@assets/images/ic_rank.png" alt="" />排名 1200
            </div>
          </div>
        </div>

        <div
          class="reward-header__rule"
          @click="$refs.rewardRuleRef.visible = true"
        >
          活动规则
        </div>
      </div>

      <!-- step info -->
      <div class="reward-card">
        <!-- staps tab -->
        <div class="reward-tab" v-if="stepsLength > 1">
          <template v-for="(step, index) in steps">
            <div
              class="item"
              :class="{
                current: index === current,
                corner: index === current - 1,
              }"
              v-if="step.status === 1"
              :key="index"
            >
              {{ step.title }} <br />
              邀请{{ fn_getTarget(index) }}人
            </div>
          </template>
        </div>

        <div class="reward-step" :class="{ 'has-radius': stepsLength === 1 }">
          <!-- info -->
          <div class="info">
            <div class="cover">
              <img :src="reward_cover || defaultCover" alt="" />
            </div>
            <div class="right">
              <div class="title">{{ reward_title || "奖品名称" }}</div>
              <div class="desc ellipsis2">
                {{ reward_desc || "奖品简介" }}
              </div>
              <div class="total">
                {{ open_stock === 1 ? "剩余库存：" + total : "库存告急" }}
              </div>
            </div>
          </div>

          <!-- progress -->
          <div class="reward-progress">
            <div class="reward-progress__bar">
              <div class="reward-progress__text" :style="{ left: barWidth }">
                已邀请 {{ inviterCount }} 人
              </div>
              <div
                class="reward-progress__inner"
                :style="{ width: barWidth }"
              ></div>
            </div>
            <div class="reward-progress__count">
              <span>{{ inviterCount }}</span
              >/{{ target_count }}
            </div>
          </div>

          <!-- unreal -->
          <div class="reward-unreal">
            <div class="reward-unreal__avatars">
              <img
                :src="item"
                alt=""
                v-for="(item, index) in avatarList.slice(0, unreal)"
                :key="index"
              />
              <span class="elip" v-if="unreal > 4"
                ><i class="el-icon-more"></i
              ></span>
              <span class="text">
                已有<b>{{ unreal }}人</b>领取成功
              </span>
            </div>
          </div>

          <!-- invite btn -->
          <div class="reward-inviter">
            还差
            {{ fn_getTarget(current) - inviterCount }}
            人，立即邀请好友
          </div>
        </div>
      </div>

      <!-- rank -->
      <div class="reward-rank">
        <img src="@assets/images/task_rank.png" alt="" />
      </div>
    </div>

    <!-- 奖品预览框 -->
    <reward-detail></reward-detail>

    <!-- 活动规则 -->
    <reward-rule ref="rewardRuleRef" :tabKey="tabKey"></reward-rule>
  </ly-mobile>
</template>

<script>
import rewardDetail from './rewardDetail'
import rewardRule from './rewardRule'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'taskMobileReward',

  props: {
    tabKey: {
      type: String,
      default: 'reward'
    }
  },

  data () {
    return {
      current: 0,
      inviterCount: 0,
      steps: [
        {
          title: '一阶任务',
          status: 1,
          unreal: 1000
        },
        {
          title: '二阶任务',
          status: 0,
          unreal: 1000
        },
        {
          title: '三阶任务',
          status: 0,
          unreal: 1000
        }
      ],
      defaultCover: require('@/assets/images/task_gift.png'),
      avatarList: [
        'https://image.01lb.com.cn//uploads/wecom/21/1012/16340233797B2wfJUM.png',
        'https://image.01lb.com.cn//uploads/wecom/21/1012/16340233630761Wn3q.png',
        'https://image.01lb.com.cn//uploads/wecom/21/1012/1634023372q055Y21g.png',
        'https://image.01lb.com.cn//uploads/wecom/21/1012/16340233550G48EwXE.png'
      ]
    }
  },

  computed: {
    ...mapGetters({
      userInfo: 'permission/GET_USERINFO'
    }),
    ...mapState({
      show_title: state => state.task.show_title,
      current_step: state => state.task.current_step,
      status_0: state => state.task.status_0 || 1,
      status_1: state => state.task.status_1,
      status_2: state => state.task.status_2
    }),
    reward_cover () {
      return this.fn_getInfo('reward_cover')
    },
    reward_title () {
      return this.fn_getInfo('reward_title')
    },
    reward_desc () {
      return this.fn_getInfo('reward_desc')
    },
    open_stock () {
      return this.fn_getInfo('open_stock')
    },
    total () {
      const use_count = this.fn_getInfo('use_count')
      const total = this.fn_getInfo('total')
      return use_count ? total - use_count : total
    },
    unreal () {
      return this.fn_getInfo('unreal')
    },
    stepsLength () {
      const openStep = [this.status_0, this.status_1, this.status_2].filter(item => {
        return item === 1
      }).length
      return openStep
    },
    target_count () {
      return this.$store.state.task[`target_count_${this.current}`]
    },
    barWidth () {
      const w = (this.inviterCount / this.target_count) <= 0.1 ? 0.1 : (this.inviterCount / this.target_count)
      return w * 100 + '%'
    }
  },

  watch: {
    current_step (val) {
      const status = this[`status_${val}`] === 1
      if (status) {
        this.current = val
      }
    },
    status_1 (val) {
      this.steps[1].status = val
      if (val && this.current !== 1) {
        this.current = 1
      } else {
        this.current = 0
      }
    },
    status_2 (val) {
      this.steps[2].status = val
      if (val && this.current !== 2) {
        this.current = 2
      } else {
        this.current = 1
      }
    }
  },

  mounted () {
    this.fn_init()
  },

  methods: {
    fn_init () {
      const step = this.current_step
      const status = this[`status_${step}`] === 1
      if (status) {
        this.current = step
      }

      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i].status = this[`status_${i}`]
      }
    },

    // 任务人数
    fn_getTarget (step) {
      return this.$store.state.task[`target_count_${step}`] || '*'
    },

    // 获取奖品信息，从vuex
    fn_getInfo (type) {
      return this.$store.getters['task/GET_REWARD_INFO']({
        step: this.current,
        type
      })
    }
  },

  components: {
    rewardDetail,
    rewardRule
  }
}
</script>

<style lang="scss" scoped>
.reward {
  background-image: url(/img/task_mobi_bg.5df8c8a0.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #fe5e29;
  padding: 16px 10px 0;
  box-sizing: border-box;
  user-select: none;

  &-header {
    display: flex;

    &__user {
      flex: 1;
      display: flex;
      color: #ffedb9;

      .avatar {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #fff;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        width: 150px;
      }

      .rank {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
        }
      }
    }

    &__rule {
      color: #fff;
      border: 1px solid #fff;
      border-radius: 12px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      padding: 0 12px;
    }
  }

  &-card {
    margin: 14px 0;
  }

  &-tab {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 1;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 20px;
      top: 10px;
      left: 0;
      background: #fff0eb;
      border-radius: 16px 16px 0 0;
      z-index: -1;
    }

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 20px;
      bottom: 0;
      left: 0;
      background: #fff;
      border-radius: 16px 16px 0 0;
      z-index: -1;
    }

    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff0eb;
      color: #fe5e29;
      font-weight: 600;
      text-align: center;
      height: 54px;

      &:first-child {
        border-top-left-radius: 16px;
      }

      &:last-child {
        border-top-right-radius: 16px;
      }

      &.current {
        background-color: #fff;
        border-radius: 16px 16px 0 0;
        height: 64px;

        & + .item {
          border-end-start-radius: 16px;
        }
      }

      &.corner {
        border-end-end-radius: 16px;
      }
    }
  }

  &-step {
    background-color: #fff;
    padding: 20px;
    border-radius: 0px 0px 16px 16px;

    &.has-radius {
      border-radius: 16px;
    }

    .info {
      display: flex;
    }

    .cover {
      width: 64px;
      height: 64px;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 12px;

      img {
        width: 100%;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .title {
      color: #212b36;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }

    .desc {
      font-size: 13px;
      color: #454D5B;
      line-height: 18px;
      margin: 4px 0;
    }

    .total {
      font-size: 12px;
      color: #fe5e29;
    }
  }

  &-progress {
    display: flex;
    align-items: center;
    margin-top: 48px;

    &__bar {
      position: relative;
      flex: 1;
      background-color: #efefef;
      height: 6px;
      border-radius: 3px;
      margin-right: 12px;
    }

    &__inner {
      position: relative;
      height: 100%;
      width: 50%;
      border-radius: 3px;
      background: linear-gradient(90deg, #fecd01 0%, #fe5e29 100%);

      &::after {
        position: absolute;
        content: "";
        right: -6.5px;
        top: -4px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #fff;
        border: 3px solid #fe5e29;
        box-sizing: border-box;
      }
    }

    &__text {
      position: absolute;
      top: -40px;
      left: 0;
      background-color: #fe5e29;
      height: 26px;
      min-width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      line-height: 1;
      color: #fff;
      font-size: 12px;
      border-radius: 7px;
      transform: translateX(-50%);

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        top: 100%;
        width: 0;
        height: 0;
        transform: translateX(-55%);
        border: 5px solid transparent;
        border-top-color: #fe5e29;
      }
    }

    &__count {
      span {
        color: #fe5e29;
      }
    }
  }

  &-unreal {
    &__avatars {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #fff;

        &:not(:first-child) {
          margin-left: -6px;
        }
      }

      .elip {
        width: 24px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #ffe4dc;
        border: 2px solid #fff;
        color: #fc990e;
        margin-left: -6px;
        border-radius: 50%;
      }

      .text {
        margin-left: 6px;
        font-size: 12px;
      }
    }
  }

  &-inviter {
    margin-top: 24px;
    height: 40px;
    border-radius: 25px;
    background: linear-gradient(151deg, #fecd01 0%, #fb7f15 100%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &-rank {
    margin: 16px -8px 0 -8px;
    img {
      width: 100%;
    }
  }
}
</style>
