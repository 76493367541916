var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "basicFormRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.basicForm,
        rules: _vm.basicFormRules,
        "label-width": "125px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "活码名称", prop: "title" } },
        [
          _c("el-input", {
            staticStyle: { width: "420px" },
            attrs: {
              maxlength: "30",
              "show-word-limit": "",
              placeholder: "名称不会展示给用户，用于企业记录渠道名称或使用场景"
            },
            on: {
              change: function($event) {
                return _vm.fn_setForm($event, "title")
              }
            },
            model: {
              value: _vm.basicForm.title,
              callback: function($$v) {
                _vm.$set(
                  _vm.basicForm,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "basicForm.title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "所属分组", required: "" } },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "" },
              on: {
                change: function($event) {
                  return _vm.fn_setForm($event, "group_id")
                }
              },
              model: {
                value: _vm.basicForm.group_id,
                callback: function($$v) {
                  _vm.$set(_vm.basicForm, "group_id", $$v)
                },
                expression: "basicForm.group_id"
              }
            },
            _vm._l(_vm.groupList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.groupName, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "涨粉账号", error: _vm.userError } },
        [
          _c("template", { slot: "label" }, [
            _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
            _vm._v(" 涨粉账号")
          ]),
          _c("ly-emp-btn", {
            attrs: {
              btnText: "添加涨粉账号",
              max: 100,
              isReal: true,
              datafilter: true,
              checkList: _vm.basicForm.wecom_user_ids_str
            },
            on: { getEmpIdArr: _vm.fn_getEmpId }
          })
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { label: "欢迎语", required: "", error: _vm.wordError } },
        [
          _c("ly-editor", {
            staticStyle: { width: "100%", "max-width": "560px" },
            attrs: {
              title: "欢迎语",
              id: "prizeWords",
              content: _vm.basicForm.wel_words,
              insetMenu: ["用户昵称"],
              recoverContent: _vm.wel_words_base,
              error: _vm.wordError !== "",
              emoji: "",
              maxLength: 800
            },
            on: { getContent: _vm.fn_getWelWords }
          }),
          _c(
            "div",
            { staticClass: "basic-attach" },
            [
              _vm._l(_vm.basicForm.attachments, function(item, i) {
                return _c("msg-template", {
                  key: item.temp,
                  class: "type" + item.msg_type,
                  attrs: {
                    title:
                      "附件" + (i + 1) + "：" + _vm.getMsgType(item.msg_type),
                    msg: item
                  },
                  on: { change: _vm.handleAction }
                })
              }),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    icon: "iconfont icon-plus",
                    type: "primary",
                    round: ""
                  },
                  on: { click: _vm.handleAddContent }
                },
                [_vm._v(" 添加图片/链接/小程序")]
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "失效时间", required: "" } },
        [
          _c(
            "el-radio-group",
            {
              on: {
                change: function($event) {
                  return _vm.fn_setForm($event, "expire_type")
                }
              },
              model: {
                value: _vm.basicForm.expire_type,
                callback: function($$v) {
                  _vm.$set(_vm.basicForm, "expire_type", $$v)
                },
                expression: "basicForm.expire_type"
              }
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("永不失效")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自定义")])
            ],
            1
          ),
          _vm.basicForm.expire_type === 2
            ? _c(
                "el-form-item",
                { staticClass: "expire_time", attrs: { error: _vm.timeError } },
                [
                  _c("el-date-picker", {
                    staticClass: "basic-time",
                    attrs: {
                      type: "datetime",
                      placeholder: "请选择时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm",
                      "popper-class": "no-current",
                      "picker-options": _vm.exPickerOptions
                    },
                    on: {
                      change: function($event) {
                        return _vm.fn_setForm($event, "expire_time")
                      }
                    },
                    model: {
                      value: _vm.basicForm.expire_time,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.basicForm,
                          "expire_time",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "basicForm.expire_time"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("add-attach", { ref: "addAttachRef", on: { push: _vm.fn_pushAttach } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }