var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "poster" }, [
    _c(
      "div",
      { staticClass: "poster-bg" },
      [
        _vm.poster_img
          ? _c("img", { attrs: { src: _vm.poster_img, alt: "" } })
          : [_c("span", { staticClass: "q-info" }, [_vm._v("请上传海报")])]
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "poster-edit" },
      [
        _vm.avatar_status === 1
          ? _c(
              "vue-draggable-resizable",
              {
                staticClass: "dragWrap dragAvatar",
                attrs: {
                  x: Number(_vm.form.avatar_x),
                  y: Number(_vm.form.avatar_y),
                  w: Number(_vm.form.avatar_width),
                  h: Number(_vm.form.avatar_height),
                  "min-width": 30,
                  "min-height": 30,
                  parent: true,
                  handles: ["br"],
                  "lock-aspect-ratio": true
                },
                on: {
                  dragging: function(x, y) {
                    return _vm.onDrag(x, y, "avatar")
                  },
                  resizing: function(x, y, w, h) {
                    return _vm.onResize(w, h, "avatar")
                  },
                  dragstop: function($event) {
                    return _vm.onDragstop("avatar")
                  },
                  resizestop: function($event) {
                    return _vm.onResizstop("avatar")
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "poster-avatar",
                    class: { "is-radius": _vm.avatar_sharp === 2 }
                  },
                  [_vm._v(" 头像 ")]
                )
              ]
            )
          : _vm._e(),
        _vm.nickname_status === 1
          ? _c(
              "vue-draggable-resizable",
              {
                staticClass: "dragWrap",
                attrs: {
                  x: Number(_vm.form.nickname_x),
                  y: Number(_vm.form.nickname_y),
                  w: _vm.nickname_size * _vm.namelength,
                  grid: [1, 1],
                  h: "auto",
                  parent: true,
                  handles: [],
                  "lock-aspect-ratio": true
                },
                on: {
                  dragging: function(x, y) {
                    return _vm.onDrag(x, y, "nickname")
                  },
                  dragstop: function($event) {
                    return _vm.onDragstop("nickname")
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    style: {
                      color: _vm.nickname_color,
                      "font-size": _vm.nickname_size + "px",
                      "text-align": _vm.nickname_align
                    }
                  },
                  [_vm._v(" 用户昵称 ")]
                )
              ]
            )
          : _vm._e(),
        _c(
          "vue-draggable-resizable",
          {
            staticClass: "dragWrap poster-code",
            attrs: {
              x: Number(_vm.form.qrcode_x),
              y: Number(_vm.form.qrcode_y),
              w: Number(_vm.form.qrcode_width),
              h: Number(_vm.form.qrcode_height),
              "min-width": 30,
              "min-height": 30,
              parent: true,
              handles: ["br"],
              "lock-aspect-ratio": true
            },
            on: {
              dragging: function(x, y) {
                return _vm.onDrag(x, y, "qrcode")
              },
              resizing: function(x, y, w, h) {
                return _vm.onResize(w, h, "qrcode")
              },
              dragstop: function($event) {
                return _vm.onDragstop("qrcode")
              },
              resizestop: function($event) {
                return _vm.onResizstop("qrcode")
              }
            }
          },
          [
            _c("img", {
              attrs: { src: require("@assets/images/contact_me_qr.png") }
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }