var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-tree", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.loading,
        expression: "loading"
      }
    ],
    ref: "depTree",
    staticClass: "dep-tree",
    attrs: {
      data: _vm.depList,
      props: _vm.defaultProps,
      "show-checkbox": "",
      "default-expanded-keys": _vm.defaultOpen,
      "node-key": "id",
      "check-strictly": _vm.checkType !== ""
    },
    on: { check: _vm.fn_treeCheck, "check-change": _vm.fn_checkChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }