var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("batch", {
    attrs: { type: "announcement" },
    on: {
      submit: function(type) {
        return (_vm.backComfirm = type)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }