<!--
 * @Descripttion: 创建活动页面
 * @version: 1.0.0
-->
<template>
  <el-main class="inner edit-container">
    <div class="edit-main edit-poster" v-loading="pageLoading" ref="editRef">
      <!-- 配置导航 -->
      <div class="edit-card edit-menu">
        <el-tabs v-model="tabKey" @tab-click="fn_setTab">
          <el-tab-pane label="活动信息" name="basic"></el-tab-pane>
          <el-tab-pane label="红包设置" name="redenvelope"></el-tab-pane>
          <el-tab-pane label="裂变海报" name="poster"></el-tab-pane>
          <el-tab-pane label="活动页设置" name="other"></el-tab-pane>
          <el-tab-pane label="高级防刷" name="preventbrush"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="edit-poster-content" ref="main">
        <el-scrollbar class="left-area">
          <!-- 配置页 -->
          <set-basic ref="basic" title="活动信息" :aid="id"></set-basic>
          <set-redenvelope ref="redenvelope" title="红包设置"></set-redenvelope>
          <set-poster ref="poster" title="裂变海报"></set-poster>
          <set-other ref="other" title="活动页设置"></set-other>
          <set-preventBrush
            ref="preventbrush"
            title="高级防刷"
          ></set-preventBrush>
        </el-scrollbar>

        <!-- 预览页 -->
        <div class="edit-poster-mobi edit-card">
          <el-scrollbar style="height: 100%">
            <mobi-basic v-if="tabKey === 'basic'"></mobi-basic>
            <mobi-redenvelope
              v-if="tabKey === 'redenvelope' || tabKey === 'other'"
              :tabKey="tabKey"
            ></mobi-redenvelope>
            <mobi-poster
              v-if="tabKey === 'poster' || tabKey === 'preventbrush'"
            ></mobi-poster>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="edit-footer" style="justify-content: space-between">
      <div class="flex flex-justify-start text-regular" style="flex: 1.2"></div>
      <div class="flex flex-center" style="flex: 0.7" v-if="!pageLoading">
        <el-button disabled v-if="progress_status === 2">活动已结束</el-button>
        <template v-else>
          <el-button
            round
            icon="iconfont icon-edit"
            @click="fn_save"
            :loading="saveLoading"
            v-if="!isEdit || progress_status === 0 || isCopy"
            >保存草稿</el-button
          >
          <el-button
            round
            icon="iconfont icon-send-fill"
            type="primary"
            @click="fn_submit"
            :loading="submitLoading"
            style="margin-left: 14px"
            >发布活动</el-button
          >
        </template>
      </div>
      <div style="flex: 1.2"></div>
    </div>
  </el-main>
</template>

<script>
// 配置页
import Basic from './activity/basic'
import Redenvelope from './activity/redenvelope'
import Poster from './activity/poster'
import PreventBrush from './activity/preventBrush'
import Other from './activity/other'
// 预览页
import mobiBasic from './mobile/basic'
import mobiRedenvelope from './mobile/redenvelope'
import mobiPoster from './mobile/poster'
import mapTag from '@/common/mapTag'
export default {
  name: 'rdEdit',
  components: {
    setBasic: Basic,
    setRedenvelope: Redenvelope,
    setPoster: Poster,
    setPreventBrush: PreventBrush,
    setOther: Other,
    mobiBasic,
    mobiRedenvelope,
    mobiPoster
  },
  data () {
    return {
      id: null,
      mainList: [
        'basic', 'redenvelope', 'poster', 'other', 'preventbrush'
      ],
      pageLoading: true, // 页面 loading
      saveLoading: false, // 保存按钮 loading
      submitLoading: false, // 提交按钮 loading
      tabKey: 'basic', // 定位 key
      progress_status: 1, // 活动状态
      backComfirm: true
    }
  },

  computed: {
    isEdit () {
      return this.$store.state.rede.isEdit
    },
    isCopy () {
      return this.$store.state.rede.isCopy
    },
    status () {
      return this.$store.state.rede.status
    }
  },

  async created () {
    this.fn_resetForm()
    // 账户余额
    const account = await this.fn_getAmount()

    const _id = this.$route.query.id
    if (_id) {
      this.pageLoading = true

      this.$store.commit('_SET', {
        'rede.isEdit': true
      })
      this.id = +_id
      this.fn_getAct(account)
    } else {
      account.max_amount = account.account_amount
      const REDE = this.$store.state.rede.redenvelope
      REDE.account = account
      REDE.fee_rate = account.fee_rate
      this.$store.commit('_SET', {
        'rede.redenvelope': REDE
      })
      this.pageLoading = false
    }
  },

  mounted () {
    window.addEventListener('beforeunload', this.fn_beforeunload, false)

    // main主体滚动监测
    this.$nextTick(() => {
      const main = document.querySelector('.left-area .el-scrollbar__wrap')
      main && main.addEventListener('scroll', this.getScroll)
    })
  },

  methods: {

    // 页面刷新
    fn_beforeunload (e) {
      e = e || window.event
      if (e) {
        e.returnValue = ''
      }
      return ''
    },

    // 获取账户余额
    async fn_getAmount () {
      const data = await this.axios.get('redPacketInfo')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      const account_amount = (data.available_amount * 100 - data.freeze_amount * 100) / 100
      const account_amount_fmt = this.fn_toThousandFilter(data.available_amount - data.freeze_amount)
      const fee_rate = data.fee_rate

      return { account_amount, account_amount_fmt, fee_rate }
    },

    // 设置余额格式
    fn_toThousandFilter (num) {
      return (+num || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
    },

    // 获取活动详情
    async fn_getAct (account) {
      // 获取详情，缓存到 store
      const data = await this.axios.get('redPacketDetail', {
        params: {
          id: this.id
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      const _isCopy = this.$route.query.edit
      if (_isCopy === 'copy') {
        data.give_amount = 0
        this.$store.commit('_SET', {
          'rede.isCopy': true
        })
        this.id = null
        this.progress_status = 1
      } else {
        this.progress_status = data.progress_status
      }

      this.fn_setForm(data, account)
    },

    // 回显活动内容
    fn_setForm (data, account) {
      data.wecom_user_ids = data.wecom_user_ids ? data.wecom_user_ids.split() : null
      // 活动信息
      const basic = this.fn_getFormContent(data,
        [
          'end_date',
          'wecom_user_ids',
          'code_fail_date',
          'code_fail_mode',
          'welcome_msg',
          'link_type',
          'link_title',
          'link_desc'
        ]
      )
      basic.title = this.isCopy ? '' : data.title
      basic.link_logo = [{ url: data.link_logo }]
      basic.end_date = new Date(basic.end_date)
      basic.code_fail_date = new Date(basic.code_fail_date)
      // 红包设置
      const redenvelope = this.fn_getFormContent(data,
        [
          'activity_amount',
          'give_amount',
          'virtual_average',
          'first_give_min',
          'first_give_max',
          'is_give_old_user',
          'invite_num',
          'give_min',
          'give_max',
          'give_limit',
          'is_auto_tags'
        ]
      )
      redenvelope.auto_tags = data.auto_tags && data.auto_tags.map((item, index) => {
        item.id = this.isCopy ? 0 : item.id
        item.key = index
        return item
      })
      if (this.isCopy || data.status === 2) {
        account.max_amount = account.account_amount
      } else {
        account.max_amount = (account.account_amount * 100 + data.activity_amount * 100) / 100
      }
      if (!this.isCopy && data.status !== 2) {
        account.fee_rate = data.fee_rate
      }
      redenvelope.account = account
      redenvelope.amount = data.activity_amount
      // 高级防刷
      const brush = this.fn_getFormContent(data,
        [
          'is_info',
          'is_over_info',
          'over_rate',
          'is_invite_limit',
          'invite_second',
          'invite_num_limit'
        ]
      )
      // 其他设置
      const other = this.fn_getFormContent(data,
        [
          'rule_desc',
          'show_title',
          'bg_title'
        ]
      )
      // 裂变海报
      const poster = this.fn_getFormContent(JSON.parse(data.poster || '{}'),
        [
          'img_url', 'avatar_sharp', 'avatar_status', 'avatar_x', 'avatar_y', 'avatar_width', 'avatar_height', 'nickname_status', 'nickname_size', 'nickname_color', 'nickname_x', 'nickname_y', 'nickname_offsetX', 'nickname_align', 'qrcode_x', 'qrcode_y', 'qrcode_width', 'qrcode_height'
        ]
      )
      poster.share_msg = data.share_msg
      this.$store.commit('_SET', {
        'rede.status': data.status,
        'rede.basic': basic,
        'rede.redenvelope': redenvelope,
        'rede.poster': poster,
        'rede.brush': brush,
        'rede.other': other
      })

      this.pageLoading = false
    },

    // 按需获取回显内容
    fn_getFormContent (data, arr) {
      data.welcome_msg && (data.welcome_msg = mapTag(data.welcome_msg))
      data.rule_desc && (data.rule_desc = mapTag(data.rule_desc))
      data.show_title = data.show_title || '活动进行中'
      data.bg_title = data.bg_title || '邀请好友领红包'
      return JSON.parse(JSON.stringify(data, arr))
    },

    // tab 点击
    fn_setTab (tab) {
      const top = this.$refs[tab.name].$el.offsetTop
      document.querySelector('.left-area .el-scrollbar__wrap').scrollTop = top
      this.tabKey = tab.name
      this.initFlag = true
      setTimeout(() => {
        this.initFlag = false
      }, 50)
    },

    // 滚动监听
    getScroll (e) {
      if (this.initFlag) return false
      const main = e.target || e.srcElement || e.path[0]
      // 底部块高度较小，单独判断一波
      const $other = this.$refs.preventbrush
      const other = $other.$el
      const top = main.scrollTop
      const height = main.offsetHeight
      if (top + height > other.offsetTop + other.offsetHeight - 63) {
        this.tabKey = 'preventbrush'
      } else {
        this.util.each(this.mainList, (i, v) => {
          const obj = this.$refs[v].$el
          const y = obj.offsetTop
          const h = obj.offsetHeight

          if (top >= y - 63 - h / 1.8) {
            this.tabKey = v
          } else {
            if (top > y - 160) {
              this.tabKey = v
            }
          }
        })
      }
    },

    // 保存草稿
    async fn_save () {
      // 提交数据处理
      const basic = await this.$refs.basic.verify(2)
      if (!basic) {
        this.formScroll(this.$refs.basic.$el)
        return false
      }
      const subForm = this.fn_initSubForm(this.id, 2)
      subForm.end_date = subForm.end_date && this.$day(subForm.end_date).format('YYYY-MM-DD HH:mm:ss')
      subForm.code_fail_date = subForm.code_fail_date && this.$day(subForm.code_fail_date).format('YYYY-MM-DD HH:mm:ss')
      this.fn_submitForm(subForm, 2)
    },

    // 表单提交前
    async fn_submit () {
      const _verify = await this.fn_allVerify()
      if (!_verify) return false
      this.submitLoading = true

      // 提交数据处理
      const subForm = this.fn_initSubForm(this.id, 1)
      if (subForm.end_date.getTime() < Date.now()) {
        this.$message.error('活动结束时间不能早于当前时间')
        this.submitLoading = false
        return false
      }
      if (subForm.code_fail_date.getTime() < subForm.end_date.getTime()) {
        this.$message.error('二维码失效时间不能早于活动结束时间')
        this.submitLoading = false
        return false
      }
      subForm.end_date = this.$day(subForm.end_date).format('YYYY-MM-DD HH:mm:ss')
      subForm.code_fail_date = this.$day(subForm.code_fail_date).format('YYYY-MM-DD HH:mm:ss')
      this.fn_submitForm(subForm, 1)
    },

    // 提交验证
    async fn_allVerify () {
      // 活动信息验证
      const basic = await this.$refs.basic.verify()
      if (!basic) {
        this.formScroll(this.$refs.basic.$el)
        return false
      }

      // 红包验证
      if (!this.$refs.redenvelope.verify()) {
        this.formScroll(this.$refs.redenvelope.$el)
        return false
      }

      // 海报验证
      if (!this.$refs.poster.verify()) {
        this.formScroll(this.$refs.poster.$el)
        return false
      }

      return true
    },

    // 提交数据处理
    fn_initSubForm (id, status) {
      const FORM = this.util.extend(true, {}, this.$store.state.rede)
      const basic = FORM.basic
      basic.wecom_user_ids = basic.wecom_user_ids ? basic.wecom_user_ids.join(',') : ''
      // 链接：1、默认，2、自定义
      if (basic.link_type === 1) {
        basic.link_title = '点击链接立即领取红包👇'
        basic.link_desc = '红包实时到账微信零钱'
        basic.link_logo = 'https://image.01lb.com.cn//uploads/wecom/21/1008/1633687472W18cbF1A.png'
      } else {
        basic.link_logo = basic.link_logo[0].url
      }
      const redenvelope = FORM.redenvelope
      const _form = {
        ...basic,
        ...redenvelope,
        poster_url: FORM.poster.img_url,
        ...FORM.brush,
        ...FORM.other,
        status: status,
        welcome_msg: mapTag(FORM.basic.welcome_msg, false),
        rule_desc: mapTag(FORM.other.rule_desc, false)
      }

      _form.share_msg = FORM.poster.share_msg
      delete FORM.poster.share_msg
      _form.poster = JSON.stringify(FORM.poster)

      if (id) {
        _form.id = +id
      }

      return _form
    },

    // 表单提交
    async fn_submitForm (form, type) {
      const data = await this.axios.post('redPacketSave', form)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.submitLoading = false
        return false
      }

      if (type === 1) {
        this.$message.success('发布成功！')
      } else {
        this.$message.success('保存成功！')
      }

      this.backComfirm = false
      this.submitLoading = false
      this.$router.push('list')
    },

    // 滚动到验证位置
    formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    // 重置表单
    fn_resetForm () {
      this.$store.commit('_SET', {
        'rede.isCopy': false,
        'rede.isEdit': false,
        'rede.status': 1,
        'rede.basic': {
          title: '',
          end_date: null,
          wecom_user_ids: null,
          code_fail_date: null,
          code_fail_mode: 1,
          welcome_msg: '#客户昵称#，终于等到你\n\n1. 参与本次活动，马上领取微信红包，直接到账的那种~\n2. 点击下方链接，立即领取。\n3. 生成你的专属海报，每邀请1位好友助力即可领取随机红包。\n4. 红包金额实时到账，可在微信钱包实时查看。\n\n点击链接立即领取红包（红包实时到账微信零钱）',
          link_type: 1,
          link_logo: [{
            url: 'https://image.01lb.com.cn//uploads/wecom/21/1008/1633687472W18cbF1A.png'
          }],
          link_title: '点击链接立即领取红包👇',
          link_desc: '红包实时到账微信零钱'
        },
        'rede.redenvelope': {
          amount: 0,
          account: {
            account_amount: null,
            account_amount_fmt: ''
          },
          activity_amount: 0,
          give_amount: 0,
          virtual_average: 4.3,
          first_give_min: 0.31,
          first_give_max: 1,
          is_give_old_user: 0,
          invite_num: 1,
          give_min: 0.31,
          give_max: 1,
          give_limit: 5,
          is_auto_tags: 0,
          auto_tags: [{
            id: 0,
            key: 0,
            invite_num: 0,
            tags: '',
            type: 1
          }, {
            id: 0,
            key: 1,
            invite_num: 2,
            tags: '',
            type: 2
          }]
        },
        'rede.poster': {
          img_url: 'https://image.01lb.com.cn//uploads/wecom/21/0902/16305856436tfAq38m.png',
          avatar_sharp: 2,
          avatar_status: 1,
          avatar_x: 30,
          avatar_y: 40,
          avatar_width: 50,
          avatar_height: 50,
          nickname_status: 1,
          nickname_size: 12,
          nickname_color: '#000000',
          nickname_x: 90,
          nickname_y: 55,
          nickname_offsetX: 0,
          nickname_align: 'left',
          qrcode_x: 230,
          qrcode_y: 512,
          qrcode_width: 80,
          qrcode_height: 80,
          share_msg: '我想要领取红包，帮忙扫码添加海报内的企业微信就能帮我助力啦！'
        },
        'rede.brush': {
          is_info: 1,
          is_over_info: 1,
          over_rate: 60,
          is_invite_limit: 0,
          invite_second: 1,
          invite_num_limit: 10
        },
        'rede.other': {
          show_title: '活动进行中',
          bg_title: '邀请好友领红包',
          rule_desc: '1、邀请【邀请好友数】位好友添加企业微信，立即获得微信实时到账的红包。\n2、任务完成后，系统实时发放红包至邀请者的微信零钱，用户可查看微信支付通知，或前往 “微信-我-支付-钱包” 中查看。\n3、若邀请助力的用户为老用户或该用户已经为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户助力无法再获得红包。\n5、删除好友后助力无效。'
        }
      })
    }

  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.fn_beforeunload, false)
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          next()
        }).catch(() => { })
      } else {
        next()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
::v-deep {
  .el-tabs__nav-wrap::after {
    content: unset;
  }
}
</style>
