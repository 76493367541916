<!--
 * @Descripttion: 参与用户页面-pane组件
 * @version: 1.0.0
-->
<template>
  <ly-table ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :pageSize="per_page" :total="total"
    @searchChange="searchChange" @sizeChange="handleSizeChange" @sortChange="handleSortChange"
    @currentChange="handleCurrentChange" @selectionChange="handleSelectionChange">
    <template #title_slot="{ row }">
      <div class="flex">
        <img :src="row.avatar" alt="" class="ly-table__main-avatar" v-if="row.avatar" />
        <span>{{ row.nickname }}</span>
      </div>
    </template>

    <template #status_slot="{ row }">
      <ly-status v-if="row.status === 1">未预约</ly-status>
      <ly-status type="success" v-else-if="row.status === 2">预约成功</ly-status>
      <ly-status type="warning" v-else>取消预约</ly-status>
    </template>

    <template #inviter_nickname_slot="{ row }">
      <ly-tag :content="row.invitor_nickname" only>
        <i class="iconfont icon-superior" slot="left"></i>
      </ly-tag>
    </template>
  </ly-table>
</template>
<script>
import { user } from '@/pages/live/lottery/http.js'
import lyTable from '@/components/mixins/ly-table.js'
import lyTag from '@/components/lyTag'
export default {
  mixins: [lyTable],
  props: ['channelOps', 'type', 'activity_id', 'activities'],
  components: {
    lyTag
  },
  data () {
    return {
      COMM_HTTP: user,
      exportLoading: false,
      taskProgress: [{
        value: 0,
        label: '全部'
      }, {
        value: '1',
        label: '完成一阶任务'
      }, {
        value: '2',
        label: '完成二阶任务'
      }, {
        value: '3',
        label: '完成三阶任务'
      }],
      actTypes: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '未预约',
          value: 1
        },
        {
          label: '预约成功',
          value: 2
        },
        {
          label: '取消预约',
          value: 3
        }
      ],
      typeProgress: [{
        value: 0,
        label: '全部'
      }, {
        value: '1',
        label: '新用户'
      }, {
        value: '2',
        label: '老用户'
      }],
      statusOption: [{
        value: -1,
        label: '全部'
      }, {
        value: 0,
        label: '已添加'
      }, {
        value: 1,
        label: '已删除'
      }]
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        scrollEl: '.page-warp-bg',
        operation: [
          {
            label: '详情',
            handler (row) {
              _this.$emit('detail', row)
            }
          }
        ],
        operationWidth: 80,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname',
            key: 'nickname',
            slot: 'title_slot',
            search: true,
            fixed: true,
            sort: 1,
            width: 200,
            placeholder: '请输入用户昵称'
          },
          {
            label: '参与时间',
            prop: 'join_date',
            search: true,
            type: 'daterange',
            key: ['start', 'end'],
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            columnProps: {
              'min-width': 145
            },
            formatter ({ join_date }) {
              return _this.util.timeTofmt(join_date, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '预约状态',
            prop: 'status',
            slot: 'status_slot',
            type: 'select',
            source: this.actTypes,
            search: true,
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '邀请用户数',
            prop: 'account_num',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '邀请预约数',
            prop: 'reserve_num',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '直接上级',
            prop: 'inviter_nickname',
            slot: 'inviter_nickname_slot',
            columnProps: {
              'min-width': 140
            }
          },
          {
            label: '来源渠道',
            prop: 'channel_name',
            search: true,
            key: 'channel_id',
            type: 'select',
            default: null,
            source: this.channelOps,
            columnProps: {
              'min-width': 120
            }
          }
        ]
      }
    }
  },
  methods: {
    async queryData () {
      this.$refs.lyTable.$refs.elTable.clearSelection()
      this.$store.dispatch('ADDLOADING')
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: this.page,
        per_page: this.per_page,
        ...this.params,
        ...this.sort
      })
      this.$store.dispatch('REMOVELOADING')
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.tableData = res.list || res.data || res[this.DATA_LOCATION] || []
      this.total = res.total
      this.listData = res
      return res
    },
    formatterParams (params) {
      return {
        ...params,
        add_account_id: params.add_account_id,
        book_lottery_id: this.activity_id
      }
    },

    // 导出数据
    async fn_export () {
      this.exportLoading = true
      const params = this.util.extend(true, {}, this.params)
      params.is_black_list = this.type === '1' ? 0 : 1
      const data = await this.COMM_HTTP.export(params)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.exportLoading = false
        return false
      }
      this.handleDownload(data.url)
      this.exportLoading = false
    },

    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },

    getFileName () {
      let _name = ''
      let actName = ''
      actName = this.getActName()
      _name = `[${actName}]-参与用户${this.type === '2' ? '-黑名单' : ''}-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },

    getActName () {
      const _obj = this.activities.find(n => {
        return n.value === this.activity_id
      })
      return _obj.label
    },

    async handleAddBlack () {
      this.$lyConfirm({
        text: '加入黑名单后该用户禁止参与平台其它活动，确定加入黑名单吗？',
        title: '提示',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const account_ids = this.selected.map(e => {
          return e.id
        }).join(',')
        const data = await this.COMM_HTTP.addBlack({ account_ids })

        if ((data && data.code) || (data && data.code === 0)) {
          this.$message.error(data.msg)
          return false
        }

        this.$message.success('加入黑名单成功！')
        this.queryData()
      }).catch(() => { })
    },
    async handleRemoveBlack () {
      const account_ids = this.selected.map(e => {
        return e.id
      }).join(',')
      const data = await this.COMM_HTTP.removeBlack({ account_ids })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }
      this.$message.success('移除黑名单成功！')
      this.queryData()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-superior {
  font-size: 13px;
  color: #2b7cff;
  margin-right: 4px;
}
</style>
