var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width,
        top: _vm.top,
        visible: _vm.visible,
        "close-on-click-modal": false,
        "before-close": _vm.fn_cancle,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: _vm.fn_closed
      }
    },
    [
      _vm.type
        ? _c("ed-" + _vm.type, {
            ref: _vm.type + "Ref",
            tag: "div",
            staticClass: "edit-form",
            attrs: {
              hasChange: _vm.hasChange,
              groupList: _vm.groupList,
              currentGroupId: _vm.currentGroupId
            },
            on: {
              "update:hasChange": function($event) {
                _vm.hasChange = $event
              },
              "update:has-change": function($event) {
                _vm.hasChange = $event
              },
              ready: _vm.fn_ready,
              setting: _vm.fn_setting
            }
          })
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", disabled: _vm.loading || _vm.btnLoading },
              on: {
                click: function($event) {
                  return _vm.fn_cancle(null)
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: _vm.loading,
                loading: _vm.btnLoading
              },
              on: { click: _vm.fn_submit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }