/*
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-03-23 17:54:11
 * @LastEditTime: 2021-12-15 18:23:49
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

export const cloudList = {
  reqQuery: (params) => axios.get(`/wecom/robot/list?${stringify(params)}`)
}

export const recordsApi = {
  reqQuery: (params) => axios.get(`/wecom/robot/login/history?${stringify(params)}`),
  getOption: (params) => axios.get(`/wecom/api/test?${stringify(params)}`)
}
