var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout ly-shadow" }, [
    _c(
      "div",
      { staticClass: "layout-left" },
      [
        _c("groups", {
          attrs: { data: _vm.groupData, current: _vm.currentGroupId },
          on: { change: _vm.fn_changeGroup, updataGroups: _vm.fn_getData }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "layout-right" },
      [
        _c("type-tab", {
          ref: "typeTabRef",
          attrs: { data: _vm.typeList, currentGroupId: _vm.currentGroupId }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }