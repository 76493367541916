<!--
 * @Author: zhanln
 * @Date: 2022-03-21 15:46:50
 * @LastEditTime: 2022-05-07 16:25:16
 * @LastEditors: zhanln
 * @Description: 小程序
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="130px"
    size="medium"
    class="edit-form"
    v-loading="loading"
  >
    <!--  素材名称 -->
    <el-form-item label="素材名称：" prop="name" key="name">
      <el-input
        class="edit-form__input"
        v-model.trim="form.name"
        maxlength="20"
        show-word-limit
        placeholder="请输入素材名称"
      ></el-input>
    </el-form-item>

    <!--  分组 -->
    <el-form-item>
      <template slot="label"><span class="q-danger">*</span> 分组：</template>
      <el-select v-model="form.group_id" class="edit-form__input">
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <!--  素材获取方式 -->
    <!-- <el-form-item>
      <template slot="label"
        ><span class="q-danger">*</span> 素材获取方式：</template
      >
      <el-radio-group v-model="form.obtain_method">
        <el-radio :label="1">本地手动添加</el-radio>
        <el-radio :label="2">云端素材</el-radio>
      </el-radio-group>

      <div class="q-bg is-primary">
        <i class="iconfont icon-info-fill q-primary"></i>
        {{
          form.obtain_method === 1
            ? "请添加在企业微信后台绑定的小程序appid和路径，否则会导致发送失败。"
            : "将需要保存为素材的小程序发送给云端账号后，在聊天记录中选择该小程序保存为素材。"
        }}
      </div>
    </el-form-item> -->

    <template v-if="form.obtain_method === 1">
      <!--  小程序名称 -->
      <el-form-item label="小程序名称：" prop="app_name" key="app_name">
        <el-input
          class="edit-form__input"
          v-model.trim="form.app_name"
          maxlength="15"
          show-word-limit
          placeholder="请输入小程序名称"
        ></el-input>
      </el-form-item>

      <!--  小程序标题 -->
      <el-form-item label="小程序标题：" prop="title" key="title">
        <el-input
          class="edit-form__input"
          v-model.trim="form.title"
          maxlength="32"
          show-word-limit
          placeholder="请输入小程序标题"
        ></el-input>
      </el-form-item>

      <!--  小程序appid -->
      <el-form-item
        label="小程序appid："
        prop="app_id"
        key="app_id"
        class="edit-form__item"
      >
        <el-input
          class="edit-form__input"
          v-model.trim="form.app_id"
          maxlength="32"
          show-word-limit
          placeholder="请输入小程序appid"
        ></el-input>
        <div
          class="edit-form__tip q-primary text-small"
          style="text-align: right; width: 320px"
        >
          <span style="cursor: pointer" @click="fn_jumpToLink(1)">
            如何获取小程序appid？</span
          >
        </div>
      </el-form-item>

      <!--  小程序路径 -->
      <el-form-item
        label="小程序路径："
        prop="path"
        key="path"
        class="edit-form__item"
      >
        <el-input
          class="edit-form__input"
          v-model.trim="form.path"
          maxlength="250"
          show-word-limit
          placeholder="请输入小程序路径"
        ></el-input>
        <div
          class="edit-form__tip q-primary text-small"
          style="text-align: right; width: 320px"
        >
          <span style="cursor: pointer" @click="fn_jumpToLink(2)">
            如何获取小程序路径？</span
          >
        </div>
      </el-form-item>

      <!--  封面图 -->
      <el-form-item label="封面图：" prop="cover_image" key="cover_image">
        <div class="q-info text-small">
          支持JPG、PNG格式，图片小于2M，尺寸建议500*376px
        </div>
        <ly-upload
          :img="form.cover_image"
          tipIcon="el-icon-plus"
          @getImg="fn_getCover"
        ></ly-upload>
      </el-form-item>
    </template>

    <template v-else>
      <!--  素材内容 -->
      <el-form-item label="素材内容：" prop="msg_content" key="msg_content">
        <div class="edit-applet">
          <div
            class="edit-applet__upload"
            @click="$refs.selectRecord.fn_open(2013)"
            v-if="!form.msg_content"
          >
            <div class="edit-applet__upload--icon">
              <i class="el-icon-plus"></i>
            </div>
            <div class="edit-applet__upload--tip">从聊天记录中选择小程序</div>
          </div>
          <div class="edit-applet__info" ref="applet" v-else>
            <app-poster :applet="form" :cover="form.cover_image"></app-poster>
            <div class="edit-applet__remove" @click.stop="fn_handleRemove">
              <i class="iconfont icon-delete"></i>
            </div>
          </div>
        </div>
        <select-record ref="selectRecord" @chose="handleChoseApp" />
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import { Base64 } from 'js-base64'
import AppPoster from '@/components/AppPoster.vue'
import SelectRecord from './selectRecord.vue'
export default {
  name: 'edApplet',

  props: {
    hasChange: {
      type: Boolean,
      defualt: false
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentGroupId: {
      type: String,
      default: '0'
    }
  },

  components: {
    AppPoster,
    SelectRecord
  },

  data () {
    return {
      loading: true,
      form: {
        type: 5,
        name: '',
        group_id: '0',
        obtain_method: 2,
        app_name: '',
        title: '',
        app_id: '',
        path: '',
        cover_image: '',
        msg_content: ''
      },
      rules: {
        name: [
          { required: true, message: '请填写素材名称', trigger: 'change' }
        ],
        app_name: [
          { required: true, message: '请填写小程序名称', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请填写小程序标题', trigger: 'change' }
        ],
        app_id: [
          { required: true, message: '请填写小程序appid', trigger: 'change' }
        ],
        path: [
          { required: true, message: '请填写小程序路径', trigger: 'change' }
        ],
        msg_content: [
          { required: true, message: '请选择小程序', trigger: 'change' }
        ],
        cover_image: [
          { required: true, message: '请上传封面图', trigger: 'change' }
        ]
      },
      isEdit: false,
      editReady: false
    }
  },

  watch: {
    loading (val) {
      if (!val) {
        this.$emit('ready')
      }
    },
    'form.obtain_method' (val) {
      this.$refs.formRef.clearValidate()
      if (val === 1) {
        this.$emit('setting', { top: '5vh' })
      } else {
        this.$emit('setting', { top: '15vh' })
      }
    },
    form: {
      handler (val) {
        if ((this.isEdit && this.editReady) || (!this.isEdit && !this.editReady)) {
          this.$emit('update:hasChange', true)
        }
        if (this.isEdit) {
          this.editReady = true
        }
      },
      deep: true
    }
  },

  methods: {
    // 初始化数据
    fn_init (form) {
      if (!form) {
        this.form.group_id = this.currentGroupId === '-1' ? '0' : this.currentGroupId
        this.loading = false
        return
      }

      const detail = this.util.extend(true, {}, form)

      detail.obtain_method = detail.content.obtain_method
      detail.app_name = detail.content.app_name
      detail.title = detail.content.title
      detail.app_id = detail.content.app_id
      detail.path = detail.content.path
      detail.cover_image = detail.content.cover_image
      detail.msg_content = detail.content.content

      this.form = detail

      this.loading = false
      this.isEdit = true
    },

    // 图片回调
    fn_getCover (cover_image) {
      this.form.cover_image = cover_image
      this.$refs.formRef.validateField('cover_image')
    },

    // 选择小程序回调
    handleChoseApp (app) {
      this.form.cover_image = app.cover_image
      this.form.msg_content = Base64.encode(JSON.stringify(app))
      this.$refs.formRef.validateField('msg_content')
    },

    // 移除小程序
    fn_handleRemove () {
      this.form.msg_content = null
      this.$refs.formRef.validateField('msg_content')
    },

    // 指引文档
    fn_jumpToLink (type) {
      if (type === 1) {
        window.open('https://www.yuque.com/docs/share/dbd084fc-33e9-45a7-ac66-6710855e2745?#', '_blank')
      } else {
        window.open('https://www.yuque.com/docs/share/32544cd6-6726-4101-8c57-bd08fe9f71c4?#', '_blank')
      }
    },

    // 表单校验，通过则返回 form
    fn_verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)
      form.content = {
        obtain_method: form.obtain_method,
        app_name: form.obtain_method === 1 ? form.app_name : '',
        title: form.obtain_method === 1 ? form.title : '',
        app_id: form.obtain_method === 1 ? form.app_id : '',
        path: form.obtain_method === 1 ? form.path : '',
        cover_image: form.cover_image,
        content: form.msg_content
      }

      delete form.obtain_method
      delete form.app_name
      delete form.title
      delete form.app_id
      delete form.path
      delete form.cover_image
      delete form.msg_content

      return form
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.q-bg {
  padding: 12px;
  width: 444px;
  box-sizing: border-box;
}

.edit-form__item {
  margin-bottom: 48px;
}

.edit-form__tip {
  position: absolute;
}

.edit-applet {
  &__upload {
    width: 146px;
    height: 128px;
    border: 1px dashed #c0ccda;
    background-color: #f7f8fc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.6;
    border-radius: 6px;
    padding: 22px;
    box-sizing: border-box;

    &:hover {
      border-color: $--color-primary;
      cursor: pointer;
    }

    &--icon {
      font-size: 24px;
    }

    &--tip {
      text-align: center;
    }
  }

  &__info {
    width: 246px;
    position: relative;
  }

  &__remove {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border-radius: 0 8px 0 8px;
    background-color: $--color-danger;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    i {
      font-size: 14px;
    }
  }
}
</style>
