var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("alys-card", { attrs: { title: _vm.title } }, [
    !_vm.actId
      ? _c(
          "div",
          {
            staticClass: "ly-table-empty flex flex-center",
            staticStyle: { height: "336px", "box-sizing": "border-box" }
          },
          [
            _c("img", {
              attrs: {
                src: require("@assets/svg/default/no_data.svg"),
                alt: ""
              }
            }),
            _vm._v(" 暂无数据 ")
          ]
        )
      : _c("div", { staticClass: "alys-channel" }, [
          _c("div", { staticClass: "alys-channel__row" }, [
            _c(
              "div",
              { staticClass: "alys-channel__item" },
              [
                _c("div", { staticClass: "alys-channel__header" }, [
                  _c("div", { staticClass: "alys-channel__title" }, [
                    _c("i", { staticClass: "badge" }),
                    _vm._v("渠道入群用户 ")
                  ])
                ]),
                _c("c-pie", {
                  ref: "pieRef",
                  attrs: { option: _vm.pieOption, colorLoop: true }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "alys-channel__item" },
              [
                _c("div", { staticClass: "alys-channel__header" }, [
                  _c("div", { staticClass: "alys-channel__title" }, [
                    _c("i", { staticClass: "badge" }),
                    _vm._v("渠道留存用户 ")
                  ])
                ]),
                _c("c-bar", {
                  ref: "barRef",
                  attrs: { option: _vm.stayOptions, colorLoop: true }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "alys-channel__item" },
            [
              _c(
                "div",
                { staticClass: "alys-channel__header mb-16" },
                [
                  _c("div", { staticClass: "alys-channel__title" }, [
                    _c("i", { staticClass: "badge" }),
                    _vm._v("渠道详情数据 ")
                  ]),
                  _vm.tableData.length > 0
                    ? _c("ly-export", {
                        attrs: {
                          exportData: _vm.exportData,
                          exportName: _vm.exportName,
                          exportSheetName: _vm.exportSheetName,
                          dateFormat: "YYYY年MM月DD日HH时mm分"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, "max-height": "500" }
                },
                [
                  _vm._l(_vm.tableHead, function(item, index) {
                    return _c(
                      "el-table-column",
                      {
                        key: index,
                        attrs: {
                          prop: item.prop,
                          "min-width": item.width || "",
                          fixed: item.fixed
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _vm._v(" " + _vm._s(item.label) + " "),
                            item.tip
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: item.tip,
                                      "popper-class": "q-tooltip"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-info-fill"
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      2
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "ly-table-empty",
                      attrs: { slot: "empty" },
                      slot: "empty"
                    },
                    [
                      _vm._t("empty", [
                        _c("img", {
                          attrs: {
                            src: require("@assets/svg/default/no_data.svg"),
                            alt: ""
                          }
                        }),
                        _vm._v(" 暂无数据 ")
                      ])
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }