<!--
 * @Author: zhan
 * @Date: 2022-05-18 17:14:53
 * @LastEditTime: 2022-08-26 16:51:26
 * @LastEditors: zhan
-->
<template>

  <div class="page-warp">
    <div class="select-box" style="padding: 0 20px; margin-bottom: 20px;">
      <label style="color: #212b36">选择活动：</label>
      <el-select v-model="activity_id" filterable style="width: 320px" @change="changeActivity">
        <el-option v-for="item in activityList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>

    <ly-table class="act-list" ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :pageSize="per_page"
      :total="total" @searchChange="searchChange" @sizeChange="handleSizeChange" @sortChange="handleSortChange"
      @currentChange="handleCurrentChange" @selectionChange="handleSelectionChange">
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button round type="primary" icon="iconfont icon-download" size="medium" :loading="exportLoading"
          v-lydebounce="['button', fn_export]">导出数据
        </el-button>
      </template>

      <!-- 列插槽 -->
      <template #title_slot="{ row }">
        <div class="avatar-name">
          <el-avatar class="avatar" :size="32" :src="row.avatar"></el-avatar>
          <span class="name">{{ row.nickname }}</span>
        </div>
      </template>

      <template #status_slot="{ row }">
        <ly-status type="success" v-if="row.status === 1">预约成功</ly-status>
        <ly-status type="warning" v-else>取消预约</ly-status>
      </template>

    </ly-table>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { reserve, activity } from './http'
export default {
  mixins: [lyTable],

  data () {
    return {
      activity_id: null,
      activityList: [],
      COMM_HTTP: reserve,
      actTypes: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '预约成功',
          value: 1
        },
        {
          label: '取消预约',
          value: 2
        }
      ],
      exportLoading: false,
      activityId: null,
      activity_name: null
    }
  },

  computed: {
    tableOpt () {
      return {
        operation: 'hide',
        column: [
          {
            label: '用户昵称',
            prop: 'nickname',
            slot: 'title_slot',
            search: true,
            sort: 1,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '预约状态',
            prop: 'status',
            slot: 'status_slot',
            type: 'select',
            source: this.actTypes,
            search: true,
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '预约时间',
            prop: 'created_date',
            search: true,
            key: ['start', 'end'],
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            },
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '视频号名称',
            prop: 'title',
            align: 'center',
            search: true,
            sort: 2,
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '直播时间',
            prop: 'live_date',
            type: 'daterange',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            },
            columnProps: {
              'min-width': 100
            }
          }
        ]
      }
    }
  },

  mounted () {
    this.activity_id = this.$route.query.id * 1
    console.log(' this.activity_id', this.activity_id)
    this.actList()
    this.getId()
  },

  methods: {
    formatterParams (params) {
      return {
        ...params,
        polite_id: this.activityId
      }
    },

    // 获取活动列表
    async actList () {
      const data = await activity.reqList()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.reload = false
        return
      }

      if (data) {
        this.activityList = data.map((i, v) => {
          return {
            value: i.id,
            label: i.title
          }
        })
      } else {
        this.activityList = []
      }
      return true
    },

    // 切换活动
    changeActivity (val) {
      this.page = 1
      this.activityId = val
      this.queryData()
      this.$router.replace({
        query: {
          id: val
        }
      })
    },

    // 当前活动id
    getId () {
      const _id = this.$route.query.id
      if (_id) {
        this.activityId = +_id
      } else {
        this.activityId = this.activityList[0] ? this.activityList[0].value : null
      }

      for (let i = 0; i < this.activityList.length; i++) {
        if (+_id === +this.activityList[i].value) {
          this.activityValue = this.activityList[i].label
        }
      }
    },

    // 导出
    async fn_export () {
      this.exportLoading = true
      const data = await this.COMM_HTTP.export({
        polite_id: this.activityId,
        ...this.params
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.exportLoading = false
        this.fn_lyMsg('error', data.msg)
        return false
      }

      this.handleDownload(data.url)
      this.exportLoading = false
    },

    // 导出
    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },

    getActName () {
      const _obj = this.activityList.find(n => {
        return n.value === this.activity_id
      })
      return _obj.label
    },
    // 获取Excel名称
    getFileName () {
      let _name = ''
      let actName = ''
      if (!this.activity_name) {
        actName = this.getActName()
      } else {
        actName = this.activity_name
      }
      _name = `[${actName}]-预约记录-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    }
  }
}
</script>
