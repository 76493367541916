var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      staticClass: "reward"
    },
    [
      _c(
        "div",
        { staticClass: "reward-tabs" },
        _vm._l(_vm.prizeSteps, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "reward-tabs__item",
              class: [
                index === _vm.activeStep ? "is-active" : "",
                item.itemValid === 1 ? "is-error" : ""
              ],
              on: {
                click: function($event) {
                  return _vm.fn_changeTab(index)
                }
              }
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        }),
        0
      ),
      _c("reward-item", {
        ref: "rewardItemRef",
        on: { getForm: _vm.fn_getForm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }