<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-17 16:23:32
 * @LastEditTime: 2022-01-05 10:54:57
-->
<template>
  <div class="popularize page-warp group-detail">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      @searchChange="searchChange"
    >
      <template #handler>
        <el-button round size="medium" icon="iconfont icon-download" type="primary" @click="fn_exportExcel"
          >导出数据</el-button
        >
      </template>
    </ly-table>
    <div class="temp-container">
      <el-table
        ref="tableDataRef"
        :data="tableData.slice((page - 1) * per_page, page * per_page)"
        style="width: 100%"
      >
        <template v-for="(item, index) in tableOpt.column">
          <el-table-column
            v-if="!item.hide"
            :prop="item.topic_names"
            :label="item.label"
            :key="index"
            :width="item.width"
            :fixed="item.fixed"
            :align="index > 4 ? 'center': 'left'"
            v-bind="item.columnProps"
          >
            <template slot-scope="scope">
              <span v-if="item.prop === 'topic_names'">{{
                scope.row[item.prop].join(",")
              }}</span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>
      <div class="table-pagination-area">
        <el-pagination
          background
          class="fixedPagination"
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="per_page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { dataDetail, groupData } from './http.js'
import XLSX from 'xlsx'
export default {
  mixins: [lyTable],
  data () {
    return {
      searchValue: '',
      COMM_HTTP: dataDetail,
      dynamicColumn: [],
      titleList: [],
      dataFields: {},
      excelName: '',
      tableHeight: 200,
      exportTitle: null
    }
  },
  computed: {
    excelData () {
      return this.tableData
    },
    tableOpt () {
      return {
        layout: 'hide',
        operation: 'hide',
        column: [
          {
            label: '选择日期',
            key: ['day_start_time', 'day_end_time'],
            search: true,
            hide: true,
            placeholder: '请输入账号名称',
            type: 'daterange',
            default: [new Date(new Date().getTime() - 6 * 8.64e7), new Date()],
            formItemProps: {
              clearable: false
            },
            pickerOptions: {
              disabledDate: (time) => {
                if (this.timeOptionRange) {
                  const day1 = 29 * 8.64e7
                  const maxTime = this.timeOptionRange + day1
                  const minTime = this.timeOptionRange - day1
                  return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
                } else {
                  return time.getTime() > Date.now()
                }
              },
              onPick: (time) => {
                if (time.minDate && !time.maxDate) {
                  this.timeOptionRange = new Date(time.minDate).getTime()
                }
                if (time.maxDate) {
                  this.timeOptionRange = null
                }
              }
            }
          },
          {
            label: '选择时段',
            prop: 'hour',
            key: 'hour',
            search: true,
            hide: true,
            placeholder: '全部分组',
            type: 'select',
            source: this.getHourList,
            sourceField: {
              value: 'id',
              label: 'name'
            },
            default: -1,
            clearable: 0
          },
          {
            label: '群分组',
            prop: 'topic_names',
            key: 'group_topic_id',
            search: true,
            type: 'select',
            placeholder: '全部群组',
            source: this.getTopic,
            sourceField: {
              value: 'id',
              label: 'name'
            },
            clearable: 0,
            default: -1,
            columnProps: {
              'min-width': 120
            },
            formatter (row, column, cellValue, index) {
              return cellValue.join(',')
            }
          },
          {
            label: '群主',
            prop: 'owner_name',
            key: 'owner_serial_no',
            placeholder: '全部',
            search: true,
            type: 'select',
            fixed: true,
            source: this.getOwner,
            sourceField: {
              value: 'member_serial_no',
              label: 'name'
            },
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '群名称',
            prop: 'group_name',
            key: 'group_name',
            search: true,
            fixed: true,
            placeholder: '请输入群名称',
            columnProps: {
              'min-width': 120
            }
          },
          ...this.dynamicColumn
        ]
      }
    }
  },
  watch: {
    tableOpt () {
      this.$refs.tableDataRef.doLayout()
    }
  },
  mounted () {
    this.setGoBackText()
  },
  methods: {
    // 导出
    fn_exportExcel () {
      const _data = []
      for (let i = 0; i < this.tableData.length; i++) {
        const values = []
        for (const key in this.tableData[i]) {
          if (key !== 'group_id') {
            if (Array.isArray(this.tableData[i][key])) {
              values.push(this.tableData[i][key].join(','))
            } else {
              values.push(this.tableData[i][key])
            }
          }
        }
        _data.push(values)
      }
      const aoa2 = [this.exportTitle, ..._data]
      const worksheet = XLSX.utils.aoa_to_sheet(aoa2)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, this.excelName)
      XLSX.writeFile(workbook, `${this.excelName}.xlsx`)
    },
    // 设置时间
    // 设置时间
    fn_setTime (time) {
      const _time = new Date(time * 1000)
      _time.setHours(0)
      _time.setMinutes(0)
      _time.setSeconds(0)
      return parseInt(_time.getTime() / 1000)
    },
    setGoBackText () {
      this.$store.commit('_SET', {
        'global.cusTitle': ` - ${this.$route.query.name}`
      })
      this.excelName = `${this.$route.meta.title} - ${this.$route.query.name}`
    },
    // 选择时段数据
    async getHourList () {
      const list = [
        {
          id: -1,
          name: '00:00  ~  24:00'
        }
      ]

      for (let i = 1; i < 25; i++) {
        const prev = (i - 1) < 10 ? '0' + (i - 1) : (i - 1)
        const next = i < 10 ? '0' + i : i
        list.push({
          id: i - 1,
          name: `${prev}:00  ~  ${next}:00`
        })
      }
      return list
    },
    // 群分组数据
    async getTopic () {
      const res = await groupData.getTopic()

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      return res
    },
    // 群主
    async getOwner () {
      const res = await groupData.getOwner({
        group_topic_id: -1
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      return res
    },

    formatterParams (params) {
      params.day_start_time = this.fn_setTime(params.day_start_time)
      params.day_end_time = this.fn_setTime(params.day_end_time)
      return {
        ...params,
        type: +this.$route.query.type
      }
    },

    async queryData () {
      this.loading = true
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: this.page,
        per_page: this.per_page,
        ...this.sort,
        ...this.params
      })
      this.loading = false
      this.dynamicColumn = []

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      const data = this.fn_initData(res)
      this.$nextTick(() => {
        this.tableData = data
        this.$refs.lyTable.doLayout()
        this.$refs.tableDataRef.doLayout()
      })
    },

    // 处理数据
    fn_initData (list) {
      this.exportTitle = ['群分组', '群主', '群名称']
      const data = []
      const titles = []
      const dayList = []
      const _type = +this.$route.query.type
      this.dataFields = {
        群分组: 'topic_names',
        群主: 'owner_name',
        群名称: 'group_name'
      }
      for (const item in list) {
        // 设置表头
        this.dynamicColumn.push({
          label: this.$day(list[item].day).format('M/D'),
          prop: list[item].day
        })

        titles.push(list[item].group_items)
        dayList.push(list[item].day)
      }
      const groups = this.fn_setTitle(titles, dayList)

      for (let i = 0; i < groups.length; i++) {
        data.push(groups[i])
      }

      for (let i = 0; i < list.length; i++) {
        const day = list[i].day
        const item = list[i].group_items
        if (item) {
          for (let j = 0; j < item.length; j++) {
            const _id = item[j].group_id
            for (let k = 0; k < data.length; k++) {
              if (data[k].group_id === _id) {
                if (_type === 6 || _type === 8 || _type === 11) {
                  data[k][day] = item[j].float_num + '%'
                } else {
                  data[k][day] = item[j].num
                }
              }
            }
          }
        }
      }
      for (let i = 0; i < dayList.length; i++) {
        this.exportTitle.push(this.$day(dayList[i]).format('M/D'))
        this.dataFields[dayList[i]] = dayList[i]
      }

      this.total = data.length

      return data
    },

    fn_setTitle (data, day) {
      const datas = []
      const items = data.filter(n => n).flat()
      const ids = []
      for (let i = 0; i < items.length; i++) {
        const gId = items[i].group_id
        if (!ids.includes(gId)) {
          ids.push(items[i].group_id)
        }
      }

      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (items[i].group_id === ids[j]) {
            datas.push({
              group_id: items[i].group_id,
              topic_names: items[i].topic_names || ['未分组'],
              owner_name: items[i].owner_name,
              group_name: items[i].group_name || '群聊'
            })
            ids.splice(j, 1)
          }
        }
      }

      for (let i = 0; i < datas.length; i++) {
        for (let j = 0; j < day.length; j++) {
          datas[i][day[j]] = 0
        }
      }
      return datas
    },
    handleSizeChange2 (val) {
      this.page = 1
      this.per_page = val
    },
    handleCurrentChange2 (val) {
      this.page = val
    }
  },

  beforeRouteLeave (to, from, next) {
    this.$store.commit('_SET', {
      'global.cusTitle': ''
    })
    next()
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table__row td {
    &:nth-of-type(1) {
      .cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.group-detail {
  ::v-deep {
    .el-form-item:last-child {
      .el-form-item__label {
        display: none;
      }
    }
    .ly-table-main{
      box-shadow: unset;
      border-radius: 16px 16px 0 0;
      margin-bottom: 0;
    }
  }
}
.temp-container{
  padding: 0 20px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  flex: 1;
  margin-bottom: 80px;
  box-shadow: 0px 16px 32px -4px rgb(145 158 171 / 24%);
}
.xl-ly-table {
  flex: unset;
  ::v-deep .el-table {
    display: none;
  }
}

.table-pagination-area {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s;
  .el-pagination {
    width: 100%;
    height: 61px;
    text-align: right;
    right: 0;
    box-sizing: border-box;
    padding: 16px 20px 0 20px;
  }
}
.fixedPagination {
  width: calc(100% - 260px)!important;
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 99;
  // transition: all 0.2s;
}
</style>
