<!--
 * @Author: zhanln
 * @Date: 2021-11-10 16:23:21
 * @LastEditTime: 2022-08-29 14:20:01
 * @LastEditors: zhan
 * @Description: 预览 - 任务设置
-->

<template>
  <ly-mobile :showWel="false" :hasFooter="false" sub="" title="视频号直播">

    <div class="reward">

      <div class="reward-title">
        <span v-if="cover_title.length > 7">{{ cover_title.slice(0, -7) }}</span>
        <span>{{ cover_title.slice(-7) }}</span>
      </div>

      <div class="reward-countdown">
        活动倒计时:
        <div class="reward-countdown__count">
          <span class="number">{{ countDown.d }}</span><span class="text">天</span>
          <span class="number">{{ countDown.h }}</span><span class="text">:</span>
          <span class="number">{{ countDown.m }}</span><span class="text">:</span>
          <span class="number">{{ countDown.s }}</span>
        </div>
      </div>

      <home-prize></home-prize>

      <div class="reward-btn">
        {{ btn_title }}
      </div>
      <div class="reward-btn__sub text-small">· 邀请好友完成直播预约，即可领取奖品 ·</div>

      <home-invite></home-invite>

      <home-rank></home-rank>

      <div class="reward-rule fx-bg">规则</div>
      <div class="reward-barrage">
        <img src="https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg" alt=""
          class="reward-barrage__img">
        a呆呆少侠a 正在预约直播
      </div>
    </div>

    <!-- 奖品预览框 -->
    <reward-detail></reward-detail>

    <!-- 活动规则 -->
    <reward-rule ref="rewardRuleRef" :tabKey="tabKey"></reward-rule>
  </ly-mobile>
</template>

<script>
import homePrize from './homePrize'
import homeInvite from './homeInvite'
import homeRank from './homeRank'
import rewardDetail from './rewardDetail'
import rewardRule from './rewardRule'
import { mapState } from 'vuex'
export default {
  name: 'taskMobileReward',

  props: {
    tabKey: {
      type: String,
      default: 'reward'
    }
  },

  data () {
    return {
      countDown: {
        d: '-',
        h: '-',
        m: '-',
        s: '-'
      }
      // current: 0,
      // inviterCount: 0,
      // steps: [
      //   {
      //     title: '一阶任务',
      //     status: 1,
      //     unreal: 1000
      //   },
      //   {
      //     title: '二阶任务',
      //     status: 0,
      //     unreal: 1000
      //   },
      //   {
      //     title: '三阶任务',
      //     status: 0,
      //     unreal: 1000
      //   }
      // ],
      // defaultCover: require('@/assets/images/task_gift.png'),
      // avatarList: [
      //   'https://image.01lb.com.cn//uploads/wecom/21/1012/16340233797B2wfJUM.png',
      //   'https://image.01lb.com.cn//uploads/wecom/21/1012/16340233630761Wn3q.png',
      //   'https://image.01lb.com.cn//uploads/wecom/21/1012/1634023372q055Y21g.png',
      //   'https://image.01lb.com.cn//uploads/wecom/21/1012/16340233550G48EwXE.png'
      // ]
    }
  },

  computed: {
    ...mapState({
      cover_title: state => state.reserveTask.cover_title,
      end_time: state => state.reserveTask.end_time,
      btn_title: state => state.reserveTask.btn_title
    })
  },

  watch: {
    end_time (val) {
      this.fn_getCountDown(val)
      console.log('end_time', val)
    }
  },

  mounted () {
    this.fn_getCountDown(this.$store.state.reserveTask.end_time)
    // this.fn_init()
  },

  methods: {
    // 计算倒计时
    fn_getCountDown (end_time) {
      const date = new Date()
      const now = date.getTime()
      const endDate = new Date(end_time)
      const end = endDate.getTime()
      const leftTime = end - now
      if (leftTime >= 0) {
        this.countDown = {
          d: Math.floor(leftTime / 1000 / 60 / 60 / 24),
          h: Math.floor(leftTime / 1000 / 60 / 60 % 24),
          m: Math.floor(leftTime / 1000 / 60 % 60),
          s: Math.floor(leftTime / 1000 % 60)
        }
      } else {
        this.countDown = {
          d: '-',
          h: '-',
          m: '-',
          s: '-'
        }
      }
    }
    // fn_init () {
    //   const step = this.current_step
    //   const status = this[`status_${step}`] === 1
    //   if (status) {
    //     this.current = step
    //   }

    //   for (let i = 0; i < this.steps.length; i++) {
    //     this.steps[i].status = this[`status_${i}`]
    //   }
    // },

    // // 任务人数
    // fn_getTarget (step) {
    //   return this.$store.state.task[`target_count_${step}`] || '*'
    // },

    // // 获取奖品信息，从vuex
    // fn_getInfo (type) {
    //   return this.$store.getters['task/GET_REWARD_INFO']({
    //     step: this.current,
    //     type
    //   })
    // }
  },

  components: {
    homePrize,
    homeInvite,
    homeRank,
    rewardDetail,
    rewardRule
  }
}
</script>

<style lang="scss" scoped>
.fx-bg {
  width: 40px;
  height: 40px;
  background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0817/1660720790cDEW0pWA.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  line-height: 1;
}

.reward {
  position: relative;
  background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0811/1660184960YZ29p4M9.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  background-color: #FFE9D8;
  box-sizing: border-box;
  user-select: none;
  padding-bottom: 32px;

  &-title {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-shadow: 0px 0px 9px rgba(235, 76, 115, 0.4500);
    line-height: 1.4;
    padding-top: 12px;
    box-sizing: border-box;
  }

  &-countdown {
    display: flex;
    justify-content: center;
    color: #fff;
    margin-top: 12px;
    font-size: 12px;

    .number {
      display: inline-flex;
      width: 18px;
      height: 18px;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin: 0 2px;
      color: #E537A3;
      border-radius: 3px;
    }
  }

  &-prize {
    margin-top: 24px;
    height: 200px;
    background-color: rgba(0, 0, 0, .4);
  }

  &-btn {
    margin-top: 42px;
    height: 65px;
    background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0811/1660198190KP4YdoGn.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding-top: 2px;
    box-sizing: border-box;

    &__sub {
      text-align: center;
      color: #E537A3;
      transform: scale(0.935);
      margin-top: -4px;
    }
  }

  &-rule {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  &-invite {
    position: absolute;
    top: 74px;
    right: 10px;
    flex-direction: column;
  }

  &-barrage {
    position: absolute;
    top: 340px;
    left: 10px;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 11px;
    height: 22px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    padding: 0 8px 0 2px;
    line-height: 1;
    z-index: 1;

    &__img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
}
</style>
