var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-card" }, [
    _c("div", { staticClass: "edit-card__header" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.cardLoading,
            expression: "cardLoading"
          }
        ],
        staticClass: "edit-card__content"
      },
      [
        _c(
          "el-form",
          {
            ref: "otherFormRef",
            staticStyle: { width: "60%", "min-width": "560px" },
            attrs: {
              model: _vm.otherForm,
              rules: _vm.rules,
              "label-width": "125px",
              size: "medium"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "活动标题", prop: "show_title" } },
              [
                _c("el-input", {
                  attrs: { maxlength: "15", "show-word-limit": "" },
                  on: {
                    change: function($event) {
                      return _vm.fn_setForm($event, "show_title")
                    }
                  },
                  model: {
                    value: _vm.otherForm.show_title,
                    callback: function($$v) {
                      _vm.$set(_vm.otherForm, "show_title", $$v)
                    },
                    expression: "otherForm.show_title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "活动页首图",
                  required: "",
                  error: _vm.bannerError
                }
              },
              [
                _c(
                  "el-radio-group",
                  {
                    on: {
                      change: function($event) {
                        return _vm.fn_setForm($event, "banner_type")
                      }
                    },
                    model: {
                      value: _vm.otherForm.banner_type,
                      callback: function($$v) {
                        _vm.$set(_vm.otherForm, "banner_type", $$v)
                      },
                      expression: "otherForm.banner_type"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("默认")]),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自定义")])
                  ],
                  1
                ),
                _vm.otherForm.banner_type === 2
                  ? _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-top": "16px" }
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "uploadFile",
                            class: [
                              "upload",
                              _vm.otherForm.banner.length === 0
                                ? ""
                                : "upload-empty"
                            ],
                            attrs: {
                              action: "#",
                              accept: ".png, .jpg",
                              "http-request": _vm.requestQiniu,
                              "on-remove": _vm.removePoster,
                              "list-type": "picture-card",
                              limit: 1,
                              "file-list": _vm.otherForm.banner
                            }
                          },
                          [
                            _c("div", { staticClass: "default" }, [
                              _c("i", { staticClass: "iconfont icon-picture" }),
                              _c("span", [_vm._v("上传首图")])
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "q-info text-small",
                            staticStyle: {
                              "line-height": "22px",
                              "margin-left": "24px"
                            }
                          },
                          [
                            _vm._v(" 首图设计须知："),
                            _c("br"),
                            _vm._v(" (1) 建议尺寸：宽度750px，分辨率72 "),
                            _c("br"),
                            _vm._v(" (2) 支持JPG、PNG格式，图片小于2MB ")
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.otherForm.banner_type === 1
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "60%", "min-width": "560px" },
                    attrs: { label: "封面标题", prop: "banner_title" }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        resize: "none",
                        maxlength: "16",
                        "show-word-limit": ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.fn_setForm($event, "banner_title")
                        }
                      },
                      model: {
                        value: _vm.otherForm.banner_title,
                        callback: function($$v) {
                          _vm.$set(_vm.otherForm, "banner_title", $$v)
                        },
                        expression: "otherForm.banner_title"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.otherForm.banner_type === 2
              ? _c(
                  "el-form-item",
                  { attrs: { label: "活动页底色", prop: "bg_color" } },
                  [
                    _c(
                      "div",
                      { staticClass: "banner-color" },
                      [
                        _c("el-color-picker", {
                          attrs: { predefine: _vm.colorList },
                          on: {
                            change: function($event) {
                              return _vm.fn_setForm($event, "bg_color")
                            }
                          },
                          model: {
                            value: _vm.otherForm.bg_color,
                            callback: function($$v) {
                              _vm.$set(_vm.otherForm, "bg_color", $$v)
                            },
                            expression: "otherForm.bg_color"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "el-form-item",
              {
                attrs: { label: "活动规则", required: "", error: _vm.ruleError }
              },
              [
                _c("ly-editor", {
                  attrs: {
                    title: "活动规则",
                    id: "otherRule",
                    content: _vm.otherForm.rule,
                    inseMenu: ["用户昵称"],
                    recoverContent: _vm.ruleInit,
                    error: _vm.ruleError !== "",
                    maxLength: 2000
                  },
                  on: { getContent: _vm.fn_getRule }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "basic-form__item",
                attrs: { prop: "customer_service_user", error: _vm.idError }
              },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
                    _vm._v(" 主办方客服 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "light",
                          placement: "right",
                          "popper-class": "q-tooltip is-light"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "24px 24px 16px",
                                  width: "424px"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://image.01lb.com.cn//uploads/wecom/21/0809/1628501487xb97X7Wi.jpg",
                                    width: "424",
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c("i", { staticClass: "iconfont icon-info-fill" })
                      ]
                    )
                  ],
                  1
                ),
                _c("ly-emp-btn", {
                  attrs: {
                    btnText: "选择客服",
                    max: 100,
                    isReal: true,
                    datafilter: true,
                    checkList: _vm.otherForm.customer_service_user
                  },
                  on: { getEmpId: _vm.fn_getEmpId }
                })
              ],
              2
            ),
            _c(
              "el-form-item",
              {
                staticClass: "basic-form__item",
                attrs: {
                  required: "",
                  error: _vm.cusWordError,
                  label: "客服欢迎语"
                }
              },
              [
                _c("ly-editor", {
                  attrs: {
                    title: "客服欢迎语",
                    id: "basicCustomerWel",
                    content: _vm.otherForm.customer_wel_come_msg,
                    recoverContent: _vm.customer_initHtml,
                    error: _vm.cusWordError !== "",
                    emoji: "",
                    insetMenu: ["用户昵称"]
                  },
                  on: { getContent: _vm.fn_getCusWelWords }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }