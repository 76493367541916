var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-form", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.cardLoading,
        expression: "cardLoading"
      }
    ],
    ref: "lyFormRef",
    staticClass: "act-form",
    attrs: { formObj: _vm.formObj, form: _vm.formData, rules: _vm.rules },
    on: { setData: _vm.fn_setData },
    scopedSlots: _vm._u([
      {
        key: "share_cover_head",
        fn: function() {
          return [
            _c("div", { staticClass: "q-info text-small" }, [
              _vm._v("建议尺寸:500*400像素，大小不超过2MB，格式为JPG/PNG/JPEG")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }