<!--
 * @Descripttion: 云端列表页面
 * @version: 1.0.0
 * @Author: lw
 * @Date: 2021-03-16 16:32:41
 * @LastEditTime: 2022-02-25 15:03:30
-->
<template>
  <div id="cloudList" class="page-warp">
    <div class="top-area">
      <div class="cloud-tips ly-shadow">
        <div class="tips-title">云端说明</div>
        <div class="tips-content">
          企业可借助云端账号向用户自动推送通知，如任务完成通知、奖品通知等；登录后，企业无需手动推送消息，且不占用企业每月群发30次的次数，便于企业管理及多次触达客户。云端账号更多应用场景，敬请期待...
        </div>
        <el-popover trigger="hover">
          <p :style="{ textAlign: 'center', margin: '0 0 10px 0' }">扫码添加客服好友</p>
          <img class="qr-code" src="@assets/images/contact_me_qr.png" alt=""
            :style="{ width: '172px', height: '172px' }" />
          <div class="tips-customer-service" slot="reference">如有疑问，请联系客服</div>
        </el-popover>
      </div>
      <div class="balance ly-shadow">
        <img class="bean" src="@assets/images/bean.png" alt="云豆" />
        <div class="bal">
          <div class="desc">{{ beanBalance }}</div>
          <div class="label">云豆余额</div>
        </div>
        <div></div>
        <!-- <div class="recharge-btn" @click="rechargeVisible = true">立即充值</div> -->
      </div>
    </div>

    <ly-table ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :pageSize="per_page" :total="total"
      @searchChange="searchChange" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange">
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button round type="primary" icon="icon-enwechat1 iconfont" @click="loginVisible = true">
          扫码登录账号
        </el-button>
      </template>
      <!-- 列插槽 -->
      <template #name_slot="{ row }">
        <div class="flex flex-start">
          <img class="avatar" :src="row.avatar" />
          <span style="margin-left: 10px">{{ row.name }}</span>
        </div>
      </template>
      <template #status_slot="{ row }">
        <ly-status type="danger" v-if="row.status === 0">离线</ly-status>
        <ly-status type="success" v-if="row.status === 1">在线</ly-status>
        <ly-status type="warning" v-else-if="row.status === 2">同步数据中，账号请勿进行操作
          <ly-count-down v-if="time" :key="row.serial_no" @done="handleDone(row)" :t="row.login_at" :st="time" />
        </ly-status>
        <ly-status v-else-if="row.status === 3">不可用</ly-status>
      </template>
      <template #login_day_slot="{ row }">
        <span>{{ `${row.login_day}天` }}</span>
      </template>
    </ly-table>
    <ly-scan-login v-model="loginVisible"></ly-scan-login>
    <ly-recharge v-model="rechargeVisible" @success="loginVisible = true"></ly-recharge>
  </div>
</template>
<script>
import lyScanLogin from './components/scanLogin'
import lyRecharge from './components/recharge'
import lyCountDown from './components/countDown'
import lyTable from '@/components/mixins/ly-table.js'
import { cloudList } from './http'
export default {
  components: { lyScanLogin, lyRecharge, lyCountDown },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: cloudList,
      beanBalance: 0,
      loginVisible: false,
      rechargeVisible: false,
      time: null // 服务器时间
    }
  },
  watch: {
    loginVisible (n, o) {
      if (!n) {
        this.time = null
        this.queryData()
        this.getBeanBalance()
      }
    },
    rechargeVisible (n, o) {
      if (!n) this.getBeanBalance()
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        operation: [
          {
            label: '下线',
            handler (row) {
              // 自定义回调
              _this.offline(row)
            }
          }
        ],
        operationWidth: 100,
        column: [
          {
            label: '登录账号',
            prop: 'name',
            key: 'user_name',
            slot: 'name_slot',
            search: true,
            placeholder: '请输入员工姓名'
          },
          {
            label: '状态',
            prop: 'status',
            slot: 'status_slot'
          },
          {
            label: '在线时间',
            prop: 'login_day',
            slot: 'login_day_slot'
          }
        ]
      }
    }
  },
  mounted () {
    this.getBeanBalance()
  },
  methods: {
    // 获取服务器时间
    getTime () {
      this.axios.get('getTime').then((res) => {
        this.time = res
      })
    },
    // 获取云豆余额
    getBeanBalance () {
      this.axios.get('beanBalance').then((data) => {
        this.beanBalance = data.bean || 0
      })
    },
    // 主动下线
    offline (row) {
      const h = this.$createElement
      this.$msgbox({
        title: '云端账号下线确认',
        type: 'warning',
        showClose: false,
        customClass: 'offline-msgbox',
        message: h('p', null, [
          h('span', null, '是否确认'),
          h('img', {
            attrs: {
              src: row.avatar
            },
            style: {
              with: '24px',
              height: '24px',
              margin: '0 8px',
              borderRadius: '24px'
            }
          }),
          h('span', null, `${row.name}从云端下线？`)
        ]),
        showCancelButton: true,
        confirmButtonText: '下线',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.axios
            .post('offline', {
              user_id: row.wecom_user_id
            })
            .then((data) => {
              this.queryData()
              switch (data.code) {
                case 0:
                  this.$message.success('操作成功！')
                  break
                case 1:
                  this.$message.error('账号未登录！')
                  break
                case 250003:
                  this.$message.error('机器人未在线！')
                  break
                case 250050:
                  this.$message.info('登录中不可退出！')
                  break
                default:
                  break
              }
            })
        })
        .catch(() => { })
    },
    handleDone (row) {
      row.status = 1
    },
    formatterParams (params) {
      this.getTime()
      return params
    }
  }
}
</script>
<style lang="scss" scoped>
#cloudList {
  .top-area {
    display: flex;
    margin-bottom: 20px;
  }

  .cloud-tips {
    flex: 1;
    background: #fff;
    border-radius: 16px;
    padding: 16px 24px 14px;
    margin-right: 20px;

    .tips-title {
      font-weight: bold;
      position: relative;
      padding-left: 12px;

      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #2b7cff;
        border-radius: 2px;
        position: absolute;
        left: 0px;
        top: 3px;
      }
    }

    .tips-content {
      margin: 13px 0 10px 0;
      color: $--color-text-regular;
    }

    .tips-customer-service {
      color: #2b7cff;
      font-size: 12px;
      float: right;
    }
  }

  .balance {
    width: 300px;
    border-radius: 16px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 30px;

    .bean {
      width: 48px;
      height: 48px;
      background: #fde6b8;
      border-radius: 50%;
    }

    .bal {
      line-height: 1.2;
      width: 140px;

      .desc {
        font-size: 24px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        font-size: 12px;
        color: #6e788a;
      }
    }

    .recharge-btn {
      width: 80px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #faad14;
      border-radius: 16px;
      color: #fff;
      cursor: pointer;
      box-shadow: 0px 8px 16px 0px rgba(255, 193, 7, 0.24);

      &:hover {
        background: #f5b588;
      }
    }
  }

  ::v-deep {
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    .offline {
      color: #2b7cff;
      cursor: pointer;
    }

    .qr-code {
      width: 172px;
      height: 172px;
    }

    .total-num {
      color: #2b7cff;
    }

    .wechart-img {
      width: 17px;
      margin-right: 6px;
    }

    .scan-code span {
      display: flex;
      align-items: center;
    }
  }
}
</style>
<style lang="scss">
.offline-msgbox {
  position: relative;
  padding: 22px 8px;

  .el-icon-warning:before {
    font-family: "iconfont";
    content: "\e6bf";
  }

  .el-button {
    height: 36px;
    border-radius: 36px;
    font-size: 14px;
  }

  .el-message-box__header {
    padding: 0 15px;
  }
}

.offline-msgbox .el-message-box__header {
  padding-left: 60px;
}

.offline-msgbox .el-message-box__status {
  position: absolute;
  top: -19px;
  left: 4px;
}

.offline-msgbox .el-message-box__status+.el-message-box__message {
  padding-left: 46px;
}

.offline-msgbox .el-message-box__message p {
  display: flex;
}
</style>
