<!--
 * @Author: zhanln
 * @Date: 2021-11-09 10:35:56
 * @LastEditTime: 2022-08-04 17:14:34
 * @LastEditors: zhan
 * @Description: 消息通知
-->

<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-width="150px" size="medium" class="act-form"
    v-loading="cardLoading">
    <!-- 推送方式 -->
    <el-form-item label="推送方式：" prop="reward_push_type">
      <el-radio-group v-model="form.reward_push_type" v-if="hasCloudBean">
        <el-radio :label="1">手动推送</el-radio>
        <el-radio :label="2">自动推送</el-radio>
      </el-radio-group>

      <div class="q-bg text-small" style="margin: 10px 0" v-if="form.reward_push_type === 1">
        <div style="margin-bottom: 8px" v-if="hasCloudBean">
          由于企微官方对群发限制，每个用户每日只能收到<b>1</b>条奖品推送消息。
          <span class="q-primary" style="cursor: pointer" @click="form.reward_push_type = 2">选择“自动推送”突破限制 ></span>
        </div>
        <div>
          消息推送完成后，需要对应的员工手动确认，确认后消息发送给对应用户。
          <el-tooltip class="item" placement="top" popper-class="q-tooltip is-light">
            <div slot="content">
              <div style="margin: 24px 24px 16px; width: 777px">
                <img src="https://image.01lb.com.cn//uploads/wecom/21/0416/1618577894N96zjbn3.png" width="777" alt="" />
              </div>
            </div>
            <span class="q-primary">员工如何确认消息发送？</span>
          </el-tooltip>
        </div>
      </div>

      <template v-else>
        <div class="q-bg text-small" style="line-height: 1.6; margin: 12px 0">
          <div style="margin-bottom: 8px">
            1.员工登录云端后，将无群发消息次数限制，能够自动发送"奖品通知"和“助力提醒”。
          </div>
          <div style="margin-bottom: 8px">
            2.
            选择此方式，未登录云端的员工，将默认使用【手动推送】的方式，且不发送“助力提醒”。
          </div>
          <div>
            3.员工登录云端后，如果员工在电脑登录企业微信，将会把云端登录挤掉线，造成无法自动发送内容，活动期间建议保持员工云端在线登录状态。
          </div>
        </div>

        <!-- 账号列表 -->
        <account-list ref="accountListRef" style="line-height: 1.6"></account-list>
      </template>
    </el-form-item>

    <!-- 奖品推送 -->
    <el-form-item label="奖品推送：" prop="reward_notice_text">
      <ly-editor class="act-form__editor" title="奖品推送" id="notice" :content="form.reward_notice_text"
        :insetMenu="['用户昵称', '任务阶段', '奖品名称']" :recoverContent="basePushText" emoji @getContent="fn_getPushText">
      </ly-editor>
    </el-form-item>

    <!-- 助力成功提醒 -->
    <el-form-item label="助力成功提醒：" prop="help_notice_text" v-if="form.reward_push_type === 2">
      <el-radio-group v-model="form.help_push_type" class="reward_radio">
        <el-radio :label="1">每新增一位好友提醒上级</el-radio>
        <el-radio :label="2">每一阶新增的第一位好友助力成功提醒上级</el-radio>
      </el-radio-group>

      <ly-editor class="act-form__editor success-notice" title="助力成功提醒" id="help" :content="form.help_notice_text"
        :insetMenu="[
          '用户昵称',
          '助力好友昵称',
          '已完成任务量',
          '未完成任务量',
          '奖品名称',
        ]" :recoverContent="baseHelpText" emoji @getContent="fn_getHelpText"></ly-editor>
    </el-form-item>

    <!-- 好友取关提醒 -->
    <el-form-item label="好友取关提醒：" prop="friend_notice_text" v-if="form.reward_push_type === 2">
      <div class="flex" style="height: 36px">
        <el-switch v-model="form.friend_push_type" :active-value="1" :inactive-value="0"></el-switch>
        <span class="q-info" style="line-height: 20px; margin-left: 10px">{{
            form.friend_push_type === 0 ? "已关闭" : "已开启"
        }}</span>
      </div>

      <ly-editor class="act-form__editor closing-notice" title="好友取关提醒" id="friend" :content="form.friend_notice_text"
        :insetMenu="[
          '用户昵称',
          '助力好友昵称',
          '已完成任务量',
          '未完成任务量',
          '奖品名称',
          '涨粉账号',
        ]" :recoverContent="baseDelText" emoji @getContent="fn_getFriendText" v-if="form.friend_push_type === 1">
      </ly-editor>
    </el-form-item>
  </el-form>
</template>

<script>
import lyEditor from '@/components/lyEditor'
import accountList from './noticeAccount'
import mapTag from '@/common/mapTag'
import { mapState } from 'vuex'
export default {
  name: 'taskNotice',

  data () {
    // 助力成功提醒校验
    const checkHelp = (rules, value, callback) => {
      if (this.form.reward_push_type === 2 && value === '') {
        callback(new Error('请填写助力成功提醒内容'))
      } else {
        callback()
      }
    }
    // 好友删除提醒校验
    const checkFriendNotice = (rules, value, callback) => {
      if (this.form.reward_push_type === 2 && this.form.friend_push_type === 1 && value === '') {
        callback(new Error('请填写好友取关提醒内容'))
      } else {
        callback()
      }
    }

    return {
      cardLoading: true,
      form: {
        reward_push_type: 1,
        reward_notice_text: '',
        help_push_type: 1,
        help_notice_text: '',
        friend_push_type: 1,
        friend_notice_text: ''
      },
      rules: {
        reward_notice_text: [{
          required: true,
          message: '请填写奖品推送内容',
          trigger: 'change'
        }],
        help_notice_text: [{
          validator: checkHelp,
          required: true,
          trigger: 'change'
        }],
        friend_notice_text: [{
          validator: checkFriendNotice,
          required: true,
          trigger: 'change'
        }]
      },
      basePushText: '#用户昵称#，恭喜您完成#任务阶段#\n获得#奖品名称#\n请查看下方奖品内容',
      baseHelpText: '你好！你的好友#助力好友昵称#支持你了！\n你已获得#已完成任务量#个好友的支持，\n还差#未完成任务量#个好友的支持，即可获得奖励。 继续努力邀请吧～',
      baseDelText: '您的好友#助力好友昵称#刚刚删除了#涨粉账号#，该好友的助力失效啦，当前还需#未完成任务量#位好友助力，快去邀请更多的好友为您助力吧！\n温馨提示：活动期间请暂勿删除好友哦~'
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.task.isEdit,
      isCopy: state => state.task.isCopy
    }),
    hasCloudBean () {
      return this.$store.state.permission.hasCloudBean
    }
  },

  watch: {
    'form.reward_push_type' (val) {
      if (val === 2) {
        this.$nextTick(() => {
          this.$refs.accountListRef.fn_getRobotList()
        })
      }
    },
    'form.reward_notice_text' (val) {
      this.$store.commit('task/SET_INFO', {
        type: 'reward_notice_text',
        val
      })
    }
  },

  methods: {

    // 表单配置
    fn_setForm (data) {
      if (data) {
        data.help_push_type = data.help_push_type || 1
        data.reward_notice_text = mapTag(data.reward_notice_text)
        data.help_notice_text = data.help_notice_text ? mapTag(data.help_notice_text) : this.baseHelpText
        data.friend_notice_text = data.friend_notice_text ? mapTag(data.friend_notice_text) : this.baseDelText
        this.form = data
      } else {
        this.form.reward_notice_text = this.form.reward_notice_text || this.basePushText
        this.form.help_notice_text = this.form.help_notice_text || this.baseHelpText
        this.form.friend_notice_text = this.form.friend_notice_text || this.baseDelText
      }
      this.cardLoading = false
    },

    // 奖品推送 回调配置
    fn_getPushText (text) {
      this.form.reward_notice_text = text
    },

    // 助力成功提醒 回调配置
    fn_getHelpText (text) {
      this.form.help_notice_text = text
    },

    // 好友取关提醒 回调配置
    fn_getFriendText (text) {
      this.form.friend_notice_text = text
    },

    // 表单校验，通过则返回 form
    verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)
      form.friend_notice_text = mapTag(form.friend_notice_text, false)
      form.help_notice_text = mapTag(form.help_notice_text, false)
      form.reward_notice_text = mapTag(form.reward_notice_text, false)

      return form
    }
  },

  components: {
    lyEditor,
    accountList
  }
}
</script>

<style lang="scss" scoped>
.reward_radio {
  ::v-deep .el-radio {
    display: block;
    line-height: 36px;
  }
}

.success-notice {
  ::v-deep {
    .ly-editor__menus {
      &:nth-of-type(4) {
        margin-left: 22px;
      }

      &:nth-of-type(4),
      &:nth-of-type(5) {
        margin-top: 5px;
      }
    }
  }
}

.closing-notice {
  ::v-deep {
    .ly-editor__menus {
      &:nth-of-type(4) {
        margin-left: 22px;
      }

      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        margin-top: 5px;
      }
    }
  }
}
</style>
