<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>
    <div class="edit-card__content">
      <div class="reward-tab">
        <div class="reward-tab__header">
          <div class="reward-tab__menu">
            <div
              v-for="item in tabs"
              :key="item.order"
              :class="[
                'item',
                item.active ? 'active' : '',
                item.disable ? 'is-disable' : '',
              ]"
              @click="fn_changeTab(item.order, item.disable)"
            >
              {{ item.label }}
              <template>
                · {{ item.status === 1 ? item.count : "未开启" }}</template
              >
            </div>
          </div>
        </div>
      </div>
      <div class="reward-pane">
        <reward-form :order="activeOrder" ref="rewardCard"></reward-form>
      </div>
    </div>
  </div>
</template>

<script>
import rewardForm from './rewardForm'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
export default {
  name: 'Reward',
  props: {
    title: {
      type: String,
      defualt: () => {
        return ''
      }
    }
  },
  mixins: [qiniuUpload],
  data () {
    return {
      tabs: [
        {
          label: '一阶任务',
          order: 'first',
          active: true,
          count: 2,
          status: 1,
          disable: false
        },
        {
          label: '二阶任务',
          order: 'second',
          active: false,
          count: 3,
          status: 0,
          disable: false
        },
        {
          label: '三阶任务',
          order: 'third',
          active: false,
          count: 4,
          status: 0,
          disable: false
        }
      ],
      activeOrder: 'first',
      bannerForm: {
        type: 1,
        list: [],
        title: '加入专属福利群\n0元领奖品',
        color: '#f4503a'
      },
      bannerRules: {
        title: [
          { required: true, message: '请输入活动标题', trigger: 'blur' },
          { min: 0, max: 16, message: '长度在 0 到 16 个字符', trigger: 'blur' }
        ]
      },
      bannerError: '',
      titleError: '',
      colorList: ['#f4503a', '#ffa500']
    }
  },

  computed: {
    isReady () {
      return this.$store.getters['groupfission/GET_READY']
    },
    firstCount () {
      return this.$store.getters['groupfission/GET_TASK']('first').target_count
    },
    firstStatus () {
      return this.$store.getters['groupfission/GET_TASK']('first').status
    },
    secondCount () {
      return this.$store.getters['groupfission/GET_TASK']('second').target_count
    },
    secondStatus () {
      return this.$store.getters['groupfission/GET_TASK']('second').status
    },
    thirdCount () {
      return this.$store.getters['groupfission/GET_TASK']('third').target_count
    },
    thirdStatus () {
      return this.$store.getters['groupfission/GET_TASK']('third').status
    }
  },

  watch: {
    isReady (val) {
      if (val) {
        const isEdit = this.$store.getters['groupfission/GET_TYPE']
        if (isEdit) {
          this.bannerForm = this.$store.getters['groupfission/GET_BANNER']
          this.tabs[0].disable = this.$store.getters['groupfission/GET_TASK']('first').disable
          this.tabs[1].disable = this.$store.getters['groupfission/GET_TASK']('second').disable
          this.tabs[2].disable = this.$store.getters['groupfission/GET_TASK']('third').disable
        }
        this.bannerForm.color = this.bannerForm.color || this.colorList[0]
        this.fn_setForm(this.bannerForm.color, 'color')
      }
    },
    firstCount (val) {
      this.tabs[0].count = val
    },
    firstStatus (val) {
      this.tabs[0].status = val
    },
    secondCount (val) {
      this.tabs[1].count = val
    },
    secondStatus (val) {
      this.tabs[1].status = val
    },
    thirdCount (val) {
      this.tabs[2].count = val
    },
    thirdStatus (val) {
      this.tabs[2].status = val
    },
    'bannerForm.type' (val) {
      this.fn_setForm(val, 'type')
      if (val === 1) {
        this.bannerError = ''
      }
    }
  },

  methods: {

    // 上传banner
    async requestQiniu (opts) {
      this.bannerForm.list = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.bannerForm.list = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.fn_setForm(this.bannerForm.list, 'list')
          },
          null,
          {
            type: ['jpg', 'png'],
            size: '2MB'
          }
        )
      }

      this.bannerError = ''
    },

    // 移除背景
    removePoster () {
      this.bannerForm.list = []
      this.bannerError = '请上传活动页首图'
      this.fn_setForm([], 'list')
    },

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: 'banner',
        name: type,
        val: val
      })
    },

    // 切换 tab
    fn_changeTab (tab, disable) {
      const currentOrder = this.activeOrder

      // 点击了当前的tab, return
      if (currentOrder === tab || disable) return

      // 切换前先校验当前阶段
      const veriry = this.verify(false)
      if (!veriry) {
        this.fn_lyMsg('info', '请先完成本阶任务的配置')
        return false
      }

      const tabArr = ['first', 'second', 'third']

      const currentIndex = tabArr.indexOf(currentOrder)
      const tabIndex = tabArr.indexOf(tab)

      // 跳三阶，判断二阶是否开启
      if (this.secondStatus === 0 && tabIndex === 2) {
        this.fn_lyMsg('info', '请先开启二阶任务')
        return false
      }

      this.tabs[currentIndex].active = false
      this.tabs[tabIndex].active = true
      this.activeOrder = tab
      this.$store.commit('_SET', {
        'groupfission.currentTask': tab
      })
    },

    // 表单验证
    verify (type = true) {
      let _allForm = true

      _allForm = this.$refs.rewardCard.fn_verify()

      return _allForm
    }
  },
  components: {
    rewardForm
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
@import "@/assets/scss/var.scss";

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: 1px solid #e4e7ed;
}

::v-deep .el-tabs__item {
  height: 40px;
  line-height: 40px;
  margin-right: 4px;
  border: 1px solid #eaebf2;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.02);
  color: $--color-text-regular;
  padding: 0 36px;
}

::v-deep
  .el-tabs--top.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:last-child {
  padding-right: 36px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  background-color: #fff;
  color: $--color-primary;
}

.reward {
  &-tab {
    &__header {
      margin-bottom: 24px;
      border-bottom: 1px solid #e4e7ed;
      display: inline-block;
    }

    &__menu {
      display: flex;
      margin-bottom: -1px;

      .item {
        padding: 0 28px;
        border: 1px solid #e4e7ed;
        background-color: #f7f8fc;
        height: 40px;
        line-height: 40px;
        border-radius: 8px 8px 0 0;
        cursor: pointer;
        transition: 0.25s;

        &:hover:not(.is-disable) {
          color: $--color-primary;
        }

        &:not(:last-child) {
          margin-right: 4px;
        }

        &.active {
          border-bottom-color: #fff;
          background-color: transparent;
          color: #2b7cff;
        }

        &.is-disable {
          cursor: not-allowed;
          background-color: #f7f8fc;
          color: #eaebf2;
        }
      }
    }
  }
}
.upload {
  line-height: 1;
  ::v-deep .el-upload-list__item {
    margin: 0;
    transition: none !important;
  }
}
.upload-empty {
  line-height: 1;
  ::v-deep .el-upload--picture-card {
    display: none;
  }
  ::v-deep .el-upload-list__item {
    margin: 0;
  }
}

.banner-color {
  display: flex;
  height: 36px;
  align-items: center;

  &__item {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 2px;
    transition: 0.25s;

    i {
      color: #fff;
    }

    &:hover,
    &.is-active {
      cursor: pointer;
    }

    &:hover {
      border-color: #333;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
</style>
