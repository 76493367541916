<!--
 * @Author: zhanln
 * @Date: 2021-11-17 16:58:54
 * @LastEditTime: 2022-01-12 17:28:07
 * @LastEditors: zhanln
 * @Description: 奖品预览
-->

<template>
  <div class="reward-drawer" :class="{ 'is-top': reward_focus }">
    <div class="reward-drawer__mask" v-if="reward_focus"></div>
    <div class="reward-drawer__box" :class="{ show: reward_focus }">
      <div class="reward-drawer__title">领取奖品</div>
      <!-- 自定义 -->
      <template v-if="reward_type === 1">
        <div class="reward-drawer__body">
          <div class="reward-drawer__detail" v-html="diy_content"></div>
          <div class="reward-drawer__img">
            <img :src="diy_img" alt="" v-if="diy_img" />
          </div>
        </div>
      </template>

      <!-- 兑换码 -->
      <template v-if="reward_type === 2">
        <div class="reward-drawer__body">
          <div class="reward-drawer__detail" v-html="code_rule"></div>
        </div>
        <div class="reward-drawer__code">SJFHEAML</div>
        <div class="reward-drawer__btn">点击复制兑换码</div>
      </template>

      <!-- 兑换码 -->
      <template v-if="reward_type === 3">
        <div class="reward-drawer__body">
          <div class="reward-drawer__detail" v-html="form_rule"></div>
        </div>
        <div class="reward-drawer__btn">
          去填写表单，领取奖品 <i class="el-icon-d-arrow-right"></i>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// store
import { mapState } from 'vuex'
export default {
  name: 'taskMobileRewardDetail',

  computed: {
    ...mapState({
      reward_focus: state => state.task.reward_focus,
      reward_type: state => state.task.reward_type,
      diy_content: state => state.task.diy_content,
      diy_img: state => state.task.diy_img,
      code_rule: state => state.task.code_rule,
      form_rule: state => state.task.form_rule
    })
  }
}
</script>

<style lang="scss" scoped>
.reward-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  &.is-top {
    z-index: 99;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100%;
    z-index: 1;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    transition: 0.4s ease-in-out;

    &.show {
      bottom: 0;
    }
  }

  &__body {
    background-color: #fffcfa;
    margin: 0 16px;
    padding: 16px;
    color: #454d5b;
    min-height: 186px;
    max-height: 300px;
    overflow-y: auto;
    box-sizing: border-box;
    word-break: break-all;
  }

  &__title {
    font-size: 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #323233;
  }

  &__img {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 50%;
    }
  }

  &__code {
    background-color: #f9f9f9;
    border-radius: 22px;
    height: 44px;
    line-height: 44px;
    margin: 16px;
    padding: 0 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  &__btn {
    background: linear-gradient(150deg, #fecd01 9%, #fb7f15 88%);
    border-radius: 22px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin: 16px;
  }
}
</style>
