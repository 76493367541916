var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加消息",
        visible: _vm.visible,
        "custom-class": "add-content",
        width: "560px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.handleOpen
      }
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "text" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _vm.hasText
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "因企微官方限制，文本消息仅可发一条",
                            placement: "top-start"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "not-allowed",
                                color: "#c0c4cc"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v("文本")]
                          )
                        ]
                      )
                    : _c("span", [_vm._v("文本")])
                ],
                1
              ),
              _c("ly-editor", {
                attrs: {
                  title: "客服欢迎语",
                  id: "basicCustomerWel",
                  content: _vm.text.welcomeContent,
                  recoverContent: _vm.defaultContent,
                  error: _vm.text.welcomeContent === "",
                  insetMenu: ["用户昵称"],
                  emoji: true,
                  maxLength: 800
                },
                on: { getContent: _vm.fn_getCusWelWords }
              }),
              _c("transition", { attrs: { name: "fade" } }, [
                !_vm.text.welcomeContent
                  ? _c("div", { staticClass: "error-desc" }, [
                      _vm._v(" 请输入文本消息 ")
                    ])
                  : _vm._e()
              ])
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { staticClass: "content", attrs: { label: "图片", name: "pic" } },
            [
              _c(
                "el-upload",
                {
                  ref: "pic",
                  staticClass: "uploader",
                  attrs: {
                    action: "#",
                    accept: ".png, .jpg",
                    "http-request": function(opts) {
                      return _vm.requestQiniu(opts, "pic")
                    },
                    limit: 1,
                    "show-file-list": false
                  }
                },
                [
                  _vm.pic.imageUrl
                    ? _c("div", { staticClass: "thum" }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.pic.imageUrl }
                        }),
                        _c(
                          "i",
                          {
                            staticClass: "el-icon-closeplus",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleRemove("pic")
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        )
                      ])
                    : [
                        _c("div", { staticClass: "upload-box flex" }, [
                          _c("i", {
                            staticClass:
                              "iconfont icon-plus avatar-uploader-icon"
                          }),
                          _c("span", [_vm._v("上传图片")])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("支持JPG、PNG格式，图片小于2M")]
                        )
                      ]
                ],
                2
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.picError
                  ? _c("div", { staticClass: "error-desc" }, [
                      _vm._v("请上传图片")
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              staticClass: "content",
              attrs: { label: "小程序", name: "applet" }
            },
            [
              _c("div", { staticClass: "tips" }, [
                _vm._v("注：请填写企业微信后台绑定的小程序id和路径")
              ]),
              _c(
                "el-form",
                {
                  ref: "appletForm",
                  staticClass: "applet-form",
                  attrs: {
                    size: "medium",
                    rules: _vm.appletRules,
                    model: _vm.applet,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "appTitle", label: "小程序标题：" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "lydebounce",
                            rawName: "v-lydebounce",
                            value: ["input", _vm.getUrlInfo],
                            expression: "['input', getUrlInfo]"
                          }
                        ],
                        attrs: {
                          "show-word-limit": "",
                          maxlength: "32",
                          placeholder: "请输入小程序标题"
                        },
                        model: {
                          value: _vm.applet.appTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.applet, "appTitle", $$v)
                          },
                          expression: "applet.appTitle"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "p-relative",
                      attrs: { prop: "appId", label: "小程序appid：" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          "show-word-limit": "",
                          maxlength: "32",
                          placeholder: "请输入小程序appid"
                        },
                        model: {
                          value: _vm.applet.appId,
                          callback: function($$v) {
                            _vm.$set(_vm.applet, "appId", $$v)
                          },
                          expression: "applet.appId"
                        }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "helper",
                          attrs: {
                            href:
                              "https://www.yuque.com/docs/share/dbd084fc-33e9-45a7-ac66-6710855e2745?#",
                            target: "_blank"
                          }
                        },
                        [_vm._v("如何获取小程序appid？")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "desc p-relative",
                      attrs: { prop: "appPage", label: "小程序路径：" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          "show-word-limit": "",
                          maxlength: "250",
                          placeholder: "请输入小程序路径"
                        },
                        model: {
                          value: _vm.applet.appPage,
                          callback: function($$v) {
                            _vm.$set(_vm.applet, "appPage", $$v)
                          },
                          expression: "applet.appPage"
                        }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "helper",
                          attrs: {
                            href:
                              "https://www.yuque.com/docs/share/32544cd6-6726-4101-8c57-bd08fe9f71c4?#",
                            target: "_blank"
                          }
                        },
                        [_vm._v("如何获取小程序路径？")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "poster",
                      attrs: { prop: "appPicMediaUrl", label: "小程序封面：" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("ly-upload", {
                            attrs: { img: _vm.applet.appPicMediaUrl },
                            on: {
                              getImg: function($event) {
                                return _vm.fn_changeCode($event, "applet")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "q-info text-small" }, [
                        _vm._v(
                          " 支持JPG、PNG格式，图片小于2M，尺寸建议520*416px "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { staticClass: "content", attrs: { label: "链接", name: "link" } },
            [
              _c(
                "el-form",
                {
                  ref: "linkForm",
                  staticClass: "link-form",
                  attrs: {
                    size: "medium",
                    rules: _vm.linkRules,
                    model: _vm.link,
                    "label-width": "0px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "linkUrl" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "lydebounce",
                            rawName: "v-lydebounce",
                            value: ["input", _vm.getUrlInfo],
                            expression: "['input', getUrlInfo]"
                          }
                        ],
                        attrs: { placeholder: "请输入链接" },
                        model: {
                          value: _vm.link.linkUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.link, "linkUrl", $$v)
                          },
                          expression: "link.linkUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "linkTitle" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 1,
                          "show-word-limit": "",
                          maxlength: "40",
                          placeholder: "链接标题"
                        },
                        model: {
                          value: _vm.link.linkTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.link, "linkTitle", $$v)
                          },
                          expression: "link.linkTitle"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "desc", attrs: { prop: "linkDsc" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          "show-word-limit": "",
                          maxlength: "170",
                          placeholder: "快来参加活动吧"
                        },
                        model: {
                          value: _vm.link.linkDsc,
                          callback: function($$v) {
                            _vm.$set(_vm.link, "linkDsc", $$v)
                          },
                          expression: "link.linkDsc"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "poster" },
                    [
                      _c("ly-upload", {
                        attrs: { img: _vm.link.linkPicUrl, canRemove: "" },
                        on: {
                          getImg: function($event) {
                            return _vm.fn_changeCode($event, "link")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "text-small",
                      staticStyle: { color: "#6e788a" }
                    },
                    [
                      _vm._v(
                        " 支持JPG、PNG格式，图片小于2M，尺寸建议500*500px "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.handlePushMsg }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }