<!--
 * @Author: zhanln
 * @Date: 2021-10-14 17:09:09
 * @LastEditTime: 2021-12-16 11:05:34
 * @LastEditors: zhanln
 * @Description: 折线图模板，调用 setOption(data) 创建
-->

<template>
  <ly-chart :height="height" :option="trendOption" ref="chartRef"></ly-chart>
</template>

<script>
import lyChart from './index'
import * as echarts from 'echarts'
import getColors from './colors'
export default {
  name: 'trend',

  props: {
    height: {
      type: String,
      default: '300px'
    },
    isAllCus: { // 为 true 则替换所有传入的option 属性，为 false 则是合并， 默认 false
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => { }
    }
  },

  computed: {
    s_option () {
      return this.option
    }
  },

  watch: {
    s_option: {
      handler (val) {
        if (val) {
          this.fn_setting(val)
        }
      },
      immediate: true,
      deep: true
    }
  },

  data () {
    return {
      baseOption: {
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(2, 15, 32, 0.78)',
          borderWidth: 0,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          icon: 'circle',
          bottom: '1%',
          itemGap: 30
        },
        grid: {
          left: '2%',
          right: '3%',
          bottom: '12%',
          top: '12%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            show: false,
            height: 12,
            // showDataShadow: false,
            showDetail: false,
            brushSelect: false,
            xAxisIndex: [0],
            startValue: 0,
            endValue: 14,
            bottom: '12%',
            zoomLock: true,
            handleStyle: {
              opacity: 0
            }
          },
          {
            type: 'inside',
            xAxisIndex: [0],
            startValue: 0,
            endValue: 10,
            zoomLock: true
          }
        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              color: '#6E788A',
              margin: 14
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(233, 233, 233, 1)'
              }
            },
            data: []
          }
        ],
        yAxis: {
          name: '',
          type: 'value',
          nameTextStyle: {
            align: 'right'
          },
          axisLabel: {
            color: '#6E788A'
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(233, 233, 233, 1)',
              type: 'dashed'
            }
          }
        },
        series: []
      },
      trendOption: null
    }
  },

  mounted () {
    // 开启显示演示数据
    // this.fn_default()
  },

  methods: {

    // 图表数据处理，先设置 baseOption 再赋值到 trendOption，避免图表多次绘制
    async setOption (data) {
      // 设置折现样式
      if (data.series) {
        data.series.forEach((item, index) => {
          const rgb = getColors(index, false)
          const style = this.fn_initStyle(rgb)
          item = Object.assign(item, style)
        })
        this.baseOption.series = data.series
      }

      // 设置x轴及滚动条
      if (data.xAxis) {
        this.baseOption.xAxis[0].data = data.xAxis.data
        if (data.xAxis.data.length > this.baseOption.dataZoom[0].endValue + 1) {
          this.baseOption.dataZoom[0].show = true
          this.baseOption.grid.bottom = '20%'
        } else {
          this.baseOption.dataZoom[0].show = false
          this.baseOption.grid.bottom = '12%'
        }
      }

      this.trendOption = this.baseOption
    },

    // 自定义配置
    fn_setting (val) {
      for (const item in val) {
        // 如果传入的属性不存在 或者 isAllCus: true, 则替换该属性
        if (item !== 'series') {
          if (!this.baseOption[item] || this.isAllCus) {
            this.baseOption[item] = val[item]
          } else {
            Object.assign(this.baseOption[item], val[item])
          }
        }
      }
    },

    // 折线样式配置
    fn_initStyle (rgb) {
      const series = this.option && this.option.series
      // 默认样式
      let obj = {
        type: 'line',
        smooth: true,
        symbol: 'circle',
        showSymbol: false,
        symbolSize: 10,
        itemStyle: {
          color: `rgb(${rgb})`
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: `rgba(${rgb}, 0)`
            },
            {
              offset: 0,
              color: `rgba(${rgb}, 0.08)`
            }
          ])
        },
        animationDuration: 1000
      }

      if (series) {
        if (!this.isAllCus) {
          Object.assign(obj, series)
        } else {
          obj = {
            type: 'line',
            ...series
          }
        }
        return obj
      } else {
        return obj
      }
    },

    // 演示效果
    fn_default () {
      const option = {
        xAxis: {
          data: ['演示数据 1', '演示数据 2', '演示数据 3']
        },
        series: [
          {
            name: 'data 1',
            data: [2, 1, 3]
          },
          {
            name: 'data 2',
            data: [2, 4, 1]
          }
        ]
      }

      this.setOption(option)
    },

    // 重置尺寸
    fn_resize (w) {
      this.$refs.chartRef._cusResize(w)
    }
  },

  components: {
    lyChart
  }
}
</script>
