var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "q-dialog inviter-dialog",
      attrs: { visible: _vm.inviterVisible, width: "960px", title: "群发助手" },
      on: {
        "update:visible": function($event) {
          _vm.inviterVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "inviter-dialog__main" },
        [
          _c(
            "el-scrollbar",
            { staticClass: "inviter-dialog__main-left" },
            [
              _c(
                "el-form",
                {
                  ref: "inviterData",
                  staticStyle: { flex: "1", "padding-right": "16px" },
                  attrs: {
                    model: _vm.inviterData,
                    rules: _vm.inviterRules,
                    "label-width": "120px",
                    size: "medium"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "wecom_user_ids",
                      attrs: {
                        label: "群发账号",
                        required: "",
                        error: _vm.wecom_user_idsTips
                      }
                    },
                    [
                      _c("ly-emp-select", {
                        ref: "empSelect",
                        attrs: {
                          placeholder: "选择群发账号",
                          datafilter: "",
                          showLength: 3
                        },
                        on: { getEmpId: _vm.getEmpId }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "flex flex-between",
                          staticStyle: {
                            "margin-top": "8px",
                            "line-height": "1"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: _vm.inviterData.tag_open,
                                  callback: function($$v) {
                                    _vm.$set(_vm.inviterData, "tag_open", $$v)
                                  },
                                  expression: "inviterData.tag_open"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "q-info",
                                  staticStyle: {
                                    "margin-left": "12px",
                                    "margin-right": "4px"
                                  }
                                },
                                [_vm._v("更多筛选组合 ")]
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top",
                                    "popper-class": "q-tooltip"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        " 打开「更多筛选组合」后，将群发给「群发账号」下满足对应条件的好友用户 "
                                      )
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "iconfont icon-info-fill1",
                                    staticStyle: { color: "#b4bbcc" }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "q-info" }, [
                            _vm._v(" 将群发给"),
                            _c("span", { staticClass: "q-primary" }, [
                              _vm._v(" " + _vm._s(_vm.clientSum) + " ")
                            ]),
                            _vm._v("个客户 ")
                          ])
                        ]
                      ),
                      _vm.inviterData.tag_open === 1
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "8px" } },
                            [
                              _c("tag-select-v2", {
                                attrs: {
                                  title: "通过标签筛选客户",
                                  checked: _vm.inviterData.tags
                                },
                                on: { getTagId: _vm.fn_getFilterIds }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "群发方式", prop: "push_type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.fn_changePushType },
                          model: {
                            value: _vm.inviterData.push_type,
                            callback: function($$v) {
                              _vm.$set(_vm.inviterData, "push_type", $$v)
                            },
                            expression: "inviterData.push_type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("手动推送")
                          ]),
                          _vm.hasCloudBean
                            ? _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("自动推送")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.inviterData.push_type === 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "is-primary flex flex-start text-small"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    flex: "1",
                                    "line-height": "20px",
                                    color: "#6e788a"
                                  }
                                },
                                [
                                  _vm.hasCloudBean
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "8px"
                                          }
                                        },
                                        [
                                          _vm._v(" 企微官方推送将使用 "),
                                          _c("b", [_vm._v("1")]),
                                          _vm._v(
                                            " 次「企微群发」次数，每个用户每月只能收到 "
                                          ),
                                          _c("b", [_vm._v("30")]),
                                          _vm._v(" 次企业群发。 "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "q-primary",
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.fn_changePushTypeByText(
                                                    2
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("选择“自动推送”突破限制 >")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    [
                                      _vm._v(
                                        " 消息推送完成后，需要对应的员工手动确认，确认后消息发送给对应用户。 "
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          staticStyle: {
                                            "border-radius": "16px"
                                          },
                                          attrs: {
                                            placement: "top",
                                            "popper-class": "q-tooltip is-light"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "24px 24px 16px",
                                                    width: "777px"
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        "https://image.01lb.com.cn//uploads/wecom/21/0416/1618577894N96zjbn3.png",
                                                      width: "777",
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "q-primary" },
                                            [_vm._v("员工如何确认消息发送？")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.inviterData.push_type === 2
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "is-primary flex flex-start text-small",
                                staticStyle: {
                                  margin: "0",
                                  "line-height": "1.6"
                                }
                              },
                              [
                                _c("div", { staticStyle: { flex: "1" } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-bottom": "8px" } },
                                    [
                                      _vm._v(
                                        ' 1、员工登录云端后，将无群发消息次数限制，能够自动发送"奖品通知"和“助力提醒”。 '
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-bottom": "8px" } },
                                    [
                                      _vm._v(
                                        " 2、 选择此方式，未登录云端的员工，将默认使用【手动推送】的方式，且不发送“助力提醒”。 "
                                      )
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " 3、员工登录云端后，如果员工在电脑登录企业微信，将会把云端登录挤掉线，造成无法自动发送内容，活动期间建议保持员工云端在线登录状态。 "
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "account" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "account-header flex flex-between"
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.fn_changeAccountType },
                                      model: {
                                        value: _vm.accountType,
                                        callback: function($$v) {
                                          _vm.accountType = $$v
                                        },
                                        expression: "accountType"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: {
                                            label: 1,
                                            disabled: _vm.accountLoading
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "全部账号(" +
                                              _vm._s(
                                                _vm.accountListAll.length
                                              ) +
                                              ")"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: {
                                            label: 2,
                                            disabled: _vm.accountLoading
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "离线(" +
                                              _vm._s(
                                                _vm.accountListOut.length
                                              ) +
                                              ")"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "lydebounce",
                                          rawName: "v-lydebounce",
                                          value: [
                                            "button",
                                            _vm.fn_getRobotList
                                          ],
                                          expression:
                                            "['button', fn_getRobotList]"
                                        }
                                      ],
                                      attrs: {
                                        type: "primary",
                                        size: "ly-mini",
                                        disabled: _vm.accountLoading
                                      }
                                    },
                                    [_vm._v("刷新")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "account-list" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.accountLoading,
                                          expression: "accountLoading"
                                        }
                                      ],
                                      staticClass:
                                        "ly-table is-mini no-border v-middle",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.accountList,
                                        "max-height": "250",
                                        "empty-text": "请选择群发账号"
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "name, avatar",
                                          label: "群发账号"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex" },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "ly-table__main-avatar",
                                                        attrs: {
                                                          src:
                                                            scope.row.avatar ||
                                                            _vm.defaultAvatar,
                                                          alt: ""
                                                        }
                                                      }),
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ly-table__main-name"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row.name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3606407791
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "name",
                                          label: "云端登录状态"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        !scope.row.status
                                                          ? "q-info"
                                                          : ""
                                                      ]
                                                    },
                                                    [
                                                      _c("span", {
                                                        class: [
                                                          "q-circle",
                                                          scope.row.status === 1
                                                            ? "success"
                                                            : ""
                                                        ]
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.status
                                                            ? "在线"
                                                            : "离线"
                                                        ) + " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2120104541
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "address",
                                          label: "操作",
                                          width: "100",
                                          "class-name": "operation-cell"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        disabled:
                                                          scope.row.status === 1
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.fn_jumpCloudPage
                                                      }
                                                    },
                                                    [_vm._v("登录云端 ")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          800623616
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "群发内容", prop: "content" } },
                    [
                      _c("ly-editor", {
                        attrs: {
                          title: "群发内容",
                          id: "noticeHelp",
                          content: _vm.inviterData.content,
                          recoverContent: _vm.contentDefault,
                          emoji: ""
                        },
                        on: { getContent: _vm.fn_getContent }
                      })
                    ],
                    1
                  ),
                  _vm.customLink
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "自定义链接", prop: "title" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入链接标题",
                              maxlength: "20",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.inviterData.link_info.title,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.inviterData.link_info,
                                  "title",
                                  $$v
                                )
                              },
                              expression: "inviterData.link_info.title"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: { "margin-top": "8px" }
                            },
                            [
                              _c("el-input", {
                                staticClass: "inviterDesc",
                                attrs: {
                                  type: "textarea",
                                  resize: "none",
                                  placeholder: "请输入链接摘要"
                                },
                                model: {
                                  value: _vm.inviterData.link_info.desc,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.inviterData.link_info,
                                      "desc",
                                      $$v
                                    )
                                  },
                                  expression: "inviterData.link_info.desc"
                                }
                              }),
                              _c(
                                "el-upload",
                                {
                                  class: [
                                    "inviterDescImg",
                                    _vm.inviterData.link_coverList.length === 0
                                      ? ""
                                      : "upload-empty"
                                  ],
                                  attrs: {
                                    action: "#",
                                    accept: ".png, .jpg, .jpeg",
                                    "http-request": _vm.requestCover,
                                    "on-remove": _vm.removeCover,
                                    "list-type": "picture-card",
                                    limit: 1,
                                    "file-list": _vm.inviterData.link_coverList
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-column flex-center",
                                      staticStyle: {
                                        "line-height": "22px",
                                        height: "100%"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-plus"
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "text q-info" },
                                        [_vm._v("链接封面")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "q-info",
                              staticStyle: {
                                "line-height": "1.6",
                                "font-size": "12px",
                                "margin-top": "5px"
                              }
                            },
                            [
                              _vm._v(
                                " 建议尺寸500*500px，JPG、PNG、JPEG格式，图片小于2MB。 "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "24px" },
                          attrs: {
                            type: "primary",
                            round: "",
                            icon: "iconfont icon-send-fill",
                            loading: _vm.btnLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitInvite("inviterData")
                            }
                          }
                        },
                        [_vm._v("立即群发")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "inviter-dialog__main-right" }, [
            _c("div", { staticClass: "mobile" }, [
              _c("div", { staticClass: "mobile-topbar" }, [
                _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
                _c("div", { staticClass: "flex" }, [
                  _c("i", {
                    staticClass: "iconfont icon-a-CellularConnection"
                  }),
                  _c("i", { staticClass: "iconfont icon-Wifi" }),
                  _c("i", { staticClass: "iconfont icon-Battery" })
                ])
              ]),
              _c("div", { staticClass: "mobile-titlebar" }, [
                _c("i", { staticClass: "el-icon-arrow-left" }),
                _c("div", { staticClass: "flex flex-column" }, [
                  _c("span", [_vm._v("张三")]),
                  _c("span", { staticStyle: { "font-size": "12px" } }, [
                    _vm._v("零一裂变（深圳）科技有限公司")
                  ])
                ]),
                _c("i", { staticClass: "el-icon-more" })
              ]),
              _c(
                "div",
                {
                  staticClass: "mobile-container",
                  staticStyle: { "max-height": "542px", overflow: "auto" }
                },
                [
                  _c("div", { staticClass: "mobile-contact" }, [
                    _c("div", { staticClass: "mobile-contact__avatar" }, [
                      _c("img", {
                        attrs: {
                          src: require("@assets/images/avatar.jpg"),
                          alt: ""
                        }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "mobile-contact__dialog" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: "",
                            resize: "none",
                            readonly: ""
                          },
                          model: {
                            value: _vm.inviterData.content,
                            callback: function($$v) {
                              _vm.$set(_vm.inviterData, "content", $$v)
                            },
                            expression: "inviterData.content"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "mobile-contact" }, [
                    _c("div", { staticClass: "mobile-contact__avatar" }, [
                      _c("img", {
                        attrs: {
                          src: require("@assets/images/avatar.jpg"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("div", { staticClass: "mobile-contact__dialog" }, [
                      _c("div", { staticClass: "link-preview" }, [
                        _c(
                          "div",
                          { staticClass: "ellipsis2 link-preview__title" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.inviterData.link_info.title) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-between flex-center link-preview__content"
                          },
                          [
                            _c("div", {
                              staticClass: "ellipsis2",
                              staticStyle: { flex: "1", "font-size": "14px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.inviterData.link_info.desc.replace(
                                    /[\n]/g,
                                    ""
                                  )
                                )
                              }
                            }),
                            _c("div", { staticClass: "link-preview__cover" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.inviterData.link_info.img_url
                                    ? _vm.inviterData.link_info.img_url
                                    : "https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png",
                                  alt: ""
                                }
                              })
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }