var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-table", {
    ref: "lyTable",
    attrs: {
      data: _vm.tableData,
      option: _vm.tableOpt,
      page: _vm.page,
      pageSize: _vm.per_page,
      total: _vm.total
    },
    on: {
      searchChange: _vm.searchChange,
      sizeChange: _vm.handleSizeChange,
      currentChange: _vm.handleCurrentChange,
      selectionChange: _vm.handleSelectionChange
    },
    scopedSlots: _vm._u([
      {
        key: "handler",
        fn: function() {
          return [
            _c(
              "span",
              { staticClass: "rd-count" },
              [
                _vm._v("共发放 " + _vm._s(_vm.total) + " 个红包"),
                _c("el-divider", { attrs: { direction: "vertical" } }),
                _c("span", { staticClass: "fail" }, [
                  _vm._v("失败 "),
                  _c("span", { staticStyle: { color: "#2b7cff" } }, [
                    _vm._v(_vm._s(_vm.sendFailNum))
                  ]),
                  _vm._v(" 个")
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "send_code_slot",
        fn: function(ref) {
          var row = ref.row
          return [
            row.send_code === 0
              ? _c("ly-status", { attrs: { type: "success" } }, [
                  _vm._v("发放成功")
                ])
              : _c(
                  "ly-status",
                  { attrs: { type: "danger" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content:
                            row.send_code === 1
                              ? "当前用户未实名"
                              : "异常错误，如果操作重新发放后，也无法正常发放，请联系平台客服",
                          placement: "top"
                        }
                      },
                      [_c("i", { staticClass: "el-icon-warning" })]
                    ),
                    _vm._v(" 发放失败 ")
                  ],
                  1
                )
          ]
        }
      },
      {
        key: "operation",
        fn: function(ref) {
          var row = ref.row
          return [
            _c(
              "el-button",
              {
                attrs: { type: "text", disabled: row.send_code === 0 },
                on: {
                  click: function($event) {
                    return _vm.handleReSend(row)
                  }
                }
              },
              [_vm._v("重新发放")]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }