<!--
 * @Author: zhan
 * @Date: 2022-05-19 15:38:51
 * @LastEditTime: 2022-06-03 14:34:44
 * @LastEditors: zhan
-->
<template>
  <div class="edit-footer">
    <el-button type="primary" round :loading="btnLoading" :icon="isEnd ? '' : 'iconfont icon-send-fill'"
      @click="$emit('submit')" :disabled="isEnd">{{ isEnd ? "活动已结束" : "发布活动" }}</el-button>
  </div>
</template>

<script>
export default {

  props: {
    btnLoading: {
      type: Boolean,
      default: false
    },
    isEnd: {
      type: Boolean,
      default: false
    }
  }
}
</script>
