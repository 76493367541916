<template>
  <ly-table
    ref="lyTable"
    :data="tableData"
    :option="tableOpt"
  >
  </ly-table>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { record } from '../http'
export default {
  mixins: [lyTable],
  props: ['rewardValue'],
  data () {
    return {
      IMMEDIATE: false
    }
  },
  computed: {
    tableOpt () {
      return {
        layout: 'hide',
        operation: 'hide',
        column: [
          {
            label: '红包金额',
            prop: 'total_amount'
          },
          {
            label: '红包到账金额',
            prop: 'amount'
          },
          {
            label: '服务费',
            prop: 'fee_amount',
            align: 'right'
          }
        ]
      }
    }
  },
  watch: {
    rewardValue: {
      handler () { this.getRedDetail() },
      immediate: true
    }
  },
  methods: {
    async getRedDetail () {
      const res = await record.redDetail({ reward_value: this.rewardValue })
      this.tableData = [res]
    }
  }
}
</script>
<style lang="scss" scoped>
.xl-ly-table{
  ::v-deep{
    .ly-table-main{
      margin-bottom: 0;
    }
  }
}
</style>
