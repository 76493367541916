<!--
 * @Author: zhanln
 * @Date: 2021-06-15 10:17:18
 * @LastEditTime: 2022-02-24 15:16:02
 * @LastEditors: zhanln
 * @Description: 自建应用手动配置
-->

<template>
  <div class="config page-warp">
    <div class="config-body">
      <el-button
        type="primary"
        round
        style="margin-right: 20px"
        @click="fn_jumpToLink"
        icon="iconfont icon-search"
        >查看配置指引</el-button
      >
      <el-popover trigger="hover" placement="right">
        <p :style="{ textAlign: 'center', margin: '0 0 10px 0' }">
          扫码添加客服好友
        </p>
        <img
          class="qr-code"
          src="~@assets/images/contact_me_qr.png"
          alt=""
          :style="{ width: '172px', height: '172px' }"
        />
        <el-button type="text" slot="reference">联系客服处理</el-button>
      </el-popover>
      <!-- <div class="config-body_item">
        <div class="config-body_title">自建应用配置信息</div>

        <div class="config-set_title">基本信息</div>
        <el-form :model="appForm" label-width="164px" class="config-form">
          <el-form-item label="自建应用AgentId" required>
            <div class="config-form_item">
              <div class="config-form_left">
                <el-input
                  v-model="appForm.app_agent_id"
                  :disabled="app_agent_id_read"
                  placeholder="请输入自建应用AgentId"
                  ref="app_agent_id_ref"
                ></el-input>
              </div>
              <div
                class="config-form_right"
                v-if="appForm.app_agent_id_status === 1"
              >
                <el-tooltip
                  effect="light"
                  content="配置异常，请点击修改按钮重新配置"
                  placement="top-start"
                >
                  <i class="el-icon-warning q-danger"></i>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="自建应用Secret" required>
            <div class="config-form_item">
              <div class="config-form_left">
                <el-input
                  v-model="appForm.app_secret"
                  :disabled="app_secret_read"
                  placeholder="请输入自建应用Secret"
                  ref="app_secret_ref"
                ></el-input>
              </div>
              <div
                class="config-form_right"
                v-if="appForm.app_secret_status === 1"
              >
                <el-tooltip
                  effect="light"
                  content="配置异常，请点击修改按钮重新配置"
                  placement="top-start"
                >
                  <i class="el-icon-warning q-danger"></i>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="fn_change(['app_agent_id', 'app_secret'])"
              round
              v-if="app_secret_read"
              >修改</el-button
            >
            <el-button
              @click="fn_close(['app_agent_id', 'app_secret'], true)"
              round
              v-if="!app_secret_read"
              >取消</el-button
            >
            <el-button
              type="primary"
              @click="fn_close(['app_agent_id', 'app_secret'])"
              round
              v-if="!app_secret_read"
              >保存</el-button
            >
          </el-form-item>
        </el-form>

        <div class="config-set_title">接收消息</div>
        <el-form :model="msgForm" label-width="164px" class="config-form">
          <el-form-item label="URL">
            <div class="config-form_item">
              <div class="config-form_left">
                <el-input v-model="msgForm.url" disabled></el-input>
              </div>
              <div class="config-form_right">
                <el-button
                  class="copy_url"
                  type="text"
                  :data-clipboard-text="msgForm.url"
                  @click="fn_copy('copy_url')"
                  >复制</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="Token">
            <div class="config-form_item">
              <div class="config-form_left">
                <el-input v-model="msgForm.token" disabled></el-input>
              </div>
              <div class="config-form_right">
                <el-button
                  class="copy_token"
                  type="text"
                  :data-clipboard-text="msgForm.token"
                  @click="fn_copy('copy_token')"
                  >复制</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="EncodingAESKey">
            <div class="config-form_item">
              <div class="config-form_left">
                <el-input v-model="msgForm.encodingAESKey" disabled></el-input>
              </div>
              <div class="config-form_right">
                <el-button
                  class="copy_encodingAESKey"
                  type="text"
                  :data-clipboard-text="msgForm.encodingAESKey"
                  @click="fn_copy('copy_encodingAESKey')"
                  >复制</el-button
                >
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div> -->
      <div class="config-body_item">
        <div class="config-body_title" style="margin-top: 40px">
          客户联系配置信息
        </div>
        <div class="q-info flex" style="margin-bottom: 32px">
          <i class="iconfont icon-warning-fill" style="margin-right: 4px"></i>
          配置成功后，可正常使用【企业标签】编辑/设置等相关权限
        </div>
        <!-- 客户联系 -->
        <el-form :model="appForm" label-width="164px" class="config-form">
          <el-form-item label="客户联系Secret" required>
            <div class="config-form_item">
              <div class="config-form_left">
                <el-input
                  v-model="appForm.internal_secret"
                  :disabled="internal_secret_read"
                  placeholder="请输入客户联系Secret"
                  ref="internal_secret_ref"
                ></el-input>
              </div>
              <div
                class="config-form_right"
                v-if="appForm.internal_secret_status === 1"
              >
                <el-tooltip
                  effect="light"
                  content="配置异常，请点击修改按钮重新配置"
                  placement="top-start"
                >
                  <i class="el-icon-warning q-danger"></i>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="fn_change(['internal_secret'])"
              round
              icon="iconfont icon-edit"
              v-if="internal_secret_read"
              >修改</el-button
            >
            <el-button
              @click="fn_close(['internal_secret'], true)"
              round
              icon="iconfont icon-close"
              v-if="!internal_secret_read"
              >取消</el-button
            >
            <el-button
              type="primary"
              @click="fn_close(['internal_secret'])"
              round
              icon="iconfont icon-selected"
              v-if="!internal_secret_read"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  name: 'config',
  data () {
    return {
      appForm: {
        app_agent_id: '', // 自建应用agentid
        app_agent_id_status: 0, // 自建应用agentid状态:0正常 1异常
        app_secret: '', // 自建应用secret
        app_secret_status: 0, // 自建应用secret状态:0正常 1异常
        internal_secret: '', // 客户联系secret
        internal_secret_status: 0 // 客户联系secret状态:0正常 1异常
      },
      msgForm: {
        url: '',
        token: '',
        encodingAESKey: ''
      },
      app_agent_id_read: true,
      app_secret_read: true,
      internal_secret_read: true,
      backUp: {
        app_agent_id: '', // 自建应用agentid
        app_agent_id_status: 0, // 自建应用agentid状态:0正常 1异常
        app_secret: '', // 自建应用secret
        app_secret_status: 0, // 自建应用secret状态:0正常 1异常
        internal_secret: '', // 客户联系secret
        internal_secret_status: 0 // 客户联系secret状态:0正常 1异常
      }
    }
  },
  created () {
    this.fn_getConfigInfo()
    this.fn_getCallbackUrl()
  },
  methods: {

    // 获取授权状态
    async fn_getAuthStatus (type = true) {
      const data = await this.axios.get('authStatus')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$store.commit('_SET', {
        'global.appConfig': data.err_status
      })
    },

    // 获取配置信息
    async fn_getConfigInfo () {
      const data = await this.axios.get('configInfo')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.appForm = {
        app_agent_id: data.app_agent_id,
        app_agent_id_status: data.agent_id_status,
        app_secret: data.app_secret,
        app_secret_status: data.secret_status,
        internal_secret: data.internal_secret,
        internal_secret_status: data.internal_secret_status
      }
    },

    // 获取回调url
    async fn_getCallbackUrl () {
      const data = await this.axios.get('getCallbackUrl')
      this.msgForm.url = data.url.trim()
      this.msgForm.token = data.token.trim()
      this.msgForm.encodingAESKey = data.aes_key.trim()
    },

    // 修改
    fn_change (key) {
      this.$refs[key[0] + '_ref'].focus()
      for (let i = 0; i < key.length; i++) {
        this[key[i] + '_read'] = false
        this.backUp[key[i]] = this.appForm[key[i]]
        this.backUp[key[i] + '_status'] = this.appForm[key[i] + '_status']
      }
    },

    // 关闭修改
    fn_close (key, isCancle) {
      if (isCancle) { // 取消
        for (let i = 0; i < key.length; i++) {
          this.appForm[key[i]] = this.backUp[key[i]]
          this.appForm[key[i] + '_status'] = this.backUp[key[i] + '_status']
          this[key[i] + '_read'] = true
        }
      } else { // 保存
        this.fn_setting(key)
      }
    },

    // 提交修改
    async fn_setting (key) {
      const params = {}

      // 非空判断
      for (let i = 0; i < key.length; i++) {
        if (this.appForm[key[i]] === '') {
          this.fn_lyMsg('warning', this.$refs[key[i] + '_ref'].$attrs.placeholder)
          return false
        }

        params[key[i]] = this.appForm[key[i]]
      }

      const data = await this.axios.post('appSetting', params)

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.appForm.app_agent_id_status = data.agent_id_check
      this.appForm.internal_secret_status = data.internal_check
      this.appForm.app_secret_status = data.secret_check

      // 如果有异常
      if (data.agent_id_check === 1) {
        this.fn_lyMsg('info', '自建应用 AgentId 异常')
      } else if (data.secret_check === 1) {
        this.fn_lyMsg('info', '自建应用 Secret 异常')
      } else if (data.internal_check === 1) {
        this.fn_lyMsg('info', '客户联系 Secret 异常')
      } else {
        this.fn_lyMsg('success', '修改成功')
        for (let i = 0; i < key.length; i++) {
          this[key[i] + '_read'] = true
        }

        this.fn_getAuthStatus()
      }
    },

    // 复制
    fn_copy (el) {
      const clipboard = new Clipboard(`.${el}`)
      clipboard.on('success', () => {
        clipboard.destroy()
        this.fn_lyMsg('success', '复制成功')
      })
      clipboard.on('error', (e) => {
        this.fn_lyMsg('error', '复制失败，请手动复制')
        console.error('E:', e)
      })
    },

    // 配置指引
    fn_jumpToLink () {
      window.open('https://www.yuque.com/docs/share/85f6c6a8-6b73-4921-b965-416de45f968c ', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.config {
  &-body {
    margin-bottom: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 16px;
    box-shadow: $--shadow;
    height: 100%;

    &_title {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      padding-left: 12px;
      color: $--color-text-primary;
      margin-bottom: 16px;
      margin-top: 32px;

      &::before {
        position: absolute;
        content: "";
        width: 4px;
        height: 17px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $--color-primary;
        border-radius: 2px;
      }
    }
  }

  &-set {
    &_title {
      padding-left: 12px;
      margin: 40px 0 24px 0;
      color: $--color-text-primary;
    }
  }

  &-form {
    &_item {
      display: flex;
    }

    &_left {
      width: 440px;
      margin-right: 16px;
    }

    &_right {
      display: flex;
      align-items: center;
      i {
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }

  &-btn {
    &_cancle {
      color: $--color-text-regular;
    }
  }

  ::v-deep .el-input__inner[disabled] {
    background-color: #f7f8fc;
  }
}
</style>
