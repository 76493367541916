var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "robots has-table" },
    [
      _c("ly-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total,
            expression: "total"
          }
        ],
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("el-image", {
                      staticClass: "create-avatar",
                      attrs: { src: row.avatar, fit: "cover" }
                    }),
                    _c("span", { staticClass: "robot-name" }, [
                      _vm._v(" " + _vm._s(row.name) + " ")
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.status === 2
                  ? _c("span", { staticClass: "q-success" }, [_vm._v("在线")])
                  : _c("span", { staticClass: "q-danger" }, [_vm._v("离线")])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }