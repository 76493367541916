/*
 * @Author: zhanln
 * @Date: 2021-06-24 10:39:36
 * @LastEditTime: 2022-01-21 10:54:00
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')
// 活动列表
export const activityList = {
  reqQuery: params => axios.get(`/wecom/redpacket-activity/list?${stringify(params)}`), // 查询表格数据的接口
  currentInfo: () => axios.get('/wecom/redpacket-activity/current-info'),
  activityStop: params => axios.post('/wecom/redpacket-activity/activity-stop', params),
  reqDelete: params => axios.post('/wecom/redpacket-activity/activity-del', params),
  checkTitle: params => axios.post('/wecom/redpacket-activity/has-same-name', params)
}
// 草稿箱
export const drafts = {
  reqQuery: params => axios.get(`/wecom/redpacket-activity/draft-list?${stringify(params)}`), // 查询表格数据的接口
  reqDelete: params => axios.post('/wecom/redpacket-activity/activity-del', params) // 删除行数据
}
// 参与用户
export const user = {
  reqQuery: params => axios.get(`/wecom/redpacket-activity/join-user-list?${stringify(params)}`),
  selectList: params => axios.get(`/wecom/redpacket-activity/sel-list?${stringify({ page: 1, per_page: 1000 })}`), // 选择活动列表
  addBlack: params => axios.post('/wecom/redpacket-activity/add-black-list', params), // 加入黑名单
  channelSel: params => axios.get(`/wecom/redpacket-activity/channel-sel?${stringify(params)}`), // 参与用户/黑名单 渠道下拉选择项
  blReqQuery: params => axios.get(`/wecom/redpacket-activity/join-black-list?${stringify(params)}`),
  export: params => axios.get(`/wecom/redpacket-activity/export-join-user?${stringify(params)}`),
  removeBlack: params => axios.post('/wecom/redpacket-activity/remove-black-list', params) // 移除黑名单
}
// 红包记录
export const record = {
  reqQuery: params => axios.get(`/wecom/redpacket-activity/packet-list?${stringify(params)}`),
  export: params => axios.get(`/wecom/redpacket-activity/export-packet?${stringify(params)}`), // 导出
  selectList: params => axios.get(`/wecom/redpacket-activity/sel-list?${stringify({ page: 1, per_page: 1000 })}`),
  packetSendAgain: params => axios.post('/wecom/redpacket-activity/packet-send-again', params) // 重新发放红包
}
// 渠道推广
export const popularize = {
  reqQuery: params => axios.get(`/wecom/redpacket-activity/channel-list?${stringify(params)}`),
  reqDelete: params => axios.post('/wecom/redpacket-activity/channel-del', params),
  channelCreate: params => axios.post('/wecom/redpacket-activity/channel-create', params),
  channelUpdate: params => axios.post('/wecom/redpacket-activity/channel-update', params),
  selectList: params => axios.get(`/wecom/redpacket-activity/sel-list?${stringify({ page: 1, per_page: 1000 })}`)
}
// 参与用户助力详情
export const userHelpDetail = {
  reqQuery: params => axios.get(`/wecom/redpacket-activity/join-detail-help?${stringify(params)}`),
  addBlack: params => axios.post('/wecom/redpacket-activity/add-black-list', params) // 加入黑名单
}
// 参与用户红包记录
export const userRedRecord = {
  reqQuery: params => axios.get(`/wecom/redpacket-activity/join-detail-packet?${stringify(params)}`),
  packetSendAgain: params => axios.post('/wecom/redpacket-activity/packet-send-again', params) // 重新发放红包
}

// 数据分析
export const analyse = {
  overview: params => axios.get(`/wecom/redpacket-activity/data-overview?${stringify(params)}`), // 数据概览
  level: params => axios.get(`/wecom/redpacket-activity/level-analyze?${stringify(params)}`), // 层级分析
  channel: params => axios.get(`/wecom/redpacket-activity/channel-analyze?${stringify(params)}`) // 层级分析
}
