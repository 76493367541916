/*
 * @Author: zhanln
 * @Date: 2022-03-28 14:55:30
 * @LastEditTime: 2022-04-21 16:55:30
 * @LastEditors: zhanln
 * @Description:
 */

import axios from '@/common/ajax.js'
const { stringify } = require('qs')

// 分组管理
export const groupApi = {
  groups: params => axios.post('/wecom/material/group/statistics', params), // 获取分组列表及统计数据（用于菜单栏）
  groupList: params => axios.post('/wecom/material/group/list', params), // 分组列表（用于编辑页下拉框）
  add: params => axios.post('/wecom/material/group/add', params), // 素材库创建分组
  edit: params => axios.post('/wecom/material/group/update-name', params), // 素材库修改分组名称
  del: params => axios.post('/wecom/material/group/del', params), // 素材库删除分组
  transfer: params => axios.post('/wecom/material/group/transfer', params) // 批量分组
}

// 素材分类
export const meterialApi = {
  reqQuery: params => axios.get(`/wecom/tasks/list/list?${stringify(params)}`),
  textData: params => axios.post('/wecom/material/item/list/text', params), // 获取文本素材列表
  static: params => axios.post('/wecom/material/statistics', params), // 获取各素材类型统计
  del: params => axios.post('/wecom/material/item/del', params), // 删除素材（批量）
  textDetail: params => axios.post('/wecom/material/item/detail/text', params), // 获取文本素材明细
  fileDetail: params => axios.post('/wecom/material/item/detail/file', params), // 获取文件素材明细
  appletDetail: params => axios.post('/wecom/material/item/detail/mini-program', params), // 获取小程序素材明细
  articleDetail: params => axios.post('/wecom/material/item/detail/article', params), // 获取文章素材明细
  vlogDetail: params => axios.post('/wecom/material/item/detail/channel', params) // 获取视频号素材明细
}

// 素材列表
export const listApi = {
  reqQuery: params => axios.post('/wecom/material/item/list/text', params) // 获取文本素材列表
}
export const fileApi = {
  reqQuery: params => axios.post('/wecom/material/item/list/file', params) // 获取文件素材列表
}
export const appletApi = {
  reqQuery: params => axios.post('/wecom/material/item/list/mini-program', params) // 获取小程序素材列表
}
export const articleApi = {
  reqQuery: params => axios.post('/wecom/material/item/list/article', params) // 获取文章素材列表
}
export const vlogApi = {
  reqQuery: params => axios.post('/wecom/material/item/list/channel', params) // 获取文章素材列表
}

// 保存素材
export const saveApi = {
  text: params => axios.post('/wecom/material/item/save/text', params), // 保存文本等素材
  file: params => axios.post('/wecom/material/item/save/file', params), // 保存文件等素材
  applet: params => axios.post('/wecom/material/item/save/mini-program', params), // 保存小程序素材
  article: params => axios.post('/wecom/material/item/save/article', params), // 保存文章素材
  vlog: params => axios.post('/wecom/material/item/save/channel', params) // 保存文章素材
}

// 消息记录
export const common = {
  messageRecord: params => axios.post('/wecom/robot/chat/message-record', params), // 获取好友聊天消息
  containsMini: params => axios.post('/wecom/robot/chat/client-list', params) // 聊天记录中的好友
}
