var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "从聊天记录中选择" + _vm.title,
        "append-to-body": "",
        visible: _vm.visible,
        width: "800px",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "div",
            { staticClass: "title" },
            [
              _vm._v(" 聊天好友 "),
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.fn_refresh }
                },
                [_vm._v("同步")]
              )
            ],
            1
          ),
          _c("el-input", {
            staticClass: "search-input",
            attrs: {
              clearable: "",
              placeholder: "请输入好友昵称，按回车搜索",
              "suffix-icon": "el-icon-search"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSearchByName($event)
              }
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _c(
            "el-scrollbar",
            { staticClass: "list" },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                {
                  key: item.external_user_id + "_" + item.user_id,
                  staticClass: "item",
                  class: {
                    active:
                      item.external_user_id === _vm.current.external_user_id &&
                      item.user_id === _vm.current.user_id
                  },
                  on: {
                    click: function($event) {
                      return _vm.getMessageRecord(item)
                    }
                  }
                },
                [
                  _c("el-avatar", {
                    attrs: { shape: "square", size: 32, src: item.avatar_url }
                  }),
                  _c("div", { staticClass: "temp" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          item.name +
                            " " +
                            (item.corp_name ? "| " + item.corp_name : "")
                        )
                      )
                    ]),
                    _c("span", { staticClass: "account" }, [
                      _vm._v(
                        "所属账号：" + _vm._s(item.wecom_user_name.join("，"))
                      )
                    ])
                  ])
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("包含" + _vm._s(_vm.title) + "的聊天记录")
        ]),
        _c(
          "div",
          { staticClass: "applet-list" },
          _vm._l(_vm.messageRecord, function(item) {
            return _c(
              "div",
              {
                key: item.msg_id,
                staticClass: "applet-item",
                class: { "by-robot": !item.is_send_by_client }
              },
              [
                item.is_send_by_client
                  ? _c(
                      "div",
                      { staticClass: "user" },
                      [
                        _c("el-avatar", {
                          attrs: {
                            shape: "square",
                            size: 32,
                            src: _vm.current.avatar_url
                          }
                        }),
                        _c("div", { staticClass: "temp" }, [
                          _c("span", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.current.name))
                          ]),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$day
                                  .unix(item.send_at)
                                  .format("YYYY年MM月DD日 HH:mm:ss")
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "user" },
                      [
                        _c("el-avatar", {
                          attrs: {
                            shape: "square",
                            size: 32,
                            src: _vm.robotInfo.avatar
                          }
                        }),
                        _c("div", { staticClass: "temp" }, [
                          _c("span", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.robotInfo.name))
                          ]),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$day
                                  .unix(item.send_at)
                                  .format("YYYY年MM月DD日 HH:mm:ss")
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                _vm.msg_type === 2013
                  ? _c("app-poster", {
                      attrs: { applet: item.content, cover: item.cover_image }
                    })
                  : _vm.msg_type === 2017
                  ? _c("vlog-poster", { attrs: { info: item.content } })
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleChoseApp(
                          item.content,
                          item.cover_image
                        )
                      }
                    }
                  },
                  [_vm._v("选择" + _vm._s(_vm.title))]
                )
              ],
              1
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }