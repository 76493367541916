var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "formRef",
      staticClass: "edit-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "110px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "素材名称：", prop: "name" } },
        [
          _c("el-input", {
            staticClass: "edit-form__input",
            attrs: {
              maxlength: "20",
              "show-word-limit": "",
              placeholder: "请输入素材名称"
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("template", { slot: "label" }, [
            _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
            _vm._v(" 分组：")
          ]),
          _c(
            "el-select",
            {
              staticClass: "edit-form__input",
              model: {
                value: _vm.form.group_id,
                callback: function($$v) {
                  _vm.$set(_vm.form, "group_id", $$v)
                },
                expression: "form.group_id"
              }
            },
            _vm._l(_vm.groupList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { label: "图片：", prop: "file_url" } },
        [
          _c("div", { staticClass: "q-info text-small" }, [
            _vm._v("支持JPG、PNG格式，图片小于10M")
          ]),
          _c("ly-upload", {
            attrs: {
              img: _vm.form.file_url,
              size: "10MB",
              tipIcon: "el-icon-plus"
            },
            on: { getImg: _vm.fn_getPoster }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }