var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-dialog",
    {
      staticClass: "add-dialog",
      attrs: {
        title: "新增群聊",
        visible: _vm.visible,
        setting: _vm.dialogSetting,
        btnSize: "medium"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        confirm: _vm.fn_confirm
      }
    },
    [
      _c(
        "div",
        { staticClass: "add-group" },
        [
          _c("ly-table", {
            ref: "lyTable",
            staticClass: "act-list",
            attrs: {
              data: _vm.tableData,
              option: _vm.tableOpt,
              page: _vm.page,
              "page-size": _vm.per_page,
              total: _vm.total
            },
            on: {
              searchChange: _vm.searchChange,
              sizeChange: _vm.handleSizeChange,
              sortChange: _vm.handleSortChange,
              currentChange: _vm.handleCurrentChange,
              selectionChange: _vm.handleSelectionChange
            },
            scopedSlots: _vm._u([
              {
                key: "countHeaderSlot",
                fn: function() {
                  return [
                    _c("span", { staticStyle: { "margin-right": "4px" } }, [
                      _vm._v("自动切群人数")
                    ]),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          placement: "top",
                          "popper-class": "q-tooltip"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              " 每个群入群人数达到「自动切换人数」后，自动切换下个群，"
                            ),
                            _c("br"),
                            _vm._v(" 当群的总人数达到190人后，不再参与分配。 ")
                          ]
                        ),
                        _c("i", { staticClass: "icon-info-fill iconfont" })
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "countSlot",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      class: ["number-small", !row.max_account ? "error" : ""],
                      attrs: {
                        "controls-position": "right",
                        min: 1,
                        max: 190,
                        size: "small"
                      },
                      model: {
                        value: row.max_account,
                        callback: function($$v) {
                          _vm.$set(row, "max_account", $$v)
                        },
                        expression: "row.max_account"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }