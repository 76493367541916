<!--
 * @Author: zhanln
 * @Date: 2021-07-26 10:31:23
 * @LastEditTime: 2022-08-05 17:43:38
 * @LastEditors: zhan
 * @Description: 活动信息
-->

<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>
    <div class="edit-card__content" v-loading="cardLoading">
      <el-form :model="basicForm" :rules="rules" ref="basicForm" label-width="125px" class="basic-form" size="medium">
        <el-form-item label="活动名称" prop="title" class="basic-form__item" :error="titleError">
          <el-input v-model.trim="basicForm.title" maxlength="16" show-word-limit placeholder="不对外展示，用户区分活动使用场景"
            @change="fn_setForm($event, 'title')"></el-input>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_at" class="basic-form__item">
          <el-date-picker type="datetime" placeholder="请选择时间" v-model.trim="basicForm.end_at" style="width: 320px"
            :picker-options="endDatePickerOptions" @change="fn_setForm($event, 'end_at')"></el-date-picker>
        </el-form-item>
        <el-form-item label="活动群设置" class="is-long basic-form__item" prop="msg_mode" required :error="groupError">
          <template v-if="hasCloudBean || showAutoSend">
            <el-radio-group v-model="basicForm.msg_mode" @change="fn_changeFissionType"
              :disabled="isEdit && !isCopy && !isDaft">
              <el-radio :label="2">普通消息模式</el-radio>
              <el-radio :label="1">智能消息模式</el-radio>
            </el-radio-group>
            <p class="q-bg text-small" style="line-height: 1.6">
              普通消息模式」：仅支持在群内发送“<b>入群欢迎语</b>”；
              <br />
              「智能消息模式」：支持在群内发送“<b>入群欢迎语</b>”、“<b>助力提醒</b>”、“<b>领奖通知</b>”。本模式需要将群内员工账号登录到云端，登录云端的账号会根据设置的规则自动发消息。<a
                @click="fn_jumpCloudPage" v-if="basicForm.msg_mode === 1 && this.hasCloudBean">如何登录云端?</a>
            </p>
          </template>
          <div style="margin-bottom: 12px">
            <el-button round type="primary" size="small" style="margin-right: 12px" @click="handleSelectGroup"
              :disabled="!hasCloudBean && basicForm.msg_mode === 1">选择群聊
            </el-button>
            <span>已选 {{ basicForm.group.length }} 个群</span>
          </div>
          <el-table :data="basicForm.group" class="group-table" max-height="380" empty-text="请选择群聊"
            v-loading="groupLoading">
            <el-table-column type="index" label="序号" width="68">
            </el-table-column>
            <el-table-column prop="name" label="群名称" min-width="80">
            </el-table-column>
            <el-table-column prop="owner_name" label="群主" min-width="110">
              <template slot-scope="scope">
                <ly-tag :content="scope.row.owner_name" :textSize="3"></ly-tag>
              </template>
            </el-table-column>
            <el-table-column prop="robots" label="云端账号" width="160" v-if="basicForm.msg_mode === 1">
              <template slot="header">
                云端账号
                <el-tooltip class="item" effect="dark" content="刷新云端账号状态" placement="top">
                  <i class="iconfont icon-shuaxin1" style="cursor: pointer" @click="fn_refresh"></i>
                </el-tooltip>
              </template>
              <template slot-scope="{ row }">
                <more-popover title="企业成员" :list="row.robots || []">
                  <template #reference>{{
                      `${getOnlineCount(row.robots)}人 `
                  }}</template>在线
                  <template #item="{ item }">
                    <div class="ly-team small">
                      <i class="el-icon-s-custom" style="font-size: 16px"></i>
                    </div>
                    <span class="m-group_name text-ellipsis">
                      {{ item.account_name }}
                      <span style="color: #00a86e" v-if="item.account_status">(在线)</span>
                      <span style="color: #909399" v-else>(离线)</span>
                    </span>
                  </template>
                </more-popover>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="自动切群人数" min-width="130">
              <template slot="header">
                <span style="margin-right: 4px">自动切群人数</span>
                <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
                  <div slot="content">
                    每个群入群人数达到「自动切换人数」后，自动切换下个群，<br />当群的总人数达到190人后，不再参与分配。
                  </div>
                  <i class="iconfont icon-info-fill"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.count" class="number-small number-mn" style="margin: 0" size="small"
                  :min="1" :max="190" controls-position="right"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80" class-name="operation-cell">
              <template slot-scope="scope">
                <el-dropdown class="el-dropdown-more" placement="bottom-start"
                  @command="(e) => fn_changeGroup(scope.$index, e, scope.row)">
                  <span class="el-dropdown-link"><i class="el-icon-more"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="scope.$index === 0" command="up">上移</el-dropdown-item>
                    <el-dropdown-item command="down" :disabled="scope.$index === basicForm.group.length - 1">下移
                    </el-dropdown-item>
                    <el-dropdown-item command="del">移除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item prop="wel_status" class="basic-form__item is-long" label="入群欢迎语" required>
          <div class="flex">
            <el-switch v-model="basicForm.wel_status" :active-value="1" :inactive-value="0"
              @change="fn_setForm($event, 'wel_status')"></el-switch>
            <span style="margin-left: 8px">{{
                basicForm.wel_status === 0 ? "关闭" : "开启"
            }}</span>
          </div>

          <div class="q-bg is-default" v-if="basicForm.msg_mode === 1 && basicForm.wel_status === 1">
            每隔
            <el-input-number v-model="basicForm.wel_gap" size="small" controls-position="right" :min="60" :max="3600"
              :precision="0" class="number-small number-mn" @change="fn_setForm($event, 'wel_gap')"></el-input-number>
            秒后发送欢迎语 <b>或</b> 进群
            <el-input-number v-model="basicForm.wel_user" size="small" controls-position="right" :min="2" :max="50"
              :precision="0" class="number-small number-mn" @change="fn_setForm($event, 'wel_user')"></el-input-number>
            人后发送欢迎语
            <div class="q-info text-small" style="margin-top: 8px">
              <i class="ri-information-line"></i>
              进群时间和进群人数同步计算，将优先触发满足条件的设置项
            </div>
          </div>

          <el-form-item :error="wordError" style="margin-bottom: 0; width: 60%">
            <ly-editor title="入群欢迎语" id="basicWel" :content="basicForm.wel_words" :recoverContent="initHtml"
              :error="wordError !== ''" emoji @getContent="fn_getWelWords" v-if="basicForm.wel_status === 1">
            </ly-editor>
            <div v-if="basicForm.wel_status === 1 && basicForm.msg_mode === 2">
              <el-tooltip class="item" effect="light" placement="right" popper-class="q-tooltip is-light">
                <div slot="content">
                  <div style="margin: 24px 24px 16px; width: 340px">
                    <img src="https://image.01lb.com.cn//uploads/wecom/21/0830/1630318814Z9wtpO5A.png" width="340"
                      alt="" />
                  </div>
                </div>

                <span style="color: #2b7cff"><i class="iconfont icon-info-fill"
                    style="margin-right: 5px"></i>发布活动后，记得在客户端配置欢迎语哦~</span>
              </el-tooltip>
            </div>
          </el-form-item>
        </el-form-item>

        <el-form-item label="入群链接" prop="link_type" class="basic-form__item">
          <el-radio-group v-model="basicForm.link_type" @change="fn_setForm($event, 'link_type')">
            <el-radio :label="1">默认</el-radio>
            <el-radio :label="2">自定义</el-radio>
          </el-radio-group>
          <template v-if="basicForm.link_type === 2">
            <el-form-item label="主图" label-width="80px" style="margin-top: 12px" :error="coverError">
              <template slot="label"><span class="q-danger">*</span> 主图
                <el-tooltip class="item" effect="light" placement="top" content="支持JPG、PNG格式，图片大小2M以内"
                  popper-class="q-tooltip is-light">
                  <i class="iconfont icon-info-fill"></i>
                </el-tooltip>
              </template>
              <el-upload action="#" accept=".png, .jpg, .jpeg" :http-request="requestContentImg"
                :on-remove="removeContentImg" list-type="picture-card" :limit="1" :file-list="basicForm.link_logo"
                :class="[
                  'upload',
                  basicForm.link_logo.length === 0 ? '' : 'upload-empty',
                ]">
                <div class="flex flex-column flex-center" style="line-height: 22px; height: 100%">
                  <i class="el-icon-plus" style="font-size: 20px"></i>
                  <span class="text q-info text-small">上传图片</span>
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item label="标题" prop="link_title" label-width="80px">
              <el-input v-model="basicForm.link_title" maxlength="20" show-word-limit placeholder="请输入链接标题"
                @change="fn_setForm($event, 'link_title')"></el-input>
            </el-form-item>

            <el-form-item label="描述" label-width="80px">
              <el-input v-model="basicForm.link_desc" maxlength="200" show-word-limit placeholder="请输入链接描述"
                @change="fn_setForm($event, 'link_desc')"></el-input>
            </el-form-item>
          </template>
        </el-form-item>
      </el-form>
    </div>

    <!-- 选择群弹框 -->
    <select-group ref="selectGroupRef" :selectList="basicForm.group" :baseSelect="currentGroupIds"
      @selectClose="fn_selectClose"></select-group>
  </div>
</template>

<script>
import lyTag from '@/components/lyTag'
import selectGroup from './selectGroup'
import lyEditor from '@/components/lyEditor'
import MorePopover from '@/components/MorePopover'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
import * as lyCheck from '@/common/checkSpecificKey'
import { activity } from '../http.js'
import { mapState } from 'vuex'
export default {
  name: 'basic',
  components: {
    lyTag,
    selectGroup,
    lyEditor,
    MorePopover
  },
  mixins: [qiniuUpload],

  props: {
    aid: {
      type: Number,
      defualt: 0
    },
    title: {
      type: String,
      defualt: () => {
        return ''
      }
    }
  },

  data () {
    const checkName = async (rule, value, callback) => {
      if (value !== null && value !== '') {
        const isSepcific = lyCheck.checkSpecificKey(value, ['！', '，'])
        if (!isSepcific) {
          callback(new Error('名称中请勿包含特殊字符'))
        } else {
          const isSame = await this.fn_checkTitleSame()
          if (!isSame) {
            callback(new Error('活动名称不允许重复'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('请输入活动名称'))
      }
    }
    return {
      COMM_HTTP: activity,
      cardLoading: true,
      basicForm: {
        title: '',
        end_at: null,
        msg_mode: 2,
        wel_status: 1,
        wel_gap: 120,
        wel_user: 5,
        wel_words:
          '欢迎加入群聊，参与活动即可免费领取奖品哦~[笑脸]\n\n❤️❤️【免费领取规则】：\n① 需邀请2人进群，即可领取A大礼包\n② 需邀请5人进群，即可领取B大礼包\n③ 需邀请8人进群，即可领取C大礼包\n👇点下方活动链接，生成专属海报！',
        group: [],
        link_type: 1,
        link_logo: [{
          url: 'https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png'
        }],
        link_title: '与我一起领取奖品👇',
        link_desc: '点击链接，领取福利'
      },
      titleError: '',
      coverError: '',
      rules: {
        title: [{ validator: checkName, required: true, trigger: 'change' }],
        end_at: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ],
        link_title: [
          { required: true, message: '请输入链接标题', trigger: 'change' }
        ],
        msg_mode: [
          { required: true, message: '请选择消息模式', trigger: 'change' }
        ],
        link_type: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        selectableRange: '00:00:00 - 23:59:59'
      },
      wordError: '',
      cusWordError: '',
      groupError: '',
      initHtml:
        '欢迎加入群聊，参与活动即可免费领取奖品哦~[笑脸]\n\n❤️❤️【免费领取规则】：\n① 需邀请2人进群，即可领取A大礼包\n② 需邀请5人进群，即可领取B大礼包\n③ 需邀请8人进群，即可领取C大礼包\n👇点下方活动链接，生成专属海报！',
      customer_initHtml:
        '#用户昵称# 你好，欢迎参加本次活动，我是你的专属小助手，有任何活动相关问题都可以咨询我哦~',
      groupLoading: true,
      unDataIds: '',
      idError: '',
      currentGroupIds: [],
      showAutoSend: false
    }
  },

  computed: {
    isEdit () {
      return this.$store.getters['groupfission/GET_TYPE']
    },
    isReady () {
      return this.$store.getters['groupfission/GET_READY']
    },
    isCopy () {
      return this.$store.state.groupfission.isCopy
    },
    isDaft () {
      return this.$store.state.groupfission.isDaft
    },
    ...mapState({
      overTimeStamp: state => state.buy.expiredTime,
      isSale: state => state.buy.isSale
    }),

    endDatePickerOptions () {
      const now = new Date()
      const today = new Date().setHours(0, 0, 0, 0)
      const pick = new Date(new Date(this.basicForm.end_at).setHours(0, 0, 0, 0)).getTime()
      let selectableRange = ''
      let rangeFirst = '00:00:00'
      let rangeLast = '23:59:59'
      // 时间段，今天时间前不可选
      if (pick === today) {
        rangeFirst = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
      }

      // 时间段，到期时间后不可选
      if (this.isSale && this.overTimeStamp) {
        const lastDay = new Date(new Date(this.overTimeStamp * 1000).setHours(0, 0, 0, 0)).getTime()
        if (pick === lastDay) {
          const lastDate = new Date(this.overTimeStamp * 1000)
          rangeLast = `${lastDate.getHours()}:${lastDate.getMinutes()}:${lastDate.getSeconds()}`
        }
      }

      selectableRange = `${rangeFirst} - ${rangeLast}`
      return {
        disabledDate: (time) => {
          if (this.isSale && this.overTimeStamp) {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 || time.getTime() > this.overTimeStamp * 1000
          } else {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          }
        },
        selectableRange
      }
    },
    hasCloudBean () {
      return this.$store.state.permission.hasCloudBean
    }
  },

  watch: {
    'basicForm.wel_status' (val) {
      if (!val) {
        this.wordError = ''
      }
    },
    'basicForm.wel_words' (val) {
      this.wordError = val ? '' : '请输入入群欢迎语'
    },
    'basicForm.customer_wel_come_msg' (val) {
      this.cusWordError = val ? '' : '请输入客服欢迎语'
    },
    async isReady (val) {
      if (val) {
        if (this.isEdit) {
          const basic = this.$store.getters['groupfission/GET_BASIC']
          this.basicForm = basic
          if (this.isCopy) {
            this.basicForm.end_at = this.fn_overTimeStamp()
            this.fn_setForm(this.basicForm.end_at, 'end_at')
          }
          if (!this.isCopy) {
            await this.fn_refresh()
          }
          if (this.basicForm.msg_mode === 1) {
            this.showAutoSend = true
          }
          setTimeout(() => {
            this.fn_setReady()
          }, 300)
          this.currentGroupIds = this.basicForm.group.map(item => +item.id)
        } else {
          this.basicForm.end_at = this.fn_overTimeStamp()
          this.fn_setForm(this.basicForm.end_at, 'end_at')
          this.fn_setReady()
        }
      }
    },
    unDataIds (val) {
      if (val) {
        this.groupError = `获取群聊数据异常，请确认该群是否已解散（如已解散请移除），列表序号：${val}`
      } else {
        this.groupError = ''
      }
    },
    overTimeStamp: {
      handler (val) {
        let _date = new Date()
        if (_date.toString().indexOf('GMT-08:00') > -1) {
          _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
        }

        const _maxTimeStamp = new Date(_date).getTime() + (7 * 24 * 60 * 60 * 1000)

        if (val * 1000 <= _maxTimeStamp) {
          this.basicForm.end_at = new Date(val * 1000)
        } else {
          this.basicForm.end_at = new Date(_maxTimeStamp)
        }
        this.fn_setForm(this.basicForm.end_at, 'end_at')
      },
      deep: true,
      immediate: true
    }
  },

  methods: {

    // 上传内容图片
    async requestContentImg (opts) {
      this.basicForm.link_logo = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.basicForm.link_logo = [{ url: path }]
          },
          null,
          {
            type: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
        )
      }

      this.fn_setForm(this.basicForm.link_logo, 'link_logo')
      this.fn_checkCover()
    },

    // 删除内容图片
    removeContentImg () {
      this.basicForm.link_logo = []
      this.fn_setForm([], 'link_logo')
      this.fn_checkCover()
    },

    fn_checkCover () {
      let check = true
      if (this.basicForm.link_logo.length <= 0) {
        this.coverError = '请上传链接主图'
        check = false
      } else {
        this.coverError = ''
      }
      return check
    },

    // 获取在线数量
    getOnlineCount (arr) {
      let temp = []
      if (arr) {
        temp = arr.filter(e => e.account_status)
      }
      return temp.length
    },
    fn_setReady () {
      this.cardLoading = false
      this.groupLoading = false
    },

    // 设置结束时间
    disabledDate (time) {
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date(_date.getTime() + (1 / 24) * 16 * 8.64e7)
      }
      const _minTime = _date.getTime()
      return time.getTime() < _minTime
    },

    // 初始结束时间
    fn_overTimeStamp () {
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date(_date.getTime() + (1 / 24) * 16 * 8.64e7)
      }

      const _maxTimeStamp = new Date(_date).getTime() + (7 * 24 * 60 * 60 * 1000)

      if (this.overTimeStamp && (this.overTimeStamp * 1000) <= _maxTimeStamp) {
        return this.$day(this.overTimeStamp * 1000).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return this.$day(_maxTimeStamp).format('YYYY-MM-DD HH:mm:ss')
      }
    },

    // 群裂变单选监听
    fn_changeFissionType (val) {
      this.basicForm.group = []
      this.$nextTick(() => {
        this.fn_setForm(val, 'msg_mode')
      })
    },

    handleSelectGroup () {
      this.$refs.basicForm.validateField('msg_mode', valid => {
        if (!valid) {
          this.$refs.selectGroupRef.type = this.basicForm.msg_mode
          this.$nextTick(() => {
            this.$refs.selectGroupRef.dialogVisible = true
          })
        }
      })
    },

    // 上传群码
    fn_changeCode (img, row) {
      row.code = img ? [{ url: img }] : []
      this.fn_checkGroup()
      this.fn_setForm(this.basicForm.group, 'group')
    },
    async requestQiniu (opts) {
      const row = this.basicForm.group.filter((item) => {
        return item.id === opts.data.id
      })[0]
      row.code = []
      const _varify = await isImg(opts, ['png', 'jpg'])
      if (!_varify) return

      // 判断图片类型是否正确
      await this.qiniuUpload2(
        opts.file,
        (path, id) => {
          row.code = [
            {
              name: 'pic-' + id,
              uid: id,
              url: path
            }
          ]
        },
        null,
        {
          type: ['jpg', 'png'],
          size: '2MB'
        }
      )
      this.fn_checkGroup()
      this.fn_setForm(this.basicForm.group, 'group')
    },

    // 移除群码
    removePoster (file, fileList, row) {
      row.code = []
      this.fn_setForm(this.basicForm.group, 'group')
    },

    // 裂变群操作
    fn_changeGroup (index, type, row) {
      // 上移
      if (type === 'up') {
        const upDate = this.basicForm.group[index - 1]
        this.basicForm.group.splice(index - 1, 1)
        this.basicForm.group.splice(index, 0, upDate)
      }

      // 下移
      if (type === 'down') {
        const upDate = this.basicForm.group[index + 1]
        this.basicForm.group.splice(index + 1, 1)
        this.basicForm.group.splice(index, 0, upDate)
      }

      this.fn_setForm(this.basicForm.group, 'group')

      // 移除
      if (type === 'del') {
        this.$lyConfirm({
          title: `是否移除群名为 { ${row.name} } 的群聊`,
          text: '',
          confirmButtonText: '确定'
        })
          .then(() => {
            this.basicForm.group.splice(index, 1)
            this.fn_setForm(this.basicForm.group, 'group')
            const ud = this.unDataIds.split(',')
            ud.splice(ud.indexOf(row.id), 1)
            this.unDataIds = ud.join(',')
            this.fn_checkGroup()
          })
          .catch(() => { })
      }
    },

    // 群聊设置
    fn_selectClose () {
      if (this.basicForm.group.length === 0) {
        this.groupError = '请选择群聊'
        this.fn_setForm([], 'group')
        return false
      }
      this.groupError = ''
      this.fn_refresh()
    },

    // 设置云端账号状态
    async fn_refresh () {
      if (this.basicForm.group.length === 0) return
      this.groupLoading = true
      const group = this.basicForm.group
      const groupIds = group.map((item) => {
        return item.id
      })

      const data = await this.COMM_HTTP.groupList({
        group_ids: groupIds.join(',')
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.groupLoading = false
        return false
      }

      if (!data) {
        this.fn_lyMsg('info', '无法获取选中的群聊信息，请重新选择')
        this.basicForm.group = []
        this.groupLoading = false
        this.fn_setForm(group, 'group')
        return false
      }

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < group.length; j++) {
          group[j].activity_group_id = group[j].activity_group_id || 0
          group[j].group_id = group[j].group_id || 0
          group[j].count = group[j].count || 190
          group[j].code = group[j].code || []
          group[j].sort = j
          if (data[i].group_id === group[j].id) {
            group[j].qrcode_type = this.basicForm.msg_mode === 1 && data[i].auto_create_qrcode ? 2 : 1
            group[j].robots = data[i].robot_account
            group[j].name = data[i].group_name || '群聊'
            group[j].owner_name = data[i].group_owner_name || '-'
          }
        }
      }

      const dataIds = data.map((item) => {
        return item.group_id
      })

      this.unDataIds = group
        .filter((item) => dataIds.indexOf(item.id) === -1)
        .map((item) => item.sort + 1)
        .join(',')

      if (this.unDataIds) {
        this.groupError = `获取群聊数据异常，请确认该群是否已解散（如已解散请移除），列表序号：${this.unDataIds}`
      } else {
        this.groupError = ''
      }

      setTimeout(() => {
        this.basicForm.group = group
        this.groupLoading = false
        this.fn_setForm(group, 'group')
      }, 200)
    },

    // 欢迎语
    fn_getWelWords (content) {
      if (this.isReady) {
        this.basicForm.wel_words = content
        this.fn_setForm(content, 'wel_words')
      }
    },
    // 欢迎语
    fn_getCusWelWords (content) {
      if (this.isReady) {
        this.basicForm.customer_wel_come_msg = content
        this.fn_setForm(content, 'customer_wel_come_msg')
      }
    },

    // 活动名称唯一
    async fn_checkTitleSame () {
      const form = {
        title: this.basicForm.title
      }
      if (this.isEdit && !this.isCopy) {
        form.filter_id = this.aid
      }
      const data = await this.COMM_HTTP.checkTitle(form)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.exist === 1) {
        return false
      }

      return true
    },

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: 'basic',
        name: type,
        val: val
      })
    },

    // 校验群聊数据
    fn_checkGroup () {
      let formValid = true

      // 判断是否选择了群
      if (this.basicForm.group.length === 0) {
        this.groupError = '请选择群聊'
        formValid = false
      } else {
        if (this.unDataIds) {
          formValid = false
          // } else {
          //   const noCode = []
          //   for (let i = 0; i < this.basicForm.group.length; i++) {
          //     if (
          //       this.basicForm.group[i].qrcode_type === 1 &&
          //       this.basicForm.group[i].code.length === 0
          //     ) {
          //       noCode.push(this.basicForm.group[i].sort + 1)
          //     }
          //   }

          //   if (noCode.length > 0) {
          //     this.groupError = `无云端账号在线（且是群主）的群请手动上传群码（列表序号：${noCode.join(
          //       ','
          //     )})`
          //     formValid = false
          //   } else {
          //     this.groupError = ''
          //   }
        }
      }

      return formValid
    },

    // 客户账号
    getEmpId (ids) {
      if (ids && ids.length > 0) {
        this.idError = ''
        this.basicForm.customer_service_user = ids.join(',')
        this.fn_setForm(ids.join(','), 'customer_service_user')
      } else {
        // this.idError = '请至少选择一个客服'
        this.basicForm.customer_service_user = ''
        this.fn_setForm('', 'customer_service_user')
      }
    },

    fn_checkTitle () {
      return new Promise((resolve, reject) => {
        this.$refs.basicForm.validateField('title', err => {
          resolve(err)
        })
      })
    },

    // 表单验证 type 1：发布 2：草稿箱 加入专属福利群，0元领奖品
    async verify (type) {
      const formName = 'basicForm'
      let formValid = true

      if (type === 2) {
        const checkTitle = await this.fn_checkTitle()
        formValid = checkTitle === ''
      } else {
        const checkForm = await this.$refs[formName].validate().catch(err => err)
        if (!checkForm) {
          formValid = false
        }

        const checkGroup = this.fn_checkGroup()
        if (!checkGroup) {
          formValid = false
        }

        // 如果开启了欢迎语，判断是否为空
        if (
          this.basicForm.wel_status === 1 &&
          this.basicForm.wel_words === ''
        ) {
          formValid = false
          this.wordError = '请输入入群欢迎语'
        }

        if (this.basicForm.link_type === 2) {
          const checkCover = this.fn_checkCover()
          formValid = formValid && checkCover
        }
      }
      return formValid
    },
    // 跳转
    fn_jumpCloudPage () {
      const routeData = this.$router.resolve({
        path: '/cloudService/account/list'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
@import "@/assets/scss/var.scss";

.icon-info-fill1 {
  color: #b4bbcc;
}

.group-table__item.is-error ::v-deep {
  .el-input__inner {
    border-color: #eaebf2 !important;
  }
}

.basic-form {
  width: 100%;

  &__item {
    width: 60%;
    min-width: 560px;

    &.is-long {
      width: 100%;
      max-width: 860px;
    }
  }

  ::v-deep .el-form-item.is-error {
    .group-table {
      border-top-color: $--color-danger;
    }
  }
}

.group-setting {
  margin-bottom: 0px !important;

  ::v-deep {
    .el-form-item__error {
      padding-top: 0;
    }
  }
}

.group-table {
  width: 100%;
  border-bottom: none;
  line-height: 1;

  &::before {
    background-color: #eaebf2;
  }

  ::v-deep .el-button--text {
    padding-top: 0;
    padding-bottom: 0;
  }

  ::v-deep .el-dropdown-more {
    color: #2b7cff;
    line-height: 32px;

    &:hover {
      color: #5ca2ff;
    }
  }

  ::v-deep .el-input-number .el-input__inner {
    border-color: #eaebf2 !important;
  }
}

.number-small {
  width: 120px;
  margin: 0 4px;

  ::v-deep .el-input__inner {
    padding-left: 4px;
    padding-right: 36px;
  }

  ::v-deep {

    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}

.number-mn {
  width: 100px;
}

.q-bg.is-default {
  border-radius: 8px;
  border: none;
  padding: 16px 20px;
  margin-bottom: 12px;
}

.q-bg {
  margin-top: 0;
  color: #6e788a;

  ::v-deep.el-icon-arrow-up,
  ::v-deep.el-icon-arrow-down {
    margin-right: 0;
  }

  a {
    color: #2b7cff;
    cursor: pointer;
  }
}

.upload {
  position: relative;
  line-height: 1;
  width: 75px;
  height: 75px;

  ::v-deep .el-upload-list__item {
    width: 75px;
    height: 75px;
    margin: 0;
    transition: none !important;
  }

  ::v-deep .el-upload-list {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  ::v-deep .el-upload--picture-card {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .el-upload-list__item-status-label,
  ::v-deep .el-icon-close-tip {
    display: none;
  }
}
</style>
