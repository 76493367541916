<!--
 * @Author: zhanln
 * @Date: 2021-10-11 14:55:25
 * @LastEditTime: 2021-12-08 10:18:23
 * @LastEditors: zhanln
 * @Description: 数据概览
-->
<template>
  <alys-card :title="title">
    <div class="alys-basic">
      <div class="alys-basic__item" v-for="(item, index) in items" :key="index">
        <div class="name">
          {{ item.name }}
          <el-tooltip :content="item.tips" placement="top">
            <i class="iconfont icon-info-fill"></i>
          </el-tooltip>
        </div>
        <div class="count">{{ item.count }}</div>
      </div>
    </div>
  </alys-card>
</template>

<script>
import alysCard from './card'
export default {
  name: 'alysBasic',

  props: {
    title: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      items: [
        {
          name: '参与用户',
          tips: '参与活动的所有用户数（包含新用户+老用户）',
          count: '-'
        },
        {
          name: '新增用户',
          tips: '参与活动的新增用户人数',
          count: '-'
        },
        {
          name: '留存用户',
          tips: '新增用户且未解除好友关系的用户数',
          count: '-'
        },
        {
          name: '留存率',
          tips: '留存率=留存用户/新增用户',
          count: '-'
        },
        {
          name: '任务完成率',
          tips: '完成任务用户/参与用户',
          count: '-'
        }
      ]
    }
  },

  components: {
    alysCard
  }
}
</script>

<style lang="scss" scoped>
.alys {
  &-basic {
    display: flex;
    margin: 0 -10px;

    &__item {
      width: 24%;
      margin: 0 10px;
      background-color: #f8fafd;
      border-radius: 18px;
      padding: 0 35px;
      height: 120px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .name {
        color: #6e788a;
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 22px;
      }

      .count {
        color: #131d24;
        font-size: 30px;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }
}
</style>
