<!--
 * @Author: zhanln
 * @Date: 2021-08-23 14:19:05
 * @LastEditTime: 2021-12-13 11:29:56
 * @LastEditors: zhanln
 * @Description: 活动表单卡片
-->

<template>
  <div class="act-card" :ref="refName">
    <div class="act-card__header">
      <div class="left">
        {{ title }}
      </div>

      <div class="right" v-if="$slots.right"></div>
    </div>
    <div class="act-card__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      defualt: () => {
        return ''
      }
    },
    refName: {
      type: String,
      defualt: () => {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.act {
  &-card {
    &__header {
      position: relative;
      padding: 0 20px;
      height: 56px;
      display: flex;
      align-items: center;
      font-weight: bold;
      box-sizing: border-box;

      .left {
        position: relative;
        line-height: 24px;
        font-size: 16px;
        padding-left: 12px;
        color: rgba(0, 0, 0, 0.85);

        &::before {
          position: absolute;
          content: "";
          top: 50%;
          left: 0;
          width: 4px;
          height: 16px;
          background-color: #2b7cff;
          border-radius: 2px;
          transform: translateY(-50%);
        }
      }
    }

    &__body {
      padding: 0 20px 20px;
    }
  }

  &-form {
    ::v-deep &__input {
      width: 320px;
    }

    ::v-deep &__editor {
      width: 478px;
    }

    ::v-deep &__info {
      line-height: 24px;
      margin-left: 24px;
    }

    ::v-deep .el-form-item__label {
      .icon-info-fill {
        color: #b4bbcc;
      }
    }
  }
}
</style>
