var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-dialog",
    {
      staticClass: "owner-dialog",
      attrs: {
        title: "转让群主",
        visible: _vm.visible,
        setting: _vm.dialogSetting
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.fn_open,
        confirm: _vm.fn_confirm
      }
    },
    [
      _c(
        "div",
        { staticClass: "q-info", staticStyle: { "margin-bottom": "16px" } },
        [
          _c("i", { staticClass: "iconfont icon-info-fill" }),
          _vm._v(
            " 仅可转让群主身份给同企业云端账号在线的成员，且成员身份完成实名认证 "
          )
        ]
      ),
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "owner-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          "page-size": _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange,
          rowClick: _vm.handleRowClick
        },
        scopedSlots: _vm._u([
          {
            key: "check",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("el-radio", {
                  attrs: { label: row.id, disabled: row.status === 0 },
                  model: {
                    value: _vm.radioId,
                    callback: function($$v) {
                      _vm.radioId = $$v
                    },
                    expression: "radioId"
                  }
                })
              ]
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.status === 1
                  ? _c("span", { staticClass: "q-success" }, [_vm._v("在线")])
                  : row.status === 0
                  ? _c("span", { staticClass: "q-warning" }, [_vm._v("离线")])
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }