var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ly-table page-warp" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          staticStyle: { padding: "0 20px" },
          attrs: { inline: true, size: "medium" }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "label-padding", attrs: { label: "选择活动：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: { filterable: "" },
                  on: { change: _vm.changeActivity },
                  model: {
                    value: _vm.activityId,
                    callback: function($$v) {
                      _vm.activityId = $$v
                    },
                    expression: "activityId"
                  }
                },
                _vm._l(_vm.activityList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "lydebounce",
                        rawName: "v-lydebounce",
                        value: ["button", _vm.fn_export],
                        expression: "['button', fn_export]"
                      }
                    ],
                    attrs: {
                      type: "primary",
                      round: "",
                      icon: "iconfont icon-download",
                      loading: _vm.exportLoading,
                      disabled: !_vm.tableData || _vm.tableData.length === 0
                    }
                  },
                  [_vm._v("导出数据")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "reward_title_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "flex" }, [
                  _c("img", {
                    staticStyle: { "margin-right": "8px" },
                    attrs: {
                      src: require("../../../assets/svg/reward.svg"),
                      alt: ""
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(row.reward_title))])
                ])
              ]
            }
          },
          {
            key: "step_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.step === 1 ? [_vm._v("一阶任务")] : _vm._e(),
                row.step === 2 ? [_vm._v("二阶任务")] : _vm._e(),
                row.step === 3 ? [_vm._v("三阶任务")] : _vm._e()
              ]
            }
          },
          {
            key: "reward_type_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.reward_type === 1
                  ? [_vm._v("文本")]
                  : row.reward_type === 2
                  ? [_vm._v("图片")]
                  : row.reward_type === 3
                  ? [_vm._v("链接")]
                  : row.reward_type === 4
                  ? [_vm._v("自定义")]
                  : row.reward_type === 5
                  ? [_vm._v("表单")]
                  : row.reward_type === 6
                  ? [_vm._v("兑换码")]
                  : _vm._e()
              ]
            }
          },
          {
            key: "nickname_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("lyTag", {
                  attrs: { content: row.nickname, avatar: row.avatar_url }
                })
              ]
            }
          },
          {
            key: "add_user_name_slot",
            fn: function(ref) {
              var row = ref.row
              return [_c("ly-drow", { attrs: { list: row.add_user_name } })]
            }
          },
          {
            key: "add_status_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.add_status
                  ? [
                      row.add_status === 1
                        ? _c(
                            "ly-status",
                            {
                              attrs: {
                                type: "success",
                                icon: "iconfont icon-success"
                              }
                            },
                            [_vm._v("已添加")]
                          )
                        : _c(
                            "ly-status",
                            {
                              attrs: {
                                type: "danger",
                                icon: "iconfont icon-close-fill"
                              }
                            },
                            [_vm._v("已删除")]
                          )
                    ]
                  : [_vm._v("-")]
              ]
            }
          },
          {
            key: "give_status_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.give_status === 1
                  ? [
                      _c(
                        "ly-status",
                        {
                          attrs: {
                            type: "danger",
                            icon: "iconfont icon-close-fill"
                          }
                        },
                        [_vm._v("未发放")]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "light",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "40px",
                                    "text-align": "center",
                                    "font-size": "14px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.reward_type === 6
                                          ? "兑换码不足"
                                          : "库存不足，请前往活动修改页面调整库存"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex flex-center" },
                                [
                                  row.reward_type === 6
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            round: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.fn_openCodeList(row)
                                            }
                                          }
                                        },
                                        [_vm._v("重新发送")]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            round: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateRewardStatus(row)
                                            }
                                          }
                                        },
                                        [_vm._v("重新发送")]
                                      )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-warning-fill" })
                        ]
                      )
                    ]
                  : row.give_status === 2
                  ? _c(
                      "ly-status",
                      {
                        attrs: {
                          type: "success",
                          icon: "iconfont icon-success"
                        }
                      },
                      [_vm._v("已发放")]
                    )
                  : [_vm._v(" - ")]
              ]
            }
          },
          {
            key: "get_status_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.get_status === 1
                  ? [_vm._v("未领取")]
                  : row.get_status === 2
                  ? [_vm._v("已领取")]
                  : row.get_status === 3
                  ? [_vm._v("未填写")]
                  : row.get_status === 4
                  ? [_vm._v("已填写")]
                  : [_vm._v(" - ")]
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                (row.reward_type === 6 || row.reward_type === 5) &&
                !row.reward_value
                  ? [_vm._v(" - ")]
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.getRewardDetail(row)
                          }
                        }
                      },
                      [_vm._v("查看奖品内容")]
                    )
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品内容",
            visible: _vm.dialogVisible,
            "custom-class": "q-dialog",
            width: "34%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "reward" }, [
            _vm.rewardType === 1 || _vm.rewardType === 6
              ? _c("div", { staticClass: "q-dialog-block" }, [
                  _vm._v(" " + _vm._s(_vm.rewardDetail) + " ")
                ])
              : _vm.rewardType === 2
              ? _c("div", { staticClass: "q-dialog__img" }, [
                  _c("div", { staticClass: "flex flex-center" }, [
                    _c("img", { attrs: { src: _vm.rewardDetail, alt: "" } })
                  ])
                ])
              : _vm.rewardType === 3
              ? _c("div", [_vm._v(" " + _vm._s(_vm.rewardDetail) + " ")])
              : _vm.rewardType === 4
              ? _c("div", {
                  staticClass: "q-dialog-block q-dialog__img",
                  domProps: { innerHTML: _vm._s(_vm.rewardDetail) }
                })
              : _vm.rewardType === 5
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.rewardLoading,
                        expression: "rewardLoading"
                      }
                    ],
                    staticClass: "reward-list"
                  },
                  _vm._l(_vm.rewardDetail, function(i, v) {
                    return _c(
                      "div",
                      { key: v, staticClass: "reward-list__item" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(i.data))
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(_vm._s(i.name))
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ]
      ),
      _c("code-list", {
        ref: "codeListRef",
        attrs: { rewardId: _vm.rewardId, userId: _vm.userId },
        on: { success: _vm.queryData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }