<!--
 * @Author: zhanln
 * @Date: 2021-06-25 15:17:30
 * @LastEditTime: 2021-12-31 18:05:07
 * @LastEditors: zhanln
 * @Description: 用户详情
-->

<template>
  <el-dialog
    title="详情"
    :visible.sync="visible"
    custom-class="q-dialog"
    width="848px"
    class="has-table"
  >
    <div class="user-info">
      <el-avatar :size="54" :src="userInfo.avatar_url"> </el-avatar>
      <div class="info">
        <span class="name">{{ userInfo.title || "-" }}</span>
        <i class="ly-sex iconfont icon-male" v-if="userInfo.sex === 1"></i>
        <i
          class="ly-sex iconfont icon-female"
          v-else-if="userInfo.sex === 2"
        ></i>
        <span class="ly-sex null" v-else>未设置性别</span>
      </div>
    </div>
    <el-tabs v-model="activeName" type="card" class="ly-tabs">
      <el-tab-pane label="TA的邀请" name="invite">
        <lnvitation
          :inviterId="userInfo.id"
          :activityId="activityId"
          ref="lnvitation"
        />
      </el-tab-pane>
      <el-tab-pane label="奖品记录" name="records">
        <prize :inviterId="userInfo.id" :activityId="activityId" ref="prize" />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import lnvitation from './lnvitation.vue'
import prize from './prize.vue'
export default {
  name: 'userDetail',
  props: {
    userInfo: {
      type: Object,
      default: () => (
        {
          avatar_url: '',
          title: '',
          sex: 0,
          id: null
        }
      )
    }
  },
  components: {
    lnvitation,
    prize
  },
  computed: {
    activityId () {
      return this.$parent.activityId
    }
  },
  data () {
    return {
      visible: false,
      activeName: 'invite'
    }
  },
  methods: {
    getData () {
      this.$nextTick(() => {
        this.$refs.lnvitation.queryData()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  margin-bottom: 24px;
  display: flex;
  .info {
    margin-left: 12px;
    padding-top: 5px;
    .name {
      display: block;
      line-height: 1;
      font-size: 18px;
      margin-bottom: 8px;
    }
  }
  .el-avatar {
    background: unset;
  }
}
::v-deep {
  .el-tabs__header {
    margin-bottom: 0px;
  }
  .xl-ly-setting .handler-left,
  .xl-ly-setting .handler-right {
    margin-bottom: 16px;
  }
}
</style>
