var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-table", {
    ref: "lyTable",
    staticClass: "page-warp",
    attrs: {
      data: _vm.tableData,
      option: _vm.tableOpt,
      page: _vm.page,
      pageSize: _vm.per_page,
      total: _vm.total
    },
    on: {
      searchChange: _vm.searchChange,
      sizeChange: _vm.handleSizeChange,
      sortChange: _vm.handleSortChange,
      currentChange: _vm.handleCurrentChange,
      selectionChange: _vm.handleSelectionChange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }