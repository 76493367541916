var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "聊天记录",
        visible: _vm.dialogVisible,
        width: "1100px",
        "destroy-on-close": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpen,
        close: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "imBox" },
        [
          _c("im", {
            ref: "im",
            attrs: { msgList: _vm.msgList },
            scopedSlots: _vm._u([
              {
                key: "im_header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "searchForm" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.form,
                              "label-width": "75px",
                              inline: true
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "消息内容：" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入消息内容" },
                                  model: {
                                    value: _vm.form.content,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "content", $$v)
                                    },
                                    expression: "form.content"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "消息类型：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "全部" },
                                    model: {
                                      value: _vm.form.msg_type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "msg_type", $$v)
                                      },
                                      expression: "form.msg_type"
                                    }
                                  },
                                  _vm._l(_vm.msgOption, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "消息时间：" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "daterange",
                                    "range-separator": "至",
                                    format: "yyyy-MM-dd",
                                    "default-time": ["00:00:00", "23:59:59"],
                                    "value-format": "timestamp",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    "picker-options": _vm.endAtOption
                                  },
                                  model: {
                                    value: _vm.date,
                                    callback: function($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn",
                                    attrs: {
                                      type: "primary",
                                      round: "",
                                      icon: "iconfont icon-search"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleQuery(1)
                                      }
                                    }
                                  },
                                  [_vm._v("查询")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn",
                                    attrs: {
                                      round: "",
                                      icon: "iconfont icon-data"
                                    },
                                    on: { click: _vm.handleReset }
                                  },
                                  [_vm._v("重置")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "im_footer",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "table-pagination-area ly-table__pagination"
                      },
                      [
                        _c("el-pagination", {
                          staticClass: "fixedPagination",
                          attrs: {
                            background: "",
                            layout: "total, prev, pager, next, jumper",
                            "page-size": _vm.per_page,
                            "current-page": _vm.page,
                            total: _vm.total
                          },
                          on: { "current-change": _vm.handleCurrentChange }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }