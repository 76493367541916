<!--
 * @Author: zhanln
 * @Date: 2021-11-01 17:18:32
 * @LastEditTime: 2022-01-07 15:16:43
 * @LastEditors: zhanln
 * @Description: 任务宝 - 活动编辑
-->

<template>
  <act-layout
    :cards="cards"
    ref="cards"
    @setKey="fn_setKey"
    v-loading="pageLoading"
  >
    <!-- 表单区域 -->
    <!-- 活动信息 -->
    <template #basic>
      <view-basic ref="basic"></view-basic>
    </template>

    <!-- 海报 -->
    <template #poster>
      <view-poster ref="poster"></view-poster>
    </template>

    <!-- 活动页设置 -->
    <template #setting>
      <view-setting ref="setting"></view-setting>
    </template>

    <!-- 任务设置 -->
    <template #reward>
      <view-reward ref="reward"></view-reward>
    </template>

    <!-- 消息通知 -->
    <template #notice>
      <view-notice ref="notice"></view-notice>
    </template>

    <!-- 高级防刷 -->
    <template #brush>
      <view-brush ref="brush" v-show="true"></view-brush>
    </template>

    <!-- 预览区域 -->
    <template #mobile>
      <mobi-basic v-if="tabKey === 'basic'"></mobi-basic>
      <mobi-poster v-if="tabKey === 'poster'"></mobi-poster>
      <mobi-reward
        v-if="tabKey === 'reward' || tabKey === 'setting' || tabKey === 'brush'"
        :tabKey="tabKey"
      ></mobi-reward>
      <mobi-notice v-if="tabKey === 'notice'"></mobi-notice>
    </template>

    <!-- footer -->
    <template #footer v-if="!pageLoading">
      <edit-footer @submit="fn_submit" :btnLoading="btnLoading"></edit-footer>
    </template>
  </act-layout>
</template>

<script>
// layout
import actLayout from '@/components/actLayout'
import editFooter from './activity/editFooter'
// forms
import viewBasic from './activity/basic'
import viewPoster from './activity/poster'
import viewSetting from './activity/setting'
import viewReward from './activity/reward'
import viewNotice from './activity/notice'
import viewBrush from './activity/preventBrush'

// mobiles
import mobiBasic from './mobile/basic'
import mobiPoster from './mobile/poster'
import mobiReward from './mobile/reward'
import mobiNotice from './mobile/notice'

// store
import { mapState } from 'vuex'
export default {
  name: 'taskEdit',

  data () {
    return {
      pageLoading: true,
      btnLoading: false,
      tabKey: '',
      cards: [
        {
          label: '活动信息',
          name: 'basic'
        },
        {
          label: '裂变海报',
          name: 'poster'
        },
        {
          label: '活动页设置',
          name: 'setting'
        },
        {
          label: '任务设置',
          name: 'reward'
        },
        {
          label: '消息通知',
          name: 'notice'
        },
        {
          label: '高级防刷',
          name: 'brush'
        }
      ],
      subForm: {},
      backComfirm: true,
      actId: null
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.task.isEdit,
      isCopy: state => state.task.isCopy
    })
  },

  async mounted () {
    // 判断是创建、编辑、复制
    const editId = this.$route.query.id
    if (editId) {
      this.$store.commit('task/SET_EDIT')
      const editType = this.$route.query.type
      if (editType === 'copy') {
        this.$store.commit('task/SET_COPY')
      }

      // 详情
      await this.fn_getAct(editId)
    } else {
      this.fn_ready()
    }
  },

  methods: {

    // 获取表单详情
    async fn_getAct (id) {
      const data = await this.axios.get('initTasks', {
        params: {
          id
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.actId = data.id

      this.$store.commit('task/SET_INFO', {
        type: 'actId',
        val: data.id
      })

      this.fn_ready(data)
    },

    // 通知各表单组件初始化
    fn_ready (data) {
      let basicForm = null
      let posterForm = null
      let settingForm = null
      let rewardForm = null
      let noticeForm = null
      let brushForm = null

      // 如果有传入详情
      if (data) {
        // 已结束
        if (data.status === 4 && !this.isCopy) {
          this.$store.state.task.isEnd = true
        }

        const {
          code_fail_date,
          code_fail_mode,
          end_at,
          friend_notice_text,
          friend_push_type,
          help_notice_text,
          help_push_type,
          new_user_tags,
          poster,
          reward_notice_text,
          reward_push_type,
          rule,
          show_title,
          steps,
          stop_type,
          tag_open,
          title,
          wecom_user_ids,
          welcome_msg,
          share_msg,
          open_info,
          open_over_info,
          over_rate,
          open_invite_limit,
          invite_second,
          invite_num
        } = data

        basicForm = {
          title,
          end_at,
          wecom_user_ids,
          code_fail_mode,
          code_fail_date,
          welcome_msg,
          tag_open,
          new_user_tags
        }

        posterForm = poster
        posterForm.share_msg = share_msg

        settingForm = {
          show_title,
          rule
        }

        rewardForm = {
          steps,
          stock: {
            stop_type
          }
        }

        noticeForm = {
          friend_notice_text,
          friend_push_type,
          help_notice_text,
          help_push_type,
          reward_notice_text,
          reward_push_type
        }

        brushForm = {
          open_info,
          open_over_info,
          over_rate,
          open_invite_limit,
          invite_second,
          invite_num
        }
      }

      // 活动信息
      this.$refs.basic.fn_setForm(basicForm)
      // 裂变海报
      this.$refs.poster.fn_setForm(posterForm)
      // 活动页设置
      this.$refs.setting.fn_setForm(settingForm)
      // 任务设置
      this.$refs.reward.fn_setForm(rewardForm)
      // 消息通知
      this.$refs.notice.fn_setForm(noticeForm)
      // 高级防刷
      this.$refs.brush.fn_setForm(brushForm)

      this.pageLoading = false
    },

    // 提交校验
    async fn_submit () {
      // 各表单组件校验回调，如果检验通过，返回的是该表单的form对象

      // 活动信息
      const basic = await this.$refs.basic.verify()
      if (!basic) {
        this.fn_formScroll(this.$refs.cards.$refs.basic[0].$el)
        return false
      }

      // 裂变海报
      const poster = this.$refs.poster.verify()
      if (!poster) {
        this.fn_formScroll(this.$refs.cards.$refs.poster[0].$el)
        return false
      }

      // 活动页设置
      const setting = this.$refs.setting.verify()
      if (!setting) {
        this.fn_formScroll(this.$refs.cards.$refs.setting[0].$el)
        return false
      }

      // 任务设置
      const reward = this.$refs.reward.verify()
      if (!reward) {
        this.fn_formScroll(this.$refs.cards.$refs.reward[0].$el)
        return false
      }

      // 任务设置
      const notice = this.$refs.notice.verify()
      if (!notice) {
        this.fn_formScroll(this.$refs.cards.$refs.notice[0].$el)
        return false
      }

      // 任务设置
      const brush = this.$refs.brush.verify()
      if (!brush) {
        this.fn_formScroll(this.$refs.cards.$refs.brush[0].$el)
        return false
      }

      this.subForm = { ...basic, ...setting, ...notice, ...reward.stock, ...brush }
      this.subForm.share_msg = poster.share_msg
      delete poster.share_msg
      this.subForm.poster = poster
      this.subForm.steps = reward.steps

      // 判断是创建还是编辑
      this.btnLoading = true
      if (this.isEdit && !this.isCopy) {
        this.fn_edit()
      } else {
        this.fn_create()
      }
    },

    // 创建活动
    async fn_create () {
      const data = await this.axios.post('initTasks', this.subForm)

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_errorSubmit(data.code, data.msg)
        this.btnLoading = false
        return false
      }

      this.backComfirm = false
      this.$router.push('list')
      this.$message.success('活动创建成功!')
    },

    // 修改活动
    async fn_edit () {
      this.subForm.id = this.actId
      const data = await this.axios.put('initTasks', this.subForm)

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_errorSubmit(data.code, data.msg)
        this.btnLoading = false
        return false
      }

      this.backComfirm = false
      this.$router.push('list')
      this.$message.success('活动修改成功!')
    },

    // 提交失败结果判断
    fn_errorSubmit (code, msg) {
      if (code === 206) {
        this.$store.state.buy.remainDay = 0
        this.fn_lyMsg('info', '应用已过期')
      } else if (code === 205) {
        this.$store.state.buy.remainDay = null
        this.fn_lyMsg('info', msg)
      } else {
        this.fn_lyMsg('info', msg)
      }
    },

    // 导航监听
    fn_setKey (key) {
      this.tabKey = key
    },

    // 滚动到表单报错位置
    fn_formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    // 重置缓存
    fn_resetForm () {
      this.$store.commit('task/RESET')
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          this.fn_resetForm()
          this.pageLoading = true
          next()
        }).catch(() => { })
      } else {
        this.fn_resetForm()
        this.pageLoading = true
        next()
      }
    }
  },

  components: {
    actLayout,
    editFooter,
    viewBasic,
    viewPoster,
    viewSetting,
    viewReward,
    viewNotice,
    viewBrush,
    mobiBasic,
    mobiPoster,
    mobiReward,
    mobiNotice
  }
}
</script>
