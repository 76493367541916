var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("alys-card", { attrs: { title: _vm.title } }, [
    _c(
      "div",
      { staticClass: "alys-basic" },
      _vm._l(_vm.items, function(item, index) {
        return _c("div", { key: index, staticClass: "alys-basic__item" }, [
          _c(
            "div",
            { staticClass: "name" },
            [
              _vm._v(" " + _vm._s(item.name) + " "),
              _c(
                "el-tooltip",
                { attrs: { content: item.tips, placement: "top" } },
                [_c("i", { staticClass: "iconfont icon-info-fill" })]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "count",
              class: [
                index === _vm.items.length - 1 &&
                item.count !== 0 &&
                item.count !== "-"
                  ? "price"
                  : ""
              ]
            },
            [_vm._v(" " + _vm._s(item.count) + " ")]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }