var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "agrDialog",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: _vm.width,
        top: _vm.dialogTop,
        "close-on-click-modal": _vm.closeOnModal,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: _vm.fn_close
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading"
            }
          ]
        },
        [
          _vm.type === "robots" ? _c("robots", { ref: "robotRef" }) : _vm._e(),
          _vm.type === "setting"
            ? _c("setting", {
                ref: "settingRef",
                on: {
                  close: function($event) {
                    _vm.visible = false
                  },
                  refresh: _vm.fn_refresh
                }
              })
            : _vm._e(),
          _vm.type === "account"
            ? _c("account", {
                ref: "accountRef",
                on: {
                  close: function($event) {
                    _vm.visible = false
                  },
                  refresh: _vm.fn_refresh
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }