var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-card" }, [
    _c("div", { staticClass: "edit-card__header" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.cardLoading,
            expression: "cardLoading"
          }
        ],
        staticClass: "edit-card__content"
      },
      [
        _c(
          "el-form",
          {
            staticStyle: { width: "60%", "min-width": "560px" },
            attrs: {
              model: _vm.prizeForm,
              "label-width": "125px",
              size: "medium"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "领奖路径" } },
              [
                _c(
                  "el-radio-group",
                  {
                    on: {
                      change: function($event) {
                        return _vm.fn_setForm($event, "type")
                      }
                    },
                    model: {
                      value: _vm.prizeForm.type,
                      callback: function($$v) {
                        _vm.$set(_vm.prizeForm, "type", $$v)
                      },
                      expression: "prizeForm.type"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("添加涨粉账号后领奖")
                    ]),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("完成任务后直接领奖")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.prizeForm.type === 1
              ? [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "领奖账号",
                        required: "",
                        error: _vm.idError
                      }
                    },
                    [
                      _c("ly-emp-btn", {
                        attrs: {
                          btnText: "选择领奖账号",
                          max: 100,
                          isReal: true,
                          datafilter: true,
                          checkList: _vm.prizeForm.ids
                        },
                        on: { getEmpId: _vm.fn_getEmpId }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "好友欢迎语",
                        required: "",
                        error: _vm.wordError
                      }
                    },
                    [
                      _c("ly-editor", {
                        attrs: {
                          title: "好友欢迎语",
                          id: "prizeWords",
                          content: _vm.prizeForm.words,
                          insetMenu: ["用户昵称"],
                          recoverContent: _vm.initHtml,
                          error: _vm.wordError !== ""
                        },
                        on: { getContent: _vm.fn_getWelWords }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "reward-form__item",
                      attrs: { label: "自动打标签" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { height: "36px" }
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            on: {
                              change: function($event) {
                                return _vm.fn_setForm($event, "tag_open")
                              }
                            },
                            model: {
                              value: _vm.prizeForm.tag_open,
                              callback: function($$v) {
                                _vm.$set(_vm.prizeForm, "tag_open", $$v)
                              },
                              expression: "prizeForm.tag_open"
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "q-info",
                              staticStyle: {
                                "line-height": "20px",
                                "margin-left": "10px"
                              }
                            },
                            [_vm._v("自动为裂变账号的新增用户打标签")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.prizeForm.tag_open,
                              expression: "prizeForm.tag_open"
                            }
                          ]
                        },
                        [
                          _c("tag-select-v2", {
                            attrs: { checked: _vm.prizeForm.tags },
                            on: { getTagId: _vm.getTagId }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }