<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>

    <div class="edit-card__content">
      <el-form
        :model="brushForm"
        label-width="60px"
        style="width: 100%; min-width: 560px"
        size="medium"
      >
        <!-- is_info 用户信息 ， is_over_info 助力，over_rate 比例， is_invite_limit invite_second invite_num_limit-->

        <!-- 实名制 -->
        <el-form-item>
          <el-checkbox
            v-model="real_name"
            :true-label="1"
            :false-label="0"
            disabled
          ></el-checkbox
          >未实名的用户，自动加入黑名单
        </el-form-item>

        <!-- 根据用户信息 -->
        <el-form-item>
          <el-checkbox
            v-model="brushForm.is_info"
            :true-label="1"
            :false-label="0"
            @change="fn_setBrush('is_info', $event)"
          ></el-checkbox
          >用户未设置头像、昵称，自动加入黑名单
        </el-form-item>

        <!-- 根据用户信息及邀请人数 -->
        <el-form-item>
          <el-checkbox
            v-model="brushForm.is_over_info"
            :true-label="1"
            :false-label="0"
            @change="fn_setBrush('is_over_info', $event)"
          ></el-checkbox
          >用户的助力好友超<el-input-number
            v-model="brushForm.over_rate"
            size="small"
            controls-position="right"
            :min="1"
            :max="100"
            :precision="0"
            class="number-small"
            @change="fn_setBrush('over_rate', $event)"
          ></el-input-number>
          %未设置头像、昵称，自动加入黑名单
        </el-form-item>

        <!-- 根据用户邀请速度 -->
        <el-form-item>
          <el-checkbox
            v-model="brushForm.is_invite_limit"
            :true-label="1"
            :false-label="0"
            @change="fn_setBrush('is_invite_limit', $event)"
          ></el-checkbox
          >用户在
          <el-input-number
            v-model="brushForm.invite_second"
            size="small"
            controls-position="right"
            :min="1"
            :max="100"
            :precision="0"
            class="number-small"
            @change="fn_setBrush('invite_second', $event)"
          ></el-input-number>
          秒内邀请
          <el-input-number
            v-model="brushForm.invite_num_limit"
            size="small"
            controls-position="right"
            :min="0"
            :max="100"
            :precision="0"
            class="number-small"
            @change="fn_setBrush('invite_num_limit', $event)"
          ></el-input-number>
          人，自动加入黑名单
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      defualt: ''
    }
  },

  data () {
    return {
      real_name: 1
    }
  },

  computed: {
    brushForm () {
      return this.$store.state.rede.brush
    }
  },

  methods: {

    // 设置活动信息
    fn_setBrush (type, data) {
      const BRUSH = this.$store.state.rede.brush
      BRUSH[type] = data
      this.$store.commit('_SET', {
        'rede.brush': BRUSH
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
@import "@/assets/scss/var.scss";

::v-deep .el-checkbox__inner {
  margin-right: 6px;
}

.number-small {
  width: 100px;
  margin: 0 6px;
  ::v-deep .el-input__inner {
    padding-left: 8px;
    padding-right: 36px;
  }
  ::v-deep {
    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}
</style>
