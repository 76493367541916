<!--
 * @Descripttion: 选择云端账号弹窗
 * @version:
 * @Author: lw
 * @Date: 2021-05-13 16:31:31
 * @LastEditTime: 2021-12-08 22:13:03
-->
<template>
  <el-dialog
    title="选择云端账号"
    custom-class="select-account"
    :visible.sync="visible"
    @open="handleOpen"
    width="635px"
  >
    <div class="temp-contain">
      <div class="left">
        <div class="list-search">
          <el-input
            v-model="filterKey"
            size="medium"
            placeholder="请输入员工名称"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
        <div class="title">云端账号</div>
        <div class="list">
          <template v-if="robotList.length === 0">
            <div class="el-table__empty-block">
              <span class="el-table__empty-text">暂无数据</span>
            </div>
          </template>
          <template v-else>
            <div class="list-block">
              <el-checkbox-group v-model="selected">
                <div
                  v-for="item in filteredList"
                  :key="item.id"
                  class="list-item"
                >
                  <div class="temp">
                    <el-avatar
                      :size="32"
                      :src="item.avatar"
                    ></el-avatar>
                    <span class="nick">{{ item.name }}</span>
                    <span class="online" v-if="item.status === 1">(在线)</span>
                    <span class="offline" v-else>(离线)</span>
                  </div>
                  <el-checkbox :label="item.id" :disabled="item.status !== 1"></el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </template>
        </div>
      </div>
      <div class="right">
        <div class="title">
          已选账号<span> ({{ selected.length }})</span>
          <el-button type="text" @click="handleClear">清空</el-button>
        </div>
        <div class="list">
          <template v-if="selected.length === 0">
            <div class="el-table__empty-block">
              <span class="el-table__empty-text">暂无数据</span>
            </div>
          </template>
          <template v-else>
            <div class="list-block">
              <div
                v-for="item in selectedList"
                :key="item.id"
                class="list-item"
              >
                <div class="temp">
                  <el-avatar
                    shape="square"
                    :size="32"
                    :src="item.avatar"
                  ></el-avatar>
                  <span class="nick">{{ item.name }}</span>
                </div>
                <i class="el-icon-close" @click="handleItemRemove(item)"></i>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        round
        type="primary"
        :disabled="!selected.length"
        size="medium"
        @click="handleSelectAccount"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { selectAccount } from '../http.js'
export default {
  props: ['initialData'],
  data () {
    return {
      COMM_HTTP: selectAccount,
      visible: false,
      name: '',
      robotList: [], // 账号列表
      filterKey: '', // 过滤条件
      selected: [] // 已选账号
    }
  },
  computed: {
    filteredList () {
      if (this.filterKey === '') {
        return this.robotList
      } else {
        return this.robotList.filter(
          (item) => item.name.indexOf(this.filterKey) > -1
        )
      }
    },
    selectedList () {
      return this.robotList.filter((item) => this.selected.includes(item.id))
    }
  },
  watch: {
    initialData (n) {
      this.selected = []
      n.map(({ id }) => {
        this.selected.push(id)
      })
    }
  },
  methods: {
    async handleOpen () {
      await this.getAccountList()
    },
    async getAccountList () {
      const res = await this.COMM_HTTP.robotlist()
      if (res && res.code === 0) {
        this.$message.error(res.msg)
        return false
      }
      this.robotList = res.list.sort((a, b) => {
        if (a.status === b.status) {
          return 0
        } else if (a.status === 0) {
          return 1
        } else if (a.status === 1) {
          return -1
        }
      })
    },
    handleItemRemove ({ id }) {
      this.selected = this.selected.filter(e => {
        return e !== id
      })
    },
    // 清空选择
    handleClear () {
      this.selected = []
    },
    // 保存选择的云端账号
    handleSelectAccount () {
      const tableData = [] // 记录表格数据
      const proList = []
      this.selectedList.map(async ({ id, name, avatar, status }) => {
        proList.push(
          this.COMM_HTTP.robotGroup({
            robot_admin_id: id,
            topic_id: -1,
            type: 1,
            page: 1,
            per_page: 1000
          })
        )
        tableData.push({ id, avatar, status, name, groupIds: [] })
      })
      Promise.all(proList).then(res => {
        res.map(({ list = [] }, i) => {
          const groupIds = []
          list.map(({ id }) => {
            if (!groupIds.includes(id)) {
              groupIds.push(id)
            }
          })
          tableData[i].groupIds = groupIds
        })
        this.$emit('submit', tableData)
        this.visible = false
      })
    },
    // 父组件删除
    handleRemove (id) {
      this.selected = this.selected.filter(e => e !== id)
      this.handleSelectAccount()
    }
  }
}
</script>
<style lang="scss" scoped>
.select-account {
  .temp-contain{
    border: 1px solid #EAEBF2;
    overflow: hidden;
    display: flex;
    height: 392px;
    border-radius: 8px;
  }
  .left,
  .right {
    flex: 1;
  }
  .left {
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    background: #F7F8FC;
    padding: 16px 0;
    .title {
      color: #212b36;
      margin-top: 10px;
      padding: 0px 20px;
    }
  }
  .list {
    flex: 1;
    overflow: hidden;
    .online {
      color: #48d848;
    }
    .offline {
      color: #EAEBF2;
    }
  }
  .list-block {
    height: 100%;
    overflow-y: scroll;
    .el-icon-close{
      cursor: pointer;
    }
  }
  .el-checkbox-group{
    font-size: 14px;
  }
  .list-search {
    padding: 0 20px;
  }
  .list-item {
    padding: 6px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover{
      background: #EAEBF2;
    }
    .temp {
      width: 150px;
      display: flex;
      flex: 1;
      align-items: center;
    }
    .nick {
      margin: 0 8px;
      max-width: 78px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .right {
    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    .title {
      color: #212b36;
      .el-button {
        float: right;
      }
    }
    .nick {
      max-width: 170px;
    }
  }
}
::v-deep {
  .el-checkbox__label {
    display: none;
  }
}
</style>
