<!--
 * @Descripttion: 红包记录页面
 * @version: 1.0.0
-->
<template>
  <div class="gf-record page-warp-bg">
    <div class="select-box">
      <label style="color: rgba(0, 0, 0, 0.85)">选择活动：</label>
      <el-select
        size="medium"
        v-model="activity_id"
        @change="handleChange"
        filterable
        placeholder="请选择活动"
      >
        <el-option
          v-for="item in activities"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-download"
          @click="handleExport"
          >导出数据</el-button
        >
      </template>

      <template #reward_title="{ row }">
        <div class="avatar-name">
          <img
            src="../../../assets/svg/reward.svg"
            alt=""
            style="margin-right: 8px"
          />
          <span class="name">{{ row.reward_title }}</span>
        </div>
      </template>

      <template #nickname="{ row }">
        <ly-tag :content="row.nickname" only>
          <i class="icon el-icon-s-custom ly-tag_icon" slot="left"></i>
        </ly-tag>
      </template>

      <template #group_status="{ row }">
        <ly-status type="success" v-if="row.group_status === 1">在群</ly-status>
        <ly-status type="danger" v-if="row.group_status === 2">退群</ly-status>
      </template>

      <template #add_status="{ row }">
        <ly-status type="success" v-if="row.add_status === 1">已添加</ly-status>
        <ly-status type="danger" v-else-if="row.add_status === 2"
          >已删除</ly-status
        >
        <span v-else>-</span>
      </template>
      <template #give_status="{ row }">
        <div v-if="row.give_status === 1" class="flex">
          <ly-status type="danger">未发放</ly-status>
          <el-popover
            class="item"
            placement="top"
            popper-class="q-tooltip"
            trigger="hover"
          >
            <div style="line-height: 40px; text-align: center; font-size: 14px">
              {{
                row.reward_type === 2
                  ? "兑换码不足"
                  : "库存不足，请前往活动修改页面调整库存"
              }}
            </div>
            <div class="flex flex-center">
              <el-button
                type="primary"
                size="small"
                round
                @click="handleResend(row)"
                v-if="row.reward_type === 2"
                >重新发送</el-button
              >
              <el-button
                type="primary"
                size="small"
                round
                @click="handleResend(row)"
                v-else
                >重新发送</el-button
              >
            </div>
            <i
              class="icon-warning-fill iconfont"
              slot="reference"
              style="margin-left: 5px"
            ></i>
          </el-popover>
        </div>
        <ly-status type="success" v-else-if="row.give_status === 2"
          >已发放</ly-status
        >
        <template v-else> - </template>
      </template>

      <template #add_user_name="{ row }">
        <ly-drow :list="row.add_user_name"> </ly-drow>
      </template>
      <template #operation="{ row }">
        <template v-if="row.reward_type === 2 && !row.reward_value">
          -
        </template>
        <el-button type="text" size="small" @click="getRewardDetail(row)" v-else
          >查看奖品内容</el-button
        >
      </template>
    </ly-table>
    <resend ref="resend"></resend>
    <!-- dialog 奖品内容 -->
    <!-- // 奖励类型 1-自定义，2-兑换码，3-表单，4-核销码 -->
    <el-dialog
      title="奖品内容"
      :visible.sync="dialogVisible"
      custom-class="q-dialog"
      width="34%"
    >
      <div class="reward">
        <div
          class="q-dialog-block q-dialog__img"
          v-if="rewardInfo.reward_type === 1"
        >
          <div v-html="rewardInfo.reward_detail"></div>
          <img :src="rewardInfo.reward_detail_img" class="reward-img" />
        </div>
        <div class="q-dialog-block" v-else-if="rewardInfo.reward_type === 2">
          <div class="reward-code">{{ rewardInfo.reward_value }}</div>
        </div>
        <div
          class="reward-list"
          v-loading="rewardLoading"
          v-else-if="rewardInfo.reward_type === 3"
        >
          <div
            class="reward-list__item"
            v-for="(i, v) in rewardDetail"
            :key="v"
          >
            <div class="title">{{ i.name }}</div>
            <div class="val">{{ i.data }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { record, exchange } from './http.js'
import lyDrow from '@/components/lyDrow'
import lyTag from '@/components/lyTag'
import Resend from './components/Resend.vue'
export default {
  mixins: [lyTable],
  components: {
    lyDrow,
    lyTag,
    Resend
  },
  data () {
    return {
      COMM_HTTP: record,
      activities: [],
      activity_id: '',
      activity: {
        activity_name: ''
      },
      exportLoading: false,
      rewardInfo: {},
      rewardLoading: true,
      rewardDetail: null,
      dialogVisible: false
    }
  },
  provide () {
    return { activity: this.activity }
  },
  computed: {
    tableOpt () {
      return {
        scrollEl: '.page-warp-bg',
        operationWidth: 160,
        column: [
          {
            label: '奖品名称',
            prop: 'reward_title',
            search: true,
            slot: 'reward_title',
            showOverflowTooltip: true,
            columnProps: {
              minWidth: 140
            }
          },
          {
            label: '任务阶段',
            prop: 'step',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: 0 },
              { label: '一阶任务', value: 1 },
              { label: '二阶任务', value: 2 },
              { label: '三阶任务', value: 3 }
            ],
            columnProps: {
              minWidth: 120
            },
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '一阶任务'
              } else if (cellValue === 2) {
                return '二阶任务'
              } else if (cellValue === 3) {
                return '三阶任务'
              }
            }
          },
          {
            label: '奖品类型',
            prop: 'reward_type', // （ 1-自定义，2-兑换码，3-表单，4-核销码）
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '自定义'
              } else if (cellValue === 2) {
                return '兑换码'
              } else if (cellValue === 3) {
                return '表单'
              } else if (cellValue === 4) {
                return '核销码'
              }
            },
            columnProps: {
              minWidth: 100
            }
          },
          {
            label: '领取客户',
            prop: 'nickname',
            search: true,
            slot: 'nickname',
            columnProps: {
              minWidth: 120
            }
          },
          {
            label: '完成任务时间',
            prop: 'finish_date',
            key: ['give_start', 'give_end'],
            search: true,
            type: 'daterange',
            columnProps: {
              minWidth: 140
            }
          },
          {
            label: '所在群聊',
            prop: 'group_name',
            columnProps: {
              minWidth: 100
            }
          },
          {
            label: '群内状态',
            prop: 'group_status',
            slot: 'group_status',
            columnProps: {
              minWidth: 100
            }
          },
          {
            label: '添加账号',
            prop: 'add_user_name',
            slot: 'add_user_name',
            width: 220
          },
          {
            label: '添加状态',
            prop: 'add_status',
            slot: 'add_status',
            type: 'select',
            search: true,
            source: [
              { label: '全部', value: null },
              { label: '已添加', value: 1 },
              { label: '已删除', value: 2 }
            ],
            columnProps: {
              minWidth: 100
            }
          },
          {
            label: '发放状态',
            prop: 'give_status',
            slot: 'give_status',
            type: 'select',
            search: true,
            source: [
              { label: '全部', value: null },
              { label: '未发送', value: 1 },
              { label: '已发送', value: 2 }
            ],
            columnProps: {
              minWidth: 120
            }
          },
          {
            label: '奖品状态',
            prop: 'get_status',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: null },
              { label: '未领取', value: 1 },
              { label: '已领取', value: 2 },
              { label: '未填写', value: 3 },
              { label: '已填写', value: 4 }
            ],
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '未领取'
              } else if (cellValue === 2) {
                return '已领取'
              } else if (cellValue === 3) {
                return '未填写'
              } else if (cellValue === 4) {
                return '已填写'
              } else {
                return '-'
              }
            },
            columnProps: {
              minWidth: 100
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.activity_id = this.$route.query.id * 1
    this.getActsOps()
  },
  methods: {
    // 获取活动选项
    getActsOps () {
      this.COMM_HTTP.selectList().then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.activities = res.list || []
        this.activity.activity_name = this.getActName()
      })
    },
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activity_id
      }
    },
    /**
     * @description: 查看奖品详情
     * @param {*} data
     * @return {*}
     */
    async getRewardDetail (data) {
      // 奖励类型 1-自定义，2-兑换码，3-表单，4-核销码
      this.rewardInfo = data
      if (data.reward_type === 3) {
        await this.fn_getFormReward(data)
      }
      this.dialogVisible = true
    },

    // 表单内容
    async fn_getFormReward (data) {
      this.rewardLoading = true
      const res = await this.COMM_HTTP.getOneDetail(
        {
          formId: data.reward_type_id + '',
          requestId: data.reward_value + ''
        }
      )

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.rewardDetail = res
      this.rewardLoading = false
    },

    /**
     * @description: 下载回调
     * @param {*}
     * @return {*}
     */
    async handleExport () {
      this.exportLoading = true
      const params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqExport({
        page: 1,
        per_page: 9999,
        ...params
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        this.exportLoading = false
        return false
      }

      this.handleDownload(res.url)
      this.exportLoading = false
    },
    /**
     * @description: 获取活动名称
     * @param {*}
     * @return {*}
     */
    getActName () {
      const _obj = this.activities.find(n => {
        return n.id === this.activity_id
      })
      return _obj.title
    },
    // 获取Excel名称
    getFileName () {
      let _name = ''
      let actName = ''
      if (!this.activity.activity_name) {
        actName = this.getActName()
      } else {
        actName = this.activity.activity_name
      }
      _name = `[${actName}]-奖品记录-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },
    /**
     * @description: 下载url
     * @param {*} url
     * @return {*}
     */
    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },
    /**
     * @description: 改变活动
     * @param {*}
     * @return {*}
     */
    handleChange () {
      this.$router.replace({ path: this.$route.path, query: { id: this.activity_id } })
      this.activity.activity_name = this.getActName()
      this.$nextTick(() => {
        this.queryData()
      })
    },
    /**
     * @description: 打开重发弹窗
     * @param {*}
     * @return {*}
     */
    async handleResend ({ id, reward_type_id, reward_type }) {
      if (reward_type === 2) {
        this.$refs.resend.code_id = reward_type_id
        this.$refs.resend.id = id
        this.$refs.resend.visible = true
      } else {
        const res = await exchange.resendReward({
          id
        })

        if ((res && res.code) || (res && res.code === 0)) {
          this.fn_lyMsg('info', res.msg)
          return false
        }

        this.fn_lyMsg('success', '发放成功！')
        this.queryData()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.gf-record {
  display: flex;
  flex-direction: column;
  .select-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    .el-select {
      width: 353px;
    }
  }
  .el-avatar {
    background: transparent;
  }
  ::v-deep {
    .send-succ {
      color: #52c41a;
    }
    .send-succ,
    .send-error {
      position: relative;
      padding-left: 15px;
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: #52c41a;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
    .send-error {
      &::before {
        background: #ed5c49;
      }
      .el-popover__reference {
        color: #ed5c49;
      }
    }
    .el-icon-warning {
      font-size: 16px;
      color: #faad14;
    }
    .q-dialog-block {
      p {
        margin: 0;
      }
    }
  }
}
.resend {
  text-align: center;
  .desc {
    margin: 10px 0;
  }
  .el-icon-warning {
    margin-right: 5px;
  }
}
// .reward-code{
//   text-align: center;
//   font-size: 18px;
// }
.reward-img {
  display: block;
  margin: 15px auto 0;
}
.reward {
  max-height: 50vh;
  overflow: auto;

  &-list {
    &__item {
      padding: 14px 0;
      border-bottom: 1px solid #f0f1f2;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }

      .title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .val {
        color: #6e6f73;
      }
    }
  }
}
</style>
