var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "welcome-word-edit" }, [
    _c("div", { staticClass: "main-area ly-shadow" }, [
      _c(
        "div",
        { staticClass: "form-area" },
        [
          _c("el-scrollbar", [
            _c(
              "div",
              { staticStyle: { "padding-right": "15px" } },
              [
                _c("div", { staticClass: "top-tips" }, [
                  _c("p", [
                    _vm._v(
                      " 1. 用户在企微官方后台设置了欢迎语，不会推送此处设置的欢迎语。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2. 用户通过渠道码添加成员，不会推送此处设置的欢迎语，只推送在渠道活码处设置的欢迎语"
                    ),
                    _c("br"),
                    _vm._v(
                      " 3. 同一成员在此设置多条欢迎语，只推送最近更新的欢迎语 "
                    )
                  ])
                ]),
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      size: "medium",
                      model: _vm.form,
                      "label-width": "120px",
                      rules: _vm.rules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "欢迎语名称：",
                          prop: "welcomeContentName"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "10",
                            "show-word-limit": "",
                            placeholder: "名称不会向用户展示"
                          },
                          model: {
                            value: _vm.form.welcomeContentName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "welcomeContentName", $$v)
                            },
                            expression: "form.welcomeContentName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "使用成员：", prop: "userList" } },
                      [
                        _c("ly-emp-btn", {
                          attrs: {
                            btnText: "添加成员账号",
                            max: 100,
                            isReal: true,
                            datafilter: true,
                            checkList: _vm.checkProp
                          },
                          on: { getEmpIdArr: _vm.handleGetEmpId }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        ref: "task_content",
                        attrs: { label: "欢迎语内容：", prop: "attachmentList" }
                      },
                      [
                        _c("div", { staticClass: "desc" }, [
                          _vm._v("最多支持发送10条消息，其中文本消息限1条")
                        ]),
                        _c(
                          "div",
                          { staticClass: "msg-list" },
                          _vm._l(_vm.form.attachmentList, function(item, i) {
                            return _c("msg-template", {
                              key: item.temp,
                              class: "type" + item.messageType,
                              attrs: {
                                title:
                                  "消息" +
                                  (i + 1) +
                                  "：" +
                                  _vm.getMsgType(item.messageType),
                                msg: item,
                                disabled: _vm.getDisabledStatus(i)
                              },
                              on: { change: _vm.handleAction }
                            })
                          }),
                          1
                        ),
                        this.form.attachmentList.length < 10
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "16px" },
                                attrs: {
                                  type: "primary",
                                  round: "",
                                  icon: "iconfont icon-plus"
                                },
                                on: { click: _vm.handleAddContent }
                              },
                              [_vm._v("添加消息")]
                            )
                          : _vm._e(),
                        _c("add-content", {
                          ref: "addContent",
                          attrs: { hasText: _vm.hasText },
                          on: { push: _vm.handlePushMsg }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h5-area" },
        [
          _c(
            "el-scrollbar",
            [_c("mobile", { attrs: { msg: _vm.form.attachmentList } })],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "submit-area" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", round: "", icon: "iconfont icon-save" },
            on: { click: _vm.handleSubmit }
          },
          [_vm._v("保存欢迎语")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }