<!--
 * @Author: zhanln
 * @Date: 2021-11-02 18:54:52
 * @LastEditTime: 2022-01-13 16:13:35
 * @LastEditors: zhanln
 * @Description: 裂变海报
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="160px"
    size="medium"
    v-loading="cardLoading"
  >
    <!-- 上传海报 -->
    <el-form-item label="上传海报：" prop="img_url">
      <div class="flex">
        <ly-upload
          :img="form.img_url"
          @getImg="fn_getPoster"
          tipText="上传海报"
        ></ly-upload>
        <div class="q-info text-small act-form__info" style="flex: 1">
          海报设计须知：<br />
          (1) 建议图片尺寸：750px*1334px，分辨率72<br />
          (2) 支持JPG、PNG格式，图片大小2M以内 <br />
          (3)
          开启【用户头像】【用户昵称】【二维码】，可在预览区域内移动展示位置。
        </div>
      </div>
    </el-form-item>

    <!-- 用户头像 -->
    <el-form-item label="用户头像：" prop="avatar_sharp">
      <div class="flex" style="height: 36px">
        <el-switch
          v-model="form.avatar_status"
          :active-value="1"
          :inactive-value="0"
          style="margin-right: 24px"
        ></el-switch>
        <el-radio-group
          v-if="form.avatar_status === 1"
          v-model="form.avatar_sharp"
          style="position: relative; top: 1px"
        >
          <el-radio :label="2">圆形</el-radio>
          <el-radio :label="1">方形</el-radio>
        </el-radio-group>
      </div>
    </el-form-item>

    <!-- 用户昵称     -->
    <el-form-item label="用户昵称：" prop="nickname_status">
      <div class="flex" style="height: 36px">
        <el-switch
          v-model="form.nickname_status"
          :active-value="1"
          :inactive-value="0"
          style="margin-right: 24px"
        ></el-switch>
        <template v-if="form.nickname_status">
          <span style="display: inline-flex; margin-right: 24px">
            颜色：<el-color-picker
              v-model="form.nickname_color"
              class="color-pick"
            ></el-color-picker>
          </span>
          <div style="width: 65px">
            <el-slider
              v-model="form.nickname_size"
              :min="10"
              :max="36"
              :format-tooltip="formatTooltip"
              style="flex: 1"
            ></el-slider>
          </div>
          <div style="margin: 0 24px 0 8px">{{ form.nickname_size }}px</div>
          <div>
            <el-button-group class="nickname-align__btn">
              <el-button
                size="small"
                :type="form.nickname_align === 'left' ? 'primary' : ''"
                @click="form.nickname_align = 'left'"
                >左对齐</el-button
              >
              <el-button
                size="small"
                :type="form.nickname_align === 'center' ? 'primary' : ''"
                @click="form.nickname_align = 'center'"
                >居中</el-button
              >
              <el-button
                size="small"
                :type="form.nickname_align === 'right' ? 'primary' : ''"
                @click="form.nickname_align = 'right'"
                >右对齐</el-button
              >
            </el-button-group>
          </div>
        </template>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'actPoster',

  data () {
    return {
      cardLoading: true,
      form: {
        img_url: null,
        avatar_sharp: null,
        avatar_status: null,
        avatar_x: null,
        avatar_y: null,
        avatar_width: null,
        avatar_height: null,
        nickname_status: null,
        nickname_size: null,
        nickname_color: null,
        nickname_x: null,
        nickname_y: null,
        nickname_offsetX: null,
        nickname_align: null,
        qrcode_status: null,
        qrcode_x: null,
        qrcode_y: null,
        qrcode_width: null,
        qrcode_height: null
      },
      rules: {
        img_url: [
          { required: true, message: '请上传海报图片', trigger: 'change' }
        ]
      },
      uploadSetting: {
        limit: null,
        'show-file-list': false
      },
      default_poster: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642061572P0DT6qQV.png'
    }
  },

  watch: {
    'form.img_url' (val) {
      this.$store.commit('lottery/SET_INFO', { type: 'poster_img', val: val })
    },
    'form.avatar_status' (val) {
      this.$store.commit('lottery/SET_INFO', { type: 'avatar_status', val: val })
    },
    'form.avatar_sharp' (val) {
      this.$store.commit('lottery/SET_INFO', { type: 'avatar_sharp', val: val })
    },
    'form.nickname_status' (val) {
      this.$store.commit('lottery/SET_INFO', { type: 'nickname_status', val: val })
    },
    'form.nickname_size' (val) {
      this.$store.commit('lottery/SET_INFO', { type: 'nickname_size', val: val })
    },
    'form.nickname_color' (val) {
      this.$store.commit('lottery/SET_INFO', { type: 'nickname_color', val: val })
    },
    'form.nickname_align' (val) {
      this.$store.commit('lottery/SET_INFO', { type: 'nickname_align', val: val })
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        data.avatar_x = this.fn_calcCoordinatesBack(data.avatar_x, 'x')
        data.avatar_y = this.fn_calcCoordinatesBack(data.avatar_y, 'y')
        data.avatar_width = this.fn_calcCoordinatesBack(data.avatar_width, 'x')
        data.avatar_height = data.avatar_width
        data.nickname_x = this.fn_calcCoordinatesBack(data.nickname_x, 'x')
        data.nickname_y = this.fn_calcCoordinatesBack(data.nickname_y, 'y')
        data.qrcode_x = this.fn_calcCoordinatesBack(data.qrcode_x, 'x')
        data.qrcode_y = this.fn_calcCoordinatesBack(data.qrcode_y, 'y')
        data.qrcode_width = this.fn_calcCoordinatesBack(data.qrcode_width, 'x')
        data.qrcode_height = data.qrcode_width
        this.form = data
      }
      this.fn_init(data)
      this.cardLoading = false
    },

    // 初始化默认值
    fn_init (data) {
      if (!data) {
        this.form = {
          img_url: this.default_poster,
          avatar_sharp: 2,
          avatar_status: 1,
          avatar_x: 16,
          avatar_y: 24,
          avatar_width: 60,
          avatar_height: 60,
          nickname_status: 1,
          nickname_size: 14,
          nickname_color: '#ffffff',
          nickname_x: 85,
          nickname_y: 32,
          nickname_offsetX: 0,
          nickname_align: 'left',
          qrcode_status: 1,
          qrcode_x: 240,
          qrcode_y: 510,
          qrcode_width: 75,
          qrcode_height: 75
        }
      }

      const items = ['avatar_x', 'avatar_y', 'avatar_width', 'avatar_height', 'nickname_x', 'nickname_y', 'qrcode_x', 'qrcode_y', 'qrcode_width', 'qrcode_height']
      for (let i = 0; i < items.length; i++) {
        this.$store.commit('lottery/SET_INFO', {
          type: items[i],
          val: this.form[items[i]]
        })
      }
    },

    // 海报设置回调
    fn_getPoster (img) {
      this.form.img_url = img
      this.$refs.formRef.validateField('img_url')
    },

    // 字体大小提示
    formatTooltip (val) {
      return val + 'px'
    },

    // 345px 转 375px
    fn_caleCoord2 (num, type) {
      let _num = null
      if (type === 'x') {
        _num = Math.round(375 / 345 * num)
      } else if (type === 'y') {
        _num = Math.round(667 / 613.64 * num)
      }
      return _num
    },

    // 计算预览框宽度为345时的坐标
    fn_calcCoordinatesBack (num, type) {
      let _num = null
      if (type === 'x') {
        _num = (num * (345 / 375)).toFixed()
      } else if (type === 'y') {
        _num = (num * (613.64 / 667)).toFixed()
      }
      return +_num
    },

    // 表单校验，通过则返回 form
    verify (type) {
      let formValid = true

      if (!type) {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            formValid = false
          }
        })

        if (!formValid) return false
      }

      const form = this.util.extend(true, {}, this.form)
      // 合并存储的头像昵称等配置数据
      const posterInfo = this.util.extend(true, {}, this.$store.getters['lottery/GET_POSTER_INFO'])
      for (const item in posterInfo) {
        if (posterInfo[item] !== null) {
          form[item] = posterInfo[item]
        }
      }

      // 像素处理，345 -> 375
      form.avatar_x = this.fn_caleCoord2(form.avatar_x, 'x')
      form.avatar_y = this.fn_caleCoord2(form.avatar_y, 'y')
      form.avatar_width = this.fn_caleCoord2(form.avatar_width, 'x')
      form.avatar_height = form.avatar_width
      form.nickname_x = this.fn_caleCoord2(form.nickname_x, 'x')
      form.nickname_y = this.fn_caleCoord2(form.nickname_y, 'y')
      form.qrcode_x = this.fn_caleCoord2(form.qrcode_x, 'x')
      form.qrcode_y = this.fn_caleCoord2(form.qrcode_y, 'y')
      form.qrcode_width = this.fn_caleCoord2(form.qrcode_width, 'x')
      form.qrcode_height = form.qrcode_width

      return form
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-slider__button {
  width: 10px;
  height: 10px;
}
.color-pick {
  ::v-deep {
    .el-color-picker__trigger {
      border: 1px solid #f4f4f4;
    }
  }
}
</style>
