<!--
 * @Author: zhanln
 * @Date: 2022-03-21 11:45:14
 * @LastEditTime: 2022-04-01 10:22:01
 * @LastEditors: zhanln
 * @Description: 个人素材
-->

<template>
  <material-list></material-list>
</template>

<script>
import materialList from './components/list.vue'
export default {

  components: {
    materialList
  },

  provide: {
    category: 2
  },

  data () {
    return {
      activeName: 'one'
    }
  }
}
</script>
