var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "has-table",
      attrs: {
        title: "详情",
        visible: _vm.visible,
        "custom-class": "q-dialog",
        width: "848px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "user-info" },
        [
          _c("el-avatar", { attrs: { size: 54, src: _vm.userInfo.avatar } }),
          _c("div", { staticClass: "info" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.userInfo.nickname || "-"))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "ly-tabs",
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "TA的邀请", name: "invite" } },
            [
              _c("lnvitation", {
                ref: "lnvitation",
                attrs: {
                  inviterId: _vm.userInfo.id,
                  activityId: _vm.activityId
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "奖品记录", name: "records" } },
            [
              _c("prize", {
                ref: "prize",
                attrs: {
                  inviterId: _vm.userInfo.id,
                  activityId: _vm.activityId
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }