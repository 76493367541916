<!--
 * @Author: zhan
 * @Date: 2022-08-17 16:37:39
 * @LastEditTime: 2022-08-19 11:22:27
 * @LastEditors: zhan
-->
<template>
  <div class="detail" :class="{ 'z-top': is_focus }">
    <div class="detail-mask" v-if="is_focus"></div>
    <div class="detail-box" :class="{ show: is_focus }">
      <div class="detail-title">我的奖品</div>
      <div class="detail-body" v-if="detail.content">
        <div class="detail-body__title">兑换规则</div>
        <el-input type="textarea" :value="detail.content" autosize resize="none" readonly class="detail-body__text">
        </el-input>
      </div>
      <template v-if="detail.type === 1">
        <div class="detail-img" v-if="detail.img">
          <img :src="detail.img" alt="">
        </div>
      </template>

      <template v-else>
        <div class="detail-btn code">SASAFASTERHFDHF</div>
        <div class="detail-btn">复制兑换码</div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      show: true
    }
  },

  computed: {
    ...mapGetters({
      detail: 'reserveTask/GET_DETAIL'
    }),
    ...mapState({
      current_step: state => state.reserveTask.current_step,
      is_focus: state => state.reserveTask.is_focus
    })
  }
}
</script>

<style lang="scss" scoped>
$--c-task: #E537A3;

.detail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  &.z-top {
    z-index: 99;
  }

  &-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &-box {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 24px 24px;
    bottom: -100%;
    z-index: 100;
    background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0817/1660726115oL8D4NP8.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    transition: 0.4s ease-in-out;

    &.show {
      bottom: 0;
    }
  }

  &-title {
    text-align: center;
    font-size: 16px;
    color: #fff8fe;
    font-weight: 500;
    margin-top: 13px;
  }

  &-body {
    margin: 24px auto;
    background-color: #FFF0F9;
    padding: 12px 12px 18px;
    border-radius: 16px;

    &__title {
      text-align: center;
      color: $--c-task;
      font-size: 16px;
      font-weight: 500;
    }

    &__text {
      margin-top: 16px;

      ::v-deep .el-textarea__inner {
        color: #272B30;
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        border: none;
      }
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    height: 44px;
    border-radius: 22px;
    background: linear-gradient(90deg, #FF71D2 0%, #FE3FA5 100%);

    &.code {
      color: $--c-task;
      background: #FFF0F9;
      margin: 24px 0 12px;
    }
  }

  &-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 24px auto 12px;
    padding: 12px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 12px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
</style>
