<template>
  <div class="page-warp">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selection-change="handleSelectionChange"
    >
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          @click="addFormTable()"
          >创建表单</el-button
        >
        <el-button
          round
          icon="iconfont icon-edit"
          @click="$refs.drafts.draftsVisible = true"
          >草稿箱({{ draft }})</el-button
        >
      </template>
      <template #wecom_user_id_slot="{ row }">
        <div class="flex flex-sta-align">
          <ly-tag :content="row.createrName" v-if="row.createrName"></ly-tag>
          <template v-else>-</template>
        </div>
      </template>

      <template #create_time_text_slot="{ row }">
        <div>
          {{ util.timeTofmt(row.createTime, "yyyy-MM-dd hh:mm") }}
        </div>
      </template>

      <template #status_type_header>
        <span style="margin-right: 4px">状态</span>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">
            已关联：被活动关联使用的表单不允许编辑、删除<br />
            未关联：未被活动使用（包含已删除的活动）
          </div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>

      <template #activity_num_header>
        <span style="margin-right: 4px">关联活动数</span>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">包含已被删除/已结束的活动数</div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>

      <template #status_type_slot="{ row }">
        <ly-tag :content="row.status | getStatusText" only dep></ly-tag>
      </template>

      <template #operation="{ row }">
        <ly-action :key="Date.now()">
          <el-button type="text" class="ly-gap-button" @click="fn_goDetail(row)"
            >数据
          </el-button>
          <el-button type="text" class="ly-gap-button" @click="fn_update(row)"
            >编辑
          </el-button>
          <el-button type="text" class="ly-gap-button" @click="fn_extend(row)"
            >推广
          </el-button>

          <el-button
            type="text"
            icon="iconfont icon-delete"
            class="ly-gap-button"
            @click="fn_del(row.id)"
            v-if="row.status !== 2"
          >
            删除
          </el-button>

          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            popper-class="q-tooltip"
            v-else
          >
            <div slot="content">表单已被关联，不可删除</div>
            <el-button
              type="text"
              icon="iconfont icon-delete"
              class="ly-gap-button is-disabled"
            >
              删除
            </el-button>
          </el-tooltip>
        </ly-action>
      </template>
    </ly-table>

    <!-- 草稿箱 -->
    <draftsCom
      ref="drafts"
      :isEmpty="draft === 0"
      @onGetDrafts="handleDrafts"
    />

    <!-- 推广 -->
    <extendF ref="extendF" />
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import draftsCom from './components/drafts'
import extendF from './components/extend.vue'
import { finance } from './http.js'
import lyTag from '@/components/lyTag'
export default {
  components: { draftsCom, extendF, lyTag },
  mixins: [lyTable],

  data () {
    return {
      COMM_HTTP: finance,
      draft: 0,
      DATA_LOCATION: 'records'
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 200,
        column: [
          {
            label: '表单名称',
            prop: 'name',
            fixed: true,
            search: true,
            placeholder: '请输入表单名称',
            key: 'keyword'
          },
          {
            label: '创建人',
            prop: 'createBy',
            slot: 'wecom_user_id_slot',
            search: true,
            type: 'select',
            source: this.getCreateList,
            filterable: true,
            sourceField: {
              value: 'id',
              label: 'name'
            }
          },
          {
            label: '创建时间',
            prop: 'createTime',
            slot: 'create_time_text_slot',
            columnProps: {
              minWidth: 130
            },
            search: true,
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            key: ['startDate', 'endDate'] // 自定义搜索条件的key
            // formatter: (row, column, cellValue, index) => {
            //   return row.login_at === 0 ? '-' : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            // }
          },
          {
            label: '状态',
            prop: 'status',
            slot: 'status_type_slot',
            headerSlot: 'status_type_header',
            search: true,
            type: 'select',
            align: 'center',
            source: [{
              value: '',
              label: '全部'
            }, {
              value: 2,
              label: '已关联'
            }, {
              value: 1,
              label: '未关联'
            }]
          },
          {
            label: '关联活动数',
            prop: 'activityNum',
            headerSlot: 'activity_num_header',
            align: 'center'
          },
          {
            label: '总提交次数',
            prop: 'commitNum',
            align: 'center'
          }
        ]
      }
    }
  },
  created () {
    // this.getCreateList()
  },
  filters: {
    getStatusText (status) {
      return status == 1 ? '未关联' : '已关联'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.drafts.getDrafts()
    })
  },
  methods: {
    async getCreateList () {
      const data = await this.COMM_HTTP.reqCreateList()
      // this.createList = data
      return data
    },
    handleDrafts (data) {
      this.draft = data.total
    },
    fn_extend (row) {
      this.$refs.extendF.show(row)
    },
    fn_goDetail (row) {
      this.$router.push(`/material/formTable/detail?formId=${row.formId}&id=${row.id}`)
    },
    fn_update (row) {
      this.$router.push(`/material/formTable/edit?formId=${row.formId}&id=${row.id}&isDelete=${row.commitNum > 0 || row.status == 2}&isUpdate=true`)
    },
    addFormTable () {
      this.$router.push('/material/formTable/edit')
    },
    fn_del (id) {
      this.$lyConfirm({
        text: '删除表单会同时删除已提交的用户数据和活动数据，是否确认删除？',
        title: '确认删除此表单吗？',
        type: 'warning'
      })
        .then(() => {
          this.COMM_HTTP.reqDelete(id).then(res => {
            this.$message.success('删除成功！')
            this.queryData()
          })
        })
        .catch(() => { })
    }
  }
}
</script>

<style lang='scss' scoped>
.red {
  color: #ed5c49;
}
</style>
