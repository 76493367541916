<!--
 * @Author: zhanln
 * @Date: 2022-03-21 15:46:50
 * @LastEditTime: 2022-04-19 11:51:30
 * @LastEditors: zhanln
 * @Description: 文本
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="110px"
    size="medium"
    class="edit-form"
    v-loading="loading"
  >
    <!--  素材名称 -->
    <el-form-item label="素材名称：" prop="name">
      <el-input
        class="edit-form__input"
        v-model.trim="form.name"
        maxlength="20"
        show-word-limit
        placeholder="请输入素材名称"
      ></el-input>
    </el-form-item>

    <!--  分组 -->
    <el-form-item>
      <template slot="label"><span class="q-danger">*</span> 分组：</template>
      <el-select v-model="form.group_id" class="edit-form__input">
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <!--  文本内容 -->
    <el-form-item label="文本内容：" prop="content">
      <ly-editor
        class="edit-form__editor"
        title="文本内容"
        id="material-text"
        :content="form.content"
        emoji
        @getContent="fn_getContent"
      ></ly-editor>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'editText',

  props: {
    hasChange: {
      type: Boolean,
      defualt: false
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentGroupId: {
      type: String,
      default: '0'
    }
  },

  data () {
    return {
      loading: true,
      form: {
        type: 1,
        name: '',
        group_id: '0',
        content: ''
      },
      rules: {
        name: [
          { required: true, message: '请填写素材名称', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请填写文本内容', trigger: 'change' }
        ]
      },
      options: [{
        value: 1,
        label: '默认分组'
      }, {
        value: 2,
        label: '默认分组1'
      }],
      isEdit: false,
      editReady: false
    }
  },

  watch: {
    loading (val) {
      if (!val) {
        this.$emit('ready')
      }
    },
    form: {
      handler (val) {
        if ((this.isEdit && this.editReady) || (!this.isEdit && !this.editReady)) {
          this.$emit('update:hasChange', true)
        }
        if (this.isEdit) {
          this.editReady = true
        }
      },
      deep: true
    }
  },

  methods: {
    // 初始化数据
    fn_init (form) {
      if (!form) {
        this.form.group_id = this.currentGroupId === '-1' ? '0' : this.currentGroupId
        this.loading = false
        return
      }

      const detail = this.util.extend(true, {}, form)
      detail.content = detail.content.text
      this.form = detail

      this.loading = false
      this.isEdit = true
    },

    // 内容回调
    fn_getContent (content) {
      this.form.content = content
    },

    // 表单校验，通过则返回 form
    fn_verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)
      const content = {
        text: form.content
      }
      form.content = content

      return form
    }
  }
}
</script>
