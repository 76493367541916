<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-03-29 10:04:44
 * @LastEditTime: 2021-03-30 16:07:48
-->
<template>
  <span>{{`(${min}分${sec}秒)`}}</span>
</template>
<script>
export default {
  props: ['t', 'st'],
  data () {
    return {
      min: 0,
      sec: 0,
      timer: null
    }
  },
  mounted () {
    const time1 = this.t + 3 * 60
    let time2 = time1 - this.st
    const fn = () => {
      this.min = Math.floor(time2 / 60)
      this.sec = time2 % 60
      time2 -= 1
    }
    fn(time2)
    this.timer = setInterval(() => {
      if (time2 <= 0) {
        clearInterval(this.timer)
        this.$emit('done', true)
      } else fn(time2)
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
