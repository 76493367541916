<!--
 * @Author: zhanln
 * @Date: 2021-08-27 17:44:33
 * @LastEditTime: 2021-12-10 15:55:17
 * @LastEditors: Please set LastEditors
 * @Description: 手机预览框
-->

<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="q-dialog"
    width="420px"
    :modal-append-to-body="false"
    append-to-body
    :show-close="false"
    class="phone-dialog"
    top="10vh"
  >
    <div class="phone">
      <div class="phone-close" @click="visible = false">
        <i class="el-icon-circle-close"></i>
      </div>
      <div class="phone-view" :class="[link.type === 2 ? 'is-high' : '']">
        <!-- type：1、欢迎语，2、有海报链接 -->
        <ly-mobile :msgList="msgList" v-if="type === 1"></ly-mobile>
        <ly-mobile :showWel="false" v-else sub="" title="" :hasFooter="false">
          <div class="qrlink">
            <div class="qrlink-carousel">
              <img src="~@assets/images/avatar.jpg" alt="" class="avatar" />
              用户 192****1274 已添加好友
            </div>
            <div class="qrlink-body">
              <!-- link.type：1、默认类型，2、自定义类型 -->
              <template v-if="link.type === 1">
                <div class="bg">
                  <div class="title" v-html="link.linkContent"></div>
                  <img :src="link.liveCodeUrl" alt="" class="code" />
                  <div class="check">
                    <img
                      src="~@assets/svg/protect.svg"
                      alt=""
                    />二维码已通过检测，请放心扫码
                  </div>
                  <div class="tip">长按识别二维码</div>
                </div>
              </template>
              <div class="qrlink-poster" v-else>
                <el-image :src="link.poster" class="qrlink-poster__bg" fit="cover">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-back"></i>请上传链接页背景
                  </div>
                </el-image>
              </div>
            </div>
            <!-- 联系我们 -->
            <div class="qrlink-footer">
              <div class="contact" v-if="link.contactStatus">
                若以上二维码无法添加，
                <span class="flex" style="color: #2d67cf"
                  >请<img
                    src="~@assets/svg/message.svg"
                    alt=""
                    class="msg"
                  />联系主办方</span
                >
              </div>
              <div class="copyright" style="color: #2d67cf">
                <img src="~@assets/images/01logo_circle.webp" alt="" />零一裂变
                | 仅提供技术支持 <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <img
              src="~@assets/images/qr_link_bg_fi.png"
              alt=""
              class="bg-right"
            />
            <img
              src="~@assets/images/qr_link_bg_fi_left.png"
              alt=""
              class="bg-left"
            />
          </div>
          <!-- 虚拟时间 -->
          <div class="qrlink-fixed">
            <div class="qrlink-expire" v-if="link.showVirtual">
              <img src="~@assets/svg/alarm-clock.svg" alt="" /><span
                class="q-info"
                style="margin-right: 4px"
                >{{ link.virtualContent }}</span
              >
              <template v-if="link.unreal_expire_day > 0">
                <span class="time">{{ link.unreal_expire_day }}</span
                >天
              </template>
              <template
                v-if="link.unreal_expire_day + link.unreal_expire_hour > 0"
              >
                <span class="time">{{ link.unreal_expire_hour }}</span
                >时
              </template>
              <template
                v-if="
                  link.unreal_expire_day +
                    link.unreal_expire_hour +
                    link.unreal_expire_minute >
                  0
                "
              >
                <span class="time">{{ link.unreal_expire_minute }}</span
                >分
              </template>

              <template
                v-if="
                  link.unreal_expire_day +
                    link.unreal_expire_hour +
                    link.unreal_expire_minute +
                    link.unreal_expire_second >
                  0
                "
              >
                <span class="time">{{ link.unreal_expire_second }}</span
                >秒
              </template>
            </div>
          </div>
          <div class="qrlink-fixed__place" v-if="link.showVirtual"></div>
        </ly-mobile>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      default: () => {
        return {}
      }
    },
    msgList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      visible: false,
      type: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-dialog {
  ::v-deep {
    .el-dialog {
      border-radius: 32px;
    }
    .el-dialog__header {
      display: none;
    }
  }
}

.phone {
  padding: 32px 0;
  position: relative;

  &-close {
    position: absolute;
    right: -32px;
    top: -32px;
    font-size: 32px;
    color: #ccc;

    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    .loud {
      width: 88px;
      height: 8px;
      background-color: #f2f3f5;
      margin-right: 10px;
      border-radius: 4px;
    }
    .shine {
      background-color: #f2f3f5;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &-view {
    width: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    justify-content: center;

    &.is-high {
      ::v-deep .mobile-view {
        padding-bottom: 64px;
      }
    }
  }
}
.qrlink {
  position: relative;
  overflow: hidden;

  .bg-right {
    position: absolute;
    right: -50px;
    top: -45px;
    width: 100px;
    z-index: 1;
  }

  .bg-left {
    position: absolute;
    left: -15px;
    top: 370px;
    width: 69px;
    z-index: 1;
  }

  &-carousel {
    display: flex;
    justify-content: center;

    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-body {
    position: relative;
    margin: 16px auto;
    z-index: 2;

    .bg {
      position: relative;
      background-image: url("https://image.01lb.com.cn//uploads/wecom/21/0826/16299458785tA419Y9.png");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100% 100%;
      width: 283px;
      height: 360px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 2;
    }

    .title {
      color: #fff;
      padding: 0 32px;
      font-size: 22px;
      max-height: 66px;
      overflow: hidden;
      text-align: center;
    }

    .code {
      width: 190px;
      height: 190px;
      margin-top: 24px;
    }

    .check {
      display: flex;
      color: #fff;
      margin-top: 12px;

      img {
        margin-right: 4px;
      }
    }

    .tip {
      font-size: 16px;
      color: #fff;
      margin-top: 32px;
    }
  }

  &-poster {
    position: relative;
    width: 284px;
    min-height: 454px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0px 1px 4px 2px rgba(18, 18, 18, 0.03);

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    ::v-deep .image-slot {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 16px;
      color: #ccc;
    }

    .dragQrcode img {
      width: 100%;
      height: 100%;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    font-size: 12px;

    &.mb-32 {
      margin-bottom: 32px;
    }

    .contact {
      display: flex;
      color: #6E788A;

      .msg {
        width: 14px;
        position: relative;
      }

      img {
        margin: 0 2px;
      }
    }

    .copyright {
      display: flex;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 32px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }

  &-fixed {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 1;

    &__place {
      height: 82px;
    }
  }

  &-expire {
    margin: 0 10px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    font-size: 12px;
    color: #fa541c;
    line-height: 1;
    box-shadow: 0px 2px 6px 0px rgba(18, 18, 18, 0.08);

    img {
      margin-right: 4px;
    }

    .time {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      background-color: #fa541c;
      color: #fff;
      font-size: 12px;
      padding: 0 4px;
      border-radius: 2px;
      min-width: 20px;
      text-align: center;
      box-sizing: border-box;
      margin: 0 2px;
    }
  }
}
</style>
