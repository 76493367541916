var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config page-warp" }, [
    _c(
      "div",
      { staticClass: "config-body" },
      [
        _c(
          "el-button",
          {
            staticStyle: { "margin-right": "20px" },
            attrs: { type: "primary", round: "", icon: "iconfont icon-search" },
            on: { click: _vm.fn_jumpToLink }
          },
          [_vm._v("查看配置指引")]
        ),
        _c(
          "el-popover",
          { attrs: { trigger: "hover", placement: "right" } },
          [
            _c("p", { style: { textAlign: "center", margin: "0 0 10px 0" } }, [
              _vm._v(" 扫码添加客服好友 ")
            ]),
            _c("img", {
              staticClass: "qr-code",
              style: { width: "172px", height: "172px" },
              attrs: {
                src: require("@assets/images/contact_me_qr.png"),
                alt: ""
              }
            }),
            _c(
              "el-button",
              { attrs: { slot: "reference", type: "text" }, slot: "reference" },
              [_vm._v("联系客服处理")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "config-body_item" },
          [
            _c(
              "div",
              {
                staticClass: "config-body_title",
                staticStyle: { "margin-top": "40px" }
              },
              [_vm._v(" 客户联系配置信息 ")]
            ),
            _vm._m(0),
            _c(
              "el-form",
              {
                staticClass: "config-form",
                attrs: { model: _vm.appForm, "label-width": "164px" }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户联系Secret", required: "" } },
                  [
                    _c("div", { staticClass: "config-form_item" }, [
                      _c(
                        "div",
                        { staticClass: "config-form_left" },
                        [
                          _c("el-input", {
                            ref: "internal_secret_ref",
                            attrs: {
                              disabled: _vm.internal_secret_read,
                              placeholder: "请输入客户联系Secret"
                            },
                            model: {
                              value: _vm.appForm.internal_secret,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "internal_secret", $$v)
                              },
                              expression: "appForm.internal_secret"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.appForm.internal_secret_status === 1
                        ? _c(
                            "div",
                            { staticClass: "config-form_right" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: "配置异常，请点击修改按钮重新配置",
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-warning q-danger"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]
                ),
                _c(
                  "el-form-item",
                  [
                    _vm.internal_secret_read
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              round: "",
                              icon: "iconfont icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_change(["internal_secret"])
                              }
                            }
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                    !_vm.internal_secret_read
                      ? _c(
                          "el-button",
                          {
                            attrs: { round: "", icon: "iconfont icon-close" },
                            on: {
                              click: function($event) {
                                return _vm.fn_close(["internal_secret"], true)
                              }
                            }
                          },
                          [_vm._v("取消")]
                        )
                      : _vm._e(),
                    !_vm.internal_secret_read
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              round: "",
                              icon: "iconfont icon-selected"
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_close(["internal_secret"])
                              }
                            }
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "q-info flex", staticStyle: { "margin-bottom": "32px" } },
      [
        _c("i", {
          staticClass: "iconfont icon-warning-fill",
          staticStyle: { "margin-right": "4px" }
        }),
        _vm._v(" 配置成功后，可正常使用【企业标签】编辑/设置等相关权限 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }