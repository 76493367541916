b<!--
 * @Author: zhanln
 * @Date: 2021-11-10 16:23:21
 * @LastEditTime: 2022-01-06 14:45:25
 * @LastEditors: zhanln
 * @Description: 预览 - 任务设置
-->

<template>
  <ly-mobile
    :showWel="false"
    :hasFooter="false"
    backIcon="icon-close"
    sub=""
    :title="show_title"
  >
    <div class="reward">
      <!-- 倒计时 -->
      <div class="reward-time">
        活动倒计时
        <template v-if="time.s < 0"> - </template>
        <template v-else>
          {{ time.d > 0 ? time.d + "天" : "" }}
          {{ time.h > 0 ? time.h + ":" : "" }}
          {{ time.m > 0 ? time.m + ":" : "" }}
          {{ time.s }}
        </template>
      </div>

      <div class="reward-top">
        <div></div>
        <!-- <div class="reward-swiper">
          <img class="reward-swiper__avatar" :src="scrollAvatar" />
          {{ scrollName }} 抽中了100元现金红包
        </div> -->

        <div
          class="reward-rule__btn"
          @click="$refs.rewardRuleRef.visible = true"
        >
          活动规则
        </div>
      </div>

      <!-- 封面标题 -->
      <div class="reward-title">
        <template v-if="cover_title">
          <span
            class="reward-title__text"
            :data-text="
              cover_title.length > 7 ? cover_title.slice(0, 7) : cover_title
            "
            >{{
              cover_title.length > 7 ? cover_title.slice(0, 7) : cover_title
            }}</span
          >
          <template v-if="cover_title.length > 7">
            <span
              class="reward-title__text"
              :data-text="cover_title.slice(7, cover_title.length)"
              >{{ cover_title.slice(7, cover_title.length) }}</span
            >
          </template>
        </template>
        <template v-else>
          <span class="reward-title__text" data-text="请输入封面标题"
            >请输入封面标题</span
          ></template
        >
      </div>

      <!-- 抽奖盘 方形 -->
      <div class="reward-lottery" v-if="lottery_type === 1">
        <div class="reward-lottery__grid">
          <div
            v-for="(item, index) of rewards"
            :class="['reward-lottery__item', index === 3 ? 'active' : '']"
            :key="index"
          >
            <template v-if="index !== 8">
              <div class="cover">
                <img :src="item.reward_cover" alt="" />
              </div>
              <div class="text">
                {{ item.reward_title }}
              </div>
            </template>
            <template v-else>
              <div class="text">{{ item.reward_title }}</div>
            </template>
          </div>
        </div>
      </div>

      <!-- 抽奖盘 圆形 -->
      <div class="reward-lottery circle" v-else>
        <div class="reward-lottery__circle">
          <div
            class="item"
            v-for="(item, index) of rewards.slice(0, 8)"
            :key="index"
          >
            <div class="content">
              <div class="text">
                {{ item.reward_title }}
              </div>
              <div class="img">
                <img :src="item.reward_cover" />
              </div>
            </div>
          </div>
          <div class="center">
            <img
              src="https://image.01lb.com.cn//uploads/wecom/22/0106/1641455695glJQW76Q.png"
              alt=""
            />
          </div>
          <div class="center-text">抽奖机会:3次</div>
        </div>
      </div>

      <!-- 按钮 -->
      <div class="reward-btn">
        喊好友参与，获更多抽奖机会
        <div class="reward-btn__pop">
          每邀{{ need_invite || 1 }}位好友可获{{
            invite_lottery_num || 1
          }}次，最多可获{{ total_num || 1 }}次
        </div>
      </div>

      <!-- 抽奖记录 -->
      <div class="reward-rank">
        <div class="reward-rank__tab">
          <div class="item active">抽奖记录</div>
          <div class="item last">邀请明细</div>
        </div>
        <div class="reward-rank__list">
          <div class="item" v-for="(item, index) of records" :key="index">
            <div class="left">
              <div class="cover"><img :src="item.cover" alt="" /></div>
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="time">
                  {{ $day().format("YYYY-MM-DD hh:mm") }}
                </div>
              </div>
            </div>
            <div class="btn">查看</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 奖品预览框 -->
    <reward-detail></reward-detail>

    <!-- 活动规则 -->
    <reward-rule ref="rewardRuleRef" :tabKey="tabKey"></reward-rule>
  </ly-mobile>
</template>

<script>
import rewardDetail from './rewardDetail'
import rewardRule from './rewardRule'
import { mapState } from 'vuex'
export default {
  name: 'taskMobileReward',

  props: {
    tabKey: {
      type: String,
      default: 'reward'
    }
  },

  data () {
    return {
      scrollAvatar: '',
      scrollName: '',
      rewards: [{
        reward_title: '奖品名称',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png'
      }, {
        reward_title: '奖品名称',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png'
      }, {
        reward_title: '奖品名称',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png'
      }, {
        reward_title: '奖品名称',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png'
      }, {
        reward_title: '奖品名称',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png'
      }, {
        reward_title: '奖品名称',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png'
      }, {
        reward_title: '奖品名称',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png'
      }, {
        reward_title: '谢谢参与',
        reward_cover: 'https://image.01lb.com.cn//uploads/wecom/22/0105/1641371126wl0x8nqG.png'
      }, {
        reward_title: '抽奖机会:3次'
      }],
      records: [
        {
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png',
          title: '奖品名称'
        },
        {
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0105/1641371126wl0x8nqG.png',
          title: '谢谢参与'
        },
        {
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png',
          title: '奖品名称'
        },
        {
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0105/1641371126wl0x8nqG.png',
          title: '谢谢参与'
        },
        {
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png',
          title: '奖品名称'
        },
        {
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0105/1641371126wl0x8nqG.png',
          title: '谢谢参与'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      lottery_type: state => state.lottery.lottery_type,
      show_title: state => state.lottery.show_title,
      cover_title: state => state.lottery.cover_title,
      reward_title_0: state => state.lottery.reward_title_0,
      reward_title_1: state => state.lottery.reward_title_1,
      reward_title_2: state => state.lottery.reward_title_2,
      reward_title_3: state => state.lottery.reward_title_3,
      reward_title_4: state => state.lottery.reward_title_4,
      reward_title_5: state => state.lottery.reward_title_5,
      reward_title_6: state => state.lottery.reward_title_6,
      reward_title_7: state => state.lottery.reward_title_7,
      reward_cover_0: state => state.lottery.reward_cover_0,
      reward_cover_1: state => state.lottery.reward_cover_1,
      reward_cover_2: state => state.lottery.reward_cover_2,
      reward_cover_3: state => state.lottery.reward_cover_3,
      reward_cover_4: state => state.lottery.reward_cover_4,
      reward_cover_5: state => state.lottery.reward_cover_5,
      reward_cover_6: state => state.lottery.reward_cover_6,
      reward_cover_7: state => state.lottery.reward_cover_7,
      need_invite: state => state.lottery.need_invite,
      invite_lottery_num: state => state.lottery.invite_lottery_num,
      total_num: state => state.lottery.total_num,
      rule_focus: state => state.lottery.rule_focus
    }),
    time () {
      const now = this.$day()
      const end = this.$day(this.$store.state.lottery.end_time)
      const time = this.fn_setExpire((end - now) / 1000)
      return time
    }
  },

  watch: {
    reward_title_0 (val) {
      this.rewards[0].reward_title = val
    },
    reward_title_1 (val) {
      this.rewards[1].reward_title = val
    },
    reward_title_2 (val) {
      this.rewards[2].reward_title = val
    },
    reward_title_3 (val) {
      this.rewards[3].reward_title = val
    },
    reward_title_4 (val) {
      this.rewards[4].reward_title = val
    },
    reward_title_5 (val) {
      this.rewards[5].reward_title = val
    },
    reward_title_6 (val) {
      this.rewards[6].reward_title = val
    },
    reward_title_7 (val) {
      this.rewards[7].reward_title = val
    },
    reward_cover_0 (val) {
      this.rewards[0].reward_cover = val
    },
    reward_cover_1 (val) {
      this.rewards[1].reward_cover = val
    },
    reward_cover_2 (val) {
      this.rewards[2].reward_cover = val
    },
    reward_cover_3 (val) {
      this.rewards[3].reward_cover = val
    },
    reward_cover_4 (val) {
      this.rewards[4].reward_cover = val
    },
    reward_cover_5 (val) {
      this.rewards[5].reward_cover = val
    },
    reward_cover_6 (val) {
      this.rewards[6].reward_cover = val
    },
    reward_cover_7 (val) {
      this.rewards[7].reward_cover = val
    },
    rule_focus (val) {
      this.$refs.rewardRuleRef.visible = val
    }
  },

  created () {

  },

  mounted () {
    const USERINFO = this.$store.state.permission.wecomUserInfo
    this.scrollAvatar = (USERINFO && USERINFO.avatar_url) || 'https://image.01lb.com.cn//uploads/wecom/22/0106/1641449534NOX15X4z.png'
    this.scrollName = (USERINFO && USERINFO.nickname) || '小叮当'

    for (let i = 0; i < this.rewards.length; i++) {
      if (this.$store.state.lottery[`reward_title_${i}`]) {
        this.rewards[i].reward_title = this.$store.state.lottery[`reward_title_${i}`]
        this.rewards[i].reward_cover = this.$store.state.lottery[`reward_cover_${i}`]
      }
    }
  },

  methods: {

    // 倒计时
    fn_setExpire (data) {
      const eTime = [86400, 3600, 60, 0]
      const time = [0, 0, 0, 0]

      let _data = data
      for (let i = 0; i < eTime.length; i++) {
        if (i === 3) {
          time[i] = Math.floor(_data)
        } else {
          if (_data >= eTime[i]) {
            time[i] = Math.floor(_data / eTime[i])
            _data = _data % eTime[i]
          }
        }
      }

      const { 0: d, 1: h, 2: m, 3: s } = time
      return { d, h, m, s }
    }
  },

  components: {
    rewardDetail,
    rewardRule
  }
}
</script>

<style lang="scss" scoped>
.reward {
  background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0106/16414385124tI0iGX9.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  background-color: #fb2528;
  user-select: none;

  &-time {
    height: 24px;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    background: linear-gradient(
      to right,
      rgba(254, 204, 2, 0) 0%,
      rgba(254, 204, 2, 0.5) 50%,
      rgba(254, 204, 2, 0) 100%
    );
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 12px;
  }

  &-swiper {
    background-color: #a9000d;
    border-radius: 11px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    padding: 1px;
    padding-right: 12px;
    box-sizing: border-box;
    font-size: 12px;
    color: #fff;
    line-height: 1;

    &__avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  &-rule__btn {
    display: inline-flex;
    background-color: #e42b00;
    border: 1px solid #fff;
    color: #fff;
    height: 24px;
    line-height: 24px;
    padding: 0 12px;
    border-radius: 12px;
    box-sizing: border-box;
    font-size: 12px;
  }

  &-title {
    text-align: center;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;

    &__text {
      position: relative;
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      text-shadow: 0px 1px 6px rgba(227, 31, 27, 0.35);
      color: #fdffff;

      &::after {
        position: absolute;
        content: attr(data-text);
        top: 0;
        left: 0;
        text-shadow: none;
        background-image: linear-gradient(rgba(0, 0, 0, 0) 20%, #fff2c8 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }

  &-lottery {
    width: 290px;
    height: 290px;
    background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0106/1641463925CWB62QGq.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    &.circle {
      background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0106/16414528807Q59844w.png);
    }

    &__grid {
      position: relative;
      top: 30px;
      left: 30px;
      width: 230px;
      height: 230px;
      box-sizing: border-box;
    }

    &__item {
      position: absolute;
      width: 73px;
      height: 73px;
      background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0106/1641464059E0jd75g0.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: none;

      &:nth-child(1) {
        left: 0;
        top: 0;
        display: flex;
      }
      &:nth-child(2) {
        left: calc(73px + 5px);
        top: 0;
        display: flex;
        // display: flex;
      }
      &:nth-child(3) {
        left: calc(73px * 2 + 5px * 2);
        top: 0;
        display: flex;
        // display: flex;
      }
      &:nth-child(4) {
        top: calc(73px + 5px);
        right: 0;
        display: flex;
        // position: absolute;
        // right: 0;
        // top: 73px;
        // display: flex;
      }
      &:nth-child(5) {
        top: calc(73px * 2 + 5px * 2);
        right: 0;
        display: flex;
      }
      &:nth-child(6) {
        left: calc(73px + 5px);
        bottom: 0;
        display: flex;
      }
      &:nth-child(7) {
        left: 0;
        bottom: 0;
        display: flex;
      }
      &:nth-child(8) {
        left: 0;
        top: calc(73px + 5px);
        display: flex;
      }
      &:nth-child(9) {
        background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0106/1641465139PUoAFL47.png);
        left: calc(73px + 5px);
        top: calc(73px + 5px);
        display: flex;
        justify-content: flex-end;
        padding-bottom: 8px;
        box-sizing: border-box;
      }

      &.active {
        background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0106/16414653121YGxbRat.png);
      }

      .cover {
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        font-size: 12px;
        color: #b7040d;
        transform: scale(0.8);
        margin-top: 2px;
        text-align: center;
      }
    }

    &__circle {
      width: 240px;
      height: 240px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      top: 25px;
      left: 25px;
      background-color: #fff;

      .item {
        position: absolute;
        width: 120px;
        height: 120px;
        transform-origin: right bottom 0;
      }

      .item:nth-child(odd) {
        background-color: #fff;
      }
      .item:nth-child(even) {
        background-color: #ffefe9;
      }
      .item:nth-child(1) {
        transform: rotate(22.5deg) skewY(45deg);
      }
      .item:nth-child(2) {
        transform: rotate(67.5deg) skewY(45deg);
      }
      .item:nth-child(3) {
        transform: rotate(112.5deg) skewY(45deg);
      }
      .item:nth-child(4) {
        transform: rotate(157.5deg) skewY(45deg);
      }
      .item:nth-child(5) {
        transform: rotate(202.5deg) skewY(45deg);
      }
      .item:nth-child(6) {
        transform: rotate(247.5deg) skewY(45deg);
      }
      .item:nth-child(7) {
        transform: rotate(292.5deg) skewY(45deg);
      }
      .item:nth-child(8) {
        transform: rotate(337.5deg) skewY(45deg);
      }

      .content {
        width: 115px;
        height: 110px;
        transform-origin: center center 0;
        transform: skewY(-45deg) rotate(-23deg) translate(10px, 70px);
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: center;
      }

      .text {
        font-size: 12px;
        transform: scale(0.8);
        color: #b7040d;
      }

      .img {
        width: 35px;
        height: 35px;
        margin: 5px auto 0;

        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 4px;
        }
      }

      .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 100px;
        height: 100px;

        img {
          width: 100%;
        }
      }

      .center-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 30%) scale(0.7);
        font-size: 12px;
        color: #b5050c;
        z-index: 1;
      }
    }
  }

  &-btn {
    position: relative;
    background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0106/1641459121sI521C2S.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 250px;
    height: 44px;
    color: #bb0501;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    &__pop {
      position: absolute;
      right: 0px;
      top: -32px;
      background-color: #fed7c6;
      height: 22px;
      color: #9b2506;
      font-size: 12px;
      border-radius: 11px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;

      &:after {
        position: absolute;
        content: "";
        right: 16px;
        bottom: -12px;
        border: 6px solid transparent;
        border-top-color: rgba(#fff5e3, 0.8);
      }
    }
  }

  &-rank {
    padding: 16px;
    margin-top: 16px;
    box-sizing: border-box;

    &__tab {
      display: flex;
      position: relative;
      z-index: 1;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0;
        background-color: #fffaef;
        z-index: -1;
      }

      .item {
        flex: 1;
        color: #bb0501;
        font-size: 14px;
        font-weight: 500;
        height: 34px;
        border-radius: 17px 17px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#ffde99 20%, #ffce66 100%);

        &.active {
          background: #fffaef;
        }

        &.last {
          border-end-start-radius: 17px;
        }
      }
    }

    &__list {
      background-color: #fffbf2;
      border-radius: 0 0 17px 17px;
      overflow: hidden;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;

        &:not(:last-child) {
          border-bottom: 1px solid #eeeeee;
        }
      }

      .left {
        display: flex;
        align-items: center;
      }

      .cover {
        width: 32px;
        height: 32px;
        margin-right: 12px;

        img {
          width: 100%;
        }
      }

      .title {
        font-weight: 500;
      }

      .time {
        font-size: 12px;
        color: #999999;
      }

      .btn {
        background: linear-gradient(-90deg, #ff3823 0%, #ff8b3d 100%);
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        color: #fff;
        border-radius: 12px;
        font-size: 12px;
        line-height: 1;
      }
    }
  }
}
</style>
