<!--
 * @Descripttion: 活动列表页面
 * @version: 1.0.0
-->
<template>
  <div class="page-warp activity-list">
    <ly-table
      class="act-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
      @sortChange="handleSortChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          size="medium"
          @click="$refs.uploadCodeRef.createVisable = true"
          >创建兑换码</el-button
        >
      </template>
      <!-- 列插槽 -->
      <template #operation="{ row }">
        <el-button
          type="text"
          @click="
            $router.push({
              path: 'detail',
              query: { id: row.id, title: row.title },
            })
          "
          >查看</el-button
        >
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" @click="fn_addCode(row)">追加兑换码</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button
          type="text"
          @click="activityDel(row.id)"
          @keydown.enter.native.prevent
          v-if="row.can_del === 1"
          >删除</el-button
        >
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
          v-else
        >
          <div slot="content">兑换码已被使用，不可删除</div>
          <span
            class="q-info ly-gap-button ly-disable"
            style="margin-bottom: 5px"
            >删除</span
          >
        </el-tooltip>
      </template>
    </ly-table>
    <!-- 创建、追加兑换码 -->
    <upload-code
      ref="uploadCodeRef"
      @success="queryData"
      :codeItem="codeItem"
    ></upload-code>
  </div>
</template>
<script>
import mx_role from '@/components/mixins/role'
import lyTable from '@/components/mixins/ly-table.js'
import { codeList } from '@/pages/apps/code/http.js'
import uploadCode from './components/uploadCode'
export default {
  mixins: [lyTable, mx_role],
  data () {
    return {
      COMM_HTTP: codeList,
      codeItem: {
        title: ''
      }
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 250,
        labelWidth: '84px',
        showIndex: true,
        column: [
          {
            label: '兑换码名称',
            prop: 'title',
            showOverflowTooltip: false,
            search: true
          },
          {
            label: '创建日期',
            prop: 'created_date',
            sortable: true,
            search: true,
            key: ['created_start', 'created_end'], // 自定义搜索条件的key
            type: 'daterange',
            formItemProps: {
              'default-time': ['00:00:00', '23:59:59']
            },
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          }
        ]
      }
    }
  },
  methods: {
    activityDel (code_id, status) {
      this.$lyConfirm({
        title: '确认要删除此兑换码文件？',
        text: '兑换码删除后，该文件和该文件下的子文件无法找回，是否继续？',
        confirmButtonText: '删除'
      }).then(async () => {
        this.COMM_HTTP.reqDelete({ code_id }).then(data => {
          if ((data && data.code) || (data && data.code === 0)) {
            this.$message.error(data.msg)
            return
          }
          this.$message.success('删除成功！')
          this.queryData()
        })
      }).catch(() => { })
    },
    // 追加兑换码
    fn_addCode (item) {
      this.$refs.uploadCodeRef.addVisable = true
      this.codeItem = item
    }
  },
  components: {
    uploadCode
  }
}
</script>
<style lang="scss" scoped>
.activity-list {
  .balance-area {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }
  .available,
  .frozen,
  .cashout {
    width: 380px;
    height: 134px;
    text-align: center;
    border: 1px solid #ffe58f;
    background: #fffaf1;
    border-radius: 5px;
    .amount {
      font-size: 26px;
      color: #212b36;
    }
    .desc {
      color: #454d5b;
    }
  }
  .available,
  .frozen {
    display: flex;
    align-items: center;
    img {
      margin: 0 16px 0 24px;
    }
    .temp {
      text-align: left;
      margin-left: 16px;
    }
    .useable {
      float: left;
      display: flex;
      align-items: center;
    }
    .button {
      margin-left: 58px;
    }
  }
  .frozen {
    margin: 0 12px;
  }
  .xl-ly-setting {
    .el-button:nth-of-type(2) {
      border: 1px solid #2b7cff;
      margin-left: 16px;
      color: #2b7cff;
    }
  }
  .xl-ly-table {
    .poster {
      margin-right: 12px;
    }
    .aname {
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .status {
      position: relative;
      padding-left: 14px;
      i {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #000;
        position: absolute;
        top: 6px;
        left: 0;
        margin-right: 5px;
      }
    }
  }
  ::v-deep {
    .el-dropdown-more {
      margin-left: 10px;
      margin-bottom: 5px;
      color: #2b7cff;
      line-height: 1;
      &:hover {
        color: #5ca2ff;
      }
    }
    .operation-cell .cell {
      flex-wrap: wrap;
      .el-button,
      .el-divider {
        line-height: 1.5;
        margin-bottom: 5px;
      }
    }
    .act-list .el-table .el-button + .el-button {
      margin-left: unset;
    }
  }
}
.rlist-dropdown {
  .padding {
    display: inline-block;
    line-height: 32px;
    width: 136px;
    box-sizing: border-box;
    padding-left: 10px;
  }
}
</style>
