<!--
 * @Author: zhanln
 * @Date: 2021-08-31 15:19:22
 * @LastEditTime: 2021-12-14 21:20:05
 * @LastEditors: Please set LastEditors
 * @Description: 批量下载弹框
-->

<template>
  <el-dialog title="批量下载二维码" :visible.sync="visible" width="480px">
    <div class="list">
      <div class="item" v-for="(i, v) in list" :key="v">
        {{ i.liveCodeName }}（{{ i.groupName || "默认分组" }}）
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        round
        size="medium"
        @click="fn_download"
        :loading="btnLoading"
        >{{ btnLoading ? "下载中..." : "确定" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { list } from '../http.js'
export default {
  props: {
    list: {
      type: [Array, null],
      default: () => {
        return null
      }
    }
  },
  data () {
    return {
      COMM_HTTP: list,
      visible: false,
      btnLoading: false,
      dList: []
    }
  },
  methods: {
    // 数据处理
    fn_initList (data) {
      const groups = {}
      for (let i = 0; i < data.length; i++) {
        const _id = data[i].groupId || 0

        if (!groups[_id]) {
          groups[_id] = []
        }
        if (groups[_id].indexOf(data[i].groupName) === -1) {
          groups[_id].push({
            groupName: _id === 0 ? '默认分组' : data[i].groupName,
            name: data[i].liveCodeName,
            group: _id,
            code: 'http://wx.qlogo.cn/mmhead/PiajxSqBRaELsicbkAVaMsg4lc4k2DHF5L9P84bSnWiatUFGbsVia0jkSg/0'
          })
        }
      }
      this.dList = groups
    },

    // 下载
    async fn_download () {
      this.btnLoading = true
      const idList = (this.list.map(item => 'idList=' + item.id)).join('&')

      const data = await this.COMM_HTTP.downLoad({
        idList
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.btnLoading = false
        return false
      }

      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '批量下载活码.zip')

      document.body.appendChild(link)
      link.click()
      this.btnLoading = false
      this.visible = false
      this.fn_lyMsg('success', '下载成功！')
      this.$emit('fn_clearSelect')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.list {
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
  color: $--color-text-regular;

  .item {
    padding-right: 16px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .group {
    color: $--color-text-primary;
    i {
      margin-right: 4px;
    }
  }

  .name {
    padding-left: 18px;
    line-height: 2;
  }
}
</style>
