<!--
 * @Author: zhanln
 * @Date: 2021-07-30 14:50:12
 * @LastEditTime: 2021-10-29 17:29:11
 * @LastEditors: Please set LastEditors
 * @Description:
-->
<template>
  <div class="mobile">
    <div class="mobile-topbar">
      <div>{{ $day().format('HH:mm')}}</div>
      <div class="flex">
        <i class="iconfont icon-a-CellularConnection"></i>
        <i class="iconfont icon-Wifi"></i>
        <i class="iconfont icon-Battery"></i>
      </div>
    </div>
    <div class="mobile-titlebar">
      <i class="iconfont icon-left1"></i>
      <div class="flex">
        <span style="margin-left: 12px">群裂变专属福利群(125) </span
        ><img
          src="~@assets/svg/wc_work_ic.svg"
          alt=""
          style="width: 22px; margin-left: 6px"
        />
      </div>
      <i class="el-icon-more"></i>
    </div>
    <div class="mobile-container">
      <div
        class="flex flex-column q-info text-small"
        style="line-height: 2; margin-top: 12px"
      >
        <span
          ><span style="color: #2B7CFF">张三@零一裂变</span
          >邀请你加入了群聊</span
        >
        <span
          >张三@零一裂变为企业微信用户，<span style="color: #2B7CFF"
            >了解更多。</span
          ></span
        >
      </div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <el-input
            type="textarea"
            v-model="welWords"
            autosize
            resize="none"
            readonly
          ></el-input>
        </div>
      </div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <div class="link-preview">
            <div class="ellipsis2 link-preview__title">
              <template v-if="linkType === 2">
                {{ linkTitle || "请输入链接标题" }}
              </template>
              <template v-else>与我一起领取奖品👇</template>
            </div>
            <div class="flex flex-between flex-center link-preview__content">
              <div class="ellipsis3" style="flex: 1">
                <template v-if="linkType === 2">
                  {{ linkDesc }}
                </template>
                <template v-else>点击链接，领取福利</template>
              </div>
              <div class="link-preview__cover">
                <template v-if="linkType === 2">
                  <img
                    :src="
                      linkCover ||
                      'https://image.01lb.com.cn//uploads/wecom/21/1009/1633767898o4UJPl8q.png'
                    "
                    alt=""
                  />
                </template>
                <template v-else>
                  <img
                    src="https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png"
                    alt=""
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobiBasic',

  computed: {
    linkType () {
      return this.$store.getters['groupfission/GET_BASIC'].link_type
    },
    linkCover () {
      return this.$store.getters['groupfission/GET_BASIC'].link_logo.length > 0 ? this.$store.getters['groupfission/GET_BASIC'].link_logo[0].url : ''
    },
    linkTitle () {
      return this.$store.getters['groupfission/GET_BASIC'].link_title
    },
    linkDesc () {
      return this.$store.getters['groupfission/GET_BASIC'].link_desc
    },
    welWords () {
      return '@用户昵称' + this.$store.getters['groupfission/GET_BASIC'].wel_words
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";

.link-preview {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;

  &__content {
    align-items: flex-start;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__cover {
    height: 48px;
    font-size: 48px;
    line-height: 1;
    margin-left: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}
</style>
