<!--
 * @Descripttion: 选择账号下的群
 * @version:
 * @Author: lw
 * @Date: 2021-05-13 16:31:31
 * @LastEditTime: 2021-10-19 11:01:30
-->
<template>
  <el-dialog
    title="选择群"
    custom-class="select-group"
    :visible.sync="visible"
    @open="getListByRobot"
    width="635px"
  >
    <div class="left">
      <div class="temp-title">云端账号：
        <el-avatar
          :size="32"
          :src="accountInfo.avatar"
        ></el-avatar>
        <span class="nick text-ellipsis">{{ accountInfo.name }}</span>
        <span class="online" v-if="accountInfo.status === 1">(在线)</span>
        <span class="offline" v-else>(离线)</span>
      </div>
      <el-input
        v-model="filterKey"
        style="width: 200px"
        clearable
        size="medium"
        placeholder="请输入群组名称"
        suffix-icon="el-icon-search"
      ></el-input>
      <div class="title">选择群</div>
      <div class="list">
        <template v-if="treeData.length === 0">
          <div class="el-table__empty-block">
            <span class="el-table__empty-text">暂无数据</span>
          </div>
        </template>
        <template v-else>
          <div class="list-block">
            <el-tree
              ref="tree"
              :data="filteredData"
              node-key="tempId"
              show-checkbox
              :props="props"
              @check="handleCheckChange">
              <span class="custom-tree-node" slot-scope="{ node }">
                <div class="ly-table__main-tearm">
                  <i class="icon qy-icon-group"></i>
                </div>
                <div class="label-temp text-ellipsis">
                  {{ node.label }}
                </div>
              </span>
            </el-tree>
          </div>
        </template>
      </div>
    </div>
    <div class="right">
      <div class="title">
        已选群聊<span> ({{ selectedList.length }})</span>
        <el-button type="text" @click="handleClear">清空</el-button>
      </div>
      <div class="list">
        <template v-if="selectedList.length === 0">
          <div class="el-table__empty-block">
            <span class="el-table__empty-text">暂无数据</span>
          </div>
        </template>
        <template v-else>
          <div class="list-block">
            <div
              v-for="item in selectedList"
              :key="item.id"
              class="list-item"
            >
              <div class="temp">
                <span class="nick">{{ item.name }}</span>
              </div>
              <i class="el-icon-close" @click="handleItemRemove(item)"></i>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        round
        type="primary"
        :disabled="!selected.length"
        size="medium"
        @click="handleSelectAccount"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { selectAccount } from '../http.js'
export default {
  data () {
    return {
      COMM_HTTP: selectAccount,
      visible: false,
      accountInfo: {}, // 当前机器人信息
      props: {
        children: 'children',
        label: 'name'
      },
      treeData: [
        {
          name: '全部',
          tempId: '-1',
          children: []
        },
        {
          name: '未分组',
          tempId: '0',
          children: []
        }
      ],
      groupList: [], // 当前机器人管理的所有群
      filterKey: '', // 通过字符串过滤
      selected: [], // 已选群id
      selectedTreeId: [] // 已选树id
    }
  },
  computed: {
    filteredData () {
      if (this.filterKey === '') {
        return this.treeData
      } else {
        return this.treeData.filter(
          (e) => e.name.indexOf(this.filterKey) > -1
        )
      }
    },
    selectedList () {
      return this.groupList.filter((e) => this.selected.includes(e.id))
    }
  },
  methods: {
    /**
     * @description: 获取当前机器人管理的所有群
     * @param {*}
     * @return {*}
     */
    async getListByRobot () {
      this.treeData = [{
        name: '全部',
        tempId: '-1',
        children: []
      }, {
        name: '未分组',
        tempId: '0',
        children: []
      }]
      const res = await this.COMM_HTTP.robotGroup({
        robot_admin_id: this.accountInfo.id,
        topic_id: -1,
        type: 1,
        page: 1,
        per_page: 1000
      })
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }
      this.groupList = res.list // 缓存群
      const topics = {}
      // 遍历群 封装分组数据
      res.list.map(e => {
        this.treeData[0].children.push({ ...e, tempId: '-1_' + e.id })
        this.echo('-1_' + e.id)
        // topics.length > 0 代表群有分组
        if (e.topics.length) {
          e.topics.map(n => {
            if (!topics[n.name]) {
              topics[n.name] = {
                name: n.name,
                tempId: n.id,
                children: []
              }
            }
            // 添加tree唯一id
            topics[n.name].children.push({ ...e, tempId: n.id + '_' + e.id })
            this.echo(n.id + '_' + e.id)
          })
        } else {
          this.treeData[1].children.push({ ...e, tempId: '0_' + e.id })
          this.echo('0_' + e.id)
        }
      })
      for (const key in topics) {
        this.treeData.push({
          name: topics[key].name,
          tempId: topics[key].tempId,
          children: topics[key].children
        })
      }
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.selectedTreeId)
      })
    },
    /**
     * @description: 回显群勾选状态
     * @param {*}
     * @return {*}
     */
    echo (tempId) {
      const id = tempId.split('_')[1]
      if (this.accountInfo.groupIds.includes(id * 1)) {
        this.selected.push(id * 1)
        this.selectedTreeId.push(tempId)
      }
    },
    // 过滤树
    filterNode (value, data, node) {
      if (!value) return true
      if (node.level === 2) {
        const parentName = node.parent.label
        return parentName.indexOf(value) !== -1
      } else return data.name.indexOf(value) !== -1
    },
    handleCheckChange (data, { checkedKeys }) {
      this.selected = []
      this.selectedTreeId = []
      checkedKeys.map(e => {
        const id = e.toString().split('_')[1]
        if (id) {
          this.selected.push(id * 1)
          this.selectedTreeId.push(e)
        }
      })
    },
    handleItemRemove ({ id }) {
      this.selected = this.selected.filter(e => {
        // eslint-disable-next-line
        return e != id
      })
      this.selectedTreeId = this.selectedTreeId.filter(e => {
        return e.indexOf(id) < 0
      })
      this.$refs.tree.setCheckedKeys(this.selectedTreeId)
    },
    // 清空选择
    handleClear () {
      this.selected = []
      this.selectedTreeId = []
      this.$refs.tree.setCheckedKeys([])
    },
    // 保存选择的云端账号
    async handleSelectAccount () {
      this.$emit('submit', { id: this.accountInfo.id, avatar: this.accountInfo.avatar, name: this.accountInfo.name, groupIds: Array.from(new Set(this.selected)) })
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.select-group {
  .left,
  .right {
    flex: 1;
  }
  .left {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    .title {
      color: #212b36;
      margin-top: 10px;
    }
  }
  .temp-title{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .nick{
      max-width: 100px;
      margin: 0 10px;
    }
    .online{
      color: #48d848;
    }
  }
  .list {
    flex: 1;
    overflow: hidden;
  }
  .list-block {
    height: 100%;
    overflow-y: scroll;
    .label-temp{
      max-width: 155px;
    }
    .el-icon-close{
      cursor: pointer;
    }
  }
  .el-checkbox-group{
    font-size: 14px;
  }
  .list-item {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .temp {
      width: 150px;
      display: flex;
      flex: 1;
      align-items: center;
    }
    .nick {
      margin: 0 8px;
      max-width: 78px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .right {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    .title {
      color: #212b36;
      .el-button {
        float: right;
      }
    }
    .nick {
      max-width: 170px;
    }
  }
}
::v-deep {
  .el-checkbox__label {
    display: none;
  }
  .el-dialog__body {
    overflow: hidden;
    display: flex;
    height: 392px;
  }
  .el-tree-node__content{
    position: relative;
    height: 48px;
    .el-checkbox{
      position: absolute;
      right: 10px;
    }
    .custom-tree-node{
      display: flex;
      align-items: center;
    }
  }
}
</style>
