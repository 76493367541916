<!--
 * @Author: zhanln
 * @Date: 2021-11-03 19:04:30
 * @LastEditTime: 2022-06-30 11:40:04
 * @LastEditors: zhan
 * @Description: 奖品内容
-->

<template>
  <div class="reward-detail">
    <el-radio-group v-model="type" :disabled="isEdit">
      <el-tooltip class="item" placement="top" content="功能准备上线中，敬请期待" v-for="(item, index) in list"
        :disabled="!item.isDisable" :key="index">
        <el-radio :label="item.value" :disabled="item.isDisable">{{
            item.name
        }}</el-radio>
      </el-tooltip>
    </el-radio-group>

    <!-- 自定义内容 -->
    <el-form :model="diyForm" :rules="diyRule" ref="diyFormRef" size="medium" v-show="type === 1" class="act-form">
      <el-form-item prop="content">
        <div class="editor act-form__editor">
          <div class="editor-content">
            <ly-editor class="act-form__editor" id="diyContent" title="自定义奖品内容" :content="diyForm.content"
              @getContent="fn_getDiyText" @focus="prize_focus = true" @blur="prize_focus = false">
            </ly-editor>
            <div class="editor-img">
              <ly-upload :img="diyForm.img" @getImg="fn_getDiyImg" mini canRemove></ly-upload>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <!-- 兑换码 -->
    <el-form :model="codeForm" :rules="codeRule" ref="codeFormRef" size="medium" v-show="type === 2">
      <el-form-item prop="codeId" class="reward-detail__form-item">
        <el-select v-model="codeForm.codeId" placeholder="请选择" clearable class="act-form__input"
          :class="{ 'mb-8': isEdit }" :disabled="isEdit" @change="fn_changeCode">
          <el-option v-for="item in codeList" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="reward-detail__tool" v-if="!isEdit">
        选择要关联活动的兑换码奖品
        <el-button type="text" size="mini" @click="fn_jumpToCode('code')">去创建兑换码</el-button>
        <div data-v-3cefa108="" class="el-divider el-divider--vertical"></div>
        <el-button type="text" size="mini" v-lydebounce="['button', fn_getCodeList]">刷新</el-button>
      </div>

      <el-form-item prop="rule">
        <ly-editor class="act-form__editor" id="codeContent" title="兑换规则" :content="codeForm.rule"
          @getContent="fn_setCodeRule" @focus="prize_focus = true" @blur="prize_focus = false">
        </ly-editor>
      </el-form-item>
      <!-- <el-tooltip class="item" placement="top" effect="light" popper-class="q-tooltip is-light">
        <div slot="content">
          <div style="margin: 24px 24px 16px; width: 528px">
            <img src="https://image.01lb.com.cn//uploads/wecom/22/0209/1644375191990z9GDl.png" width="528" alt="" />
          </div>
        </div>
        <span class="q-primary text-small inline-flex" style="line-height: 1.6; margin-bottom: 0">
          <i class="iconfont icon-help1" style="margin-right: 2px"></i>用户点击抽奖后，如何获得奖品
        </span>
      </el-tooltip> -->
    </el-form>

    <!-- 表单 -->
    <el-form :model="form" :rules="formRule" ref="formRef" size="medium" v-show="type === 3">
      <el-form-item prop="formId" class="reward-detail__form-item">
        <el-select v-model="form.formId" placeholder="请选择" clearable class="act-form__input" :disabled="isEdit">
          <el-option v-for="item in formList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="reward-detail__tool" v-if="!isEdit">
        选择要关联活动的表单
        <el-button type="text" size="mini" @click="fn_jumpToCode('form')">去创建表单</el-button>
        <div class="el-divider el-divider--vertical"></div>
        <el-button type="text" size="mini" v-lydebounce="['button', fn_getFormList]">刷新</el-button>
      </div>

      <el-form-item prop="rule">
        <div class="editor act-form__editor">
          <div class="editor-header">奖品规则：</div>
          <div class="editor-count">
            <span class="editor-regain" @click="fn_regain('form')">恢复默认文本</span>{{ form.form_rule_length }} / 1000
          </div>
        </div>
      </el-form-item>
    </el-form>

    <el-form :model="redForm" :rules="redFormRule" ref="redFormRef" size="medium" v-show="type === 4">
      <el-form-item prop="reward_max_cash">
        <div class="q-bg is-default rule">
          每抽中一次领取现金红包<el-input-number v-model="redForm.reward_min_cash" controls-position="right" :min="0.31"
            :max="this.redForm.reward_max_cash" :precision="2" class="number-small no-error"
            @blur="fn_handleBlur($event, 'reward_min_cash', 0.31)"></el-input-number>
          ~
          <el-input-number v-model="redForm.reward_max_cash" controls-position="right" :min="redForm.reward_min_cash"
            :max="5" :precision="2" class="number-small" @blur="fn_handleBlur($event, 'reward_max_cash', 0.31)">
          </el-input-number>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { fDetails } from '../http'
// store
import { mapState } from 'vuex'
export default {
  name: 'rewardDetail',

  data () {
    // 自定义内容校验
    const checkContent = async (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入奖品内容'))
      } else {
        if (this.diyForm.diy_rule_length > this.codeMax) {
          callback(new Error(`最多可输入${this.codeMax}字`))
        } else {
          callback()
        }
      }
    }
    // 兑换码规则
    const checkCodeRule = async (rule, value, callback) => {
      if (this.codeForm.code_rule_length > this.codeMax) {
        callback(new Error(`最多可输入${this.codeMax}字`))
      } else {
        callback()
      }
    }
    // 表单规则
    const checkFormRule = async (rule, value, callback) => {
      if (this.form.form_rule_length > this.codeMax) {
        callback(new Error(`最多可输入${this.codeMax}字`))
      } else {
        callback()
      }
    }
    // 红包规则
    const checkRedForm = async (rule, value, callback) => {
      if (!this.open_packet) {
        callback(new Error('请在【抽奖规则】中开启红包抽奖后，设置现金红包金额'))
      } else {
        if (this.redForm.reward_max_cash > this.activity_amount) {
          callback(new Error(`领取红包需小于或等于活动金额：${this.activity_amount}`))
        } else {
          callback()
        }
      }
    }

    return {
      type: 1,
      list: [
        {
          name: '自定义',
          value: 1
        },
        {
          name: '兑换码',
          value: 2
        }
      ],
      diyForm: {
        content: '',
        img: ''
      },
      diyRule: {
        content: [
          { validator: checkContent, required: true, trigger: 'change' }
        ]
      },
      urlTips: false,
      codeForm: {
        codeId: null,
        rule: '',
        code_rule_length: 0
      },
      codeMax: 1000,
      codeRule: {
        codeId: [
          { required: true, message: '请选择兑换码', trigger: 'change' }
        ],
        rule: [
          { validator: checkCodeRule, trigger: 'change' }
        ]
      },
      codeList: [],
      codeReady: false,
      form: {
        formId: null,
        rule: '',
        form_rule_length: 0
      },
      formRule: {
        formId: [
          { required: true, message: '请选择表单', trigger: 'change' }
        ],
        rule: [
          { validator: checkFormRule, trigger: 'change' }
        ]
      },
      formList: [],
      formReady: false,
      redForm: {
        reward_min_cash: 0.31,
        reward_max_cash: 1
      },
      redFormRule: {
        reward_max_cash: [
          { validator: checkRedForm, trigger: 'change' }
        ]
      },
      prize_focus: false,
      codeRuleDefault: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
      formRuleDefault: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
    }
  },

  computed: {
    ...mapState({
      open_packet: state => state.livelottery.open_packet,
      activity_amount: state => state.livelottery.activity_amount,
      isEdit: state => {
        return state.livelottery.editType === 'edit'
      }
    })
  },

  watch: {
    type (val) {
      if (val === 2 && !this.codeReady) {
        this.codeReady = true
        this.fn_getCodeList()
      }
      if (val === 3 && !this.formReady) {
        this.formReady = true
        this.fn_getFormList()
      }
      if (val === 4) {
        this.$refs.redFormRef.validateField('reward_max_cash')
      }
      this.fn_setStore('reward_prize_type', val)
    },
    'diyForm.content' (val) {
      this.fn_setStore('diy_content', val)
    },
    'diyForm.img' (val) {
      this.fn_setStore('diy_img', val)
    },
    'codeForm.rule' (val) {
      this.fn_setStore('code_rule', val)
    },
    'form.rule' (val) {
      this.fn_setStore('form_rule', val)
    },
    open_packet (val) {
      this.$refs.redFormRef.validateField('reward_max_cash')
    },
    activity_amount (val) {
      this.$refs.redFormRef.validateField('reward_max_cash')
    },
    prize_focus (val) {
      this.fn_setStore('prize_focus', val)
    }
  },

  methods: {

    // 传入数据处理
    fn_setDetail (obj) {
      this.diyForm.content = obj.reward_detail
      this.diyForm.img = obj.reward_detail_img
      this.codeForm.codeId = obj.code_id ? +obj.code_id : null
      this.codeForm.rule = obj.code_rule
      this.form.formId = obj.form_id
      this.form.rule = obj.form_rule || this.formRuleDefault
      this.redForm.reward_min_cash = obj.reward_min_cash || 0.31
      this.redForm.reward_max_cash = obj.reward_max_cash || 1

      this.fn_setStore('reward_prize_type', this.type)
      this.fn_setStore('diy_content', this.diyForm.content)
      this.fn_setStore('diy_Img', this.diyForm.img)
      this.fn_setStore('code_rule', this.codeForm.rule)
      this.fn_setStore('form_rule', this.form.rule)

      this.type = obj.reward_type

      this.$nextTick(() => {
        this.$refs.codeFormRef.clearValidate()
        this.$refs.formRef.clearValidate()
        this.$refs.diyFormRef.clearValidate()
      })

      if (this.type === 2 && !this.codeReady && this.codeForm.codeId) {
        this.codeReady = true
        this.fn_getCodeList()
      }
      if (this.type === 3 && !this.formReady && this.codeForm.formId) {
        this.formReady = true
        this.fn_getFormList()
      }
    },

    // 自定义 - 内容text
    fn_getDiyText (text) {
      this.diyForm.content = text
    },

    // 自定义 - 图片
    fn_getDiyImg (img) {
      this.diyForm.img = img
    },

    // 获取兑换码列表
    async fn_getCodeList () {
      const data = await this.axios.get('taskCodeList', { params: { code_id: this.codeForm.codeId } })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.codeList = data
    },

    // 跳转兑换码 / 表单列表
    fn_jumpToCode (type) {
      const path = type === 'code' ? '/material/code/list' : '/material/formTable/list'
      const routeData = this.$router.resolve({
        path: path
      })
      window.open(routeData.href, '_blank')
    },

    // 兑换码 规则text
    fn_getCodeText (text) {
      this.codeForm.code_rule_length = text.length
      this.$refs.codeFormRef.validateField('rule')
    },

    // 兑换码 - 内容html
    fn_setCodeRule (html) {
      this.codeForm.rule = html
    },

    // 获取表单列表
    async fn_getFormList () {
      const data = await fDetails.reqQuery({
        page: 1,
        per_page: 9999
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.formList = data.records
    },

    // 表单 规则text
    fn_getFormText (text) {
      this.form.form_rule_length = text.length
      this.$refs.formRef.validateField('rule')
    },

    // 表单 - 内容html
    fn_setFormRule (html) {
      this.form.rule = html
    },

    // 监听数字
    fn_handleBlur (val, type, defualt) {
      if (this.redForm[type] === undefined) {
        this.redForm[type] = defualt
      }
      this.$refs.redFormRef.validateField('reward_max_cash')
    },

    // 清空校验
    fn_clearValid () {
      this.$refs.codeFormRef.clearValidate()
      this.$refs.formRef.clearValidate()
      this.$refs.diyFormRef.clearValidate()
    },

    // 恢复默认文本
    fn_regain (type) {
      const form = type === 'code' ? this.codeForm : this.form
      const typeName = type === 'code' ? '兑换规则' : '奖品规则'
      const base = type === 'code' ? this.codeRuleDefault : this.formRuleDefault
      this.$lyConfirm({
        title: `确定将${typeName}恢复为默认文本吗？`,
        text: ''
      }).then(async () => {
        form.rule = base
      }).catch(() => { })
    },

    // 选择/切换兑换码
    fn_changeCode (val) {
      if (val) {
        const wait = this.codeList.filter(item => item.id === val)[0].wait
        this.$emit('changeTotal', wait)
      }
    },

    // 配置
    fn_getForm () {
      const form = {
        type: this.type,
        diy: this.diyForm,
        code: this.codeForm,
        form: this.form
        // reward_min_cash: this.redForm.reward_min_cash,
        // reward_max_cash: this.redForm.reward_max_cash
      }
      return form
    },

    // 存储, store
    fn_setStore (type, val) {
      this.$store.commit('livelottery/SET_INFO', {
        name: type,
        val
      })
    },

    // 校验
    verify (type) {
      let formName = null
      let detailValid = true

      switch (this.type) {
        case 1:
          formName = 'diyForm'
          break
        case 2:
          formName = 'codeForm'
          break
        case 3:
          formName = 'form'
          break
        case 4:
          formName = 'redForm'
          break
      }

      if (!type) {
        this.$refs[formName + 'Ref'].validate((valid) => {
          if (!valid) {
            detailValid = false
          }
        })
      }

      const form = this.fn_getForm()

      return {
        detailValid,
        form
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";

.reward-detail {
  &__tool {
    font-size: 12px;
    color: $--color-text-secondary;
  }

  &__form-item {
    ::v-deep .el-form-item__content {
      display: flex;
      align-items: center;
    }

    ::v-deep .el-form-item__error {
      position: relative;
      margin-left: 16px;
    }
  }
}

.editor {
  border: 1px solid #dfe1e8;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
  transition: 0.25s border;

  &:hover {
    border-color: $--color-primary;
  }

  &-header {
    background-color: #f0f1f8;
    border-radius: 4px 4px 0 0;
    padding: 0 12px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-content {
    ::v-deep {
      .w-e-text-container {
        height: 169px !important;
      }

      .ly-editor {
        border: none;
      }
    }
  }

  &-count {
    text-align: right;
    padding: 0 12px;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.45);
  }

  &-img {
    border-top: 1px solid #eaebf2;
    padding: 16px 18px;
  }

  &-regain {
    display: inline-flex;
    color: $--color-text-regular;
    border-right: 2px;
    font-size: 12px;
    padding: 0 8px;
    height: 28px;
    line-height: 28px;
    margin-right: 2px;
    cursor: pointer;
    border-radius: 14px;
    transition: 0.25s color;

    &:hover {
      background-color: #f0f1f8;
    }
  }
}

.el-form-item.is-error {
  .editor {
    border-color: $--color-danger;
  }
}

.no-tool {
  ::v-deep {
    .w-e-toolbar {
      display: none;
    }
  }
}

.rule {
  width: 100%;
  max-width: 560px;
  line-height: inherit;
  padding: 12px 20px;
  box-sizing: border-box;

  &+& {
    margin-top: 8px;
  }
}

.number-small {
  width: 120px;
  margin: 0 8px;

  &.no-error {
    color: #ccc;

    ::v-deep .el-input__inner {
      border-color: #dfe1e8;
    }
  }
}

.q-bg {

  ::v-deep.el-icon-arrow-up,
  ::v-deep.el-icon-arrow-down {
    margin-right: 0;
  }
}
</style>
