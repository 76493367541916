var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp records-warp" },
    [
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "user_name_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.WecomUserID != 0
                  ? [
                      _c("el-image", {
                        staticClass: "avatar",
                        attrs: { lazy: "", src: row.user_avatar }
                      }),
                      _c("span", [_vm._v(_vm._s(row.user_name))])
                    ]
                  : [
                      _c("span", { staticClass: "other-icon" }, [_vm._v("外")]),
                      _c("span", { staticClass: "other-name" }, [
                        _vm._v("非本企业账号")
                      ])
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }