<!--
 * @Author: zhan
 * @Date: 2022-06-15 11:38:43
 * @LastEditTime: 2022-07-12 18:01:53
 * @LastEditors: zhan
-->
<template>
  <div class="reward-rule" v-if="visible">
    <div class="reward-rule__mask"></div>
    <div class="reward-rule__body">
      <div class="reward-rule__box">
        <div class="reward-rule__title">活动规则</div>
        <div class="reward-rule__content">
          <el-input type="textarea" v-model="rule" resize="none" readonly></el-input>
        </div>
      </div>
      <div class="reward-rule__close">
        <i class="el-icon-circle-close" @click="visible = false"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'popRule',

  data () {
    return {
      visible: false
    }
  },

  created () {
    this.visible = this.$store.getters['livelottery/GET_INFO']('rule_focus')
  },

  computed: {
    rule () {
      return this.$store.getters['livelottery/GET_INFO']('rule')
    }
  }
}
</script>

<style lang="scss" scoped>
.reward-rule {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 6;

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    overflow: hidden;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }

  &__box {
    background: url('https://image.01lb.com.cn//uploads/wecom/22/0705/1656992403Nosv1VNj.png') no-repeat;
    background-size: 100% auto;
    padding: 40px 10px 30px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    position: relative;
    width: 170px;
    height: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    line-height: 32px;
    text-align: center;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      background-color: #FFF06E;
      width: 4px;
      height: 4px;
      transform: translateX(-50%);
      border-radius: 50%;
      left: 40px;
    }

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      background-color: #FFF06E;
      width: 4px;
      height: 4px;
      transform: translateX(-50%);
      border-radius: 50%;
      right: 40px;
    }
  }

  &__content {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;

    ::v-deep .el-textarea__inner {
      height: 210px;
      overflow: auto;
      border: none;
      background-color: transparent;
      border-radius: 0;
      padding: 6px 12px;
      font-size: 12px;
      color: #CF663D;
    }
  }

  &__close {
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-top: 8px;
  }
}
</style>
