/*
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-12 19:55:13
 * @LastEditTime: 2021-09-22 12:01:52
 */
import axios from '@/common/ajax.js'

const apiD = '/scrm-api/'

// 企业标签
export const tagApi = {
  reqQuery: () => axios.get(`${apiD}tag/group/list`), // 标签列表
  tagSync: params => axios.get(`${apiD}tag/group/sync`, params) // 同步
}
