var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      staticClass: "material-tabs",
      attrs: { type: "card" },
      model: {
        value: _vm.currentStep,
        callback: function($$v) {
          _vm.currentStep = $$v
        },
        expression: "currentStep"
      }
    },
    _vm._l(_vm.data, function(item) {
      return _c(
        "el-tab-pane",
        {
          key: item.id,
          attrs: {
            label: item.label + "（" + item.count + "）",
            name: item.name
          }
        },
        [
          item.name === _vm.currentStep
            ? _c(item.name + "-list", {
                tag: "div",
                attrs: { currentGroupId: _vm.currentGroupId }
              })
            : _vm._e()
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }