<!--
 * @Descripttion: 用户详情红包记录列表
 * @version: 1.0.0
-->
<template>
  <div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #reward_title="{ row }">
        <div class="avatar-name">
          <img src="../../../../assets/svg/reward.svg" alt="" style="margin-right: 8px"/>
          <span class="name">{{ row.reward_title }}</span>
        </div>
      </template>

      <template #status="{ row }">
        <!-- 表单类型 -->
        <template v-if="row.reward_type === 3">
          <ly-status v-if="row.status === 1" type="danger">未填写</ly-status>
          <ly-status v-if="row.status === 2" type="success">已填写</ly-status>
          <ly-status v-if="row.status === 3" type="warning">未发放</ly-status>
        </template>

        <!--  -->
        <template v-else>
          <ly-status v-if="row.status !== 3" type="success">已发放</ly-status>
          <template v-else>
            <!-- 重发 -->
            <el-popover
              v-if="row.reward_type === 2"
              placement="top-start"
              trigger="hover"
            >
              <ly-status slot="reference" type="danger">未发放</ly-status>
              <div class="resend">
                <p class="desc">
                  <i class="el-icon-warning" style="color: #e6a23c"></i
                  >兑换码不足
                </p>
                <el-button type="primary" round size="mini" @click="handleResend(row)"
                  >重新发放</el-button
                >
              </div>
            </el-popover>
            <ly-status v-else type="danger">未发放</ly-status>
          </template>
        </template>
      </template>

      <template #operation="{ row }">
        <template v-if="row.reward_type === 3">
          <el-button
            type="text"
            size="small"
            @click="getRewardDetail(row)"
            v-if="row.reward_value"
            >查看奖品内容</el-button
          >
          <template v-else> - </template>
        </template>

        <template v-else>
          <el-button type="text" size="small" @click="getRewardDetail(row)"
            >查看奖品内容</el-button
          >
        </template>
      </template>
    </ly-table>
    <resend ref="resend" :recordId="recordId"></resend>
    <!-- dialog 奖品内容 -->
    <el-dialog
      title="奖品内容"
      :visible.sync="dialogVisible"
      append-to-body
      custom-class="q-dialog"
      width="34%"
    >
      <div class="reward">
        <div
          class="q-dialog-block q-dialog__img"
          v-if="rewardInfo.reward_type === 1"
        >
          <div v-html="rewardInfo.reward_detail"></div>
          <img :src="rewardInfo.reward_detail_img" class="reward-img" />
        </div>
        <div class="q-dialog-block" v-else-if="rewardInfo.reward_type === 2">
          <div class="reward-code">{{ rewardInfo.reward_value }}</div>
        </div>
        <div
          class="reward-list"
          v-loading="rewardLoading"
          v-else-if="rewardInfo.reward_type === 3"
        >
          <div
            class="reward-list__item"
            v-for="(i, v) in rewardDetail"
            :key="v"
          >
            <div class="title">{{ i.name }}</div>
            <div class="val">{{ i.data }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { record } from '../http.js'
import lyTable from '@/components/mixins/ly-table.js'
import Resend from './Resend.vue'
export default {
  mixins: [lyTable],
  components: {
    Resend
  },
  data () {
    return {
      COMM_HTTP: record,
      dialogVisible: false,
      rewardLoading: true,
      rewardInfo: {},
      rewardDetail: null,
      per_page: 5,
      recordId: '' // 奖品记录id
    }
  },
  computed: {
    tableOpt () {
      return {
        levitate: false,
        layout: 'prev, pager, next',
        operationWidth: 120,
        column: [
          {
            label: '奖品名称',
            prop: 'reward_title',
            slot: 'reward_title',
            showOverflowTooltip: true
          },
          {
            label: '奖品类型',
            prop: 'reward_type', // （ 1-自定义，2-兑换码，3-表单，4-核销码）
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '自定义'
              } else if (cellValue === 2) {
                return '兑换码'
              } else if (cellValue === 3) {
                return '表单'
              } else if (cellValue === 4) {
                return '核销码'
              }
            }
          },
          {
            label: '完成任务时间',
            prop: 'finish_date'
          },
          {
            label: '发放状态',
            prop: 'give_status',
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '未发放'
              } else if (cellValue === 2) {
                return '已发放'
              } else {
                return '-'
              }
            }
          },
          {
            label: '奖品状态',
            prop: 'get_status',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: null },
              { label: '未领取', value: 1 },
              { label: '已领取', value: 2 },
              { label: '未填写', value: 3 },
              { label: '已填写', value: 4 }
            ],
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '未领取'
              } else if (cellValue === 2) {
                return '已领取'
              } else if (cellValue === 3) {
                return '未填写'
              } else if (cellValue === 4) {
                return '已填写'
              } else {
                return '-'
              }
            }
          }
        ]
      }
    },
    sendFailNum () {
      return (this.listData && this.listData.expand && this.listData.expand.send_fail_num) || 0
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.$route.query.id,
        account_id: this.$attrs.id
      }
    },
    /**
     * @description: 重新发放奖品
     * @param {*} id
     * @param {*} reward_value
     * @return {*}
     */
    handleResend ({ id, reward_type_id }) {
      this.$refs.resend.code_id = reward_type_id
      this.$refs.resend.id = id
      this.$refs.resend.visible = true
    },
    /**
     * @description: 查看奖品详情
     * @param {*} data
     * @return {*}
     */
    async getRewardDetail (data) {
      // 奖励类型 1-自定义，2-兑换码，3-表单，4-核销码
      this.rewardInfo = data
      if (data.reward_type === 3) {
        await this.fn_getFormReward(data)
      }
      this.dialogVisible = true
    },

    // 表单内容
    async fn_getFormReward (data) {
      this.rewardLoading = true
      const res = await this.COMM_HTTP.getOneDetail(
        {
          formId: data.reward_type_id,
          requestId: data.reward_value
        }
      )

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.rewardDetail = res
      this.rewardLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .xl-ly-search{
    margin-bottom: 20px!important;
  }
  .handler-left {
    color: #212b36;
  }
  .el-divider {
    top: -1px;
  }
  .fail {
    font-weight: normal;
  }
  .send-succ {
    color: #52c41a;
  }
  .send-succ,
  .send-error {
    position: relative;
    padding-left: 15px;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: #52c41a;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .send-error {
    &::before {
      background: #ed5c49;
    }
  }
  .el-icon-warning {
    font-size: 16px;
    color: #faad14;
  }
}
.resend {
  text-align: center;
  .desc {
    margin: 10px 0;
  }
  .el-icon-warning {
    margin-right: 5px;
  }
}
.reward-code {
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
  background: #d2d4d4;
  line-height: 2;
}
.reward-img {
  display: block;
  margin: 15px auto 0;
  height: 120px;
}
.el-avatar {
  background: transparent;
}
.reward {
  max-height: 50vh;
  overflow: auto;

  &-list {
    &__item {
      padding: 14px 0;
      border-bottom: 1px solid #f0f1f2;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }

      .title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .val {
        color: #6e6f73;
      }
    }
  }
}
</style>
