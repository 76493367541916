<!--
 * @Author: zhanln
 * @Date: 2021-11-18 15:02:51
 * @LastEditTime: 2022-01-12 15:05:20
 * @LastEditors: zhanln
 * @Description: 活动规则预览
-->

<template>
  <div class="reward-rule" v-if="visible">
    <div class="reward-rule__mask"></div>
    <div class="reward-rule">
      <div class="content">
        <div class="head">活动规则</div>
        <el-input type="textarea" v-model="rule" resize="none"></el-input>
      </div>
      <i
        class="el-icon-circle-close reward-rule__close"
        @click="visible = false"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileRewardRule',

  props: {
    tabKey: {
      type: String,
      default: ''
    }
  },

  watch: {
    tabKey (val) {
      this.$store.state.lottery.reward_focus = false
    }
  },

  data () {
    return {
      visible: false
    }
  },

  computed: {
    ...mapState({
      rule: state => state.lottery.rule
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
.reward {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9;

  &-rule {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content {
      // position: absolute;
      // top: 100px;
      // left: 50%;
      // transform: translateX(-50%);
      width: 80%;
      min-height: 400px;
      max-height: 600px;
      background-color: #fff;
      border: 8px solid #fde0ba;
      overflow-y: auto;
      border-radius: 16px;
      box-sizing: border-box;
      z-index: 9;
    }

    &__mask {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 8;
    }

    .head {
      @include flex;
      color: #ae1c07;
      font-weight: 500;
      font-size: 16px;
      margin-top: -4px;
      height: 40px;
      background: url("https://image.01lb.com.cn//uploads/wecom/21/0805/16281295053k54EYvt.png")
        no-repeat top center;
      background-size: 200px;
      margin-bottom: 16px;
    }

    &__close {
      position: relative;
      margin-top: 12px;
      color: #fff;
      font-size: 24px;
      z-index: 9;
    }

    ::v-deep .el-textarea__inner {
      min-height: 100% !important;
      height: 320px;
      border: none !important;
      color: #ae1c07;
    }
  }
}
</style>
