var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { on: { click: _vm.handleFocus } }, [
    _c("div", {
      ref: _vm.refName,
      staticClass: "wangeditor_base",
      attrs: { id: _vm.refName }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }