var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      staticClass: "agr-record__step",
      model: {
        value: _vm.tabName,
        callback: function($$v) {
          _vm.tabName = $$v
        },
        expression: "tabName"
      }
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "私聊", name: "private" } },
        [
          _vm.tabName === "private"
            ? _c("private-record", { attrs: { cusList: _vm.cusList } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "群聊", name: "group" } },
        [
          _vm.tabName === "group"
            ? _c("group-record", { attrs: { cusList: _vm.cusList } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }