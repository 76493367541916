<!--
 * @Author: zhanln
 * @Date: 2022-01-04 15:35:12
 * @LastEditTime: 2022-07-26 15:38:05
 * @LastEditors: zhan
 * @Description: 抽奖规则
-->

<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-width="160px" size="medium" class="act-form"
    v-loading="cardLoading">
    <!-- 助力规则 -->
    <el-form-item label="助力规则：" prop="rule_check">
      <div class="q-bg is-default rule">
        无门槛抽奖次数<el-input-number v-model="form.old_num" controls-position="right" :min="0" :max="100" :precision="0"
          class="number-small" :disabled="allowEdit" @blur="fn_handleBlur($event, 'old_num')"></el-input-number>次
        <div class="q-info text-small" style="line-height: 1.6; margin-top: 12px">
          企业老客户无需邀请好友即可拥有的抽奖机会
        </div>
        <div style="margin-top: 16px">
          每邀请<el-input-number v-model="form.need_invite" controls-position="right" :min="1" :max="100" :precision="0"
            class="number-small no-error" :disabled="allowEdit" @blur="fn_handleBlur($event, 'need_invite')">
          </el-input-number>人，获得<el-input-number v-model="form.invite_lottery_num" controls-position="right" :min="1"
            :max="100" :precision="0" class="number-small no-error" :disabled="allowEdit"
            @blur="fn_handleBlur($event, 'invite_lottery_num')"></el-input-number>次抽奖机会
        </div>
        <div style="margin-top: 12px">
          总抽奖机会不超过<el-input-number v-model="form.total_num" controls-position="right" :min="1" :max="100" :precision="0"
            class="number-small" :disabled="allowEdit" @blur="fn_handleBlur($event, 'total_num')"></el-input-number>次
        </div>
      </div>
    </el-form-item>

    <!-- 红包抽奖 -->
    <el-form-item label="红包抽奖：" prop="open_packet">
      <div class="flex">
        <el-switch v-model="form.open_packet" :active-value="1" :inactive-value="0" :disabled="allowEdit"></el-switch>
        <span class="q-info" style="margin-left: 12px">开启后奖品兑换方式将启用抽中现金红包，立即到账到零钱</span>
      </div>
    </el-form-item>

    <template v-if="form.open_packet === 1">
      <!-- 活动金额 -->
      <el-form-item label="活动金额：" prop="activity_amount">
        <div>
          <el-input-number v-model="form.activity_amount" controls-position="right" :min="0.31" :max="3000"
            :precision="2" class="number-small" style="margin-left: 0"
            @blur="fn_handleBlur($event, 'activity_amount', 0.31)"></el-input-number>元
          <span style="margin-left: 32px">账户可用余额：{{ account_amount_fmt }} 元
          </span>
          <el-button type="text" @click="$refs.recharge.rechargeVisible = true">立即充值</el-button>
        </div>
        <div v-if="allowEdit">
          已发放金额：<span class="q-primary" style="margin-right: 24px">{{
              form.use_amount
          }}</span>
          剩余活动金额：<span class="q-primary">{{ remain_amount }}</span>
        </div>
        <div class="q-info text-small" style="line-height: 1.6; margin-top: 12px">
          1. 活动发布后，暂时从账户内冻结对应的活动金额。<br />2.
          活动发布后，活动金额支持修改。<br />3. 每次向用户发放红包，平台收取{{
              !allowEdit ? fee_rate : form.fee_rate
          }}%手续费，手续费不足0.01元时，按0.01元计算。<br />4.
          活动金额发放完后，用户会随机抽中任意奖品项。
        </div>
      </el-form-item>

      <!-- 中奖金额 -->
      <el-form-item label="中奖金额：" prop="max_amount">
        <div class="q-bg is-default rule">
          总抽中红包金额不超过<el-input-number v-model="form.max_amount" controls-position="right" :min="0" :max="50"
            :precision="2" class="number-small" :disabled="allowEdit" @blur="fn_handleBlur($event, 'max_amount', 0)">
          </el-input-number>元
          <div class="q-info text-small" style="line-height: 1.6; margin-top: 12px">
            用户领取金额满足抽中红包金额上限后，用户会随机抽中任意奖品项<br />
            若上限值为0时，则不限制用户抽中的红包发放上限
          </div>
        </div>
      </el-form-item>
    </template>
    <!-- 充值 -->
    <rd-recharge ref="recharge" @success="fn_paySuccess" />
  </el-form>
</template>

<script>
// store
import { mapState } from 'vuex'
import rdRecharge from '@/components/LyRecharge.vue'
export default {
  name: 'actRule',

  components: {
    rdRecharge
  },

  data () {
    // 规则校验
    const checkRule = async (rule, value, callback) => {
      if (this.form.old_num > this.form.total_num) {
        callback(new Error('无门槛抽奖次数不可大于总抽奖次数'))
      } else {
        callback()
      }
    }
    // 活动金额校验
    const checkAmount = async (rule, value, callback) => {
      if (value < 0.31) {
        callback(new Error('活动金额需大于 0.31 元'))
      } else if (this.allowEdit && value < this.form.use_amount) {
        callback(new Error('活动金额需大于已发放金额'))
      } else if (this.allowEdit ? value > (this.account_amount * 1000 + this.amount * 1000) / 1000 : value > this.account_amount) {
        callback(new Error('当前账户余额不足'))
      } else {
        callback()
      }
    }
    // 总抽中金额校验
    const checkMax = async (rule, value, callback) => {
      callback()
    }
    return {
      cardLoading: true,
      form: {
        old_num: 0,
        need_invite: 1,
        invite_lottery_num: 1,
        total_num: 1,
        open_packet: 0,
        activity_amount: 0.31,
        max_amount: 10,
        use_amount: 0,
        fee_rate: null
      },
      remain_amount: 0,
      amount: 0,
      rules: {
        rule_check: [
          { validator: checkRule, required: true, trigger: 'change' }
        ],
        open_packet: [
          { required: true, trigger: 'change' }
        ],
        activity_amount: [
          { validator: checkAmount, trigger: 'change' }
        ],
        max_amount: [
          { validator: checkMax, trigger: 'change' }
        ]
      },
      fee_rate: null
    }
  },

  computed: {
    ...mapState({
      account_amount: state => state.lottery.account_amount,
      account_amount_fmt: state => state.lottery.account_amount_fmt,
      allowEdit: state => {
        return state.lottery.isEdit && !state.lottery.isCopy && !state.lottery.isDraft
      }
    })
  },

  watch: {
    'form.old_num' (val) {
      this.$nextTick(() => {
        this.$refs.formRef.validateField('rule_check')
      })
    },
    'form.need_invite' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'need_invite',
        val: val
      })
    },
    'form.invite_lottery_num' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'invite_lottery_num',
        val: val
      })
    },
    'form.total_num' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'total_num',
        val: val
      })
      this.$nextTick(() => {
        this.$refs.formRef.validateField('rule_check')
      })
    },
    'form.open_packet' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'open_packet',
        val: val
      })
      this.$store.commit('lottery/SET_INFO', {
        type: 'activity_amount',
        val: this.form.activity_amount
      })
    },
    'form.activity_amount' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'activity_amount',
        val: val
      })
      this.$nextTick(() => {
        if (this.form.open_packet) {
          this.$refs.formRef.validateField('activity_amount')
          this.$refs.formRef.validateField('max_amount')
        }
      })
    },
    'form.max_amount' (val) {
      this.$nextTick(() => {
        if (this.form.open_packet) {
          this.$refs.formRef.validateField('max_amount')
        }
      })
    },
    account_amount (val) {
      if (this.form.open_packet) {
        this.$refs.formRef.validateField('activity_amount')
      }
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      this.fn_getAmount()
      if (data) {
        this.amount = data.activity_amount
        this.remain_amount = (data.activity_amount * 1000 - data.use_amount * 1000) / 1000
        this.form = data
      }
      this.cardLoading = false
    },

    // 充值成功
    fn_paySuccess () {
      this.$refs.recharge.rechargeVisible = false
      this.fn_getAmount()
    },

    // 获取账户余额
    async fn_getAmount () {
      const data = await this.axios.get('redPacketInfo')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      const account_amount = (data.available_amount * 1000 - data.freeze_amount * 1000) / 1000
      const account_amount_fmt = this.fn_toThousandFilter(data.available_amount - data.freeze_amount)
      this.fee_rate = data.fee_rate

      this.$store.commit('lottery/SET_INFO', {
        type: 'account_amount',
        val: account_amount
      })
      this.$store.commit('lottery/SET_INFO', {
        type: 'account_amount_fmt',
        val: account_amount_fmt
      })

      if (this.form.open_packet) {
        this.$refs.formRef.validateField('activity_amount')
      }
    },

    // 设置余额格式
    fn_toThousandFilter (num) {
      return (+num || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
    },

    // 监听数字
    fn_handleBlur (val, type, defualt) {
      if (this.form[type] === undefined) {
        this.form[type] = defualt !== undefined || type === 'old_num' ? 0 : 1
      }
    },

    // 表单校验，通过则返回 form
    verify (type) {
      let formValid = true

      if (type) {
        return this.form
      }

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      return formValid ? this.form : false
    }
  }
}
</script>

<style lang="scss" scoped>
.rule {
  width: 100%;
  max-width: 560px;
  line-height: inherit;
  padding: 12px 20px;
  box-sizing: border-box;

  &+& {
    margin-top: 8px;
  }
}

.number-small {
  width: 120px;
  margin: 0 8px;

  &.no-error {
    color: #ccc;

    ::v-deep .el-input__inner {
      border-color: #dfe1e8;
    }
  }
}

.q-bg {

  ::v-deep.el-icon-arrow-up,
  ::v-deep.el-icon-arrow-down {
    margin-right: 0;
  }
}
</style>
