<!--
 * @Descripttion: 活动列表页面
 * @version: 1.0.0
-->
<template>
  <div class="page-warp activity-list">
    <ly-table
      class="act-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          size="medium"
          @click="$router.push('edit')"
          >创建活动</el-button
        >
        <el-button
          round
          icon="iconfont icon-edit"
          size="medium"
          @click="$refs.drafts.draftsVisible = true"
          >草稿箱({{ draft }})</el-button
        >
      </template>
      <!-- 列插槽 -->
      <template #title="{ row }">
        <div class="poster-name">
          <el-image
            class="poster"
            style="width: 36px; height: 66px"
            :src="row.poster_url"
            fit="cover"
            lazy
          ></el-image>
          <span
            class="aname"
            v-html="
              row.title.length > 8
                ? row.title.slice(0, 8) +
                  '<br />' +
                  row.title.slice(8, row.title.length)
                : row.title
            "
          ></span>
        </div>
      </template>

      <template #stock_header>
        <span style="margin-right: 4px">剩余库存</span>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">
            活动库存为0时将自动暂停活动，活动暂停后，新添加用<br />户不再自动发送活动规则和海报，同时不作为本次参与<br />用户统计，也不统计上级邀请数量。您可以添加库存或<br />者结束活动。
          </div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>
      <template #stock_num="{ row }" class="test">
        <div
          v-for="(item, i) in row.group_activity_task"
          :key="i"
          :class="[item.remain === 0 ? 'q-danger' : '']"
        >
          <!-- 奖励类型 1-自定义，2-兑换码，3-表单，4-核销码 -->
          <template v-if="item.step === 1">
            一阶：{{ item.remain }}
            <span
              class="q-danger"
              v-if="item.reward_type === 2 && item.remain > item.type_remain"
            >
              （兑换码：{{ item.type_remain }}）
            </span></template
          >
          <template v-else-if="item.step === 2">
            二阶：{{ item.remain }}
            <span
              class="q-danger"
              v-if="item.reward_type === 2 && item.remain > item.type_remain"
            >
              （兑换码：{{ item.type_remain }}）
            </span></template
          >
          <template v-else-if="item.step === 3">
            三阶：{{ item.remain }}
            <span
              class="q-danger"
              v-if="item.reward_type === 2 && item.remain > item.type_remain"
            >
              （兑换码：{{ item.type_remain }}）
            </span></template
          >
        </div>
      </template>

      <template #status="{ row }">
        <ly-status v-if="row.status === 1" type="success">进行中</ly-status>
        <ly-status v-else-if="row.status === 2" type="info">已结束</ly-status>
      </template>
      <template #operation="{ row }">
        <ly-action :key="Date.now()">
          <el-button
            type="text"
            @click="$router.push({ path: 'analysis', query: { id: row.id } })"
            >数据分析</el-button
          >
          <el-button
            type="text"
            @click="$router.push({ path: 'users', query: { id: row.id } })"
            >参与用户</el-button
          >
          <el-button
            type="text"
            :disabled="row.status === 2"
            @click="$router.push({ path: 'group', query: { id: row.id } })"
            >活动群</el-button
          >
          <el-button
            icon="iconfont icon-jiangpin"
            type="text"
            @click="$router.push({ path: 'record', query: { id: row.id } })"
            >奖品记录</el-button
          >
          <el-button
            type="text"
            icon="iconfont icon-Code"
            @click="$router.push({ path: 'popularize', query: { id: row.id } })"
            >渠道推广</el-button
          >
          <el-button
            type="text"
            :disabled="row.status === 2"
            icon="iconfont icon-send"
            @click="
              $refs.aide.inviterData.activity_id = row.id;
              $refs.aide.inviterVisible = true;
            "
            >群发助手</el-button
          >
          <el-divider></el-divider>
          <el-button
            type="text"
            icon="iconfont icon-edit"
            @click="$router.push({ path: 'edit', query: { id: row.id } })"
            >修改</el-button
          >
          <el-button
            type="text"
            icon="iconfont icon-copy"
            @click="
              $router.push({
                path: 'edit',
                query: { id: row.id, edit: 'copy' },
              })
            "
            >复制</el-button
          >
          <el-button
            type="text"
            :disabled="row.status === 2"
            icon="iconfont icon-ending"
            @click="activityStop(row.id)"
            >结束</el-button
          >
          <el-button
            type="text"
            icon="iconfont icon-delete"
            @click="activityDel(row.id, row.status)"
            >删除</el-button
          >
        </ly-action>
      </template>
    </ly-table>
    <!-- 草稿箱 -->
    <drafts ref="drafts" />
    <!-- 群发助手 -->
    <gs-aide ref="aide" :custom-link="false" />
  </div>
</template>
<script>
import mx_role from '@/components/mixins/role'
import lyTable from '@/components/mixins/ly-table.js'
import { activityList } from './http.js'
import drafts from './components/drafts'
import gsAide from './components/groupSendAide'
export default {
  mixins: [lyTable, mx_role],
  components: {
    drafts,
    gsAide
  },
  data () {
    return {
      COMM_HTTP: activityList,
      draft: 0 // 草稿箱数量
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 270,
        column: [
          {
            label: '活动名称',
            prop: 'title',
            slot: 'title',
            showOverflowTooltip: false,
            search: true,
            sort: 1,
            fixed: true,
            columnProps: {
              minWidth: 200
            }
          },
          {
            label: '群总数',
            prop: 'group_num',
            width: 80,
            align: 'center'
          },
          {
            label: '参与用户数',
            prop: 'account_num',
            width: 120,
            align: 'center'
          },
          {
            label: '剩余库存',
            prop: 'stock_num',
            slot: 'stock_num',
            headerSlot: 'stock_header',
            columnProps: {
              className: 'stock-num',
              minWidth: 120
            }
          },
          {
            label: '创建日期',
            prop: 'created_date',
            sortable: true,
            search: true,
            key: ['created_start', 'created_end'], // 自定义搜索条件的key
            type: 'daterange',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '结束日期',
            prop: 'end_date',
            sortable: true,
            search: true,
            key: ['end_start', 'end_end'], // 自定义搜索条件的key
            type: 'daterange',
            pickerOptions: {},
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '活动状态',
            prop: 'status',
            search: true,
            type: 'select',
            clearable: 0,
            default: 0,
            sort: 2,
            source: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '进行中',
                value: 1
              },
              {
                label: '已结束',
                value: 2
              }
            ],
            slot: 'status',
            align: 'center',
            columnProps: {
              minWidth: 90
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.$refs.drafts.queryData()
  },
  methods: {
    activityStop (id) {
      this.$lyConfirm(
        {
          text: '活动结束后无法重新开启，您确定结束吗？',
          title: '确认结束此活动吗？'
        }
      )
        .then(() => {
          this.COMM_HTTP.activityStop({ id }).then((res) => {
            if ((res && res.code) || (res && res.code === 0)) {
              this.$message.error(res.msg)
              return
            }
            this.$message.success('操作成功！')
            this.queryData()
          })
        })
        .catch(() => { })
    },
    activityDel (id, status) {
      if (status !== 2) {
        this.$message.error('只允许删除已结束的活动！')
      } else {
        this.$lyConfirm({
          text: '活动删除后将无法找回，您确定删除吗？',
          title: '确认删除此活动吗？'
        }).then(() => {
          this.COMM_HTTP.reqDelete({ id }).then((res) => {
            if ((res && res.code) || (res && res.code === 0)) {
              this.$message.error(res.msg)
              return
            }
            this.$message.success('删除成功！')
            this.queryData()
          })
        }).catch(() => { })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-list {
  .xl-ly-table {
    .poster {
      margin-right: 12px;
      border-radius: 4px;
    }
    .aname {
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  ::v-deep {
    .el-dropdown-more {
      margin-bottom: 5px;
      color: #2b7cff;
      line-height: 1;
      &:hover {
        color: #5ca2ff;
      }
    }
    .el-table__body-wrapper .stock-num .cell {
      flex-direction: column;
      align-items: unset;
    }
    .operation-cell .cell {
      flex-wrap: wrap;
      .el-button,
      .el-divider {
        line-height: 1.5;
        margin-bottom: 5px;
      }
    }
    .act-list .el-table .el-button + .el-button {
      margin-left: unset;
    }
  }
}
.rlist-dropdown {
  .padding {
    display: inline-block;
    line-height: 32px;
    width: 136px;
    box-sizing: border-box;
    padding-left: 10px;
  }
}
</style>
