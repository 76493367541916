var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popularize page-warp-bg" },
    [
      _c(
        "div",
        { staticClass: "select-box" },
        [
          _c("label", { staticStyle: { color: "rgba(0, 0, 0, 0.85)" } }, [
            _vm._v("选择活动：")
          ]),
          _c(
            "el-select",
            {
              attrs: {
                size: "medium",
                filterable: "",
                placeholder: "请选择活动"
              },
              on: { change: _vm.queryData },
              model: {
                value: _vm.activity_id,
                callback: function($$v) {
                  _vm.activity_id = $$v
                },
                expression: "activity_id"
              }
            },
            _vm._l(_vm.activities, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          events: _vm.handleEvents,
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "iconfont icon-plus",
                      round: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.visible = true
                      }
                    }
                  },
                  [_vm._v("添加渠道")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "link_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "url-box" },
                  [
                    _c("span", { staticClass: "activity_url" }, [
                      _vm._v(_vm._s(row.activity_url))
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "16px" },
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleCopy(row)
                          }
                        }
                      },
                      [_vm._v("复制")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "qrcode_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "p-qrcode-box" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          trigger: "hover",
                          placement: "right",
                          "close-delay": 0
                        }
                      },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: row.poster_url,
                              alt: "",
                              width: "250",
                              height: "445"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: {
                                "justify-content": "space-around",
                                "margin-top": "8px"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDownload(row, "poster")
                                    }
                                  }
                                },
                                [_vm._v("下载海报")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDownload(row, "code")
                                    }
                                  }
                                },
                                [_vm._v("下载二维码")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("i", {
                          staticClass: "iconfont icon-Code",
                          staticStyle: {
                            "font-size": "18px",
                            "margin-right": "0px"
                          },
                          attrs: { slot: "reference" },
                          slot: "reference"
                        })
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "copy",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleDownload(row, "code")
                          }
                        }
                      },
                      [_vm._v("下载")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", disabled: row.can_edit === 0 },
                    on: {
                      click: function($event) {
                        return _vm.handleEdit(row)
                      }
                    }
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", disabled: row.can_del === 0 },
                    on: {
                      click: function($event) {
                        return _vm.handleDel(row)
                      }
                    }
                  },
                  [_vm._v("删除")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.edit ? "编辑渠道" : "添加渠道",
            visible: _vm.visible,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                size: "medium",
                model: _vm.form,
                rules: _vm.rules,
                "show-message": false,
                "label-width": "90px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入渠道名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "span",
                { staticStyle: { color: "#6e788a", "margin-right": "12px" } },
                [_vm._v("保存后自动生成二维码和链接")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "", size: "medium" },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }