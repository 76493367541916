var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.only
    ? _c(
        "div",
        {
          class: [
            "tag_over",
            _vm.content && _vm.content.length > 0 ? "" : "is-empty"
          ]
        },
        [
          _vm.content
            ? [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      disabled: _vm.content.length <= _vm.size
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(_vm._s(_vm.content))
                    ]),
                    _c(
                      "span",
                      {
                        ref: "tagRef",
                        class: ["ly-tag", _vm.dep ? "is-dep" : ""]
                      },
                      [
                        _vm._t("left", [
                          _vm.icon
                            ? [_c("i", { class: _vm.icon })]
                            : [
                                !_vm.dep && _vm.avatar
                                  ? _c("el-image", {
                                      attrs: { src: _vm.avatar }
                                    })
                                  : !_vm.dep
                                  ? _c("i", {
                                      staticClass: "iconfont icon-Staff"
                                    })
                                  : _vm._e()
                              ]
                        ]),
                        _vm._t("default", [
                          _vm._v(
                            _vm._s(
                              _vm.content.length > _vm.size
                                ? _vm.content.slice(0, _vm.size) + "..."
                                : _vm.content
                            )
                          )
                        ]),
                        _vm._t("right")
                      ],
                      2
                    )
                  ]
                )
              ]
            : [_vm._v(" " + _vm._s(_vm.defaultText) + " ")]
        ],
        2
      )
    : _c(
        "el-tooltip",
        {
          attrs: {
            effect: "dark",
            placement: "top",
            disabled: _vm.content.length <= _vm.size
          }
        },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _vm._v(_vm._s(_vm.content))
          ]),
          _c(
            "span",
            { ref: "tagRef", class: ["ly-tag", _vm.dep ? "is-dep" : ""] },
            [
              _vm._t("left", [
                _vm.icon
                  ? [_c("i", { class: _vm.icon })]
                  : [
                      !_vm.dep && _vm.avatar
                        ? _c("el-image", { attrs: { src: _vm.avatar } })
                        : !_vm.dep
                        ? _c("i", { staticClass: "iconfont icon-Staff" })
                        : _vm._e()
                    ]
              ]),
              _vm._t("default", [
                _vm._v(
                  _vm._s(
                    _vm.content.length > _vm.size
                      ? _vm.content.slice(0, _vm.size) + "..."
                      : _vm.content
                  )
                )
              ]),
              _vm._t("right")
            ],
            2
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }