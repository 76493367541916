<!--
 * @Description: 添加群发任务
-->
<template>
  <div class="to-group">
    <div class="main-area">
      <el-scrollbar class="form-area">
        <el-form
          ref="form"
          size="medium"
          :rules="rules"
          :show-message="false"
          :model="form"
          label-width="120px"
        >
          <el-form-item
            :label="type === 'announcement' ? '群发公告名称' : '群发名称'"
            prop="task_title"
          >
            <el-input
              v-model="form.task_title"
              style="width: 280px"
              maxlength="20"
              show-word-limit
              :placeholder="
                type === 'announcement'
                  ? '请输入群发公告名称'
                  : '请输入群发名称'
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="选择群聊" ref="task_group">
            <selected-table ref="groupTable" @change="handleGroupChange" />
          </el-form-item>
          <el-form-item
            label="群公告内容"
            ref="task_announcement"
            v-if="type === 'announcement'"
          >
            <ly-editor
              class="act-form__editor"
              title="群公告"
              id="help"
              :content="form.content"
              emoji
              @getContent="fn_getNoticeText"
            ></ly-editor>
          </el-form-item>
          <el-form-item ref="task_content" label="群发内容" v-else>
            <div class="msg-list">
              <msg-template
                v-for="(item, i) in form.message"
                :title="`消息${i + 1}：${getMsgType(item.msg_type)}`"
                :class="`type${item.msg_type}`"
                :msg="item"
                :key="item.temp"
                :disabled="getDisabledStatus(i)"
                @change="handleAction"
              />
            </div>
            <el-button
              round
              type="primary"
              icon="iconfont icon-plus"
              @click="handleAddContent"
              >添加内容</el-button
            >
            <add-content ref="addContent" @push="handlePushMsg" />
          </el-form-item>
          <el-form-item label="群发方式" prop="book_time">
            <el-radio v-model="form.send_time_type" :label="1"
              >立即群发</el-radio
            >
            <el-radio v-model="form.send_time_type" :label="2"
              >定时群发</el-radio
            >
            <el-date-picker
              v-if="form.send_time_type === 2"
              v-model="sendTime"
              type="datetime"
              placeholder="选择日期时间"
              default-time="12:00:00"
              value-format="timestamp"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否@所有人" v-if="type !== 'announcement'">
            <el-radio v-model="at" :label="0">否</el-radio>
            <el-radio v-model="at" :label="1">是</el-radio>
            <span class="tips"
              >(必须满足：1.群发成员为群主 2.第一条消息为文本内容)</span
            >
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <el-scrollbar class="h5-area">
        <mobile
          type="group"
          :at="at"
          :msg="!type ? form.message : [{ msg_content: form.content }]"
        />
      </el-scrollbar>
    </div>
    <div class="submit-area">
      <el-button
        round
        icon="iconfont icon-send-fill"
        type="primary"
        :loading="loading"
        @click="handleSubmit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import selectedTable from './components/selectedTable.vue'
import addContent from './components/addContent.vue'
import msgTemplate from './components/msgTemplate.vue'
import mobile from '@/components/Mobile.vue'
import lyEditor from '@/components/lyEditor'
import { common } from './http'
export default {
  name: 'batch',
  props: {
    type: {
      type: String, // announcement: 公告
      default: ''
    }
  },
  components: {
    msgTemplate,
    addContent,
    selectedTable,
    mobile,
    lyEditor
  },
  data () {
    return {
      COMM_HTTP: common,
      editType: '', // update为修改  为空表示新增（复制）
      sendType: 1, // 1立即群发 2定时群发
      sendTime: null, // 定时群发时间
      at: 0,
      form: {
        task_title: '',
        group_ids: [],
        robot_ids: [],
        message: [],
        send_time_type: 1, // 1：立即群发 2：定时群发
        book_time: null, // 定时群发时间 只在send_time_type=1时有效
        extra_param: ''
      },
      rules: {
        task_title: [{ required: true }]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      },
      backComfirm: true,
      loading: false
    }
  },
  watch: {
    $route: {
      handler (n) {
        // query形式是修改 params形式是复制
        if (n.query.task_id) {
          this.editType = 'update'
          this.queryTaskDetail(n.query.task_id)
        } else if (n.params.task_id) {
          this.queryTaskDetail(n.params.task_id)
        }
      },
      immediate: true
    }
  },
  beforeMount () {
    if (this.type === 'announcement') {
      // 修改为发公告
      this.form.type = 2
      this.$set(this.form, 'content', '')
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          next()
        }).catch(() => {
          next(false)
        })
      } else {
        next()
      }
    }
  },
  methods: {
    handleAddContent () {
      if (this.form.message.length >= 9) {
        this.$message.error('最多群发9条消息')
        return false
      }
      this.$refs.addContent.visible = true
    },
    /**
     * @description: 查询任务详情 用来复制或者修改
     * @param {*} task_id
     * @return {*}
     */
    queryTaskDetail (task_id) {
      const method = this.type === 'announcement' ? 'noticeDetail' : 'messageDetail'
      this.COMM_HTTP[method]({
        task_id,
        message_type: this.type === 'announcement' ? 2 : 1
      }).then(res => {
        if (res && res.code === 0) {
          this.$message.error(res.msg)
          return false
        }
        Object.assign(this.form, res)
        this.sendTime = this.form.book_time * 1000 || null
        // 回显选择群聊数据
        this.$refs.groupTable.extra_param = this.form.extra_param ? JSON.parse(this.form.extra_param) : {}
        const temp = Date.now()
        if (this.type === 'announcement') { return false }
        // 仅在群发消息时
        this.form.message = res.message.map((e, i) => {
          return {
            ...e,
            temp: temp + i
          }
        })
      })
    },
    /**
     * @description: 获取群id
     * @param {*} group_ids
     * @param {*} robot_ids
     * @return {*}
     */
    handleGroupChange ({ group_ids, extra_param }) {
      this.form.group_ids = group_ids
      this.form.extra_param = JSON.stringify(extra_param)
    },
    // 修改消息
    handleMsgEdit (msg) {
      this.$refs.addContent.handleEdit(msg)
    },
    getDisabledStatus (i) {
      if (this.form.message.length === 1) {
        return ['up', 'down']
      }
      if (i === 0) {
        return ['up']
      } else if (i === this.form.message.length - 1) {
        return ['down']
      } else {
        return []
      }
    },
    getMsgType (code) {
      let type = ''
      switch (code) {
        case 2001:
          type = '文本'
          break
        case 2002:
          type = '图片'
          break
        case 2004:
          type = '视频'
          break
        case 2005:
          type = '链接'
          break
        case 2010:
          type = '文件'
          break
        case 2013:
          type = '小程序'
          break
      }
      return type
    },
    handleAction (action, msg) {
      switch (action) {
        case 'edit':
          this.handleMsgEdit(msg)
          break
        case 'up':
          this.handleMsgUp(msg)
          break
        case 'down':
          this.handleMsgDown(msg)
          break
        case 'remove':
          this.handleMsgRemove(msg)
          break
      }
    },
    // 新增消息回调
    handlePushMsg (msg) {
      if (msg.temp) {
        for (let i = 0; i < this.form.message.length; i++) {
          if (this.form.message[i].temp === msg.temp) {
            this.form.message.splice(i, 1, msg)
            break
          }
        }
      } else {
        this.form.message.push({
          temp: Date.now(), // 增加唯一标识用于调整顺序
          ...msg
        })
      }
    },
    // 上移
    handleMsgUp ({ temp }) {
      this.form.message.some((e, i) => {
        if (i !== 0 && e.temp === temp) {
          const item = this.form.message.splice(i, 1)[0]
          this.$nextTick(() => {
            this.form.message.splice(i - 1, 0, item)
          })
        }
      })
    },
    // 下移
    handleMsgDown ({ temp }) {
      this.form.message.some((e, i) => {
        if (e.temp === temp) {
          const item = this.form.message.splice(i, 1)[0]
          this.$nextTick(() => {
            this.form.message.splice(i + 1, 0, item)
          })
        }
      })
    },
    // 移除消息
    handleMsgRemove ({ temp }) {
      this.form.message = this.form.message.filter(e => {
        return e.temp !== temp
      })
    },
    fn_getNoticeText (text) {
      this.form.content = text
    },
    // 滚动到报错位置
    formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },
    handleSubmit () {
      this.type === 'announcement' ? this.handleSubmitAn() : this.handleSubmitGr()
    },
    /**
     * @description: 提交群发任务
     * @param {*}
     * @return {*}
     */
    handleSubmitGr () {
      if (!this.form.task_title) {
        this.$message.error('请输入群发名称')
        this.formScroll(this.$refs.form.$el)
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.group_ids.length < 1) {
            this.$message.error('请选择群')
            this.formScroll(this.$refs.task_group.$el)
            return
          }
          if (this.form.message.length < 1) {
            this.$message.error('请添加内容')
            this.formScroll(this.$refs.task_content.$el)
            return
          }
          // 群id转number
          this.form.group_ids = this.form.group_ids.map(e => {
            return e * 1
          })
          this.form.message.map((e, i) => {
            if (e.msg_type === 2001) {
              this.form.message[i].at = this.at
            }
          })
          if (this.form.send_time_type === 2 && !this.sendTime) {
            this.$message.error('请选择发送时间')
            return false
          }
          this.form.book_time = this.sendTime ? this.sendTime / 1000 : null
          const method = this.editType ? 'messageUpdate' : 'messageSend'
          this.loading = true
          this.COMM_HTTP[method](this.form).then(res => {
            this.loading = false
            if ((res && res.code) || (res && res.code === 0)) {
              this.$message.error(res.msg)
              return false
            }
            this.loading = false
            this.$message.success('任务创建成功')
            this.backComfirm = false
            const path = this.type === 'announcement' ? '/cloudService/batchAnnouncement/list' : '/cloudService/batchMessage/list'
            this.$router.push(path)
          })
        }
      })
    },
    /**
     * @description: 提交公告
     * @param {*}
     * @return {*}
     */
    handleSubmitAn () {
      if (!this.form.task_title) {
        this.$message.error('请输入群发公告名称')
        this.formScroll(this.$refs.form.$el)
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.group_ids.length < 1) {
            this.$message.error('请选择群聊')
            this.formScroll(this.$refs.task_group.$el)
            return
          }
          if (!this.form.content) {
            this.$message.error('请添加公告内容')
            this.formScroll(this.$refs.task_announcement.$el)
            return
          }
          // 群id转number
          this.form.group_ids = this.form.group_ids.map(e => {
            return e * 1
          })
          this.form.book_time = this.sendTime ? this.sendTime / 1000 : null
          const method = this.editType ? 'noticeUpdate' : 'noticeSend'
          this.loading = true
          this.COMM_HTTP[method](this.form).then(res => {
            if ((res && res.code) || (res && res.code === 0)) {
              this.$message.error(res.msg)
              return false
            }
            this.$message.success('任务创建成功')
            this.loading = false
            this.$emit('submit', false)
            this.$router.push('list')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.to-group {
  flex: 1;
  background: #f7f8fc;
  overflow: hidden;
  position: relative;
  .main-area {
    padding: 20px 20px 0 20px;
    display: flex;
    box-sizing: border-box;
    height: calc(100% - 56px);
  }
  .form-area {
    height: 100%;
    width: 800px;
    background: #fff;
    border-radius: 16px 0 0 0;
    .el-form {
      padding: 24px;
      .msg-temp:last-of-type {
        margin-bottom: 15px;
      }
      .type2005 {
        width: 271px;
      }
      .type2013 {
        width: 205px;
      }
    }
    .announcement-content {
      height: 290px;
      background: #fff;
      border: 1px solid #EAEBF2;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .el-textarea {
        flex: 1;
      }
      ::v-deep {
        .el-textarea__inner {
          border: unset;
          background: unset;
          resize: none;
          height: 100%;
        }
      }
      .emoji {
        display: flex;
        align-items: center;
        color: rgba($color: #000000, $alpha: 0.5);
        .emojibox {
          width: 40px;
          text-align: center;
        }
        img {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .h5-area {
    flex: 1;
    background: #fff;
    border-radius: 0 16px 0 0;
    box-sizing: border-box;
    ::v-deep {
      .mobile {
        margin: 40px auto;
      }
    }
  }
  .submit-area {
    height: 56px;
    line-height: 56px;
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 5px 2px #f3f3f3;
    z-index: 99;
  }
  ::v-deep {
    .xl-ly-table .ly-table-main {
      padding: 0;
    }
    .handler-left {
      margin-top: 0;
    }
    .el-form-item__label::before {
      content: "*";
      color: #ed5c49;
      margin-right: 4px;
    }
  }
}
</style>
