<!--
 * @Author: zhanln
 * @Date: 2022-03-24 17:30:21
 * @LastEditTime: 2022-03-31 14:33:53
 * @LastEditors: zhanln
 * @Description: 视频号直播-展示卡片
-->

<template>
  <div class="live">
    <div class="live-cover">
      <img :src="liveInfo.weappiconurl" alt="" />
    </div>
    <div class="live-title">
      <img :src="liveInfo.weappiconurl" class="live-icon" alt="" />
      <span>
        {{ liveInfo.title }}
      </span>
      <i class="iconfont icon-shipinhao q-warning"></i>
    </div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
export default {
  name: 'liveCard',
  props: ['info'],
  computed: {
    liveInfo () {
      return JSON.parse(Base64.decode(this.info))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
.live {
  width: 246px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dfe1e8;
  box-sizing: border-box;

  &-cover {
    position: relative;
    height: 260px;
    overflow: hidden;

    img {
      height: 100%;
      max-height: 100%;
      filter: brightness(90%);
    }

    .icon-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 40px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    margin: 12px;
    font-size: 12px;
    line-height: 1;
    span {
      flex: 1;
      @include ellipsis;
      margin-right: 8px;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &-desc {
    line-height: 1.6;
    margin: 0 12px 12px;
    @include ellipsis;
  }
}
</style>
