<!--
 * @Author: zhanln
 * @Date: 2022-03-04 10:23:26
 * @LastEditTime: 2022-03-17 18:25:44
 * @LastEditors: Please set LastEditors
 * @Description: 弹框
-->

<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    class="agrDialog"
    :top="dialogTop"
    :close-on-click-modal="closeOnModal"
    append-to-body
    @closed="fn_close"
  >
    <div class="" v-loading="pageLoading">
      <!-- 接入企微号 -->
      <robots v-if="type === 'robots'" ref="robotRef"></robots>
      <!-- 设置 -->
      <setting
        v-if="type === 'setting'"
        ref="settingRef"
        @close="visible = false"
        @refresh="fn_refresh"
      ></setting>
      <!-- 修改账号密码 -->
      <account
        v-if="type === 'account'"
        ref="accountRef"
        @close="visible = false"
        @refresh="fn_refresh"
      ></account>
    </div>
  </el-dialog>
</template>

<script>
import robots from './robots'
import setting from './setting'
import account from './account'
export default {
  name: 'agrDialog',

  components: {
    robots,
    setting,
    account
  },

  data () {
    return {
      visible: false,
      title: '',
      width: '456px',
      type: '',
      dialogTop: '15vh',
      pageLoading: true,
      closeOnModal: true
    }
  },

  methods: {
    open (type, item, opt) {
      this.title = opt.title
      this.width = opt.width
      this.type = type
      this.visible = true

      if (type === 'robots') {
        this.title = `接入企微号（${item.robot.length}）`
        this.$nextTick(() => {
          this.$refs.robotRef.fn_init(item)
        })
      }

      if (type === 'setting') {
        this.closeOnModal = false
        this.dialogTop = '35px'
        this.$nextTick(() => {
          this.$refs.settingRef.fn_init(item.id)
        })
      }

      if (type === 'account') {
        this.closeOnModal = false
        this.$nextTick(() => {
          this.$refs.accountRef.fn_init(item)
        })
      }

      this.$nextTick(() => {
        this.pageLoading = false
      })
    },

    fn_close () {
      this.dialogTop = '15vh'
      this.closeOnModal = true
      this.type = ''
    },

    fn_refresh () {
      this.$emit('refresh')
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.agrDialog {
  ::v-deep .el-dialog__body {
    color: #212b36;
  }
}
</style>
