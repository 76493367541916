var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "dorwRef", staticClass: "ly-dorw" },
    [
      _vm.list && _vm.list.length
        ? _c(
            "div",
            { staticClass: "tag_over" },
            [
              _vm.isSort
                ? [
                    _vm._l(_vm.list.slice(0, _vm.size), function(item, index) {
                      return [
                        _c(
                          "ly-tag",
                          {
                            key: index,
                            attrs: {
                              content: _vm.name ? item[_vm.name] : item,
                              only: false,
                              dep: _vm.dep,
                              avatar: _vm.avatarField
                                ? item[_vm.avatarField]
                                : item.avatar_url,
                              size: _vm.textSize
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "right" },
                              [
                                _vm._t("right", [
                                  _vm.right
                                    ? [
                                        _vm.rightType
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  "ly-dorw_right",
                                                  item[_vm.right] === 1
                                                    ? "q-success"
                                                    : "q-info"
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item[_vm.right] === 0
                                                      ? "(离线)"
                                                      : "(在线)"
                                                  )
                                                )
                                              ]
                                            )
                                          : [_vm._v(_vm._s(_vm.right))]
                                      ]
                                    : _vm._e()
                                ])
                              ],
                              2
                            )
                          ],
                          2
                        )
                      ]
                    })
                  ]
                : [
                    _vm._l(_vm.list, function(item, index) {
                      return [
                        _c(
                          "ly-tag",
                          {
                            key: index,
                            attrs: {
                              content: _vm.name ? item[_vm.name] : item,
                              only: false,
                              dep: _vm.dep,
                              avatar: _vm.avatarField
                                ? item[_vm.avatarField]
                                : item.avatar_url,
                              size: _vm.textSize
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "right" },
                              [
                                _vm._t("right", [
                                  _vm.right
                                    ? [
                                        _vm.rightType
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  "ly-dorw_right",
                                                  item[_vm.right] === 1
                                                    ? "q-success"
                                                    : "q-info"
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item[_vm.right] === 0
                                                      ? "(离线)"
                                                      : "(在线)"
                                                  )
                                                )
                                              ]
                                            )
                                          : [_vm._v(_vm._s(_vm.right))]
                                      ]
                                    : _vm._e()
                                ])
                              ],
                              2
                            )
                          ],
                          2
                        )
                      ]
                    })
                  ],
              _vm.list.length > _vm.size
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "ly-dorw_btn ly-dorw_down",
                        on: {
                          click: function($event) {
                            _vm.isSort = !_vm.isSort
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.isSort ? "展开" : "收起")),
                        _c("i", {
                          staticClass: "iconfont icon-up",
                          style: {
                            transform: _vm.isSort ? "rotate(180deg)" : "unset"
                          }
                        })
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : [_vm._v(_vm._s(_vm.empty))]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }