<template>
  <el-form-item
    class="normalTable-view normalTable-view-disabled"
    :prop="normalTable.model"
    :label="formIndex + 1 + '.' + normalTable.name"
  >
    <template v-if="normalTable.type == 'input'">
      <el-input
        v-if="normalTable.options.dataType == 'number'"
        type="number"
        v-model.number="dataModel"
        :placeholder="normalTable.options.placeholder"
        :disabled="normalTable.options.disabled"
        readonly
      ></el-input>
      <el-input
        v-else
        :type="normalTable.options.dataType"
        v-model="dataModel"
        :disabled="normalTable.options.disabled"
        :placeholder="normalTable.options.placeholder"
        readonly
      ></el-input>
    </template>

    <template v-if="normalTable.type == 'textarea'">
      <el-input
        type="textarea"
        :rows="5"
        v-model="dataModel"
        :disabled="normalTable.options.disabled"
        :placeholder="normalTable.options.placeholder"
        readonly
      ></el-input>
    </template>

    <template v-if="normalTable.type == 'number'">
      <el-input-number
        v-model="dataModel"
        :step="normalTable.options.step"
        controls-position="right"
        :disabled="normalTable.options.disabled"
        :min="normalTable.options.min"
        :max="normalTable.options.max"
        readonly
      ></el-input-number>
    </template>

    <template v-if="normalTable.type == 'radio'">
      <el-radio-group
        v-model="dataModel"
        :disabled="normalTable.options.disabled"
      >
        <el-radio
          :style="{
            display: normalTable.options.inline ? 'inline-block' : 'block'
          }"
          :label="item.value"
          v-for="(item, index) in normalTable.options.options"
          :key="index"
        >
          <template>{{ item.value }}</template>
        </el-radio>
      </el-radio-group>
    </template>

    <template v-if="normalTable.type == 'checkbox'">
      <el-checkbox-group
        v-model="dataModel"
        :disabled="normalTable.options.disabled"
      >
        <el-checkbox
          :style="{
            display: normalTable.options.inline ? 'inline-block' : 'block'
          }"
          :label="item.value"
          v-for="(item, index) in normalTable.options.options"
          :key="index"
        >
          <template>{{ item.value }}</template>
        </el-checkbox>
      </el-checkbox-group>
    </template>

    <template v-if="normalTable.type == 'select'">
      <el-select
        v-model="dataModel"
        :disabled="normalTable.options.disabled"
        :multiple="normalTable.options.multiple"
        :clearable="normalTable.options.clearable"
        :placeholder="normalTable.options.placeholder"
        :filterable="normalTable.options.filterable"
      >
        <el-option
          v-for="item in normalTable.options.options"
          :key="item.value"
          :value="item.value"
          :label="item.value"
        ></el-option>
      </el-select>
    </template>

    <template v-if="normalTable.type == 'area'">
      <el-select
        v-model="normalTable.options.pro"
        :placeholder="normalTable.options.placeholder"
        style="margin-bottom: 8px"
        :disabled="normalTable.options.disabled"
      >
        <el-option label="北京" value="北京"></el-option>
      </el-select>
      <div>
        <el-input
          type="textarea"
          :rows="5"
          v-model="normalTable.options.desc"
          :disabled="normalTable.options.disabled"
          :placeholder="normalTable.options.descplaceholder"
          readonly
        ></el-input>
      </div>
    </template>
  </el-form-item>
</template>

<script>
import area from './area.js'
// import FmUpload from './Upload'

export default {
  props: ['normalTable', 'models', 'rules', 'formIndex'],
  components: {
    // FmUpload
  },
  data () {
    function getArea () {
      let json = {}

      for (const [a, b] of Object.entries(area.province_list)) {
        let _startWith = a.substr(0, 2)
        json[_startWith] = {
          value: a,
          label: b,
          children: {}
        }
      }

      for (const [a, b] of Object.entries(area.city_list)) {
        let _cityStartWith = a.substr(0, 2)
        let _citymiddleWite = a.substr(2, 2)
        json[_cityStartWith].children[_citymiddleWite] = {
          value: a,
          label: b,
          children: []
        }
      }
      for (const [a, b] of Object.entries(area.county_list)) {
        let _cityStartWith = a.substr(0, 2)
        let _citymiddleWite = a.substr(2, 2)
        json[_cityStartWith].children[_citymiddleWite].children.push({
          value: a,
          label: b,
        })
      }

      return json
    }
    return {
      dataModel: this.models[this.normalTable.model],
      areaJSON: getArea(),
      prolist: [],
    }
  },
  created () {
  },
  methods: {
    initProlist () {
      if (!!this.prolist.length) return

      this.prolist = Object.values(this.areaJSON)
    },
    changePro (com, ind) {
      let _startWith = com.pro.slice(0, 2)
      com.city = com.area = ''
      com.citylist = this.areaJSON[_startWith].children
      com.arealist = []
    },
    changeCity (com, ind) {
      let _startWith = com.pro.slice(0, 2)
      let _middleWith = com.city.slice(2, 4)
      com.area = ''
      com.arealist = this.areaJSON[_startWith].children[_middleWith].children
    },
  },
  watch: {
    models: {
      deep: true,
      handler (val) {
        this.dataModel = val[this.normalTable.model]
      }
    },
    areaJSON: {
      handler (newVal) {
        this.initProlist()
      },
      deep: true,
      immediate: true
    },
  }
}
</script>
