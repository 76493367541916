var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "select"
        ? _c(
            "el-select",
            {
              staticClass: "emp-select",
              attrs: {
                "popper-class": "hide-select",
                "popper-append-to-body": false,
                placeholder: _vm.checkList.length > 0 ? "" : _vm.placeholder,
                disabled: _vm.selectDisabled
              },
              on: { focus: _vm.fn_showDialog },
              model: {
                value: _vm.selectValue,
                callback: function($$v) {
                  _vm.selectValue = $$v
                },
                expression: "selectValue"
              }
            },
            [
              _vm.checkList.length > 0
                ? _c(
                    "template",
                    { slot: "prefix" },
                    [
                      _vm._l(_vm.checkList.slice(0, _vm.showLength), function(
                        item,
                        index
                      ) {
                        return _c(
                          "span",
                          { key: item.id, staticClass: "emp-select--item" },
                          [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            index !== _vm.checkList.length - 1
                              ? _c("span", { staticClass: "emp-select--gap" }, [
                                  _vm._v(",")
                                ])
                              : _vm._e()
                          ]
                        )
                      }),
                      _vm.checkList.length - _vm.showLength > 0
                        ? _c("span", { staticClass: "emp-select--more" }, [
                            _vm._v(
                              "+" +
                                _vm._s(_vm.checkList.length - _vm.showLength)
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        : _c(
            "el-button",
            { attrs: { type: _vm.btnType }, on: { click: _vm.fn_showDialog } },
            [_vm._v(_vm._s(_vm.btnText))]
          ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "custom-class": "q-dialog",
            width: "800px",
            "close-on-click-modal": false,
            modal: _vm.modal,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { width: "100%" },
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c("div", { staticClass: "flex flex-between" }, [
                _c("div", { staticClass: "el-dialog__title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _c(
                  "div",
                  {
                    staticClass: "el-dialog__header-close",
                    on: { click: _vm.fn_cancle }
                  },
                  [_c("i", { staticClass: "el-icon-close" })]
                )
              ])
            ]
          ),
          _c("div", { staticClass: "emp" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "emp-left",
                attrs: { "element-loading-text": "数据加载中" }
              },
              [
                _c("el-input", {
                  attrs: {
                    size: "medium",
                    placeholder: _vm.inputPlaceholder,
                    clearable: ""
                  },
                  model: {
                    value: _vm.filterText,
                    callback: function($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText"
                  }
                }),
                _c("div", { staticClass: "emp-all" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.empAll) + "（" + _vm._s(_vm.empTotal) + "）"
                    )
                  ]),
                  _vm.chooseType === "mkdir"
                    ? _c(
                        "span",
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { indeterminate: _vm.indeterminate },
                              on: { change: _vm.fn_changeAll },
                              model: {
                                value: _vm.checkedAll,
                                callback: function($$v) {
                                  _vm.checkedAll = $$v
                                },
                                expression: "checkedAll"
                              }
                            },
                            [_vm._v("全选")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "emp-tree" },
                  [
                    _c("el-tree", {
                      ref: "empTree",
                      class: [
                        "tree",
                        _vm.isFilter ? "is-filter" : "",
                        _vm.chooseType
                      ],
                      attrs: {
                        data: _vm.treeData,
                        props: _vm.defaultProps,
                        "default-expanded-keys": _vm.defaultOpenId,
                        "filter-node-method": _vm.fn_filterNode,
                        "node-key": "id",
                        "show-checkbox": "",
                        accordion: "",
                        "expand-on-click-node": _vm.chooseType !== "mkdir"
                      },
                      on: {
                        "node-click": _vm.fn_nodeClick,
                        check: _vm.fn_getCheckList
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "div",
                              { staticClass: "tree-content" },
                              [
                                data.mkdir
                                  ? [
                                      !node.expanded
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-folder tree-icon--foolder"
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-folder-opened tree-icon--foolder"
                                          }),
                                      _c(
                                        "div",
                                        { staticClass: "tree-content--label" },
                                        [
                                          _vm._v(" " + _vm._s(data.name) + " "),
                                          _vm.chooseType === "file"
                                            ? [
                                                _vm._v(
                                                  "(" + _vm._s(data.count) + ")"
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        { staticClass: "flex tree-user" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex flex-column flex-start tree-user--info"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(data.name))
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                              ],
                              2
                            )
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "emp-right" }, [
              _c("div", { staticClass: "emp-all emp-all--right" }, [
                _c("div", [
                  _vm._v(" 已选（" + _vm._s(_vm.checkList.length)),
                  _vm.max
                    ? _c("span", [_vm._v("/" + _vm._s(_vm.max))])
                    : _vm._e(),
                  _vm._v("） "),
                  _vm.readyText
                    ? _c("span", [_vm._v(", " + _vm._s(_vm.readyText))])
                    : _vm._e()
                ])
              ]),
              _c(
                "div",
                { staticClass: "emp-check" },
                _vm._l(_vm.checkList, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "flex flex-between emp-check--item"
                    },
                    [
                      _vm.chooseType == "mkdir"
                        ? [
                            _c("i", {
                              staticClass: "el-icon-folder tree-icon--foolder"
                            }),
                            _c("div", { staticClass: "tree-content--label" }, [
                              _vm._v(" " + _vm._s(item.name) + " ")
                            ])
                          ]
                        : _c("div", { staticClass: "flex tree-user" }, [
                            _c(
                              "div",
                              { staticClass: "tree-user--avatar flex" },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          ]),
                      _c(
                        "div",
                        {
                          staticClass: "emp-check--close",
                          on: {
                            click: function($event) {
                              return _vm.fn_removeCheck(item.id)
                            }
                          }
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      )
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ]),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "div",
              { staticClass: "flex flex-between" },
              [
                _c(
                  "el-button",
                  { attrs: { round: "" }, on: { click: _vm.fn_clearCheck } },
                  [_vm._v("清空所选")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      { attrs: { round: "" }, on: { click: _vm.fn_cancle } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", type: "primary" },
                        on: { click: _vm.fn_getEmpId }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }