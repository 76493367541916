/*
 * @Author: zhanln
 * @Date: 2021-07-26 14:58:55
 * @LastEditTime: 2022-08-26 16:51:12
 * @LastEditors: zhan
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

// 活动
export const activity = {
  edit: params => axios.post('/wecom/book-lottery/save', params),
  detail: params => axios.get(`/wecom/book-lottery/show?${stringify(params)}`),
  reqList: params => axios.get('/wecom/book-lottery/select')
}

// 活动列表
export const list = {
  reqQuery: params => axios.get(`/wecom/book-lottery/list?${stringify(params)}`),
  changeShow: params => axios.post('/wecom/book-lottery/change-show', params), // 预约有礼-修改首页展示
  end: params => axios.post('/wecom/book-lottery/fold-up', params), // 预约有礼-结束活动
  destroy: params => axios.post('/wecom/book-lottery/destroy', params) // 预约有礼-删除活动
}

// 参与用户
export const user = {
  reqQuery: params => axios.get(`/wecom/book-lottery/account-list?${stringify(params)}`),
  export: params => axios.get(`/wecom/book-lottery/account-export?${stringify(params)}`),
  channelSel: params => axios.get(`/wecom/book-lottery/channel-list?${stringify(params)}`), // 参与用户/黑名单 渠道下拉选择项
  addBlack: params => axios.post('/wecom/task/move-in-black', params), // 加入黑名单
  removeBlack: params => axios.post('/wecom/task/move-out-black', params) // 移除黑名单
}

export const popularize = {
  reqQuery: params => axios.get(`/wecom/book-lottery/channel-list?${stringify(params)}`),
  create: params => axios.post('/wecom/book-lottery/channel-create', params), // 创建渠道
  update: params => axios.post('/wecom/book-lottery/channel-update', params), // 更新渠道
  delete: params => axios.post('/wecom/book-lottery/channel-delete', params) // 删除渠道
}

export const reserve = {
  reqQuery: params => axios.get(`/wecom/book-lottery/reserve-list?${stringify(params)}`), // 预约有礼-预约记录列表
  export: params => axios.get(`/wecom/book-lottery/reserve-export?${stringify(params)}`) // 预约有礼-导出预约记录列表
}

// 授权信息
export const auth = {
  authMpInfo: params => axios.get('/wecom/open-platform/auth-mp-info')
}

// 表单详情
export const fDetails = {
  reqQuery: params => axios.post('/scrm-api/form/business/list', params),
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`) // 获取单个表单组件
}

// 奖品记录
export const reward = {
  reqQuery: params => axios.get(`/wecom/book-lottery/reward-list?${stringify(params)}`),
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/id/${params.formId}/requestId/${params.requestId}/details`) // 获取单个表单组件
}
