<!--
 * @Author: zhanln
 * @Date: 2021-10-09 18:12:27
 * @LastEditTime: 2022-08-05 17:51:35
 * @LastEditors: zhan
 * @Description: 活动群管理
-->

<template>
  <div class="ly-table-group page-warp">
    <!-- searchBar -->
    <div class="ly-search">
      <el-form :inline="true" size="medium">
        <el-form-item label="选择活动：">
          <el-select v-model="activityId" filterable style="width: 320px" @change="fn_selectAct">
            <el-option v-for="item in activityList" :key="item.id" :label="item.title" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- searchBar end -->

    <ly-table class="act-list" ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :page-size="per_page"
      :total="total" @searchChange="searchChange" @sizeChange="handleSizeChange" @sortChange="handleSortChange"
      @currentChange="handleCurrentChange" @selectionChange="handleSelectionChange">
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button type="primary" icon="el-icon-plus" size="medium" round
          :disabled="loading || (!hasCloudBean && msg_mode === 1)" @click="fn_openAddGroup">新增群聊</el-button>
      </template>
      <!-- 列插槽 -->
      <template #groupMaster="{ row }">
        <ly-tag :content="row.group_master" :size="12"></ly-tag>
      </template>
      <template #countHeaderSlot>
        <span style="margin-right: 4px">自动切群人数</span>
        <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
          <div slot="content">
            每个群入群人数达到「自动切换人数」后，自动切换下个群，<br />
            当群的总人数达到190人后，不再参与分配。
          </div>
          <i class="icon-info-fill iconfont"></i>
        </el-tooltip>
      </template>
      <template #idheaderSlot>
        <span style="margin-right: 4px">云端账号</span>
        <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
          <div slot="content">刷新云端账号状态</div>
          <i class="iconfont icon-shuaxin" style="cursor: pointer" @click="queryData"></i>
        </el-tooltip>
      </template>
      <template #idSlot="{ row }">
        <more-popover title="企业成员" :list="row.yun_account || []">
          <template #reference>{{
              `${getOnlineCount(row.yun_account)}人 `
          }}</template>在线
          <template #item="{ item }">
            <div class="ly-team small">
              <i class="el-icon-s-custom" style="font-size: 16px"></i>
            </div>
            <span class="m-group_name text-ellipsis">
              {{ item.member_name }}
              <span style="color: #00a86e" v-if="item.online_status">(在线)</span>
              <span style="color: #909399" v-else>(离线)</span>
            </span>
          </template>
        </more-popover>
      </template>
      <template #countSlot="{ row }">
        <div class="flex">
          <template v-if="row.edit_count">
            <el-input-number v-model="row.max_account" controls-position="right" :min="1" :max="190" size="small"
              class="number-small" v-if="row.edit_count"></el-input-number>
            <span class="group-edit__btn" @click="fn_changeMax(row)">
              <i class="iconfont icon-success"></i>
            </span>
          </template>
          <template v-else>
            {{ row.max_account }}
            <span class="group-edit__btn" @click="row.edit_count = true">
              <i class="iconfont icon-edit"></i>
            </span>
          </template>
        </div>
      </template>
      <template #operation="{ row, index }">
        <ly-action :key="Date.now()" :display="2" v-if="msg_mode === 1">
          <el-button type="text" size="small" @click="fn_msgAccount(row)" v-if="hasCloudBean">消息号</el-button>
          <el-button type="text" size="small" @click="fn_groupTransfer(row)" :disabled="row.can_change_master === 0"
            v-if="hasCloudBean">
            转让群主</el-button>
          <el-button type="text" icon="iconfont icon-delete" :disabled="tableData.length === 1" @click="fn_delete(row)">
            移除</el-button>
          <el-button type="text" icon="iconfont icon-up" :disabled="index === 0"
            @click="fn_changeSort(index, row, 'up')">上移</el-button>
          <el-button type="text" icon="iconfont icon-down" :disabled="index === tableData.length - 1"
            @click="fn_changeSort(index, row, 'down')">下移</el-button>
        </ly-action>
        <ly-action :key="Date.now()" v-else>
          <el-button type="text" :disabled="tableData.length === 1" @click="fn_delete(row)">移除</el-button>
          <el-button type="text" :disabled="index === 0" @click="fn_changeSort(index, row, 'up')">上移</el-button>
          <el-button type="text" :disabled="index === tableData.length - 1" @click="fn_changeSort(index, row, 'down')">
            下移</el-button>
        </ly-action>
      </template>
    </ly-table>

    <msg-account ref="msgAccountRef" @fn_changeMsgAccount="fn_changeMsgAccount"></msg-account>

    <!-- 转让群主 -->
    <group-transfer ref="groupTransferRef" :gid="gid" @success="fn_selectAct(activityId)"></group-transfer>

    <!-- 选择群弹框 -->
    <select-group ref="selectGroupRef" :hasCheck="true" :selectList="selectList" @selectClose="fn_selectClose">
    </select-group>

    <!-- 新增群聊 -->
    <add-group ref="addGroupRef" :activityId="activityId" @addSuccess="fn_addSuccess"></add-group>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import MorePopover from '@/components/MorePopover'
import lyTag from '@/components/lyTag'
import groupTransfer from './components/groupTransfer'
import msgAccount from './components/msgAccount'
import selectGroup from './activity/selectGroup'
import addGroup from './components/addGroup'
import { groupManage, activity } from './http.js'
export default {
  name: 'group',
  mixins: [lyTable],
  components: {
    MorePopover,
    lyTag,
    groupTransfer,
    msgAccount,
    selectGroup,
    addGroup
  },

  data () {
    return {
      COMM_HTTP: groupManage,
      dynamicColumn: [],
      activityList: null,
      activityId: null,
      IMMEDIATE: false,
      gid: null,
      uploadSetting: {
        limit: null,
        'show-file-list': false
      },
      msg_mode: null,
      currentGroupIds: null,
      currentRow: null,
      selectList: [],
      loading: false
    }
  },

  async created () {
    if (this.$route.query.id) {
      this.activityList = await this.fn_getActList()
      this.activityId = +this.$route.query.id
      this.queryData()
    }
  },

  computed: {
    tableOpt () {
      return {
        layout: 'hide',
        operationWidth: 180,
        column: [
          {
            label: '群名称',
            prop: 'group_name',
            search: true,
            placeholder: '请输入群名称',
            key: 'group_name',
            formatter: (row, column, cellValue, index) => {
              return cellValue || '群聊'
            }
          },
          {
            label: '群主',
            prop: 'group_master',
            slot: 'groupMaster',
            search: true,
            type: 'select',
            sourceField: {
              value: 'value',
              label: 'title'
            },
            source: this.fn_getSearchData,
            placeholder: '请选择群主',
            align: 'center'
          },
          {
            label: '群人数',
            prop: 'group_account_num',
            align: 'center'
          },
          ...this.dynamicColumn,
          {
            label: '自动切群人数',
            prop: 'max_account',
            slot: 'countSlot',
            headerSlot: 'countHeaderSlot'
          }
        ]
      }
    },
    hasCloudBean () {
      return this.$store.state.permission.hasCloudBean
    }
  },

  methods: {

    // 进行中活动列表
    async fn_getActList () {
      const data = await this.COMM_HTTP.actList()
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      return data
    },

    // 切换活动
    async fn_selectAct (val) {
      this.activityId = val
      this.$refs.lyTable.$children[0].initFrom()
      this.queryData()
    },

    // 活动详情
    async queryData () {
      this.dynamicColumn = []
      this.loading = true
      const res = await this.COMM_HTTP.reqQuery({
        activity_id: this.activityId,
        ...this.params
      })
      if ((res && res.code) || (res && res.code === 0)) {
        this.loading = false
        this.$message.error(res.msg)
        return
      }
      this.msg_mode = res.msg_mode

      if (this.msg_mode === 1) {
        this.dynamicColumn.push({
          label: '云端账号',
          prop: 'yun_account',
          slot: 'idSlot',
          headerSlot: 'idheaderSlot',
          order: 4
        })
      }

      if (res.list) {
        const timeData = res.list.map(item => {
          item.edit_count = false
          return item
        })
        timeData.sort((a, b) => {
          return a.sort < b
        })

        // 只能模式下判断群码是否自动
        // if (this.msg_mode === 1) {
        //   this.tableData = await this.fn_getCodeStatus(timeData, 'group_id')
        // } else {
        this.tableData = timeData
        // }
      } else {
        this.tableData = []
      }

      this.$nextTick(() => {
        this.$refs.lyTable.doLayout()
      })

      this.loading = false
      return res
    },

    // 是否需要上传群码
    async fn_getCodeStatus (tableData, name) {
      const resData = this.util.extend(true, [], tableData)

      const groupIds = resData.map((item) => {
        return item[name]
      })

      const data = await activity.groupList({
        group_ids: groupIds.join(',')
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      const batchList = data
      for (let i = 0; i < batchList.length; i++) {
        const bid = batchList[i].group_id

        for (let j = 0; j < resData.length; j++) {
          const tid = resData[j][name]

          if (bid === tid) {
            resData[j].auto_create_qrcode = batchList[i].auto_create_qrcode
            resData[j].qrcode_type = batchList[i].auto_create_qrcode ? 2 : 1
          }
        }
      }

      return resData
    },

    // 获取在线数量
    getOnlineCount (arr) {
      let temp = []
      if (arr) {
        temp = arr.filter(e => e.online_status)
      }
      return temp.length
    },

    // 获取供搜索的数据
    async fn_getSearchData () {
      const data = await this.COMM_HTTP.searchData({
        activity_id: this.activityId || +this.$route.query.id
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      return data.master
    },

    // 修改切群人数
    fn_changeMax (row) {
      row.edit_count = false
      this.fn_update([row])
    },

    // 修改
    async fn_update (rows, tip = true) {
      this.loading = true
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]
        const data = await this.COMM_HTTP.update({
          id: row.id,
          max_account: row.max_account,
          qrcode_url: row.group_code,
          sort: row.sort,
          send_user: row.send_user
        })

        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          return false
        }
      }

      if (tip) {
        this.fn_lyMsg('success', '修改成功！')
        if (this.$refs.msgAccountRef.visible) {
          this.$refs.msgAccountRef.visible = false
          this.currentRow = null
        }
      }
      this.queryData()
    },

    // 修改群码
    fn_changeCode (img, row) {
      row.group_code = img
      this.fn_update([row], false)
    },

    // 移除群
    fn_delete ({ id }) {
      this.$lyConfirm({
        title: '确定移除此群吗？',
        text: ''
      }).then(async () => {
        const data = await this.COMM_HTTP.delete({
          id
        })
        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          return false
        }
        this.fn_lyMsg('success', '删除成功！')
        this.queryData()
      }).catch(() => { })
    },

    // 消息号设置
    fn_msgAccount (row) {
      this.currentRow = row
      this.$refs.msgAccountRef.tableData = row.yun_account.map(item => {
        item.isChecked = item.send_type
        return item
      })
      this.$refs.msgAccountRef.visible = true
    },

    // 修改消息号
    fn_changeMsgAccount (ids) {
      this.currentRow.send_user = ids
      this.fn_update([this.currentRow])
    },

    // 转让群主
    fn_groupTransfer (row) {
      this.gid = row.group_id
      this.$refs.groupTransferRef.radioId = null
      this.$refs.groupTransferRef.visible = true
    },

    // 移动排序
    fn_changeSort (index, row, type) {
      let rowObj = null

      // 上移
      if (type === 'up') {
        rowObj = this.tableData[index - 1]
      }

      // 下移
      if (type === 'down') {
        rowObj = this.tableData[index + 1]
      }

      const prev = this.util.extend(true, {}, rowObj)
      prev.sort = row.sort
      row.sort = rowObj.sort
      this.fn_update([prev, row])
    },

    // 新增群聊（按钮）
    fn_openAddGroup () {
      this.$refs.selectGroupRef.type = this.msg_mode
      this.$refs.selectGroupRef.btnText = '下一步'
      this.currentGroupIds = this.tableData.map(item => +item.group_id)
      this.selectList = []
      this.$refs.selectGroupRef.dialogVisible = true
    },

    // 新增群回调(下一步)
    async fn_selectClose (data) {
      console.log('data', data)
      if (data.length === 0) {
        return this.fn_lyMsg('info', '请选择要新增的群聊！')
      }

      data.map((item, index) => {
        item.max_account = 190
        item.qrcode_type = 1
        item.qrcode_url = ''
        item.sort = this.tableData[this.tableData.length - 1].sort + index + 1
        item.code_error = false
        item.auto_create_qrcode = false
      })

      if (this.msg_mode === 1) {
        const list = await this.fn_getCodeStatus(data, 'id')
        this.$refs.addGroupRef.tableData = list
      } else {
        this.$refs.addGroupRef.tableData = this.util.extend(true, [], data)
      }

      this.$refs.addGroupRef.visible = true
    },

    // 新增成功回调
    fn_addSuccess () {
      this.$refs.addGroupRef.visible = false
      this.$refs.selectGroupRef.dialogVisible = false
      this.queryData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.ly-table-group {
  .ly-search {
    padding-left: 20px;
  }

  ::v-deep {
    .el-form-item {
      margin-bottom: 20px;
    }

    .ly-table-main {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
}

.group {
  &-edit {
    &__btn {
      font-size: 16px;
      color: $--color-text-secondary;
      margin-left: 6px;
      cursor: pointer;
      transition: 0.25s color;

      &:hover {
        color: $--color-primary;
      }
    }
  }

  &-upload {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
    }

    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }

      &:hover {
        .tips-opacity {
          opacity: 1;
        }
      }
    }

    &__icon {
      width: 70px;
      height: 70px;
      line-height: 70px;
    }

    &__tips {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      opacity: 0;
      transition: opacity 0.3s;
      line-height: 72px;

      i {
        color: #fff;
      }
    }
  }
}

.number-small {
  width: 120px;
  margin: 0 4px;

  ::v-deep .el-input__inner {
    padding-left: 4px;
    padding-right: 36px;
  }

  ::v-deep {

    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}
</style>
