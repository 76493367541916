var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-dialog",
    {
      staticClass: "owner-dialog",
      attrs: {
        title: "消息号设置",
        visible: _vm.visible,
        setting: _vm.dialogSetting
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.fn_open,
        confirm: _vm.fn_confirm
      }
    },
    [
      _c(
        "div",
        { staticClass: "q-info", staticStyle: { "margin-bottom": "16px" } },
        [
          _c("i", { staticClass: "icon-warning-fill iconfont" }),
          _vm._v(
            " 勾选的成员将成为消息号，在群内自动发送消息，如果勾选多个，则使用多个成员随机轮流发送消息。 "
          )
        ]
      ),
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "owner-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          "page-size": _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "online_status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.online_status
                  ? _c("span", { staticClass: "q-success" }, [_vm._v("在线")])
                  : _c("span", { staticClass: "q-warning" }, [_vm._v("离线")])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }