/*
 * @Author: zhanln
 * @Date: 2021-07-26 14:58:55
 * @LastEditTime: 2022-06-16 14:31:28
 * @LastEditors: zhan
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

// 授权信息
export const auth = {
  authMpInfo: params => axios.get('/wecom/open-platform/auth-mp-info'),
  authMpVer: params => axios.get('/wecom/open-platform/auth-mp-ver')
}

// 参与用户
export const user = {
  reqQuery: params => axios.get(`/wecom/task/accounts?${stringify(params)}`),
  export: params => axios.get(`/wecom/task/account-export?${stringify(params)}`),
  channelSel: params => axios.get(`/wecom/task/channel-list?${stringify(params)}`), // 参与用户/黑名单 渠道下拉选择项
  addBlack: params => axios.post('/wecom/task/move-in-black', params), // 加入黑名单
  removeBlack: params => axios.post('/wecom/task/move-out-black', params) // 移除黑名单
}

export const activity = {
  reqList: params => axios.get('/wecom/task/select'),
  checkTitle: params => axios.post('/wecom/task/has-same-name', params)
}

export const popularize = {
  reqQuery: params => axios.get(`/wecom/task/channel-list?${stringify(params)}`)
}
