<!--
 * @Author: zhanln
 * @Date: 2021-07-02 10:54:40
 * @LastEditTime: 2021-12-30 14:31:14
 * @LastEditors: Please set LastEditors
 * @Description:  发送详情
-->

<template>
  <el-dialog
    title="发送详情"
    :visible.sync="visible"
    custom-class="q-dialog"
    width="800px"
    @open="queryData"
  >
    <div class="detail">
      <ly-table
        ref="lyTable"
        :data="tableData"
        :option="tableOpt"
        :page="page"
        :pageSize="per_page"
        :total="total"
        @searchChange="searchChange"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelectionChange"
      >
        <template #handler>
          共 {{ resData.total_num }} 条，已发送 ：{{ resData.finish_num }}，待发送：{{ resData.sending_num }}，发送失败：{{ resData.fail_num }}
        </template>

        <template #handlerright>
          <el-button round size="medium" type="primary" @click="retry" v-if="status === 3 && resData.fail_num">失败重发</el-button>
          <template v-else>
            <el-tooltip class="item" effect="dark" content="群发任务还未完成" placement="top" v-if="status !== 3">
              <el-button round class="is-disabled" size="medium" type="primary">失败重发</el-button>
            </el-tooltip>
            <el-button round class="is-disabled" size="medium" type="primary" v-else>失败重发</el-button>
          </template>
        </template>

        <template #robot_info="{ row }">
          <span class="nick text-ellipsis">{{ row.robot_info.name }}</span>
          <span :class="row.robot_info.online_status === 1 ? 'online' : 'offline'">({{ row.robot_info.online_status === 1 ? '在线': '离线' }})</span>
        </template>

        <template #status_slot="{ row }">
          <span :style="getStatusStyle(row.status)">{{
            formatterStatus(row.status)
          }}</span>
        </template>
      </ly-table>
    </div>
  </el-dialog>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { sendDetail } from '../http.js'
export default {
  name: 'sendDetail',
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: sendDetail,
      IMMEDIATE: false,
      visible: false,
      task_id: '',
      type: '',
      status: 0
    }
  },
  computed: {
    tableOpt () {
      return {
        layout: 'hide',
        levitate: false,
        operation: 'hide',
        tableProps: {
          height: 400
        },
        column: [
          {
            label: '群名称',
            prop: 'group_name',
            columnProps: {
              'class-name': 'group-name'
            }
          },
          {
            label: '群发账号',
            prop: 'robot_info',
            slot: 'robot_info',
            columnProps: {
              'class-name': 'send-acount'
            }
          },
          {
            label: '发送状态',
            prop: 'status',
            slot: 'status_slot',
            width: 100,
            search: true,
            type: 'select',
            source: [
              {
                value: 0,
                label: '全部'
              },
              {
                value: 1,
                label: '待发送'
              },
              {
                value: 2,
                label: '发送中'
              },
              {
                value: 3,
                label: '发送成功'
              }
            ]
          },
          {
            label: '发送失败原因',
            prop: 'remark'
          },
          {
            label: '发送时间',
            prop: 'send_at',
            width: '170',
            formatter: (row, column, cellValue, index) => {
              if (!cellValue) {
                return '-'
              }
              return this.$day.unix(cellValue).format('YYYY-MM-DD HH:mm:ss')
            }
          }
        ]
      }
    },
    resData () {
      return (this.listData && this.listData.statistics) || {}
    }
  },
  methods: {
    retry () {
      this.$lyConfirm({
        title: `是否对${this.resData.fail_num}个群发失败的群进行群发`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.COMM_HTTP.retry({
          message_type: this.type ? 2 : 1,
          task_id: this.task_id
        }).then(res => {
          this.queryData()
        })
      })
    },
    formatterParams (params) {
      return {
        ...params,
        message_type: this.type ? 2 : 1,
        task_id: this.task_id
      }
    },
    getStatusStyle (val) {
      const style = {}
      switch (val) {
        case 1:
          style.color = '#FAAD14'
          break
        case 2:
          style.color = '#2B7CFF'
          break
        case 4:
          style.color = '#F56C6C'
          break
      }
      return style
    },

    formatterStatus (val) {
      let text = '-'
      switch (val) {
        case 1:
          text = '待发送'
          break
        case 2:
          text = '发送中'
          break
        case 3:
          text = '发送成功'
          break
        case 4:
          text = '发送失败'
          break
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  max-height: 50vh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }
    .handler-left,
    .handler-right {
      margin-bottom: 16px;
    }
  }

  .xl-ly-table {
    flex: 1;
    overflow: hidden;
    ::v-deep {
      .handler-left{
        color: #6E788A
      }
      .ly-table-main{
        margin-bottom: 0;
      }
      .group-name .cell{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .send-acount .cell{
        display: flex;
        .nick{
          max-width: 66%;
          margin-right: 5px;
        }
      }
      .el-table {
        display: flex;
        flex-direction: column;
        .el-table__header-wrapper {
          overflow: unset;
        }
        .el-table__body-wrapper {
          overflow-x: hidden;
          overflow-y: scroll;
        }
      }
    }
  }
  .online{
    color: #67C23A;
  }
  .offline{
    color: #6E788A;
  }
}

::v-deep{
  .el-dialog{
    .el-form{
      padding: 0;
      margin-bottom: 0;
    }
  }
}
</style>
