<!--
 * @Author: zhanln
 * @Date: 2021-10-11 14:55:25
 * @LastEditTime: 2021-12-16 11:11:49
 * @LastEditors: zhanln
 * @Description: 裂变层级分析
-->
<template>
  <alys-card :title="title" ref="cardRef">
    <div
      v-if="!actId"
      class="ly-table-empty flex flex-center"
      style="height: 336px; box-sizing: border-box"
    >
      <img src="@assets/svg/default/no_data.svg" alt="" />
      暂无数据
    </div>

    <!-- 工具栏 -->
    <template #toolbar v-if="actId">
      <div class="flex">
        <!-- 显示模式(showType: 1.图表, 2.列表) -->
        <el-button-group style="margin-right: 20px">
          <el-button
            :type="item.value === showType ? 'primary' : ''"
            v-for="(item, index) in typeList"
            :key="index"
            @click="showType = item.value"
            >{{ item.label }}</el-button
          >
        </el-button-group>

        <!-- 自定义日期 -->
        <el-date-picker
          v-model="dateValue"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="endDatePickerOptions"
          @change="fn_changeDate"
          :clearable="false"
          style="margin-right: 20px"
        >
        </el-date-picker>

        <el-radio-group
          v-model="trendType"
          @change="fn_setTrend"
          v-show="showType === 1"
        >
          <el-radio :label="1">每日累计数据</el-radio>
          <el-radio :label="2">每日新增数据</el-radio>
        </el-radio-group>

        <ly-export
          :exportData="exportData"
          :exportName="exportName"
          :exportSheetName="exportSheetName"
          dateFormat="YYYY年MM月DD日HH时mm分"
          :disabled="tableData.length === 0"
          v-if="showType === 2"
        ></ly-export>
      </div>
    </template>

    <!-- 趋势图 -->
    <c-line
      :option="trendOption"
      ref="trendRef"
      v-show="showType === 1 && actId"
    ></c-line>

    <!-- 列表 -->
    <el-table
      v-show="showType === 2"
      :data="tableData"
      style="width: 100%"
      height="300"
    >
      <el-table-column
        :prop="item.prop"
        v-for="(item, index) in tableHead"
        :key="index"
      >
        <template slot="header">
          {{ item.label }}
          <el-tooltip
            v-if="item.tip"
            class="item"
            effect="dark"
            placement="top"
            :content="item.tip"
            popper-class="q-tooltip"
          >
            <i class="ri-information-line"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <div slot="empty" class="ly-table-empty">
        <slot name="empty">
          <img src="@assets/svg/default/no_data.svg" alt="" />
          暂无数据
        </slot>
      </div>
    </el-table>
  </alys-card>
</template>

<script>
import alysCard from './card' // 卡片布局
import cLine from '@/components/lyChart/line' // 折线图
import lyExport from '@/components/lyExport' // 本地导出excel
import { analyse } from '../http'
export default {
  name: 'alysTrend',

  props: {
    actId: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    actName: {
      type: String,
      default: ''
    },
    actIndex: {
      type: String,
      default: ''
    },
    actStartTime: {
      type: String,
      default: ''
    },
    actEndTime: {
      type: String,
      default: ''
    }
  },

  watch: {
    actIndex (val) {
      if (val) {
        this.startTime = this.$day(this.actStartTime).format('YYYY-MM-DD') + ' 00:00:00'

        const today = new Date().getTime()
        const endDay = new Date(this.actEndTime).getTime()

        this.endTime = endDay > today ? this.$day().format('YYYY-MM-DD') + ' 23:59:59' : this.$day(this.actEndTime).format('YYYY-MM-DD') + ' 23:59:59'
        this.dateValue = [this.startTime, this.endTime]
        this.trendType = 1
        this.fn_getData()
      }
    },
    showType (val) {
      if (val === 1 && this.actId) {
        this.$refs.trendRef.fn_resize(this.$refs.cardRef.$el.clientWidth - 40)
      }
    }
  },

  data () {
    return {
      COMM_HTTP: analyse,
      pageLoading: true,
      showType: 1,
      typeList: [
        {
          label: '趋势走势图',
          value: 1
        },
        {
          label: '趋势表格',
          value: 2
        }
      ],
      dateType: null,
      dateList: [
        {
          label: '今日',
          value: 1
        },
        {
          label: '近7日',
          value: 2
        },
        {
          label: '近30日',
          value: 3
        }
      ],
      dateValue: '',
      startTime: null,
      endTime: null,
      trendType: 1,
      timeOptionRange: null,
      trendOption: {
        series: {
          showSymbol: false,
          animationDuration: 1000
        }
      },
      tableHead: [
        {
          label: '活动日期',
          prop: 'date'
        }, {
          label: '累计访问用户',
          prop: 'visitor_num'
        }, {
          label: '累计入群用户',
          prop: 'in_group_num'
        }, {
          label: '累计留存用户',
          prop: 'keep_num'
        }, {
          label: '留存率',
          prop: 'keep_rate'
        }, {
          label: '当日访问用户',
          prop: 'visitor_day'
        }, {
          label: '当日入群用户',
          prop: 'in_group_day'
        }, {
          label: '当日删除用户',
          prop: 'out_group_day'
        }
      ],
      tableData: [],
      tableBase: [],
      isChange: true,
      exportName: '',
      exportSheetName: '',
      exportData: []
    }
  },

  computed: {
    endDatePickerOptions () {
      return {
        disabledDate: (time) => {
          const now = this.$day().valueOf()
          const end = this.$day(this.actEndTime).valueOf()
          return time.getTime() >= (end > now ? now : end) || time.getTime() < this.$day(this.actStartTime).valueOf() - 8.64e7
        },
        onPick: ({ maxDate, minDate }) => {
          this.onPick({ maxDate, minDate })
        }
      }
    }
  },

  methods: {

    // 获取数据
    async fn_getData () {
      const params = {
        activity_id: this.actId,
        start: this.startTime,
        end: this.endTime
      }

      const data = await this.COMM_HTTP.overview(params)

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      if (!data) return false

      this.tableBase = data.list

      this.tableData = data.list.map(item => {
        item.keep_rate = item.keep_rate === '0.00' ? 0 : item.keep_rate + '%'
        item.task_finish_rate = item.task_finish_rate === '0.00' ? 0 : item.task_finish_rate + '%'
        return item
      })

      const baseData = [
        data.visitor_num,
        data.in_group_num,
        data.keep_num,
        data.keep_rate === '0.00' ? 0 : data.keep_rate + '%',
        data.task_finish_rate === '0.00' ? 0 : data.task_finish_rate + '%'
      ]

      this.$emit('getBase', baseData)
      this.fn_setTrend()
      this.fn_initExport()
    },

    // 设置图表数据
    fn_setTrend () {
      const seriesData = {
        visitor_num: {
          name: this.trendType === 1 ? '累计访问用户' : '当日访问用户',
          data: []
        },
        in_group_num: {
          name: this.trendType === 1 ? '累计入群用户' : '当日入群用户',
          data: []
        },
        keep_num: {
          name: this.trendType === 1 ? '累计留存用户' : '当日退群用户',
          data: []
        }
      }

      const xAxisData = this.tableBase.map(item => {
        seriesData.visitor_num.data.push(this.trendType === 1 ? item.visitor_num : item.visitor_day)
        seriesData.in_group_num.data.push(this.trendType === 1 ? item.in_group_num : item.in_group_day)
        seriesData.keep_num.data.push(this.trendType === 1 ? item.keep_num : item.out_group_day)
        return item.date
      })

      const obj = {
        xAxis: {
          data: xAxisData
        },
        series: Object.values(seriesData)
      }

      // 只有一天数据时显示 symbol 并加快显示动画的速度
      this.trendOption.series.showSymbol = this.tableBase.length === 1
      this.trendOption.series.animationDuration = this.tableBase.length === 1 ? 500 : 1000

      this.$refs.trendRef.setOption(obj)
    },

    // 自定义日期
    fn_changeDate (val) {
      if (val) {
        this.dateType = ''
        this.startTime = val[0] + ' 00:00:00'
        this.endTime = val[1] + ' 23:59:59'
        this.fn_getData()
      }
    },

    // 点击时设置日期范围
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },

    // 导出数据处理
    fn_initExport () {
      this.exportData = [this.tableHead.map(item => item.label)]

      this.tableData.forEach((item, index) => {
        const tbody = []
        this.tableHead.forEach(tItem => {
          tbody.push(item[tItem.prop])
        })
        this.exportData.push(tbody)
      })

      this.exportSheetName = this.actName + '-数据趋势'
      this.exportName = this.actName + '-数据趋势-'
    }

  },

  components: {
    alysCard,
    cLine,
    lyExport
  }
}
</script>
