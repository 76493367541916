/*
 * @Author: zhanln
 * @Date: 2021-10-29 10:02:34
 * @LastEditTime: 2021-12-10 16:23:05
 * @LastEditors: zhanln
 * @Description: colors
 */

const rgb = [
  '43, 124, 255', // 年轻蓝
  '0, 199, 128', // 绿色
  '250, 210, 12', // 黄色
  '250, 138, 20', // 橙
  '255, 72, 66', // 红
  '164, 71, 255', // 紫
  '43, 124, 255', // 蓝
  '19, 194, 194', // 绿松石
  '133, 199, 0' // 草绿
]

// // 参数
// index: 颜色组下标
// isRgb：是否直接返回 rgb(x.x.x) 格式，非rgb模式一般用于渐变使用
// loop: 是否循环使用颜色组
// loop 为 false时，如果 index 大于颜色组长度，则按数组顺序获取rgb，并随机计算rgb各值，防止颜色重复
const getColors = (index = 0, isRgb = true, loop = false) => {
  const baseRgb = rgb
  let _color

  if (loop) {
    return isRgb ? `rgb(${baseRgb[index % baseRgb.length]})` : baseRgb[index % baseRgb.length]
  }

  if (index > baseRgb.length - 1) {
    const rgbList = baseRgb[[index] % baseRgb.length].split(',').map(item => {
      item.trim()
      return +item
    })

    for (let j = 0; j < rgbList.length; j++) {
      // 颜色随机浮动数
      const colorRange = Math.ceil(Math.random() * 50)
      const colorRadom = Math.ceil(Math.random() * (index === 0 ? colorRange : index + colorRange))
      if (rgbList[j] + colorRadom >= 255) {
        rgbList[j] = rgbList[j] - colorRadom
      } else {
        rgbList[j] = rgbList[j] + colorRadom
      }
    }
    _color = rgbList.join(', ')
  } else {
    _color = baseRgb[index]
  }

  if (isRgb) {
    return `rgb(${_color})`
  } else {
    return _color
  }
}

export default getColors
