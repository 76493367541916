var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "q-dialog",
      attrs: { visible: _vm.promoteVisible, width: "800px", title: "推广素材" },
      on: {
        "update:visible": function($event) {
          _vm.promoteVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.promoteLoading,
              expression: "promoteLoading"
            }
          ],
          staticClass: "promote"
        },
        [
          _c(
            "div",
            { staticClass: "promote-left" },
            [
              _c("div", { staticClass: "promote-left__title" }, [
                _vm._v("裂变海报")
              ]),
              _c("div", { staticClass: "promote-left__poster" }, [
                _vm.promotePoster
                  ? _c("img", {
                      attrs: {
                        src: _vm.promotePoster,
                        id: "promotePoster",
                        alt: ""
                      }
                    })
                  : _vm._e()
              ]),
              _c(
                "el-button",
                {
                  staticClass: "is-block",
                  attrs: {
                    type: "primary",
                    size: "medium",
                    disabled: !_vm.promotePoster
                  },
                  on: {
                    click: function($event) {
                      return _vm.fn_downloadImg("poster")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "ri-download-2-line" }),
                  _vm._v(" 下载海报")
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "promote-gap" }),
          _c("div", { staticClass: "promote-right" }, [
            _c("div", { staticClass: "promote-right__item" }, [
              _c("div", { staticClass: "promote-right__label" }, [
                _vm._v("活动链接")
              ]),
              _c(
                "div",
                { staticClass: "promote-right__content" },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("el-input", {
                        attrs: { size: "medium", readonly: "" },
                        model: {
                          value: _vm.promoteUrl,
                          callback: function($$v) {
                            _vm.promoteUrl = $$v
                          },
                          expression: "promoteUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      class: "copy" + _vm.promoteId + "link",
                      attrs: {
                        type: "text",
                        disabled: !_vm.promoteUrl,
                        "data-clipboard-text": _vm.promoteUrl
                      },
                      on: {
                        click: function($event) {
                          return _vm.fn_clip(_vm.promoteId, "link")
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "promote-right__item" }, [
              _c("div", { staticClass: "promote-right__label" }, [
                _vm._v("裂变欢迎语")
              ]),
              _c(
                "div",
                { staticClass: "promote-right__content" },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          type: "textarea",
                          resize: "none",
                          readonly: ""
                        },
                        model: {
                          value: _vm.promoteWords,
                          callback: function($$v) {
                            _vm.promoteWords = $$v
                          },
                          expression: "promoteWords"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      class: "copy" + _vm.promoteId + "word",
                      attrs: {
                        type: "text",
                        disabled: !_vm.promoteWords,
                        "data-clipboard-text": _vm.promoteWords
                      },
                      on: {
                        click: function($event) {
                          return _vm.fn_clip(_vm.promoteId, "word")
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "promote-right__item" }, [
              _c("div", { staticClass: "promote-right__label" }, [
                _vm._v("推广二维码")
              ]),
              _c(
                "div",
                { staticClass: "promote-right__content code" },
                [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "promote-right__code" }, [
                      _vm.promoteCode
                        ? _c("img", {
                            attrs: {
                              src: _vm.promoteCode,
                              id: "promoteCode",
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", disabled: !_vm.promoteCode },
                      on: {
                        click: function($event) {
                          return _vm.fn_downloadImg("code")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "ri-download-2-line" }),
                      _vm._v(" 下载二维码")
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }