var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "page-warp" }, [
    _c("div", { staticClass: "edit-main" }, [
      _c("div", { staticClass: "edit-card" }, [
        _c(
          "div",
          { staticClass: "edit-card__content" },
          [
            _c(
              "el-form",
              {
                ref: "addRoleForm",
                attrs: {
                  model: _vm.addRoleForm,
                  rules: _vm.rules,
                  "label-width": "120px",
                  size: "medium"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "角色名称", prop: "name" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "flex-left" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入角色名称",
                              maxlength: "10",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.addRoleForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.addRoleForm, "name", $$v)
                              },
                              expression: "addRoleForm.name"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "数据范围", prop: "mask" } },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _vm._v(" 数据范围 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.maskTips,
                              placement: "top-start",
                              "popper-class": "q-tooltip"
                            }
                          },
                          [_c("i", { staticClass: "iconfont icon-info-fill" })]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "flex-left" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.editType === "edit"
                              },
                              on: { change: _vm.fn_maskChange },
                              model: {
                                value: _vm.addRoleForm.mask,
                                callback: function($$v) {
                                  _vm.$set(_vm.addRoleForm, "mask", $$v)
                                },
                                expression: "addRoleForm.mask"
                              }
                            },
                            _vm._l(_vm.maskOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "角色描述", prop: "desc" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "flex-left" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入角色描述，留空则不显示",
                              rows: "4",
                              maxlength: "100",
                              resize: "none",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.addRoleForm.desc,
                              callback: function($$v) {
                                _vm.$set(_vm.addRoleForm, "desc", $$v)
                              },
                              expression: "addRoleForm.desc"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "form-title" }, [
                  _c("span", [_vm._v("功能权限")])
                ]),
                _vm.editType !== "edit"
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "12px" },
                        attrs: { label: "已有角色" }
                      },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "flex-left" },
                            [
                              _c("el-cascader", {
                                ref: "roleCascader",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  options: _vm.roleList,
                                  props: _vm.roleListProps,
                                  placeholder:
                                    "可根据现有角色快速设置新角色的功能权限",
                                  filterable: ""
                                },
                                on: { change: _vm.fn_changeCascader }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { prop: "app_ids", "label-width": "32px" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "flex-left is-block" },
                        [
                          _c("el-tree", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.treeLoading,
                                expression: "treeLoading"
                              }
                            ],
                            ref: "tree",
                            staticClass: "role-tree",
                            attrs: {
                              data: _vm.allAsyncRoutes,
                              "show-checkbox": "",
                              "default-expand-all": "",
                              props: _vm.defaultProps,
                              "node-key": "id"
                            },
                            on: {
                              check: _vm.fn_handleCheck,
                              "check-change": _vm.fn_handleCheckChange
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var node = ref.node
                                  var data = ref.data
                                  return _c(
                                    "div",
                                    {
                                      class: {
                                        "custom-tree-node": data.kind === 2
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(" " + _vm._s(node.label) + " "),
                                        (node.label === "客户管理" &&
                                          !node.checked) ||
                                        (node.label === "群管理" &&
                                          !node.checked)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "q-info",
                                                staticStyle: {
                                                  "margin-left": "8px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "q-danger" },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v(
                                                  "未勾选此基础功能可能导致其他问题"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticClass: "role-checkbox",
                                          model: {
                                            value: _vm.checkList,
                                            callback: function($$v) {
                                              _vm.checkList = $$v
                                            },
                                            expression: "checkList"
                                          }
                                        },
                                        _vm._l(data.actionList, function(item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.id,
                                              attrs: {
                                                label: item.id,
                                                "data-pid": data.id,
                                                "data-id": item.id
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.fn_changeRoleCheckbox(
                                                    data,
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "edit-footer" },
      [
        _vm.editType !== "edit"
          ? _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.addState, round: "" },
                on: {
                  click: function($event) {
                    return _vm.fn_submitForm("addRoleForm")
                  }
                }
              },
              [_vm._v("立即创建")]
            )
          : _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", loading: _vm.addState },
                on: {
                  click: function($event) {
                    return _vm.fn_submitForm("addRoleForm")
                  }
                }
              },
              [_vm._v("保存")]
            ),
        _vm.editType !== "edit"
          ? _c(
              "el-button",
              {
                attrs: { round: "" },
                on: {
                  click: function($event) {
                    return _vm.fn_resetForm("addRoleForm")
                  }
                }
              },
              [_vm._v("重置")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }