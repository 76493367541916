<!--
 * @Descripttion: 选择群分组弹窗
 * @version:
 * @Author: lw
 * @Date: 2021-05-13 16:31:31
 * @LastEditTime: 2021-10-19 11:01:22
-->
<template>
  <el-dialog
    title="选择群分组"
    custom-class="select-group"
    :visible.sync="visible"
    width="635px"
    @open="getAllGroupList"
  >
    <div class="left">
      <el-input
        v-model="filterKey"
        style="width: 200px"
        size="medium"
        placeholder="请输入群组名称"
        suffix-icon="el-icon-search"
      ></el-input>
      <div class="title">选择群<span> (只展示有群聊的分组)</span></div>
      <div class="list">
        <div class="list-block">
          <el-tree
            v-show="filteredData.length"
            ref="tree"
            :data="filteredData"
            node-key="tempId"
            show-checkbox
            :props="props"
            @check="handleCheckChange">
            <span class="custom-tree-node" slot-scope="{ node }">
              <div class="ly-table__main-tearm">
                <i class="icon qy-icon-group"></i>
              </div>
              <div class="label-temp">
                <span class="text-ellipsis">{{ node.label }}</span>
                <span class="offline" v-if="node.disabled">(账号离线)</span>
              </div>
            </span>
          </el-tree>
          <el-empty v-show="!filteredData.length" description="暂无分组数据"></el-empty>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">
        已选群聊<span> ({{ selectedList.length }})</span>
        <el-button round type="text" @click="handleClear">清空</el-button>
      </div>
      <div class="list">
        <template v-if="selectedList.length === 0">
          <div class="el-table__empty-block">
            <span class="el-table__empty-text">暂无数据</span>
          </div>
        </template>
        <template v-else>
          <div class="list-block">
            <div
              v-for="item in selectedList"
              :key="item.id"
              class="list-item"
            >
              <div class="temp">
                <span class="nick">{{ item.name }}</span>
              </div>
              <i class="el-icon-close" @click="handleItemRemove(item)"></i>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        round
        type="primary"
        :disabled="!selectedList.length"
        size="medium"
        @click="handleSelectAccount"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { selectGroup } from '../http.js'
export default {
  props: ['value', 'initialData'],
  data () {
    return {
      COMM_HTTP: selectGroup,
      visible: false,
      props: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      },
      treeData: [
        {
          name: '全部',
          tempId: '-1',
          children: []
        },
        {
          name: '未分组',
          tempId: '0',
          children: []
        }
      ], // el-tree数据
      groupList: [], // 所有群数据
      groupMap: {
        '-1': '全部',
        0: '未分组'
      }, // 分组名与id映射关系
      filterKey: '', // 通过字符串过滤
      selected: [], // 已选群id
      selectedTreeId: [] // 已选树id
    }
  },
  computed: {
    filteredData () {
      if (this.filterKey === '') {
        return this.treeData
      } else {
        return this.treeData.filter(
          (item) => item.name.indexOf(this.filterKey) > -1
        )
      }
    },
    selectedList () {
      return this.groupList.filter((item) => this.selected.includes(item.id))
    }
  },
  watch: {
    initialData (n) {
      this.selected = []
      this.selectedTreeId = []
      n.map(({ groupIds, id }) => {
        groupIds.map(e => {
          this.selected.push(e)
          this.selectedTreeId.push(id + '_' + e)
        })
      })
    }
  },
  methods: {
    /**
     * @description: 获取被管理的所有群
     * @param {*}
     * @return {*}
     */
    async getAllGroupList () {
      this.treeData = [
        {
          name: '全部',
          tempId: '-1',
          children: []
        },
        {
          name: '未分组',
          tempId: '0',
          children: []
        }
      ]
      const res = await this.COMM_HTTP.allGroup({
        robot_admin_id: 0, // 查询所有机器人
        topic_id: -1, // 查询已分组和未分组
        type: 1, // 查询被管理的群
        page: 1,
        per_page: 1000
      })
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }
      this.groupList = res.list // 缓存所有群数据
      const topics = {}
      // 遍历群 封装分组数据
      res.list.map(e => {
        const robots = []
        const work_robots = e.work_robots || []
        work_robots.map(item => {
          if (item.status === 1) {
            robots.push(item.wecom_user_id)
          }
        })
        // 全部分组
        this.treeData[0].children.push({ ...e, tempId: '-1_' + e.id, disabled: robots.length === 0 })
        // topics.length > 0 代表群有分组
        if (e.topics.length) {
          e.topics.map(n => {
            this.groupMap[n.id] = n.name // 缓存分组id与name映射关系
            if (!topics[n.name]) {
              topics[n.name] = {
                name: n.name,
                tempId: n.id,
                children: []
              }
            }
            // 添加tree唯一id
            topics[n.name].children.push({ ...e, tempId: n.id + '_' + e.id, disabled: robots.length === 0 })
          })
        } else {
          this.treeData[1].children.push({ ...e, tempId: '0_' + e.id, disabled: robots.length === 0 })
        }
      })
      for (const key in topics) {
        this.treeData.push({
          name: topics[key].name,
          tempId: topics[key].tempId,
          children: topics[key].children
        })
      }
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.selectedTreeId)
      })
    },
    // 监听tree点击
    handleCheckChange (data, { checkedKeys }) {
      this.selected = []
      this.selectedTreeId = []
      checkedKeys.map(e => {
        const id = e.toString().split('_')[1]
        if (id) {
          this.selected.push(id * 1)
          this.selectedTreeId.push(e)
        }
      })
    },
    // 过滤树
    filterNode (value, data, node) {
      if (!value) return true
      if (node.level === 2) {
        const parentName = node.parent.label
        return parentName.indexOf(value) !== -1
      } else return data.name.indexOf(value) !== -1
    },
    // 单个删除
    handleItemRemove ({ id }) {
      this.selected = this.selected.filter(e => {
        // eslint-disable-next-line
        return e != id
      })
      this.selectedTreeId = this.selectedTreeId.filter(e => {
        return e.indexOf(id) < 0
      })
      this.$refs.tree.setCheckedKeys(this.selectedTreeId)
    },
    // 清空选择
    handleClear () {
      this.selected = []
      this.selectedTreeId = []
      this.$refs.tree.setCheckedKeys([])
    },
    // 保存选择的群
    async handleSelectAccount () {
      const tableData = []
      let dataItem = { id: '', groupIds: [] }
      for (let i = 0; i <= this.selectedTreeId.length; i++) {
        const e = this.selectedTreeId[i]
        const pid = e && e.split('_')[0] * 1
        const id = e && e.split('_')[1] * 1
        // id不等于dataItem.id 表示dataItem已完成
        // eslint-disable-next-line
        if (i !== 0 && pid != dataItem.id) {
          tableData.push(dataItem)
          dataItem = { id: '', groupIds: [] }
        }
        dataItem.id = pid
        dataItem.name = this.groupMap[pid]
        dataItem.groupIds.push(id)
      }
      this.$emit('submit', tableData)
      this.visible = false
    },
    /**
     * @description: 移除
     * @param {*} id
     * @return {*}
     */
    handleRemove (id, i) {
      this.initialData.splice(i, 1)
      this.$nextTick(() => {
        this.handleSelectAccount()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.select-group {
  .left,
  .right {
    flex: 1;
    padding: 16px 0;
  }
  .left {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    .title {
      color: #212b36;
      margin-top: 10px;
      span{
        font-size: 12px;
        color: #b5b5b5;
      }
    }
  }
  .list {
    flex: 1;
    overflow: hidden;
  }
  .list-block {
    height: 100%;
    overflow-y: scroll;
    .label-temp{
      display: flex;
      max-width: 175px;
    }
    .text-ellipsis{
      flex: 1;
      margin-right: 5px;
    }
    .offline{
      color: #ddd;
    }
  }
  .el-checkbox-group{
    font-size: 14px;
  }
  .list-item {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .temp {
      width: 150px;
      display: flex;
      flex: 1;
      align-items: center;
    }
    .nick {
      margin: 0 8px;
      max-width: 78px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .el-icon-close{
      cursor: pointer;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    .title {
      color: #212b36;
      padding-left: 16px;
      .el-button {
        float: right;
      }
    }
    .nick {
      max-width: 170px;
    }
  }
}
::v-deep {
  .el-checkbox__label {
    display: none;
  }
  .el-dialog__body {
    overflow: hidden;
    display: flex;
    height: 392px;
  }
  .el-tree-node__content{
    position: relative;
    height: 48px;
    .el-checkbox{
      position: absolute;
      right: 10px;
    }
    .custom-tree-node{
      display: flex;
      align-items: center;
    }
  }
  .el-empty{
    width: 140px;
    margin: 30px auto 0;
    .el-empty__description{
      text-align: center;
      color: #6E788A;
    }
  }
}
</style>
