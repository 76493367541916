<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-14 10:28:24
 * @LastEditTime: 2021-12-13 18:24:00
-->
<template>
  <el-dialog title="调整分组" :visible.sync="visible" width="420px">
    <div class="selected">
      已选择<span style="color: #2B7CFF"> ({{ selected.length }}) </span>个活码
    </div>
    <div class="group-list">
      <el-scrollbar v-if="groupList.length">
        <div class="group-list__radio" v-for="item in groupList" :key="item.id">
          <el-radio v-model="group" :label="item.id">{{
            item.groupName
          }}</el-radio>
        </div>
      </el-scrollbar>
      <div v-else class="no-group flex flex-column flex-center">
        <img src="@/assets/svg/null.svg" alt="" srcset="" />
        <div class="desc">暂无分组</div>
        <el-button plain type="primary" size="small" @click="handleAddGroup"
          >添加分组</el-button
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="groupList.length">
      <el-button type="primary" round size="medium" @click="handleDoGroup"
        >确定分组</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { groupManagement } from '../http'
export default {
  data () {
    return {
      visible: false,
      group: null
    }
  },
  computed: {
    selected () {
      return this.$parent.selected
    },
    groupList () {
      return this.$parent.groupList.filter(e => {
        return e.id !== '1'
      })
    }
  },
  methods: {
    handleAddGroup () {
      this.$parent.$refs.addGroup.visible = true
    },
    async handleDoGroup () {
      if (!this.group) {
        this.fn_lyMsg('info', '请选择分组！')
        return false
      }
      const group_ids = this.selected.map(e => {
        return e.id
      })
      const params = { groupId: this.group, id: group_ids }
      const res = await groupManagement.doGroup(params)

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      this.$message.success('分组成功!')
      this.visible = false
      this.group = null
      this.$emit('change')
    }
  }
}
</script>
<style lang="scss" scoped>
.selected {
  color: #212b36;
  font-size: 16px;
  margin-bottom: 16px;
}
.group-list {
  .el-scrollbar {
    height: 280px;
  }
  .desc {
    margin: 10px 0;
    color: #6E788A;
  }
  &__radio {
    display: block;
    margin-bottom: 14px;
  }
}
.no-group {
  padding-top: 20px;
  .el-button {
    margin-bottom: 90px;
  }
}
::v-deep {
  .el-checkbox {
    display: flex;
    align-items: center;
  }
  .el-checkbox__label {
    max-width: 285px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
