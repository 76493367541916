<!--
 * @Author: zhan
 * @Date: 2022-08-01 14:18:03
 * @LastEditTime: 2022-09-08 17:58:08
 * @LastEditors: zhanln
-->
<template>
  <el-dialog
    title="创建活动"
    :visible.sync="visible"
    custom-class="q-dialog"
    width="900px"
  >
    <el-button-group>
      <el-button :type="type === 1 ? 'primary' : ''" @click="type = 1"
        >直播裂变</el-button
      >
      <el-button :type="type === 2 ? 'primary' : ''" @click="type = 2"
        >私域+直播裂变</el-button
      >
    </el-button-group>

    <div class="title">裂变路径</div>
    <div class="step">
      <div
        class="step-item"
        v-for="(item, index) of type === 1 ? step : step2"
        :key="index"
      >
        <span class="step-name">{{ item }}</span>
        <img
          class="step-arrow"
          src="https://image.01lb.com.cn//uploads/wecom/22/0801/165933596115OMWwG6.png"
          alt=""
        />
      </div>
    </div>

    <div class="title">裂变页面</div>
    <div class="ex-img">
      <img
        src="https://image.01lb.com.cn//uploads/wecom/22/0801/1659337554hMIs42l6.png"
        v-if="type === 1"
      />
      <img
        src="https://image.01lb.com.cn//uploads/wecom/22/0908/1662631077dRidDKj8.png"
        v-else
      />
    </div>

    <el-button round type="primary" class="create-btn" @click="fn_created"
      >创建活动</el-button
    >
  </el-dialog>
</template>

<script>
export default {
  name: 'createTask',

  data () {
    return {
      visible: false,
      type: 1,
      step: [
        '分享活动',
        '邀请好友',
        '完成直播预约',
        '领取任务奖品'
      ],
      step2: [
        '分享活动',
        '邀请好友',
        '完成直播预约',
        '添加企微好友',
        '领取任务奖品'
      ]
    }
  },

  methods: {
    fn_created () {
      this.$router.push({
        path: 'edit',
        query: {
          mode: this.type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  font-size: 16px;
  padding-left: 11px;
  margin: 24px 0 14px;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    height: 12px;
    width: 5px;
    border-radius: 3px;
    background-color: #2b7cff;
    transform: translateY(-50%);
  }
}

.step {
  display: flex;
  user-select: none;

  &-item {
    display: flex;
    align-items: center;

    &:last-child {
      .step-arrow {
        display: none;
      }
    }
  }

  &-name {
    height: 36px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1890ff;
    color: #2b7cff;
    border-radius: 8px;
    font-weight: 500;
  }

  &-arrow {
    width: 53px;
    height: 6px;
    margin: 0 8px;
  }
}

.ex-img {
  text-align: center;

  img {
    height: 340px;
  }
}

.create-btn {
  display: flex;
  width: 112px;
  height: 44px;
  padding: 0;
  align-items: center;
  justify-content: center;
  margin: 56px auto 0;
  border-radius: 24px;
  font-size: 16px;
}
</style>
