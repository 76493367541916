var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "group_name",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "ly-table-tearm" }, [
                    _c("i", { staticClass: "iconfont icon-weixin" })
                  ]),
                  _c("span", { staticStyle: { flex: "1" } }, [
                    _vm._v(" " + _vm._s(row.group_name || "群聊") + " ")
                  ])
                ])
              ]
            }
          },
          {
            key: "master",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-tag", {
                  attrs: {
                    content: row.master_name,
                    size: 6,
                    avatar: row.master_avatar
                  }
                })
              ]
            }
          },
          {
            key: "user_name_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-tag", { attrs: { content: row.user_name, size: 8 } })
              ]
            }
          },
          {
            key: "customer_name",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-tag", {
                  attrs: {
                    content: row.customer_name,
                    size: 6,
                    icon: "iconfont icon-a-customerservice q-primary"
                  }
                })
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.fn_imList(row)
                      }
                    }
                  },
                  [_vm._v("聊天记录")]
                )
              ]
            }
          }
        ])
      }),
      _c("im", {
        ref: "im",
        attrs: { selfInfo: _vm.selfInfo, chat_id: _vm.chat_id }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }