var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp activity-list" },
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      round: "",
                      icon: "iconfont icon-plus"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("edit")
                      }
                    }
                  },
                  [_vm._v("创建活动")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "iconfont icon-edit", round: "" },
                    on: {
                      click: function($event) {
                        _vm.$refs.drafts.draftsVisible = true
                      }
                    }
                  },
                  [_vm._v("草稿箱(" + _vm._s(_vm.draft) + ")")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "name",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "poster-name" },
                  [
                    _c("el-image", {
                      staticClass: "poster",
                      attrs: { src: row.poster_url, fit: "cover", lazy: "" }
                    }),
                    _c("span", { staticClass: "aname" }, [
                      _vm._v(_vm._s(row.name))
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "giveAmount",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      (row.give_amount * 1).toFixed(2) +
                        " / " +
                        (row.total_amount * 1).toFixed(2)
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.status === 1
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("进行中")
                    ])
                  : _c("ly-status", { attrs: { type: "info" } }, [
                      _vm._v("已结束")
                    ])
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ly-action",
                  { key: Date.now() },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "analysis",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("数据分析")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "users",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("参与用户")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "record",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("红包记录")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: row.status === 2,
                          icon: "iconfont icon-send",
                          type: "text"
                        },
                        on: {
                          click: function($event) {
                            return _vm.groupSending(row)
                          }
                        }
                      },
                      [_vm._v("群发助手")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-Code" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "popularize",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("渠道推广")]
                    ),
                    _c("el-divider"),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "edit",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-copy" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "edit",
                              query: { id: row.id, edit: "copy" }
                            })
                          }
                        }
                      },
                      [_vm._v("复制")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.status === 2,
                          icon: "iconfont icon-ending"
                        },
                        on: {
                          click: function($event) {
                            return _vm.activityStop(row.id)
                          }
                        }
                      },
                      [_vm._v("结束")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-delete" },
                        on: {
                          click: function($event) {
                            return _vm.activityDel(row.id, row.status)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("drafts", {
        ref: "drafts",
        attrs: { isEmpty: _vm.draft === 0 },
        on: { onGetDrafts: _vm.handleDrafts }
      }),
      _c("gs-aide", { ref: "aide" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }