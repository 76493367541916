<!--
 * @Author: zhanln
 * @Date: 2021-07-26 10:10:49
 * @LastEditTime: 2022-12-15 11:50:19
 * @LastEditors: zhanln
 * @Description: 群裂变 - 编辑
-->

<template>
  <el-main
    class="inner edit-container"
    style="padding-right: 0"
    v-loading="watting"
  >
    <div class="edit-main edit-poster">
      <!-- 顶部导航 -->
      <div class="edit-card edit-menu">
        <el-tabs v-model="tabKey" @tab-click="fn_setTab">
          <el-tab-pane label="活动信息" name="basic"></el-tab-pane>
          <el-tab-pane label="裂变海报" name="poster"></el-tab-pane>
          <el-tab-pane label="活动页设置" name="other"></el-tab-pane>
          <el-tab-pane label="任务设置" name="reward"></el-tab-pane>
          <el-tab-pane label="领奖路径" name="way"></el-tab-pane>
          <el-tab-pane
            v-if="msg_mode === 1"
            label="消息通知"
            name="notice"
          ></el-tab-pane>
        </el-tabs>
      </div>

      <!-- 表单 -->
      <div class="edit-poster-content" ref="main">
        <el-scrollbar class="left-area">
          <view-basic ref="basic" title="活动信息" :aid="actId"></view-basic>
          <view-poster ref="poster" title="裂变海报"></view-poster>
          <view-other ref="other" title="活动页设置"></view-other>
          <view-reward ref="reward" title="任务设置"></view-reward>
          <view-way ref="way" title="领奖路径"></view-way>
          <view-notice
            v-if="msg_mode === 1"
            ref="notice"
            title="消息通知"
          ></view-notice>
        </el-scrollbar>

        <!-- 手机预览框 -->
        <div class="edit-poster-mobi edit-card">
          <el-scrollbar style="height: 100%">
            <mobi-basic v-if="tabKey === 'basic'"></mobi-basic>
            <mobi-poster v-if="tabKey === 'poster'"></mobi-poster>
            <mobi-way v-if="tabKey === 'way'"></mobi-way>
            <mobi-reward
              v-if="tabKey === 'reward' || tabKey === 'other'"
              :current="tabKey"
            ></mobi-reward>
            <mobi-notice v-if="tabKey === 'notice'"></mobi-notice>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <edit-footer
      @saveDraft="fn_submit(2)"
      @submit="fn_submit(1)"
      :btnLoading="btnLoading"
    ></edit-footer>
  </el-main>
</template>

<script>
// form
import Basic from './activity/basic'
import Poster from './activity/poster'
import Reward from './activity/reward'
import Way from './activity/way'
import Notice from './activity/notice'
import Other from './activity/other'
import editFooter from './activity/editFooter'
// mobi
import mobiBasic from './mobile/basic'
import mobiPoster from './mobile/poster'
import mobiReward from './mobile/reward'
import mobiWay from './mobile/way'
import mobiNotice from './mobile/notice'
// plugin
import mapTag from '@/common/mapTag'
// api
import { activity } from './http.js'
export default {
  name: 'edit',

  data () {
    return {
      actId: 0,
      COMM_HTTP: activity,
      watting: true,
      isCopy: false,
      isEdit: false,
      isEnd: false,
      tabs: [
        {
          label: '活动信息',
          name: 'basic'
        },
        {
          label: '裂变海报',
          name: 'poster'
        },
        {
          label: '活动页设置',
          name: 'other'
        },
        {
          label: '任务设置',
          name: 'reward'
        },
        {
          label: '领奖路径',
          name: 'way'
        },
        {
          label: '消息通知',
          name: 'notice'
        }
      ],
      tabKey: 'basic',
      subForm: {},
      isGetTag: false,
      btnLoading: false,
      backComfirm: true,
      actType: 1
    }
  },
  computed: {
    metaId () {
      return this.$route.meta.pid
    },
    mapAppId () {
      return this.$store.state.buy.mapAppId
    },
    isSale () {
      return this.$store.state.buy.isSale
    },
    isBought () {
      return this.$store.state.buy.isBought
    },
    buyInfo () {
      const remainDay = this.$store.state.buy.remainDay
      if (remainDay === null) {
        return false
      } else {
        if (remainDay <= 0) {
          return false
        } else {
          return true
        }
      }
    },
    remainDay () {
      return this.$store.state.buy.remainDay
    },
    msg_mode () {
      return this.$store.getters['groupfission/GET_BASIC'].msg_mode
    }
  },
  created () {
    this.fn_resetForm()
  },

  async mounted () {
    window.addEventListener('beforeunload', this.fn_beforeunload, false)

    // main主体滚动监测
    this.$nextTick(() => {
      const main = document.querySelector('.left-area .el-scrollbar__wrap')
      main && main.addEventListener('scroll', this.fn_getScroll)
    })

    const id = this.$route.query.id
    if (id) {
      this.actId = +id
      this.isCopy = this.$route.query.edit === 'copy'
      this.$store.state.groupfission.isCopy = this.$route.query.edit === 'copy'
      this.isEdit = true
      this.$store.state.groupfission.isEdit = true
      // 回显配置
      await this.getForm(id)
    } else {
      this.$store.state.groupfission.isReady = true
      this.watting = false
    }
  },

  methods: {

    // 顶部导航监听
    fn_setTab (tab) {
      const top = this.$refs[tab.name].$el.offsetTop
      document.querySelector('.left-area .el-scrollbar__wrap').scrollTop = top
      this.tabKey = tab.name
      // this.initFlag = true
      // setTimeout(() => {
      //   this.initFlag = false
      // }, 50)
    },

    // 获取详情
    async getForm (id) {
      const data = await this.COMM_HTTP.show({ id })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.backComfirm = false
        return false
      }
      this.isEnd = data.status === 2
      this.fn_setForm(data)
      this.$store.state.groupfission.isReady = true
      this.watting = false
      this.actType = data.type
      this.$store.state.groupfission.isDaft = data.type === 2
      this.$store.state.groupfission.isEnd = data.status === 2
    },

    // 回显表单
    fn_setForm (form) {
      this.$store.state.groupfission.id = form.id
      this.fn_setBasic(form)
      this.fn_setPoster(form)
      this.fn_setTask(form.task, form.type)
      this.fn_setReward(form)
      this.fn_setNotice(form)
      this.fn_setOther(form)
    },

    // 回显 - 活动信息
    fn_setBasic (form) {
      let _group = []

      if (form.group) {
        _group = form.group.map(item => {
          const _item = {
            activity_group_id: this.isCopy ? 0 : item.activity_group_id,
            id: item.id,
            name: item.group_name || '',
            owner_name: '',
            count: item.max_account,
            robots: [],
            code: item.qrcode_url ? [{ url: item.qrcode_url }] : [],
            sort: item.sort
          }
          return _item
        })
      }

      const basic = {
        title: this.isCopy ? '' : form.title,
        end_at: form.end_time,
        msg_mode: form.msg_mode,
        group: !this.isCopy ? _group : [],
        wel_status: form.open_welcome,
        link_type: form.link_type
      }

      if (form.link_type === 2) {
        basic.link_title = form.link_title
        basic.link_logo = [{ url: form.link_logo }]
        basic.link_desc = form.link_desc
      }

      if (form.open_welcome === 1) {
        basic.wel_gap = form.welcome_send_second
        basic.wel_user = form.welcome_send_add
        basic.wel_words = mapTag(form.welcome_msg)
      }

      for (const key in basic) {
        this.fn_setLocalForm('basic', basic[key], key)
      }
    },

    // 回显 - 海报
    fn_setPoster (form) {
      const poster = {
        share_msg: form.share_msg
      }

      poster.img_url = form.poster_url ? [{ url: form.poster_url }] : []

      const conf = JSON.parse(form.poster_conf)

      if (!conf.nickname_align) {
        if (conf.nickname_offsetX === (conf.nickname_x + conf.nickname_size * 6 / 2).toFixed() - 2) {
          conf.nickname_align = 'center'
        } else if (conf.nickname_offsetX === (conf.nickname_x + conf.nickname_size * 6).toFixed() - 2) {
          conf.nickname_align = 'right'
        } else {
          conf.nickname_align = 'left'
        }
      }

      conf.avatar_x = this.fn_calcCoordinatesBack(conf.avatar_x, 'x')
      conf.avatar_y = this.fn_calcCoordinatesBack(conf.avatar_y, 'y')
      conf.avatar_width = this.fn_calcCoordinatesBack(conf.avatar_width, 'x')
      conf.avatar_height = conf.avatar_width
      conf.nickname_x = this.fn_calcCoordinatesBack(conf.nickname_x, 'x')
      conf.nickname_y = this.fn_calcCoordinatesBack(conf.nickname_y, 'y')
      conf.qrcode_x = this.fn_calcCoordinatesBack(conf.qrcode_x, 'x')
      conf.qrcode_y = this.fn_calcCoordinatesBack(conf.qrcode_y, 'y')
      conf.qrcode_width = this.fn_calcCoordinatesBack(conf.qrcode_width, 'x')
      conf.qrcode_height = conf.qrcode_width

      Object.assign(poster, conf)

      for (const key in poster) {
        this.fn_setLocalForm('poster', poster[key], key)
      }
    },

    // 回显 - 任务设置
    fn_setTask (form, type) {
      const taskList = ['first', 'second', 'third']
      const taskForm = {
        first: {},
        second: {},
        third: {}
      }
      for (let i = 0; i < form.length; i++) {
        taskForm[taskList[i]] = {
          id: this.isCopy ? 0 : form[i].id,
          give_num: form[i].give_num,
          target_count: form[i].target_count,
          reward_title: form[i].reward_title,
          reward_desc: form[i].reward_desc,
          reward_coverList: form[i].reward_cover ? [{ url: form[i].reward_cover }] : [],
          reward_detail_imgList: form[i].reward_detail_img ? [{ url: form[i].reward_detail_img }] : [],
          unreal: form[i].virtual_count,
          reward_type: form[i].reward_type,
          total: form[i].total,
          remain: form[i].remain,
          reward_type_id: null,
          form_id: null,
          tag_open: form[i].mark_tag,
          finish_task_tags: form[i].tags,
          reward_detail_text: form[i].reward_detail_text,
          reward_rule_text: form[i].reward_rule_text
        }

        if (form[i].reward_type === 1) {
          taskForm[taskList[i]].reward_detail = form[i].reward_detail
        }
        if (form[i].reward_type === 2) {
          taskForm[taskList[i]].reward_type_id = this.isCopy ? null : +form[i].reward_type_id
          taskForm[taskList[i]].codeRules = form[i].reward_detail
        }
        if (form[i].reward_type === 3) {
          taskForm[taskList[i]].form_id = this.isCopy ? null : form[i].reward_type_id
          taskForm[taskList[i]].formRules = form[i].reward_detail
        }
      }
      console.log('taskForm', taskForm)
      const { first, second, third } = taskForm
      for (const key in first) {
        this.fn_setLocalForm('first', first[key], key)
      }

      if (second.target_count) {
        this.fn_setLocalForm('second', 1, 'status')
        for (const key in second) {
          this.fn_setLocalForm('second', second[key], key)
        }
      } else {
        if (!this.isCopy && type === 1) {
          this.fn_setLocalForm('second', true, 'disable')
        } else {
          this.fn_setLocalForm('second', first.target_count + 1, 'target_count')
        }
      }

      if (third.target_count) {
        this.fn_setLocalForm('third', 1, 'status')
        for (const key in third) {
          this.fn_setLocalForm('third', third[key], key)
        }
      } else {
        if (!this.isCopy && type === 1) {
          this.fn_setLocalForm('third', true, 'disable')
        } else {
          this.fn_setLocalForm('third', first.target_count + 2, 'target_count')
        }
      }
    },

    // 回显 - 领奖路径
    fn_setReward (form) {
      const reward = {
        type: form.reward_type
      }

      if (form.reward_type === 1) {
        reward.ids = form.reward_add_user ? form.reward_add_user.split() : null
        reward.words = mapTag(form.reward_wel_come_msg)
        reward.tag_open = form.reward_mark
        reward.tags = form.reward_tag
      }
      for (const key in reward) {
        this.fn_setLocalForm('way', reward[key], key)
      }
    },

    // 回显 - 消息通知
    fn_setNotice (form) {
      const notice = {
        open_help: form.open_help,
        open_reward: form.open_reward
      }

      if (form.open_help === 1) {
        notice.help_send_second = form.help_send_second
        notice.help_send_add = form.help_send_add
        notice.help_msg = mapTag(form.help_msg)
      }

      if (form.open_reward === 1) {
        notice.reward_send_second = form.reward_send_second
        notice.reward_send_add = form.reward_send_add
        notice.reward_msg = mapTag(form.reward_msg)
      }

      for (const key in notice) {
        this.fn_setLocalForm('notice', notice[key], key)
      }
    },

    // 回显 - 其他设置
    fn_setOther (form) {
      const other = {
        show_title: form.show_title || '邀请加入群聊',
        rule: form.rule,
        banner_type: form.background_type,
        customer_service_user: form.customer_service_user ? form.customer_service_user.split() : null,
        customer_wel_come_msg: mapTag(form.customer_wel_come_msg)
      }

      const obj = JSON.parse(form.background_conf)

      if (form.background_type === 2) {
        other.bg_color = obj.color
        other.banner = [{ url: obj.url }]
      } else {
        other.banner_title = obj.title
      }

      for (const key in other) {
        this.fn_setLocalForm('other', other[key], key)
      }
    },

    // 回显配置
    fn_setLocalForm (form, val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: form,
        name: type,
        val: val
      })
    },

    // 计算预览框宽度为345时的坐标
    fn_calcCoordinatesBack (num, type) {
      let _num = null
      if (type === 'x') {
        _num = (num * (345 / 375)).toFixed(1)
      } else if (type === 'y') {
        _num = (num * (613.64 / 667)).toFixed(1)
      }
      return +_num
    },

    // 重置
    fn_resetForm () {
      this.$store.commit('groupfission/RESET')
    },

    // 提交 type 1 发布 2 草稿
    async fn_submit (type) {
      const _verify = await this.fn_verify(type)
      if (!_verify) {
        this.btnLoading = false
        return false
      }
      this.btnLoading = true
      this.fn_initForm(type)
      this.fn_submitForm(type)
    },

    // 验证各表单
    async fn_verify (type) {
      if (type === 1) {
        const basic = await this.$refs.basic.verify()
        if (!basic) {
          this.fn_formScroll(this.$refs.basic.$el)
          return false
        }
        if (!this.$refs.poster.verify()) {
          this.fn_formScroll(this.$refs.poster.$el)
          return false
        }
        if (!this.$refs.other.verify()) {
          this.fn_formScroll(this.$refs.other.$el)
          return false
        }
        if (!this.$refs.reward.verify()) {
          this.fn_formScroll(this.$refs.reward.$el)
          return false
        }
        if (!this.$refs.way.verify()) {
          this.fn_formScroll(this.$refs.way.$el)
          return false
        }
        if (this.msg_mode === 1 && !this.$refs.notice.verify()) {
          this.fn_formScroll(this.$refs.notice.$el)
          return false
        }
      } else {
        const basic = await this.$refs.basic.verify(type)
        if (!basic) {
          this.fn_formScroll(this.$refs.basic.$el)
          return false
        }
      }

      return true
    },

    // 处理需要提交的数据
    fn_initForm (type) {
      const form = {
        type
      }
      if (!this.isCopy) {
        form.id = this.actId
      }
      // 活动信息
      Object.assign(form, this.fn_initBasic(type))
      // // 海报
      Object.assign(form, this.fn_initPoster())
      // 其他设置
      Object.assign(form, this.fn_initOther())
      // // banner
      // Object.assign(form, this.fn_initBanner())
      // 任务设置
      form.task = this.fn_initTask()
      // 领奖路径
      Object.assign(form, this.fn_initReward())
      // 消息通知
      Object.assign(form, this.fn_initNotice())
      this.subForm = form
    },

    // 提交 - 活动信息
    fn_initBasic (type) {
      const basic = this.$store.getters['groupfission/GET_BASIC']
      const group = []
      if (basic.group.length > 0) {
        for (let i = 0; i < basic.group.length; i++) {
          const obj = {
            id: basic.group[i].id,
            activity_group_id: basic.group[i].activity_group_id,
            max_account: basic.group[i].count,
            qrcode_type: basic.group[i].qrcode_type,
            sort: i
          }
          group.push(obj)
        }
      }
      const basicForm = {
        title: basic.title,
        msg_mode: basic.msg_mode,
        end_time: this.$day(basic.end_at).format('YYYY-MM-DD HH:mm:ss'),
        group,
        open_welcome: basic.wel_status,
        link_type: basic.link_type
      }

      // 入群链接：1、默认，2、自定义
      if (basic.link_type === 1) {
        basicForm.link_title = '与我一起领取奖品👇'
        basicForm.link_desc = '点击链接，领取福利'
        basicForm.link_logo = 'https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png'
      } else {
        basicForm.link_title = basic.link_title
        basicForm.link_desc = basic.link_desc
        basicForm.link_logo = basic.link_logo[0].url
      }

      // 如果开启了入群欢迎语，添加时间和人数间隔
      if (basic.wel_status === 1) {
        basicForm.welcome_send_second = basic.wel_gap
        basicForm.welcome_send_add = basic.wel_user
        basicForm.welcome_msg = mapTag(basic.wel_words, false)
      }

      return basicForm
    },

    // 提交 - 海报
    fn_initPoster () {
      const poster = this.util.extend(true, {}, this.$store.getters['groupfission/GET_POSTER'])
      const posterForm = {
        poster_url: poster.img_url.length > 0 ? poster.img_url[0].url : '',
        share_msg: poster.share_msg
      }
      delete (poster.img_url)
      delete (poster.share_msg)
      poster.avatar_x = this.fn_calcCoordinates(poster.avatar_x, 'x')
      poster.avatar_y = this.fn_calcCoordinates(poster.avatar_y, 'y')
      poster.avatar_width = this.fn_calcCoordinates(poster.avatar_width, 'x')
      poster.avatar_height = poster.avatar_width
      poster.nickname_x = this.fn_calcCoordinates(poster.nickname_x, 'x')
      poster.nickname_y = this.fn_calcCoordinates(poster.nickname_y, 'y')
      poster.qrcode_x = this.fn_calcCoordinates(poster.qrcode_x, 'x')
      poster.qrcode_y = this.fn_calcCoordinates(poster.qrcode_y, 'y')
      poster.qrcode_width = this.fn_calcCoordinates(poster.qrcode_width, 'x')
      poster.qrcode_height = poster.qrcode_width
      posterForm.poster_conf = JSON.stringify(poster)
      return posterForm
    },

    // 提交 - banner
    fn_initBanner () {
      const banner = this.$store.getters['groupfission/GET_BANNER']
      const obj = {
        background_type: +banner.type
      }
      const title = banner.title
      if (banner.type === 2) { // 自定义
        const conf = {
          url: banner.list.length > 0 ? banner.list[0].url : '',
          color: banner.color,
          title: ''
        }
        obj.background_conf = JSON.stringify(conf)
      } else { // 默认
        const defaultBanner = this.$store.getters['groupfission/GET_DEFAULT']
        const conf = {
          url: defaultBanner.banner,
          color: defaultBanner.color,
          title: title
        }
        obj.background_conf = JSON.stringify(conf)
      }
      return obj
    },

    // 提交 - 任务设置
    fn_initTask () {
      const first = this.$store.getters['groupfission/GET_TASK']('first')
      const second = this.$store.getters['groupfission/GET_TASK']('second')
      const third = this.$store.getters['groupfission/GET_TASK']('third')

      const task = [first]
      if (second.status === 1) {
        task.push(second)
      }
      if (third.status === 1) {
        task.push(third)
      }
      this.fn_initStep(task)
      return this.fn_initStep(task)
    },

    // 提交 - 任务设置详情
    fn_initStep (step) {
      const stepForm = []
      for (let i = 0; i < step.length; i++) {
        const form = {
          id: +step[i].id,
          step: i + 1,
          mark_tag: +step[i].tag_open,
          reward_cover: step[i].reward_coverList.length > 0 ? step[i].reward_coverList[0].url : '',
          reward_desc: step[i].reward_desc,
          reward_title: step[i].reward_title,
          reward_type: +step[i].reward_type,
          reward_type_id: '',
          tags: step[i].finish_task_tags,
          target_count: +step[i].target_count,
          total: +step[i].total,
          reward_detail_img: step[i].reward_detail_imgList.length > 0 ? step[i].reward_detail_imgList[0].url : '',
          virtual_count: +step[i].unreal
        }

        if (step[i].reward_type === 1) {
          form.reward_detail = step[i].reward_detail
        }
        if (step[i].reward_type === 2) {
          form.reward_type_id = step[i].reward_type_id + ''
          form.reward_detail = step[i].codeRules
        }
        if (step[i].reward_type === 3) {
          form.reward_type_id = step[i].form_id
          form.reward_detail = step[i].formRules
        }
        stepForm.push(form)
      }
      return stepForm
    },

    // 提交 - 领奖路径
    fn_initReward () {
      const form = this.$store.getters['groupfission/GET_WAY']
      const obj = {
        reward_type: +form.type
      }
      if (+form.type === 1) {
        obj.reward_add_user = form.ids ? form.ids.join(',') : ''
        obj.reward_wel_come_msg = mapTag(form.words, false)
        obj.reward_mark = form.tag_open
        obj.reward_tag = form.tags
      }
      return obj
    },

    // 提交 - 消息通知
    fn_initNotice () {
      const form = this.$store.getters['groupfission/GET_NOTICE']
      const obj = {
        open_help: +form.open_help,
        open_reward: +form.open_reward
      }

      if (+form.open_help === 1) {
        obj.help_send_second = form.help_send_second
        obj.help_send_add = form.help_send_add
        obj.help_msg = mapTag(form.help_msg, false)
      }

      if (+form.open_reward === 1) {
        obj.reward_send_second = form.reward_send_second
        obj.reward_send_add = form.reward_send_add
        obj.reward_msg = mapTag(form.reward_msg, false)
      }

      return obj
    },

    // 提交 - 其他设置
    fn_initOther () {
      const { show_title, banner_type, banner, banner_title, bg_color, customer_service_user, customer_wel_come_msg, rule } = this.util.extend(true, {}, this.$store.getters['groupfission/GET_OTHER'])

      let background_conf = null
      if (banner_type === 2) { // 自定义
        const conf = {
          url: banner[0].url,
          color: bg_color,
          title: ''
        }
        background_conf = JSON.stringify(conf)
      } else { // 默认
        const defaultBanner = this.$store.getters['groupfission/GET_DEFAULT']
        const conf = {
          url: defaultBanner.banner,
          color: defaultBanner.color,
          title: banner_title
        }
        background_conf = JSON.stringify(conf)
      }

      const form = {
        show_title,
        background_type: banner_type,
        background_conf,
        customer_service_user: customer_service_user ? customer_service_user.join(',') : '',
        customer_wel_come_msg: mapTag(customer_wel_come_msg, false),
        rule
      }

      return form
    },

    // 计算预览框宽度为345时的数值
    fn_calcCoordinates (num, type) {
      let _num = null
      if (type === 'x') {
        _num = Math.round(375 / 345 * num)
      } else if (type === 'y') {
        _num = Math.round(667 / 613.64 * num)
      }
      return +_num
    },

    // 提交活动
    async fn_submitForm (type) {
      const data = await this.COMM_HTTP.save(this.subForm)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.btnLoading = false
        return false
      }

      this.backComfirm = false
      this.$router.push('list')
      this.fn_lyMsg('success', type === 1 ? '活动发布成功！' : '草稿保存成功！')
      this.btnLoading = false
    },

    // 滚动到报错位置
    fn_formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    // 滚动监听
    fn_getScroll (e) {
      const main = e.target || e.srcElement || e.path[0]
      const mainList = this.tabs.map(item => item.name)
      const top = main.scrollTop
      const height = main.offsetHeight
      this.util.each(mainList, (i, v) => {
        if (this.$refs[v]) {
          const obj = this.$refs[v].$el
          const y = obj.offsetTop

          if (top >= y - 63 - height / 2) {
            this.tabKey = v
          }
        }
      })
    },

    // 页面刷新
    fn_beforeunload (e) {
      e = e || window.event
      if (e) {
        e.returnValue = ''
      }
      return ''
    }
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.fn_beforeunload, false)
  },

  destroyed () {
    window.removeEventListener('scroll', this.fn_getScroll)
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          this.fn_resetForm()
          this.watting = true
          next()
        }).catch(() => { })
      } else {
        this.fn_resetForm()
        this.watting = true
        next()
      }
    }
  },

  components: {
    viewBasic: Basic,
    viewPoster: Poster,
    viewReward: Reward,
    viewWay: Way,
    viewNotice: Notice,
    viewOther: Other,
    editFooter,
    mobiBasic,
    mobiPoster,
    mobiWay,
    mobiNotice,
    mobiReward
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/edit.scss';
::v-deep {
  .el-tabs__nav-wrap::after {
    content: unset;
  }
}
</style>
