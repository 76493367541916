<!--
 * @Author: zhanln
 * @Date: 2021-08-25 20:14:43
 * @LastEditTime: 2021-12-10 15:55:21
 * @LastEditors: Please set LastEditors
 * @Description: 链接预览
-->

<template>
  <ly-mobile :showWel="false" :hasFooter="false" sub="" title="">
    <div
      class="qrlink"
      :class="[linkType === 2 && link_unreal_expire_open ? 'is-high' : '']"
    >
      <img src="~@assets/images/qr_link_bg_fi.png" alt="" class="bg-right" />
      <img
        src="~@assets/images/qr_link_bg_fi_left.png"
        alt=""
        class="bg-left"
      />
      <div class="qrlink-carousel">
        <img src="~@assets/images/avatar.jpg" alt="" class="avatar" />
        用户 192****1274 已添加好友
      </div>
      <div class="qrlink-body">
        <template v-if="linkType === 1">
          <div class="bg">
            <div class="title" v-html="guide || '请输入引导说明'"></div>
            <img src="~@assets/images/contact_me_qr.png" alt="" class="code" />
            <div class="check">
              <img
                src="~@assets/svg/protect.svg"
                alt=""
              />二维码已通过检测，请放心扫码
            </div>
            <div class="tip">长按识别二维码</div>
          </div>
        </template>
        <template v-else>
          <div class="qrlink-poster">
            <el-image :src="linkBg" fit="cover" class="qrlink-poster__bg">
              <div slot="error" class="image-slot">
                <i class="el-icon-back"></i>请上传链接页背景
              </div>
            </el-image>
            <vue-draggable-resizable
              class="dragWrap dragQrcode"
              :x="Number(qrcode_x)"
              :y="Number(qrcode_y)"
              :w="Number(qrcode_width)"
              :h="Number(qrcode_height)"
              :parent="true"
              :handles="['br']"
              :lock-aspect-ratio="true"
              @dragging="(x, y) => onDrag(x, y, 'qrcode')"
              @resizing="(x, y, w, h) => onResize(w, h, 'qrcode')"
            >
              <img src="~@assets/images/contact_me_qr.png" />
            </vue-draggable-resizable>
          </div>
        </template>
      </div>
      <div class="qrlink-footer">
        <div class="contact" v-if="sevice_open">
          若以上二维码无法添加，
          <span class="flex" style="color: #2d67cf"
            >请<img
              src="~@assets/svg/message.svg"
              alt=""
              class="msg"
            />联系主办方</span
          >
        </div>
        <div class="copyright" style="color: #2d67cf">
          <img src="~@assets/images/01logo_circle.webp" alt="" />零一裂变 |
          仅提供技术支持 <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="qrlink-fixed">
      <div
        class="qrlink-expire"
        v-if="linkType === 2 && expire > 0 && link_unreal_expire_open"
      >
        <img src="~@assets/svg/alarm-clock.svg" alt="" /><span
          class="q-info"
          style="margin-right: 4px"
          >{{ link_unreal_expire_text }}</span
        >
        <template v-if="link_unreal_expire_day > 0">
          <span class="time">{{ link_unreal_expire_day }}</span
          >天
        </template>
        <template v-if="link_unreal_expire_day + link_unreal_expire_hour > 0">
          <span class="time">{{ link_unreal_expire_hour }}</span
          >时
        </template>
        <template
          v-if="
            link_unreal_expire_day +
              link_unreal_expire_hour +
              link_unreal_expire_minute >
            0
          "
        >
          <span class="time">{{ link_unreal_expire_minute }}</span
          >分
        </template>

        <template
          v-if="
            link_unreal_expire_day +
              link_unreal_expire_hour +
              link_unreal_expire_minute +
              link_unreal_expire_second >
            0
          "
        >
          <span class="time">{{ link_unreal_expire_second }}</span
          >秒
        </template>
      </div>
    </div>
    <div
      class="qrlink-fixed__place"
      v-if="linkType === 2 && expire > 0 && link_unreal_expire_open"
    ></div>
  </ly-mobile>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  components: {
    [VueDraggableResizable.name]: VueDraggableResizable
  },
  computed: {
    welWords () {
      return this.$store.getters['qrcode/GET_BASIC'].wel_words
    },
    linkType () {
      return this.$store.getters['qrcode/GET_LINK'].link_type
    },
    title () {
      return this.$store.getters['qrcode/GET_BASIC'].title
    },
    guide () {
      return this.$store.getters['qrcode/GET_LINK'].guide.replace(/\n/g, '<br />')
    },
    sevice_open () {
      return this.$store.getters['qrcode/GET_LINK'].sevice_open
    },
    linkBg () {
      const arr = this.$store.getters['qrcode/GET_LINK'].link_bg
      return arr.length > 0 ? arr[0].url : ''
    },
    qrcode_width () {
      return this.$store.getters['qrcode/GET_LINK'].qrcode_width
    },
    qrcode_height () {
      return this.$store.getters['qrcode/GET_LINK'].qrcode_height
    },
    qrcode_x () {
      return this.$store.getters['qrcode/GET_LINK'].qrcode_x
    },
    qrcode_y () {
      return this.$store.getters['qrcode/GET_LINK'].qrcode_y
    },
    link_unreal_expire_text () {
      return this.$store.getters['qrcode/GET_LINK'].unreal_expire_text
    },
    link_unreal_expire_open () {
      return this.$store.getters['qrcode/GET_LINK'].unreal_expire_open
    },
    link_unreal_expire_day () {
      return this.$store.getters['qrcode/GET_LINK'].unreal_expire_day
    },
    link_unreal_expire_hour () {
      return this.$store.getters['qrcode/GET_LINK'].unreal_expire_hour
    },
    link_unreal_expire_minute () {
      return this.$store.getters['qrcode/GET_LINK'].unreal_expire_minute
    },
    link_unreal_expire_second () {
      return this.$store.getters['qrcode/GET_LINK'].unreal_expire_second
    },
    expire () {
      return this.$store.getters['qrcode/GET_EXPIRE']
    }
  },
  methods: {

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('qrcode/SET_FORM', {
        form: 'link',
        name: type,
        val: val
      })
    },
    onDrag (x, y, name) {
      this.fn_setForm(x, `${name}_x`)
      this.fn_setForm(y, `${name}_y`)
    },
    onResize (w, h, name) {
      this.fn_setForm(w, `${name}_width`)
      this.fn_setForm(h, `${name}_height`)
    }
  }
}
</script>

<style lang="scss" scoped>
.qrlink {
  position: relative;
  overflow: hidden;

  .bg-right {
    position: absolute;
    right: -50px;
    top: -45px;
    width: 100px;
    z-index: 1;
  }

  .bg-left {
    position: absolute;
    left: -15px;
    top: 435px;
    width: 69px;
    z-index: 1;
  }

  &.is-high {
    ::v-deep .mobile-view {
      padding-bottom: 64px;
    }
  }

  ::v-deep .mobile-view {
    position: relative;
  }

  &-carousel {
    display: flex;
    justify-content: center;
    margin-top: 4px;

    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-body {
    position: relative;
    padding: 16px 5px;
    z-index: 1;

    .bg {
      position: relative;
      background-image: url("https://image.01lb.com.cn//uploads/wecom/21/0826/16299458785tA419Y9.png");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100% 100%;
      width: 280px;
      height: 423px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 2;
    }

    .title {
      color: #fff;
      padding: 0 32px;
      font-size: 22px;
      max-height: 66px;
      overflow: hidden;
      text-align: center;
    }

    .code {
      width: 190px;
      height: 190px;
      margin-top: 24px;
    }

    .check {
      display: flex;
      color: rgba(#fff, 0.8);
      margin-top: 12px;

      img {
        margin-right: 4px;
      }
    }

    .tip {
      font-size: 16px;
      color: #fff;
      margin-top: 32px;
    }
  }

  &-poster {
    position: relative;
    width: 100%;
    height: 533.6px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0px 1px 4px 2px rgba(18, 18, 18, 0.03);

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    ::v-deep .image-slot {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 16px;
      color: #ccc;
    }

    .dragQrcode img {
      width: 100%;
      height: 100%;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    font-size: 12px;

    &.mb-32 {
      margin-bottom: 32px;
    }

    .contact {
      display: flex;
      color: #6E788A;

      .msg {
        width: 14px;
        position: relative;
        top: -1px;
      }

      img {
        margin: 0 2px;
      }
    }

    .copyright {
      display: flex;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 32px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }

  &-fixed {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 1;

    &__place {
      height: 82px;
    }
  }

  &-expire {
    margin: 0 10px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    font-size: 12px;
    color: #fa541c;
    line-height: 1;
    box-shadow: 0px 2px 6px 0px rgba(18, 18, 18, 0.08);

    img {
      margin-right: 4px;
    }

    .time {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      background-color: #fa541c;
      color: #fff;
      font-size: 12px;
      padding: 0 4px;
      border-radius: 2px;
      min-width: 20px;
      text-align: center;
      box-sizing: border-box;
      margin: 0 2px;
    }
  }
}
</style>
