var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prize" },
    [
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "reward_title_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "avatar-name" }, [
                  row.reward_cover
                    ? _c("img", {
                        staticStyle: {
                          "margin-right": "8px",
                          "max-height": "24px",
                          "max-width": "24px"
                        },
                        attrs: { src: row.reward_cover, alt: "" }
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(row.reward_title))
                  ])
                ])
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                row.reward_type === 5
                  ? [
                      row.reward_value
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.getRewardDetail(row)
                                }
                              }
                            },
                            [_vm._v("查看奖品内容")]
                          )
                        : _c("span", [_vm._v("-")])
                    ]
                  : [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.getRewardDetail(row)
                            }
                          }
                        },
                        [_vm._v("查看奖品内容")]
                      )
                    ]
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品内容",
            "append-to-body": "",
            visible: _vm.dialogVisible,
            "custom-class": "q-dialog",
            width: "34%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "reward" },
            [
              _vm.rewardType === 1
                ? [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 6, maxRows: 10 },
                        resize: "none",
                        readonly: ""
                      },
                      model: {
                        value: _vm.rewardDetail,
                        callback: function($$v) {
                          _vm.rewardDetail = $$v
                        },
                        expression: "rewardDetail"
                      }
                    }),
                    _vm.rewardDetailImg
                      ? _c("div", { staticClass: "q-dialog__img" }, [
                          _c("div", { staticClass: "flex flex-center" }, [
                            _c("img", {
                              attrs: { src: _vm.rewardDetailImg, alt: "" }
                            })
                          ])
                        ])
                      : _vm._e()
                  ]
                : _vm.rewardType === 2
                ? _c("div", { staticClass: "q-dialog-block" }, [
                    _c("div", { staticClass: "reward-code" }, [
                      _vm._v(_vm._s(_vm.rewardDetail))
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }