var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "to-group" }, [
    _c(
      "div",
      { staticClass: "main-area" },
      [
        _c(
          "el-scrollbar",
          { staticClass: "form-area" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  size: "medium",
                  rules: _vm.rules,
                  "show-message": false,
                  model: _vm.form,
                  "label-width": "120px"
                }
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label:
                        _vm.type === "announcement"
                          ? "群发公告名称"
                          : "群发名称",
                      prop: "task_title"
                    }
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "280px" },
                      attrs: {
                        maxlength: "20",
                        "show-word-limit": "",
                        placeholder:
                          _vm.type === "announcement"
                            ? "请输入群发公告名称"
                            : "请输入群发名称"
                      },
                      model: {
                        value: _vm.form.task_title,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "task_title", $$v)
                        },
                        expression: "form.task_title"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { ref: "task_group", attrs: { label: "选择群聊" } },
                  [
                    _c("selected-table", {
                      ref: "groupTable",
                      on: { change: _vm.handleGroupChange }
                    })
                  ],
                  1
                ),
                _vm.type === "announcement"
                  ? _c(
                      "el-form-item",
                      {
                        ref: "task_announcement",
                        attrs: { label: "群公告内容" }
                      },
                      [
                        _c("ly-editor", {
                          staticClass: "act-form__editor",
                          attrs: {
                            title: "群公告",
                            id: "help",
                            content: _vm.form.content,
                            emoji: ""
                          },
                          on: { getContent: _vm.fn_getNoticeText }
                        })
                      ],
                      1
                    )
                  : _c(
                      "el-form-item",
                      { ref: "task_content", attrs: { label: "群发内容" } },
                      [
                        _c(
                          "div",
                          { staticClass: "msg-list" },
                          _vm._l(_vm.form.message, function(item, i) {
                            return _c("msg-template", {
                              key: item.temp,
                              class: "type" + item.msg_type,
                              attrs: {
                                title:
                                  "消息" +
                                  (i + 1) +
                                  "：" +
                                  _vm.getMsgType(item.msg_type),
                                msg: item,
                                disabled: _vm.getDisabledStatus(i)
                              },
                              on: { change: _vm.handleAction }
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              type: "primary",
                              icon: "iconfont icon-plus"
                            },
                            on: { click: _vm.handleAddContent }
                          },
                          [_vm._v("添加内容")]
                        ),
                        _c("add-content", {
                          ref: "addContent",
                          on: { push: _vm.handlePushMsg }
                        })
                      ],
                      1
                    ),
                _c(
                  "el-form-item",
                  { attrs: { label: "群发方式", prop: "book_time" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.form.send_time_type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "send_time_type", $$v)
                          },
                          expression: "form.send_time_type"
                        }
                      },
                      [_vm._v("立即群发")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 2 },
                        model: {
                          value: _vm.form.send_time_type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "send_time_type", $$v)
                          },
                          expression: "form.send_time_type"
                        }
                      },
                      [_vm._v("定时群发")]
                    ),
                    _vm.form.send_time_type === 2
                      ? _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期时间",
                            "default-time": "12:00:00",
                            "value-format": "timestamp",
                            "picker-options": _vm.pickerOptions
                          },
                          model: {
                            value: _vm.sendTime,
                            callback: function($$v) {
                              _vm.sendTime = $$v
                            },
                            expression: "sendTime"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.type !== "announcement"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "是否@所有人" } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: _vm.at,
                              callback: function($$v) {
                                _vm.at = $$v
                              },
                              expression: "at"
                            }
                          },
                          [_vm._v("否")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.at,
                              callback: function($$v) {
                                _vm.at = $$v
                              },
                              expression: "at"
                            }
                          },
                          [_vm._v("是")]
                        ),
                        _c("span", { staticClass: "tips" }, [
                          _vm._v(
                            "(必须满足：1.群发成员为群主 2.第一条消息为文本内容)"
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-scrollbar",
          { staticClass: "h5-area" },
          [
            _c("mobile", {
              attrs: {
                type: "group",
                at: _vm.at,
                msg: !_vm.type
                  ? _vm.form.message
                  : [{ msg_content: _vm.form.content }]
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "submit-area" },
      [
        _c(
          "el-button",
          {
            attrs: {
              round: "",
              icon: "iconfont icon-send-fill",
              type: "primary",
              loading: _vm.loading
            },
            on: { click: _vm.handleSubmit }
          },
          [_vm._v("提交")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }