var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticStyle: { "margin-bottom": "200px" },
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "140px",
        size: "medium"
      }
    },
    [
      _c("el-form-item", { attrs: { label: "上传海报：", prop: "img_url" } }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("ly-upload", {
              attrs: { img: _vm.form.img_url, tipText: "上传海报" },
              on: { getImg: _vm.fn_getPoster }
            }),
            _c(
              "div",
              {
                staticClass: "q-info text-small act-form__info",
                staticStyle: { flex: "1" }
              },
              [
                _vm._v(" 海报设计须知："),
                _c("br"),
                _vm._v(" (1) 建议图片尺寸：750px*1334px，分辨率72"),
                _c("br"),
                _vm._v(" (2) 支持JPG、PNG格式，图片大小2M以内 "),
                _c("br"),
                _vm._v(
                  " (3) 开启【用户头像】【用户昵称】，可在预览区域内移动展示位置。 "
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "用户头像：", prop: "avatar_sharp" } },
        [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { height: "36px" } },
            [
              _c("el-switch", {
                staticStyle: { "margin-right": "24px" },
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.form.avatar_status,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "avatar_status", $$v)
                  },
                  expression: "form.avatar_status"
                }
              }),
              _vm.form.avatar_status === 1
                ? _c(
                    "el-radio-group",
                    {
                      staticStyle: { position: "relative", top: "1px" },
                      model: {
                        value: _vm.form.avatar_sharp,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "avatar_sharp", $$v)
                        },
                        expression: "form.avatar_sharp"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("圆形")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("方形")])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "el-form-item",
        { attrs: { label: "用户昵称：", prop: "nickname_status" } },
        [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { height: "36px" } },
            [
              _c("el-switch", {
                staticStyle: { "margin-right": "24px" },
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.form.nickname_status,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname_status", $$v)
                  },
                  expression: "form.nickname_status"
                }
              }),
              _vm.form.nickname_status
                ? [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-flex",
                          "margin-right": "24px"
                        }
                      },
                      [
                        _vm._v(" 颜色："),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.form.nickname_color,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "nickname_color", $$v)
                            },
                            expression: "form.nickname_color"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "65px" } },
                      [
                        _c("el-slider", {
                          staticStyle: { flex: "1" },
                          attrs: {
                            min: 10,
                            max: 36,
                            "format-tooltip": _vm.formatTooltip
                          },
                          model: {
                            value: _vm.form.nickname_size,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "nickname_size", $$v)
                            },
                            expression: "form.nickname_size"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { margin: "0 24px 0 8px" } }, [
                      _vm._v(_vm._s(_vm.form.nickname_size) + "px")
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button-group",
                          { staticClass: "nickname-align__btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type:
                                    _vm.form.nickname_align === "left"
                                      ? "primary"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.form.nickname_align = "left"
                                  }
                                }
                              },
                              [_vm._v("左对齐")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type:
                                    _vm.form.nickname_align === "center"
                                      ? "primary"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.form.nickname_align = "center"
                                  }
                                }
                              },
                              [_vm._v("居中")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type:
                                    _vm.form.nickname_align === "right"
                                      ? "primary"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.form.nickname_align = "right"
                                  }
                                }
                              },
                              [_vm._v("右对齐")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }