<template>
  <el-dialog
    :visible.sync="promoteVisible"
    width="800px"
    title="推广素材"
    class="q-dialog"
  >
    <div class="promote" v-loading="promoteLoading">
      <div class="promote-left">
        <div class="promote-left__title">裂变海报</div>
        <div class="promote-left__poster">
          <img
            :src="promotePoster"
            id="promotePoster"
            v-if="promotePoster"
            alt=""
          />
        </div>
        <el-button
          type="primary"
          size="medium"
          class="is-block"
          :disabled="!promotePoster"
          @click="fn_downloadImg('poster')"
          ><i class="ri-download-2-line"></i> 下载海报</el-button
        >
      </div>
      <div class="promote-gap"></div>
      <div class="promote-right">
        <div class="promote-right__item">
          <div class="promote-right__label">活动链接</div>
          <div class="promote-right__content">
            <div class="left">
              <el-input size="medium" readonly v-model="promoteUrl"></el-input>
            </div>
            <el-button
              type="text"
              :class="'copy' + promoteId + 'link'"
              :disabled="!promoteUrl"
              :data-clipboard-text="promoteUrl"
              @click="fn_clip(promoteId, 'link')"
              >复制</el-button
            >
          </div>
        </div>
        <div class="promote-right__item">
          <div class="promote-right__label">裂变欢迎语</div>
          <div class="promote-right__content">
            <div class="left">
              <el-input
                size="medium"
                type="textarea"
                resize="none"
                readonly
                v-model="promoteWords"
              ></el-input>
            </div>
            <el-button
              type="text"
              :class="'copy' + promoteId + 'word'"
              :disabled="!promoteWords"
              :data-clipboard-text="promoteWords"
              @click="fn_clip(promoteId, 'word')"
              >复制</el-button
            >
          </div>
        </div>
        <div class="promote-right__item">
          <div class="promote-right__label">推广二维码</div>
          <div class="promote-right__content code">
            <div class="left">
              <div class="promote-right__code">
                <img
                  :src="promoteCode"
                  id="promoteCode"
                  v-if="promoteCode"
                  alt=""
                />
              </div>
            </div>
            <el-button
              type="text"
              :disabled="!promoteCode"
              @click="fn_downloadImg('code')"
              ><i class="ri-download-2-line"></i> 下载二维码</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
import initCvs from '@/components/qrcanvas/comform'
export default {
  name: 'Promote',
  props: {
    promoteVisibleProp: {
      type: Boolean,
      default: false
    },
    promoteId: {
      type: [Number, null],
      default: null
    }
  },

  data () {
    return {
      promoteLoading: true,
      promoteCode: '',
      promoteUrl: '',
      promotePoster: '',
      promoteWords: ''
    }
  },

  computed: {
    promoteVisible: {
      get () {
        return this.promoteVisibleProp
      },
      set (val) {
        this.$emit('update:promoteVisibleProp', val)
      }
    }
  },

  watch: {
    promoteVisible (val) {
      if (val) {
        this.fn_getData()
      }
    }
  },

  methods: {

    // 获取推广信息
    async fn_getData () {
      this.promoteLoading = true

      if (!this.promoteId) {
        return false
      }

      this.promoteCode = ''
      this.promoteUrl = ''
      this.promotePoster = ''
      this.promoteWords = ''
      this.promoteVisible = true
      const data = await this.axios.get('taskUrls', {
        params: {
          activity_id: this.promoteId
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.promoteLoading = false
        return false
      }

      this.promoteCode = data.qrcode_url
      this.promoteUrl = data.h5_url
      this.promoteWords = data.rule
      this.fn_initCodeImg(data.qrcode_url)
      if (data.poster && data.poster.img_url && data.poster.img_url !== '') {
        this.fn_initPosterImg(data.poster)
      }

      setTimeout(() => {
        this.promoteLoading = false
      }, 300)
    },

    // 转换活码图片
    fn_initCodeImg (imgUrl) {
      const _img = new Image()
      let cvs, ctx
      _img.crossOrigin = 'anonymous'
      _img.src = imgUrl
      _img.onload = () => {
        cvs = document.getElementById('codeCanvas')
        ctx = cvs.getContext('2d')
        ctx.drawImage(_img, 0, 0, 180, 180)
        this.codeImg = cvs.toDataURL('image/png')
      }
    },

    // 生成海报
    fn_initPosterImg (data) {
      const that = this

      // 用户头像，昵称
      let avatar_url = require('@/assets/images/default_avatar.png')
      let nickname = ''
      const _userInfo = localStorage.getItem('userInfo')
      if (_userInfo) {
        const _info = JSON.parse(_userInfo)

        if (_info.avatar_url && _info.avatar_url !== '') {
          avatar_url = _info.avatar_url
        }

        if (_info.nickname && _info.nickname !== '') {
          nickname = _info.nickname
        }
      }

      const sources = [
        {
          image: data.img_url, // 背景, 是否把该图片绘制成圆形，该图片必须是正方形,否则会导致该形状异常，默认为false
          left: 0, // 距离画布的左侧距离
          top: 0, // 距离画布的顶部距离
          width: 765, // 在画布中的宽度
          height: 1334 // 在画布中的高度
        }
      ]

      if (data.avatar_status === 1) {
        sources.push({
          arc: data.avatar_sharp === 2, // 头像
          image: avatar_url,
          left: data.avatar_x * 2 + 6, // 距离画布的左侧距离
          top: data.avatar_y * 2, // 距离画布的顶部距离
          width: data.avatar_width * 2 + 6, // 在画布中的宽度
          height: data.avatar_height * 2 + 6 // 在画布中的高度
        })
      }

      if (data.nickname_status === 1) {
        sources.push({
          text: nickname, // 用户昵称， 绘制文字内容、人为换行请使用\n  |  设置宽度后自动换行
          left: data.nickname_x + 6, // 距离画布的左侧距离
          // left: (237 * 2) - (19 * 7 / 2) + 6, //距离画布的左侧距离
          top: data.nickname_y * 2, // 距离画布的顶部距离
          fontSize: data.nickname_size * 2 + 6, // 文字大小
          color: data.nickname_color
        })
      }

      // 如果有二维码
      if (this.promoteCode && this.promoteCode !== '') {
        sources.push(
          {
            image: this.promoteCode ? this.promoteCode : '', // 二维码
            left: data.qrcode_x * 2 + 6, // 距离画布的左侧距离
            top: data.qrcode_y * 2, // 距离画布的顶部距离
            width: data.qrcode_width * 2 + 6, // 在画布中的宽度
            height: data.qrcode_height * 2 + 6 // 在画布中的高度
          }
        )
      }
      initCvs({
        width: 765, // 765
        height: 1334, // 1334
        sources, // sources 以下配置
        load: function () { },
        success: (base64) => {
          that.promotePoster = base64
        }
      })
    },

    // 下载图片
    fn_downloadImg (type) {
      const a = document.createElement('a')
      a.setAttribute('download', type + '_' + this.promoteId)
      if (type === 'code') {
        a.setAttribute('href', this.codeImg)
      } else if (type === 'poster') {
        a.setAttribute('href', this.promotePoster)
      }
      a.click()
    },

    // 复制内容
    fn_clip (id, text) {
      const clipboard = new Clipboard('.copy' + id + text)
      const _type = text === 'link' ? '活动链接' : '欢迎语'
      clipboard.on('success', () => {
        clipboard.destroy()
        this.fn_msg('success', `复制${_type}成功`)
      })
      clipboard.on('error', (e) => {
        this.fn_msg('info', `复制${_type}失败，请手动复制`)
        console.error('E:', e)
      })
    },

    // 提示框
    fn_msg (type, text) {
      if (this.msgBox) {
        this.msgBox.close()
      }
      this.msgBox = this.$message[type](text)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.promote {
  display: flex;
  margin: 16px 32px;

  &-left {
    width: 184px;

    &__title {
      margin-bottom: 8px;
    }

    &__poster {
      width: 100%;
      height: 327px;
      background-color: rgba($--color-primary, 0.06);
      margin-bottom: 18px;
      border-radius: 2px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .el-button {
      border-radius: 4px;
    }
  }

  &-gap {
    width: 1px;
    background-color: #f5f5f5;
    margin: 0 48px;
  }

  &-right {
    flex: 1;

    &__code {
      width: 118px;
      height: 118px;
      background-color: rgba($--color-primary, 0.06);

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &__label {
      margin-bottom: 8px;
    }

    &__content {
      display: flex;
      align-items: flex-start;

      .left {
        width: 338px;
        margin-right: 16px;
      }

      .el-button {
        line-height: 34px;
        padding: 0;
      }

      ::v-deep .el-textarea__inner {
        height: 132px;
      }

      ::v-deep .el-input__inner,
      ::v-deep .el-textarea__inner {
        background-color: #f7f8fc;
        color: #454d5b;
        border-color: #eaebf2;
      }

      &.code {
        align-items: center;
        .left {
          width: auto;
        }
      }
    }
  }
}
</style>
