<!--
 * @Author: zhan
 * @Date: 2022-08-01 15:28:23
 * @LastEditTime: 2022-09-08 18:22:01
 * @LastEditors: zhanln
-->
<template>
  <div>
    <ly-form
      ref="lyFormRef"
      class="act-form"
      :formObj="formObj"
      :form="formData"
      :rules="rules"
      @setData="fn_setData"
      v-loading="cardLoading"
    >
      <template #wecomTag>
        <el-form-item label="客户标签：" prop="tags">
          <el-radio-group v-model="formData.add_tags_type">
            <el-radio :label="1">添加即打标签</el-radio>
            <el-radio :label="2">新老用户场景标签</el-radio>
          </el-radio-group>

          <tag-select-v2
            @getTagId="fn_getTagId($event, 'tags1')"
            :checked="formData.tags1"
            v-if="formData.add_tags_type === 1"
          >
          </tag-select-v2>

          <template v-else>
            <div>
              新用户：<span class="q-info">添加涨粉账号的新增用户打标签</span>
            </div>
            <tag-select-v2
              @getTagId="fn_getTagId($event, 'tags2')"
              :checked="formData.tags2"
            >
            </tag-select-v2>
            <div class="mt-16">
              老用户：<span class="q-info">添加涨粉账号的企业老用户打标签</span>
            </div>
            <tag-select-v2
              @getTagId="fn_getTagId($event, 'tags3')"
              :checked="formData.tags3"
            >
            </tag-select-v2>
          </template>
        </el-form-item>
      </template>

      <template #rewardType>
        <el-radio-group v-model="formData.reward_type" class="rule-form__radio">
          <el-radio :label="1" class="mb-8"
            >活动结束
            <el-input-number
              v-model="formData.reward_day"
              size="small"
              controls-position="right"
              :min="1"
              :max="10"
              class="number-small number-mn"
              @change="fn_changeDay"
            ></el-input-number
            >天可领取
            <el-tooltip class="item" placement="top" popper-class="q-tooltip">
              <div slot="content">
                活动结束后，在指定时间内，已完成阶段任务的用户依然可以添加客服领取奖品
              </div>
              <i class="ri-question-line"></i>
            </el-tooltip>
          </el-radio>
          <el-radio :label="2"
            >活动结束不再发放
            <el-tooltip class="item" placement="top" popper-class="q-tooltip">
              <div slot="content">
                活动结束后，已完成阶段任务的用户不再发放奖品
              </div>
              <i class="ri-question-line"></i>
            </el-tooltip>
          </el-radio>
        </el-radio-group>
      </template>
    </ly-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import tagSelectV2 from '@/components/wecomTags'
import mapTag from '@/common/mapTag'
export default {
  name: 'way',

  components: {
    tagSelectV2
  },

  data () {
    // 标签校验
    const checkTags = (rule, value, callback) => {
      if (this.formData.add_tags === 1 && this.formData.add_tags_type === 1 && !this.formData.tags1) {
        return callback(new Error('请选择标签'))
      }
      if (this.formData.add_tags === 1 && this.formData.add_tags_type === 2 && (!this.formData.tags2 || !this.formData.tags3)) {
        return callback(new Error('请选择标签'))
      }
      callback()
    }
    return {
      cardLoading: true,
      formData: {
        add_user: null,
        add_user_ids: null,
        welcome_msg: '',
        add_tags: 2,
        add_tags_type: 1,
        tags1: '',
        tags2: '',
        tags3: '',
        reward_type: 1,
        reward_day: 3
      },
      rules: {
        add_user_ids: [
          { required: true, message: '请选择涨粉账号', trigger: 'change' }
        ],
        welcome_msg: [
          { required: true, message: '请填写裂变欢迎语', trigger: 'change' }
        ],
        tags: [
          { validator: checkTags, required: true, trigger: 'change' }
        ]
      },
      base_welcome_msg: '#用户昵称#，恭喜您完成任务\n请点击下方领取奖品内容\n#活动链接#'
    }
  },

  computed: {
    formObj () {
      return {
        labelWidth: '160px',
        item: [
          {
            type: 'switch',
            label: '开启裂变涨粉',
            prop: 'add_user',
            key: [2, 1],
            switchTip: '开启后，可设置需添加涨粉账号后领取奖品',
            attr: {
              disabled: this.eType === 1
            }
          },
          {
            type: 'emp',
            label: '涨粉账号',
            prop: 'add_user_ids',
            show: () => {
              return this.formData.add_user === 1
            }
          },
          {
            type: 'textEditor',
            label: '裂变欢迎语',
            prop: 'welcome_msg',
            attr: {
              emoji: true,
              insetMenu: ['用户昵称', '活动链接'],
              recoverContent: this.base_welcome_msg
            },
            show: () => {
              return this.formData.add_user === 1
            }
          },
          {
            type: 'switch',
            label: '自动打标签',
            prop: 'add_tags',
            key: [2, 1],
            switchTip: '自动为裂变账号的客户打标签',
            show: () => {
              return this.formData.add_user === 1
            }
          },
          {
            slot: 'wecomTag',
            label: '客户标签',
            prop: 'tags',
            show: () => {
              return this.formData.add_user === 1 && this.formData.add_tags === 1
            }
          },
          {
            itemSlot: 'rewardType',
            label: '领奖规则',
            prop: 'reward_type',
            show: () => {
              return this.formData.add_user === 1
            }
          }
        ]
      }
    },
    ...mapState({
      eType: state => state.reserveTask.eType
    })
  },

  watch: {
    'formData.add_user' (val) {
      this.fn_setStore('add_user', val)
    },
    'formData.welcome_msg' (val) {
      this.fn_setStore('welcome_msg', val)
    },
    'formData.add_tags_type' () {
      this.$nextTick(() => {
        this.$refs.lyFormRef.clearValidate('tags')
      })
    }
  },

  methods: {

    // 初始化
    fn_setForm (data) {
      if (data) {
        data.welcome_msg = mapTag(data.welcome_msg)
        data.add_user_ids = data.add_user_ids ? data.add_user_ids.split(',') : null
        this.formData = data
      } else {
        this.fn_init()
      }

      console.log('this.formData, this.formData', this.formData)

      this.$nextTick(() => {
        this.cardLoading = false
      })
    },

    // 配置默认表单
    fn_init () {
      this.formData.add_user = 2
      this.formData.welcome_msg = this.base_welcome_msg
    },

    // 标签设置
    fn_getTagId (val, name) {
      this.formData[name] = val ? val.join(',') : ''
      this.$refs.lyFormRef.verify('tags')
    },

    fn_changeDay (val) {
      if (!val) {
        this.fn_setData(1, 'reward_day')
      }
    },

    // 组件内容回调及校验
    fn_setData (val, name) {
      this.formData[name] = val
      this.$refs.lyFormRef.verify(name)
      console.log('store', this.formData)
    },

    fn_setStore (name, val) {
      this.$store.commit('reserveTask/SET_DATA', {
        name,
        val
      })
    },

    // 表单校验，通过则返回 form
    async verify (draft) {
      let formValid = true
      if (!draft) {
        formValid = await this.$refs.lyFormRef.verify()
      }

      if (!formValid) return false

      const {
        add_user,
        add_user_ids,
        welcome_msg,
        add_tags,
        add_tags_type,
        tags1,
        tags2,
        tags3,
        reward_type,
        reward_day
      } = this.formData

      const basicForm = {
        add_user,
        welcome_msg,
        add_tags,
        add_tags_type,
        reward_type,
        reward_day
      }

      const form = {
        ...basicForm,
        add_user_ids: add_user === 1 ? (add_user_ids ? add_user_ids.join(',') : '') : '',
        welcome_msg: mapTag(welcome_msg, false),
        tags: add_tags_type === 1 ? tags1 : tags2,
        old_tags: add_tags_type === 2 ? tags3 : ''
      }

      return form
    }
  }
}
</script>

<style lang="scss" scoped>
.act-form {
  ::v-deep {
    .el-input {
      max-width: 320px;
    }

    .ly-editor {
      width: 485px;
    }
  }
}

.rule-form__radio {
  .el-radio {
    display: block;
    line-height: 36px;
  }
}

.number-small {
  width: 120px;
  margin: 0 4px;

  ::v-deep .el-input__inner {
    padding-left: 4px;
    padding-right: 36px;
  }

  ::v-deep {
    [class^='el-icon-'] {
      margin-right: 0;
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}

.number-mn {
  width: 100px;

  ::v-deep .el-input__inner {
    padding-right: 30px;
  }
}
</style>
