<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>

    <div class="edit-card__content" v-loading="cardLoading">
      <el-form
        :model="posterForm"
        ref="form"
        :rules="rules"
        label-width="125px"
        class="edit-task__form"
        size="medium"
      >
        <el-form-item
          label="上传海报"
          ref="poster"
          required
          :error="posterTips"
          style="max-width: 560px"
        >
          <div class="flex">
            <el-upload
              action="#"
              accept=".png, .jpg"
              :http-request="requestQiniu"
              :on-remove="removePoster"
              list-type="picture-card"
              :limit="1"
              ref="uploadFile"
              :file-list="posterForm.img_url"
              :class="[
                'upload',
                posterForm.img_url.length === 0 ? '' : 'upload-empty',
              ]"
            >
              <div class="default">
                <i class="iconfont icon-picture"></i>
                <span>上传海报</span>
              </div>
            </el-upload>
            <div
              class="q-info text-small"
              style="line-height: 22px; margin-left: 24px"
            >
              海报设计须知：<br />
              (1) 建议图片尺寸：750px*1334px，分辨率72 <br />
              (2) 支持JPG、PNG格式，图片大小2M以内 <br />
              (3)
              开启【用户头像】【用户昵称】【二维码】，可在预览区域内移动展示位置。
            </div>
          </div>
        </el-form-item>
        <el-form-item label="用户头像" prop="avatar_sharp">
          <div class="flex" style="height: 36px">
            <el-switch
              v-model="posterForm.avatar_status"
              :active-value="1"
              :inactive-value="0"
              style="margin-right: 24px"
              @change="fn_setForm($event, 'avatar_status')"
            ></el-switch>
            <el-radio-group
              v-model="posterForm.avatar_sharp"
              style="position: relative; top: 1px"
              @change="fn_setForm($event, 'avatar_sharp')"
            >
              <el-radio :label="2">圆形</el-radio>
              <el-radio :label="1">方形</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="用户昵称" prop="nickname_status">
          <div class="flex">
            <el-switch
              v-model="posterForm.nickname_status"
              :active-value="1"
              :inactive-value="0"
              style="margin-right: 24px"
              @change="fn_setForm($event, 'nickname_status')"
            ></el-switch>
            <span style="display: inline-flex; margin-right: 24px">
              颜色：<el-color-picker
                v-model="posterForm.nickname_color"
                @change="fn_setForm($event, 'nickname_color')"
              ></el-color-picker>
            </span>
            <div style="width: 65px">
              <el-slider
                v-model="posterForm.nickname_size"
                :min="10"
                :max="36"
                :format-tooltip="formatTooltip"
                style="flex: 1"
                @change="fn_setNickNameAlign()"
              ></el-slider>
            </div>
            <div style="margin: 0 24px 0 8px">
              {{ posterForm.nickname_size }}px
            </div>
            <div>
              <el-button-group class="nickname-align__btn">
                <el-button
                  round
                  size="small"
                  :type="posterForm.nickname_align === 'left' ? 'primary' : ''"
                  @click="fn_setNickNameAlign('left')"
                  >左对齐</el-button
                >
                <el-button
                  round
                  size="small"
                  :type="
                    posterForm.nickname_align === 'center' ? 'primary' : ''
                  "
                  @click="fn_setNickNameAlign('center')"
                  >居中</el-button
                >
                <el-button
                  round
                  size="small"
                  :type="posterForm.nickname_align === 'right' ? 'primary' : ''"
                  @click="fn_setNickNameAlign('right')"
                  >右对齐</el-button
                >
              </el-button-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="分享话术"
          prop="share_msg"
          class="reward-form__item"
          :error="shareError"
          style="width: 60%; min-width: 560px"
        >
          <ly-editor
            class="share-msg"
            title="分享话术"
            id="posterShare"
            :content="posterForm.share_msg"
            :recoverContent="recoverMsg"
            :error="shareError !== ''"
            @getContent="fn_getWelWords"
          ></ly-editor>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import lyEditor from '@/components/lyEditor'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
export default {
  name: 'Poster',
  props: {
    title: {
      type: String,
      defualt: () => {
        return ''
      }
    }
  },
  mixins: [qiniuUpload],
  data () {
    return {
      cardLoading: true,
      sliderMarks: {
        12: '小',
        34: '大'
      },
      posterForm: {
        img_url: [],
        avatar_sharp: 2,
        avatar_status: 1,
        avatar_x: 30,
        avatar_y: 40,
        avatar_width: 50,
        avatar_height: 50,
        nickname_status: 1,
        nickname_size: 12,
        nickname_color: '#000000',
        nickname_x: 90,
        nickname_y: 55,
        nickname_offsetX: 0,
        nickname_align: 'left',
        qrcode_x: 107.5,
        qrcode_y: 427,
        qrcode_width: 130,
        qrcode_height: 130,
        share_msg: '我想要领取奖品，点击入群就能帮我助力成功啦[笑脸]'
      },
      rules: {
        share_msg: [
          { required: true, message: '请输入分享话术', trigger: 'change' }
        ]
      },
      posterTips: '',
      posterCale: false,
      shareError: '',
      recoverMsg: '我想要领取奖品，点击入群就能帮我助力成功啦[笑脸]'
    }
  },
  computed: {
    isReady () {
      return this.$store.getters['groupfission/GET_READY']
    }
  },
  watch: {
    isReady (val) {
      if (val) {
        const isEdit = this.$store.getters['groupfission/GET_TYPE']
        if (isEdit) {
          this.posterForm = this.$store.getters['groupfission/GET_POSTER']
        }
        this.cardLoading = false
      }
    },
    'posterForm.share_msg' (val) {
      this.shareError = val ? '' : '请输入分享话术'
    }
  },
  methods: {

    // 计算375
    fn_caleCoord2 (num, type) {
      let _num = null
      if (type === 'x') {
        _num = Math.round(375 / 345 * num)
      } else if (type === 'y') {
        _num = Math.round(667 / 613.64 * num)
      }
      return _num
    },

    // 上传背景海报
    async requestQiniu (opts) {
      this.posterForm.img_url = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.posterForm.img_url = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.fn_setForm(this.posterForm.img_url, 'img_url')
          },
          null,
          {
            type: ['jpg', 'png'],
            size: '2MB'
          }
        )
      }

      this.posterTips = ''
    },

    // 移除背景
    removePoster () {
      this.posterForm.img_url = []
      this.posterTips = '请上传海报图片'
      this.fn_setForm([], 'img_url')
    },

    // 字体大小提示
    formatTooltip (val) {
      return val + 'px'
    },

    // 设置昵称对齐
    fn_setNickNameAlign (type) {
      const _type = type || this.posterForm.nickname_align
      let _x = null
      if (type) {
        this.posterForm.nickname_align = type
      }
      if (_type === 'left') {
        this.posterForm.nickname_offsetX = 0
      } else if (_type === 'center') {
        _x = this.fn_caleCoord2(this.posterForm.nickname_x, 'x')
        this.posterForm.nickname_offsetX = (_x + this.posterForm.nickname_size * 6 / 2).toFixed() - 2
      } else if (_type === 'right') {
        _x = this.fn_caleCoord2(this.posterForm.nickname_x, 'x')
        this.posterForm.nickname_offsetX = (_x + this.posterForm.nickname_size * 6).toFixed() - 2
      }
      this.fn_setForm(this.posterForm.nickname_size, 'nickname_size')
      this.fn_setForm(_type, 'nickname_align')
      this.fn_setForm(this.posterForm.nickname_offsetX, 'nickname_offsetX')
    },

    // 分享话术
    fn_getWelWords (content) {
      if (this.isReady) {
        this.posterForm.share_msg = content
        this.fn_setForm(content, 'share_msg')
      }
    },

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: 'poster',
        name: type,
        val: val
      })
    },

    // 表单验证
    verify () {
      let verify = true

      if (this.posterForm.img_url.length === 0) {
        this.posterTips = '请上传海报图片'
        verify = false
      }

      if (this.posterForm.share_msg === '') {
        this.shareError = '请输入分享话术'
        verify = false
      }

      return verify
    }
  },
  components: {
    lyEditor
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
@import "@/assets/scss/edit.scss";

.share-msg {
  width: 100%;
  max-width: 558px;

  ::v-deep .el-textarea__inner {
    border-radius: 4px;
    border: none;
    height: 80px;
  }
}

.upload {
  line-height: 1;
  ::v-deep .el-upload-list__item {
    margin: 0;
    transition: none !important;
  }
  ::v-deep {
    .el-upload-list__item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-upload-list__item-thumbnail {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}
.upload-empty {
  line-height: 1;
  ::v-deep .el-upload--picture-card {
    display: none;
  }
  ::v-deep .el-upload-list__item {
    margin: 0;
  }
}

::v-deep .el-slider__button {
  width: 10px;
  height: 10px;
}

.edit-task__form {
  width: 100%;
}
</style>
