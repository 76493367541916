<!--
 * @Descripttion: 参与用户页面
 * @version: 1.0.0
-->
<template>
  <div class="gf-users page-warp">
    <div class="select-box">
      <label style="color: rgba(0, 0, 0, 0.85)">选择活动：</label>
      <el-select
        size="medium"
        v-model="activity_id"
        @change="handleChange"
        filterable
        placeholder="请选择活动"
      >
        <el-option
          v-for="item in activities"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          type="primary"
          :loading="exportLoading"
          :disabled="!tableData || tableData.length === 0"
          icon="iconfont icon-download"
          @click="handleExport"
          >导出数据</el-button
        >
      </template>

      <template #total_lottery_num_header>
        <span style="margin-right: 4px">总抽奖次数</span>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">完成助力任务获得的总抽奖次数，包含无门槛次数</div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>

      <template #lottery_num_header>
        <span style="margin-right: 4px">中奖次数</span>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">
            统计获取奖品的次数，不包含奖品类型为“谢谢参与”的奖品次数
          </div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>

      <template #join_type_header>
        <div>
          参与方式
          <el-tooltip class="item" placement="top" popper-class="q-tooltip">
            <div slot="content">
              <div style="margin-bottom: 8px">
                链接进入：用户通过直接点击活动链接参与活动，参与活动后不能再为其他人助力
              </div>
              <div>
                添加员工：用户通过扫码海报二维码添加员工参与活动，参与活动后不能再为其他人助力
              </div>
            </div>
            <i class="iconfont icon-info-fill q-info"></i>
          </el-tooltip>
        </div>
      </template>

      <template #nickname="{ row }">
        <div class="flex flex-start">
          <img
            :src="row.avatar"
            alt=""
            class="ly-table__main-avatar"
            v-if="row.avatar"
          />
          <span>{{ row.nickname }}</span>
        </div>
      </template>

      <template #tag_name_status="{ row }">
        <ly-drow :list="row.tag_name" dep></ly-drow>
      </template>

      <template #invitor_name="{ row }">
        <ly-tag :content="row.invitor_name" :size="3" only>
          <i class="iconfont icon-superior" slot="left"></i>
        </ly-tag>
      </template>

      <template #step="{ row }">
        <div class="step-box">
          <div
            v-if="row.first_finish !== 0"
            class="item"
            :class="{ 'is-done': row.first_finish === 1 }"
          >
            一阶：<span>{{
              row.first_finish === 1 ? "已完成" : "未完成"
            }}</span>
          </div>
          <div
            v-if="row.second_finish !== 0"
            class="item"
            :class="{ 'is-done': row.second_finish === 1 }"
          >
            二阶：<span>{{
              row.second_finish === 1 ? "已完成" : "未完成"
            }}</span>
          </div>
          <div
            v-if="row.third_finish !== 0"
            class="item"
            :class="{ 'is-done': row.third_finish === 1 }"
          >
            三阶：<span>{{
              row.third_finish === 1 ? "已完成" : "未完成"
            }}</span>
          </div>
        </div>
      </template>

      <template #channel_id="{ row }">
        {{ row.channel_name || "-" }}
      </template>

      <template #add_user_name="{ row }">
        <ly-drow :list="row.add_user_name"></ly-drow>
      </template>

      <template #add_status="{ row }">
        <ly-status type="success" v-if="row.add_status === 1">已添加</ly-status>
        <ly-status type="danger" v-else-if="row.add_status === 2"
          >已删除</ly-status
        >
        <span v-else>-</span>
      </template>
    </ly-table>
    <!--  用户详情 -->
    <user-detail ref="userDetail"></user-detail>
  </div>
</template>
<script>
import { users } from './http.js'
import lyTable from '@/components/mixins/ly-table.js'
import userDetail from './components/userDetail.vue'
import lyDrow from '@/components/lyDrow'
import lyTag from '@/components/lyTag'
export default {
  components: {
    userDetail, lyDrow, lyTag
  },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: users,
      activity_id: '',
      activity: {
        activity_name: ''
      },
      exportLoading: false,
      activities: [],
      channelOps: [{ label: '全部', value: '' }]
    }
  },
  provide () {
    return {
      activity: this.activity
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        operation: [{
          label: '详情',
          handler (row) {
            _this.$refs.userDetail.visible = true
            _this.$refs.userDetail.userInfo = row
          }
        }],
        operationWidth: 75,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname',
            slot: 'nickname',
            search: true,
            width: 210
          },
          {
            label: '用户类型',
            prop: 'user_type',
            columnProps: {
              minWidth: 110
            },
            search: true,
            type: 'select',
            align: 'center',
            source: [
              { label: '全部', value: null },
              { label: '新用户', value: 1 },
              { label: '老用户', value: 2 }
            ],
            formatter (row, column, cellValue, inde) {
              if (cellValue === 1) {
                return '新用户'
              } else if (cellValue === 2) {
                return '老用户'
              } else {
                return '-'
              }
            }
          },
          {
            label: '用户标签',
            prop: 'tag_name',
            width: 150,
            slot: 'tag_name_status',
            type: 'select',
            source: [
              { label: '全部', value: 0 },
              { label: '在群', value: 1 },
              { label: '退群', value: 2 }
            ]
          },
          {
            label: '来源渠道',
            prop: 'channel_id',
            columnProps: {
              minWidth: 110
            },
            slot: 'channel_id',
            search: true,
            type: 'select',
            source: this.channelOps
          },
          {
            label: '直接上级',
            prop: 'invitor_name',
            width: 120
          },
          {
            label: '参与时间',
            prop: 'join_date',
            key: ['join_start', 'join_end'],
            sortable: true,
            columnProps: {
              minWidth: 110
            },
            search: true,
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '总抽奖次数',
            prop: 'total_lottery_num',
            headerSlot: 'total_lottery_num_header',
            columnProps: {
              minWidth: 110
            },
            align: 'center'
          },
          {
            label: '已抽奖次数',
            prop: 'use_lottery_num',
            columnProps: {
              minWidth: 110
            },
            align: 'center'
          },
          {
            label: '中奖次数',
            prop: 'lottery_num',
            headerSlot: 'lottery_num_header',
            width: 130,
            align: 'center'
          },
          {
            label: '有效邀请人数',
            prop: 'invite_num',
            sortable: true,
            width: 140,
            align: 'center'
          },
          {
            label: '参与方式',
            prop: 'join_type',
            headerSlot: 'join_type_header',
            columnProps: {
              minWidth: 110
            },
            align: 'center',
            formatter: (row, column, cellValue, index) => {
              return cellValue === 1 ? '链接进入' : '添加员工'
            }
          },
          {
            label: '添加账号',
            prop: 'add_user_name',
            key: 'add_user_id',
            width: 220,
            slot: 'add_user_name',
            search: true,
            type: 'emp'
          },
          {
            label: '添加状态',
            prop: 'add_status',
            columnProps: {
              minWidth: 110
            },
            slot: 'add_status',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: 0 },
              { label: '已添加', value: 1 },
              { label: '已删除', value: 2 }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.activity_id = this.$route.query.id * 1
    this.getActsOps()
    this.channelSel()
  },
  methods: {
    // 获取活动选项
    getActsOps () {
      this.COMM_HTTP.selectList().then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.activities = res || []
        this.activity.activity_name = this.getActName()
      })
    },
    // 获取渠道选项
    channelSel () {
      this.COMM_HTTP.channelSel({
        activity_id: this.activity_id,
        page: 1,
        per_page: 1000
      }).then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.channelOps = [{ label: '全部', value: '' }]
        const result = res.list || []
        result.map((e, i) => {
          this.channelOps.push({
            label: e.channel_name,
            value: e.id
          })
        })
      })
    },
    /**
     * @description: 格式化参数
     * @param {*}
     * @return {*}
     */
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activity_id
      }
    },
    /**
     * @description: 下载回调
     * @param {*}
     * @return {*}
     */
    async handleExport () {
      this.exportLoading = true
      const params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqExport({
        page: 1,
        per_page: 9999,
        ...params
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        this.exportLoading = false
        return false
      }
      this.handleDownload(res.url)
      this.exportLoading = false
    },
    /**
     * @description: 获取活动名称
     * @param {*}
     * @return {*}
     */
    getActName () {
      const _obj = this.activities.find(n => {
        return n.id === this.activity_id
      })
      return _obj.title
    },
    // 获取Excel名称
    getFileName () {
      let _name = ''
      let actName = ''
      if (!this.activity.activity_name) {
        actName = this.getActName()
      } else {
        actName = this.activity.activity_name
      }
      _name = `[${actName}]-参与用户-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },
    /**
     * @description: 下载url
     * @param {*} url
     * @return {*}
     */
    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },
    /**
     * @description: 改变活动
     * @param {*}
     * @return {*}
     */
    handleChange () {
      this.$router.replace({ path: this.$route.path, query: { id: this.activity_id } })
      this.activity.activity_name = this.getActName()
      this.$nextTick(() => {
        this.channelSel()
        this.queryData()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.gf-users {
  display: flex;
  flex-direction: column;
}
.select-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  .el-select {
    width: 320px;
  }
}
.board {
  margin-bottom: 24px;
}
.board-item {
  height: 100px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 16px 24px;
  box-sizing: border-box;
  align-items: center;
  background: #fdfdfd;
  box-shadow: 0px 16px 32px -4px rgb(145 158 171 / 24%),
    0px 0px 2px 0px rgb(145 158 171 / 24%);
  .desc {
    display: flex;
    align-items: center;
    width: 100%;
    span {
      flex: 1;
    }
    i {
      width: 20px;
    }
  }
  .value {
    font-size: 26px;
    font-weight: bold;
    align-self: flex-start;
  }
}
.step-box {
  span {
    color: #909399;
  }
  .is-done span {
    color: #00a86e;
  }
}
.icon-superior {
  font-size: 14px;
  color: #2b7cff;
}
::v-deep {
  .m-group_name {
    flex: 1;
  }
}
</style>
