<!--
 * @Description: 无
 * @Version: 1.0
 * @Autor: wangs
 * @Date: 2021-03-30 14:31:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-27 14:41:26
-->
<template>
  <div class="ly-table">
    <!-- toolBtn -->
    <div class="ly-btns" v-if="$slots.toolBtn">
      <slot name="toolBtn"></slot>
    </div>
    <!-- toolBtn end -->

    <!-- searchBar -->
    <div class="ly-search ly-shadow" v-if="$slots.searchBar">
      <el-form
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
        @submit.native.prevent
      >
        <slot name="searchBar"></slot>

        <el-form-item>
          <el-button round icon="iconfont icon-search" type="primary" v-lydebounce="['button', getList]">查询</el-button>
          <el-button round icon="iconfont icon-data" v-lydebounce="['button', resetList]">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- searchBar end -->
    <div class="ly-table-main ly-shadow">
      <!-- toolBar -->
      <div class="ly-tool" v-if="$slots.toolBar">
        <slot name="toolBar"></slot>
      </div>
      <!-- toolBar end -->

      <!-- table -->
      <div
        :class="['ly-table__main', tableCenter ? 'center' : '']"
        v-if="$slots.default"
        v-loading="loading"
      >
        <slot></slot>

        <!-- pagination -->
        <div class="table-pagination-area ly-table__pagination" v-if="pageTotal != null">
          <el-pagination
            background
            class="fixedPagination"
            layout="total, prev, pager, next, jumper"
            @current-change="pageChange"
            :page-size="pageSize"
            :current-page="page"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
        <!-- pagination end -->
      </div>
      <!-- table end -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    pageTotal: {
      // 数据总条数
      type: [Number, null],
      default: null
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      // 每页显示条目个数
      type: Number,
      default: 10
    },
    tableCenter: {
      type: Boolean,
      default: false
    },
    searchForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    pageChange ($event, type) {
      if (this.$listeners.pageChange) {
        this.$emit('pageChange', $event, type)
      } else {
        console.log('未定义【pageChange】方法')
      }
    },
    sizeChange ($event, type) {
      if (this.$listeners.sizeChange) {
        this.$emit('sizeChange', $event, type)
      } else {
        console.log('未定义【pageChange】方法')
      }
    },
    getList () {
      if (this.$listeners.getList) {
        this.$emit('getList')
      } else {
        console.log('未定义【getList】方法')
      }
    },
    resetList () {
      if (this.$listeners.resetList) {
        this.$emit('resetList')
      } else {
        console.log('未定义【resetList】方法')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/table.scss";
</style>
