var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "group-detail page-warp" }, [
    _c(
      "div",
      { staticClass: "admin" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("托管群的账号")]),
        _c("account-of-management")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "member" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v(" 群成员 "),
          _c(
            "div",
            {
              staticClass: "total-customer q-info",
              staticStyle: {
                display: "inline",
                "font-size": "14px",
                color: "#212b36"
              }
            },
            [
              _vm._v(
                " 共 " +
                  _vm._s(_vm.total) +
                  " 条记录，在群内 " +
                  _vm._s(_vm.in_group_total) +
                  " 人，已退群 " +
                  _vm._s(_vm.exist_group_total) +
                  " 人 "
              )
            ]
          )
        ]),
        _c("ly-table", {
          attrs: {
            data: _vm.tableData,
            option: _vm.tableOpt,
            page: _vm.page,
            pageSize: _vm.per_page,
            total: _vm.total
          },
          on: {
            searchChange: _vm.searchChange,
            sizeChange: _vm.handleSizeChange,
            currentChange: _vm.handleCurrentChange,
            selectionChange: _vm.handleSelectionChange
          },
          scopedSlots: _vm._u([
            {
              key: "name_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "flex flex-start" }, [
                    row.profile_photo
                      ? _c("img", {
                          staticClass: "ly-table__main-avatar",
                          attrs: { src: row.profile_photo, alt: "" }
                        })
                      : _c("img", {
                          staticClass: "ly-table__main-avatar",
                          attrs: { src: "defaultAvatar", alt: "" }
                        }),
                    _c("div", [_vm._v(" " + _vm._s(row.name) + " ")])
                  ])
                ]
              }
            },
            {
              key: "status_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.status === 0
                    ? _c("ly-status", { attrs: { type: "success" } }, [
                        _vm._v("在群内")
                      ])
                    : _c("ly-status", { attrs: { type: "danger" } }, [
                        _vm._v("已退群")
                      ])
                ]
              }
            },
            {
              key: "statistics",
              fn: function(ref) {
                var label = ref.label
                return [
                  _vm._v(" " + _vm._s(label) + " "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "关闭后，统计群聊数据时，该成员的数据将不进行统计。",
                        placement: "top-end"
                      }
                    },
                    [_c("i", { staticClass: "icon-warning-fill iconfont" })]
                  )
                ]
              }
            },
            {
              key: "state_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("el-switch", {
                    attrs: { "active-value": 0, "inactive-value": 1 },
                    on: {
                      change: function($event) {
                        return _vm.fn_handleSwitch($event, row)
                      }
                    },
                    model: {
                      value: row.state,
                      callback: function($$v) {
                        _vm.$set(row, "state", $$v)
                      },
                      expression: "row.state"
                    }
                  })
                ]
              }
            },
            {
              key: "joined_at_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "ly-table__main-time" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.joined_at === 0
                            ? "-"
                            : _vm.util.timeTofmt(
                                row.joined_at,
                                "yyyy-MM-dd hh:mm:ss"
                              )
                        ) +
                        " "
                    )
                  ])
                ]
              }
            },
            {
              key: "exited_at_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "ly-table__main-time" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.exited_at === 0
                            ? "-"
                            : _vm.util.timeTofmt(
                                row.exited_at,
                                "yyyy-MM-dd hh:mm:ss"
                              )
                        ) +
                        " "
                    )
                  ])
                ]
              }
            },
            {
              key: "activity_at_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "ly-table__main-time" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.activity_at === 0
                            ? "-"
                            : _vm.util.timeTofmt(
                                row.activity_at,
                                "yyyy-MM-dd hh:mm:ss"
                              )
                        ) +
                        " "
                    )
                  ])
                ]
              }
            }
          ])
        }),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.doClose ? "关闭统计成员数据" : "开启统计成员数据",
              visible: _vm.visible,
              "custom-class": "account-dialog",
              width: "480px"
            },
            on: {
              "update:visible": function($event) {
                _vm.visible = $event
              },
              close: _vm.fn_handleClose
            }
          },
          [
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.radio,
                  callback: function($$v) {
                    _vm.radio = $$v
                  },
                  expression: "radio"
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.doClose
                      ? "关闭后，不统计该成员所有群的数据"
                      : "开启后，统计该成员所有群的数据"
                  )
                )
              ]
            ),
            _c(
              "el-radio",
              {
                attrs: { label: "2" },
                model: {
                  value: _vm.radio,
                  callback: function($$v) {
                    _vm.radio = $$v
                  },
                  expression: "radio"
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.doClose
                      ? "关闭后，仅不统计该成员在本群的数据"
                      : "开启后，仅统计该成员在本群的数据"
                  )
                )
              ]
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { round: "", size: "medium", type: "primary" },
                    on: { click: _vm.fn_changeStatus }
                  },
                  [_vm._v(_vm._s(_vm.doClose ? "确定关闭" : "确认开启"))]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }