<template>
  <div class="popularize page-warp">
    <div class="select-box">
      <label style="color: rgba(0, 0, 0, 0.85)">选择活动：</label>
      <el-select
        size="medium"
        v-model="activity_id"
        @change="handleActChange"
        filterable
        placeholder="请选择活动"
      >
        <el-option
          v-for="item in activities"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @events="handleEvents"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          @click="
            channel_name_disable = false;
            visible = true;
          "
          >添加渠道</el-button
        >
      </template>

      <template #link_slot="{ row }">
        <div class="url-box">
          <span class="activity_url">{{ row.link_url }}</span>
          <el-button
            round
            style="margin-left: 16px"
            type="text"
            @click="handleCopy(row)"
            >复制</el-button
          >
        </div>
      </template>

      <template #qrcode_slot="{ row }">
        <div class="p-qrcode-box">
          <el-popover placement="bottom" width="200" trigger="hover">
            <img :src="row.poster_url" alt="" class="poster" />
            <div class="btn-area">
              <el-button
                size="small"
                type="text"
                @click="handleDownload(row, 'poster')"
                >下载海报</el-button
              >
              <el-button
                size="small"
                type="text"
                @click="handleDownload(row, 'qr')"
                >下载二维码</el-button
              >
            </div>
            <i
              slot="reference"
              class="iconfont icon-Code"
              style="font-size: 18px; margin-right: 10px"
            ></i>
          </el-popover>
          <el-button type="text" @click="handleDownload(row, 'qr')"
            >下载</el-button
          >
        </div>
      </template>

      <template #tags_slot="{ row }">
        <ly-drow :list="fn_getTagName(row.tags)" dep></ly-drow>
      </template>

      <template #operation="{ row }">
        <el-button type="text" @click="handleEdit(row)">编辑</el-button>
        <el-button
          type="text"
          :disabled="row.status === 2 || row.status === 3"
          @click="handleDel(row)"
          >删除</el-button
        >
      </template>
    </ly-table>
    <!-- 添加渠道弹窗 -->
    <el-dialog
      :title="edit ? '编辑渠道' : '添加渠道'"
      :visible.sync="visible"
      width="500px"
    >
      <el-form
        ref="form"
        size="medium"
        :model="form"
        :rules="rules"
        :show-message="false"
        label-width="90px"
        @submit.native.prevent
        v-loading="popLoading"
        :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item label="渠道名称：" prop="channel_name">
          <el-input
            v-model="form.channel_name"
            maxlength="20"
            show-word-limit
            :disabled="channel_name_disable"
            placeholder="请输入渠道名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道标签：">
          <tag-select-v2
            title="为本渠道新增用户自动打标签"
            style="padding-top: 7px"
            @getTagId="fn_getAddIds"
            :checked="form.tags"
          ></tag-select-v2>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span style="color: #6e788a; margin-right: 12px"
          >保存后自动生成二维码和链接</span
        >
        <el-button
          round
          type="primary"
          size="medium"
          @click="handleSave"
          :loading="popLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { popularize } from './http.js'
import lyTable from '@/components/mixins/ly-table.js'
import lyDrow from '@/components/lyDrow'
import tagSelectV2 from '@/components/wecomTags'
export default {
  components: {
    lyDrow, tagSelectV2
  },
  mixins: [lyTable],
  data () {
    return {
      IMMEDIATE: false,
      COMM_HTTP: popularize,
      visible: false,
      activities: [],
      activity_id: '',
      edit: false,
      editId: '',
      groupQrCode: '',
      poster: '',
      channel_name_disable: false,
      form: {
        channel_name: '',
        tags: ''
      },
      rules: {
        channel_name: [{ required: true, max: 20 }]
      },
      popLoading: false,
      loadingText: ''
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 200,
        column: [
          {
            label: '渠道名称',
            prop: 'channel_name',
            key: 'title',
            search: true,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '活动链接',
            prop: 'link_url',
            slot: 'link_slot',
            columnProps: {
              'min-width': 400
            }
          },
          {
            label: '渠道二维码',
            prop: 'qrcode_url',
            slot: 'qrcode_slot',
            columnProps: {
              'min-width': 180
            }
          },
          {
            label: '渠道标签',
            prop: 'tags',
            slot: 'tags_slot',
            columnProps: {
              'min-width': 180
            }
          }
        ]
      }
    },
    poster_url () {
      return this.listData.poster_url
    }
  },
  mounted () {
    this.activity_id = this.$route.query.id * 1
    this.COMM_HTTP.selectList().then(res => {
      this.activities = res || []
    })
    this.getWecomTags()
  },
  methods: {
    handleActChange () {
      this.$router.replace({ path: this.$route.path, query: { id: this.activity_id } })
      this.$nextTick(() => {
        this.queryData()
      })
    },
    // 获取标签
    fn_getAddIds (ids) {
      this.form.tags = ids ? ids.join(',') : ''
    },
    // 格式化参数
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activity_id
      }
    },
    // 获取标签列表
    async getWecomTags () {
      const data = await this.axios.get('wecomTagsV2')

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        return false
      }

      const tags = []
      if (data && data.length > 0) {
        for (const i in data) {
          tags.push(data[i].tagList)
        }

        this.$store.commit('_SET', {
          'tag.list': tags.flat()
        })
      }
      this.queryData()
    },
    // 获取渠道标签名
    fn_getTagName (tagId) {
      const tagArr = tagId.split(',').map(item => +item)
      const tagList = this.$store.state.tag.list
      const _tagNames = []
      for (let i = 0; i < tagList.length; i++) {
        if (tagArr.indexOf(tagList[i].tagId) > -1) {
          _tagNames.push(tagList[i].tagName)
        }
      }
      return _tagNames
    },
    // 保存渠道
    handleSave () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.popLoading = true
          this.loadingText = '活动链接及海报生成中，请稍等'
          let res
          if (this.edit) {
            res = await this.COMM_HTTP.channelUpdate({
              id: this.editId,
              ...this.form
            })
          } else {
            res = await this.COMM_HTTP.channelCreate({
              activity_id: this.activity_id,
              ...this.form
            })
          }
          this.popLoading = false
          if ((res && res.code) || (res && res.code === 0)) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success(this.edit ? '修改渠道成功！' : '添加渠道成功！')
          this.visible = false
          this.getWecomTags()
          setTimeout(() => {
            this.edit = false
            this.form.channel_name = ''
            this.form.tags = ''
            this.$nextTick(() => {
              this.$refs.form.clearValidate()
            })
          }, 200)
        }
      })
    },
    // 复制操作
    handleCopy ({ link_url }) {
      const input = document.createElement('input')
      input.value = link_url
      document.getElementsByTagName('body')[0].appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.getElementsByTagName('body')[0].removeChild(input)
      this.$message.success('复制成功')
    },
    /**
     * @description: 下载二维码/海报
     * @param {*} row
     * @return {*}
     */
    async handleDownload ({ channel_name, qrcode_url, poster_url }, type) {
      const x = new XMLHttpRequest()
      x.open('GET', type === 'poster' ? poster_url : qrcode_url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = type === 'poster' ? channel_name + '-poster' : channel_name + '-qr'
        a.click()
      }
      x.send()
    },
    /**
     * @description: 修改渠道
     * @param {*} row
     * @return {*}
     */
    handleEdit (row) {
      this.edit = true
      if (row.status === 3) {
        this.channel_name_disable = true
      } else this.channel_name_disable = false
      this.form.channel_name = row.channel_name
      this.form.tags = row.tags
      this.editId = row.id
      this.visible = true
    },
    /**
     * @description: 删除渠道
     * @param {*} id
     * @return {*}
     */
    async handleDel ({ id }) {
      this.$lyConfirm({
        text: '此操作无法撤销, 是否确定删除?',
        title: '确认要删除吗？'
      }).then(async () => {
        const res = await this.COMM_HTTP.channelDelete({ id })
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.queryData()
      }).catch(() => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.popularize {
  display: flex;
  flex-direction: column;
}
.select-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  .el-select {
    width: 353px;
  }
}
.p-qrcode-box {
  display: flex;
  & > span {
    height: 24px;
  }
  .p-qrcode {
    margin-right: 20px;
  }
}
.url-box {
  display: flex;
  .activity_url {
    max-width: calc(100% - 40px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.btn-area {
  text-align: center;
  margin-top: 10px;
}
.poster {
  width: 200px;
  height: 356px;
  display: block;
  object-fit: contain;
}
::v-deep {
  .act-selectbox .el-input__inner {
    width: 353px;
  }
  .el-form-item__label {
    padding-right: 2px;
  }
  .el-dialog__body {
    padding: 24px 24px 30px 24px;
  }
}
</style>
