<!--
 * @Author: zhanln
 * @Date: 2021-10-11 14:50:18
 * @LastEditTime: 2021-12-10 11:59:50
 * @LastEditors: zhanln
 * @Description: card
-->

<template>
  <div class="alys-card">
    <div class="alys-card__header">
      <div class="alys-card__title">
        {{ title }}
      </div>
    </div>
    <div class="alys-card__toolbar" v-if="$slots.toolbar">
      <slot name="toolbar"></slot>
    </div>

    <div class="alys-card__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alysCard',

  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.alys {
  &-card {
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    margin: 0 20px 20px;
    box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24),
      0px 0px 2px 0px rgba(145, 158, 171, 0.24);

    &__header {
      margin-bottom: 16px;
    }

    &__title {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      padding-left: 11px;

      &::before {
        position: absolute;
        content: "";
        width: 5px;
        height: 12px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $--color-primary;
        border-radius: 2.5px;
      }
    }

    &__toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__type {
      margin-right: 36px;

      ::v-deep .el-button {
        padding: 0;
      }
    }

    &__btn {
      display: inline-block;
      line-height: 30px;
      color: #93a3b0;
      border: 1px solid transparent;

      &.active {
        color: $--color-primary;
      }

      &:not(.active):hover {
        cursor: pointer;
      }

      &:last-child {
        margin-left: 30px;
      }
    }

    &__diy {
      margin-left: 20px;
    }
  }
}
</style>
