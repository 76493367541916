<!--
 * @Author: zhanln
 * @Date: 2021-06-30 11:55:04
 * @LastEditTime: 2022-08-29 17:38:35
 * @LastEditors: zhan
 * @Description: 渠道编辑
-->

<template>
  <el-dialog :visible.sync="visible" width="500px" :title="title" class="q-dialog" :before-close="fn_handleClose">
    <el-form size="medium" label-width="95px" :rules="popFormRules" ref="popFormRef" :model="form"
      @submit.native.prevent v-loading="popLoading" :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading">
      <el-form-item label="渠道名称：" prop="title">
        <el-input v-model.trim="form.title" :disabled="status === 3" placeholder="请输入渠道名称" maxlength="20"
          show-word-limit>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <span style="color: #6E788A; margin-right: 12px">保存后自动生成渠道海报和二维码</span>
      <el-button type="primary" round @click="fn_subForm('popFormRef')" :loading="popLoading">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as lyCheck from '@/common/checkSpecificKey'
import { popularize } from '@/pages/live/task/http.js'
export default {
  name: 'taskEditChannel',
  props: {
    actId: {
      type: Number,
      default: 0
    },
    popForm: {
      type: Object,
      default: () => { }
    },
    type: {
      type: String,
      default: 'add'
    }
  },

  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          if (this.popForm) {
            this.status = this.popForm.status
            this.form = {
              id: this.popForm.id,
              title: this.popForm.title
            }
          } else {
            this.status = 0
            this.form = {
              book_task_id: this.actId,
              title: ''
            }
          }
          this.$refs.popFormRef.clearValidate()
        })
      }
    }
  },

  data () {
    const checkName = (rule, value, callback) => {
      if (value !== null && value !== '') {
        if (!lyCheck.checkSpecificKey(value)) {
          callback(new Error('名称中请勿包含特殊字符'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入渠道名称'))
      }
    }
    return {
      channelId: null,
      visible: false,
      title: '添加渠道',
      form: {
        title: ''
      },
      status: 0,
      popFormRules: {
        title: [
          { validator: checkName, required: true, trigger: 'change' }
        ]
      },
      popLoading: false,
      loadingText: '',
      btnLoading: false
    }
  },

  methods: {
    // 提交
    fn_subForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.popLoading = true
          this.loadingText = '渠道海报生成中，请稍等'
          if (this.type === 'add') {
            this.fn_addChannel()
          } else {
            this.fn_updateChannel()
          }
        } else {
          return false
        }
      })
    },

    // 添加渠道
    async fn_addChannel () {
      const data = await popularize.create(this.form)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.popLoading = false
        return false
      }

      this.fn_success()
    },

    // 修改渠道
    async fn_updateChannel () {
      const data = await popularize.update(this.form)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.popLoading = false
        return false
      }

      this.fn_success()
    },

    // 请求成功处理
    fn_success () {
      this.popLoading = false
      this.loadingText = ''
      this.form = {
        activity_id: '',
        title: ''
      }
      this.$refs.popFormRef.resetFields()
      this.visible = false
      this.$emit('success')
    },

    fn_handleClose () {
      this.form = {
        activity_id: '',
        title: ''
      }
      this.$refs.popFormRef.resetFields()
      this.visible = false
    }
  }
}
</script>
