var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "inner is-dark detail" }, [
    _c("div", { staticClass: "flex" }, [
      _c(
        "div",
        {
          staticClass: "detail-base ly-card is-margin",
          staticStyle: { "min-height": "224px" }
        },
        [
          _c(
            "div",
            {
              staticClass: "detail-base__header flex",
              staticStyle: { "margin-bottom": "30px" }
            },
            [
              _c(
                "div",
                { staticClass: "detail-base__avatar" },
                [
                  _vm.info.avatar_url
                    ? _c("img", {
                        attrs: { src: _vm.info.avatar_url, alt: "" }
                      })
                    : _c("img", {
                        attrs: {
                          src: require("../../../assets/images/default_avatar.png"),
                          alt: ""
                        }
                      }),
                  _vm.info.gender === 1
                    ? [
                        _c("div", { staticClass: "detail-base__gender" }, [
                          _c("i", { staticClass: "ri-men-line" })
                        ])
                      ]
                    : _vm._e(),
                  _vm.info.gender === 2
                    ? [
                        _c("div", { staticClass: "detail-base__gender pink" }, [
                          _c("i", { staticClass: "ri-women-line" })
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c("div", { staticClass: "flex flex-column flex-start" }, [
                _c("div", [
                  _c("span", { staticClass: "detail-base__name" }, [
                    _vm._v(_vm._s(_vm.info.name))
                  ]),
                  _vm.info.corp_name
                    ? _c("span", { staticClass: "q-warning" }, [
                        _vm._v("@" + _vm._s(_vm.info.corp_name))
                      ])
                    : _c("span", { staticClass: "q-success" }, [
                        _vm._v("@微信")
                      ])
                ]),
                _vm.info.earliest_e.id > 0
                  ? _c(
                      "div",
                      [
                        _vm.info.earliest_e.etype === 0
                          ? [
                              _c("span", { staticClass: "q-info" }, [
                                _vm._v("最早于")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.util.timeTofmt(
                                      _vm.info.earliest_e.etime,
                                      "yyyy-MM-dd hh:mm"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "q-info" }, [
                                _vm._v("通过")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.joinType(_vm.info.earliest_e.way)
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "q-info" }, [
                                _vm._v("被")
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.info.earliest_e.name) + " "
                              ),
                              _c("span", { staticClass: "q-info" }, [
                                _vm._v("添加")
                              ])
                            ]
                          : [
                              _c("span", { staticClass: "q-info" }, [
                                _vm._v("最早于")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.util.timeTofmt(
                                      _vm.info.earliest_e.etime,
                                      "yyyy-MM-dd hh:mm"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "q-info" }, [
                                _vm._v("通过")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.joinType(_vm.info.earliest_e.way)
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "q-info" }, [
                                _vm._v("添加")
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.info.earliest_e.name) + " "
                              )
                            ]
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ]
          ),
          _c("div", { staticClass: "ly-list" }, [
            _c("div", { staticClass: "ly-list-item" }, [
              _c("div", { staticClass: "ly-list__label" }, [
                _vm._v("企业标签：")
              ]),
              _c(
                "div",
                { staticClass: "ly-list__content" },
                [
                  _c("ly-drow", {
                    attrs: {
                      list: _vm.info.wecom_tags,
                      dep: "",
                      textSize: 99,
                      size: 999
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "detail-right ly-card is-margin",
          staticStyle: { "min-height": "256px" }
        },
        [
          _c("div", { staticClass: "detail-base__header" }, [
            _c("div", { staticClass: "ly-table__main-title" }, [
              _vm._v("客户动态")
            ])
          ]),
          _vm.activities.length === 0
            ? [
                _c(
                  "div",
                  {
                    staticClass: "flex flex-column",
                    staticStyle: { "padding-top": "30px" }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "83px", "margin-bottom": "16px" },
                      attrs: {
                        src: require("../../../assets/images/dynamic_empty.png"),
                        alt: ""
                      }
                    }),
                    _c("div", { staticClass: "q-info" }, [_vm._v("暂无动态")])
                  ]
                )
              ]
            : [
                _c(
                  "el-timeline",
                  { staticClass: "detail-base__timeline" },
                  _vm._l(_vm.activities, function(item, index) {
                    return _c(
                      "el-timeline-item",
                      {
                        key: index,
                        attrs: { type: item.type, timestamp: item.timestamp }
                      },
                      [
                        item.id > 0
                          ? [
                              item.etype === 0
                                ? [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("通过")
                                    ]),
                                    _vm._v(" " + _vm._s(item.way) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("添加")
                                    ]),
                                    _vm._v(" " + _vm._s(_vm.info.name) + " ")
                                  ]
                                : _vm._e(),
                              item.etype === 1
                                ? [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("主动删除")
                                    ]),
                                    _vm._v(" " + _vm._s(_vm.info.name) + " ")
                                  ]
                                : _vm._e(),
                              item.etype === 2
                                ? [
                                    _vm._v(" " + _vm._s(_vm.info.name) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("通过")
                                    ]),
                                    _vm._v(" " + _vm._s(item.way) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("添加")
                                    ]),
                                    _vm._v(" " + _vm._s(item.name) + " ")
                                  ]
                                : _vm._e(),
                              item.etype === 3
                                ? [
                                    _vm._v(" " + _vm._s(_vm.info.name) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("主动删除")
                                    ]),
                                    _vm._v(" " + _vm._s(item.name) + " ")
                                  ]
                                : _vm._e(),
                              item.etype === 4
                                ? [
                                    _vm._v(" " + _vm._s(_vm.info.name) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("通过")
                                    ]),
                                    _vm._v(" " + _vm._s(item.way) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("进入群聊")
                                    ]),
                                    _vm._v(" " + _vm._s(item.name) + " ")
                                  ]
                                : _vm._e(),
                              item.etype === 5
                                ? [
                                    _vm._v(" " + _vm._s(_vm.info.name) + " "),
                                    _c("span", { staticClass: "q-info" }, [
                                      _vm._v("退出群聊")
                                    ]),
                                    _vm._v(" " + _vm._s(item.name) + " ")
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  1
                )
              ]
        ],
        2
      )
    ]),
    _c(
      "div",
      { staticClass: "ly-card is-margin", staticStyle: { padding: "0 20px" } },
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "所属员工", name: "first" } },
              [
                _c(
                  "layout-table",
                  {
                    attrs: {
                      pageTotal: _vm.belongDataTotal,
                      loading: _vm.belongLoading,
                      page: _vm.belongPage
                    },
                    on: { pageChange: _vm.belongPageChange }
                  },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.belongData }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            width: "200",
                            label: "所属员工"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-start" },
                                    [
                                      scope.row.avatar_url
                                        ? _c("img", {
                                            staticClass:
                                              "ly-table__main-avatar",
                                            attrs: {
                                              src: scope.row.avatar_url,
                                              alt: ""
                                            }
                                          })
                                        : _c("img", {
                                            staticClass:
                                              "ly-table__main-avatar",
                                            attrs: {
                                              src: require("../../../assets/images/default_avatar.png"),
                                              alt: ""
                                            }
                                          }),
                                      _c("div", [
                                        _vm._v(_vm._s(scope.row.name || "-"))
                                      ])
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "add_way", label: "客户来源" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.joinType(scope.row.add_way)) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "added_at",
                            width: "140",
                            label: "添加时间"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "ly-table__main-time" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.util.timeTofmt(
                                              scope.row.added_at,
                                              "yyyy-MM-dd hh:mm"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "remark", label: "备注名" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ellipsis3",
                                      attrs: { title: scope.row.remark }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.remark
                                              ? scope.row.remark
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "tags",
                            "min-width": "120",
                            label: "自定义标签"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.tags && scope.row.tags.length > 0
                                    ? _c("ly-drow", {
                                        attrs: { list: scope.row.tags, dep: "" }
                                      })
                                    : [_vm._v(" - ")]
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "wecom_tags",
                            label: "企业标签",
                            width: "200"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.wecom_tags &&
                                  scope.row.wecom_tags.length > 0
                                    ? _c("ly-drow", {
                                        attrs: {
                                          list: scope.row.wecom_tags,
                                          dep: ""
                                        }
                                      })
                                    : [_vm._v(" - ")]
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "description", label: "描述信息" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ellipsis3",
                                      attrs: { title: scope.row.description }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.description
                                              ? scope.row.description
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "status", label: "添加状态" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.status === 0
                                    ? _c(
                                        "ly-status",
                                        { attrs: { type: "success" } },
                                        [_vm._v(" 已添加 ")]
                                      )
                                    : scope.row.status === 1
                                    ? _c(
                                        "ly-status",
                                        { attrs: { type: "danger" } },
                                        [_vm._v(" 已删除 ")]
                                      )
                                    : scope.row.status === 2
                                    ? _c(
                                        "ly-status",
                                        { attrs: { type: "warning" } },
                                        [_vm._v(" 重新添加 ")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            "class-name": "operation-cell",
                            width: "80"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: !scope.row.view
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.jumpToEmp(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("详情")]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "ly-table-empty",
                            attrs: { slot: "empty" },
                            slot: "empty"
                          },
                          [
                            _vm._t("empty", [
                              _c("img", {
                                attrs: {
                                  src: require("@assets/svg/default/no_data.svg"),
                                  alt: ""
                                }
                              }),
                              _vm._v(" 暂无数据 ")
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: "所属群聊",
                  name: "second",
                  fixed: "",
                  width: "200"
                }
              },
              [
                _c(
                  "layout-table",
                  {
                    attrs: {
                      pageTotal: _vm.groupDataTotal,
                      loading: _vm.groupLoading,
                      page: _vm.groupPage
                    },
                    on: { pageChange: _vm.groupPageChange }
                  },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.groupData }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "name", label: "所属群聊" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-start" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ly-table__main-tearm" },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-weixin"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-right": "8px" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.name
                                                ? scope.row.name
                                                : "群聊"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "owner_name",
                            label: "群主",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.owner_name
                                    ? [
                                        _c("ly-tag", {
                                          attrs: {
                                            content: scope.row.owner_name
                                          }
                                        })
                                      ]
                                    : [_vm._v(" - ")]
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "user_count",
                            label: "群人数",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "status",
                            label: "进群状态",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.status === 0
                                          ? "已退群"
                                          : "已进群"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "join_scene",
                            label: "进群方式",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " 通过" +
                                      _vm._s(scope.row.join_scene) +
                                      "入群 "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "join_at", label: "进群时间" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "ly-table__main-time" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.util.timeTofmt(
                                              scope.row.join_at,
                                              "yyyy-MM-dd hh:mm"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "create_at", label: "群聊创建时间" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "ly-table__main-time" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.util.timeTofmt(
                                              scope.row.create_at,
                                              "yyyy-MM-dd hh:mm"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            "class-name": "operation-cell",
                            width: "80"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !scope.row.view ||
                                          !!scope.row.owner_name
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.jumpToGroup(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("详情")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }