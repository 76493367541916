var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-mobile",
    {
      attrs: {
        showWel: false,
        hasFooter: false,
        backIcon: "icon-close",
        sub: "",
        title: _vm.show_title
      }
    },
    [
      _c("div", { staticClass: "reward" }, [
        _c(
          "div",
          { staticClass: "reward-time" },
          [
            _vm._v(" 活动倒计时 "),
            _vm.time.s < 0
              ? [_vm._v(" - ")]
              : [
                  _vm._v(
                    " " +
                      _vm._s(_vm.time.d > 0 ? _vm.time.d + "天" : "") +
                      " " +
                      _vm._s(_vm.time.h > 0 ? _vm.time.h + ":" : "") +
                      " " +
                      _vm._s(_vm.time.m > 0 ? _vm.time.m + ":" : "") +
                      " " +
                      _vm._s(_vm.time.s) +
                      " "
                  )
                ]
          ],
          2
        ),
        _c("div", { staticClass: "reward-top" }, [
          _c("div"),
          _c(
            "div",
            {
              staticClass: "reward-rule__btn",
              on: {
                click: function($event) {
                  _vm.$refs.rewardRuleRef.visible = true
                }
              }
            },
            [_vm._v(" 活动规则 ")]
          )
        ]),
        _c(
          "div",
          { staticClass: "reward-title" },
          [
            _vm.cover_title
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "reward-title__text",
                      attrs: {
                        "data-text":
                          _vm.cover_title.length > 7
                            ? _vm.cover_title.slice(0, 7)
                            : _vm.cover_title
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.cover_title.length > 7
                            ? _vm.cover_title.slice(0, 7)
                            : _vm.cover_title
                        )
                      )
                    ]
                  ),
                  _vm.cover_title.length > 7
                    ? [
                        _c(
                          "span",
                          {
                            staticClass: "reward-title__text",
                            attrs: {
                              "data-text": _vm.cover_title.slice(
                                7,
                                _vm.cover_title.length
                              )
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.cover_title.slice(7, _vm.cover_title.length)
                              )
                            )
                          ]
                        )
                      ]
                    : _vm._e()
                ]
              : [
                  _c(
                    "span",
                    {
                      staticClass: "reward-title__text",
                      attrs: { "data-text": "请输入封面标题" }
                    },
                    [_vm._v("请输入封面标题")]
                  )
                ]
          ],
          2
        ),
        _vm.lottery_type === 1
          ? _c("div", { staticClass: "reward-lottery" }, [
              _c(
                "div",
                { staticClass: "reward-lottery__grid" },
                _vm._l(_vm.rewards, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "reward-lottery__item",
                        index === 3 ? "active" : ""
                      ]
                    },
                    [
                      index !== 8
                        ? [
                            _c("div", { staticClass: "cover" }, [
                              _c("img", {
                                attrs: { src: item.reward_cover, alt: "" }
                              })
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(" " + _vm._s(item.reward_title) + " ")
                            ])
                          ]
                        : [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.reward_title))
                            ])
                          ]
                    ],
                    2
                  )
                }),
                0
              )
            ])
          : _c("div", { staticClass: "reward-lottery circle" }, [
              _c(
                "div",
                { staticClass: "reward-lottery__circle" },
                [
                  _vm._l(_vm.rewards.slice(0, 8), function(item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(" " + _vm._s(item.reward_title) + " ")
                        ]),
                        _c("div", { staticClass: "img" }, [
                          _c("img", { attrs: { src: item.reward_cover } })
                        ])
                      ])
                    ])
                  }),
                  _c("div", { staticClass: "center" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "https://image.01lb.com.cn//uploads/wecom/22/0106/1641455695glJQW76Q.png",
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", { staticClass: "center-text" }, [
                    _vm._v("抽奖机会:3次")
                  ])
                ],
                2
              )
            ]),
        _c("div", { staticClass: "reward-btn" }, [
          _vm._v(" 喊好友参与，获更多抽奖机会 "),
          _c("div", { staticClass: "reward-btn__pop" }, [
            _vm._v(
              " 每邀" +
                _vm._s(_vm.need_invite || 1) +
                "位好友可获" +
                _vm._s(_vm.invite_lottery_num || 1) +
                "次，最多可获" +
                _vm._s(_vm.total_num || 1) +
                "次 "
            )
          ])
        ]),
        _c("div", { staticClass: "reward-rank" }, [
          _c("div", { staticClass: "reward-rank__tab" }, [
            _c("div", { staticClass: "item active" }, [_vm._v("抽奖记录")]),
            _c("div", { staticClass: "item last" }, [_vm._v("邀请明细")])
          ]),
          _c(
            "div",
            { staticClass: "reward-rank__list" },
            _vm._l(_vm.records, function(item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "cover" }, [
                    _c("img", { attrs: { src: item.cover, alt: "" } })
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title))
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$day().format("YYYY-MM-DD hh:mm")) +
                          " "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "btn" }, [_vm._v("查看")])
              ])
            }),
            0
          )
        ])
      ]),
      _c("reward-detail"),
      _c("reward-rule", { ref: "rewardRuleRef", attrs: { tabKey: _vm.tabKey } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }