var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("act-layout", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.pageLoading,
        expression: "pageLoading"
      }
    ],
    ref: "cards",
    attrs: { cards: _vm.cards },
    on: { setKey: _vm.fn_setKey },
    scopedSlots: _vm._u(
      [
        {
          key: "basic",
          fn: function() {
            return [_c("view-basic", { ref: "basic" })]
          },
          proxy: true
        },
        {
          key: "poster",
          fn: function() {
            return [_c("view-poster", { ref: "poster" })]
          },
          proxy: true
        },
        {
          key: "setting",
          fn: function() {
            return [_c("view-setting", { ref: "setting" })]
          },
          proxy: true
        },
        {
          key: "reward",
          fn: function() {
            return [_c("view-reward", { ref: "reward" })]
          },
          proxy: true
        },
        {
          key: "notice",
          fn: function() {
            return [_c("view-notice", { ref: "notice" })]
          },
          proxy: true
        },
        {
          key: "brush",
          fn: function() {
            return [
              _c("view-brush", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: true,
                    expression: "true"
                  }
                ],
                ref: "brush"
              })
            ]
          },
          proxy: true
        },
        {
          key: "mobile",
          fn: function() {
            return [
              _vm.tabKey === "basic" ? _c("mobi-basic") : _vm._e(),
              _vm.tabKey === "poster" ? _c("mobi-poster") : _vm._e(),
              _vm.tabKey === "reward" ||
              _vm.tabKey === "setting" ||
              _vm.tabKey === "brush"
                ? _c("mobi-reward", { attrs: { tabKey: _vm.tabKey } })
                : _vm._e(),
              _vm.tabKey === "notice" ? _c("mobi-notice") : _vm._e()
            ]
          },
          proxy: true
        },
        !_vm.pageLoading
          ? {
              key: "footer",
              fn: function() {
                return [
                  _c("edit-footer", {
                    attrs: { btnLoading: _vm.btnLoading },
                    on: { submit: _vm.fn_submit }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }