var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile" }, [
    _c("div", { staticClass: "mobile-topbar" }, [
      _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
      _vm._m(0)
    ]),
    _vm._m(1),
    _c("div", { staticClass: "mobile-container" }, [
      _vm._m(2),
      _c("div", { staticClass: "mobile-contact" }, [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "mobile-contact__dialog" },
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                autosize: "",
                resize: "none",
                readonly: ""
              },
              model: {
                value: _vm.way_words,
                callback: function($$v) {
                  _vm.way_words = $$v
                },
                expression: "way_words"
              }
            })
          ],
          1
        )
      ]),
      _vm._m(4)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "iconfont icon-a-CellularConnection" }),
      _c("i", { staticClass: "iconfont icon-Wifi" }),
      _c("i", { staticClass: "iconfont icon-Battery" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-titlebar" }, [
      _c("i", { staticClass: "iconfont icon-left1" }),
      _c("div", { staticClass: "flex flex-column" }, [
        _c("span", [_vm._v("张三")]),
        _c("span", { staticStyle: { "font-size": "12px" } }, [
          _vm._v("零一裂变（深圳）科技有限公司")
        ])
      ]),
      _c("i", { staticClass: "el-icon-more" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-column q-info text-small",
        staticStyle: { "line-height": "2", "margin-top": "12px" }
      },
      [
        _c("span", [_vm._v("您已添加了张三，现在可以开始聊天了。")]),
        _c("span", [
          _vm._v("对方为企业微信用户，"),
          _c("span", { staticStyle: { color: "#000" } }, [_vm._v("了解更多。")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-contact__avatar" }, [
      _c("img", {
        attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-contact" }, [
      _c("div", { staticClass: "mobile-contact__avatar" }, [
        _c("img", {
          attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
        })
      ]),
      _c("div", { staticClass: "mobile-contact__dialog" }, [
        _c("div", { staticClass: "link-preview" }, [
          _c("div", { staticClass: "ellipsis2 link-preview__title" }, [
            _vm._v("点击领取你的奖励👇")
          ]),
          _c(
            "div",
            {
              staticClass: "flex flex-between flex-center link-preview__content"
            },
            [
              _c(
                "div",
                { staticClass: "ellipsis3", staticStyle: { flex: "1" } },
                [_vm._v("领取奖励，再接再厉！")]
              ),
              _c("div", { staticClass: "link-preview__cover" }, [
                _c("img", {
                  attrs: {
                    src:
                      "https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png",
                    alt: ""
                  }
                })
              ])
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }