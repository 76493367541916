var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
        size: "medium"
      }
    },
    [
      _vm.current_step !== 0
        ? _c("el-form-item", { attrs: { label: "任务状态：" } }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      content: "请先关闭第三阶任务",
                      "popper-class": "q-tooltip",
                      disabled: !_vm.status_disable
                    }
                  },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        disabled:
                          (_vm.isEdit && !_vm.isCopy) || _vm.status_disable
                      },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    })
                  ],
                  1
                ),
                _c("span", { staticStyle: { "margin-left": "8px" } }, [
                  _vm._v(_vm._s(_vm.form.status === 0 ? "关闭" : "开启"))
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.form.status === 1,
              expression: "form.status === 1"
            }
          ]
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "任务人数：", prop: "target_count" } },
            [
              _c("el-input-number", {
                staticClass: "number-big",
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 100,
                  precision: 0,
                  disabled: this.isEdit && !this.isCopy,
                  placeholder: "目标邀请人数"
                },
                model: {
                  value: _vm.form.target_count,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "target_count", $$v)
                  },
                  expression: "form.target_count"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "任务达成标签：" } }, [
            _c(
              "div",
              { staticClass: "flex", staticStyle: { height: "36px" } },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.tag_open,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "tag_open", $$v)
                    },
                    expression: "form.tag_open"
                  }
                }),
                _c(
                  "span",
                  {
                    staticClass: "q-info",
                    staticStyle: {
                      "line-height": "20px",
                      "margin-left": "10px"
                    }
                  },
                  [_vm._v("开启后，可自动为任务达成用户打标签")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.tag_open,
                    expression: "form.tag_open"
                  }
                ]
              },
              [
                _c("tag-select-v2", {
                  attrs: { checked: _vm.form.finish_task_tags },
                  on: { getTagId: _vm.fn_getFinishIds }
                })
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "奖品信息：", prop: "reward_title" } },
            [
              _c(
                "div",
                { staticClass: "flex reward" },
                [
                  _c("ly-upload", {
                    attrs: {
                      img: _vm.form.reward_cover,
                      tipText: "奖品封面",
                      canRemove: ""
                    },
                    on: { getImg: _vm.fn_getCover }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex flex-column reward-info" },
                    [
                      _c("el-input", {
                        staticClass: "reward-info__title act-form__input",
                        attrs: {
                          placeholder: "奖品名称，必填",
                          maxlength: "10",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.reward_title,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "reward_title", $$v)
                          },
                          expression: "form.reward_title"
                        }
                      }),
                      _c("el-input", {
                        staticClass: "reward-info__desc act-form__input",
                        attrs: {
                          type: "textarea",
                          resize: "none",
                          placeholder: "奖品简介，选填",
                          maxlength: "40",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.reward_desc,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "reward_desc", $$v)
                          },
                          expression: "form.reward_desc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "虚拟完成人数：", prop: "unreal" } },
            [
              _c("el-input-number", {
                staticClass: "number-big",
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 99999,
                  precision: 0,
                  placeholder: "虚拟完成人数"
                },
                model: {
                  value: _vm.form.unreal,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "unreal", $$v)
                  },
                  expression: "form.unreal"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("template", { slot: "label" }, [
                _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
                _vm._v(" 奖品内容： ")
              ]),
              _c("reward-detail", {
                ref: "rewardDetailRef",
                on: { changeTotal: _vm.fn_changeTotal }
              })
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖品库存：", prop: "total" } },
            [
              _c("el-input-number", {
                staticClass: "number-big",
                attrs: {
                  "controls-position": "right",
                  min: _vm.isEdit && !_vm.isCopy ? 0 : 1,
                  max: 99999,
                  precision: 0,
                  placeholder: "奖品库存"
                },
                model: {
                  value: _vm.form.total,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "total", $$v)
                  },
                  expression: "form.total"
                }
              }),
              _vm.isEdit && !_vm.isCopy
                ? _c("div", { staticClass: "q-info" }, [
                    _c("span", { staticStyle: { "margin-right": "24px" } }, [
                      _vm._v("已使用："),
                      _c("b", [_vm._v(_vm._s(_vm.form.use_count))])
                    ]),
                    _c("span", [
                      _vm._v("剩余库存："),
                      _c("b", [
                        _vm._v(_vm._s(_vm.form.total - _vm.form.use_count))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.showRiskNum >= _vm.showRiskMax
                ? _c(
                    "p",
                    {
                      staticClass: "q-info text-small",
                      staticStyle: {
                        "line-height": "1.6",
                        "margin-bottom": "0"
                      }
                    },
                    [
                      _vm._v(
                        " 温馨提示：账号被动添加好友建议值：新企微号每天200~400人左右，高权重老号每天800~1000人左右。当前奖品库存/涨粉账号≈" +
                          _vm._s(_vm.showRiskNum) +
                          "人，请谨慎设置库存 "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "真实库存展示：", prop: "open_stock" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-switch", {
                    staticStyle: { "margin-right": "16px" },
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.open_stock,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "open_stock", $$v)
                      },
                      expression: "form.open_stock"
                    }
                  }),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        placement: "top",
                        "popper-class": "q-tooltip"
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(" 1.关闭：活动页显示“库存告急”。"),
                          _c("br"),
                          _vm._v(
                            " 2.开启：展示任务设置的奖品剩余库存，如果奖品类型为兑换码，"
                          ),
                          _c("br"),
                          _vm._v(" 则展示兑换码的真实库存。 ")
                        ]
                      ),
                      _c("i", { staticClass: "ri-question-line" })
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }