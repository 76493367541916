var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "msgBubbles",
      class: { isMe: _vm.msgData.user.identity === "me" }
    },
    [
      _c("div", { staticClass: "useMsg" }, [
        _c("img", {
          attrs: {
            src:
              _vm.msgData.user.avatar ||
              "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            alt: ""
          }
        }),
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.msgData.user.name))
        ]),
        _vm.msgData.msg.sender_suffix
          ? _c("span", [_vm._v(_vm._s(_vm.msgData.msg.sender_suffix))])
          : _vm._e(),
        _c("div", { staticClass: "time" }, [
          _vm._v(_vm._s(_vm.msgData.msg.time))
        ])
      ]),
      _c("div", { staticClass: "bubbles" }, [
        _vm.msgData.msg.type === "text"
          ? _c("div", {
              directives: [
                {
                  name: "emoji",
                  rawName: "v-emoji",
                  value: _vm.msgData.msg.content,
                  expression: "msgData.msg.content"
                }
              ],
              staticClass: "msg"
            })
          : _vm.msgData.msg.type === "img"
          ? _c(
              "div",
              { staticClass: "msg img" },
              [
                _c(
                  "el-image",
                  {
                    staticStyle: {
                      "max-height": "260px",
                      "max-width": "160px"
                    },
                    attrs: {
                      fit: "scale-down",
                      src: _vm.msgData.msg.content,
                      "preview-src-list": [_vm.msgData.msg.content]
                    }
                  },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticClass: "img-slot",
                      attrs: {
                        slot: "placeholder",
                        "element-loading-background": "rgba(0, 0, 0, 0)"
                      },
                      slot: "placeholder"
                    })
                  ]
                )
              ],
              1
            )
          : _vm.msgData.msg.type === "file"
          ? _c("div", { staticClass: "msg file" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "fileName" }, [
                  _vm._v(" " + _vm._s(_vm.msgData.msg.title) + " ")
                ]),
                _c("div", { staticClass: "size" }, [
                  _vm._v(_vm._s(_vm.msgData.msg.size))
                ])
              ]),
              _c("img", { attrs: { src: _vm.extIcon } })
            ])
          : _vm.msgData.msg.type === "voice"
          ? _c(
              "div",
              {
                staticClass: "msg voice",
                class: { cehui: _vm.msgData.msg.status === 4 },
                on: {
                  click: function($event) {
                    return _vm.handleClickVoice(_vm.msgData)
                  }
                }
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isPlay,
                      expression: "!isPlay"
                    }
                  ],
                  attrs: {
                    src: require("@/assets/images/icon/icon_voice_l.svg"),
                    alt: ""
                  }
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPlay,
                      expression: "isPlay"
                    }
                  ],
                  attrs: {
                    src: require("@/assets/images/icon/play/play_l.gif"),
                    alt: ""
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.msgData.msg.url,
                        expression: "msgData.msg.url"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm.msgData.msg.voice_time) + "''")]
                ),
                _c("audio", { ref: "audio", attrs: { hidden: "true" } })
              ]
            )
          : _vm.msgData.msg.type === "applet"
          ? _c(
              "div",
              {
                staticClass: "msg applet",
                class: { cehui: _vm.msgData.msg.status === 4 }
              },
              [
                _c("div", { staticClass: "top" }, [
                  _vm.msgData.msg.logo
                    ? _c("img", {
                        attrs: {
                          src:
                            _vm.msgData.msg.logo ||
                            "@/assets/images/applet.png",
                          alt: ""
                        }
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/images/applet.png"),
                          alt: ""
                        }
                      }),
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.msgData.msg.name))
                  ])
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.msgData.msg.title))
                ]),
                _c("img", {
                  staticClass: "applet_img",
                  attrs: { src: _vm.msgData.msg.cover_url, alt: "" }
                }),
                _vm._m(0)
              ]
            )
          : _vm.msgData.msg.type === "video"
          ? _c("div", { staticClass: "msg video" }, [
              _c("img", {
                staticClass: "video_cover",
                attrs: { src: _vm.msgData.msg.cover_url, alt: "" }
              }),
              _c("img", {
                staticClass: "video_stop",
                attrs: {
                  src: require("@/assets/images/icon/icon_videoStop.svg")
                },
                on: {
                  click: function($event) {
                    return _vm.playVideo(_vm.msgData.msg)
                  }
                }
              })
            ])
          : _vm.msgData.msg.type === "videoAccount"
          ? _c(
              "div",
              {
                staticClass: "msg video-account",
                class: { cehui: _vm.msgData.msg.status === 4 }
              },
              [
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c("el-image", {
                      staticClass: "video_img",
                      attrs: { src: _vm.msgData.msg.cover_url, lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "error",
                          fn: function() {
                            return [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/vlog_default.png"),
                                  alt: ""
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "text_content" }, [
                  _c("div", { staticClass: "mid" }, [
                    _c("img", {
                      attrs: { src: _vm.msgData.msg.avatar, alt: "" }
                    }),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.msgData.msg.name))
                    ])
                  ]),
                  _vm._m(1)
                ])
              ]
            )
          : _vm.msgData.msg.type === "businessCard"
          ? _c("div", { staticClass: "msg businessCard" }, [
              _c("div", { staticClass: "content" }, [
                _c("img", { attrs: { src: _vm.msgData.msg.avatar, alt: "" } }),
                _c("p", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.msgData.msg.name))
                ])
              ]),
              _c("span", [_vm._v("个人名片")])
            ])
          : _vm.msgData.msg.type === "graphic"
          ? _c(
              "div",
              {
                staticClass: "msg graphic",
                class: { cehui: _vm.msgData.msg.status === 4 },
                on: {
                  click: function($event) {
                    return _vm.handleGraphic(_vm.msgData.msg.href)
                  }
                }
              },
              [
                _c("div", { staticClass: "left" }, [
                  _c("p", { staticClass: "des" }, [
                    _vm._v(_vm._s(_vm.msgData.msg.title))
                  ]),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.msgData.msg.desc))
                  ])
                ]),
                _c(
                  "el-image",
                  {
                    staticStyle: {
                      "line-height": "1",
                      "font-size": "0",
                      width: "64px",
                      height: "64px"
                    },
                    attrs: { src: _vm.msgData.msg.cover_url, lazy: "" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        slot: "error",
                        src:
                          "https://image.01lb.com.cn//uploads/wecom/22/0507/1651905734wMI905Dp.png",
                        alt: ""
                      },
                      slot: "error"
                    })
                  ]
                )
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "msg", staticStyle: { background: "#dfe1e8" } },
              [_vm._v(" 暂不支持的消息类型，可在手机上查看 ")]
            ),
        _c("div", { staticClass: "bubbles_icon" }, [
          _vm.msgData.msg.status !== 3 && _vm.msgData.msg.status !== 1
            ? _c("div", { staticClass: "success" }, [
                ["img", "file", "video"].includes(_vm.msgData.msg.type) &&
                _vm.msgData.msg.status !== 1
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleClickMsg(_vm.msgData)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icon/icon_download.svg"),
                            alt: ""
                          }
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icon/icon_download_a.svg"),
                            alt: ""
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm.textType
                  ? _c(
                      "span",
                      {
                        class: "copy_" + _vm.msgData.msg.id,
                        attrs: {
                          "data-clipboard-text": _vm.msgData.msg.content
                        },
                        on: {
                          click: function($event) {
                            return _vm.fn_clip(_vm.msgData.msg.id)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icon/icon_copy.svg"),
                            alt: ""
                          }
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icon/icon_copy_a.svg"),
                            alt: ""
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm.msgData.msg.status === 3
          ? _c("div", { staticClass: "err" }, [_vm._v("消息发送失败")])
          : _vm._e()
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoVisible,
            width: "800px",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.videoVisible = $event
            }
          }
        },
        [
          _c("video", {
            ref: "video",
            staticClass: "video-player",
            attrs: { controls: "", autoplay: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon/icon_link.svg"), alt: "" }
      }),
      _c("span", { staticClass: "text" }, [_vm._v("小程序")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon/icon_vlog.svg"),
          alt: "",
          width: "14"
        }
      }),
      _c("span", { staticClass: "text" }, [_vm._v("视频号")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }