<!--
 * @Author: zhanln
 * @Date: 2022-03-21 15:46:50
 * @LastEditTime: 2022-05-05 14:08:13
 * @LastEditors: zhanln
 * @Description: 视频
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="110px"
    size="medium"
    class="edit-form"
    v-loading="loading"
  >
    <!--  素材名称 -->
    <el-form-item label="素材名称：" prop="name">
      <el-input
        class="edit-form__input"
        v-model.trim="form.name"
        maxlength="20"
        show-word-limit
        placeholder="请输入素材名称"
      ></el-input>
    </el-form-item>

    <!--  分组 -->
    <el-form-item>
      <template slot="label"><span class="q-danger">*</span> 分组：</template>
      <el-select v-model="form.group_id" class="edit-form__input">
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <!--  视频封面图 -->
    <el-form-item label="视频封面图：">
      <div class="q-info text-small">支持JPG、PNG格式，图片小于2MB</div>
      <ly-upload
        :img="form.cover_image"
        canRemove
        size="2MB"
        tipIcon="el-icon-plus"
        @getImg="fn_getPoster"
      ></ly-upload>
    </el-form-item>

    <!--  视频 -->
    <el-form-item :error="urlError">
      <template slot="label"><span class="q-danger">*</span> 视频：</template>
      <div class="q-info text-small">支持MP4格式，文件小于10MB</div>
      <ly-upload
        type="video"
        size="10MB"
        accept=".mp4"
        tipText="上传视频"
        tipIcon="el-icon-plus"
        :img="form.file_url"
        :canRemove="true"
        getTime
        @getFile="fn_getVideo"
      ></ly-upload>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'edVideo',

  props: {
    hasChange: {
      type: Boolean,
      defualt: false
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentGroupId: {
      type: String,
      default: '0'
    }
  },

  data () {
    return {
      loading: true,
      form: {
        type: 3,
        name: '',
        group_id: '0',
        cover_image: '',
        file_url: '',
        content: {}
      },
      rules: {
        name: [
          { required: true, message: '请填写素材名称', trigger: 'change' }
        ]
      },
      urlError: '',
      isEdit: false,
      editReady: false
    }
  },

  watch: {
    loading (val) {
      if (!val) {
        this.$emit('ready')
      }
    },
    form: {
      handler (val) {
        if ((this.isEdit && this.editReady) || (!this.isEdit && !this.editReady)) {
          this.$emit('update:hasChange', true)
        }
        if (this.isEdit) {
          this.editReady = true
        }
      },
      deep: true
    }
  },

  methods: {
    // 初始化数据
    fn_init (form) {
      if (!form) {
        this.form.group_id = this.currentGroupId === '-1' ? '0' : this.currentGroupId
        this.loading = false
        return
      }

      const detail = this.util.extend(true, {}, form)
      detail.cover_image = detail.content.cover_image
      detail.file_url = detail.content.file_url
      this.form = detail

      this.loading = false
      this.isEdit = true
    },

    // 封面回调
    fn_getPoster (cover_image, info) {
      this.form.cover_image = cover_image
      this.form.content.cover_image = info ? cover_image : ''
      this.$refs.formRef.validateField('cover_image')
    },

    // 视频回调
    fn_getVideo (file_url, info) {
      this.form.file_url = file_url
      this.form.content.file_url = info ? file_url : ''
      this.form.content.file_origin_name = info ? info.name : ''
      this.form.content.file_size = info ? info.size : 0
      this.form.content.file_ext = info ? info.type : ''
      this.form.content.file_time = info ? info.time : 0
      this.urlError = info ? '' : '请上传文件'
    },

    // 表单校验，通过则返回 form
    fn_verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      this.urlError = this.form.file_url ? '' : '请上传文件'

      if (!formValid || !this.form.file_url) return false

      const form = this.util.extend(true, {}, this.form)
      form.content.cover_image = form.cover_image || form.file_url + '?vframe/jpg/offset/3'
      delete form.file_url
      delete form.cover_image

      return form
    }
  }
}
</script>
