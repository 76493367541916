<!--
 * @Author: zhan
 * @Date: 2022-08-09 14:51:49
 * @LastEditTime: 2022-08-29 11:24:01
 * @LastEditors: zhan
-->
<template>
  <ly-form ref="lyFormRef" class="act-form" :formObj="formObj" :form="formData" :rules="rules" @setData="fn_setData"
    v-loading="cardLoading">
    <template #share_cover_head>
      <div class="q-info text-small">建议尺寸:500*400像素，大小不超过2MB，格式为JPG/PNG/JPEG</div>
    </template>
  </ly-form>
</template>

<script>
export default {
  data () {
    return {
      cardLoading: true,
      formData: {
        share_title: '',
        share_cover: ''
      },
      rules: {
        share_title: [
          { required: true, message: '请填写分享标题', trigger: 'change' }
        ],
        share_cover: [
          { required: true, message: '请上传分享封面', trigger: 'change' }
        ]
      }
    }
  },

  computed: {
    formObj () {
      return {
        labelWidth: '160px',
        item: [
          {
            type: 'text',
            label: '分享标题',
            prop: 'share_title',
            attr: {
              maxlength: '10',
              showWordLimit: true
            }
          },
          {
            type: 'upload',
            label: '分享封面',
            prop: 'share_cover',
            headSlot: 'share_cover_head'
          }
        ]
      }
    }
  },

  watch: {
    'formData.share_title' (val) {
      this.fn_setStore('share_title', val)
    },
    'formData.share_cover' (val) {
      this.fn_setStore('share_cover', val)
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        this.formData = data
      } else {
        this.formData.share_title = '参与活动抽奖'
      }

      this.$nextTick(() => {
        this.cardLoading = false
      })
    },

    fn_setData (val, name) {
      this.formData[name] = val
      this.$refs.lyFormRef.verify(name)
      console.log('bsic', this.formData)
    },

    fn_setStore (name, val) {
      this.$store.commit('reserveTask/SET_DATA', {
        name,
        val
      })
    },

    async verify (draft) {
      let formValid = true
      if (!draft) {
        formValid = await this.$refs.lyFormRef.verify()
      }
      if (!formValid) return false

      return this.formData
    }
  }
}
</script>

<style lang="scss" scoped>
.act-form {
  ::v-deep {

    .el-input {
      max-width: 320px;
    }
  }
}
</style>
