var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "msg-temp" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _vm._v(" " + _vm._s(_vm.title) + " "),
        _c(
          "el-dropdown",
          {
            attrs: { placement: "bottom" },
            on: { command: _vm.handleCommand }
          },
          [
            _c("i", { staticClass: "el-icon-more" }),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "msg-handler",
                attrs: { slot: "dropdown" },
                slot: "dropdown"
              },
              [
                _c("el-dropdown-item", { attrs: { command: "edit" } }, [
                  _vm._v("编辑")
                ]),
                _c(
                  "el-dropdown-item",
                  { staticClass: "up", attrs: { command: "up" } },
                  [_vm._v("上移")]
                ),
                _c(
                  "el-dropdown-item",
                  { staticClass: "up", attrs: { command: "down" } },
                  [_vm._v("下移")]
                ),
                _c("el-dropdown-item", { attrs: { command: "remove" } }, [
                  _vm._v("移除")
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.msg.msg_type === 2001
      ? _c("div", { staticClass: "content flex" }, [
          _vm._v(" " + _vm._s(_vm.msg.msg_content) + " ")
        ])
      : _vm._e(),
    _vm.msg.msg_type === 2002
      ? _c(
          "div",
          { staticClass: "content flex" },
          [
            _c("el-image", {
              staticStyle: { height: "90px" },
              attrs: { src: _vm.msg.msg_content, fit: "contain" }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.msg.msg_type === 2004
      ? _c("div", { staticClass: "content video" }, [
          _c("video", { attrs: { src: _vm.msg.href } }),
          _c("span", { staticClass: "video-title" }, [
            _vm._v(_vm._s(_vm.msg.title))
          ])
        ])
      : _vm._e(),
    _vm.msg.msg_type === 2010
      ? _c("div", { staticClass: "content flex" }, [
          _c("div", { staticClass: "file-temp" }, [
            _c("span", { staticClass: "file-title" }, [
              _vm._v(_vm._s(_vm.msg.title))
            ]),
            _c("img", {
              staticClass: "file-icon",
              attrs: { src: require("@/assets/images/doc.png") }
            })
          ])
        ])
      : _vm._e(),
    _vm.msg.msg_type === 2005
      ? _c("div", { staticClass: "link" }, [
          _c("div", { staticClass: "link-title" }, [
            _vm._v(_vm._s(_vm.msg.title))
          ]),
          _c(
            "div",
            { staticClass: "link-bottom" },
            [
              _c("div", { staticClass: "link-desc" }, [
                _vm._v(_vm._s(_vm.msg.desc))
              ]),
              _c("el-image", {
                staticStyle: { width: "48px", height: "48px" },
                attrs: {
                  src:
                    _vm.msg.msg_content ||
                    "https://image.01lb.com.cn//uploads/wecom/21/0830/1630312272q3GfX7a3.png",
                  fit: "contain"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.msg.msg_type === 2013
      ? _c("div", { staticClass: "applet" }, [
          _c("div", { staticClass: "app-title" }, [
            _vm._v(_vm._s(_vm.msg.title))
          ]),
          _c("img", {
            staticClass: "applet-img",
            attrs: {
              src: _vm.msg.msg_content || require("@/assets/images/applet.png")
            }
          }),
          _vm._m(0)
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-sys" }, [
      _c("span", [_vm._v("S")]),
      _vm._v("小程序")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }