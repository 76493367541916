<!--
 * @Author: zhanln
 * @Date: 2022-03-21 15:46:50
 * @LastEditTime: 2022-04-19 11:53:34
 * @LastEditors: zhanln
 * @Description: 文章
-->

<template>
  <div :class="['article', form.source === 2 ? 'article-high' : '']">
    <el-scrollbar class="article-scroll">
      <el-form
        :model="form"
        :rules="rules"
        ref="formRef"
        label-width="120px"
        size="medium"
        class="edit-form"
        v-loading="loading"
      >
        <!--  素材名称 -->
        <el-form-item label="素材名称：" prop="name">
          <el-input
            class="edit-form__input"
            v-model.trim="form.name"
            maxlength="20"
            show-word-limit
            placeholder="请输入素材名称"
          ></el-input>
        </el-form-item>

        <!--  分组 -->
        <el-form-item>
          <template slot="label"
            ><span class="q-danger">*</span> 分组：</template
          >
          <el-select v-model="form.group_id" class="edit-form__input">
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!--  文章来源 -->
        <el-form-item>
          <template slot="label"
            ><span class="q-danger">*</span> 文章来源：</template
          >
          <el-radio-group v-model="form.source">
            <el-radio :label="1">图文转载</el-radio>
            <el-radio :label="2">原创文章</el-radio>
          </el-radio-group>
        </el-form-item>

        <!--  文章标题 -->
        <el-form-item label="文章标题：" prop="title" key="title">
          <el-input
            class="edit-form__input"
            v-model.trim="form.title"
            maxlength="20"
            show-word-limit
            placeholder="请输入文章标题"
          ></el-input>
        </el-form-item>

        <!--  文章链接 -->
        <el-form-item
          label="文章链接："
          prop="art_link"
          v-if="form.source === 1"
          key="art_link"
        >
          <el-input
            class="edit-form__input"
            v-model.trim="form.art_link"
            maxlength="250"
            show-word-limit
            placeholder="请输入文章链接"
          ></el-input>
        </el-form-item>

        <!--  链接摘要 -->
        <el-form-item label="链接摘要：">
          <el-input
            class="edit-form__input"
            v-model.trim="form.art_desc"
            maxlength="170"
            show-word-limit
            placeholder="请输入链接摘要"
          ></el-input>
        </el-form-item>

        <!--  主图 -->
        <el-form-item prop="cover_image" key="cover_image">
          <template slot="label"
            >主图
            <el-tooltip
              class="item"
              effect="light"
              placement="right"
              popper-class="q-tooltip is-light"
            >
              <div slot="content">
                <div
                  style="
                    margin: 24px 24px 16px;
                    width: 164px;
                    background: #f2f7fe;
                    padding: 16px 24px;
                  "
                >
                  <div style="text-align: center" class="mb-16">
                    上传文章主图将会展示在 聊天会话框中
                  </div>
                  <img
                    src="https://image.01lb.com.cn//uploads/wecom/22/0331/16486983611e5D6ZOE.png"
                    width="164"
                    alt=""
                  />
                </div>
              </div>
              <i class="iconfont icon-info-fill q-info"></i></el-tooltip
            >：
          </template>
          <div class="q-info text-small">
            支持JPG、PNG格式，图片小于2M，尺寸建议500*500px
          </div>
          <ly-upload
            :img="form.cover_image"
            tipIcon="el-icon-plus"
            @getImg="fn_getPoster"
          ></ly-upload>
        </el-form-item>

        <!--  正文 -->
        <el-form-item
          label="正文："
          prop="art_content"
          v-if="form.source === 2"
          key="art_content"
        >
          <div :class="['article-content', contentError !== '' ? 'error' : '']">
            <wang-editor
              class="wangEditor"
              refName="article"
              :content="form.art_content"
              :pasteIgnoreImg="false"
              uploadImg
              uploadVideo
              :excludeMenus="['code']"
              @getText="fn_getContentText"
              placeholder="请输入文章正文"
              :setCont="
                (html) => {
                  fn_setDetail(html);
                }
              "
            />
            <div class="article-content__footer">
              {{ contentText.length }}/4000
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-scrollbar>
  </div>
</template>

<script>
import wangEditor from '@/components/wangEditor'
export default {
  name: 'edArticle',

  props: {
    hasChange: {
      type: Boolean,
      defualt: false
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentGroupId: {
      type: String,
      default: '0'
    }
  },

  components: {
    [wangEditor.name]: wangEditor
  },

  data () {
    const checkUrl = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请填写文章链接'))
      }
      if (!this.util.isUrl(value)) {
        return callback(new Error('链接格式错误'))
      }
      callback()
    }
    const checkContent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请填写正文'))
      }
      if (this.contentText.length > 4000) {
        return callback(new Error('正文字数请勿超过4000'))
      }
      callback()
    }
    return {
      loading: true,
      form: {
        type: 6,
        name: '',
        group_id: '0',
        source: 1,
        title: '',
        art_link: '',
        art_desc: '',
        cover_image: '',
        art_content: '',
        content: {}
      },
      rules: {
        name: [
          { required: true, message: '请填写素材名称', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请填写文本内容', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请填写文章标题', trigger: 'change' }
        ],
        art_link: [
          { validator: checkUrl, required: true, trigger: 'change' }
        ],
        cover_image: [
          { required: true, message: '请上传主图', trigger: 'change' }
        ],
        art_content: [
          { required: true, validator: checkContent, trigger: 'change' }
        ]
      },
      contentText: '',
      contentError: false
    }
  },

  watch: {
    loading (val) {
      if (!val) {
        this.$emit('ready')
      }
    },
    'form.source' (val) {
      this.$refs.formRef.clearValidate()
      if (val === 1) {
        this.$emit('setting', { width: '672px', top: '15vh' })
      } else {
        this.$emit('setting', { width: '992px', top: '5vh' })
      }
    },
    form: {
      handler (val) {
        if ((this.isEdit && this.editReady) || (!this.isEdit && !this.editReady)) {
          this.$emit('update:hasChange', true)
        }
        if (this.isEdit) {
          this.editReady = true
        }
      },
      deep: true
    }
  },

  methods: {
    // 初始化数据
    fn_init (form) {
      if (!form) {
        this.form.group_id = this.currentGroupId === '-1' ? '0' : this.currentGroupId
        this.loading = false
        return
      }

      const detail = this.util.extend(true, {}, form)

      detail.cover_image = detail.content.cover_image
      detail.art_link = detail.content.link
      detail.title = detail.content.title
      detail.source = detail.content.source
      detail.art_desc = detail.content.summary
      detail.art_content = detail.content.content
      if (detail.source === 2) {
        this.$emit('setting', { width: '992px', top: '5vh' })
      }

      this.form = detail
      this.loading = false
      this.isEdit = true
    },

    // 图片回调
    fn_getPoster (cover_image) {
      this.form.cover_image = cover_image
      this.$refs.formRef.validateField('cover_image')
    },

    // 内容 text
    fn_getContentText (txt) {
      this.contentText = txt
    },

    // 内容回调
    fn_setDetail (html) {
      this.form.art_content = html
      this.$refs.formRef.validateField('art_content')
    },

    // 表单校验，通过则返回 form
    fn_verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)
      form.content = {
        source: form.source,
        title: form.title,
        link: form.art_link,
        summary: form.art_desc,
        cover_image: form.cover_image,
        content: form.art_content
      }

      delete form.source
      delete form.title
      delete form.art_link
      delete form.art_desc
      delete form.cover_image
      delete form.art_content

      return form
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.article {
  height: auto;
  overflow: hidden;

  &-high {
    height: 670px;
  }

  &-scroll {
    height: 100%;
  }

  &-content {
    width: 800px;
    border: 1px solid #dfe1e8;
    border-radius: 8px;
    overflow: hidden;

    ::v-deep .w-e-text-container {
      height: 560px !important;
    }

    &__footer {
      text-align: right;
      padding: 0 12px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.is-error {
  ::v-deep .article-content {
    border-color: $--color-danger;
  }
}
</style>
