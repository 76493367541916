<!--
 * @Author: zhanln
 * @Date: 2021-11-01 17:31:38
 * @LastEditTime: 2022-07-07 16:36:43
 * @LastEditors: zhan
 * @Description: 活动信息
-->

<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-width="140px" size="medium" class="act-form"
    v-loading="cardLoading">
    <!-- 活动名称 -->
    <el-form-item label="活动名称：" prop="title" class="basic-form__item">
      <el-input class="act-form__input" v-model.trim="form.title" maxlength="16" show-word-limit placeholder="请输入活动名称">
      </el-input>
    </el-form-item>

    <!-- 活动封面图 -->
    <el-form-item label="活动封面图：" prop="show_url" class="basic-form__item">
      <template slot="label">活动封面图
        <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
          <div slot="content">
            活动封面图将展示在小程序活动中心“ 首页” 进行推广
          </div>
          <i class="iconfont icon-info-fill" style="position: relative; top: 1px"></i>
        </el-tooltip>：
      </template>
      <div class="q-info text-small">
        建议尺寸:702*340像素，大小不超过2MB，格式为JPG/PNG/JPEG
      </div>
      <ly-upload :img="form.show_url" @getImg="fn_getImg($event, 'show_url')" tipText="活动封面图" accept=".png,.jpg,.jpeg">
      </ly-upload>
    </el-form-item>

    <!-- 直播预告 -->
    <el-form-item label="直播预告：" prop="vn_id" class="basic-form__item">
      <el-input class="act-form__input" v-model.trim="form.vn_id" maxlength="60" show-word-limit placeholder="请输入视频号ID">
      </el-input>
      <p class="q-info text-small" style="line-height: 1.6; margin-bottom: 0px;">
        视频号ID必须与小程序主体一致，否则将无法跳转
        <span class="q-primary" style="margin-left: 24px; cursor: help;" @click="fn_toDoc">如何获取视频号ID？</span>
      </p>
    </el-form-item>
  </el-form>
</template>

<script>
// store
import { mapState } from 'vuex'
import { activity } from '../http'
export default {
  name: 'actBasic',

  data () {
    return {
      COMM_HTTP: activity,
      cardLoading: true,
      form: {
        title: '',
        show_url: '',
        vn_id: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        show_url: [
          { required: true, message: '请上传活动封面图', trigger: 'change' }
        ],
        vn_id: [
          { required: true, message: '请输入视频号ID', trigger: 'change' }
        ]
      }
    }
  },

  computed: {
    ...mapState({
      open_time: state => state.livelottery.open_time,
      isEdit: state => state.livelottery.editType === 'edit'
    }),
    endDatePickerOptions () {
      return {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
        selectableRange: (() => {
          const now = new Date()
          const pick = new Date(new Date(this.form.end_time).setHours(0, 0, 0, 0)).getTime()
          const today = new Date().setHours(0, 0, 0, 0)
          // 时间段，今天时间前不可选
          let rangeFirst = '00:00:00'
          if (pick === today) {
            rangeFirst = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
          }
          return `${rangeFirst} - 23:59:59`
        })()
      }
    }
  },

  methods: {

    // 初始化
    fn_setForm (data) {
      if (data) {
        this.form = data
      }
      setTimeout(() => {
        this.cardLoading = false
      }, 100)
    },

    // 活动首图回调设置
    fn_getImg (img, type) {
      this.form[type] = img
      this.$refs.formRef.validateField(type)
    },

    // 如何获取视频号
    fn_toDoc () {
      window.open('https://www.yuque.com/docs/share/2890cdcb-3648-48ae-8308-40dbd9efbca6', '_blank')
    },

    // 表单校验，通过则返回 form
    verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)

      return form
    }

  }
}
</script>

<style lang="scss" scoped>
.basic-form {
  width: 100%;

  &__item {
    width: 60%;
    min-width: 560px;

    &.is-long {
      width: 100%;
      max-width: 860px;
    }
  }
}

.group-table {
  width: 100%;
  border-bottom: none;
  line-height: 1;

  &::before {
    background-color: #eaebf2;
  }

  ::v-deep .el-button--text {
    padding-top: 0;
    padding-bottom: 0;
  }

  ::v-deep .el-dropdown-more {
    color: #2b7cff;
    line-height: 32px;

    &:hover {
      color: #5ca2ff;
    }
  }

  ::v-deep .el-input-number .el-input__inner {
    border-color: #eaebf2 !important;
  }
}
</style>
