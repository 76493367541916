<template>
  <el-main class="inner is-dark detail">
    <div class="flex">
      <div class="detail-base ly-card is-margin">
        <div class="flex flex-between detail-base__header">
          <div class="flex">
            <div class="detail-base__avatar tearm">
              <i class="iconfont icon-weixin"></i>
            </div>
            <div class="flex flex-column flex-start">
              <span class="detail-base__name">{{
                info.name !== "" ? info.name : "群聊"
              }}</span>
              <div class="flex flex-center" style="color: #6e788a">
                群主：<img
                  :src="info.owner_avatar"
                  alt=""
                  class="detail-base__avatar-small"
                />
                {{ info.owner_name }}
                <div class="flex" v-if="isSuperManager">
                  <span class="ly-gap is-vertical"></span
                  ><span class="ly-link" @click="jumpToAnalyse"
                    >查看ta的群聊分析</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-row :gutter="16">
          <el-col :span="8">
            <div class="is-gray">
              <div class="q-info">今日入群</div>
              <div>
                <span class="detail-num">{{ info.today_add }}</span
                ><span class="q-info">人</span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="is-gray">
              <div class="q-info">今日退群</div>
              <div>
                <span class="detail-num">{{ info.today_del }}</span
                ><span class="q-info">人</span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="is-gray">
              <div class="q-info">群总人数</div>
              <div>
                <span class="detail-num">{{ info.total }}</span
                ><span class="q-info">人</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="detail-right ly-card is-margin" style="min-height: 171px">
        <div class="detail-base__header">
          <div class="ly-table__main-title">群公告</div>
        </div>
        <div class="detail-base__inner">
          <template v-if="info.notice === ''">
            <div class="flex flex-center">
              <div class="flex flex-column">
                <img
                  src="@assets/svg/default/no_notice.svg"
                  alt=""
                  style="width: 100px; margin-bottom: 16px"
                />
                <div class="q-info">暂无群公告</div>
              </div>
            </div>
          </template>
          <template v-else>
            {{ info.notice }}
          </template>
        </div>
      </div>
    </div>

    <layout-table
      :pageTotal="total"
      @pageChange="pageChange"
      :loading="loading"
      :page="page"
    >
      <div class="ly-table__main-header flex flex-between">
        <div class="flex">
          <div class="ly-table__main-title">群成员</div>
          <span>共{{ info.total }}人</span>
          <span class="ly-gap is-vertical"></span>
          员工 {{ info.wecom_user_total }} 人，客户
          {{ info.wecom_external_user_total }} 人
        </div>
      </div>

      <el-table :data="tableData" style="width: 100%" @sort-change="tableSort">
        <!-- <el-table-column
          type="index"
          width="50"
          align="center">
        </el-table-column> -->
        <el-table-column prop="name, avatar_url" label="成员昵称">
          <template slot-scope="scope">
            <div class="flex">
              <img
                v-if="scope.row.avatar_url"
                :src="scope.row.avatar_url"
                alt=""
                class="ly-table__main-avatar"
              />
              <img
                v-else
                src="../../../assets/images/default_avatar.png"
                alt=""
                class="ly-table__main-avatar"
              />
              <div class="ly-table__main-name">
                {{ scope.row.name }}
                <template v-if="scope.row.is_owner">
                  <span class="group-tag">群主</span>
                </template>
                <template v-else>
                  <span class="q-warning" v-if="scope.row.corp_name"
                    >@{{ scope.row.corp_name }}</span
                  >
                  <span class="q-success" v-else>@微信</span>
                </template>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="join_scene" label="入群方式">
          <template slot-scope="scope">
            <template v-if="scope.row.join_scene === 0">未知来源</template>
            <template v-if="scope.row.join_scene === 1">员工直接邀请</template>
            <template v-if="scope.row.join_scene === 2">员工链接邀请</template>
            <template v-if="scope.row.join_scene === 3">扫描群二维码</template>
          </template>
        </el-table-column>
        <el-table-column prop="join_at" label="入群时间" sortable="custom">
          <template slot-scope="scope">
            <div class="ly-table__main-time">
              {{ util.timeTofmt(scope.row.join_at, "yyyy-MM-dd hh:mm") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="操作" class-name="operation-cell">
          <template slot-scope="scope">
            <template v-if="scope.row.type === 'wecom_user'">
              <el-button
                type="text"
                :disabled="!scope.row.view"
                @click="jumpToEmp(scope.row)"
                >员工详情</el-button
              >
            </template>
            <template v-else-if="scope.row.type === 'wecom_external_user'">
              <el-button
                type="text"
                :disabled="!scope.row.view"
                @click="jumpToCus(scope.row)"
                >客户详情</el-button
              >
            </template>
            <template v-else-if="scope.row.type === 'group_external_user'">
              <el-button
                type="text"
                :disabled="!scope.row.user_id"
                @click="jumpToCus(scope.row)"
                >客户详情</el-button
              >
            </template>
          </template>
        </el-table-column>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>
    </layout-table>
  </el-main>
</template>

<script>
import layoutTable from '@/components/layout/lyTable'
import { mapGetters } from 'vuex'
export default {
  name: 'detail',
  data () {
    return {
      id: null,
      info: {
        name: '',
        owner_name: '',
        owner_avatar: '',
        today_add: 0,
        today_del: 0,
        total: 0
      },
      tableData: [
        {
          name: '',
          join_scene: 1,
          create_at: 'create_at',
          note: 'note',
          tag: 'tag',
          wecom: 'wecom',
          info: 'info'
        }
      ],
      page: 1,
      total: 0,
      loading: false,
      userform: {
        is_desc: true,
        order_by: 'added_at'
      }
    }
  },
  computed: {
    ...mapGetters({
      isSuperManager: 'permission/GET_SUPERMANAGER'
    })
  },
  created () {
    this.id = this.$route.query.id
    this.getGroupInfo()
    this.getGroupUsers()
  },
  methods: {

    // 详情
    async getGroupInfo () {
      const data = await this.axios.get('groupInfo', {
        params: {
          group_id: this.id
        }
      })
      this.info = data
    },

    // 成员
    async getGroupUsers () {
      this.loading = true
      const data = await this.axios.get('groupUsers', {
        params: {
          group_id: this.id,
          page: this.page,
          per_page: 10,
          ...this.userform
        }
      })
      this.tableData = data.list
      this.loading = false
      this.total = data.total
    },

    // 群聊分析
    jumpToAnalyse () {
      const routeData = this.$router.resolve({
        path: '/groupOperation/management/analysis',
        query: {
          id: this.info.ower_id
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 群成员分页监听
    pageChange (val) {
      this.page = val
      this.getGroupUsers()
    },

    // 员工详情
    jumpToEmp (item) {
      const routeData = this.$router.resolve({
        path: '/enterprise/employees/detail',
        query: {
          id: item.user_id
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 客户详情
    jumpToCus (item) {
      const routeData = this.$router.resolve({
        path: '/accountOperation/overview/detail',
        query: {
          id: item.user_id
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 排序监听
    tableSort (column) {
      this.page = 1
      if (column.order === null) {
        this.userform.direction = null
      } else {
        this.userform.is_desc = column.order === 'descending'
      }
      this.getGroupUsers()
    }

  },
  components: {
    layoutTable
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/table.scss";
@import "@/assets/scss/components/card.scss";
@import "@/assets/scss/components/gap.scss";

.detail {
  .group-tag {
    background: #f7f8fc;
    height: 24px;
    line-height: 24px;
    border-radius: 24px;
    display: inline-block;
    padding: 0 8px;
  }
  &-base {
    flex: 1;
    margin-right: 20px;
    .is-gray {
      padding: 12px 20px 20px;
      background: #f8fafd;
      border-radius: 16px;
    }
    &__header {
      margin-bottom: 24px;
    }

    &__avatar {
      position: relative;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;

      &.tearm {
        background: #338bff;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-weixin {
          font-size: 32px;
        }
      }
      img {
        width: 100%;
      }
    }

    &__avatar-small {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 4px;

      img {
        width: 100%;
      }
    }

    &__name {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      color: #212b36;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    &__inner {
      height: (171-25-16px);
      overflow: auto;
    }
  }

  &-right {
    width: 402px;
  }

  &-num {
    display: inline-block;
    font-size: 30px;
    color: #212b36;
    margin-right: 6px;
    line-height: 1;
    margin-top: 8px;
  }
  ::v-deep {
    .ly-card.is-margin {
      height: 221px;
      box-sizing: border-box;
    }
    .ly-table__main {
      border-radius: 16px;
    }
  }
}
</style>
