var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "reward_title",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "avatar-name" }, [
                  row.reward_type === 4
                    ? _c("img", {
                        staticStyle: { width: "20px", "margin-right": "8px" },
                        attrs: {
                          src: require("@/assets/images/redenvelopes.png"),
                          alt: ""
                        }
                      })
                    : _c("img", {
                        staticStyle: { width: "20px", "margin-right": "8px" },
                        attrs: {
                          src: require("@/assets/svg/reward.svg"),
                          alt: ""
                        }
                      }),
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(row.reward_title))
                  ])
                ])
              ]
            }
          },
          {
            key: "get_status_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.get_status === 1
                  ? [_vm._v("未领取")]
                  : row.get_status === 2
                  ? [_vm._v("已领取")]
                  : row.get_status === 3
                  ? [_vm._v("未填写")]
                  : row.get_status === 4
                  ? [_vm._v("已填写")]
                  : row.get_status === 5
                  ? [_vm._v("发放成功")]
                  : row.get_status === 6
                  ? [
                      _vm._v(" 发放失败 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: row.fail_msg,
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-warning-fill" })]
                      )
                    ]
                  : [_vm._v("-")]
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                row.reward_type === 4
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: row.get_status === 5 },
                        on: {
                          click: function($event) {
                            return _vm.handleReSend(row)
                          }
                        }
                      },
                      [_vm._v("重新发放")]
                    )
                  : _vm._e(),
                row.reward_type === 4
                  ? _c("el-divider", { attrs: { direction: "vertical" } })
                  : _vm._e(),
                (row.reward_type === 2 && !row.reward_value) ||
                row.get_status === 3
                  ? [_vm._v(" - ")]
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.getRewardDetail(row)
                          }
                        }
                      },
                      [_vm._v("查看奖品内容")]
                    )
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品内容",
            visible: _vm.dialogVisible,
            "append-to-body": "",
            "custom-class": "q-dialog",
            width: "34%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _vm.rewardInfo.reward_type === 4
            ? _c("rd-detail", {
                attrs: { rewardValue: _vm.rewardInfo.reward_value }
              })
            : _c("div", { staticClass: "reward" }, [
                _vm.rewardInfo.reward_type === 1
                  ? _c("div", { staticClass: "q-dialog-block q-dialog__img" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.rewardInfo.reward_detail)
                        }
                      }),
                      _c("img", {
                        staticClass: "reward-img",
                        attrs: { src: _vm.rewardInfo.reward_detail_img }
                      })
                    ])
                  : _vm.rewardInfo.reward_type === 2
                  ? _c("div", { staticClass: "q-dialog-block" }, [
                      _c("div", { staticClass: "reward-code" }, [
                        _vm._v(_vm._s(_vm.rewardInfo.reward_value))
                      ])
                    ])
                  : _vm.rewardInfo.reward_type === 3
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.rewardLoading,
                            expression: "rewardLoading"
                          }
                        ],
                        staticClass: "reward-list"
                      },
                      _vm._l(_vm.rewardDetail, function(i, v) {
                        return _c(
                          "div",
                          { key: v, staticClass: "reward-list__item" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(i.name))
                            ]),
                            _c("div", { staticClass: "val" }, [
                              _vm._v(_vm._s(i.data))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }