<template>
  <div class="edit-card" v-loading="pageLoading">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>

    <div class="edit-card__content">
      <el-form
        :model="basicForm"
        :rules="rules"
        ref="basicFormRef"
        label-width="150px"
        style="width: 60%; min-width: 560px"
        size="medium"
      >
        <!-- 活动名称 -->
        <el-form-item label="活动名称" prop="title">
          <el-input
            v-model="basicForm.title"
            maxlength="16"
            show-word-limit
            placeholder="不对外展示，用户区分活动使用场景"
            @input="fn_setBasic('title', $event)"
          ></el-input>
        </el-form-item>

        <!-- 结束时间 -->
        <el-form-item label="活动结束时间" prop="end_date">
          <el-date-picker
            type="datetime"
            placeholder="请选择时间"
            v-model="basicForm.end_date"
            popper-class="end-date-picker"
            :picker-options="endDatePickerOptions"
            @change="fn_setBasic('end_date', $event)"
          ></el-date-picker>
        </el-form-item>

        <!-- 涨粉账号 -->
        <el-form-item required :error="wecom_user_idsTips">
          <template slot="label"
            >涨粉账号
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">
                1.「涨粉账号」会关联「裂变海报」的二维码。<br />
                2.用户扫描海报二维码后会随机平均添加「涨粉账号」好友。
              </div>
              <i class="iconfont icon-info-fill"></i
            ></el-tooltip>
          </template>
          <ly-emp-btn
            btnText="添加涨粉账号"
            :max="100"
            :isReal="true"
            :datafilter="true"
            :checkList="basicForm.wecom_user_ids"
            @getEmpId="fn_getEmpId"
          ></ly-emp-btn>
        </el-form-item>

        <el-form-item prop="code_fail_date">
          <template slot="label"
            >二维码失效时间
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">
                活动结束后，据失效时间判断新用户是否可以进入活动页
              </div>
              <i class="iconfont icon-info-fill"></i
            ></el-tooltip>
          </template>
          <el-radio-group v-model="basicForm.code_fail_mode">
            <el-radio :label="1">活动结束后立即失效</el-radio>
            <el-radio :label="2">自定义</el-radio>
          </el-radio-group>
          <el-date-picker
            v-if="basicForm.code_fail_mode === 2"
            type="datetime"
            placeholder="请选择时间"
            v-model="basicForm.code_fail_date"
            style="width: 320px"
            popper-class="expiration-picker"
            :picker-options="exPickerOptions"
            @change="fn_setBasic('code_fail_date', $event)"
          ></el-date-picker>
        </el-form-item>

        <!-- 裂变欢迎语 -->
        <el-form-item prop="welcome_msg">
          <template slot="label"
            >裂变欢迎语
            <el-tooltip
              class="item"
              effect="light"
              placement="right"
              popper-class="q-tooltip is-light"
            >
              <div slot="content">
                <div style="margin: 24px 24px 16px; width: 472px">
                  <img
                    src="https://image.01lb.com.cn//uploads/wecom/21/0506/16202874779hIE5N66.png"
                    width="472"
                    alt=""
                  />
                  <div class="text-bold" style="margin: 16px 0 8px">
                    欢迎语下发规则提示：
                  </div>
                  <div>
                    若涨粉账号在【企业微信后台】被配置了欢迎语，这里的欢迎语将会失效，请确保所涨粉账号未被配置欢迎语。
                  </div>
                </div>
              </div>
              <i class="iconfont icon-info-fill"></i
            ></el-tooltip>
          </template>

          <ly-editor
            title="欢迎语"
            id="prizeWords"
            :content="basicForm.welcome_msg"
            :insetMenu="['客户昵称']"
            :recoverContent="defaultWelWords"
            emoji
            @getContent="fn_getWelWords"
          ></ly-editor>
        </el-form-item>
        <el-form-item label="裂变链接" prop="link_type">
          <el-radio-group v-model="basicForm.link_type">
            <el-radio :label="1">默认</el-radio>
            <el-radio :label="2">自定义</el-radio>
          </el-radio-group>
          <template v-if="basicForm.link_type === 2">
            <el-form-item
              label="主图"
              label-width="80px"
              style="margin-top: 12px"
              :error="coverError"
            >
              <template slot="label"
                ><span class="q-danger">*</span> 主图
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="top"
                  content="支持JPG、PNG格式，图片大小2M以内"
                  popper-class="q-tooltip is-light"
                >
                  <i class="iconfont icon-info-fill"></i
                ></el-tooltip>
              </template>
              <el-upload
                action="#"
                accept=".png, .jpg, .jpeg"
                :http-request="requestContentImg"
                :on-remove="removeContentImg"
                list-type="picture-card"
                :limit="1"
                :file-list="basicForm.link_logo"
                :class="[
                  'upload',
                  basicForm.link_logo.length === 0 ? '' : 'upload-empty',
                ]"
              >
                <div
                  class="flex flex-column flex-center"
                  style="line-height: 22px; height: 100%"
                >
                  <i class="el-icon-plus" style="font-size: 20px"></i>
                  <span class="text q-info text-small">上传图片</span>
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item label="标题" prop="link_title" label-width="80px">
              <el-input
                v-model="basicForm.link_title"
                maxlength="20"
                show-word-limit
                placeholder="请输入链接标题"
                @change="fn_setBasic('link_title', $event)"
              ></el-input>
            </el-form-item>

            <el-form-item label="描述" label-width="80px">
              <el-input
                v-model="basicForm.link_desc"
                maxlength="200"
                show-word-limit
                placeholder="请输入链接描述"
                @change="fn_setBasic('link_desc', $event)"
              ></el-input>
            </el-form-item>
          </template>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import lyEditor from '@/components/lyEditor'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
import { activityList } from '../http'
export default {
  name: 'rdBasic',
  props: {
    aid: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    }
  },
  mixins: [qiniuUpload],

  data () {
    const checkTitle = async (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error('请输入活动名称'))
      } else {
        const isSame = await this.fn_checkTitleSame()
        if (!isSame) {
          callback(new Error('活动名称不允许重复'))
        } else {
          callback()
        }
      }
    }
    return {
      COMM_HTTP: activityList,
      pageLoading: true,
      rules: {
        title: [
          { required: true, validator: checkTitle, trigger: 'blur' }
        ],
        end_date: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ],
        welcome_msg: [
          { required: true, message: '请填写裂变欢迎语', trigger: 'change' }
        ],
        code_fail_date: [
          { required: true, message: '请选择二维码失效时间', trigger: 'change' }
        ],
        link_type: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        link_title: [
          { required: true, message: '请输入链接标题', trigger: 'change' }
        ]
      },
      wecom_user_idsTips: '',
      defaultWelWords: '#客户昵称#，终于等到你\n\n1. 参与本次活动，马上领取微信红包，直接到账的那种~\n2. 点击下方链接，立即领取。\n3. 生成你的专属海报，每邀请1位好友助力即可领取随机红包。\n4. 红包金额实时到账，可在微信钱包实时查看。\n\n点击链接立即领取红包（红包实时到账微信零钱）',
      editCount: 0,
      tagList: [],
      tagTotal: 0,
      tagsReady: false,
      newTagGroup: [],
      num: 2,
      basicAlReady: false,
      coverError: ''
    }
  },

  computed: {
    // 获取红包活动信息
    basicForm () {
      const BASIC = this.$store.state.rede.basic
      return BASIC
    },
    isCopy () {
      return this.$store.state.rede.isCopy
    },
    isEdit () {
      return this.$store.state.rede.isEdit
    },
    status () {
      return this.$store.state.rede.status
    },
    endDatePickerOptions () {
      const now = new Date()
      const today = new Date().setHours(0, 0, 0, 0)
      const pick = new Date(new Date(this.basicForm.end_date).setHours(0, 0, 0, 0)).getTime()
      let selectableRange = '00:00:00 - 23:59:59'
      if (pick === today) {
        selectableRange = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} - 23:59:59`
      }
      return {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
        selectableRange
      }
    },
    exPickerOptions () {
      const now = new Date()
      const today = new Date().setHours(0, 0, 0, 0)
      const pick = new Date(new Date(this.basicForm.code_fail_date).setHours(0, 0, 0, 0)).getTime()
      let selectableRange = '00:00:00 - 23:59:59'
      if (pick === today) {
        selectableRange = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} - 23:59:59`
      }
      return {
        disabledDate: (time) => {
          const end_date = this.$day(this.basicForm.end_date).valueOf()
          return time.getTime() < end_date - 24 * 60 * 60 * 1000
        },
        selectableRange
      }
    }
  },

  mounted () {
  },

  watch: {
    basicForm: {
      handler (val) {
        this.editCount = val.welcome_msg.length
        if (!val.end_date || this.isCopy) {
          val.end_date = this.fn_getCurrent()
        }
        this.pageLoading = false
      },
      immediate: true
    },
    'basicForm.end_date': {
      handler (n) {
        if (n) {
          if (!this.basicForm.code_fail_date || this.basicForm.code_fail_mode === 1) {
            this.basicForm.code_fail_date = n
          }
          if (n.getTime() > this.basicForm.code_fail_date.getTime()) {
            this.basicForm.code_fail_date = n
          }
        }
      },
      immediate: true
    },
    'basicForm.code_fail_mode' (n) {
      if (n === 1) {
        this.basicForm.code_fail_date = this.basicForm.end_date
      }
    }
  },

  methods: {
    // 设置默认结束时间（7天后）
    fn_getCurrent (time) {
      let _time = new Date()
      if (_time.toString().indexOf('GMT-08:00') > -1) {
        _time = new Date((_time.getTime() + (1 / 24 * 60 * 60 * 1000)))
      }
      const TIME = time || new Date(_time).getTime() + (7 * 24 * 60 * 60 * 1000)
      this.fn_setBasic('end_date', this.$day(TIME).toDate())
      return this.$day(TIME).toDate()
    },

    // 获取涨粉账号
    fn_getEmpId (list) {
      if (list && list.length > 0) {
        this.fn_setBasic('wecom_user_ids', list)
        this.wecom_user_idsTips = ''
      } else {
        this.fn_setBasic('wecom_user_ids', null)
        this.wecom_user_idsTips = '请至少选择一位涨粉账号'
      }
    },

    fn_getWelWords (val) {
      this.basicForm.welcome_msg = val
      this.fn_setBasic('welcome_msg', val)
    },

    // 上传内容图片
    async requestContentImg (opts) {
      this.basicForm.link_logo = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.basicForm.link_logo = [{ url: path }]
          },
          null,
          {
            type: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
        )
      }
      this.fn_setBasic(this.basicForm.link_logo, 'link_logo')
      this.fn_checkCover()
    },

    // 删除内容图片
    removeContentImg () {
      this.basicForm.link_logo = []
      this.fn_setBasic([], 'link_logo')
      this.fn_checkCover()
    },

    fn_checkCover () {
      let check = true
      if (this.basicForm.link_logo.length <= 0) {
        this.coverError = '请上传链接主图'
        check = false
      } else {
        this.coverError = ''
      }
      return check
    },

    // 设置活动信息
    fn_setBasic (type, data) {
      const BASIC = this.$store.state.rede.basic
      BASIC[type] = data
      this.$store.commit('_SET', {
        'rede.basic': BASIC
      })

      if (type === 'welcome_msg') {
        this.editCount = data.length
      }
    },

    // 活动名称唯一
    async fn_checkTitleSame () {
      const form = {
        title: this.basicForm.title
      }
      if (this.isEdit && !this.isCopy) {
        form.filter_id = this.aid
      }
      const data = await this.COMM_HTTP.checkTitle(form)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.exist === 1) {
        return false
      }

      return true
    },

    fn_checkTitle () {
      return new Promise((resolve, reject) => {
        this.$refs.basicFormRef.validateField('title', err => {
          resolve(err)
        })
      })
    },

    // 表单验证
    async verify (type) {
      let formValid = false

      if (type === 2) {
        const checkTitle = await this.fn_checkTitle()
        formValid = checkTitle === ''
        return formValid
      } else {
        formValid = await this.$refs.basicFormRef.validate().catch(err => err)

        if (!this.basicForm.wecom_user_ids) {
          this.wecom_user_idsTips = '请至少选择一位涨粉账号'
          formValid = false
        }

        if (this.basicForm.link_type === 2) {
          const checkCover = this.fn_checkCover()
          return formValid && checkCover
        } else {
          return formValid
        }
      }
    }
  },

  components: {
    lyEditor
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";

.rules {
  border-radius: 8px;
  border: 1px solid #eaebf2;
  overflow: hidden;

  &-header {
    background-color: #f7f8fc;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #dfe1e8;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-footer {
    text-align: right;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    &-inset {
      border-right: 2px;
      padding: 0 8px;
      height: 24px;
      line-height: 24px;
      cursor: pointer;
      border-radius: 2px;
      color: $--color-text-regular;
      font-weight: 500;
      font-size: 14px;
      &:hover {
        color: $--color-primary;
      }
    }
  }

  &-inset {
    display: inline-block;
    cursor: pointer;
    background: #fff;
    line-height: 28px;
    padding: 0 8px;
    border-radius: 2px;
    transition: $--transition;
    font-size: 12px;
    border-radius: 28px;

    &:hover {
      background-color: $--color-primary;
      color: #fff;
    }

    &.default {
      background-color: transparent;
      color: $--color-text-regular;
      padding: 0;

      &:hover {
        color: $--color-primary;
      }
    }

    & + & {
      margin-left: 12px;
    }
  }

  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 200px !important;
  }
}

.tag-select {
  margin-top: 16px;

  & + & {
    margin-top: 24px;
    border-radius: 4px;
  }
}
.upload {
  line-height: 1;
  ::v-deep .el-upload--picture-card,
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 72px;
    height: 72px;
  }
}

.upload-empty {
  line-height: 1.6;
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}

::v-deep .el-upload-list__item {
  margin: 0;
}
::v-deep .upload-empty {
  line-height: 1;
}
</style>
