<!--
 * @Author: zhanln
 * @Date: 2021-11-18 15:02:51
 * @LastEditTime: 2021-11-30 15:01:48
 * @LastEditors: zhanln
 * @Description: 活动规则预览
-->

<template>
  <div class="reward-rule" v-if="visible">
    <div class="reward-rule__mask"></div>
    <div class="reward-rule__body">
      <div class="reward-rule__box">
        <div class="reward-rule__title">活动规则</div>
        <div class="reward-rule__content">
          <el-input
            type="textarea"
            v-model="rule"
            resize="none"
            readonly
          ></el-input>
        </div>
      </div>
      <div class="reward-rule__close">
        <i class="el-icon-circle-close" @click="visible = false"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'taskMobileRewardRule',

  props: {
    tabKey: {
      type: String,
      default: ''
    }
  },

  watch: {
    tabKey (val) {
      this.visible = val === 'setting'
      this.$store.state.task.reward_focus = false
    }
  },

  data () {
    return {
      visible: false
    }
  },

  created () {
    this.visible = this.tabKey === 'setting'
  },

  computed: {
    rule () {
      return this.$store.getters['task/GET_INFO']('rule')
    }
  }
}
</script>

<style lang="scss" scoped>
.reward-rule {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    overflow: hidden;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }

  &__box {
    padding: 0 20px 20px;
    background-color: #fff;
    border-radius: 12px;
  }

  &__title {
    line-height: 42px;
    text-align: center;
  }

  &__content {
    ::v-deep .el-textarea__inner {
      height: 300px;
      overflow: auto;
      border: none;
      background-color: #f5f5f5;
      border-radius: 0;
      padding: 12px;
      font-size: 12px;
    }
  }

  &__close {
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-top: 8px;
  }
}
</style>
