var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-mobile",
    {
      attrs: { showWel: false, hasFooter: false, sub: "", title: "视频号直播" }
    },
    [
      _c(
        "div",
        { staticClass: "reward" },
        [
          _c("div", { staticClass: "reward-title" }, [
            _vm.cover_title.length > 7
              ? _c("span", [_vm._v(_vm._s(_vm.cover_title.slice(0, -7)))])
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.cover_title.slice(-7)))])
          ]),
          _c("div", { staticClass: "reward-countdown" }, [
            _vm._v(" 活动倒计时: "),
            _c("div", { staticClass: "reward-countdown__count" }, [
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.countDown.d))
              ]),
              _c("span", { staticClass: "text" }, [_vm._v("天")]),
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.countDown.h))
              ]),
              _c("span", { staticClass: "text" }, [_vm._v(":")]),
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.countDown.m))
              ]),
              _c("span", { staticClass: "text" }, [_vm._v(":")]),
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.countDown.s))
              ])
            ])
          ]),
          _c("home-prize"),
          _c("div", { staticClass: "reward-btn" }, [
            _vm._v(" " + _vm._s(_vm.btn_title) + " ")
          ]),
          _c("div", { staticClass: "reward-btn__sub text-small" }, [
            _vm._v("· 邀请好友完成直播预约，即可领取奖品 ·")
          ]),
          _c("home-invite"),
          _c("home-rank"),
          _c("div", { staticClass: "reward-rule fx-bg" }, [_vm._v("规则")]),
          _c("div", { staticClass: "reward-barrage" }, [
            _c("img", {
              staticClass: "reward-barrage__img",
              attrs: {
                src:
                  "https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg",
                alt: ""
              }
            }),
            _vm._v(" a呆呆少侠a 正在预约直播 ")
          ])
        ],
        1
      ),
      _c("reward-detail"),
      _c("reward-rule", { ref: "rewardRuleRef", attrs: { tabKey: _vm.tabKey } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }