<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>

    <div class="edit-card__content">
      <el-form
        :model="posterForm"
        :rules="rules"
        ref="posterFormRef"
        label-width="150px"
        class="edit-task__form"
        size="medium"
      >
        <!-- 上传海报 -->
        <el-form-item
          label="上传海报"
          ref="poster"
          required
          :error="posterImgTips"
        >
          <div class="flex">
            <el-upload
              action="#"
              accept=".png, .jpg, .jpeg"
              :http-request="requestQiniu"
              :on-remove="removePoster"
              list-type="picture-card"
              :limit="1"
              ref="uploadFile"
              :file-list="posterImg"
              class="upload"
              style="height: 104px"
            >
              <div class="default">
                <i class="iconfont icon-picture"></i>
                <span>上传图片</span>
              </div>
            </el-upload>
            <div
              class="q-info text-small"
              style="line-height: 22px; margin-left: 24px"
            >
              <span style="color: #454d5b">海报设计须知：</span><br />
              (1) 建议图片尺寸：750px*1334px，分辨率72<br />
              (2) 支持JPG、PNG格式，图片大小2M以内<br />
              (3)
              开启【用户头像】【用户昵称】【二维码】，可在预览区域内移动展示位置。
            </div>
          </div>
        </el-form-item>
        <el-form-item label="用户头像" prop="avatar_sharp">
          <div class="flex" style="height: 36px">
            <el-switch
              v-model="posterForm.avatar_status"
              :active-value="1"
              :inactive-value="0"
              style="margin-right: 24px"
              @change="fn_setPoster('avatar_status', $event)"
            ></el-switch>
            <el-radio-group
              v-model="posterForm.avatar_sharp"
              style="position: relative; top: 1px"
              @change="fn_setPoster('avatar_sharp', $event)"
            >
              <el-radio :label="2">圆形</el-radio>
              <el-radio :label="1">方形</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="用户昵称" prop="nickname_status">
          <div class="flex">
            <el-switch
              v-model="posterForm.nickname_status"
              :active-value="1"
              :inactive-value="0"
              style="margin-right: 24px"
              @change="fn_setPoster('nickname_status', $event)"
            ></el-switch>
            <span style="display: inline-flex; margin-right: 24px">
              颜色：<el-color-picker
                v-model="posterForm.nickname_color"
                @change="fn_setPoster('nickname_color', $event)"
              ></el-color-picker>
            </span>
            <div style="width: 65px">
              <el-slider
                v-model="posterForm.nickname_size"
                :min="10"
                :max="36"
                :format-tooltip="formatTooltip"
                style="flex: 1"
                @change="fn_setNickNameAlign()"
              ></el-slider>
            </div>
            <div style="margin: 0 24px 0 8px">
              {{ posterForm.nickname_size }}px
            </div>
            <div>
              <el-button-group class="nickname-align__btn">
                <el-button
                  size="small"
                  :type="posterForm.nickname_align === 'left' ? 'primary' : ''"
                  @click="fn_setNickNameAlign('left')"
                  >左对齐</el-button
                >
                <el-button
                  size="small"
                  :type="
                    posterForm.nickname_align === 'center' ? 'primary' : ''
                  "
                  @click="fn_setNickNameAlign('center')"
                  >居中</el-button
                >
                <el-button
                  size="small"
                  :type="posterForm.nickname_align === 'right' ? 'primary' : ''"
                  @click="fn_setNickNameAlign('right')"
                  >右对齐</el-button
                >
              </el-button-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="分享话术" prop="share_msg">
          <ly-editor
            title="分享话术"
            id="redeShareMsg"
            :content="posterForm.share_msg"
            :recoverContent="defaultShare"
            style="width: 600px"
            @getContent="fn_getWelWords"
          ></ly-editor>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
export default {
  props: {
    title: {
      type: String,
      defualt: ''
    }
  },
  mixins: [qiniuUpload],

  data () {
    return {
      rules: {
        share_msg: [
          { required: true, message: '请填写分享话术', trigger: 'change' }
        ]
      },
      posterImg: [
        {
          url: 'https://image.01lb.com.cn//uploads/wecom/21/0902/16305856436tfAq38m.png'
        }
      ],
      posterImgTips: '',
      defaultShare: '我想要领取红包，帮忙扫码添加海报内的企业微信就能帮我助力啦！'
    }
  },

  computed: {
    posterForm () {
      return this.$store.state.rede.poster
    }
  },

  watch: {
    posterForm: {
      handler (val) {
        if (val.img_url) {
          this.posterImg = [{ url: val.img_url }]
        }
      }
    }
  },

  methods: {

    fn_getWelWords (content) {
      this.fn_setPoster('share_msg', content)
    },
    // 上传背景海报
    async requestQiniu (opts) {
      this.posterImg = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.posterImg = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.fn_setPoster('img_url', path)
          },
          null,
          {
            types: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
        )
      }

      this.posterImgTips = ''
    },

    // 移除背景
    removePoster () {
      this.fn_setPoster('img_url', '')
      this.posterImg = []
      this.posterImgTips = '请上传海报图片'
    },

    // 字体大小提示
    formatTooltip (val) {
      return val + 'px'
    },

    // 设置昵称对齐
    fn_setNickNameAlign (type) {
      const _type = type || this.posterForm.nickname_align
      let _x = null
      if (type) {
        this.fn_setPoster('nickname_align', type)
      }
      if (_type === 'left') {
        this.fn_setPoster('nickname_offsetX', 0)
      } else if (_type === 'center') {
        _x = this.fn_caleCoord2(this.posterForm.nickname_x, 'x')
        this.fn_setPoster('nickname_offsetX', (_x + this.posterForm.nickname_size * 6 / 2).toFixed() - 2)
      } else if (_type === 'right') {
        _x = this.fn_caleCoord2(this.posterForm.nickname_x, 'x')
        this.fn_setPoster('nickname_offsetX', (_x + this.posterForm.nickname_size * 6).toFixed() - 2)
      }
    },

    // 计算375
    fn_caleCoord2 (num, type) {
      let _num = null
      if (type === 'x') {
        _num = Math.round((375 / 345) * num)
      } else if (type === 'y') {
        _num = Math.round((667 / 613.64) * num)
      }
      return _num
    },

    // 设置海报缓存
    fn_setPoster (type, data) {
      const POSTER = this.$store.state.rede.poster
      POSTER[type] = data
      this.$store.commit('_SET', {
        'rede.poster': POSTER
      })
    },

    // 表单验证
    verify () {
      if (this.posterImg.length === 0) {
        this.posterImgTips = '请上传海报图片'
        return false
      } else {
        this.posterImgTips = ''
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";

::v-deep .el-slider__button {
  width: 10px;
  height: 10px;
}

.edit-task__form {
  width: 100%;
}

::v-deep .el-radio__label {
  position: relative;
  top: 1px;
}

.upload {
  ::v-deep {
    .el-upload-list__item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-upload-list__item-thumbnail {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}
</style>
