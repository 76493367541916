<template>
  <div class="page-warp">
    <ly-table ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :pageSize="per_page" :total="total"
      @searchChange="searchChange" @sizeChange="handleSizeChange" @sortChange="handleSortChange"
      @currentChange="handleCurrentChange" @selectionChange="handleSelectionChange">
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button round type="primary" icon="icon-plus iconfont" @click="addActivity">创建活动</el-button>
      </template>
      <!-- 列插槽 -->
      <template #title_slot="{ row }">
        <div class="flex flex-start">
          <el-image :src="row.poster_url" lazy fit="cover" style="
              width: 36px;
              height: 62px;
              margin-right: 12px;
              border-radius: 4px;
            "></el-image>
          <span v-html="
            row.title.length > 8
              ? row.title.slice(0, 8) +
              '<br />' +
              row.title.slice(8, row.title.length)
              : row.title
          "></span>
        </div>
      </template>

      <template #steps_header_slot>
        <span style="margin-right: 4px">剩余库存</span>
        <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
          <div slot="content">
            活动库存为0时将自动暂停活动，活动暂停后，新添加用<br />户不再自动发送活动规则和海报，同时不作为本次参与<br />用户统计，也不统计上级邀请数量。您可以添加库存或<br />者结束活动。
          </div>
          <i class="iconfont icon-info-fill"></i>
        </el-tooltip>
      </template>
      <template #steps_slot="{ row }">
        <div v-for="(item, stepIndex) in row.steps" :key="stepIndex" :class="[item.remain === 0 ? 'q-danger' : '']">
          <template v-if="item.step === 1 && item.status === 1">一阶：{{ item.remain }}
            <span class="q-danger" v-if="item.reward_type === 6 && item.remain > item.type_remain">
              （兑换码：{{ item.type_remain }}）
            </span></template>
          <template v-else-if="item.step === 2 && item.status === 1">二阶：{{ item.remain }}
            <span class="q-danger" v-if="item.reward_type === 6 && item.remain > item.type_remain">
              （兑换码：{{ item.type_remain }}）
            </span></template>
          <template v-else-if="item.step === 3 && item.status === 1">三阶：{{ item.remain }}
            <span class="q-danger" v-if="item.reward_type === 6 && item.remain > item.type_remain">
              （兑换码：{{ item.type_remain }}）
            </span></template>
        </div>
      </template>

      <template #status_slot="{ row }">
        <template v-if="row.status === 0">
          <ly-status>未开启</ly-status>
        </template>
        <template v-else-if="row.status === 1">
          <ly-status type="success">进行中</ly-status>
        </template>
        <template v-else-if="row.status === 2">
          <el-tooltip class="item" effect="dark" placement="top-start" popper-class="q-tooltip">
            <div slot="content">
              <div>
                活动暂停中，新添加用户不再自动发送活动规则和海报，<br />同时不作为本次参与用户统计，也不统计上级邀请数量。<br />您可以添加库存或者结束活动。
              </div>
            </div>
            <ly-status type="warning">已暂停</ly-status>
          </el-tooltip>
        </template>
        <template v-else-if="row.status === 3">
          <ly-status>未开始</ly-status>
        </template>
        <template v-else>
          <ly-status>已结束</ly-status>
        </template>
      </template>

      <template #operation="{ row }">
        <ly-action :key="Date.now()">
          <el-button type="text" @click="jumgToAnalysis(row.id)">数据分析</el-button>
          <el-button type="text" @click="jumpToUsers(row.id)">参与用户</el-button>
          <el-button type="text" @click="jumpToReward(row.id)">奖品记录</el-button>
          <el-button type="text" icon="iconfont icon-send" @click="handleInvite(row.id)" :disabled="row.status !== 1">
            群发助手</el-button>
          <el-button type="text" icon="iconfont icon-Code" @click="fn_jumpToPop(row.id)">渠道推广</el-button>
          <el-divider></el-divider>
          <el-button type="text" icon="iconfont icon-edit" @click="editActivity(row.id)">修改</el-button>
          <el-button type="text" icon="iconfont icon-copy" @click="editActivity(row.id, 'copy')">复制</el-button>
          <el-button type="text" icon="iconfont icon-Code" @click="upadateActivity(row.title, row.id, 'start')"
            v-if="row.status === 2">开始</el-button>
          <el-button type="text" icon="iconfont icon-pause" @click="upadateActivity(row.title, row.id, 'stop')"
            v-if="row.status === 1">暂停</el-button>
          <el-button type="text" icon="iconfont icon-ending" @click="upadateActivity(row.title, row.id, 'end')"
            v-if="row.status === 1 || row.status === 2">结束</el-button>
          <el-button type="text" icon="iconfont icon-delete" @click="delActivity(row)">删除</el-button>
          <el-divider></el-divider>
          <el-button type="text" icon="iconfont icon-empty" @click="handlePosterClear(row.title, row.id)">清空海报缓存
          </el-button>
        </ly-action>
      </template>
    </ly-table>

    <!-- 群发助手 -->
    <el-dialog :visible.sync="inviterVisible" width="960px" title="群发助手" class="q-dialog inviter-dialog"
      @closed="fn_resetInviter">
      <div class="inviter-dialog__main">
        <el-scrollbar class="inviter-dialog__main-left">
          <el-form :model="inviterData" :rules="inviterRules" ref="inviterData" label-width="120px"
            style="flex: 1; padding-right: 16px">
            <el-form-item label="群发账号" required ref="wecom_user_ids" :error="wecom_user_idsTips">
              <ly-emp-select placeholder="选择群发账号" datafilter :showLength="3" ref="empSelectRef" @getEmpId="getEmpId">
              </ly-emp-select>
              <div class="flex flex-between" style="margin-top: 8px; line-height: 1">
                <div class="flex">
                  <el-switch v-model="inviterData.tag_open" :active-value="1" :inactive-value="0">
                  </el-switch><span class="q-info" style="margin-left: 12px; margin-right: 4px">更多筛选组合
                  </span>
                  <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
                    <div slot="content">
                      打开「更多筛选组合」后，将群发给「群发账号」下满足对应条件的好友用户
                    </div>
                    <i class="iconfont icon-info-fill1" style="color: #b4bbcc"></i>
                  </el-tooltip>
                </div>
                <div class="q-info">
                  将群发给<span class="q-primary"> {{ clientSum }} </span>个用户
                </div>
              </div>
              <div v-if="inviterData.tag_open === 1" style="margin-top: 8px">
                <tag-select-v2 @getTagId="fn_getFilterIds" title="通过标签筛选用户" :checked="inviterData.tags"></tag-select-v2>
              </div>
            </el-form-item>
            <el-form-item label="群发方式" prop="push_type">
              <el-radio-group v-model="inviterData.push_type" @change="fn_changePushType">
                <el-radio :label="1">手动推送</el-radio>
                <el-radio :label="2" v-if="hasCloudBean">自动推送</el-radio>
              </el-radio-group>

              <div class="is-primary flex flex-start text-small" v-if="inviterData.push_type === 1">
                <div style="flex: 1; line-height: 20px; color: #6e788a">
                  <div style="margin-bottom: 8px" v-if="hasCloudBean">
                    企微官方推送将使用
                    <b>1</b> 次「企微群发」次数，每个用户每月只能收到
                    <b>30</b> 次企业群发。
                    <span class="q-primary" style="cursor: pointer" @click="fn_changePushTypeByText(2)">选择“自动推送”突破限制
                      ></span>
                  </div>
                  <div>
                    消息推送完成后，需要对应的员工手动确认，确认后消息发送给对应用户。
                    <el-tooltip class="item" placement="top" popper-class="q-tooltip is-light"
                      style="border-radius: 16px">
                      <div slot="content">
                        <div style="margin: 24px 24px 16px; width: 777px">
                          <img src="https://image.01lb.com.cn//uploads/wecom/21/0416/1618577894N96zjbn3.png" width="777"
                            alt="" />
                        </div>
                      </div>
                      <span class="q-primary">员工如何确认消息发送？</span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <template v-if="inviterData.push_type === 2">
                <div style="
                    flex: 1;
                    line-height: 20px;
                    color: #6e788a;
                    font-size: 12px;
                  ">
                  <div style="margin-bottom: 8px">
                    1.员工登录云端后，将无群发消息次数限制，能够自动发送"奖品通知"和“助力提醒”。
                  </div>
                  <div style="margin-bottom: 8px">
                    2.
                    选择此方式，未登录云端的员工，将默认使用【手动推送】的方式，且不发送“助力提醒”。
                  </div>
                  <div>
                    3.员工登录云端后，如果员工在电脑登录企业微信，将会把云端登录挤掉线，造成无法自动发送内容，活动期间建议保持员工云端在线登录状态。
                  </div>
                </div>
                <div class="account">
                  <div class="account-header flex flex-between">
                    <el-radio-group v-model="accountType" @change="fn_changeAccountType">
                      <el-radio :label="1" :disabled="accountLoading">全部账号({{ accountListAll.length }})</el-radio>
                      <el-radio :label="2" :disabled="accountLoading">离线({{ accountListOut.length }})</el-radio>
                    </el-radio-group>
                    <el-button type="primary" size="ly-mini" :disabled="accountLoading"
                      v-lydebounce="['button', fn_getRobotList]">刷新</el-button>
                  </div>
                  <div class="account-list">
                    <el-table :data="accountList" style="width: 100%" max-height="250"
                      class="ly-table is-mini no-border v-middle" v-loading="accountLoading" empty-text="请选择群发账号">
                      <el-table-column prop="name, avatar" label="群发账号">
                        <template slot-scope="scope">
                          <div class="flex">
                            <img :src="scope.row.avatar" alt="" class="ly-table__main-avatar" />
                            <div>
                              <div class="ly-table__main-name">
                                {{ scope.row.name }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="name" label="云端登录状态">
                        <template slot-scope="scope">
                          <div :class="[!scope.row.status ? 'q-info' : '']">
                            <span :class="[
                              'q-circle',
                              scope.row.status === 1 ? 'success' : '',
                            ]"></span>{{ scope.row.status ? "在线" : "离线" }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="address" label="操作" width="100" class-name="operation-cell">
                        <template slot-scope="scope">
                          <el-button type="text" :disabled="scope.row.status === 1" @click="fn_jumpCloudPage">登录云端
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </template>
            </el-form-item>
            <el-form-item label="群发内容" prop="content">
              <ly-editor title="群发内容" id="prizeWords" :content="inviterData.content" :recoverContent="initHtml" emoji
                @getContent="fn_getContent"></ly-editor>
            </el-form-item>
            <el-form-item label="自定义链接" prop="title">
              <el-input v-model="inviterData.title" placeholder="请输入链接标题" maxlength="20" show-word-limit></el-input>
              <div class="flex" style="margin-top: 8px">
                <el-input type="textarea" resize="none" v-model="inviterData.desc" placeholder="请输入链接摘要"
                  class="inviterDesc"></el-input>
                <el-upload action="#" accept=".png, .jpg, .jpeg" :http-request="requestCover" :on-remove="removeCover"
                  list-type="picture-card" :limit="1" :file-list="inviterData.link_coverList" :class="[
                    'inviterDescImg',
                    inviterData.link_coverList.length === 0
                      ? ''
                      : 'upload-empty',
                  ]">
                  <div class="flex flex-column flex-center" style="line-height: 22px; height: 100%">
                    <i class="iconfont icon-plus"></i>
                    <span class="text q-info">链接封面</span>
                  </div>
                </el-upload>
              </div>
              <div class="q-info" style="line-height: 1.6; font-size: 12px; margin-top: 5px">
                建议尺寸500*500px，JPG、PNG、JPEG格式，图片小于2MB。
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round icon="iconfont icon-send-fill" @click="submitInvite('inviterData')"
                style="margin-right: 24px">立即群发</el-button>
            </el-form-item>
          </el-form>
        </el-scrollbar>
        <div class="inviter-dialog__main-right">
          <div class="mobile">
            <div class="mobile-topbar">
              <div>{{ $day().format("HH:mm") }}</div>
              <div class="flex">
                <i class="iconfont icon-a-CellularConnection"></i>
                <i class="iconfont icon-Wifi"></i>
                <i class="iconfont icon-Battery"></i>
              </div>
            </div>
            <div class="mobile-titlebar">
              <i class="el-icon-arrow-left"></i>
              <div class="flex flex-column">
                <span>张三</span>
                <span style="font-size: 12px">零一裂变（深圳）科技有限公司</span>
              </div>
              <i class="el-icon-more"></i>
            </div>
            <div class="mobile-container" style="max-height: 542px; overflow: auto">
              <div class="mobile-contact">
                <div class="mobile-contact__avatar">
                  <img src="../../../assets/images/avatar.jpg" alt="" />
                </div>
                <div class="mobile-contact__dialog">
                  <el-input type="textarea" v-model="inviterData.content" autosize resize="none" readonly></el-input>
                </div>
              </div>
              <div class="mobile-contact">
                <div class="mobile-contact__avatar">
                  <img src="../../../assets/images/avatar.jpg" alt="" />
                </div>
                <div class="mobile-contact__dialog">
                  <div class="link-preview">
                    <div class="ellipsis2 link-preview__title">
                      {{ inviterData.title }}
                    </div>
                    <div class="
                        flex flex-between flex-center
                        link-preview__content
                      ">
                      <div class="ellipsis2" style="flex: 1; font-size: 14px"
                        v-html="inviterData.desc.replace(/[\n]/g, '')"></div>
                      <div class="link-preview__cover">
                        <img :src="
                          inviterData.link_cover
                            ? inviterData.link_cover
                            : defaultCover
                        " alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tagSelectV2 from '@/components/wecomTags'
import lyEditor from '@/components/lyEditor'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
import mx_role from '@/components/mixins/role'
import mapTag from '@/common/mapTag'
import lyTable from '@/components/mixins/ly-table.js'
import { taskList } from './http'
import util from '@/common/util.js'
export default {
  components: {
    lyEditor,
    tagSelectV2
  },
  mixins: [mx_role, qiniuUpload, lyTable],
  data () {
    var ruleVerify = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error('请填写群发内容'))
      } else if (value.length > 1000) {
        callback(new Error('请限制在1000个字符以内'))
      } else {
        callback()
      }
    }
    return {
      COMM_HTTP: taskList,
      activityOptions: [{
        value: null,
        label: '全部'
      }, {
        value: '1',
        label: '进行中'
      }, {
        value: '2',
        label: '已暂停'
      }, {
        value: '4',
        label: '已结束'
      }],
      dates: [],
      inviterVisible: false,
      activity_id: null,
      inviterData: {
        activity_id: null,
        title: '点击这里，完成任务领取奖品吧👇',
        link_coverList: [],
        tag_open: 0,
        tags: '',
        push_type: 1,
        link_cover: '',
        desc: '快来参加活动吧',
        content: '你终于找到我了，完成助力任务即可领取奖品~\n\n邀请好友领取奖品：\n1. 邀请2人添加，即可获得a大礼包\n2. 邀请5人添加，即可获得b大礼包\n3. 邀请8人添加，即可获得c大礼包\n\n点击链接生成海报分享好友（请不要直接转发活动链接给好友，无法成功记录数据的哦）',
        wecom_user_ids: null
      },
      defaultCover: require('@/assets/images/task_gift.png'),
      inviterRules: {
        title: [
          { required: true, message: '请输入链接标题', trigger: 'change' }
        ],
        content: [
          { validator: ruleVerify, required: true, trigger: 'change' }
        ],
        push_type: [
          {
            required: true
          }
        ]
      },
      wecom_user_idsTips: '',
      initHtml: '你终于找到我了，完成助力任务即可领取奖品~\n\n邀请好友领取奖品：\n1. 邀请2人添加，即可获得a大礼包\n2. 邀请5人添加，即可获得b大礼包\n3. 邀请8人添加，即可获得c大礼包\n\n点击链接生成海报分享好友（请不要直接转发活动链接给好友，无法成功记录数据的哦）',
      editor: null,
      editHtml: '',
      editCount: 0,
      checkProp: null,
      codeCvs: null,
      codeImg: null,
      msgBox: null,
      tagsReady: false,
      clientSum: 0,
      accountType: 1,
      accountLoading: true,
      accountList: [],
      accountListAll: [],
      accountListOut: []
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 290,
        column: [
          {
            label: '活动名称',
            prop: 'title',
            slot: 'title_slot',
            search: true,
            sort: 1,
            width: 200,
            placeholder: '请输入活动名称'
          },
          {
            label: '新增用户数',
            prop: 'add_user_count',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '删除用户数',
            prop: 'del_user_count',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '净增用户数',
            prop: 'net_user_count',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '剩余库存',
            prop: 'steps',
            slot: 'steps_slot',
            headerSlot: 'steps_header_slot',
            columnProps: {
              'min-width': 160
            }
          },
          {
            label: '结束日期',
            prop: 'end_at',
            search: true,
            key: ['begin_at', 'end_at'],
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            sort: 3,
            sortable: true,
            formatter ({ end_at }) {
              return util.timeTofmt(end_at, 'yyyy-MM-dd hh:mm')
            },
            pickerOptions: {},
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '活动状态',
            prop: 'status',
            slot: 'status_slot',
            search: true,
            type: 'select',
            source: this.activityOptions,
            default: null,
            sort: 2,
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          }
        ]
      }
    },
    hasCloudBean () {
      return this.$store.state.permission.hasCloudBean
    }
  },
  methods: {

    // 群发内容
    fn_getContent (val) {
      this.inviterData.content = val
    },

    // 计算字数
    changeRule (val) {
      this.editCount = val.length
    },

    // 群发助手
    handleInvite (id) {
      this.inviterVisible = true
      this.$nextTick(() => {
        this.inviterData.activity_id = id
      })
    },

    // 重置群发助手表单
    fn_resetInviter () {
      this.inviterData = {
        activity_id: null,
        title: '点击这里，完成任务领取奖品吧👇',
        link_coverList: [],
        tag_open: 0,
        tags: '',
        push_type: 1,
        link_cover: '',
        desc: '快来参加活动吧',
        content: this.initHtml,
        wecom_user_ids: null
      }
      this.clientSum = 0
      this.accountType = 1
      this.accountList = []
      this.accountListAll = []
      this.accountListOut = []
      this.$refs.empSelectRef.selected = []
    },

    // 选中的标签
    fn_getFilterIds (tags) {
      if (tags) {
        this.inviterData.tags = tags.join(',')
      } else {
        this.inviterData.tags = ''
      }
      this.fn_countCustomer()
    },

    // 用户数量
    async fn_countCustomer () {
      // 如果未选择员工，不查询
      if (!this.inviterData.wecom_user_ids) {
        this.clientSum = 0
        return false
      }

      const data = await this.axios.get('customerSum', {
        params: {
          user_ids: this.inviterData.wecom_user_ids,
          tag_ids: this.inviterData.tags
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.clientSum = data
    },

    // 突破限制
    fn_changePushTypeByText (val) {
      this.inviterData.push_type = 2
      this.fn_changePushType(val)
    },

    // 群发方式
    fn_changePushType (val) {
      if (val === 2) {
        this.fn_getRobotList()
      }
    },

    // 云端列表
    async fn_getRobotList () {
      this.accountLoading = true

      const _ids = this.inviterData.wecom_user_ids

      if (!_ids || (_ids && _ids.length === 0)) {
        this.accountLoading = false
        this.accountList = []
        this.accountListAll = []
        this.accountListOut = []
        return false
      }
      const data = await this.axios.get('robotListById', {
        params: {
          user_ids: _ids
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.accountLoading = false
        this.accountList = []
        this.accountListAll = []
        this.accountListOut = []
        return false
      }

      this.accountList = data.list
      this.accountListAll = data.list
      this.accountListOut = data.list.filter(item => {
        return item.status === 0
      })

      setTimeout(() => {
        this.accountLoading = false
      }, 300)
    },

    // 跳转云端
    fn_jumpCloudPage () {
      const routeData = this.$router.resolve({
        path: '/cloudService/account/list'
      })
      window.open(routeData.href, '_blank')
    },

    // 查看离线列表
    fn_changeAccountType (val) {
      if (val === 2) {
        this.$nextTick(() => {
          this.accountList = this.accountListOut
        })
      } else if (val === 1) {
        this.$nextTick(() => {
          this.accountList = this.accountListAll
        })
      }
    },

    // 上传图片
    async requestCover (opts) {
      this.inviterData.link_coverList = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.inviterData.link_coverList = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.inviterData.link_cover = path
          },
          null,
          {
            type: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
        )
      }
    },

    // 删除图片
    removeCover () {
      this.inviterData.link_cover = ''
      this.inviterData.link_coverList = []
    },

    // 通知员工发送
    async submitInvite (formName) {
      let inviteValid = false
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          inviteValid = true
        } else {
          inviteValid = false
        }
      })

      if (!this.inviterData.wecom_user_ids) {
        inviteValid = false
        this.wecom_user_idsTips = '请选择员工'

        this.$refs.wecom_user_ids.$el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      }

      if (inviteValid) {
        const _iform = this.util.extend(true, {}, this.inviterData)
        _iform.content = mapTag(_iform.content, false)
        const _form = {
          activity_id: _iform.activity_id,
          content: _iform.content,
          tag_open: _iform.tag_open,
          tags: _iform.tags,
          push_type: _iform.push_type,
          link_info: {
            desc: _iform.desc.replace(/[\n]/g, ''),
            title: _iform.title,
            img_url: _iform.link_cover
          },
          wecom_user_ids: _iform.wecom_user_ids
        }
        const data = await this.axios.post('inviteAccount', _form)
        if ((data && data.code) || (data && data.code === 0)) {
          this.$message.error(data.msg)
          return
        }
        this.$message.success('邀请通知已发送')
        this.inviterVisible = false
      }
    },

    // 选择员工
    getEmpId (ids) {
      this.inviterData.wecom_user_ids = ids ? ids.join(',') : ''
      this.wecom_user_idsTips = ids ? '' : '请选择员工'
      this.fn_countCustomer()
    },

    // 创建活动
    addActivity () {
      this.fn_initDefault()

      this.$router.push({
        path: 'edit'
      })
    },

    // 初始化活动数据
    fn_initDefault () {
      this.$store.commit('_SET', {
        'task.isEdit': false
      })
      this.$store.commit('_SET', {
        'task.status': null
      })
      this.$store.commit('_SET', {
        'task.basic': null
      })
      this.$store.commit('_SET', {
        'task.poster': null
      })
      this.$store.commit('_SET', {
        'task.step_first': {
          id: 0,
          status: 1,
          target_count: 2,
          reward_title: '',
          reward_desc: '',
          reward_coverList: [],
          reward_cover: '',
          unreal: 1000,
          reward_type: 4,
          reward_detail: '',
          reward_detail_img: '',
          reward_detail_imgList: [],
          total: 9999,
          remain: 9999,
          reward_type_id: null,
          codeRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
          tag_open: false,
          finish_task_tags: ''
        }
      })
      this.$store.commit('_SET', {
        'task.step_second': null
      })
      this.$store.commit('_SET', {
        'task.step_third': null
      })
      this.$store.commit('_SET', {
        'task.stepTab': 'first'
      })
      this.$store.commit('_SET', {
        'task.notice': null
      })
      this.$store.commit('_SET', {
        'task.more': null
      })

      this.$store.commit('_SET', {
        'task.disableSecond': false
      })

      this.$store.commit('_SET', {
        'task.disableThird': false
      })
    },

    // 编辑活动
    async editActivity (id, type) {
      //  跳转编辑页
      this.$router.push({
        path: 'edit',
        query: {
          id: id,
          type
        }
      })
    },

    // 暂停活动
    upadateActivity (title, id, type) {
      let _text = ''
      let _status = null
      switch (type) {
        case 'start':
          _text = '开始'
          _status = 1
          break
        case 'stop':
          _text = '暂停'
          _status = 2
          break
        case 'end':
          _text = '结束'
          _status = 4
          break
      }

      this.$lyConfirm({
        title: `您确定要${_text}${title}吗?`,
        text: ''
      }).then(async () => {
        if (_status === 1) {
          const data = await this.axios.put('updateStatusStart', { id: id, status: _status })
          if ((data && data.code) || (data && data.code === 0)) {
            this.$message.error(data.msg)
            return
          }
          this.$message.success(`已${_text}${title}`)
          this.queryData()
        } else if (_status === 2) {
          const data = await this.axios.put('updateStatusPause', { id: id, status: _status })
          if ((data && data.code) || (data && data.code === 0)) {
            this.$message.error(data.msg)
            return
          }
          this.$message.success(`已${_text}${title}`)
          this.queryData()
        } else if (_status === 4) {
          const data = await this.axios.put('updateStatusEnd', { id: id, status: _status })
          if ((data && data.code) || (data && data.code === 0)) {
            this.$message.error(data.msg)
            return
          }
          this.$message.success(`已${_text}${title}`)
          this.queryData()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: `已取消${_text}活动`
        })
      })
    },

    // 清除海报缓存
    async handlePosterClear (title, id) {
      const data = await this.axios.post('taskClearCache', {
        activity_id: id
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return
      }
      this.$message.success(`已清除${title}海报缓存`)
    },

    // 删除活动确认
    delActivity (data) {
      if (data.status !== 4) {
        this.$message.warning('只允许删除已结束的活动')
      } else {
        this.$lyConfirm({
          title: '确认要删除这个活动吗？',
          text: '活动删除后将无法找回，您确定删除吗?'
        }).then(async () => {
          this.delActivityFn(data.title, data.id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },

    // 删除活动
    async delActivityFn (title, id) {
      const data = await this.axios.delete('initTasks', {
        params: {
          id: id
        }
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return
      }
      this.$message.success(`已删除${title}`)
      this.queryData()
    },

    // 跳转参与用户
    jumgToAnalysis (id) {
      this.$router.push({ path: 'analysis', query: { id } })
    },

    // 跳转参与用户
    jumpToUsers (id) {
      this.$router.push({ path: 'users', query: { id } })
    },

    // 跳转奖品记录
    jumpToReward (id) {
      this.$router.push({ path: 'rewards', query: { id } })
    },

    // 跳转渠道推广
    fn_jumpToPop (id) {
      this.$router.push({ path: 'popularize', query: { id } })
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";

.inviterDesc {
  margin-right: 8px;

  ::v-deep {
    .el-textarea__inner {
      height: 104px;
    }

    .el-upload-list__item {
      margin: 0;
    }
  }
}

.act-name {
  ::v-deep {
    .el-image {
      margin-right: 12px;
    }

    .el-image__inner {
      width: 36px;
      height: 64px;
      border-radius: 4px;
    }
  }
}

.inviter-dialog {
  ::v-deep {
    .el-dialog {
      margin-top: 10vh !important;
    }

    .el-dialog__body {
      position: relative;
    }
  }

  .el-table {
    line-height: 1.6;
  }

  &__main {
    height: 680px;
    padding-right: 345px + 32px;

    &-left {
      height: 100%;
    }

    &-right {
      position: absolute;
      right: 18px;
      top: 0;
      width: 345px;
    }
  }
}

.upload-empty {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}

.rules {
  border-radius: 8px;
  border: 1px solid #eaebf2;
  overflow: hidden;

  &-header {
    background-color: #f7f8fc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #dfe1e8;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-footer {
    text-align: right;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);
  }

  &-inset {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;
    background: #f2f5fe;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: $--transition;
    font-size: 12px;

    &:hover {
      background-color: $--color-primary;
      color: #fff;
    }

    &.default {
      background-color: transparent;
      color: $--color-text-regular;
      padding: 0;

      &:hover {
        color: $--color-primary;
      }
    }

    &+& {
      margin-left: 12px;
    }
  }

  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 220px !important;
  }
}

.link-preview {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;

  &__content {
    align-items: flex-start;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__cover {
    width: 48px;
    height: 48px;
    font-size: 48px;
    line-height: 1;
    margin-left: 8px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}

#codeCanvas {
  position: absolute;
  left: -99999px;
  z-index: -1;
}

.filter-tag {
  padding: 14px 20px;
  background: #f7f8fc;
  line-height: initial;
}

.account {
  margin-top: 20px;
  border: 1px solid #eaebf2;
  border-radius: 8px;
  overflow: hidden;

  &-header {
    height: 44px;
    padding: 0 24px;
  }
}

.ly-table__main-time {
  text-align: left;
}

.inviterDescImg {
  height: 104px;
}
</style>
