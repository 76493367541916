<!--
 * @Author: zhan
 * @Date: 2022-08-09 14:51:49
 * @LastEditTime: 2022-08-29 11:23:30
 * @LastEditors: zhan
-->
<template>
  <ly-form ref="lyFormRef" class="act-form" :formObj="formObj" :form="formData" :rules="rules" @setData="fn_setData"
    v-loading="cardLoading">

    <template #poster_url_slot>
      <div class="flex">
        <ly-upload :img="formData.poster_url" @getImg="fn_getPoster" tipText="上传海报"></ly-upload>
        <div class="q-info text-small act-form__info" style="flex: 1">
          海报设计须知：<br />
          (1) 建议图片尺寸：750px*1334px，分辨率72<br />
          (2) 支持JPG、PNG格式，图片大小2M以内 <br />
          (3) 开启【用户头像】【用户昵称】，可在预览区域内移动展示位置。
        </div>
      </div>
    </template>

    <template #avatar_status_slot>
      <div class="flex" style="height: 36px; user-select: none;">
        <el-switch v-model="formData.avatar_status" :active-value="1" :inactive-value="0" style="margin-right: 24px">
        </el-switch>
        <el-radio-group v-if="formData.avatar_status === 1" v-model="formData.avatar_sharp"
          style="position: relative; top: 1px">
          <el-radio :label="2">圆形</el-radio>
          <el-radio :label="1">方形</el-radio>
        </el-radio-group>
      </div>
    </template>

    <template #nickname_status_slot>
      <div class="flex" style="height: 36px; user-select: none;">
        <el-switch v-model="formData.nickname_status" :active-value="1" :inactive-value="0" style="margin-right: 24px">
        </el-switch>
        <template v-if="formData.nickname_status">
          <span style="display: inline-flex; margin-right: 24px">
            颜色：<el-color-picker v-model="formData.nickname_color"></el-color-picker>
          </span>
          <div style="width: 65px">
            <el-slider v-model="formData.nickname_size" :min="10" :max="36" :format-tooltip="formatTooltip"
              style="flex: 1"></el-slider>
          </div>
          <div style="margin: 0 24px 0 8px">{{ formData.nickname_size }}px</div>
          <div>
            <el-button-group class="nickname-align__btn">
              <el-button size="small" :type="formData.nickname_align === 'left' ? 'primary' : ''"
                @click="formData.nickname_align = 'left'">左对齐</el-button>
              <el-button size="small" :type="formData.nickname_align === 'center' ? 'primary' : ''"
                @click="formData.nickname_align = 'center'">居中</el-button>
              <el-button size="small" :type="formData.nickname_align === 'right' ? 'primary' : ''"
                @click="formData.nickname_align = 'right'">右对齐</el-button>
            </el-button-group>
          </div>
        </template>
      </div>
    </template>

  </ly-form>
</template>

<script>
export default {
  data () {
    return {
      cardLoading: true,
      formData: {
        poster_url: '',
        avatar_status: null,
        avatar_sharp: null,
        nickname_status: null,
        nickname_color: '',
        nickname_size: null,
        nickname_align: ''
      },
      rules: {
        poster_url: [
          { required: true, message: '请上传海报', trigger: 'change' }
        ]
      }
    }
  },

  computed: {
    formObj () {
      return {
        labelWidth: '160px',
        item: [
          {
            label: '上传海报',
            prop: 'poster_url',
            itemSlot: 'poster_url_slot'
          },
          {
            label: '用户头像',
            itemSlot: 'avatar_status_slot'
          },
          {
            label: '用户昵称',
            itemSlot: 'nickname_status_slot'
          }
        ]
      }
    }
  },

  watch: {
    'formData.poster_url' (val) {
      this.fn_setStore('poster_url', val)
    },
    'formData.avatar_status' (val) {
      this.fn_setStore('avatar_status', val)
    },
    'formData.avatar_sharp' (val) {
      this.fn_setStore('avatar_sharp', val)
    },
    'formData.nickname_status' (val) {
      this.fn_setStore('nickname_status', val)
    },
    'formData.nickname_size' (val) {
      this.fn_setStore('nickname_size', val)
    },
    'formData.nickname_color' (val) {
      this.fn_setStore('nickname_color', val)
    },
    'formData.nickname_align' (val) {
      this.fn_setStore('nickname_align', val)
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        const {
          avatar_status,
          avatar_sharp,
          nickname_status,
          nickname_size,
          nickname_color,
          nickname_align,
          avatar_x,
          avatar_y,
          avatar_width,
          nickname_x,
          nickname_y,
          nickname_offsetX,
          qrcode_x,
          qrcode_y,
          qrcode_width
        } = JSON.parse(data.poster_conf)

        const form = {
          poster_url: data.poster_url,
          avatar_status,
          avatar_sharp,
          nickname_status,
          nickname_color,
          nickname_size,
          nickname_align,
          avatar_x: this.fn_calcCoordinatesBack(avatar_x, 'x'),
          avatar_y: this.fn_calcCoordinatesBack(avatar_y, 'y'),
          avatar_width: this.fn_calcCoordinatesBack(avatar_width, 'x'),
          avatar_height: avatar_width,
          nickname_x: this.fn_calcCoordinatesBack(nickname_x, 'x'),
          nickname_y: this.fn_calcCoordinatesBack(nickname_y, 'y'),
          qrcode_x: this.fn_calcCoordinatesBack(qrcode_x, 'x'),
          qrcode_y: this.fn_calcCoordinatesBack(qrcode_y, 'y'),
          qrcode_width: this.fn_calcCoordinatesBack(qrcode_width, 'x'),
          qrcode_height: qrcode_width,
          nickname_offsetX
        }

        this.formData = form
      }

      this.fn_init(data)

      this.$nextTick(() => {
        this.cardLoading = false
      })
    },

    // 初始化默认值
    fn_init (data) {
      if (!data) {
        this.formData = {
          poster_url: '',
          avatar_status: 1,
          avatar_sharp: 2,
          nickname_status: 1,
          nickname_color: '#000000',
          nickname_size: 12,
          nickname_align: 'left',
          avatar_x: 30,
          avatar_y: 40,
          avatar_width: 50,
          avatar_height: 50,
          nickname_x: 90,
          nickname_y: 55,
          nickname_offsetX: 0,
          qrcode_status: 1,
          qrcode_x: 107.5,
          qrcode_y: 427,
          qrcode_width: 120,
          qrcode_height: 120
        }
      }

      for (const i in this.formData) {
        this.fn_setStore(i, this.formData[i])
      }
    },

    // 计算预览框宽度为345时的坐标
    fn_calcCoordinatesBack (num, type) {
      let _num = null
      if (type === 'x') {
        _num = (num * (345 / 375)).toFixed()
      } else if (type === 'y') {
        _num = (num * (613.64 / 667)).toFixed()
      }
      return +_num
    },

    // 海报设置回调
    fn_getPoster (img) {
      this.fn_setData(img, 'poster_url')
    },

    fn_setData (val, name) {
      this.formData[name] = val
      this.$refs.lyFormRef.verify(name)
    },

    // 字体大小提示
    formatTooltip (val) {
      return val + 'px'
    },

    // 345px 转 375px
    fn_caleCoord2 (num, type) {
      let _num = null
      if (type === 'x') {
        _num = Math.round(375 / 345 * num)
      } else if (type === 'y') {
        _num = Math.round(667 / 613.64 * num)
      }
      return _num
    },

    fn_setStore (name, val) {
      this.$store.commit('reserveTask/SET_DATA', {
        name,
        val
      })
    },

    async verify (draft) {
      let formValid = true
      if (!draft) {
        formValid = await this.$refs.lyFormRef.verify()
      }

      if (!formValid) return false

      const poster_url = this.formData.poster_url

      // 合并存储的头像昵称等配置数据
      const poster_conf = this.util.extend(true, {}, this.$store.getters['reserveTask/GET_POSTER_INFO'])

      // 像素处理，345 -> 375
      poster_conf.avatar_x = this.fn_caleCoord2(poster_conf.avatar_x, 'x')
      poster_conf.avatar_y = this.fn_caleCoord2(poster_conf.avatar_y, 'y')
      poster_conf.avatar_width = this.fn_caleCoord2(poster_conf.avatar_width, 'x')
      poster_conf.avatar_height = poster_conf.avatar_width
      poster_conf.nickname_x = this.fn_caleCoord2(poster_conf.nickname_x, 'x')
      poster_conf.nickname_y = this.fn_caleCoord2(poster_conf.nickname_y, 'y')
      poster_conf.qrcode_x = this.fn_caleCoord2(poster_conf.qrcode_x, 'x')
      poster_conf.qrcode_y = this.fn_caleCoord2(poster_conf.qrcode_y, 'y')
      poster_conf.qrcode_width = this.fn_caleCoord2(poster_conf.qrcode_width, 'x')
      poster_conf.qrcode_height = poster_conf.qrcode_width
      console.log('poster_conf', poster_conf)

      return {
        poster_conf,
        poster_url
      }
    }
  }
}
</script>
