<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-14 18:20:49
 * @LastEditTime: 2021-12-30 14:31:50
-->
<template>
  <ly-table
    :data="tableData"
    :option="tableOpt"
    @searchChange="searchChange"
  >
    <template #name_slot="{ row }">
      <div class="flex flex-start">
        <img
          v-if="row.avatar"
          :src="row.avatar"
          alt=""
          class="ly-table__main-avatar"
        />
        <img v-else :src="defaultAvatar" alt="" class="ly-table__main-avatar" />
        <div>
          {{ row.name }}
        </div>
      </div>
    </template>
    <template #status_slot="{ row }">
      <ly-status type="info" v-if="row.status === 0 || row.status === 2"
        >离线</ly-status
      >
      <ly-status type="success" v-if="row.status === 1">在线</ly-status>
    </template>
    <template #operation="{ row }">
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
        popper-class="q-tooltip"
        :disabled="row.status === 1"
        content="当前账号已离线，不可操作"
      >
        <span>
          <el-button
            type="text"
            @click="fn_unFocus(row)"
            :disabled="row.status === 0 || row.status === 2"
            >取消托管</el-button
          ></span
        >
      </el-tooltip>
    </template>
  </ly-table>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { account } from '../http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: account,
      defaultAvatar: require('@/assets/images/default_avatar.png')
    }
  },
  computed: {
    groupId () {
      return this.$route.query.id
    },
    tableOpt () {
      return {
        operationWidth: 120,
        layout: 'hide',
        column: [
          {
            label: '云端账号',
            prop: 'name',
            slot: 'name_slot'
          },
          {
            label: '登录状态',
            prop: 'status',
            slot: 'status_slot'
          }
        ]
      }
    }
  },
  methods: {
    // 取消托管
    fn_unFocus ({ id }) {
      this.$lyConfirm({
        title: '是否取消托管本群？',
        text: '取消后，当前账号将无法对群进行数据统计或者群发等批量操作。',
        confirmButtonText: '确定'
      }).then(async () => {
        const data = await this.COMM_HTTP.unFocus({
          group_ids: [+this.groupId],
          robot_id: +id,
          type: 1
        })

        if ((data && data.code) || (data && data.code === 0)) {
          this.$message.error(data.msg)
          return false
        }

        this.fn_getRobot()
      }).catch(() => { })
    },
    formatterParams (params) {
      return {
        ...params,
        group_id: this.groupId
      }
    },
    async fn_getRobot () {
      const data = await this.COMM_HTTP.reqQuery({
        page: 1,
        per_page: 10,
        group_id: this.groupId
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.tableData = data.list
    }
  }
}
</script>
