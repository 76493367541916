var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-card" }, [
    _c("div", { staticClass: "edit-card__header" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _c(
      "div",
      { staticClass: "edit-card__content" },
      [
        _c(
          "el-form",
          {
            ref: "posterFormRef",
            staticClass: "edit-task__form",
            attrs: {
              model: _vm.posterForm,
              rules: _vm.rules,
              "label-width": "150px",
              size: "medium"
            }
          },
          [
            _c(
              "el-form-item",
              {
                ref: "poster",
                attrs: {
                  label: "上传海报",
                  required: "",
                  error: _vm.posterImgTips
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "uploadFile",
                        staticClass: "upload",
                        staticStyle: { height: "104px" },
                        attrs: {
                          action: "#",
                          accept: ".png, .jpg, .jpeg",
                          "http-request": _vm.requestQiniu,
                          "on-remove": _vm.removePoster,
                          "list-type": "picture-card",
                          limit: 1,
                          "file-list": _vm.posterImg
                        }
                      },
                      [
                        _c("div", { staticClass: "default" }, [
                          _c("i", { staticClass: "iconfont icon-picture" }),
                          _c("span", [_vm._v("上传图片")])
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "q-info text-small",
                        staticStyle: {
                          "line-height": "22px",
                          "margin-left": "24px"
                        }
                      },
                      [
                        _c("span", { staticStyle: { color: "#454d5b" } }, [
                          _vm._v("海报设计须知：")
                        ]),
                        _c("br"),
                        _vm._v(" (1) 建议图片尺寸：750px*1334px，分辨率72"),
                        _c("br"),
                        _vm._v(" (2) 支持JPG、PNG格式，图片大小2M以内"),
                        _c("br"),
                        _vm._v(
                          " (3) 开启【用户头像】【用户昵称】【二维码】，可在预览区域内移动展示位置。 "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "用户头像", prop: "avatar_sharp" } },
              [
                _c(
                  "div",
                  { staticClass: "flex", staticStyle: { height: "36px" } },
                  [
                    _c("el-switch", {
                      staticStyle: { "margin-right": "24px" },
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.fn_setPoster("avatar_status", $event)
                        }
                      },
                      model: {
                        value: _vm.posterForm.avatar_status,
                        callback: function($$v) {
                          _vm.$set(_vm.posterForm, "avatar_status", $$v)
                        },
                        expression: "posterForm.avatar_status"
                      }
                    }),
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { position: "relative", top: "1px" },
                        on: {
                          change: function($event) {
                            return _vm.fn_setPoster("avatar_sharp", $event)
                          }
                        },
                        model: {
                          value: _vm.posterForm.avatar_sharp,
                          callback: function($$v) {
                            _vm.$set(_vm.posterForm, "avatar_sharp", $$v)
                          },
                          expression: "posterForm.avatar_sharp"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("圆形")
                        ]),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("方形")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "用户昵称", prop: "nickname_status" } },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("el-switch", {
                      staticStyle: { "margin-right": "24px" },
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.fn_setPoster("nickname_status", $event)
                        }
                      },
                      model: {
                        value: _vm.posterForm.nickname_status,
                        callback: function($$v) {
                          _vm.$set(_vm.posterForm, "nickname_status", $$v)
                        },
                        expression: "posterForm.nickname_status"
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-flex",
                          "margin-right": "24px"
                        }
                      },
                      [
                        _vm._v(" 颜色："),
                        _c("el-color-picker", {
                          on: {
                            change: function($event) {
                              return _vm.fn_setPoster("nickname_color", $event)
                            }
                          },
                          model: {
                            value: _vm.posterForm.nickname_color,
                            callback: function($$v) {
                              _vm.$set(_vm.posterForm, "nickname_color", $$v)
                            },
                            expression: "posterForm.nickname_color"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "65px" } },
                      [
                        _c("el-slider", {
                          staticStyle: { flex: "1" },
                          attrs: {
                            min: 10,
                            max: 36,
                            "format-tooltip": _vm.formatTooltip
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setNickNameAlign()
                            }
                          },
                          model: {
                            value: _vm.posterForm.nickname_size,
                            callback: function($$v) {
                              _vm.$set(_vm.posterForm, "nickname_size", $$v)
                            },
                            expression: "posterForm.nickname_size"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { margin: "0 24px 0 8px" } }, [
                      _vm._v(" " + _vm._s(_vm.posterForm.nickname_size) + "px ")
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button-group",
                          { staticClass: "nickname-align__btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type:
                                    _vm.posterForm.nickname_align === "left"
                                      ? "primary"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_setNickNameAlign("left")
                                  }
                                }
                              },
                              [_vm._v("左对齐")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type:
                                    _vm.posterForm.nickname_align === "center"
                                      ? "primary"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_setNickNameAlign("center")
                                  }
                                }
                              },
                              [_vm._v("居中")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type:
                                    _vm.posterForm.nickname_align === "right"
                                      ? "primary"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_setNickNameAlign("right")
                                  }
                                }
                              },
                              [_vm._v("右对齐")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "分享话术", prop: "share_msg" } },
              [
                _c("ly-editor", {
                  staticStyle: { width: "600px" },
                  attrs: {
                    title: "分享话术",
                    id: "redeShareMsg",
                    content: _vm.posterForm.share_msg,
                    recoverContent: _vm.defaultShare
                  },
                  on: { getContent: _vm.fn_getWelWords }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }