var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ly-table page-warp" },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.searchForm, size: "medium" } },
            [
              _c(
                "el-form-item",
                { staticStyle: { "margin-left": "0" } },
                [
                  _c(
                    "el-button-group",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnState === "day" ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.getAnalyse("day")
                            }
                          }
                        },
                        [_vm._v("昨日")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnState === "week" ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.getAnalyse("week")
                            }
                          }
                        },
                        [_vm._v("近7日")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnState === "month" ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.getAnalyse("month")
                            }
                          }
                        },
                        [_vm._v("近30日")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自定义：" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "260px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "~",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions
                    },
                    on: { change: _vm.changeTime },
                    model: {
                      value: _vm.dates,
                      callback: function($$v) {
                        _vm.dates = $$v
                      },
                      expression: "dates"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择员工：" } },
                [
                  _c("ly-emp-select", {
                    attrs: {
                      placeholder: "选择员工",
                      showLength: 1,
                      checkList: _vm.checkProp
                    },
                    on: { getEmpId: _vm.getEmpId }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class: _vm.cardName === "chat_cnt" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("chat_cnt")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon primary" }, [
                  _c("i", { staticClass: "iconfont icon-icon-chat" })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "temp" }, [
                    _c("div", { staticClass: "num" }, [
                      _c("span", { staticClass: "all" }, [
                        _vm._v(_vm._s(_vm.summary.chat.curr))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.summary.chat.prev) + " ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "rate" },
                      [
                        _vm.summary.chat.rate === 0
                          ? [
                              _c("span", { staticClass: "rate-num q-info" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.summary.chat.rate) + "% "
                                )
                              ])
                            ]
                          : _vm.summary.chat.rate.toString().indexOf("-") === -1
                          ? [
                              _c(
                                "span",
                                { staticClass: "rate-num q-success" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s("+" + _vm.summary.chat.rate) +
                                      "% "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c("span", { staticClass: "rate-num q-danger" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.summary.chat.rate) + "% "
                                )
                              ])
                            ]
                      ],
                      2
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 聊天总数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "统计周期内，员工有发送过消息的单聊的数量。",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-info-fill" })]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class: _vm.cardName === "message_cnt" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("message_cnt")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon warning" }, [
                  _c("i", { staticClass: "iconfont icon-send-fill" })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "temp" }, [
                    _c("div", { staticClass: "num" }, [
                      _c("span", { staticClass: "all" }, [
                        _vm._v(_vm._s(_vm.summary.msg.curr))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.summary.msg.prev) + " ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "rate" },
                      [
                        _vm.summary.msg.rate === 0
                          ? [
                              _c("span", { staticClass: "rate-num q-info" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.summary.msg.rate) + "% "
                                )
                              ])
                            ]
                          : _vm.summary.msg.rate.toString().indexOf("-") === -1
                          ? [
                              _c(
                                "span",
                                { staticClass: "rate-num q-success" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s("+" + _vm.summary.msg.rate) +
                                      "% "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c("span", { staticClass: "rate-num q-danger" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.summary.msg.rate) + "% "
                                )
                              ])
                            ]
                      ],
                      2
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 发送消息数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "统计周期内，员工在单聊中发送的消息总数。",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-info-fill" })]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class: _vm.cardName === "reply_rate" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("reply_rate")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon success" }, [
                  _c("i", { staticClass: "iconfont icon-icon-replied" })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "temp" }, [
                    _c("div", { staticClass: "num" }, [
                      _c("span", { staticClass: "all" }, [
                        _vm._v(_vm._s(_vm.summary.reply_rate.curr) + "%")
                      ]),
                      _vm._v(" / " + _vm._s(_vm.summary.reply_rate.prev) + "% ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "rate" },
                      [
                        _vm.summary.reply_rate.rate === 0
                          ? [
                              _c("span", { staticClass: "rate-num q-info" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.summary.reply_rate.rate) +
                                    "% "
                                )
                              ])
                            ]
                          : _vm.summary.reply_rate.rate
                              .toString()
                              .indexOf("-") === -1
                          ? [
                              _c(
                                "span",
                                { staticClass: "rate-num q-success" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "+" + _vm.summary.reply_rate.rate
                                      ) +
                                      "% "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c("span", { staticClass: "rate-num q-danger" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.summary.reply_rate.rate) +
                                    "% "
                                )
                              ])
                            ]
                      ],
                      2
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 已回复聊天占比 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(" 统计周期内，客户主动发起单聊后，"),
                              _c("br"),
                              _vm._v(
                                "员工在一个自然日内有回复过消息的单聊数/客户主动发起的单聊数量。 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class: _vm.cardName === "reply_time" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("reply_time")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon brown" }, [
                  _c("i", { staticClass: "iconfont icon-icon-hourglass" })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "temp" }, [
                    _c("div", { staticClass: "num" }, [
                      _c("span", { staticClass: "all" }, [
                        _vm._v(_vm._s(_vm.summary.reply_time.curr))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.summary.reply_time.prev) + " ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "rate" },
                      [
                        _vm.summary.reply_time.rate === 0
                          ? [
                              _c("span", { staticClass: "rate-num q-info" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.summary.reply_time.rate) +
                                    "% "
                                )
                              ])
                            ]
                          : _vm.summary.reply_time.rate
                              .toString()
                              .indexOf("-") === -1
                          ? [
                              _c(
                                "span",
                                { staticClass: "rate-num q-success" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "+" + _vm.summary.reply_time.rate
                                      ) +
                                      "% "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c("span", { staticClass: "rate-num q-danger" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.summary.reply_time.rate) +
                                    "% "
                                )
                              ])
                            ]
                      ],
                      2
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 平均首次回复时长 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " 客户主动发起单聊后，员工在一个自然日内首次回复的时长间隔为首次回复时长，"
                              ),
                              _c("br"),
                              _vm._v(
                                "所有单聊的首次回复总时长/已回复的单聊总数即为平均首次回复时长。 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "temp-contain" }, [
        _c(
          "div",
          { staticClass: "ly-tool" },
          [
            _c(
              "el-button-group",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "medium",
                      type: _vm.analyseState === "chart" ? "primary" : ""
                    },
                    on: {
                      click: function($event) {
                        _vm.analyseState = "chart"
                      }
                    }
                  },
                  [_vm._v("图表")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "medium",
                      type: _vm.analyseState === "list" ? "primary" : ""
                    },
                    on: {
                      click: function($event) {
                        _vm.analyseState = "list"
                      }
                    }
                  },
                  [_vm._v("列表")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "temp-box" },
          [
            _c("ly-chart", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.analyseState === "chart" && _vm.showEmpty,
                  expression: "analyseState === 'chart' && showEmpty"
                }
              ],
              staticClass: "chart",
              attrs: { option: _vm.chartOption, height: "400px" }
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.analyseState === "chart" && !_vm.showEmpty,
                    expression: "analyseState === 'chart' && !showEmpty"
                  }
                ],
                staticClass: "flex flex-center q-info",
                staticStyle: { height: "240px" }
              },
              [_vm._v(" 暂无分析数据 ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.analyseState === "list",
                    expression: "analyseState === 'list'"
                  }
                ],
                staticClass: "ly-table__main"
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "stat_time", label: "日期" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "chat_cnt",
                        label: "聊天总数",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "message_cnt",
                        label: "发送消息数",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reply_percentage",
                        label: "已回复聊天占比",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "avg_reply_time",
                        label: "平均首次回复时长",
                        align: "center"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "ly-table-empty",
                        attrs: { slot: "empty" },
                        slot: "empty"
                      },
                      [
                        _vm._t("empty", [
                          _c("img", {
                            attrs: {
                              src: require("@assets/svg/default/no_data.svg"),
                              alt: ""
                            }
                          }),
                          _vm._v(" 暂无数据 ")
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }