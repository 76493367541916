<!--
 * @Descripttion: 群发助手
 * @version: 1.0.0
-->
<template>
  <el-dialog :visible.sync="inviterVisible" width="960px" title="群发助手" class="q-dialog inviter-dialog">
    <div class="inviter-dialog__main">
      <el-scrollbar class="inviter-dialog__main-left">
        <el-form :model="inviterData" :rules="inviterRules" ref="inviterData" label-width="120px"
          style="flex: 1; padding-right: 16px" size="medium">
          <el-form-item label="群发账号" required ref="wecom_user_ids" :error="wecom_user_idsTips">
            <ly-emp-select placeholder="选择群发账号" datafilter :showLength="3" ref="empSelect" @getEmpId="getEmpId">
            </ly-emp-select>
            <div class="flex flex-between" style="margin-top: 8px; line-height: 1">
              <div class="flex">
                <el-switch v-model="inviterData.tag_open" :active-value="1" :inactive-value="0">
                </el-switch><span class="q-info" style="margin-left: 12px; margin-right: 4px">更多筛选组合
                </span>
                <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
                  <div slot="content">
                    打开「更多筛选组合」后，将群发给「群发账号」下满足对应条件的好友用户
                  </div>
                  <i class="iconfont icon-info-fill1" style="color: #b4bbcc"></i>
                </el-tooltip>
              </div>
              <div class="q-info">
                将群发给<span class="q-primary"> {{ clientSum }} </span>个客户
              </div>
            </div>
            <div v-if="inviterData.tag_open === 1" style="margin-top: 8px">
              <tag-select-v2 @getTagId="fn_getFilterIds" title="通过标签筛选客户" :checked="inviterData.tags"></tag-select-v2>
            </div>
          </el-form-item>
          <el-form-item label="群发方式" prop="push_type">
            <el-radio-group v-model="inviterData.push_type" @change="fn_changePushType">
              <el-radio :label="1">手动推送</el-radio>
              <el-radio :label="2" v-if="hasCloudBean">自动推送</el-radio>
            </el-radio-group>

            <div class="is-primary flex flex-start text-small" v-if="inviterData.push_type === 1">
              <div style="flex: 1; line-height: 20px; color: #6e788a">
                <div style="margin-bottom: 8px" v-if="hasCloudBean">
                  企微官方推送将使用
                  <b>1</b> 次「企微群发」次数，每个用户每月只能收到
                  <b>30</b> 次企业群发。
                  <span class="q-primary" style="cursor: pointer" @click="fn_changePushTypeByText(2)">选择“自动推送”突破限制
                    ></span>
                </div>
                <div>
                  消息推送完成后，需要对应的员工手动确认，确认后消息发送给对应用户。
                  <el-tooltip class="item" placement="top" popper-class="q-tooltip is-light"
                    style="border-radius: 16px">
                    <div slot="content">
                      <div style="margin: 24px 24px 16px; width: 777px">
                        <img src="https://image.01lb.com.cn//uploads/wecom/21/0416/1618577894N96zjbn3.png" width="777"
                          alt="" />
                      </div>
                    </div>
                    <span class="q-primary">员工如何确认消息发送？</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <template v-if="inviterData.push_type === 2">
              <div class="q-bg is-primary flex flex-start text-small" style="margin: 0; line-height: 1.6">
                <div>提示：</div>
                <div style="flex: 1">
                  <div style="margin-bottom: 8px">
                    1.员工登录云端后，将无群发消息次数限制，能够自动发送"奖品通知"和“助力提醒”。
                  </div>
                  <div style="margin-bottom: 8px">
                    2.
                    选择此方式，未登录云端的员工，将默认使用【手动推送】的方式，且不发送“助力提醒”。
                  </div>
                  <div>
                    3.员工登录云端后，如果员工在电脑登录企业微信，将会把云端登录挤掉线，造成无法自动发送内容，活动期间建议保持员工云端在线登录状态。
                  </div>
                </div>
              </div>
              <div class="account">
                <div class="account-header flex flex-between">
                  <el-radio-group v-model="accountType" @change="fn_changeAccountType">
                    <el-radio :label="1" :disabled="accountLoading">全部账号({{ accountList.length }})</el-radio>
                    <el-radio :label="2" :disabled="accountLoading">离线({{ accountListOut.length }})</el-radio>
                  </el-radio-group>
                  <el-button type="primary" size="ly-mini" :disabled="accountLoading"
                    v-lydebounce="['button', fn_getRobotList]">刷新</el-button>
                </div>
                <div class="account-list">
                  <el-table :data="accountList" style="width: 100%" max-height="250"
                    class="ly-table is-mini no-border v-middle" v-loading="accountLoading" empty-text="请选择群发账号">
                    <el-table-column prop="name, avatar" label="群发账号">
                      <template slot-scope="scope">
                        <div class="flex">
                          <img :src="scope.row.avatar || defaultAvatar" alt="" class="ly-table__main-avatar" />
                          <div>
                            <div class="ly-table__main-name">
                              {{ scope.row.name }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" label="云端登录状态">
                      <template slot-scope="scope">
                        <div :class="[!scope.row.status ? 'q-info' : '']">
                          <span :class="[
                            'q-circle',
                            scope.row.status === 1 ? 'success' : '',
                          ]"></span>{{ scope.row.status ? "在线" : "离线" }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="100" class-name="operation-cell">
                      <template slot-scope="scope">
                        <el-button type="text" :disabled="scope.row.status === 1" @click="fn_jumpCloudPage">登录云端
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </template>
          </el-form-item>
          <el-form-item label="群发内容" prop="content">
            <ly-editor title="群发内容" id="prizeWords" :content="inviterData.content" :recoverContent="initHtml" emoji
              @getContent="fn_getContent"></ly-editor>
          </el-form-item>
          <el-form-item label="自定义链接" prop="title">
            <el-input v-model="inviterData.title" placeholder="请输入链接标题" maxlength="20" show-word-limit></el-input>
            <div class="flex" style="margin-top: 8px">
              <el-input type="textarea" resize="none" v-model="inviterData.desc" placeholder="请输入链接摘要"
                class="inviterDesc"></el-input>
              <el-upload action="#" accept=".png, .jpg, .jpeg" :http-request="requestCover" :on-remove="removeCover"
                list-type="picture-card" :limit="1" :file-list="inviterData.link_coverList" :class="[
                  'inviterDescImg',
                  inviterData.link_coverList.length === 0 ? '' : 'upload-empty',
                ]">
                <div class="flex flex-column flex-center" style="line-height: 22px; height: 100%">
                  <i class="iconfont icon-plus"></i>
                  <span class="text q-info">链接封面</span>
                </div>
              </el-upload>
            </div>
            <div class="q-info" style="line-height: 1.6; font-size: 12px; margin-top: 5px">
              建议尺寸500*500px，JPG、PNG、JPEG格式，图片小于2MB。
            </div>
          </el-form-item>
          <el-form-item>
            <el-button round icon="iconfont icon-send-fill" type="primary" @click="submitInvite('inviterData')"
              style="margin-right: 24px">立即群发</el-button>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div class="inviter-dialog__main-right">
        <div class="mobile">
          <div class="mobile-topbar">
            <div>{{ $day().format("HH:mm") }}</div>
            <div class="flex">
              <i class="iconfont icon-a-CellularConnection"></i>
              <i class="iconfont icon-Wifi"></i>
              <i class="iconfont icon-Battery"></i>
            </div>
          </div>
          <div class="mobile-titlebar">
            <i class="el-icon-arrow-left"></i>
            <div class="flex flex-column">
              <span>张三</span>
              <span style="font-size: 12px">零一裂变（深圳）科技有限公司</span>
            </div>
            <i class="el-icon-more"></i>
          </div>
          <div class="mobile-container" style="max-height: 542px; overflow: auto">
            <div class="mobile-contact">
              <div class="mobile-contact__avatar">
                <img src="@assets/images/avatar.jpg" alt="" />
              </div>
              <div class="mobile-contact__dialog">
                <el-input type="textarea" v-model="inviterData.content" autosize resize="none" readonly></el-input>
              </div>
            </div>
            <div class="mobile-contact">
              <div class="mobile-contact__avatar">
                <img src="@assets/images/avatar.jpg" alt="" />
              </div>
              <div class="mobile-contact__dialog">
                <div class="link-preview">
                  <div class="ellipsis2 link-preview__title">
                    {{ inviterData.title }}
                  </div>
                  <div class="flex flex-between flex-center link-preview__content">
                    <div class="ellipsis2" style="flex: 1; font-size: 14px"
                      v-html="inviterData.desc.replace(/[\n]/g, '')"></div>
                    <div class="link-preview__cover">
                      <img :src="
                        inviterData.link_cover
                          ? inviterData.link_cover
                          : defaultCover
                      " alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import tagSelectV2 from '@/components/wecomTags'
import isImg from '@/common/isImg'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import mapTag from '@/common/mapTag'
import lyEditor from '@/components/lyEditor'
export default {
  components: {
    tagSelectV2, lyEditor
  },
  mixins: [qiniuUpload],
  data () {
    const ruleVerify = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error('请填写群发内容'))
      } else if (value.length > 1000) {
        callback(new Error('请限制在1000个字符以内'))
      } else {
        callback()
      }
    }
    return {
      inviterVisible: false,
      defaultCover: require('@/assets/images/gift_red.png'),
      inviterRules: {
        title: [
          { required: true, message: '请输入链接标题', trigger: 'change' }
        ],
        content: [
          { validator: ruleVerify, required: true, trigger: 'change' }
        ],
        push_type: [
          {
            required: true
          }
        ]
      },
      wecom_user_idsTips: '',
      accountType: 1,
      accountLoading: true,
      accountList: [],
      accountListAll: [],
      accountListOut: [],
      inviterData: {
        activity_id: null,
        title: '点击链接立即领取红包👇',
        link_coverList: [],
        tag_open: 0,
        tags: '',
        push_type: 1,
        link_cover: '',
        desc: '红包实时到账微信零钱 ',
        content: '你终于找到我了，参与活动即可领取红包~\n\n1. 参与本次活动，马上领取微信红包，直接到账的那种~\n2. 点击下方链接，立即领取。\n3. 生成你的专属海报，每邀请1位好友助力即可领取随机红包。\n4. 红包金额实时到账，可在微信钱包实时查看。\n\n点击链接立即领取红包（红包实时到账微信零钱）',
        wecom_user_ids: null
      },
      initHtml: '你终于找到我了，参与活动即可领取红包~\n\n1. 参与本次活动，马上领取微信红包，直接到账的那种~\n2. 点击下方链接，立即领取。\n3. 生成你的专属海报，每邀请1位好友助力即可领取随机红包。\n4. 红包金额实时到账，可在微信钱包实时查看。\n\n点击链接立即领取红包（红包实时到账微信零钱）',
      clientSum: 0,
      tagList: []
    }
  },

  computed: {
    hasCloudBean () {
      return this.$store.state.permission.hasCloudBean
    }
  },

  watch: {
    inviterVisible (val) {
      if (!val) {
        this.fn_resetInviter()
      }
    }
  },

  methods: {

    // 重置群发助手表单
    fn_resetInviter () {
      this.inviterData = {
        activity_id: null,
        title: '点击这里，完成任务领取奖品吧👇',
        link_coverList: [],
        tag_open: 0,
        tags: '',
        push_type: 1,
        link_cover: '',
        desc: '快来参加活动吧',
        content: '你终于找到我了，参与活动即可领取红包~\n\n1. 参与本次活动，马上领取微信红包，直接到账的那种~\n2. 点击下方链接，立即领取。\n3. 生成你的专属海报，每邀请1位好友助力即可领取随机红包。\n4. 红包金额实时到账，可在微信钱包实时查看。\n\n点击链接立即领取红包（红包实时到账微信零钱）',
        wecom_user_ids: null
      }
      this.clientSum = 0
      this.accountType = 1
      this.accountList = []
      this.accountListAll = []
      this.accountListOut = []
      this.$refs.empSelect.selected = []
    },

    // 选择员工
    getEmpId (ids) {
      this.inviterData.wecom_user_ids = ids ? ids.join(',') : ''
      this.wecom_user_idsTips = ids ? '' : '请选择员工'
      this.fn_countCustomer()
    },
    // 查看离线列表
    fn_changeAccountType (val) {
      if (val === 2) {
        this.accountList = this.accountListOut
      } else if (val === 1) {
        this.accountList = this.accountListALl
      }
    },

    // 选中的标签
    fn_getFilterIds (tags) {
      if (tags) {
        this.inviterData.tags = tags.join(',')
      } else {
        this.inviterData.tags = ''
      }
      this.fn_countCustomer()
    },
    // 上传图片
    async requestCover (opts) {
      this.inviterData.link_coverList = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.inviterData.link_coverList = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.inviterData.link_cover = path
          },
          null,
          {
            type: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
        )
      }
    },
    // 删除图片
    removeCover () {
      this.inviterData.link_cover = ''
      this.inviterData.link_coverList = []
    },
    // 客户数量
    async fn_countCustomer () {
      // 如果未选择员工，不查询
      if (!this.inviterData.wecom_user_ids) {
        this.clientSum = 0
        return false
      }
      const data = await this.axios.get('customerSum', {
        params: {
          user_ids: this.inviterData.wecom_user_ids,
          tag_ids: this.inviterData.tags
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.clientSum = data
    },
    // 突破限制
    fn_changePushTypeByText (val) {
      this.inviterData.push_type = 2
      this.fn_changePushType(val)
    },

    // 群发方式
    fn_changePushType (val) {
      if (val === 2) {
        this.fn_getRobotList()
      }
    },

    // 群发内容
    fn_getContent (val) {
      this.inviterData.content = val
    },

    // 云端列表
    async fn_getRobotList () {
      this.accountLoading = true

      const _ids = this.inviterData.wecom_user_ids

      if (!_ids || !_ids.length) {
        this.accountList = []
        this.accountLoading = false
        return false
      }
      const data = await this.axios.get('robotListById', {
        params: {
          user_ids: _ids
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return
      }

      this.accountList = data.list
      this.accountListALl = data.list
      this.accountListOut = data.list.filter(item => item.status === 0)

      setTimeout(() => {
        this.accountLoading = false
      }, 300)
    },

    // 跳转云端
    fn_jumpCloudPage () {
      const routeData = this.$router.resolve({
        path: '/cloudService/account/list'
      })
      window.open(routeData.href, '_blank')
    },
    // 通知员工发送
    async submitInvite (formName) {
      let inviteValid = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          inviteValid = true
        } else {
          inviteValid = false
        }
      })

      if (!this.inviterData.wecom_user_ids) {
        inviteValid = false
        this.wecom_user_idsTips = '请选择员工'
        this.$refs.wecom_user_ids.$el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      }

      if (inviteValid) {
        const _form = {
          activity_id: this.inviterData.activity_id,
          send_content: mapTag(this.inviterData.content, false),
          tag_open: this.inviterData.tag_open,
          tags: this.inviterData.tags,
          send_type: this.inviterData.push_type,
          link_content: this.inviterData.desc.replace(/[\n]/g, ''),
          link_title: this.inviterData.title,
          link_cover_url: this.inviterData.link_cover,
          wecom_user_ids: this.inviterData.wecom_user_ids
        }
        const data = await this.axios.post('/wecom/redpacket-activity/batch-send', _form)

        if ((data && data.code) || (data && data.code === 0)) {
          this.$message.error(data.msg)
          return false
        }

        this.$message.success('邀请通知已发送')
        this.inviterVisible = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";
@import "@/assets/scss/var.scss";

.inviter-dialog {
  ::v-deep {
    .el-dialog {
      margin-top: 10vh !important;
    }

    .el-dialog__body {
      position: relative;
      padding-right: 0;
    }
  }

  &__main {
    height: 680px;
    // overflow: auto;
    padding-right: 345px + 32px;

    &-left {
      height: 100%;
    }

    &-right {
      position: absolute;
      right: 18px;
      top: 16px;
      width: 345px;
    }
  }
}

.upload-empty {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}

.rules {
  border-radius: 8px;
  border: 1px solid #eaebf2;
  overflow: hidden;

  &-header {
    background-color: #f7f8fc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #dfe1e8;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-footer {
    text-align: right;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);
  }

  &-inset {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;
    background: #f2f5fe;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: $--transition;
    font-size: 12px;

    &:hover {
      background-color: $--color-primary;
      color: #fff;
    }

    &.default {
      background-color: transparent;
      color: $--color-text-regular;
      padding: 0;

      &:hover {
        color: $--color-primary;
      }
    }

    &+& {
      margin-left: 12px;
    }
  }

  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 220px !important;
  }
}

.link-preview {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;

  &__content {
    align-items: flex-start;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__cover {
    width: 48px;
    height: 48px;
    font-size: 48px;
    line-height: 1;
    margin-left: 8px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}

#codeCanvas {
  position: absolute;
  left: -99999px;
  z-index: -1;
}

.auto-tag {
  padding: 14px 20px;
  background: #f7f8fc;
  line-height: initial;

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__title {
    margin-bottom: 8px;
    line-height: 22px;
  }

  .el-button {
    border-radius: 4px;
  }

  .qy-tag {
    padding: 0 8px;
    background-color: #fff;
    border-color: #eaebf2;
    margin-right: 8px;
    margin-bottom: 8px;

    ::v-deep .el-icon-close {
      top: 0;
    }
  }
}

.account {
  margin-top: 8px;
  border: 1px solid #eaebf2;
  border-radius: 5px;

  &-header {
    height: 44px;
    padding: 0 24px;
  }
}

.inviterDesc {
  margin-right: 8px;

  ::v-deep .el-textarea__inner {
    height: 104px;
  }
}

.inviterDescImg {
  ::v-deep .el-upload-list__item {
    margin: 0;
    transition: none !important;
  }
}
</style>
