<!--
 * @Author: zhanln
 * @Date: 2021-11-03 16:13:59
 * @LastEditTime: 2021-11-26 11:58:08
 * @LastEditors: zhanln
 * @Description: 活动页设置
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="160px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <!-- 活动标题 -->
    <el-form-item label="活动标题：" prop="show_title">
      <el-input
        class="act-form__input"
        v-model.trim="form.show_title"
        maxlength="15"
        show-word-limit
        placeholder="请输入活动标题"
      ></el-input>
    </el-form-item>
    <!-- 活动规则 -->
    <el-form-item label="活动规则：" prop="rule">
      <ly-editor
        class="act-form__editor"
        title="活动规则"
        :content="form.rule"
        :recoverContent="base_rule"
        :maxLength="2000"
        @getContent="fn_getRule"
      ></ly-editor>
    </el-form-item>
  </el-form>
</template>

<script>
import lyEditor from '@/components/lyEditor'
export default {
  name: 'taskSetting',

  data () {
    return {
      cardLoading: true,
      form: {
        show_title: '',
        rule: ''
      },
      rules: {
        show_title: [
          { required: true, message: '请输入活动标题', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ]
      },
      base_rule: '1、邀请【邀请好友数】位好友添加企业微信，即可获得奖品。\n2、任务完成后，系统会发放奖品链接，点击即可领取。用户也可自行保存活动链接，实时了解任务进度。\n3、若邀请的用户已为老用户或已为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户助力无法获得奖品。\n5、删除好友后助力无效。'
    }
  },

  watch: {
    'form.show_title' (val) {
      this.fn_setStore('show_title', val)
    },
    'form.rule' (val) {
      this.fn_setStore('rule', val)
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        this.form = data
        this.form.rule = this.form.rule || this.base_rule
      } else {
        this.fn_init()
      }
      this.cardLoading = false
    },

    // 配置默认表单
    fn_init () {
      this.form.show_title = '活动进行中'
      this.form.rule = this.base_rule
    },

    // 活动规则回调
    fn_getRule (val) {
      this.form.rule = val
    },

    // save store
    fn_setStore (type, val) {
      this.$store.commit('task/SET_INFO', {
        type,
        val
      })
    },

    // 表单校验，通过则返回 form
    verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      return formValid ? this.form : false
    }
  },

  components: {
    lyEditor
  }
}
</script>
