<template>
  <batch type="announcement" @submit="type => backComfirm = type"/>
</template>

<script>
import batch from '../batchMessage/edit.vue'
export default {
  components: {
    batch
  },
  data () {
    return {
      backComfirm: true
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          next()
        }).catch(() => {
          next(false)
        })
      } else {
        next()
      }
    }
  }
}
</script>
