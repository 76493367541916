var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "reward-tab mb-24" },
        [
          _vm._l(_vm.tabList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                class: [
                  "reward-tab__item",
                  _vm.activeTab === index ? "active" : ""
                ],
                on: {
                  click: function($event) {
                    return _vm.fn_changeTab(index)
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(item.title) + " "),
                _c("span", { staticClass: "circle" }),
                _vm._v(_vm._s(item.count) + "人 "),
                _c("div", { staticClass: "reward-tab__remove" }, [
                  _vm.canRemoveTab(index)
                    ? _c("i", {
                        staticClass: "el-icon-remove",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.fn_removeTab(index)
                          }
                        }
                      })
                    : _vm._e()
                ])
              ]
            )
          }),
          _vm.canAddTab
            ? _c(
                "div",
                {
                  staticClass: "reward-tab__item reward-tab__add",
                  on: { click: _vm.fn_addTab }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              )
            : _vm._e()
        ],
        2
      ),
      _c("reward-item", { ref: "rewardItemRef" }),
      _c("stock", { ref: "stockRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }