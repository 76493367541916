<!--
 * @Descripttion: 用户详情助力列表
 * @version: 1.0.0
-->
<template>
  <ly-table
    ref="lyTable"
    :data="tableData"
    :option="tableOpt"
    :page="page"
    :pageSize="per_page"
    :total="total"
    @searchChange="searchChange"
    @sizeChange="handleSizeChange"
    @currentChange="handleCurrentChange"
    @selectionChange="handleSelectionChange"
  >
    <template #handler>
      <span class="total">邀请了 {{ total }} 名用户</span>
    </template>
    <template #handlerright>
      <el-button
        type="primary"
        round
        :disabled="!selected.length"
        plain
        size="medium"
        @click="handleAddBlack"
        >加入黑名单</el-button
      >
    </template>

    <template #name_slot="{ row }">
      <div class="avatar-name">
        <el-image class="avatar" :src="row.avatar_url" lazy></el-image>
        <span class="name">{{ row.name }}</span>
      </div>
    </template>

    <template #help_status_slot="{ row }">
      <ly-status v-if="row.help_status_code === 1" type="success"
        >助力成功</ly-status
      >
      <ly-status v-else type="danger">{{
        `${row.help_status_title} 助力无效` || "-"
      }}</ly-status>
    </template>
  </ly-table>
</template>
<script>
import { userHelpDetail } from '@/pages/client/redenvelope/http.js'
import lyTable from '@/components/mixins/ly-table.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: userHelpDetail
    }
  },
  computed: {
    tableOpt () {
      return {
        levitate: false,
        showSelection: true,
        operation: 'hide',
        layout: 'sizes, prev, pager, next',
        tableProps: {
          height: 300
        },
        column: [
          {
            label: '用户昵称',
            prop: 'name',
            key: 'external_user_name',
            search: true,
            slot: 'name_slot'
          },
          {
            label: '助力时间',
            prop: 'help_date'
          },
          {
            label: '助力情况',
            prop: 'help_status',
            slot: 'help_status_slot'
          }
        ]
      }
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.$route.query.id * 1,
        join_id: this.$attrs.join_id
      }
    },
    async handleAddBlack () {
      this.$lyConfirm({
        text: '加入黑名单后该用户禁止参与平台其它活动，确定加入黑名单吗？',
        title: '提示',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const join_ids = this.selected.map(e => {
          return e.join_id
        })
        const data = await this.COMM_HTTP.addBlack({ activity_id: this.$route.query.id * 1, join_ids: join_ids.join(',') })
        if ((data && data.code) || (data && data.code === 0)) {
          this.$message.error(data.msg)
          return false
        }
        this.$message.success('加入黑名单成功！')
        this.queryData()
      }).catch(() => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.total {
  color: #212b36;
}
</style>
