<!--
 * @Descripttion: 草稿箱
 * @version: 1.0.0
-->
<template>
  <div class="drafts">
    <el-dialog
      title="草稿箱"
      :visible.sync="draftsVisible"
      class="has-table"
      width="584px"
    >
      <ly-table
        ref="lyTable"
        v-if="!$attrs.isEmpty"
        :data="tableData"
        :option="tableOpt"
        :page="page"
        :pageSize="per_page"
        :total="total"
        @events="handleEvents"
        @searchChange="searchChange"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelectionChange"
      >
        <template #name="{ row }">
          <div class="poster-name">
            <!-- <el-image
              class="poster"
              style="width: 36px; height: 66px"
              :src="row.poster_url || defaultPoster"
              fit="cover"
            ></el-image> -->
            <span class="aname">{{ row.name || "-" }}</span>
          </div>
        </template>
      </ly-table>
      <div v-else class="ly-nodata">
        <el-empty
          :image="require('@assets/svg/default/no_drafts.svg')"
          description="暂无草稿"
        >
          <el-button round type="primary" size="medium" @click="creatAct"
            >创建表单</el-button
          >
        </el-empty>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { drafts } from '../http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: drafts,
      draftsVisible: false,
      IMMEDIATE: false,
      per_page: 5,
      defaultPoster: require('@assets/svg/defaultPoster.svg'),
      DATA_LOCATION: 'records'
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        levitate: false,
        layout: 'total, prev, pager, next',
        operation: [
          // 是否显示操作栏 为空或者不传时隐藏  自带 'detail', 'edit', 'delete' 字符串形式传入
          {
            label: '继续编辑',
            handler (row) {
              _this.$router.push(`/material/formTable/edit?formId=${row.formId}&id=${row.id}&status=${row.status}`)
            }
          },
          {
            label: '删除',
            handler (row) {
              _this.handleDelete(row)
            }
          }
        ],
        operationWidth: 150,
        column: [
          {
            label: '表单名称',
            prop: 'name',
            slot: 'name'
          },
          {
            label: '创建时间',
            prop: 'updateTime',
            width: '160',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          }
        ]
      }
    }
  },
  mounted () { },
  methods: {
    async getDrafts () {
      const res = await this.queryData()
      this.$emit('onGetDrafts', res)
    },
    creatAct () {
      this.draftsVisible = false
      this.$router.push('/material/formTable/edit')
    },
    handleDelete ({ id }) {
      this.$lyConfirm({
        title: '确认删除此表单吗？',
        text: '删除表单会同时删除已提交的用户数据和活动数据，是否确认删除？'
      }
      )
        .then(() => {
          this.COMM_HTTP.reqDelete(id).then(data => {
            if ((data && data.code) || (data && data.code === 0)) {
              this.$message.error(data.msg)
              return
            }
            this.$message.success('删除成功！')
            this.getDrafts()
          })
        })
        .catch(() => { })
    },
    formatterParams (params) {
      return {
        ...params,
        status: 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.xl-ly-table {
  .poster {
    margin-right: 12px;
  }
  .aname {
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.nodata {
  height: 544px;
  text-align: center;
  img {
    margin-top: 156px;
  }
  .desc {
    color: #6e788a;
    margin: 5px 0 20px 0;
  }
}
</style>
