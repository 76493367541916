<template>
  <div class="formPreviewEdit">
    <el-form label-position="top">
      <el-form-item
        v-for="(normalTable, ind) in formTable"
        :label="ind + 1 + '.' + normalTable.name"
        :key="ind"
        :required="normalTable.required"
      >
        <template v-if="normalTable.type == 'input'">
          <template v-if="isEdit">
            <el-input
              v-if="normalTable.options.dataType == 'number'"
              type="number"
              v-model.number="normalTable.dataModel"
              :placeholder="normalTable.options.placeholder"
              style="width: 319px"
              :disabled="normalTable.options.disabled"
              :maxlength="normalTable.options.maxLength"
              show-word-limit
            ></el-input>
            <el-input
              v-else
              :type="normalTable.options.dataType"
              v-model="normalTable.dataModel"
              :disabled="normalTable.options.disabled"
              :placeholder="normalTable.options.placeholder"
              :maxlength="normalTable.options.maxLength"
              show-word-limit
              style="width: 319px"
            ></el-input>
          </template>
          <div v-else>{{ normalTable.dataModel }}</div>
        </template>

        <template v-if="normalTable.type == 'textarea'">
          <template v-if="isEdit">
            <el-input
              type="textarea"
              :rows="5"
              v-model="normalTable.dataModel"
              :disabled="normalTable.options.disabled"
              :placeholder="normalTable.options.placeholder"
              :maxlength="normalTable.options.maxLength"
              show-word-limit
            ></el-input>
          </template>
          <div v-else>{{ normalTable.dataModel }}</div>
        </template>

        <template v-if="normalTable.type == 'number'">
          <template v-if="isEdit">
            <el-input-number
              v-model="normalTable.dataModel"
              :step="normalTable.options.step"
              controls-position="right"
              :disabled="normalTable.options.disabled"
              :min="normalTable.options.min"
              :max="normalTable.options.max"
            ></el-input-number
          ></template>

          <div v-else>{{ normalTable.dataModel }}</div>
        </template>

        <template v-if="normalTable.type == 'radio'">
          <template v-if="isEdit">
            <el-radio-group
              v-model="normalTable.dataModel"
              :disabled="normalTable.options.disabled"
            >
              <el-radio
                :style="{
                  display: 'block'
                }"
                :label="item.value"
                v-for="(item, index) in normalTable.options.options"
                :key="index"
              >
                <template>{{ item.value }}</template>
              </el-radio>
            </el-radio-group>
          </template>
          <div v-else>{{ normalTable.dataModel }}</div>
        </template>

        <template v-if="normalTable.type == 'checkbox'">
          <template v-if="isEdit">
            <el-checkbox-group
              v-model="normalTable.dataModel"
              :disabled="normalTable.options.disabled"
            >
              <el-checkbox
                :label="item.value"
                v-for="(item, index) in normalTable.options.options"
                :key="index"
              >
                <template>{{ item.value }}</template>
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <div v-else>
            {{
              normalTable.dataModel.length > 0
                ? normalTable.dataModel.join(',')
                : '-'
            }}
          </div>
        </template>

        <template v-if="normalTable.type == 'select'">
          <template v-if="isEdit">
            <el-select
              v-model="normalTable.dataModel"
              :disabled="normalTable.options.disabled"
              :multiple="normalTable.options.multiple"
              :clearable="normalTable.options.clearable"
              :placeholder="normalTable.options.placeholder"
              style="width: 319px"
              :filterable="normalTable.options.filterable"
            >
              <el-option
                v-for="item in normalTable.options.options"
                :key="item.value"
                :value="item.value"
                :label="item.value"
              ></el-option>
            </el-select>
          </template>
          <div v-else>{{ normalTable.dataModel }}</div>
        </template>

        <template v-if="normalTable.type == 'area'">
          <div v-if="isEdit">
            <el-cascader
              :options="options"
              style="width: 319px"
              v-model="normalTable.dataModel"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.label }}</span>
              </template>
            </el-cascader>
            <div class="mt-16">
              <el-input
                style="width: 319px"
                v-model="normalTable.options.desc"
                placeholder="请输入详细地址信息"
              ></el-input>
            </div>
          </div>
          <div v-else>
            {{ normalTable.descMs }} {{ normalTable.options.desc }}
          </div>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import area from '@/common/area.js'
import { useCom, currencyCom } from '../formC/componentsConfig'
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    formCom: {
      type: Array,
      default: () => []
    }
  },
  data () {

    function getOptions () {
      let _op = []
      for (const [a, b] of Object.entries(area.province_list)) {
        _op.push({
          value: a,
          label: b,
          children: []
        })
      }

      for (const [a, b] of Object.entries(area.city_list)) {
        let _cityStartWith = a.substr(0, 2)
        let _filter = _op.find(item => {
          let _d = item.value.substr(0, 2)
          if (_cityStartWith == _d) { return item }
        })
        _filter.children.push({
          value: a,
          label: b,
          children: []
        })
      }

      for (const [a, b] of Object.entries(area.county_list)) {
        let _cityStartWith = a.substr(0, 2)
        let _citymiddleWite = a.substr(2, 2)

        let _filter = _op.find(item => {
          let _d = item.value.substr(0, 2)
          if (_cityStartWith == _d) { return item }
        })

        let _child = _filter.children.find(item => {
          let _d = item.value.substr(2, 2)
          if (_citymiddleWite == _d) { return item }
        })

        _child.children.push({
          value: a,
          label: b
        })
      }



      return _op
    }

    return {
      // areaJSON: getArea(),
      prolist: [],
      formTable: [],
      options: getOptions()
    }
  },
  methods: {
    _clearTable () {
      this.formTable = []
    },
    _initFromTable (data) {
      this.formTable = []
      let _com = [...useCom, ...currencyCom]
      let _list = []



      data.forEach(_ => {

        let _filCom = _com.filter(com => com.dataType == _.dataType && com.mainType == _.mainType)
        if (_.presetType || _.presetType == 0) {
          _filCom = _filCom.filter(com => com.presetType == _.presetType)
        }

        let [_filComfist] = _filCom

        _filComfist = JSON.parse(JSON.stringify(_filComfist))


        if (_.options) {
          _filComfist.options.options = JSON.parse(_.options)
        }

        // _filComfist.options.isDelete = +this.$route.query.status - 1

        delete _.options

        if (_filComfist.type == 'area') {

          let s = JSON.parse((_.data)) || {}

          let _sa = s[0] && s[0].split(',') || []
          _filComfist.options.desc = s[1]


          if (_sa.length < 2) {
            _filComfist.descMs = ''
          } else {
            let findOne = this.options.find(_ => _.label == _sa[0])
            const pro = findOne.value

            let cityOne = findOne.children.find(_ => _.label == _sa[1])
            const city = cityOne.value

            let areaOne = cityOne.children.find(_ => _.label == _sa[2])
            const area = areaOne.value

            _filComfist.descMs = _sa.join('/')
            _.data = [pro + '', city + '', area + '']

          }

        }
        if (_filComfist.type == 'checkbox') {
          _.data = _.data && _.data.split(',') || []
        }

        _list.push(Object.assign({}, _filComfist, _, {
          dataModel: _.data
        })
        )
      })

      this.$oldsource = this.util.extend(true, [], _list)
      this.formTable = _list
    },
    getFormTableValue () {
      let _filter = this.formTable.filter(item => {
        if (item.required && !item.dataModel) {
          return item
        }
      })
      if (_filter.length) {
        this.$toast(`${_filter[0].name}为必填字段`)
        return []
      }


      this.formTable.forEach(item => {
        item.postValue = item.dataModel
        if (item.type == 'area') {
          let _vals = item.dataModel
          item.postValue = JSON.stringify([area.province_list[_vals[0]] + ',' + area.city_list[_vals[1]] + ',' + area.county_list[_vals[2]], item.options.desc])
          item.descMs = area.province_list[_vals[0]] + ',' + area.city_list[_vals[1]] + ',' + area.county_list[_vals[2]]
        }

        if (item.type == 'checkbox') {
          item.postValue = item.dataModel && item.dataModel.join(',') || ''
        }
      })

      return this.formTable
    },
  },
}
</script>

<style lang='scss'>
.formPreviewEdit {
  .el-form-item__label {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #212b36;
    line-height: 20px;
  }
  .el-form-item__content {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6e6f73;
    line-height: 20px;
  }
  .el-form-item {
    padding-bottom: 14px;
    border-bottom: 1px dashed #f0f1f2;
    margin-bottom: 14px;
  }

  // .el-select,
  // .el-input,
  textarea {
    width: 319px;
    resize: none;
  }

  .el-radio-group,
  .el-checkbox-group {
    .el-radio,
    .el-checkbox {
      width: 295px;
      // height: 44px;
      border-radius: 4px;
      border: 1px solid #dee0e6;
      padding: 12px;
      margin: 0;
      line-height: 0 !important;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .el-radio__label,
    .el-checkbox__label {
      padding-left: 6px;
    }
  }

  .el-textarea {
    width: 313px;
  }
}
</style>