<!--
 * @Author: zhanln
 * @Date: 2021-08-23 10:40:09
 * @LastEditTime: 2021-12-10 20:38:10
 * @LastEditors: Please set LastEditors
 * @Description: 用户设置
-->
<template>
  <el-form
    :model="cusForm"
    :rules="cusFormRules"
    ref="cusFormRef"
    label-width="125px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <el-form-item label="用户标签">
      <div class="flex cus-flex flex-start">
        <el-switch
          v-model="cusForm.tag_open"
          :active-value="true"
          :inactive-value="false"
          @change="fn_setForm($event, 'tag_open')"
        ></el-switch>

        <span class="q-info" style="line-height: 20px; margin-left: 10px"
          >开启后将为本渠道新增用户自动打标签；因官方限制，标签存在延时，预计会在添加用户后的2分钟之内完成</span
        >
      </div>
      <el-form-item prop="tags" v-if="cusForm.tag_open">
        <tag-select-v2
          @getTagIdArr="fn_getTagId"
          :checked="tags"
        ></tag-select-v2>
      </el-form-item>
    </el-form-item>
    <el-form-item label="昵称备注">
      <div class="flex cus-flex flex-start">
        <el-switch
          v-model="cusForm.name_suffix_open"
          :active-value="true"
          :inactive-value="false"
          @change="fn_setForm($event, 'name_suffix_open')"
        ></el-switch>
        <span class="q-info" style="line-height: 20px; margin-left: 10px"
          >开启后将为本渠道新增用户昵称增加后缀备注；因官方限制，微信昵称和昵称备注之和若超过20个字符，仅显示前20个字符</span
        >
      </div>
      <template v-if="cusForm.name_suffix_open">
        <el-form-item prop="name_suffix">
          <el-input
            placeholder="请输入昵称备注"
            v-model="cusForm.name_suffix"
            maxlength="10"
            show-word-limit
            style="width: 360px"
            @change="fn_setForm($event, 'name_suffix')"
          >
            <template slot="prepend">微信昵称-</template>
          </el-input>
        </el-form-item>
        <div class="flex cus-flex" style="margin-top: 24px">
          预览：<span class="cus-view">
            <i class="el-icon-s-custom ly-tag_icon"></i>
            <span class="suffix">{{ nameSuffix }}</span>
          </span>
        </div>
      </template>
    </el-form-item>
  </el-form>
</template>

<script>
import tagSelectV2 from '@/components/wecomTags'
export default {
  components: {
    tagSelectV2
  },
  data () {
    return {
      cardLoading: true,
      cusForm: {
        tag_open: true,
        tags: '',
        name_suffix_open: true,
        name_suffix: ''
      },
      cusFormRules: {
        name_suffix: [
          { required: true, message: '请输入昵称备注', trigger: 'change' }
        ],
        tags: [
          { type: 'array', required: true, message: '请选择标签', trigger: 'change' }
        ]
      },
      tags: ''
    }
  },

  computed: {
    isReady () {
      return this.$store.getters['qrcode/GET_READY']
    },
    nameSuffix () {
      return `微信昵称-${this.cusForm.name_suffix}`
    }
  },

  watch: {
    isReady (val) {
      if (val) {
        if (this.$store.getters['qrcode/GET_EDIT']) {
          this.cusForm = this.$store.getters['qrcode/GET_CUS']
        }
        this.cardLoading = false
      }
    },
    'cusForm.tags' (val) {
      if (val) {
        this.tags = val.map(item => item.tagId || item.id).join(',')
      } else {
        this.tags = ''
      }
    }
  },

  methods: {
    // 用户标签回调
    fn_getTagId (tags) {
      this.cusForm.tags = tags
      this.fn_setForm(tags, 'tags')
      this.$refs.cusFormRef.validateField('tags')
    },
    // 配置
    fn_setForm (val, type) {
      this.$store.commit('qrcode/SET_FORM', {
        form: 'cus',
        name: type,
        val: val
      })
    },
    // 表单验证
    verify () {
      let formValid = true

      this.$refs.cusFormRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })
      return formValid
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.act-form {
  ::v-deep .wecom-tag__selected {
    width: 60%;
    min-width: 560px;
  }
}

.cus {
  &-flex {
    margin-top: 8px;
    margin-bottom: 8px;

    ::v-deep .el-switch {
      height: 20px;
    }
  }

  &-view {
    display: flex;
    height: 36px;
    align-items: center;
    background-color: #f7f8fc;
    border: 1px solid #DFE1E8;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 8px;
    width: 318px;
    i {
      color: $--color-primary;
      margin-right: 4px;
    }
  }
}
</style>
