<!--
 * @Descripttion: 云端登录弹窗
 * @version: 1.0.0
 * @Author: lw
 * @Date: 2021-03-17 17:14:39
 * @LastEditTime: 2022-08-05 18:43:37
-->
<template>
  <div class="scan-login">
    <el-dialog title="云端登录" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false"
      @open="getBeanBalance" @closed="handleClose" width="678px">
      <div v-loading="gloading">
        <div class="use-notice">
          <h5 class="title"><img src="@assets/svg/Info.svg" />使用须知</h5>
          <p class="tips-item">
            1.扫描二维码成功登录后，你的企业微信将托管到云端电脑上，手机上方将显示【Windows】或【ipad】已登录。
          </p>
          <p class="tips-item">
            2.帐户扫码登录后，建议在<span style="color: #2B7CFF">8小时</span>以上再进行群发等操作。
          </p>
          <p class="tips-item">
            3.登录云端的员工账号保持24小时在线，请切记不要做以下操作
          </p>
          <p class="tips-item indent">
            不要在其他电脑设备上登录托管的企业微信；
          </p>
          <p class="tips-item indent">不要在企业微信手机端【退出登录】；</p>
          <p class="tips-item indent">
            不要在企业微信手机端【切换企业】，否则云端将会自动掉线。
            <el-popover trigger="hover">
              <p :style="{ textAlign: 'center', margin: '0 0 10px 0' }">
                扫码添加客服好友
              </p>
              <img class="qr-code" src="@assets/images/contact_me_qr.png" alt=""
                :style="{ width: '172px', height: '172px' }" />
              <div class="tips-customer-service" slot="reference">
                如有疑问，请联系客服
              </div>
            </el-popover>
          </p>
        </div>
        <div class="balance">
          <div class="bean">
            <span class="num">{{ beanBalance }}</span>（当前可用云豆）<span style="marginleft: 15px" v-if="beanFreeze">已冻结云豆：{{
                beanFreeze
            }}</span>
            <!-- <span class="recharge-btn" @click="handleRecharge">立即充值</span> -->
          </div>
          <div class="rule">
            <h5 class="title"><img src="@assets/svg/file.svg" />扣费规则</h5>
            <p class="tips-item">
              1.一个在线账号每个自然日扣1云豆，每个账号登录即扣费，请确保使用正确账号登录；
            </p>
            <p class="tips-item">
              2.扫描二维码登录成功即扣除云豆，请确保使用本企业微信员工账号扫码登录；
            </p>
            <p class="tips-item">
              3.为了避免重复扣费，生成二维码在没扫码登录前，会暂时冻结1云豆3分钟。
            </p>
          </div>
        </div>
        <div class="qr-area">
          <img class="qr" :src="qr || require('@assets/images/qrCode.png')" alt="qr" />
          <div class="mask" v-if="beanBalance === 0 && !succ3min">
            <!-- 余额不足 -->
            <div class="no-balance">
              <div>当前余额不足</div>
              <div>无法生成二维码</div>
              <span class="recharge-btn" @click="handleRecharge">立即充值</span>
            </div>
          </div>
          <div class="mask succ-cont" v-else-if="showBtn">
            <img class="succ-img" v-if="!isFirst" src="@assets/svg/paySucc.svg" alt="" />
            <p class="succ-desc" v-if="!isFirst">登录成功</p>
            <el-button round type="primary" @click="requestLogin">{{
                isFirst ? "立即扫码登录" : "继续生成二维码"
            }}</el-button>
          </div>
          <div class="mask" v-else-if="errorType === 2 || errorType === 3">
            <!-- 二维码失效 -->
            <div class="invalid">
              <div>
                {{ errorType === 3 ? "生成二维码超时" : "二维码已失效" }}
              </div>
              <span class="recharge-btn" @click="requestLogin">点击刷新</span>
            </div>
          </div>
          <div class="mask" v-else-if="errorType === 0">
            <!-- 设备不足 -->
            <div class="not-enough">
              <div>当前设备不足</div>
              <div>请联系客服处理</div>
              <span class="recharge-btn" @click="requestLogin">点击刷新</span>
            </div>
          </div>
          <div class="mask" v-else-if="errorType === 1">
            <!-- 网络错误 -->
            <div class="net-error">
              <div>当前网络错误</div>
              <span class="recharge-btn" @click="requestLogin">点击刷新</span>
            </div>
          </div>
          <div class="mask" v-else-if="loading || loging">
            <!-- 加载二维码、登录中 -->
            <div class="loading">
              <img class="loading-img" src="@assets/images/loading_m.png" alt="loading" />
              <div class="loading-desc">
                {{ loging ? "登录中" : "正在生成二维码" }}
              </div>
            </div>
          </div>
        </div>
        <div class="desc">
          <div class="wx">
            请使用<img src="@assets/svg/enwechat.svg" alt="qr" />企业微信扫码登录
          </div>
          <div>如有问题，请联系客服处理</div>
        </div>
      </div>
    </el-dialog>
    <ly-recharge v-model="showRecharge" @success="getBeanBalance"></ly-recharge>
  </div>
</template>
<script>
import lyRecharge from './recharge'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    lyRecharge
  },
  watch: {
    value (n) {
      this.visible = n
    }
  },
  data () {
    return {
      gloading: true,
      visible: false,
      beanBalance: 0,
      beanFreeze: 0,
      loading: false, // 获取二维码或者登录时loading
      loging: false, // 是否登录中
      showBtn: true, // 展示登录按钮
      qr: '',
      isFirst: true, // 是否首次请求登录  判断登录按钮文案
      showRecharge: false,
      timerQR: null,
      time3min: null, // 三分钟后二维码提示失效
      succ3min: null, // 三分钟内生成二维码失败提示超时
      errorType: null, // 0：设备不足 1：网络错误 2：二维码失效 3：生成二维码超时
      retry: 0
    }
  },
  mounted () {
  },
  methods: {
    // 获取云豆余额
    getBeanBalance () {
      this.gloading = true
      this.axios.get('beanBalance', null, { a: 1 }).then((data) => {
        this.gloading = false
        this.beanBalance = data.bean || 0
        this.beanFreeze = data.freeze || 0
      })
    },
    // 请求登录
    async requestLogin () {
      this.time3min = null
      this.succ3min = null
      this.loading = true
      this.showBtn = false
      this.errorType = null
      try {
        const res = await this.axios.post('requestLogin')
        // eslint-disable-next-line camelcase
        const { code, rela_serial_no } = res
        // 0=success 1=资产不足 250039=没有可用设备 160003=token无效 500003=已经登录了
        if (code === 0) {
          // 刷新云豆
          this.getBeanBalance()
          this.getRobotQrcode(rela_serial_no)
        } else if (code === 1) {
          this.$message.error('余额不足，请充值！')
          this.beanBalance = 0
        } else if (code === 250039) {
          this.$message.error('当前设备不足，请联系客服处理！')
          this.errorType = 0
        } else {
          this.errorType = 1
        }
      } catch (error) {
        this.errorType = 1
      }
    },
    // 拿到操作编号获取二维码
    // eslint-disable-next-line
    async getRobotQrcode (serial_no) {
      // 3min获取不到二维码提示生成二维码超时
      !this.succ3min && (this.succ3min = Date.now())
      const currentTime = Date.now()
      if (!this.time3min && this.succ3min && (currentTime - this.succ3min >= 3 * 60 * 1000)) {
        this.errorType = 3
        // 刷新云豆
        this.getBeanBalance()
        return false
      }
      // 生成二维码后3min失效
      if (this.time3min && (currentTime - this.time3min >= 3 * 60 * 1000)) {
        this.errorType = 2
        // 刷新云豆
        this.getBeanBalance()
        return false
      }
      this.timerQR = setTimeout(async () => {
        try {
          const qr = await this.axios.get('robotQrcode', {
            params: { serial_no }
          })
          if (qr.code === 1001204) {
            this.errorType = 1
            return false
          }
          // 0=第三方未回调，请持续请求 1=已经回调，请处理 2=二维码已经失效或超时 3-已经扫描并登录成功回调
          if (qr.status === 2) {
            this.errorType = 2
            // 刷新云豆
            this.getBeanBalance()
            this.getLoginStatus(serial_no)
            this.loading = false
          } else if (qr.status === 3) {
            this.getLoginStatus(serial_no)
          } else {
            if (!this.time3min && qr.qrcode) {
              this.loading = false
              this.qr = qr.qrcode
              this.time3min = Date.now()
            }
            this.getRobotQrcode(serial_no)
          }
        } catch (error) {
          // 重试3次后提示网络错误
          if (this.retry >= 2) {
            this.errorType = 1
            this.retry = 0
            return false
          }
          this.retry++
          this.getRobotQrcode(serial_no)
        }
      }, 3000)
    },
    // 获取登录状态
    // eslint-disable-next-line
    async getLoginStatus (serial_no) {
      const data = await this.axios.get('loginStatus', {
        params: { serial_no }
      })
      // 0=第三方未回调，请持续请求 1=已经回调，请处理 2=账号重复登录 3=账号登录超时 4=取消登录
      // 假性登录成功 按钮变成继续扫码登录
      if (data.id > 0 && data.WecomUserID !== 0) {
        // 设置3min的loading
        // this.loging = true
        // setTimeout(() => {
        //   this.loging = false
        //   this.$message.success('登录成功！')
        //   this.showBtn = true
        // }, 3 * 60 * 1000)
        this.isFirst = false
        this.showBtn = true
      } else if (data.status === 1 && data.WecomUserID === 0) {
        // 非本企业员工登录
        this.enterpriseError()
      } else if (data.status === 2) {
        this.$message.error('你已登录此账号，不能重复登录！')
        this.errorType = 2
      } else if (data.status === 3 || data.status === 4) {
        this.errorType = 2
      }
      // 刷新云豆
      this.getBeanBalance()
    },
    handleClose () {
      this.$emit('input', false)
      clearTimeout(this.timerQR)
      Object.assign(this.$data, this.$options.data())
    },
    handleRecharge () {
      this.showRecharge = true
    },
    enterpriseError () {
      this.$lyConfirm({
        title: '登录账号错误',
        text: '当前扫码账号不是本企业员工账号，因占用云端资源，已扣除云豆。请使用本企业账号扫码。',
        type: 'error',
        confirmButtonText: '重新扫码',
        cancelButtonText: '取消'
      }).then(() => {
        this.requestLogin()
      }).catch(() => {
        this.errorType = 2
      })
    }
  }
}
</script>
<style lang="scss" scoped>
p,
h5 {
  margin: 0;
  padding: 0;
}

.title {
  font-size: 14px;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}

.recharge-btn {
  display: inline-block;
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  background: #FFC107;
  box-shadow: 0px 8px 16px 0px rgba(255, 193, 7, 0.24);
  border-radius: 36px;
  cursor: pointer;
}

.use-notice {
  background: #f2f7fe;
  border-radius: 4px;
  padding: 12px 16px 8px;
  color: #212b36;
  font-size: 12px;

  .tips-item {
    margin: 4px 0;

    &:last-child {
      position: relative;
    }
  }

  .indent {
    text-indent: 30px;
  }
}

.balance {
  background: #fffaf1;
  border-radius: 4px;
  margin-top: 12px;
  color: #faad14;

  .bean {
    display: flex;
    align-items: center;
    padding: 13px 16px;
    position: relative;
    border-bottom: 1px solid #ffeccb;
  }

  .num {
    font-size: 26px;
  }

  .recharge-btn {
    position: absolute;
    right: 16px;
  }

  .rule {
    padding: 13px 16px;
    color: #212b36;
    font-size: 12px;

    .tips-item {
      margin: 4px 0;
    }
  }
}

.qr-area {
  margin-top: 24px;
  height: 180px;
  position: relative;

  .qr,
  .mask {
    height: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .qr {
    border: 1px solid #EAEBF2;
    box-sizing: border-box;
    border-radius: 2px;
  }

  .mask {
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    width: 175px;
    height: 175px;

    .el-button {
      margin: auto;
      padding: 9px 22px;
    }

    .no-balance,
    .loading,
    .invalid,
    .not-enough,
    .net-error {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #212b36;
    }

    .loading-img {
      animation: rotate 2s linear infinite;
    }

    .loading-desc {
      margin-top: 14px;
    }

    .recharge-btn {
      margin-top: 10px;
    }

    .invalid,
    .not-enough,
    .net-error {
      .recharge-btn {
        background: #2B7CFF;
        box-shadow: unset;
      }
    }
  }

  .succ-cont {
    flex-direction: column;

    .succ-img {
      width: 24px;
      height: 24px;
      margin: 24px auto 0;
    }

    .succ-desc {
      color: #212b36;
      text-align: center;
      margin: 10px 0;

      &~.el-button {
        top: unset;
      }
    }

    .el-button {
      margin: 0 auto;
      position: relative;
      top: calc(50% - 16px);
    }
  }
}

.desc {
  text-align: center;

  .wx {
    img {
      position: relative;
      top: 3px;
      margin: 0 5px;
    }
  }
}

::v-deep {
  .tips-customer-service {
    color: #2B7CFF;
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
