var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "分组", visible: _vm.visible, width: "420px" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "selected" }, [
        _vm._v(" 已选择"),
        _c("span", { staticStyle: { color: "#2B7CFF" } }, [
          _vm._v(" (" + _vm._s(_vm.selected.length) + ") ")
        ]),
        _vm._v("个群 ")
      ]),
      _c(
        "div",
        { staticClass: "group-list" },
        [
          _vm.groupList.length
            ? _c(
                "el-scrollbar",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.group,
                        callback: function($$v) {
                          _vm.group = $$v
                        },
                        expression: "group"
                      }
                    },
                    _vm._l(_vm.groupList, function(item) {
                      return _c(
                        "el-checkbox",
                        { key: item.id, attrs: { label: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "no-group flex flex-column flex-center" },
                [
                  _c("img", {
                    attrs: {
                      src: require("@assets/svg/default/no_data.svg"),
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "desc" }, [_vm._v("暂无分组")])
                ]
              )
        ],
        1
      ),
      _vm.groupList.length
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary", size: "medium" },
                  on: { click: _vm.handleDoGroup }
                },
                [_vm._v("确定分组")]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }