var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-form", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.cardLoading,
        expression: "cardLoading"
      }
    ],
    ref: "lyFormRef",
    staticClass: "act-form",
    attrs: { formObj: _vm.formObj, form: _vm.formData, rules: _vm.rules },
    on: { setData: _vm.fn_setData },
    scopedSlots: _vm._u([
      {
        key: "vn_id_bottom",
        fn: function() {
          return [
            _c(
              "p",
              {
                staticClass: "q-info text-small",
                staticStyle: { "line-height": "1.6", "margin-bottom": "0px" }
              },
              [
                _vm._v(" 视频号ID必须与小程序主体一致，否则将无法跳转 "),
                _c(
                  "span",
                  {
                    staticClass: "q-primary",
                    staticStyle: { "margin-left": "24px", cursor: "help" },
                    on: { click: _vm.fn_toDoc }
                  },
                  [_vm._v("如何获取视频号ID？")]
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "show_url_head",
        fn: function() {
          return [
            _c("div", { staticClass: "q-info text-small" }, [
              _vm._v(
                " 建议尺寸:702*340像素，大小不超过2MB，格式为JPG/PNG/JPEG "
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "wecomTag",
        fn: function() {
          return [
            _c(
              "el-form-item",
              { attrs: { label: "客户标签：" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.formData.add_tags_type,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "add_tags_type", $$v)
                      },
                      expression: "formData.add_tags_type"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("添加即打标签")
                    ]),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("新老用户场景标签")
                    ])
                  ],
                  1
                ),
                _vm.formData.add_tags_type === 1
                  ? _c("tag-select-v2", {
                      attrs: { checked: _vm.formData.tags1 },
                      on: {
                        getTagId: function($event) {
                          return _vm.fn_getTagId($event, "tags1")
                        }
                      }
                    })
                  : [
                      _c("div", [
                        _vm._v(" 新用户："),
                        _c("span", { staticClass: "q-info" }, [
                          _vm._v("添加涨粉账号的新增用户打标签")
                        ])
                      ]),
                      _c("tag-select-v2", {
                        attrs: { checked: _vm.formData.tags2 },
                        on: {
                          getTagId: function($event) {
                            return _vm.fn_getTagId($event, "tags2")
                          }
                        }
                      }),
                      _c("div", { staticClass: "mt-16" }, [
                        _vm._v(" 老用户："),
                        _c("span", { staticClass: "q-info" }, [
                          _vm._v("添加涨粉账号的企业老用户打标签")
                        ])
                      ]),
                      _c("tag-select-v2", {
                        attrs: { checked: _vm.formData.tags3 },
                        on: {
                          getTagId: function($event) {
                            return _vm.fn_getTagId($event, "tags3")
                          }
                        }
                      })
                    ]
              ],
              2
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }