<!--
 * @Descripttion: 红包记录页面
 * @version: 1.0.0
-->
<template>
  <div class="rd-record page-warp-bg">
    <div class="select-box">
      <label style="color: rgba(0, 0, 0, 0.85)">选择活动：</label>
      <el-select
        size="medium"
        v-model="activity_id"
        @change="queryData"
        filterable
        placeholder="请选择活动"
      >
        <el-option
          v-for="item in activities"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-download"
          v-lydebounce="['button', fn_export]"
          :disabled="tableData.length === 0"
          :loading="exportLoading"
          >导出数据</el-button
        >
      </template>
      <template #user_name_slot="{ row }">
        <div class="avatar-name">
          <el-image class="avatar" :src="row.avatar_url" lazy></el-image>
          <img
            class="sex male"
            v-if="row.sex === 1"
            src="@assets/svg/male.svg"
            alt=""
          />
          <img
            class="sex female"
            v-if="row.sex === 2"
            src="@assets/svg/female.svg"
            alt=""
          />
          <div class="name">
            <span>{{ row.name }}</span>
            <span class="nosex" v-if="row.sex === '' || row.sex === null"
              >未设置性别</span
            >
          </div>
        </div>
      </template>

      <template #send_code_slot="{ row }">
        <ly-status v-if="row.send_code === 0" type="success"
          >发放成功</ly-status
        >
        <ly-status v-else type="danger">
          发放失败
          <el-tooltip
            effect="dark"
            :content="
              row.send_code === 1
                ? '当前用户未实名'
                : '异常错误，如果操作重新发放后，也无法正常发放，请联系平台客服'
            "
            placement="top"
          >
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </ly-status>
      </template>
      <template #operation="{ row }">
        <el-button
          type="text"
          :disabled="row.send_code === 0"
          @click="handleReSend(row)"
          >重新发放</el-button
        >
      </template>
    </ly-table>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { record } from '@/pages/client/redenvelope/http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: record,
      activities: [],
      activity_id: '',
      exportLoading: false
    }
  },
  computed: {
    tableOpt () {
      return {
        scrollEl: '.page-warp-bg',
        operationWidth: 100,
        column: [
          {
            label: '用户昵称',
            prop: 'name',
            search: true,
            key: 'external_user_name',
            placeholder: '请输入用户昵称',
            slot: 'user_name_slot'
          },
          {
            label: '红包金额',
            prop: 'total_amount'
          },
          {
            label: '红包到账金额',
            prop: 'get_amount'
          },
          {
            label: '服务费',
            prop: 'handing_amount'
          },
          {
            label: '发放时间',
            prop: 'give_date',
            search: true,
            key: ['send_date_start', 'send_date_end'],
            type: 'daterange',
            formatter ({ give_date }) {
              return give_date.substr(0, 16)
            }
          },
          {
            label: '发放状态',
            prop: 'send_code',
            key: 'send_status',
            search: true,
            type: 'select',
            default: 0,
            slot: 'send_code_slot',
            source: [
              { label: '全部', value: 0 },
              { label: '发送成功', value: 1 },
              { label: '发送失败', value: 2 }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.activity_id = this.$route.query.id * 1
    this.COMM_HTTP.selectList().then(res => {
      this.activities = res.data || []
    })
  },
  methods: {
    // 导出数据
    async fn_export () {
      this.exportLoading = true
      const data = await this.COMM_HTTP.export(this.params)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.exportLoading = false
        return false
      }
      this.handleDownload(data.url)
      this.exportLoading = false
    },
    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },

    getFileName () {
      let _name = ''
      let actName = ''
      actName = this.getActName()
      _name = `[${actName}]-红包记录-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },

    getActName () {
      const _obj = this.activities.find(n => {
        return n.id === this.activity_id
      })
      return _obj.name
    },

    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activity_id
      }
    },
    async handleReSend ({ record_id }) {
      const res = await this.COMM_HTTP.packetSendAgain({ record_id })
      if (res.send_success) {
        this.$message.success('发送成功！')
        this.queryData()
      } else {
        this.$message.error('发送失败！')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.rd-record {
  display: flex;
  flex-direction: column;
  .select-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    .el-select {
      width: 320px;
    }
  }
  ::v-deep {
    .el-icon-warning {
      font-size: 16px;
      color: #faad14;
    }
  }
}
</style>
