var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c(
        "layout-table",
        {
          attrs: {
            pageTotal: _vm.total,
            loading: _vm.loading,
            tableCenter: true
          },
          on: { pageChange: _vm.pageChange },
          scopedSlots: _vm._u([
            {
              key: "toolBar",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        round: "",
                        type: "primary",
                        icon: "iconfont icon-plus"
                      },
                      on: { click: _vm.fn_addRole }
                    },
                    [_vm._v("添加角色")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.roleList } },
            [
              _c("el-table-column", {
                attrs: { prop: "name, mask", label: "角色名称", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: { "line-height": "1" }
                          },
                          [
                            scope.row.type === 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "qy-tag qy-tag--primary",
                                    staticStyle: { width: "24px" }
                                  },
                                  [_vm._v("官")]
                                )
                              : _vm._e(),
                            _c("span", [_vm._v(_vm._s(scope.row.name))]),
                            scope.row.is_default === 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "qy-tag qy-tag--warning qy-tag--mini",
                                    staticStyle: {
                                      "margin-left": "6px",
                                      "margin-right": "0"
                                    }
                                  },
                                  [_vm._v("默认")]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "desc", label: "角色描述", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.desc
                          ? [
                              scope.row.desc.length > 24
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        placement: "top-start"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { width: "400px" },
                                          attrs: { slot: "content" },
                                          slot: "content"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.desc) + " "
                                          )
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.desc.slice(0, 24)) +
                                            "..."
                                        )
                                      ])
                                    ]
                                  )
                                : _c("span", [_vm._v(_vm._s(scope.row.desc))])
                            ]
                          : [_vm._v("-")]
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mask",
                  label: "数据范围",
                  "min-width": "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.mask === 0
                          ? [_vm._v("个人")]
                          : scope.row.mask === 1
                          ? [_vm._v("全局")]
                          : scope.row.mask === 2
                          ? [_vm._v("部门")]
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "wecom_users_count",
                  label: "员工数量",
                  "min-width": "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: scope.row.wecom_users_count === 0
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_jumpToEmpList(scope.row.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(scope.row.wecom_users_count) + " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "200",
                  "class-name": "operation-cell"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type === 3
                          ? _c(
                              "el-button",
                              {
                                staticClass: "ly-gap-button",
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_editRole(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        scope.row.type === 3
                          ? [
                              scope.row.wecom_users_count > 0 ||
                              scope.row.is_default === 1
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                        "popper-class": "q-tooltip"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content"
                                        },
                                        [
                                          scope.row.wecom_users_count > 0
                                            ? [
                                                _vm._v(
                                                  " 当前角色已被使用，不可删除！ "
                                                )
                                              ]
                                            : scope.row.is_default === 1
                                            ? [
                                                _vm._v(
                                                  " 当前角色已被设为默认角色，不可删除！ "
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "q-info ly-gap-button ly-disable"
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "ly-gap-button",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.fn_deleteRole(scope.row.id)
                                        }
                                      }
                                    },
                                    [_vm._v(" 删除 ")]
                                  )
                            ]
                          : _vm._e(),
                        scope.row.is_default === 0 &&
                        ((scope.row.type !== 1 && scope.row.mask !== 2) ||
                          (scope.row.type === 1 && scope.row.mask === 0))
                          ? [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ly-gap-button",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.fn_defaultRole(
                                        scope.row.name,
                                        scope.row.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("设为默认")]
                              )
                            ]
                          : _vm._e(),
                        scope.row.mask !== 2 && scope.row.id !== 4
                          ? _c("ly-emp-btn", {
                              ref: "lyEmpRef",
                              staticClass: "ly-gap-button ly-emp-btn",
                              attrs: {
                                btnText: "添加人员",
                                title: "添加" + scope.row.name,
                                diabledList: [scope.row.id, 4],
                                "ready-text": "原角色将会被覆盖",
                                showSelect: false
                              },
                              on: {
                                getEmpId: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.getEmpId.apply(
                                    void 0,
                                    [scope.row.id].concat(argsArray)
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "ly-table-empty",
                  attrs: { slot: "empty" },
                  slot: "empty"
                },
                [
                  _vm._t("empty", [
                    _c("img", {
                      attrs: {
                        src: require("@assets/svg/default/no_data.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" 暂无数据 ")
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }