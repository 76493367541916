<template>
  <el-main class="inner is-dark detail">
    <!-- 员工信息 -->
    <div class="detail-base ly-card is-margin">
      <div class="detail-base__header flex flex-between">
        <div class="ly-table__main-title">员工信息</div>
        <div class="ly-link is-dark" @click="jumpToAnalyse">
          查看完整客户数据<i class="ri-arrow-right-s-line"></i>
        </div>
      </div>
      <div class="flex flex-between">
        <div class="flex">
          <div class="detail-base__avatar">
            <el-avatar :size="64" :src="info.avatar_url || defaultAvatar" />
          </div>
          <div class="flex flex-column flex-start">
            <div class="flex" style="margin-bottom: 8px">
              <span class="detail-base__name">{{ info.name || "-" }}</span>
              <template v-if="info.role">
                <el-tag
                  size="small"
                  type="warning"
                  v-if="info.role === '管理员'"
                  >{{ info.role }}</el-tag
                >
                <el-tag size="small" type="primary" v-else>{{
                  info.role
                }}</el-tag>
              </template>
            </div>
            <div class="q-info">别名：{{ info.alias || "-" }}</div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-column">
            <div class="q-info">今日新增客户</div>
            <div class="detail-num">{{ info.today_add }}</div>
          </div>
          <div class="ly-gap is-vertical"></div>
          <div class="flex flex-column">
            <div class="q-info">今日流失客户</div>
            <div class="detail-num">{{ info.today_del }}</div>
          </div>
          <div class="ly-gap is-vertical"></div>
          <div class="flex flex-column">
            <div class="q-info">客户总数</div>
            <div class="detail-num">{{ info.total }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 聊天数据 -->
    <div class="ly-card is-margin">
      <div
        class="ly-table__main-header flex flex-between"
        style="margin-bottom: 24px"
      >
        <div class="flex">
          <div class="ly-table__main-title">聊天数据</div>
          <div class="ly-search">
            <el-form
              :inline="true"
              :model="searchForm"
              class="demo-form-inline"
              size="medium"
            >
              <el-form-item style="margin-bottom: 0">
                <el-button-group>
                  <el-button
                    :type="btnState === 'day' ? 'primary' : ''"
                    @click="getAnalyse('day')"
                    >昨日</el-button
                  >
                  <el-button
                    :type="btnState === 'week' ? 'primary' : ''"
                    @click="getAnalyse('week')"
                    >近7日</el-button
                  >
                  <el-button
                    :type="btnState === 'month' ? 'primary' : ''"
                    @click="getAnalyse('month')"
                    >近30日</el-button
                  >
                </el-button-group>
              </el-form-item>
              <el-form-item label="自定义：" style="margin-bottom: 0">
                <el-date-picker
                  v-model="dates"
                  type="daterange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  @change="changeTime"
                  style="width: 260px"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="ly-link is-dark" @click="jumpToAnalyseCus">
          查看完整聊天数据<i class="ri-arrow-right-s-line"></i>
        </div>
      </div>
      <el-row :gutter="16">
        <el-col :span="6" v-for="(cItem, cIndex) in cardData" :key="cIndex">
          <div class="ly-card is-gray">
            <div class="q-info">{{ cItem.title }}</div>
            <div>
              <span class="detail-num">{{ cItem.count }}</span
              ><span class="q-info">{{ cItem.sub }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 客户列表 -->
    <layout-table
      :pageTotal="total"
      @pageChange="pageChange"
      :loading="loading"
      :page="page"
    >
      <div class="ly-table__main-header flex flex-between">
        <div class="flex">
          <div class="ly-table__main-title">客户列表</div>
          <span>共{{ total }}个客户</span>
          <span class="ly-gap is-vertical"></span>
          {{ added }}个已添加，{{ deled }}个已删除
        </div>
      </div>

      <el-table :data="tableData" style="width: 100%" @sort-change="tableSort">
        <el-table-column
          prop="avatar, name, corp_name"
          label="全部客户"
        >
          <template slot-scope="scope">
            <div class="flex">
              <img
                v-if="scope.row.avatar_url"
                :src="scope.row.avatar_url"
                alt=""
                class="ly-table__main-avatar"
              />
              <img
                v-else
                src="../../../assets/images/default_avatar.png"
                alt=""
                class="ly-table__main-avatar"
              />
              <div>
                {{ scope.row.name }}
                <span class="q-warning" v-if="scope.row.corp_name"
                  >@{{ scope.row.corp_name }}</span
                >
                <span class="q-success" v-else>@微信</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="客户标签">
          <template slot-scope="scope">
            <ly-drow :list="scope.row.tags" dep></ly-drow>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="客户状态">
          <template slot-scope="scope">
            <ly-status v-if="scope.row.status === 0" type="success"
              >已添加</ly-status
            >
            <ly-status v-else-if="scope.row.status === 1" type="danger"
              >已删除</ly-status
            >
          </template>
        </el-table-column>
        <el-table-column prop="added_at" label="添加时间" sortable="custom">
          <template slot-scope="scope">
            <div class="ly-table__main-time">
              {{ util.timeTofmt(scope.row.added_at, "yyyy-MM-dd hh:mm") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" class-name="operation-cell">
          <template slot-scope="scope">
            <el-button type="text" @click="jumpToCus(scope.row)"
              >客户详情</el-button
            >
          </template>
        </el-table-column>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>
    </layout-table>
  </el-main>
</template>

<script>
import layoutTable from '@/components/layout/lyTable'
import lyDrow from '@/components/lyDrow'
export default {
  name: 'detail',
  data () {
    return {
      defaultAvatar: require('@assets/images/default_avatar.png'),
      id: null,
      info: {
        avatar_url: '',
        today_add: 0,
        today_del: 0,
        total: 0,
        user_id: 0,
        alias: '-'
      },
      btnState: 'week',
      dates: [],
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        onPick: (time) => {
          return this.onPick(time)
        }
      },
      cardData: [
        {
          title: '聊天总数',
          sub: '条',
          count: 0
        },
        {
          title: '发送消息数',
          sub: '条',
          count: 0
        },
        {
          title: '已回复聊天占比',
          sub: '%',
          count: 0
        },
        {
          title: '平均首次回复时长',
          sub: 'min',
          count: 0
        }
      ],
      tableData: [],
      total: 0,
      deled: 0,
      added: 0,
      loading: false,
      searchForm: {
        begin_at: '',
        end_at: '',
        user_ids: null
      },
      page: 1,
      clientForm: {
        is_desc: true,
        order_by: 'added_at'
      },
      clientAnalysis: false,
      empAnalysis: false,
      clientDetail: false
    }
  },
  created () {
    this.id = this.$route.query.id
    this.searchForm.user_ids = this.id
    this.getEmpInfo()
    this.getAnalyse('week')
    this.getEmpClients()
  },
  methods: {

    // 设置时间范围
    disabledDate (time) {
      const timeOptionRange = this.timeOptionRange
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _time = _date.getTime()
      if (timeOptionRange) {
        const secondNum = 30 * 8.64e7
        const _maxTime = timeOptionRange.getTime() + secondNum < (_time - 1 * 8.64e7) ? timeOptionRange.getTime() + secondNum : (_time - 1 * 8.64e7)
        const _minTime = timeOptionRange.getTime() - secondNum > (_time - 151 * 8.64e7) ? timeOptionRange.getTime() - secondNum : (_time - 151 * 8.64e7)
        return time.getTime() >= _maxTime || time.getTime() < _minTime
      } else {
        return time.getTime() >= _time - 1 * 8.64e7 || time.getTime() < _time - 151 * 8.64e7
      }
    },

    // 点击时设置
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },

    // 切换时间
    changeTime (val) {
      if (val) {
        this.searchForm.begin_at = this.util.timeTofmt(val[0])
        this.searchForm.end_at = this.util.timeTofmt(val[1])
        this.btnState = ''
        this.getAnalyse()
      } else {
        this.searchForm.begin_at = null
        this.searchForm.end_at = null
        this.getAnalyse('week')
      }
    },

    // 员工详情
    async getEmpInfo () {
      const data = await this.axios.get('empInfo', {
        params: {
          user_id: this.id
        }
      })
      this.info = data
    },

    // 聊天数据
    async getAnalyse (type) {
      let beginAt, endAt
      if (type) {
        this.btnState = type
        let time = await this.axios.get('getTime')
        time *= 1000
        endAt = this.util.timeTofmt(time - 1 * 8.64e7)
        switch (type) {
          case 'day':
            beginAt = this.util.timeTofmt(time - 2 * 8.64e7)
            break
          case 'week':
            beginAt = this.util.timeTofmt(time - 7 * 8.64e7)
            break
          case 'month':
            beginAt = this.util.timeTofmt(time - 30 * 8.64e7)
            break
        }
        this.$nextTick(() => {
          this.dates = [beginAt, endAt]
        })
        this.searchForm.begin_at = beginAt
        this.searchForm.end_at = endAt
      }

      const data = await this.axios.get('chatAnalysis', {
        params: {
          ...this.searchForm
        }
      })

      const _summary = data.summary
      this.cardData[0].count = _summary.chat.curr
      this.cardData[1].count = _summary.msg.curr
      this.cardData[2].count = _summary.reply_rate.curr
      this.cardData[3].count = _summary.reply_time.curr
    },

    // 客户列表
    async getEmpClients () {
      const data = await this.axios.get('empClients', {
        params: {
          page: this.page,
          per_page: 10,
          user_id: this.id,
          ...this.clientForm
        }
      })
      this.total = data.total
      this.deled = data.deled
      this.added = data.added
      this.tableData = data.list
    },

    // 客户列表分页监听
    pageChange (val) {
      this.page = val
      this.getEmpClients()
    },

    // 跳转客户分析
    jumpToAnalyse () {
      const routeData = this.$router.resolve({
        path: '/accountOperation/overview/analysis',
        query: {
          id: this.id
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 跳转聊天分析
    jumpToAnalyseCus () {
      const form = {
        user_ids: this.searchForm.user_ids
      }
      if (this.btnState) {
        form.btnState = this.btnState
      } else {
        form.begin_at = this.searchForm.begin_at
        form.end_at = this.searchForm.end_at
      }
      const routeData = this.$router.resolve({
        path: '/enterprise/employees/analysis',
        query: {
          ...form
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 客户详情
    jumpToCus (item) {
      const routeData = this.$router.resolve({
        path: '/accountOperation/overview/detail',
        query: {
          id: item.user_id
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 排序监听
    tableSort (column) {
      this.page = 1
      if (column.order === null) {
        this.clientForm.direction = null
      } else {
        this.clientForm.is_desc = column.order === 'descending'
      }
      this.getEmpClients()
    }

  },
  components: {
    layoutTable,
    lyDrow
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/table.scss";
@import "@/assets/scss/components/card.scss";
@import "@/assets/scss/components/gap.scss";

.ly-card {
  &.is-gray {
    padding: 16px 24px;
    background: #F8FAFD;
    box-shadow: unset;
  }
}

.detail {
  &-base {
    &__header {
      margin-bottom: 16px;
    }

    &__avatar {
      position: relative;
      width: 64px;
      height: 64px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 12px;

      img {
        width: 100%;
      }
    }

    &__name {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      color: #212b36;
      margin-right: 8px;
    }

    .ly-gap {
      height: 56px;
      margin: 0 40px;
    }
  }

  &-num {
    display: inline-block;
    font-size: 28px;
    color: #212b36;
    margin-right: 6px;
    line-height: 1;
    margin-top: 8px;
  }
  ::v-deep {
    .ly-table__main {
      border-radius: 16px;
    }
  }
}
.ly-search {
  box-shadow: unset;
  padding: 0;
}
</style>
