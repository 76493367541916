var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "表单详情",
        visible: _vm.visible,
        "custom-class": "q-dialog",
        width: "560px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: _vm.closeDialog
      }
    },
    [
      _c(
        "div",
        { staticClass: "user-info flex" },
        [
          _c("el-avatar", {
            attrs: {
              size: 54,
              src:
                _vm.userInfo.avatarUrl ||
                "http://wx.qlogo.cn/mmhead/XCopLcwfzedShSmWhma2kPnUa4rs3yJtabpichHEtP90lwhuTVqovLA/0"
            }
          }),
          _c("div", { staticClass: "info" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.userInfo.userName || "-"))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "ly-tabs",
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            {
              staticClass: "invite-wrapper",
              attrs: { label: "基本信息", name: "invite" }
            },
            [
              _c("el-scrollbar", { staticStyle: { height: "383px" } }, [
                _c("div", { staticClass: "list-panel" }, [
                  _c("span", { staticClass: "names" }, [_vm._v("提交时间：")]),
                  _c("span", { staticClass: "values" }, [
                    _vm._v(_vm._s(_vm.userInfo.createTime || "-"))
                  ])
                ]),
                _c("div", { staticClass: "list-panel" }, [
                  _c("span", { staticClass: "names" }, [_vm._v("填写来源：")]),
                  _c(
                    "span",
                    { staticClass: "values" },
                    [
                      _vm.userInfo.source === 0
                        ? [_vm._v("裂变活动-任务宝 ")]
                        : _vm._e(),
                      _vm.userInfo.source === 1
                        ? [_vm._v("裂变活动-群裂变 ")]
                        : _vm._e(),
                      _vm.userInfo.source === 2
                        ? [_vm._v("推广链接 ")]
                        : _vm._e(),
                      _vm.userInfo.source === 3
                        ? [_vm._v("推广二维码 ")]
                        : _vm._e(),
                      _vm.userInfo.source === 4
                        ? [_vm._v("裂变活动-抽奖 ")]
                        : _vm._e()
                    ],
                    2
                  )
                ]),
                _vm.userInfo.source < 2
                  ? _c("div", { staticClass: "list-panel" }, [
                      _c("span", { staticClass: "names" }, [
                        _vm._v("活动名称：")
                      ]),
                      _c("span", { staticClass: "values" }, [
                        _vm._v(_vm._s(_vm.userInfo.activityName || "-"))
                      ])
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "表单信息", name: "records" } },
            [
              _c(
                "el-scrollbar",
                { staticStyle: { height: "323px" } },
                [
                  _c("formPreviewEdit", {
                    ref: "formPreviewEdit",
                    attrs: { isEdit: _vm.isEdit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.activeName == "records"
        ? _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("div", { staticClass: "flex_item" }),
              !_vm.isEdit
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.updateEdit()
                        }
                      }
                    },
                    [_vm._v(" 修改 ")]
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.updateHandle()
                        }
                      }
                    },
                    [_vm._v(" 确认修改 ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }