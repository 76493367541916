<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-17 16:44:15
 * @LastEditTime: 2021-12-30 14:31:42
-->
<template>
  <div class="popularize key-detail page-warp">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          size="medium"
          type="primary"
          icon="iconfont icon-download"
          @click="fn_createData"
          :loading="exportLoading"
          :disabled="exportLoading"
          >导出数据</el-button
        >
      </template>
    </ly-table>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { keyWordsDetail, groupData } from './http.js'
import XLSX from 'xlsx'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: keyWordsDetail,
      excelName: '',
      exportLoading: false,
      dataFields: ['群分组',
        '群主',
        '群名称',
        '回复人数',
        '回复次数']
    }
  },
  computed: {
    tableOpt () {
      return {
        operation: 'hide',
        column: [
          {
            label: '选择日期',
            key: ['day_start_time', 'day_end_time'],
            search: true,
            hide: true,
            placeholder: '请输入账号名称',
            type: 'daterange',
            default: [this.fn_setTime(6), this.fn_setTime()],
            formItemProps: {
              clearable: false
            },
            pickerOptions: {
              disabledDate: (time) => {
                if (this.timeOptionRange) {
                  const day1 = 29 * 8.64e7
                  const maxTime = this.timeOptionRange + day1
                  const minTime = this.timeOptionRange - day1
                  return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
                } else {
                  return time.getTime() > Date.now()
                }
              },
              onPick: (time) => {
                if (time.minDate && !time.maxDate) {
                  this.timeOptionRange = new Date(time.minDate).getTime()
                }
                if (time.maxDate) {
                  this.timeOptionRange = null
                }
              }
            }
          },
          {
            label: '选择时段',
            prop: 'hour',
            key: 'hour',
            search: true,
            hide: true,
            placeholder: '全部分组',
            type: 'select',
            source: this.getHourList,
            sourceField: {
              value: 'id',
              label: 'name'
            },
            default: -1,
            clearable: 0
          },
          {
            label: '群分组',
            prop: 'topics',
            key: 'group_topic_id',
            search: true,
            type: 'select',
            source: groupData.getTopic,
            placeholder: '全部群组',
            sourceField: {
              value: 'id',
              label: 'name'
            },
            default: -1,
            clearable: 0,
            formatter (row, column, cellValue, index) {
              if (row.topics.length === 0) {
                return '未分组'
              } else {
                const arr = row.topics.map(e => {
                  return e.name
                })
                return arr.join(',')
              }
            }
          },
          {
            label: '群主',
            prop: 'owner',
            search: true,
            type: 'select',
            placeholder: '全部',
            source: this.getOwner,
            key: 'owner_serial_no',
            sourceField: {
              value: 'member_serial_no',
              label: 'name'
            },
            formatter (row, column, cellValue, index) {
              return cellValue.name
            }
          },
          {
            label: '群名称',
            prop: 'name',
            key: 'group_name',
            search: true,
            placeholder: '请输入群名称',
            formatter (row, column, cellValue, index) {
              return cellValue || '群聊'
            }
          },
          {
            label: '回复人数',
            prop: 'key_say_user_total',
            align: 'center'
          },
          {
            label: '回复次数',
            prop: 'key_say_count',
            align: 'center'
          }
        ]
      }
    }
  },
  mounted () {
    this.setGoBackText()
  },
  methods: {
    // 导出
    fn_exportExcel (data) {
      const _data = []
      for (let i = 0; i < data.length; i++) {
        const values = []
        for (const key in data[i]) {
          values.push(data[i][key])
        }
        _data[i] = values
      }
      const aoa2 = [this.dataFields, ..._data]
      const worksheet = XLSX.utils.aoa_to_sheet(aoa2)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, this.excelName)
      XLSX.writeFile(workbook, `${this.excelName}.xlsx`)
    },
    // 设置时间
    fn_setTime (late, format, date) {
      let _now = null
      if (date) {
        _now = new Date(date * 1000)
      } else {
        _now = new Date()
      }
      let _time = null
      _now.setHours(0)
      _now.setMinutes(0)
      _now.setSeconds(0)
      if (late) {
        _time = new Date(_now.getTime() - late * 8.64e7).getTime()
      } else {
        _time = new Date(_now.getTime()).getTime()
      }

      if (format) {
        _time = parseInt(_time / 1000)
      }
      return _time
    },
    setGoBackText () {
      this.$store.commit('_SET', {
        'global.cusTitle': ` - ${this.$route.query.type}`
      })
      this.excelName = `${this.$route.meta.title} - ${this.$route.query.type}`
    },
    // 群分组数据
    async getTopic () {
      const res = await groupData.getTopic()

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      console.log('topic', res.filter(item => item.name !== ''))
      return res.filter(item => item.name !== '')
    },
    // 选择时段数据
    async getHourList () {
      const list = [
        {
          id: -1,
          name: '00:00  ~  24:00'
        }
      ]

      for (let i = 1; i < 25; i++) {
        const prev = (i - 1) < 10 ? '0' + (i - 1) : (i - 1)
        const next = i < 10 ? '0' + i : i
        list.push({
          id: i - 1,
          name: `${prev}:00  ~  ${next}:00`
        })
      }
      return list
    },
    // 群主
    async getOwner () {
      const res = await groupData.getOwner({
        group_topic_id: -1
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      return res
    },
    formatterParams (params) {
      const defaultParams = {
        day_start_time: this.fn_setTime(6, true),
        day_end_time: this.fn_setTime(null, true),
        start_time: 0,
        end_time: 86400,
        group_topic_id: -1,
        key: this.$route.query.type
      }
      if (params.day_end_time) {
        params.day_end_time = this.fn_setTime(null, true, params.day_end_time)
      }
      const _params = Object.assign(defaultParams, params)
      return {
        ..._params
      }
    },
    // 导出
    async fn_createData () {
      this.exportLoading = true
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: 1,
        per_page: 9999,
        ...this.sort,
        ...this.params
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        this.exportLoading = false
        return false
      }

      const list = res.list
      const _data = []
      if (list) {
        for (const items in list) {
          _data.push({
            topics: list[items].topics.length > 0 ? list[items].topics.map(item => item.name).join(',') : '未分组',
            owner: list[items].owner.name,
            name: list[items].name,
            key_say_user_total: list[items].key_say_user_total,
            key_say_count: list[items].key_say_count
          })
        }
      }

      this.exportLoading = false
      this.fn_exportExcel(_data)
    }
  },

  beforeRouteLeave (to, from, next) {
    this.$store.commit('_SET', {
      'global.cusTitle': ''
    })
    next()
  }
}
</script>

<style lang="scss" scoped>
.key-detail {
  ::v-deep {
    .el-form-item:last-child {
      .el-form-item__label {
        display: none;
      }
    }
  }
}
</style>
