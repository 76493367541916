var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading"
        }
      ],
      staticClass: "edit-card"
    },
    [
      _c("div", { staticClass: "edit-card__header" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _c(
        "div",
        { staticClass: "edit-card__content" },
        [
          _c(
            "el-form",
            {
              ref: "basicFormRef",
              staticStyle: { width: "60%", "min-width": "560px" },
              attrs: {
                model: _vm.basicForm,
                rules: _vm.rules,
                "label-width": "150px",
                size: "medium"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "16",
                      "show-word-limit": "",
                      placeholder: "不对外展示，用户区分活动使用场景"
                    },
                    on: {
                      input: function($event) {
                        return _vm.fn_setBasic("title", $event)
                      }
                    },
                    model: {
                      value: _vm.basicForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.basicForm, "title", $$v)
                      },
                      expression: "basicForm.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动结束时间", prop: "end_date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "请选择时间",
                      "popper-class": "end-date-picker",
                      "picker-options": _vm.endDatePickerOptions
                    },
                    on: {
                      change: function($event) {
                        return _vm.fn_setBasic("end_date", $event)
                      }
                    },
                    model: {
                      value: _vm.basicForm.end_date,
                      callback: function($$v) {
                        _vm.$set(_vm.basicForm, "end_date", $$v)
                      },
                      expression: "basicForm.end_date"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", error: _vm.wecom_user_idsTips } },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _vm._v("涨粉账号 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " 1.「涨粉账号」会关联「裂变海报」的二维码。"
                              ),
                              _c("br"),
                              _vm._v(
                                " 2.用户扫描海报二维码后会随机平均添加「涨粉账号」好友。 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("ly-emp-btn", {
                    attrs: {
                      btnText: "添加涨粉账号",
                      max: 100,
                      isReal: true,
                      datafilter: true,
                      checkList: _vm.basicForm.wecom_user_ids
                    },
                    on: { getEmpId: _vm.fn_getEmpId }
                  })
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "code_fail_date" } },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _vm._v("二维码失效时间 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " 活动结束后，据失效时间判断新用户是否可以进入活动页 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.basicForm.code_fail_mode,
                        callback: function($$v) {
                          _vm.$set(_vm.basicForm, "code_fail_mode", $$v)
                        },
                        expression: "basicForm.code_fail_mode"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("活动结束后立即失效")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("自定义")
                      ])
                    ],
                    1
                  ),
                  _vm.basicForm.code_fail_mode === 2
                    ? _c("el-date-picker", {
                        staticStyle: { width: "320px" },
                        attrs: {
                          type: "datetime",
                          placeholder: "请选择时间",
                          "popper-class": "expiration-picker",
                          "picker-options": _vm.exPickerOptions
                        },
                        on: {
                          change: function($event) {
                            return _vm.fn_setBasic("code_fail_date", $event)
                          }
                        },
                        model: {
                          value: _vm.basicForm.code_fail_date,
                          callback: function($$v) {
                            _vm.$set(_vm.basicForm, "code_fail_date", $$v)
                          },
                          expression: "basicForm.code_fail_date"
                        }
                      })
                    : _vm._e()
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "welcome_msg" } },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _vm._v("裂变欢迎语 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "light",
                            placement: "right",
                            "popper-class": "q-tooltip is-light"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    margin: "24px 24px 16px",
                                    width: "472px"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "https://image.01lb.com.cn//uploads/wecom/21/0506/16202874779hIE5N66.png",
                                      width: "472",
                                      alt: ""
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-bold",
                                      staticStyle: { margin: "16px 0 8px" }
                                    },
                                    [_vm._v(" 欢迎语下发规则提示： ")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " 若涨粉账号在【企业微信后台】被配置了欢迎语，这里的欢迎语将会失效，请确保所涨粉账号未被配置欢迎语。 "
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c("i", { staticClass: "iconfont icon-info-fill" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("ly-editor", {
                    attrs: {
                      title: "欢迎语",
                      id: "prizeWords",
                      content: _vm.basicForm.welcome_msg,
                      insetMenu: ["客户昵称"],
                      recoverContent: _vm.defaultWelWords,
                      emoji: ""
                    },
                    on: { getContent: _vm.fn_getWelWords }
                  })
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "裂变链接", prop: "link_type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.basicForm.link_type,
                        callback: function($$v) {
                          _vm.$set(_vm.basicForm, "link_type", $$v)
                        },
                        expression: "basicForm.link_type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("默认")]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("自定义")
                      ])
                    ],
                    1
                  ),
                  _vm.basicForm.link_type === 2
                    ? [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: {
                              label: "主图",
                              "label-width": "80px",
                              error: _vm.coverError
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "label" },
                              [
                                _c("span", { staticClass: "q-danger" }, [
                                  _vm._v("*")
                                ]),
                                _vm._v(" 主图 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      placement: "top",
                                      content:
                                        "支持JPG、PNG格式，图片大小2M以内",
                                      "popper-class": "q-tooltip is-light"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-info-fill"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-upload",
                              {
                                class: [
                                  "upload",
                                  _vm.basicForm.link_logo.length === 0
                                    ? ""
                                    : "upload-empty"
                                ],
                                attrs: {
                                  action: "#",
                                  accept: ".png, .jpg, .jpeg",
                                  "http-request": _vm.requestContentImg,
                                  "on-remove": _vm.removeContentImg,
                                  "list-type": "picture-card",
                                  limit: 1,
                                  "file-list": _vm.basicForm.link_logo
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-column flex-center",
                                    staticStyle: {
                                      "line-height": "22px",
                                      height: "100%"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-plus",
                                      staticStyle: { "font-size": "20px" }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text q-info text-small" },
                                      [_vm._v("上传图片")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "标题",
                              prop: "link_title",
                              "label-width": "80px"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "20",
                                "show-word-limit": "",
                                placeholder: "请输入链接标题"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fn_setBasic("link_title", $event)
                                }
                              },
                              model: {
                                value: _vm.basicForm.link_title,
                                callback: function($$v) {
                                  _vm.$set(_vm.basicForm, "link_title", $$v)
                                },
                                expression: "basicForm.link_title"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "描述", "label-width": "80px" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "200",
                                "show-word-limit": "",
                                placeholder: "请输入链接描述"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fn_setBasic("link_desc", $event)
                                }
                              },
                              model: {
                                value: _vm.basicForm.link_desc,
                                callback: function($$v) {
                                  _vm.$set(_vm.basicForm, "link_desc", $$v)
                                },
                                expression: "basicForm.link_desc"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }