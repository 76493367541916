<!--
 * @Author: zhanln
 * @Date: 2021-11-03 16:39:39
 * @LastEditTime: 2022-07-18 16:29:49
 * @LastEditors: zhan
 * @Description: 任务设置
-->

<template>
  <div class="reward" v-loading="cardLoading">
    <div class="reward-tabs">
      <div class="reward-tabs__item" :class="[
        index === activeStep ? 'is-active' : '',
        item.itemValid === 1 ? 'is-error' : '',
      ]" v-for="(item, index) in prizeSteps" :key="index" @click="fn_changeTab(index)">
        {{ item.title }}
      </div>
    </div>

    <!-- 表单 -->
    <reward-item ref="rewardItemRef" @getForm="fn_getForm"></reward-item>
  </div>
</template>

<script>
import rewardItem from './rewardItem'
import { mapState } from 'vuex'
export default {
  name: 'actReward',

  data () {
    return {
      cardLoading: true,
      activeStep: 0,
      prizeSteps: [
        {
          title: '奖品一',
          itemValid: 0
        },
        {
          title: '奖品二',
          itemValid: 0
        },
        {
          title: '奖品三',
          itemValid: 0
        }
      ],
      prizeForms: [null, null, null],
      baseForm: {
        id: 0,
        rate: 0,
        reward_cover: '',
        reward_detail: '',
        reward_detail_img: '',
        reward_number: 1,
        reward_title: '',
        reward_type: 1,
        reward_type_id: '',
        total: 9999,
        type: 1,
        code_id: null,
        code_rule: '',
        code_rule_length: 0,
        form_id: null,
        form_rule: '',
        form_rule_length: 0,
        remain: null
      }
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.livelottery.editType === 'edit'
    })
  },

  methods: {

    // 初始化
    fn_setForm (data) {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          //     // 复制活动，清空兑换码和表单选项
          //     if (this.isCopy || data[i].reward_type_id === '0') {
          //       data[i].reward_type_id = null
          //     }

          if (data[i].type === 1) {
            if (data[i].reward_type === 2) {
              data[i].code_id = data[i].reward_type_id
              data[i].code_rule = data[i].reward_detail
            }

            if (data[i].reward_type === 3) {
              data[i].form_id = data[i].reward_type_id
              data[i].form_rule = data[i].reward_detail
            }
          }

          data[i].user_count = data[i].total - data[i].remain

          // 存
          this.$store.commit('livelottery/SET_INFO', {
            name: `reward_type_${i}`,
            val: data[i].type
          })
          this.$store.commit('livelottery/SET_INFO', {
            name: `reward_title_${i}`,
            val: data[i].reward_title
          })
          this.$store.commit('livelottery/SET_INFO', {
            name: `reward_cover_${i}`,
            val: data[i].reward_cover
          })
          this.$store.commit('livelottery/SET_INFO', {
            name: `reward_rate_${i}`,
            val: data[i].rate
          })
        }

        this.prizeForms = data
      }
      this.fn_setItemForm()
      this.cardLoading = false
    },

    // 设置各阶段表单
    fn_setItemForm (index = 0) {
      let form = {}

      // 如果该阶段表单不为 null， 则直接使用
      if (this.prizeForms[index]) {
        form = this.prizeForms[index]
      } else {
        form = { ...this.baseForm }
        form.reward_number = index + 1
      }

      this.$refs.rewardItemRef.fn_setForm(form)
    },

    // 切换阶段
    fn_changeTab (index) {
      // 点击当前阶段或者禁用的阶段 return
      if (index === this.activeStep) return false

      // // 判断当前阶段是否已完成
      const { itemValid, form } = this.$refs.rewardItemRef.verify()
      if (!itemValid) {
        this.prizeSteps[this.activeStep].itemValid = 1
      } else {
        this.prizeSteps[this.activeStep].itemValid = 2
      }

      // 完成的阶段存储
      this.fn_getForm(form)

      // 切换阶段
      this.activeStep = index

      this.$store.commit('livelottery/SET_INFO', {
        name: 'current_step',
        val: this.activeStep
      })
      this.fn_setItemForm(index)
    },

    // 表单组件回调
    fn_getForm (obj) {
      this.prizeForms[this.activeStep] = obj
    },

    // 奖品内容提交处理
    fn_initReward () {
      const form = this.util.extend(true, [], this.prizeForms)

      for (let i = 0; i < form.length; i++) {
        // if (form[i].type === 2) {
        //   form[i].reward_title = '谢谢参与'
        // }

        if (form[i].reward_type === 1) {
          form[i].reward_type_id = null
        }

        if (form[i].reward_type === 2) {
          form[i].reward_type_id = form[i].code_id ? form[i].code_id + '' : form[i].reward_type_id
          form[i].reward_detail = form[i].code_rule ? form[i].code_rule : form[i].reward_detail
        }

        if (form[i].reward_type === 3) {
          form[i].reward_type_id = form[i].form_id ? form[i].form_id + '' : form[i].reward_type_id
          form[i].reward_detail = form[i].form_rule ? form[i].form_rule : form[i].reward_detail
        }

        delete form[i].diy_rule_length
        delete form[i].code_id
        delete form[i].code_rule
        delete form[i].code_rule_length
        delete form[i].form_id
        delete form[i].form_rule
        delete form[i].form_rule_length
      }

      return form
    },

    // 校验单个奖品内容
    fn_vefiryItem (item) {
      let validItem = true
      let realPrize = true
      const rate = item.rate

      realPrize = true

      if (!item.reward_title || !item.reward_cover) {
        validItem = false
      }

      if (item.reward_type === 1 && (item.reward_detail === '' || item.diy_rule_length > 1000)) {
        validItem = false
      }

      if (item.reward_type === 2 && (!item.code_id || item.code_rule_length > 1000)) {
        validItem = false
      }

      if (item.reward_type === 3 && (!item.form_id || item.form_rule_length > 1000)) {
        validItem = false
      }

      if (item.reward_type === 4 && (this.open_packet === 0 || item.reward_max_cash > this.activity_amount)) {
        validItem = false
      }

      if (this.isEdit && item.total - item.user_count <= 0) {
        validItem = false
      }

      return { realPrize, rate, validItem }
    },

    // 校验内容
    fn_verifyAllItem (type) {
      let valid = true
      let prizeRealNum = 0
      let prizeRate = 0

      // 当前所在的阶段
      const { form } = this.$refs.rewardItemRef.verify(type)
      this.prizeForms[this.activeStep] = form

      // 所有阶段校验
      for (let i = 0; i < this.prizeForms.length; i++) {
        const item = this.prizeForms[i]
        if (!item) {
          valid = false
          this.prizeSteps[i].itemValid = 1
        } else {
          const { realPrize, rate, validItem } = this.fn_vefiryItem(item)
          if (realPrize) {
            prizeRealNum += 1
          }
          prizeRate += rate
          if (!validItem) {
            valid = false
            this.prizeSteps[i].itemValid = 1
          } else {
            this.prizeSteps[i].itemValid = 2
          }
        }
      }

      return { prizeRealNum, prizeRate, valid }
    },

    // 表单校验，通过则返回 form
    verify (type) {
      const { prizeRate, valid } = this.fn_verifyAllItem(type)

      if (!type) {
        // 配置未完成
        if (!valid) {
          this.fn_lyMsg('info', '请完成奖品设置！', 5000)
          return valid
        }

        // // 没有实物/虚拟奖品
        // if (prizeRealNum < 3) {
        //   this.fn_lyMsg('info', '目前只支持设置一个谢谢参与')
        //   return false
        // }

        // // 没有谢谢参与
        // if (prizeRealNum === 4) {
        //   this.fn_lyMsg('info', '奖品类型必须存在一项谢谢参与')
        //   return false
        // }

        // 中奖概率总和不等于100
        if (prizeRate !== 100) {
          this.fn_lyMsg('info', '奖品概率总和应等于100%')
          return false
        }
      }

      const form = this.fn_initReward()

      return form
    }
  },

  components: {
    rewardItem
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";

.reward {
  &-tabs {
    display: inline-flex;
    border-bottom: 1px solid #dfe1e8;
    margin-bottom: 24px;

    &__item {
      position: relative;
      height: 42px;
      display: flex;
      padding: 0 32px;
      align-items: center;
      justify-content: center;
      border: 1px solid #dfe1e8;
      box-sizing: border-box;
      margin-bottom: -1px;
      border-radius: 8px 8px 0 0;
      user-select: none;
      background: #f7f8fc;

      .circle {
        display: inline-flex;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #454d5b;
        margin: 0 4px;
      }

      &:not(.is-active) {
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.is-active {
        color: $--color-primary;
        border-bottom-color: #fff;
        background-color: #fff;
        font-weight: 500;

        .circle {
          background-color: $--color-primary;
        }
      }

      &.is-error {
        color: $--color-danger;
      }

      &.is-disable {
        cursor: not-allowed;
        background-color: #f7f8fc;
        color: #eaebf2;

        .circle {
          background-color: #eaebf2;
        }
      }
    }

    &__tip {
      position: absolute;
      top: -7px;
      left: 0;
      color: $--color-danger;
      background-color: #fff;
      line-height: 14px;
      border-radius: 50%;
    }
  }
}

.rule-form {
  border-top: 1px solid #e4e7ed;
  padding-top: 24px;

  &__radio {
    ::v-deep {
      .el-radio {
        display: block;
        line-height: 36px;
      }

      .el-radio__label {
        display: inline-flex;

        i {
          margin-left: 8px;
        }
      }
    }
  }
}

.rule-form {
  border-top: 1px solid #e4e7ed;
  padding-top: 24px;

  &__radio {
    ::v-deep {
      .el-radio {
        display: block;
        line-height: 36px;
      }

      .el-radio__label {
        display: inline-flex;

        i {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
