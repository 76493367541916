<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-14 15:19:56
 * @LastEditTime: 2021-10-18 14:59:48
-->
<template>
  <el-dialog
    title="添加关键词"
    :visible.sync="visible"
    @close="handleClose"
    width="480px"
  >
    <div class="form-box">
      <label>关键词：</label>
      <el-input
        size="medium"
        v-model.trim="content"
        maxlength="20"
        show-word-limit
        placeholder="请输入关键词"
      ></el-input>
    </div>
    <div class="q-info" style="margin-left: 56px; margin-top: 10px">
      请确保输入正确关键词，保存后无法修改
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button round type="primary" size="medium" @click="handleSave"
        >保 存</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { keyWords } from '../http'
export default {
  data () {
    return {
      visible: false,
      content: '',
      msgBox: null
    }
  },
  methods: {
    async handleSave () {
      if (this.msgBox) {
        this.msgBox.close()
      }
      if (this.content === '') {
        this.msgBox = this.$message.info('请输入要添加的关键词！')
        return false
      }
      const res = await keyWords.reqAdd({
        content: this.content
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.msgBox = this.$message.error(res.msg)
        return false
      }
      this.$message.success('添加成功！')
      this.visible = false
      this.$emit('change')
    },
    handleClose () {
      this.isEdit = false
      this.editId = ''
      this.content = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.form-box {
  display: flex;
  align-items: center;
  label {
    color: #212b36;
  }
  .el-input {
    width: 248px;
  }
}
</style>
