<!--
 * @Author: zhanln
 * @Date: 2022-03-03 16:57:57
 * @LastEditTime: 2022-04-21 10:20:12
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <el-container class="agr">
    <el-main>
      <!-- <div class="agr-steps ly-shadow">
        <div class="agr-steps__item">
          <span class="agr-steps__step active">
            <template v-if="step === 2"
              ><i class="iconfont icon-selected"></i
            ></template>
            <template v-else> 1 </template></span
          >客服基本信息
        </div>
        <div class="agr-steps__line"></div>
        <div :class="['agr-steps__item', step === 2 ? '' : 'q-info']">
          <span :class="['agr-steps__step', step === 2 ? 'active' : '']">2</span
          >会话范围设置
        </div>
      </div> -->
      <div class="agr-block ly-shadow">
        <el-scrollbar class="agr-block__scroll">
          <account ref="accountRef" type="new" class="agr-account"></account>
          <setting ref="settingRef" type="new" :table-height="400"></setting>
        </el-scrollbar>
      </div>
    </el-main>
    <el-footer class="agr-footer">
      <el-button round v-if="step === 2" @click="step = 1" :loading="btnLoading"
        >上一步</el-button
      >
      <el-button round type="primary" @click="fn_confirm" :loading="btnLoading"
        >确认</el-button
      >
    </el-footer>
  </el-container>
</template>

<script>
import account from './components/account'
import setting from './components/setting'
import { agrs } from './http.js'
export default {
  name: 'edit',

  components: {
    account,
    setting
  },

  data () {
    return {
      step: 1,
      backComfirm: true,
      form: {},
      btnLoading: false
    }
  },

  mounted () {
    this.$refs.settingRef.pageLoading = false
    window.addEventListener('beforeunload', this.fn_beforeunload, false)
  },

  methods: {

    // 确认
    fn_confirm () {
      const accountForm = this.$refs.accountRef.fn_verify(false)
      if (!accountForm) return

      const setting = this.$refs.settingRef.fn_verify(false)
      if (!setting) {
        return false
      }

      this.form = {
        account: accountForm.name,
        passwd: accountForm.password
      }
      this.form.group_remind = setting.notice
      this.form.user_ids = setting.ids

      this.fn_submit()
    },

    // 提交
    async fn_submit () {
      this.btnLoading = true
      const data = await agrs.create(this.form)
      if ((data && data.code) || (data && data.code === 0)) {
        this.btnLoading = false
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.backComfirm = false
      this.fn_lyMsg('success', '创建客服成功！')
      this.$router.push('list')
      this.btnLoading = false
    },

    // 离开确认(window)
    fn_beforeunload (e) {
      e = e || window.event
      if (e) {
        e.returnValue = ''
      }
      return ''
    }
  },

  // 离开确认
  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '离开此页面后，当前页面填写信息将不会保存，是否确认离开？'
        }).then(async () => {
          next()
        }).catch(() => { })
        return
      }
      next()
    }
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.fn_beforeunload, false)
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/utils.scss";
.agr {
  height: calc(100% - 48px);

  .el-main {
    height: 100%;
    overflow: hidden;
  }

  &-steps {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &__item {
      line-height: 1;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    &__step {
      background-color: #b4bbcc;
      display: inline-flex;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-right: 8px;

      &.active {
        background-color: $--color-primary;
      }
    }

    &__line {
      height: 1px;
      width: 328px;
      margin: 0 8px;
      background-color: #dfe1e8;
    }
  }

  &-block {
    height: calc(100% - 40px - 20px);
    overflow: hidden;
    border-radius: 16px;
    background-color: #fff;
    box-sizing: border-box;

    &__scroll {
      height: 100%;
    }

    ::v-deep .ly-table-main {
      box-shadow: unset;
      padding: 0 !important;
      border: 1px solid #dfe1e8;
    }

    ::v-deep {
      .agrForm-title,
      .agrSetting-title {
        position: relative;
        line-height: 24px;
        font-size: 16px;
        padding-left: 12px;
        text-align: left;
        height: 56px;
        line-height: 56px;
        display: flex;
        font-weight: bold;
        margin: 0;
        margin-left: 20px;

        &::before {
          position: absolute;
          content: "";
          top: 50%;
          left: 0;
          width: 4px;
          height: 16px;
          background-color: #2b7cff;
          border-radius: 2px;
          transform: translateY(-50%);
        }
      }

      .agrSetting-item {
        padding-top: 20px;
      }

      .agrSetting-form__lable {
        padding-left: 80px;
      }
    }
  }

  &-account {
    width: 560px;
    margin-top: 12px;

    ::v-deep .agrForm {
      padding-left: 80px;
    }
  }

  &-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
    z-index: 999;
  }
}
</style>
