var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-panel" },
    [
      _c("div", { staticClass: "main-title mb-24 pl-16" }, [
        _vm._v("数据概览")
      ]),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "el-col-wrapper" }, [
              _c("div", { staticClass: "title flex flex-between" }, [
                _vm._v("访问人数")
              ]),
              _c("div", { staticClass: "nums" }, [
                _vm._v(_vm._s(_vm.panelInfo.yesterdayAddUserCount || 0))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "el-col-wrapper" }, [
              _c("div", { staticClass: "title flex flex-between" }, [
                _vm._v("总提交")
              ]),
              _c("div", { staticClass: "nums" }, [
                _vm._v(_vm._s(_vm.panelInfo.yesterdayRetentionRate || 0))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "el-col-wrapper" }, [
              _c("div", { staticClass: "title flex flex-between" }, [
                _vm._v("提交率")
              ]),
              _c("div", { staticClass: "nums" }, [
                _vm._v(_vm._s(_vm.panelInfo.todayAddUserCount || 0) + "%")
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }