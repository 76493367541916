<!--
 * @Author: zhanln
 * @Date: 2021-08-19 17:47:32
 * @LastEditTime: 2021-12-09 14:50:56
 * @LastEditors: Please set LastEditors
 * @Description: 个人活码数据分析
-->

<template>
  <div class="qr-analysis">
    <div class="qr-analysis-wrap navTabs">
      <el-header height="48px" class="flex pH">
        活码名称：{{ $route.query.name }}
      </el-header>
      <!-- -->
      <!-- <el-col :span="12">
          <el-tabs v-model="navIndex" @tab-click="fn_setTab">
            <el-tab-pane
              :label="item.name"
              :name="item.name"
              :index="item.name"
              v-for="(item, index) in tabs"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
        </el-col> -->
      <!-- </el-row> -->
    </div>
    <div class="qr-analysis-panel grey-wrapper" id="dataPanel">
      <analysis-panel :panelInfo="panelInfo" />
    </div>
    <div class="grey-wrapper card-wrapper" id="growthTrendPanel">
      <el-card>
        <div class="main-title">增长趋势</div>
        <div class="time-wrapper">
          <timeSelect @emitInfo="timeSelectEmit" :startTime="startTime" />
          <!-- <el-form ref="form" inline size="medium">
            <el-form-item>
              <el-radio-group v-model="radioButtonTime">
                <el-radio-button label="近7天"></el-radio-button>
                <el-radio-button label="近30天"></el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="自定义：">
              <el-date-picker
                v-model="pickerDate"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form> -->
        </div>

        <el-row class="flex flex-between">
          <div class="flex flex_item">
            <el-radio-group v-model="radioButtonCharts" @change="changeRadio">
              <el-radio-button label="用户数"></el-radio-button>
              <el-radio-button label="新增用户数"></el-radio-button>
              <el-radio-button label="新增留存率"></el-radio-button>
            </el-radio-group>

            <div class="flex remarksInfo ml-18">
              统计说明
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                popper-class="q-tooltip"
              >
                <div slot="content">
                  用户数：当天留存的用户数<br />
                  新增用户数：扫码添加（包含当天已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算；合计：每日新增用户数之和<br />
                  新增留存率：新增且当天未删除的用户数/当天新增用户数；合计：新增且该段时间内未删除的用户数/该段时间内新增用户数
                </div>
                <i class="iconfont icon-info-fill" style="color: #b4bbcc"></i>
              </el-tooltip>
            </div>
          </div>
          <el-button
            @click="exportExcel"
            type="primary"
            round
            icon="iconfont icon-download"
            >导出图表</el-button
          >
        </el-row>

        <lyChart :option="chartOption" height="450px" />

        <el-table
          :data="tableData"
          border
          style="width: 100%"
          class="mt-40"
          ref="tableRef"
        >
          <el-table-column align="center" prop="time" label="时间">
          </el-table-column>
          <el-table-column
            align="center"
            prop="aliveUserCount"
            label="总用户数"
          >
            <template #header>
              总用户数
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                popper-class="q-tooltip"
              >
                <div slot="content">当天留存的用户数</div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="addUserCount"
            label="新增用户数"
          >
            <template #header>
              新增用户数
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                popper-class="q-tooltip"
              >
                <div slot="content">
                  扫码添加（包含当天已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算；合计：每日新增用户之和
                </div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="addRetentionRate"
            label="新增留存率"
          >
            <template #header>
              新增留存率
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                popper-class="q-tooltip"
              >
                <div slot="content">
                  新增且当天未删除的用户数/新增用户数；合计：该段时间内新增且未删除的用户数/该段时间内新增用户数
                </div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty" class="ly-table-empty">
            <slot name="empty">
              <img src="@assets/svg/default/no_data.svg" alt="" />
              暂无数据
            </slot>
          </div>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import lyChart from '@components/lyChart'
import analysisPanel from './components/analysisPanel.vue'
import timeSelect from './components/timeSelect.vue'
import { exportExcelHandle } from '@/common/excel.js'
import { analysis } from './http'
export default {
  data () {
    return {
      tabs: [{
        name: '数据概览'
      }, {
        name: '增长趋势'
      }],
      navIndex: '数据概览',
      pickerDate: [],
      panelInfo: {},
      radioButtonCharts: '用户数',
      chartOption: {},
      tableData: [],
      startTime: ''
    }
  },
  created () {
    this.getPageInfo()
    this.getCodeStartTime()
    // this.$store.commit('_SET', {
    //   'global.cusTitle': ` - ${this.$route.query.name}`
    // })
  },
  mounted () {
    // main主体滚动监测
    this.$nextTick(() => {
      const main = document.querySelector('.qr-analysis')
      main && main.addEventListener('scroll', this.fn_getScroll)
    })
  },
  methods: {
    initCharts () {
      const _slice = JSON.parse(JSON.stringify(this.resData))
      _slice.splice(_slice.length - 1, 1)
      const _times = _slice.map(item => item.time)
      const _name = this.radioButtonCharts

      const _mj = { 用户数: 'aliveUserCount', 新增用户数: 'addUserCount', 新增留存率: 'addRetentionRate' }
      const _keys = _mj[_name]

      const _value = _slice.map(item => item[_keys])

      let min = 0
      let max = 0
      let _sortArr = JSON.parse(JSON.stringify(_value))

      if (_keys !== 'addRetentionRate') {
        _sortArr = _sortArr.sort((a, b) => b - a)

        max = _sortArr.shift()
        min = _sortArr.pop()

        max = Math.ceil(max * 1.1)
        min = Math.floor(min * 0.9)
        max = max > 10 ? max : 10
      } else {
        min = 0
        max = 100
      }

      const tooltip = {
        show: true, // 是否显示
        trigger: 'axis'
      }

      const yAxis = {
        type: 'value',
        minInterval: 1,
        min: 0,
        max: max
      }

      if (_keys == 'addRetentionRate') {
        Object.assign(yAxis, {
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %'
          }
        })
        tooltip.formatter = function (arg) {
          let str = `<div>${arg[0].axisValue}</div>`
          arg.forEach(item => {
            str += `<div>${item.marker}${item.seriesName}：${arg[0].data}%</div>`
          })
          return str
        }
      }

      const json = {
        title: {
          text: ''
        },
        tooltip: tooltip,
        grid: {
          left: '3%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: false
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: _times
        },
        yAxis: yAxis,
        color: ['#2B7CFF'],
        series: [
          {
            name: _name,
            type: 'line',
            data: _value,
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: '#fff'
                },
                {
                  offset: 0,
                  color: '#EBF3FF'
                }
              ])
            }
          }
        ]
      }

      this.chartOption = json
    },
    changeRadio () {
      this.initCharts()
    },

    // 获取活码开始时间
    async getCodeStartTime () {
      const data = await analysis.getCodeStartTime({
        idList: [this.$route.query.id],
        type: 0
      })

      // console.log(data)
      this.startTime = data
    },
    async getPageInfo () {
      const data = await analysis.simpleRecordOverview({
        liveCodeId: this.$route.query.id
      })
      this.panelInfo = data
    },
    async growthTrend (timeArr) {
      const data = await analysis.simpleGrowthTrend({
        endTime: this.util.timeTofmt(timeArr[1]),
        liveCodeId: this.$route.query.id,
        startTime: this.util.timeTofmt(timeArr[0])
      })
      Object.assign(data[data.length - 1], {
        time: data[data.length - 1].name
      })

      const res = JSON.parse(JSON.stringify(data))
      this.resData = data

      res.forEach(item => {
        item.addRetentionRate = item.addRetentionRate ? item.addRetentionRate + '%' : item.addRetentionRate
      })

      this.tableData = res
      this.initCharts()

      this.fn_iniscroll()
    },
    timeSelectEmit (timeArr) {
      this.growthTrend(timeArr)
    },
    exportExcel () {
      exportExcelHandle({
        xlsxName: `${this.$route.query.name}的增长明细.xlsx`,
        sourceData: this.tableData,
        header: ['日期', '总用户数', '新增用户数', '新增留存率'],
        filterVal: ['time', 'aliveUserCount', 'addUserCount', 'addRetentionRate']
      })
    },

    // 页面刷新
    fn_beforeunload (e) {
      e = e || window.event
      if (e) {
        e.returnValue = ''
      }
      return ''
    },
    fn_setTab () {
      if (this.navIndex == '增长趋势') {
        this.scrollEl.scrollTo(0, this.growthTrendPanel - 150)
      } else {
        this.scrollEl.scrollTo(0, this.dataPanel - 150)
      }
    },
    fn_iniscroll () {
      this.scrollEl = document.querySelector('.qr-analysis')
      this.growthTrendPanel = document.querySelector('#growthTrendPanel').getBoundingClientRect().top
      this.dataPanel = document.querySelector('#dataPanel').getBoundingClientRect().top
    },

    fn_getScroll (e) {
      const main = e.target || e.srcElement || e.path[0]

      const scrollList = [Math.floor(this.dataPanel - 150), Math.floor(this.growthTrendPanel - 150)]
      const scrollMap = {
        0: '数据概览',
        1: '增长趋势'
      }

      let _md = 0
      for (let i = 0; i <= scrollList.length; i++) {
        if (main.scrollTop >= scrollList[i]) {
          _md = i
        }
      }

      this.navIndex = scrollMap[_md]
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.fn_beforeunload, false)
  },
  destroyed () {
    window.removeEventListener('scroll', this.fn_getScroll)
  },
  components: {
    analysisPanel,
    lyChart,
    timeSelect
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
@import "@assets/scss/utils.scss";
@import "@assets/scss/layout.scss";
.qr-analysis {
  height: 100%;
  overflow: auto;
  background-color: #f8f9fb;
  .qr-analysis-wrap {
    padding: 0 24px 0;
    background: #fff;
  }
  ::v-deep {
    .el-form-item__label {
      padding: 0;
    }
    .el-tabs__header {
      margin: 0;
    }

    .el-form-item {
      margin-bottom: 16px;
    }
    .el-table--border, .el-table--group{
      border-radius: 8px;
    }
  }
  .main-title {
    font-size: 16px;
    font-weight: 500;
    color: #212b36;
    margin-bottom: 16px;
    padding-left: 10px;
    position: relative;
    &::before{
      position: absolute;
      content: "";
      width: 5px;
      height: 12px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #2B7CFF;
      border-radius: 2.5px;
    }
  }

  .grey-wrapper {
    background-color: #f8f9fb;
  }

  .qr-analysis-panel {
    padding: 20px 20px 20px;
  }

  .el-card{
    border-radius: 16px;
    border: unset;
    &.is-always-shadow{
      box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24)
    }
  }

  .card-wrapper {
    padding: 0 20px;

    .card-title {
      font-size: 16px;
      font-weight: 500;
      color: #212b36;
    }
  }
  .navTabs {
    position: sticky;
    top: 0;
    z-index: 2;
    .pH {
      padding: 0;
    }
  }

  .remarksInfo {
    color: #454D5B;
    font-size: 12px;
  }
}
</style>
