<!--
 * @Author: zhanln
 * @Date: 2022-03-21 17:53:31
 * @LastEditTime: 2022-04-10 12:53:02
 * @LastEditors: zhanln
 * @Description:
-->
<template>
  <div class="fm-container">
    <formCom ref="formCom"> </formCom>
  </div>
</template>

<script>
import '@/assets/scss/formTable/cover.scss'
import '@/assets/scss/formTable/index.scss'
import formCom from './formC/formCom.vue'
export default {
  created () {
    this.$store.commit('_SET', {
      'global.asideWidth': '0'
    })
  },
  components: {
    formCom
  },
  beforeDestroy () {
    this.$store.commit('_SET', {
      'global.asideWidth': '226px'
    })
  }
}
</script>

<style>
.fm-container {
  height: calc(100% - 48px);
  /* user-select: none; */
}
</style>
