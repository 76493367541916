/*
 * @Author: zhan
 * @Date: 2022-06-02 10:32:02
 * @LastEditTime: 2022-06-04 16:27:13
 * @LastEditors: zhan
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

export const lnvitation = {
  reqQuery: params => axios.get(`/wecom/polite/account-list?${stringify(params)}`) // 查询表格数据的接口
}

export const prize = {
  reqQuery: (params) => axios.get(`/wecom/task/reward-records?${stringify(params)}`) // 查询表格数据的接口
}
