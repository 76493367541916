<template>
  <el-main class="inner is-dark detail">
    <div class="flex">
      <div class="detail-base ly-card is-margin" style="min-height: 224px">
        <div class="detail-base__header flex" style="margin-bottom: 30px">
          <div class="detail-base__avatar">
            <img v-if="info.avatar_url" :src="info.avatar_url" alt="" />
            <img
              v-else
              src="../../../assets/images/default_avatar.png"
              alt=""
            />
            <template v-if="info.gender === 1">
              <div class="detail-base__gender">
                <i class="ri-men-line"></i>
              </div>
            </template>
            <template v-if="info.gender === 2">
              <div class="detail-base__gender pink">
                <i class="ri-women-line"></i>
              </div>
            </template>
          </div>
          <div class="flex flex-column flex-start">
            <div>
              <span class="detail-base__name">{{ info.name }}</span>
              <span class="q-warning" v-if="info.corp_name"
                >@{{ info.corp_name }}</span
              >
              <span class="q-success" v-else>@微信</span>
            </div>
            <div v-if="info.earliest_e.id > 0">
              <template v-if="info.earliest_e.etype === 0">
                <span class="q-info">最早于</span>
                {{ util.timeTofmt(info.earliest_e.etime, "yyyy-MM-dd hh:mm") }}
                <span class="q-info">通过</span>
                {{ joinType(info.earliest_e.way) }}
                <span class="q-info">被</span> {{ info.earliest_e.name }}
                <span class="q-info">添加</span>
              </template>
              <template v-else>
                <span class="q-info">最早于</span>
                {{ util.timeTofmt(info.earliest_e.etime, "yyyy-MM-dd hh:mm") }}
                <span class="q-info">通过</span>
                {{ joinType(info.earliest_e.way) }}
                <span class="q-info">添加</span> {{ info.earliest_e.name }}
              </template>
            </div>
          </div>
        </div>

        <div class="ly-list">
          <div class="ly-list-item">
            <div class="ly-list__label">企业标签：</div>
            <div class="ly-list__content">
              <ly-drow
                :list="info.wecom_tags"
                dep
                :textSize="99"
                :size="999"
              ></ly-drow>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-right ly-card is-margin" style="min-height: 256px">
        <div class="detail-base__header">
          <div class="ly-table__main-title">客户动态</div>
        </div>
        <template v-if="activities.length === 0">
          <div class="flex flex-column" style="padding-top: 30px">
            <img
              src="../../../assets/images/dynamic_empty.png"
              alt=""
              style="width: 83px; margin-bottom: 16px"
            />
            <div class="q-info">暂无动态</div>
          </div>
        </template>
        <template v-else>
          <el-timeline class="detail-base__timeline">
            <el-timeline-item
              v-for="(item, index) in activities"
              :key="index"
              :type="item.type"
              :timestamp="item.timestamp"
            >
              <template v-if="item.id > 0">
                <template v-if="item.etype === 0">
                  {{ item.name }} <span class="q-info">通过</span>
                  {{ item.way }} <span class="q-info">添加</span>
                  {{ info.name }}
                </template>
                <template v-if="item.etype === 1">
                  {{ item.name }} <span class="q-info">主动删除</span>
                  {{ info.name }}
                </template>
                <template v-if="item.etype === 2">
                  {{ info.name }} <span class="q-info">通过</span>
                  {{ item.way }} <span class="q-info">添加</span>
                  {{ item.name }}
                </template>
                <template v-if="item.etype === 3">
                  {{ info.name }} <span class="q-info">主动删除</span>
                  {{ item.name }}
                </template>
                <template v-if="item.etype === 4">
                  {{ info.name }} <span class="q-info">通过</span>
                  {{ item.way }} <span class="q-info">进入群聊</span>
                  {{ item.name }}
                </template>
                <template v-if="item.etype === 5">
                  {{ info.name }} <span class="q-info">退出群聊</span>
                  {{ item.name }}
                </template>
              </template>
            </el-timeline-item>
          </el-timeline>
        </template>
      </div>
    </div>

    <div class="ly-card is-margin" style="padding: 0 20px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="所属员工" name="first">
          <layout-table
            :pageTotal="belongDataTotal"
            @pageChange="belongPageChange"
            :loading="belongLoading"
            :page="belongPage"
          >
            <el-table :data="belongData" style="width: 100%">
              <!-- <el-table-column
                type="index"
                width="50"
                align="center">
              </el-table-column> -->
              <el-table-column prop="name" width="200" label="所属员工">
                <template slot-scope="scope">
                  <div class="flex flex-start">
                    <img
                      v-if="scope.row.avatar_url"
                      :src="scope.row.avatar_url"
                      alt=""
                      class="ly-table__main-avatar"
                    />
                    <img
                      v-else
                      src="../../../assets/images/default_avatar.png"
                      alt=""
                      class="ly-table__main-avatar"
                    />
                    <div>{{ scope.row.name || "-" }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="add_way" label="客户来源">
                <template slot-scope="scope">
                  {{ joinType(scope.row.add_way) }}
                </template>
              </el-table-column>
              <el-table-column prop="added_at" width="140" label="添加时间">
                <template slot-scope="scope">
                  <div class="ly-table__main-time">
                    {{ util.timeTofmt(scope.row.added_at, "yyyy-MM-dd hh:mm") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注名">
                <template slot-scope="scope">
                  <div class="ellipsis3" :title="scope.row.remark">
                    {{ scope.row.remark ? scope.row.remark : "-" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="tags" min-width="120" label="自定义标签">
                <template slot-scope="scope">
                  <ly-drow
                    :list="scope.row.tags"
                    v-if="scope.row.tags && scope.row.tags.length > 0"
                    dep
                  ></ly-drow>
                  <template v-else> - </template>
                </template>
              </el-table-column>
              <el-table-column prop="wecom_tags" label="企业标签" width="200">
                <template slot-scope="scope">
                  <ly-drow
                    :list="scope.row.wecom_tags"
                    v-if="
                      scope.row.wecom_tags && scope.row.wecom_tags.length > 0
                    "
                    dep
                  ></ly-drow>
                  <template v-else> - </template>
                </template>
              </el-table-column>
              <el-table-column prop="description" label="描述信息">
                <template slot-scope="scope">
                  <div class="ellipsis3" :title="scope.row.description">
                    {{ scope.row.description ? scope.row.description : "-" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="添加状态">
                <template slot-scope="scope">
                  <ly-status type="success" v-if="scope.row.status === 0">
                    已添加
                  </ly-status>
                  <ly-status type="danger" v-else-if="scope.row.status === 1">
                    已删除
                  </ly-status>
                  <ly-status type="warning" v-else-if="scope.row.status === 2">
                    重新添加
                  </ly-status>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                class-name="operation-cell"
                width="80"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    :disabled="!scope.row.view"
                    @click="jumpToEmp(scope.row)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
              <div slot="empty" class="ly-table-empty">
                <slot name="empty">
                  <img src="@assets/svg/default/no_data.svg" alt="" />
                  暂无数据
                </slot>
              </div>
            </el-table>
          </layout-table>
        </el-tab-pane>
        <el-tab-pane label="所属群聊" name="second" fixed width="200">
          <layout-table
            :pageTotal="groupDataTotal"
            @pageChange="groupPageChange"
            :loading="groupLoading"
            :page="groupPage"
          >
            <el-table :data="groupData" style="width: 100%">
              <!-- <el-table-column
                type="index"
                width="50"
                align="center">
              </el-table-column> -->
              <el-table-column prop="name" label="所属群聊">
                <template slot-scope="scope">
                  <div class="flex flex-start">
                    <div class="ly-table__main-tearm">
                      <i class="iconfont icon-weixin"></i>
                    </div>
                    <span style="margin-right: 8px">{{
                      scope.row.name ? scope.row.name : "群聊"
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="owner_name" label="群主" align="center">
                <template slot-scope="scope">
                  <template v-if="scope.row.owner_name">
                    <ly-tag :content="scope.row.owner_name"></ly-tag>
                  </template>
                  <template v-else> - </template>
                </template>
              </el-table-column>
              <el-table-column prop="user_count" label="群人数" align="center">
              </el-table-column>
              <el-table-column prop="status" label="进群状态" align="center">
                <template slot-scope="scope">
                  {{ scope.row.status === 0 ? "已退群" : "已进群" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="join_scene"
                label="进群方式"
                align="center"
              >
                <template slot-scope="scope">
                  通过{{ scope.row.join_scene }}入群
                </template>
              </el-table-column>
              <el-table-column prop="join_at" label="进群时间">
                <template slot-scope="scope">
                  <div class="ly-table__main-time">
                    {{ util.timeTofmt(scope.row.join_at, "yyyy-MM-dd hh:mm") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="create_at" label="群聊创建时间">
                <template slot-scope="scope">
                  <div class="ly-table__main-time">
                    {{
                      util.timeTofmt(scope.row.create_at, "yyyy-MM-dd hh:mm")
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                class-name="operation-cell"
                width="80"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    :disabled="!scope.row.view || !!scope.row.owner_name"
                    @click="jumpToGroup(scope.row)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </layout-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>

<script>
import layoutTable from '@/components/layout/lyTable'
import lyDrow from '@/components/lyDrow'
import lyTag from '@/components/lyTag'
export default {
  name: 'detail',
  components: {
    layoutTable,
    lyDrow,
    lyTag
  },
  data () {
    return {
      id: null,
      info: {
        earliest_e: {}
      },
      activeName: 'first',
      activities: [],
      belongData: [],
      belongDataTotal: 0,
      belongLoading: false,
      belongPage: 1,
      groupData: [],
      groupDataTotal: 0,
      groupLoading: false,
      groupPage: 1,
      customerDetail: false,
      empDetail: false
    }
  },
  created () {
    this.id = this.$route.query.id
    if (!this.id) {
      this.fn_lyMsg('info', '客户 id 为空')
      return
    }
    this.getClientInfo()
    this.getClientDynamic()
    this.getClientGroups()
    this.getClientUsers()
  },
  methods: {

    // 详情
    async getClientInfo () {
      const data = await this.axios.get('clientInfo', {
        params: {
          user_id: this.id
        }
      })
      this.info = data
    },

    // 客户动态
    async getClientDynamic () {
      const data = await this.axios.get('clientDynamic', {
        params: {
          page: 1,
          page_size: 9999,
          external_userid: this.id
        }
      })

      if (data.list.length > 0) {
        this.activities = data.list.map(item => {
          const _item = {}
          let _type = 'danger'

          if (item.etype === 0 || item.etype === 2 || item.etype === 4) {
            _type = 'success'
          }
          _item.timestamp = this.util.timeTofmt(item.etime, 'yyyy-MM-dd hh:mm')
          _item.way = this.joinType(item.way, item.etype)
          _item.name = item.name
          _item.type = _type
          _item.etype = item.etype
          _item.id = item.id
          return _item
        })
      }
    },

    // 所属群列表
    async getClientGroups () {
      const data = await this.axios.get('clientGroups', {
        params: {
          page: this.groupPage,
          page_size: 10,
          external_userid: this.id
        }
      })
      if (data.list) {
        this.groupData = data.list.map(item => {
          item.join_scene = this.joinType(item.join_scene)
          return item
        })
      }
    },

    // 所属员工列表
    async getClientUsers () {
      const data = await this.axios.get('clientUsers', {
        params: {
          page: this.belongPage,
          page_size: 10,
          external_userid: this.id
        }
      })
      this.belongData = data.list
    },

    // 返回入群方式
    joinType (way, type = 1) {
      let _way = ''
      switch (way) {
        case 0:
          _way = '未知来源'
          break
        case 1:
          _way = type <= 3 ? '扫描二维码' : '直接邀请'
          break
        case 2:
          _way = type <= 3 ? '搜索手机号' : '邀请链接'
          break
        case 3:
          _way = type <= 3 ? '名片分享' : '扫描群二维码'
          break
        case 4:
          _way = '群聊'
          break
        case 5:
          _way = '手机通讯录'
          break
        case 6:
          _way = '微信联系人'
          break
        case 7:
          _way = '来自微信的添加好友申请'
          break
        case 8:
          _way = '安装第三方应用时自动添加的客服人员'
          break
        case 9:
          _way = '搜索邮箱'
          break
        case 201:
          _way = '内部成员共享'
          break
        case 202:
          _way = '管理员/负责人分配'
          break
      }
      return _way
    },

    // 所属员工分页监听
    belongPageChange (val) {
      this.belongPage = val
      this.getClientUsers()
    },

    // 所属群聊分页监听
    groupPageChange (val) {
      this.groupPage = val
      this.getClientGroups()
    },

    // 跳转员工详情
    jumpToEmp (item) {
      const routeData = this.$router.resolve({
        path: '/enterprise/employees/detail',
        query: {
          id: item.user_id
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 跳转群聊详情
    jumpToGroup (item) {
      const routeData = this.$router.resolve({
        path: '/groupOperation/management/detail',
        query: {
          id: item.group_id
        }
      })
      window.open(routeData.href, '_blank')
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
@import "@/assets/scss/layout/table.scss";
@import "@/assets/scss/components/card.scss";
::v-deep {
  .ly-table-main {
    box-shadow: unset;
    margin-bottom: 0;
  }
  .ly-table__main {
    padding: 0;
  }
  .el-tabs__nav-wrap::after {
    content: unset;
  }
  .el-timeline-item__tail {
    border-width: 1px;
    left: 5px;
  }
}
.el-tab-pane {
  .ly-table {
    padding: 0;
  }
}

.detail {
  &-base {
    flex: 1;
    margin-right: 20px;

    &__header {
      margin-bottom: 16px;
    }

    &__avatar {
      width: 64px;
      height: 64px;
      position: relative;
      overflow: hidden;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &__gender {
      position: absolute;
      right: 2px;
      bottom: 2px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #57b9ff;
      color: #fff;
      border-radius: 50%;
      z-index: 9999;
      i {
        transform: scale(0.83);
      }

      &.pink {
        background-color: #fe7ab6;
        i {
          transform: scale(0.83) rotate(30deg);
        }
      }
    }

    &__name {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      color: #212b36;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .ly-gap {
      height: 56px;
      margin: 0 40px;
    }

    &__timeline {
      height: (224-25-16px);
      overflow: auto;
      padding-left: 1px;
    }
  }

  &-right {
    width: 418px;
    // margin-right: -18px;
    padding-right: 6px;
    box-sizing: border-box;
  }

  &-num {
    display: inline-block;
    font-size: 28px;
    color: #212b36;
    margin-right: 6px;
    line-height: 1;
    margin-top: 8px;
  }
}

.ly-list {
  &-item {
    display: flex;
    overflow: hidden;

    &:not(:first-child) {
      border-top: none;
    }
  }

  &__label {
    color: #6e788a;
  }
}
</style>
