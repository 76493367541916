var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prize" },
    [
      _c(
        "swiper",
        {
          ref: "swiperRef",
          staticClass: "prize-swiper",
          attrs: { options: _vm.swiperOption }
        },
        _vm._l(_vm.rewards, function(item, index) {
          return _c(
            "swiper-slide",
            { key: index, staticClass: "prize-swiper__item" },
            [
              item.virtual_count > 0
                ? _c("div", { staticClass: "prize-unreal p-color" }, [
                    _c(
                      "div",
                      { staticClass: "prize-unreal__avatars" },
                      _vm._l(
                        _vm.avatarList.slice(0, item.virtual_count),
                        function(item, index) {
                          return _c("img", {
                            key: index,
                            attrs: { src: item, alt: "" }
                          })
                        }
                      ),
                      0
                    ),
                    _vm._v(" " + _vm._s(item.virtual_count) + "人已领取 ")
                  ])
                : _vm._e(),
              _c("div", { staticClass: "prize-item" }, [
                _c("div", { staticClass: "prize-cover" }, [
                  _c("img", {
                    attrs: { src: item.cover || _vm.defaultPrizeCover }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "prize-total" },
                  [
                    item.show_stock === 1
                      ? [
                          _vm._v(" 库存剩余："),
                          _c("span", { staticClass: "p-color" }, [
                            _vm._v(_vm._s(item.total))
                          ])
                        ]
                      : [
                          _c("span", { staticClass: "p-color" }, [
                            _vm._v("库存告急")
                          ])
                        ]
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "prize-name p-color" }, [
                _vm._v(_vm._s(item.title || "奖品名称"))
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }