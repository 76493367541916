<!--
 * @Author: zhanln
 * @Date: 2021-06-04 16:54:30
 * @LastEditTime: 2022-05-11 14:41:35
 * @LastEditors: zhanln
 * @Description: tag
-->
<template>
  <div
    :class="['tag_over', content && content.length > 0 ? '' : 'is-empty']"
    v-if="only"
  >
    <template v-if="content">
      <el-tooltip
        effect="dark"
        placement="top"
        :disabled="content.length <= size"
      >
        <div slot="content">{{ content }}</div>
        <span :class="['ly-tag', dep ? 'is-dep' : '']" ref="tagRef">
          <slot name="left">
            <template v-if="icon">
              <i :class="icon"></i>
            </template>
            <template v-else>
              <el-image :src="avatar" v-if="!dep && avatar"></el-image>
              <i class="iconfont icon-Staff" v-else-if="!dep"></i>
            </template>
          </slot>
          <slot>{{
            content.length > size ? content.slice(0, size) + "..." : content
          }}</slot>
          <slot name="right"></slot>
        </span>
      </el-tooltip>
    </template>
    <template v-else> {{defaultText}} </template>
  </div>
  <el-tooltip
    effect="dark"
    placement="top"
    :disabled="content.length <= size"
    v-else
  >
    <div slot="content">{{ content }}</div>
    <span :class="['ly-tag', dep ? 'is-dep' : '']" ref="tagRef">
      <slot name="left">
        <template v-if="icon">
          <i :class="icon"></i>
        </template>
        <template v-else>
          <el-image :src="avatar" v-if="!dep && avatar"></el-image>
          <i class="iconfont icon-Staff" v-else-if="!dep"></i>
        </template>
      </slot>
      <slot>{{
        content.length > size ? content.slice(0, size) + "..." : content
      }}</slot>
      <slot name="right"></slot>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  name: 'lyTag',
  props: {
    avatar: {
      type: String,
      default: ''
    },
    only: {
      type: Boolean,
      default: true
    },
    content: {
      default: ''
    },
    dep: {
      type: Boolean, // 标签使用，无icon, 灰底样式
      default: false
    },
    size: {
      type: Number,
      default: 3
    },
    icon: {
      type: String,
      default: ''
    },
    defaultText: {
      type: String,
      default: '-'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
@import "@assets/scss/utils.scss";
.ly-tag {
  display: inline-flex;
  flex: 1;
  align-items: center;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  background: #f7f8fc;
  border-radius: 24px;
  box-sizing: border-box;
  margin-bottom: 8px;
  margin-left: 8px;
  color: $--color-text-regular;
  ::v-deep &_icon {
    margin-right: 2px;
    color: $--color-tag-icon;
    font-size: 12px;
  }
  .el-image {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background: #ededed;
    margin-right: 5px;
    ::v-deep {
      .el-image__error {
        display: none;
      }
    }
  }

  i {
    margin-right: 5px;
  }

  .icon-Staff {
    color: #6e788a;
  }
  &.is-dep {
    background: #f7f8fc;
  }
}
</style>
