<!--
 * @Author: zhanln
 * @Date: 2021-08-25 17:56:40
 * @LastEditTime: 2021-12-10 17:32:16
 * @LastEditors: zhanln
 * @Description: 基本信息预览
-->

<template>
  <ly-mobile :msgList="msgList"></ly-mobile>
</template>

<script>
export default {
  data () {
    return {
      msgList: []
    }
  },
  computed: {
    welWords () {
      return this.$store.getters['qrcode/GET_BASIC'].wel_words || '请输入好友欢迎语'
    },
    attach () {
      return this.$store.getters['qrcode/GET_BASIC'].attachments
    }
  },
  mounted () {
    const attachList = this.$store.getters['qrcode/GET_BASIC'].attachments
    this.fn_initList(attachList)
  },
  watch: {
    welWords (val) {
      this.msgList[0].content = val
    },
    attach (val) {
      this.fn_initList(val)
    }
  },
  methods: {
    // initList
    fn_initList (data) {
      const list = [
        {
          type: 'text',
          content: this.$store.getters['qrcode/GET_BASIC'].wel_words || '请输入好友欢迎语'
        }
      ]

      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          switch (data[i].msg_type) {
            case 2002:
              list.push({
                type: 'image',
                image: data[i].msg_content
              })
              break
            case 2005:
              list.push({
                type: 'link',
                title: data[i].title,
                desc: data[i].desc,
                cover: data[i].msg_content
              })
              break
            case 2013:
              list.push({
                type: 'applet',
                title: data[i].title,
                cover: data[i].msg_content
              })
              break
          }
        }
      }
      this.msgList = list
    }
  }
}
</script>
