var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "添加关键词", visible: _vm.visible, width: "480px" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-box" },
        [
          _c("label", [_vm._v("关键词：")]),
          _c("el-input", {
            attrs: {
              size: "medium",
              maxlength: "20",
              "show-word-limit": "",
              placeholder: "请输入关键词"
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "content"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "q-info",
          staticStyle: { "margin-left": "56px", "margin-top": "10px" }
        },
        [_vm._v(" 请确保输入正确关键词，保存后无法修改 ")]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary", size: "medium" },
              on: { click: _vm.handleSave }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }