var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-card" },
    [
      _c("div", { staticClass: "edit-card__header" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.cardLoading,
              expression: "cardLoading"
            }
          ],
          staticClass: "edit-card__content"
        },
        [
          _c(
            "el-form",
            {
              ref: "basicForm",
              staticClass: "basic-form",
              attrs: {
                model: _vm.basicForm,
                rules: _vm.rules,
                "label-width": "125px",
                size: "medium"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "basic-form__item",
                  attrs: {
                    label: "活动名称",
                    prop: "title",
                    error: _vm.titleError
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "16",
                      "show-word-limit": "",
                      placeholder: "不对外展示，用户区分活动使用场景"
                    },
                    on: {
                      change: function($event) {
                        return _vm.fn_setForm($event, "title")
                      }
                    },
                    model: {
                      value: _vm.basicForm.title,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.basicForm,
                          "title",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "basicForm.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "basic-form__item",
                  attrs: { label: "结束时间", prop: "end_at" }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "320px" },
                    attrs: {
                      type: "datetime",
                      placeholder: "请选择时间",
                      "picker-options": _vm.endDatePickerOptions
                    },
                    on: {
                      change: function($event) {
                        return _vm.fn_setForm($event, "end_at")
                      }
                    },
                    model: {
                      value: _vm.basicForm.end_at,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.basicForm,
                          "end_at",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "basicForm.end_at"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-long basic-form__item",
                  attrs: {
                    label: "活动群设置",
                    prop: "msg_mode",
                    required: "",
                    error: _vm.groupError
                  }
                },
                [
                  _vm.hasCloudBean || _vm.showAutoSend
                    ? [
                        _c(
                          "el-radio-group",
                          {
                            attrs: {
                              disabled: _vm.isEdit && !_vm.isCopy && !_vm.isDaft
                            },
                            on: { change: _vm.fn_changeFissionType },
                            model: {
                              value: _vm.basicForm.msg_mode,
                              callback: function($$v) {
                                _vm.$set(_vm.basicForm, "msg_mode", $$v)
                              },
                              expression: "basicForm.msg_mode"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("普通消息模式")
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("智能消息模式")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "q-bg text-small",
                            staticStyle: { "line-height": "1.6" }
                          },
                          [
                            _vm._v(" 普通消息模式」：仅支持在群内发送“"),
                            _c("b", [_vm._v("入群欢迎语")]),
                            _vm._v("”； "),
                            _c("br"),
                            _vm._v(" 「智能消息模式」：支持在群内发送“"),
                            _c("b", [_vm._v("入群欢迎语")]),
                            _vm._v("”、“"),
                            _c("b", [_vm._v("助力提醒")]),
                            _vm._v("”、“"),
                            _c("b", [_vm._v("领奖通知")]),
                            _vm._v(
                              "”。本模式需要将群内员工账号登录到云端，登录云端的账号会根据设置的规则自动发消息。"
                            ),
                            _vm.basicForm.msg_mode === 1 && this.hasCloudBean
                              ? _c(
                                  "a",
                                  { on: { click: _vm.fn_jumpCloudPage } },
                                  [_vm._v("如何登录云端?")]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "12px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "12px" },
                          attrs: {
                            round: "",
                            type: "primary",
                            size: "small",
                            disabled:
                              !_vm.hasCloudBean && _vm.basicForm.msg_mode === 1
                          },
                          on: { click: _vm.handleSelectGroup }
                        },
                        [_vm._v("选择群聊 ")]
                      ),
                      _c("span", [
                        _vm._v(
                          "已选 " + _vm._s(_vm.basicForm.group.length) + " 个群"
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.groupLoading,
                          expression: "groupLoading"
                        }
                      ],
                      staticClass: "group-table",
                      attrs: {
                        data: _vm.basicForm.group,
                        "max-height": "380",
                        "empty-text": "请选择群聊"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "68" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "群名称",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "owner_name",
                          label: "群主",
                          "min-width": "110"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("ly-tag", {
                                  attrs: {
                                    content: scope.row.owner_name,
                                    textSize: 3
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm.basicForm.msg_mode === 1
                        ? _c(
                            "el-table-column",
                            {
                              attrs: {
                                prop: "robots",
                                label: "云端账号",
                                width: "160"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "more-popover",
                                          {
                                            attrs: {
                                              title: "企业成员",
                                              list: row.robots || []
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "reference",
                                                  fn: function() {
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getOnlineCount(
                                                            row.robots
                                                          ) + "人 "
                                                        )
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                },
                                                {
                                                  key: "item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ly-team small"
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-s-custom",
                                                            staticStyle: {
                                                              "font-size":
                                                                "16px"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "m-group_name text-ellipsis"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.account_name
                                                              ) +
                                                              " "
                                                          ),
                                                          item.account_status
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#00a86e"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(在线)"
                                                                  )
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#909399"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(离线)"
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_vm._v("在线 ")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                973823853
                              )
                            },
                            [
                              _c(
                                "template",
                                { slot: "header" },
                                [
                                  _vm._v(" 云端账号 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "刷新云端账号状态",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-shuaxin1",
                                        staticStyle: { cursor: "pointer" },
                                        on: { click: _vm.fn_refresh }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            prop: "count",
                            label: "自动切群人数",
                            "min-width": "130"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input-number", {
                                    staticClass: "number-small number-mn",
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      size: "small",
                                      min: 1,
                                      max: 190,
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value: scope.row.count,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "count", $$v)
                                      },
                                      expression: "scope.row.count"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "template",
                            { slot: "header" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "4px" } },
                                [_vm._v("自动切群人数")]
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top",
                                    "popper-class": "q-tooltip"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        " 每个群入群人数达到「自动切换人数」后，自动切换下个群，"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "当群的总人数达到190人后，不再参与分配。 "
                                      )
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "iconfont icon-info-fill"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "80",
                          "class-name": "operation-cell"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-dropdown",
                                  {
                                    staticClass: "el-dropdown-more",
                                    attrs: { placement: "bottom-start" },
                                    on: {
                                      command: function(e) {
                                        return _vm.fn_changeGroup(
                                          scope.$index,
                                          e,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [_c("i", { staticClass: "el-icon-more" })]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              disabled: scope.$index === 0,
                                              command: "up"
                                            }
                                          },
                                          [_vm._v("上移")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: "down",
                                              disabled:
                                                scope.$index ===
                                                _vm.basicForm.group.length - 1
                                            }
                                          },
                                          [_vm._v("下移 ")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "del" } },
                                          [_vm._v("移除")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "basic-form__item is-long",
                  attrs: {
                    prop: "wel_status",
                    label: "入群欢迎语",
                    required: ""
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.fn_setForm($event, "wel_status")
                          }
                        },
                        model: {
                          value: _vm.basicForm.wel_status,
                          callback: function($$v) {
                            _vm.$set(_vm.basicForm, "wel_status", $$v)
                          },
                          expression: "basicForm.wel_status"
                        }
                      }),
                      _c("span", { staticStyle: { "margin-left": "8px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.basicForm.wel_status === 0 ? "关闭" : "开启"
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.basicForm.msg_mode === 1 && _vm.basicForm.wel_status === 1
                    ? _c(
                        "div",
                        { staticClass: "q-bg is-default" },
                        [
                          _vm._v(" 每隔 "),
                          _c("el-input-number", {
                            staticClass: "number-small number-mn",
                            attrs: {
                              size: "small",
                              "controls-position": "right",
                              min: 60,
                              max: 3600,
                              precision: 0
                            },
                            on: {
                              change: function($event) {
                                return _vm.fn_setForm($event, "wel_gap")
                              }
                            },
                            model: {
                              value: _vm.basicForm.wel_gap,
                              callback: function($$v) {
                                _vm.$set(_vm.basicForm, "wel_gap", $$v)
                              },
                              expression: "basicForm.wel_gap"
                            }
                          }),
                          _vm._v(" 秒后发送欢迎语 "),
                          _c("b", [_vm._v("或")]),
                          _vm._v(" 进群 "),
                          _c("el-input-number", {
                            staticClass: "number-small number-mn",
                            attrs: {
                              size: "small",
                              "controls-position": "right",
                              min: 2,
                              max: 50,
                              precision: 0
                            },
                            on: {
                              change: function($event) {
                                return _vm.fn_setForm($event, "wel_user")
                              }
                            },
                            model: {
                              value: _vm.basicForm.wel_user,
                              callback: function($$v) {
                                _vm.$set(_vm.basicForm, "wel_user", $$v)
                              },
                              expression: "basicForm.wel_user"
                            }
                          }),
                          _vm._v(" 人后发送欢迎语 "),
                          _c(
                            "div",
                            {
                              staticClass: "q-info text-small",
                              staticStyle: { "margin-top": "8px" }
                            },
                            [
                              _c("i", { staticClass: "ri-information-line" }),
                              _vm._v(
                                " 进群时间和进群人数同步计算，将优先触发满足条件的设置项 "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0", width: "60%" },
                      attrs: { error: _vm.wordError }
                    },
                    [
                      _vm.basicForm.wel_status === 1
                        ? _c("ly-editor", {
                            attrs: {
                              title: "入群欢迎语",
                              id: "basicWel",
                              content: _vm.basicForm.wel_words,
                              recoverContent: _vm.initHtml,
                              error: _vm.wordError !== "",
                              emoji: ""
                            },
                            on: { getContent: _vm.fn_getWelWords }
                          })
                        : _vm._e(),
                      _vm.basicForm.wel_status === 1 &&
                      _vm.basicForm.msg_mode === 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    placement: "right",
                                    "popper-class": "q-tooltip is-light"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            margin: "24px 24px 16px",
                                            width: "340px"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "https://image.01lb.com.cn//uploads/wecom/21/0830/1630318814Z9wtpO5A.png",
                                              width: "340",
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#2b7cff" } },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-info-fill",
                                        staticStyle: { "margin-right": "5px" }
                                      }),
                                      _vm._v(
                                        "发布活动后，记得在客户端配置欢迎语哦~"
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "basic-form__item",
                  attrs: { label: "入群链接", prop: "link_type" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        change: function($event) {
                          return _vm.fn_setForm($event, "link_type")
                        }
                      },
                      model: {
                        value: _vm.basicForm.link_type,
                        callback: function($$v) {
                          _vm.$set(_vm.basicForm, "link_type", $$v)
                        },
                        expression: "basicForm.link_type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("默认")]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("自定义")
                      ])
                    ],
                    1
                  ),
                  _vm.basicForm.link_type === 2
                    ? [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: {
                              label: "主图",
                              "label-width": "80px",
                              error: _vm.coverError
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "label" },
                              [
                                _c("span", { staticClass: "q-danger" }, [
                                  _vm._v("*")
                                ]),
                                _vm._v(" 主图 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      placement: "top",
                                      content:
                                        "支持JPG、PNG格式，图片大小2M以内",
                                      "popper-class": "q-tooltip is-light"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-info-fill"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-upload",
                              {
                                class: [
                                  "upload",
                                  _vm.basicForm.link_logo.length === 0
                                    ? ""
                                    : "upload-empty"
                                ],
                                attrs: {
                                  action: "#",
                                  accept: ".png, .jpg, .jpeg",
                                  "http-request": _vm.requestContentImg,
                                  "on-remove": _vm.removeContentImg,
                                  "list-type": "picture-card",
                                  limit: 1,
                                  "file-list": _vm.basicForm.link_logo
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-column flex-center",
                                    staticStyle: {
                                      "line-height": "22px",
                                      height: "100%"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-plus",
                                      staticStyle: { "font-size": "20px" }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text q-info text-small" },
                                      [_vm._v("上传图片")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "标题",
                              prop: "link_title",
                              "label-width": "80px"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "20",
                                "show-word-limit": "",
                                placeholder: "请输入链接标题"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fn_setForm($event, "link_title")
                                }
                              },
                              model: {
                                value: _vm.basicForm.link_title,
                                callback: function($$v) {
                                  _vm.$set(_vm.basicForm, "link_title", $$v)
                                },
                                expression: "basicForm.link_title"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "描述", "label-width": "80px" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "200",
                                "show-word-limit": "",
                                placeholder: "请输入链接描述"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fn_setForm($event, "link_desc")
                                }
                              },
                              model: {
                                value: _vm.basicForm.link_desc,
                                callback: function($$v) {
                                  _vm.$set(_vm.basicForm, "link_desc", $$v)
                                },
                                expression: "basicForm.link_desc"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("select-group", {
        ref: "selectGroupRef",
        attrs: {
          selectList: _vm.basicForm.group,
          baseSelect: _vm.currentGroupIds
        },
        on: { selectClose: _vm.fn_selectClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }