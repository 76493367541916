<!--
 * @Author: zhan
 * @Date: 2022-08-11 14:26:25
 * @LastEditTime: 2022-09-16 17:58:03
 * @LastEditors: zhanln
-->
<template>
  <div class="invite bg">
    <div class="title">邀请进度</div>

    <div class="invite-body">
      <template v-if="rewards.length === 1">
        <div class="reward-single">
          <div class="reward-single__img">
            <img :src="rewards[0].cover || defaultPrizeCover" alt="" />
          </div>
          <div class="reward-single__info">
            <div class="reward-single__name">
              {{ rewards[0].title || '奖品名称' }}
            </div>
            <view-progress :count="rewards[0].count || 1"></view-progress>
          </div>
          <div
            class="reward-btn"
            :class="{ 'is-finish': stepNum >= rewards[0].count }"
          >
            {{ stepNum >= rewards[0].count ? '查看' : '待解锁' }}
          </div>
        </div>
      </template>

      <template v-else>
        <div class="reward-multiple">
          <div class="reward-multiple__body">
            <div class="reward-multiple__left"></div>

            <div class="reward-multiple__list">
              <div class="reward-multiple__item">
                <div class="reward-multiple__col theme-text">邀请好友</div>
                <div class="reward-multiple__col theme-text" style="flex: 1">
                  你的奖励
                </div>
                <div class="reward-multiple__col theme-text">领取状态</div>
              </div>
            </div>
          </div>

          <div class="reward-multiple__body">
            <div class="reward-multiple__left">
              <view-progress
                type="vertical"
                :rewards="rewards"
                :stepNum="stepNum"
              ></view-progress>
            </div>

            <div class="reward-multiple__list">
              <div
                class="reward-multiple__item mg"
                v-for="(item, index) of rewards"
                :key="index"
              >
                <div class="reward-multiple__col theme-text">
                  {{ item.count }}人
                </div>
                <div class="reward-multiple__col" style="flex: 1">
                  <div class="reward-multiple__img reward-single__img">
                    <img :src="item.cover || defaultPrizeCover" alt="" />
                  </div>
                  <div class="reward-multiple__info reward-single__info">
                    <div class="reward-multiple__name reward-single__name">
                      {{ item.title || '奖品名称' }}
                    </div>
                  </div>
                </div>
                <div
                  class="reward-multiple__col reward-btn"
                  :class="{ 'is-finish': stepNum >= item.count }"
                >
                  {{ stepNum >= item.count ? '查看' : '待解锁' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="invite-list__title">当前已成功邀请好友 {{ stepNum }} 人</div>
      <div class="invite-list">
        <div
          class="invite-list__item"
          v-for="(item, index) of inviterList"
          :key="index"
        >
          <img :src="item" alt="" v-if="item" class="invite-list__avatar" />
          <img :src="inviteAddImg" alt="" v-else />
        </div>
        <img
          src="@assets/images/invite_more.png"
          alt=""
          style="height: 12px; width: auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import viewProgress from './viewProgress'
export default {
  components: {
    viewProgress
  },
  data () {
    return {
      defaultPrizeCover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
      inviteAddImg: 'https://image.01lb.com.cn//uploads/wecom/22/0811/1660206501w4611t24.png',
      inviterList: ['https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg', null, null, null, null],
      stepNum: 1
    }
  },
  computed: {
    ...mapGetters({
      rewards: 'reserveTask/GET_REWARD'
    })
  }
}
</script>

<style lang="scss" scoped>
.theme-text {
  color: #e537a3;
}

.bg {
  background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0811/1660210620k7CDxp2n.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  background-color: #fff8fc;
  box-shadow: 0px 3px 5px 0px #fcdec8, 0px 2px 0px 0px #fbf7ff,
    0px 1.5px 0px 0px #fff inset;
  border-radius: 16px;
}

.title {
  width: 180px;
  height: 38px;
  margin: 0 auto;
  background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0811/1660210845DYm7wzlj.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  color: #fff8fe;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  box-sizing: border-box;
}

.invite {
  margin: 0 12px;
  margin-top: 16px;

  &-body {
    padding: 0 12px;
    margin-top: 18px;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 26px;
    box-sizing: border-box;

    &__title {
      color: #40347e;
      margin-top: 18px;
      margin-bottom: 12px;
      text-align: center;
    }

    &__item {
      height: 36px;
      width: 36px;

      &:not(:last-child) {
        margin-right: 8px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__avatar {
      border-radius: 50%;
    }
  }
}

.reward {
  &-single {
    display: flex;
    align-items: center;

    &__img {
      width: 60px;
      height: 60px;
      background: #ffffff;
      border: 1px solid #ffe7f3;
      border-radius: 5px;
      padding: 4px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__info {
      flex: 1;
      margin-right: 24px;
    }

    &__name {
      color: #40347e;
    }
  }

  &-multiple {
    &__body {
      display: flex;
    }

    &__list {
      flex: 1;
    }

    &__left {
      width: 18px;
    }

    &__item {
      display: flex;
      align-items: center;

      &.mg {
        height: 52px;
      }
    }

    &__col {
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__info {
      display: flex;
      flex: 1;
      margin-right: 0;
    }

    &__img {
      width: 40px;
      height: 40px;
      margin-right: 4px;
    }

    &__name {
      font-size: 12px;
    }
  }

  &-btn {
    width: 50px;
    height: 20px;
    border: 1px solid #e537a3;
    border-radius: 10px;
    color: #e537a3;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1;
    box-sizing: border-box;

    &.is-finish {
      background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0815/166054649928rORW46.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100% auto;
      color: #fff;
      border: none;
    }
  }
}
</style>
