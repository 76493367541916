/*
 * @Author: zhanln
 * @Date: 2021-07-10 15:09:57
 * @LastEditTime: 2022-01-05 18:18:23
 * @LastEditors: zhanln
 * @Description: 映射/反映射，消息匹配字段
 */

const tList = [
  {
    tag: '{{CUSTOMER_NAME}}',
    text: '#用户昵称#',
    oldText: '#客户昵称#'
  },
  {
    tag: '{{ACTIVITY_TITLE}}',
    text: '#活动名称#'
  },
  {
    tag: '{{ACTIVITY_STEP}}',
    text: '#任务阶段#'
  },
  {
    tag: '{{HELP_NICKNAME}}',
    text: '#助力好友昵称#'
  },
  {
    tag: '{{FINISH_NUM}}',
    text: '#已完成任务量#'
  },
  {
    tag: '{{REMAIN_NUM}}',
    text: '#未完成任务量#'
  },
  {
    tag: '{{USER_NAME}}',
    text: '#涨粉账号#'
  },
  {
    tag: '{{REWARD_TITLE}}',
    text: '#奖品名称#'
  },
  {
    tag: '{{TASK_FINISH_NUM}}',
    text: '#领奖人数#'
  },
  {
    tag: '{{ACTIVITY_LINK}}',
    text: '#活动链接#'
  }
]

const mapTag = (str, type = true) => {
  let _str = str
  if (!type) { // text 转 tag
    _str = _str.replace(new RegExp('#客户昵称#', 'g'), '{{CUSTOMER_NAME}}')
    for (let i = 0; i < tList.length; i++) {
      _str = _str.replace(new RegExp(tList[i].text, 'g'), tList[i].tag)
    }
  } else { // tag 转 text
    for (let i = 0; i < tList.length; i++) {
      _str = _str.replace(new RegExp(tList[i].tag, 'g'), tList[i].text)
    }
  }
  return _str
}

export default mapTag
