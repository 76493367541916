var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "agr" },
    [
      _c("el-main", [
        _c(
          "div",
          { staticClass: "agr-block ly-shadow" },
          [
            _c(
              "el-scrollbar",
              { staticClass: "agr-block__scroll" },
              [
                _c("account", {
                  ref: "accountRef",
                  staticClass: "agr-account",
                  attrs: { type: "new" }
                }),
                _c("setting", {
                  ref: "settingRef",
                  attrs: { type: "new", "table-height": 400 }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-footer",
        { staticClass: "agr-footer" },
        [
          _vm.step === 2
            ? _c(
                "el-button",
                {
                  attrs: { round: "", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      _vm.step = 1
                    }
                  }
                },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.fn_confirm }
            },
            [_vm._v("确认")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }