<template>
  <el-card>
    <div class="main-title">数据概览</div>

    <el-row :gutter="20" v-if="!isShowEmpty">
      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title">
            昨日新增用户数
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">
                昨日扫码添加（包含昨日已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算
              </div>
              <i class="iconfont icon-info-fill"></i>
            </el-tooltip>
          </div>
          <div class="nums">{{ panelInfo.yesterdayAddUserCount || 0 }}</div>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title">
            昨日新增留存率
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">
                昨日新增且昨日未删除的用户数/昨日新增用户数
              </div>
              <i class="iconfont icon-info-fill"></i>
            </el-tooltip>
          </div>
          <div class="nums">
            {{ panelInfo.yesterdayRetentionRate || 0 }}

            <template v-if="panelInfo.yesterdayRetentionRate">%</template>
          </div>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title">
            今日新增用户数
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">
                今日扫码添加（包含已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算
              </div>
              <i class="iconfont icon-info-fill"></i>
            </el-tooltip>
          </div>
          <div class="nums">{{ panelInfo.todayAddUserCount || 0 }}</div>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title">
            今日新增留存率
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">今日新增且未删除的用户数/今日新增用户数</div>
              <i class="iconfont icon-info-fill"></i>
            </el-tooltip>
          </div>
          <div class="nums">
            {{ panelInfo.todayRetentionRate || 0 }}
            <template v-if="panelInfo.todayRetentionRate">%</template>
          </div>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title">
            总用户数
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">留存的用户数</div>
              <i class="iconfont icon-info-fill"></i>
            </el-tooltip>
          </div>
          <div class="nums">{{ panelInfo.userCount || 0 }}</div>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title">
            总留存率
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">总用户数/新增用户总数</div>
              <i class="iconfont icon-info-fill"></i>
            </el-tooltip>
          </div>
          <div class="nums">
            {{ panelInfo.retentionRate || 0 }}
            <template v-if="panelInfo.retentionRate">%</template>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row v-if="isShowEmpty">
      <el-col :span="24" class="flex flex-center" style="text-align: center">
        <el-empty :image-size="200" description="请选择渠道码"></el-empty>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>

export default {
  props: {
    isShowEmpty: {
      type: Boolean,
      default: false
    },
    panelInfo: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang='scss' scoped>
.el-card {
  background: #fff;
  // padding: 24px;

  .main-title {
    font-size: 16px;
    font-weight: 500;
    color: #212b36;
    margin-bottom: 16px;
    padding-left: 10px;
    position: relative;
    &::before{
      position: absolute;
      content: "";
      width: 5px;
      height: 12px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #2B7CFF;
      border-radius: 2.5px;
    }
  }
  .el-col {
    .el-col-wrapper {
      background: #F7F8FC;
      width: 100%;
      height: 120px;
      padding: 0 35px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title{
        color: #6E788A;
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 22px
      }
    }
  }

  .nums {
    color: #131d24;
    font-size: 30px;
    font-weight: 500;
    line-height: 32px;
  }
}
</style>
