var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.element && _vm.element.key
    ? _c(
        "el-form-item",
        {
          staticClass: "normalTable-view drag-normalTable",
          class: {
            active: _vm.selectnormalTable.key == _vm.element.key,
            is_req: _vm.element.options.required
          },
          attrs: { label: _vm.index + 1 + "." + _vm.element.name },
          nativeOn: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleSelectnormalTable(_vm.index)
            }
          }
        },
        [
          _vm.element.type == "input"
            ? [
                _c("el-input", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    placeholder: _vm.element.options.placeholder,
                    disabled: _vm.element.options.disabled,
                    type: _vm.element.options.type,
                    readonly: ""
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue"
                  }
                })
              ]
            : _vm._e(),
          _vm.element.type == "textarea"
            ? [
                _c("el-input", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    type: "textarea",
                    rows: 5,
                    disabled: _vm.element.options.disabled,
                    placeholder: _vm.element.options.placeholder,
                    readonly: ""
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue"
                  }
                })
              ]
            : _vm._e(),
          _vm.element.type == "number"
            ? [
                _c("el-input-number", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    disabled: _vm.element.options.disabled,
                    "controls-position": _vm.element.options.controlsPosition,
                    readonly: ""
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue"
                  }
                })
              ]
            : _vm._e(),
          _vm.element.type == "radio"
            ? [
                _c(
                  "el-radio-group",
                  {
                    style: { width: _vm.element.options.width },
                    model: {
                      value: _vm.element.options.defaultValue,
                      callback: function($$v) {
                        _vm.$set(_vm.element.options, "defaultValue", $$v)
                      },
                      expression: "element.options.defaultValue"
                    }
                  },
                  _vm._l(_vm.element.options.options, function(item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: item.value + index,
                        staticStyle: { display: "block" },
                        attrs: { label: item.value }
                      },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
                  1
                )
              ]
            : _vm._e(),
          _vm.element.type == "checkbox"
            ? [
                _c(
                  "el-checkbox-group",
                  {
                    style: { width: _vm.element.options.width },
                    model: {
                      value: _vm.element.options.defaultValue,
                      callback: function($$v) {
                        _vm.$set(_vm.element.options, "defaultValue", $$v)
                      },
                      expression: "element.options.defaultValue"
                    }
                  },
                  _vm._l(_vm.element.options.options, function(item, index) {
                    return _c(
                      "el-checkbox",
                      {
                        key: item.value + index,
                        staticStyle: { display: "block" },
                        attrs: { label: item.value }
                      },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
                  1
                )
              ]
            : _vm._e(),
          _vm.element.type == "select"
            ? [
                _c(
                  "el-select",
                  {
                    style: { width: _vm.element.options.width },
                    attrs: {
                      disabled: _vm.element.options.disabled,
                      multiple: _vm.element.options.multiple,
                      clearable: _vm.element.options.clearable,
                      placeholder: _vm.element.options.placeholder
                    },
                    model: {
                      value: _vm.element.options.defaultValue,
                      callback: function($$v) {
                        _vm.$set(_vm.element.options, "defaultValue", $$v)
                      },
                      expression: "element.options.defaultValue"
                    }
                  },
                  _vm._l(_vm.element.options.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { value: item.value, label: item.value }
                    })
                  }),
                  1
                )
              ]
            : _vm._e(),
          _vm.element.type == "area"
            ? [
                _c(
                  "el-select",
                  {
                    staticStyle: { "margin-bottom": "8px" },
                    style: { width: _vm.element.options.width },
                    attrs: {
                      placeholder: _vm.element.options.placeholder,
                      disabled: _vm.element.options.disabled
                    },
                    model: {
                      value: _vm.element.options.pro,
                      callback: function($$v) {
                        _vm.$set(_vm.element.options, "pro", $$v)
                      },
                      expression: "element.options.pro"
                    }
                  },
                  [
                    _c("el-option", { attrs: { label: "北京", value: "北京" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 5,
                        disabled: _vm.element.options.disabled,
                        placeholder: _vm.element.options.descplaceholder,
                        readonly: ""
                      },
                      model: {
                        value: _vm.element.options.desc,
                        callback: function($$v) {
                          _vm.$set(_vm.element.options, "desc", $$v)
                        },
                        expression: "element.options.desc"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.selectnormalTable.key == _vm.element.key
            ? _c("div", { staticClass: "normalTable-view-action" }, [
                _c("i", {
                  staticClass: "el-icon-document-copy",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handlenormalTableClone(_vm.index)
                    }
                  }
                }),
                !_vm.selectnormalTable.options.isDelete
                  ? _c("i", {
                      staticClass: "el-icon-delete",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handlenormalTableDelete(_vm.index)
                        }
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }