<template>
  <div class="mobile">
    <div class="mobile-topbar">
      <div>{{ $day().format("HH:mm") }}</div>
      <div class="flex">
        <i class="iconfont icon-a-CellularConnection"></i>
        <i class="iconfont icon-Wifi"></i>
        <i class="iconfont icon-Battery"></i>
      </div>
    </div>
    <div class="mobile-titlebar">
      <i class="iconfont icon-close"></i>
      <div class="flex flex-column">
        <span style="font-size: 12px">{{ otherForm.show_title }}</span>
      </div>
      <i class="el-icon-more"></i>
    </div>
    <div :class="['mobile-container', tabKey === 'other' ? 'mask' : '']">
      <!-- 背景图 -->
      <div class="mobile-container__bg"></div>

      <!-- 活动 -->
      <div class="rede">
        <!-- 跑马灯 -->
        <div class="rede-scroll">
          <img :src="scrollAvatar" alt="" class="rede-scroll__avatar" />
          {{ scrollName }} 获得 xx 元
        </div>

        <!-- 标题 -->
        <template v-if="otherForm.bg_title">
          <div
            class="rede-title"
            v-html="
              otherForm.bg_title.length > 8
                ? otherForm.bg_title.length > 8
                  ? otherForm.bg_title.slice(0, 8) +
                    '<br />' +
                    otherForm.bg_title.slice(8, otherForm.bg_title.length)
                  : otherForm.bg_title
                : otherForm.bg_title
            "
          ></div>
        </template>
        <div class="rede-title" v-else>请输入封面标题</div>
        <div class="rede-sub">
          <span class="left">
            <img :src="subLeftImg" alt="" />
          </span>
          <span class="rede-sub__text"
            >每邀 {{ redeForm.invite_num }} 人，拆 1 次红包</span
          >
          <span class="left">
            <img :src="subRightImg" alt="" />
          </span>
        </div>

        <!-- 红包 -->
        <div class="rede-bag">
          <div class="rede-bag__average">
            人均获得<b class="q-danger">{{ redeForm.virtual_average }}</b
            >元，您暂获：
          </div>
          <div class="rede-bag__amount"><span class="sub">￥</span>66.66</div>
          <div class="rede-bag__wechat flex flex-center">
            <img :src="wechatDefaultImg" alt="" />微信到账
          </div>
          <div class="rede-bag__time">
            红包翻倍<span>23</span>分<span>53</span>秒<span>53</span>后失效！
          </div>
          <div class="rede-bag__btn">
            还差{{ redeForm.invite_num }}人～开多1次
          </div>
        </div>

        <!-- 排行 -->
        <div class="rede-rank"></div>

        <!-- 浮动框-->
        <div class="rede-fixed">
          <div class="rede-fixed__block">活动规则</div>
          <div class="rede-fixed__block">红包记录</div>
        </div>

        <div class="rede-dialog flex flex-center" v-if="tabKey === 'other'">
          <div class="rede-dialog__container">
            <div class="rede-dialog__header flex flex-center">
              <div class="rede-dialog__title">活动规则</div>
              <div class="rede-dialog__title-before"></div>
            </div>
            <el-input
              type="textarea"
              v-model="otherForm.rule_desc"
              class="rede-dialog__body"
              autosize
              resize="none"
              readonly
            ></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    tabKey: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      scrollAvatar: '',
      subLeftImg: require('@assets/images/rede_ellipse_left.png'),
      subRightImg: require('@assets/images/rede_ellipse_right.png'),
      wechatDefaultImg: require('@assets/images/wx_pay.png')
    }
  },

  computed: {
    basicForm () {
      return this.$store.state.rede.basic
    },
    redeForm () {
      return this.$store.state.rede.redenvelope
    },
    otherForm () {
      return this.$store.state.rede.other
    }
  },

  created () {
    const USERINFO = this.$store.state.permission.wecomUserInfo
    this.scrollAvatar = (USERINFO && USERINFO.avatar_url) || 'https://rescdn.qqmail.com/node/wwmng/wwmng/style/images/independent/DefaultAvatar$73ba92b5.png'
    this.scrollName = (USERINFO && USERINFO.nickname) || '用户昵称'
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";
$--color-rede-red: #d11600;
.mobile {
  position: relative;
  overflow: hidden;
  user-select: none;
  width: 342px;
  height: 666px;
  background-size: 100%;
  padding: 15px 18px;
  box-sizing: border-box;
  margin: 0 auto;
  background-image: url(~@/assets/images/mobile.png);
  &-topbar {
    height: 20px;
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    padding: 0 15px 0 20px;
    font-weight: 600;
    font-size: 12px;
    i {
      font-size: 12px;
    }
  }
  &-titlebar {
    height: 44px;
    display: flex;
    align-items: center;
    color: #000;
    justify-content: space-between;
    padding: 0 12px;
    .text-small {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  &-container {
    position: relative;
    height: 573px;
    background: linear-gradient(#ff501d, #ffc37d);
    overflow: auto;
    border-radius: 0 0 36px 36px;

    &.mask {
      overflow: hidden;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("https://image.01lb.com.cn//uploads/wecom/21/0430/1619775847vRS7X0F5.png")
        no-repeat top center;
      background-size: 100%;
      z-index: 1;
    }
  }
}

.rede {
  position: relative;
  z-index: 2;
  &-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 12px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);

    &__avatar {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }

  &-title {
    font-size: 28px;
    font-style: italic;
    font-weight: bold;
    color: #fff;
    margin: 8px auto;
    letter-spacing: 4px;
    word-break: break-all;
    text-align: center;
  }

  &-sub {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    .left,
    .right {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        width: 23px;
      }
    }
    &__text {
      margin: 0 8px;
    }
  }

  &-bag {
    width: calc(320px * 0.9);
    height: calc(373px * 0.9);
    margin: 16px auto;
    background: url("https://image.01lb.com.cn//uploads/wecom/21/0430/1619776130ez26q5QS.png")
      no-repeat top center;
    background-size: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__average {
      font-size: 12px;
      color: #ca7f15;
      background-color: rgba(#ffc37d, 0.3);
      width: calc(324px * 0.65);
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 13px;
      margin-top: 20px;

      .q-danger {
        margin: 0 2px;
        color: $--color-rede-red;
      }
    }

    &__amount {
      font-size: 50px;
      line-height: 55px;
      margin-top: 12px;
      font-weight: 600;
      color: $--color-rede-red;
      font-family: PingFangSC, PingFangSC-Semibold;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);

      .sub {
        font-size: 29px;
      }
    }

    &__wechat {
      font-size: 13px;
      color: #09bb07;

      img {
        margin-right: 4px;
      }
    }

    &__time {
      color: #fff177;
      font-size: 13px;
      margin-top: 48px;

      span {
        width: 21px;
        height: 20px;
        margin: 0 4px;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #c60c0c;
        border-radius: 3px;
      }
    }

    &__btn {
      height: 50px;
      width: 243px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      background: linear-gradient(180deg, #fff176 6%, #ff9e30);
      border-radius: 25px;
      color: $--color-rede-red;
      font-size: 17px;
      font-weight: 600;
    }
  }

  &-rank {
    width: calc(318px * 0.9);
    height: calc(490px * 0.9);
    margin: 16px auto;
    background: url("https://image.01lb.com.cn//uploads/wecom/21/0420/1618909578sF3W0kF8.png")
      no-repeat top center;
    background-size: 100%;
  }

  &-fixed {
    position: absolute;
    right: 0;
    top: 56px;
    color: #fff;
    font-size: 12px;

    &__block {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0 6px 0 12px;
      border-radius: 12px 0 0 12px;
      background-color: rgba(0, 0, 0, 0.2);

      & + & {
        margin-top: 8px;
      }
    }
  }

  &-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 613.64px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;

    &__container {
      width: calc(297px * 0.9);
      margin: 0 auto;
      background-color: #fcf9e7;
      border-radius: 12px;
      overflow: hidden;
    }

    &__header {
      position: relative;
      height: 48px;
      border-bottom: 1px dashed #ffe3b1;
    }

    &__title {
      position: relative;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
      color: $--color-rede-red;
      z-index: 999;
    }

    &__title-before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 14px;
      width: 74px;
      height: 9px;
      background: linear-gradient(181deg, #fff176 7%, #ff5900 100%);
      border-radius: 5px;
      z-index: 1;
    }

    &__body {
      ::v-deep .el-textarea__inner {
        padding: 12px 16px;
        font-size: 12px;
        color: $--color-rede-red;
        line-height: 20px;
        max-height: 300px;
        border: none;
        border-radius: 0;
        background-color: #fcf9e7;
      }
    }
  }
}
</style>
