var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["progress", _vm.type],
      style: {
        height: _vm.type === "vertical" ? "calc(100% - 26px)" : "auto",
        "--innerWidth": _vm.innerWidth,
        "--dotTop": _vm.dotTop
      }
    },
    [
      _vm._m(0),
      !_vm.type
        ? _c("div", { staticClass: "progress-count" }, [
            _vm._v(_vm._s(_vm.stepNum) + "/" + _vm._s(_vm.count))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress-bar" }, [
      _c("div", { staticClass: "progress-inner" }),
      _c("div", { staticClass: "progress-dot" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }