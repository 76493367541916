var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "im" },
    [
      _vm._t("im_header"),
      _c(
        "div",
        { staticClass: "chatWindow" },
        [
          _c(
            "el-scrollbar",
            { ref: "chatWindow_scrollbar", staticStyle: { height: "100%" } },
            _vm._l(_vm.msgList, function(item) {
              return _c("msgBubbles", {
                key: item.msg.id,
                attrs: { msgData: item }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._t("im_footer")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }