<!--
 * @Author: zhanln
 * @Date: 2021-11-18 16:34:53
 * @LastEditTime: 2022-08-19 17:32:42
 * @LastEditors: zhan
 * @Description: 预览 - 信息通知
-->

<template>
  <ly-mobile :msgList="msgList" :showWel="false"></ly-mobile>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileNotice',

  data () {
    return {
      msgList: [
        {
          type: 'applet',
          logo: '',
          name: '小程序名称',
          title: '小程序标题',
          cover: require('@/assets/images/applet.png')
        }
      ]
    }
  },

  computed: {
    ...mapState({
      share_cover: state => state.reserveTask.share_cover,
      share_title: state => state.reserveTask.share_title,
      appInfo: state => state.miniapp.info
    })
  },

  watch: {
    share_cover (val) {
      this.msgList[0].cover = val
    },
    share_title (val) {
      this.msgList[0].title = val
    }
  },

  mounted () {
    this.msgList[0].logo = this.appInfo.head_image
    this.msgList[0].name = this.appInfo.nick_name
    if (this.share_cover) {
      this.msgList[0].cover = this.share_cover
    }
    this.msgList[0].title = this.share_title
  }
}
</script>
