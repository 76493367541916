var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "formRef",
      staticClass: "edit-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "130px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { key: "name", attrs: { label: "素材名称：", prop: "name" } },
        [
          _c("el-input", {
            staticClass: "edit-form__input",
            attrs: {
              maxlength: "20",
              "show-word-limit": "",
              placeholder: "请输入素材名称"
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("template", { slot: "label" }, [
            _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
            _vm._v(" 分组：")
          ]),
          _c(
            "el-select",
            {
              staticClass: "edit-form__input",
              model: {
                value: _vm.form.group_id,
                callback: function($$v) {
                  _vm.$set(_vm.form, "group_id", $$v)
                },
                expression: "form.group_id"
              }
            },
            _vm._l(_vm.groupList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        2
      ),
      _vm.form.obtain_method === 1
        ? [
            _c(
              "el-form-item",
              {
                key: "app_name",
                attrs: { label: "小程序名称：", prop: "app_name" }
              },
              [
                _c("el-input", {
                  staticClass: "edit-form__input",
                  attrs: {
                    maxlength: "15",
                    "show-word-limit": "",
                    placeholder: "请输入小程序名称"
                  },
                  model: {
                    value: _vm.form.app_name,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "app_name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.app_name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { key: "title", attrs: { label: "小程序标题：", prop: "title" } },
              [
                _c("el-input", {
                  staticClass: "edit-form__input",
                  attrs: {
                    maxlength: "32",
                    "show-word-limit": "",
                    placeholder: "请输入小程序标题"
                  },
                  model: {
                    value: _vm.form.title,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "title",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                key: "app_id",
                staticClass: "edit-form__item",
                attrs: { label: "小程序appid：", prop: "app_id" }
              },
              [
                _c("el-input", {
                  staticClass: "edit-form__input",
                  attrs: {
                    maxlength: "32",
                    "show-word-limit": "",
                    placeholder: "请输入小程序appid"
                  },
                  model: {
                    value: _vm.form.app_id,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "app_id",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.app_id"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "edit-form__tip q-primary text-small",
                    staticStyle: { "text-align": "right", width: "320px" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.fn_jumpToLink(1)
                          }
                        }
                      },
                      [_vm._v(" 如何获取小程序appid？")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                key: "path",
                staticClass: "edit-form__item",
                attrs: { label: "小程序路径：", prop: "path" }
              },
              [
                _c("el-input", {
                  staticClass: "edit-form__input",
                  attrs: {
                    maxlength: "250",
                    "show-word-limit": "",
                    placeholder: "请输入小程序路径"
                  },
                  model: {
                    value: _vm.form.path,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "path",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.path"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "edit-form__tip q-primary text-small",
                    staticStyle: { "text-align": "right", width: "320px" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.fn_jumpToLink(2)
                          }
                        }
                      },
                      [_vm._v(" 如何获取小程序路径？")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                key: "cover_image",
                attrs: { label: "封面图：", prop: "cover_image" }
              },
              [
                _c("div", { staticClass: "q-info text-small" }, [
                  _vm._v(" 支持JPG、PNG格式，图片小于2M，尺寸建议500*376px ")
                ]),
                _c("ly-upload", {
                  attrs: { img: _vm.form.cover_image, tipIcon: "el-icon-plus" },
                  on: { getImg: _vm.fn_getCover }
                })
              ],
              1
            )
          ]
        : [
            _c(
              "el-form-item",
              {
                key: "msg_content",
                attrs: { label: "素材内容：", prop: "msg_content" }
              },
              [
                _c("div", { staticClass: "edit-applet" }, [
                  !_vm.form.msg_content
                    ? _c(
                        "div",
                        {
                          staticClass: "edit-applet__upload",
                          on: {
                            click: function($event) {
                              return _vm.$refs.selectRecord.fn_open(2013)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "edit-applet__upload--icon" },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _c(
                            "div",
                            { staticClass: "edit-applet__upload--tip" },
                            [_vm._v("从聊天记录中选择小程序")]
                          )
                        ]
                      )
                    : _c(
                        "div",
                        { ref: "applet", staticClass: "edit-applet__info" },
                        [
                          _c("app-poster", {
                            attrs: {
                              applet: _vm.form,
                              cover: _vm.form.cover_image
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "edit-applet__remove",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.fn_handleRemove($event)
                                }
                              }
                            },
                            [_c("i", { staticClass: "iconfont icon-delete" })]
                          )
                        ],
                        1
                      )
                ]),
                _c("select-record", {
                  ref: "selectRecord",
                  on: { chose: _vm.handleChoseApp }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }