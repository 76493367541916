var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("act-layout", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.pageLoading,
        expression: "pageLoading"
      }
    ],
    ref: "cards",
    attrs: { cards: _vm.cards },
    on: { setKey: _vm.fn_setKey },
    scopedSlots: _vm._u(
      [
        {
          key: "basic",
          fn: function() {
            return [_c("act-basic", { ref: "basic" })]
          },
          proxy: true
        },
        {
          key: "poster",
          fn: function() {
            return [_c("act-poster", { ref: "poster" })]
          },
          proxy: true
        },
        {
          key: "mobile",
          fn: function() {
            return [
              _vm.tabKey === "basic" ? _c("mobi-basic") : _vm._e(),
              _vm.tabKey === "poster" ? _c("mobi-poster") : _vm._e()
            ]
          },
          proxy: true
        },
        !_vm.pageLoading
          ? {
              key: "footer",
              fn: function() {
                return [
                  _c("act-footer", {
                    attrs: { btnLoading: _vm.btnLoading, isEnd: _vm.isEnd },
                    on: { submit: _vm.fn_submit }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }