var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading"
        }
      ],
      staticClass: "edit-card"
    },
    [
      _c("div", { staticClass: "edit-card__header flex flex-between" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "amount" },
          [
            _vm._v(" 账户可用余额："),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.redForm.account.account_amount_fmt))
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "text" },
                on: {
                  click: function($event) {
                    _vm.$refs.recharge.rechargeVisible = true
                  }
                }
              },
              [_vm._v("立即充值")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "edit-card__content" },
        [
          _c(
            "el-form",
            {
              ref: "redFormRef",
              staticStyle: { width: "100%", "max-width": "760px" },
              attrs: {
                model: _vm.redForm,
                rules: _vm.rules,
                "label-width": "150px",
                size: "medium"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "活动金额", prop: "activity_amount" },
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function(ref) {
                        var error = ref.error
                        return [
                          _c(
                            "div",
                            {
                              class: ["el-form-item__error", "customErrorClass"]
                            },
                            [_vm._v(" " + _vm._s(error) + " ")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      "controls-position": "right",
                      min: _vm.redForm.give_amount,
                      precision: 2
                    },
                    on: {
                      change: function($event) {
                        return _vm.fn_setRede("activity_amount", $event)
                      },
                      blur: function($event) {
                        return _vm.fn_validAmount($event)
                      }
                    },
                    model: {
                      value: _vm.redForm.activity_amount,
                      callback: function($$v) {
                        _vm.$set(_vm.redForm, "activity_amount", $$v)
                      },
                      expression: "redForm.activity_amount"
                    }
                  }),
                  !_vm.isCopy && _vm.isEdit && _vm.status === 1
                    ? _c("div", { staticStyle: { "margin-top": "8px" } }, [
                        _vm._v(" 已发放金额："),
                        _c(
                          "span",
                          {
                            staticClass: "q-primary",
                            staticStyle: { "margin-right": "16px" }
                          },
                          [_vm._v(_vm._s(_vm.give_amount))]
                        ),
                        _vm._v(" 剩余活动金额："),
                        _c("span", { staticClass: "q-primary" }, [
                          _vm._v(_vm._s(_vm.remain))
                        ]),
                        _vm.calculate !== 0
                          ? _c(
                              "span",
                              {
                                class: [
                                  _vm.calculate >= 0 ? "q-success" : "q-danger"
                                ]
                              },
                              [
                                _vm._v(
                                  "（" +
                                    _vm._s(_vm.calculate >= 0 ? "+" : "") +
                                    _vm._s(_vm.calculate.toFixed(2)) +
                                    "）"
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c(
                    "p",
                    {
                      staticClass: "is-primary text-small",
                      staticStyle: {
                        "line-height": "24px",
                        margin: "8px 0 0",
                        color: "#6e788a"
                      }
                    },
                    [
                      _vm._v(
                        " 1. 活动发布后，暂时从红包账户内冻结对应的活动金额。"
                      ),
                      _c("br"),
                      _vm._v(" 2. 活动发布后，活动金额支持修改。"),
                      _c("br"),
                      _vm._v(
                        " 3. 每次向用户发放红包，平台收取" +
                          _vm._s(_vm.redForm.account.fee_rate) +
                          "%手续费，手续费不足0.01元时，按0.01元计算。"
                      ),
                      _c("br"),
                      _vm._v(" 4. 活动金额发放完后，活动自动结束。 ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟人均获得", required: "" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      max: 999,
                      precision: 2
                    },
                    on: {
                      change: function($event) {
                        return _vm.fn_setRede("virtual_average", $event)
                      }
                    },
                    model: {
                      value: _vm.redForm.virtual_average,
                      callback: function($$v) {
                        _vm.$set(_vm.redForm, "virtual_average", $$v)
                      },
                      expression: "redForm.virtual_average"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "红包规则", required: "" } },
                [
                  _c("div", { staticClass: "q-bg is-default rules" }, [
                    _c(
                      "div",
                      [
                        _vm._v(" 用户首拆红包，领取 "),
                        _c("el-input-number", {
                          staticClass: "number-small",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 0.31,
                            max: _vm.redForm.first_give_max,
                            precision: 2,
                            disabled: !_vm.redForm.activity_amount
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setRede("first_give_min", $event)
                            }
                          },
                          model: {
                            value: _vm.redForm.first_give_min,
                            callback: function($$v) {
                              _vm.$set(_vm.redForm, "first_give_min", $$v)
                            },
                            expression: "redForm.first_give_min"
                          }
                        }),
                        _vm._v(" ~ "),
                        _c("el-input-number", {
                          staticClass: "number-small",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: _vm.redForm.first_give_min,
                            max: _vm.redmax,
                            precision: 2,
                            disabled: !_vm.redForm.activity_amount
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setRede("first_give_max", $event)
                            }
                          },
                          model: {
                            value: _vm.redForm.first_give_max,
                            callback: function($$v) {
                              _vm.$set(_vm.redForm, "first_give_max", $$v)
                            },
                            expression: "redForm.first_give_max"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "4px" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "企业老用户也享有首拆",
                            disabled: _vm.ruleDisable
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setRede("is_give_old_user", $event)
                            }
                          },
                          model: {
                            value: _vm.redForm.is_give_old_user,
                            callback: function($$v) {
                              _vm.$set(_vm.redForm, "is_give_old_user", $$v)
                            },
                            expression: "redForm.is_give_old_user"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "q-bg is-default rules" }, [
                    _c(
                      "div",
                      [
                        _vm._v(" 每邀请 "),
                        _c("el-input-number", {
                          staticClass: "number-small",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 1,
                            max: 99,
                            precision: 0,
                            disabled: _vm.ruleDisable
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setRede("invite_num", $event)
                            }
                          },
                          model: {
                            value: _vm.redForm.invite_num,
                            callback: function($$v) {
                              _vm.$set(_vm.redForm, "invite_num", $$v)
                            },
                            expression: "redForm.invite_num"
                          }
                        }),
                        _vm._v(" 人，领取 "),
                        _c("el-input-number", {
                          staticClass: "number-small",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 0.31,
                            max: _vm.redForm.give_max,
                            precision: 2,
                            disabled: !_vm.redForm.activity_amount
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setRede("give_min", $event)
                            }
                          },
                          model: {
                            value: _vm.redForm.give_min,
                            callback: function($$v) {
                              _vm.$set(_vm.redForm, "give_min", $$v)
                            },
                            expression: "redForm.give_min"
                          }
                        }),
                        _vm._v(" ~ "),
                        _c("el-input-number", {
                          staticClass: "number-small",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: _vm.redForm.give_min,
                            max: _vm.redmax,
                            precision: 2,
                            disabled: !_vm.redForm.activity_amount
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setRede("give_max", $event)
                            }
                          },
                          model: {
                            value: _vm.redForm.give_max,
                            callback: function($$v) {
                              _vm.$set(_vm.redForm, "give_max", $$v)
                            },
                            expression: "redForm.give_max"
                          }
                        }),
                        _vm._v(" 元 ")
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "12px" } },
                      [
                        _vm._v(" 每人单场活动最多领取 "),
                        _c("el-input-number", {
                          staticClass: "number-small",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 1,
                            max: 99,
                            precision: 0,
                            disabled: _vm.ruleDisable
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setRede("give_limit", $event)
                            }
                          },
                          model: {
                            value: _vm.redForm.give_limit,
                            callback: function($$v) {
                              _vm.$set(_vm.redForm, "give_limit", $$v)
                            },
                            expression: "redForm.give_limit"
                          }
                        }),
                        _vm._v(" 个红包 ")
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "q-danger text-small",
                      staticStyle: {
                        "line-height": "24px",
                        "margin-top": "8px"
                      }
                    },
                    [
                      _vm._v(
                        " * 发布活动后，老用户是否享有首拆、邀请人数、红包最多领取个数不允许修改 "
                      )
                    ]
                  )
                ]
              ),
              _c("el-form-item", { attrs: { label: "自动打标签" } }, [
                _c(
                  "div",
                  { staticClass: "flex", staticStyle: { height: "36px" } },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-text": "开启后，可自动为活动用户打标签"
                      },
                      on: {
                        change: function($event) {
                          return _vm.fn_setRede("is_auto_tags", $event)
                        }
                      },
                      model: {
                        value: _vm.redForm.is_auto_tags,
                        callback: function($$v) {
                          _vm.$set(_vm.redForm, "is_auto_tags", $$v)
                        },
                        expression: "redForm.is_auto_tags"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.redForm.is_auto_tags,
                        expression: "redForm.is_auto_tags"
                      }
                    ]
                  },
                  [
                    _c("div", [_vm._v("为本次活动新增客户自动打标签")]),
                    _c("tag-select-v2", {
                      staticClass: "tag-select",
                      attrs: { checked: _vm.addTags.tags },
                      on: {
                        getTagId: function($event) {
                          return _vm.fn_setTags(_vm.addTags.key, $event)
                        }
                      }
                    }),
                    _c("div", { staticStyle: { "margin-top": "16px" } }, [
                      _vm._v("按邀请人数自动为客户打标签")
                    ]),
                    _c(
                      "div",
                      { staticClass: "border-temp" },
                      [
                        _vm._l(_vm.inviteTags, function(item, index) {
                          return [
                            _c("tag-select-v2", {
                              key: item.key,
                              staticClass: "tag-select",
                              attrs: { checked: item.tags },
                              on: {
                                getTagId: function($event) {
                                  return _vm.fn_setTags(item.key, $event)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "prefix",
                                    fn: function() {
                                      return [
                                        _vm._v(" 邀请人数为 "),
                                        _c("el-input-number", {
                                          staticClass: "number-small number-mn",
                                          attrs: {
                                            size: "small",
                                            "controls-position": "right",
                                            min: 1,
                                            max: 999,
                                            precision: 0
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.fn_setTagsNum(
                                                item.key,
                                                $event
                                              )
                                            }
                                          },
                                          model: {
                                            value: item.invite_num,
                                            callback: function($$v) {
                                              _vm.$set(item, "invite_num", $$v)
                                            },
                                            expression: "item.invite_num"
                                          }
                                        }),
                                        _vm._v(" 时，自动打标签 ")
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "suffix",
                                    fn: function() {
                                      return [
                                        _c("el-button", {
                                          staticClass: "tag-minus",
                                          attrs: {
                                            icon: "el-icon-minus",
                                            circle: "",
                                            size: "mini",
                                            type: "danger",
                                            disabled:
                                              _vm.inviteTags.length === 1
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.fn_removeInviteTag(
                                                index
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "tag-select__add" },
                      [
                        _vm.inviteTags.length >= 10
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "最多可设置10条规则",
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "iconfont icon-plus-fill",
                                          type: "text",
                                          disabled: ""
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont icon-plus-fill"
                                        }),
                                        _vm._v(" 新增邀请人数设置")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "iconfont icon-plus-fill",
                                  type: "text"
                                },
                                on: { click: _vm.fn_addInviteTag }
                              },
                              [_vm._v("新增邀请人数设置")]
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("rd-recharge", { ref: "recharge", on: { success: _vm.fn_paySuccess } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }