<!--
 * @Author: zhanln
 * @Date: 2021-11-18 16:34:53
 * @LastEditTime: 2021-11-30 17:39:21
 * @LastEditors: zhanln
 * @Description: 预览 - 信息通知
-->

<template>
  <ly-mobile :msgList="msgList" :showWel="false"></ly-mobile>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileNotice',

  data () {
    return {
      msgList: [
        {
          type: 'text',
          content: ''
        },
        {
          type: 'link',
          title: '点击这里，领取任务奖品吧👇',
          desc: '赶快领取奖品吧',
          image: require('@/assets/images/task_gift.png')
        }
      ]
    }
  },

  computed: {
    ...mapState({
      reward_notice_text: state => state.task.reward_notice_text
    })
  },

  watch: {
    reward_notice_text (val) {
      this.msgList[0].content = val
    }
  },

  mounted () {
    this.msgList[0].content = this.$store.state.task.reward_notice_text
  }
}
</script>
