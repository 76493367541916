var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-footer" },
    [
      _c(
        "el-button",
        {
          attrs: {
            type: "primary",
            round: "",
            loading: _vm.saveLoading,
            icon: _vm.isEnd ? "" : "iconfont icon-send-fill",
            disabled: _vm.isEnd || _vm.draftLoading
          },
          on: {
            click: function($event) {
              return _vm.fn_save(1)
            }
          }
        },
        [_vm._v(_vm._s(_vm.isEnd ? "活动已结束" : "发布活动"))]
      ),
      _vm.eType !== 1
        ? _c(
            "el-button",
            {
              attrs: {
                round: "",
                icon: "iconfont icon-edit",
                loading: _vm.draftLoading,
                disabled: _vm.type === 1 && _vm.saveLoading
              },
              on: {
                click: function($event) {
                  return _vm.fn_save(2)
                }
              }
            },
            [_vm._v("保存草稿 ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }