<!--
 * @Author: zhanln
 * @Date: 2022-03-03 16:57:57
 * @LastEditTime: 2022-04-19 14:56:46
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <div class="page-warp">
    <ly-table
      class="act-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          size="medium"
          @click="$router.push('edit')"
          >添加客服</el-button
        >
        <el-button
          round
          size="medium"
          :disabled="!workUrl"
          @click="fn_jumpToWork"
          >客服工作台</el-button
        >
      </template>

      <!-- 列插槽 -->
      <template #account_slot="{ row }">
        <span
          v-html="
            row.account.length > 10
              ? row.account.slice(0, 10) +
                '<br />' +
                row.account.slice(10, row.account.length)
              : row.account
          "
        ></span>
      </template>

      <template #group_num="{ row }">
        <el-button
          type="text"
          @click="
            fn_showDialog('robots', row, {
              title: '接入企微号',
              width: '456px',
            })
          "
        >
          {{ row.online_num }} / {{ row.offline_num + row.online_num }}
        </el-button>
      </template>

      <template #creator="{ row }">
        <div class="flex">
          <el-image
            class="create-avatar"
            :src="row.avatar"
            fit="cover"
            lazy
          ></el-image>
          <span style="flex: 1">
            {{ row.creator }}
          </span>
        </div>
      </template>

      <template #status="{ row }">
        <el-switch
          v-model="row.status"
          :active-value="1"
          :inactive-value="2"
          @change="fn_changeStatus($event, row)"
        ></el-switch>
      </template>

      <template #operation="{ row }">
        <ly-action :key="Date.now()">
          <el-button
            type="text"
            @click="
              fn_showDialog('setting', row, {
                title: '设置',
                width: '770px',
              })
            "
            >设置</el-button
          >
          <el-button
            type="text"
            @click="
              fn_showDialog('account', row, {
                title: '修改账号密码',
                width: '428px',
              })
            "
            >修改账号密码</el-button
          >
          <el-button type="text" @click="fn_delete(row)">删除</el-button>
        </ly-action>
      </template>
    </ly-table>

    <!-- 弹框 -->
    <agr-dialog ref="agrDialogRef" @refresh="queryData"></agr-dialog>
  </div>
</template>

<script>
import mx_role from '@/components/mixins/role'
import lyTable from '@/components/mixins/ly-table.js'
import { agrs } from './http.js'
import agrDialog from './components/agrDialog'
export default {

  mixins: [lyTable, mx_role],

  components: {
    agrDialog
  },

  data () {
    return {
      COMM_HTTP: agrs,
      workUrl: ''
    }
  },

  computed: {
    tableOpt () {
      return {
        operationWidth: 240,
        column: [
          {
            label: '客服账号',
            prop: 'account',
            slot: 'account_slot',
            search: true,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '接入企微号（在线/总数）',
            slot: 'group_num',
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '创建人',
            prop: 'creator',
            slot: 'creator',
            type: 'select',
            source: this.fn_getCreate,
            search: true,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '创建时间',
            prop: 'created_date',
            type: 'daterange',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            },
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '禁用/开启',
            prop: 'status',
            slot: 'status',
            width: 100
          }
        ]
      }
    }
  },

  created () {
    this.fn_getWorkUrl()
  },

  methods: {

    // 获取客服工作台地址
    async fn_getWorkUrl () {
      const data = await agrs.workUrl()
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.workUrl = data.url
    },

    // 跳转客服工作台
    fn_jumpToWork () {
      window.open(this.workUrl, '_blank')
    },

    async fn_getCreate () {
      const res = await this.COMM_HTTP.creatorList()
      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }
      const result = res || []
      const list = []
      result.map(e => {
        list.push({
          label: e.name,
          value: e.id
        })
      })
      return list
    },

    // 按需打开弹框
    fn_showDialog (type, item, opt) {
      this.$refs.agrDialogRef.open(type, item, opt)
    },

    // 改变状态
    async fn_changeStatus (event, row) {
      if (event === 2) {
        this.$lyConfirm({
          title: '是否禁用？',
          text: '禁用账号后，客服账号无法正常登录客服工作台，是否确认禁用？'
        }).then(async () => {
          const data = await this.COMM_HTTP.changeStatus({ id: row.id, status: event })
          if ((data && data.code) || (data && data.code === 0)) {
            row.status = 1
            this.fn_lyMsg('info', data.msg)
            return false
          }

          this.fn_lyMsg('success', '客服账号禁用成功！')
        }).catch(() => {
          row.status = 1
        })
        return
      }

      const data = await this.COMM_HTTP.changeStatus({ id: row.id, status: event })
      if ((data && data.code) || (data && data.code === 0)) {
        row.status = 2
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.fn_lyMsg('success', '客服账号开启成功！')
    },

    // 删除
    fn_delete (item) {
      this.$lyConfirm({
        title: '是否确认删除？',
        text: '删除客服账号后，将同步删除该客服产生的聊天数据，不再保存，是否确认删除？'
      }).then(async () => {
        const data = await this.COMM_HTTP.delete({ id: item.id })
        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          return false
        }

        this.fn_lyMsg('success', '删除成功！')
        this.queryData()
      }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.act-list {
  ::v-deep .el-table.el-table--medium td {
    vertical-align: middle;
  }
}
</style>
