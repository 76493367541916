<!--
 * @Author: zhanln
 * @Date: 2021-07-30 14:50:12
 * @LastEditTime: 2022-06-05 16:16:22
 * @LastEditors: zhan
 * @Description:
-->
<template>
  <ly-mobile :showWel="false" :hasFooter="false" sub="" title="活动详情" class="ly-mobile">
    <div class="act">
      <!-- 活动首图 -->
      <div class="banner">
        <el-image :src="bg_url" class="banner-img" lazy>
          <div slot="placeholder" class="banner-img__err">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="banner-img__err">
            请上传活动首图
          </div>
        </el-image>
      </div>

      <!-- 预约 -->
      <div class="content">
        <div class="box">
          <div class="box-time">
            <img src="@/assets/images/icon/icon_clock.svg" alt="" class="box-icon">
            <span class="box-title">倒计时：</span>
            <div class="box-count">
              <span class="number">1</span><span class="text">天</span>
              <span class="number">23</span><span class="text">时</span>
              <span class="number">59</span><span class="text">分</span>
              <span class="number">59</span><span class="text">秒</span>
            </div>
          </div>
          <el-button class="box-btn">{{ btn_title }}</el-button>
        </div>
      </div>

      <!-- 更多预告 -->
      <div class="list">
        <div class="list-tab">
          <div class="list-tab__item" :class="tabName === 0 ? 'active' : ''" @click="tabName = 0" v-if="notice.length">
            更多直播预告
          </div>
          <div class="list-tab__item" :class="tabName === 1 || !notice.length ? 'active' : ''" @click="tabName = 1">
            我的邀请记录
          </div>
        </div>
        <template v-if="notice.length && tabName === 0">
          <div class="list-item" :class="index === 0 ? 'first' : ''" v-for="(item, index) of notice" :key="index">
            <img class="list-cover" :src="item.poster_url" />
            <div class="list-info">
              <div class="list-info__title">{{ item.title }}</div>
              <div class="list-info__time">开播时间：{{ item.open_time }}</div>
              <el-button class="list-info__btn">预约直播</el-button>
            </div>
          </div>
          <div class="list-more">
            查看更多直播预告
          </div>
        </template>
        <template v-else>
          <div class="invite">
            <div class="invite-item" style="color: #666666; font-size: 13px;">
              <div class="invite-info">
                <span class="invite-name">用户</span>
              </div>
              <div class="invite-status">预约状态</div>
            </div>
            <div class="invite-item" v-for="(item, index) of inviterList" :key="index">
              <div class="invite-info">
                <img :src="item.avatar" alt="" class="invite-avatar">
                <span class="invite-name">{{ item.nickname }}</span>
              </div>
              <div class="invite-status">
                <span :class="[item.status === 1 ? 'q-success' : 'q-info']">
                  {{ item.status === 1 ? '预约成功' : '未预约' }}
                </span>
              </div>
            </div>
            <div class="list-more" style="padding-bottom: 13px;">
              查看更多
            </div>
          </div>
        </template>
      </div>
    </div>
  </ly-mobile>
</template>

<script>
export default {
  name: 'mobiBasic',

  data () {
    return {
      tabName: 0,
      noticeList: [
        {
          title: '全棉时代官方直播精选',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          time: Date.now()
        },
        {
          title: '全棉时代官方直播精选',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          time: Date.now()
        },
        {
          title: '全棉时代官方直播精选',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          time: Date.now()
        }
      ],

      inviterList: [
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          nickname: '无与伦比',
          status: 1
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145807XZyd07u6.jpg',
          nickname: '阿轩',
          status: 0
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          nickname: '康康康王',
          status: 1
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          nickname: '马克大菠萝',
          status: 1
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          nickname: '无与伦比',
          status: 1
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145807XZyd07u6.jpg',
          nickname: '阿轩',
          status: 0
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          nickname: '康康康王',
          status: 1
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          nickname: '马克大菠萝',
          status: 1
        }
      ]
    }
  },

  computed: {
    bg_url () {
      return this.$store.getters['liveact/GET_INFO']('bg_url')
    },
    notice () {
      return this.$store.getters['liveact/GET_INFO']('notice')
    },
    btn_title () {
      return this.$store.getters['liveact/GET_INFO']('btn_title')
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-mobile {

  ::v-deep {
    .mobimodel-view {
      background-color: #fff;
    }
  }
}

.act {
  background-color: #f9f9f9;
}

.banner {

  &-img {
    width: 100%;

    ::v-deep &__err {
      background-color: #F0F1F8;
      height: 360px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6E788A;
    }
  }
}

.content {
  position: relative;
  padding-top: 66px;

  .box {
    position: absolute;
    left: 0;
    right: 0;
    top: -52px;
    margin: 0 12px;
    background-color: #ccc;
    background: linear-gradient(131deg, #FFFCF3 0%, #FFF3FA 100%);
    box-shadow: 0px 7px 13px 1px rgba(247, 127, 124, 0.04);
    border-radius: 8px;
    padding: 15px 0 17px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &-time {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 4.5px;
      padding: 0 12px;
      margin-bottom: 12px;
    }

    &-title {
      font-weight: 500;
    }

    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }

    &-count {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1;

      .number {
        height: 18px;
        min-width: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(#ED5352, 0.36);
        border-radius: 3px;
        font-size: 12px;
        font-weight: 500;
        margin: 0 2px;
      }
    }

    &-btn {
      height: 40px;
      width: calc(100% - 60px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(101deg, #FFAE61 0%, #F74551 100%);
      box-shadow: 0px 2px 8px 0px rgba(240, 81, 76, 0.56);
      border-radius: 25px;
      border: none;
      color: #fff;
      font-size: 20px;
      box-sizing: border-box;
    }
  }
}

.list {
  padding: 16px 12px 6px;

  &-tab {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F9F9F9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &__item {
      flex: 1;
      position: relative;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 500;
      color: #666666;

      &.active {
        color: #F24446;

        &:after {
          position: absolute;
          left: 50%;
          bottom: 0;
          content: '';
          height: 2px;
          width: 30px;
          border-radius: 1px;
          background-color: #F24446;
          transform: translateX(-50%);
        }
      }
    }
  }

  &-item {
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 8px 6px;
    display: flex;
    background-color: #fff;

    &.first {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-cover {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 8px;
  }

  &-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-size: 15px;
      font-weight: 500;
      color: #333;
      line-height: 21px;
    }

    &__time {
      font-size: 12px;
      color: #F24446;
      line-height: 18px;
      margin-top: 4px;
    }

    &__btn {
      font-size: 14px;
      width: 134px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #D8D8D8 linear-gradient(101deg, #FFAE61 0%, #F64952 100%);
      box-shadow: 0px 2px 4px 0px rgba(249, 90, 85, 0.27);
      border-radius: 15px;
      border: none;
      margin: 6px 0 0;
    }
  }

  &-more {
    color: #999;
    font-size: 12px;
    text-align: center;
    margin: 10px 0;
  }
}

.invite {
  background-color: #fff;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  margin-bottom: 8px;

  &-item {
    display: flex;
    align-items: center;
    padding: 9px 12px;
  }

  &-info {
    width: 68%;
    display: flex;
    align-items: center;
  }

  &-avatar {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &-status {
    flex: 1;
    text-align: center;
    font-size: 12px;
  }
}
</style>
