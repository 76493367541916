var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "q-dialog",
      attrs: {
        visible: _vm.visible,
        width: "600px",
        title: _vm.title,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            size: "medium",
            "label-width": "110px",
            rules: _vm.rules,
            model: _vm.form
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "直播间名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入直播间名称",
                  maxlength: "60",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开播时间：", prop: "open_time" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "请选择开播时间",
                  "picker-options": _vm.endAtOption,
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.form.open_time,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "open_time", $$v)
                  },
                  expression: "form.open_time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预告首图：", prop: "poster_url" } },
            [
              _c("div", { staticClass: "q-info text-small" }, [
                _vm._v(
                  " 建议尺寸:260*260像素，大小不超过2MB，格式为JPG/GIF/PNG/JPEG "
                )
              ]),
              _c("ly-upload", {
                attrs: {
                  img: _vm.form.poster_url,
                  tipText: "上传海报",
                  accept: ".png,.jpg,.jpeg,.gif"
                },
                on: { getImg: _vm.fn_getPoster }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预告二维码：", prop: "qrcode_url" } },
            [
              _c("div", { staticClass: "q-info text-small" }, [
                _vm._v(" 视频号预告二维码后进行上传 ")
              ]),
              _c("ly-upload", {
                attrs: {
                  img: _vm.form.qrcode_url,
                  tipText: "上传二维码",
                  cropper: ""
                },
                on: { getImg: _vm.fn_getCode }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.fn_subForm }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }