var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-chart", {
    ref: "chartRef",
    attrs: { height: _vm.height, option: _vm.pieOption }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }