<!--
 * @Author: zhanln
 * @Date: 2021-09-22 10:26:46
 * @LastEditTime: 2021-12-08 20:06:54
 * @LastEditors: Please set LastEditors
 * @Description: 选择标签显示
-->

<template>
  <div class="wecom-tag">
    <div class="wecom-tag__selected">
      <div class="wecom-tag__title" v-if="title">{{ title }}</div>

      <div>
        <slot name="prefix"></slot>
        <el-button round plain type="primary" icon="iconfont icon-plus" @click="fn_tagSelect">选择标签</el-button>
        <slot name="suffix"></slot>
      </div>

      <div class="wecom-tag__selected-list">
        <span class="wecom-tag__selected-item" v-for="(tag, index) in checkList" :key="index">
          {{ tag.tagName }}<i class="iconfont icon-close-fill" @click="fn_removeTag(index)"></i>
        </span>
      </div>
    </div>

    <tag-select-v2
      ref="tagSelectRef"
      :checked="checked"
      @getSelect="fn_getSelect"
    ></tag-select-v2>
  </div>
</template>

<script>
import tagSelectV2 from '@components/wecomTags/select'
import { tagApi } from '@components/wecomTags/http'
export default {
  name: 'wecomTags',

  props: {
    title: {
      type: String,
      default: ''
    },
    checked: {
      type: String,
      default: () => {
        return ''
      }
    }
  },

  watch: {
    checked (val) {
      if (val) {
        if (this.tagReady || this.tagList.length > 0) {
          this.fn_getChecked()
        } else {
          this.fn_getListSync()
        }
      } else {
        this.checkList = []
      }
    }
  },

  components: {
    tagSelectV2
  },

  data () {
    return {
      COMM_HTTP: tagApi,
      checkList: [],
      tagList: [],
      tagReady: false
    }
  },

  mounted () {
    if (this.checked) {
      if (this.tagReady || this.tagList.length > 0) {
        this.fn_getChecked()
      } else {
        this.fn_getListSync()
      }
    }
  },

  methods: {
    // 获取标签列表
    async fn_getListSync () {
      this.tagReady = true
      this.pageLoading = true
      const data = await this.COMM_HTTP.reqQuery()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.tagList = data

      if (this.checked) {
        this.fn_getChecked()
      }
    },

    // 显示选中，根据 tagid
    fn_getChecked () {
      if (!this.checked) {
        this.checkList = []
      } else {
        const checked = this.checked.split(',').map(item => +item)
        const checkedTags = []
        this.tagList.forEach(item => {
          const list = item.tagList.filter(item => checked.indexOf(item.tagId) > -1)
          if (list.length > 0) {
            checkedTags.push(list)
          }
        })
        this.checkList = checkedTags.flat()
      }
    },

    // 移除，从显示框
    fn_removeTag (index) {
      this.checkList.splice(index, 1)
      this.fn_getWecomTags()
    },

    // 返回当前选中
    fn_getWecomTags () {
      if (this.$listeners.getTagIdArr) {
        this.$emit('getTagIdArr', this.checkList)
      }

      if (this.$listeners.getTagId) {
        const tags = this.checkList.map(item => item.tagId)
        this.$emit('getTagId', tags)
      }
    },

    // 打开标签选择器
    fn_tagSelect () {
      this.$refs.tagSelectRef.visible = true
    },

    // 弹框选中回调
    fn_getSelect (selected) {
      this.checkList = selected
      this.fn_getWecomTags()
    }
  }
}
</script>

<style lang="scss" scoped>
.wecom-tag {
  line-height: 1.6;
  &__selected {
    &-list {
      i {
        color: #c0c6d4;
        cursor: pointer;
        font-size: 14px;
        margin-left: 5px;
        &:hover{
          color: #aab2c5;
        }
      }
    }

    &-item {
      height: 32px;
      padding: 0 10px;
      display: inline-flex;
      line-height: 32px;
      background-color: #F7F8FC;
      border-radius: 32px;
      color: #212b36;
      margin: 8px 8px 0 0 !important;
      font-size: 14px;
      box-sizing: border-box;
      transition: 0.15s linear;

      &.is-add {
        background-color: #fff;

        i {
          margin-right: 4px;
        }
      }

      &.has-hover {
        &:hover,
        &.active {
          cursor: pointer;
          background-color: #f2f5fe;
          border-color: #7badef;
          color: #2B7CFF;
        }
      }
    }
  }

  &__title {
    margin-bottom: 8px;
    line-height: 22px;
  }
  ::v-deep{
    .el-tag.el-tag--info{
      border: unset;
    }
    .el-tag .el-icon-close::before{
      content: '\e6ba';
      font-family: 'iconfont';
      font-size: 18px;
    }
    .el-button{
      padding: 9px 15px;
      .icon-plus{
        font-size: 16px;
      }
    }
  }
}
</style>
