var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "cusFormRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.cusForm,
        rules: _vm.cusFormRules,
        "label-width": "125px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "用户标签" } },
        [
          _c(
            "div",
            { staticClass: "flex cus-flex flex-start" },
            [
              _c("el-switch", {
                attrs: { "active-value": true, "inactive-value": false },
                on: {
                  change: function($event) {
                    return _vm.fn_setForm($event, "tag_open")
                  }
                },
                model: {
                  value: _vm.cusForm.tag_open,
                  callback: function($$v) {
                    _vm.$set(_vm.cusForm, "tag_open", $$v)
                  },
                  expression: "cusForm.tag_open"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "q-info",
                  staticStyle: { "line-height": "20px", "margin-left": "10px" }
                },
                [
                  _vm._v(
                    "开启后将为本渠道新增用户自动打标签；因官方限制，标签存在延时，预计会在添加用户后的2分钟之内完成"
                  )
                ]
              )
            ],
            1
          ),
          _vm.cusForm.tag_open
            ? _c(
                "el-form-item",
                { attrs: { prop: "tags" } },
                [
                  _c("tag-select-v2", {
                    attrs: { checked: _vm.tags },
                    on: { getTagIdArr: _vm.fn_getTagId }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "昵称备注" } },
        [
          _c(
            "div",
            { staticClass: "flex cus-flex flex-start" },
            [
              _c("el-switch", {
                attrs: { "active-value": true, "inactive-value": false },
                on: {
                  change: function($event) {
                    return _vm.fn_setForm($event, "name_suffix_open")
                  }
                },
                model: {
                  value: _vm.cusForm.name_suffix_open,
                  callback: function($$v) {
                    _vm.$set(_vm.cusForm, "name_suffix_open", $$v)
                  },
                  expression: "cusForm.name_suffix_open"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "q-info",
                  staticStyle: { "line-height": "20px", "margin-left": "10px" }
                },
                [
                  _vm._v(
                    "开启后将为本渠道新增用户昵称增加后缀备注；因官方限制，微信昵称和昵称备注之和若超过20个字符，仅显示前20个字符"
                  )
                ]
              )
            ],
            1
          ),
          _vm.cusForm.name_suffix_open
            ? [
                _c(
                  "el-form-item",
                  { attrs: { prop: "name_suffix" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "360px" },
                        attrs: {
                          placeholder: "请输入昵称备注",
                          maxlength: "10",
                          "show-word-limit": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.fn_setForm($event, "name_suffix")
                          }
                        },
                        model: {
                          value: _vm.cusForm.name_suffix,
                          callback: function($$v) {
                            _vm.$set(_vm.cusForm, "name_suffix", $$v)
                          },
                          expression: "cusForm.name_suffix"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _vm._v("微信昵称-")
                        ])
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex cus-flex",
                    staticStyle: { "margin-top": "24px" }
                  },
                  [
                    _vm._v(" 预览："),
                    _c("span", { staticClass: "cus-view" }, [
                      _c("i", { staticClass: "el-icon-s-custom ly-tag_icon" }),
                      _c("span", { staticClass: "suffix" }, [
                        _vm._v(_vm._s(_vm.nameSuffix))
                      ])
                    ])
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }