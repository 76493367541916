<template>
  <div>
    <!-- 下拉框类型 -->
    <el-select v-model="selectValue" v-if="type === 'select'" popper-class="hide-select" class="emp-select"
      :popper-append-to-body="false" :placeholder="checkList.length > 0 ? '' : placeholder" :disabled="selectDisabled"
      @focus="fn_showDialog">
      <template slot="prefix" v-if="checkList.length > 0">
        <span class="emp-select--item" v-for="(item, index) in checkList.slice(0, showLength)" :key="item.id">
          <!-- <img :src="item.avatar_url || defaultAvatar" alt="" class="avatar" /> -->
          <span class="name">{{ item.name }}</span>
          <span class="emp-select--gap" v-if="index !== checkList.length - 1">,</span>
        </span>
        <span class="emp-select--more" v-if="checkList.length - showLength > 0">+{{ checkList.length - showLength
        }}</span>
      </template>
    </el-select>

    <!-- 按钮类型，可设置样式，文字 -->
    <el-button @click="fn_showDialog" :type="btnType" v-else>{{
        btnText
    }}</el-button>

    <!-- 选择器弹框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" custom-class="q-dialog" width="800px"
      :close-on-click-modal="false" :modal="modal" :show-close="false">
      <div slot="title" style="width: 100%">
        <div class="flex flex-between">
          <div class="el-dialog__title">{{ title }}</div>
          <div class="el-dialog__header-close" @click="fn_cancle">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <!-- 自定义 body -->
      <div class="emp">
        <!-- 左边选择器，tree -->
        <div class="emp-left" v-loading="loading" element-loading-text="数据加载中">
          <!-- 搜索过滤 -->
          <el-input size="medium" :placeholder="inputPlaceholder" v-model="filterText" clearable></el-input>
          <!-- 全部成员 -->
          <div class="emp-all">
            <span>{{ empAll }}（{{ empTotal }}）</span>
            <span v-if="chooseType === 'mkdir'">
              <el-checkbox v-model="checkedAll" @change="fn_changeAll" :indeterminate="indeterminate">全选</el-checkbox>
            </span>
          </div>

          <!-- 成员 -->
          <!-- lazy
              :load="fn_loadNode" -->
          <div class="emp-tree">
            <el-tree :data="treeData" :props="defaultProps" :default-expanded-keys="defaultOpenId"
              :filter-node-method="fn_filterNode" :class="['tree', isFilter ? 'is-filter' : '', chooseType]"
              node-key="id" show-checkbox accordion ref="empTree" @node-click="fn_nodeClick" @check="fn_getCheckList"
              :expand-on-click-node="chooseType !== 'mkdir'">
              <div slot-scope="{ node, data }" class="tree-content">
                <!-- 文件夹级别 -->
                <template v-if="data.mkdir">
                  <i class="el-icon-folder tree-icon--foolder" v-if="!node.expanded"></i>
                  <i class="el-icon-folder-opened tree-icon--foolder" v-else></i>
                  <div class="tree-content--label">
                    {{ data.name }}
                    <template v-if="chooseType === 'file'">({{ data.count }})</template>
                  </div>
                </template>

                <!-- 文件级别 -->
                <template v-else>
                  <div class="flex tree-user">
                    <!-- <div class="tree-user--avatar">
                      <img :src="data.avatar_url || defaultAvatar" alt="" />
                    </div> -->
                    <div class="flex flex-column flex-start tree-user--info">
                      <span>{{ data.name }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </el-tree>
          </div>
        </div>

        <!-- 右边结果 -->
        <div class="emp-right">
          <div class="emp-all emp-all--right">
            <div>
              已选（{{ checkList.length }}<span v-if="max">/{{ max }}</span>）
              <span v-if="readyText">, {{ readyText }}</span>
            </div>
          </div>
          <div class="emp-check">
            <div class="flex flex-between emp-check--item" v-for="item in checkList" :key="item.id">
              <template v-if="chooseType == 'mkdir'">
                <i class="el-icon-folder tree-icon--foolder"></i>
                <div class="tree-content--label">
                  {{ item.name }}
                </div>
              </template>

              <div class="flex tree-user" v-else>
                <div class="tree-user--avatar flex">
                  <!-- <img
                    :src="item.avatar_url || defaultAvatar"
                    alt=""
                    class="mr-16"
                  /> -->
                  {{ item.name }}
                </div>
              </div>
              <div class="emp-check--close" @click="fn_removeCheck(item.id)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <div class="flex flex-between">
          <el-button round @click="fn_clearCheck">清空所选</el-button>
          <div>
            <el-button round @click="fn_cancle">取消</el-button>
            <el-button round type="primary" @click="fn_getEmpId">确定</el-button>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'EmpSelect',

  props: {

    type: {
      type: String,
      default: 'select'
    },
    btnText: {
      type: String,
      default: '选择数据'
    },
    btnType: {
      type: String,
      default: 'text'
    },
    title: {
      type: String,
      default: '选择数据'
    },
    empAll: {
      type: String,
      default: ''
    },
    modal: {
      type: Boolean,
      default: true
    },
    datafilter: {
      type: Boolean,
      default: false
    },
    showLength: {
      type: Number,
      default: 2
    },
    checkProp: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    inputPlaceholder: {
      type: String,
      default: '请输入分组名称'
    },
    max: {
      type: [Number, null],
      default: null
    },
    disableRoleId: {
      type: Array,
      default: () => {
        return []
      }
    },
    treeData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 选择后展示的类型
    // 默认是文件夹形式
    chooseType: {
      type: String,
      default: 'mkdir'
    },
    closeClear: {
      type: Boolean,
      defautl: false
    },
    readyText: {
      type: String,
      default: ''
    },
    empTotal: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      loading: false,
      selectDisabled: false,
      selectValue: '',
      empDatas: null,
      depDatas: null,
      dialogVisible: false,
      filterText: '',
      isFilter: false,
      // treeData: null,
      defaultProps: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'liveCodeInfoVOList'
      },
      defaultOpenId: [],
      checkList: [],
      defaultAvatar: require('@assets/images/default_avatar.png'),
      checkedAll: false,
      indeterminate: true
    }
  },

  watch: {
    filterText (val) {
      // this.fn_filterNode()
      this.$refs.empTree.filter(val)
    },
    chooseType (val) {
      if (val) {
        this.copyCheckList = []
        if (this.$refs.empTree) {
          const nodes = this.$refs.empTree.store.nodesMap
          for (var i in nodes) {
            nodes[i].expanded = false
          }
          this.fn_clearCheck()
        }
      }
    }
    // checkProp: {

    //   handler: async function (val) {
    //     this.copyCheckList = this.copyCheckList || []
    //     if (val) {
    //       this.copyCheckList = this.util.extend(true, [], val)
    //     }
    //     this.selectDisabled = false
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  mounted () {
    this.copyCheckList = []
  },

  methods: {

    // 全选
    fn_changeAll (e) {
      const keys = this.treeData.map(item => item.id)
      for (let i = 0; i < keys.length; i++) {
        this.$refs.empTree.setChecked(keys[i], e)
      }

      this.checkList = e ? this.treeData : []
      this.indeterminate = false
    },

    fn_loadNode (node, resolve) {
      if (node.level == 0) {
        resolve(this.treeData)
      } else if (node.level > 0 && node.data.leaf) {
        resolve([])
      } else {
        if (node.data.liveCodeInfoVOList) {
          const _round = node.data.liveCodeInfoVOList.map((item, key) => { return { name: item.liveCodeName, leaf: true, id: item.id } })
          resolve(_round)
        } else {
          this.$emit('loadNode', node.data, resolve)
        }
      }
    },

    // 打开弹框
    fn_showDialog () {
      this.dialogVisible = true
      this.fn_initData()
    },

    fn_initData () {
      this.$nextTick(() => {
        if (this.treeData.length === this.copyCheckList.length) {
          this.checkedAll = true
        } else {
          this.checkedAll = false
          this.indeterminate = true
        }
        // this.defaultOpenId = [...this.copyCheckList.map(item => item.id)]
        this.$refs.empTree.setCheckedKeys([...this.copyCheckList.map(item => item.id)])
      })
    },

    // 输入框搜索
    fn_filterNode (value, data) {
      if (!value) {
        this.isFilter = false
        return true
      } else {
        this.isFilter = true
      }
      if (this.chooseType == 'mkdir') {
        return !!data.mkdir && data.name.indexOf(value) !== -1
      } else if (this.chooseType == 'file' && !data.mkdir) {
        return data.name.indexOf(value) !== -1
      }
    },
    // 点击节点
    fn_nodeClick (data, node) {
      if (data.disabled) return
    },

    // 获取选中节点
    fn_getCheckList () {

      const _arr = []

      const [node, tree] = arguments
      // let _mx = this.$refs.empTree.getNode(node.id); // 通过节点id找到对应树节点对象
      // if (_mx && !_mx.loaded) {
      //   _mx.loaded = false;
      //   _mx.expand()
      // }

      const _list = this.$refs.empTree.getCheckedNodes()

      if (arguments && this.chooseType == 'mkdir') {
        _arr.push(...tree.checkedNodes.filter(item => !!item.mkdir))
      } else {
        _arr.push(..._list.filter(item => !item.mkdir))
      }

      if (this.max && _arr.length > this.max) {
        this.$message.error(`最多可选${this.max}条数据`)
        this.$refs.empTree.setChecked(node.id, false)
        return
      }

      this.checkList = _arr

      if (this.checkList.length > 0) {
        if (this.checkList.length === this.treeData.length) {
          this.checkedAll = true
          this.indeterminate = false
        } else {
          this.checkedAll = false
          this.indeterminate = true
        }
      } else {
        this.checkedAll = false
        this.indeterminate = false
      }
    },

    // 取消单选
    fn_removeCheck (id) {
      this.$refs.empTree.setChecked(id, false)
      const _checkList = this.checkList
      for (let i = 0; i < _checkList.length; i++) {
        if (_checkList[i].id === id) {
          _checkList.splice(i, 1)
        }
      }
    },

    // 清空所选
    fn_clearCheck () {
      this.$refs.empTree.setCheckedKeys([])
      this.checkList = []
    },

    //
    fn_initTree () {
      if (this.chooseType == 'mkdir') {
        this.checkList = [this.treeData[0]]
        this.copyCheckList = [this.treeData[0]]
      } else if (this.chooseType == 'file') {
        if (!this.treeData[0].liveCodeInfoVOList || !this.treeData[0].liveCodeInfoVOList.length) {
          this.checkList = []
          this.copyCheckList = []
        } else {
          const _file = this.treeData[0].liveCodeInfoVOList[0]
          this.checkList = [_file]
          this.copyCheckList = [_file]
        }
      }

      this.fn_getEmpId()
    },

    // 返回选中节点
    fn_getEmpId () {
      const _list = this.checkList
      if (_list.length) {
        // 超出最大值
        // if (this.max && this._isChooseArr.length > this.max) {
        //   this.$message.error(`最多可选${this.max}条数据`)
        //   return
        // }

        // const _id = _list.map(item => {
        //   return item.id
        // })

        this.copyCheckList = _list
        this.$emit('getEmpId', _list)
      } else if (!_list.length) {
        if (!this.dialogVisible) {
          this.$emit('getEmpId', null)
          return
        }
        if (this.chooseType == 'mkdir') { this.$message.info('请至少选择一个分组') } else if (this.chooseType == 'file') { this.$message.info('请至少选择一个活码') }
        return
      }

      if (this.closeClear) {
        this.checkList = []
        this.$refs.empTree.setCheckedKeys([])
      }
      this.dialogVisible = false
      this.filterText = ''
    },

    // 取消
    fn_cancle () {
      this.checkList = this.copyCheckList.slice()
      this.dialogVisible = false
      this.filterText = ''
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
@import "@/assets/scss/utils.scss";

::v-deep .hide-select {
  display: none;
}

.emp {
  height: 398px;
  display: flex;

  &-left,
  &-right {
    flex: 1;
    box-sizing: border-box;
  }

  &-left {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    padding-right: 24px;
    overflow: hidden;
  }

  &-right {
    padding-left: 24px;
    overflow: hidden;
    margin-right: -24px;
  }

  &-all {
    margin: 16px 0 8px;
    color: $--color-text-primary;
    display: flex;
    justify-content: space-between;

    &--right {
      margin: 0;
      line-height: 36px;
    }
  }

  &-check {
    height: calc(100% - 36px);
    overflow: auto;
    padding-right: 16px;

    &--item {
      padding: 8px;

      &:hover {
        background-color: #f5f7fa;
      }
    }

    &--close {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        color: #ed5c49;
      }
    }
  }

  &-tree {
    overflow: auto;
    height: calc(100% - 106px);
    margin-right: -24px;
    padding-right: 16px;
  }
}

::v-deep .tree {
  .el-tree-node__content {
    position: relative;
    height: auto;
    min-height: 34px;
    padding: 8px 0;

    &>label.el-checkbox {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.mkdir .el-tree-node__children .is-leaf~.el-checkbox {
    display: none;
  }

  &.is-filter.file {
    .el-tree-node__content {
      .el-checkbox {
        display: none;
      }

      .is-leaf+.el-checkbox {
        display: inline-block;
      }
    }
  }

  &.mkdir .el-tree-node__expand-icon {
    display: none;
  }
}

.tree {
  &-content {
    display: flex;
    overflow: hidden;
    padding-right: 8px;
    align-items: center;

    &--label {
      display: inline-block;
      @include ellipsis;
      flex: 1;
      margin-left: 4px;
    }
  }

  &-user {
    &--avatar {
      line-height: 0;
      margin-right: 12px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 2px;
      }
    }

    &--info {
      .qy-tag {
        margin-top: 4px;
        margin-right: 0;
        margin-bottom: 4px;
      }
    }
  }
}

.emp-select {
  width: 100%;

  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #454D5B;
  }

  ::v-deep .el-input__prefix {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    overflow: hidden;
    padding-right: 32px;
    cursor: pointer;
  }

  &--item {
    display: inline-flex;
    align-items: center;
    color: #454D5B;

    .avatar {
      width: 22px;
      height: 22px;
      border-radius: 2px;
      margin-right: 4px;
    }

    .name {
      max-width: 56px;
      @include ellipsis;
    }
  }

  &--gap {
    margin-right: 5px;
  }
}

::v-deep.real-dialog {
  &__title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }

  &__icon {
    font-size: 32px;
    margin-right: 16px;
  }

  &__content {
    padding-left: 48px;
  }

  .el-dialog__header {
    display: none;
  }
}
</style>
