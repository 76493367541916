<!--
 * @Author: zhanln
 * @Date: 2022-03-11 11:51:32
 * @LastEditTime: 2022-05-11 16:05:56
 * @LastEditors: zhanln
 * @Description: 群聊列表
-->

<template>
  <div>
    <ly-table
      class="act-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 列插槽 -->
      <template #group_name="{ row }">
        <div class="flex">
          <div class="ly-table-tearm">
            <i class="iconfont icon-weixin"></i>
          </div>
          <span style="flex: 1">
            {{ row.group_name || '群聊' }}
          </span>
        </div>
      </template>

      <template #master="{ row }">
        <ly-tag
          :content="row.master_name"
          :size="6"
          :avatar="row.master_avatar"
        ></ly-tag>
      </template>

      <template #user_name_slot="{ row }">
        <ly-tag :content="row.user_name" :size="8"></ly-tag>
      </template>

      <template #customer_name="{ row }">
        <ly-tag
          :content="row.customer_name"
          :size="6"
          icon="iconfont icon-a-customerservice q-primary"
        ></ly-tag>
      </template>

      <template #operation="{ row }">
        <el-button type="text" @click="fn_imList(row)">聊天记录</el-button>
      </template>
    </ly-table>
    <im ref="im" :selfInfo="selfInfo" :chat_id="chat_id"> </im>
  </div>
</template>

<script>
import Im from './components/im'
import { recordGroup } from './http.js'
import lyTable from '@/components/mixins/ly-table.js'
import lyTag from '@/components/lyTag'
export default {
  name: 'groupRecord',
  mixins: [lyTable],
  components: {
    lyTag,
    Im
  },
  props: ['cusList'],

  data () {
    return {
      COMM_HTTP: recordGroup,
      chat_id: '',
      selfInfo: {}
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 100,
        column: [
          {
            label: '群聊名称',
            prop: 'group_name',
            slot: 'group_name',
            search: true,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '群主',
            prop: 'master_name',
            slot: 'master',
            search: true,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '托管账号',
            prop: 'user_name',
            slot: 'user_name_slot',
            key: 'user_id',
            search: true,
            type: 'robot',
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '所属客服',
            prop: 'account_id',
            slot: 'customer_name',
            search: true,
            default: null,
            type: 'select',
            source: this.cusList,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '最后聊天时间',
            prop: 'last_chat_date',
            type: 'daterange',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            },
            columnProps: {
              'min-width': 150
            }
          },
          {
            label: '消息数',
            prop: 'chat_num',
            columnProps: {
              'min-width': 150
            },
            formatter: (row, column, cellValue, index) => {
              return row.chat_num > 999999
                ? '999999+'
                : row.chat_num
            }
          }
        ]
      }
    }
  },
  methods: {
    fn_imList (row) {
      this.chat_id = row.chat_id
      this.selfInfo = {
        id: row.user_id,
        name: row.user_name,
        avatar: row.user_avatar,
        identity: 'me',
        created_time: row.created_time,
        last_chat_date: row.last_chat_date
      }
      this.$refs.im.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-warp,
.act-list {
  height: 100%;
}
.create-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.act-list {
  ::v-deep .el-table.el-table--medium td {
    vertical-align: middle;
  }
}
</style>
