var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "page-tabel",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "icon-plus iconfont"
                    },
                    on: { click: _vm.fn_edit }
                  },
                  [_vm._v("添加文本")]
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "tooltip",
                    attrs: {
                      content: "请先选择要分组的素材",
                      effect: "dark",
                      placement: "top",
                      disabled: _vm.selected.length > 0
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              plain: "",
                              icon: "iconfont icon-edit",
                              size: "medium",
                              disabled: _vm.selected.length === 0
                            },
                            on: { click: _vm.fn_changeGroupBatch }
                          },
                          [_vm._v("批量分组")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "tooltip",
                    attrs: {
                      content: "请先选择要删除的素材",
                      effect: "dark",
                      placement: "top",
                      disabled: _vm.selected.length > 0
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              plain: "",
                              icon: "iconfont icon-delete",
                              size: "medium",
                              disabled: _vm.selected.length === 0
                            },
                            on: { click: _vm.fn_removeBatch }
                          },
                          [_vm._v("批量删除")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "name_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "ellipsis2" }, [
                  _vm._v(_vm._s(row.name))
                ])
              ]
            }
          },
          {
            key: "text_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "ellipsis2" }, [
                  _vm._v(_vm._s(row.text))
                ])
              ]
            }
          },
          {
            key: "create_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "flex" }, [
                  _c("img", {
                    staticClass: "ly-table__main-avatar",
                    attrs: { src: row.creator.avatar, alt: "" },
                    on: {
                      error: function($event) {
                        return _vm.imgError(row)
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "ly-table__main-name",
                      staticStyle: { flex: "1" }
                    },
                    [_vm._v(" " + _vm._s(row.creator.username) + " ")]
                  )
                ])
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ly-action",
                  { key: Date.now() },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          placement: "top",
                          content: "数据功能正在开发中",
                          "popper-class": "q-tooltip"
                        }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c(
                              "el-button",
                              { attrs: { type: "text", disabled: "" } },
                              [_vm._v("数据")]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.fn_edit(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.fn_remove(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("edit", {
        ref: "editRef",
        attrs: { currentGroupId: _vm.currentGroupId }
      }),
      _c("change-group", {
        ref: "changeGroupRef",
        attrs: { currentGroupId: _vm.currentGroupId },
        on: { clearSelected: _vm.fn_clearSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }