var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qr-analysis" }, [
    _c(
      "div",
      { staticClass: "qr-analysis-wrap navTabs" },
      [
        _c("el-header", { staticClass: "flex pH", attrs: { height: "48px" } }, [
          _vm._v(" 活码名称：" + _vm._s(_vm.$route.query.name) + " ")
        ])
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "qr-analysis-panel grey-wrapper",
        attrs: { id: "dataPanel" }
      },
      [_c("analysis-panel", { attrs: { panelInfo: _vm.panelInfo } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "grey-wrapper card-wrapper",
        attrs: { id: "growthTrendPanel" }
      },
      [
        _c(
          "el-card",
          [
            _c("div", { staticClass: "main-title" }, [_vm._v("增长趋势")]),
            _c(
              "div",
              { staticClass: "time-wrapper" },
              [
                _c("timeSelect", {
                  attrs: { startTime: _vm.startTime },
                  on: { emitInfo: _vm.timeSelectEmit }
                })
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "flex flex-between" },
              [
                _c(
                  "div",
                  { staticClass: "flex flex_item" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.changeRadio },
                        model: {
                          value: _vm.radioButtonCharts,
                          callback: function($$v) {
                            _vm.radioButtonCharts = $$v
                          },
                          expression: "radioButtonCharts"
                        }
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "用户数" } }),
                        _c("el-radio-button", {
                          attrs: { label: "新增用户数" }
                        }),
                        _c("el-radio-button", {
                          attrs: { label: "新增留存率" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex remarksInfo ml-18" },
                      [
                        _vm._v(" 统计说明 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "top",
                              "popper-class": "q-tooltip"
                            }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(" 用户数：当天留存的用户数"),
                                _c("br"),
                                _vm._v(
                                  " 新增用户数：扫码添加（包含当天已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算；合计：每日新增用户数之和"
                                ),
                                _c("br"),
                                _vm._v(
                                  " 新增留存率：新增且当天未删除的用户数/当天新增用户数；合计：新增且该段时间内未删除的用户数/该段时间内新增用户数 "
                                )
                              ]
                            ),
                            _c("i", {
                              staticClass: "iconfont icon-info-fill",
                              staticStyle: { color: "#b4bbcc" }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      round: "",
                      icon: "iconfont icon-download"
                    },
                    on: { click: _vm.exportExcel }
                  },
                  [_vm._v("导出图表")]
                )
              ],
              1
            ),
            _c("lyChart", {
              attrs: { option: _vm.chartOption, height: "450px" }
            }),
            _c(
              "el-table",
              {
                ref: "tableRef",
                staticClass: "mt-40",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, border: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", prop: "time", label: "时间" }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "aliveUserCount",
                    label: "总用户数"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(" 总用户数 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "top",
                                "popper-class": "q-tooltip"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [_vm._v("当天留存的用户数")]
                              ),
                              _c("i", {
                                staticClass: "iconfont icon-info-fill"
                              })
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "addUserCount",
                    label: "新增用户数"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(" 新增用户数 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "top",
                                "popper-class": "q-tooltip"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    " 扫码添加（包含当天已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算；合计：每日新增用户之和 "
                                  )
                                ]
                              ),
                              _c("i", {
                                staticClass: "iconfont icon-info-fill"
                              })
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "addRetentionRate",
                    label: "新增留存率"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(" 新增留存率 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "top",
                                "popper-class": "q-tooltip"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    " 新增且当天未删除的用户数/新增用户数；合计：该段时间内新增且未删除的用户数/该段时间内新增用户数 "
                                  )
                                ]
                              ),
                              _c("i", {
                                staticClass: "iconfont icon-info-fill"
                              })
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c(
                  "div",
                  {
                    staticClass: "ly-table-empty",
                    attrs: { slot: "empty" },
                    slot: "empty"
                  },
                  [
                    _vm._t("empty", [
                      _c("img", {
                        attrs: {
                          src: require("@assets/svg/default/no_data.svg"),
                          alt: ""
                        }
                      }),
                      _vm._v(" 暂无数据 ")
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }