<template>
  <div class="ly-table page-warp">
    <div class="balance-area">
      <div class="available ly-shadow">
        <div class="useable">
          <img src="@assets/images/redenvelopes.png" alt="" srcset="" />
          <div class="temp">
            <div class="amount">{{ available }}</div>
            <div class="desc">
              账户可用余额
              <!-- <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                popper-class="q-tooltip"
              >
                <div slot="content">账户中可用余额</div>
                <i class="iconfont icon-info-fill" style="font-size: 14px"></i>
              </el-tooltip> -->
            </div>
          </div>
        </div>
        <div class="button">
          <el-button
            round
            type="warning"
            @click="$refs.recharge.rechargeVisible = true"
            >立即充值</el-button
          >
          <!-- <el-button type="warning" plain class="cashoutBtn">立即提现</el-button> -->
        </div>
      </div>
      <div class="frozen ly-shadow">
        <img src="@assets/images/redenvelopes.png" alt="" srcset="" />
        <div class="temp">
          <div class="amount">{{ Number(freeze).toFixed(2) }}</div>
          <div class="desc">
            冻结金额
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              popper-class="q-tooltip"
            >
              <div slot="content">创建营销活动后，将会从「账户可用余额」中冻结对应的活动金额，活动结束后系统自动结算已发送的红包金额，在此处扣除</div>
              <i class="iconfont icon-info-fill" style="font-size: 14px"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- <div class="cashout">
        <div class="amount">
          <amount :val="500.50"/>
        </div>
        <div class="desc">
          提现中金额
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            popper-class="q-tooltip"
          >
            <div slot="content">已发起提现申请，但还没有提现成功的金额</div>
            <i class="iconfont icon-info-fill"></i>
          </el-tooltip>
        </div>
      </div> -->
      <!-- 充值 -->
      <ly-recharge ref="recharge" @success="getCurrentInfo" />
    </div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selection-change="handleSelectionChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <!-- 列插槽 -->
      <template #business_type_slot="{ row }">
        <div class="flex flex-start">
          <span v-if="row.business_type === 1">应用购买</span>
          <span v-else-if="row.business_type === 2">云豆充值</span>
          <span v-else-if="row.business_type === 4">账户充值</span>
          <span v-else-if="row.business_type === 5">应用赠送</span>
          <span v-else-if="row.business_type === 6">应用开通</span>
          <span v-else-if="row.business_type === 7">应用试用</span>
          <span v-else-if="row.business_type === 8">云豆开通</span>
          <span v-else-if="row.business_type === 9">云豆试用</span>
        </div>
      </template>
      <template #trade_state_text_slot="{ row }">
        <span class="q-success" v-if="row.trade_state_text === '已支付'"
          >已支付</span
        >
        <span v-else>{{ row.trade_state_text }}</span>
      </template>
      <template #wecom_user_id_slot="{ row }">
        <div class="flex flex-start">
          <el-image
            v-if="row.wecom_user_id.avatar"
            :src="row.wecom_user_id.avatar"
            lazy
            class="ly-table__main-avatar"
          ></el-image>
          <el-image
            v-else
            src="../../../assets/images/default_avatar.png"
            alt=""
            class="ly-table__main-avatar"
          ></el-image>
          <div>
            {{ row.wecom_user_id.name }}
          </div>
        </div>
      </template>
      <template #create_time_text_slot="{ row }">
        <div class="ly-table__main-time">
          {{ row.create_time_text }}
        </div>
      </template>
    </ly-table>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import LyRecharge from '@/components/LyRecharge.vue'
import { finance } from './http.js'
export default {
  mixins: [lyTable],
  components: {
    LyRecharge
  },
  data () {
    return {
      COMM_HTTP: finance,
      available: 0,
      freeze: 0
    }
  },
  computed: {
    tableOpt () {
      return {
        operation: 'hide',
        column: [
          {
            label: '订单编号',
            prop: 'order_no',
            fixed: true,
            width: 240,
            search: true,
            placeholder: '请输入订单编号'
          },
          {
            label: '业务类型',
            prop: 'business_type',
            slot: 'business_type_slot',
            width: 160,
            search: true,
            type: 'select',
            source: [{
              value: 1,
              label: '应用购买'
            }, {
              value: 2,
              label: '云豆充值'
            }, {
              value: 4,
              label: '账户充值'
            }, {
              value: 5,
              label: '应用赠送'
            }, {
              value: 6,
              label: '应用开通'
            }, {
              value: 7,
              label: '应用试用'
            }, {
              value: 8,
              label: '云豆开通'
            }, {
              value: 9,
              label: '云豆试用'
            }]
          },
          {
            label: '商品信息',
            prop: 'goods_name',
            width: 180
          },
          {
            label: '单价',
            prop: 'unit_price',
            columnProps: {
              minWidth: 100
            },
            formatter: (row, column, cellValue, index) => {
              return `￥ ${row.unit_price}`
            }
          },
          {
            label: '数量',
            prop: 'amount',
            width: 80,
            align: 'center'
          },
          {
            label: '优惠金额',
            prop: 'discount_price',
            columnProps: {
              minWidth: 100
            },
            formatter: (row, column, cellValue, index) => {
              return `￥ ${row.discount_price}`
            },
            align: 'center'
          },
          {
            label: '实付金额',
            prop: 'actually_paid_price',
            columnProps: {
              minWidth: 100
            },
            formatter: (row, column, cellValue, index) => {
              return `￥ ${row.actually_paid_price}`
            },
            align: 'center'
          },
          {
            label: '操作员工',
            prop: 'wecom_user_id',
            slot: 'wecom_user_id_slot',
            columnProps: {
              minWidth: 200
            }
          },
          {
            label: '支付状态',
            prop: 'trade_state_text',
            slot: 'trade_state_text_slot',
            columnProps: {
              minWidth: 100
            }
          },
          {
            label: '订单生成时间',
            prop: 'create_time_text',
            slot: 'create_time_text_slot',
            columnProps: {
              minWidth: 130
            },
            search: true,
            type: 'datetimerange',
            key: ['start_time', 'end_time'], // 自定义搜索条件的key
            formItemProps: {
              style: 'width: 360px',
              'default-time': ['00:00:00', '23:59:59']
            },
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0 ? '-' : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.getCurrentInfo()
  },
  methods: {
    getCurrentInfo () {
      this.COMM_HTTP.currentInfo().then(res => {
        this.available = Math.round((res.available_amount || 0) * 100 - (res.freeze_amount || 0) * 100) / 100
        this.freeze = res.freeze_amount || 0
        this.draft = res.draft_num || 0
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep{
  .ly-table-main{
    padding-top: 20px!important;
  }
}

.balance-area {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.available,
.frozen,
.cashout {
  width: 50%;
  text-align: center;
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  .amount {
    font-size: 24px;
    color: #212b36;
    font-weight: bold;
    line-height: 1;
  }
  .desc {
    font-size: 12px;
    color: #6E788A;
    margin-top: 5px;
  }
}
.available,
.frozen {
  display: flex;
  align-items: center;
  img {
    width: 21px;
    height: 24px;
    align-self: flex-start;
    margin-right: 10px;
  }
  .temp {
    text-align: left;
  }
  .useable {
    float: left;
    display: flex;
    align-items: center;
    flex: 1;
  }
}
.frozen {
  margin-left: 20px;
}
</style>
