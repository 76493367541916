var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile" }, [
    _c("div", { staticClass: "mobile-topbar" }, [
      _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
      _vm._m(0)
    ]),
    _vm._m(1),
    _c("div", { staticClass: "mobile-container" }, [
      _vm._m(2),
      _c("div", { staticClass: "mobile-contact" }, [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "mobile-contact__dialog" },
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                autosize: "",
                resize: "none",
                readonly: ""
              },
              model: {
                value: _vm.welWords,
                callback: function($$v) {
                  _vm.welWords = $$v
                },
                expression: "welWords"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mobile-contact" }, [
        _vm._m(4),
        _c("div", { staticClass: "mobile-contact__dialog" }, [
          _c("div", { staticClass: "link-preview" }, [
            _c(
              "div",
              { staticClass: "ellipsis2 link-preview__title" },
              [
                _vm.linkType === 2
                  ? [
                      _vm._v(
                        " " + _vm._s(_vm.linkTitle || "请输入链接标题") + " "
                      )
                    ]
                  : [_vm._v("与我一起领取奖品👇")]
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-between flex-center link-preview__content"
              },
              [
                _c(
                  "div",
                  { staticClass: "ellipsis3", staticStyle: { flex: "1" } },
                  [
                    _vm.linkType === 2
                      ? [_vm._v(" " + _vm._s(_vm.linkDesc) + " ")]
                      : [_vm._v("点击链接，领取福利")]
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "link-preview__cover" },
                  [
                    _vm.linkType === 2
                      ? [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.linkCover ||
                                "https://image.01lb.com.cn//uploads/wecom/21/1009/1633767898o4UJPl8q.png",
                              alt: ""
                            }
                          })
                        ]
                      : [
                          _c("img", {
                            attrs: {
                              src:
                                "https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png",
                              alt: ""
                            }
                          })
                        ]
                  ],
                  2
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "iconfont icon-a-CellularConnection" }),
      _c("i", { staticClass: "iconfont icon-Wifi" }),
      _c("i", { staticClass: "iconfont icon-Battery" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-titlebar" }, [
      _c("i", { staticClass: "iconfont icon-left1" }),
      _c("div", { staticClass: "flex" }, [
        _c("span", { staticStyle: { "margin-left": "12px" } }, [
          _vm._v("群裂变专属福利群(125) ")
        ]),
        _c("img", {
          staticStyle: { width: "22px", "margin-left": "6px" },
          attrs: { src: require("@assets/svg/wc_work_ic.svg"), alt: "" }
        })
      ]),
      _c("i", { staticClass: "el-icon-more" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-column q-info text-small",
        staticStyle: { "line-height": "2", "margin-top": "12px" }
      },
      [
        _c("span", [
          _c("span", { staticStyle: { color: "#2B7CFF" } }, [
            _vm._v("张三@零一裂变")
          ]),
          _vm._v("邀请你加入了群聊")
        ]),
        _c("span", [
          _vm._v("张三@零一裂变为企业微信用户，"),
          _c("span", { staticStyle: { color: "#2B7CFF" } }, [
            _vm._v("了解更多。")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-contact__avatar" }, [
      _c("img", {
        attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-contact__avatar" }, [
      _c("img", {
        attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }