<!--
 * @Author: zhanln
 * @Date: 2021-07-30 14:50:12
 * @LastEditTime: 2022-07-12 15:19:46
 * @LastEditors: zhan
 * @Description:
-->
<template>
  <ly-mobile :showWel="false" :hasFooter="false" sub="" title="活动详情" class="ly-mobile">
    <div class="lottery">

      <div class="lottery-header">
        幸运抽奖活动
      </div>

      <div class="lottery-rule" @click="fn_openPop">
        规则
      </div>

      <div class="lottery-body">
        <div class="lottery-body__countdown">
          <template v-if="countDown">
            开奖时间:
            <div class="lottery-body__countdown-count">
              <span class="number">{{ countDown.d }}</span><span class="text">天</span>
              <span class="number">{{ countDown.h }}</span><span class="text">时</span>
              <span class="number">{{ countDown.m }}</span><span class="text">分</span>
              <span class="number">{{ countDown.s }}</span><span class="text">秒</span>
            </div>
          </template>
          <template v-else>
            请设置开奖时间
          </template>
        </div>

        <div class="lottery-prize">
          <swiper :options="swiperOption" class="lottery-prize__swiper">
            <swiper-slide v-for="(item, index) of prizeList" :key="index" class="lottery-prize__swiper--item">
              <div class="lottery-prize__item">
                <div class="lottery-prize__cover">
                  <img :src="item.cover || defaultPrizeCover" class="lottery-prize__item--img" />
                </div>
                <div class="lottery-prize__name">{{ item.title || '奖品名称' }}</div>
              </div>
            </swiper-slide>
          </swiper>
          <img class="lottery-prize__mask left" src="@assets/images/mask_left.png" />
          <img class="lottery-prize__mask right" src="@assets/images/mask_right.png" />
        </div>

        <div class="lottery-btn">{{ btn_title }}
          <img src="@assets/images/btn_left.png" mode="heightFix" class="lottery-btn__arrow is-left is-move" />
          <img src="@assets/images/btn_right.png" mode="heightFix" class="lottery-btn__arrow is-right is-move" />
        </div>

        <div class="lottery-barrage">
          <img src="https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg"
            class="lottery-barrage__avatar" alt="">
          <span class="lottery-barrage__name">无与伦比</span>
          <span class="lottery-barrage__msg">正在参与活动</span>
        </div>
      </div>

      <div class="lottery-inviter">
        <div class="lottery-inviter__tab">
          <div class="lottery-inviter__tab--item" :class="{ active: tabIndex === 1 }" @click="tabIndex = 1">邀请记录</div>
          <div class="lottery-inviter__tab--item" :class="{ active: tabIndex === 2 }" @click="tabIndex = 2">奖品记录</div>
        </div>

        <div class="lottery-inviter__body" :class="[tabIndex === 1 ? 'is-left' : 'is-right']">
          <div class="invite" v-if="tabIndex === 1">
            <div class="invite-item is-head">
              <div class="invite-left">
                用户
              </div>
              <div class="invite-right">
                预约状态
              </div>
            </div>

            <div class="invite-list">
              <div class="invite-item" v-for="(item, index) of inviterList" :key="index">
                <div class="invite-left">
                  <img class="invite-avatar" :src="item.avatar" />
                  <span class="invite-name text-ellipsis">{{ item.nickname }}</span>
                </div>
                <div class="invite-right invite-status">
                  <span class="q-info" v-if="item.status === 1">未预约</span>
                  <span class="q-success" v-else-if="item.status === 2">预约成功</span>
                  <span class="q-warning" v-else>取消预约</span>
                </div>
              </div>
            </div>
          </div>

          <div class="prizes" v-else>
            <div class="prizes-item" v-for="(item, index) of prizes" :key="index">
              <div class="prizes-left">
                <img :src="item.cover" class="prizes-cover" alt="">
                <div class="prizes-info">
                  <div class="prizes-name">{{ item.title }}</div>
                  <div class="prizes-time">{{ $day(new Date()).format('YYYY-MM-DD HH:mm') }}</div>
                </div>
              </div>
              <div class="prizes-btn" v-if="item.status === 1">查看</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pop-rule ref="popRuleRef"></pop-rule>

    <pop-prize ref="popPrizeRef"></pop-prize>
  </ly-mobile>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import '@/assets/style/swiper.min.css'
import popRule from './popRule'
import popPrize from './popPrize'
export default {
  name: 'mobiBasic',

  components: {
    swiper,
    swiperSlide,
    popRule,
    popPrize
  },

  data () {
    return {
      swiperOption: {
        slidesPerView: 2,
        initialSlide: 1,
        centeredSlides: true,
        autoplay: true,
        delay: 1500
      },
      tabIndex: 1,
      prizes: [
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        },
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        },
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        },
        {
          title: '谢谢参与',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0708/1657265213BieaDd98.png',
          status: 2
        },
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        }
      ],
      defaultPrizeCover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
      inviterList: [
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          nickname: '无与伦比',
          status: 2
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145807XZyd07u6.jpg',
          nickname: '阿轩',
          status: 2
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          nickname: '康康康王',
          status: 2
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          nickname: '马克大菠萝',
          status: 2
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          nickname: '无与伦比',
          status: 1
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145807XZyd07u6.jpg',
          nickname: '阿轩',
          status: 0
        }
      ],
      countDown: null
    }
  },

  methods: {
    fn_openPop () {
      this.$refs.popRuleRef.visible = true
    },

    // 计算倒计时
    fn_getCountDown (end_time) {
      const date = new Date()
      const now = date.getTime()
      const endDate = new Date(end_time)
      const end = endDate.getTime()
      const leftTime = end - now
      if (leftTime >= 0) {
        this.countDown = {
          d: Math.floor(leftTime / 1000 / 60 / 60 / 24),
          h: Math.floor(leftTime / 1000 / 60 / 60 % 24),
          m: Math.floor(leftTime / 1000 / 60 % 60),
          s: Math.floor(leftTime / 1000 % 60)
        }
      }
    }
  },

  computed: {
    rule_focus () {
      return this.$store.getters['livelottery/GET_INFO']('rule_focus')
    },
    end_time () {
      return this.$store.getters['livelottery/GET_INFO']('end_time')
    },
    prizeList () {
      const arr = this.$store.getters['livelottery/GET_REWARD']
      return arr.filter(item => item.type === 1).slice(0, 3)
    },
    btn_title () {
      return this.$store.getters['livelottery/GET_INFO']('btn_title')
    }
  },

  watch: {
    rule_focus (val) {
      this.$refs.popRuleRef.visible = val
    },
    end_time (val) {
      this.fn_getCountDown(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-mobile {

  ::v-deep {
    .mobimodel-view {
      background-color: #fff;
    }
  }
}

.lottery {
  width: 100%;
  position: relative;
  background: url('https://image.01lb.com.cn//uploads/wecom/22/0704/1656918537d8kxmD8J.png') no-repeat;
  background-size: 100% auto;
  background-color: #FEECDE;
  padding-bottom: 12px;

  &-rule {
    position: absolute;
    right: 0;
    top: 38px;
    width: 25px;
    height: 37px;
    background: url('https://image.01lb.com.cn//uploads/wecom/22/0705/1656990763aYncePLu.png') no-repeat;
    background-size: 100% auto;
    z-index: 1;
    font-size: 12px;
    writing-mode: vertical-lr;
    color: #7B1EFF;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-header {
    padding: 30px 0 10px 0;
    text-align: center;
    font-size: 36px;
    line-height: 1;
    color: #FF82D6;
    font-weight: bold;
    background: linear-gradient(0deg, #FFDAEB 0%, #FFFFFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-body {
    position: relative;
    width: 100%;
    height: 430px;
    box-sizing: border-box;

    &__countdown {
      position: absolute;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #D2BFFF;
      font-size: 12px;
      line-height: 18px;

      &-count {
        display: flex;
        align-items: center;

        .number {
          display: inline-flex;
          background: rgba(89, 22, 219, .8);
          color: #fff;
          width: 18px;
          height: 18px;
          border-radius: 4px;
          align-items: center;
          justify-content: center;
          margin: 0 2px;
        }
      }
    }
  }

  &-prize {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 234px;
    height: 190px;
    background: url('https://image.01lb.com.cn//uploads/wecom/22/0704/16569247874HPhP25A.png') no-repeat;
    background-size: 100% auto;

    &__swiper {
      height: 100%;

      &--item {
        display: flex;
        align-items: center;
        justify-content: center;

        &.swiper-slide-active {
          .lottery-prize__item {
            transform: scale(1);
          }
        }
      }
    }

    &__item {
      height: 140px;
      width: 134px;
      background: url('https://image.01lb.com.cn//uploads/wecom/22/0704/16569252954g3f2gg5.png') no-repeat;
      background-size: 100% auto;
      padding: 8px 8px 0;
      box-sizing: border-box;
      transition: .25s;
      transform: scale(0.7);

      &--img {
        max-height: 100%;
        max-width: 100%;
      }

      &--title {
        font-size: 12px;
        color: #fff;
        margin-top: 4px;
        width: 70px;
        text-align: center;
      }
    }

    &__cover {
      line-height: 1;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #fff;
      padding: 2px;
      box-sizing: border-box;
    }

    &__name {
      color: #fff;
      font-size: 12px;
      text-align: center;
      margin-top: 6px;
    }

    &__mask {
      position: absolute;
      top: 0;
      width: 36px;
      height: 190px;
      z-index: 1;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }
  }

  &-btn {
    position: absolute;
    top: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    background: url('https://image.01lb.com.cn//uploads/wecom/22/0704/1656921224n6Iudh7a.png') no-repeat;
    background-size: 100% auto;
    padding-bottom: 18px;
    box-sizing: border-box;
    line-height: 1;
    color: #B33B00;

    &::after {
      position: absolute;
      content: "预约直播自动参与活动";
      right: 22px;
      top: -16px;
      width: 140px;
      height: 30px;
      background: url('https://image.01lb.com.cn//uploads/wecom/22/0707/1657161362TZQfn8DI.png') no-repeat;
      background-size: 100% auto;
      font-size: 12px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 8px;
      box-sizing: border-box;
    }

    &__arrow {
      position: absolute;
      height: 18px;
      top: 8px;

      &.is-left {
        left: 60px;

        &.is-move {
          animation: left_move 0.5s infinite;
        }
      }

      &.is-right {
        right: 60px;

        &.is-move {
          animation: right_move 0.5s infinite;
        }
      }
    }
  }

  &-barrage {
    position: absolute;
    top: 375px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    height: 26px;
    line-height: 1;

    &__avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 4px;
    }

    &__name {
      margin-right: 2px;
    }
  }

  &-inviter {
    position: relative;
    box-sizing: border-box;
    background: url('https://image.01lb.com.cn//uploads/wecom/22/0705/1657008803x1JuW484.png') no-repeat;
    background-size: 100% 100%;
    margin: 0 12px;
    padding: 8px 8px 9px;

    &__tab {
      position: relative;
      display: flex;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      z-index: 2;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: linear-gradient(90deg, #EEB7FF 0%, #9167FF 100%);
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: #fff;
        z-index: 1;
      }

      &--item {
        position: relative;
        flex: 1;
        color: #fff;
        font-size: 15px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        cursor: pointer;

        &:first-child {
          background: linear-gradient(90deg, #EEB7FF 0%, #be8eff 100%);
          border-bottom-right-radius: 10px;

          &.active {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          background: linear-gradient(90deg, #be8eff 0%, #9167FF 100%);
          border-bottom-left-radius: 10px;

          &.active {
            border-top-left-radius: 10px;
          }
        }

        &.active {
          color: #8A5DF5;
          font-size: 16px;
          background: #fff;
          font-weight: bold;
        }
      }
    }

    &__body {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: linear-gradient(90deg, #EEB7FF 0%, #9167FF 100%);
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #fff;
        border-radius: 0 0 10px 10px;
        z-index: 2;
      }

      &.is-left::after {
        border-top-right-radius: 10px;
      }

      &.is-right::after {
        border-top-left-radius: 10px;
      }
    }
  }

  .invite {
    position: relative;
    z-index: 3;
    overflow: auto;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 5px;

      &.is-head {
        color: #666;
        font-size: 13px;
        padding: 7px 0;
        border-bottom: 1px solid #eee;
        position: sticky;
        top: 0;
        z-index: 1;
        margin: 0 5px;
      }
    }

    &-left {
      width: 50%;
      text-align: center;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-right {
      width: 50%;
      text-align: right;
      padding-right: 40px;
      box-sizing: border-box;
    }

    &-avatar {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      margin-right: 4px;
      margin-left: 10px;
    }

    &-name {
      flex: 1;
      text-align: left;
      font-size: 14px;
    }

    &-status {
      font-size: 12px;
    }
  }

  .prizes {
    position: relative;
    z-index: 3;
    padding: 12px 8px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #FAE8FF;
      padding: 4px 6px;
      margin-bottom: 8px;
      border-radius: 8px;
    }

    &-left {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin-right: 6px;
    }

    &-cover {
      width: 48px;
      height: 48px;
      border-radius: 6px;
      margin-right: 6px;
    }

    &-name {
      color: #D01761;
      font-size: 13px;
    }

    &-time {
      color: #999;
      font-size: 12px;
      transform: scale(0.9);
      transform-origin: left;
    }

    &-btn {
      font-size: 12px;
      color: #fff;
      background-color: #FF3D77;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 50px;
      line-height: 1;
    }

  }
}

@keyframes left_move {
  0% {
    left: 60px;
  }

  49% {
    left: 60px;
  }

  50% {
    left: 70px;
  }

  100% {
    left: 70px;
  }
}

@keyframes right_move {
  0% {
    right: 60px;
  }

  49% {
    right: 60px;
  }

  50% {
    right: 70px;
  }

  100% {
    right: 70px;
  }
}
</style>
