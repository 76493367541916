<template>
  <div class="data-panel">
    <div class="main-title mb-24 pl-16">数据概览</div>

    <el-row :gutter="30">
      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title flex flex-between">访问人数</div>
          <div class="nums">{{ panelInfo.yesterdayAddUserCount || 0 }}</div>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title flex flex-between">总提交</div>
          <div class="nums">{{ panelInfo.yesterdayRetentionRate || 0 }}</div>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="el-col-wrapper">
          <div class="title flex flex-between">提交率</div>
          <div class="nums">{{ panelInfo.todayAddUserCount || 0 }}%</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  props: {
    panelInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.data-panel {
  background: #fff;
  // padding: 24px;

  .main-title {
    font-size: 16px;
    font-weight: 500;
    color: #212b36;
    border-left: 4px solid #2B7CFF;
    box-sizing: content-box;
  }

  .el-col {
    .el-col-wrapper {
      background: #F7F8FC;
      width: 100%;
      padding: 16px 24px;
    }
  }

  .nums {
    font-size: 26px;
    font-weight: 500;
    color: #212b36;
  }
}
</style>
