<!--
 * @Author: zhanln
 * @Date: 2021-10-11 11:03:43
 * @LastEditTime: 2021-12-30 14:32:23
 * @LastEditors: Please set LastEditors
 * @Description: 转让群主弹框
-->

<template>
  <ly-dialog
    title="转让群主"
    :visible.sync="visible"
    class="owner-dialog"
    :setting="dialogSetting"
    @open="fn_open"
    @confirm="fn_confirm"
  >
    <div class="q-info" style="margin-bottom: 16px">
      <i class="iconfont icon-info-fill"></i>
      仅可转让群主身份给同企业云端账号在线的成员，且成员身份完成实名认证
    </div>

    <ly-table
      class="owner-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :page-size="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
      @rowClick="handleRowClick"
    >
      <template #check="{ row }">
        <el-radio
          :label="row.id"
          v-model="radioId"
          :disabled="row.status === 0"
        ></el-radio>
      </template>

      <template #status="{ row }">
        <span class="q-success" v-if="row.status === 1">在线</span>
        <span class="q-warning" v-else-if="row.status === 0">离线</span>
      </template>
    </ly-table>
  </ly-dialog>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import lyDialog from '@/components/global/lyDialog'
import { groupManage } from '../http.js'
export default {
  name: 'groupTransfer',
  mixins: [lyTable],

  computed: {
    tableOpt () {
      return {
        layout: 'hide',
        operation: 'hide',
        tableProps: {
          'max-height': 300
        },
        column: [
          {
            label: '',
            prop: 'check',
            slot: 'check',
            width: 60
          },
          {
            label: '成员',
            prop: 'name'
          },
          {
            label: '云端状态',
            prop: 'status',
            slot: 'status'
          }
        ]
      }
    }
  },

  components: {
    lyDialog
  },

  props: {
    gid: {
      type: [Number, null],
      default: null
    }
  },

  data () {
    return {
      COMM_HTTP: groupManage,
      IMMEDIATE: false,
      visible: false,
      dialogSetting: {
        confirmText: '确认转让',
        confirmDisable: !this.radioId
      },
      radioId: null
    }
  },

  methods: {
    async queryData () {
      this.loading = true
      // 获取自定义参数
      const res = await this.COMM_HTTP.ownerList({
        group_id: this.gid
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        this.loading = false
        return false
      }

      this.tableData = res
      this.loading = false
    },

    handleRowClick (row, column, event) {
      if (row.status === 0) return false
      this.radioId = row.id
      this.dialogSetting.confirmDisable = false
    },

    fn_open () {
      this.queryData()
    },

    async fn_confirm () {
      const radioId = this.radioId
      if (!radioId) {
        return this.fn_lyMsg('info', '请选择群主！')
      } else {
        await this.fn_changeOwner()
      }
    },

    async fn_changeOwner () {
      const data = await this.COMM_HTTP.changeOwner({
        group_id: this.gid,
        group_user_id: this.radioId
      })

      if (data.code !== 0) {
        this.$message.success('转让成功！')
        this.visible = false
        this.$emit('success')
      } else {
        return this.$message.info(data.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-dialog {
  ::v-deep .ly-dialog__body {
    max-height: inherit;
  }
}

.owner-list {
  .el-radio {
    ::v-deep .el-radio__label {
      display: none;
    }
  }
}
</style>
