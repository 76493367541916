var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-config-container" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-position": "top",
            model: _vm.ruleForm,
            rules: _vm.rules
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "表单名称", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入表单名称",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                on: { input: _vm.inputTitle },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.title"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }