<!--
 * @Descripttion: 所在群聊、添加账号等可能有多个的时候 表格内只显示数量 点击查看更多的弹层组件
 * 插槽reference：同el-popover  默认使用list.length
 * 插槽item：自定义item内容
 * @version: 1.0.0
-->
<template>
  <div class="more-popover">
    <el-popover
      placement="bottom-start"
      width="180"
      trigger="click">
      <div class="content">
        <div class="list-title">{{ `${title} (${list.length})` }}</div>
        <el-scrollbar class="list">
          <div class="item" v-for="(item, i) in list" :key="i">
            <slot name="item" :item="item" >
              <span class="text text-ellipsis" :key="i">{{item}}</span>
            </slot>
          </div>
        </el-scrollbar>
      </div>
      <span slot="reference">
        <slot name="reference">{{list.length}}</slot>
      </span>
    </el-popover>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    // 弹层名
    title: {
      type: String,
      default: ''
    },
    // 数组格式的数据
    list: {
      type: Array,
      default: () => []
    }
  }

}
</script>

<style lang="scss" scoped>
.list-title{
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}
.list{
  height: 112px;
  .item{
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin: 4px 0;
    padding: 0 5px;
    background: #F2F7FE;
  }
  .text{
    flex: 1;
  }
}
::v-deep{
  .el-popover__reference{
    color: #2B7CFF;
    cursor: pointer;
  }
}
</style>
