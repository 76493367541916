<!--
 * @Author: zhanln
 * @Date: 2021-11-03 17:09:31
 * @LastEditTime: 2022-03-29 15:14:25
 * @LastEditors: zhanln
 * @Description: 任务设置 - 表单
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="160px"
    class="act-form"
    size="medium"
  >
    <!-- 任务状态，第一阶默认开启，不需显示 -->
    <el-form-item label="任务状态：" v-if="current_step !== 0">
      <div class="flex">
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          content="请先关闭第三阶任务"
          popper-class="q-tooltip"
          :disabled="!status_disable"
        >
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            :disabled="(isEdit && !isCopy) || status_disable"
          ></el-switch>
        </el-tooltip>
        <span style="margin-left: 8px">{{
          form.status === 0 ? "关闭" : "开启"
        }}</span>
      </div>
    </el-form-item>

    <!-- 如果开启，显示表单 -->
    <div v-show="form.status === 1">
      <!-- 任务人数 -->
      <el-form-item label="任务人数：" prop="target_count">
        <el-input-number
          v-model="form.target_count"
          controls-position="right"
          :min="1"
          :max="100"
          :precision="0"
          class="number-big"
          :disabled="this.isEdit && !this.isCopy"
          placeholder="目标邀请人数"
        ></el-input-number>
      </el-form-item>

      <!-- 任务达成标签 -->
      <el-form-item label="任务达成标签：">
        <div class="flex" style="height: 36px">
          <el-switch
            v-model="form.tag_open"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <span class="q-info" style="line-height: 20px; margin-left: 10px"
            >开启后，可自动为任务达成用户打标签</span
          >
        </div>
        <div v-show="form.tag_open">
          <tag-select-v2
            @getTagId="fn_getFinishIds"
            :checked="form.finish_task_tags"
          ></tag-select-v2>
        </div>
      </el-form-item>

      <!-- 奖品信息 -->
      <el-form-item label="奖品信息：" prop="reward_title">
        <div class="flex reward">
          <ly-upload
            :img="form.reward_cover"
            @getImg="fn_getCover"
            tipText="奖品封面"
            canRemove
          ></ly-upload>
          <div class="flex flex-column reward-info">
            <el-input
              v-model="form.reward_title"
              placeholder="奖品名称，必填"
              maxlength="10"
              show-word-limit
              class="reward-info__title act-form__input"
            ></el-input>
            <el-input
              v-model="form.reward_desc"
              type="textarea"
              resize="none"
              placeholder="奖品简介，选填"
              maxlength="40"
              show-word-limit
              class="reward-info__desc act-form__input"
            ></el-input>
          </div>
        </div>
      </el-form-item>

      <!-- 虚拟完成人数 -->
      <el-form-item label="虚拟完成人数：" prop="unreal">
        <el-input-number
          v-model="form.unreal"
          controls-position="right"
          :min="0"
          :max="99999"
          :precision="0"
          class="number-big"
          placeholder="虚拟完成人数"
        ></el-input-number>
      </el-form-item>

      <!-- 奖品内容 -->
      <el-form-item>
        <template slot="label"
          ><span class="q-danger">*</span> 奖品内容：
        </template>
        <reward-detail
          ref="rewardDetailRef"
          @changeTotal="fn_changeTotal"
        ></reward-detail>
      </el-form-item>

      <!-- 奖品库存 -->
      <el-form-item label="奖品库存：" prop="total">
        <el-input-number
          v-model="form.total"
          controls-position="right"
          :min="isEdit && !isCopy ? 0 : 1"
          :max="99999"
          :precision="0"
          class="number-big"
          placeholder="奖品库存"
        ></el-input-number>
        <div class="q-info" v-if="isEdit && !isCopy">
          <span style="margin-right: 24px"
            >已使用：<b>{{ form.use_count }}</b></span
          >
          <span
            >剩余库存：<b>{{ form.total - form.use_count }}</b></span
          >
        </div>
        <p
          class="q-info text-small"
          style="line-height: 1.6; margin-bottom: 0"
          v-if="showRiskNum >= showRiskMax"
        >
          温馨提示：账号被动添加好友建议值：新企微号每天200~400人左右，高权重老号每天800~1000人左右。当前奖品库存/涨粉账号≈{{
            showRiskNum
          }}人，请谨慎设置库存
        </p>
      </el-form-item>

      <!-- 真实库存展示 -->
      <el-form-item label="真实库存展示：" prop="open_stock">
        <div class="flex">
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="form.open_stock"
            style="margin-right: 16px"
          >
          </el-switch>
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            popper-class="q-tooltip"
          >
            <div slot="content">
              1.关闭：活动页显示“库存告急”。<br />
              2.开启：展示任务设置的奖品剩余库存，如果奖品类型为兑换码，<br />
              则展示兑换码的真实库存。
            </div>
            <i class="ri-question-line"></i
          ></el-tooltip>
        </div>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
// components
import tagSelectV2 from '@/components/wecomTags'
import rewardDetail from './rewardDetail'
// store
import { mapState } from 'vuex'
export default {
  name: 'taskRewardItem',

  data () {
    // 人数校验
    const checkTargetCount = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入任务人数'))
      } else {
        const check = this.fn_checkCount(+value)
        if (check) {
          callback(new Error(check))
        } else {
          callback()
        }
      }
    }

    // 奖品信息校验
    const checkInfo = async (rule, value, callback) => {
      if (value === '' && this.form.reward_cover === '') {
        callback(new Error('请输入奖品信息（奖品名称为必填）'))
      } else {
        callback()
      }
    }

    // 虚拟人数校验
    const checkUnreal = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('请输入虚拟完成人数'))
      } else {
        const check = this.fn_checkUnreal(+value)
        if (check) {
          callback(new Error(check))
        } else {
          callback()
        }
      }
    }

    // 奖品库存校验
    const checkTotal = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('请输入奖品库存'))
      } else {
        if (this.isEdit && !this.isCopy && value - this.form.use_count < 0) {
          callback(new Error('请确保剩余库存大于0'))
        } else {
          callback()
        }
      }
    }

    return {
      form: {
        id: 0,
        step: 1,
        status: 0,
        target_count: 0,
        tag_open: 0,
        finish_task_tags: '',
        reward_cover: '',
        reward_title: '',
        reward_desc: '',
        unreal: 0,
        reward_type: 4,
        reward_detail: '',
        reward_type_id: null,
        total: 0,
        open_stock: 0
      },
      rules: {
        target_count: [
          { validator: checkTargetCount, required: true, trigger: ['change', 'blur'] }
        ],
        reward_title: [
          { validator: checkInfo, required: true, trigger: 'change' }
        ],
        unreal: [
          { validator: checkUnreal, required: true, trigger: ['change', 'blur'] }
        ],
        total: [
          { validator: checkTotal, required: true, trigger: ['change', 'blur'] }
        ]
      },
      showRiskMax: 200,
      showRiskNum: 9999
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.task.isEdit,
      isCopy: state => state.task.isCopy,
      current_step: state => state.task.current_step || 0,
      wecom_users: state => state.task.wecom_users,
      target_count_0: state => state.task.target_count_0,
      unreal_0: state => state.task.unreal_0,
      status_1: state => state.task.status_1,
      target_count_1: state => state.task.target_count_1,
      unreal_1: state => state.task.unreal_1,
      status_2: state => state.task.status_2,
      target_count_2: state => state.task.target_count_2,
      unreal_2: state => state.task.unreal_2
    }),
    status_disable () {
      // 如果是第二阶段，且第三阶段为开启，则不可关闭
      return this.current_step === 1 && this.status_2 === 1
    }
  },

  watch: {
    'wecom_users' (val) {
      if (val) {
        this.showRiskNum = (this.form.total / val.length).toFixed()
      } else {
        this.showRiskNum = 9999
      }
    },
    'form.status' (val) {
      this.fn_setStore('status', val)

      // 关闭时，清空奖品内容的校验
      this.$refs.rewardDetailRef.fn_clearValid()
    },
    'form.target_count' (val) {
      this.fn_setStore('target_count', val)
    },
    'form.reward_cover' (val) {
      this.fn_setStore('reward_cover', val)
    },
    'form.reward_title' (val) {
      this.fn_setStore('reward_title', val)
    },
    'form.reward_desc' (val) {
      this.fn_setStore('reward_desc', val)
    },
    'form.unreal' (val) {
      this.fn_setStore('unreal', val)
    },
    'form.total' (val) {
      this.fn_setStore('total', val)
      if (val && this.wecom_users) {
        this.showRiskNum = (val / this.wecom_users.length).toFixed()
      } else {
        this.showRiskNum = 9999
      }
    },
    'form.open_stock' (val) {
      this.fn_setStore('open_stock', val)
    }
  },

  methods: {

    // 初始化表单
    fn_setForm (obj) {
      // 1,2,3,4 需要处理旧数据格式
      if (obj.reward_type === 2) {
        obj.reward_type = 4
        obj.reward_detail_img = obj.reward_detail
        obj.reward_detail = ''
      }

      if (obj.reward_type === 3) {
        obj.reward_type = 4
        obj.reward_detail = ''
        if (obj.link_url) {
          obj.reward_detail = `<a href="${obj.link_url}">${obj.link_url}</a>`
        }
      }

      if (obj.reward_type === 4) {
        const isJson = this.fn_isJson(obj.reward_detail)
        if (isJson) {
          const detail = JSON.parse(obj.reward_detail)
          obj.reward_detail = detail.text
          obj.reward_detail_img = detail.img_url
        }
      }

      if (obj.reward_type === 5) {
        obj.form_id = obj.form_id || obj.reward_type_id
        obj.form_rule = obj.form_rule || obj.reward_detail
      }

      if (obj.reward_type === 6) {
        obj.code_id = obj.code_id || obj.reward_type_id
        obj.code_rule = obj.code_rule || obj.reward_detail
      }

      this.form = obj

      this.fn_setStore('unreal', this.form.unreal)
      this.fn_setStore('total', this.form.total)

      // 切换阶段，清空校验
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate()
      })
      this.$refs.rewardDetailRef.fn_setDetail(this.form)
    },

    // 判断JSON
    fn_isJson (str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    },

    // 兑换码库存
    fn_changeTotal (val) {
      this.form.total = val
    },

    // 保存到 vuex
    fn_setStore (type, val) {
      this.$store.commit('task/SET_REWARD_INFO', {
        step: this.current_step,
        type,
        val
      })
    },

    // 任务达成标签 回调
    fn_getFinishIds (tags) {
      const tagStr = tags ? tags.join(',') : ''
      this.form.finish_task_tags = tagStr
    },

    // 奖品封面设置 回调
    fn_getCover (img) {
      this.form.reward_cover = img
      this.$refs.formRef.validateField('reward_title')
    },

    // 校验任务人数，规则：大于上阶小于下阶
    fn_checkCount (val) {
      const thisStep = this.current_step
      const exTargetCount = thisStep > 0 ? this[`target_count_${thisStep - 1}`] : 0 // 上阶段任务人数
      const nextStatus = this[`status_${thisStep + 1}`] === 1 // 下阶段开启状态
      const nextTargetCount = this[`target_count_${thisStep + 1}`] // 下阶段任务人数
      const stepName = ['一阶', '二阶', '三阶']
      let msg = null

      // 一阶校验
      if (thisStep === 0) {
        if (nextStatus && val >= nextTargetCount) {
          msg = `任务人数需小于${stepName[thisStep + 1]}任务人数`
        }
      }

      // 二阶校验
      if (thisStep === 1) {
        if (val <= exTargetCount) {
          msg = `任务人数需大于${stepName[thisStep - 1]}任务人数`
        } else if (nextStatus) {
          if (val >= nextTargetCount) {
            msg = `任务人数需小于${stepName[thisStep + 1]}任务人数`
          }
        }
      }

      // 三阶校验
      if (thisStep === 2) {
        if (val <= exTargetCount) {
          msg = `任务人数需大于${stepName[thisStep - 1]}任务人数`
        }
      }
      return msg
    },

    // 校验虚拟人数，规则：小于等于上阶，大于等于下阶
    fn_checkUnreal (val) {
      const thisStep = this.current_step
      const exUnreal = thisStep > 0 ? this[`unreal_${thisStep - 1}`] : 0 // 上阶段任务人数
      const nextStatus = this[`status_${thisStep + 1}`] === 1 // 下阶段开启状态
      const nextUnreal = this[`unreal_${thisStep + 1}`] // 下阶段任务人数
      const stepName = ['一阶', '二阶', '三阶']
      let msg = ''

      // 一阶校验
      if (thisStep === 0) {
        if (nextStatus && val < nextUnreal) {
          msg = `需大于等于${stepName[thisStep + 1]}虚拟完成人数（${nextUnreal}）`
        }
      }

      // 二阶校验
      if (thisStep === 1) {
        if (val > exUnreal) {
          msg = `需小于等于${stepName[thisStep - 1]}虚拟完成人数（${exUnreal}）`
        } else if (nextStatus) {
          if (val < nextUnreal) {
            msg = `需大于等于${stepName[thisStep + 1]}虚拟完成人数（${nextUnreal}）`
          }
        }
      }

      // 三阶校验
      if (thisStep === 2) {
        if (val > exUnreal) {
          msg = `需小于等于${stepName[thisStep - 1]}虚拟完成人数（${exUnreal}）`
        }
      }

      return msg
    },

    // 奖品内容 回调配置
    fn_setRewardContent (data) {
      switch (data.type) {
        case 1:
          this.form.reward_type = 4
          break
        case 2:
          this.form.reward_type = 6
          break
        case 3:
          this.form.reward_type = 5
          break
      }
      this.form.reward_detail = data.diy.content
      this.form.reward_detail_img = data.diy.img
      this.form.code_id = data.code.codeId
      this.form.code_rule = data.code.rule
      this.form.form_id = data.form.formId
      this.form.form_rule = data.form.rule
    },

    // 校验
    verify () {
      let formValid = true
      let rewardDetail = null

      // 如果状态未开启，不校验
      if (this.form.status === 1) {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            formValid = false
          }
        })

        // 校验奖品内容，通过则设置到form
        rewardDetail = this.$refs.rewardDetailRef.verify()
        if (!rewardDetail) {
          formValid = false
        }
      } else {
        rewardDetail = this.$refs.rewardDetailRef.fn_getForm()
      }

      if (!formValid) return false

      this.fn_setRewardContent(rewardDetail)

      return this.form
    }
  },

  components: {
    tagSelectV2,
    rewardDetail
  }
}
</script>

<style lang="scss" scoped>
.reward {
  &-info {
    margin-left: 8px;

    &__title {
      margin-bottom: 8px;
    }

    &__desc {
      ::v-deep .el-textarea__inner {
        height: 60px;
        border-color: #dfe1e8 !important;
      }
    }
  }
}
.number-big {
  width: 200px;
}
</style>
