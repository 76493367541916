var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail", class: { "z-top": _vm.is_focus } },
    [
      _vm.is_focus ? _c("div", { staticClass: "detail-mask" }) : _vm._e(),
      _c(
        "div",
        { staticClass: "detail-box", class: { show: _vm.is_focus } },
        [
          _c("div", { staticClass: "detail-title" }, [_vm._v("我的奖品")]),
          _vm.detail.content
            ? _c(
                "div",
                { staticClass: "detail-body" },
                [
                  _c("div", { staticClass: "detail-body__title" }, [
                    _vm._v("兑换规则")
                  ]),
                  _c("el-input", {
                    staticClass: "detail-body__text",
                    attrs: {
                      type: "textarea",
                      value: _vm.detail.content,
                      autosize: "",
                      resize: "none",
                      readonly: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.detail.type === 1
            ? [
                _vm.detail.img
                  ? _c("div", { staticClass: "detail-img" }, [
                      _c("img", { attrs: { src: _vm.detail.img, alt: "" } })
                    ])
                  : _vm._e()
              ]
            : [
                _c("div", { staticClass: "detail-btn code" }, [
                  _vm._v("SASAFASTERHFDHF")
                ]),
                _c("div", { staticClass: "detail-btn" }, [_vm._v("复制兑换码")])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }