import axios from '@/common/ajax.js'
// 兑换码列表
export const codeList = {
  reqQuery: params => axios.post('/wecom/task/code-list', params), // 查询表格数据的接口
  reqDelete: params => axios.post('/wecom/task/del-code', params) // 删除兑换码
}

// 兑换码详情
export const detail = {
  reqQuery: params => axios.post('/wecom/task/code-detail', params), // 兑换码详情
  reqDelete: params => axios.post('/wecom/task/del-code-detail', params) // 删除兑换码
}

// 兑换码
export const code = {
  reqQuery: params => axios.post('/wecom/task/create-code', params) // 创建兑换码
}
