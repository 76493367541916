<!--
 * @Author: zhanln
 * @Date: 2021-09-27 18:08:11
 * @LastEditTime: 2021-12-16 11:10:16
 * @LastEditors: zhanln
 * @Description: echarts 初始化
-->
<template>
  <div
    :id="chartId"
    :style="{ width: '100%', height: this.height }"
    v-loading="loading"
  ></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'lyChat',
  props: {
    height: {
      type: String,
      default: '200px'
    },
    option: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      loading: true,
      chart: null,
      chartId: 'chart_' + Date.now() + this._randomString(10)
    }
  },
  mounted () {
    // this._initChart().then(() => {
    //   this._resize()
    // })
  },
  computed: {
    s_option () {
      return this.option
    }
  },
  watch: {
    s_option: {
      handler (val) {
        if (val) {
          // 判断确保已经创建了 echarts 实例
          if (this.chart) {
            this.loading = false
            this.chart.clear()
            this.chart.setOption(val, true)
          } else {
            this._initChart().then(() => {
              this.loading = false
              this.chart.setOption(val, true)
              this._resize()
            })
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {

    _randomString (len) {
      len = len || 32
      const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      const maxPos = $chars.length
      let pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    },
    _initChart () {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          this.chart = echarts.init(document.getElementById(this.chartId))
          resolve()
        })
      })
    },
    _resize () {
      if (this.chart) {
        window.addEventListener('resize', () => this.chart.resize({ width: 'auto' }))
      }
    },

    _cusResize (w) {
      this.chart.resize({
        width: w
      })
    }
  },
  beforeMount () {
    window.removeEventListener('resize', function () { })
  }
}
</script>
