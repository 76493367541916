<template>
  <div class="ly-table page-warp">
    <!-- searchBar -->
    <div>
      <el-form :inline="true" :model="searchForm" size="medium">
        <el-form-item style="margin-left: 0">
          <el-button-group>
            <el-button
              :type="btnState === 'day' ? 'primary' : ''"
              @click="getAnalyse('day')"
              >昨日</el-button
            >
            <el-button
              :type="btnState === 'week' ? 'primary' : ''"
              @click="getAnalyse('week')"
              >近7日</el-button
            >
            <el-button
              :type="btnState === 'month' ? 'primary' : ''"
              @click="getAnalyse('month')"
              >近30日</el-button
            >
          </el-button-group>
        </el-form-item>
        <el-form-item label="自定义：">
          <el-date-picker
            v-model="dates"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="changeTime"
            style="width: 260px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择员工：">
          <ly-emp-select
            placeholder="选择员工"
            :showLength="1"
            :checkList="checkProp"
            @getEmpId="getEmpId"
          ></ly-emp-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- searchBar End -->

    <!-- card -->
    <el-row :gutter="20">
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'chat_cnt' ? 'active' : ''"
          @click="changeCard('chat_cnt')"
        >
          <div class="icon primary">
            <i class="iconfont icon-icon-chat"></i>
          </div>
          <div class="info">
            <div class="temp">
              <div class="num">
                <span class="all">{{ summary.chat.curr }}</span> /
                {{ summary.chat.prev }}
              </div>
              <div class="rate">
                <template v-if="summary.chat.rate === 0">
                  <span class="rate-num q-info">
                    {{ summary.chat.rate }}%
                  </span>
                </template>
                <template
                  v-else-if="summary.chat.rate.toString().indexOf('-') === -1"
                >
                  <span class="rate-num q-success">
                    {{ "+" + summary.chat.rate }}%
                  </span>
                </template>
                <template v-else>
                  <span class="rate-num q-danger">
                    {{ summary.chat.rate }}%
                  </span>
                </template>
              </div>
            </div>
            <div class="title">
              聊天总数
              <el-tooltip
                class="item"
                effect="dark"
                content="统计周期内，员工有发送过消息的单聊的数量。"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'message_cnt' ? 'active' : ''"
          @click="changeCard('message_cnt')"
        >
          <div class="icon warning">
            <i class="iconfont icon-send-fill"></i>
          </div>
          <div class="info">
            <div class="temp">
              <div class="num">
                <span class="all">{{ summary.msg.curr }}</span> /
                {{ summary.msg.prev }}
              </div>
              <div class="rate">
                <template v-if="summary.msg.rate === 0">
                  <span class="rate-num q-info"> {{ summary.msg.rate }}% </span>
                </template>
                <template
                  v-else-if="summary.msg.rate.toString().indexOf('-') === -1"
                >
                  <span class="rate-num q-success">
                    {{ "+" + summary.msg.rate }}%
                  </span>
                </template>
                <template v-else>
                  <span class="rate-num q-danger">
                    {{ summary.msg.rate }}%
                  </span>
                </template>
              </div>
            </div>
            <div class="title">
              发送消息数
              <el-tooltip
                class="item"
                effect="dark"
                content="统计周期内，员工在单聊中发送的消息总数。"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'reply_rate' ? 'active' : ''"
          @click="changeCard('reply_rate')"
        >
          <div class="icon success">
            <i class="iconfont icon-icon-replied"></i>
          </div>
          <div class="info">
            <div class="temp">
              <div class="num">
                <span class="all">{{ summary.reply_rate.curr }}%</span> /
                {{ summary.reply_rate.prev }}%
              </div>
              <div class="rate">
                <template v-if="summary.reply_rate.rate === 0">
                  <span class="rate-num q-info">
                    {{ summary.reply_rate.rate }}%
                  </span>
                </template>
                <template
                  v-else-if="
                    summary.reply_rate.rate.toString().indexOf('-') === -1
                  "
                >
                  <span class="rate-num q-success">
                    {{ "+" + summary.reply_rate.rate }}%
                  </span>
                </template>
                <template v-else>
                  <span class="rate-num q-danger">
                    {{ summary.reply_rate.rate }}%
                  </span>
                </template>
              </div>
            </div>
            <div class="title">
              已回复聊天占比
              <el-tooltip
                class="item"
                effect="dark"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <div slot="content">
                  统计周期内，客户主动发起单聊后，<br />员工在一个自然日内有回复过消息的单聊数/客户主动发起的单聊数量。
                </div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'reply_time' ? 'active' : ''"
          @click="changeCard('reply_time')"
        >
          <div class="icon brown">
            <i class="iconfont icon-icon-hourglass"></i>
          </div>
          <div class="info">
            <div class="temp">
              <div class="num">
                <span class="all">{{ summary.reply_time.curr }}</span> /
                {{ summary.reply_time.prev }}
              </div>
              <div class="rate">
                <template v-if="summary.reply_time.rate === 0">
                  <span class="rate-num q-info">
                    {{ summary.reply_time.rate }}%
                  </span>
                </template>
                <template
                  v-else-if="
                    summary.reply_time.rate.toString().indexOf('-') === -1
                  "
                >
                  <span class="rate-num q-success">
                    {{ "+" + summary.reply_time.rate }}%
                  </span>
                </template>
                <template v-else>
                  <span class="rate-num q-danger">
                    {{ summary.reply_time.rate }}%
                  </span>
                </template>
              </div>
            </div>
            <div class="title">
              平均首次回复时长
              <el-tooltip
                class="item"
                effect="dark"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <div slot="content">
                  客户主动发起单聊后，员工在一个自然日内首次回复的时长间隔为首次回复时长，<br />所有单聊的首次回复总时长/已回复的单聊总数即为平均首次回复时长。
                </div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- card End -->
    <div class="temp-contain">
      <!-- toolBar -->
      <div class="ly-tool">
        <el-button-group>
          <el-button
            size="medium"
            :type="analyseState === 'chart' ? 'primary' : ''"
            @click="analyseState = 'chart'"
            >图表</el-button
          >
          <el-button
            size="medium"
            :type="analyseState === 'list' ? 'primary' : ''"
            @click="analyseState = 'list'"
            >列表</el-button
          >
        </el-button-group>
      </div>
      <!-- toolBar End -->
      <div class="temp-box">
        <!-- chart -->
        <ly-chart
          class="chart"
          :option="chartOption"
          height="400px"
          v-show="analyseState === 'chart' && showEmpty"
        ></ly-chart>
        <div
          v-show="analyseState === 'chart' && !showEmpty"
          class="flex flex-center q-info"
          style="height: 240px"
        >
          暂无分析数据
        </div>
        <!-- chart End -->

        <!-- table -->
        <div class="ly-table__main" v-show="analyseState === 'list'">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="stat_time" label="日期"> </el-table-column>
            <el-table-column prop="chat_cnt" label="聊天总数" align="center">
            </el-table-column>
            <el-table-column
              prop="message_cnt"
              label="发送消息数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="reply_percentage"
              label="已回复聊天占比"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="avg_reply_time"
              label="平均首次回复时长"
              align="center"
            >
            </el-table-column>
            <div slot="empty" class="ly-table-empty">
              <slot name="empty">
                <img src="@assets/svg/default/no_data.svg" alt="" />
                暂无数据
              </slot>
            </div>
          </el-table>
        </div>
      </div>
    </div>
    <!-- table End -->
  </div>
</template>

<script>
import lyChart from '@/components/lyChart'
export default {
  data () {
    return {
      btnState: 'week',
      dates: [],
      allEmpsData: [],
      filterText: '',
      chooseList: [],
      searchForm: {
        begin_at: '',
        end_at: '',
        user_ids: null
      },
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        onPick: (time) => {
          return this.onPick(time)
        }
      },
      showPlaceholder: true,
      cardName: 'chat_cnt',
      curLabel: '聊天总数',
      preLabel: '上期聊天总数',
      analyseState: 'chart',
      summary: {
        chat: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        msg: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        reply_rate: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        reply_time: {
          curr: 0,
          prev: 0,
          rate: 0
        }
      },
      current: [],
      previous: [],
      summaryCurr: 0,
      chart: null,
      chartOption: {
        color: ['rgb(43, 124, 255)', 'rgb(0, 199, 128)'],
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(0, 0, 0, .8)',
          formatter: function (params) {
            const text = `<div style="line-height: 2; padding: 4px 12px; color: #fff;">
                            <div>同比增长 ${params[0].data.range}</div>
                            <span style="min-width: 80px;display: inline-block;">${params[0].data.time}</span>
                            ${params[0].marker}${params[0].seriesName}：${params[0].data.value}<br/>
                            <span style="min-width: 80px;display: inline-block;">${params[1].data.time}</span>
                            ${params[1].marker}${params[1].seriesName}：${params[1].data.value}
                          </div>`

            return text
          }
        },
        grid: {
          top: '10%',
          left: '2%',
          right: '2%',
          containLabel: true
        },
        legend: {
          left: 'center',
          bottom: 20,
          itemGap: 24,
          icon: 'circle',
          itemWidth: 12,
          itemHeight: 12
        },
        xAxis: {
          boundaryGap: ['2%', '20%'],
          axisLine: {
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          axisLabel: {
            color: '#6E788A'
          },
          data: []
        },
        yAxis: {
          name: '人数',
          nameTextStyle: {
            align: 'right'
          },
          minInterval: 1,
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          }
        },
        series: [
          {
            type: 'line',
            name: '聊天总数',
            symbol: 'circle',
            data: [],
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#EBF3FF' // 0% 处的颜色
                }, {
                  offset: 1, color: '#fff' // 100% 处的颜色
                }]
              }
            }
          },
          {
            type: 'line',
            name: '上期聊天总数',
            symbol: 'circle',
            data: [],
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#E0F8F1' // 0% 处的颜色
                }, {
                  offset: 1, color: '#fff' // 100% 处的颜色
                }]
              }
            }
          }
        ]
      },
      tableData: [],
      checkProp: null,
      showEmpty: true
    }
  },
  created () {
    const user_ids = this.$route.query.user_ids
    if (user_ids) {
      this.checkProp = [user_ids]
      if (this.$route.query.btnState) {
        this.btnState = this.$route.query.btnState
        this.searchForm = {
          user_ids: this.$route.query.user_ids
        }
        this.getAnalyse(this.btnState)
      } else {
        this.searchForm = {
          begin_at: this.$route.query.begin_at,
          end_at: this.$route.query.end_at,
          user_ids: this.$route.query.user_ids
        }
        this.dates = [this.searchForm.begin_at, this.searchForm.end_at]
        this.btnState = ''
        this.getAnalyse()
      }
      this.$router.replace({ query: {} })
    } else {
      this.getAnalyse('week')
    }
    window.onresize = () => {
      if (this.chart) {
        this.chart.resize()
      }
    }
  },
  watch: {
    filterText (val) {
      this.$refs.transfer.filter(val)
    },
    chooseList () {
      this.chooseList.length === 0 ? this.showPlaceholder = true : this.showPlaceholder = false
    },
    cardName (newVal, oldVal) {
      this.initChartOptions()
    }
  },
  methods: {

    // 设置时间范围
    disabledDate (time) {
      const timeOptionRange = this.timeOptionRange
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _time = _date.getTime()
      if (timeOptionRange) {
        const secondNum = 30 * 8.64e7
        const _maxTime = timeOptionRange.getTime() + secondNum < (_time - 1 * 8.64e7) ? timeOptionRange.getTime() + secondNum : (_time - 1 * 8.64e7)
        const _minTime = timeOptionRange.getTime() - secondNum > (_time - 151 * 8.64e7) ? timeOptionRange.getTime() - secondNum : (_time - 151 * 8.64e7)
        return time.getTime() >= _maxTime || time.getTime() < _minTime
      } else {
        return time.getTime() >= _time - 1 * 8.64e7 || time.getTime() < _time - 151 * 8.64e7
      }
    },

    // 点击时设置
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },

    // 切换时间
    changeTime (val) {
      if (val) {
        this.searchForm.begin_at = this.util.timeTofmt(val[0])
        this.searchForm.end_at = this.util.timeTofmt(val[1])
        this.btnState = ''
        this.getAnalyse()
      } else {
        this.searchForm.begin_at = null
        this.searchForm.end_at = null
        this.getAnalyse('week')
      }
    },

    // 切换卡片
    changeCard (val) {
      this.cardName = val
      switch (val) {
        case 'chat_cnt':
          this.curLabel = '聊天总数'
          this.preLabel = '上期聊天总数'
          break
        case 'message_cnt':
          this.curLabel = '发送消息数'
          this.preLabel = '上期发送消息数'
          break
        case 'reply_rate':
          this.curLabel = '已回复聊天占比'
          this.preLabel = '上期已回复聊天占比'
          break
        case 'reply_time':
          this.curLabel = '平均首次回复时长'
          this.preLabel = '上期平均首次回复时长'
          break
      }
    },

    getEmpId (ids) {
      this.searchForm.user_ids = ids && ids.length > 0 ? ids.join(',') : null
      this.getAnalyse()
    },

    // 获取数据
    async getAnalyse (type) {
      let beginAt, endAt
      if (type) {
        this.btnState = type
        let time = await this.axios.get('getTime')
        time *= 1000
        endAt = this.util.timeTofmt(time - 1 * 8.64e7)
        switch (type) {
          case 'day':
            beginAt = this.util.timeTofmt(time - 2 * 8.64e7)
            break
          case 'week':
            beginAt = this.util.timeTofmt(time - 7 * 8.64e7)
            break
          case 'month':
            beginAt = this.util.timeTofmt(time - 30 * 8.64e7)
            break
        }
        this.$nextTick(() => {
          this.dates = [beginAt, endAt]
        })
        this.searchForm.begin_at = beginAt
        this.searchForm.end_at = endAt
      }

      const data = await this.axios.get('chatAnalysis', {
        params: {
          ...this.searchForm
        }
      })
      Object.assign(this.summary, data.summary)
      this.current = data.current || []
      if (this.current) {
        this.tableData = this.current.map(item => {
          item.stat_time = this.util.timeTofmt(item.stat_time)
          item.reply_percentage = item.reply_percentage ? item.reply_percentage : '0%'
          item.avg_reply_time = item.avg_reply_time ? item.avg_reply_time : 0
          return item
        })
        this.previous = data.previous || []
        this.initChartOptions()
      } else {
        this.showEmpty = false
      }
    },

    // 切换图表
    initChartOptions () {
      const curArr = this.current
      const preArr = this.previous
      const labelData = []
      const curData = []
      const preData = []

      for (let i = 0; i < curArr.length; i++) {
        const _curTime = this.util.timeTofmt(curArr[i].stat_time, 'd')
        const _toolCurTime = this.util.timeTofmt(curArr[i].stat_time, 'M月d日')
        const _toolPreTime = this.util.timeTofmt(preArr[i].stat_time, 'M月d日')
        labelData.push(_curTime + '日')
        if (curArr[i][this.cardName] === undefined) {
          curData.push({
            value: 0,
            time: _toolCurTime,
            range: '0%'
          })
          preData.push({
            value: 0,
            time: _toolPreTime,
            range: '0%'
          })
        } else {
          const _rangeNum = curArr[i][this.cardName] - preArr[i][this.cardName]
          let _range = 0
          if (preArr[i][this.cardName] === 0) {
            _range = curArr[i][this.cardName] * 100 + '%'
          } else {
            if (curArr[i][this.cardName] === 0) {
              _range = -(parseInt(preArr[i][this.cardName] * 100)) + '%'
            } else {
              _range = parseInt(_rangeNum / preArr[i][this.cardName] * 100) + '%'
            }
          }
          curData.push({
            value: curArr[i][this.cardName],
            time: _toolCurTime,
            range: _range
          })
          preData.push({
            value: preArr[i][this.cardName],
            time: _toolPreTime
          })
        }
      }
      // 配置图表x轴字段
      this.chartOption.xAxis.data = labelData
      // 配置图表本期数据
      this.chartOption.series[0].data = curData
      this.chartOption.series[0].name = this.curLabel
      // 配置图表上期数据
      this.chartOption.series[1].data = preData
      this.chartOption.series[1].name = this.preLabel
    }
  },
  components: {
    lyChart
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/table.scss";
@import "@/assets/scss/analyse.scss";
::v-deep {
  .el-form--inline .el-form-item {
    margin-bottom: 20px;
  }
  .ly-tool {
    background: #fff;
    margin: 0 0 20px;
    border-radius: 16px;
  }
  .temp-contain {
    flex: 1;
    margin-top: 20px;
    background: #fff;
    border-radius: 16px;
    box-shadow: $--shadow;
    display: flex;
    flex-direction: column;
    .temp-box {
      flex: 1;
      position: relative;
      .chart {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .ly-table__main {
    padding-top: 0 !important;
    padding-bottom: 20px;
    flex: 1;
  }
  .el-form-item__label {
    padding: 0;
  }
}
</style>
