/*
 * @Author: zhan
 * @Date: 2022-06-02 10:32:02
 * @LastEditTime: 2022-09-06 17:51:22
 * @LastEditors: zhan01lb zhanlienan@01lb.com.cn
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

export const lnvitation = {
  reqQuery: params => axios.get(`/wecom/book-task/account-list?${stringify(params)}`) // 查询表格数据的接口
}

export const prize = {
  reqQuery: (params) => axios.get(`/wecom/book-task/reward-list?${stringify(params)}`) // 查询表格数据的接口
}
