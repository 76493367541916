var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择云端账号",
        "custom-class": "select-account",
        visible: _vm.visible,
        width: "635px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.handleOpen
      }
    },
    [
      _c("div", { staticClass: "temp-contain" }, [
        _c("div", { staticClass: "left" }, [
          _c(
            "div",
            { staticClass: "list-search" },
            [
              _c("el-input", {
                attrs: {
                  size: "medium",
                  placeholder: "请输入员工名称",
                  "suffix-icon": "el-icon-search"
                },
                model: {
                  value: _vm.filterKey,
                  callback: function($$v) {
                    _vm.filterKey = $$v
                  },
                  expression: "filterKey"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("云端账号")]),
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm.robotList.length === 0
                ? [
                    _c("div", { staticClass: "el-table__empty-block" }, [
                      _c("span", { staticClass: "el-table__empty-text" }, [
                        _vm._v("暂无数据")
                      ])
                    ])
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "list-block" },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          },
                          _vm._l(_vm.filteredList, function(item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "list-item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "temp" },
                                  [
                                    _c("el-avatar", {
                                      attrs: { size: 32, src: item.avatar }
                                    }),
                                    _c("span", { staticClass: "nick" }, [
                                      _vm._v(_vm._s(item.name))
                                    ]),
                                    item.status === 1
                                      ? _c("span", { staticClass: "online" }, [
                                          _vm._v("(在线)")
                                        ])
                                      : _c("span", { staticClass: "offline" }, [
                                          _vm._v("(离线)")
                                        ])
                                  ],
                                  1
                                ),
                                _c("el-checkbox", {
                                  attrs: {
                                    label: item.id,
                                    disabled: item.status !== 1
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            { staticClass: "title" },
            [
              _vm._v(" 已选账号"),
              _c("span", [_vm._v(" (" + _vm._s(_vm.selected.length) + ")")]),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.handleClear } },
                [_vm._v("清空")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm.selected.length === 0
                ? [
                    _c("div", { staticClass: "el-table__empty-block" }, [
                      _c("span", { staticClass: "el-table__empty-text" }, [
                        _vm._v("暂无数据")
                      ])
                    ])
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "list-block" },
                      _vm._l(_vm.selectedList, function(item) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "list-item" },
                          [
                            _c(
                              "div",
                              { staticClass: "temp" },
                              [
                                _c("el-avatar", {
                                  attrs: {
                                    shape: "square",
                                    size: 32,
                                    src: item.avatar
                                  }
                                }),
                                _c("span", { staticClass: "nick" }, [
                                  _vm._v(_vm._s(item.name))
                                ])
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass: "el-icon-close",
                              on: {
                                click: function($event) {
                                  return _vm.handleItemRemove(item)
                                }
                              }
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
            ],
            2
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !_vm.selected.length,
                size: "medium"
              },
              on: { click: _vm.handleSelectAccount }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }