var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "iconfont icon-plus",
                      size: "medium"
                    },
                    on: { click: _vm.fn_createAct }
                  },
                  [_vm._v("创建活动 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { round: "", size: "medium" },
                    on: {
                      click: function($event) {
                        _vm.$refs.drafts.draftsVisible = true
                      }
                    }
                  },
                  [_vm._v("草稿箱(" + _vm._s(_vm.draft) + ") ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "title_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-start" },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "36px",
                        height: "62px",
                        "margin-right": "12px",
                        "border-radius": "4px"
                      },
                      attrs: { src: row.poster_url, lazy: "", fit: "cover" }
                    }),
                    _c("span", { staticStyle: { flex: "1" } }, [
                      _vm._v(_vm._s(row.title))
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "show_status_header",
            fn: function() {
              return [
                _c("span", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v("首页展示")
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      "popper-class": "q-tooltip"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("开启后，活动将展示在小程序首页进行推广")
                    ]),
                    _c("i", { staticClass: "iconfont icon-info-fill" })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "status_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.status === 1
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("进行中")
                    ])
                  : row.status === 2
                  ? _c("ly-status", [_vm._v("已结束")])
                  : row.status === 3
                  ? _c("ly-status", [_vm._v("未开始")])
                  : _c("ly-status", { attrs: { type: "warning" } }, [
                      _vm._v("已暂停")
                    ])
              ]
            }
          },
          {
            key: "show_status_slot",
            fn: function(ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 2 },
                  on: {
                    change: function($event) {
                      return _vm.fn_changeShow($event, row.id, index)
                    }
                  },
                  model: {
                    value: row.show_center,
                    callback: function($$v) {
                      _vm.$set(row, "show_center", $$v)
                    },
                    expression: "row.show_center"
                  }
                })
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ly-action",
                  { key: Date.now() },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "users",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("参与用户")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "rewards",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("奖品记录")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "record",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("预约记录")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "popularize",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("推广")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "edit",
                              query: {
                                id: row.id,
                                type: 1,
                                mode: row.scene
                              }
                            })
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "edit",
                              query: {
                                id: row.id,
                                type: 2,
                                mode: row.scene
                              }
                            })
                          }
                        }
                      },
                      [_vm._v("复制")]
                    ),
                    row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.fn_handelAct(
                                  row.id,
                                  row.title,
                                  "stop"
                                )
                              }
                            }
                          },
                          [_vm._v("暂停")]
                        )
                      : _vm._e(),
                    row.status === 4
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.fn_handelAct(
                                  row.id,
                                  row.title,
                                  "start"
                                )
                              }
                            }
                          },
                          [_vm._v("开启 ")]
                        )
                      : _vm._e(),
                    row.status !== 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.fn_handelAct(
                                  row.id,
                                  row.title,
                                  "delete"
                                )
                              }
                            }
                          },
                          [_vm._v("删除 ")]
                        )
                      : _vm._e(),
                    row.status === 1 || row.status === 4
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.fn_handelAct(
                                  row.id,
                                  row.title,
                                  "end"
                                )
                              }
                            }
                          },
                          [_vm._v("结束")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("view-create", { ref: "createRef" }),
      _c("drafts", { ref: "drafts", on: { create: _vm.fn_createAct } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }