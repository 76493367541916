var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invite bg" }, [
    _c("div", { staticClass: "title" }, [_vm._v("邀请进度")]),
    _c(
      "div",
      { staticClass: "invite-body" },
      [
        _vm.rewards.length === 1
          ? [
              _c("div", { staticClass: "reward-single" }, [
                _c("div", { staticClass: "reward-single__img" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.rewards[0].cover || _vm.defaultPrizeCover,
                      alt: ""
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "reward-single__info" },
                  [
                    _c("div", { staticClass: "reward-single__name" }, [
                      _vm._v(
                        " " + _vm._s(_vm.rewards[0].title || "奖品名称") + " "
                      )
                    ]),
                    _c("view-progress", {
                      attrs: { count: _vm.rewards[0].count || 1 }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "reward-btn",
                    class: { "is-finish": _vm.stepNum >= _vm.rewards[0].count }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.stepNum >= _vm.rewards[0].count
                            ? "查看"
                            : "待解锁"
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]
          : [
              _c("div", { staticClass: "reward-multiple" }, [
                _vm._m(0),
                _c("div", { staticClass: "reward-multiple__body" }, [
                  _c(
                    "div",
                    { staticClass: "reward-multiple__left" },
                    [
                      _c("view-progress", {
                        attrs: {
                          type: "vertical",
                          rewards: _vm.rewards,
                          stepNum: _vm.stepNum
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "reward-multiple__list" },
                    _vm._l(_vm.rewards, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "reward-multiple__item mg" },
                        [
                          _c(
                            "div",
                            { staticClass: "reward-multiple__col theme-text" },
                            [_vm._v(" " + _vm._s(item.count) + "人 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "reward-multiple__col",
                              staticStyle: { flex: "1" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "reward-multiple__img reward-single__img"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.cover || _vm.defaultPrizeCover,
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "reward-multiple__info reward-single__info"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "reward-multiple__name reward-single__name"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.title || "奖品名称") +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "reward-multiple__col reward-btn",
                              class: { "is-finish": _vm.stepNum >= item.count }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.stepNum >= item.count
                                      ? "查看"
                                      : "待解锁"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ])
            ],
        _c("div", { staticClass: "invite-list__title" }, [
          _vm._v("当前已成功邀请好友 " + _vm._s(_vm.stepNum) + " 人")
        ]),
        _c(
          "div",
          { staticClass: "invite-list" },
          [
            _vm._l(_vm.inviterList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "invite-list__item" },
                [
                  item
                    ? _c("img", {
                        staticClass: "invite-list__avatar",
                        attrs: { src: item, alt: "" }
                      })
                    : _c("img", { attrs: { src: _vm.inviteAddImg, alt: "" } })
                ]
              )
            }),
            _c("img", {
              staticStyle: { height: "12px", width: "auto" },
              attrs: { src: require("@assets/images/invite_more.png"), alt: "" }
            })
          ],
          2
        )
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reward-multiple__body" }, [
      _c("div", { staticClass: "reward-multiple__left" }),
      _c("div", { staticClass: "reward-multiple__list" }, [
        _c("div", { staticClass: "reward-multiple__item" }, [
          _c("div", { staticClass: "reward-multiple__col theme-text" }, [
            _vm._v("邀请好友")
          ]),
          _c(
            "div",
            {
              staticClass: "reward-multiple__col theme-text",
              staticStyle: { flex: "1" }
            },
            [_vm._v(" 你的奖励 ")]
          ),
          _c("div", { staticClass: "reward-multiple__col theme-text" }, [
            _vm._v("领取状态")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }