<!--
 * @Author: zhanln
 * @Date: 2022-03-21 17:53:30
 * @LastEditTime: 2022-08-19 16:22:17
 * @LastEditors: zhan
 * @Description:
-->
<!--小程序海报组件
  传入小程序信息applet
-->
<template>
  <div class="applet">
    <div class="app-title">
      <img class="app-icon" :src="appletInfo.weappiconurl" alt="" v-if="appletInfo.weappiconurl" />
      {{ appletInfo.title || appletInfo.appTitle }}
    </div>
    <div class="app-desc">{{ appletInfo.des_1 }}</div>
    <img :src="
      appletInfo.cover_image || cover || require('@/assets/images/applet.png')
    " class="applet-img" />
    <div class="app-sys"><img src="@/assets/svg/xiaochengxu.svg" />小程序</div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
export default {
  props: ['applet', 'cover'],
  computed: {
    appletInfo () {
      return this.applet.msg_content ? JSON.parse(Base64.decode(this.applet.msg_content)) : this.applet
    }
  }
}
</script>

<style lang="scss" scoped>
.applet {
  width: 100%;
  // height: 260px;
  line-height: 1.6;
  border: 1px solid #dfe1e8;
  border-radius: 8px;
  background: #fff;
  padding: 8px 12px 0;
  box-sizing: border-box;

  .applet-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .app-title {
    color: #6e788a;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .app-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .app-desc {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 5px 0 8px;
    height: 22px;
  }

  .app-sys {
    color: #b4bbcc;
    padding-left: 2px;
    display: flex;
    border-top: 1px solid #eaebf2;
    line-height: 26px;
    font-size: 12px;

    img {
      margin-right: 2px;
      width: 14px;
    }

    span {
      color: #2b7cff;
      font-weight: bold;
      display: inline-block;
      transform: rotate(45deg);
      margin-right: 10px;
      font-family: cursive;
      font-size: 18px;
    }
  }
}
</style>
