<!--
 * @Author: zhanln
 * @Date: 2021-11-12 11:54:42
 * @LastEditTime: 2021-12-30 14:32:26
 * @LastEditors: Please set LastEditors
 * @Description: 消息号
-->

<template>
  <ly-dialog
    title="消息号设置"
    :visible.sync="visible"
    :setting="dialogSetting"
    class="owner-dialog"
    @open="fn_open"
    @confirm="fn_confirm"
  >
    <div class="q-info" style="margin-bottom: 16px">
      <i class="icon-warning-fill iconfont"></i>
      勾选的成员将成为消息号，在群内自动发送消息，如果勾选多个，则使用多个成员随机轮流发送消息。
    </div>

    <ly-table
      class="owner-list"
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :page-size="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #online_status="{ row }">
        <span class="q-success" v-if="row.online_status">在线</span>
        <span class="q-warning" v-else>离线</span>
      </template>
    </ly-table>
  </ly-dialog>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import lyDialog from '@/components/global/lyDialog'
export default {
  mixins: [lyTable],
  computed: {
    tableOpt () {
      return {
        layout: 'hide',
        operation: 'hide',
        showSelection: true,
        tableProps: {
          'highlight-current-row': true,
          'max-height': 300
        },
        column: [
          {
            label: '成员',
            prop: 'member_name'
          },
          {
            label: '云端状态',
            prop: 'online_status',
            slot: 'online_status'
          }
        ]
      }
    }
  },

  data () {
    return {
      IMMEDIATE: false,
      visible: false,
      dialogSetting: {
        confirmLoading: false
      },
      baseIds: []
    }
  },

  watch: {
    visible (val) {
      if (!val) {
        this.baseIds = []
        this.dialogSetting.confirmLoading = false
      }
    }
  },

  methods: {
    fn_open () {
      this.$nextTick(() => {
        for (let i = 0; i < this.tableData.length; i++) {
          this.$refs.lyTable.$refs.elTable.toggleRowSelection(this.tableData[i], this.tableData[i].isChecked)
          if (this.tableData[i].isChecked) {
            this.baseIds.push(this.tableData[i].id)
          }
        }
      })
    },

    // 确认
    async fn_confirm () {
      // 未选择
      if (this.selected.length === 0) {
        this.fn_lyMsg('info', '最少选择一个成员作为消息号')
        return false
      }

      this.dialogSetting.confirmLoading = true

      const ids = []
      for (let i = 0; i < this.selected.length; i++) {
        ids.push(this.selected[i].id)
      }

      const hasNew = this.fn_difference(ids)

      if (hasNew) {
        this.$emit('fn_changeMsgAccount', ids.join(','))
      } else {
        this.visible = false
      }
    },

    // 找不同
    fn_difference (ids) {
      let hasNew = false

      // 数组长度不同，直接提交
      if (ids.length !== this.baseIds.length) {
        return true
      }

      // 数组长度相同，判断是否有差集
      const maxArr = new Set(ids)
      const minArr = new Set(this.baseIds)

      for (const item of maxArr) {
        if (!minArr.has(item)) {
          hasNew = true
          break
        }
      }

      return hasNew
    }
  },

  components: {
    lyDialog
  }
}
</script>
