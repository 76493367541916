var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rd-users" },
    [
      _c(
        "div",
        { staticClass: "select-box", staticStyle: { padding: "0 20px" } },
        [
          _c("label", { staticStyle: { color: "#212b36" } }, [
            _vm._v("选择活动：")
          ]),
          _c(
            "el-select",
            {
              attrs: {
                size: "medium",
                filterable: "",
                placeholder: "请选择活动"
              },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.activity_id,
                callback: function($$v) {
                  _vm.activity_id = $$v
                },
                expression: "activity_id"
              }
            },
            _vm._l(_vm.activities, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "ly-tabs user-list",
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "参与用户", name: "users" } },
            [
              _vm.activeName === "users"
                ? _c("user-pane", {
                    ref: "userPane",
                    attrs: {
                      activity_id: _vm.activity_id,
                      activities: _vm.activities,
                      type: "1",
                      channelOps: _vm.channelOps
                    },
                    on: { detail: _vm.handleDetail }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "黑名单", name: "blacklist" } },
            [
              _vm.activeName === "blacklist"
                ? _c("user-pane", {
                    ref: "userPane",
                    attrs: {
                      activity_id: _vm.activity_id,
                      activities: _vm.activities,
                      type: "2",
                      channelOps: _vm.channelOps
                    },
                    on: { detail: _vm.handleDetail }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("userDetail", {
        ref: "userDetail",
        on: {
          close: function($event) {
            return _vm.$refs.userPane.queryData()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }