<!--
 * @Author: zhanln
 * @Date: 2021-11-01 17:31:38
 * @LastEditTime: 2022-01-13 20:56:30
 * @LastEditors: zhanln
 * @Description: 活动信息
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="160px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <!-- 活动名称 -->
    <el-form-item label="活动名称：" prop="title">
      <el-input
        class="act-form__input"
        v-model.trim="form.title"
        maxlength="16"
        show-word-limit
        placeholder="不对外展示，用户区分活动使用场景"
      ></el-input>
    </el-form-item>

    <!-- 活动结束时间 -->
    <el-form-item label="活动结束时间：" prop="end_time">
      <el-date-picker
        class="act-form__input"
        type="datetime"
        placeholder="请选择时间"
        v-model="form.end_time"
        :clearable="false"
        :picker-options="endAtOption"
      ></el-date-picker>
    </el-form-item>

    <!-- 涨粉账号 -->
    <el-form-item ref="wecom_user_ids" :error="wecomUserError">
      <template slot="label"
        ><span class="q-danger">*</span> 涨粉账号
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">
            1.“涨粉账号”会关联“裂变海报”的二维码<br />
            2.用户扫描海报二维码后会随机平均添加 “涨粉账号”好友。
          </div>
          <i class="iconfont icon-info-fill"></i></el-tooltip
        >：
      </template>
      <ly-emp-btn
        btnText="添加涨粉账号"
        :max="100"
        :isReal="true"
        :datafilter="true"
        :checkList="form.wecom_user_ids"
        @getEmpId="fn_getEmpId"
      ></ly-emp-btn>
    </el-form-item>

    <!-- 裂变欢迎语 -->
    <el-form-item prop="welcome_msg">
      <template slot="label"
        >裂变欢迎语
        <el-tooltip
          class="item"
          effect="light"
          placement="right"
          popper-class="q-tooltip is-light"
        >
          <div slot="content">
            <div style="margin: 24px 24px 16px; width: 472px">
              <img
                src="https://image.01lb.com.cn//uploads/wecom/22/0113/1642062047nDYL2AqK.png"
                width="472"
                alt=""
              />
              <div class="text-bold" style="margin: 16px 0 8px">
                欢迎语下发规则提示：
              </div>
              <div>
                若涨粉账号在【企业微信后台】被配置了欢迎语，这里的欢迎语将会失效，请确保所涨粉账号未被配置欢迎语。
              </div>
            </div>
          </div>
          <i class="iconfont icon-info-fill"></i></el-tooltip
        >：
      </template>
      <ly-editor
        class="act-form__editor"
        title="欢迎语"
        id="prizeWords"
        :content="form.welcome_msg"
        :insetMenu="['用户昵称']"
        :recoverContent="default_welcome_msg"
        emoji
        @getContent="fn_getWelWords"
      ></ly-editor>
      <span class="q-info text-small"> 首次添加涨粉账号，推送此裂变话术</span>
    </el-form-item>

    <!-- 二维码失效时间 -->
    <el-form-item>
      <template slot="label"
        ><span class="q-danger">*</span> 二维码失效时间
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="q-tooltip"
        >
          <div slot="content">
            活动结束后，据失效时间判断新用户是否可以进入活动页
          </div>
          <i class="iconfont icon-info-fill"></i></el-tooltip
        >：
      </template>
      <el-radio-group v-model="form.code_fail_mode">
        <el-radio :label="1">活动结束后立即失效</el-radio>
        <el-radio :label="2">自定义</el-radio>
      </el-radio-group>
      <el-form-item
        class="expire_time"
        prop="code_fail_date"
        :error="codeFailError"
        v-if="form.code_fail_mode === 2"
      >
        <el-date-picker
          type="datetime"
          placeholder="请选择时间"
          v-model="form.code_fail_date"
          popper-class="expiration-picker"
          :picker-options="failDateOption"
          :clearable="false"
          style="margin-left: 16px"
        ></el-date-picker>
      </el-form-item>
    </el-form-item>

    <!-- 裂变链接 -->
    <el-form-item>
      <template slot="label"
        ><span class="q-danger">*</span> 裂变链接：
      </template>
      <el-radio-group v-model="form.link_type">
        <el-radio :label="1">默认</el-radio>
        <el-radio :label="2">自定义</el-radio>
      </el-radio-group>
      <template v-if="form.link_type === 2">
        <el-form-item
          label-width="84px"
          prop="link_logo"
          style="margin-top: 24px"
        >
          <template slot="label"
            >主图
            <el-tooltip
              class="item"
              effect="light"
              placement="top"
              content="支持JPG、PNG格式，图片大小2M以内"
              popper-class="q-tooltip is-light"
            >
              <i class="iconfont icon-info-fill"></i></el-tooltip
            >：
          </template>
          <ly-upload
            :img="form.link_logo"
            @getImg="fn_getPoster"
            mini
          ></ly-upload>
        </el-form-item>

        <el-form-item
          label="标题："
          prop="link_title"
          label-width="84px"
          style="margin-top: 24px; width: 480px"
        >
          <el-input
            v-model="form.link_title"
            maxlength="20"
            show-word-limit
            placeholder="请输入链接标题"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="描述："
          label-width="84px"
          style="margin-top: 24px; width: 480px"
        >
          <el-input
            v-model="form.link_desc"
            maxlength="200"
            show-word-limit
            placeholder="请输入链接描述"
          ></el-input>
        </el-form-item>
      </template>
    </el-form-item>

    <!-- 自动打标签 -->
    <el-form-item label="自动打标签：">
      <div class="flex" style="height: 36px">
        <el-switch
          v-model="form.open_tags"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
        <span class="q-info" style="line-height: 20px; margin-left: 10px"
          >开启后，自动为本次活动新增用户打标签</span
        >
      </div>
      <tag-select-v2
        @getTagId="fn_getAddIds"
        :checked="form.tag_ids"
        v-if="form.open_tags === 1"
      ></tag-select-v2>
    </el-form-item>
  </el-form>
</template>

<script>
// components
import tagSelectV2 from '@/components/wecomTags'
import mapTag from '@/common/mapTag'
import { mapState } from 'vuex'
// api
import { act } from '../http'
export default {
  name: 'actBasic',

  data () {
    // 活动名称校验
    const checkTitle = async (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入活动名称'))
      } else {
        const isSame = await this.fn_checkTitleSame()
        if (!isSame) {
          callback(new Error('活动名称不允许重复'))
        } else {
          callback()
        }
      }
    }

    // 活动结束时间
    const checkEnd = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择活动结束时间'))
      } else {
        if (!this.isEnd && this.$day() > this.$day(this.form.end_time)) {
          callback(new Error('活动结束时间不能早于当前时间'))
        } else {
          callback()
        }
      }
    }

    // 二维码失效时间
    const checkFailDate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择二维码失效时间'))
      } else {
        if (this.$day(value).valueOf() < this.$day(this.form.end_time).valueOf()) {
          callback(new Error('二维码失效时间不能早于活动结束时间'))
        } else {
          callback()
        }
      }
    }

    return {
      COMM_HTTP: act,
      cardLoading: true,
      form: {
        title: '',
        end_time: null,
        wecom_user_ids: null,
        code_fail_mode: 1,
        code_fail_date: null,
        welcome_msg: '',
        link_type: 1,
        link_logo: '',
        link_title: '',
        link_desc: '',
        open_tags: 0,
        tag_ids: ''
      },
      rules: {
        title: [
          { validator: checkTitle, required: true, trigger: 'blur' }
        ],
        end_time: [
          { validator: checkEnd, required: true, trigger: 'change' }
        ],
        welcome_msg: [
          { required: true, message: '请填写裂变欢迎语', trigger: 'change' }
        ],
        code_fail_date: [
          { validator: checkFailDate, required: true, trigger: 'change' }
        ],
        link_logo: [
          { required: true, message: '请上传链接主图', trigger: 'change' }
        ],
        link_title: [
          { required: true, message: '请输入链接标题', trigger: 'change' }
        ]
      },
      codeFailError: '',
      wecomUserError: '',
      default_welcome_msg: '#用户昵称# 😊 终于等到你~，快来参与本次抽奖活动~\n\n点击下方活动链接，生成你的专属海报\n邀请好友扫码助力，即可获取抽奖机会哦~\n\n快来参加吧~'
    }
  },

  computed: {

    ...mapState({
      isEdit: state => state.lottery.isEdit,
      isCopy: state => state.lottery.isCopy,
      isEnd: state => state.lottery.isEnd,
      actId: state => state.lottery.actId,
      default_link_logo: state => state.lottery.default_link_logo,
      default_link_title: state => state.lottery.default_link_title,
      default_link_desc: state => state.lottery.default_link_desc

      //   // 购买期限，如果有且大于当前日期，则返回，否则返回null
      //   expiredTime: (state) => {
      //     const time = state.buy.expiredTime
      //     if (time) {
      //       if (new Date(time * 1000).getTime() > new Date().getTime()) {
      //         return time
      //       } else {
      //         return null
      //       }
      //     } else {
      //       return null
      //     }
      //   }
    }),

    // 结束时间选择器设置：大于当前，如果有购买，则小于购买期限
    endAtOption () {
      return {
        disabledDate: (time) => {
          if (this.expiredTime) {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 || time.getTime() > this.expiredTime * 1000
          } else {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          }
        },
        selectableRange: (() => {
          return this.fn_setEndRange()
        })()
      }
    },

    // 过期时间设置：大于结束时间（如果有）
    failDateOption () {
      const now = new Date()
      const today = new Date().setHours(0, 0, 0, 0)
      const pick = new Date(new Date(this.form.code_fail_date).setHours(0, 0, 0, 0)).getTime()
      let selectableRange = '00:00:00 - 23:59:59'
      if (pick === today) {
        selectableRange = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} - 23:59:59`
      }
      return {
        disabledDate: (time) => {
          const end_time = this.$day(this.form.end_time).valueOf()
          if (end_time) {
            return time.getTime() < end_time - 24 * 60 * 60 * 1000
          } else {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          }
        },
        selectableRange
      }
    }
  },

  watch: {
    'form.welcome_msg' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'welcome_msg',
        val: val
      })
    },
    'form.link_type' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'link_type',
        val: val
      })
    },
    'form.link_logo' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'link_logo',
        val: val
      })
    },
    'form.link_title' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'link_title',
        val: val
      })
    },
    'form.link_desc' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'link_desc',
        val: val
      })
    },
    'form.end_time': {
      // 根据结束时间设置二维码失效时间，失效时间不可小于结束时间
      handler (val) {
        if (val) {
          if (!this.form.code_fail_date || this.form.code_fail_mode === 1) {
            this.form.code_fail_date = val
          } else {
            if (new Date(val).getTime() > new Date(this.form.code_fail_date).getTime()) {
              this.form.code_fail_date = val
            }
          }

          this.$store.commit('lottery/SET_INFO', {
            type: 'end_time',
            val: val
          })
        }
      },
      immediate: true
    },

    // 活动结束失效
    'form.code_fail_mode' (val) {
      if (val === 1) {
        this.form.code_fail_date = this.form.end_time
      }
    }
    // expiredTime (val) {
    //   this.fn_initEnd()
    // }
  },

  methods: {

    // 初始化
    fn_setForm (data) {
      if (data) {
        data.welcome_msg = mapTag(data.welcome_msg)
        data.wecom_user_ids = data.wecom_user_ids ? data.wecom_user_ids.split() : null
        this.form = data
        if (this.isCopy) {
          this.form.title = ''
          this.fn_initEnd()
        }
      } else {
        this.fn_init()
      }
      setTimeout(() => {
        this.cardLoading = false
      }, 100)
    },

    // 配置默认表单
    fn_init () {
      this.fn_initEnd()
      this.form.welcome_msg = this.default_welcome_msg
      this.form.link_logo = this.default_link_logo
      this.form.link_title = this.default_link_title
      this.form.link_desc = this.default_link_desc
    },

    // 设置默认结束时间，大于当前，购买期限大于7天则设置7天，小于则使用购买期限
    fn_initEnd () {
      const expired = this.expiredTime
      const week = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)).getTime()
      let end_time = null
      if (expired) {
        const ex_time = new Date(expired * 1000).getTime()
        if (ex_time > week) {
          end_time = week
        } else {
          end_time = ex_time
        }
      } else {
        end_time = week
      }
      this.form.end_time = end_time
    },

    // 设置结束时间的时间段可选范围：例：00:00:01 - 23:59:49
    fn_setEndRange () {
      const EXPIRED = this.expiredTime ? this.expiredTime * 1000 : null
      const now = new Date()
      const today = new Date().setHours(0, 0, 0, 0)
      const pick = new Date(new Date(this.form.end_time).setHours(0, 0, 0, 0)).getTime()
      let selectableRange = '00:00:00 - 23:59:59'

      // 点击后的时间限制
      // 如果选择了今天
      if (pick === today) {
        selectableRange = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} - 23:59:59`
      }

      // 如果有购买期限并选择了购买到期那天
      if (EXPIRED) {
        const _expiredDay = new Date(new Date(EXPIRED).setHours(0, 0, 0, 0)).getTime()
        if (pick === _expiredDay) {
          // 如果恰好还是今天到期...
          if (pick === today) {
            selectableRange = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} - ${new Date(EXPIRED).getHours()}:${new Date(EXPIRED).getMinutes()}:${new Date(EXPIRED).getSeconds()}`
          } else {
            selectableRange = `00:00:00 - ${new Date(EXPIRED).getHours()}:${new Date(EXPIRED).getMinutes()}:${new Date(EXPIRED).getSeconds()}`
          }
        }
      }

      return selectableRange
    },

    // 涨粉账号选择回调
    fn_getEmpId (emps) {
      if (emps && emps.length > 0) {
        this.form.wecom_user_ids = emps
      } else {
        this.form.wecom_user_ids = null
      }

      this.wecomUserError = this.form.wecom_user_ids ? '' : '请选择涨粉账号'
    },

    // 欢迎语回调
    fn_getWelWords (val) {
      this.form.welcome_msg = val
    },

    // 新增用户标签回调
    fn_getAddIds (tags) {
      if (tags) {
        this.form.tag_ids = tags.join(',')
      } else {
        this.form.tag_ids = ''
      }
    },

    // 活动名称校验
    async fn_checkTitleSame () {
      const form = {
        title: this.form.title
      }
      if (this.isEdit && !this.isCopy) {
        form.filter_id = this.actId
      }

      const data = await this.COMM_HTTP.hasSameName(form)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.exist === 1) {
        return false
      }

      return true
    },

    // 链接图片回调
    fn_getPoster (img) {
      this.form.link_logo = img
      this.$refs.formRef.validateField('link_logo')
    },

    // 单独校验名称 - 草稿
    fn_checkTitle () {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validateField('title', err => {
          resolve(err)
        })
      })
    },

    // 表单校验，通过则返回 form
    async verify (type) {
      let formValid = true

      if (type) {
        const checkTitle = await this.fn_checkTitle()
        formValid = checkTitle === ''
      } else {
        formValid = await this.$refs.formRef.validate().catch(err => err)

        if (!this.form.wecom_user_ids) {
          this.wecomUserError = '请选择涨粉账号'
          formValid = false
        }
      }

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)
      form.end_time = this.$day(form.end_time).format('YYYY-MM-DD HH:mm:ss')
      form.code_fail_date = this.$day(form.code_fail_date).format('YYYY-MM-DD HH:mm:ss')
      form.welcome_msg = mapTag(form.welcome_msg, false)
      if (form.wecom_user_ids) {
        form.wecom_user_ids = form.wecom_user_ids.length ? form.wecom_user_ids.join(',') : form.wecom_user_ids
      } else {
        form.wecom_user_ids = ''
      }

      return form
    }

  },

  components: {
    tagSelectV2
  }
}
</script>

<style lang="scss" scoped>
.expire_time {
  display: inline-block;

  ::v-deep .el-form-item__error {
    left: 16px;
  }
}
</style>
