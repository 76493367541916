<!--
 * @Descripttion: 充值弹窗组件
 * @version: 1.0.0
 * @Author: lw
 * @Date: 2021-03-18 11:33:29
 * @LastEditTime: 2021-12-23 15:16:21
-->
<template>
  <div class="recharge">
    <el-dialog
      title="云豆充值"
      top="20vh"
      custom-class="q-dialog"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="496px"
      @open="handleOpen"
      @close="handleClose"
    >
      <div class="recharge-box">
        <div v-if="!success">
          <div class="goods-area" v-loading="loading">
            <div
              class="good-item"
              v-for="(item, i) in goods"
              :key="item.name"
              :class="{
                active: current.id ? item.id === current.id : item.id === 1,
              }"
              @click="hangdleChose(item)"
            >
              <div class="good-name">{{ item.bean }}云豆</div>
              <div class="good-unit">
                ￥<span style="fontsize: 30px; fontweight: bold">{{
                  item.price
                }}</span>
                <del>￥{{ item.raw_price }}</del>
              </div>
              <div class="label" v-if="i === 0">热卖</div>
            </div>
          </div>
          <div class="desc">每1个云豆，可供1个账号登录云端1天。</div>
          <div class="quantity">
            购买份数：
            <el-input-number
              size="medium"
              v-model="amount"
              @change="handleChange"
              :precision="0"
              :min="1"
              :max="500"
              controls-position="right"
            ></el-input-number>
            <span class="total">共{{ total }}云豆</span>
          </div>
          <div class="wx-qr">
            <div
              class="wxpay-box"
              v-loading="payQrloading"
              element-loading-spinner="el-icon-loading"
            >
              <div class="left">
                <div v-if="netError" class="net-error">
                  <div class="error-desc">网络错误</div>
                  <span class="recharge-btn" @click="getPaymentCode"
                    >点击刷新</span
                  >
                </div>
                <img class="wxpay-qr" v-if="qr" :src="qr" />
              </div>
              <div class="wx-pay-desc">
                <div class="flex">
                  <img
                    class="wx-pay-icon"
                    src="@assets/images/wechat_pay.webp"
                    alt="wx"
                  />
                  <span>微信扫码支付</span>
                </div>
                <div class="amount">
                  ￥
                  <span>{{ totalPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 充值成功界面 -->
        <div class="success-box" v-else>
          <img class="succ-icon" src="@assets/svg/paySucc.svg" alt="" />
          <div class="p1">充值成功</div>
          <div class="p2">
            你已成功充值<span style="color: #faad14">{{ succAmount }}云豆</span>
          </div>
          <div class="p3">云豆余额：{{ beanBalance }}</div>
          <el-button round type="primary" size="medium" @click="reset"
            >马上去登录</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import qs from 'qs'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false,
      loading: false,
      payQrloading: false,
      goods: [], // 商品列表
      netError: false,
      qr: '',
      amount: 1, // 购买套餐数量
      timer: null,
      total: 0,
      totalPrice: 0,
      qrTime: null, // 第一次轮询的时间
      current: {}, // 当前选中的套餐
      success: false, // 购买成功标记
      succAmount: 0,
      beanBalance: 0
    }
  },
  watch: {
    value (n) {
      this.visible = n
    }
  },
  mounted () {
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  methods: {
    handleOpen () {
      this.loading = true
      this.axios.get('buyList').then((data) => {
        this.loading = false
        this.goods = data || []
        this.current = data[1]
        this.hangdleChose(data[1])
      })
    },
    /**
     * @Descripttion: 获取云豆余额
     * @param {*}
     * @return {*}
     */
    getBeanBalance () {
      this.axios.get('beanBalance', null, { a: 1 }).then((data) => {
        this.beanBalance = data.bean || 0
      })
    },
    // 获取合计购买金额
    getAmount () {
      return this.axios.get('amount', {
        params: { count: this.amount, id: this.current.id }
      })
    },
    handleClose () {
      clearTimeout(this.timer)
      this.$emit('input', false)
      Object.assign(this.$data, this.$options.data())
    },
    // 修改购买数量回调
    async handleChange (newVal, oldVal) {
      this.getPaymentCode()
      const { amount } = await this.getAmount()
      this.tween(oldVal * this.current.bean, newVal * this.current.bean, 'total')
      this.tween(this.totalPrice, amount || 0, 'totalPrice', 2)
    },
    // 选择套餐回调
    async hangdleChose (item) {
      this.amount = 1
      this.current = item
      this.getPaymentCode()
      const { amount } = await this.getAmount()
      this.tween(this.total, this.amount * item.bean, 'total')
      this.tween(this.totalPrice, amount || 0, 'totalPrice', 2)
    },
    /**
     * @Descripttion: 获取付款码
     * @param {*}
     * @return {*}
     */
    async getPaymentCode () {
      clearTimeout(this.timer)
      // trade_type（购买类型,必填,amount(数量,必填),bid(套餐ID,必填)
      const params = {
        trade_type: 2, // [1.应用购买 2. 云豆]
        amount: this.amount,
        bid: this.current.id
      }
      this.payQrloading = true
      try {
        const res = await this.axios.post('paymentCode', qs.stringify(params), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        this.payQrloading = false
        if (res.code_url) {
          this.netError = false
          this.qr = await this.util.qrCode(res.code_url)
          this.queryResult(res.out_trade_no)
        } else {
          this.netError = true
        }
      } catch (error) {
        this.payQrloading = false
        this.netError = true
      }
    },
    /**
     * @Descripttion: 查询充值结果
     * @param {out_trade_no:订单号（必填）}
     * @return {*}
     */
    // eslint-disable-next-line
    queryResult (out_trade_no) {
      // if (this.qrTime && (Date.now() - this.qrTime > 15 * 1000)) {
      //   // 获得充值结果超时
      //   // this.$message.info('获取充值结果超时，请稍后查询充值记录')
      //   return false
      // }
      const params = {
        out_trade_no
      }
      this.timer = setTimeout(() => {
        // 记录第一次轮询的时间
        !this.qrTime && (this.qrTime = Date.now())
        // eslint-disable-next-line
        this.axios.post('paymentOrder', qs.stringify(params), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(({ trade_state, get_amount, trade_state_desc }) => {
          // eslint-disable-next-line
          if (trade_state === 1) {
            this.getBeanBalance()
            this.success = true
            // eslint-disable-next-line
            this.succAmount = get_amount
          } else if (trade_state === 2 || trade_state === 3 || trade_state === 4) {
            this.$message.error(trade_state_desc)
          } else {
            this.queryResult(out_trade_no)
          }
        })
      }, 3000)
    },
    tween (startValue, endValue, field, fiexd) {
      var vm = this
      function animate () {
        if (vm.$tween.update()) {
          requestAnimationFrame(animate)
        }
      }
      new this.$tween.Tween({ tweeningValue: startValue })
        .to({ tweeningValue: endValue }, 300)
        .onUpdate(function () {
          vm[field] = this._object.tweeningValue.toFixed(fiexd || 0)
        })
        .start()
      animate()
    },
    // 重置状态
    reset () {
      this.visible = false
      Object.assign(this.$data, this.$options.data())
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.goods-area {
  display: flex;
  justify-content: space-between;
  height: 110px;
  .good-item {
    width: 214px;
    border: 2px solid #eaebf2;
    border-radius: 9px;
    text-align: left;
    padding: 20px 24px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    &.active {
      background: rgba(250, 173, 20, 0.06);
      border: 2px solid rgba(250, 173, 20, 0.3);
    }
    .good-name,
    .good-unit,
    .good-original {
      margin: 3px 0;
    }
    .good-name {
      font-size: 16px;
      font-weight: bold;
    }
    .good-unit {
      font-size: 30px;
      color: #faad14;
      del {
        font-size: 14px;
        color: #BFBFBF;
        margin-left: 8px;
      }
    }
    .good-original {
      font-size: 14px;
      color: #EAEBF2;
    }
    .label {
      position: absolute;
      top: -12px;
      left: -1px;
      width: 48px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 11px 11px 11px 0;
      background: #f55945;
      color: #fff;
    }
  }
}
.desc {
  font-size: 12px;
  color: #6e788a;
  margin-top: 15px;
}
.quantity {
  padding: 5px 0 15px 0;
  .total {
    color: #faad14;
    margin-left: 12px;
  }
}
.wx-qr {
  border: 1px solid #eaebf2;
  border-radius: 8px;
  height: 128px;
  .wx-pay-icon {
    width: 20px;
    margin-right: 5px;
  }
  .amount {
    font-size: 20px;
    color: #faad14;
    margin: 0 3px;
    span {
      font-size: 30px;
    }
  }
  .wxpay-box {
    margin: 8px auto 0;
    padding: 2px;
    box-sizing: border-box;
    overflow: hidden;
    align-items: center;
    .left {
      width: 45%;
      float: left;
      position: relative;
    }
  }
  .wx-pay-desc {
    float: left;
    padding-top: 25px;
    margin-left: 15px;
  }
  .wxpay-qr {
    width: 110px;
    height: 110px;
    float: right;
  }
  .net-error {
    margin: auto;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    width: 110px;
    height: 110px;
    position: absolute;
    right: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error-desc {
    color: #212b36;
    margin-top: 22px;
  }
  .recharge-btn {
    display: inline-block;
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: #2b7cff;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
}
.success-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .succ-icon {
    width: 72px;
    height: 72px;
    margin-top: 30px;
  }
  .p1 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 20px 0;
  }
  .p2 {
    margin-bottom: 5px;
  }
  .el-button {
    margin-top: 25px;
  }
}
::v-deep {
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #f7f8fc;
  }
  .el-input-number--medium {
    width: 138px;
  }
  .el-loading-spinner i {
    color: #7c7e82;
    font-size: 20px;
  }
}
</style>
