var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading"
        }
      ],
      staticClass: "agrSetting"
    },
    [
      _c("div", { staticClass: "agrSetting-item" }, [
        _c("div", { staticClass: "agrSetting-title" }, [
          _vm._v("接入账号管理")
        ]),
        _c("div", { staticClass: "agrSetting-form__lable" }, [
          _c("div", { staticClass: "flex flex-start agrSetting-flex" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "div",
                  { staticClass: "agrSetting-form__tip" },
                  [
                    _vm._v(
                      " 1、选择该账号分管的员工账号，选择后切换多个企微员工账号进行会话管理；"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2、请保证分管的员工账号云端账号登录且保持在线的状态。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 3、接收群聊消息需要先将微号下的群聊云端托管后才可以正常接收新消息。"
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "q-primary",
                        staticStyle: { padding: "0" },
                        attrs: { type: "text" },
                        on: { click: _vm.fn_jumpToGroup }
                      },
                      [_vm._v("群托管>>")]
                    )
                  ],
                  1
                ),
                _c("ly-robot-btn", {
                  staticStyle: { margin: "12px 0" },
                  attrs: { robotIds: _vm.robotIds, visibleIds: _vm.visibleIds },
                  on: { callback: _vm.fn_getRobots }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.type !== "new" || _vm.total,
                        expression: "type !== 'new' || total"
                      }
                    ],
                    staticClass: "has-table"
                  },
                  [
                    _vm.type !== "new" && !_vm.total
                      ? _c("div", { staticClass: "flex flex-column" }, [
                          _c("img", {
                            attrs: {
                              src: require("@assets/svg/default/no_data.svg"),
                              alt: ""
                            }
                          }),
                          _c("span", { staticClass: "q-danger" }, [
                            _vm._v("请选择企微账号")
                          ])
                        ])
                      : _vm._e(),
                    _c("ly-table", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total,
                          expression: "total"
                        }
                      ],
                      ref: "lyTable",
                      staticClass: "act-list",
                      attrs: {
                        data: _vm.tableData,
                        option: _vm.tableOpt,
                        page: _vm.page,
                        pageSize: _vm.per_page,
                        total: _vm.total
                      },
                      on: {
                        searchChange: _vm.searchChange,
                        sizeChange: _vm.handleSizeChange,
                        sortChange: _vm.handleSortChange,
                        currentChange: _vm.handleCurrentChange,
                        selectionChange: _vm.handleSelectionChange
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "group_num",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c("el-image", {
                                    staticClass: "create-avatar",
                                    attrs: { src: row.avatar, fit: "cover" }
                                  }),
                                  _c("span", { staticClass: "robot-name" }, [
                                    _vm._v(" " + _vm._s(row.name) + " ")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "account_num",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.status === 2
                                ? _c("span", { staticClass: "q-success" }, [
                                    _vm._v("在线")
                                  ])
                                : _c("span", { staticClass: "q-danger" }, [
                                    _vm._v("离线")
                                  ])
                            ]
                          }
                        },
                        {
                          key: "operation",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "operation-btn",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.fn_removeRobot(row)
                                    }
                                  }
                                },
                                [_vm._v("移除")]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm.type !== "new"
        ? _c(
            "div",
            { staticClass: "agrSetting-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.fn_verify }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
      _vm._v("企微账号：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }