<!--
 * @Author: zhanln
 * @Date: 2021-11-17 16:58:54
 * @LastEditTime: 2022-01-12 17:25:22
 * @LastEditors: zhanln
 * @Description: 奖品预览
-->

<template>
  <div class="reward-drawer" :class="{ 'is-top': reward_focus }">
    <div class="reward-drawer__mask" v-if="reward_focus"></div>
    <div class="reward-drawer__box" :class="{ show: reward_focus }">
      <div class="reward-drawer__title">奖品详情</div>
      <!-- 自定义 -->
      <template v-if="reward_type === 1">
        <div class="reward-drawer__body">
          <div class="reward-drawer__detail" v-html="diy_content"></div>
          <div class="reward-drawer__img">
            <img :src="diy_img" alt="" v-if="diy_img" />
          </div>
        </div>
      </template>

      <!-- 兑换码 -->
      <template v-if="reward_type === 2">
        <div class="reward-drawer__body">
          <div class="reward-drawer__detail" v-html="code_rule"></div>
        </div>
        <div class="reward-drawer__code">SJFHEAML</div>
        <div class="reward-drawer__btn">点击复制兑换码</div>
      </template>

      <!-- 表单 -->
      <template v-if="reward_type === 3">
        <div class="reward-drawer__body">
          <div class="reward-drawer__detail" v-html="form_rule"></div>
        </div>
        <div class="reward-drawer__btn">
          去填写表单，领取奖品 <i class="el-icon-d-arrow-right"></i>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// store
import { mapState } from 'vuex'
export default {
  name: 'taskMobileRewardDetail',

  computed: {
    ...mapState({
      reward_focus: state => state.lottery.reward_focus,
      reward_type: state => state.lottery.reward_type,
      diy_content: state => state.lottery.diy_content,
      diy_img: state => state.lottery.diy_img,
      code_rule: state => state.lottery.code_rule,
      form_rule: state => state.lottery.form_rule
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
.reward-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  &.is-top {
    z-index: 99;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100%;
    z-index: 1;
    background-color: #fff9f3;
    border-radius: 20px 20px 0 0;
    transition: 0.4s ease-in-out;

    &.show {
      bottom: 0;
    }
  }

  &__body {
    background-color: #fffcfa;
    margin: 16px;
    padding: 16px;
    color: #454d5b;
    min-height: 186px;
    max-height: 300px;
    overflow-y: auto;
    box-sizing: border-box;
    word-break: break-all;
    color: #ae1c07;
  }

  &__title {
    @include flex;
    color: #ae1c07;
    font-weight: 500;
    font-size: 16px;
    margin-top: -2px;
    height: 40px;
    background: url("https://image.01lb.com.cn//uploads/wecom/21/0805/16281295053k54EYvt.png")
      no-repeat top center;
    background-size: 200px;
  }

  &__img {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 50%;
    }
  }

  &__code {
    background-color: #f9f9f9;
    border-radius: 22px;
    height: 44px;
    line-height: 44px;
    margin: 10px 16px;
    padding: 0 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ae1c07;
  }

  &__btn {
    background: linear-gradient(90deg, #ff685f, #ff3f25);
    border-radius: 22px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin: 16px;
  }
}
</style>
