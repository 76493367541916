var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "page-warp"
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "applet-wrap applet-box ly-shadow" }, [
        _vm.appletInfo
          ? _c("div", { staticClass: "applet-info" }, [
              _c("div", { staticClass: "applet-name" }, [
                _c("img", {
                  staticClass: "applet-name__icon",
                  attrs: { src: _vm.appletInfo.head_image, alt: "" }
                }),
                _vm._v(" " + _vm._s(_vm.appletInfo.nick_name) + " ")
              ]),
              _c("div", { staticClass: "applet-container" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "applet-list" }, [
                    _c("div", { staticClass: "applet-list__title" }, [
                      _vm._v("当前版本")
                    ]),
                    _c("div", { staticClass: "applet-list__item" }, [
                      _c("div", { staticClass: "applet-list__info" }, [
                        _c("div", { staticClass: "applet-list__label" }, [
                          _vm._v("AppId：")
                        ]),
                        _vm._v(" " + _vm._s(_vm.appletInfo.app_id) + " ")
                      ]),
                      _c("div", { staticClass: "applet-list__info" }, [
                        _c("div", { staticClass: "applet-list__label" }, [
                          _vm._v("认证状态：")
                        ]),
                        _vm.appletInfo.verify_type === 1
                          ? _c(
                              "span",
                              { staticClass: "applet-list__text q-success" },
                              [
                                _c("i", { staticClass: "el-icon-success" }),
                                _vm._v(" 已认证 ")
                              ]
                            )
                          : _c(
                              "span",
                              { staticClass: "applet-list__text q-warning" },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-info-fill"
                                }),
                                _vm._v(" 未认证 ")
                              ]
                            )
                      ]),
                      _c("div", { staticClass: "applet-list__info" }, [
                        _c("div", { staticClass: "applet-list__label" }, [
                          _vm._v("版本号：")
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.vers.release_version || "-") + " "
                        )
                      ]),
                      _c("div", { staticClass: "applet-list__info" }, [
                        _c("div", { staticClass: "applet-list__label" }, [
                          _vm._v("上线时间：")
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.vers.publish_time
                                ? _vm
                                    .$day(_vm.vers.publish_time * 1000)
                                    .format("YYYY-MM-DD HH:mm")
                                : "-"
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "applet-list__title",
                        staticStyle: { "margin-top": "24px" }
                      },
                      [_vm._v(" 待上线版本 ")]
                    ),
                    _c("div", { staticClass: "applet-list__item" }, [
                      _c("div", { staticClass: "applet-list__info" }, [
                        _c("div", { staticClass: "applet-list__label" }, [
                          _vm._v("版本号：")
                        ]),
                        _vm._v(" " + _vm._s(_vm.vers.audit_ver || "-") + " ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "applet-list__info" },
                        [
                          _c("div", { staticClass: "applet-list__label" }, [
                            _vm._v("审核状态：")
                          ]),
                          _vm.vers.audit_status === 1
                            ? _c(
                                "span",
                                { staticClass: "applet-list__text q-success" },
                                [
                                  _vm._v(" 审核中 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "小程序微信官方审核中，预约需要1-2个工作日，请耐心等候",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-info-fill"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm.vers.audit_status === 2
                            ? _c(
                                "span",
                                { staticClass: "applet-list__text q-danger" },
                                [
                                  _vm._v(" 审核未通过 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.vers.audit_msg,
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-info-fill"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm.vers.audit_status === 3
                            ? _c(
                                "span",
                                { staticClass: "applet-list__text q-warning" },
                                [
                                  _vm._v(" 审核延后 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "服务商系统升级，请耐心等待审核",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-info-fill"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm.vers.audit_status === 4
                            ? _c(
                                "span",
                                { staticClass: "applet-list__text q-warning" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "服务商发布上线中，请耐心等待",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-s-promotion"
                                      })
                                    ]
                                  ),
                                  _vm._v(" 待发布 ")
                                ],
                                1
                              )
                            : [_vm._v("-")]
                        ],
                        2
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("div", [
                      _c("img", {
                        staticClass: "applet-code",
                        attrs: { src: _vm.appletInfo.qr_code_url, alt: "" }
                      })
                    ]),
                    _vm.appletInfo.qr_code_url
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", round: "" },
                            on: { click: _vm.handleDownload }
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-download" }),
                            _vm._v(" 下载")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        !_vm.appletInfo && !_vm.loading
          ? _c(
              "div",
              { staticClass: "applet-auth" },
              [
                _c("img", {
                  staticClass: "applet-auth__icon",
                  attrs: {
                    src: require("@assets/images/app_empty.png"),
                    alt: ""
                  }
                }),
                _c("div", { staticClass: "q-info text-small" }, [
                  _vm._v("暂未授权小程序")
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "applet-auth__btn",
                    attrs: { round: "", type: "primary" },
                    on: { click: _vm.fn_auth }
                  },
                  [_vm._v("授权小程序")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "applet-box ly-shadow" }, [
      _c("div", { staticClass: "q-bg is-primary" }, [
        _vm._v(" 1、确保小程序和企业微信、视频号认证主体保持一致；"),
        _c("br"),
        _vm._v(
          " 2、使用微信小程序管理员帐号扫码进行授权，授权过程中请勾选所有权限以确保小程序功能完整性；"
        ),
        _c("br"),
        _vm._v(
          " 3、扫码授权后，等待3秒过程中不可提前关闭页面，授权成功后将自动跳转回此页面；"
        ),
        _c("br"),
        _vm._v(
          " 4、授权完成后，请登录企业微信后台，将该小程序绑定在企微公众平台中，否则无法与企微客户进行匹配；"
        ),
        _c("br"),
        _vm._v(
          " 5、小程序发版上线后，将覆盖原小程序内容，建议使用全新小程序。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }