<template>
  <div class="mobile mobile-poster">
    <div class="mobile-container" style="height: 100%">
      <div class="mobile-poster-bg" v-if="posterUrl">
        <img :src="posterUrl" alt="" />
      </div>
      <div class="mobile-poster-edit" style="overflow: hidden">
        <vue-draggable-resizable
          class="dragWrap dragAvatar"
          :x="Number(avatar_x)"
          :y="Number(avatar_y)"
          :w="Number(avatar_width)"
          :h="Number(avatar_height)"
          :parent="true"
          :handles="['br']"
          :lock-aspect-ratio="true"
          @dragging="(x, y) => onDrag(x, y, 'avatar')"
          @resizing="(x, y, w, h) => onResize(w, h, 'avatar')"
          v-if="avatar_status === 1"
        >
          <div
            class="poster-avatar"
            :class="{ 'is-radius': avatar_sharp == 2 }"
          >
            头像
          </div>
        </vue-draggable-resizable>
        <vue-draggable-resizable
          class="dragWrap"
          :x="Number(nickname_x)"
          :y="Number(nickname_y)"
          :w="name_width"
          :grid="[1, 1]"
          h="auto"
          :parent="true"
          :handles="[]"
          :lock-aspect-ratio="true"
          @dragging="(x, y) => onDrag(x, y, 'nickname')"
          v-if="nickname_status === 1"
        >
          <div
            :style="{
              color: nickname_color,
              'font-size': nickname_size + 'px',
              'text-align': nickname_align,
            }"
          >
            用户昵称
          </div>
        </vue-draggable-resizable>
        <vue-draggable-resizable
          class="dragWrap dragQrcode"
          :x="Number(qrcode_x)"
          :y="Number(qrcode_y)"
          :w="Number(qrcode_width)"
          :h="Number(qrcode_height)"
          :parent="true"
          :handles="['br']"
          :lock-aspect-ratio="true"
          @dragging="(x, y) => onDrag(x, y, 'qrcode')"
          @resizing="(x, y, w, h) => onResize(w, h, 'qrcode')"
        >
          <img src="~@assets/images/contact_me_qr.png" />
        </vue-draggable-resizable>
      </div>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'mobiPoster',
  data () {
    return {
      name_length: 6,
      name_width: 0
    }
  },
  computed: {
    posterUrl () {
      const poster = this.$store.getters['groupfission/GET_POSTER'].img_url
      if (poster.length > 0) {
        return poster[0].url
      } else {
        return ''
      }
    },
    avatar_status () {
      return this.$store.getters['groupfission/GET_POSTER'].avatar_status
    },
    avatar_x () {
      return this.$store.getters['groupfission/GET_POSTER'].avatar_x
    },
    avatar_y () {
      return this.$store.getters['groupfission/GET_POSTER'].avatar_y
    },
    avatar_width () {
      return this.$store.getters['groupfission/GET_POSTER'].avatar_width
    },
    avatar_height () {
      return this.$store.getters['groupfission/GET_POSTER'].avatar_height
    },
    avatar_sharp () {
      return this.$store.getters['groupfission/GET_POSTER'].avatar_sharp
    },
    nickname_status () {
      return this.$store.getters['groupfission/GET_POSTER'].nickname_status
    },
    nickname_x () {
      return this.$store.getters['groupfission/GET_POSTER'].nickname_x
    },
    nickname_y () {
      return this.$store.getters['groupfission/GET_POSTER'].nickname_y
    },
    nickname_size () {
      return this.$store.getters['groupfission/GET_POSTER'].nickname_size
    },
    nickname_align () {
      return this.$store.getters['groupfission/GET_POSTER'].nickname_align
    },
    nickname_color () {
      return this.$store.getters['groupfission/GET_POSTER'].nickname_color
    },
    qrcode_x () {
      return this.$store.getters['groupfission/GET_POSTER'].qrcode_x
    },
    qrcode_y () {
      return this.$store.getters['groupfission/GET_POSTER'].qrcode_y
    },
    qrcode_width () {
      return this.$store.getters['groupfission/GET_POSTER'].qrcode_width
    },
    qrcode_height () {
      return this.$store.getters['groupfission/GET_POSTER'].qrcode_height
    }
  },

  watch: {
    nickname_size (val) {
      if (val) {
        // 控制宽度，防止撑出屏幕
        const _w = this.nickname_x + val * this.name_length
        if (_w > 345) {
          this.fn_setForm(345 - val * this.name_length, 'nickname_x')
        }
        this.name_width = val * this.name_length
      }
    }
  },

  created () {
    const size = this.$store.getters['groupfission/GET_POSTER'].nickname_size
    this.name_width = size * this.name_length
  },

  methods: {

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: 'poster',
        name: type,
        val: val
      })
    },

    onDrag (x, y, name) {
      this.fn_setForm(x, `${name}_x`)
      this.fn_setForm(y, `${name}_y`)
      if (name === 'nickname') {
        if (this.nickname_align === 'center') {
          this.fn_setForm((x + this.nickname_size * this.name_length / 2).toFixed() - 2, `${name}_offsetX`)
        } else if (this.nickname_align === 'right') {
          this.fn_setForm((x + this.nickname_size * this.name_length).toFixed() - 2, `${name}_offsetX`)
        } else {
          this.fn_setForm(0, `${name}_offsetX`)
        }
      }
    },
    onResize (w, h, name) {
      this.fn_setForm(w, `${name}_width`)
      this.fn_setForm(h, `${name}_height`)
    }
  },
  components: {
    [VueDraggableResizable.name]: VueDraggableResizable
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";
.mobile-poster {
  width: 345px;
  height: 613.64px;
}
</style>
