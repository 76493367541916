<template>
  <div class="mobile">
    <div class="mobile-topbar">
      <div>{{ $day().format('HH:mm')}}</div>
      <div class="flex">
        <i class="iconfont icon-a-CellularConnection"></i>
        <i class="iconfont icon-Wifi"></i>
        <i class="iconfont icon-Battery"></i>
      </div>
    </div>
    <div class="mobile-titlebar">
      <i class="el-icon-arrow-left"></i>
      <div class="flex flex-column">
        <div class="reward-tit">{{ pageTitle }}</div>
      </div>
      <i class="el-icon-more"></i>
    </div>
    <div class="mobile-container is-reward">
      <div
        class="mobile-reward"
        :style="{ backgroundColor: bannerType === 1 ? defaultColor : cusColor }"
      >
        <div class="mobile-banner">
          <img :src="defaultBanner" alt="" v-if="bannerType === 1" />
          <template v-else>
            <img :src="cusBanner" alt="" v-if="cusBanner" />
            <div class="mobile-banner__default" v-else>请上传活动页首图</div>
          </template>
          <div class="mobile-banner__title" v-if="bannerType === 1">
            <el-input
              type="textarea"
              v-model="basicTitle"
              resize="none"
            ></el-input>
          </div>
        </div>

        <div class="mobile-carousel">
          <img
            src="https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg"
            alt=""
            class="avatar"
          />
          <span class="text">XX 1分钟前 领走奖品</span>
        </div>

        <div class="mobile-rule" @click="ruleShow = true">活动规则</div>

        <div class="mobile-body">
          <div class="mobile-share">
            <div class="mobile-share__btn">再邀请1人 领取奖品</div>
            <div class="mobile-share__time">
              活动倒计时：{{ countDown.d > 0 ? countDown.d : 0 }}天{{
                countDown.h > 0 ? countDown.h : 0
              }}时{{ countDown.m > 0 ? countDown.m : 0 }}分{{
                countDown.s > 0 ? countDown.s : 0
              }}秒00
            </div>
          </div>

          <div class="mobile-prize">
            <div class="mobile-prize__header">兑换奖品</div>

            <div class="mobile-prize__list">
              <div class="item">
                <div class="cover">
                  <img :src="firstCover || defaultCover" alt="" />
                </div>
                <div class="info">
                  <div class="title">{{ firstTitle || "请输入奖品名称" }}</div>
                  <div class="desc" v-if="firstUnreal && firstUnreal > 0">
                    <span>已有{{ firstUnreal }}人领走奖品</span>
                    <div class="avatars">
                      <img
                        src="https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg"
                        alt=""
                        v-for="(k, v) in firstUnreal > 3 ? 3 : firstUnreal"
                        :key="v"
                      />
                    </div>
                  </div>
                  <div class="foot">
                    <div class="progress">
                      <div class="progress-bar">
                        <div class="progress-bar__inner"></div>
                      </div>
                      <div class="progress-text">
                        {{ firstCount }}/{{ firstCount }}
                      </div>
                    </div>
                    <div class="btn is-success">领奖</div>
                  </div>
                </div>
              </div>
              <div class="item" v-if="secondStatus">
                <div class="cover">
                  <img :src="secondCover || defaultCover" alt="" />
                </div>
                <div class="info">
                  <div class="title">{{ secondTitle || "请输入奖品名称" }}</div>
                  <div class="desc" v-if="secondUnreal && secondUnreal > 0">
                    <span>已有{{ secondUnreal }}人领走奖品</span>
                    <div class="avatars">
                      <img
                        src="https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg"
                        alt=""
                        v-for="(item, index) in secondUnreal > 3
                          ? 3
                          : secondUnreal"
                        :key="index"
                      />
                    </div>
                  </div>
                  <div class="foot">
                    <div class="progress">
                      <div class="progress-bar">
                        <div
                          class="progress-bar__inner"
                          :style="{
                            width: (firstCount / secondCount) * 100 + '%',
                          }"
                        ></div>
                      </div>
                      <div class="progress-text">
                        {{ firstCount }}/{{ secondCount }}
                      </div>
                    </div>
                    <div class="btn">还差{{ secondCount - firstCount }}人</div>
                  </div>
                </div>
              </div>
              <div class="item" v-if="thirdStatus">
                <div class="cover">
                  <img :src="thirdCover || defaultCover" alt="" />
                </div>
                <div class="info">
                  <div class="title">{{ thirdTitle || "请输入奖品名称" }}</div>
                  <div class="desc" v-if="thirdUnreal && thirdUnreal > 0">
                    <span>已有{{ thirdUnreal }}人领走奖品</span>
                    <div class="avatars">
                      <img
                        src="https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg"
                        alt=""
                        v-for="(item, index) in thirdUnreal > 3
                          ? 3
                          : thirdUnreal"
                        :key="index"
                      />
                    </div>
                  </div>
                  <div class="foot">
                    <div class="progress">
                      <div class="progress-bar">
                        <div
                          class="progress-bar__inner"
                          :style="{
                            width: (firstCount / thirdCount) * 100 + '%',
                          }"
                        ></div>
                      </div>
                      <div class="progress-text">
                        {{ firstCount }}/{{ thirdCount }}
                      </div>
                    </div>
                    <div class="btn">还差{{ thirdCount - firstCount }}人</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mobile-rank">
            <div class="mobile-rank__tabs">
              <div class="item is-active">排行榜</div>
              <div class="item">好友助力榜</div>
            </div>

            <div class="mobile-rank__body">
              <div class="mobile-rank__top">
                <div class="item" v-for="(item, index) in topList" :key="index">
                  <div class="header">
                    <img
                      :src="item.avatar"
                      alt=""
                      class="avatar"
                      :class="[index === 1 ? 'is-top' : '', 'is-' + index]"
                    />
                    <img
                      src="~@assets/images/top2.webp"
                      alt=""
                      :class="['tip', 'is-' + index]"
                      v-if="index === 0"
                    />
                    <img
                      src="~@assets/images/top1.webp"
                      alt=""
                      :class="['tip', 'is-' + index]"
                      v-if="index === 1"
                    />
                    <img
                      src="~@assets/images/top3.webp"
                      alt=""
                      :class="['tip', 'is-' + index]"
                      v-if="index === 2"
                    />
                  </div>
                  <div class="name">{{ item.name }}</div>
                  <div class="count">已邀{{ item.count }}人</div>
                </div>
              </div>
              <div class="mobile-rank__list">
                <div class="head">
                  <div class="item">
                    <div class="rank">排名</div>
                    <div class="info">昵称</div>
                    <div class="invite">邀请人数</div>
                  </div>
                </div>
                <div
                  class="item"
                  v-for="(item, index) in rankList"
                  :key="index"
                >
                  <div class="rank">{{ item.rank }}</div>
                  <div class="info">
                    <img :src="item.avatar" alt="" class="avatar" />
                    {{ item.name }}
                  </div>
                  <div class="invite">{{ item.invite }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-prizefix" :class="[isFocus ? 'is-show' : '']">
        <div class="head">领取奖品</div>
        <div class="body">
          <template v-if="type === 1">
            <div class="content" v-html="diyContent || '请输入奖品内容'"></div>
            <div class="cover" v-if="prizeCover">
              <img :src="prizeCover" alt="" />
            </div>
          </template>
          <template v-if="type === 2">
            <div class="content" v-html="codeContent" v-if="codeContent"></div>
            <div class="code">SJFHEAML</div>
            <div class="copy">点击复制兑换码</div>
          </template>
          <template v-if="type === 3">
            <div class="content" v-html="formContent" v-if="formContent"></div>
            <div class="copy" style="margin-top: 16px">
              去填写表单，领取奖品 <i class="el-icon-d-arrow-right"></i>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- 活动规则 -->
    <div class="mobile-dialog__mask" v-if="isFocus || ruleShow"></div>
    <div class="mobile-dialog" v-if="ruleShow">
      <div class="content">
        <div class="head">活动规则</div>
        <el-input type="textarea" v-model="ruleText" resize="none"></el-input>
      </div>
      <i
        class="el-icon-circle-close mobile-dialog__close"
        @click="ruleShow = false"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobiReward',

  props: {
    current: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      banner: '',
      bgColor: '',
      defaultCover: 'https://image.01lb.com.cn//uploads/wecom/21/0813/1628848634fO96xYCl.png',
      topList: [
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145726j0VgP778.jpg',
          name: 'SUSU淑芬',
          count: 88
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145745wadxhkhE.jpg',
          name: 'THESHY',
          count: 188
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg',
          name: '艾欧尼亚',
          count: 68
        }
      ],
      rankList: [
        {
          rank: 3,
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          name: '马克大菠萝',
          invite: 102
        },
        {
          rank: 4,
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145807XZyd07u6.jpg',
          name: 'Solomon',
          invite: 98
        },
        {
          rank: 5,
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/162814583254h18EKw.png',
          name: 'Aurora',
          invite: 75
        },
        {
          rank: 6,
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          name: '是翠花呀',
          invite: 50
        },
        {
          rank: 7,
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281462669V715m03.jpg',
          name: '超级厉害的狮子吖',
          invite: 30
        }
      ],
      prizeContent: '',
      ruleShow: false,
      countDown: {
        d: 0,
        h: 0,
        m: 0,
        s: 0
      }
    }
  },

  computed: {
    endTime () {
      return this.$store.getters['groupfission/GET_BASIC'].end_at
    },
    pageTitle () {
      return this.$store.getters['groupfission/GET_OTHER'].show_title
    },
    defaultBanner () {
      return this.$store.getters['groupfission/GET_DEFAULT'].banner
    },
    defaultColor () {
      return this.$store.getters['groupfission/GET_DEFAULT'].color
    },
    basicTitle () {
      return this.$store.getters['groupfission/GET_OTHER'].banner_title
    },
    bannerType () {
      return this.$store.getters['groupfission/GET_OTHER'].banner_type
    },
    cusColor () {
      return this.$store.getters['groupfission/GET_OTHER'].bg_color
    },
    cusBanner () {
      return this.$store.getters['groupfission/GET_OTHER'].banner.length > 0 ? this.$store.getters['groupfission/GET_OTHER'].banner[0].url : ''
    },
    firstTitle () {
      return this.$store.getters['groupfission/GET_TASK']('first').reward_title
    },
    firstUnreal () {
      return this.$store.getters['groupfission/GET_TASK']('first').unreal
    },
    firstCount () {
      return this.$store.getters['groupfission/GET_TASK']('first').target_count
    },
    firstCover () {
      return this.$store.getters['groupfission/GET_TASK']('first').reward_coverList.length > 0 ? this.$store.getters['groupfission/GET_TASK']('first').reward_coverList[0].url : ''
    },
    secondStatus () {
      return this.$store.getters['groupfission/GET_TASK']('second').status
    },
    secondTitle () {
      return this.$store.getters['groupfission/GET_TASK']('second').reward_title
    },
    secondUnreal () {
      return this.$store.getters['groupfission/GET_TASK']('second').unreal
    },
    secondCount () {
      return this.$store.getters['groupfission/GET_TASK']('second').target_count
    },
    secondCover () {
      return this.$store.getters['groupfission/GET_TASK']('second').reward_coverList.length > 0 ? this.$store.getters['groupfission/GET_TASK']('second').reward_coverList[0].url : ''
    },
    thirdStatus () {
      return this.$store.getters['groupfission/GET_TASK']('third').status
    },
    thirdTitle () {
      return this.$store.getters['groupfission/GET_TASK']('third').reward_title
    },
    thirdUnreal () {
      return this.$store.getters['groupfission/GET_TASK']('third').unreal
    },
    thirdCount () {
      return this.$store.getters['groupfission/GET_TASK']('third').target_count
    },
    thirdCover () {
      return this.$store.getters['groupfission/GET_TASK']('third').reward_coverList.length > 0 ? this.$store.getters['groupfission/GET_TASK']('third').reward_coverList[0].url : ''
    },
    ruleText () {
      return this.$store.getters['groupfission/GET_OTHER'].rule || '请输入活动规则'
    },
    order () {
      return this.$store.state.groupfission.currentTask
    },
    type () {
      return this.$store.getters['groupfission/GET_TASK'](this.order).reward_type
    },
    prizeCover () {
      const list = this.$store.getters['groupfission/GET_TASK'](this.order).reward_detail_imgList
      return list.length > 0 ? list[0].url : ''
    },
    diyContent () {
      return this.$store.getters['groupfission/GET_TASK'](this.order).reward_detail
    },
    codeContent () {
      return this.$store.getters['groupfission/GET_TASK'](this.order).codeRules
    },
    formContent () {
      return this.$store.getters['groupfission/GET_TASK'](this.order).formRules
    },
    isFocus () {
      return this.$store.state.groupfission.defaultFocus
    }
  },

  watch: {
    endTime: {
      handler (val) {
        if (val) {
          const endTime = this.$day(val).unix()
          const today = this.$day(new Date()).unix()
          const countDown = endTime - today
          const countTime = this.fn_setExpire(countDown)
          this.countDown = countTime
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    // 计算倒计时
    fn_setExpire (data) {
      const eTime = [86400, 3600, 60, 0]
      const time = [0, 0, 0, 0]

      let _data = data
      for (let i = 0; i < eTime.length; i++) {
        if (i === 3) {
          time[i] = _data
        } else {
          if (_data >= eTime[i]) {
            time[i] = Math.floor(_data / eTime[i])
            _data = _data % eTime[i]
          }
        }
      }

      const { 0: d, 1: h, 2: m, 3: s } = time
      return { d, h, m, s }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";
@import "@/assets/scss/utils.scss";
$--color-prize: #9b2506;

.mobile {
  &-container.is-reward {
    overflow: hidden;
    overflow-y: auto;
  }

  &-reward {
    background-image: none;
    background: none;
    padding: 0;
  }

  &-banner {
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &__default {
      @include flex;
      height: 120px;
      background-color: #ddd;
    }

    &__title {
      position: absolute;
      left: 0;
      top: 40px;
      right: 0;

      ::v-deep .el-textarea__inner {
        height: 68px;
        color: #fff;
        font-size: 22px;
        text-shadow: 0px 3px 4px rgb(238 33 16 / 80%);
        overflow: hidden;
        padding: 0;
        text-align: center;
        background-color: transparent;
        border: none;
      }
    }
  }

  &-carousel {
    position: absolute;
    top: 14px;
    left: 16px;
    height: 22px;
    border-radius: 11px;
    background-color: rgba(0, 0, 0, 0.4);
    @include flex;
    color: #fff;
    font-size: 12px;
    padding-left: 1px;
    padding-right: 16px;

    .avatar {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }

  &-rule {
    position: absolute;
    right: 12px;
    top: 12px;
    height: 24px;
    width: 73px;
    @include flex;
    border-radius: 12px;
    border: 1px solid rgba(#fff, 0.5);
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
  }

  &-share {
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 32px;

    &__btn {
      @include flex;
      color: $--color-prize;
      font-size: 18px;
      font-weight: 500;
      height: 52px;
      width: 290px;
      background: url("https://image.01lb.com.cn//uploads/wecom/21/0804/16280735685477fz2r.png")
        no-repeat top center;
      background-size: 100%;
    }

    &__time {
      position: absolute;
      right: 16px;
      top: 0;
      background-color: rgba(#fff5e3, 0.8);
      border-radius: 12px;
      font-weight: 400;
      height: 24px;
      font-size: 12px;
      padding: 0 8px;
      color: $--color-prize;
      @include flex;

      &:after {
        position: absolute;
        content: "";
        right: 16px;
        bottom: -12px;
        border: 6px solid transparent;
        border-top-color: rgba(#fff5e3, 0.8);
      }
    }
  }

  &-body {
    padding: 16px;
  }

  &-prize {
    position: relative;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 16px;
    padding-top: 40px;

    &__header {
      position: absolute;
      top: -3px;
      left: 0;
      @include flex;
      color: $--color-prize;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      width: 100%;
      background: url("https://image.01lb.com.cn//uploads/wecom/21/0805/16281295053k54EYvt.png")
        no-repeat top center;
      background-size: 200px;
    }

    &__list {
      padding: 16px;

      .item {
        height: 104px;
        display: flex;
        align-items: center;
      }

      .left {
        flex: 1;
        display: flex;
      }

      .cover {
        @include flex;
        width: 68px;
        height: 68px;
        border-radius: 8px;
        box-shadow: 0px 0px 20px 1px rgba(112, 25, 4, 0.09);
        margin-right: 12px;

        img {
          height: 80%;
        }
      }

      .info {
        flex: 1;
      }

      .title {
        color: #333;
        font-size: 16px;
        font-weight: 500;
      }

      .desc {
        display: flex;
        color: #999;
        font-size: 12px;
      }

      .avatars {
        margin-left: 4px;
        img {
          width: 16px;
          height: 16px;
          border-radius: 50%;

          &:not(:first-child) {
            margin-left: -6px;
          }
        }
      }

      .foot {
        display: flex;
        justify-content: space-between;
      }

      .btn {
        @include flex;
        font-weight: 500;
        width: 68px;
        height: 28px;
        background-image: linear-gradient(to right, #ff685f, #ff685f);
        color: #fff;
        border-radius: 16px;

        &.is-success {
          color: $--color-prize;
          background-image: linear-gradient(to right, #fec16e, #fec16e);
        }
      }

      .progress {
        display: flex;
        align-items: center;
        &-bar {
          flex: 1;
          margin-right: 8px;
          background-color: rgba(#fe4935, 0.2);
          width: 35px;
          height: 8px;
          border-radius: 4px;

          &__inner {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background-color: #fe4935;
          }
        }
      }
    }
  }

  &-rank {
    margin-top: 30px;
    &__tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        @include flex;
        height: 40px;
        width: 48%;
        background-image: linear-gradient(#fde0ba, #fde0ba);
        border-radius: 12px 12px 0 0;
        font-size: 16px;
        font-weight: 500;
        color: $--color-prize;

        &.is-active {
          background-image: none;
          background-color: #fff;
        }
      }
    }

    &__body {
      background: #fff;
      padding-top: 24px;
      overflow: hidden;
      border-radius: 0 0 10px 10px;
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 16px;

      .item {
        position: relative;
        text-align: center;
      }

      .header {
        position: relative;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 8px;
      }

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        &.is-0 {
          border: 2px solid #b1814d;
        }

        &.is-1 {
          border: 2px solid #f5c10c;
        }

        &.is-2 {
          border: 2px solid #a6a6a6;
        }

        &.is-top {
          width: 65px;
          height: 65px;
        }
      }

      .tip {
        position: absolute;
        top: -1px;
        left: 1px;
        width: 32px;

        &.is-1 {
          top: -21px;
          left: -9px;
          width: 41px;
        }

        &.is-2 {
          top: -3px;
          left: -3px;
        }
      }

      .name {
        color: #333;
        font-weight: 500;
        font-size: 15px;
      }

      .count {
        color: #fe4935;
        font-weight: 500;
      }
    }

    &__list {
      .head {
        color: #f73c34;
        font-weight: 500;
        background-color: #fef2ee;
      }

      .item {
        display: flex;
        align-items: center;
        padding: 0 16px;

        &:nth-child(odd) {
          background-color: #fef2ee;
        }

        .rank {
          width: 54px;
        }

        .info {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 8px 0;
          @include ellipsis;
        }

        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 12px;
        }

        .invite {
          width: 66px;
          color: #f73c34;
          text-align: center;
        }
      }
    }
  }

  &-prizefix {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100%;
    background-color: #fff9f3;
    border-radius: 12px 12px 0 0;
    transition: 0.25s linear;
    z-index: 1;

    &.is-show {
      bottom: 0;
    }

    .head {
      @include flex;
      color: #ae1c07;
      font-weight: 500;
      font-size: 16px;
      margin-top: -4px;
      height: 40px;
      background: url("https://image.01lb.com.cn//uploads/wecom/21/0805/16281295053k54EYvt.png")
        no-repeat top center;
      background-size: 200px;
    }

    .body {
      margin: 16px;

      ::v-deep p {
        margin: 0;
      }

      img {
        width: 50%;
      }
    }

    .content {
      padding: 16px;
      max-height: 150px;
      overflow-y: auto;
      background-color: #fff;
    }

    .cover {
      background-color: #fff;
      text-align: center;
      padding: 16px;
    }

    .code {
      height: 44px;
      @include flex;
      background-color: #fff;
      font-size: 18px;
      font-weight: 500;
      color: #ae1c07;
      margin-top: 16px;
      margin-bottom: 12px;
    }

    .copy {
      background-image: linear-gradient(#ff685f, #ff685f);
      color: #fff;
      font-weight: 16px;
      font-weight: 500;
      height: 52px;
      @include flex;
      border-radius: 22px;
    }
  }

  &-dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content {
      // position: absolute;
      // top: 100px;
      // left: 50%;
      // transform: translateX(-50%);
      width: 80%;
      min-height: 400px;
      max-height: 600px;
      background-color: #fff;
      border: 8px solid #fde0ba;
      overflow-y: auto;
      border-radius: 16px;
      box-sizing: border-box;
      z-index: 1;
    }

    &__mask {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.8);
      margin: 78px 18px 13px;
      border-radius: 0 0 35px 35px
    }

    .head {
      @include flex;
      color: #ae1c07;
      font-weight: 500;
      font-size: 16px;
      margin-top: -4px;
      height: 40px;
      background: url("https://image.01lb.com.cn//uploads/wecom/21/0805/16281295053k54EYvt.png")
        no-repeat top center;
      background-size: 200px;
      margin-bottom: 16px;
    }

    &__close {
      margin-top: 12px;
      color: #fff;
      font-size: 24px;
    }

    ::v-deep .el-textarea__inner {
      min-height: 100% !important;
      height: 320px;
      border: none !important;
      color: #ae1c07;
    }
  }
}

.reward {
  &-code {
    background-color: #f9f9f9;
    border-radius: 22px;
    height: 44px;
    line-height: 44px;
    margin: 16px;
    padding: 0 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;

    &__input {
      ::v-deep .el-textarea__inner {
        background-color: transparent;
        border: none;
        padding: 0;
        height: auto;
        max-height: 170px;
      }
      ::v-deep p {
        margin: 0;
      }
    }

    &__title {
      text-align: center;
      font-weight: 500;
      margin-bottom: 16px;
    }

    &__btn {
      background: linear-gradient(150deg, #fecd01 9%, #fb7f15 88%);
      border-radius: 22px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      margin: 16px;
    }
  }
}
</style>
