var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work ly-shadow" },
    [
      _c("el-scrollbar", { staticClass: "work-scroll" }, [
        _c(
          "div",
          { staticClass: "work-wrap" },
          [
            _c("div", { staticClass: "work-title" }, [_vm._v("功能介绍")]),
            _c(
              "div",
              {
                staticClass: "flex flex-between",
                staticStyle: { "margin-left": "12px" }
              },
              [
                _c("div", [
                  _c("div", [
                    _vm._v(
                      " 将多个企微号聚合在一个页面上进行管理，可快捷回复和管理聊天消息，无需频繁切换账号。 "
                    )
                  ])
                ]),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      round: "",
                      disabled: !_vm.workUrl
                    },
                    on: { click: _vm.fn_jumpToWork }
                  },
                  [
                    _vm._v("企微工作台 "),
                    _c("i", { staticClass: "el-icon-arrow-right" })
                  ]
                )
              ],
              1
            ),
            _c("el-image", {
              staticClass: "work-pre",
              staticStyle: { width: "1140px" },
              attrs: {
                src:
                  "https://image.01lb.com.cn//uploads/wecom/22/0407/16493182174mq1G143.png",
                "preview-src-list": [
                  "https://image.01lb.com.cn//uploads/wecom/22/0407/16493182174mq1G143.png"
                ]
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }