<!--
 * @Author: zhanln
 * @Date: 2022-03-08 16:51:44
 * @LastEditTime: 2022-04-14 18:56:54
 * @LastEditors: zhanln
 * @Description: 设置
-->

<template>
  <div class="agrSetting" v-loading="pageLoading">
    <div class="agrSetting-item">
      <div class="agrSetting-title">接入账号管理</div>
      <div class="agrSetting-form__lable">
        <div class="flex flex-start agrSetting-flex">
          <div class="left"><span class="q-danger">*</span>企微账号：</div>
          <div class="right">
            <div class="agrSetting-form__tip">
              1、选择该账号分管的员工账号，选择后切换多个企微员工账号进行会话管理；<br />
              2、请保证分管的员工账号云端账号登录且保持在线的状态。<br />
              3、接收群聊消息需要先将微号下的群聊云端托管后才可以正常接收新消息。<el-button
                type="text"
                style="padding: 0"
                class="q-primary"
                @click="fn_jumpToGroup"
                >群托管>></el-button
              >
            </div>
            <ly-robot-btn
              style="margin: 12px 0"
              @callback="fn_getRobots"
              :robotIds="robotIds"
              :visibleIds="visibleIds"
            ></ly-robot-btn>
            <div class="has-table" v-show="type !== 'new' || total">
              <div class="flex flex-column" v-if="type !== 'new' && !total">
                <img src="@assets/svg/default/no_data.svg" alt="" />
                <span class="q-danger">请选择企微账号</span>
              </div>
              <ly-table
                class="act-list"
                ref="lyTable"
                v-show="total"
                :data="tableData"
                :option="tableOpt"
                :page="page"
                :pageSize="per_page"
                :total="total"
                @searchChange="searchChange"
                @sizeChange="handleSizeChange"
                @sortChange="handleSortChange"
                @currentChange="handleCurrentChange"
                @selectionChange="handleSelectionChange"
              >
                <!-- 列插槽 -->
                <template #group_num="{ row }">
                  <div class="flex">
                    <el-image
                      class="create-avatar"
                      :src="row.avatar"
                      fit="cover"
                    ></el-image>
                    <span class="robot-name">
                      {{ row.name }}
                    </span>
                  </div>
                </template>

                <template #account_num="{ row }">
                  <span class="q-success" v-if="row.status === 2">在线</span>
                  <span class="q-danger" v-else>离线</span>
                </template>

                <template #operation="{ row }">
                  <el-button
                    class="operation-btn"
                    type="text"
                    @click="fn_removeRobot(row)"
                    >移除</el-button
                  >
                </template>
              </ly-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="agrSetting-item"> -->
    <!-- <div class="agrSetting-title">群聊提醒</div> -->
    <!-- <div>开启全部消息提醒，有新消息时，即可进行提醒</div> -->
    <!-- <div class="agrSetting-form__lable">
        <el-radio v-model="group_remind" :label="1" class="agrSetting-radio"
          >开启全部消息提醒，有新消息时，即可进行提醒</el-radio
        >
        <el-radio v-model="group_remind" :label="2" class="agrSetting-radio"
          >仅有消息@我时，进行提醒</el-radio
        >
      </div> -->
    <!-- </div> -->
    <div class="agrSetting-footer" v-if="type !== 'new'">
      <el-button round @click="$emit('close')">取消</el-button>
      <el-button round type="primary" @click="fn_verify">确定</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import lyTable from '@/components/mixins/ly-table.js'
import { agrs } from '../http.js'
export default {
  name: 'setting',

  mixins: [lyTable],

  props: {
    type: {
      type: String,
      default: ''
    },
    tableHeight: {
      type: Number,
      default: 200
    }
  },

  data () {
    return {
      pageLoading: true,
      IMMEDIATE: false,
      per_page: 999,
      id: null,
      visibleIds: null,
      group_remind: 1
    }
  },

  computed: {
    ...mapState({
      loadingData: state => state.global.loading > 0 // 大于0时loading
    }),
    tableOpt () {
      return {
        levitate: false,
        layout: 'hide',
        operationWidth: 100,
        tableProps: {
          'max-height': this.tableHeight
        },
        column: [
          {
            label: '企微号',
            prop: 'group_num',
            slot: 'group_num'
          },
          {
            label: '状态',
            prop: 'account_num',
            slot: 'account_num',
            width: 200
          }
        ]
      }
    },
    robotIds () {
      return this.tableData.map(item => +item.user_id || +item.id).join()
    }
  },

  methods: {

    async fn_init (id) {
      this.id = id
      this.pageLoading = true
      const data = await agrs.detail({ id })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.group_remind = data.group_remind || this.group_remind
      this.tableData = data.robot
      this.total = data.robot.length
      this.visibleIds = this.tableData.map(item => item.user_id).join()

      this.pageLoading = false
    },

    // 移除企微号
    fn_removeRobot (row) {
      const rowId = row.id || row.user_id
      const index = this.tableData.findIndex(item => {
        const itemId = item.id || item.user_id
        return itemId === rowId
      })
      this.tableData.splice(index, 1)
      this.total -= 1
    },

    // 选择企微号
    fn_selectRobot () {
      this.$refs.selectRobotRef.fn_init(this.tableData)
    },

    // 选择企微号回调
    fn_getRobots (list) {
      this.tableData = list
      this.total = list.length
    },

    // 提交校验
    fn_verify (type = true) {
      if (this.tableData.length === 0) {
        this.fn_lyMsg('error', '请选择企微账号')
        return false
      }

      const robotsIds = this.tableData.map(item => +item.user_id || +item.id)

      const form = {
        ids: robotsIds.join(),
        notice: this.group_remind
      }

      if (type) {
        this.fn_submit(form)
        return
      }

      return form
    },

    // 提交表单
    async fn_submit (form) {
      const data = await agrs.setting({
        id: this.id,
        group_remind: form.notice,
        user_ids: form.ids
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.fn_lyMsg('success', '修改成功！')
      this.$emit('refresh')
    },

    // 跳转
    fn_jumpToGroup () {
      const routeData = this.$router.resolve({
        path: '/cloudService/group/hosting'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/utils.scss";
.agrSetting-item {
  margin-bottom: 40px;
}

.agrSetting-title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-left: 12px;
  margin-bottom: 24px;

  &::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 12px;
    top: 50%;
    left: 0;
    background-color: $--color-primary;
    border-radius: 2.5px;
    transform: translateY(-50%);
  }
}

.agrSetting-form__lable {
  padding-left: 32px;
}

.agrSetting-flex {
  .left {
    margin-right: 12px;
  }
  .right {
    width: 600px;
  }
}

.agrSetting-form__tip {
  padding: 12px;
  background-color: #f7f8fc;
  border-radius: 8px;
  line-height: 1.8;
  box-sizing: border-box;
}

.agrSetting-footer {
  border-top: 1px solid #dfe1e8;
  margin-top: 24px;
  padding-top: 24px;
  text-align: right;
}

.agrSetting-radio {
  display: block;
  margin: 18px 0;
}

.has-table {
  min-height: 200px;
}

.create-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.robot-name {
  flex: 1;
}
</style>
