<template>
  <div @click="handleFocus">
    <div :id="refName" class="wangeditor_base" :ref="refName"></div>
  </div>
</template>
<script>
// 文档：https://www.wangeditor.com/doc/
import E from 'wangeditor'
import emoji from '@/components/emoji'
import qiniuUpload from '@/common/mixins/qiniuUpload'
export default {
  name: 'wang-editor',
  data () {
    return {
      ttt: '',
      editor: null,
      editorCont: '',
      msgBox: null,
      isFocus: false
    }
  },
  props: {
    refName: {
      type: String,
      default: 'wangEditorRef'
    },
    setCont: {
      type: Function,
      default: () => {
        console.log('未注册设置内容(setCont)事件')
      }
    },
    getCont: [Function, null],
    content: {
      type: String,
      default: ''
    },
    menus: {
      type: Array,
      default: () => {
        return null
      }
    },
    excludeMenus: {
      type: Array,
      default: () => {
        return null
      }
    },
    getText: [Function, null],
    placeholder: {
      type: String,
      default: ''
    },
    cusMenus: {
      type: Object,
      default: () => {
        return {}
      }
    },
    wecomEmoji: {
      type: Boolean,
      default: false
    },
    pasteIgnoreImg: {
      type: Boolean,
      default: true
    },
    uploadImg: {
      type: Boolean,
      default: false
    },
    uploadImgAccept: {
      type: Array,
      default: () => {
        return null
      }
    },
    uploadVideoAccept: {
      type: Array,
      default: () => {
        return null
      }
    },
    uploadVideo: {
      type: Boolean,
      default: false
    },
    showFullScreen: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 200
    }
  },

  mixins: [qiniuUpload],

  computed: {
    editContent () {
      return this.content
    }
  },
  watch: {
    editContent: {
      immediate: true,
      handler (html) {
        if (this.editorCont !== html) {
          this.editorCont = html
          this.editor && this.editor.txt.html(html)
        }
        if (this.editor) {
          this.fn_getEditText()
        }
      }
    }
  },
  mounted () {
    this.editor = new E(`#${this.refName}`)

    // 设置编辑器基本信息
    this.setEditor()

    if (this.editorCont) {
      this.editor.txt.html(this.editorCont)
      this.fn_getEditText()
    }

    document.addEventListener('click', this.handleClick, true)
  },
  methods: {

    fn_getEditText () {
      if (this.$listeners.getText) {
        this.$emit('getText', this.editor.txt.text())
      }
    },

    handleClick (e) {
      if (this.$refs[this.refName]) {
        const isSelf = this.$refs[this.refName].contains(e.target)
        if (!isSelf) {
          if (this.$listeners.eBlur) {
            this.$emit('eBlur')
          }
        }
      }
    },

    setEditor () {
      const that = this

      // 传入的菜单
      if (this.menus) {
        this.editor.config.menus = this.menus
      }

      this.editor.config.height = this.height

      // 剔除不需要的菜单
      if (this.excludeMenus) {
        this.editor.config.excludeMenus = this.excludeMenus
      }

      // 取消全屏
      this.editor.config.showFullScreen = this.showFullScreen

      // 编辑器的事件，每次改变会获取其html内容
      this.editor.config.onchange = (html) => {
        this.editorCont = html
        this.setCont(html, this.editor.txt.text())
        if (this.getText) {
          this.getText(this.editor.txt.text())
        }
      }

      // 取消自动 focus
      this.editor.config.focus = false

      // placeholder
      this.editor.config.placeholder = this.placeholder

      this.editor.config.showMenuTooltips = false

      // 配置emoji
      if (this.wecomEmoji) {
        this.editor.config.emotions = [
          {
            title: 'emoji', // tab 的标题
            type: 'emoji', // 'emoji' / 'image'
            content: emoji.wecomEmoji.split(/\s/)
          }
        ]
      }

      // 过滤复制的图片
      this.editor.config.pasteIgnoreImg = this.pasteIgnoreImg

      // 自定义上传图片
      this.editor.config.uploadImgAccept = this.uploadImgAccept || ['jpg', 'jpeg', 'png']
      if (this.uploadImg) {
        this.editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
          // resultFiles 是 input 中选中的文件列表
          // insertImgFn 是获取图片 url 后，插入到编辑器的方法

          // 上传图片，返回结果，将图片插入到编辑器中
          // insertImgFn(imgUrl)
          await this.qiniuUpload2(
            resultFiles,
            (path, id) => {
              insertImgFn(path[0].path)
            },
            null,
            {
              types: this.editor.config.uploadImgAccept,
              size: '10MB'
            }
          )
        }
      }

      // 自定义上传视频
      this.editor.config.uploadVideoAccept = this.uploadVideoAccept || ['mp4']
      if (this.uploadVideo) {
        this.editor.config.customUploadVideo = async (resultFiles, insertVideoFn) => {
          // resultFiles 是 input 中选中的文件列表
          // insertVideoFn 是获取视频 url 后，插入到编辑器的方法

          // 上传视频，返回结果，将视频地址插入到编辑器中
          await this.qiniuUpload2(
            resultFiles[0],
            (path, id) => {
              insertVideoFn(path)
            },
            null,
            {
              types: this.editor.config.uploadVideoAccept,
              size: '10mb'
            }
          )
        }
      }

      // 链接校验
      this.editor.config.linkCheck = function (text, link) {
        if (link !== '') {
          var reg = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i')
          if (!reg.test(link)) {
            if (that.msgBox) {
              that.msgBox.close()
            }
            that.msgBox = that.$message.info('请输入正确的网址(例如 https://www.01lb.com.cn/)')
          } else {
            return true
          }
        }
      }

      this.editor.create()
    },

    // 提示框
    fn_msg (type, text) {
      if (this.msgBox) {
        this.msgBox.close()
      }
      this.msgBox = this.$message[type](text)
    },

    handleFocus () {
      if (this.$listeners.eFocus) {
        this.$emit('eFocus')
      }
    }
  },

  destroyed () {
    document.removeEventListener('click', this.handleClick, true)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.wangeditor_base {
  position: relative;
  z-index: 0;
  line-height: 1.6;

  ::v-deep .w-e-toolbar {
    border: none !important;
    border-bottom: 1px solid #dfe1e8 !important;
    background-color: #f7f8fc !important;
    height: 36px;
    display: flex;
    align-items: center;
    .w-e-menu {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      &:hover {
        background: #fff;
      }
    }
  }

  ::v-deep .w-e-text-container {
    border: none !important;
    .placeholder {
      font-size: 14px;
    }
  }
  ::v-deep .w-e-text {
    font-size: 1em !important;
    padding-top: 12px;
    p {
      font-size: 1em !important;
      min-height: 1em;
      margin: 0;
    }

    a {
      color: $--color-primary;
    }
  }

  ::v-deep .w-e-cusmenu {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;

    &:hover {
      cursor: pointer;
      background-color: #f6f6f6;
      color: #2b7cff;
    }
  }

  ::v-deep .w-e-menu {
    &:hover i {
      color: #2b7cff;
    }
  }
}
</style>
