var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "q-dialog",
      attrs: {
        visible: _vm.visible,
        width: "500px",
        title: _vm.title,
        "before-close": _vm.fn_handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.popLoading,
              expression: "popLoading"
            }
          ],
          ref: "popFormRef",
          attrs: {
            size: "medium",
            "label-width": "95px",
            rules: _vm.popFormRules,
            model: _vm.form,
            "element-loading-text": _vm.loadingText,
            "element-loading-spinner": "el-icon-loading"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "渠道名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.status === 3,
                  placeholder: "请输入渠道名称",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "span",
            { staticStyle: { color: "#6E788A", "margin-right": "12px" } },
            [_vm._v("保存后自动生成渠道海报和二维码")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "", loading: _vm.popLoading },
              on: {
                click: function($event) {
                  return _vm.fn_subForm("popFormRef")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }