<template>
  <div class="page-warp">
    <layout-table
      :pageTotal="total"
      @pageChange="pageChange"
      :loading="loading"
      :tableCenter="true"
    >
      <!-- toolBar -->
      <template #toolBar>
        <el-button
          round
          type="primary"
          @click="fn_addRole"
          icon="iconfont icon-plus"
          >添加角色</el-button
        >
      </template>

      <!-- table -->
      <el-table :data="roleList">
        <el-table-column prop="name, mask" label="角色名称" width="200">
          <template slot-scope="scope">
            <div class="flex" style="line-height: 1">
              <span
                class="qy-tag qy-tag--primary"
                style="width: 24px"
                v-if="scope.row.type === 1"
                >官</span
              >
              <span>{{ scope.row.name }}</span>
              <span
                class="qy-tag qy-tag--warning qy-tag--mini"
                style="margin-left: 6px; margin-right: 0"
                v-if="scope.row.is_default === 1"
                >默认</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="角色描述" min-width="200">
          <template slot-scope="scope">
            <template v-if="scope.row.desc">
              <el-tooltip
                effect="dark"
                placement="top-start"
                v-if="scope.row.desc.length > 24"
              >
                <div slot="content" style="width: 400px">
                  {{ scope.row.desc }}
                </div>
                <span>{{ scope.row.desc.slice(0, 24) }}...</span>
              </el-tooltip>
              <span v-else>{{ scope.row.desc }}</span>
            </template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="mask"
          label="数据范围"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.mask === 0">个人</template>
            <template v-else-if="scope.row.mask === 1">全局</template>
            <template v-else-if="scope.row.mask === 2">部门</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="wecom_users_count"
          label="员工数量"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="scope.row.wecom_users_count === 0"
              @click="fn_jumpToEmpList(scope.row.id)"
            >
              {{ scope.row.wecom_users_count }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="200"
          class-name="operation-cell"
        >
          <template slot-scope="scope">
            <!-- 编辑：自定义角色可编辑 -->
            <el-button
              @click="fn_editRole(scope.row.id)"
              class="ly-gap-button"
              type="text"
              v-if="scope.row.type === 3"
              >编辑</el-button
            >

            <!-- 删除：自定义角色 -->
            <template v-if="scope.row.type === 3">
              <!-- 且员工数量 = 0 || 未被设置为默认角色时，可删除 -->
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                popper-class="q-tooltip"
                v-if="
                  scope.row.wecom_users_count > 0 || scope.row.is_default === 1
                "
              >
                <div slot="content">
                  <template v-if="scope.row.wecom_users_count > 0">
                    当前角色已被使用，不可删除！
                  </template>
                  <template v-else-if="scope.row.is_default === 1">
                    当前角色已被设为默认角色，不可删除！
                  </template>
                </div>
                <span class="q-info ly-gap-button ly-disable">删除</span>
              </el-tooltip>

              <el-button
                type="text"
                class="ly-gap-button"
                v-else
                @click="fn_deleteRole(scope.row.id)"
              >
                删除
              </el-button>
            </template>

            <!-- 设为默认：内置员工角色及数据范围为 “个人和全局” 的自定义角色可设置为默认 -->
            <template
              v-if="
                scope.row.is_default === 0 &&
                ((scope.row.type !== 1 && scope.row.mask !== 2) ||
                  (scope.row.type === 1 && scope.row.mask === 0))
              "
            >
              <el-button
                type="text"
                class="ly-gap-button"
                @click="fn_defaultRole(scope.row.name, scope.row.id)"
                >设为默认</el-button
              >
            </template>

            <!-- 添加人员：数据范围为 “部门” 不可添加人员 -->
            <ly-emp-btn
              v-if="scope.row.mask !== 2 && scope.row.id !== 4"
              btnText="添加人员"
              :title="'添加' + scope.row.name"
              :diabledList="[scope.row.id, 4]"
              ready-text="原角色将会被覆盖"
              class="ly-gap-button ly-emp-btn"
              :showSelect="false"
              ref="lyEmpRef"
              @getEmpId="getEmpId(scope.row.id, ...arguments)"
            ></ly-emp-btn>
            <!-- <emp-select
              @getEmpId="getEmpId(scope.row.id, ...arguments)"
              type="button"
              style="display: inline-block"
              btn-text="添加人员"
              :title="'添加' + scope.row.name"
              class="ly-gap-button"
              :disable-role-id="[scope.row.id, 4]"
              ready-text="原角色将会被覆盖"
              :close-clear="true"
              v-if="scope.row.mask !== 2 && scope.row.id !== 4"
            ></emp-select> -->
          </template>
        </el-table-column>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>
    </layout-table>
  </div>
</template>

<script>
import layoutTable from '@/components/layout/lyTable'
import mx_role from '@/components/mixins/role'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: true,
      roleList: [],
      page: 1,
      per_page: 10,
      total: 0,
      msgBox: null,
      selectTitle: ''
    }
  },
  mixins: [mx_role],
  created () {
    this.fn_getRoleList()
  },
  computed: {
    ...mapGetters({
      isSuperManager: 'permission/GET_SUPERMANAGER'
    })
  },
  methods: {
    // 获取角色列表
    async fn_getRoleList () {
      this.loading = true
      const data = await this.axios.get('rolesList', {
        params: {
          page: this.page,
          per_page: this.per_page
        }
      })
      this.roleList = data.data
      this.total = data.total
      this.loading = false
    },

    // 分页监听
    pageChange (val) {
      this.page = val
      this.fn_getRoleList()
    },

    // 添加角色
    fn_addRole () {
      this.$router.push('editrole')
    },

    // 编辑角色
    fn_editRole (id) {
      this.$router.push({
        path: 'editrole',
        query: {
          id
        }
      })
    },

    // 删除角色
    fn_deleteRole (id) {
      this.$lyConfirm({
        title: '确认要删除这个角色吗？',
        text: '此操作将永久删除该角色, 是否继续'
      }).then(async () => {
        await this.axios.delete('roles', {
          params: {
            id: id
          }
        })
        this.fn_getRoleList()
        if (this.msgBox) {
          this.msgBox.close()
        }
        this.msgBox = this.$message.success('删除角色成功')
      }).catch(() => { })
    },

    // 设置默认角色
    async fn_defaultRole (name, id) {
      await this.axios.post('rolesDefault', {
        id: id
      })

      if (this.msgBox) {
        this.msgBox.close()
      }
      this.msgBox = this.$message.success(`已将 ${name} 设置为默认角色`)
      this.fn_getRoleList()
    },

    // 跳转员工列表
    fn_jumpToEmpList (id) {
      this.$router.push({
        path: '/enterprise/employees/list',
        query: {
          role_id: id
        }
      })
    },

    // 添加人员
    async getEmpId (id, ids) {
      const data = await this.axios.post('rolesAddUser', {
        role_id: id,
        user_ids: ids
      })

      if (data) {
        this.$message.success('添加人员成功！')
        this.fn_getRoleList()
      }

      this.$refs.lyEmpRef.selected = []
    }
  },

  destroyed () {
    if (this.msgBox) {
      this.msgBox.close()
    }
  },

  components: {
    layoutTable
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ly-table-main {
    overflow-y: hidden;
  }
  .ly-tool {
    margin-top: 0;
  }
}

.ly-emp-btn {
  display: inline-block;

  ::v-deep .el-button {
    margin: 0;
    padding: 0 !important;
    height: 20px !important;
    line-height: 20px !important;
    border: none;

    &:hover {
      background-color: transparent;
    }
  }
}
</style>
