var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "批量下载二维码", visible: _vm.visible, width: "480px" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function(i, v) {
          return _c("div", { key: v, staticClass: "item" }, [
            _vm._v(
              " " +
                _vm._s(i.liveCodeName) +
                "（" +
                _vm._s(i.groupName || "默认分组") +
                "） "
            )
          ])
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                size: "medium",
                loading: _vm.btnLoading
              },
              on: { click: _vm.fn_download }
            },
            [_vm._v(_vm._s(_vm.btnLoading ? "下载中..." : "确定"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }