var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-footer" },
    [
      _c(
        "el-button",
        {
          attrs: {
            type: "primary",
            round: "",
            loading: _vm.btnLoading,
            icon: _vm.isEnd ? "" : "iconfont icon-send-fill",
            disabled: _vm.isEnd
          },
          on: {
            click: function($event) {
              return _vm.$emit("submit")
            }
          }
        },
        [_vm._v(_vm._s(_vm.isEnd ? "活动已结束" : "发布活动"))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }