var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-mobile",
    {
      staticClass: "ly-mobile",
      attrs: { showWel: false, hasFooter: false, sub: "", title: "活动详情" }
    },
    [
      _c("div", { staticClass: "lottery" }, [
        _c("div", { staticClass: "lottery-header" }, [
          _vm._v(" 幸运抽奖活动 ")
        ]),
        _c(
          "div",
          { staticClass: "lottery-rule", on: { click: _vm.fn_openPop } },
          [_vm._v(" 规则 ")]
        ),
        _c("div", { staticClass: "lottery-body" }, [
          _c(
            "div",
            { staticClass: "lottery-body__countdown" },
            [
              _vm.countDown
                ? [
                    _vm._v(" 开奖时间: "),
                    _c(
                      "div",
                      { staticClass: "lottery-body__countdown-count" },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm._v(_vm._s(_vm.countDown.d))
                        ]),
                        _c("span", { staticClass: "text" }, [_vm._v("天")]),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(_vm._s(_vm.countDown.h))
                        ]),
                        _c("span", { staticClass: "text" }, [_vm._v("时")]),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(_vm._s(_vm.countDown.m))
                        ]),
                        _c("span", { staticClass: "text" }, [_vm._v("分")]),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(_vm._s(_vm.countDown.s))
                        ]),
                        _c("span", { staticClass: "text" }, [_vm._v("秒")])
                      ]
                    )
                  ]
                : [_vm._v(" 请设置开奖时间 ")]
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "lottery-prize" },
            [
              _c(
                "swiper",
                {
                  staticClass: "lottery-prize__swiper",
                  attrs: { options: _vm.swiperOption }
                },
                _vm._l(_vm.prizeList, function(item, index) {
                  return _c(
                    "swiper-slide",
                    { key: index, staticClass: "lottery-prize__swiper--item" },
                    [
                      _c("div", { staticClass: "lottery-prize__item" }, [
                        _c("div", { staticClass: "lottery-prize__cover" }, [
                          _c("img", {
                            staticClass: "lottery-prize__item--img",
                            attrs: { src: item.cover || _vm.defaultPrizeCover }
                          })
                        ]),
                        _c("div", { staticClass: "lottery-prize__name" }, [
                          _vm._v(_vm._s(item.title || "奖品名称"))
                        ])
                      ])
                    ]
                  )
                }),
                1
              ),
              _c("img", {
                staticClass: "lottery-prize__mask left",
                attrs: { src: require("@assets/images/mask_left.png") }
              }),
              _c("img", {
                staticClass: "lottery-prize__mask right",
                attrs: { src: require("@assets/images/mask_right.png") }
              })
            ],
            1
          ),
          _c("div", { staticClass: "lottery-btn" }, [
            _vm._v(_vm._s(_vm.btn_title) + " "),
            _c("img", {
              staticClass: "lottery-btn__arrow is-left is-move",
              attrs: {
                src: require("@assets/images/btn_left.png"),
                mode: "heightFix"
              }
            }),
            _c("img", {
              staticClass: "lottery-btn__arrow is-right is-move",
              attrs: {
                src: require("@assets/images/btn_right.png"),
                mode: "heightFix"
              }
            })
          ]),
          _c("div", { staticClass: "lottery-barrage" }, [
            _c("img", {
              staticClass: "lottery-barrage__avatar",
              attrs: {
                src:
                  "https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg",
                alt: ""
              }
            }),
            _c("span", { staticClass: "lottery-barrage__name" }, [
              _vm._v("无与伦比")
            ]),
            _c("span", { staticClass: "lottery-barrage__msg" }, [
              _vm._v("正在参与活动")
            ])
          ])
        ]),
        _c("div", { staticClass: "lottery-inviter" }, [
          _c("div", { staticClass: "lottery-inviter__tab" }, [
            _c(
              "div",
              {
                staticClass: "lottery-inviter__tab--item",
                class: { active: _vm.tabIndex === 1 },
                on: {
                  click: function($event) {
                    _vm.tabIndex = 1
                  }
                }
              },
              [_vm._v("邀请记录")]
            ),
            _c(
              "div",
              {
                staticClass: "lottery-inviter__tab--item",
                class: { active: _vm.tabIndex === 2 },
                on: {
                  click: function($event) {
                    _vm.tabIndex = 2
                  }
                }
              },
              [_vm._v("奖品记录")]
            )
          ]),
          _c(
            "div",
            {
              staticClass: "lottery-inviter__body",
              class: [_vm.tabIndex === 1 ? "is-left" : "is-right"]
            },
            [
              _vm.tabIndex === 1
                ? _c("div", { staticClass: "invite" }, [
                    _c("div", { staticClass: "invite-item is-head" }, [
                      _c("div", { staticClass: "invite-left" }, [
                        _vm._v(" 用户 ")
                      ]),
                      _c("div", { staticClass: "invite-right" }, [
                        _vm._v(" 预约状态 ")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "invite-list" },
                      _vm._l(_vm.inviterList, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "invite-item" },
                          [
                            _c("div", { staticClass: "invite-left" }, [
                              _c("img", {
                                staticClass: "invite-avatar",
                                attrs: { src: item.avatar }
                              }),
                              _c(
                                "span",
                                { staticClass: "invite-name text-ellipsis" },
                                [_vm._v(_vm._s(item.nickname))]
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "invite-right invite-status" },
                              [
                                item.status === 1
                                  ? _c("span", { staticClass: "q-info" }, [
                                      _vm._v("未预约")
                                    ])
                                  : item.status === 2
                                  ? _c("span", { staticClass: "q-success" }, [
                                      _vm._v("预约成功")
                                    ])
                                  : _c("span", { staticClass: "q-warning" }, [
                                      _vm._v("取消预约")
                                    ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "prizes" },
                    _vm._l(_vm.prizes, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "prizes-item" },
                        [
                          _c("div", { staticClass: "prizes-left" }, [
                            _c("img", {
                              staticClass: "prizes-cover",
                              attrs: { src: item.cover, alt: "" }
                            }),
                            _c("div", { staticClass: "prizes-info" }, [
                              _c("div", { staticClass: "prizes-name" }, [
                                _vm._v(_vm._s(item.title))
                              ]),
                              _c("div", { staticClass: "prizes-time" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .$day(new Date())
                                      .format("YYYY-MM-DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ]),
                          item.status === 1
                            ? _c("div", { staticClass: "prizes-btn" }, [
                                _vm._v("查看")
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
            ]
          )
        ])
      ]),
      _c("pop-rule", { ref: "popRuleRef" }),
      _c("pop-prize", { ref: "popPrizeRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }