import axios from '@/common/ajax.js'
const { stringify } = require('qs')
// 表单列表
export const finance = {
  reqQuery: params => axios.post('/scrm-api/form/business/list', params),
  reqCreateList: params => axios.get('/scrm-api/form/business/creator/list'), // 创建人列表
  reqDelete: id => axios.delete(`/scrm-api/form/business/id/${id}`) // 删除行数据
}

// 表单
export const tables = {
  createTables: params => axios.post('/scrm-api/form/business/create', params), // 创建表单
  putTables: params => axios.put(`/scrm-api/form/business/id/${params.id}`, params), // 更新表单
  getOneCom: formId => axios.get(`/scrm-api/form/business/formId/${formId}/component/list`), // 获取单个表单组件
  getOneForm: id => axios.get(`/scrm-api/form/business/id/${id}`) // 表单详情
}

// 草稿箱
export const drafts = {
  reqQuery: params => axios.post('/scrm-api/form/business/list', params),
  reqDelete: params => axios.delete(`/scrm-api/form/business/id/${params}`), // 删除行数据
}

// 详情列表
export const fDetails = {
  reqQuery: params => axios.post(`/scrm-api/form/commit/business/formId/${params.id}/list`, params),
  getOneDetail: params => axios.get(`/scrm-api/form/commit/business/formId/${params.formId}/requestId/${params.requestId}/details`), // 获取单个表单组件
  putDetail: params => axios.put(`/scrm-api/form/commit/business/formId/${params.formId}/requestId/${params.requestId}/update`, params.list),
  getActivityList: params => axios.get(`/scrm-api/form/commit/business/formId/${params.formId}/activity/list`),
  exportDeatil: params => axios.post(`/scrm-api/form/commit/business/from/${params.formId}/export?sync=true`, params)// 导出
}