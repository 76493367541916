<template>
  <el-dialog
    title="添加群发内容"
    :visible.sync="visible"
    custom-class="add-content"
    width="560px"
  >
    <div class="type-list">
      <span
        class="item"
        :class="{ active: type === 2001 }"
        @click="handleClick(2001)"
        >文本</span
      >
      <span
        class="item"
        :class="{ active: type === 2002 }"
        @click="handleClick(2002)"
        >图片</span
      >
      <span
        class="item"
        :class="{ active: type === 2005 }"
        @click="handleClick(2005)"
        >链接</span
      >
      <span
        class="item"
        :class="{ active: type === 2010 }"
        @click="handleClick(2010)"
        >文件</span
      >
      <span
        class="item"
        :class="{ active: type === 2004 }"
        @click="handleClick(2004)"
        >视频</span
      >
      <span
        class="item"
        :class="{ active: type === 2013 }"
        @click="handleClick(2013)"
        >小程序</span
      >
    </div>
    <!-- 文本 -->
    <el-input
      class="text-input"
      v-if="type === 2001"
      type="textarea"
      placeholder="请输入文本内容"
      maxlength="1000"
      show-word-limit
      v-model="text.msg_content"
    >
    </el-input>
    <div class="content" v-else>
      <!-- 图片 -->
      <template v-if="type === 2002">
        <el-upload
          class="uploader"
          ref="pic"
          action="#"
          accept=".png, .jpg"
          :http-request="(opts) => requestQiniu(opts, 'pic')"
          :limit="1"
          :show-file-list="false"
        >
          <div v-if="pic.msg_content" class="thum">
            <img :src="pic.msg_content" class="img" fit="cover" />
            <i class="el-icon-closeplus" @click.stop="handleRemove('pic')"
              ><i class="el-icon-close"></i
            ></i>
          </div>
          <template v-else>
            <div class="upload-box flex">
              <i class="iconfont icon-plus avatar-uploader-icon"></i>
              <span>上传图片</span>
            </div>
            <div class="desc" @click.stop>支持JPG、PNG格式，图片小于10M</div>
          </template>
        </el-upload>
      </template>
      <!-- 视频 -->
      <template v-else-if="type === 2004">
        <el-upload
          class="uploader"
          ref="video"
          action="#"
          accept=".mp4"
          :http-request="(opts) => requestQiniu(opts, 'video')"
          :limit="1"
          :show-file-list="false"
        >
          <div v-if="video.href" class="thum">
            <video
              :src="video.href"
              ref="videoDom"
              class="img"
              @loadedmetadata="getDuration"
            />
            <i class="el-icon-closeplus" @click.stop="handleRemove('video')"
              ><i class="el-icon-close"></i
            ></i>
          </div>
          <template v-else>
            <div class="upload-box upload-video-box">
              <i class="iconfont icon-plus avatar-uploader-icon"></i>
              <span class="warp-desc">从本地上传视频</span>
            </div>
            <div class="desc" @click.stop>支持MP4格式，文件小于10MB</div>
          </template>
        </el-upload>
      </template>
      <!-- 文件 -->
      <template v-else-if="type === 2010">
        <el-upload
          class="uploader"
          ref="file"
          action="#"
          :http-request="(opts) => requestQiniu(opts, 'file')"
          :limit="1"
          :show-file-list="false"
        >
          <div v-if="file.msg_content" class="file-temp thum">
            <span class="file-title">{{ file.title }}</span>
            <img class="file-icon" src="@/assets/images/doc.png" />
            <i class="el-icon-closeplus" @click.stop="handleRemove('file')"
              ><i class="el-icon-close"></i
            ></i>
          </div>
          <template v-else>
            <div class="upload-box flex">
              <i class="iconfont icon-plus avatar-uploader-icon"></i>
              <span>上传文件</span>
            </div>
            <div class="desc" @click.stop>文件大小不超过10MB</div>
          </template>
        </el-upload>
      </template>

      <template v-else-if="type === 2005">
        <el-form
          size="medium"
          ref="linkForm"
          class="link-form"
          :show-message="false"
          :rules="linkRules"
          :model="link"
          label-width="0px"
        >
          <el-form-item prop="href">
            <el-input
              v-model="link.href"
              placeholder="请输入链接"
              v-lydebounce="['input', getUrlInfo]"
            ></el-input>
          </el-form-item>
          <el-form-item prop="title">
            <el-input
              show-word-limit
              maxlength="50"
              v-model="link.title"
              placeholder="链接标题"
            ></el-input>
          </el-form-item>
          <el-form-item prop="desc" class="desc">
            <el-input
              type="textarea"
              show-word-limit
              maxlength="100"
              v-model="link.desc"
              placeholder="快来参加活动吧"
            ></el-input>
          </el-form-item>
          <el-form-item prop="msg_content" class="poster">
            <el-upload
              ref="link"
              action="#"
              accept=".png, .jpg"
              :on-success="handleSuccess"
              :http-request="(opts) => requestQiniu(opts, 'link')"
              :limit="1"
              :show-file-list="false"
            >
              <div v-if="link.msg_content" class="thum">
                <img :src="link.msg_content" class="img link-img" fit="cover" />
                <i class="el-icon-closeplus" @click.stop="handleRemove('link')"
                  ><i class="el-icon-close"></i
                ></i>
              </div>
              <div v-else class="upload-box flex">
                <i class="iconfont icon-plus avatar-uploader-icon"></i>
                <span>上传封面</span>
              </div>
            </el-upload>
          </el-form-item>
          <span class="text-small" style="color: #6e788a"
            >建议封面尺寸500*500px，JPG，PNG，JPEG格式，小于2MB</span
          >
        </el-form>
      </template>

      <template v-else-if="type === 2013">
        <div
          class="applet-temp"
          @click="$refs.selectApplet.visible = true"
          v-if="!appletInfo.appid && !appletInfo.weappiconurl"
        >
          <i class="iconfont icon-plus avatar-uploader-icon"></i>
          <span class="warp-desc">从聊天记录中选择小程序</span>
        </div>
        <div class="thum" ref="applet" v-else>
          <app-poster :applet="appletInfo"></app-poster>
          <i class="el-icon-closeplus" @click.stop="handleRemove('applet')"
            ><i class="el-icon-close"></i
          ></i>
        </div>
        <select-applet ref="selectApplet" @chose="handleChoseApp" />
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button round type="primary" @click="handlePushMsg">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Base64 } from 'js-base64'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import selectApplet from './selectApplet.vue'
import AppPoster from '@/components/AppPoster.vue'
import { common } from '../http'
export default {
  components: {
    selectApplet, AppPoster
  },
  mixins: [qiniuUpload],
  data () {
    const checkUrl = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('链接不能为空'))
      }
      if (!this.util.isUrl(value)) {
        callback(new Error('链接格式错误'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      edit: false,
      // 2001文字（支持传入系统表情，暂不支持emoji表情）；
      // 2002图片（支持jpg/gif格式）；2003语音（支持amr/silk格式）；
      // 2004视频；2005图文链接；2006好友名片；2010 文件；2013 小程序
      type: 2001, // 内容类型
      text: { // 文本类型文本
        msg_content: ''
      },
      pic: { // 图片类型
        msg_content: ''
      },
      video: {
        href: '',
        voice_time: 0,
        msg_content: '' // 视频封面图地址：视频地址+?vframe/jpg/offset/3
      },
      file: {
        title: '',
        msg_content: '',
        href: ''
      },
      link: { // 链接类型表单
        title: '',
        desc: '',
        msg_content: '',
        href: ''
      },
      applet: {
        title: '',
        msg_content: '',
        href: '',
        content: {}
      },
      linkRules: {
        title: [{ required: true }, { max: 50 }],
        desc: [{ max: 100 }],
        href: [{ validator: checkUrl }],
        msg_content: [{ required: true }]
      },
      typeDic: {
        2001: 'text',
        2002: 'pic',
        2004: 'video',
        2005: 'link',
        2010: 'file',
        2013: 'applet'
      },
      appletInfo: {} // 所选小程序信息
    }
  },
  methods: {
    /**
     * @description: 切换类型
     * @param {*} type
     * @return {*}
     */
    handleClick (type) {
      this.$refs.pic && this.$refs.pic.clearFiles()
      this.$refs.file && this.$refs.file.clearFiles()
      this.$refs.video && this.$refs.video.clearFiles()
      this.$refs.link && this.$refs.link.clearFiles()
      this.type = type
    },
    // 上传
    async requestQiniu (opts, type) {
      let rule = {}
      let key = 'msg_content'
      switch (type) {
        case 'pic':
          rule = {
            types: ['jpg', 'png'],
            size: '10MB'
          }
          break
        case 'video':
          key = 'href'
          rule = {
            types: ['mp4'],
            size: '10MB'
          }
          break
        case 'file':
          key = 'href'
          rule = {
            size: '10MB'
          }
          break
        case 'link':
          rule = {
            size: '2MB'
          }
          break
      }
      const res = await this.qiniuUpload2(
        opts.file,
        path => {
          this[type][key] = path
          if (type === 'file') {
            this[type].title = opts.file.name
            this.file.msg_content = this.file.href
          }
          if (type === 'link') {
            this.$refs.linkForm.validate(() => { })
          }
        },
        null,
        rule
      )
      if (res) {
        this.$refs[type].clearFiles()
      }
    },
    // 获取视频时长
    getDuration () {
      this.video.voice_time = this.$refs.videoDom.duration.toFixed(0) * 1 || 0
      this.video.msg_content = this.video.href + '?vframe/jpg/offset/3'
    },
    handleSuccess () {
      this.$refs.link.clearFiles()
    },
    // 移除
    handleRemove (key) {
      if (key === 'link') {
        this.link.msg_content = ''
      } else {
        this.$refs[key].clearFiles && this.$refs[key].clearFiles()
        this[key].title && (this[key].title = '')
        this[key].href && (this[key].href = '')
        this[key].msg_content && (this[key].msg_content = '')
        this[key].desc && (this[key].desc = '')
        this[key].voice_time && (this[key].voice_time = 0)
        key === 'applet' && (this.appletInfo = {})
      }
    },
    getUrlInfo () {
      if (this.util.https(this.link.href)) {
        common.urlInfo({ url: this.link.href }).then(res => {
          this.link.title = res.title
          this.link.desc = res.description
          this.link.msg_content = res.img_url
          this.$refs.linkForm.validate()
        })
      }
    },
    /**
     * @description: 获取小程序
     * @param {*} app
     * @return {*}
     */
    handleChoseApp (app) {
      this.appletInfo = app
      this.applet.content = app
      this.applet.title = app.title
      this.applet.msg_content = Base64.encode(JSON.stringify(app))
    },
    /**
     * @description: 添加消息
     * @param {*}
     * @return {*}
     */
    handlePushMsg () {
      if (this.type === 2005) {
        let res = false
        this.$refs.linkForm.validate(valid => {
          res = valid
        })
        if (!res) {
          return false
        }
      } else if (!this[this.typeDic[this.type]].msg_content) {
        this.$message.error('群发内容不能为空')
        return false
      }
      this.visible = false
      if (this.edit) {
        this.$emit('push', {
          temp: this.edit,
          msg_type: this.type,
          ...this[this.typeDic[this.type]]
        })
      } else {
        this.$emit('push', {
          msg_type: this.type,
          ...this[this.typeDic[this.type]]
        })
      }
      Object.assign(this.$data, this.$options.data.call(this))
    },
    handleEdit (msg) {
      this.visible = true
      this.edit = msg.temp
      this.type = msg.msg_type
      const obj = this[this.typeDic[msg.msg_type]]
      for (const key in obj) {
        obj[key] = msg[key]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-content {
  .type-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .item {
      color: #454d5b;
      font-size: 16px;
      cursor: pointer;
      &.active {
        color: #2b7cff;
      }
    }
  }
  .text-input {
    ::v-deep {
      .el-textarea__inner {
        resize: none;
        border-color: #dfe1e8;
        height: 164px;
      }
    }
  }
  .content {
    border-radius: 8px;
    border: 1px solid #dfe1e8;
    .upload-box {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 22px 0;
      box-sizing: border-box;
      border-radius: 8px;
      i {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .uploader {
      min-height: 164px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px 0 10px;
      box-sizing: border-box;

      .upload-box {
        height: 104px;
        width: 104px;
        border: 1px dashed #dfe1e8;
      }
    }
    .thum {
      position: relative;
      &:hover {
        .el-icon-closeplus {
          display: block;
        }
      }
      .el-icon-closeplus {
        display: none;
        background: #b4bbcc;
        width: 14px;
        height: 14px;
        color: #fff;
        border-radius: 50%;
        position: absolute;
        right: -3px;
        top: -3px;
        line-height: 13px;
        text-align: center;
        cursor: pointer;
      }
      .el-icon-close {
        font-size: 12px;
      }
    }
    .img {
      height: 104px;
      display: block;
    }
    .link-img {
      width: 130px;
      height: 110px;
      object-fit: cover;
    }
    .link-form {
      margin: 8px 12px;
      position: relative;
      .el-form-item {
        margin-bottom: 8px;
        &:nth-of-type(2),
        &:nth-of-type(3) {
          width: 330px;
        }
      }
      .poster {
        border: 1px solid #dfe1e8;
        position: absolute;
        right: 0;
        top: 44px;
        border-radius: 8px;
        &.is-error {
          border-color: #ff4842;
        }
        ::v-deep {
          .el-upload {
            width: 144px;
            height: 122px;
          }
        }
      }
      ::v-deep {
        .desc {
          .el-textarea__inner {
            height: 80px;
          }
        }
        .el-textarea__inner {
          resize: none;
          border-color: #dfe1e8;
        }
      }
    }
    .upload-video-box {
      padding-top: 15px;
      .warp-desc {
        display: block;
      }
    }
    .applet-temp {
      cursor: pointer;
      width: 104px;
      height: 104px;
      border: 1px dashed #dfe1e8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 22px 0;
      box-sizing: border-box;
      margin: 29px auto;
      border-radius: 8px;
      i {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .applet {
      margin: 20px auto;
      & ~ .el-icon-closeplus {
        right: 120px;
      }
    }
    .warp-desc {
      padding: 0 8px;
      text-align: center;
      line-height: 1.5;
      margin-top: 6px;
    }
    .file-temp {
      width: 190px;
      height: 56px;
      background: #ffffff;
      border: 1px solid #dfe1e8;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      box-sizing: border-box;
      .file-title {
        flex: 1;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #454d5b;
      }
      .file-icon {
        width: 30px;
        height: 30px;
        margin-left: 20px;
      }
    }
  }
}
::v-deep {
  .el-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    height: 100%;
    color: #454d5b;
    &:focus {
      border-color: #dfe1e8;
      color: #454d5b;
    }
  }
}
</style>
