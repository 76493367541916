<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-14 16:02:38
 * @LastEditTime: 2022-02-25 10:33:24
-->
<template>
  <div class="group-detail page-warp">
    <div class="admin">
      <div class="title">托管群的账号</div>
      <account-of-management />
    </div>
    <div class="member">
      <div class="title">
        群成员
        <div
          class="total-customer q-info"
          style="display: inline; font-size: 14px; color: #212b36"
        >
          共 {{ total }} 条记录，在群内 {{ in_group_total }} 人，已退群
          {{ exist_group_total }} 人
        </div>
      </div>
      <ly-table
        :data="tableData"
        :option="tableOpt"
        :page="page"
        :pageSize="per_page"
        :total="total"
        @searchChange="searchChange"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelectionChange"
      >
        <template #name_slot="{ row }">
          <div class="flex flex-start">
            <img
              v-if="row.profile_photo"
              :src="row.profile_photo"
              alt=""
              class="ly-table__main-avatar"
            />
            <img
              v-else
              src="defaultAvatar"
              alt=""
              class="ly-table__main-avatar"
            />
            <div>
              {{ row.name }}
            </div>
          </div>
        </template>

        <template #status_slot="{ row }">
          <ly-status type="success" v-if="row.status === 0">在群内</ly-status>
          <ly-status type="danger" v-else>已退群</ly-status>
        </template>

        <template #statistics="{ label }">
          {{ label }}
          <el-tooltip
            class="item"
            effect="dark"
            content="关闭后，统计群聊数据时，该成员的数据将不进行统计。"
            placement="top-end"
          >
            <i class="icon-warning-fill iconfont"></i>
          </el-tooltip>
        </template>

        <template #state_slot="{ row }">
          <el-switch
            v-model="row.state"
            :active-value="0"
            :inactive-value="1"
            @change="fn_handleSwitch($event, row)"
          >
          </el-switch>
        </template>
        <template #joined_at_slot="{ row }">
          <div class="ly-table__main-time">
            {{
              row.joined_at === 0
                ? "-"
                : util.timeTofmt(row.joined_at, "yyyy-MM-dd hh:mm:ss")
            }}
          </div>
        </template>
        <template #exited_at_slot="{ row }">
          <div class="ly-table__main-time">
            {{
              row.exited_at === 0
                ? "-"
                : util.timeTofmt(row.exited_at, "yyyy-MM-dd hh:mm:ss")
            }}
          </div>
        </template>
        <template #activity_at_slot="{ row }">
          <div class="ly-table__main-time">
            {{
              row.activity_at === 0
                ? "-"
                : util.timeTofmt(row.activity_at, "yyyy-MM-dd hh:mm:ss")
            }}
          </div>
        </template>
      </ly-table>
      <el-dialog
        :title="doClose ? '关闭统计成员数据' : '开启统计成员数据'"
        :visible.sync="visible"
        custom-class="account-dialog"
        width="480px"
        @close="fn_handleClose"
      >
        <el-radio v-model="radio" label="1">{{
          doClose
            ? "关闭后，不统计该成员所有群的数据"
            : "开启后，统计该成员所有群的数据"
        }}</el-radio>
        <el-radio v-model="radio" label="2">{{
          doClose
            ? "关闭后，仅不统计该成员在本群的数据"
            : "开启后，仅统计该成员在本群的数据"
        }}</el-radio>
        <span slot="footer" class="dialog-footer">
          <el-button
            round
            size="medium"
            type="primary"
            @click="fn_changeStatus"
            >{{ doClose ? "确定关闭" : "确认开启" }}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import accountOfManagement from './components/accountOfManagement'
import { groupDetail } from './http'
export default {
  mixins: [lyTable],
  components: {
    accountOfManagement
  },
  data () {
    return {
      COMM_HTTP: groupDetail,
      id: null,
      visible: false,
      doClose: false,
      radio: '1',
      in_group_total: 0,
      exist_group_total: 0
    }
  },
  computed: {
    groupId () {
      return this.$route.query.id
    },
    tableOpt () {
      return {
        operation: 'hide',
        column: [
          {
            label: '成员昵称',
            prop: 'name',
            key: 'group_user_name',
            search: true,
            slot: 'name_slot',
            width: 200
          },
          {
            label: '成员类型',
            prop: 'type',
            search: true,
            type: 'select',
            placeholder: '全部',
            clearable: 0,
            default: -1,
            source: [
              { label: '全部', value: -1 },
              { label: '普通成员', value: 0 },
              { label: '管理员', value: 1 },
              { label: '群主', value: 2 }
            ],
            formatter (row, column, cellValue, index) {
              if (cellValue === 0) {
                return '普通成员'
              } else if (cellValue === 1) {
                return '管理员'
              } else {
                return '群主'
              }
            }
          },
          {
            label: '群内状态',
            prop: 'status',
            slot: 'status_slot',
            search: true,
            type: 'select',
            placeholder: '全部',
            clearable: 0,
            default: -1,
            source: [
              { label: '全部', value: -1 },
              { label: '在群内', value: 0 },
              { label: '已退群', value: 1 }
            ]
          },
          {
            label: '入群时间',
            prop: 'joined_at',
            slot: 'joined_at_slot'
          },
          {
            label: '退群时间',
            prop: 'exited_at',
            slot: 'exited_at_slot'
          },
          {
            label: '最近活跃时间',
            prop: 'activity_at',
            slot: 'activity_at_slot'
          },
          {
            label: '是否统计成员数据',
            prop: 'state',
            slot: 'state_slot',
            width: 160,
            align: 'center',
            headerSlot: 'statistics'
          }
        ]
      }
    }
  },
  mounted () {
    this.setGoBackText()
  },
  methods: {
    setGoBackText () {
      this.$store.commit('_SET', {
        'global.cusTitle': ` - ${this.$route.query.name}`
      })
    },
    // 切换统计 switch
    fn_handleSwitch (val, row) {
      if (val === 0) {
        this.doClose = false
      } else {
        this.doClose = true
      }

      this.id = row.id
      this.visible = true
    },

    // 改变统计状态
    async fn_changeStatus () {
      let status = 0
      if (this.doClose) {
        status = this.radio === '1' ? 2 : 3
      } else {
        status = this.radio === '1' ? 0 : 1
      }
      const res = await this.COMM_HTTP.changeStatus({
        id: +this.id,
        status: +status
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      this.visible = false
    },

    // 关闭切换统计弹框
    fn_handleClose () {
      this.radio = '1'
      this.queryData()
    },

    // 获取列表数据
    async queryData () {
      this.loading = true
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: this.page,
        per_page: this.per_page,
        ...this.sort,
        ...this.params
      })
      this.loading = false
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.tableData = res.list || res.data || res[this.DATA_LOCATION]
      this.total = res.total
      this.listData = res
      this.in_group_total = res.in_group_total
      this.exist_group_total = res.exist_group_total
      return res
    },
    formatterParams (params) {
      return {
        ...params,
        group_id: this.groupId
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    this.$store.commit('_SET', {
      'global.cusTitle': ''
    })
    next()
  }
}
</script>
<style lang="scss" scoped>
.admin {
  margin-bottom: 20px;
  box-shadow: $--shadow;
}
.admin,
.member {
  border-radius: 16px;
  ::v-deep {
    .ly-table-main {
      box-shadow: unset;
    }
  }
}
::v-deep {
  .ly-table__main-avatar {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin-right: 12px;
  }
  .xl-ly-search {
    margin-bottom: 0;
    border-radius: unset;
  }
  .admin .ly-table-main {
    margin-bottom: 0;
    border-radius: 0 0 16px 16px !important;
    .el-table__row:last-of-type {
      td {
        border-bottom: unset;
      }
    }
  }
  .member .ly-table-main {
    border-radius: 0 0 16px 16px !important;
    padding-top: 20px !important;
    box-shadow: $--shadow;
  }
}
.title {
  padding: 0 22px;
  height: 61px;
  line-height: 61px;
  color: #212b36;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  border-radius: 16px 16px 0 0;
  box-shadow: $--shadow;
}
.icon-warning-fill iconfont {
  font-size: 15px;
}
::v-deep {
  .el-form {
    padding: 0 20px;
    box-shadow: unset;
    z-index: 9;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-radio {
    display: block;
    margin: 14px 0;
  }
  .account-dialog {
    .el-dialog__header {
      border-bottom: unset;
      padding: 16px 20px 10px;
    }
    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }
}

.status {
  padding-left: 6px;
  &:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #2b7cff;
    border-radius: 50%;
    position: relative;
    top: -2px;
    left: -6px;
  }
}
.status0:before {
  background: #4e88de;
}
.status1:before {
  background: #ff3b00;
}
.total-customer {
  padding: 0 20px;
  margin-bottom: 16px;
}
</style>
