var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-mobile",
    {
      staticClass: "ly-mobile",
      attrs: { showWel: false, hasFooter: false, sub: "", title: "活动详情" }
    },
    [
      _c("div", { staticClass: "act" }, [
        _c(
          "div",
          { staticClass: "banner" },
          [
            _c(
              "el-image",
              {
                staticClass: "banner-img",
                attrs: { src: _vm.bg_url, lazy: "" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "banner-img__err",
                    attrs: { slot: "placeholder" },
                    slot: "placeholder"
                  },
                  [
                    _vm._v(" 加载中"),
                    _c("span", { staticClass: "dot" }, [_vm._v("...")])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "banner-img__err",
                    attrs: { slot: "error" },
                    slot: "error"
                  },
                  [_vm._v(" 请上传活动首图 ")]
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "box" },
            [
              _c("div", { staticClass: "box-time" }, [
                _c("img", {
                  staticClass: "box-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_clock.svg"),
                    alt: ""
                  }
                }),
                _c("span", { staticClass: "box-title" }, [_vm._v("倒计时：")]),
                _c("div", { staticClass: "box-count" }, [
                  _c("span", { staticClass: "number" }, [_vm._v("1")]),
                  _c("span", { staticClass: "text" }, [_vm._v("天")]),
                  _c("span", { staticClass: "number" }, [_vm._v("23")]),
                  _c("span", { staticClass: "text" }, [_vm._v("时")]),
                  _c("span", { staticClass: "number" }, [_vm._v("59")]),
                  _c("span", { staticClass: "text" }, [_vm._v("分")]),
                  _c("span", { staticClass: "number" }, [_vm._v("59")]),
                  _c("span", { staticClass: "text" }, [_vm._v("秒")])
                ])
              ]),
              _c("el-button", { staticClass: "box-btn" }, [
                _vm._v(_vm._s(_vm.btn_title))
              ])
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "list" },
          [
            _c("div", { staticClass: "list-tab" }, [
              _vm.notice.length
                ? _c(
                    "div",
                    {
                      staticClass: "list-tab__item",
                      class: _vm.tabName === 0 ? "active" : "",
                      on: {
                        click: function($event) {
                          _vm.tabName = 0
                        }
                      }
                    },
                    [_vm._v(" 更多直播预告 ")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "list-tab__item",
                  class:
                    _vm.tabName === 1 || !_vm.notice.length ? "active" : "",
                  on: {
                    click: function($event) {
                      _vm.tabName = 1
                    }
                  }
                },
                [_vm._v(" 我的邀请记录 ")]
              )
            ]),
            _vm.notice.length && _vm.tabName === 0
              ? [
                  _vm._l(_vm.notice, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "list-item",
                        class: index === 0 ? "first" : ""
                      },
                      [
                        _c("img", {
                          staticClass: "list-cover",
                          attrs: { src: item.poster_url }
                        }),
                        _c(
                          "div",
                          { staticClass: "list-info" },
                          [
                            _c("div", { staticClass: "list-info__title" }, [
                              _vm._v(_vm._s(item.title))
                            ]),
                            _c("div", { staticClass: "list-info__time" }, [
                              _vm._v("开播时间：" + _vm._s(item.open_time))
                            ]),
                            _c("el-button", { staticClass: "list-info__btn" }, [
                              _vm._v("预约直播")
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _c("div", { staticClass: "list-more" }, [
                    _vm._v(" 查看更多直播预告 ")
                  ])
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "invite" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "invite-item",
                          staticStyle: { color: "#666666", "font-size": "13px" }
                        },
                        [
                          _c("div", { staticClass: "invite-info" }, [
                            _c("span", { staticClass: "invite-name" }, [
                              _vm._v("用户")
                            ])
                          ]),
                          _c("div", { staticClass: "invite-status" }, [
                            _vm._v("预约状态")
                          ])
                        ]
                      ),
                      _vm._l(_vm.inviterList, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "invite-item" },
                          [
                            _c("div", { staticClass: "invite-info" }, [
                              _c("img", {
                                staticClass: "invite-avatar",
                                attrs: { src: item.avatar, alt: "" }
                              }),
                              _c("span", { staticClass: "invite-name" }, [
                                _vm._v(_vm._s(item.nickname))
                              ])
                            ]),
                            _c("div", { staticClass: "invite-status" }, [
                              _c(
                                "span",
                                {
                                  class: [
                                    item.status === 1 ? "q-success" : "q-info"
                                  ]
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.status === 1
                                          ? "预约成功"
                                          : "未预约"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "list-more",
                          staticStyle: { "padding-bottom": "13px" }
                        },
                        [_vm._v(" 查看更多 ")]
                      )
                    ],
                    2
                  )
                ]
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }