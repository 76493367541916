<!--
 * @Descripttion: 渠道推广页面
 * @version:
 * @Author: lw
 * @Date: 2021-05-07 16:50:20
 * @LastEditTime: 2022-02-25 10:54:03
-->
<template>
  <div class="popularize page-warp-bg">
    <div class="select-box">
      <label style="color: rgba(0, 0, 0, 0.85)">选择活动：</label>
      <el-select
        size="medium"
        v-model="activity_id"
        @change="handleActChange"
        filterable
        placeholder="请选择活动"
      >
        <el-option
          v-for="item in activities"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @events="handleEvents"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          @click="visible = true"
          >添加渠道</el-button
        >
      </template>

      <template #link_slot="{ row }">
        <div class="url-box">
          <span class="activity_url">{{ row.link_url }}</span>
          <el-button
            round
            style="marginleft: 20px"
            type="text"
            @click="handleCopy(row)"
            >复制</el-button
          >
        </div>
      </template>

      <template #qrcode_slot="{ row }">
        <div class="p-qrcode-box">
          <el-popover
            placement="bottom"
            width="200"
            trigger="hover"
            @show="handleShow(row.link_url)"
          >
            <img :src="poster" alt="" class="poster" />
            <div class="btn-area">
              <el-button size="small" type="text" @click="handleDownload()"
                >下载海报</el-button
              >
              <el-button size="small" type="text" @click="handleDownload(row)"
                >下载二维码</el-button
              >
            </div>
            <i
              slot="reference"
              class="iconfont icon-Code"
              style="font-size: 18px; margin-right: 10px"
            ></i>
          </el-popover>
          <el-button type="text" @click="handleDownload(row)">下载</el-button>
        </div>
      </template>

      <template #operation="{ row }">
        <el-button
          type="text"
          :disabled="row.status === 3"
          @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          :disabled="row.status === 2 || row.status === 3"
          @click="handleDel(row)"
          >删除</el-button
        >
      </template>
    </ly-table>
    <!-- 添加渠道弹窗 -->
    <el-dialog
      :title="edit ? '编辑渠道' : '添加渠道'"
      :visible.sync="visible"
      width="500px"
    >
      <el-form
        ref="form"
        size="medium"
        :model="form"
        :rules="rules"
        :show-message="false"
        label-width="90px"
        @submit.native.prevent
      >
        <el-form-item label="渠道名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入渠道名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span style="color: #6e788a; margin-right: 12px"
          >保存后自动生成二维码和链接</span
        >
        <el-button round type="primary" size="medium" @click="handleSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { popularize } from './http.js'
import lyTable from '@/components/mixins/ly-table.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: popularize,
      visible: false,
      activities: [],
      activity_id: '',
      edit: false,
      editId: '',
      groupQrCode: '',
      poster: '',
      form: {
        name: ''
      },
      rules: {
        name: [{ required: true, max: 20 }]
      }
    }
  },
  computed: {
    tableOpt () {
      return {
        scrollEl: '.page-warp-bg',
        operationWidth: 200,
        column: [
          {
            label: '渠道名称',
            prop: 'channel_name',
            key: 'title',
            search: true
          },
          {
            label: '活动链接',
            prop: 'link_url',
            slot: 'link_slot'
          },
          {
            label: '渠道二维码',
            prop: 'qrcode_url',
            slot: 'qrcode_slot',
            width: 200
          }
        ]
      }
    },
    poster_url () {
      return this.listData.poster_url
    },
    poster_conf () {
      return JSON.parse(this.listData.poster_conf)
    }
  },
  mounted () {
    this.activity_id = this.$route.query.id * 1
    this.COMM_HTTP.selectList().then(res => {
      this.activities = res.list || []
    })
  },
  methods: {
    handleActChange () {
      this.$router.replace({ path: this.$route.path, query: { id: this.activity_id } })
      this.$nextTick(() => {
        this.queryData()
      })
    },
    // 格式化参数
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activity_id
      }
    },
    // 保存渠道
    handleSave () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let res
          if (this.edit) {
            res = await this.COMM_HTTP.channelUpdate({
              id: this.editId,
              channel_name: this.form.name
            })
          } else {
            res = await this.COMM_HTTP.channelCreate({
              activity_id: this.activity_id,
              channel_name: this.form.name
            })
          }
          if ((res && res.code) || (res && res.code === 0)) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success(this.edit ? '修改渠道成功！' : '添加渠道成功！')
          this.visible = false
          this.queryData()
          setTimeout(() => {
            this.edit = false
            this.form.name = ''
            this.$nextTick(() => {
              this.$refs.form.clearValidate()
            })
          }, 200)
        }
      })
    },
    // 复制操作
    handleCopy ({ link_url }) {
      const input = document.createElement('input')
      input.value = link_url
      document.getElementsByTagName('body')[0].appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.getElementsByTagName('body')[0].removeChild(input)
      this.$message.success('复制成功')
    },
    /**
     * @description: 弹出层弹出回调 此时生成群二维码  生成海报
     * @param {*} url
     * @return {*}
     */
    async handleShow (url) {
      this.groupQrCode = await this.util.qrCode(url, 280)
      this.poster = await this.util.syntheticPoster(this.poster_url, [
        {
          url: this.groupQrCode,
          sx: this.poster_conf.qrcode_x * 2,
          sy: this.poster_conf.qrcode_y * 2,
          swidth: this.poster_conf.qrcode_width * 2,
          sheight: this.poster_conf.qrcode_height * 2
        }
      ])
    },
    /**
     * @description: 下载二维码/海报
     * @param {*} row
     * @return {*}
     */
    async handleDownload (row) {
      // 下载二维码
      if (row) {
        if (!this.groupQrCode) {
          this.groupQrCode = await this.util.qrCode(row.link_url, 280)
        }
        const x = new XMLHttpRequest()
        x.open('GET', this.groupQrCode, true)
        x.responseType = 'blob'
        x.onload = function () {
          const url = window.URL.createObjectURL(x.response)
          const a = document.createElement('a')
          a.href = url
          a.download = 'QR'
          a.click()
        }
        x.send()
      } else {
        const a = document.createElement('a')
        a.href = this.poster
        a.download = 'POSTER'
        a.click()
      }
    },
    /**
     * @description: 修改渠道
     * @param {*} row
     * @return {*}
     */
    handleEdit (row) {
      this.edit = true
      this.form.name = row.channel_name
      this.editId = row.id
      this.visible = true
    },
    /**
     * @description: 删除渠道
     * @param {*} id
     * @return {*}
     */
    async handleDel ({ id }) {
      this.$lyConfirm({
        text: '此操作无法撤销, 是否确定删除?',
        title: '确认要删除吗？'
      }).then(async () => {
        const res = await this.COMM_HTTP.reqDelete({ id })
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.queryData()
      }).catch(() => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.popularize {
  display: flex;
  flex-direction: column;
}
.select-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  .el-select {
    width: 353px;
  }
}
.p-qrcode-box {
  display: flex;
  & > span {
    height: 24px;
  }
  .p-qrcode {
    margin-right: 20px;
  }
}
.url-box {
  display: flex;
  .activity_url {
    max-width: calc(100% - 40px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 20px;
  }
}
.btn-area {
  text-align: center;
  margin-top: 10px;
}
.poster {
  width: 200px;
  height: 356px;
  display: block;
  object-fit: contain;
}
::v-deep {
  .act-selectbox .el-input__inner {
    width: 353px;
  }
  .el-form-item__label {
    padding-right: 2px;
  }
  .el-dialog__body {
    padding: 24px 24px 30px 24px;
  }
}
</style>
