var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "recipient_name",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(" " + _vm._s(row.recipient_name) + " "),
                row.recipient_type === 1
                  ? _c("span", { staticClass: "q-success" }, [
                      _vm._v(_vm._s(row.recipient_suffix))
                    ])
                  : _c("span", { staticClass: "q-warning" }, [
                      _vm._v(_vm._s(row.recipient_suffix))
                    ])
              ]
            }
          },
          {
            key: "customer_name",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-tag", {
                  attrs: {
                    content: row.customer_name,
                    size: 6,
                    icon: "iconfont icon-a-customerservice q-primary"
                  }
                })
              ]
            }
          },
          {
            key: "user_name",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("el-image", {
                      staticClass: "create-avatar",
                      attrs: { src: row.user_avatar, fit: "cover", lazy: "" }
                    }),
                    _c("span", { staticStyle: { flex: "1" } }, [
                      _vm._v(" " + _vm._s(row.user_name) + " ")
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.fn_imList(row)
                      }
                    }
                  },
                  [_vm._v("聊天记录")]
                )
              ]
            }
          }
        ])
      }),
      _c("im", {
        ref: "im",
        attrs: { selfInfo: _vm.selfInfo, chat_id: _vm.chat_id }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }