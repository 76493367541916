<!--
 * @Author: zhanln
 * @Date: 2021-07-28 16:05:06
 * @LastEditTime: 2022-02-25 10:13:07
 * @LastEditors: zhanln
 * @Description: 领奖路径
-->
<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>
    <div class="edit-card__content" v-loading="cardLoading">
      <el-form
        :model="prizeForm"
        label-width="125px"
        style="width: 60%; min-width: 560px"
        size="medium"
      >
        <el-form-item label="领奖路径">
          <el-radio-group
            v-model="prizeForm.type"
            @change="fn_setForm($event, 'type')"
          >
            <el-radio :label="1">添加涨粉账号后领奖</el-radio>
            <el-radio :label="2">完成任务后直接领奖</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="prizeForm.type === 1">
          <el-form-item label="领奖账号" required :error="idError">
            <ly-emp-btn
              btnText="选择领奖账号"
              :max="100"
              :isReal="true"
              :datafilter="true"
              :checkList="prizeForm.ids"
              @getEmpId="fn_getEmpId"
            ></ly-emp-btn>
          </el-form-item>

          <el-form-item label="好友欢迎语" required :error="wordError">
            <ly-editor
              title="好友欢迎语"
              id="prizeWords"
              :content="prizeForm.words"
              :insetMenu="['用户昵称']"
              :recoverContent="initHtml"
              :error="wordError !== ''"
              @getContent="fn_getWelWords"
            ></ly-editor>
          </el-form-item>

          <el-form-item label="自动打标签" class="reward-form__item">
            <div class="flex" style="height: 36px">
              <el-switch
                v-model="prizeForm.tag_open"
                :active-value="1"
                :inactive-value="0"
                @change="fn_setForm($event, 'tag_open')"
              ></el-switch>
              <span class="q-info" style="line-height: 20px; margin-left: 10px"
                >自动为裂变账号的新增用户打标签</span
              >
            </div>
            <div v-show="prizeForm.tag_open">
              <tag-select-v2
                @getTagId="getTagId"
                :checked="prizeForm.tags"
              ></tag-select-v2>
            </div>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import tagSelectV2 from '@/components/wecomTags'
import lyEditor from '@/components/lyEditor'
export default {
  name: 'way',

  props: {
    title: {
      type: String,
      defualt: () => {
        return ''
      }
    }
  },

  data () {
    return {
      cardLoading: true,
      prizeForm: {
        type: 1,
        ids: null,
        words: '#用户昵称# 你的好友已为你助力成功啦，点下方链接领取你的专属奖品吧~',
        tag_open: 1,
        tags: ''
      },
      idError: '',
      wordError: '',
      initHtml: '#用户昵称# 你的好友已为你助力成功啦，点下方链接领取你的专属奖品吧~'
    }
  },

  computed: {
    isReady () {
      return this.$store.getters['groupfission/GET_READY']
    }
  },

  watch: {
    isReady (val) {
      if (val) {
        const isEdit = this.$store.getters['groupfission/GET_TYPE']
        if (isEdit) {
          this.prizeForm = this.$store.getters['groupfission/GET_WAY']
        }
        this.cardLoading = false
      }
    },
    'prizeForm.words' (val) {
      this.wordError = val ? '' : '请输入好友欢迎语'
    }
  },

  methods: {

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: 'way',
        name: type,
        val: val
      })
    },

    // 涨粉账号
    fn_getEmpId (ids) {
      if (ids && ids.length > 0) {
        this.idError = ''
        this.prizeForm.ids = ids
        this.fn_setForm(ids, 'ids')
      } else {
        this.idError = '请至少选择一个涨粉账号'
        this.prizeForm.ids = null
        this.fn_setForm(null, 'ids')
      }
    },

    // 分享话术
    fn_getWelWords (content) {
      if (this.isReady) {
        this.prizeForm.words = content
        this.fn_setForm(content, 'words')
      }
    },

    // 完成任务客户标签
    getTagId (tags) {
      const tagStr = tags ? tags.join(',') : ''
      this.prizeForm.tags = tagStr
      this.fn_setForm(tagStr, 'tags')
    },

    // 校验
    verify () {
      let veriry = true
      const form = this.prizeForm

      if (form.type === 1) {
        if (!form.ids || form.ids === '') {
          this.idError = '请至少选择一个涨粉账号'
          veriry = false
        }

        if (form.words === '') {
          this.wordError = '请输入好友欢迎语'
          veriry = false
        }
      }

      return veriry
    }
  },

  components: {
    tagSelectV2,
    lyEditor
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
@import "@/assets/scss/var.scss";

.rules {
  border-radius: 4px;
  border: 1px solid #eaebf2;
  overflow: hidden;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &.is-error {
    border-color: $--color-danger;
  }

  &:hover {
    border-color: $--color-primary;
  }

  &-header {
    background-color: #f7f8fc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #dfe1e8;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);

    &-inset {
      background-color: #f5f5f5;
      border-right: 2px;
      font-size: 12px;
      padding: 0 8px;
      height: 24px;
      line-height: 24px;
      margin-right: 16px;
      cursor: pointer;
      border-radius: 2px;

      &:hover {
        color: $--color-primary;
      }
    }
  }

  &-inset {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;
    background: #f2f5fe;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: $--transition;
    font-size: 12px;

    &:hover {
      background-color: $--color-primary;
      color: #fff;
    }

    &.default {
      background-color: transparent;
      color: $--color-text-regular;
      padding: 0;

      &:hover {
        color: $--color-primary;
      }
    }

    & + & {
      margin-left: 12px;
    }
  }

  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 200px !important;
  }
}
</style>
