<!--
 * @Author: zhanln
 * @Date: 2021-08-23 10:40:55
 * @LastEditTime: 2022-01-05 18:51:40
 * @LastEditors: zhanln
 * @Description: 链接设置
-->

<template>
  <el-form
    :model="linkForm"
    :rules="linkFormRules"
    ref="linkFormRef"
    label-width="125px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <el-form-item label="活码链接">
      <div class="flex link-flex">
        <el-switch
          v-model="linkForm.link_open"
          :active-value="true"
          :inactive-value="false"
          @change="fn_setForm($event, 'link_open')"
        ></el-switch>
        <span style="margin-left: 10px">开启后将会为该活码生成一个链接页</span>
      </div>
    </el-form-item>
    <template v-if="linkForm.link_open">
      <el-form-item label="链接页风格">
        <el-radio-group
          v-model="linkForm.link_type"
          @change="fn_setForm($event, 'link_type')"
        >
          <el-radio :label="1">简约风格</el-radio>
          <el-radio :label="2">自定义风格</el-radio>
        </el-radio-group>
        <span class="q-info">（选择自定义风格会生成最终的宣传海报）</span>
      </el-form-item>
      <el-form-item
        label="引导说明"
        prop="guide"
        v-if="linkForm.link_type === 1"
      >
        <el-input
          type="textarea"
          v-model="linkForm.guide"
          maxlength="20"
          resize="none"
          show-word-limit
          placeholder="引导用户扫码，例如：添加老师领取独家私域资料"
          @change="fn_setForm($event, 'guide')"
        ></el-input>
      </el-form-item>
      <template v-else>
        <el-form-item label="链接页背景" ref="poster" prop="link_bg">
          <div class="flex">
            <el-upload
              action="#"
              accept=".png, .jpg"
              :http-request="requestQiniu"
              :on-remove="removePoster"
              list-type="picture-card"
              :limit="1"
              ref="uploadFile"
              :file-list="linkForm.link_bg"
              :class="[
                'upload',
                linkForm.link_bg.length === 0 ? '' : 'upload-empty',
              ]"
            >
              <div class="default">
                <i class="iconfont icon-picture"></i>
                <span>上传图片</span>
              </div>
            </el-upload>
            <div
              class="q-info text-small"
              style="line-height: 22px; margin-left: 24px"
            >
              背景设计须知：<br />
              (1) 建议图片尺寸：750px*1334px，分辨率72 <br />
              (2) 支持JPG、PNG格式，图片大小3MB以内 <br />
              (3) 二维码可在预览区域内移动展示位置。
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="虚拟剩余时间"
          :error="expireError"
          class="expire-item"
        >
          <div class="flex link-flex">
            <el-switch
              v-model="linkForm.unreal_expire_open"
              :active-value="true"
              :inactive-value="false"
              @change="fn_setForm($event, 'unreal_expire_open')"
            ></el-switch>
          </div>
          <div class="link-expire" v-if="linkForm.unreal_expire_open">
            <div class="link-expire__item">
              <span class="link-expire__label"
                ><span class="q-danger">*</span> 剩余时间文案：</span
              >
              <el-input
                v-model="linkForm.unreal_expire_text"
                maxlength="8"
                show-word-limit
                class="link-expire__text"
                :class="[textError ? 'item-error' : '']"
                @change="fn_setForm($event, 'unreal_expire_text')"
              ></el-input>
            </div>
            <div class="link-expire__item">
              <span class="link-expire__label"
                ><span class="q-danger">*</span> 时间设置：</span
              >
              <div>
                <div>
                  <el-input-number
                    v-model="linkForm.unreal_expire_day"
                    class="link-expire__input"
                    :precision="0"
                    :min="0"
                    :max="100"
                    :controls="false"
                    :class="[timeError ? 'item-error' : '']"
                    @blur="fn_changeTime('unreal_expire_day')"
                  ></el-input-number
                  >天
                  <el-input-number
                    v-model="linkForm.unreal_expire_hour"
                    class="link-expire__input"
                    :precision="0"
                    :min="0"
                    :max="23"
                    :controls="false"
                    :class="[timeError ? 'item-error' : '']"
                    @blur="fn_changeTime('unreal_expire_hour')"
                  ></el-input-number
                  >时
                  <el-input-number
                    v-model="linkForm.unreal_expire_minute"
                    class="link-expire__input"
                    :precision="0"
                    :min="0"
                    :max="59"
                    :controls="false"
                    :class="[timeError ? 'item-error' : '']"
                    @blur="fn_changeTime('unreal_expire_minute')"
                  ></el-input-number
                  >分
                  <el-input-number
                    v-model="linkForm.unreal_expire_second"
                    class="link-expire__input"
                    :precision="0"
                    :min="0"
                    :max="59"
                    :controls="false"
                    :class="[timeError ? 'item-error' : '']"
                    @blur="fn_changeTime('unreal_expire_second')"
                  ></el-input-number
                  >秒
                </div>
                <div class="q-info" style="line-height: 1.6; margin-top: 4px">
                  时间为虚拟时间，最小1分钟，活码创建后开始倒计时，<br />结束会开始新一轮倒计时
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </template>
      <el-form-item label="联系主办方">
        <template slot="label"
          >联系主办方
          <el-tooltip
            class="item"
            effect="light"
            placement="right"
            popper-class="q-tooltip is-light"
          >
            <div slot="content">
              <div style="margin: 24px 24px 16px; width: 464px">
                <img
                  src="https://image.01lb.com.cn//uploads/wecom/21/0909/1631160179e6PuX3lX.png"
                  width="464"
                  alt=""
                />
              </div>
            </div>
            <i class="iconfont icon-info-fill"></i
          ></el-tooltip>
        </template>
        <div class="flex link-flex">
          <el-switch
            v-model="linkForm.sevice_open"
            :active-value="true"
            :inactive-value="false"
            @change="fn_setForm($event, 'sevice_open')"
          ></el-switch>
          <span class="q-info" style="margin-left: 10px"
            >开启后，若客户无法通过活码添加好友，可通过该方式联系到你</span
          >
        </div>
      </el-form-item>
      <template v-if="linkForm.sevice_open">
        <el-form-item label="联系账号" :error="userError">
          <template slot="label"
            ><span class="q-danger">*</span> 联系账号</template
          >
          <ly-emp-btn
            btnText="选择联系账号"
            :max="1"
            isReal
            datafilter
            :checkList="linkForm.sevice_ids"
            @getEmpIdArr="fn_getEmpId"
          ></ly-emp-btn>
        </el-form-item>
      </template>
    </template>
  </el-form>
</template>

<script>
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
export default {
  mixins: [qiniuUpload],
  data () {
    return {
      cardLoading: true,
      linkForm: {
        link_open: false,
        link_type: 1,
        guide: '',
        sevice_open: true,
        sevice_ids: null,
        link_bg: [],
        unreal_expire_open: true,
        unreal_expire_text: '活动剩余时间',
        unreal_expire_day: 0,
        unreal_expire_hour: 0,
        unreal_expire_minute: 0,
        unreal_expire_second: 0
      },
      linkFormRules: {
        guide: [
          { message: '请输入引导说明', required: true, trigger: 'change' }
        ],
        link_bg: [
          { type: 'array', required: true, message: '请上传链接页背景', trigger: 'change' }
        ]
      },
      guideError: '',
      expireError: '',
      textError: '',
      userError: '',
      timeError: false,
      hasSetExpire: false
    }
  },

  computed: {
    isReady () {
      return this.$store.getters['qrcode/GET_READY']
    },
    expireCount () {
      const day = this.linkForm.unreal_expire_day
      const hour = this.linkForm.unreal_expire_hour
      const minute = this.linkForm.unreal_expire_minute
      const second = this.linkForm.unreal_expire_second
      return day + hour + minute + second
    }
  },

  watch: {
    isReady (val) {
      if (val) {
        if (this.$store.getters['qrcode/GET_EDIT']) {
          this.$nextTick(() => {
            this.linkForm = this.$store.getters['qrcode/GET_LINK']
          })
        }
        this.cardLoading = false
      }
    },
    'linkForm.link_type' (val) {
      this.$refs.linkFormRef.clearValidate()
      if (val === 2) {
        this.fn_setUnrealTime()
      }
    },
    'linkForm.guide' (val) {
      this.guideError = val === '' ? '请输入引导说明' : ''
    },
    'linkForm.unreal_expire_open' (val) {
      if (!val) {
        this.expireError = ''
      } else {
        this.fn_setUnrealTime()
        let etxt = ''
        if (this.textError) {
          etxt = '请设置剩余时间文案'
        }

        if (this.timeError) {
          etxt = etxt ? etxt + '，请设置时间（最小1分钟）' : '请设置时间（最小1分钟）'
        }
        this.expireError = etxt
      }
    },
    'linkForm.unreal_expire_text' (val) {
      this.expireError = ''
      const txt = this.timeError ? '请设置时间（最小1分钟）' : ''
      if (val) {
        const valid = this.fn_checkTime()
        if (!valid) {
          this.expireError = this.txt ? this.txt + '，请设置时间（最小1分钟）' : '请设置时间（最小1分钟）'
        } else {
          this.expireError = this.txt
        }
      } else {
        this.expireError = '请设置剩余时间文案' + (txt ? `，${txt}` : '')
      }
      this.textError = !val
    },
    expireCount (val) {
      if (this.isReady) {
        this.expireError = ''
        this.txt = this.textError ? '请设置剩余时间文案' : ''
        let error = false
        if (!val) {
          this.expireError = this.txt ? this.txt + '，请设置时间（最小1分钟）' : '请设置时间（最小1分钟）'
          error = true
        } else {
          const valid = this.fn_checkTime()
          if (!valid) {
            this.expireError = this.txt ? this.txt + '，请设置时间（最小1分钟）' : '请设置时间（最小1分钟）'
            error = true
          } else {
            this.expireError = this.txt
            error = false
          }
        }
        this.timeError = error
      }
    }
  },

  methods: {

    // 设置虚拟时间
    fn_setUnrealTime () {
      const { unreal_expire_day: d, unreal_expire_hour: h, unreal_expire_minute: m, unreal_expire_second: s } = this.linkForm

      if (!this.linkForm.unreal_expire_open) {
        return false
      }

      if ((d + h + m + s) > 0 || this.hasSetExpire) return false
      this.hasSetExpire = true

      const basicForm = this.$store.getters['qrcode/GET_BASIC']
      const expireType = basicForm.expire_type
      const expireTime = basicForm.expire_time

      if (expireType === 2) {
        const cTime = this.util.timeTofmt(new Date(), 'yyyy/MM/dd hh:mm')
        const _expireTime = this.util.timeTofmt(expireTime, 'yyyy/MM/dd hh:mm:ss')
        const _baseTime = (new Date(_expireTime).getTime() - new Date(cTime).getTime()) / 1000
        const { d, h, m, s } = this.fn_setExpire(_baseTime)
        this.linkForm.unreal_expire_day = d
        this.linkForm.unreal_expire_hour = h
        this.linkForm.unreal_expire_minute = m
        this.linkForm.unreal_expire_second = s
        this.fn_setForm(d, 'unreal_expire_day')
        this.fn_setForm(h, 'unreal_expire_hour')
        this.fn_setForm(m, 'unreal_expire_minute')
        this.fn_setForm(s, 'unreal_expire_second')
      } else {
        this.linkForm.unreal_expire_day = 1
        this.fn_setForm(1, 'unreal_expire_day')
      }
    },

    // 校验虚拟时间, 最小一分钟
    fn_checkTime () {
      const day = this.linkForm.unreal_expire_day
      const hour = this.linkForm.unreal_expire_hour
      const minute = this.linkForm.unreal_expire_minute
      if (day + hour + minute <= 0) {
        return false
      } else {
        return true
      }
    },

    // 回显 - 虚拟剩余时间
    fn_setExpire (data) {
      const eTime = [86400, 3600, 60, 0]
      const time = [0, 0, 0, 0]

      let _data = data
      for (let i = 0; i < eTime.length; i++) {
        if (i === 3) {
          time[i] = _data
        } else {
          if (_data >= eTime[i]) {
            time[i] = Math.floor(_data / eTime[i])
            _data = _data % eTime[i]
          }
        }
      }

      const { 0: d, 1: h, 2: m, 3: s } = time
      return { d, h, m, s }
    },

    // 涨粉账号
    fn_getEmpId (ids) {
      if (ids && ids.length > 0) {
        this.linkForm.sevice_ids = ids
        this.fn_setForm(ids, 'sevice_ids')
      } else {
        this.linkForm.sevice_ids = null
        this.fn_setForm(null, 'sevice_ids')
      }
      this.fn_checkEmp()
    },

    // 校验联系账号
    fn_checkEmp () {
      let valid = true

      const users = this.linkForm.sevice_ids

      if (!users) {
        this.userError = '请选择联系账号'
        if (this.linkForm.link_open && this.linkForm.sevice_open) {
          valid = false
        }
      } else {
        this.userError = ''
      }

      return valid
    },

    // 上传背景海报
    async requestQiniu (opts) {
      this.linkForm.link_bg = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.linkForm.link_bg = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.fn_setForm(this.linkForm.link_bg, 'link_bg')
          },
          null,
          {
            type: ['jpg', 'png'],
            size: '3MB'
          }
        )
      }

      this.$refs.linkFormRef.validateField('link_bg')
    },
    // 移除背景
    removePoster () {
      this.linkForm.link_bg = []
      this.fn_setForm([], 'link_bg')
      this.$refs.linkFormRef.validateField('link_bg')
    },

    fn_changeTime (type) {
      if (isNaN(this.linkForm[type])) {
        this.linkForm[type] = 0
      }
      this.fn_setForm(this.linkForm[type], type)
    },

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('qrcode/SET_FORM', {
        form: 'link',
        name: type,
        val: val
      })
    },
    // 表单验证
    verify () {
      let formValid = true

      const userError = this.fn_checkEmp()
      formValid = userError

      if (this.linkForm.link_open && this.linkForm.link_type === 2) {
        if (this.linkForm.link_bg.length === 0) {
          formValid = false
          this.bgError = '请上传链接页背景'
        }

        if (this.expireError) {
          formValid = false
        }
      }

      this.$refs.linkFormRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      return formValid
    }
  }
}
</script>

<style lang="scss" scoped>
.act-form {
  width: 100%;
  max-width: 760px;
}
.link {
  &-flex {
    height: 36px;
  }

  &-expire {
    display: flex;
    flex-direction: column;
    background-color: #f7f8fc;
    padding: 16px;

    &__item {
      display: flex;
      line-height: 36px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__label {
      width: 110px;
      text-align: right;
    }

    &__input {
      width: 40px;
      margin: 0 8px;

      ::v-deep .el-input__inner {
        padding: 0 4px;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    &__text {
      width: auto;
    }

    ::v-deep .el-input {
      flex: 1;
    }
  }
}
.upload {
  line-height: 1;
  ::v-deep .el-upload-list__item {
    margin: 0;
    transition: none !important;
  }
}
.upload-empty {
  line-height: 1;
  ::v-deep .el-upload--picture-card {
    display: none;
  }
  ::v-deep .el-upload-list__item {
    margin: 0;
  }
}

::v-deep .el-form-item.is-error {
  .link-expire__input:not(.item-error) .el-input,
  .link-expire__text:not(.item-error) {
    .el-input__inner {
      border-color: #eaebf2 !important;
    }
  }
}
</style>
