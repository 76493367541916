var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ly-table page-warp" },
    [
      _c(
        "div",
        { staticClass: "ly-search" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchForm, size: "medium" }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { "margin-left": "0" } },
                [
                  _c(
                    "el-button-group",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnState === "day" ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.getAnalyse("day")
                            }
                          }
                        },
                        [_vm._v("昨日")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnState === "week" ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.getAnalyse("week")
                            }
                          }
                        },
                        [_vm._v("近7日")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnState === "month" ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.getAnalyse("month")
                            }
                          }
                        },
                        [_vm._v("近30日")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自定义：" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "260px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "~",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions
                    },
                    on: { change: _vm.changeTime },
                    model: {
                      value: _vm.dates,
                      callback: function($$v) {
                        _vm.dates = $$v
                      },
                      expression: "dates"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择员工：" } },
                [
                  _c("ly-emp-select", {
                    attrs: { showLength: 1, checkList: _vm.checkProp },
                    on: { getEmpId: _vm.getEmpId }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { margin: "4px -10px 20px !important" },
          attrs: { gutter: 20 }
        },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class: _vm.cardName === "new_apply_cnt" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("new_apply_cnt")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon primary" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/images/icon-sq.png") }
                  })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "num" }, [
                    _c("span", { staticClass: "all" }, [
                      _vm._v(_vm._s(_vm.summary.new_apply_cnt.curr))
                    ]),
                    _vm._v(" / " + _vm._s(_vm.summary.new_apply_cnt.prev) + " ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 发起申请数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " 统计周期内，员工通过「搜索手机号」、「扫一扫」、"
                              ),
                              _c("br"),
                              _vm._v(
                                "「从微信好友中添加」等渠道主动向客户发起的好友申请数量。 "
                              )
                            ]
                          ),
                          _c("i", { staticClass: "icon-info-fill iconfont" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class: _vm.cardName === "new_contact_cnt" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("new_contact_cnt")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon success" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/images/icon-xz.png") }
                  })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "num" }, [
                    _c("span", { staticClass: "all" }, [
                      _vm._v(_vm._s(_vm.summary.new_contact_cnt.curr))
                    ]),
                    _vm._v(
                      " / " + _vm._s(_vm.summary.new_contact_cnt.prev) + " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 新增客户数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "统计周期内，所有员工新添加的客户数量。",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-info-fill" })]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class: _vm.cardName === "all_contact_cnt" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("all_contact_cnt")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon warning" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/images/icon-kh.png") }
                  })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "num" }, [
                    _c("span", { staticClass: "all" }, [
                      _vm._v(_vm._s(_vm.clientTotal))
                    ]),
                    _vm._v(" / " + _vm._s(_vm.clientTotal) + " ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 客户总数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "统计周期内，实时累计所有客户数量。",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-info-fill" })]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "q-analyse-card",
                class:
                  _vm.cardName === "negative_feed_back_cnt" ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.changeCard("negative_feed_back_cnt")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon brown" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/images/icon-lh.png") }
                  })
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "num" }, [
                    _c("span", { staticClass: "all" }, [
                      _vm._v(_vm._s(_vm.summary.negative_feed_back_cnt.curr))
                    ]),
                    _vm._v(
                      " / " +
                        _vm._s(_vm.summary.negative_feed_back_cnt.prev) +
                        " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._v(" 删除/拉黑客户数 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "统计周期内，删除/拉黑员工的客户数量。",
                            placement: "top-start",
                            "popper-class": "q-tooltip"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-info-fill" })]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "chart-area ly-shadow" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("数据趋势")]),
          _c(
            "div",
            { staticClass: "ly-tool" },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "medium",
                        type: _vm.analyseState === "chart" ? "primary" : ""
                      },
                      on: {
                        click: function($event) {
                          _vm.analyseState = "chart"
                        }
                      }
                    },
                    [_vm._v("图表")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "medium",
                        type: _vm.analyseState === "list" ? "primary" : ""
                      },
                      on: {
                        click: function($event) {
                          _vm.analyseState = "list"
                        }
                      }
                    },
                    [_vm._v("列表")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ly-chart", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.analyseState === "chart" && _vm.showEmpty,
                expression: "analyseState === 'chart' && showEmpty"
              }
            ],
            attrs: { option: _vm.chartOption, height: "400px" }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.analyseState === "chart" && !_vm.showEmpty,
                  expression: "analyseState === 'chart' && !showEmpty"
                }
              ],
              staticClass: "flex flex-center q-info",
              staticStyle: { height: "240px" }
            },
            [_vm._v(" 暂无分析数据 ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.analyseState === "list",
                  expression: "analyseState === 'list'"
                }
              ],
              staticClass: "ly-table__main"
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "stat_time", label: "日期" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "new_apply_cnt",
                      label: "发起申请数",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "new_contact_cnt",
                      label: "新增客户数",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "all_contact_cnt",
                      label: "客户总数",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "negative_feed_back_cnt",
                      label: "删除/拉黑客户数",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "lost_contact_cnt",
                      label: "流失客户占比",
                      align: "center"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "ly-table-empty",
                      attrs: { slot: "empty" },
                      slot: "empty"
                    },
                    [
                      _vm._t("empty", [
                        _c("img", {
                          attrs: {
                            src: require("@assets/svg/default/no_data.svg"),
                            alt: ""
                          }
                        }),
                        _vm._v(" 暂无数据 ")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }