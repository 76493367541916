<template>
  <div class="ly-table page-warp">
    <!-- searchBar -->
    <el-form
      :inline="true"
      size="medium"
      class="demo-form-inline"
      style="padding: 0 20px"
    >
      <el-form-item label="选择活动：" class="label-padding">
        <el-select
          v-model="activityId"
          filterable
          style="width: 320px"
          @change="changeActivity"
        >
          <el-option
            v-for="item in activityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- searchBar end -->

    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button
          type="primary"
          round
          icon="iconfont icon-download"
          v-lydebounce="['button', fn_export]"
          :loading="exportLoading"
          :disabled="!tableData || tableData.length === 0"
          >导出数据</el-button
        >
      </template>
      <!-- 列插槽 -->
      <template #reward_title_slot="{ row }">
        <div class="flex">
          <img
            src="../../../assets/svg/reward.svg"
            alt=""
            style="margin-right: 8px"
          />
          <span>{{ row.reward_title }}</span>
        </div>
      </template>

      <template #step_slot="{ row }">
        <template v-if="row.step === 1">一阶任务</template>
        <template v-if="row.step === 2">二阶任务</template>
        <template v-if="row.step === 3">三阶任务</template>
      </template>

      <template #reward_type_slot="{ row }">
        <template v-if="row.reward_type === 1">文本</template>
        <template v-else-if="row.reward_type === 2">图片</template>
        <template v-else-if="row.reward_type === 3">链接</template>
        <template v-else-if="row.reward_type === 4">自定义</template>
        <template v-else-if="row.reward_type === 5">表单</template>
        <template v-else-if="row.reward_type === 6">兑换码</template>
      </template>

      <template #nickname_slot="{ row }">
        <lyTag :content="row.nickname" :avatar="row.avatar_url"></lyTag>
      </template>

      <template #add_user_name_slot="{ row }">
        <ly-drow :list="row.add_user_name"></ly-drow>
      </template>

      <template #add_status_slot="{ row }">
        <template v-if="row.add_status">
          <ly-status
            type="success"
            icon="iconfont icon-success"
            v-if="row.add_status === 1"
            >已添加</ly-status
          >
          <ly-status type="danger" icon="iconfont icon-close-fill" v-else
            >已删除</ly-status
          >
        </template>
        <template v-else>-</template>
      </template>

      <template #give_status_slot="{ row }">
        <template v-if="row.give_status === 1">
          <ly-status type="danger" icon="iconfont icon-close-fill"
            >未发放</ly-status
          >
          <el-tooltip
            class="item"
            effect="light"
            placement="top-start"
            popper-class="q-tooltip"
          >
            <div slot="content">
              <div
                style="line-height: 40px; text-align: center; font-size: 14px"
              >
                {{
                  row.reward_type === 6
                    ? "兑换码不足"
                    : "库存不足，请前往活动修改页面调整库存"
                }}
              </div>
              <div class="flex flex-center">
                <el-button
                  type="primary"
                  size="mini"
                  round
                  @click="fn_openCodeList(row)"
                  v-if="row.reward_type === 6"
                  >重新发送</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  round
                  @click="updateRewardStatus(row)"
                  v-else
                  >重新发送</el-button
                >
              </div>
            </div>
            <i class="iconfont icon-warning-fill"></i>
          </el-tooltip>
        </template>
        <ly-status
          type="success"
          icon="iconfont icon-success"
          v-else-if="row.give_status === 2"
          >已发放</ly-status
        >
        <template v-else> - </template>
      </template>

      <template #get_status_slot="{ row }">
        <template v-if="row.get_status === 1">未领取</template>
        <template v-else-if="row.get_status === 2">已领取</template>
        <template v-else-if="row.get_status === 3">未填写</template>
        <template v-else-if="row.get_status === 4">已填写</template>
        <template v-else> - </template>
      </template>

      <template #operation="{ row }">
        <template
          v-if="
            (row.reward_type === 6 || row.reward_type === 5) &&
            !row.reward_value
          "
        >
          -
        </template>
        <el-button type="text" @click="getRewardDetail(row)" v-else
          >查看奖品内容</el-button
        >
      </template>
    </ly-table>
    <!-- dialog 奖品内容 -->
    <el-dialog
      title="奖品内容"
      :visible.sync="dialogVisible"
      custom-class="q-dialog"
      width="34%"
    >
      <div class="reward">
        <div class="q-dialog-block" v-if="rewardType === 1 || rewardType === 6">
          {{ rewardDetail }}
        </div>
        <div class="q-dialog__img" v-else-if="rewardType === 2">
          <div class="flex flex-center">
            <img :src="rewardDetail" alt="" />
          </div>
        </div>
        <div v-else-if="rewardType === 3">
          {{ rewardDetail }}
        </div>
        <div
          class="q-dialog-block q-dialog__img"
          v-else-if="rewardType === 4"
          v-html="rewardDetail"
        ></div>
        <div
          class="reward-list"
          v-loading="rewardLoading"
          v-else-if="rewardType === 5"
        >
          <div
            class="reward-list__item"
            v-for="(i, v) in rewardDetail"
            :key="v"
          >
            <div class="title">{{ i.data }}</div>
            <div class="val">{{ i.name }}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 兑换码列表 -->
    <code-list
      ref="codeListRef"
      @success="queryData"
      :rewardId="rewardId"
      :userId="userId"
    ></code-list>
  </div>
</template>

<script>
import codeList from './components/codeList'
import lyDrow from '@/components/lyDrow'
import lyTag from '@/components/lyTag'
import lyTable from '@/components/mixins/ly-table.js'
import { activity, reward } from './http'
import util from '@/common/util.js'
export default {
  components: {
    codeList,
    lyDrow,
    lyTag
  },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: reward,
      loading: true,
      activityList: [],
      activityId: '',
      activityValue: '',
      exportLoading: false,

      taskProgress: [{
        value: null,
        label: '全部'
      }, {
        value: '1',
        label: '一阶任务'
      }, {
        value: '2',
        label: '二阶任务'
      }, {
        value: '3',
        label: '三阶任务'
      }],
      sendOptions: [{
        value: '-1',
        label: '全部'
      }, {
        value: '0',
        label: '等待发送'
      }, {
        value: '2',
        label: '发送失败'
      }, {
        value: '1',
        label: '发送成功'
      }],
      exOptions: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '未领取'
      }, {
        value: 2,
        label: '已领取'
      }, {
        value: 3,
        label: '未填写'
      }, {
        value: 4,
        label: '已填写'
      }],
      rewardOption: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '未发放'
      }, {
        value: 2,
        label: '已发放'
      }],
      addOptions: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '已添加'
      }, {
        value: 2,
        label: '已删除'
      }],
      dialogVisible: false,
      rewardType: null,
      rewardDetail: null,
      rewardLoading: true,
      rewardId: null,
      userId: null
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 140,
        column: [
          {
            label: '奖品名称',
            prop: 'reward_title',
            key: 'giftname',
            slot: 'reward_title_slot',
            search: true,
            width: 200,
            placeholder: '请输入奖品名称'
          },
          {
            label: '任务阶段',
            prop: 'step',
            slot: 'step_slot',
            search: true,
            type: 'select',
            source: this.taskProgress,
            default: null,
            width: 200
          },
          {
            label: '奖品类型',
            prop: 'reward_type',
            slot: 'reward_type_slot',
            align: 'center',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '领取用户',
            prop: 'nickname',
            search: true,
            placeholder: '请输入用户昵称',
            slot: 'nickname_slot',
            width: 170
          },
          {
            label: '完成任务时间',
            prop: 'notice_at',
            search: true,
            sortable: true,
            key: ['begin_at', 'end_at'],
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            columnProps: {
              'min-width': 150
            },
            formatter ({ notice_at }) {
              return util.timeTofmt(notice_at, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '添加账号',
            prop: 'add_user_name',
            slot: 'add_user_name_slot',
            columnProps: {
              'min-width': 220
            }
          },
          {
            label: '添加状态',
            prop: 'add_status',
            slot: 'add_status_slot',
            search: true,
            type: 'select',
            source: this.addOptions,
            default: null,
            align: 'center',
            columnProps: {
              'min-width': 150
            }
          },
          {
            label: '发放状态',
            prop: 'give_status',
            slot: 'give_status_slot',
            search: true,
            type: 'select',
            source: this.rewardOption,
            default: null,
            align: 'center',
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '奖品状态',
            prop: 'get_status',
            slot: 'get_status_slot',
            search: true,
            type: 'select',
            source: this.exOptions,
            default: null,
            align: 'center',
            columnProps: {
              'min-width': 120
            }
          }
        ]
      }
    }
  },
  async created () {
    await this.taskList()
    await this.getId()
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activityId
      }
    },
    // 打开兑换码列表
    fn_openCodeList (row) {
      this.$refs.codeListRef.codeVisable = true
      this.rewardId = +row.reward_type_id
      this.userId = +row.id
    },

    // 获取活动列表
    async taskList () {
      const data = await activity.reqList()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return
      }

      this.loading = false
      if (data) {
        this.activityList = data.map((i, v) => {
          return {
            value: i.id,
            label: i.title
          }
        })
      }
    },

    // 当前活动id
    getId () {
      const _id = this.$route.query.id
      if (_id) {
        this.activityId = +_id
      } else {
        this.activityId = this.activityList[0] ? this.activityList[0].value : null
      }

      for (let i = 0; i < this.activityList.length; i++) {
        if (+_id === +this.activityList[i].value) {
          this.activityValue = this.activityList[i].label
        }
      }
    },

    // 切换活动
    changeActivity (val) {
      this.page = 1
      this.activityId = val
      this.queryData()
      this.$store.commit('_SET', {
        'task.id': val
      })
      this.$router.replace({
        query: {
          id: val
        }
      })
    },

    // 修改奖品发送状态
    async updateRewardStatus ({ id }) {
      const data = await this.axios.post('updateRewardStatusV2', {
        reward_id: id
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.$message.success('发放成功！')
      this.queryData()
    },

    // 查看奖品详情
    async getRewardDetail (data) {
      this.rewardType = data.reward_type
      if (this.rewardType === 3) {
        this.rewardDetail = JSON.parse(data.reward_detail).link_url
      } else if (this.rewardType === 4) {
        const isJson = this.fn_isJson(data.reward_detail)

        if (isJson) {
          const _info = JSON.parse(data.reward_detail)
          this.rewardDetail = _info.text
          if (_info.img_url !== '') {
            this.rewardDetail += `<br/><img src="${_info.img_url}"/>`
          }
        } else {
          this.rewardDetail = data.reward_detail
          if (data.reward_detail_img) {
            this.reward_detail += `<br/><img src="${data.reward_detail_img}"/>`
          }
        }
      } else if (this.rewardType === 6) {
        this.rewardDetail = data.reward_value
      } else if (this.rewardType === 5) {
        await this.fn_getFormReward(data)
      } else {
        this.rewardDetail = data.reward_detail
      }

      this.dialogVisible = true
    },

    // 判断JSON
    fn_isJson (str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    },

    // 获取奖品内容为表单的数据
    async fn_getFormReward (data) {
      this.rewardLoading = true
      const res = await this.COMM_HTTP.getOneDetail(
        {
          formId: data.reward_type_id + '',
          requestId: data.reward_value + ''
        }
      )

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.rewardDetail = res
      this.rewardLoading = false
    },

    // 获取活动名称
    getFileName () {
      let _name = ''
      const _obj = this.activityList.find(n => {
        return +n.value === +this.activityId
      })
      _name = `[${_obj.label}]-奖品记录-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },

    // 导出
    async fn_export () {
      this.exportLoading = true
      if (this.params.step === '') {
        this.params.step = null
      }
      const data = await this.axios.get('taskRewerdExport', {
        params: {
          activity_id: this.activityId,
          page: 1,
          per_page: 9999,
          ...this.params
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.exportLoading = false
        this.fn_lyMsg('error', data.msg)
        return false
      }

      this.handleDownload(data.url)
      this.exportLoading = false
    },

    // 下载二维码
    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/table.scss";
@import "@/assets/scss/components/card.scss";
@import "@/assets/scss/components/gap.scss";

.ly-tool {
  background: #fff;
  border-radius: 16px 16px 0 0;
}
::v-deep .q-dialog {
  p {
    margin: 0;
  }

  a {
    color: $--color-primary;
    pointer-events: none;
  }

  &__img {
    img {
      max-width: 300px;
    }
  }

  &__body {
    height: 240px;
  }
}

.reward {
  max-height: 50vh;
  min-width: 360px;
  overflow: auto;

  &-list {
    &__item {
      padding: 14px 0;
      border-bottom: 1px solid #f0f1f2;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }

      .title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .val {
        color: #6e6f73;
      }
    }
  }
}
</style>
