import axios from '@/common/ajax.js'
const { stringify } = require('qs')
// 活动列表
export const finance = {
  reqQuery: params => axios.post('/wecom/order/list', stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }),
  currentInfo: () => axios.get('/wecom/redpacket-activity/current-info')
}

// 充值接口
export const recharge = {
  getQrCode: params => axios.post('/wecom/redpacket-activity/recharge', params)
}
