var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile" }, [
    _c("div", { staticClass: "mobile-topbar" }, [
      _c("div", [_vm._v(_vm._s(_vm.$day().format("HH:mm")))]),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "mobile-titlebar" }, [
      _c("i", { staticClass: "el-icon-arrow-left" }),
      _c("div", { staticClass: "flex flex-column" }, [
        _c("div", { staticClass: "reward-tit" }, [
          _vm._v(_vm._s(_vm.pageTitle))
        ])
      ]),
      _c("i", { staticClass: "el-icon-more" })
    ]),
    _c("div", { staticClass: "mobile-container is-reward" }, [
      _c(
        "div",
        {
          staticClass: "mobile-reward",
          style: {
            backgroundColor:
              _vm.bannerType === 1 ? _vm.defaultColor : _vm.cusColor
          }
        },
        [
          _c(
            "div",
            { staticClass: "mobile-banner" },
            [
              _vm.bannerType === 1
                ? _c("img", { attrs: { src: _vm.defaultBanner, alt: "" } })
                : [
                    _vm.cusBanner
                      ? _c("img", { attrs: { src: _vm.cusBanner, alt: "" } })
                      : _c("div", { staticClass: "mobile-banner__default" }, [
                          _vm._v("请上传活动页首图")
                        ])
                  ],
              _vm.bannerType === 1
                ? _c(
                    "div",
                    { staticClass: "mobile-banner__title" },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", resize: "none" },
                        model: {
                          value: _vm.basicTitle,
                          callback: function($$v) {
                            _vm.basicTitle = $$v
                          },
                          expression: "basicTitle"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._m(1),
          _c(
            "div",
            {
              staticClass: "mobile-rule",
              on: {
                click: function($event) {
                  _vm.ruleShow = true
                }
              }
            },
            [_vm._v("活动规则")]
          ),
          _c("div", { staticClass: "mobile-body" }, [
            _c("div", { staticClass: "mobile-share" }, [
              _c("div", { staticClass: "mobile-share__btn" }, [
                _vm._v("再邀请1人 领取奖品")
              ]),
              _c("div", { staticClass: "mobile-share__time" }, [
                _vm._v(
                  " 活动倒计时：" +
                    _vm._s(_vm.countDown.d > 0 ? _vm.countDown.d : 0) +
                    "天" +
                    _vm._s(_vm.countDown.h > 0 ? _vm.countDown.h : 0) +
                    "时" +
                    _vm._s(_vm.countDown.m > 0 ? _vm.countDown.m : 0) +
                    "分" +
                    _vm._s(_vm.countDown.s > 0 ? _vm.countDown.s : 0) +
                    "秒00 "
                )
              ])
            ]),
            _c("div", { staticClass: "mobile-prize" }, [
              _c("div", { staticClass: "mobile-prize__header" }, [
                _vm._v("兑换奖品")
              ]),
              _c("div", { staticClass: "mobile-prize__list" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "cover" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.firstCover || _vm.defaultCover,
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.firstTitle || "请输入奖品名称"))
                    ]),
                    _vm.firstUnreal && _vm.firstUnreal > 0
                      ? _c("div", { staticClass: "desc" }, [
                          _c("span", [
                            _vm._v(
                              "已有" + _vm._s(_vm.firstUnreal) + "人领走奖品"
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "avatars" },
                            _vm._l(
                              _vm.firstUnreal > 3 ? 3 : _vm.firstUnreal,
                              function(k, v) {
                                return _c("img", {
                                  key: v,
                                  attrs: {
                                    src:
                                      "https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg",
                                    alt: ""
                                  }
                                })
                              }
                            ),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "foot" }, [
                      _c("div", { staticClass: "progress" }, [
                        _vm._m(2),
                        _c("div", { staticClass: "progress-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.firstCount) +
                              "/" +
                              _vm._s(_vm.firstCount) +
                              " "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "btn is-success" }, [
                        _vm._v("领奖")
                      ])
                    ])
                  ])
                ]),
                _vm.secondStatus
                  ? _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "cover" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.secondCover || _vm.defaultCover,
                            alt: ""
                          }
                        })
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.secondTitle || "请输入奖品名称"))
                        ]),
                        _vm.secondUnreal && _vm.secondUnreal > 0
                          ? _c("div", { staticClass: "desc" }, [
                              _c("span", [
                                _vm._v(
                                  "已有" +
                                    _vm._s(_vm.secondUnreal) +
                                    "人领走奖品"
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "avatars" },
                                _vm._l(
                                  _vm.secondUnreal > 3 ? 3 : _vm.secondUnreal,
                                  function(item, index) {
                                    return _c("img", {
                                      key: index,
                                      attrs: {
                                        src:
                                          "https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg",
                                        alt: ""
                                      }
                                    })
                                  }
                                ),
                                0
                              )
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "foot" }, [
                          _c("div", { staticClass: "progress" }, [
                            _c("div", { staticClass: "progress-bar" }, [
                              _c("div", {
                                staticClass: "progress-bar__inner",
                                style: {
                                  width:
                                    (_vm.firstCount / _vm.secondCount) * 100 +
                                    "%"
                                }
                              })
                            ]),
                            _c("div", { staticClass: "progress-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.firstCount) +
                                  "/" +
                                  _vm._s(_vm.secondCount) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "btn" }, [
                            _vm._v(
                              "还差" +
                                _vm._s(_vm.secondCount - _vm.firstCount) +
                                "人"
                            )
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.thirdStatus
                  ? _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "cover" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.thirdCover || _vm.defaultCover,
                            alt: ""
                          }
                        })
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.thirdTitle || "请输入奖品名称"))
                        ]),
                        _vm.thirdUnreal && _vm.thirdUnreal > 0
                          ? _c("div", { staticClass: "desc" }, [
                              _c("span", [
                                _vm._v(
                                  "已有" +
                                    _vm._s(_vm.thirdUnreal) +
                                    "人领走奖品"
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "avatars" },
                                _vm._l(
                                  _vm.thirdUnreal > 3 ? 3 : _vm.thirdUnreal,
                                  function(item, index) {
                                    return _c("img", {
                                      key: index,
                                      attrs: {
                                        src:
                                          "https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg",
                                        alt: ""
                                      }
                                    })
                                  }
                                ),
                                0
                              )
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "foot" }, [
                          _c("div", { staticClass: "progress" }, [
                            _c("div", { staticClass: "progress-bar" }, [
                              _c("div", {
                                staticClass: "progress-bar__inner",
                                style: {
                                  width:
                                    (_vm.firstCount / _vm.thirdCount) * 100 +
                                    "%"
                                }
                              })
                            ]),
                            _c("div", { staticClass: "progress-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.firstCount) +
                                  "/" +
                                  _vm._s(_vm.thirdCount) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "btn" }, [
                            _vm._v(
                              "还差" +
                                _vm._s(_vm.thirdCount - _vm.firstCount) +
                                "人"
                            )
                          ])
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "mobile-rank" }, [
              _vm._m(3),
              _c("div", { staticClass: "mobile-rank__body" }, [
                _c(
                  "div",
                  { staticClass: "mobile-rank__top" },
                  _vm._l(_vm.topList, function(item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("img", {
                          staticClass: "avatar",
                          class: [index === 1 ? "is-top" : "", "is-" + index],
                          attrs: { src: item.avatar, alt: "" }
                        }),
                        index === 0
                          ? _c("img", {
                              class: ["tip", "is-" + index],
                              attrs: {
                                src: require("@assets/images/top2.webp"),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        index === 1
                          ? _c("img", {
                              class: ["tip", "is-" + index],
                              attrs: {
                                src: require("@assets/images/top1.webp"),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        index === 2
                          ? _c("img", {
                              class: ["tip", "is-" + index],
                              attrs: {
                                src: require("@assets/images/top3.webp"),
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _c("div", { staticClass: "count" }, [
                        _vm._v("已邀" + _vm._s(item.count) + "人")
                      ])
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "mobile-rank__list" },
                  [
                    _vm._m(4),
                    _vm._l(_vm.rankList, function(item, index) {
                      return _c("div", { key: index, staticClass: "item" }, [
                        _c("div", { staticClass: "rank" }, [
                          _vm._v(_vm._s(item.rank))
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: { src: item.avatar, alt: "" }
                          }),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ]),
                        _c("div", { staticClass: "invite" }, [
                          _vm._v(_vm._s(item.invite))
                        ])
                      ])
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mobile-prizefix",
          class: [_vm.isFocus ? "is-show" : ""]
        },
        [
          _c("div", { staticClass: "head" }, [_vm._v("领取奖品")]),
          _c(
            "div",
            { staticClass: "body" },
            [
              _vm.type === 1
                ? [
                    _c("div", {
                      staticClass: "content",
                      domProps: {
                        innerHTML: _vm._s(_vm.diyContent || "请输入奖品内容")
                      }
                    }),
                    _vm.prizeCover
                      ? _c("div", { staticClass: "cover" }, [
                          _c("img", { attrs: { src: _vm.prizeCover, alt: "" } })
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.type === 2
                ? [
                    _vm.codeContent
                      ? _c("div", {
                          staticClass: "content",
                          domProps: { innerHTML: _vm._s(_vm.codeContent) }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "code" }, [_vm._v("SJFHEAML")]),
                    _c("div", { staticClass: "copy" }, [
                      _vm._v("点击复制兑换码")
                    ])
                  ]
                : _vm._e(),
              _vm.type === 3
                ? [
                    _vm.formContent
                      ? _c("div", {
                          staticClass: "content",
                          domProps: { innerHTML: _vm._s(_vm.formContent) }
                        })
                      : _vm._e(),
                    _vm._m(5)
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]),
    _vm.isFocus || _vm.ruleShow
      ? _c("div", { staticClass: "mobile-dialog__mask" })
      : _vm._e(),
    _vm.ruleShow
      ? _c("div", { staticClass: "mobile-dialog" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "head" }, [_vm._v("活动规则")]),
              _c("el-input", {
                attrs: { type: "textarea", resize: "none" },
                model: {
                  value: _vm.ruleText,
                  callback: function($$v) {
                    _vm.ruleText = $$v
                  },
                  expression: "ruleText"
                }
              })
            ],
            1
          ),
          _c("i", {
            staticClass: "el-icon-circle-close mobile-dialog__close",
            on: {
              click: function($event) {
                _vm.ruleShow = false
              }
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "iconfont icon-a-CellularConnection" }),
      _c("i", { staticClass: "iconfont icon-Wifi" }),
      _c("i", { staticClass: "iconfont icon-Battery" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-carousel" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: {
          src:
            "https://image.01lb.com.cn//uploads/wecom/21/0804/1628073151TscJerXF.jpg",
          alt: ""
        }
      }),
      _c("span", { staticClass: "text" }, [_vm._v("XX 1分钟前 领走奖品")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress-bar" }, [
      _c("div", { staticClass: "progress-bar__inner" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-rank__tabs" }, [
      _c("div", { staticClass: "item is-active" }, [_vm._v("排行榜")]),
      _c("div", { staticClass: "item" }, [_vm._v("好友助力榜")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "rank" }, [_vm._v("排名")]),
        _c("div", { staticClass: "info" }, [_vm._v("昵称")]),
        _c("div", { staticClass: "invite" }, [_vm._v("邀请人数")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "copy", staticStyle: { "margin-top": "16px" } },
      [
        _vm._v(" 去填写表单，领取奖品 "),
        _c("i", { staticClass: "el-icon-d-arrow-right" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }