<!--
 * @Author: zhanln
 * @Date: 2022-03-11 11:51:32
 * @LastEditTime: 2022-04-14 18:35:38
 * @LastEditors: zhanln
 * @Description: 私聊列表
-->

<template>
  <div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      class="act-list"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 列插槽 -->
      <template #recipient_name="{ row }">
        {{ row.recipient_name }}
        <span class="q-success" v-if="row.recipient_type === 1">{{
          row.recipient_suffix
        }}</span>
        <span class="q-warning" v-else>{{ row.recipient_suffix }}</span>
      </template>

      <template #customer_name="{ row }">
        <ly-tag
          :content="row.customer_name"
          :size="6"
          icon="iconfont icon-a-customerservice q-primary"
        ></ly-tag>
      </template>

      <template #user_name="{ row }">
        <div class="flex">
          <el-image
            class="create-avatar"
            :src="row.user_avatar"
            fit="cover"
            lazy
          ></el-image>
          <span style="flex: 1">
            {{ row.user_name }}
          </span>
        </div>
      </template>

      <template #operation="{ row }">
        <el-button type="text" @click="fn_imList(row)">聊天记录</el-button>
      </template>
    </ly-table>
    <im ref="im" :selfInfo="selfInfo" :chat_id="chat_id"> </im>
  </div>
</template>

<script>
import { recordSingle } from './http.js'
import lyTag from '@/components/lyTag'
import Im from './components/im'

import lyTable from '@/components/mixins/ly-table.js'
export default {
  name: 'privateRecord',
  mixins: [lyTable],

  props: ['cusList'],

  components: {
    Im, lyTag
  },

  data () {
    return {
      COMM_HTTP: recordSingle,
      chat_id: '',
      selfInfo: {}
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 240,
        column: [
          {
            label: '客户昵称',
            prop: 'recipient_name',
            slot: 'recipient_name',
            search: true,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '所属员工',
            slot: 'user_name',
            key: 'user_id',
            search: true,
            type: 'robot',
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '所属客服',
            slot: 'customer_name',
            search: true,
            type: 'select',
            key: 'account_id',
            default: null,
            source: this.cusList,
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '最后聊天时间',
            prop: 'last_chat_date',
            type: 'daterange',
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0
                ? '-'
                : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            },
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '消息数',
            prop: 'chat_num',
            width: 100,
            formatter: (row, column, cellValue, index) => {
              return row.chat_num > 999999
                ? '999999+'
                : row.chat_num
            }
          }
        ]
      }
    }
  },
  methods: {
    fn_imList (row) {
      this.chat_id = row.chat_id
      this.selfInfo = {
        id: row.user_id,
        name: row.user_name,
        avatar: row.user_avatar,
        identity: 'me',
        created_time: row.created_time,
        last_chat_date: row.last_chat_date
      }
      this.$refs.im.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.create-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.searchForm {
  margin: 0 -40px;
  border-bottom: 1px solid #eaebf2;
  padding: 0 20px;
}
.ly-table__pagination {
  display: flex;
  justify-content: flex-end;
}

::v-deep .ly-table-main {
  // padding-top: 20px !important;
  // height: 100%;
  // margin-bottom: 31px !important;
}
.act-list {
  ::v-deep .el-table.el-table--medium td {
    vertical-align: middle;
  }
}
</style>
