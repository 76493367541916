var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile mobile-poster" }, [
    _c(
      "div",
      { staticClass: "mobile-container", staticStyle: { height: "100%" } },
      [
        _vm.posterUrl
          ? _c("div", { staticClass: "mobile-poster-bg" }, [
              _c("img", { attrs: { src: _vm.posterUrl, alt: "" } })
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "mobile-poster-edit",
            staticStyle: { overflow: "hidden" }
          },
          [
            _vm.avatar_status === 1
              ? _c(
                  "vue-draggable-resizable",
                  {
                    staticClass: "dragWrap dragAvatar",
                    attrs: {
                      x: Number(_vm.avatar_x),
                      y: Number(_vm.avatar_y),
                      w: Number(_vm.avatar_width),
                      h: Number(_vm.avatar_height),
                      parent: true,
                      handles: ["br"],
                      "lock-aspect-ratio": true
                    },
                    on: {
                      dragging: function(x, y) {
                        return _vm.onDrag(x, y, "avatar")
                      },
                      resizing: function(x, y, w, h) {
                        return _vm.onResize(w, h, "avatar")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "poster-avatar",
                        class: { "is-radius": _vm.avatar_sharp == 2 }
                      },
                      [_vm._v(" 头像 ")]
                    )
                  ]
                )
              : _vm._e(),
            _vm.nickname_status === 1
              ? _c(
                  "vue-draggable-resizable",
                  {
                    staticClass: "dragWrap",
                    attrs: {
                      x: Number(_vm.nickname_x),
                      y: Number(_vm.nickname_y),
                      w: _vm.name_width,
                      grid: [1, 1],
                      h: "auto",
                      parent: true,
                      handles: [],
                      "lock-aspect-ratio": true
                    },
                    on: {
                      dragging: function(x, y) {
                        return _vm.onDrag(x, y, "nickname")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          color: _vm.nickname_color,
                          "font-size": _vm.nickname_size + "px",
                          "text-align": _vm.nickname_align
                        }
                      },
                      [_vm._v(" 用户昵称 ")]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "vue-draggable-resizable",
              {
                staticClass: "dragWrap dragQrcode",
                attrs: {
                  x: Number(_vm.qrcode_x),
                  y: Number(_vm.qrcode_y),
                  w: Number(_vm.qrcode_width),
                  h: Number(_vm.qrcode_height),
                  parent: true,
                  handles: ["br"],
                  "lock-aspect-ratio": true
                },
                on: {
                  dragging: function(x, y) {
                    return _vm.onDrag(x, y, "qrcode")
                  },
                  resizing: function(x, y, w, h) {
                    return _vm.onResize(w, h, "qrcode")
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@assets/images/contact_me_qr.png") }
                })
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }