var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-table", {
    attrs: { data: _vm.tableData, option: _vm.tableOpt },
    on: { searchChange: _vm.searchChange },
    scopedSlots: _vm._u([
      {
        key: "name_slot",
        fn: function(ref) {
          var row = ref.row
          return [
            _c("div", { staticClass: "flex flex-start" }, [
              row.avatar
                ? _c("img", {
                    staticClass: "ly-table__main-avatar",
                    attrs: { src: row.avatar, alt: "" }
                  })
                : _c("img", {
                    staticClass: "ly-table__main-avatar",
                    attrs: { src: _vm.defaultAvatar, alt: "" }
                  }),
              _c("div", [_vm._v(" " + _vm._s(row.name) + " ")])
            ])
          ]
        }
      },
      {
        key: "status_slot",
        fn: function(ref) {
          var row = ref.row
          return [
            row.status === 0 || row.status === 2
              ? _c("ly-status", { attrs: { type: "info" } }, [_vm._v("离线")])
              : _vm._e(),
            row.status === 1
              ? _c("ly-status", { attrs: { type: "success" } }, [
                  _vm._v("在线")
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "operation",
        fn: function(ref) {
          var row = ref.row
          return [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  placement: "top",
                  "popper-class": "q-tooltip",
                  disabled: row.status === 1,
                  content: "当前账号已离线，不可操作"
                }
              },
              [
                _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.status === 0 || row.status === 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.fn_unFocus(row)
                          }
                        }
                      },
                      [_vm._v("取消托管")]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }