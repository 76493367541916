var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reward-drawer", class: { "is-top": _vm.reward_focus } },
    [
      _vm.reward_focus
        ? _c("div", { staticClass: "reward-drawer__mask" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "reward-drawer__box",
          class: { show: _vm.reward_focus }
        },
        [
          _c("div", { staticClass: "reward-drawer__title" }, [
            _vm._v("领取奖品")
          ]),
          _vm.reward_type === 1
            ? [
                _c("div", { staticClass: "reward-drawer__body" }, [
                  _c("div", {
                    staticClass: "reward-drawer__detail",
                    domProps: { innerHTML: _vm._s(_vm.diy_content) }
                  }),
                  _c("div", { staticClass: "reward-drawer__img" }, [
                    _vm.diy_img
                      ? _c("img", { attrs: { src: _vm.diy_img, alt: "" } })
                      : _vm._e()
                  ])
                ])
              ]
            : _vm._e(),
          _vm.reward_type === 2
            ? [
                _c("div", { staticClass: "reward-drawer__body" }, [
                  _c("div", {
                    staticClass: "reward-drawer__detail",
                    domProps: { innerHTML: _vm._s(_vm.code_rule) }
                  })
                ]),
                _c("div", { staticClass: "reward-drawer__code" }, [
                  _vm._v("SJFHEAML")
                ]),
                _c("div", { staticClass: "reward-drawer__btn" }, [
                  _vm._v("点击复制兑换码")
                ])
              ]
            : _vm._e(),
          _vm.reward_type === 3
            ? [
                _c("div", { staticClass: "reward-drawer__body" }, [
                  _c("div", {
                    staticClass: "reward-drawer__detail",
                    domProps: { innerHTML: _vm._s(_vm.form_rule) }
                  })
                ]),
                _vm._m(0)
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reward-drawer__btn" }, [
      _vm._v(" 去填写表单，领取奖品 "),
      _c("i", { staticClass: "el-icon-d-arrow-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }