var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wecom-tag" },
    [
      _c("div", { staticClass: "wecom-tag__selected" }, [
        _vm.title
          ? _c("div", { staticClass: "wecom-tag__title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          : _vm._e(),
        _c(
          "div",
          [
            _vm._t("prefix"),
            _c(
              "el-button",
              {
                attrs: {
                  round: "",
                  plain: "",
                  type: "primary",
                  icon: "iconfont icon-plus"
                },
                on: { click: _vm.fn_tagSelect }
              },
              [_vm._v("选择标签")]
            ),
            _vm._t("suffix")
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "wecom-tag__selected-list" },
          _vm._l(_vm.checkList, function(tag, index) {
            return _c(
              "span",
              { key: index, staticClass: "wecom-tag__selected-item" },
              [
                _vm._v(" " + _vm._s(tag.tagName)),
                _c("i", {
                  staticClass: "iconfont icon-close-fill",
                  on: {
                    click: function($event) {
                      return _vm.fn_removeTag(index)
                    }
                  }
                })
              ]
            )
          }),
          0
        )
      ]),
      _c("tag-select-v2", {
        ref: "tagSelectRef",
        attrs: { checked: _vm.checked },
        on: { getSelect: _vm.fn_getSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }