<!--
  模拟私聊、群聊界面
-->
<template>
  <div class="mobile">
    <div class="mobile-topbar">
      <div>{{ $day().format('HH:mm')}}</div>
      <div class="flex">
        <i class="iconfont icon-a-CellularConnection"></i>
        <i class="iconfont icon-Wifi"></i>
        <i class="iconfont icon-Battery"></i>
      </div>
    </div>
    <div class="mobile-titlebar">
      <i class="el-icon-arrow-left"></i>
      <div class="flex flex-column">
        <template v-if="type === 'private'">
          <span>张三</span>
          <span class="text-small">零一裂变（深圳）科技有限公司</span>
        </template>
        <template v-else>
          <span>群名称</span>
        </template>
      </div>
      <i class="el-icon-more"></i>
    </div>
    <el-scrollbar class="mobile-container">
      <div
        class="flex flex-column q-info text-small"
        style="line-height: 2; margin-top: 12px"
      >
        <template v-if="type === 'private'">
          <span>您已添加了张三，现在可以开始聊天了。</span>
          <span>对方为企业微信用户，<span style="color: #000">了解更多。</span></span>
        </template>
        <template v-else><span>{{$day(Date.now()).format('YYYY年MM月DD HH:mm:ss')}}</span></template>
      </div>
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div class="mobile-contact" v-for="item in msg">
        <div class="mobile-contact__avatar">
          <img src="@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <!-- 文本类型 -->
          <el-input
            v-if="item.msg_type === 2001"
            type="textarea"
            :value="at ? `@所有人 ${item.msg_content}` : item.msg_content "
            autosize
            resize="none"
            readonly
            ref="refRule"
          ></el-input>

          <!-- 欢迎语：文本类型 -->
          <el-input
            v-if="item.messageType === 4"
            type="textarea"
            :value="item.welcomeContent"
            autosize
            resize="none"
            readonly
            ref="refRule"
          ></el-input>
          <!-- 图片类型 -->
          <el-image v-if="item.msg_type === 2002 || item.messageType === 1"  class="type-pic" :src="item.msg_content || item.imageUrl" fit="contain"></el-image>
          <!-- 视频 -->
          <div class="type-video" v-if="item.msg_type === 2004">
            <video :src="item.href"></video>
            <img class="play-btn" src="@/assets/images/play.png">
          </div>
          <!-- 文件 -->
          <div class="type-file flex" v-if="item.msg_type === 2010">
            <div class="file-temp">
              <span class="file-title">{{ item.title }}</span>
              <img class="file-icon" src="@/assets/images/doc.png"/>
            </div>
          </div>
          <!-- 链接 -->
          <div class="link" v-if="item.msg_type === 2005 || item.messageType === 2">
            <div class="link-title">{{ item.title || item.linkTitle }}</div>
            <div class="link-bottom">
              <div class="link-desc">{{ item.desc || item.linkDsc }}</div>
              <el-image style="width: 48px;height: 48px" :src="item.msg_content || item.linkPicUrl" fit="contain"></el-image>
            </div>
          </div>
            <!-- 小程序 -->
          <div v-if="item.msg_type === 2013 || item.messageType === 3">
            <app-poster :applet="item"/>
          </div>
            <!-- 群公告 -->
          <div class="announcement" v-if="!item.msg_type && !item.messageType">
            <div class="title">@所有人</div>
            <div v-emoji="item.msg_content"></div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import AppPoster from '@/components/AppPoster.vue'
import emoji from '@/components/emoji/emoji.js'
export default {
  name: 'mobile',
  components: {
    AppPoster
  },
  directives: {
    emoji
  },
  props: {
    // 聊天类型：private私聊  group群聊
    type: {
      type: String,
      default: 'private'
    },
    msg: {
      type: Array,
      default: () => []
    },
    // 是否@所有人
    at: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";
.mobile{
  display: flex;
  flex-direction: column;
}
.mobile-contact__dialog{
  overflow: hidden;
}
.type-pic{
  border-radius: 4px;
  ::v-deep{
    .el-image__inner{
      max-height: 120px;
      width: unset;
    }
  }
}
.type-video{
  position: relative;
  display: inline-block;
  video{
    height: 120px;
    border-radius: 4px;
  }
  .play-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.file-temp{
  width: 190px;
  height: 56px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  .file-title{
    flex: 1;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #454D5B
  }
  .file-icon{
    width: 30px;
    height: 30px;
    margin-left: 20px;
  }
}
.link{
  border-radius: 4px;
  line-height: 1.3;
  padding: 10px 12px;
  background: #fff;
  .link-title{
    color: rgba(0,0,0,0.85);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
  }
  .link-bottom{
    display: flex;
    margin-top: 8px;
    .link-desc{
      flex: 1;
      margin-right: 15px;
      color: #6E788A;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.4;
      word-break: break-word
    }
    .el-image{
      border-radius: 2px;
    }
  }
}
.announcement{
  background: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
}
::v-deep{
  .el-scrollbar__wrap{
    overflow-x: hidden;
  }
  .applet{
    border: unset;
  }
}
</style>
