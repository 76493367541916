var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arg" }, [
      _c("header", [
        _c("img", {
          attrs: { src: require("@assets/svg/lylb.svg"), alt: "", height: "24" }
        })
      ]),
      _c("div", { staticClass: "arg-main" }, [
        _c("div", { staticClass: "arg-header" }, [_vm._v("用户协议")]),
        _c("div", { staticClass: "arg-content" }, [
          _c("p", [_vm._v("1")]),
          _c("p", [_vm._v("2")]),
          _c("p", [_vm._v("3")]),
          _c("p", [_vm._v("4")]),
          _c("p", [_vm._v("5")]),
          _c("p", [_vm._v("6")]),
          _c("p", [_vm._v("7")]),
          _c("p", [_vm._v("8")]),
          _c("p", [_vm._v("9")]),
          _c("p", [_vm._v("10")]),
          _c("p", [_vm._v("11")]),
          _c("p", [_vm._v("12")]),
          _c("p", [_vm._v("13")]),
          _c("p", [_vm._v("14")]),
          _c("p", [_vm._v("15")]),
          _c("p", [_vm._v("16")]),
          _c("p", [_vm._v("17")]),
          _c("p", [_vm._v("18")]),
          _c("p", [_vm._v("19")]),
          _c("p", [_vm._v("20")]),
          _c("p", [_vm._v("21")]),
          _c("p", [_vm._v("22")]),
          _c("p", [_vm._v("23")]),
          _c("p", [_vm._v("24")]),
          _c("p", [_vm._v("25")]),
          _c("p", [_vm._v("26")]),
          _c("p", [_vm._v("27")]),
          _c("p", [_vm._v("28")]),
          _c("p", [_vm._v("29")]),
          _c("p", [_vm._v("30")]),
          _c("p", [_vm._v("31")]),
          _c("p", [_vm._v("32")]),
          _c("p", [_vm._v("33")]),
          _c("p", [_vm._v("34")]),
          _c("p", [_vm._v("35")]),
          _c("p", [_vm._v("36")]),
          _c("p", [_vm._v("37")]),
          _c("p", [_vm._v("38")]),
          _c("p", [_vm._v("39")]),
          _c("p", [_vm._v("40")]),
          _c("p", [_vm._v("41")]),
          _c("p", [_vm._v("42")]),
          _c("p", [_vm._v("43")]),
          _c("p", [_vm._v("44")]),
          _c("p", [_vm._v("45")]),
          _c("p", [_vm._v("46")]),
          _c("p", [_vm._v("47")]),
          _c("p", [_vm._v("48")]),
          _c("p", [_vm._v("49")]),
          _c("p", [_vm._v("50")]),
          _c("p", [_vm._v("51")]),
          _c("p", [_vm._v("52")]),
          _c("p", [_vm._v("53")]),
          _c("p", [_vm._v("54")]),
          _c("p", [_vm._v("55")]),
          _c("p", [_vm._v("56")]),
          _c("p", [_vm._v("57")]),
          _c("p", [_vm._v("58")]),
          _c("p", [_vm._v("59")]),
          _c("p", [_vm._v("60")]),
          _c("p", [_vm._v("61")]),
          _c("p", [_vm._v("62")]),
          _c("p", [_vm._v("63")]),
          _c("p", [_vm._v("64")]),
          _c("p", [_vm._v("65")]),
          _c("p", [_vm._v("66")]),
          _c("p", [_vm._v("67")]),
          _c("p", [_vm._v("68")]),
          _c("p", [_vm._v("69")]),
          _c("p", [_vm._v("70")]),
          _c("p", [_vm._v("71")]),
          _c("p", [_vm._v("72")]),
          _c("p", [_vm._v("73")]),
          _c("p", [_vm._v("74")]),
          _c("p", [_vm._v("75")]),
          _c("p", [_vm._v("76")]),
          _c("p", [_vm._v("77")]),
          _c("p", [_vm._v("78")]),
          _c("p", [_vm._v("79")]),
          _c("p", [_vm._v("80")]),
          _c("p", [_vm._v("81")]),
          _c("p", [_vm._v("82")]),
          _c("p", [_vm._v("83")]),
          _c("p", [_vm._v("84")]),
          _c("p", [_vm._v("85")]),
          _c("p", [_vm._v("86")]),
          _c("p", [_vm._v("87")]),
          _c("p", [_vm._v("88")]),
          _c("p", [_vm._v("89")]),
          _c("p", [_vm._v("90")]),
          _c("p", [_vm._v("91")]),
          _c("p", [_vm._v("92")]),
          _c("p", [_vm._v("93")]),
          _c("p", [_vm._v("94")]),
          _c("p", [_vm._v("95")]),
          _c("p", [_vm._v("96")]),
          _c("p", [_vm._v("97")]),
          _c("p", [_vm._v("98")]),
          _c("p", [_vm._v("99")]),
          _c("p", [_vm._v("100")])
        ])
      ]),
      _c("footer", [
        _vm._v("Copyright ©2020 Powered by 零一裂变 Version 1.0.0")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }