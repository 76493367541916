// github: https://github.com/SheetJS/sheetjs


import { Message } from 'element-ui'
import XLSX from 'xlsx'
import util from './util'

/**
 * 数据源导出excel
 * @param {element} tableEl 表单元素(如果传了header以及keyData和sourceData,此参数可不传)
 *                  tableEl 也可以直接传这个元素，这样就会默认将table的所有数据都导出
 * @param {Array[<>]} sourceData 数据源，使用二维数组，支持数组<对象>或者数组<基础类型>
 * @param {string} xlsxName 文件名字
 * @param {string} sheetName xlsx工作表名字
 * @param {string[]} header xlsx标题头(不传，获取tableEl下的label标签信息)
 * @param {string[]} filterVal 需要提取的数据字段(不传，获取tableEl下的property标签信息)
 * @param {string[]} totalData 合计信息
 */
export function exportExcelHandle () {
  sheet2blob(arguments[0])
}

export function sheet2blob ({ tableEl, sourceData, xlsxName, sheetName, header, filterVal, totalData } = {}) {

  if (!tableEl && !filterVal) {
    console.warn('数据不完整')
    return
  }

  // if (!tableEl && !filterVal) {
  //   console.warn('数据不完整')
  //   return
  // }

  if (filterVal && header && !sourceData) {
    console.warn('未传数据源')
    return
  }

  if (!xlsxName) {
    console.warn('文件名必填')
    return
  }


  const data = mergeData(tableEl, sourceData, header, filterVal)
  if (!!totalData) {
    data.push(totalData)
  }
  const sheet = XLSX.utils.aoa_to_sheet(data);

  const result = getColWidth(data)
  sheet['!cols'] = result;

  sheetName = sheetName || 'sheet1';
  let workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  let wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary'
  };
  let wbout = XLSX.write(workbook, wopts);
  let blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // 字符串转ArrayBuffer
  function s2ab (s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  openDownloadDialog(blob, xlsxName)
}

// 组装导出数据
const mergeData = (tableEl, sourceData, header, filterVal) => {


  if (!header) {
    header = tableEl.columns.map(item => item.label)
  }

  filterVal = filterVal || []

  let data = [header]

  if (!filterVal.length) {
    for (let j = 0; j < header.length; j++) {
      for (let i = 0; i < tableEl.columns.length; i++) {
        if (header[j] == tableEl.columns[i].label) {
          filterVal.push(tableEl.columns[i].property || 0)
          break;
        }
      }
    }
  }


  if (!sourceData) {
    for (let i = 0; i < tableEl.data.length; i++) {
      let _arr = []
      for (let j = 0; j < filterVal.length; j++) {

        if (tableEl.data[i][filterVal[j]] || tableEl.data[i][filterVal[j]] == 0) {
          _arr.push(tableEl.data[i][filterVal[j]] || 0)
        }

        else if (!tableEl.data[i][filterVal[j]]) {
          _arr.push(null)
        }
      }
      data.push(_arr)
    }
  } else {
    // 取第一个来判断他是array[string]还是array[object]
    let _x = sourceData[0]
    if (util.type(_x) == 'string') {
      sourceData.forEach(item => data.push(item.slice(0, header.length)))
    } else if (util.type(_x) == 'object') {
      console.log(_x)
      for (let j = 0; j < sourceData.length; j++) {
        let _arr = []
        for (let i = 0; i < filterVal.length; i++) {
          if (sourceData[j][filterVal[i]] || sourceData[j][filterVal[i]] == 0) {
            _arr.push(sourceData[j][filterVal[i]] || 0)
          } else if (!sourceData[j][filterVal[i]]) {
            _arr.push(null)
          }

        }
        data.push(_arr)
      }
    }
  }

  return data
}

// 下载
export function openDownloadDialog (url, saveName) {
  if (typeof url == 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  var aLink = document.createElement('a');
  aLink.href = url;
  aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if (window.MouseEvent) event = new MouseEvent('click');
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }
  aLink.dispatchEvent(event);

  Message.success('导出成功')

}


// 获取每一列最长的宽度
export function getColWidth (data) {
  const colWidth = data.map(row => row.map(val => {
    /*先判断是否为null/undefined*/
    if (val == null) {
      return {
        'wch': 10
      };
    }
    /*再判断是否为中文*/
    else if (val.toString().charCodeAt(0) > 255) {
      return {
        'wch': val.toString().length * 2
      };
    } else {
      return {
        'wch': val.toString().length
      };
    }
  }))
  /*以第一行为初始值*/
  let result = colWidth[0];
  for (let i = 1; i < colWidth.length; i++) {
    for (let j = 0; j < colWidth[i].length; j++) {
      if (result[j]['wch'] < colWidth[i][j]['wch']) {
        result[j]['wch'] = colWidth[i][j]['wch'];
      }
    }
  }

  return result
}