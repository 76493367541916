/*
 * @Author: zhanln
 * @Date: 2021-08-16 16:48:58
 * @LastEditTime: 2021-08-19 10:37:06
 * @LastEditors: zhanln
 * @Description:
 */
const mx_role = {
  data () {
    return {
      pageActions: null
    }
  },
  created () {
    const _pageActions = this.$store.state.global.pageActions
    this.pageActions = _pageActions || {}
  }
}

export default mx_role
