var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "phone-dialog",
      attrs: {
        visible: _vm.visible,
        "custom-class": "q-dialog",
        width: "420px",
        "modal-append-to-body": false,
        "append-to-body": "",
        "show-close": false,
        top: "10vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "phone" }, [
        _c(
          "div",
          {
            staticClass: "phone-close",
            on: {
              click: function($event) {
                _vm.visible = false
              }
            }
          },
          [_c("i", { staticClass: "el-icon-circle-close" })]
        ),
        _c(
          "div",
          {
            staticClass: "phone-view",
            class: [_vm.link.type === 2 ? "is-high" : ""]
          },
          [
            _vm.type === 1
              ? _c("ly-mobile", { attrs: { msgList: _vm.msgList } })
              : _c(
                  "ly-mobile",
                  {
                    attrs: {
                      showWel: false,
                      sub: "",
                      title: "",
                      hasFooter: false
                    }
                  },
                  [
                    _c("div", { staticClass: "qrlink" }, [
                      _c("div", { staticClass: "qrlink-carousel" }, [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: require("@assets/images/avatar.jpg"),
                            alt: ""
                          }
                        }),
                        _vm._v(" 用户 192****1274 已添加好友 ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "qrlink-body" },
                        [
                          _vm.link.type === 1
                            ? [
                                _c("div", { staticClass: "bg" }, [
                                  _c("div", {
                                    staticClass: "title",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.link.linkContent)
                                    }
                                  }),
                                  _c("img", {
                                    staticClass: "code",
                                    attrs: {
                                      src: _vm.link.liveCodeUrl,
                                      alt: ""
                                    }
                                  }),
                                  _c("div", { staticClass: "check" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@assets/svg/protect.svg"),
                                        alt: ""
                                      }
                                    }),
                                    _vm._v("二维码已通过检测，请放心扫码 ")
                                  ]),
                                  _c("div", { staticClass: "tip" }, [
                                    _vm._v("长按识别二维码")
                                  ])
                                ])
                              ]
                            : _c(
                                "div",
                                { staticClass: "qrlink-poster" },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "qrlink-poster__bg",
                                      attrs: {
                                        src: _vm.link.poster,
                                        fit: "cover"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-back"
                                          }),
                                          _vm._v("请上传链接页背景 ")
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                        ],
                        2
                      ),
                      _c("div", { staticClass: "qrlink-footer" }, [
                        _vm.link.contactStatus
                          ? _c("div", { staticClass: "contact" }, [
                              _vm._v(" 若以上二维码无法添加， "),
                              _c(
                                "span",
                                {
                                  staticClass: "flex",
                                  staticStyle: { color: "#2d67cf" }
                                },
                                [
                                  _vm._v("请"),
                                  _c("img", {
                                    staticClass: "msg",
                                    attrs: {
                                      src: require("@assets/svg/message.svg"),
                                      alt: ""
                                    }
                                  }),
                                  _vm._v("联系主办方")
                                ]
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "copyright",
                            staticStyle: { color: "#2d67cf" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@assets/images/01logo_circle.webp"),
                                alt: ""
                              }
                            }),
                            _vm._v("零一裂变 | 仅提供技术支持 "),
                            _c("i", { staticClass: "el-icon-arrow-right" })
                          ]
                        )
                      ]),
                      _c("img", {
                        staticClass: "bg-right",
                        attrs: {
                          src: require("@assets/images/qr_link_bg_fi.png"),
                          alt: ""
                        }
                      }),
                      _c("img", {
                        staticClass: "bg-left",
                        attrs: {
                          src: require("@assets/images/qr_link_bg_fi_left.png"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("div", { staticClass: "qrlink-fixed" }, [
                      _vm.link.showVirtual
                        ? _c(
                            "div",
                            { staticClass: "qrlink-expire" },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@assets/svg/alarm-clock.svg"),
                                  alt: ""
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "q-info",
                                  staticStyle: { "margin-right": "4px" }
                                },
                                [_vm._v(_vm._s(_vm.link.virtualContent))]
                              ),
                              _vm.link.unreal_expire_day > 0
                                ? [
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(_vm._s(_vm.link.unreal_expire_day))
                                    ]),
                                    _vm._v("天 ")
                                  ]
                                : _vm._e(),
                              _vm.link.unreal_expire_day +
                                _vm.link.unreal_expire_hour >
                              0
                                ? [
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(
                                        _vm._s(_vm.link.unreal_expire_hour)
                                      )
                                    ]),
                                    _vm._v("时 ")
                                  ]
                                : _vm._e(),
                              _vm.link.unreal_expire_day +
                                _vm.link.unreal_expire_hour +
                                _vm.link.unreal_expire_minute >
                              0
                                ? [
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(
                                        _vm._s(_vm.link.unreal_expire_minute)
                                      )
                                    ]),
                                    _vm._v("分 ")
                                  ]
                                : _vm._e(),
                              _vm.link.unreal_expire_day +
                                _vm.link.unreal_expire_hour +
                                _vm.link.unreal_expire_minute +
                                _vm.link.unreal_expire_second >
                              0
                                ? [
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(
                                        _vm._s(_vm.link.unreal_expire_second)
                                      )
                                    ]),
                                    _vm._v("秒 ")
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ]),
                    _vm.link.showVirtual
                      ? _c("div", { staticClass: "qrlink-fixed__place" })
                      : _vm._e()
                  ]
                )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }