var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "rewardFormRef",
      attrs: {
        model: _vm.rewardForm,
        rules: _vm.rules,
        "label-width": "125px",
        size: "medium"
      }
    },
    [
      _vm.order !== "first"
        ? _c(
            "el-form-item",
            {
              staticClass: "reward-form__item",
              attrs: { label: "任务状态", prop: "status" }
            },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      disabled: _vm.isEdit && !_vm.isCopy && !_vm.isDaft
                    },
                    on: { change: _vm.fn_changeStatus },
                    model: {
                      value: _vm.rewardForm.status,
                      callback: function($$v) {
                        _vm.$set(_vm.rewardForm, "status", $$v)
                      },
                      expression: "rewardForm.status"
                    }
                  }),
                  _c("span", { staticStyle: { "margin-left": "8px" } }, [
                    _vm._v(
                      _vm._s(_vm.rewardForm.status === 0 ? "关闭" : "开启")
                    )
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.rewardForm.status === 1
        ? [
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item",
                attrs: {
                  label: "任务人数",
                  error: _vm.targetCountError,
                  required: ""
                }
              },
              [
                _c("el-input-number", {
                  staticClass: "number-big",
                  attrs: {
                    "controls-position": "right",
                    min: 1,
                    max: 100,
                    precision: 0,
                    placeholder: "目标邀请人数",
                    disabled: _vm.isEdit && !_vm.isCopy && !_vm.isDaft
                  },
                  on: { change: _vm.fn_checkTarget },
                  model: {
                    value: _vm.rewardForm.target_count,
                    callback: function($$v) {
                      _vm.$set(_vm.rewardForm, "target_count", $$v)
                    },
                    expression: "rewardForm.target_count"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item",
                attrs: { label: "任务达成标签" }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex", staticStyle: { height: "36px" } },
                  [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: { change: _vm.fn_handleTagStatus },
                      model: {
                        value: _vm.rewardForm.tag_open,
                        callback: function($$v) {
                          _vm.$set(_vm.rewardForm, "tag_open", $$v)
                        },
                        expression: "rewardForm.tag_open"
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "q-info",
                        staticStyle: {
                          "line-height": "20px",
                          "margin-left": "10px"
                        }
                      },
                      [_vm._v("开启后，可自动为任务达成用户打标签")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rewardForm.tag_open,
                        expression: "rewardForm.tag_open"
                      }
                    ]
                  },
                  [
                    _c("tag-select-v2", {
                      attrs: { checked: _vm.rewardForm.finish_task_tags },
                      on: { getTagId: _vm.fn_getFinishIds }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item",
                attrs: { label: "奖品信息", prop: "reward_title" }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex reward" },
                  [
                    _c(
                      "el-upload",
                      {
                        class: [
                          "upload",
                          _vm.rewardForm.reward_coverList.length === 0
                            ? ""
                            : "upload-empty"
                        ],
                        attrs: {
                          action: "#",
                          accept: ".png, .jpg, .jpeg",
                          "http-request": _vm.requestCover,
                          "on-remove": _vm.removeCover,
                          "list-type": "picture-card",
                          limit: 1,
                          "file-list": _vm.rewardForm.reward_coverList
                        }
                      },
                      [
                        _c("div", { staticClass: "default" }, [
                          _c("i", { staticClass: "iconfont icon-picture" }),
                          _c("span", [_vm._v("奖品封面")])
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-column reward-info" },
                      [
                        _c("el-input", {
                          ref: "rewardTitleRef" + _vm.order,
                          staticClass: "reward-info__title",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "奖品名称，必填",
                            maxlength: "10",
                            "show-word-limit": ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setForm($event, "reward_title")
                            }
                          },
                          model: {
                            value: _vm.rewardForm.reward_title,
                            callback: function($$v) {
                              _vm.$set(_vm.rewardForm, "reward_title", $$v)
                            },
                            expression: "rewardForm.reward_title"
                          }
                        }),
                        _c("el-input", {
                          staticClass: "reward-info__desc",
                          attrs: {
                            type: "textarea",
                            resize: "none",
                            placeholder: "奖品简介，选填",
                            maxlength: "40",
                            "show-word-limit": ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.fn_setForm($event, "reward_desc")
                            }
                          },
                          model: {
                            value: _vm.rewardForm.reward_desc,
                            callback: function($$v) {
                              _vm.$set(_vm.rewardForm, "reward_desc", $$v)
                            },
                            expression: "rewardForm.reward_desc"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item",
                attrs: {
                  label: "虚拟完成人数",
                  error: _vm.unrealError,
                  required: ""
                }
              },
              [
                _c("el-input-number", {
                  staticClass: "number-big",
                  attrs: {
                    "controls-position": "right",
                    min: 0,
                    max: 99999,
                    precision: 0,
                    placeholder: "虚拟完成人数"
                  },
                  on: { change: _vm.fn_checkUnreal },
                  model: {
                    value: _vm.rewardForm.unreal,
                    callback: function($$v) {
                      _vm.$set(_vm.rewardForm, "unreal", $$v)
                    },
                    expression: "rewardForm.unreal"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item",
                attrs: {
                  label: "奖品内容",
                  required: "",
                  error: _vm.detailError
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "16px" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: {
                          disabled: _vm.isEdit && !_vm.isCopy && !_vm.isDaft
                        },
                        on: { change: _vm.fn_changeDetailRadio },
                        model: {
                          value: _vm.rewardForm.reward_type,
                          callback: function($$v) {
                            _vm.$set(_vm.rewardForm, "reward_type", $$v)
                          },
                          expression: "rewardForm.reward_type"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("自定义")
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("兑换码")
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("表单")
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              placement: "top",
                              content: "功能准备上线中，敬请期待",
                              "popper-class": "q-tooltip"
                            }
                          },
                          [
                            _c(
                              "el-radio",
                              { attrs: { label: 7, disabled: "" } },
                              [_vm._v("核销码")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rewardForm.reward_type === 1,
                        expression: "rewardForm.reward_type === 1"
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "reward-detail",
                          _vm.detailError !== "" ? "error" : ""
                        ]
                      },
                      [
                        _c("wang-editor", {
                          staticClass: "wangEditor",
                          attrs: {
                            refName: "detailRef_" + _vm.order,
                            content: _vm.rewardForm.reward_detail,
                            menus: _vm.editorMenus,
                            placeholder: "支持输入文本，复制链接",
                            setCont: function(html) {
                              _vm.fn_setDetail(html, "diy")
                            }
                          },
                          on: {
                            eFocus: _vm.fn_detailFocus,
                            eBlur: _vm.fn_detailBlur,
                            getText: _vm.fn_getText1
                          }
                        }),
                        _c("div", { staticClass: "reward-detail__count" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.detailText.length) +
                              "/" +
                              _vm._s(_vm.detailLength) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "reward-detail__upload" },
                          [
                            _c(
                              "el-upload",
                              {
                                class: [
                                  "upload",
                                  _vm.rewardForm.reward_detail_imgList
                                    .length === 0
                                    ? ""
                                    : "upload-empty"
                                ],
                                attrs: {
                                  action: "#",
                                  accept: ".png, .jpg, .jpeg",
                                  "http-request": _vm.requestContentImg,
                                  "on-remove": _vm.removeContentImg,
                                  "list-type": "picture-card",
                                  limit: 1,
                                  "file-list":
                                    _vm.rewardForm.reward_detail_imgList
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-column flex-center",
                                    staticStyle: {
                                      "line-height": "22px",
                                      height: "100%"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-plus",
                                      staticStyle: { "font-size": "20px" }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text q-info text-small" },
                                      [_vm._v("上传图片")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          placement: "top",
                          "popper-class": "q-tooltip is-light"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "24px 24px 16px",
                                  width: "528px"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://image.01lb.com.cn//uploads/wecom/22/0209/1644375062Jh5fIxJa.png",
                                    width: "528",
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "q-primary text-small inline-flex",
                            staticStyle: {
                              "line-height": "1.6",
                              "margin-bottom": "0"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-help1",
                              staticStyle: { "margin-right": "2px" }
                            }),
                            _vm._v(" 用户完成任务后，如何领取奖品 ")
                          ]
                        )
                      ]
                    ),
                    _vm.urlTips
                      ? _c(
                          "p",
                          {
                            staticClass: "q-info text-small",
                            staticStyle: {
                              "line-height": "1.6",
                              "margin-bottom": "0"
                            }
                          },
                          [
                            _vm._v(
                              " 请确保奖品链接页面无法复制分享，或使用人工审核的表单类链接，避免奖品内容被分享传播，造成损失或用户投诉。 "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rewardForm.reward_type === 2,
                        expression: "rewardForm.reward_type === 2"
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "reward-type__select",
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              disabled: _vm.isEdit && !_vm.isCopy && !_vm.isDaft
                            },
                            on: { change: _vm.fn_codeChange },
                            model: {
                              value: _vm.rewardForm.reward_type_id,
                              callback: function($$v) {
                                _vm.$set(_vm.rewardForm, "reward_type_id", $$v)
                              },
                              expression: "rewardForm.reward_type_id"
                            }
                          },
                          _vm._l(_vm.codeList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.title, value: item.id }
                            })
                          }),
                          1
                        ),
                        !_vm.rewardForm.reward_type_id
                          ? _c("span", { staticClass: "reward-code__error" }, [
                              _vm._v("请选择兑换码")
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "q-info text-small",
                            staticStyle: {
                              "line-height": "24px",
                              "margin-bottom": "16px"
                            }
                          },
                          [
                            !_vm.isEdit || _vm.isCopy
                              ? [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "16px" } },
                                    [_vm._v("选择要关联活动的兑换码奖品")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "flex",
                                      staticStyle: { display: "inline-flex" }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.fn_jumpToCode("code")
                                            }
                                          }
                                        },
                                        [_vm._v("去创建兑换码")]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "el-divider el-divider--vertical",
                                        attrs: { "data-v-3cefa108": "" }
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "lydebounce",
                                              rawName: "v-lydebounce",
                                              value: [
                                                "button",
                                                _vm.fn_getCodeList
                                              ],
                                              expression:
                                                "['button', fn_getCodeList]"
                                            }
                                          ],
                                          attrs: { type: "text", size: "mini" }
                                        },
                                        [_vm._v("刷新")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "rules reward-type__select",
                        staticStyle: { border: "none" }
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "reward-detail",
                              _vm.detailError !== "" ? "error" : ""
                            ]
                          },
                          [
                            _c("wang-editor", {
                              staticClass: "wangEditor wangEditor-code",
                              attrs: {
                                refName: "codeRulesRef" + _vm.order,
                                content: _vm.rewardForm.codeRules,
                                menus: ["link"],
                                placeholder: "请输入兑换规则",
                                setCont: function(html) {
                                  _vm.fn_setDetail(html, "code")
                                }
                              },
                              on: {
                                eFocus: _vm.fn_detailFocus,
                                eBlur: _vm.fn_detailBlur,
                                getText: _vm.fn_getText2
                              }
                            }),
                            _c("div", { staticClass: "rules-footer" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "rules-footer-inset default",
                                  on: {
                                    click: function($event) {
                                      return _vm.resetRules("code")
                                    }
                                  }
                                },
                                [_vm._v(" 恢复默认文本 ")]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.ruleText.length) + "/1000 "
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          placement: "top",
                          "popper-class": "q-tooltip is-light"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "24px 24px 16px",
                                  width: "528px"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://image.01lb.com.cn//uploads/wecom/22/0209/1644375128Qt849l2k.png",
                                    width: "528",
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "q-primary text-small inline-flex",
                            staticStyle: {
                              "line-height": "1.6",
                              "margin-bottom": "0"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-help1",
                              staticStyle: { "margin-right": "2px" }
                            }),
                            _vm._v(" 用户完成任务后，如何领取奖品 ")
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rewardForm.reward_type === 3,
                        expression: "rewardForm.reward_type === 3"
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "reward-type__select",
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              disabled: _vm.isEdit && !_vm.isCopy && !_vm.isDaft
                            },
                            on: {
                              focus: _vm.fn_detailFocus,
                              blur: _vm.fn_detailBlur,
                              change: _vm.fn_formChange
                            },
                            model: {
                              value: _vm.rewardForm.form_id,
                              callback: function($$v) {
                                _vm.$set(_vm.rewardForm, "form_id", $$v)
                              },
                              expression: "rewardForm.form_id"
                            }
                          },
                          _vm._l(_vm.formList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        ),
                        !_vm.rewardForm.form_id
                          ? _c("span", { staticClass: "reward-code__error" }, [
                              _vm._v("请选择表单")
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "q-info text-small",
                            staticStyle: {
                              "line-height": "24px",
                              "margin-bottom": "16px"
                            }
                          },
                          [
                            !_vm.isEdit || _vm.isCopy
                              ? [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "16px" } },
                                    [_vm._v("选择要关联活动的表单")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "flex",
                                      staticStyle: { display: "inline-flex" }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.fn_jumpToCode("form")
                                            }
                                          }
                                        },
                                        [_vm._v("去创建表单")]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "el-divider el-divider--vertical",
                                        attrs: { "data-v-3cefa108": "" }
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "lydebounce",
                                              rawName: "v-lydebounce",
                                              value: [
                                                "button",
                                                _vm.fn_getFormList
                                              ],
                                              expression:
                                                "['button', fn_getFormList]"
                                            }
                                          ],
                                          attrs: { type: "text", size: "mini" }
                                        },
                                        [_vm._v("刷新")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "rules reward-type__select",
                        staticStyle: { border: "none" }
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "reward-detail",
                              _vm.detailError !== "" ? "error" : ""
                            ]
                          },
                          [
                            _c("div", { staticClass: "rules-header" }, [
                              _c("div", [_vm._v("奖品规则：")])
                            ]),
                            _c("wang-editor", {
                              staticClass: "wangEditor wangEditor-code no-tool",
                              attrs: {
                                refName: "formRulesRef" + _vm.order,
                                content: _vm.rewardForm.formRules,
                                menus: _vm.codeMenus,
                                getText: _vm.fn_getText3,
                                placeholder: "请输入奖品规则",
                                setCont: function(html) {
                                  _vm.fn_setDetail(html, "form")
                                }
                              },
                              on: {
                                eFocus: _vm.fn_detailFocus,
                                eBlur: _vm.fn_detailBlur
                              }
                            }),
                            _c("div", { staticClass: "rules-footer" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "rules-footer-inset default",
                                  on: {
                                    click: function($event) {
                                      return _vm.resetRules("form")
                                    }
                                  }
                                },
                                [_vm._v(" 恢复默认文本 ")]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.formText.length) + "/1000 "
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          placement: "top",
                          "popper-class": "q-tooltip is-light"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "24px 24px 16px",
                                  width: "528px"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://image.01lb.com.cn//uploads/wecom/21/0922/16322925175DqeoK5C.png",
                                    width: "528",
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "q-primary text-small inline-flex",
                            staticStyle: {
                              "line-height": "1.6",
                              "margin-bottom": "0"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-help1",
                              staticStyle: { "margin-right": "2px" }
                            }),
                            _vm._v(" 用户完成任务后，如何领取奖品 ")
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-form-item",
              {
                staticClass: "reward-form__item",
                attrs: {
                  label: "奖品库存",
                  error: _vm.totalError,
                  required: ""
                }
              },
              [
                _c("el-input-number", {
                  staticClass: "number-big",
                  attrs: {
                    "controls-position": "right",
                    min: 1,
                    max: 99999,
                    precision: 0,
                    placeholder: "奖品库存"
                  },
                  on: { change: _vm.fn_checkTotal },
                  model: {
                    value: _vm.rewardForm.total,
                    callback: function($$v) {
                      _vm.$set(_vm.rewardForm, "total", $$v)
                    },
                    expression: "rewardForm.total"
                  }
                }),
                _vm.isEdit && !_vm.isCopy && !_vm.isDaft
                  ? _c("div", { staticClass: "q-info" }, [
                      _c("span", { staticStyle: { "margin-right": "24px" } }, [
                        _vm._v("已使用："),
                        _c("b", [_vm._v(_vm._s(_vm.rewardForm.give_num))])
                      ]),
                      _c("span", [
                        _vm._v("剩余库存："),
                        _c("b", [_vm._v(_vm._s(_vm.rewardForm.remain))])
                      ])
                    ])
                  : _vm._e(),
                _vm.showRiskTips
                  ? _c(
                      "p",
                      {
                        staticClass: "q-bg is-primary text-small",
                        staticStyle: {
                          "line-height": "1.6",
                          "margin-bottom": "0"
                        }
                      },
                      [
                        _vm._v(
                          " 温馨提示：账号被动添加好友建议值：新企微号每天200~400人左右，高权重老号每天800~1000人左右。当前奖品库存/涨粉账号=" +
                            _vm._s(_vm.showRiskNum) +
                            "人，请谨慎设置库存 "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }