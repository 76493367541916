var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加群发内容",
        visible: _vm.visible,
        "custom-class": "add-content",
        width: "560px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "type-list" }, [
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2001 },
            on: {
              click: function($event) {
                return _vm.handleClick(2001)
              }
            }
          },
          [_vm._v("文本")]
        ),
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2002 },
            on: {
              click: function($event) {
                return _vm.handleClick(2002)
              }
            }
          },
          [_vm._v("图片")]
        ),
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2005 },
            on: {
              click: function($event) {
                return _vm.handleClick(2005)
              }
            }
          },
          [_vm._v("链接")]
        ),
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2010 },
            on: {
              click: function($event) {
                return _vm.handleClick(2010)
              }
            }
          },
          [_vm._v("文件")]
        ),
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2004 },
            on: {
              click: function($event) {
                return _vm.handleClick(2004)
              }
            }
          },
          [_vm._v("视频")]
        ),
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2013 },
            on: {
              click: function($event) {
                return _vm.handleClick(2013)
              }
            }
          },
          [_vm._v("小程序")]
        )
      ]),
      _vm.type === 2001
        ? _c("el-input", {
            staticClass: "text-input",
            attrs: {
              type: "textarea",
              placeholder: "请输入文本内容",
              maxlength: "1000",
              "show-word-limit": ""
            },
            model: {
              value: _vm.text.msg_content,
              callback: function($$v) {
                _vm.$set(_vm.text, "msg_content", $$v)
              },
              expression: "text.msg_content"
            }
          })
        : _c(
            "div",
            { staticClass: "content" },
            [
              _vm.type === 2002
                ? [
                    _c(
                      "el-upload",
                      {
                        ref: "pic",
                        staticClass: "uploader",
                        attrs: {
                          action: "#",
                          accept: ".png, .jpg",
                          "http-request": function(opts) {
                            return _vm.requestQiniu(opts, "pic")
                          },
                          limit: 1,
                          "show-file-list": false
                        }
                      },
                      [
                        _vm.pic.msg_content
                          ? _c("div", { staticClass: "thum" }, [
                              _c("img", {
                                staticClass: "img",
                                attrs: {
                                  src: _vm.pic.msg_content,
                                  fit: "cover"
                                }
                              }),
                              _c(
                                "i",
                                {
                                  staticClass: "el-icon-closeplus",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleRemove("pic")
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              )
                            ])
                          : [
                              _c("div", { staticClass: "upload-box flex" }, [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-plus avatar-uploader-icon"
                                }),
                                _c("span", [_vm._v("上传图片")])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "desc",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [_vm._v("支持JPG、PNG格式，图片小于10M")]
                              )
                            ]
                      ],
                      2
                    )
                  ]
                : _vm.type === 2004
                ? [
                    _c(
                      "el-upload",
                      {
                        ref: "video",
                        staticClass: "uploader",
                        attrs: {
                          action: "#",
                          accept: ".mp4",
                          "http-request": function(opts) {
                            return _vm.requestQiniu(opts, "video")
                          },
                          limit: 1,
                          "show-file-list": false
                        }
                      },
                      [
                        _vm.video.href
                          ? _c("div", { staticClass: "thum" }, [
                              _c("video", {
                                ref: "videoDom",
                                staticClass: "img",
                                attrs: { src: _vm.video.href },
                                on: { loadedmetadata: _vm.getDuration }
                              }),
                              _c(
                                "i",
                                {
                                  staticClass: "el-icon-closeplus",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleRemove("video")
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              )
                            ])
                          : [
                              _c(
                                "div",
                                { staticClass: "upload-box upload-video-box" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-plus avatar-uploader-icon"
                                  }),
                                  _c("span", { staticClass: "warp-desc" }, [
                                    _vm._v("从本地上传视频")
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "desc",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [_vm._v("支持MP4格式，文件小于10MB")]
                              )
                            ]
                      ],
                      2
                    )
                  ]
                : _vm.type === 2010
                ? [
                    _c(
                      "el-upload",
                      {
                        ref: "file",
                        staticClass: "uploader",
                        attrs: {
                          action: "#",
                          "http-request": function(opts) {
                            return _vm.requestQiniu(opts, "file")
                          },
                          limit: 1,
                          "show-file-list": false
                        }
                      },
                      [
                        _vm.file.msg_content
                          ? _c("div", { staticClass: "file-temp thum" }, [
                              _c("span", { staticClass: "file-title" }, [
                                _vm._v(_vm._s(_vm.file.title))
                              ]),
                              _c("img", {
                                staticClass: "file-icon",
                                attrs: {
                                  src: require("@/assets/images/doc.png")
                                }
                              }),
                              _c(
                                "i",
                                {
                                  staticClass: "el-icon-closeplus",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleRemove("file")
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              )
                            ])
                          : [
                              _c("div", { staticClass: "upload-box flex" }, [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-plus avatar-uploader-icon"
                                }),
                                _c("span", [_vm._v("上传文件")])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "desc",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [_vm._v("文件大小不超过10MB")]
                              )
                            ]
                      ],
                      2
                    )
                  ]
                : _vm.type === 2005
                ? [
                    _c(
                      "el-form",
                      {
                        ref: "linkForm",
                        staticClass: "link-form",
                        attrs: {
                          size: "medium",
                          "show-message": false,
                          rules: _vm.linkRules,
                          model: _vm.link,
                          "label-width": "0px"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "href" } },
                          [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "lydebounce",
                                  rawName: "v-lydebounce",
                                  value: ["input", _vm.getUrlInfo],
                                  expression: "['input', getUrlInfo]"
                                }
                              ],
                              attrs: { placeholder: "请输入链接" },
                              model: {
                                value: _vm.link.href,
                                callback: function($$v) {
                                  _vm.$set(_vm.link, "href", $$v)
                                },
                                expression: "link.href"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "title" } },
                          [
                            _c("el-input", {
                              attrs: {
                                "show-word-limit": "",
                                maxlength: "50",
                                placeholder: "链接标题"
                              },
                              model: {
                                value: _vm.link.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.link, "title", $$v)
                                },
                                expression: "link.title"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "desc", attrs: { prop: "desc" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                "show-word-limit": "",
                                maxlength: "100",
                                placeholder: "快来参加活动吧"
                              },
                              model: {
                                value: _vm.link.desc,
                                callback: function($$v) {
                                  _vm.$set(_vm.link, "desc", $$v)
                                },
                                expression: "link.desc"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "poster",
                            attrs: { prop: "msg_content" }
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "link",
                                attrs: {
                                  action: "#",
                                  accept: ".png, .jpg",
                                  "on-success": _vm.handleSuccess,
                                  "http-request": function(opts) {
                                    return _vm.requestQiniu(opts, "link")
                                  },
                                  limit: 1,
                                  "show-file-list": false
                                }
                              },
                              [
                                _vm.link.msg_content
                                  ? _c("div", { staticClass: "thum" }, [
                                      _c("img", {
                                        staticClass: "img link-img",
                                        attrs: {
                                          src: _vm.link.msg_content,
                                          fit: "cover"
                                        }
                                      }),
                                      _c(
                                        "i",
                                        {
                                          staticClass: "el-icon-closeplus",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.handleRemove("link")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close"
                                          })
                                        ]
                                      )
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "upload-box flex" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-plus avatar-uploader-icon"
                                        }),
                                        _c("span", [_vm._v("上传封面")])
                                      ]
                                    )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-small",
                            staticStyle: { color: "#6e788a" }
                          },
                          [
                            _vm._v(
                              "建议封面尺寸500*500px，JPG，PNG，JPEG格式，小于2MB"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                : _vm.type === 2013
                ? [
                    !_vm.appletInfo.appid && !_vm.appletInfo.weappiconurl
                      ? _c(
                          "div",
                          {
                            staticClass: "applet-temp",
                            on: {
                              click: function($event) {
                                _vm.$refs.selectApplet.visible = true
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "iconfont icon-plus avatar-uploader-icon"
                            }),
                            _c("span", { staticClass: "warp-desc" }, [
                              _vm._v("从聊天记录中选择小程序")
                            ])
                          ]
                        )
                      : _c(
                          "div",
                          { ref: "applet", staticClass: "thum" },
                          [
                            _c("app-poster", {
                              attrs: { applet: _vm.appletInfo }
                            }),
                            _c(
                              "i",
                              {
                                staticClass: "el-icon-closeplus",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleRemove("applet")
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            )
                          ],
                          1
                        ),
                    _c("select-applet", {
                      ref: "selectApplet",
                      on: { chose: _vm.handleChoseApp }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary" },
              on: { click: _vm.handlePushMsg }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }