var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "formRef",
      staticClass: "edit-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "110px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "素材名称：", prop: "name" } },
        [
          _c("el-input", {
            staticClass: "edit-form__input",
            attrs: {
              maxlength: "20",
              "show-word-limit": "",
              placeholder: "请输入素材名称"
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("template", { slot: "label" }, [
            _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
            _vm._v(" 分组：")
          ]),
          _c(
            "el-select",
            {
              staticClass: "edit-form__input",
              model: {
                value: _vm.form.group_id,
                callback: function($$v) {
                  _vm.$set(_vm.form, "group_id", $$v)
                },
                expression: "form.group_id"
              }
            },
            _vm._l(_vm.groupList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { label: "素材内容：", prop: "vlog_content" } },
        [
          _c("div", { staticClass: "edit-vlog" }, [
            !_vm.form.vlog_content
              ? _c(
                  "div",
                  {
                    staticClass: "edit-vlog__upload",
                    on: {
                      click: function($event) {
                        return _vm.$refs.selectRecord.fn_open(2017)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "edit-vlog__upload--icon" }, [
                      _c("i", { staticClass: "el-icon-plus" })
                    ]),
                    _c("div", { staticClass: "edit-vlog__upload--tip" }, [
                      _vm._v("从聊天记录中选择视频号")
                    ])
                  ]
                )
              : _c(
                  "div",
                  { ref: "applet", staticClass: "edit-vlog__info" },
                  [
                    _c("vlog-card", { attrs: { info: _vm.form.vlog_content } }),
                    _c(
                      "div",
                      {
                        staticClass: "edit-vlog__remove",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.fn_handleRemove($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "iconfont icon-delete" })]
                    )
                  ],
                  1
                )
          ]),
          _c("select-record", {
            ref: "selectRecord",
            on: { chose: _vm.handleChoseApp }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }