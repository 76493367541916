<!--
 * @Author: zhanln
 * @Date: 2021-10-14 17:09:09
 * @LastEditTime: 2021-12-16 11:15:07
 * @LastEditors: zhanln
 * @Description: 柱状图模板，调用 setOption(data) 创建
-->

<template>
  <ly-chart :height="height" :option="barOption" ref="chartRef"></ly-chart>
</template>

<script>
import lyChart from './index'
import getColors from './colors'
export default {
  name: 'trend',

  props: {
    height: {
      type: String,
      default: '300px'
    },
    isAllCus: { // 为 true 则替换所有传入的option 属性，为 false 则是合并， 默认 false
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => { }
    },
    colorLoop: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    s_option () {
      return this.option
    }
  },

  watch: {
    s_option: {
      handler (val) {
        if (val) {
          this.fn_setting(val)
        }
      },
      immediate: true,
      deep: true
    }
  },

  data () {
    return {
      baseOption: {
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(2, 15, 32, 0.78)',
          borderWidth: 0,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '12%',
          top: '12%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            show: false,
            height: 12,
            showDataShadow: false,
            showDetail: false,
            brushSelect: false,
            xAxisIndex: [0],
            startValue: 0,
            endValue: 6,
            bottom: '10%',
            zoomLock: true,
            handleStyle: {
              opacity: 0
            }
          },
          {
            type: 'inside',
            xAxisIndex: [0],
            startValue: 0,
            endValue: 10,
            zoomLock: true
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              color: '#6E788A',
              margin: 14
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(233, 233, 233, 1)'
              }
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 5,
            axisLabel: {
              color: '#6E788A'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(233, 233, 233, 1)',
                type: 'dashed'
              }
            }
          }
        ],
        series: []
      },
      barOption: null
    }
  },

  mounted () {
    // 开启显示演示数据
    // this.fn_default()
  },

  methods: {

    // 图表数据处理，先设置 baseOption 再赋值到 barOption，避免图表多次绘制
    async setOption (data) {
      if (!data || !(data && data.length)) return false

      const _series = this.fn_initStyle()

      this.baseOption.xAxis[0].data = []
      for (let i = 0; i < data.length; i++) {
        this.baseOption.xAxis[0].data.push(data[i].name)
        data[i].itemStyle = {}
        data[i].itemStyle.color = getColors(i, true, this.colorLoop)
        _series.data.push(data[i])
      }

      this.baseOption.series = _series

      if (data.length > this.baseOption.dataZoom[0].endValue) {
        this.baseOption.dataZoom[0].show = true
        this.baseOption.grid.bottom = '20%'
      } else {
        this.baseOption.dataZoom[0].show = false
        this.baseOption.grid.bottom = '12%'
      }
      this.barOption = this.baseOption
    },

    // 自定义配置
    fn_setting (val) {
      for (const item in val) {
        // 如果传入的属性不存在 或者 isAllCus: true, 则替换该属性
        if (item !== 'series') {
          if (!this.baseOption[item] || this.isAllCus) {
            this.baseOption[item] = val[item]
          } else {
            Object.assign(this.baseOption[item], val[item])
          }
        }
      }
    },

    // 折线样式配置
    fn_initStyle () {
      const series = this.option && this.option.series
      // 默认样式
      let obj =
      {
        type: 'bar',
        barMaxWidth: 24,
        barCategoryGap: 10,
        itemStyle: {
          borderRadius: 12
        },
        data: []
      }

      if (series) {
        if (!this.isAllCus) {
          Object.assign(obj, series)
        } else {
          obj = {
            type: 'bar',
            ...series
          }
        }
        return obj
      } else {
        return obj
      }
    },

    // 演示效果
    fn_default () {
      const obj = []
      for (let i = 0; i < 40; i++) {
        const idate = `${i}级`
        const value = Math.ceil((Math.random() * 200).toFixed(2))
        const valid = Math.ceil(value * (Math.random() * 1).toFixed(2))
        const rate = +((valid / value * 100).toFixed(2))
        obj.push({
          name: idate,
          value,
          valid,
          rate
        })
      }

      // 数据示例
      // obj = [
      //   {
      //     name: 'name1',
      //     value: 1,
      //     ...
      //   },
      //   {
      //     name: 'name2',
      //     value: 2,
      //     ...
      //   }
      // ]

      this.setOption(obj)
    },

    // 重置尺寸
    fn_resize (w) {
      this.$refs.chartRef._cusResize(w)
    }
  },

  components: {
    lyChart
  }
}
</script>
