<!--
 * @Author: zhanln
 * @Date: 2021-11-09 16:34:36
 * @LastEditTime: 2022-01-07 11:29:33
 * @LastEditors: zhanln
 * @Description: 预览- 活动信息
-->

<template>
  <ly-mobile :msgList="msgList"></ly-mobile>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileBasic',

  data () {
    return {
      defaultPoster: require('@/assets/images/poster-default.png'),
      msgList: [{
        type: 'text',
        content: ''
      }, {
        type: 'link',
        title: '',
        desc: '',
        cover: 'https://image.01lb.com.cn//uploads/wecom/22/0107/1641525658JD39vwQA.png'
      }]
    }
  },

  computed: {
    ...mapState({
      welcome_msg: state => state.lottery.welcome_msg,
      link_type: state => state.lottery.link_type,
      link_title: state => state.lottery.link_title,
      link_desc: state => state.lottery.link_desc,
      link_logo: state => state.lottery.link_logo,
      default_link_logo: state => state.lottery.default_link_logo,
      default_link_title: state => state.lottery.default_link_title,
      default_link_desc: state => state.lottery.default_link_desc
    })
  },

  watch: {
    welcome_msg (val) {
      this.msgList[0].content = val
    },
    link_type (val) {
      this.msgList[1].title = (val === 2) ? this.$store.state.lottery.link_title : this.default_link_title
      this.msgList[1].desc = (val === 2) ? this.$store.state.lottery.link_desc : this.default_link_desc
      this.msgList[1].cover = (val === 2) ? this.$store.state.lottery.link_logo : this.default_link_logo
    },
    link_title (val) {
      this.msgList[1].title = val
    },
    link_desc (val) {
      this.msgList[1].desc = val
    },
    link_logo (val) {
      this.msgList[1].cover = val || this.default_link_logo
    }
  },

  mounted () {
    this.msgList[0].content = this.$store.state.lottery.welcome_msg
    this.msgList[1].title = this.$store.state.lottery.link_title
    this.msgList[1].desc = this.$store.state.lottery.link_desc
    this.msgList[1].cover = this.$store.state.lottery.link_logo || this.default_link_logo
  }
}
</script>
