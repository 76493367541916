<!--
 * @Author: zhanln
 * @Date: 2021-11-01 17:18:32
 * @LastEditTime: 2022-09-01 11:34:37
 * @LastEditors: zhan
 * @Description: 任务宝 - 活动编辑
-->

<template>
  <act-layout :cards="cards" ref="cards" @setKey="fn_setKey" lastRef="share" v-loading="pageLoading">
    <!-- 表单区域 -->
    <!-- 活动信息 -->
    <template #basic>
      <view-basic ref="basic"></view-basic>
    </template>

    <!-- 任务设置 -->
    <template #reward>
      <view-reward ref="reward"></view-reward>
    </template>

    <!-- 领奖路径 -->
    <template #way v-if="this.mode === 1">
      <view-way ref="way"></view-way>
    </template>

    <!-- 活动页设置 -->
    <template #setting>
      <view-setting ref="setting"></view-setting>
    </template>

    <!-- 海报设置 -->
    <template #poster>
      <view-poster ref="poster"></view-poster>
    </template>

    <!-- 分享设置 -->
    <template #share>
      <view-share ref="share"></view-share>
    </template>

    <!-- 预览区域 -->
    <template #mobile>
      <mobi-home v-if="mobiKey === 1"></mobi-home>
      <mobi-welmsg v-if="mobiKey === 2"></mobi-welmsg>
      <mobi-poster v-if="mobiKey === 3"></mobi-poster>
      <mobi-share v-if="mobiKey === 4"></mobi-share>
    </template>

    <!-- footer -->
    <template #footer v-if="!pageLoading">
      <edit-footer @submit="fn_submit" @draft="fn_submit(true)" :saveLoading="saveLoading" :draftLoading="draftLoading">
      </edit-footer>
    </template>
  </act-layout>
</template>

<script>
import { activity, auth } from './http'
// layout
import actLayout from '@/components/actLayout'
import editFooter from './activity/editFooter'
// forms
import viewBasic from './activity/basic'
import viewReward from './activity/reward'
import viewWay from './activity/way'
import viewPoster from './activity/poster'
import viewSetting from './activity/setting'
import viewShare from './activity/share'

// mobiles
import mobiHome from './mobile/home'
import mobiWelmsg from './mobile/welmsg'
import mobiPoster from './mobile/poster'
import mobiShare from './mobile/share'

// store
import { mapState } from 'vuex'
export default {
  name: 'taskEdit',

  data () {
    return {
      pageLoading: true,
      saveLoading: false,
      draftLoading: false,
      tabKey: '',
      cards: [
        {
          label: '活动信息',
          name: 'basic'
        },
        {
          label: '任务设置',
          name: 'reward'
        },
        {
          label: '领奖路径',
          name: 'way'
        },
        {
          label: '活动页设置',
          name: 'setting'
        },
        {
          label: '分享海报设置',
          name: 'poster'
        },
        {
          label: '分享小程序设置',
          name: 'share'
        }
      ],
      subForm: {},
      backComfirm: true,
      actId: null
    }
  },

  computed: {
    ...mapState({
      mode: state => state.reserveTask.mode,
      eType: state => state.reserveTask.eType,
      add_user: state => state.reserveTask.add_user
    }),
    mobiKey () {
      if ((this.tabKey === 'way' && this.add_user === 1) || (this.mode === 2 && this.tabKey === 'basic')) {
        return 2
      }

      if (this.tabKey === 'poster') {
        return 3
      }

      if (this.tabKey === 'share') {
        return 4
      }

      return 1
    }
  },

  created () {
    const mode = this.$route.query.mode || 1
    const type = this.$route.query.type
    if (type) {
      this.fn_setStore('eType', +type)
    }
    // 裂变模式隐藏《领奖路径》
    if (+mode === 2) {
      this.cards[2].hidden = true
    }
    this.fn_setStore('mode', +mode)
  },

  async mounted () {
    // 判断是创建、编辑、复制
    const editId = this.$route.query.id
    let detailData = null
    if (editId) {
      detailData = await this.fn_getAct(+editId)
    }

    this.fn_ready(detailData)
    await this.fn_getAppInfo()
  },

  methods: {

    // 小程序信息
    async fn_getAppInfo () {
      const info = this.$store.state.miniapp.info
      if (!info) {
        const res = await auth.authMpInfo()
        if (res) {
          this.$store.state.miniapp.info = res
        }
      }
    },

    // 获取表单详情
    async fn_getAct (id) {

      const data = await activity.detail({
        id
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.actId = data.id
      this.fn_setStore('mode', data.scene)

      return data
    },

    // 通知各表单组件初始化
    fn_ready (data) {
      let basicForm = null
      let rewardForm = null
      let wayForm = null
      let posterForm = null
      let settingForm = null
      let shareForm = null

      // 如果有传入详情
      if (data) {
        // 已结束
        if (data.status === 2 && !this.isCopy) {
          this.fn_setStore('isEnd', true)
        }

        const {
          title,
          end_time,
          vn_id,
          show_url,
          add_user_ids,
          code_fail_mode,
          code_fail_time,
          welcome_msg,
          add_tags,
          add_tags_type,
          tags,
          old_tags,
          rewards,
          stock_type,
          btn_title,
          cover_title,
          rule,
          poster_url,
          poster_conf,
          share_title,
          share_cover,
          add_user,
          reward_type,
          reward_day
        } = data

        basicForm = {
          title,
          end_time,
          vn_id,
          show_url,
          add_user_ids,
          code_fail_mode,
          code_fail_time,
          welcome_msg,
          add_tags,
          add_tags_type,
          tags1: add_tags_type === 1 ? tags : '',
          tags2: add_tags_type === 2 ? tags : '',
          tags3: add_tags_type === 2 ? old_tags : ''
        }

        rewardForm = {
          rewards,
          stock: {
            stock_type
          }
        }

        if (this.mode === 1) {
          wayForm = {
            add_user,
            add_user_ids,
            welcome_msg,
            add_tags,
            add_tags_type,
            tags1: add_tags_type === 1 ? tags : '',
            tags2: add_tags_type === 2 ? tags : '',
            tags3: add_tags_type === 2 ? old_tags : '',
            reward_type,
            reward_day
          }
        }

        settingForm = {
          btn_title,
          cover_title,
          rule
        }

        posterForm = {
          poster_url,
          poster_conf
        }

        shareForm = {
          share_title,
          share_cover
        }
      }

      // 组件表单初始化
      // 活动信息
      this.$refs.basic.fn_setForm(basicForm)

      // // 任务设置
      this.$refs.reward.fn_setForm(rewardForm)

      // // 领奖路径
      if (this.mode === 1) {
        this.$refs.way.fn_setForm(wayForm)
      }

      // // 活动页设置
      this.$refs.setting.fn_setForm(settingForm)

      // // 海报设置
      this.$refs.poster.fn_setForm(posterForm)

      // // 消息通知
      this.$refs.share.fn_setForm(shareForm)

      this.pageLoading = false
    },

    // 提交校验
    async fn_submit (draft) {
      // 各表单组件校验回调，如果检验通过，返回的是该表单的form对象
      // 活动信息
      const basic = await this.$refs.basic.verify(draft)
      if (!basic) {
        this.fn_formScroll(this.$refs.cards.$refs.basic[0].$el)
        return false
      }
      console.log('basic', basic)

      // 任务设置
      const reward = await this.$refs.reward.verify(draft)
      if (!reward) {
        this.fn_formScroll(this.$refs.cards.$refs.reward[0].$el)
        return false
      }
      console.log('reward', reward)

      // 领奖路径
      let way = null
      if (this.mode === 1) {
        way = await this.$refs.way.verify(draft)
        if (!way) {
          this.fn_formScroll(this.$refs.cards.$refs.way[0].$el)
          return false
        }
        console.log('way', way)
      }

      // 活动页设置
      const setting = await this.$refs.setting.verify(draft)
      if (!setting) {
        this.fn_formScroll(this.$refs.cards.$refs.setting[0].$el)
        return false
      }
      console.log('setting', setting)

      // 裂变海报
      const poster = await this.$refs.poster.verify(draft)
      if (!poster) {
        this.fn_formScroll(this.$refs.cards.$refs.poster[0].$el)
        return false
      }
      console.log('poster', poster)

      // 分享设置
      const share = await this.$refs.share.verify(draft)
      if (!share) {
        this.fn_formScroll(this.$refs.cards.$refs.share[0].$el)
        return false
      }
      console.log('share', share)

      this.subForm = {
        ...basic,
        rewards: reward.rewards,
        ...reward.stock,
        ...setting,
        poster_conf: JSON.stringify(poster.poster_conf),
        poster_url: poster.poster_url,
        ...share
      }

      if (this.mode === 1) {
        Object.assign(this.subForm, way)
      }

      console.log('subFormsubFormsubForm', this.subForm)

      this.fn_setLoading(draft, true)
      this.fn_save(draft)
    },

    async fn_save (draft) {
      console.log('this.eType', this.eType)
      // 活动模式
      this.subForm.scene = this.mode
      // 是否填入id，修改活动及修改草稿（草稿发布为活动则为0）
      if (this.eType === 1 || (this.eType === 3 && draft)) {
        this.subForm.id = this.actId
      }

      let res = null

      if (draft) {
        res = await activity.saveDraft(this.subForm)
      } else {
        res = await activity.save(this.subForm)
      }

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        this.fn_setLoading(draft, false)
        return false
      }

      this.$message.success(draft ? '保存草稿成功！' : '发布成功！')
      this.backComfirm = false
      this.fn_setLoading(draft, false)
      this.$router.push('list')
    },

    fn_setLoading (draft, load) {
      if (draft) {
        this.draftLoading = load
      } else {
        this.saveLoading = load
      }
    },

    // 提交失败结果判断
    fn_errorSubmit (code, msg) {
      if (code === 206) {
        this.$store.state.buy.remainDay = 0
        this.fn_lyMsg('info', '应用已过期')
      } else if (code === 205) {
        this.$store.state.buy.remainDay = null
        this.fn_lyMsg('info', msg)
      } else {
        this.fn_lyMsg('info', msg)
      }
    },

    // 导航监听
    fn_setKey (key) {
      this.tabKey = key
    },

    // 滚动到表单报错位置
    fn_formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    // 重置缓存
    fn_resetForm () {
      this.$store.commit('reserveTask/RESET')
    },

    // 设置store
    fn_setStore (name, val) {
      this.$store.commit('reserveTask/SET_DATA', {
        name,
        val
      })
    }
  },

  provide () {
    return { fn_setStore: this.fn_setStore }
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          this.fn_resetForm()
          this.pageLoading = true
          next()
        }).catch(() => { })
      } else {
        this.fn_resetForm()
        this.pageLoading = true
        next()
      }
    }
  },

  components: {
    actLayout,
    editFooter,
    viewBasic,
    viewReward,
    viewWay,
    viewSetting,
    viewPoster,
    viewShare,
    mobiHome,
    mobiWelmsg,
    mobiPoster,
    mobiShare
  }
}
</script>
