<!--
 * @Author: zhan
 * @Date: 2022-08-15 15:28:49
 * @LastEditTime: 2022-09-06 16:17:30
 * @LastEditors: zhan01lb zhanlienan@01lb.com.cn
-->
<template>
  <div class="prize">
    <swiper :options="swiperOption" class="prize-swiper" ref="swiperRef">
      <swiper-slide
        v-for="(item, index) of rewards"
        :key="index"
        class="prize-swiper__item"
      >
        <div class="prize-unreal p-color" v-if="item.virtual_count > 0">
          <div class="prize-unreal__avatars">
            <img
              v-for="(item, index) of avatarList.slice(0, item.virtual_count)"
              :key="index"
              :src="item"
              alt=""
            />
          </div>
          {{ item.virtual_count }}人已领取
        </div>
        <div class="prize-item">
          <div class="prize-cover">
            <img :src="item.cover || defaultPrizeCover" />
          </div>
          <div class="prize-total">
            <template v-if="item.show_stock === 1">
              库存剩余：<span class="p-color">{{ item.total }}</span>
            </template>
            <template v-else>
              <span class="p-color">库存告急</span>
            </template>
          </div>
        </div>
        <div class="prize-name p-color">{{ item.title || '奖品名称' }}</div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import '@/assets/style/swiper.min.css'
export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      swiper: null,
      swiperOption: {
        slidesPerView: 1,
        initialSlide: 1,
        centeredSlides: true
      },
      tabIndex: 1,
      avatarList: ['https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg', 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg', 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg'],
      prizes: [
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        },
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        },
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        },
        {
          title: '谢谢参与',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0708/1657265213BieaDd98.png',
          status: 2
        },
        {
          title: '100元优惠券',
          cover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png',
          status: 1
        }
      ],
      defaultPrizeCover: 'https://image.01lb.com.cn//uploads/wecom/22/0614/1655203852sAnRf55N.png'
    }
  },
  computed: {
    ...mapGetters({
      rewards: 'reserveTask/GET_REWARD'
    }),
    ...mapState({
      current_step: state => state.reserveTask.current_step
    })
  },

  watch: {
    rewards (val) {
      this.$refs.swiperRef.update()
    },
    current_step (val) {
      this.$nextTick(() => {
        this.$refs.swiperRef.swiper.slideTo(val)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.p-color {
  color: #e537a3;
}

.prize {
  width: 213px;
  height: 160px;
  margin: 44px auto 0;
  border-radius: 52px;
  overflow: hidden;
  position: relative;
  left: 2px;

  &-swiper {
    height: 100%;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &-unreal {
    display: flex;
    align-items: center;
    padding: 2px 6px;
    background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0817/1660707513K7pgpWd7.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    font-size: 12;
    margin-top: 4px;
    margin-bottom: 6px;

    &__avatars {
      display: flex;
      align-items: center;
      margin-right: 2px;

      img {
        width: 20px;
        border-radius: 50%;
        border: 1px solid #fff;
        box-sizing: border-box;

        &:not(:first-child) {
          margin-left: -4px;
        }
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100px;
    border: 1px solid #ffe7f3;
    border-radius: 10px;
    overflow: hidden;
  }

  &-cover {
    line-height: 1;
    width: 60px;
    height: 60px;
    margin: 6px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &-total {
    width: 100%;
    background: #fffdea;
    font-size: 12px;
    text-align: center;
    color: #828a99;
  }

  &-name {
    text-align: center;
    margin-top: 6px;
  }
}
</style>
