var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "70px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        [
          _c("el-checkbox", {
            attrs: { "true-label": 1, "false-label": 0 },
            model: {
              value: _vm.form.open_info,
              callback: function($$v) {
                _vm.$set(_vm.form, "open_info", $$v)
              },
              expression: "form.open_info"
            }
          }),
          _vm._v("用户未设置头像、昵称，自动加入黑名单 ")
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("el-checkbox", {
            attrs: { "true-label": 1, "false-label": 0 },
            model: {
              value: _vm.form.open_over_info,
              callback: function($$v) {
                _vm.$set(_vm.form, "open_over_info", $$v)
              },
              expression: "form.open_over_info"
            }
          }),
          _vm._v("用户的助力好友超 "),
          _c(
            "el-form-item",
            { staticClass: "item-inline", attrs: { prop: "over_rate" } },
            [
              _c("el-input-number", {
                staticClass: "number-small",
                attrs: {
                  size: "small",
                  "controls-position": "right",
                  min: 1,
                  max: 100,
                  precision: 0
                },
                model: {
                  value: _vm.form.over_rate,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "over_rate", $$v)
                  },
                  expression: "form.over_rate"
                }
              })
            ],
            1
          ),
          _vm._v(" %未设置头像、昵称，自动加入黑名单 ")
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("el-checkbox", {
            attrs: { "true-label": 1, "false-label": 0 },
            model: {
              value: _vm.form.open_invite_limit,
              callback: function($$v) {
                _vm.$set(_vm.form, "open_invite_limit", $$v)
              },
              expression: "form.open_invite_limit"
            }
          }),
          _vm._v("用户在 "),
          _c(
            "el-form-item",
            { staticClass: "item-inline", attrs: { prop: "invite_second" } },
            [
              _c("el-input-number", {
                staticClass: "number-small",
                attrs: {
                  size: "small",
                  "controls-position": "right",
                  min: 1,
                  max: 100,
                  precision: 0
                },
                model: {
                  value: _vm.form.invite_second,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "invite_second", $$v)
                  },
                  expression: "form.invite_second"
                }
              })
            ],
            1
          ),
          _vm._v(" 秒内邀请超过 "),
          _c(
            "el-form-item",
            { staticClass: "item-inline", attrs: { prop: "invite_num" } },
            [
              _c("el-input-number", {
                staticClass: "number-small",
                attrs: {
                  size: "small",
                  "controls-position": "right",
                  min: 0,
                  max: 100,
                  precision: 0
                },
                model: {
                  value: _vm.form.invite_num,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "invite_num", $$v)
                  },
                  expression: "form.invite_num"
                }
              })
            ],
            1
          ),
          _vm._v(" 人，自动加入黑名单 ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }