<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>

    <div class="edit-card__content">
      <el-form
        :model="otherForm"
        :rules="rules"
        ref="otherFormRef"
        label-width="150px"
        style="width: 60%; min-width: 560px"
        size="medium"
      >
        <!-- 活动标题 -->
        <el-form-item prop="show_title" label="活动标题">
          <el-input
            maxlength="15"
            show-word-limit
            placeholder="请输入活动标题"
            v-model="otherForm.show_title"
            @change="fn_setOther('show_title', $event)"
          ></el-input>
        </el-form-item>

        <!-- 封面标题 -->
        <el-form-item prop="bg_title" label="封面标题">
          <el-input
            maxlength="16"
            show-word-limit
            placeholder="请输入封面标题"
            v-model="otherForm.bg_title"
            @change="fn_setOther('bg_title', $event)"
          ></el-input>
        </el-form-item>

        <!-- 活动规则 -->
        <el-form-item prop="rule_desc" label="活动规则">
          <ly-editor
            title="活动规则"
            id="redRule"
            :content="otherForm.rule_desc"
            :recoverContent="defaultRules"
            :maxLength="2000"
            @getContent="fn_getRules"
          ></ly-editor>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import lyEditor from '@/components/lyEditor'
export default {
  props: {
    title: {
      type: String,
      defualt: ''
    }
  },

  components: {
    lyEditor
  },

  data () {
    return {
      rules: {
        show_title: [
          { required: true, message: '请填写活动标题', trigger: 'change' }
        ],
        bg_title: [
          { required: true, message: '请填写封面标题', trigger: 'change' }
        ],
        rule_desc: [
          { required: true, message: '请填写活动规则', trigger: 'change' }
        ]
      },
      defaultRules: '1、邀请【邀请好友数】位好友添加企业微信，立即获得微信实时到账的红包。\n2、任务完成后，系统实时发放红包至邀请者的微信零钱，用户可查看微信支付通知，或前往 “微信-我-支付-钱包” 中查看。\n3、若邀请助力的用户为老用户或该用户已经为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户助力无法再获得红包。\n5、删除好友后助力无效。'
    }
  },

  computed: {
    otherForm () {
      return this.$store.state.rede.other
    }
  },

  watch: {
    otherForm: {
      handler (val) {
        this.editCount = val.rule_desc.length
      },
      deep: true,
      immediate: true
    }
  },

  methods: {

    fn_getRules (val) {
      this.otherForm.rule_desc = val
      this.fn_setOther('rule_desc', val)
    },

    // 设置缓存
    fn_setOther (type, data) {
      const OTHER = this.$store.state.rede.other
      OTHER[type] = data
      this.$store.commit('_SET', {
        'rede.other': OTHER
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
@import "@/assets/scss/var.scss";

.rules {
  border-radius: 4px;
  border: 1px solid #eaebf2;
  overflow: hidden;
  font-size: 12px;
  &-header {
    background-color: #f7f8fc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #DFE1E8;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-footer {
    text-align: right;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);
  }

  &-inset {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;
    background: #f2f5fe;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: $--transition;
    font-size: 12px;

    &:hover {
      background-color: $--color-primary;
      color: #fff;
    }

    &.default {
      background-color: transparent;
      color: $--color-text-regular;
      padding: 0;

      &:hover {
        color: $--color-primary;
      }
    }

    & + & {
      margin-left: 12px;
    }
  }

  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 200px !important;
  }
}
</style>
