var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.watting,
          expression: "watting"
        }
      ],
      staticClass: "inner edit-container",
      staticStyle: { "padding-right": "0" }
    },
    [
      _c("div", { staticClass: "edit-main edit-poster" }, [
        _c(
          "div",
          { staticClass: "edit-card edit-menu" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.fn_setTab },
                model: {
                  value: _vm.tabKey,
                  callback: function($$v) {
                    _vm.tabKey = $$v
                  },
                  expression: "tabKey"
                }
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "活动信息", name: "basic" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "裂变海报", name: "poster" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "活动页设置", name: "other" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "任务设置", name: "reward" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "领奖路径", name: "way" }
                }),
                _vm.msg_mode === 1
                  ? _c("el-tab-pane", {
                      attrs: { label: "消息通知", name: "notice" }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { ref: "main", staticClass: "edit-poster-content" },
          [
            _c(
              "el-scrollbar",
              { staticClass: "left-area" },
              [
                _c("view-basic", {
                  ref: "basic",
                  attrs: { title: "活动信息", aid: _vm.actId }
                }),
                _c("view-poster", {
                  ref: "poster",
                  attrs: { title: "裂变海报" }
                }),
                _c("view-other", {
                  ref: "other",
                  attrs: { title: "活动页设置" }
                }),
                _c("view-reward", {
                  ref: "reward",
                  attrs: { title: "任务设置" }
                }),
                _c("view-way", { ref: "way", attrs: { title: "领奖路径" } }),
                _vm.msg_mode === 1
                  ? _c("view-notice", {
                      ref: "notice",
                      attrs: { title: "消息通知" }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-poster-mobi edit-card" },
              [
                _c(
                  "el-scrollbar",
                  { staticStyle: { height: "100%" } },
                  [
                    _vm.tabKey === "basic" ? _c("mobi-basic") : _vm._e(),
                    _vm.tabKey === "poster" ? _c("mobi-poster") : _vm._e(),
                    _vm.tabKey === "way" ? _c("mobi-way") : _vm._e(),
                    _vm.tabKey === "reward" || _vm.tabKey === "other"
                      ? _c("mobi-reward", { attrs: { current: _vm.tabKey } })
                      : _vm._e(),
                    _vm.tabKey === "notice" ? _c("mobi-notice") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("edit-footer", {
        attrs: { btnLoading: _vm.btnLoading },
        on: {
          saveDraft: function($event) {
            return _vm.fn_submit(2)
          },
          submit: function($event) {
            return _vm.fn_submit(1)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }