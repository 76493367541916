<template>
  <div class="page-warp qr">
    <div class="form-area">
      <el-form ref="form" inline :model="mainForm">
        <el-form-item label="活码名称">
          <el-input
            v-model="mainForm.liveCodeName"
            clear
            placeholder="请输入活码名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="mainForm.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" label-width="0">
          <el-button
            round
            icon="iconfont icon-search"
            type="primary"
            @click="handlerSearch"
            >查询</el-button
          >
          <el-button round icon="iconfont icon-data" @click="fn_reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="main-area">
      <div class="hander-area">
        <el-button
          round
          type="primary"
          icon="iconfont icon-plus"
          size="medium"
          @click="$router.push('edit')"
          >新建活码</el-button
        >
        <el-tooltip
          content="请选择要调整的活码"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              plain
              round
              :disabled="selected.length === 0"
              @click="handleGroup"
              >调整分组</el-button
            ></span
          >
        </el-tooltip>
        <el-tooltip
          content="请选择要下载的活码"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              plain
              round
              :disabled="selected.length === 0"
              @click="$refs.downloadList.visible = true"
              >批量下载二维码</el-button
            ></span
          >
        </el-tooltip>
      </div>
      <div class="temp-container">
        <div class="qr-list">
          <div class="list">
            <div
              class="list-item flex"
              :class="{ active: topicId === item.id }"
              @click="handleTopic(item)"
              v-for="item in groupList"
              :key="item.id"
            >
              <div class="temp flex">
                <el-tooltip
                  :disabled="toolDisable"
                  effect="dark"
                  :content="item.groupName"
                  placement="top"
                >
                  <span class="ell name" @mouseenter="fn_groupEnter">{{
                    item.groupName
                  }}</span>
                </el-tooltip>
                <span class="num">{{ `(${item.count})` }}</span>
              </div>
              <!-- 全部和默认分组 -->
              <div class="btn" v-if="item.id !== '1' && item.id !== '0'">
                <el-popover
                  placement="bottom-end"
                  trigger="click"
                  popper-class="add-group-popover"
                >
                  <span @click.stop="handleEdit(item)" slot="reference">
                    <i class="iconfont icon-edit"></i>
                  </span>
                  <div class="add-group">
                    <el-input
                      size="small"
                      placeholder="请输入分组名称"
                      maxlength="20"
                      show-word-limit
                      v-model="form.name"
                    ></el-input>
                    <el-button round size="small" @click="handleCancel"
                      >取消</el-button
                    >
                    <el-button
                      round
                      size="small"
                      type="primary"
                      @click="handleSave"
                      >确定</el-button
                    >
                  </div>
                </el-popover>
                <span @click.stop="handleDelete(item)" v-if="item.count === 0"
                  ><i class="iconfont icon-delete"></i
                ></span>
                <el-tooltip
                  effect="dark"
                  content="请移除或删除分组下的活码，再删除分组"
                  placement="top"
                  v-else
                >
                  <span class="q-info" @click.stop
                    ><i class="iconfont icon-delete"></i
                  ></span>
                </el-tooltip>
              </div>

              <el-popover
                v-if="item.id === '1'"
                placement="bottom-end"
                trigger="click"
                popper-class="add-group-popover"
                @show="
                  form.name = '';
                  form.editId = '';
                "
              >
                <i
                  class="iconfont icon-plus-fill"
                  slot="reference"
                  @click.stop="handleCancel"
                ></i>
                <div class="add-group">
                  <el-input
                    size="small"
                    placeholder="请输入分组名称"
                    maxlength="20"
                    show-word-limit
                    v-model="form.name"
                  ></el-input>
                  <el-button round size="small" @click="handleCancel"
                    >取消</el-button
                  >
                  <el-button
                    round
                    size="small"
                    type="primary"
                    @click="handleSave"
                    >确定</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="right-area">
          <el-table
            ref="table"
            v-if="ready"
            :data="tableData"
            :height="tableHeight"
            @selection-change="handleSelectionChange"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="liveCodeName"
              label="活码名称"
              min-width="120"
            >
            </el-table-column>
            <el-table-column prop="liveCodeUrl" label="二维码" width="85">
              <template slot-scope="scope">
                <div class="p-qrcode-box">
                  <el-popover placement="top" width="200" trigger="hover">
                    <img
                      :src="scope.row.liveCodeUrl"
                      style="width: 100%; height: 100%"
                    />
                    <i
                      slot="reference"
                      class="iconfont icon-Code"
                      style="font-size: 18px; margin-right: 10px"
                    ></i>
                  </el-popover>
                  <el-button
                    type="text"
                    @click="handleDownload(scope.row)"
                    style="padding: 0"
                    >下载</el-button
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="totalUser"
              label="总用户数"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="yesUser"
              label="昨日新增用户数"
              align="center"
              min-width="140"
            >
              <template #header>
                <span style="margin-right: 4px">昨日新增用户数</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top"
                  popper-class="q-tooltip"
                >
                  <div slot="content">
                    昨日扫码添加（包含昨日已删除）的用户总数，<br />此前通过该活码添加再删除的用户不纳入计算
                  </div>
                  <i class="iconfont icon-info-fill"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="todayUser"
              label="今日新增用户数"
              align="center"
              min-width="140"
            >
              <template slot="header">
                <span style="margin-right: 4px">今日新增用户数</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top"
                  popper-class="q-tooltip"
                >
                  <div slot="content">
                    今日扫码添加（包含已删除）的用户总数，<br />此前通过该活码添加再删除的用户不纳入计算
                  </div>
                  <i class="iconfont icon-info-fill"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="expireTime" label="失效时间" min-width="100">
              <template slot-scope="scope">
                <div class="ly-table__main-time">
                  <template v-if="scope.row.expireTime">
                    <template v-if="scope.row.expireStatus">已失效</template>
                    <template v-else>{{
                      util.timeTofmt(scope.row.expireTime, "yyyy-MM-dd hh:mm")
                    }}</template>
                  </template>
                  <template v-else>永不失效</template>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建日期" min-width="100">
              <template slot-scope="scope">
                <div class="ly-table__main-time">
                  {{ util.timeTofmt(scope.row.createTime, "yyyy-MM-dd hh:mm") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <ly-action :display="2" :key="Date.now()">
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: 'simpleAnalysis',
                        query: {
                          id: scope.row.id,
                          name: scope.row.liveCodeName,
                        },
                      })
                    "
                    >数据分析</el-button
                  >
                  <el-button
                    type="text"
                    @click="
                      fn_showDetail({
                        id: scope.row.id,
                        createTime: scope.row.createTime,
                      })
                    "
                    >详情</el-button
                  >
                  <el-button
                    v-if="!(scope.row.expireTime && scope.row.expireStatus)"
                    type="text"
                    icon="iconfont icon-edit"
                    :disabled="scope.row.expireTime && scope.row.expireStatus"
                    @click="
                      $router.push({
                        path: 'edit',
                        query: { id: scope.row.id },
                      })
                    "
                    >修改</el-button
                  >
                  <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    content="活码已失效"
                    placement="top"
                  >
                    <el-button
                      type="text"
                      icon="iconfont icon-edit"
                      class="is-disabled"
                      >修改</el-button
                    >
                  </el-tooltip>
                  <el-button
                    type="text"
                    icon="iconfont icon-copy"
                    @click="
                      $router.push({
                        path: 'edit',
                        query: { id: scope.row.id, type: 'copy' },
                      })
                    "
                    >复制</el-button
                  >
                  <el-button
                    type="text"
                    icon="iconfont icon-delete"
                    @click="activityDel(scope.row.id, scope.row.liveCodeName)"
                    >删除</el-button
                  >
                </ly-action>
              </template>
            </el-table-column>
            <div slot="empty" class="ly-table-empty">
              <slot name="empty">
                <template v-if="flag">
                  <template v-if="isCustomerSeach">
                    <img src="@assets/svg/default/no_res.svg" alt="" />
                    无搜索结果
                  </template>
                  <template v-else>
                    <img src="@assets/svg/default/no_data.svg" alt="" />
                    暂无数据
                  </template>
                </template>
              </slot>
            </div>
          </el-table>
        </div>
      </div>
      <el-pagination
        background
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="per_page"
        layout="slot, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <div class="pagination-slot">
          <span class="total">共{{ total }}条记录</span>
          <slot name="pagination"></slot>
        </div>
      </el-pagination>
    </div>
    <grouping ref="grouping" @change="handleGrouped" />
    <!-- <add-group ref="addGroup" @change="getTopic" /> -->
    <detail
      ref="detail"
      :did="detailId"
      :createTime="detailCreateTime"
    ></detail>
    <download-list
      ref="downloadList"
      :list="selected"
      @fn_clearSelect="fn_clearSelect"
    ></download-list>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { list, groupManagement } from './http.js'
import grouping from './components/grouping'
// import addGroup from './components/addGroup'
import detail from './components/detail'
import downloadList from './components/downloadList'
export default {
  mixins: [lyTable],
  components: {
    grouping,
    detail,
    downloadList
  },
  data () {
    const disabledDate = (time) => {
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _times = this.$day(this.$day(_date).format('YYYY-MM-DD') + ' 23:59:59')
      return time.getTime() > _times.valueOf()
    }
    return {
      COMM_HTTP: list,
      loading: false,
      page: 1,
      per_page: 10,
      total: 0,
      groupList: [],
      topicId: '1',
      topicName: '',
      toolDisable: true,
      detailId: null,
      detailCreateTime: '',
      tableHeight: 0,
      form: {
        editId: '',
        name: ''
      },
      mainForm: {
        liveCodeName: '',
        createTime: ''
      },
      pickerOptions: {
        disabledDate: (time) => {
          return disabledDate(time)
        }
      },
      ready: false,
      selected: [],
      flag: false,
      isCustomerSeach: false
    }
  },
  beforeMount () {
    this.getTopic()
    this.queryData()
  },

  mounted () {
    window.onresize = () => {
      this.getTableHeight()
    }
    setTimeout(() => {
      this.getTableHeight()
    })
  },
  methods: {
    handlerSearch () {
      this.isCustomerSeach = true
      this.queryData()
    },
    async queryData () {
      this.$store.dispatch('ADDLOADING')
      const params = {
        groupId: this.topicId === '1' ? null : this.topicId,
        page: this.page,
        per_page: this.per_page
      }
      if (this.mainForm.liveCodeName) {
        params.liveCodeName = this.mainForm.liveCodeName
      }
      if (this.mainForm.createTime) {
        params.begin = this.mainForm.createTime[0]
        params.end = this.mainForm.createTime[1]
      }
      const res = await this.COMM_HTTP.reqQuery(params)
      this.$store.dispatch('REMOVELOADING')
      this.flag = true
      this.tableData = res.records
      this.total = res.total
    },

    fn_reset () {
      this.mainForm = {
        liveCodeName: '',
        createTime: ''
      }
      this.queryData()
    },
    // 清空选择
    fn_clearSelect () {
      this.$refs.table.clearSelection()
    },
    // 分组名称tooltip
    fn_groupEnter (e, max) {
      const ev = e.target
      const ev_width = ev.offsetWidth
      if (ev_width >= (max || 78)) {
        this.toolDisable = false // 文本的实际高度)
      } else {
        this.toolDisable = true
      }
    },
    handleGroup () {
      this.$refs.grouping.visible = true
    },

    // 获取最新分组数据 并清空选择
    handleGrouped () {
      this.getTopic()
      this.page = 1
      this.queryData()
      this.$refs.table.clearSelection()
    },

    // 添加分组
    handleAddGroup () {
      this.$refs.addGroup.visible = true
    },

    // 分组列表数据
    async getTopic () {
      const res = await groupManagement.getTopic()
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.groupList = res
    },

    // 点击分组
    handleTopic (item) {
      this.isCustomerSeach = false
      this.topicId = item.id
      this.topicName = item.groupName
      this.page = 1
      this.queryData()
    },

    // 删除分组
    async handleDelete ({ id, groupName }) {
      this.$lyConfirm({
        title: `确认要删除分组 ${groupName} 吗？`,
        text: '分组删除后将无法找回, 是否继续?',
        confirmButtonText: '删除'
      }).then(async () => {
        await groupManagement.delTopic({ id })
        this.topicId = '1'
        this.getTopic()
        this.queryData()
      }).catch(() => { })
    },

    // 修改分组信息
    async handleEdit ({ id, groupName }) {
      this.handleCancel()
      this.form.name = groupName
      this.form.editId = id
    },

    formatterParams (params) {
      return {
        ...params,
        groupId: this.topicId === '1' ? null : this.topicId
      }
    },

    // 下载二维码
    async handleDownload ({ liveCodeName, liveCodeUrl }) {
      const x = new XMLHttpRequest()
      x.open('GET', liveCodeUrl, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = `${liveCodeName}_code`
        a.click()
      }
      x.send()
    },

    // 监听更多操作
    handleCommand (e, { id, liveCodeName }) {
      switch (e) {
        case 'edit':
          this.$router.push({ path: 'edit', query: { id } })
          break
        case 'copy':
          this.$router.push({ path: 'edit', query: { id, type: 'copy' } })
          break
        case 'delete':
          this.activityDel(id, liveCodeName)
          break
      }
    },

    // 活码详情
    fn_showDetail ({ id, createTime }) {
      this.$refs.detail.visible = true
      this.detailId = id
      this.detailCreateTime = createTime
    },

    // 删除
    activityDel (id, title) {
      this.$lyConfirm({
        title: `确认要删除活码【${title}】吗？`,
        text: ''
      }).then(async () => {
        const res = await this.COMM_HTTP.delete({
          id
        })
        if ((res && res.code) || (res && res.code === 0)) {
          this.fn_lyMsg('info', res.msg)
          return false
        }

        this.fn_lyMsg('success', '活码删除成功！')
        this.getTopic()
        this.page = 1
        this.queryData()
        this.$emit('change')
        this.fn_clearSelect()
        this.selected = []
      }).catch(() => { })
    },

    handleCancel () {
      document.body.click()
    },

    // 保存分组
    async handleSave () {
      if (this.form.name === '') {
        this.msgBox = this.$message.info('分组名称不能为空！')
        return false
      }

      const method = this.form.editId ? 'editTopic' : 'addTopic'
      const params = this.form.editId ? { id: this.form.editId, groupName: this.form.name } : { groupName: this.form.name }
      const res = await groupManagement[method](params)
      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }
      this.handleCancel()
      this.getTopic()
    },

    getTableHeight () {
      this.tableHeight = document.querySelector('.temp-container').offsetHeight
      this.ready = true
    },
    handleSizeChange (v) {
      this.per_page = v
      this.page = 1
      this.queryData()
    },
    handleCurrentChange (v) {
      this.page = v
      this.queryData()
    },
    handleSelectionChange (select) {
      this.selected = select
    }
  },

  destroyed () {
    window.onresize = null
  }
}
</script>
<style lang="scss" scoped>
@import "@assets/scss/components/tags.scss";
@import "@assets/scss/utils.scss";
.p-qrcode-box {
  display: flex;
  & > span {
    height: 24px;
  }
  .p-qrcode {
    margin-right: 16px;
  }
}
.el-dropdown {
  color: $--color-primary;
}
.qr {
  display: flex;
  .form-area {
    padding: 20px 20px 0 20px;
    border-radius: 16px;
    box-shadow: $--shadow;
    background: #fff;
    margin-bottom: 20px;
    ::v-deep {
      .el-form-item {
        margin-bottom: 20px;
      }
      .el-input--medium .el-input__inner {
        width: 160px;
      }
      .el-date-editor--daterange.el-input__inner {
        width: 240px;
      }
    }
  }
  .main-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 16px;
    box-shadow: $--shadow;
    background: #fff;
    margin-bottom: 80px;
    overflow: hidden;
    .right-area {
      flex: 1;
      overflow: hidden;
    }
    .hander-area {
      margin-bottom: 20px;
    }
    .temp-container {
      flex: 1;
      display: flex;
      border: 1px solid #dfe1e8;
      border-radius: 8px;
      overflow: hidden;
    }
    ::v-deep {
      .el-button--text {
        padding: 0;
      }
    }
  }
  .qr-list {
    width: 240px;
    overflow-y: scroll;
    background: #f7f8fc;
    border-right: 1px solid #dfe1e8;
    box-sizing: border-box;
  }
  .title {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #212b36;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0 20px;
  }
  .add-group {
    display: inline-block;
    width: 95px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    background: #ececec;
    border-radius: 4px;
    font-size: 14px;
  }
  .list {
    padding: 8px 0;
    max-height: calc(616px - 48px - 6px);
    margin-bottom: 16px;
  }
  .list-item {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    cursor: pointer;
    &.active {
      .temp {
        color: #2b7cff;
      }
    }
    &:hover {
      background-color: #eaebf2;
      .btn {
        display: inline-block;
      }
    }
    .temp {
      width: 115px;
      overflow: hidden;
      flex: 1;
    }
    .name {
      max-width: 90px;
      @include ellipsis;
    }
    .num {
      margin: 0 10px;
    }
    .btn {
      width: 70px;
      display: none;
      text-align: right;
      line-height: 22px;
      font-size: 16px;
      span {
        color: #454d5b;
        cursor: pointer;
        &:nth-of-type(2) {
          margin-left: 10px;
        }

        &.q-info {
          color: #b5b5b5;
          cursor: not-allowed;
        }
      }
    }
    .icon-plus-fill {
      color: $--color-primary;
      font-size: 22px;
      margin-left: 50px;
      &:hover {
        color: #5596ff;
      }
    }
  }
  .el-pagination {
    width: calc(100% - 260px) !important;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
    background: #fff;
    position: fixed;
    bottom: 0;
    height: 61px;
    text-align: right;
    right: 0;
    box-sizing: border-box;
    padding: 16px 20px 0 20px;
    z-index: 99;
    ::v-deep {
      .pagination-slot {
        float: left;
        font-weight: normal;
      }
      .el-pagination__total {
        display: none;
      }
    }
  }

  .xl-ly-table {
    flex: 1;
    ::v-deep {
      .el-table {
        width: calc(100% - 240px) !important;
        position: relative;
        left: 240px;
        border: 1px solid #dfe1e8;
        z-index: 999;
        border-radius: 0 8px 8px 0;
      }
      .ly-table-main {
        // min-height: calc(100% - 200px);
        flex: 1;
      }
      .el-table__row:last-of-type {
        td {
          border-bottom: unset;
        }
      }
      .el-table__header {
        border-radius: 0 8px 8px 0;
      }
    }
  }
  ::v-deep {
    .operation-cell .cell {
      flex-wrap: wrap;
      .el-button,
      .el-divider {
        line-height: 1.5;
        margin-bottom: 5px;
      }
    }
    .qr-list .el-table .el-button + .el-button {
      margin-left: unset;
    }
  }
}
.add-group {
  .el-input {
    width: 220px;
    margin-right: 24px;
  }
}
</style>
<style lang="scss">
.add-group-popover {
  padding: 12px;
  box-shadow: 0px 12px 24px 0px rgba(145, 158, 171, 0.24),
    0px 0px 2px 0px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  .popper__arrow {
    display: none;
  }
}
</style>
