<template>
  <div class="drafts">
    <el-dialog title="推广素材" :visible.sync="extendVisible" width="900px">
      <el-row>
        <el-col :span="12">
          <div class="previewMobilew" style="margin-right: 20px">
            <div
              class="previewMobile"
              style="width: 321px"
              v-loading="!isGenerateForm"
            >
              <normal-form :data="normalTableForm" :formName="formTable.name">
              </normal-form>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div>表单链接：</div>
          <div class="activity_url mt-12">{{ linkUrl }}</div>
          <div class="mt-12">
            <el-button round size="small" type="primary" @click="handleCopy()">
              复制链接
            </el-button>
          </div>

          <div style="margin-top: 60px">表单二维码：</div>

          <el-image
            class="mt-12"
            style="width: 140px; height: 140px"
            :src="imageUrl || '@/assets/svg/QRcode.svg'"
          >
          </el-image>

          <div class="mt-16">
            <el-button
              round
              type="primary"
              size="small"
              icon="iconfont icon-download"
              @click="handleDownload(imageUrl)"
            >
              下载二维码
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>

import '@/assets/scss/formTable/index.scss'
import normalForm from '../formC/normalForm'
import { useCom, currencyCom } from '../formC/componentsConfig.js'
import { tables } from '../http.js'

export default {
  data () {
    return {
      normalTableForm: {
        list: []
      },
      extendVisible: false,
      isGenerateForm: false,
      formTable: {},
      linkUrl: 'http://www.baidu.com',
      imageUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
    }
  },
  components: {
    normalForm
  },
  methods: {
    async show (row) {
      this.extendVisible = true
      await this._getComInfoById(row)
      this._getFormTable(row)
    },
    // 生成二维码
    createdQrCode () {
      this.util.qrCode(this.formTable.url + '/3', 140).then((rs) => {
        // console.log(rs)
        this.imageUrl = rs
      })
    },
    async _getFormTable (row) {
      const data = await tables.getOneForm(row.id)
      this.linkUrl = data.url + '/2'
      this.formTable = data
      this.createdQrCode()
    },
    async _getComInfoById (row) {
      const _com = [...useCom, ...currencyCom]
      const data = await tables.getOneCom(row.formId)
      const _list = []

      data.forEach(_ => {
        const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)

        let _filCom = _com.filter(com => com.dataType == _.dataType && com.mainType == _.mainType)
        if (_.presetType || _.presetType == 0) {
          _filCom = _filCom.filter(com => com.presetType == _.presetType)
        }
        _filCom = JSON.parse(JSON.stringify(_filCom))
        if (_.options) {
          _filCom[0].options.options = JSON.parse(_.options)
        }
        // _filCom[0].options.isDelete = +this.$route.query.status - 1

        delete _.options

        _list.push(Object.assign({}, ..._filCom, _,
          {
            key,
            // 绑定键值
            model: _filCom[0].type + '_' + key,
            rules: []
          }
        )
        )
      })
      this.$_copyComList = JSON.parse(JSON.stringify(_list))
      this.normalTableFormSelect = _list[0]
      this.normalTableForm.list = _list
      this.$nextTick(() => {
        this.isGenerateForm = true
      })
    },

    close () {
      this.extendVisible = false
    },
    handleCopy () {
      const input = document.createElement('input')
      input.value = this.linkUrl
      document.getElementsByTagName('body')[0].appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.getElementsByTagName('body')[0].removeChild(input)
      this.$message.success('复制成功')
    },
    async handleDownload ({ qr_url }) {
      const groupQrCode = await this.util.qrCode(this.formTable.url + '/3', 280)
      const x = new XMLHttpRequest()
      x.open('GET', groupQrCode, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = 'QR'
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang='scss' scoped>
.activity_url {
  width: 324px;
  // height: 88px;
  background: #f7f8fc;
  border-radius: 4px;
  border: 1px solid #eaebf2;
  padding: 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #454d5b;
  line-height: 22px;
}

.btns {
  width: 140px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #2b7cff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2b7cff;
  line-height: 22px;
  img {
    margin-right: 4px;
  }
}

.previewMobile {
  // margin-top: -40px;
  margin: 0 auto 0;
  height: 560px;

  ::v-deep {
    // .form-header,
    // .normalTable-form-list,
    .form-style .normalTable-form-container .normalTable-form-body {
      // width: 296px;
      transform: scale(0.856);
      margin-left: -27px;
    }
    .normalTable-form-container {
      height: 490px;
    }
    // .form-style .normalTable-form-container .form-info-remarks {
    //   width: 288px;
    // }
  }
  // transform: scale(0.87);
}
</style>
