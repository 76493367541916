<!--
 * @Author: zhanln
 * @Date: 2021-07-29 10:35:36
 * @LastEditTime: 2022-02-25 11:40:41
 * @LastEditors: zhanln
 * @Description: 选择群
-->

<template>
  <el-dialog
    v-if="dialogVisible"
    title="选择群聊"
    :visible.sync="dialogVisible"
    width="1000px"
    custom-class="q-dialog has-table"
    @close="handleSubmit"
  >
    <div class="group">
      <div class="group-left">
        <ly-table
          ref="lyTable"
          :data="tableData"
          :option="tableOpt"
          :page="page"
          :pageSize="per_page"
          :total="total"
          @searchChange="searchChange"
          @currentChange="handleCurrentChange"
          @selectionChange="handleSelectionChange"
        >
          <template #handler>
            <div class="text-small q-bg q-info flex">
              <el-button
                @click="fn_querySync"
                style="margin-right: 16px"
                :loading="loading"
                icon="iconfont icon-shuaxin1"
                round
                v-if="type === 1"
                >同步</el-button
              >
              <div>
                <i
                  class="icon-warning-fill iconfont"
                  style="margin-right: 2px; vertical-align: middle"
                ></i>
                <template v-if="type === 1">
                  仅展示云端账号在线且未用于其他裂变活动的群聊，无数据时，请发送任意消息激活群聊后尝试同步数据
                  <a @click="fn_jumpCloudPage">如何登录云端?</a>
                </template>
                <template v-else
                  >若该群已被用于其他裂变活动，则不可选择。</template
                >
              </div>
            </div>
          </template>
          <template #name_slot="{ row }">
            <div class="flex flex-start">
              <div class="ly-table-tearm">
                <i class="iconfont icon-weixin"></i>
              </div>
              <span style="margin-right: 8px; flex: 1">{{
                row.name ? row.name : "群聊"
              }}</span>
            </div>
          </template>

          <template #robot_list="{ row }">
            <more-popover
              title="企业成员"
              :list="sortRobot(row.robot_list || [])"
            >
              <template #reference>{{
                `${getOnlineCount(row.robot_list)}人 `
              }}</template
              >在线
              <template #item="{ item }">
                <div class="ly-team small">
                  <i class="el-icon-s-custom" style="font-size: 16px"></i>
                </div>
                <span class="m-group_name text-ellipsis">
                  {{ item.member_name }}
                  <span style="color: #00a86e" v-if="item.online_status"
                    >(在线)</span
                  >
                  <span style="color: #909399" v-else>(离线)</span>
                </span>
              </template>
            </more-popover>
          </template>

          <template #robot_list_head>
            云端账号
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新云端账号状态"
              placement="top"
            >
              <i
                class="iconfont icon-shuaxin1"
                style="cursor: pointer"
                @click="queryData"
              ></i>
            </el-tooltip>
          </template>

          <template #empty>
            <div class="q-bg q-info">
              <template v-if="type === 1">
                暂无数据，请同步云端账号且保持在线。<a @click="fn_jumpCloudPage"
                  >登录云端>></a
                >
              </template>
              <template v-else> 暂无数据 </template>
            </div>
          </template>
        </ly-table>
      </div>
      <div class="group-right">
        <div class="group-right__title">已选群（{{ selectList.length }}）</div>
        <div class="group-right__empty" v-if="selectList.length === 0">
          请选择群聊
        </div>
        <div class="group-right__list" v-else>
          <div class="item" v-for="(item, index) in selectList" :key="item.id">
            <div class="flex flex-start">
              <div class="ly-table-tearm">
                <i class="iconfont icon-weixin"></i>
              </div>
              <span style="margin-right: 8px; flex: 1">{{
                item.name || "群聊"
              }}</span>
            </div>
            <span class="remove" @click="fn_removeGroup(index, item)"
              ><i class="el-icon-close"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" round @click="handleCancle" v-if="hasCheck"
        >取消</el-button
      >
      <el-button type="primary" round size="medium" @click="handleSubmit">{{
        btnText
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { groupList, activity } from '../http.js'
import axios from '@/common/ajax.js'
import MorePopover from '@/components/MorePopover'
export default {
  name: 'selectGroup',
  mixins: [lyTable],
  components: {
    MorePopover
  },
  props: {
    baseSelect: {
      default: () => {
        return []
      }
    },
    hasCheck: {
      type: Boolean,
      default: false
    },
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data () {
    return {
      COMM_HTTP: groupList,
      IMMEDIATE: false,
      destory: false,
      dialogVisible: false,
      selectIds: [],
      isSearch: true,
      type: null, // 1 智能模式 2 普通模式
      btnText: '确定'
    }
  },

  watch: {
    selectList: {
      handler (val) {
        if (val.length > 0) {
          this.selectIds = val.map(item => {
            return item.id
          })
        } else {
          this.selectIds = []
        }
      },
      deep: true,
      immediate: true
    },
    type: {
      handler (n) {
        if (n === 1) {
          this.COMM_HTTP.reqQuery = params => axios.get('/wecom/group-activity/robotgroup-sel-list', { params })
        } else {
          this.COMM_HTTP.reqQuery = params => axios.get('/wecom/group-activity/group-sel-list', { params })
        }
        this.$nextTick(() => {
          this.$refs.lyTable && this.$refs.lyTable.doLayout()
          this.queryData()
        })
      }
    }
  },

  computed: {
    tableOpt () {
      return {
        tableProps: {
          height: 300
        },
        layout: 'total, prev, pager, next',
        showSelection: true,
        operation: 'hide',
        levitate: false,
        checkSelectable: this.checkSelectable,
        column: [
          {
            label: '群名称',
            prop: 'name',
            slot: 'name_slot',
            search: true
          },
          {
            label: '群成员数',
            prop: 'user_count',
            align: 'center'
          },
          {
            label: '群主',
            prop: 'owner_name',
            key: ['wecom_user_ids'],
            search: true,
            type: 'emp',
            empModal: false,
            empShowLength: 1,
            align: 'center'
          },
          {
            label: '云端账号',
            prop: 'robot_list',
            slot: 'robot_list',
            headerSlot: 'robot_list_head',
            hide: this.type === 2,
            align: 'center'
          }
        ]
      }
    }
  },

  methods: {

    async fn_querySync () {
      const res = await activity.groupList()
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.queryData()
    },

    async queryData () {
      this.isSearch = true
      this.loading = true
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: this.page,
        per_page: this.per_page,
        ...this.sort,
        ...this.params
      })
      this.loading = false
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        this.isSearch = false
        return
      }
      this.tableData = res.list || res.data || res[this.DATA_LOCATION]

      // 禁用判断，如果活动已经选择或使用了该群，则不禁用
      if (this.tableData) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.baseSelect.indexOf(this.tableData[i].id) > -1) {
            this.tableData[i].is_used_activity = false
          }
        }
      }
      this.total = res.total
      this.listData = res
      this.$nextTick(() => {
        this.fn_select()
      })
      return res
    },
    sortRobot (arr) {
      const temp = [...arr]
      return temp.sort((a, b) => {
        if (a.online_status === b.online_status) {
          return 0
        } else if (a.online_status) {
          return -1
        } else if (!a.online_status) {
          return 1
        }
      })
    },
    // 获取在线数量
    getOnlineCount (arr = []) {
      const temp = arr.filter(e => e.online_status)
      return temp.length
    },
    // 设置选中状态
    fn_select () {
      const list = this.tableData
      if (!list) return
      for (let i = 0; i < list.length; i++) {
        const index = this.selectIds.findIndex(item => {
          return item === list[i].id
        })
        if (index !== -1) {
          this.$refs.lyTable.$refs.elTable.toggleRowSelection(list[i], true)
        }
      }
      this.isSearch = false
    },

    // 移除
    fn_removeGroup (index, row) {
      this.selectList.splice(index, 1)
      this.selectIds.splice(this.selectIds.indexOf(row.id), 1)
      const rowId = row.id
      const data = this.tableData
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === rowId) {
          this.$refs.lyTable.$refs.elTable.toggleRowSelection(data[i], false)
        }
      }
    },

    // table 复选框监听
    handleSelectionChange (selection) {
      if (this.isSearch || this.tableData.length === 0) return

      // 选中的id
      const selectIds = selection.map(item => item.id)

      // 当前列表的所有id
      const dataIds = this.tableData.map(item => item.id)

      // 没选中/取消的id
      const unSelectIds = dataIds.filter(item => selectIds.indexOf(item) === -1)

      const ids = this.selectIds

      // 添加选中行
      for (let i = 0; i < selectIds.length; i++) {
        if (!ids.includes(selectIds[i])) {
          const row = this.tableData.filter(item => item.id === selectIds[i])
          this.selectList.push(...row)
        }
      }

      // 取消选中行
      for (let i = 0; i < unSelectIds.length; i++) {
        for (let j = 0; j < this.selectList.length; j++) {
          if (this.selectList[j].id === unSelectIds[i]) {
            this.selectList.splice(j, 1)
          }
        }
      }
    },

    // 设置复选框禁用
    checkSelectable (row) {
      if (row.is_used_activity) {
        return false
      } else {
        return true
      }
    },
    handleSubmit () {
      this.$emit('selectClose', this.selectList)
      if (!this.hasCheck) {
        this.dialogVisible = false
      }
    },

    handleCancle () {
      this.$refs.lyTable.$refs.elTable.clearSelection()
      this.dialogVisible = false
    },

    formatterParams (params) {
      return {
        ...params,
        order_by: 'group_created_at',
        direction: 'desc'
      }
    },
    // 跳转
    fn_jumpCloudPage () {
      const routeData = this.$router.resolve({
        path: '/cloudService/account/list'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";

.q-bg {
  padding: 0;

  a {
    color: #2b7cff;
    cursor: pointer;
  }
}

.group {
  display: flex;

  &-left {
    min-height: 400px;

    ::v-deep {
      .el-table__empty-block {
        width: 100% !important;
      }
      .el-table__empty-text {
        width: 100%;
      }
    }
  }
  &-left {
    width: 60%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
  }

  &-right {
    flex: 1;
    &__empty {
      min-height: 60px;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      color: #212b36;
      height: 36px;
      line-height: 36px;
      background: #f7f8fc;
      border-radius: 8px;
      padding: 0 22px;
      margin-bottom: 20px;
    }

    &__list {
      max-height: calc(50vh - 80px);
      min-height: calc(400px - 60px);
      overflow-y: scroll;
      .item {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        transition: 0.25s;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &:hover {
          background-color: #f5f7fa;
        }

        .info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          margin-right: 8px;
        }

        .ic {
          width: 32px;
          height: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          margin-right: 12px;
          border-radius: 2px;
          background-color: #2b7cff;
          color: #fff;
        }

        .name {
          flex: 1;
          @include ellipsis;
        }

        .remove {
          flex: 0 0 32px;
          width: 32px;
          height: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: color 0.25s;

          &:hover {
            cursor: pointer;
            color: $--color-danger;
          }
        }
      }
    }
  }

  ::v-deep {
    .xl-ly-search {
      padding: 0;
    }
  }
}

.xl-ly-table {
  flex: 1;
  overflow: hidden;
  ::v-deep {
    .el-input,
    .el-select {
      width: unset !important;
    }
    .xl-ly-search {
      margin-bottom: 0;
    }
    .handler-left {
      margin-top: 0;
    }
    .el-table {
      display: flex;
      flex-direction: column;
      .el-table__header-wrapper {
        overflow: unset;
      }
      .el-table__body-wrapper {
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }
  }
}
</style>
