<!--
 * @Author: zhanln
 * @Date: 2021-11-01 17:31:38
 * @LastEditTime: 2022-06-16 20:20:26
 * @LastEditors: zhan
 * @Description: 活动信息
-->

<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-width="160px" size="medium" class="act-form"
    v-loading="cardLoading">
    <!-- 活动名称 -->
    <el-form-item label="活动名称：" prop="title" class="basic-form__item">
      <el-input class="act-form__input" v-model.trim="form.title" maxlength="16" show-word-limit placeholder="请输入活动名称">
      </el-input>
    </el-form-item>

    <!-- 结束时间 -->
    <el-form-item label="结束时间：" prop="end_time" class="basic-form__item">
      <el-date-picker type="datetime" placeholder="请选择结束时间" v-model.trim="form.end_time"
        :picker-options="endDatePickerOptions" value-format="yyyy-MM-dd HH:mm:ss">
      </el-date-picker>
    </el-form-item>

    <!-- 活动首图 -->
    <el-form-item label="活动首图：" prop="bg_url" class="basic-form__item">
      <div class="q-info text-small">
        建议尺寸:680*800像素，大小不超过2MB，格式为JPG/GIF/PNG/JPEG
      </div>
      <ly-upload :img="form.bg_url" @getImg="fn_getImg($event, 'bg_url')" tipText="活动首图" accept=".png,.jpg,.jpeg,.gif">
      </ly-upload>
    </el-form-item>

    <!-- 活动封面图 -->
    <el-form-item label="活动封面图：" prop="show_url" class="basic-form__item">
      <template slot="label">活动封面图
        <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
          <div slot="content">
            活动封面图将展示在小程序活动中心“ 首页” 进行推广
          </div>
          <i class="iconfont icon-info-fill" style="position: relative; top: 1px"></i>
        </el-tooltip>：
      </template>
      <div class="q-info text-small">
        建议尺寸:680*450像素，大小不超过2MB，格式为JPG/PNG/JPEG
      </div>
      <ly-upload :img="form.show_url" @getImg="fn_getImg($event, 'show_url')" tipText="活动封面图" accept=".png,.jpg,.jpeg">
      </ly-upload>
    </el-form-item>

    <!-- 直播预告 -->
    <el-form-item label="直播预告：" prop="vn_id" class="basic-form__item">
      <el-input class="act-form__input" v-model.trim="form.vn_id" maxlength="60" show-word-limit placeholder="请输入视频号ID">
      </el-input>
      <p class="q-info text-small" style="line-height: 1.6; margin-bottom: 0px;">
        视频号ID必须与小程序主体一致，否则将无法跳转
        <span class="q-primary" style="margin-left: 24px; cursor: help;" @click="fn_toDoc">如何获取视频号ID？</span>
      </p>
    </el-form-item>

    <!-- 预约按钮 -->
    <el-form-item label="预约按钮：" prop="btn_title" class="basic-form__item">
      <el-input class="act-form__input" v-model.trim="form.btn_title" maxlength="8" show-word-limit
        placeholder="请输入预约按钮文字">
      </el-input>
    </el-form-item>

    <!-- 更多直播预告 -->
    <el-form-item label="更多直播预告：" class="basic-form__item is-long">
      <el-button round type="primary" size="small" icon="iconfont icon-plus"
        style="margin-right: 12px; margin-bottom: 12px;" @click="fn_openEditNotice(null)">新增</el-button>
      <span class="q-info text-small">新增更多直播预告，增加直播曝光率</span>

      <el-table :data="form.notice" class="group-table" max-height="380" v-if="form.notice.length">
        <el-table-column type="index" label="排序" width="68">
        </el-table-column>
        <el-table-column prop="title" label="直播间名称" min-width="90">
        </el-table-column>
        <el-table-column prop="open_time" label="开播时间" width="98">
          <template slot-scope="scope">
            {{ $day(scope.row.open_time).format('YYYY-MM-DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="poster_url" label="预告首图" min-width="80">
          <template slot-scope="scope">
            <ly-upload :img="scope.row.poster_url" @getImg="fn_changeRow($event, scope.row, 'poster_url')" mini>
            </ly-upload>
          </template>
        </el-table-column>
        <el-table-column prop="qrcode_url" label="预告二维码" min-width="80">
          <template slot-scope="scope">
            <ly-upload :img="scope.row.qrcode_url" @getImg="fn_changeRow($event, scope.row, 'qrcode_url')" mini cropper>
            </ly-upload>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" class-name="operation-cell">
          <template slot-scope="scope">
            <el-dropdown class="el-dropdown-more" placement="bottom-start"
              @command="(e) => fn_changeGroup(scope.$index, e, scope.row)">
              <span class="el-dropdown-link"><i class="el-icon-more"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="edit">编辑</el-dropdown-item>
                <el-dropdown-item :disabled="scope.$index === 0" command="up">上移</el-dropdown-item>
                <el-dropdown-item command="down" :disabled="scope.$index === form.notice.length - 1">下移
                </el-dropdown-item>
                <el-dropdown-item command="del">移除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <add-notice ref="addNoticeRef" @noticeEdit="fn_noticeEdit"></add-notice>
  </el-form>
</template>

<script>
// components
// import { mapState } from 'vuex'
// api
import { activity } from '../http'
import addNotice from './addNotice.vue'
export default {
  name: 'actBasic',

  components: {
    addNotice
  },

  data () {
    return {
      COMM_HTTP: activity,
      cardLoading: true,
      form: {
        title: '',
        end_time: null,
        bg_url: '',
        show_url: '',
        vn_id: '',
        btn_title: '预约直播',
        notice: []
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        end_time: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ],
        bg_url: [
          { required: true, message: '请上传活动首图', trigger: 'change' }
        ],
        show_url: [
          { required: true, message: '请上传活动封面图', trigger: 'change' }
        ],
        vn_id: [
          { required: true, message: '请输入视频号ID', trigger: 'change' }
        ],
        btn_title: [
          { required: true, message: '请填写预约按钮文字', trigger: 'change' }
        ]
      }
    }
  },

  computed: {
    endDatePickerOptions () {
      return {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
        selectableRange: (() => {
          const now = new Date()
          const pick = new Date(new Date(this.form.end_time).setHours(0, 0, 0, 0)).getTime()
          const today = new Date().setHours(0, 0, 0, 0)
          // 时间段，今天时间前不可选
          let rangeFirst = '00:00:00'
          if (pick === today) {
            rangeFirst = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
          }
          return `${rangeFirst} - 23:59:59`
        })()
      }
    }

    //   ...mapState({
    //     isEdit: state => state.task.isEdit,
    //     isCopy: state => state.task.isCopy,
    //     isEnd: state => state.task.isEnd,
    //     actId: state => state.task.actId
    //   })
  },

  watch: {
    'form.bg_url' (n) {
      this.$store.commit('liveact/SET_INFO', {
        name: 'bg_url',
        val: n
      })
    },
    'form.notice': {
      handler (n) {
        this.$store.commit('liveact/SET_INFO', {
          name: 'notice',
          val: n
        })
      }
    },
    'form.btn_title' (n) {
      this.$store.commit('liveact/SET_INFO', {
        name: 'btn_title',
        val: n
      })
    }
  },

  methods: {

    handleChange (e) {
      console.log('handleChange', e)
    },

    // 初始化
    fn_setForm (data) {
      if (data) {
        this.form = data
      }
      setTimeout(() => {
        this.cardLoading = false
      }, 100)
    },

    // 活动首图回调设置
    fn_getImg (img, type) {
      console.log('img', img, type)
      this.form[type] = img
      this.$refs.formRef.validateField(type)
    },

    // 新增/编辑预告
    fn_openEditNotice (item) {
      const id = this.form.notice.length
      this.$refs.addNoticeRef.fn_open(item, id)
    },

    // 编辑预告回调
    fn_noticeEdit (item) {
      const index = this.form.notice.findIndex(mitem => mitem.fid === item.fid)
      if (index === -1) {
        this.form.notice.push(item)
      } else {
        this.$set(this.form.notice, index, item)
      }
    },

    // 预告图、二维码编辑
    fn_changeRow (img, row, type) {
      row[type] = img
    },

    // 预告列表操作
    fn_changeGroup (index, type, row) {
      // 上移
      if (type === 'up') {
        const upDate = this.form.notice[index - 1]
        this.form.notice.splice(index - 1, 1)
        this.form.notice.splice(index, 0, upDate)
      }

      // 下移
      if (type === 'down') {
        const upDate = this.form.notice[index + 1]
        this.form.notice.splice(index + 1, 1)
        this.form.notice.splice(index, 0, upDate)
      }

      // 移除
      if (type === 'del') {
        this.$lyConfirm({
          title: '确定移除此预告?',
          text: '',
          confirmButtonText: '确定'
        })
          .then(() => {
            this.form.notice.splice(index, 1)
          })
          .catch(() => { })
      }

      if (type === 'edit') {
        this.$refs.addNoticeRef.fn_open(row)
      }
    },

    // 如何获取视频号
    fn_toDoc () {
      window.open('https://www.yuque.com/docs/share/2890cdcb-3648-48ae-8308-40dbd9efbca6', '_blank')
    },

    // 表单校验，通过则返回 form
    verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      if (new Date(this.form.end_time) < Date.now()) {
        this.fn_lyMsg('info', '活动结束时间不可早于当前时间')
        return false
      }

      if (this.form.notice.length) {
        this.form.notice.forEach((item, index) => {
          item.sort = index
          delete item.fid
        })
      }

      const form = this.util.extend(true, {}, this.form)

      return form
    }

  }
}
</script>

<style lang="scss" scoped>
.basic-form {
  width: 100%;

  &__item {
    width: 60%;
    min-width: 560px;

    &.is-long {
      width: 100%;
      max-width: 860px;
    }
  }
}

.group-table {
  width: 100%;
  border-bottom: none;
  line-height: 1;

  &::before {
    background-color: #eaebf2;
  }

  ::v-deep .el-button--text {
    padding-top: 0;
    padding-bottom: 0;
  }

  ::v-deep .el-dropdown-more {
    color: #2b7cff;
    line-height: 32px;

    &:hover {
      color: #5ca2ff;
    }
  }

  ::v-deep .el-input-number .el-input__inner {
    border-color: #eaebf2 !important;
  }
}
</style>
