import axios from '@/common/ajax.js'
const { stringify } = require('qs')

export const lnvitation = {
  reqQuery: (params) => axios.get(`/wecom/task/accounts?${stringify(params)}`) // 查询表格数据的接口
}

export const prize = {
  reqQuery: (params) => axios.get(`/wecom/task/reward-records?${stringify(params)}`) // 查询表格数据的接口
}
