<!--
 * @Author: zhanln
 * @Date: 2021-11-18 15:02:51
 * @LastEditTime: 2022-08-19 14:59:34
 * @LastEditors: zhan
 * @Description: 活动规则预览
-->

<template>
  <div class="reward-rule__pop" v-if="visible">
    <div class="reward-rule__mask"></div>
    <div class="reward-rule__body">
      <div class="reward-rule__box">
        <div class="reward-rule__title">活动规则</div>
        <div class="reward-rule__content">
          <el-input type="textarea" v-model="rule" resize="none" autosize readonly></el-input>
        </div>
      </div>
      <div class="reward-rule__close">
        <i class="el-icon-circle-close" @click="visible = false"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileRewardRule',

  data () {
    return {
      visible1: true
    }
  },

  computed: {
    ...mapState({
      visible: state => state.reserveTask.is_rule_focus,
      rule: state => state.reserveTask.rule
    })
  }
}
</script>

<style lang="scss" scoped>
.reward-rule {

  &__pop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    overflow: hidden;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }

  &__box {
    height: 290px;
    padding: 0 32px 20px;
    background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0819/16608917166DKR0OJE.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    border-radius: 12px;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    color: #fff8fe;
    font-weight: 500;
    padding-top: 13px;
  }

  &__content {
    margin-top: 24px;

    ::v-deep .el-textarea__inner {
      color: #272B30;
      border: none;
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      font-size: 12px;
      max-height: 200px;
    }
  }

  &__close {
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-top: 8px;
  }
}
</style>
