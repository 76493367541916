var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "140px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "奖品名称：", prop: "reward_title" } },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "8",
              "show-word-limit": "",
              placeholder: "请输入奖品名称"
            },
            model: {
              value: _vm.form.reward_title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "reward_title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.reward_title"
            }
          })
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "中奖概率：", prop: "rate" } }, [
        _c(
          "div",
          { staticClass: "q-bg is-default rule" },
          [
            _c("el-input-number", {
              staticClass: "number-small",
              attrs: {
                "controls-position": "right",
                min: 0,
                max: 100,
                precision: 0
              },
              on: {
                blur: function($event) {
                  return _vm.fn_handleBlur($event, "rate")
                }
              },
              model: {
                value: _vm.form.rate,
                callback: function($$v) {
                  _vm.$set(_vm.form, "rate", $$v)
                },
                expression: "form.rate"
              }
            }),
            _vm._v(" % "),
            _c(
              "span",
              {
                staticClass: "q-info text-small",
                staticStyle: { "margin-left": "12px" }
              },
              [
                _vm._v("已设置："),
                _c(
                  "span",
                  { class: [_vm.all_rate > 100 ? "q-danger" : "q-success"] },
                  [_vm._v(_vm._s(_vm.all_rate) + "%")]
                )
              ]
            ),
            _c(
              "span",
              {
                staticClass: "q-info text-small",
                staticStyle: { "margin-left": "12px" }
              },
              [
                _vm._v("剩余可设置："),
                _c(
                  "span",
                  { class: [_vm.remain_rate > 0 ? "q-success" : ""] },
                  [
                    _vm._v(
                      _vm._s(_vm.remain_rate >= 0 ? _vm.remain_rate : 0) + "%"
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "q-info text-small",
                staticStyle: { "line-height": "1.6", "margin-top": "12px" }
              },
              [
                _vm._v(
                  " 1.概率越大中奖概率越大，概率为0时，则不会中奖，建议填写在1-100之间"
                ),
                _c("br"),
                _vm._v(" 2.中奖概率总和应等于100% ")
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "奖品图片：", prop: "reward_cover" } },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("ly-upload", {
                attrs: { img: _vm.form.reward_cover, size: "1MB" },
                on: { getImg: _vm.fn_getRewardCover }
              }),
              _c(
                "div",
                {
                  staticClass: "q-info text-small act-form__info",
                  staticStyle: { flex: "1" }
                },
                [
                  _vm._v(" 奖品图片设计须知："),
                  _c("br"),
                  _vm._v(" 1. 建议图片尺寸：260px*260px，分辨率72 "),
                  _c("br"),
                  _vm._v(" 2. 支持JPG、PNG格式，大小1M以内 ")
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-form-item",
        [
          _c("template", { slot: "label" }, [
            _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
            _vm._v(" 兑换方式： ")
          ]),
          _c("reward-detail", {
            ref: "rewardDetailRef",
            on: { changeTotal: _vm.fn_changeTotal }
          })
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { label: "奖品库存：", prop: "total" } },
        [
          _c("el-input-number", {
            staticClass: "number-big",
            attrs: {
              "controls-position": "right",
              min: 0,
              max: 99999,
              precision: 0,
              placeholder: "奖品库存"
            },
            on: {
              blur: function($event) {
                return _vm.fn_handleBlur($event, "total")
              }
            },
            model: {
              value: _vm.form.total,
              callback: function($$v) {
                _vm.$set(_vm.form, "total", $$v)
              },
              expression: "form.total"
            }
          }),
          _vm.isEdit
            ? _c("div", { staticClass: "q-info" }, [
                _c("span", { staticStyle: { "margin-right": "24px" } }, [
                  _vm._v("已使用："),
                  _c("b", [_vm._v(_vm._s(_vm.form.user_count))])
                ]),
                _c("span", [
                  _vm._v("剩余库存："),
                  _c("b", [
                    _vm._v(_vm._s(_vm.form.total - _vm.form.user_count))
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }