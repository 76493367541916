<!--
 * @Author: zhanln
 * @Date: 2022-03-21 15:46:50
 * @LastEditTime: 2022-04-19 11:52:41
 * @LastEditors: zhanln
 * @Description: 图片
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="110px"
    size="medium"
    class="edit-form"
    v-loading="loading"
  >
    <!--  素材名称 -->
    <el-form-item label="素材名称：" prop="name">
      <el-input
        class="edit-form__input"
        v-model.trim="form.name"
        maxlength="20"
        show-word-limit
        placeholder="请输入素材名称"
      ></el-input>
    </el-form-item>

    <!--  分组 -->
    <el-form-item>
      <template slot="label"><span class="q-danger">*</span> 分组：</template>
      <el-select v-model="form.group_id" class="edit-form__input">
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <!--  图片 -->
    <el-form-item label="图片：" prop="file_url">
      <div class="q-info text-small">支持JPG、PNG格式，图片小于10M</div>
      <ly-upload
        :img="form.file_url"
        size="10MB"
        tipIcon="el-icon-plus"
        @getImg="fn_getPoster"
      ></ly-upload>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'edImage',

  props: {
    hasChange: {
      type: Boolean,
      defualt: false
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentGroupId: {
      type: String,
      default: '0'
    }
  },

  data () {
    return {
      loading: true,
      form: {
        type: 2,
        name: '',
        group_id: '0',
        file_url: '',
        content: {}
      },
      rules: {
        name: [
          { required: true, message: '请填写素材名称', trigger: 'change' }
        ],
        file_url: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      },
      isEdit: false,
      editReady: false
    }
  },

  watch: {
    loading (val) {
      if (!val) {
        this.$emit('ready')
      }
    },
    form: {
      handler (val) {
        if ((this.isEdit && this.editReady) || (!this.isEdit && !this.editReady)) {
          this.$emit('update:hasChange', true)
        }
        if (this.isEdit) {
          this.editReady = true
        }
      },
      deep: true
    }
  },

  methods: {
    // 初始化数据
    fn_init (form) {
      if (!form) {
        this.form.group_id = this.currentGroupId === '-1' ? '0' : this.currentGroupId
        this.loading = false
        return
      }

      const detail = this.util.extend(true, {}, form)
      detail.file_url = detail.content.file_url
      this.form = detail

      this.loading = false
      this.isEdit = true
    },

    // 图片回调
    fn_getPoster (file_url, info) {
      this.form.file_url = file_url
      this.form.content.file_url = info ? file_url : ''
      this.form.content.file_origin_name = info ? info.name : ''
      this.form.content.file_size = info ? info.size : 0
      this.form.content.file_ext = info ? info.type : ''
      this.$refs.formRef.validateField('file_url')
    },

    // 表单校验，通过则返回 form
    fn_verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)
      delete form.file_url

      return form
    }
  }
}
</script>
