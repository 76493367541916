var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "140px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "活动名称：", prop: "title" }
        },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "16",
              "show-word-limit": "",
              placeholder: "请输入活动名称"
            },
            model: {
              value: _vm.form.title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "活动封面图：", prop: "show_url" }
        },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm._v("活动封面图 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    placement: "top",
                    "popper-class": "q-tooltip"
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 活动封面图将展示在小程序活动中心“ 首页” 进行推广 ")
                  ]),
                  _c("i", {
                    staticClass: "iconfont icon-info-fill",
                    staticStyle: { position: "relative", top: "1px" }
                  })
                ]
              ),
              _vm._v("： ")
            ],
            1
          ),
          _c("div", { staticClass: "q-info text-small" }, [
            _vm._v(" 建议尺寸:702*340像素，大小不超过2MB，格式为JPG/PNG/JPEG ")
          ]),
          _c("ly-upload", {
            attrs: {
              img: _vm.form.show_url,
              tipText: "活动封面图",
              accept: ".png,.jpg,.jpeg"
            },
            on: {
              getImg: function($event) {
                return _vm.fn_getImg($event, "show_url")
              }
            }
          })
        ],
        2
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "直播预告：", prop: "vn_id" }
        },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "60",
              "show-word-limit": "",
              placeholder: "请输入视频号ID"
            },
            model: {
              value: _vm.form.vn_id,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "vn_id",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.vn_id"
            }
          }),
          _c(
            "p",
            {
              staticClass: "q-info text-small",
              staticStyle: { "line-height": "1.6", "margin-bottom": "0px" }
            },
            [
              _vm._v(" 视频号ID必须与小程序主体一致，否则将无法跳转 "),
              _c(
                "span",
                {
                  staticClass: "q-primary",
                  staticStyle: { "margin-left": "24px", cursor: "help" },
                  on: { click: _vm.fn_toDoc }
                },
                [_vm._v("如何获取视频号ID？")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }