<!--
 * @Author: zhanln
 * @Date: 2021-08-27 16:42:56
 * @LastEditTime: 2021-12-15 15:34:13
 * @LastEditors: Please set LastEditors
 * @Description: 活码详情
-->

<template>
  <el-dialog
    title="活码详情"
    :visible.sync="visible"
    custom-class="q-dialog"
    width="880px"
    append-to-body
  >
    <div class="detail" v-if="did" v-loading="dataLoading">
      <div class="detail-left" v-loading="posterReady">
        <img
          :src="poster"
          alt=""
          class="detail-poster"
          v-if="detailData.linkStatus && detailData.link.linkType === 2"
        />
        <img :src="detailData.liveCodeUrl" alt="" class="detail-code" v-else />
        <div class="detail-title">{{ detailData.liveCodeName }}</div>
        <el-button type="primary" round @click="fn_download('code')"
          ><i class="iconfont icon-download"></i> 下载活码</el-button
        >
        <el-button
          icon="iconfont icon-picture"
          round
          v-if="detailData.linkStatus && detailData.link.linkType === 2"
          @click="fn_download('poster')"
          >下载海报</el-button
        >
        <el-tooltip
          class="item"
          content="活码已失效"
          :disabled="!isEnd"
          placement="top"
        >
          <div>
            <el-button
              icon="iconfont icon-edit"
              :disabled="isEnd"
              style="margin-bottom: 0"
              round
              @click="fn_edit"
              >修改活码</el-button
            >
          </div>
        </el-tooltip>
      </div>
      <div class="detail-right">
        <div class="detail-right__items">
          <div class="title">基本信息</div>
          <div class="item">
            <div class="label">创建时间：</div>
            <div class="content">
              {{ util.timeTofmt(createTime, "yyyy-MM-dd hh:mm") }}
            </div>
          </div>
          <div class="item">
            <div class="label">所属分组：</div>
            <div class="content">{{ detailData.groupName }}</div>
          </div>
          <div class="item">
            <div class="label">涨粉账号：</div>
            <div class="content">
              <ly-drow
                :list="detailData.users"
                name="name"
                :size="999999"
              ></ly-drow>
            </div>
          </div>
          <div class="item">
            <div class="label">失效时间：</div>
            <template v-if="!!detailData.expireTime && detailData.expireStatus"
              >已失效
            </template>
            <template v-else>
              {{
                !!detailData.expireTime
                  ? util.timeTofmt(detailData.expireTime, "yyyy-MM-dd hh:mm")
                  : "永不失效"
              }}
            </template>
          </div>
        </div>

        <div class="detail-right__items">
          <div class="title">用户设置</div>
          <div class="item">
            <div class="label">用户标签：</div>
            <div class="content">
              <ly-drow
                :list="detailData.tags"
                name="name"
                dep
                :size="999999"
                v-if="detailData.tagStatus"
              ></ly-drow>
              <template v-else>无</template>
            </div>
          </div>
          <div class="item">
            <div class="label">昵称备注：</div>
            <div class="content" v-if="detailData.nicknameRemark">
              <div class="flex cus-flex">
                <span class="cus-view">
                  <i class="el-icon-s-custom ly-tag_icon"></i>
                  <span class="suffix"
                    >微信昵称-{{ detailData.nicknameRemark }}</span
                  >
                </span>
              </div>
            </div>
            <template v-else>无</template>
          </div>
        </div>

        <div class="detail-right__items">
          <div class="title">链接设置</div>
          <div class="item">
            <div class="label">活码链接：</div>
            <div v-if="detailData.linkStatus">
              <el-button
                size="small"
                round
                type="primary"
                class="copy_url"
                @click="fn_copy('copy_url')"
                :data-clipboard-text="detailData.link.liveCodeLink"
                >复制链接</el-button
              >
            </div>
            <div v-else>无</div>
          </div>
          <div class="item">
            <div class="label">联系账号：</div>
            <div class="content" v-if="detailData.contactStatus">
              <ly-drow
                :list="contactUsers"
                name="name"
                :size="999999"
              ></ly-drow>
            </div>
            <template v-else>无</template>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
import lyDrow from '@/components/lyDrow'
import { list } from '../http.js'
import mapTag from '@/common/mapTag'
export default {
  props: {
    createTime: {
      type: String,
      default: ''
    },
    did: {
      type: String,
      default: ''
    }
  },
  components: {
    lyDrow
  },
  data () {
    return {
      COMM_HTTP: list,
      dataLoading: true,
      visible: false,
      detailData: {
        link: {}
      },
      msgList: [],
      link: {},
      contactUsers: [],
      qrCode: '',
      poster: '',
      posterReady: true,
      isEnd: false
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.detailData = { link: {} }
        this.dataLoading = true
        this.fn_getDetail()
      }
    }
  },
  methods: {

    // 获取详情
    async fn_getDetail () {
      const data = await this.COMM_HTTP.detail({
        id: this.did
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.backComfirm = false
        return false
      }

      this.detailData = data

      // 是否已经失效
      this.isEnd = this.detailData.expireTime && this.detailData.expireStatus

      // 欢迎语预览处理
      this.msgList = this.fn_initAttach(this.detailData.welcomeContent, this.detailData.attachments)

      this.link = {
        title: this.detailData.liveCodeName,
        contactStatus: this.detailData.contactStatus,
        liveCodeUrl: this.detailData.liveCodeUrl
      }

      if (this.detailData.linkStatus) {
        this.link.type = this.detailData.link.linkType
        this.link.code = this.detailData.liveCodeUrl
        this.link.linkContent = this.detailData.link.linkContent

        // 联系账号
        if (this.detailData.contactStatus) {
          this.contactUsers = await this.fn_getDataForId(this.detailData.link.contactUserId)
          this.link.contactUsers = this.contactUsers
        }

        // 海报生成
        if (this.detailData.link.linkType === 2) {
          this.fn_initPoster(this.detailData.liveCodeUrl, this.detailData.link)
        } else {
          this.posterReady = false
        }

        if (this.detailData.virtualStatus && this.detailData.link.virtualTime) {
          this.link.virtualContent = this.detailData.link.virtualContent
          this.link.showVirtual = true
          const { d, h, m, s } = this.fn_setExpire(this.detailData.link.virtualTime)
          this.link.unreal_expire_day = d
          this.link.unreal_expire_hour = h
          this.link.unreal_expire_minute = m
          this.link.unreal_expire_second = s
        }
      } else {
        this.posterReady = false
      }

      this.dataLoading = false
    },

    // 回显 - 虚拟剩余时间
    fn_setExpire (data) {
      const eTime = [86400, 3600, 60, 0]
      const time = [0, 0, 0, 0]

      let _data = data
      for (let i = 0; i < eTime.length; i++) {
        if (i === 3) {
          time[i] = _data
        } else {
          if (_data >= eTime[i]) {
            time[i] = Math.floor(_data / eTime[i])
            _data = _data % eTime[i]
          }
        }
      }

      const { 0: d, 1: h, 2: m, 3: s } = time
      return { d, h, m, s }
    },

    // 生成海报
    async fn_initPoster (code, opt) {
      this.poster = await this.util.syntheticPoster(opt.linkImage, [
        {
          url: code,
          sx: Math.round(opt.qrcodeLeft * 2.5),
          sy: Math.round(opt.qrcodeTop * 2.5),
          swidth: Math.round(opt.qrcodeWidth * 2.5),
          sheight: Math.round(opt.qrcodeHeight * 2.5)
        }
      ])
      this.link.poster = this.poster
      this.posterReady = false
    },

    // 根据回显 id 获取员工信息
    async fn_getDataForId (ids) {
      let _list = []
      const data = await this.axios.get('empUsers', {
        params: {
          user_ids: ids
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.data) {
        _list = data.data
      } else {
        _list = []
      }
      return _list
    },

    // 处理消息
    fn_initAttach (text, data) {
      const attach = [
        {
          type: 'text',
          content: mapTag(text)
        }
      ]
      for (let i = 0; i < data.length; i++) {
        // 图片
        if (data[i].messageType === 1) {
          attach.push({
            type: 'image',
            image: data[i].imageUrl
          })
        }

        // 链接
        if (data[i].messageType === 2) {
          attach.push({
            type: 'link',
            title: data[i].linkTitle,
            desc: data[i].linkDsc,
            cover: data[i].linkPicUrl
          })
        }

        // 小程序
        if (data[i].messageType === 3) {
          attach.push({
            type: 'applet',
            title: data[i].title,
            cover: data[i].picMediaUrl
          })
        }
      }
      return attach
    },

    // 手机预览
    fn_showPhone (type) {
      this.$refs.phone.type = type
      this.$refs.phone.visible = true
    },

    // 编辑
    fn_edit () {
      this.$store.commit('_SET', {
        'global.firstTitle': '修改个人活码'
      })
      this.$router.push({ path: 'edit', query: { id: this.did } })
    },

    // 下载
    fn_download (type) {
      const img = type === 'code' ? this.detailData.liveCodeUrl : this.poster
      const name = this.detailData.liveCodeName
      const x = new XMLHttpRequest()
      x.open('GET', img, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = name + '_' + type
        a.click()
      }
      x.send()
    },

    // 复制
    fn_copy (el) {
      const clipboard = new Clipboard(`.${el}`)
      clipboard.on('success', () => {
        clipboard.destroy()
        this.fn_lyMsg('success', '复制成功')
      })
      clipboard.on('error', (e) => {
        this.fn_lyMsg('error', '复制失败，请手动复制')
        console.error('E:', e)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.detail {
  display: flex;

  &-title {
    margin-bottom: 16px;
  }

  &-left {
    width: 340px;
    border-right: 1px solid #DFE1E8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .el-button {
      margin-bottom: 16px;
      margin-left: 0;
    }
  }

  &-code {
    width: 200px;
    height: 200px;
    margin-bottom: 16px;
  }

  &-poster {
    width: 168px;
    height: 296.08px;
    margin-bottom: 16px;
  }

  &-right {
    flex: 1;
    height: 564px;
    overflow-y: scroll;
    padding: 8px 60px;
    box-sizing: border-box;

    &__items {
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .title {
        position: relative;
        color: $--color-text-primary;
        padding-left: 12px;

        &:before {
          position: absolute;
          content: "";
          width: 4px;
          height: 16px;
          top: 50%;
          left: 0;
          border-radius: 2px;
          transform: translateY(-50%);
          background: $--color-primary;
        }
      }

      .item {
        margin: 4px 0;
        position: relative;
        line-height: 36px;
        display: flex;
      }

      .label {
        color: #6E788A;
        line-height: 36px;
        min-width: 84px;
        text-align: right;
      }

      .content {
        flex: 1;
      }

      .el-button--primary.is-plain {
        border-color: #EAEBF2;
        background: #F7F8FC;

        &:hover,
        &:active,
        &:focus {
          background-color: $--color-primary;
          border-color: $--color-primary;
        }
      }
    }
  }

  ::v-deep {
    .tag_over {
      padding-top: 2px;
    }
    .ly-tag {
      height: 24px;
      line-height: 24px;
      padding: 0 8px;
    }
  }
}
.cus {
  &-flex {
    height: 36px;
    margin-bottom: 8px;
  }

  &-view {
    display: flex;
    height: 36px;
    align-items: center;
    border: 1px solid #EAEBF2;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 8px;
    i {
      color: $--color-primary;
      margin-right: 4px;
    }
  }
}
</style>
