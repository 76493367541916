<!--
 * @Author: zhan
 * @Date: 2022-05-19 10:19:31
 * @LastEditTime: 2022-08-22 15:58:21
 * @LastEditors: zhan
-->
<template>
  <act-layout :cards="cards" ref="cards" @setKey="fn_setKey" v-loading="pageLoading">

    <!-- 表单区域 -->
    <!-- 活动信息 -->
    <template #basic>
      <act-basic ref="basic"></act-basic>
    </template>

    <!-- 海报 -->
    <template #poster>
      <act-poster ref="poster"></act-poster>
    </template>

    <!-- 预览区域 -->
    <template #mobile>
      <mobi-basic v-if="tabKey === 'basic'"></mobi-basic>
      <mobi-poster v-if="tabKey === 'poster'"></mobi-poster>
    </template>

    <!-- footer -->
    <template #footer v-if="!pageLoading">
      <act-footer @submit="fn_submit" :btnLoading="btnLoading" :isEnd="isEnd"></act-footer>
    </template>
  </act-layout>
</template>

<script>
// layout
import actLayout from '@/components/actLayout'
import actFooter from './activity/footer'
// forms
import actBasic from './activity/basic'
import actPoster from './activity/poster'
// mobiles
import mobiBasic from './mobile/basic'
import mobiPoster from './mobile/poster'
// store
import { mapState } from 'vuex'
// api
import { activity } from './http'
export default {
  components: {
    actLayout,
    actFooter,
    actBasic,
    actPoster,
    mobiBasic,
    mobiPoster
  },

  data () {
    return {
      pageLoading: true,
      btnLoading: false,
      tabKey: '',
      cards: [
        {
          label: '活动信息',
          name: 'basic'
        },
        {
          label: '海报设置',
          name: 'poster'
        }
      ],
      subForm: {},
      backComfirm: true,
      actId: null,
      isEnd: false
    }
  },

  computed: {
    ...mapState({
      editType: state => state.liveact.editType
    })
  },

  async mounted () {
    // 判断是创建、编辑、复制
    const editId = this.$route.query.id
    if (editId) {
      this.$store.commit('liveact/SET_INFO', {
        name: 'editType',
        val: 'edit'
      })

      // 详情
      await this.fn_getAct(editId)
    } else {
      this.fn_ready()
    }
  },

  methods: {

    // 获取表单详情
    async fn_getAct (id) {
      const data = await activity.detail({
        id
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.actId = data.id

      this.fn_ready(data)
    },

    // 导航监听
    fn_setKey (key) {
      this.tabKey = key
    },

    // 通知各表单组件初始化
    fn_ready (data) {
      let basicForm = null
      let posterForm = null

      // 如果有传入详情
      if (data) {
        // 已结束
        if (data.status === 2) {
          this.isEnd = true
        }

        const {
          bg_url,
          show_url,
          btn_title,
          end_time,
          notice,
          poster_conf,
          poster_url,
          title,
          vn_id
        } = data

        basicForm = {
          bg_url,
          show_url,
          btn_title,
          end_time,
          notice,
          title,
          vn_id
        }

        const poster = JSON.parse(poster_conf)
        posterForm = {
          ...poster,
          img_url: poster_url
        }
      }
      // 活动信息
      this.$refs.basic.fn_setForm(basicForm)
      // 裂变海报
      this.$refs.poster.fn_setForm(posterForm)
      this.pageLoading = false
    },

    // 提交校验
    async fn_submit () {
      // 各表单组件校验回调，如果检验通过，返回的是该表单的form对象

      // 活动信息
      const basic = this.$refs.basic.verify()
      if (!basic) {
        this.fn_formScroll(this.$refs.cards.$refs.basic[0].$el)
        return false
      }

      // 裂变海报
      const poster = this.$refs.poster.verify()
      if (!poster) {
        this.fn_formScroll(this.$refs.cards.$refs.poster[0].$el)
        return false
      }

      const poster_url = poster.img_url
      delete poster.img_url

      const poster_conf = JSON.stringify(poster)

      this.subForm = { ...basic, poster_conf, poster_url }

      // 判断是创建还是编辑
      this.btnLoading = true
      this.fn_edit()
      console.log('this.editType', this.editType)
    },

    // 提交
    async fn_edit () {
      if (this.editType === 'edit') {
        this.subForm.id = this.actId
      }

      const res = await activity.edit(this.subForm)

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        this.btnLoading = false
        return false
      }

      this.$message.success('发布成功！')
      this.backComfirm = false
      this.btnLoading = false
      this.$router.push('list')
    },

    // 滚动到表单报错位置
    fn_formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    // 重置缓存
    fn_resetForm () {
      this.$store.commit('liveact/RESET')
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          this.fn_resetForm()
          this.pageLoading = true
          next()
        }).catch(() => { })
      } else {
        this.fn_resetForm()
        this.pageLoading = true
        next()
      }
    }
  }
}
</script>
