var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "q-dialog",
          attrs: {
            title: "创建兑换码",
            visible: _vm.createVisable,
            "lock-scroll": "",
            "close-on-click-modal": false,
            "show-close": !_vm.dialogLoading
          },
          on: {
            "update:visible": function($event) {
              _vm.createVisable = $event
            },
            close: _vm.fn_resetForm
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ],
              attrs: { "element-loading-text": "正在导入文件，请稍等..." }
            },
            [
              _c(
                "el-form",
                {
                  ref: "createFormRef",
                  staticStyle: { width: "96%" },
                  attrs: {
                    model: _vm.createForm,
                    rules: _vm.createFormRules,
                    "label-width": "120px",
                    size: "medium"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "兑换码名称", prop: "title" } },
                    [
                      _c("el-input", {
                        staticClass: "input-limit",
                        attrs: {
                          placeholder: "仅自己查看，便于区分兑换码的用途",
                          minlength: "3",
                          maxlength: "32",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.createForm.title,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.createForm,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "createForm.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上传兑换码",
                        prop: "file",
                        error: _vm.fileErrorText
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "download_link",
                          attrs: { type: "text" },
                          on: { click: _vm.handleDownload }
                        },
                        [
                          _c("i", { staticClass: "iconfont icon-download" }),
                          _vm._v(" 下载模板")
                        ]
                      ),
                      _c("div", { staticClass: "q-info text-small" }, [
                        _vm._v(
                          " *兑换码格式请遵循‘下载模板’格式，请上传.xlsx、.csv文件 "
                        )
                      ]),
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: "#",
                            "http-request": _vm.fn_uploadFile,
                            accept: ".xlsx,.csv",
                            "show-file-list": false
                          }
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { round: "", type: "primary" } },
                            [_vm._v("上传文件")]
                          )
                        ],
                        1
                      ),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.fileName
                          ? _c("div", { staticClass: "code-upload" }, [
                              _c("span", { staticClass: "code-upload__item" }, [
                                _c("i", {
                                  staticClass: "el-icon-document",
                                  staticStyle: { "margin-right": "6px" }
                                }),
                                _vm._v(" " + _vm._s(_vm.fileName) + " ")
                              ]),
                              _c("span", { staticClass: "code-upload__icon" }, [
                                _c("i", {
                                  staticClass:
                                    "icon qy-icon-check-line q-success icon-check"
                                }),
                                _c("i", {
                                  staticClass: "el-icon-close icon-close",
                                  on: { click: _vm.fn_removeFile }
                                })
                              ])
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", disabled: _vm.dialogLoading },
                  on: {
                    click: function($event) {
                      _vm.createVisable = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    type: "primary",
                    loading: _vm.dialogLoading
                  },
                  on: { click: _vm.fn_submitCreateForm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "q-dialog",
          attrs: {
            title: "追加兑换码",
            visible: _vm.addVisable,
            "lock-scroll": "",
            "close-on-click-modal": false,
            "show-close": !_vm.dialogLoading
          },
          on: {
            "update:visible": function($event) {
              _vm.addVisable = $event
            },
            close: _vm.fn_resetForm
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ],
              staticClass: "code-add"
            },
            [
              _c("span", { staticStyle: { width: "68%" } }, [
                _vm._v("如果你要给"),
                _c("span", { staticClass: "q-primary" }, [
                  _vm._v("「" + _vm._s(_vm.codeItem.title) + "」")
                ]),
                _vm._v("追加兑换码数据， "),
                _c("br"),
                _vm._v("请上传兑换码文件 ")
              ]),
              _c(
                "el-form",
                {
                  ref: "addFormRef",
                  staticClass: "code-add__form",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.addFormRules,
                    size: "medium"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "file", error: _vm.fileErrorText } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: "#",
                            "http-request": _vm.fn_uploadFile,
                            accept: ".xlsx,.csv",
                            "show-file-list": false
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                round: "",
                                size: "medium",
                                type: "primary",
                                plain: ""
                              }
                            },
                            [_vm._v("上传文件")]
                          )
                        ],
                        1
                      ),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.fileName
                          ? _c(
                              "div",
                              {
                                staticClass: "code-upload",
                                staticStyle: { "margin-top": "16px" }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "code-upload__item" },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-document",
                                      staticStyle: { "margin-right": "6px" }
                                    }),
                                    _c("span", { staticClass: "code-name" }, [
                                      _vm._v(_vm._s(_vm.fileName))
                                    ])
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "code-upload__icon" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "icon qy-icon-check-line q-success icon-check"
                                    }),
                                    _c("i", {
                                      staticClass: "el-icon-close icon-close",
                                      on: { click: _vm.fn_removeFile }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", disabled: _vm.dialogLoading },
                  on: {
                    click: function($event) {
                      _vm.addVisable = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    type: "primary",
                    disabled: _vm.dialogLoading
                  },
                  on: { click: _vm.fn_submitAddForm }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }