<!--
 * @Author: zhanln
 * @Date: 2022-01-05 17:36:06
 * @LastEditTime: 2022-01-13 20:55:41
 * @LastEditors: zhanln
 * @Description: 裂变文案
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="160px"
    size="medium"
    class="act-form"
    v-loading="cardLoading"
  >
    <!-- 助力文案 -->
    <el-form-item label="助力文案：" prop="help_msg">
      <ly-editor
        class="act-form__editor"
        title="助力文案"
        id="helpMsg"
        :content="form.help_msg"
        :insetMenu="['用户昵称', '助力好友昵称']"
        :recoverContent="default_help_msg"
        emoji
        @getContent="fn_getHelpMsg"
      ></ly-editor>
      <span class="q-info text-small">
        当好友B助力了好友A时，推送给好友B的助力文案
      </span>
    </el-form-item>

    <!-- 结束文案 -->
    <el-form-item label="结束文案：" prop="end_msg">
      <ly-editor
        class="act-form__editor"
        title="结束文案"
        id="endMsg"
        :content="form.end_msg"
        :insetMenu="['用户昵称']"
        :recoverContent="default_end_msg"
        emoji
        @getContent="fn_getEndMsg"
      ></ly-editor>
      <span class="q-info text-small">
        当活动结束，二维码仍未失效时，用户添加涨粉账号时，推送此文案
      </span>
    </el-form-item>
  </el-form>
</template>

<script>
import mapTag from '@/common/mapTag'
export default {
  name: 'actNotice',

  data () {
    return {
      cardLoading: true,
      form: {
        help_msg: '',
        end_msg: ''
      },
      rules: {
        help_msg: [
          { required: true, message: '请填写助力文案', trigger: 'change' }
        ],
        end_msg: [
          { required: true, message: '请填写结束文案', trigger: 'change' }
        ]
      },
      default_help_msg: '#用户昵称# 😊 很高兴遇到你~，你已经为好友#助力好友昵称#成功助力了~\n\n活动正在火热进行中，你也可以点击下方活动链接，生成你的专属海报，成功邀请好友为你助力，即可获得抽奖机会哦~\n\n快来参加吧~',
      default_end_msg: '本次活动已经结束了哦~  😊 \n后续还会有更多活动信息，请持续关注我们，期待与您下次见面~'
    }
  },

  watch: {
    'form.help_msg' (val) {
      this.$store.commit('lottery/SET_INFO', {
        type: 'help_msg',
        val: val
      })
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        data.help_msg = mapTag(data.help_msg)
        data.end_msg = mapTag(data.end_msg)
        this.form = data
      } else {
        this.form.help_msg = this.default_help_msg
        this.form.end_msg = this.default_end_msg
      }
      this.cardLoading = false
    },

    // 助力文案回调
    fn_getHelpMsg (val) {
      this.form.help_msg = val
    },

    // 结束文案回调
    fn_getEndMsg (val) {
      this.form.end_msg = val
    },

    // 表单校验，通过则返回 form
    verify (type) {
      let formValid = true

      if (type) {
        const form = this.util.extend(true, {}, this.form)
        form.help_msg = form.help_msg ? mapTag(form.help_msg, false) : ''
        form.end_msg = form.end_msg ? mapTag(form.end_msg, false) : ''
        return this.form
      }

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })
      const form = this.util.extend(true, {}, this.form)
      form.help_msg = mapTag(form.help_msg, false)
      form.end_msg = mapTag(form.end_msg, false)

      return formValid ? form : false
    }
  }
}
</script>
