<!--
 * @Descripttion: 参与用户页面-pane组件
 * @version: 1.0.0
-->
<template>
  <ly-table
    ref="lyTable"
    :data="tableData"
    :option="tableOpt"
    :page="page"
    :pageSize="per_page"
    :total="total"
    @searchChange="searchChange"
    @sizeChange="handleSizeChange"
    @sortChange="handleSortChange"
    @currentChange="handleCurrentChange"
    @selectionChange="handleSelectionChange"
  >
    <template #handler>
      <el-button
        size="medium"
        round
        icon="iconfont icon-download"
        type="primary"
        v-lydebounce="['button', fn_export]"
        :disabled="!tableData || tableData.length === 0"
        :loading="exportLoading"
        >导出数据</el-button
      >
      <el-button
        v-if="type === '1'"
        type="primary"
        plain
        round
        icon="iconfont icon-plus"
        size="medium"
        :disabled="!selected.length"
        @click="handleAddBlack"
        >加入黑名单</el-button
      >
      <el-button
        v-else
        type="primary"
        plain
        round
        size="medium"
        :disabled="!selected.length"
        @click="handleRemoveBlack"
        >移除黑名单</el-button
      >
    </template>
    <template #title_slot="{ row }">
      <div class="flex flex-start">
        <img
          :src="row.avatar_url"
          alt=""
          class="ly-table__main-avatar"
          v-if="row.avatar_url"
        />
        <span>{{ row.title }}</span>
      </div>
    </template>

    <template #tag_names_slot="{ row }">
      <ly-drow :list="row.tag_names" dep v-if="row.tag_names"></ly-drow>
      <template v-else> - </template>
    </template>

    <template #join_type_headslot>
      <div>
        参与方式
        <el-tooltip class="item" placement="top" popper-class="q-tooltip">
          <div slot="content">
            <div style="margin-bottom: 8px">
              链接进入：用户通过直接点击活动链接参与活动，参与活动后不能再为其他人助力
            </div>
            <div>
              添加员工：用户通过扫码海报二维码添加员工参与活动，参与活动后不能再为其他人助力
            </div>
          </div>
          <i class="iconfont icon-info-fill q-info"></i>
        </el-tooltip>
      </div>
    </template>

    <template #inviter_nickname_slot="{ row }">
      <ly-tag :content="row.inviter_nickname" only>
        <i class="iconfont icon-superior" slot="left"></i>
      </ly-tag>
    </template>

    <template #steps_slot="{ row }">
      <div class="step">
        <template v-if="row.steps">
          <div
            class="step__item"
            v-for="(item, stepIndex) in row.steps"
            :key="stepIndex"
          >
            <div class="step__title">
              <template v-if="item.step === 1">一阶</template>
              <template v-else-if="item.step === 2">二阶</template>
              <template v-else>三阶</template>
            </div>
            <div class="step__line">
              <span
                :class="['circle', item.status === 0 ? '' : 'success']"
              ></span>
            </div>
            <div class="step__status">
              <template v-if="item.status === 0"
                ><span class="q-info">未完成</span></template
              >
              <template v-else><span class="q-success">已完成</span></template>
            </div>
          </div>
        </template>
        <template v-else> - </template>
      </div>
    </template>

    <template #wecom_user_name_slot="{ row }">
      <ly-drow :list="row.wecom_user_name"></ly-drow>
    </template>

    <template #is_deleted_slot="{ row }">
      <template v-if="row.is_deleted === 0">
        <ly-status type="success" v-if="row.wecom_user_name">已添加</ly-status>
        <span v-else>-</span>
      </template>
      <ly-status type="danger" v-else>已删除</ly-status>
    </template>
  </ly-table>
</template>
<script>
import { user } from '@/pages/client/task/http.js'
import lyTable from '@/components/mixins/ly-table.js'
import lyDrow from '@/components/lyDrow'
import lyTag from '@/components/lyTag'
export default {
  mixins: [lyTable],
  props: ['channelOps', 'type', 'activity_id', 'activities'],
  components: {
    lyDrow, lyTag
  },
  data () {
    return {
      COMM_HTTP: user,
      exportLoading: false,
      taskProgress: [{
        value: 0,
        label: '全部'
      }, {
        value: '1',
        label: '完成一阶任务'
      }, {
        value: '2',
        label: '完成二阶任务'
      }, {
        value: '3',
        label: '完成三阶任务'
      }],
      typeProgress: [{
        value: 0,
        label: '全部'
      }, {
        value: '1',
        label: '新用户'
      }, {
        value: '2',
        label: '老用户'
      }],
      statusOption: [{
        value: -1,
        label: '全部'
      }, {
        value: 0,
        label: '已添加'
      }, {
        value: 1,
        label: '已删除'
      }]
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        scrollEl: '.page-warp-bg',
        showSelection: true,
        operation: [
          {
            label: '详情',
            handler (row) {
              _this.$emit('detail', row)
            }
          }
        ],
        operationWidth: 80,
        column: [
          {
            label: '用户昵称',
            prop: 'title',
            key: 'nickname',
            slot: 'title_slot',
            search: true,
            fixed: true,
            sort: 1,
            width: 200,
            placeholder: '请输入用户昵称'
          },
          {
            label: '用户类型',
            prop: 'user_type',
            search: true,
            type: 'select',
            align: 'center',
            source: this.typeProgress,
            default: 0,
            width: 120,
            formatter ({ user_type }) {
              return user_type === 1 ? '新用户' : '老用户'
            }
          },
          {
            label: '用户标签',
            prop: 'tag_names',
            slot: 'tag_names_slot',
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '来源渠道',
            prop: 'channel_name',
            search: true,
            key: 'channel_id',
            type: 'select',
            default: null,
            source: this.channelOps,
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '直接上级',
            prop: 'inviter_nickname',
            slot: 'inviter_nickname_slot',
            columnProps: {
              'min-width': 140
            }
          },
          {
            label: '任务进度',
            prop: 'steps',
            key: 'step',
            slot: 'steps_slot',
            search: true,
            type: 'select',
            source: this.taskProgress,
            default: 0,
            columnProps: {
              'min-width': 150
            }
          },
          {
            label: '参与时间',
            prop: 'created_at',
            sortable: true,
            search: true,
            type: 'daterange',
            key: ['begin_at', 'end_at'],
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            columnProps: {
              'min-width': 145
            },
            formatter ({ created_at }) {
              return _this.util.timeTofmt(created_at, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '参与方式',
            prop: 'join_type',
            headerSlot: 'join_type_headslot',
            align: 'center',
            columnProps: {
              'min-width': 120
            },
            formatter ({ join_type }) {
              return join_type === 1 ? '添加员工' : '链接进入'
            }
          },
          {
            label: '有效邀请人数',
            prop: 'invite_count',
            sortable: true,
            align: 'center',
            columnProps: {
              'min-width': 140
            }
          },
          {
            label: '添加账号',
            prop: 'wecom_user_name',
            slot: 'wecom_user_name_slot',
            search: true,
            key: 'wecom_user_id',
            type: 'emp',
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '添加状态',
            prop: 'is_deleted',
            slot: 'is_deleted_slot',
            search: true,
            type: 'select',
            source: this.statusOption,
            default: -1,
            columnProps: {
              'min-width': 100
            }
          }
        ]
      }
    }
  },
  methods: {
    async queryData () {
      this.$refs.lyTable.$refs.elTable.clearSelection()
      this.$store.dispatch('ADDLOADING')
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: this.page,
        per_page: this.per_page,
        ...this.params,
        ...this.sort
      })
      this.$store.dispatch('REMOVELOADING')
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.tableData = res.list || res.data || res[this.DATA_LOCATION] || []
      this.total = res.total
      this.listData = res
      return res
    },
    formatterParams (params) {
      return {
        ...params,
        add_account_id: params.add_account_id,
        activity_id: this.activity_id,
        list_type: this.type
      }
    },

    // 导出数据
    async fn_export () {
      this.exportLoading = true
      const params = this.util.extend(true, {}, this.params)
      params.is_black_list = this.type === '1' ? 0 : 1
      const data = await this.COMM_HTTP.export(params)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.exportLoading = false
        return false
      }
      this.handleDownload(data.url)
      this.exportLoading = false
    },

    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },

    getFileName () {
      let _name = ''
      let actName = ''
      actName = this.getActName()
      _name = `[${actName}]-参与用户${this.type === '2' ? '-黑名单' : ''}-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },

    getActName () {
      const _obj = this.activities.find(n => {
        return n.value === this.activity_id
      })
      return _obj.label
    },

    async handleAddBlack () {
      this.$lyConfirm({
        text: '加入黑名单后该用户禁止参与平台其它活动，确定加入黑名单吗？',
        title: '提示',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const account_ids = this.selected.map(e => {
          return e.id
        }).join(',')
        const data = await this.COMM_HTTP.addBlack({ account_ids })

        if ((data && data.code) || (data && data.code === 0)) {
          this.$message.error(data.msg)
          return false
        }

        this.$message.success('加入黑名单成功！')
        this.queryData()
      }).catch(() => { })
    },
    async handleRemoveBlack () {
      const account_ids = this.selected.map(e => {
        return e.id
      }).join(',')
      const data = await this.COMM_HTTP.removeBlack({ account_ids })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }
      this.$message.success('移除黑名单成功！')
      this.queryData()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-superior {
  font-size: 13px;
  color: #2b7cff;
  margin-right: 4px;
}
</style>
