<template>
  <div>
    <!-- 创建兑换码 -->
    <el-dialog
      title="创建兑换码"
      :visible.sync="createVisable"
      lock-scroll
      :close-on-click-modal="false"
      :show-close="!dialogLoading"
      @close="fn_resetForm"
      class="q-dialog"
    >
      <div
        v-loading="dialogLoading"
        element-loading-text="正在导入文件，请稍等..."
      >
        <el-form
          :model="createForm"
          :rules="createFormRules"
          ref="createFormRef"
          label-width="120px"
          size="medium"
          style="width: 96%"
        >
          <el-form-item label="兑换码名称" prop="title">
            <el-input
              v-model.trim="createForm.title"
              placeholder="仅自己查看，便于区分兑换码的用途"
              minlength="3"
              maxlength="32"
              show-word-limit
              class="input-limit"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传兑换码" prop="file" :error="fileErrorText">
            <el-button type="text" class="download_link" @click="handleDownload"
              ><i class="iconfont icon-download"></i> 下载模板</el-button
            >
            <div class="q-info text-small">
              *兑换码格式请遵循‘下载模板’格式，请上传.xlsx、.csv文件
            </div>
            <el-upload
              action="#"
              :http-request="fn_uploadFile"
              accept=".xlsx,.csv"
              :show-file-list="false"
            >
              <el-button round type="primary">上传文件</el-button>
            </el-upload>
            <transition name="fade">
              <div class="code-upload" v-if="fileName">
                <span class="code-upload__item">
                  <i class="el-icon-document" style="margin-right: 6px"></i>
                  {{ fileName }}
                </span>
                <span class="code-upload__icon">
                  <i class="icon qy-icon-check-line q-success icon-check"></i>
                  <i
                    class="el-icon-close icon-close"
                    @click="fn_removeFile"
                  ></i>
                </span>
              </div>
            </transition>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          @click="createVisable = false"
          :disabled="dialogLoading"
          >取 消</el-button
        >
        <el-button
          round
          type="primary"
          @click="fn_submitCreateForm"
          :loading="dialogLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 追加兑换码 -->
    <el-dialog
      title="追加兑换码"
      :visible.sync="addVisable"
      lock-scroll
      :close-on-click-modal="false"
      :show-close="!dialogLoading"
      class="q-dialog"
      @close="fn_resetForm"
    >
      <div class="code-add" v-loading="dialogLoading">
        <span style="width: 68%"
          >如果你要给<span class="q-primary">「{{ codeItem.title }}」</span
          >追加兑换码数据， <br />请上传兑换码文件
        </span>
        <el-form
          :model="addForm"
          :rules="addFormRules"
          ref="addFormRef"
          size="medium"
          class="code-add__form"
        >
          <el-form-item prop="file" :error="fileErrorText">
            <el-upload
              action="#"
              :http-request="fn_uploadFile"
              accept=".xlsx,.csv"
              :show-file-list="false"
            >
              <el-button round size="medium" type="primary" plain
                >上传文件</el-button
              >
            </el-upload>
            <transition name="fade">
              <div class="code-upload" v-if="fileName" style="margin-top: 16px">
                <span class="code-upload__item">
                  <i class="el-icon-document" style="margin-right: 6px"></i>
                  <span class="code-name">{{ fileName }}</span>
                </span>
                <span class="code-upload__icon">
                  <i class="icon qy-icon-check-line q-success icon-check"></i>
                  <i
                    class="el-icon-close icon-close"
                    @click="fn_removeFile"
                  ></i>
                </span>
              </div>
            </transition>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="addVisable = false" :disabled="dialogLoading"
          >取消</el-button
        >
        <el-button
          round
          type="primary"
          @click="fn_submitAddForm"
          :disabled="dialogLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { code } from '@/pages/apps/code/http.js'
import * as lyCheck from '@/common/checkSpecificKey'
export default {
  props: {
    codeItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    const checkName = (rule, value, callback) => {
      if (value !== null && value !== '') {
        if (!lyCheck.checkSpecificKey(value)) {
          callback(new Error('名称中请勿包含特殊字符'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请填写兑换码名称'))
      }
    }
    return {
      COMM_HTTP: code,
      dialogLoading: false,
      createVisable: false,
      createForm: {
        id: 0,
        title: '',
        file: null,
        file_type: ''
      },
      createFormRules: {
        title: [
          { validator: checkName, required: true, trigger: 'change' },
          { min: 3, max: 32, message: '长度在 3 到 32 个字符', trigger: 'change' }
        ],
        file: [
          { required: true, message: '请上传兑换码文件', trigger: 'change' }
        ]
      },
      addVisable: false,
      addForm: {
        id: null,
        file: null,
        file_type: ''
      },
      addFormRules: {
        file: [
          { required: true, message: '请上传兑换码文件', trigger: 'change' }
        ]
      },
      fileList: [],
      fileName: '',
      fileErrorText: ''
    }
  },

  methods: {
    // 创建兑换码校验
    fn_submitCreateForm () {
      this.$refs.createFormRef.validate((valid) => {
        if (valid) {
          this.fn_submitForm(1)
        }
      })
    },

    // 追加兑换码校验
    fn_submitAddForm () {
      this.$refs.addFormRef.validate((valid) => {
        this.addForm.id = +this.codeItem.id
        if (valid) {
          this.fn_submitForm(2)
        }
      })
    },

    // 提交表单
    async fn_submitForm (type) {
      this.dialogLoading = true
      let form = {}
      if (type === 1) {
        form = this.createForm
      } else {
        form = this.addForm
      }
      const data = await this.COMM_HTTP.reqQuery(form)
      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.dialogLoading = false
        return false
      }

      this.dialogLoading = false

      if (data.success === 0) {
        this.fn_error(data)
      } else {
        this.fn_success(data)
      }
    },

    // 导入成功弹框
    fn_success (data) {
      this.$lyConfirm({
        title: '导入成功',
        text: `导入兑换码总数 ${data.total} 条，导入失败 ${data.check_fail_num} 条，其中重复数据 ${data.repeat_num} 条，实际导入 ${data.success} 条。`,
        confirmButtonText: '知道了',
        showCancelButton: false,
        type: 'success'
      }).then(async () => {
        if (this.createVisable) {
          this.createVisable = false
        } else {
          this.addVisable = false
        }
        this.$emit('success')
      })
    },

    // 导入失败弹框(实际导入为0时)
    fn_error (data) {
      this.$lyConfirm({
        title: '导入失败',
        text: `导入兑换码总数 ${data.total} 条，导入失败 ${data.check_fail_num} 条，其中重复数据 ${data.repeat_num} 条，实际导入 ${data.success} 条。`,
        confirmButtonText: '知道了',
        showCancelButton: false,
        type: 'error'
      }).then(async () => {
      })
    },

    // 选择文件
    fn_uploadFile (obj) {
      this.fileErrorText = ''
      // 文件类型判断（苹果系统 or safari 类型判断有问题，先用后缀判断）
      // if (obj.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && obj.file.type !== 'application/vnd.ms-excel') {
      //   this.fileErrorText = '请选择.xlsx、.csv文件'
      //   this.fileList = []
      //   this.fileName = ''
      //   return false
      // }
      const _suffix = obj.file.name.slice(obj.file.name.lastIndexOf('.'))
      // 文件类型判断（后缀
      if (_suffix !== '.csv' && _suffix !== '.xlsx') {
        this.fileErrorText = '请选择.xlsx、.csv文件'
        this.fileList = []
        this.fileName = ''
        return false
      }

      // 文件大小，限制3M
      if (obj.file.size > (1024 * 1024 * 3)) {
        this.fileErrorText = '文件大小请勿超过3M'
        this.fileList = []
        this.fileName = ''
        return false
      }

      this.fileList = []
      this.fileName = ''
      if (this.createVisable) {
        this.fn_getBase64(obj.file).then(resBase64 => {
          this.createForm.file = resBase64
          this.createForm.file_type = _suffix.slice(1, _suffix.length)
          this.$refs.createFormRef.validateField('file')
        })
      } else {
        this.fn_getBase64(obj.file).then(resBase64 => {
          this.addForm.file = resBase64
          this.addForm.file_type = _suffix.slice(1, _suffix.length)
          this.$refs.addFormRef.validateField('file')
        })
      }
      this.fileList = [obj.file]
      this.fileName = obj.file.name
    },

    // 文件转base65
    fn_getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        let fileResult = ''
        reader.readAsDataURL(file)
        // 开始转
        reader.onload = function () {
          fileResult = reader.result.substring(reader.result.indexOf(',') + 1)
        }
        // 转 失败
        reader.onerror = function (error) {
          reject(error)
        }
        // 转 结束  咱就 resolve 出去
        reader.onloadend = function () {
          resolve(fileResult)
        }
      })
    },

    // 下载模板
    handleDownload () {
      const x = new XMLHttpRequest()
      x.open('GET', 'https://image.01lb.com.cn//uploads/wecom/static/兑换码模板 .xlsx', true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = '兑换码模板.xlsx'
        a.click()
      }
      x.send()
    },

    // 删除文件
    fn_removeFile () {
      if (this.createVisable) {
        this.createForm.file = null
        this.$refs.createFormRef.validateField('file')
      } else {
        this.addForm.file = null
        this.$refs.addFormRef.validateField('file')
      }
      this.fileList = []
      this.fileName = ''
    },

    // 重置表单
    fn_resetForm () {
      if (this.$refs.createFormRef) {
        this.$refs.createFormRef.resetFields()
      }
      if (this.$refs.addFormRef) {
        this.$refs.addFormRef.resetFields()
      }
      this.fileList = []
      this.fileName = ''
      this.fileErrorText = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.qy-icon-download {
  font-size: 16px;
}
.q-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.q-dialog {
  ::v-deep .el-dialog {
    width: 580px;
    margin: 0 !important;
  }
}

.icon-close {
  display: none;

  &:hover {
    color: $--color-danger;
  }
}

.icon-check {
  display: inline-block;
}

.code {
  &-upload {
    line-height: 1.8;
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    border-right: 4px;
    cursor: pointer;
    transition: 0.5s background-color;

    &__item {
      margin-right: 16px;
    }

    &__icon {
      i {
        line-height: 24px;
        font-size: 16px;
      }
    }

    &:hover {
      background-color: #f5f7fa;

      .code-name {
        color: $--color-primary;
      }

      .icon-close {
        display: inline-block;
      }

      .icon-check {
        display: none;
      }
    }
  }

  &-add {
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 48px;

    &__form {
      margin-top: 24px;
      width: 100%;

      ::v-deep .el-form-item__error {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-leave-to {
  transition: opacity 0s;
}

.input-limit {
  ::v-deep .el-input__inner {
    padding-right: 48px;
  }
}
</style>
