<!--
 * @Author: zhanln
 * @Date: 2021-11-09 18:48:26
 * @LastEditTime: 2022-06-04 14:26:18
 * @LastEditors: zhan
 * @Description: 预览- 海报
-->

<template>
  <div class="poster">
    <div class="poster-bg">
      <img :src="poster_img" alt="" v-if="poster_img" />
      <template v-else><span class="q-info">请上传海报</span></template>
    </div>
    <div class="poster-edit">
      <!-- 头像 -->
      <vue-draggable-resizable class="dragWrap dragAvatar" :x="Number(form.avatar_x)" :y="Number(form.avatar_y)"
        :w="Number(form.avatar_width)" :h="Number(form.avatar_height)" :min-width="30" :min-height="30" :parent="true"
        :handles="['br']" :lock-aspect-ratio="true" @dragging="(x, y) => onDrag(x, y, 'avatar')"
        @resizing="(x, y, w, h) => onResize(w, h, 'avatar')" @dragstop="onDragstop('avatar')"
        @resizestop="onResizstop('avatar')" v-if="avatar_status === 1">
        <div class="poster-avatar" :class="{ 'is-radius': avatar_sharp === 2 }">
          头像
        </div>
      </vue-draggable-resizable>

      <!-- 昵称 -->
      <vue-draggable-resizable class="dragWrap" :x="Number(form.nickname_x)" :y="Number(form.nickname_y)"
        :w="nickname_size * namelength" :grid="[1, 1]" h="auto" :parent="true" :handles="[]" :lock-aspect-ratio="true"
        @dragging="(x, y) => onDrag(x, y, 'nickname')" @dragstop="onDragstop('nickname')" v-if="nickname_status === 1">
        <div :style="{
          color: nickname_color,
          'font-size': nickname_size + 'px',
          'text-align': nickname_align,
        }">
          用户昵称
        </div>
      </vue-draggable-resizable>

      <!-- 二维码 -->
      <vue-draggable-resizable class="dragWrap poster-code" :x="Number(form.qrcode_x)" :y="Number(form.qrcode_y)"
        :w="Number(form.qrcode_width)" :h="Number(form.qrcode_height)" :min-width="30" :min-height="30" :parent="true"
        :handles="['br']" :lock-aspect-ratio="true" @dragging="(x, y) => onDrag(x, y, 'qrcode')"
        @resizing="(x, y, w, h) => onResize(w, h, 'qrcode')" @dragstop="onDragstop('qrcode')"
        @resizestop="onResizstop('qrcode')">
        <img src="@assets/images/contact_me_qr.png" />
      </vue-draggable-resizable>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import { mapState } from 'vuex'
export default {
  name: 'liveactMobilePoster',

  data () {
    return {
      form: {
        avatar_x: null,
        avatar_y: null,
        avatar_width: null,
        avatar_height: null,
        nickname_x: null,
        nickname_y: null,
        qrcode_x: null,
        qrcode_y: null,
        qrcode_width: null,
        qrcode_height: null
      },
      blockWidth: 345,
      namelength: 6
    }
  },

  computed: {
    ...mapState({
      poster_img: state => state.liveact.poster_img,
      avatar_status: state => state.liveact.avatar_status,
      avatar_sharp: state => state.liveact.avatar_sharp,
      nickname_status: state => state.liveact.nickname_status,
      nickname_size: state => state.liveact.nickname_size,
      nickname_color: state => state.liveact.nickname_color,
      nickname_align: state => state.liveact.nickname_align
    })
  },

  watch: {
    nickname_size (val) {
      // 改变字体大小，重新计算x坐标，防止宽度超出
      const _w = +this.form.nickname_x + val * this.namelength
      if (_w > this.blockWidth) {
        this.form.nickname_x = this.blockWidth - val * this.namelength
        this.$store.commit('liveact/SET_INFO', {
          name: 'nickname_x',
          val: this.form.nickname_x
        })
      }
      this.fn_countOffset()
    },
    nickname_align (val) {
      this.fn_countOffset()
    }
  },

  // 显示时，回显数据
  created () {
    this.fn_init()
  },

  methods: {

    fn_init () {
      const form = this.form
      for (const item in form) {
        if (this.$store.state.liveact[item] !== null) {
          this.form[item] = this.$store.state.liveact[item]
        }
      }
    },

    // 移动
    onDrag (x, y, name) {
      this.form[`${name}_x`] = x
      this.form[`${name}_y`] = y
    },

    // 昵称对齐计算
    fn_countOffset () {
      let _offsetX = null
      const _x = this.fn_caleCoord2(this.form.nickname_x, 'x')
      if (this.nickname_align === 'center') {
        _offsetX = (_x + this.nickname_size * this.namelength / 2).toFixed() - 2
      } else if (this.nickname_align === 'right') {
        _offsetX = (_x + this.nickname_size * this.namelength).toFixed() - 2
      } else {
        _offsetX = 0
      }
      this.$store.commit('liveact/SET_INFO', {
        name: 'nickname_offsetX',
        val: _offsetX
      })
    },

    // 计算375
    fn_caleCoord2 (num, type) {
      let _num = null
      if (type === 'x') {
        _num = Math.round(375 / 345 * num)
      } else if (type === 'y') {
        _num = Math.round(667 / 613.64 * num)
      }
      return _num
    },

    // 拖动停止时，存储结果
    onDragstop (name) {
      // x 坐标
      this.$store.commit('liveact/SET_INFO', {
        name: `${name}_x`,
        val: this.form[`${name}_x`]
      })
      // y 坐标
      this.$store.commit('liveact/SET_INFO', {
        name: `${name}_y`,
        val: this.form[`${name}_y`]
      })
      // 如果是昵称，计算对齐方式的偏移值
      if (name === 'nickname') {
        this.fn_countOffset()
      }
    },

    // 缩放
    onResize (w, h, name) {
      this.form[`${name}_width`] = w
      this.form[`${name}_height`] = h
    },

    // 缩放停止时，存储数据
    onResizstop (name) {
      this.$store.commit('liveact/SET_INFO', {
        name: `${name}_width`,
        val: this.form[`${name}_width`]
      })
      this.$store.commit('liveact/SET_INFO', {
        name: `${name}_height`,
        val: this.form[`${name}_height`]
      })
    }
  },

  components: {
    [VueDraggableResizable.name]: VueDraggableResizable
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/actLayout/poster";

.poster-bg {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
