<!--
 * @Author: zhanln
 * @Date: 2021-10-12 10:36:32
 * @LastEditTime: 2021-12-16 11:16:50
 * @LastEditors: zhanln
 * @Description: 裂变层级分析
-->

<template>
  <alys-card :title="title" ref="cardRef">
    <div
      v-if="!actId"
      class="ly-table-empty flex flex-center"
      style="height: 336px; box-sizing: border-box"
    >
      <img src="@assets/svg/default/no_data.svg" alt="" />
      暂无数据
    </div>

    <!-- 工具栏 -->
    <template #toolbar v-if="actId">
      <div class="flex">
        <!-- 显示模式 -->
        <el-button-group style="margin-right: 20px">
          <el-button
            :type="item.value === showType ? 'primary' : ''"
            v-for="(item, index) in typeList"
            :key="index"
            @click="showType = item.value"
            >{{ item.label }}</el-button
          >
        </el-button-group>
      </div>

      <div>
        <ly-export
          :exportData="exportData"
          :exportName="exportName"
          :exportSheetName="exportSheetName"
          dateFormat="YYYY年MM月DD日HH时mm分"
          v-if="showType === 2 && tableData.length > 0"
        ></ly-export>
      </div>
    </template>

    <div class="alys-steps" v-if="actId">
      <!-- 统计 -->
      <div class="alys-steps__count">
        <div class="item" v-for="(item, index) in countList" :key="index">
          <div class="icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="right">
            <div class="count">
              {{ item.count }}<span> / {{ item.unit }}</span>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <!-- 趋势图 -->
      <ly-chart
        :option="stepsOption"
        height="300px"
        v-show="showType === 1"
        ref="barRef"
      ></ly-chart>

      <!-- 表格 -->
      <el-table
        v-show="showType === 2"
        :data="tableData"
        style="width: 100%"
        height="300"
      >
        <el-table-column
          :prop="item.prop"
          v-for="(item, index) in tableHead"
          :key="index"
        >
          <template slot="header">
            {{ item.label }}
            <el-tooltip
              v-if="item.tip"
              class="item"
              effect="dark"
              placement="top"
              :content="item.tip"
              popper-class="q-tooltip"
            >
              <i class="ri-information-line"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <div slot="empty" class="ly-table-empty">
          <slot name="empty">
            <img src="@assets/svg/default/no_data.svg" alt="" />
            暂无数据
          </slot>
        </div>
      </el-table>
    </div>
  </alys-card>
</template>

<script>
import lyChart from '@/components/lyChart' // echart
import * as echarts from 'echarts'
import alysCard from './card' // 卡片布局
import lyExport from '@/components/lyExport' // 本地导出excel
import { analyse } from '../http'
export default {
  name: 'alysSteps',

  props: {
    actId: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    actName: {
      type: String,
      default: ''
    },
    actIndex: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      COMM_HTTP: analyse,
      showType: 1,
      typeList: [
        {
          label: '趋势走势图',
          value: 1
        },
        {
          label: '趋势表格',
          value: 2
        }
      ],
      countList: [
        {
          icon: require('@assets/svg/alys_icon_1.svg'),
          count: '-',
          name: '入群用户',
          unit: '人'
        },
        {
          icon: require('@assets/svg/alys_icon_2.svg'),
          count: '-',
          name: '有效推广用户',
          unit: '人'
        },
        {
          icon: require('@assets/svg/alys_icon_3.svg'),
          count: '-',
          name: '裂变层级',
          unit: '级'
        }
      ],
      stepsOption: {
        color: ['rgb(43, 124, 255)', 'rgb(250, 210, 12)'],
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: function (params) {
            return `<div>裂变层级：<span>${params[0].axisValue}<br/>${params[0].marker}入群用户：${params[0].data}</span>
            <br/>${params[1].marker}有效推广用户：${params[1].data}
            <br/>${params[2].marker}裂变率：${params[2].data === '0.00' ? 0 : params[2].data + '%'}</div>`
          },
          backgroundColor: 'rgba(2, 15, 32, 0.78)',
          textStyle: {
            color: '#fff',
            lineHeight: 50
          }
        },
        legend: {
          icon: 'circle',
          bottom: '1%',
          itemGap: 30,
          data: ['入群用户', '有效推广用户', '裂变率']
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '12%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            show: false,
            height: 12,
            showDataShadow: false,
            showDetail: false,
            brushSelect: false,
            xAxisIndex: [0],
            startValue: 0,
            endValue: 10,
            bottom: '10%',
            zoomLock: true,
            handleStyle: {
              opacity: 0
            }
          },
          {
            type: 'inside',
            xAxisIndex: [0],
            startValue: 0,
            endValue: 10,
            zoomLock: true
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              color: '#6E788A',
              margin: 14
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(233, 233, 233, 1)'
              }
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 0,
            interval: 0,
            axisLabel: {
              color: '#6E788A'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(233, 233, 233, 1)',
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              color: '#6E788A'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(233, 233, 233, 1)',
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            name: '入群用户',
            type: 'bar',
            barMaxWidth: 14,
            barCategoryGap: 10,
            itemStyle: {
              borderRadius: 7
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
            ]
          },
          {
            name: '有效推广用户',
            type: 'bar',
            barMaxWidth: 14,
            barCategoryGap: 10,
            itemStyle: {
              borderRadius: 7
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
          {
            name: '裂变率',
            type: 'line',
            yAxisIndex: 1,
            itemStyle: {
              color: 'rgb(0, 199, 128)'
            },
            smooth: true,
            symbol: 'circle',
            symbolSize: 8,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: 'rgba(0, 199, 128, 0)'
                },
                {
                  offset: 0,
                  color: 'rgba(0, 199, 128, 0.08)'
                }
              ])
            },
            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
          }
        ]
      },
      tableHead: [
        {
          label: '裂变层级',
          prop: 'lv_title'
        }, {
          label: '入群用户',
          prop: 'in_group_num'
        }, {
          label: '有效推广用户',
          prop: 'active_num'
        }, {
          label: '裂变率',
          prop: 'fission_rate_str'
        }
      ],
      tableData: [],
      exportName: '',
      exportSheetName: '',
      exportData: []
    }
  },

  watch: {
    actIndex (val) {
      if (val) {
        this.fn_getLevel()
      }
    },
    showType (val) {
      if (val === 1 && this.actId) {
        this.$refs.barRef._cusResize(this.$refs.cardRef.$el.clientWidth - 40)
      }
    }
  },

  methods: {

    async fn_getLevel () {
      const data = await this.COMM_HTTP.level({
        activity_id: this.actId
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      if (!data) return false

      this.countList[0].count = data.in_group_num
      this.countList[1].count = data.active_num
      this.countList[2].count = data.max_lv

      this.tableData = data.list.map(item => {
        item.fission_rate_str = item.fission_rate === '0.00' ? 0 : item.fission_rate + '%'
        return item
      })

      this.fn_setData(this.tableData)
      this.fn_initExport()
    },

    // 图表数据处理
    fn_setData (datas) {
      const stepsData = {
        xAxis: datas.map(item => item.lv_title),
        series0: datas.map(item => item.in_group_num),
        series1: datas.map(item => item.active_num),
        series2: datas.map(item => item.fission_rate)
      }

      this.fn_initTrend(stepsData)
    },

    // 图表数据
    fn_initTrend (data) {
      const options = this.stepsOption
      options.xAxis[0].data = data.xAxis
      options.series[0].data = data.series0
      options.series[1].data = data.series1
      options.series[2].data = data.series2

      const MaxUser = Math.max(...data.series0)
      const MaxValid = Math.max(...data.series1)
      const MaxNum = Math.max(MaxUser, MaxValid)
      const yMax = this.fn_computeMax(MaxNum)

      options.yAxis[0].max = yMax
      options.yAxis[0].interval = yMax / 5

      if (data.xAxis.length > 10) {
        options.dataZoom[0].show = true
        options.grid.bottom = '20%'
      }
    },

    // 计算合适的最大值
    fn_computeMax (allMax) {
      let yMax = 0
      const str = allMax + ''
      switch (true) {
        case (allMax >= 1000):
          yMax = allMax + (str.slice(str.length - 2) === '00' ? 0 : 500 - str.slice(str.length - 2))
          break
        case (allMax >= 100):
          yMax = allMax + (str.slice(2) === '0' ? 0 : 20 - str.slice(2))
          break
        case (allMax >= 10):
          yMax = allMax + (str.slice(1) === '0' ? 0 : 10 - str.slice(1))
          break
        case (allMax > 5):
          yMax = 10
          break
        default:
          yMax = 5
          break
      }

      return yMax
    },

    // 导出数据处理
    fn_initExport () {
      // 第一行，表头
      this.exportData = [this.tableHead.map(item => item.label)]
      // 数据
      this.tableData.forEach((item, index) => {
        const tbody = []
        this.tableHead.forEach(tItem => {
          tbody.push(item[tItem.prop])
        })
        this.exportData.push(tbody)
      })

      this.exportSheetName = this.actName + '-裂变层级'
      this.exportName = this.actName + '-裂变层级-'
    }
  },

  components: {
    alysCard,
    lyChart,
    lyExport
  }
}
</script>

<style lang="scss" scoped>
.alys {
  &-steps {
    &__count {
      display: flex;
      padding-left: 11px;
      margin-bottom: 24px;
      margin-top: 24px;

      .item {
        display: flex;

        &:not(:last-child) {
          margin-right: 80px;
        }
      }

      .icon {
        width: 36px;
        height: 36px;
        margin-right: 16px;
        margin-top: 6px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .count {
        font-size: 21px;
        color: rgba(29, 42, 54, 1);
        font-weight: 600;
        line-height: 29px;

        span {
          font-size: 12px;
          font-weight: normal;
          line-height: 1;
        }
      }

      .name {
        color: #6e788a;
        line-height: 20px;
      }
    }
  }
}
</style>
