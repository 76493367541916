var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ly-table page-warp" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          staticStyle: { padding: "0 20px" },
          attrs: { inline: true, size: "medium" }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "label-padding", attrs: { label: "选择活动：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: { filterable: "" },
                  on: { change: _vm.changeActivity },
                  model: {
                    value: _vm.activityId,
                    callback: function($$v) {
                      _vm.activityId = $$v
                    },
                    expression: "activityId"
                  }
                },
                _vm._l(_vm.activityList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "reward_title_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "flex" }, [
                  row.reward_cover
                    ? _c("img", {
                        staticStyle: {
                          "margin-right": "8px",
                          "max-height": "24px",
                          "max-width": "24px"
                        },
                        attrs: { src: row.reward_cover, alt: "" }
                      })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(row.reward_title))])
                ])
              ]
            }
          },
          {
            key: "reward_type_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.reward_type === 1
                  ? [_vm._v("自定义")]
                  : row.reward_type === 2
                  ? [_vm._v("兑换码")]
                  : _vm._e()
              ]
            }
          },
          {
            key: "nickname_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("lyTag", {
                  attrs: { content: row.nickname, avatar: row.avatar_url }
                })
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.getRewardDetail(row)
                      }
                    }
                  },
                  [_vm._v("查看奖品内容")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品内容",
            visible: _vm.dialogVisible,
            "custom-class": "q-dialog",
            width: "34%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "reward" },
            [
              _vm.rewardType === 1
                ? [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 6, maxRows: 10 },
                        resize: "none",
                        readonly: ""
                      },
                      model: {
                        value: _vm.rewardDetail,
                        callback: function($$v) {
                          _vm.rewardDetail = $$v
                        },
                        expression: "rewardDetail"
                      }
                    }),
                    _vm.rewardDetailImg
                      ? _c("div", { staticClass: "q-dialog__img" }, [
                          _c("div", { staticClass: "flex flex-center" }, [
                            _c("img", {
                              attrs: { src: _vm.rewardDetailImg, alt: "" }
                            })
                          ])
                        ])
                      : _vm._e()
                  ]
                : _vm.rewardType === 2
                ? _c("div", { staticClass: "q-dialog-block" }, [
                    _c("div", { staticClass: "reward-code" }, [
                      _vm._v(_vm._s(_vm.rewardDetail))
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c("code-list", {
        ref: "codeListRef",
        attrs: { rewardId: _vm.rewardId, userId: _vm.userId },
        on: { success: _vm.queryData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }