<template>
  <span class="form-style">
    <el-container class="fm2-container">
      <el-main class="fm2-main">
        <el-container>
          <el-aside width="288px">
            <div class="components-list">
              <div class="normalTable-cate">常用组件</div>
              <draggable
                tag="ul"
                :list="useCom"
                v-bind="{
                  group: { name: 'formItem', pull: 'clone', put: false },
                  sort: false,
                  ghostClass: 'handleIcon',
                }"
                @end="MoveEndFn"
                @start="MoveStartFn"
                :move="handleMove"
              >
                <el-button
                  class="form-edit-normalTable-label"
                  v-for="(item, index) in useCom"
                  :key="index"
                  @click="handleClick(item)"
                >
                  <svg-icon :iconClass="item.svgIcon"></svg-icon>
                  <div>{{ item.name }}</div>
                </el-button>
              </draggable>

              <template>
                <div class="normalTable-cate">通用组件</div>
                <draggable
                  tag="ul"
                  :list="currencyCom"
                  v-bind="{
                    group: { name: 'formItem', pull: 'clone', put: false },
                    sort: false,
                    ghostClass: 'handleIcon',
                  }"
                  @end="MoveEndFn"
                  @start="MoveStartFn"
                  :move="handleMove"
                >
                  <el-button
                    class="form-edit-normalTable-label"
                    size="small"
                    v-for="(item, index) in currencyCom"
                    :key="index"
                    @click="handleClick(item)"
                  >
                    <svg-icon :iconClass="item.svgIcon"></svg-icon>
                    <div>{{ item.name }}</div>
                  </el-button>
                </draggable>
              </template>
            </div>
          </el-aside>
          <el-container class="center-container" direction="vertical">
            <el-main
              :class="{ 'normalTable-empty': normalTableForm.list.length == 0 }"
            >
              <normalTable-form
                v-loading="isEndLoading"
                ref="normalTableForm"
                :data="normalTableForm"
                :select.sync="normalTableFormSelect"
                :formName="formName"
                :selectParentIndex.sync="selectParentIndex"
              ></normalTable-form>
            </el-main>
          </el-container>

          <el-aside class="normalTable-config-container" width="320px">
            <el-container>
              <el-header height="54px">
                <div
                  class="config-tab"
                  :class="{ active: configTab == 'normalTable' }"
                  @click="handleNavUpdate('normalTable')"
                >
                  {{ formTableComTitle }}
                </div>
                <div
                  class="config-tab"
                  :class="{ active: configTab == 'form' }"
                  @click="handleNavUpdate('form')"
                >
                  表单设置
                </div>
              </el-header>
              <el-main class="config-content">
                <normalTable-config
                  v-show="configTab == 'normalTable'"
                  :data="normalTableFormSelect"
                ></normalTable-config>
                <form-config
                  v-show="configTab == 'form'"
                  ref="formConfig"
                  v-model.trim="formName"
                ></form-config>
              </el-main>
            </el-container>
          </el-aside>
        </el-container>
      </el-main>

      <div class="edit-footer" style="justify-content: space-between">
        <div
          class="flex flex-justify-start text-regular"
          style="flex: 1.2"
        ></div>
        <div class="flex flex-center" style="flex: 0.7">
          <el-button
            round
            :loading="btnLoading"
            @click="handleSave(0)"
            icon="icon-edit iconfont"
            v-if="isShowDrafts"
          >
            保存草稿
          </el-button>
          <el-button round @click="handlePreview" icon="iconfont icon-yulan">
            预览
          </el-button>
          <el-button
            round
            icon="iconfont icon-send-fill"
            @click="handleSave(1)"
            type="primary"
            :loading="submitLoading"
          >
            发布表单
          </el-button>
        </div>
        <div style="flex: 1.2"></div>
      </div>
    </el-container>

    <mobile-dialog
      :visible="previewVisible"
      @on-close="previewVisible = false"
      ref="normalTablePreview"
      width="423px"
      form
      title="预览"
      class="dialogWrapper"
    >
      <normal-form
        v-if="previewVisible"
        :data="normalTableForm"
        :formName="formName"
      >
      </normal-form>
    </mobile-dialog>
  </span>
</template>

<script>
import Draggable from 'vuedraggable'
import normalTableConfig from './normalTableConfig'
import formConfig from './FormConfig'
import normalTableForm from './normalTableForm'
import mobileDialog from './mobileDialog'
import normalForm from './normalForm'
import { useCom, currencyCom } from './componentsConfig.js'

import SvgIcon from './SvgIcon/index.vue'// svg组件
import './SvgIcon/index.js'

import { tables } from '../http.js'

import { mapState } from 'vuex'
export default {
  name: 'fm-making-form',
  components: {
    Draggable,
    normalTableConfig,
    formConfig,
    SvgIcon,
    normalTableForm,
    mobileDialog,
    normalForm
  },
  props: {
    preview: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      useCom,
      currencyCom,
      resetJson: false,
      isEndLoading: true,
      normalTableForm: {
        list: []
      },
      configTab: 'normalTable',
      normalTableFormSelect: null,
      previewVisible: false,
      formName: '',
      submitLoading: false,
      btnLoading: false,
      isShowDrafts: true,
      selectParentIndex: -1
    }
  },
  computed: {
    ...mapState({
      formTableComTitle: state => state.client.formTableComTitle
    })
  },
  mounted () {
    if (this.$route.query.formId) {
      this._getComInfoById()
      this._getFormTable()
    } else {
      this.isEndLoading = false
    }

    this.isShowDrafts = !this.$route.query.isUpdate
  },
  methods: {
    async _getFormTable () {
      const data = await tables.getOneForm(this.$route.query.id)
      this.formName = data.name
    },
    async _getComInfoById () {
      const _com = [...useCom, ...currencyCom]
      const data = await tables.getOneCom(this.$route.query.formId)
      const _list = []

      data.forEach(_ => {
        const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)

        let _filCom = _com.filter(com => com.dataType == _.dataType && com.mainType == _.mainType)
        if (_.presetType || _.presetType == 0) {
          _filCom = _filCom.filter(com => com.presetType == _.presetType)
        }

        if (_.options) {
          _filCom[0].options.options = JSON.parse(_.options)
        }

        _filCom = JSON.parse(JSON.stringify(_filCom))
        _filCom[0].options.isDelete = this.$route.query.isDelete == 'true'
        _filCom[0].options.required = _.required
        _filCom[0].options.maxLength = _.maxLength

        if (_.prompt && !!_.prompt.length) {
          const dotArr = _.prompt.split('&')
          dotArr.forEach(dot => {
            const mn = dot.split('=')
            if (mn.length > 1) {
              _filCom[0].options[mn[0]] = mn[1]
            }
          })
        }

        delete _.options

        _list.push(Object.assign({}, ..._filCom, _,
          {
            key,
            // 绑定键值
            model: _filCom[0].type + '_' + key,
            rules: []
          }
        )
        )
      })
      this.$_copyComList = JSON.parse(JSON.stringify(_list))
      this.normalTableFormSelect = _list[0]
      this.selectParentIndex = 0
      this.isEndLoading = false
      this.normalTableForm.list = _list
    },
    handleNavUpdate (value) {
      this.configTab = value
    },
    MoveEndFn (evt) {
      this.configTab = 'normalTable'
    },
    MoveStartFn () {
    },
    handleClick (item) {
      let _index = -1
      if (this.selectParentIndex == -1) {
        this.normalTableForm.list.push(item)
        _index = 0
      } else {
        _index = this.selectParentIndex + 1
        this.normalTableForm.list.splice(this.selectParentIndex + 1, 0, item)
      }

      this.$refs.normalTableForm.handleClickTareget(_index)
    },
    handleMove () {
    },
    handlePreview () {
      this.previewVisible = true
    },
    handleClear () {
      this.normalTableForm = {
        list: []
      }

      this.normalTableFormSelect = {}
    },
    clear () {
      this.handleClear()
    },
    handleSave (status) {
      const validForm = this.$refs.formConfig.submitForm()
      if (!validForm) {
        this.configTab = 'form'
        return
      }

      const _json = {
        name: this.formName,
        status,
        resourceId: 'formTable'
      }

      let _ty = 'createTables'
      const _comlist = this.getComlist()

      if (!_comlist.length) {
        this.$message.error('最少选择一个组件~')
        return
      }

      const _comFilter = _comlist.filter(_ => !_.name)

      if (_comFilter.length) {
        this.$message.error(`${_comFilter[0].comName}组件，字段名称不能为空`)
        return
      }

      if (status == 0) {
        this.btnLoading = true
      } else {
        this.submitLoading = true
      }

      if (this.$route.query.formId) {
        _json.addComponentList = _comlist.filter(_ => !_.id)
        _json.updateComponentList = _comlist.filter(_ => !!_.id)
        const oldMap = this.$_copyComList.map(item => item.id)
        const newMap = _json.updateComponentList.map(item => item.id)
        _json.id = this.$route.query.id
        _json.deleteComponentIdList = newMap.concat(oldMap).filter(v => !newMap.includes(v))
        _ty = 'putTables'
      } else {
        _json.componentList = _comlist
      }

      tables[_ty](_json).then(data => {
        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('info', data.msg)
          return false
        }

        this.fn_lyMsg('success', (status == 1 ? '发布成功' : '保存草稿成功'))

        setTimeout(() => {
          this.submitLoading = false
          this.btnLoading = false
          this.$router.go(-1)
        }, 1000)
      })
    },
    getComlist () {
      const componentList = []
      const _list = this.normalTableForm.list
      _list.forEach((_, sort) => {
        let _op = ''
        let _prompt = ''

        if (_.options.options) {
          _op = JSON.stringify(_.options.options)
        }

        for (const _key in _.options) {
          if (_key.indexOf('placeholder') > -1) {
            _prompt += `${_key}=${_.options[_key]}&`
          }
        }
        const _strJson = {
          dataType: _.dataType,
          mainType: _.mainType,
          maxLength: _.options.maxLength,
          name: _.name,
          options: _op,
          presetType: _.presetType,
          required: _.options.required,
          sort
        }

        if (this.$route.query.id) {
          _strJson.id = _.id
        }

        if (_prompt.length > 0) {
          _strJson.prompt = _prompt
        }

        componentList.push(_strJson)
      })
      return componentList
    }
  }
}
</script>
