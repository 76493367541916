var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "助力文案：", prop: "help_msg" } },
        [
          _c("ly-editor", {
            staticClass: "act-form__editor",
            attrs: {
              title: "助力文案",
              id: "helpMsg",
              content: _vm.form.help_msg,
              insetMenu: ["用户昵称", "助力好友昵称"],
              recoverContent: _vm.default_help_msg,
              emoji: ""
            },
            on: { getContent: _vm.fn_getHelpMsg }
          }),
          _c("span", { staticClass: "q-info text-small" }, [
            _vm._v(" 当好友B助力了好友A时，推送给好友B的助力文案 ")
          ])
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "结束文案：", prop: "end_msg" } },
        [
          _c("ly-editor", {
            staticClass: "act-form__editor",
            attrs: {
              title: "结束文案",
              id: "endMsg",
              content: _vm.form.end_msg,
              insetMenu: ["用户昵称"],
              recoverContent: _vm.default_end_msg,
              emoji: ""
            },
            on: { getContent: _vm.fn_getEndMsg }
          }),
          _c("span", { staticClass: "q-info text-small" }, [
            _vm._v(
              " 当活动结束，二维码仍未失效时，用户添加涨粉账号时，推送此文案 "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }