<!--
 * @Author: zhanln
 * @Date: 2021-07-28 16:05:06
 * @LastEditTime: 2021-12-09 22:32:56
 * @LastEditors: Please set LastEditors
 * @Description: 消息推送
-->
<template>
  <div class="edit-card">
    <div class="edit-card__header">
      <div class="title">{{ title }}</div>
    </div>
    <div class="edit-card__content" v-loading="cardLoading">
      <p class="text-small notice">
        1.群内有成员云端在线，则可以在群内实时发送【助力成功提醒】和【奖品推送】；<br />
        2.如使用云端账号进行消息推送，活动期间请保持云端账号在线状态。
      </p>
      <el-form
        :model="noticeForm"
        label-width="125px"
        class="notice-form"
        size="medium"
      >
        <el-form-item
          label="助力成功提醒"
          class="reward-form__item is-long"
          :error="helpError"
        >
          <div class="flex">
            <el-switch
              v-model="noticeForm.open_help"
              :active-value="1"
              :inactive-value="0"
              @change="fn_setForm($event, 'open_help')"
            ></el-switch>
            <span style="margin-left: 8px">{{
              noticeForm.open_help === 0 ? "关闭" : "开启"
            }}</span>
          </div>

          <div class="q-bg is-default" v-if="noticeForm.open_help === 1">
            每隔
            <el-input-number
              v-model="noticeForm.help_send_second"
              size="small"
              controls-position="right"
              :min="60"
              :max="3600"
              :precision="0"
              class="number-mn"
              @change="fn_setForm($event, 'help_send_second')"
            ></el-input-number>
            秒后发送助力提醒 <b>或</b> 群内
            <el-input-number
              v-model="noticeForm.help_send_add"
              size="small"
              controls-position="right"
              :min="2"
              :max="50"
              :precision="0"
              class="number-mn"
              @change="fn_setForm($event, 'help_send_add')"
            ></el-input-number>
            人获得好友助力发送助力提醒
            <div class="q-info text-small" style="margin-top: 16px">
              <i class="ri-information-line"></i>
              助力提醒时间和助力人数同步计算，将优先触发满足条件的设置项
            </div>
          </div>

          <ly-editor
            title="助力成功提醒"
            id="noticeHelp"
            :insetMenu="['领奖人数']"
            :content="noticeForm.help_msg"
            :recoverContent="helpInit"
            :error="helpError !== ''"
            emoji
            @getContent="fn_getHelpMsg"
            v-if="noticeForm.open_help === 1"
            style="width: 435px"
          ></ly-editor>
        </el-form-item>

        <el-form-item
          label="奖品推送"
          class="reward-form__item"
          :error="rewardError"
        >
          <div class="flex">
            <el-switch
              v-model="noticeForm.open_reward"
              :active-value="1"
              :inactive-value="0"
              @change="fn_setForm($event, 'open_reward')"
            ></el-switch>
            <span style="margin-left: 8px">{{
              noticeForm.open_reward === 0 ? "关闭" : "开启"
            }}</span>
          </div>

          <div class="q-bg is-default" v-if="noticeForm.open_reward === 1">
            每隔
            <el-input-number
              v-model="noticeForm.reward_send_second"
              size="small"
              controls-position="right"
              :min="60"
              :max="3600"
              :precision="0"
              class="number-small number-mn"
              @change="fn_setForm($event, 'reward_send_second')"
            ></el-input-number>
            秒后发送奖品通知 <b>或</b> 群内
            <el-input-number
              v-model="noticeForm.reward_send_add"
              size="small"
              controls-position="right"
              :min="2"
              :max="50"
              :precision="0"
              class="number-small number-mn"
              @change="fn_setForm($event, 'reward_send_add')"
            ></el-input-number>
            人完成任务发送奖品通知
            <div class="q-info text-small" style="margin-top: 16px">
              <i class="ri-information-line"></i>
              奖品通知时间和完成任务人数同步计算，将优先触发满足条件的设置项
            </div>
          </div>

          <ly-editor
            title="助力成功提醒"
            id="noticeReward"
            :content="noticeForm.reward_msg"
            :recoverContent="rewardInit"
            :error="rewardError !== ''"
            emoji
            @getContent="fn_getRewardMsg"
            v-if="noticeForm.open_reward === 1"
            style="width: 435px"
          ></ly-editor>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import lyEditor from '@/components/lyEditor'
export default {
  name: 'way',

  props: {
    title: {
      type: String,
      defualt: () => {
        return ''
      }
    }
  },

  data () {
    return {
      cardLoading: true,
      noticeForm: {
        open_help: 1,
        help_send_second: 120,
        help_send_add: 5,
        help_msg: '又有新的好友支持你啦，你离奖品又近了一步~[太阳][太阳][太阳]\n当前活动已有#领奖人数#人领取奖品，快看看你的进度~',
        open_reward: 1,
        reward_send_second: 120,
        reward_send_add: 5,
        reward_msg: '超级多朋友为你助力，已经可以领取奖品啦~[庆祝][庆祝][庆祝]'
      },
      helpError: '',
      rewardError: '',
      idMaxLength: 100,
      idError: '',
      helpInit: '又有新的好友支持你啦，你离奖品又近了一步~[太阳][太阳][太阳]\n当前活动已有#领奖人数#人领取奖品，快看看你的进度~',
      rewardInit: '超级多朋友为你助力，已经可以领取奖品啦~[庆祝][庆祝][庆祝]'
    }
  },

  computed: {
    isReady () {
      return this.$store.getters['groupfission/GET_READY']
    }
  },

  watch: {
    isReady: {
      handler (val) {
        if (val) {
          const isEdit = this.$store.getters['groupfission/GET_TYPE']
          if (isEdit) {
            this.noticeForm = this.$store.getters['groupfission/GET_NOTICE']
          }
          this.cardLoading = false
        }
      },
      immediate: true
    },
    'noticeForm.open_help' (val) {
      if (!val) {
        this.helpError = ''
      }
    },
    'noticeForm.help_msg' (val) {
      this.helpError = val ? '' : '请输入助力成功提醒'
    },
    'noticeForm.open_reward' (val) {
      if (!val) {
        this.rewardError = ''
      }
    },
    'noticeForm.reward_msg' (val) {
      this.rewardError = val ? '' : '请输入奖品推送内容'
    }
  },

  methods: {

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: 'notice',
        name: type,
        val: val
      })
    },

    // 助力提醒
    fn_getHelpMsg (content) {
      if (this.isReady) {
        this.noticeForm.help_msg = content
        this.fn_setForm(content, 'help_msg')
      }
    },

    // 助力提醒
    fn_getRewardMsg (content) {
      if (this.isReady) {
        this.noticeForm.reward_msg = content
        this.fn_setForm(content, 'reward_msg')
      }
    },

    // 校验
    verify () {
      let veriry = true
      const form = this.noticeForm

      if (form.open_help === 1 && form.help_msg === '') {
        this.helpError = '请输入助力成功提醒'
        veriry = false
      }

      if (form.open_reward === 1 && form.reward_msg === '') {
        this.rewardError = '请输入奖品推送内容'
        veriry = false
      }

      return veriry
    }
  },
  components: {
    lyEditor
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
.notice-form{
  width: 100%;

  &__item {
    width: 60%;
    min-width: 560px;

    &.is-long {
      width: 100%;
      max-width: 860px;
    }
  }
  .el-form-item{
    min-width: 560px
  }
}
.notice.text-small{
  margin: 0
}

.q-bg.is-default {
  border-radius: 8px;
  border: none;
  padding: 16px 20px;
  margin-bottom: 12px;
}

.number-small {
  width: 120px;
  margin: 0 4px;
  ::v-deep .el-input__inner {
    padding-left: 4px;
    padding-right: 36px;
  }

  ::v-deep {
    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}

.number-mn {
  width: 100px;
}

.q-bg {
  margin-top: 0;
  ::v-deep.el-icon-arrow-up,
  ::v-deep.el-icon-arrow-down {
    margin-right: 0;
  }
}
</style>
