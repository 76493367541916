<!--
 * @Author: zhan
 * @Date: 2022-08-17 11:46:13
 * @LastEditTime: 2022-08-17 15:18:15
 * @LastEditors: zhan
-->
<template>
  <div class="rank bg">
    <div class="title">邀请排行榜</div>
    <div class="rank-top">
      <div class="rank-top__item" v-for="(item, index) of topList" :key="index">
        <img :src="topBgList[index]" alt="" class="rank-top__bg" :class="[`index_${index}`]">
        <img :src="item.avatar" alt="" class="rank-top__avatar" :class="[`index_${index}`]">
        <div class="rank-top__name n-color">{{ item.name }}</div>
        <div class="rank-top__invite i-color"><span class="count">{{ item.invite }}</span> <span
            class="text-small">人</span></div>
      </div>
    </div>
    <div class="rank-list">
      <div class="rank-list__item" v-for="(item, index) of rankList.slice(3, 10)" :key="index">
        <div class="rank-list__left">
          <div class="rank-list__index n-color">{{ index + 3 + 1 }}</div>
          <img class="rank-list__avatar" :src="item.avatar" alt="">
          <div class="rank-list__name n-color">{{ item.name }}</div>
        </div>
        <div class="rank-list__invite i-color">
          <span class="count">{{ item.invite }}</span> <span class="text-small">人</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      topBgList: [
        'https://image.01lb.com.cn//uploads/wecom/22/0817/1660717719iDbqvr1z.png',
        'https://image.01lb.com.cn//uploads/wecom/22/0817/16607177475j532p2r.png',
        'https://image.01lb.com.cn//uploads/wecom/22/0817/1660717732T1EmW2i8.png'
      ],
      rankList: [
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          name: '茜茜',
          invite: 100
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          name: '保罗',
          invite: 96
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          name: '达人',
          invite: 96
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          name: '阿凡',
          invite: 100
        }, {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          name: '茜茜',
          invite: 100
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/22/0524/1653378815CKCH9wGT.jpg',
          name: '保罗',
          invite: 96
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          name: '达人',
          invite: 96
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          name: '阿凡',
          invite: 100
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/16281457856tZ5Z7ce.jpg',
          name: '达人',
          invite: 96
        },
        {
          avatar: 'https://image.01lb.com.cn//uploads/wecom/21/0805/1628145918I65nVO9x.jpg',
          name: '阿凡',
          invite: 100
        }
      ]
    }
  },

  computed: {
    topList () {
      const tops = [
        this.rankList[1],
        this.rankList[0],
        this.rankList[2]
      ]
      return tops
    }
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0811/1660210620k7CDxp2n.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  background-color: #FFF8FC;
  box-shadow: 0px 3px 5px 0px #FCDEC8, 0px 2px 0px 0px #FBF7FF, 0px 1.5px 0px 0px #fff inset;
  border-radius: 16px;
}

.title {
  width: 180px;
  height: 38px;
  margin: 0 auto;
  background-image: url(https://image.01lb.com.cn//uploads/wecom/22/0811/1660210845DYm7wzlj.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  color: #FFF8FE;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  box-sizing: border-box;
}

.n-color {
  color: #40347E;
}

.i-color {
  color: #E537A3;
}

.count {
  font-weight: bold;
}

.rank {
  min-height: 300px;
  margin: 16px 12px 0;
  padding: 0 24px;

  &-top {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    &__item {
      position: relative;
      text-align: center;
      width: 60px;

      &:nth-child(2) {
        width: 70px;
      }
    }

    &__bg {
      position: relative;
      width: 100%;
      margin-top: 12px;
      z-index: 1;

      &.index_1 {
        margin-top: 0;
      }
    }

    &__avatar {
      position: absolute;
      width: calc(100% - 6px);
      margin-top: 12px;
      border-radius: 50%;
      top: 3px;
      left: 3px;

      &.index_1 {
        margin-top: 0;
      }
    }

    &__invite {
      line-height: 1;
      margin-top: 4px;
    }
  }

  &-list {
    margin-top: 24px;

    &__left {
      display: flex;
      align-items: center;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;

      &:nth-child(even) {
        background-color: #FAF6FF;
      }
    }

    &__index {
      min-width: 30px;
      margin-right: 12px;
    }

    &__avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
}
</style>
