var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "el-dialog",
        {
          staticClass: "has-table",
          attrs: { title: "详情", visible: _vm.visible, width: "500" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "user-info" },
            [
              _c("el-avatar", {
                attrs: { size: 54, src: _vm.userInfo.avatar }
              }),
              _c("div", { staticClass: "info" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.userInfo.nickname || "-"))
                ])
              ])
            ],
            1
          ),
          _vm._l(this.userInfo.tag_name || [], function(item) {
            return _c("el-tag", { key: item }, [_vm._v(_vm._s(item))])
          }),
          _c(
            "el-tabs",
            {
              staticClass: "ly-tabs",
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "TA的邀请", name: "help" } },
                [
                  _c(
                    "detail-help",
                    _vm._b({}, "detail-help", _vm.userInfo, false)
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "奖品记录", name: "records" } },
                [
                  _c(
                    "detail-record",
                    _vm._b({}, "detail-record", _vm.userInfo, false)
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }