<!--
 * @Author: zhanln
 * @Date: 2021-11-01 17:31:38
 * @LastEditTime: 2022-08-09 14:28:31
 * @LastEditors: zhan
 * @Description: 抽奖规则
-->

<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-width="140px" size="medium" class="act-form"
    v-loading="cardLoading">

    <!-- 抽奖类型 -->
    <el-form-item label="抽奖类型：" prop="lottery_type" class="basic-form__item">
      <el-radio-group v-model="form.lottery_type">
        <el-radio :label="1">定时开奖</el-radio>
        <el-tooltip class="item" placement="top" content="功能开发中">
          <el-radio :label="2" disabled>即抽即中</el-radio>
        </el-tooltip>
      </el-radio-group>
    </el-form-item>

    <!-- 活动结束时间/开奖时间 -->
    <el-form-item prop="end_time" class="basic-form__item">
      <template slot="label">{{ form.lottery_type === 1 ? '开奖时间' : '活动结束时间' }}
        <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip" v-if="form.lottery_type === 1">
          <div slot="content">
            开奖时间即活动结束时间，开奖后不允许参与活动
          </div>
          <i class="iconfont icon-info-fill" style="position: relative; top: 1px"></i>
        </el-tooltip>：
      </template>
      <el-date-picker type="datetime" placeholder="请选择" v-model.trim="form.end_time"
        :picker-options="endDatePickerOptions" value-format="timestamp" :disabled="isEdit">
      </el-date-picker>
    </el-form-item>

    <!-- 机制 -->
    <el-form-item class="basic-form__item is-long">
      <template slot="label"><span class="q-danger">*</span> 邀请好友机制：</template>
      <div class="q-bg is-default rules">
        <div>
          每邀请
          <el-input-number v-model="form.invite_num" size="small" controls-position="right" :min="1" :max="100"
            class="number-small number-mn"></el-input-number>
          人获得
          <el-input-number v-model="form.add_rate" size="small" controls-position="right" :min="0.1" :max="100"
            :precision="2" class="number-small number-mn"></el-input-number>
        </div>
        <div class="q-info text-small" style="line-height: 1.6; margin-top: 8px;">
          1.用户中奖概率越高，抽中实物/虚拟奖品的机会越大；<br>2.中奖概率为总值100%。
        </div>
        <div>
          每个用户中奖概率值限制：
          <el-radio-group v-model="form.max_type">
            <el-radio :label="1">不设限制</el-radio>
            <el-radio :label="2">自定义</el-radio>
          </el-radio-group>
          <template v-if="form.max_type === 2">
            ：<el-input-number v-model="form.max_rate" size="small" controls-position="right" :min="10" :max="60"
              :precision="2" class="number-small number-mn"></el-input-number>
          </template>
        </div>
      </div>
    </el-form-item>

    <!-- 预约按钮 -->
    <el-form-item label="预约按钮：" prop="btn_title" class="basic-form__item">
      <el-input class="act-form__input" v-model.trim="form.btn_title" maxlength="8" show-word-limit
        placeholder="请输入预约按钮文字">
      </el-input>
    </el-form-item>

    <!-- 活动规则 -->
    <el-form-item label="活动规则：" prop="rule" class="basic-form__item">
      <ly-editor class="act-form__editor" title="活动规则" id="rules" :content="form.rule" :recoverContent="default_rules"
        @getContent="fn_getRules" @focus="ruleFocus = true" @blur="ruleFocus = false"></ly-editor>
    </el-form-item>
  </el-form>
</template>

<script>
import { activity } from '../http'
// store
import { mapState } from 'vuex'
export default {
  name: 'actBasic',

  data () {
    // 开奖时间校验
    const checkTime = async (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择开奖时间'))
      } else {
        if (this.isEdit) {
          return callback()
        }

        if (new Date(value) < Date.now()) {
          callback(new Error('开奖时间不可早于当前时间'))
        }

        if (this.act_time) {
          const start_time = this.act_time[0]
          if (new Date(value) < new Date(start_time)) {
            const start = this.$day(start_time).format('YYYY-MM-DD HH:mm:ss')
            callback(new Error(`开奖时间不可早于活动开始时间：${start}`))
          }
        }
      }
    }

    return {
      COMM_HTTP: activity,
      cardLoading: true,
      form: {
        lottery_type: 1,
        end_time: null,
        invite_num: 1,
        add_rate: 0.1,
        max_type: 1,
        max_rate: 50,
        btn_title: '参与活动',
        rule: ''
      },
      rules: {
        lottery_type: [
          { required: true, message: '请选择抽奖类型', trigger: 'blur' }
        ],
        end_time: [
          { validator: checkTime, required: true, trigger: 'change' }
        ],
        btn_title: [
          { required: true, message: '请填写预约按钮文字', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ]
      },
      default_rules: '1、点击预约直播，即可成功预约直播间预告，开播前将进行开播提醒哦~\n2、请在活动时间内参与活动，如有任何问题请联系主办方。',
      ruleFocus: false
    }
  },

  computed: {
    endDatePickerOptions () {
      return {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
        selectableRange: (() => {
          const now = new Date()
          const pick = new Date(new Date(this.form.end_time).setHours(0, 0, 0, 0)).getTime()
          const today = new Date().setHours(0, 0, 0, 0)
          // 时间段，今天时间前不可选
          let rangeFirst = '00:00:00'
          if (pick === today) {
            rangeFirst = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
          }
          return `${rangeFirst} - 23:59:59`
        })()
      }
    },

    ...mapState({
      act_time: state => state.livelottery.act_time,
      isEdit: state => state.livelottery.editType === 'edit'
    })
  },

  watch: {
    'form.btn_title' (val) {
      this.$store.commit('livelottery/SET_INFO', {
        name: 'btn_title',
        val
      })
    },
    'form.rule' (val) {
      this.$store.commit('livelottery/SET_INFO', {
        name: 'rule',
        val
      })
    },
    'form.end_time' (val) {
      this.$store.commit('livelottery/SET_INFO', {
        name: 'end_time',
        val
      })
    },
    ruleFocus (val) {
      this.$store.commit('livelottery/SET_INFO', {
        name: 'rule_focus',
        val
      })
    }
  },

  methods: {

    // 初始化
    fn_setForm (data) {
      if (data) {
        this.form = data
        this.$store.commit('livelottery/SET_INFO', {
          name: 'end_time',
          val: data.end_time
        })
      } else {
        this.form.rule = this.default_rules
        this.$store.commit('livelottery/SET_INFO', {
          name: 'rule',
          val: this.default_rules
        })
      }
      setTimeout(() => {
        this.cardLoading = false
      }, 100)
    },

    // 规则回调
    fn_getRules (val) {
      this.form.rule = val
    },

    // 活动首图回调设置
    fn_getImg (img, type) {
      this.form[type] = img
      this.$refs.formRef.validateField(type)
    },

    // 如何获取视频号
    fn_toDoc () {
      window.open('https://www.yuque.com/docs/share/2890cdcb-3648-48ae-8308-40dbd9efbca6', '_blank')
    },

    // 表单校验，通过则返回 form
    verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false
      const form = this.util.extend(true, {}, this.form)
      form.end_time = form.end_time / 1000

      return form
    }

  }
}
</script>

<style lang="scss" scoped>
.basic-form {
  width: 100%;

  &__item {
    width: 60%;
    min-width: 560px;

    &.is-long {
      width: 100%;
      max-width: 860px;
    }
  }
}

.rules {
  line-height: inherit;
  padding: 12px 20px;
}

.number-small {
  width: 120px;
  margin: 0 4px;

  ::v-deep .el-input__inner {
    padding-left: 4px;
    padding-right: 36px;
  }

  ::v-deep {

    [class^=el-icon-] {
      margin-right: 0;
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}

.number-mn {
  width: 100px;

  ::v-deep .el-input__inner {
    padding-right: 30px;
  }
}
</style>
