var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "inner edit-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.pageLoading,
            expression: "pageLoading"
          }
        ],
        ref: "editRef",
        staticClass: "edit-main edit-poster"
      },
      [
        _c(
          "div",
          { staticClass: "edit-card edit-menu" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.fn_setTab },
                model: {
                  value: _vm.tabKey,
                  callback: function($$v) {
                    _vm.tabKey = $$v
                  },
                  expression: "tabKey"
                }
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "活动信息", name: "basic" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "红包设置", name: "redenvelope" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "裂变海报", name: "poster" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "活动页设置", name: "other" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "高级防刷", name: "preventbrush" }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { ref: "main", staticClass: "edit-poster-content" },
          [
            _c(
              "el-scrollbar",
              { staticClass: "left-area" },
              [
                _c("set-basic", {
                  ref: "basic",
                  attrs: { title: "活动信息", aid: _vm.id }
                }),
                _c("set-redenvelope", {
                  ref: "redenvelope",
                  attrs: { title: "红包设置" }
                }),
                _c("set-poster", {
                  ref: "poster",
                  attrs: { title: "裂变海报" }
                }),
                _c("set-other", {
                  ref: "other",
                  attrs: { title: "活动页设置" }
                }),
                _c("set-preventBrush", {
                  ref: "preventbrush",
                  attrs: { title: "高级防刷" }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-poster-mobi edit-card" },
              [
                _c(
                  "el-scrollbar",
                  { staticStyle: { height: "100%" } },
                  [
                    _vm.tabKey === "basic" ? _c("mobi-basic") : _vm._e(),
                    _vm.tabKey === "redenvelope" || _vm.tabKey === "other"
                      ? _c("mobi-redenvelope", {
                          attrs: { tabKey: _vm.tabKey }
                        })
                      : _vm._e(),
                    _vm.tabKey === "poster" || _vm.tabKey === "preventbrush"
                      ? _c("mobi-poster")
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "edit-footer",
        staticStyle: { "justify-content": "space-between" }
      },
      [
        _c("div", {
          staticClass: "flex flex-justify-start text-regular",
          staticStyle: { flex: "1.2" }
        }),
        !_vm.pageLoading
          ? _c(
              "div",
              { staticClass: "flex flex-center", staticStyle: { flex: "0.7" } },
              [
                _vm.progress_status === 2
                  ? _c("el-button", { attrs: { disabled: "" } }, [
                      _vm._v("活动已结束")
                    ])
                  : [
                      !_vm.isEdit || _vm.progress_status === 0 || _vm.isCopy
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                round: "",
                                icon: "iconfont icon-edit",
                                loading: _vm.saveLoading
                              },
                              on: { click: _vm.fn_save }
                            },
                            [_vm._v("保存草稿")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "14px" },
                          attrs: {
                            round: "",
                            icon: "iconfont icon-send-fill",
                            type: "primary",
                            loading: _vm.submitLoading
                          },
                          on: { click: _vm.fn_submit }
                        },
                        [_vm._v("发布活动")]
                      )
                    ]
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticStyle: { flex: "1.2" } })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }