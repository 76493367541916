<!--
 * @Author: zhanln
 * @Date: 2021-11-01 17:31:38
 * @LastEditTime: 2022-09-08 18:22:35
 * @LastEditors: zhanln
 * @Description: 活动信息
-->

<template>
  <ly-form
    ref="lyFormRef"
    class="act-form"
    :formObj="formObj"
    :form="formData"
    :rules="rules"
    @setData="fn_setData"
    v-loading="cardLoading"
  >
    <template #vn_id_bottom>
      <p class="q-info text-small" style="line-height: 1.6; margin-bottom: 0px">
        视频号ID必须与小程序主体一致，否则将无法跳转
        <span
          class="q-primary"
          style="margin-left: 24px; cursor: help"
          @click="fn_toDoc"
          >如何获取视频号ID？</span
        >
      </p>
    </template>

    <template #show_url_head>
      <div class="q-info text-small">
        建议尺寸:702*340像素，大小不超过2MB，格式为JPG/PNG/JPEG
      </div>
    </template>

    <template #wecomTag>
      <el-form-item label="客户标签：">
        <el-radio-group v-model="formData.add_tags_type">
          <el-radio :label="1">添加即打标签</el-radio>
          <el-radio :label="2">新老用户场景标签</el-radio>
        </el-radio-group>

        <tag-select-v2
          @getTagId="fn_getTagId($event, 'tags1')"
          :checked="formData.tags1"
          v-if="formData.add_tags_type === 1"
        >
        </tag-select-v2>

        <template v-else>
          <div>
            新用户：<span class="q-info">添加涨粉账号的新增用户打标签</span>
          </div>
          <tag-select-v2
            @getTagId="fn_getTagId($event, 'tags2')"
            :checked="formData.tags2"
          >
          </tag-select-v2>
          <div class="mt-16">
            老用户：<span class="q-info">添加涨粉账号的企业老用户打标签</span>
          </div>
          <tag-select-v2
            @getTagId="fn_getTagId($event, 'tags3')"
            :checked="formData.tags3"
          >
          </tag-select-v2>
        </template>
      </el-form-item>
    </template>
  </ly-form>
</template>

<script>
// components
import tagSelectV2 from '@/components/wecomTags'
import mapTag from '@/common/mapTag'
import { mapState } from 'vuex'
// api
import { activity } from '../http'
export default {
  name: 'actBasic',

  components: {
    tagSelectV2
  },

  data () {
    // 活动结束时间
    const checkEnd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择活动结束时间'))
      }
      const now = this.$day().valueOf()
      const select = this.$day(value).valueOf()
      if (select < now) {
        return callback(new Error('活动结束时间不能早于当前时间'))
      }
      callback()
    }

    return {
      COMM_HTTP: activity,
      cardLoading: true,
      formData: {
        title: '',
        end_time: null,
        vn_id: '',
        show_url: '',
        add_user_ids: null,
        welcome_msg: '',
        add_tags: 2,
        add_tags_type: 1,
        tags1: '',
        tags2: '',
        tags3: ''
      },
      rules: {
        title: [
          { required: true, message: '请填写活动名称', trigger: 'change' }
        ],
        end_time: [
          { validator: checkEnd, required: true, trigger: 'change' }
        ],
        vn_id: [
          { required: true, message: '请输入视频号ID', trigger: 'change' }
        ],
        show_url: [
          { required: true, message: '请上传活动封面图', trigger: 'change' }
        ],
        add_user_ids: [
          { required: true, message: '请选择涨粉账号', trigger: 'change' }
        ],
        welcome_msg: [
          { required: true, message: '请填写裂变欢迎语', trigger: 'change' }
        ]
      },
      base_welcome_msg: '#用户昵称#，你终于找到我了，欢迎参与活动哦～\n点击以下活动信息查看任务进度'
    }
  },

  inject: ['fn_setStore'],

  computed: {
    formObj () {
      return {
        labelWidth: '160px',
        item: [
          {
            type: 'text',
            label: '活动名称',
            prop: 'title',
            attr: {
              maxlength: '16',
              showWordLimit: true,
              placeholder: '请输入活动名称'
            }
          },
          {
            type: 'date',
            label: '活动结束时间',
            prop: 'end_time',
            dateType: 'datetime',
            pickerOptions: this.endDatePickerOptions,
            attr: {
              clearable: false,
              placeholder: '请选择时间',
              valueFormat: 'timestamp'
            }
          },
          {
            type: 'text',
            label: '直播预告',
            prop: 'vn_id',
            bottomSlot: 'vn_id_bottom',
            attr: {
              maxlength: '60',
              showWordLimit: true,
              placeholder: '请输入视频号ID'
            }
          },
          {
            type: 'upload',
            label: '活动封面图',
            prop: 'show_url',
            labelTip: '活动封面图将展示在小程序活动中心 “首页” 进行推广',
            headSlot: 'show_url_head'
          },
          {
            type: 'emp',
            label: '涨粉账号',
            prop: 'add_user_ids',
            show: () => {
              return this.mode === 2
            }
          },
          {
            type: 'textEditor',
            label: '裂变欢迎语',
            prop: 'welcome_msg',
            attr: {
              emoji: true,
              insetMenu: ['用户昵称', '活动链接'],
              recoverContent: this.base_welcome_msg
            },
            show: () => {
              return this.mode === 2
            }
          },
          {
            type: 'switch',
            label: '自动打标签',
            prop: 'add_tags',
            key: [2, 1],
            switchTip: '自动为裂变账号的客户打标签',
            show: () => {
              return this.mode === 2
            }
          },
          {
            slot: 'wecomTag',
            label: '客户标签',
            show: () => {
              return this.mode === 2 && this.formData.add_tags === 1
            }
          }
        ]
      }
    },

    endDatePickerOptions () {
      return {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
        selectableRange: (() => {
          const now = new Date()
          const pick = new Date(new Date(this.formData.end_time).setHours(0, 0, 0, 0)).getTime()
          const today = new Date().setHours(0, 0, 0, 0)
          // 时间段，今天时间前不可选
          let rangeFirst = '00:00:00'
          if (pick === today) {
            rangeFirst = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
          }
          return `${rangeFirst} - 23:59:59`
        })()
      }
    },

    ...mapState({
      mode: state => state.reserveTask.mode
    })

  },

  watch: {
    'formData.end_time' (val) {
      this.fn_setStore('end_time', val)
    },
    'formData.welcome_msg' (val) {
      this.fn_setStore('welcome_msg', val)
    }
  },

  methods: {
    // 初始化
    fn_setForm (data) {
      if (data) {
        data.end_time = data.end_time ? data.end_time * 1000 : null
        data.welcome_msg = mapTag(data.welcome_msg)
        data.add_user_ids = data.add_user_ids ? data.add_user_ids.split(',') : null
        this.formData = data
        if (this.mode === 2) {
          this.fn_setStore('welcome_msg', data.welcome_msg)
        }
      } else {
        this.fn_init()
      }

      this.$nextTick(() => {
        this.cardLoading = false
        this.$refs.lyFormRef.clearValidate()
      })
    },

    // 配置默认表单
    fn_init () {
      this.formData.welcome_msg = this.base_welcome_msg
      this.fn_setStore('welcome_msg', this.base_welcome_msg)
    },

    // 组件内容回调及校验
    fn_setData (val, name) {
      this.formData[name] = val
      this.$refs.lyFormRef.verify(name)
    },

    // 标签设置
    fn_getTagId (val, name) {
      this.formData[name] = val ? val.join(',') : ''
    },

    // 如何获取视频号文档
    fn_toDoc () {
      window.open('https://www.yuque.com/docs/share/2890cdcb-3648-48ae-8308-40dbd9efbca6', '_blank')
    },

    // 表单校验，通过则返回 form
    async verify (draft) {
      let formValid = false

      if (draft) {
        formValid = await this.$refs.lyFormRef.verify('title')
      } else {
        formValid = await this.$refs.lyFormRef.verify()
      }

      if (!formValid) return false

      const {
        title,
        end_time,
        vn_id,
        show_url,
        add_user_ids,
        welcome_msg,
        add_tags,
        add_tags_type,
        tags1,
        tags2,
        tags3
      } = this.formData

      const basicForm = {
        title,
        end_time: end_time && end_time / 1000,
        vn_id,
        show_url
      }

      console.log('basicForm', basicForm)

      // 普通模式
      if (this.mode === 1) {
        return basicForm
      }

      // 私域裂变模式
      const form = {
        ...basicForm,
        add_user: 1,
        add_user_ids: add_user_ids ? add_user_ids.join(',') : '',
        welcome_msg: mapTag(welcome_msg, false),
        add_tags,
        add_tags_type,
        tags: add_tags_type === 1 ? tags1 : tags2,
        old_tags: add_tags_type === 2 ? tags3 : ''
      }

      return form
    }

  }
}
</script>

<style lang="scss" scoped>
.act-form {
  ::v-deep {
    .el-input {
      max-width: 320px;
    }

    .ly-editor {
      width: 485px;
    }
  }
}

.expire_time {
  display: inline-block;

  ::v-deep .el-form-item__error {
    left: 16px;
  }
}
</style>
