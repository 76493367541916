<!--
 * @Author: zhanln
 * @Date: 2021-11-03 19:04:30
 * @LastEditTime: 2022-02-09 10:43:20
 * @LastEditors: zhanln
 * @Description: 奖品内容
-->

<template>
  <div class="reward-detail">
    <el-radio-group v-model="type" :disabled="isEdit && !isCopy">
      <el-tooltip
        class="item"
        placement="top"
        content="功能准备上线中，敬请期待"
        v-for="(item, index) in list"
        :disabled="!item.isDisable"
        :key="index"
      >
        <el-radio :label="item.value" :disabled="item.isDisable">{{
          item.name
        }}</el-radio>
      </el-tooltip>
    </el-radio-group>

    <!-- 自定义内容 -->
    <el-form
      :model="diyForm"
      :rules="diyRule"
      ref="diyFormRef"
      size="medium"
      v-show="type === 1"
      class="act-form"
    >
      <el-form-item prop="content">
        <div class="editor act-form__editor">
          <wang-editor
            refName="diyEdit"
            :content="diyForm.content"
            :menus="['link']"
            :getText="fn_getDiyText"
            placeholder="支持输入文本，链接"
            class="editor-content"
            :setCont="(html) => fn_setDiyContent(html)"
            @eFocus="fn_detailFocus"
            @eBlur="fn_detailBlurs"
          />
          <div class="editor-count">{{ diyTextLength }} / 1000</div>
          <div class="editor-img">
            <ly-upload
              :img="diyForm.img"
              @getImg="fn_getDiyImg"
              mini
              canRemove
            ></ly-upload>
          </div>
        </div>
        <p
          class="q-info text-small"
          style="line-height: 1.6; margin-bottom: 0"
          v-if="urlTips"
        >
          请确保奖品链接页面无法复制分享，或使用人工审核的表单类链接，避免奖品内容被分享传播，造成损失或用户投诉。
        </p>
        <el-tooltip
          class="item"
          placement="top"
          popper-class="q-tooltip is-light"
        >
          <div slot="content">
            <div style="margin: 24px 24px 16px; width: 528px">
              <img
                src="https://image.01lb.com.cn//uploads/wecom/21/0416/161857859748669etR.png"
                width="528"
                alt=""
              />
            </div>
          </div>
          <span
            class="q-primary text-small inline-flex"
            style="line-height: 1.6; margin-bottom: 0"
          >
            <i class="iconfont icon-help1" style="margin-right: 2px"></i>
            用户完成任务后，如何领取奖品
          </span>
        </el-tooltip>
      </el-form-item>
    </el-form>

    <!-- 兑换码 -->
    <el-form
      :model="codeForm"
      :rules="codeRule"
      ref="codeFormRef"
      size="medium"
      v-show="type === 2"
    >
      <el-form-item prop="codeId" class="reward-detail__form-item">
        <el-select
          v-model="codeForm.codeId"
          placeholder="请选择"
          clearable
          class="act-form__input"
          :disabled="isEdit && !isCopy"
          @change="fn_changeCode"
        >
          <el-option
            v-for="item in codeList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="reward-detail__tool" v-if="!isEdit || isCopy">
        选择要关联活动的兑换码奖品
        <el-button type="text" size="mini" @click="fn_jumpToCode('code')"
          >去创建兑换码</el-button
        >
        <div data-v-3cefa108="" class="el-divider el-divider--vertical"></div>
        <el-button
          type="text"
          size="mini"
          v-lydebounce="['button', fn_getCodeList]"
          >刷新</el-button
        >
      </div>

      <el-form-item prop="rule">
        <div class="editor act-form__editor">
          <wang-editor
            refName="codeEdit"
            :content="codeForm.rule"
            :menus="['link']"
            :getText="fn_getCodeText"
            placeholder="请输入兑换规则"
            :setCont="(html) => fn_setCodeRule(html)"
            @eFocus="fn_detailFocus"
            @eBlur="fn_detailBlurs"
          />
          <div class="editor-count">
            <span class="editor-regain" @click="fn_regain('code')"
              >恢复默认文本</span
            >{{ codeTextLength }} / 1000
          </div>
        </div>
      </el-form-item>
      <el-tooltip
        class="item"
        placement="top"
        popper-class="q-tooltip is-light"
      >
        <div slot="content">
          <div style="margin: 24px 24px 16px; width: 528px">
            <img
              src="https://image.01lb.com.cn//uploads/wecom/21/0514/1620972249aA8TLu71.png"
              width="528"
              alt=""
            />
          </div>
        </div>
        <span
          class="q-primary text-small inline-flex"
          style="line-height: 1.6; margin-bottom: 0"
        >
          <i class="iconfont icon-help1" style="margin-right: 2px"></i>
          用户完成任务后，如何领取奖品
        </span>
      </el-tooltip>
    </el-form>

    <!-- 表单 -->
    <el-form
      :model="form"
      :rules="formRule"
      ref="formRef"
      size="medium"
      v-show="type === 3"
    >
      <el-form-item prop="formId" class="reward-detail__form-item">
        <el-select
          v-model="form.formId"
          placeholder="请选择"
          clearable
          class="act-form__input"
          :disabled="isEdit && !isCopy"
        >
          <el-option
            v-for="item in formList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="reward-detail__tool" v-if="!isEdit || isCopy">
        选择要关联活动的表单
        <el-button type="text" size="mini" @click="fn_jumpToCode('form')"
          >去创建表单</el-button
        >
        <div class="el-divider el-divider--vertical"></div>
        <el-button
          type="text"
          size="mini"
          v-lydebounce="['button', fn_getFormList]"
          >刷新</el-button
        >
      </div>

      <el-form-item prop="rule">
        <div class="editor act-form__editor">
          <div class="editor-header">奖品规则：</div>
          <wang-editor
            refName="formEdit"
            :content="form.rule"
            :menus="[]"
            :getText="fn_getFormText"
            placeholder="请输入奖品规则"
            :setCont="(html) => fn_setFormRule(html)"
            class="no-tool"
            @eFocus="fn_detailFocus"
            @eBlur="fn_detailBlurs"
          />
          <div class="editor-count">
            <span class="editor-regain" @click="fn_regain('form')"
              >恢复默认文本</span
            >{{ formTextLength }} / 1000
          </div>
        </div>
      </el-form-item>
      <el-tooltip
        class="item"
        placement="top"
        popper-class="q-tooltip is-light"
      >
        <div slot="content">
          <div style="margin: 24px 24px 16px; width: 528px">
            <img
              src="https://image.01lb.com.cn//uploads/wecom/22/0209/1644374555Jvrrd7Us.png"
              width="528"
              alt=""
            />
          </div>
        </div>
        <span
          class="q-primary text-small inline-flex"
          style="line-height: 1.6; margin-bottom: 0"
        >
          <i class="iconfont icon-help1" style="margin-right: 2px"></i>
          用户完成任务后，如何领取奖品
        </span>
      </el-tooltip>
    </el-form>
  </div>
</template>

<script>
import wangEditor from '@/components/wangEditor'
import { fDetails } from '../http'
// store
import { mapState } from 'vuex'
export default {
  name: 'taskRewardDetail',

  data () {
    // 自定义内容校验
    const checkContent = async (rule, value, callback) => {
      if (!value && !this.diyForm.img) {
        callback(new Error('请输入奖品内容（文本或图片至少一项）'))
      } else {
        if (this.diyTextLength > this.codeMax) {
          callback(new Error(`最多可输入${this.codeMax}字`))
        } else {
          callback()
        }
      }
    }
    // 兑换码规则
    const checkCodeRule = async (rule, value, callback) => {
      if (this.codeTextLength > this.codeMax) {
        callback(new Error(`最多可输入${this.codeMax}字`))
      } else {
        callback()
      }
    }
    // 表单规则
    const checkFormRule = async (rule, value, callback) => {
      if (this.formTextLength > this.codeMax) {
        callback(new Error(`最多可输入${this.codeMax}字`))
      } else {
        callback()
      }
    }
    return {
      type: 1,
      list: [
        {
          name: '自定义',
          value: 1
        },
        {
          name: '兑换码',
          value: 2
        },
        {
          name: '表单',
          value: 3
        },
        {
          name: '核销码',
          value: 4,
          isDisable: true
        }
      ],
      diyForm: {
        content: '',
        img: ''
      },
      diyRule: {
        content: [
          { validator: checkContent, required: true, trigger: 'change' }
        ]
      },
      diyTextLength: 0,
      urlTips: false,
      codeForm: {
        codeId: null,
        rule: ''
      },
      codeMax: 1000,
      codeRule: {
        codeId: [
          { required: true, message: '请选择兑换码', trigger: 'change' }
        ],
        rule: [
          { validator: checkCodeRule, trigger: 'change' }
        ]
      },
      codeList: [],
      codeReady: false,
      codeTextLength: 0,
      form: {
        formId: null,
        rule: ''
      },
      formRule: {
        formId: [
          { required: true, message: '请选择表单', trigger: 'change' }
        ],
        rule: [
          { validator: checkFormRule, trigger: 'change' }
        ]
      },
      formList: [],
      formReady: false,
      formTextLength: 0,
      codeRuleDefault: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
      formRuleDefault: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.task.isEdit,
      isCopy: state => state.task.isCopy
    })
  },

  watch: {
    type (val) {
      if (val === 2 && !this.codeReady) {
        this.codeReady = true
        this.fn_getCodeList()
      }
      if (val === 3 && !this.formReady) {
        this.formReady = true
        this.fn_getFormList()
      }
      this.fn_setStore('reward_type', val)
    },
    'diyForm.content' (val) {
      this.fn_setStore('diy_content', val)
    },
    'diyForm.img' (val) {
      this.fn_setStore('diy_img', val)
    },
    'codeForm.rule' (val) {
      this.fn_setStore('code_rule', val)
    },
    'form.rule' (val) {
      this.fn_setStore('form_rule', val)
    }
  },

  methods: {

    // 传入数据处理
    fn_setDetail (obj) {
      this.diyForm.content = obj.reward_detail
      this.diyForm.img = obj.reward_detail_img
      this.codeForm.codeId = obj.code_id ? +obj.code_id : null
      this.codeForm.rule = obj.code_rule || this.codeRuleDefault
      this.form.formId = obj.form_id
      this.form.rule = obj.form_rule || this.formRuleDefault

      this.fn_setStore('reward_type', this.type)
      this.fn_setStore('diy_content', this.diyForm.content)
      this.fn_setStore('diy_Img', this.diyForm.img)
      this.fn_setStore('code_rule', this.codeForm.rule)
      this.fn_setStore('form_rule', this.form.rule)

      switch (obj.reward_type) {
        case 4:
          this.type = 1
          break
        case 6:
          this.type = 2
          break
        case 5:
          this.type = 3
          break
      }

      if (this.type === 2 && !this.codeReady && this.codeForm.codeId) {
        this.codeReady = true
        this.fn_getCodeList()
      }
      if (this.type === 3 && !this.formReady && this.codeForm.formId) {
        this.formReady = true
        this.fn_getFormList()
      }
      this.$store.state.task.reward_focus = false
    },

    // 自定义 - 内容text
    fn_getDiyText (text) {
      this.diyTextLength = text.length
      this.$refs.diyFormRef.validateField('content')
    },

    // 自定义 - 内容html
    fn_setDiyContent (html) {
      this.diyForm.content = html
      this.fn_urlValid(html)
    },

    // 匹配粘贴的内容是否为链接
    fn_urlValid (val) {
      if (val.indexOf('<a href="') > -1) {
        this.urlTips = true
      } else {
        this.urlTips = false
      }
    },

    // 自定义 - 图片
    fn_getDiyImg (img) {
      this.diyForm.img = img
      this.fn_detailFocus()
      this.$refs.diyFormRef.validateField('content')
    },

    // 获取兑换码列表
    async fn_getCodeList () {
      const data = await this.axios.get('taskCodeList', { params: { code_id: this.codeForm.codeId } })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.codeList = data
    },

    // 跳转兑换码 / 表单列表
    fn_jumpToCode (type) {
      const path = type === 'code' ? '/material/code/list' : '/material/formTable/list'
      const routeData = this.$router.resolve({
        path: path
      })
      window.open(routeData.href, '_blank')
    },

    // 兑换码 规则text
    fn_getCodeText (text) {
      this.codeTextLength = text.length
      this.$refs.codeFormRef.validateField('rule')
    },

    // 兑换码 - 内容html
    fn_setCodeRule (html) {
      this.codeForm.rule = html
    },

    // 获取表单列表
    async fn_getFormList () {
      const data = await fDetails.reqQuery({
        page: 1,
        per_page: 9999
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.formList = data.records
    },

    // 表单 规则text
    fn_getFormText (text) {
      this.formTextLength = text.length
      this.$refs.formRef.validateField('rule')
    },

    // 表单 - 内容html
    fn_setFormRule (html) {
      this.form.rule = html
    },

    // 清空校验
    fn_clearValid () {
      this.$refs.codeFormRef.clearValidate()
      this.$refs.formRef.clearValidate()
      this.$refs.diyFormRef.clearValidate()
    },

    // 恢复默认文本
    fn_regain (type) {
      const form = type === 'code' ? this.codeForm : this.form
      const typeName = type === 'code' ? '兑换规则' : '奖品规则'
      const base = type === 'code' ? this.codeRuleDefault : this.formRuleDefault
      this.$lyConfirm({
        title: `确定将${typeName}恢复为默认文本吗？`,
        text: ''
      }).then(async () => {
        form.rule = base
      }).catch(() => { })
    },

    // 选择/切换兑换码
    fn_changeCode (val) {
      if (val) {
        const wait = this.codeList.filter(item => item.id === val)[0].wait
        this.$emit('changeTotal', wait)
      }
    },

    // 配置
    fn_getForm () {
      const form = {
        type: this.type,
        diy: this.diyForm,
        code: this.codeForm,
        form: this.form
      }
      return form
    },

    // 聚焦
    fn_detailFocus () {
      this.$store.state.task.reward_focus = true
    },

    // 失焦
    fn_detailBlurs () {
      this.$store.state.task.reward_focus = false
    },

    // 存储, store
    fn_setStore (type, val) {
      this.$store.commit('task/SET_INFO', {
        type,
        val
      })
    },

    // 校验
    verify () {
      let formName = null
      let detailValid = true

      switch (this.type) {
        case 1:
          formName = 'diyForm'
          break
        case 2:
          formName = 'codeForm'
          break
        case 3:
          formName = 'form'
          break
      }

      this.$refs[formName + 'Ref'].validate((valid) => {
        if (!valid) {
          detailValid = false
        }
      })

      if (!detailValid) return false

      return this.fn_getForm()
    }

  },

  components: {
    wangEditor
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.reward-detail {
  &__tool {
    font-size: 12px;
    color: $--color-text-secondary;
  }

  &__form-item {
    ::v-deep .el-form-item__content {
      display: flex;
      align-items: center;
    }
    ::v-deep .el-form-item__error {
      position: relative;
      margin-left: 16px;
    }
  }
}
.editor {
  border: 1px solid #dfe1e8;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
  transition: 0.25s border;

  &:hover {
    border-color: $--color-primary;
  }

  &-header {
    background-color: #f0f1f8;
    border-radius: 4px 4px 0 0;
    padding: 0 12px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-content {
    ::v-deep {
      .w-e-text-container {
        height: 169px !important;
      }
    }
  }

  &-count {
    text-align: right;
    padding: 0 12px;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.45);
  }

  &-img {
    border-top: 1px solid #eaebf2;
    padding: 16px 18px;
  }

  &-regain {
    display: inline-flex;
    color: $--color-text-regular;
    border-right: 2px;
    font-size: 12px;
    padding: 0 8px;
    height: 28px;
    line-height: 28px;
    margin-right: 2px;
    cursor: pointer;
    border-radius: 14px;
    transition: 0.25s color;

    &:hover {
      background-color: #f0f1f8;
    }
  }
}

.el-form-item.is-error {
  .editor {
    border-color: $--color-danger;
  }
}

.no-tool {
  ::v-deep {
    .w-e-toolbar {
      display: none;
    }
  }
}
</style>
