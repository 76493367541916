var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加附件",
        visible: _vm.visible,
        "custom-class": "add-content",
        "close-on-click-modal": false,
        width: "560px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.handleDialogClose
      }
    },
    [
      _c("div", { staticClass: "type-list" }, [
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2002 },
            on: {
              click: function($event) {
                return _vm.handleClick(2002)
              }
            }
          },
          [_vm._v("图片")]
        ),
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2005 },
            on: {
              click: function($event) {
                return _vm.handleClick(2005)
              }
            }
          },
          [_vm._v("链接")]
        ),
        _c(
          "span",
          {
            staticClass: "item",
            class: { active: _vm.type === 2013 },
            on: {
              click: function($event) {
                return _vm.handleClick(2013)
              }
            }
          },
          [_vm._v("小程序")]
        )
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.type === 2002
            ? [
                _c(
                  "el-upload",
                  {
                    ref: "pic",
                    staticClass: "uploader",
                    attrs: {
                      action: "#",
                      accept: ".png, .jpg",
                      "file-list": _vm.picImgList,
                      "show-file-list": false,
                      "http-request": function(opts) {
                        return _vm.requestQiniu(opts, "pic")
                      },
                      limit: 1
                    }
                  },
                  [
                    _vm.pic.msg_content
                      ? _c("div", { staticClass: "thum" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: { src: _vm.pic.msg_content }
                          }),
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-closeplus",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleRemove("pic")
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          )
                        ])
                      : [
                          _c("div", { staticClass: "upload-box flex" }, [
                            _c("i", {
                              staticClass:
                                "iconfont icon-plus avatar-uploader-icon"
                            }),
                            _c("span", [_vm._v("上传图片")])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "desc q-info text-small",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v(" 支持JPG、PNG格式，图片小于2M ")]
                          )
                        ]
                  ],
                  2
                )
              ]
            : _vm.type === 2005
            ? [
                _c(
                  "el-form",
                  {
                    ref: "linkForm",
                    staticClass: "link-form",
                    attrs: {
                      size: "medium",
                      "show-message": false,
                      rules: _vm.linkRules,
                      model: _vm.link,
                      "label-width": "0px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "href" } },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "lydebounce",
                              rawName: "v-lydebounce",
                              value: ["input", _vm.getUrlInfo],
                              expression: "['input', getUrlInfo]"
                            }
                          ],
                          attrs: { placeholder: "请输入链接" },
                          model: {
                            value: _vm.link.href,
                            callback: function($$v) {
                              _vm.$set(_vm.link, "href", $$v)
                            },
                            expression: "link.href"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "title" } },
                      [
                        _c("el-input", {
                          attrs: {
                            rows: 1,
                            "show-word-limit": "",
                            maxlength: "40",
                            placeholder: "链接标题"
                          },
                          model: {
                            value: _vm.link.title,
                            callback: function($$v) {
                              _vm.$set(_vm.link, "title", $$v)
                            },
                            expression: "link.title"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "desc", attrs: { prop: "desc" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            "show-word-limit": "",
                            maxlength: "170",
                            placeholder: "快来参加活动吧"
                          },
                          model: {
                            value: _vm.link.desc,
                            callback: function($$v) {
                              _vm.$set(_vm.link, "desc", $$v)
                            },
                            expression: "link.desc"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "poster" },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "link",
                            attrs: {
                              action: "#",
                              accept: ".png, .jpg",
                              "on-success": _vm.handleSuccess,
                              "http-request": function(opts) {
                                return _vm.requestQiniu(opts, "link")
                              },
                              limit: 1,
                              "file-list": _vm.linkImgList,
                              "show-file-list": false
                            }
                          },
                          [
                            _vm.link.msg_content
                              ? _c("div", { staticClass: "thum" }, [
                                  _c("img", {
                                    staticClass: "img link-img",
                                    attrs: { src: _vm.link.msg_content }
                                  }),
                                  _c(
                                    "i",
                                    {
                                      staticClass: "el-icon-closeplus",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleRemove("link")
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "el-icon-close" })]
                                  )
                                ])
                              : _c("div", { staticClass: "upload-box flex" }, [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-plus avatar-uploader-icon"
                                  }),
                                  _c("span", [_vm._v("上传封面")])
                                ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("span", { staticClass: "desc q-info text-small" }, [
                      _vm._v(
                        "封面图片支持JPG、PNG格式，小于2M，建议尺寸500*500px"
                      )
                    ])
                  ],
                  1
                )
              ]
            : _vm.type === 2013
            ? [
                _c(
                  "div",
                  { staticClass: "q-danger", staticStyle: { margin: "16px" } },
                  [_vm._v(" 注：请填写企业微信后台绑定的小程序id和路径 ")]
                ),
                _c(
                  "el-form",
                  {
                    ref: "appletForm",
                    staticClass: "applet-form",
                    attrs: {
                      size: "medium",
                      "show-message": false,
                      rules: _vm.appletRules,
                      model: _vm.applet,
                      "label-width": "120px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序标题", prop: "title" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入小程序标题",
                            "show-word-limit": "",
                            maxlength: "32"
                          },
                          model: {
                            value: _vm.applet.title,
                            callback: function($$v) {
                              _vm.$set(_vm.applet, "title", $$v)
                            },
                            expression: "applet.title"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序appid", prop: "id" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入小程序appid",
                            maxlength: "32",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.applet.id,
                            callback: function($$v) {
                              _vm.$set(_vm.applet, "id", $$v)
                            },
                            expression: "applet.id"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "q-primary text-small",
                            staticStyle: { "text-align": "right" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_jumpToLink(1)
                                  }
                                }
                              },
                              [_vm._v(" 如何获取小程序appid？")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序路径", prop: "path" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入小程序路径",
                            maxlength: "250",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.applet.path,
                            callback: function($$v) {
                              _vm.$set(_vm.applet, "path", $$v)
                            },
                            expression: "applet.path"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "text-small",
                            staticStyle: { "text-align": "right" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "q-primary",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_jumpToLink(2)
                                  }
                                }
                              },
                              [_vm._v("如何获取小程序路径？")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序封面", prop: "title" } },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "applet",
                            staticClass: "uploader",
                            attrs: {
                              action: "#",
                              accept: ".png, .jpg",
                              "http-request": function(opts) {
                                return _vm.requestQiniu(opts, "applet")
                              },
                              limit: 1,
                              "file-list": _vm.appletImgList,
                              "show-file-list": false
                            }
                          },
                          [
                            _vm.applet.msg_content
                              ? _c("div", { staticClass: "thum" }, [
                                  _c("img", {
                                    staticClass: "img",
                                    attrs: { src: _vm.applet.msg_content }
                                  }),
                                  _c(
                                    "i",
                                    {
                                      staticClass: "el-icon-closeplus",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleRemove("applet")
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "el-icon-close" })]
                                  )
                                ])
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "upload-box flex" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-plus avatar-uploader-icon"
                                      }),
                                      _c("span", [_vm._v("上传图片")])
                                    ]
                                  )
                                ]
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "desc q-info text-small",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " 支持JPG、PNG格式，图片小于2M，尺寸建议520*416px "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.handlePushMsg }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }