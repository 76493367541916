/*
 * @Author: zhanln
 * @Date: 2021-07-26 14:58:55
 * @LastEditTime: 2022-03-18 11:10:54
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

// 客服
export const agrs = {
  reqQuery: params => axios.get(`/wecom/agg-chat/list?${stringify(params)}`), // 查询表格数据的接口
  creatorList: params => axios.get('/wecom/agg-chat/creator-select'), // 创建人下拉选择
  changeStatus: params => axios.post('/wecom/agg-chat/change-status', params), // 修改客服状态
  changePasswd: params => axios.post('/wecom/agg-chat/change-passwd', params), // 修改客服密码
  delete: params => axios.post('/wecom/agg-chat/delete', params), // 删除客服账号
  detail: params => axios.get(`/wecom/agg-chat/show?${stringify(params)}`), // 创建客服
  workUrl: params => axios.get('/wecom/agg-chat/work-url'), // 工作台地址
  create: params => axios.post('/wecom/agg-chat/create', params), // 创建客服
  setting: params => axios.post('/wecom/agg-chat/setting', params), // 设置客服
  selectList: params => axios.get('/wecom/agg-chat/select'), // 客服下拉数据
  robotList: params => axios.get('/wecom/agg-chat/user-select') // 企微号列表
}

// 聊天记录
export const recordSingle = {
  reqQuery: params => axios.get(`/wecom/agg-chat/single?${stringify(params)}`) // 私聊
}
export const recordGroup = {
  reqQuery: params => axios.get(`/wecom/agg-chat/group?${stringify(params)}`) // 群聊
}
export const recordImList = {
  reqQuery: params => axios.get(`/wecom/agg-chat/chat-msg?${stringify(params)}`)
}

// 活动列表
export const activityList = {
  reqQuery: params => axios.get(`/wecom/group-activity/list?${stringify(params)}`), // 查询表格数据的接口
  changeStatus: params => axios.post('/wecom/agg-chat/change-status', params), // 修改客服状态
  changePasswd: params => axios.post('/wecom/agg-chat/change-passwd', params), // 修改客服密码
  delete: params => axios.post('/wecom/agg-chat/delete', params), // 删除客服账号
  detail: params => axios.post('/wecom/agg-chat/show', params), // 创建客服
  workUrl: params => axios.post('/wecom/agg-chat/work-url', params) // 工作台地址
}
