<!--
 * @Author: zhanln
 * @Date: 2022-03-21 15:46:50
 * @LastEditTime: 2022-04-19 11:53:48
 * @LastEditors: zhanln
 * @Description: 视频号
-->

<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="110px"
    size="medium"
    class="edit-form"
    v-loading="loading"
  >
    <!--  素材名称 -->
    <el-form-item label="素材名称：" prop="name">
      <el-input
        class="edit-form__input"
        v-model.trim="form.name"
        maxlength="20"
        show-word-limit
        placeholder="请输入素材名称"
      ></el-input>
    </el-form-item>

    <!--  分组 -->
    <el-form-item>
      <template slot="label"><span class="q-danger">*</span> 分组：</template>
      <el-select v-model="form.group_id" class="edit-form__input">
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <!--  素材内容 -->
    <el-form-item label="素材内容：" prop="vlog_content">
      <div class="edit-vlog">
        <div
          class="edit-vlog__upload"
          @click="$refs.selectRecord.fn_open(2017)"
          v-if="!form.vlog_content"
        >
          <div class="edit-vlog__upload--icon">
            <i class="el-icon-plus"></i>
          </div>
          <div class="edit-vlog__upload--tip">从聊天记录中选择视频号</div>
        </div>
        <div class="edit-vlog__info" ref="applet" v-else>
          <vlog-card :info="form.vlog_content"></vlog-card>
          <div class="edit-vlog__remove" @click.stop="fn_handleRemove">
            <i class="iconfont icon-delete"></i>
          </div>
        </div>
      </div>
      <select-record ref="selectRecord" @chose="handleChoseApp" />
    </el-form-item>
  </el-form>
</template>

<script>
import { Base64 } from 'js-base64'
import vlogCard from '@/components/material/vlog.vue'
import SelectRecord from './selectRecord.vue'
export default {
  name: 'editText',

  props: {
    hasChange: {
      type: Boolean,
      defualt: false
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentGroupId: {
      type: String,
      default: '0'
    }
  },

  components: {
    vlogCard,
    SelectRecord
  },

  data () {
    return {
      loading: true,
      form: {
        type: 7,
        name: '',
        group_id: '0',
        vlog_content: '',
        content: {}
      },
      rules: {
        name: [
          { required: true, message: '请填写素材名称', trigger: 'change' }
        ],
        vlog_content: [
          { required: true, message: '请选择视频号', trigger: 'change' }
        ]
      },
      isEdit: false,
      editReady: false
    }
  },

  watch: {
    loading (val) {
      if (!val) {
        this.$emit('ready')
      }
    },
    form: {
      handler (val) {
        if ((this.isEdit && this.editReady) || (!this.isEdit && !this.editReady)) {
          this.$emit('update:hasChange', true)
        }
        if (this.isEdit) {
          this.editReady = true
        }
      },
      deep: true
    }
  },

  methods: {
    // 初始化数据
    fn_init (form) {
      if (!form) {
        this.form.group_id = this.currentGroupId === '-1' ? '0' : this.currentGroupId
        this.loading = false
        return
      }

      const detail = this.util.extend(true, {}, form)

      detail.vlog_content = JSON.parse(Base64.decode(detail.content.content))

      this.form = detail
      this.loading = false
      this.isEdit = true
    },

    // 选择视频号回调
    handleChoseApp (app) {
      this.form.vlog_content = app
      this.$refs.formRef.validateField('vlog_content')
    },

    // 移除视频号
    fn_handleRemove () {
      this.form.vlog_content = ''
      this.$refs.formRef.validateField('vlog_content')
    },

    // 表单校验，通过则返回 form
    fn_verify () {
      let formValid = true

      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          formValid = false
        }
      })

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.form)
      form.content.content = Base64.encode(JSON.stringify(form.vlog_content))
      delete form.vlog_content

      return form
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.edit-vlog {
  &__upload {
    width: 146px;
    height: 128px;
    border: 1px dashed #c0ccda;
    background-color: #f7f8fc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.6;
    border-radius: 6px;
    padding: 22px;
    box-sizing: border-box;

    &:hover {
      border-color: $--color-primary;
      cursor: pointer;
    }

    &--icon {
      font-size: 24px;
    }

    &--tip {
      text-align: center;
    }
  }

  &__info {
    width: 246px;
    position: relative;
  }

  &__remove {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border-radius: 0 8px 0 8px;
    background-color: $--color-danger;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    i {
      font-size: 14px;
    }
  }
}
</style>
