var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "iconfont icon-plus",
                      size: "medium"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("edit")
                      }
                    }
                  },
                  [_vm._v("添加客服")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      size: "medium",
                      disabled: !_vm.workUrl
                    },
                    on: { click: _vm.fn_jumpToWork }
                  },
                  [_vm._v("客服工作台")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "account_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      row.account.length > 10
                        ? row.account.slice(0, 10) +
                            "<br />" +
                            row.account.slice(10, row.account.length)
                        : row.account
                    )
                  }
                })
              ]
            }
          },
          {
            key: "group_num",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.fn_showDialog("robots", row, {
                          title: "接入企微号",
                          width: "456px"
                        })
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(row.online_num) +
                        " / " +
                        _vm._s(row.offline_num + row.online_num) +
                        " "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "creator",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("el-image", {
                      staticClass: "create-avatar",
                      attrs: { src: row.avatar, fit: "cover", lazy: "" }
                    }),
                    _c("span", { staticStyle: { flex: "1" } }, [
                      _vm._v(" " + _vm._s(row.creator) + " ")
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 2 },
                  on: {
                    change: function($event) {
                      return _vm.fn_changeStatus($event, row)
                    }
                  },
                  model: {
                    value: row.status,
                    callback: function($$v) {
                      _vm.$set(row, "status", $$v)
                    },
                    expression: "row.status"
                  }
                })
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ly-action",
                  { key: Date.now() },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.fn_showDialog("setting", row, {
                              title: "设置",
                              width: "770px"
                            })
                          }
                        }
                      },
                      [_vm._v("设置")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.fn_showDialog("account", row, {
                              title: "修改账号密码",
                              width: "428px"
                            })
                          }
                        }
                      },
                      [_vm._v("修改账号密码")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.fn_delete(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("agr-dialog", { ref: "agrDialogRef", on: { refresh: _vm.queryData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }