<!--
* 邀请记录
-->
<template>
  <div class="lnvitation">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <div class="temp">
          共邀请了 <span>{{ total }}</span> 名用户，
          <span>{{ expand }}</span> 名助力成功
        </div>
        <div class="cus-nick flex">
          <label>用户昵称：</label>
          <el-input size="medium" clearable v-model="nickname"></el-input>
        </div>
      </template>

      <template #title_slot="{ row }">
        <div class="avatar-name">
          <el-avatar
            class="avatar"
            :size="32"
            :src="row.avatar_url"
          ></el-avatar>
          <span class="name">{{ row.title }}</span>
        </div>
      </template>

      <template #help_info_slot="{ row }">
        <ly-status
          v-if="row.help_info === 1"
          type="success"
          icon="iconfont icon-success"
          >助力成功</ly-status
        >
        <ly-status v-else type="danger" icon="iconfont icon-close-fill">
          <template v-if="row.help_info === 2">老用户</template>
          <template v-if="row.help_info === 3">删除员工</template>
          <template v-if="row.help_info === 4">活动暂停</template>
          <template v-if="row.help_info === 5">账号异常</template>
          助力无效
        </ly-status>
      </template>
    </ly-table>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { lnvitation } from './http.js'
import { debounce } from 'throttle-debounce'
export default {
  mixins: [lyTable],
  props: ['inviterId', 'activityId'],
  data () {
    return {
      COMM_HTTP: lnvitation,
      IMMEDIATE: false,
      per_page: 5,
      nickname: '',
      handleInputDebounce: null,
      expand: 0 // 助力成功数量
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        levitate: false,
        operation: 'hide',
        layout: 'prev, pager, next',
        column: [
          {
            label: '用户昵称',
            prop: 'title',
            slot: 'title_slot'
          },
          {
            label: '助力时间',
            prop: 'created_at',
            formatter (row, column, cellValue, index) {
              return _this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '助力情况',
            prop: 'help_info',
            slot: 'help_info_slot'
          }
        ]
      }
    }
  },
  watch: {
    // inviterId: {
    //   handler (val) {
    //     console.log('val', val)
    //     this.queryData()
    //   },
    //   immediate: true
    // },
    nickname () {
      this.handleInputDebounce()
    }
  },
  mounted () {
    this.handleInputDebounce = debounce(300, this.handleInput)
  },
  methods: {
    // 查询数据
    async queryData () {
      this.loading = true
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqQuery({
        page: this.page,
        per_page: this.per_page,
        ...this.sort,
        ...this.params
      })
      this.loading = false
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.tableData = res.list || res.data || res[this.DATA_LOCATION]
      this.total = res.total
      this.listData = res
      this.expand = res.expand.success_invite
      return res
    },
    handleInput () {
      this.searchChange({ nickname: this.nickname })
    },
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activityId,
        inviter_id: this.inviterId,
        user_type: 0,
        step: 0,
        is_deleted: -1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lnvitation {
  .avatar-name {
    align-items: center;
  }
  ::v-deep {
    .handler-left {
      display: flex;
      align-items: center;
      .temp {
        span {
          color: #2b7cff;
        }
      }
      .cus-nick {
        margin-left: 74px;
      }
      .el-input {
        flex: 1;
        width: 178px;
      }
    }
    .el-table.el-table--medium td {
      vertical-align: middle;
    }
  }
}
</style>
