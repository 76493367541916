/* comform 图片合成
 * 依赖 qrcanvas 安装方法如下：
 * npm install --save qrcanvas
 *
 * 当前插件引用方法：
 * import comform from "@/js/comform";
 */
// import { qrcanvas } from 'qrcanvas';
import {qrcanvas as qrObj } from './qrcanvas.min';

// const {qrcanvas} = qrcanvas

var qrcanvas = qrObj.qrcanvas;

/**
 * 使用方法
 * comform({
        width: 合成之后图片的宽度, // 750
        height: 合成之后图片的高度, // 1334
        sources: [], //sources 以下配置
        load: function() {},
        success(base64) {
            //base64 合成之后的图片;
        }
    });
 * sources = [
     { //绘制图片
        arc: true, //是否把该图片绘制成圆形，该图片必须是正方形,否则会导致该形状异常，默认为false
        image: '绘制图片的地址',
        left: 0, //距离画布的左侧距离
        top: 0, //距离画布的顶部距离
        width: 0, //在画布中的宽度
        height: 0 //在画布中的高度
    }, {
        text: '绘制文字内容、人为换行请使用\n', //绘制文字内容、人为换行请使用\n  |  设置宽度后自动换行
        left: 25, //距离画布的左侧距离
        top: 170, //距离画布的顶部距离
        width: 460, //设置文字的可绘制宽度范围
        height: 300, //设置文字的可绘制高度范围
        lineHeight: 36, //设置文字的可绘制行高
        align: 'left', //left center right
        baseline: 'top', //top  middle  bottom
        fontSize: 16, //文字大小
        fontFamily: 'sans-serif', //字体
        color: 'blue' //文字颜色
    }, {
        code: 'https://wangchunhai.com/', //二维码地址
        left: 0, //距离画布的左侧距离
        top: 0, //距离画布的顶部距离
        width: 100, //在画布中的宽度
        height: 100 //在画布中的高度
    }
 ]
 */

('use strict');
const comform = function (opt) {
	var util = {
		extend: function () {
			var defaults = {};
			var obj,
				k,
				i = 0,
				l = arguments.length;
			for (; i < l; i++) {
				obj = arguments[i];
				if (typeof obj === 'object') {
					for (k in obj) {
						defaults[k] = obj[k];
					}
				}
			}
			return defaults;
		},
		type: (function () {
			var class2type = {},
				toString = class2type.toString;
			var typeName = [
				'Boolean',
				'Number',
				'String',
				'Function',
				'Array',
				'Date',
				'RegExp',
				'Object',
				'Error',
				'Symbol',
			];
			for (var i = 0; i < typeName.length; i++) {
				class2type['[object ' + typeName[i] + ']'] = typeName[i].toLowerCase();
			}
			return function (obj, type) {
				var _type = (function () {
					if (obj == null) {
						return obj + '';
					}
					return typeof obj === 'object' || typeof obj === 'function'
						? class2type[toString.call(obj)] || 'object'
						: typeof obj;
				})();
				if (type) {
					return _type == type;
				} else {
					return _type;
				}
			};
		})(),
		text: function (d) {
			var result = [];
			var point = 0;
			var txts = d.text.split('');
			var txtn = [];
			util.each(txts, function (i, v) {
				point += d.fontSize;
				if (v == '\n' || point >= d.width || i == txts.length - 1) {
					result.push(txtn.join(''));
					txtn = [];
					point = 0;
				}
				v != '\n' && txtn.push(v);
			});
			var cols = (d.height / (d.lineHeight || d.fontSize || 24)) | 0 || 100000;
			return result.slice(0, cols);
		},
		each: function (obj, callback) {
			var length,
				i = 0,
				k;
			if (this.type(obj, 'object')) {
				for (k in obj) {
					if (callback.call(obj[k], k, obj[k]) === false) {
						break;
					}
				}
			} else {
				length = obj.length;
				for (; i < length; i++) {
					if (callback.call(obj[i], i, obj[i]) === false) {
						break;
					}
				}
			}
			return obj;
		},
	};
	var _comform = function (opt) {
		this.opt = util.extend(
			{},
			{
				width: 640,
				height: 1008,
				color: '#fff',
				globalAlpha: 1,
			},
			opt
		);
		this.init();
	};

	var pto = _comform.prototype;

	pto.init = function () {
		this.canvas = document.createElement('canvas');
		this.ctx = this.canvas.getContext('2d');
		this.canvas.width = this.opt.width;
		this.canvas.height = this.opt.height;
		this.draw();
	};

	//重新设置信息 可设置canvas的宽度和高度...
	pto.set = function (a, b) {
		if (!(a == null || a == '')) {
			if (util.type(a, 'string')) {
				this.opt[a] = b;
			} else if (util.type(a, 'object')) {
				if (util.type(a.index, 'number')) {
					this.opt.sources[a.index] = util.extend(this.opt.sources[a.index], a);
				}
			} else if (util.type(a, 'array')) {
				for (var i = 0; i < a.length; i++) {
					this.opt.sources[a[i].index] = util.extend(this.opt.sources[a[i].index], a[i]);
				}
			}
		}
		this.canvas.width = this.opt.width;
		this.canvas.height = this.opt.height;
	};

	pto.draw = function (i, l) {
		if (!i) {
			i = 0;
			l = this.opt.sources.length;
			util.type(this.opt.load, 'function') && this.opt.load(1, 'start');

			//绘制背景颜色
			if (this.opt.color) {
				this.ctx.save();
				this.ctx.globalAlpha = this.getAlpha(this.opt.globalAlpha);
				this.ctx.fillStyle = this.opt.color || '#fff';
				this.ctx.fillRect(0, 0, this.opt.width, this.opt.height);
				this.ctx.restore();
			}
		}
		if (i == l) {
			util.type(this.opt.load, 'function') && this.opt.load(0, 'end');
			try {
				var url = this.canvas.toDataURL('image/png', 1);
				util.type(this.opt.success, 'function') && this.opt.success(url, this.base64ToFile(url));
			} catch (e) {
				new Error(e);
			}
			return;
		}
		if (this.opt.sources[i].image) {
			this.drawImage(this.opt.sources[i], i, l);
		} else if (this.opt.sources[i].text) {
			this.drawText(this.opt.sources[i], i, l);
		} else if (this.opt.sources[i].code) {
			this.drawCode(this.opt.sources[i], i, l);
		} else {
			this.draw(++i, l);
		}
	};
	//绘制图片
	pto.drawImage = function (v, i, l) {
		var that = this;
		var image = new Image();
		image.crossOrigin = 'Anonymous';
		image.src = v.image;
		image.onload = function () {
			if (v.arc) {
				//把图片变成圆形
				that.drawArc(this, v, i, l);
			} else {
				that.drawImg(this, v, i, l);
			}
		};
		image.onerror = function () {
			new Error('Image Address Error "' + v.image + '"');
			that.draw(++i, l);
		};
	};
	//正常图片绘制
	pto.drawImg = function (img, v, i, l) {
		this.ctx.save();
		this.ctx.globalAlpha = this.getAlpha(v.globalAlpha);
		this.ctx.drawImage(img, 0, 0, img.width, img.height, v.left, v.top, v.width, v.height);
		this.ctx.restore();
		this.draw(++i, l);
	};
	//非正常图片绘制，绘制圆形图片
	pto.drawArc = function (img, v, i, l) {
		var canvas = document.createElement('canvas');
		var ctx = canvas.getContext('2d');
		canvas.width = img.width;
		canvas.height = img.height;
		var min = Math.min(img.width, img.height);
		var r = min / 2;
		ctx.fillStyle = ctx.createPattern(img, 'no-repeat');
		ctx.clearRect(0, 0, img.width, img.height);
		ctx.arc(img.width / 2, img.height / 2, r, 0, Math.PI * 2);
		ctx.fill();

		this.ctx.save();
		this.ctx.globalAlpha = this.getAlpha(v.globalAlpha);
		this.ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, v.left, v.top, v.width, v.height);
		this.ctx.restore();
		this.draw(++i, l);
	};
	//绘制文字
	pto.drawText = function (v, i, l) {
		var that = this;

		that.ctx.save();
		that.ctx.font = that.getFont(v);
		that.ctx.textAlign = v.align || 'left';
		that.ctx.textBaseline = v.baseline || 'top';
		that.ctx.fillStyle = v.color || '#000';
		that.ctx.globalAlpha = that.getAlpha(v.globalAlpha);
		if (!v.width || !v.height) {
			that.ctx.fillText(v.text, v.left, v.top);
		} else {
			util.each(util.text(v), function (i, txt) {
				that.ctx.fillText(txt, v.left, v.top + (v.lineHeight || v.fontSize || 24) * i);
			});
		}
		that.ctx.restore();

		that.draw(++i, l);
	};
	//字体编辑
	pto.getFont = function (v) {
		var weight = v.fontWeight == 'bold' ? 'bold ' : '',
			fontSize = v.fontSize || 16,
			fontFamily = v.fontFamily || 'sans-serif';
		return weight + fontSize + 'px ' + fontFamily;
	};
	//链接转二维码
	pto.drawCode = function (v, i, l) {
		if (util.type(qrcanvas, 'undefined')) {
			this.draw(++i, l);
			throw new Error('QRCode is not defined');
		} else {
			var size = Math.min(v.width, v.height);
			var canvas = qrcanvas({
				data: decodeURIComponent(v.code),
				size: size | 0,
				padding: (size * 0.06667) | 0,
			});
			v.image = canvas.toDataURL('image/png', 1);
			this.drawImage(v, i, l);
		}
	};
	//将base64转换成文件
	pto.base64ToFile = function (dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		filename = this.opt.downname || +new Date();
		return new File([u8arr], filename + '.png', {
			type: mime,
		});
	};
	//处理透明度异常
	pto.getAlpha = function (v) {
		var flag = !isNaN(v - parseFloat(v));
		if (flag) {
			if (+v > 1) {
				return 1;
			} else if (+v < 0) {
				return 0;
			} else {
				return +v;
			}
		} else {
			return 1;
		}
	};

	return new _comform(opt);
};

export default comform;
