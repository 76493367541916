<!--
 * @Descripttion: 参与用户页面-pane组件
 * @version: 1.0.0
-->
<template>
  <ly-table
    ref="lyTable"
    :data="tableData"
    :option="tableOpt"
    :page="page"
    :pageSize="per_page"
    :total="total"
    @searchChange="searchChange"
    @sizeChange="handleSizeChange"
    @sortChange="handleSortChange"
    @currentChange="handleCurrentChange"
    @selectionChange="handleSelectionChange"
  >
    <template #handler>
      <el-button
        size="medium"
        round
        icon="iconfont icon-download"
        type="primary"
        v-lydebounce="['button', fn_export]"
        :disabled="!tableData || tableData.length === 0"
        :loading="exportLoading"
        >导出数据</el-button
      >
      <el-button
        v-if="type === '1'"
        type="primary"
        plain
        round
        icon="iconfont icon-plus"
        size="medium"
        :disabled="!selected.length"
        @click="handleAddBlack"
        >加入黑名单</el-button
      >
      <el-button
        v-else
        type="primary"
        plain
        round
        size="medium"
        :disabled="!selected.length"
        @click="handleRemoveBlack"
        >移除黑名单</el-button
      >
    </template>
    <template #user_name_slot="{ row }">
      <div class="avatar-name">
        <el-avatar class="avatar" :size="32" :src="row.avatar_url"></el-avatar>
        <img
          class="sex male"
          v-if="row.sex === 1"
          src="@assets/svg/male.svg"
          alt=""
        />
        <img
          class="sex female"
          v-else-if="row.sex === 2"
          src="@assets/svg/female.svg"
          alt=""
        />
        <div class="name">
          <span>{{ row.name }}</span>
          <span class="nosex" v-if="row.sex === 0 || row.sex === null"
            >未设置性别</span
          >
        </div>
      </div>
    </template>

    <template #tag_names_slot="{ row }">
      <ly-drow :list="row.tag_names" dep></ly-drow>
    </template>

    <template #parent_name_slot="{ row }">
      <ly-tag :content="row.parent_name" only>
        <i class="iconfont icon-superior" slot="left"></i>
      </ly-tag>
    </template>

    <template #join_date_slot="{ row }">
      <div>{{ row.join_date.split(" ")[0] }}</div>
      <div>{{ row.join_date.split(" ")[1] }}</div>
    </template>

    <template #add_account_name_slot="{ row }">
      <ly-drow :list="row.add_account_name"></ly-drow>
    </template>

    <template #add_status_slot="{ row }">
      <template v-if="row.add_status">
        <ly-status type="success" v-if="row.add_status === '已添加'">{{
          row.add_status
        }}</ly-status>
        <ly-status type="danger" v-else>{{ row.add_status }}</ly-status>
      </template>
      <span v-else>-</span>
    </template>
  </ly-table>
</template>
<script>
import { user } from '@/pages/client/redenvelope/http.js'
import lyTable from '@/components/mixins/ly-table.js'
import lyDrow from '@/components/lyDrow'
import lyTag from '@/components/lyTag'
export default {
  mixins: [lyTable],
  props: ['channelOps', 'type', 'activity_id', 'activities'],
  components: {
    lyDrow, lyTag
  },
  data () {
    return {
      COMM_HTTP: user,
      exportLoading: false
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        scrollEl: '.page-warp-bg',
        showSelection: true,
        operation: [
          {
            label: '详情',
            handler (row) {
              _this.$emit('detail', row)
            }
          }
        ],
        operationWidth: 80,
        column: [
          {
            label: '用户昵称',
            prop: 'name',
            key: 'external_user_name',
            search: true,
            placeholder: '请输入用户昵称',
            slot: 'user_name_slot',
            width: 160
          },
          {
            label: '用户类型',
            prop: 'type',
            key: 'external_user_type',
            search: true,
            clearable: 0,
            default: '0',
            type: 'select',
            source: [
              { label: '全部', value: '0' },
              { label: '新用户', value: '1' },
              { label: '老用户', value: '2' }
            ],
            columnProps: {
              minWidth: 110
            },
            align: 'center'
          },
          {
            label: '用户标签',
            prop: 'tag_names',
            slot: 'tag_names_slot',
            width: 200,
            align: 'center'
          },
          {
            label: '来源渠道',
            prop: 'channel_name',
            search: true,
            key: 'channel_id',
            type: 'select',
            default: '',
            source: this.channelOps,
            columnProps: {
              minWidth: 110
            },
            align: 'center'
          },
          {
            label: '直接上级',
            prop: 'parent_name',
            slot: 'parent_name_slot',
            placeholder: '请输入上级昵称',
            columnProps: {
              minWidth: 130
            },
            align: 'center'
          },
          {
            label: '有效邀请人数',
            prop: 'invite_num',
            placeholder: '请输入账号名称',
            sortable: true,
            columnProps: {
              minWidth: 140
            },
            align: 'center'
          },
          {
            label: '领取金额',
            prop: 'get_amount',
            placeholder: '请输入账号名称',
            columnProps: {
              minWidth: 110
            },
            align: 'center'
          },
          {
            label: '参与时间',
            prop: 'join_date',
            search: true,
            key: ['join_date_start', 'join_date_end'],
            type: 'daterange',
            sortable: true,
            slot: 'join_date_slot',
            columnProps: {
              minWidth: 120
            }
          },
          {
            label: '添加账号',
            prop: 'add_account_name',
            key: 'add_account_id',
            search: true,
            type: 'emp',
            slot: 'add_account_name_slot',
            width: 220
          },
          {
            label: '添加状态',
            prop: 'add_status',
            search: true,
            clearable: 0,
            default: '0',
            type: 'select',
            slot: 'add_status_slot',
            source: [
              { label: '全部', value: '0' },
              { label: '已添加', value: '1' },
              { label: '已删除', value: '2' }
            ],
            columnProps: {
              minWidth: 110
            }
          }
        ]
      }
    }
  },
  methods: {
    async queryData () {
      this.$refs.lyTable.$refs.elTable.clearSelection()
      this.$store.dispatch('ADDLOADING')
      // 获取自定义参数
      this.params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const method = this.type === '1' ? 'reqQuery' : 'blReqQuery'
      const res = await this.COMM_HTTP[method]({
        page: this.page,
        per_page: this.per_page,
        ...this.params,
        ...this.sort
      })
      this.$store.dispatch('REMOVELOADING')
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.tableData = res.list || res.data || res[this.DATA_LOCATION] || []
      this.total = res.total
      this.listData = res
      return res
    },
    formatterParams (params) {
      return {
        ...params,
        add_account_id: params.add_account_id,
        activity_id: this.activity_id
      }
    },

    // 导出数据
    async fn_export () {
      this.exportLoading = true
      const params = this.util.extend(true, {}, this.params)
      params.is_black_list = this.type === '1' ? 0 : 1
      const data = await this.COMM_HTTP.export(params)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.exportLoading = false
        return false
      }
      this.handleDownload(data.url)
      this.exportLoading = false
    },

    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },

    getFileName () {
      let _name = ''
      let actName = ''
      actName = this.getActName()
      _name = `[${actName}]-参与用户${this.type === '2' ? '-黑名单' : ''}-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },

    getActName () {
      const _obj = this.activities.find(n => {
        return n.id === this.activity_id
      })
      return _obj.name
    },

    async handleAddBlack () {
      this.$lyConfirm({
        text: '加入黑名单后该用户禁止参与平台其它活动，确定加入黑名单吗？',
        title: '提示',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const join_ids = this.selected.map(e => {
          return e.join_id
        })
        const res = await this.COMM_HTTP.addBlack({ activity_id: this.activity_id, join_ids: join_ids.join(',') })
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('加入黑名单成功！')
        this.queryData()
      }).catch(() => { })
    },
    async handleRemoveBlack () {
      const join_ids = this.selected.map(e => {
        return e.join_id
      })
      const res = await this.COMM_HTTP.removeBlack({ activity_id: this.activity_id, join_ids: join_ids.join(',') })
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.$message.success('移除黑名单成功！')
      this.queryData()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-superior {
  font-size: 13px;
  color: #2b7cff;
  margin-right: 4px;
}
</style>
