var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ly-form", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.cardLoading,
            expression: "cardLoading"
          }
        ],
        ref: "lyFormRef",
        staticClass: "act-form",
        attrs: { formObj: _vm.formObj, form: _vm.formData, rules: _vm.rules },
        on: { setData: _vm.fn_setData },
        scopedSlots: _vm._u([
          {
            key: "wecomTag",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户标签：", prop: "tags" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.formData.add_tags_type,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "add_tags_type", $$v)
                          },
                          expression: "formData.add_tags_type"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("添加即打标签")
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("新老用户场景标签")
                        ])
                      ],
                      1
                    ),
                    _vm.formData.add_tags_type === 1
                      ? _c("tag-select-v2", {
                          attrs: { checked: _vm.formData.tags1 },
                          on: {
                            getTagId: function($event) {
                              return _vm.fn_getTagId($event, "tags1")
                            }
                          }
                        })
                      : [
                          _c("div", [
                            _vm._v(" 新用户："),
                            _c("span", { staticClass: "q-info" }, [
                              _vm._v("添加涨粉账号的新增用户打标签")
                            ])
                          ]),
                          _c("tag-select-v2", {
                            attrs: { checked: _vm.formData.tags2 },
                            on: {
                              getTagId: function($event) {
                                return _vm.fn_getTagId($event, "tags2")
                              }
                            }
                          }),
                          _c("div", { staticClass: "mt-16" }, [
                            _vm._v(" 老用户："),
                            _c("span", { staticClass: "q-info" }, [
                              _vm._v("添加涨粉账号的企业老用户打标签")
                            ])
                          ]),
                          _c("tag-select-v2", {
                            attrs: { checked: _vm.formData.tags3 },
                            on: {
                              getTagId: function($event) {
                                return _vm.fn_getTagId($event, "tags3")
                              }
                            }
                          })
                        ]
                  ],
                  2
                )
              ]
            },
            proxy: true
          },
          {
            key: "rewardType",
            fn: function() {
              return [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "rule-form__radio",
                    model: {
                      value: _vm.formData.reward_type,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "reward_type", $$v)
                      },
                      expression: "formData.reward_type"
                    }
                  },
                  [
                    _c(
                      "el-radio",
                      { staticClass: "mb-8", attrs: { label: 1 } },
                      [
                        _vm._v("活动结束 "),
                        _c("el-input-number", {
                          staticClass: "number-small number-mn",
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 1,
                            max: 10
                          },
                          on: { change: _vm.fn_changeDay },
                          model: {
                            value: _vm.formData.reward_day,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "reward_day", $$v)
                            },
                            expression: "formData.reward_day"
                          }
                        }),
                        _vm._v("天可领取 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              placement: "top",
                              "popper-class": "q-tooltip"
                            }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  " 活动结束后，在指定时间内，已完成阶段任务的用户依然可以添加客服领取奖品 "
                                )
                              ]
                            ),
                            _c("i", { staticClass: "ri-question-line" })
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-radio",
                      { attrs: { label: 2 } },
                      [
                        _vm._v("活动结束不再发放 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              placement: "top",
                              "popper-class": "q-tooltip"
                            }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  " 活动结束后，已完成阶段任务的用户不再发放奖品 "
                                )
                              ]
                            ),
                            _c("i", { staticClass: "ri-question-line" })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }