<!--
 * @Author: zhanln
 * @Date: 2021-08-09 15:50:00
 * @LastEditTime: 2021-10-29 17:40:22
 * @LastEditors: Please set LastEditors
 * @Description:
-->
<template>
  <div class="mobile">
    <div class="mobile-topbar">
      <div>{{ $day().format('HH:mm')}}</div>
      <div class="flex">
        <i class="iconfont icon-a-CellularConnection"></i>
        <i class="iconfont icon-Wifi"></i>
        <i class="iconfont icon-Battery"></i>
      </div>
    </div>
    <div class="mobile-titlebar">
      <i class="iconfont icon-left1"></i>
      <div class="flex">
        <span>群裂变专属福利群(125)</span
        ><img
          src="~@assets/svg/wc_work_ic.svg"
          alt=""
          style="width: 22px; margin-left: 6px"
        />
      </div>
      <i class="el-icon-more"></i>
    </div>
    <div class="mobile-container">
      <div class="mobile-headTip q-info">助力成功提醒预览</div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <el-input
            type="textarea"
            v-model="help_msg"
            autosize
            resize="none"
            readonly
          ></el-input>
        </div>
      </div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <div class="link-preview">
            <div class="ellipsis2 link-preview__title">点击查看助力详情👇</div>
            <div class="flex flex-between flex-center link-preview__content">
              <div class="ellipsis3" style="flex: 1">
                看看是哪位伙伴为我助力了
              </div>
              <div class="link-preview__cover">
                <img
                  src="https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-headTip q-info">奖品推送预览</div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <el-input
            type="textarea"
            v-model="reward_msg"
            autosize
            resize="none"
            readonly
          ></el-input>
        </div>
      </div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <div class="link-preview">
            <div class="ellipsis2 link-preview__title">点击领取你的奖励👇</div>
            <div class="flex flex-between flex-center link-preview__content">
              <div class="ellipsis3" style="flex: 1">领取奖励，再接再厉！</div>
              <div class="link-preview__cover">
                <img
                  src="https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobiNotice',

  computed: {
    help_msg () {
      const msg = this.$store.getters['groupfission/GET_NOTICE'].help_msg
      const previewMsg = '@用户昵称 ' + msg
      return previewMsg
    },
    reward_msg () {
      return '@用户昵称 ' + this.$store.getters['groupfission/GET_NOTICE'].reward_msg
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";

.mobile-headTip {
  text-align: center;
  font-size: 12px;
  margin-top: 24px;
}

.link-preview {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;

  &__content {
    align-items: flex-start;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__cover {
    height: 48px;
    font-size: 48px;
    line-height: 1;
    margin-left: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}
</style>
