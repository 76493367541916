var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "修改分组" : "添加分组",
        visible: _vm.visible,
        width: "480px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-box" },
        [
          _c("label", [_vm._v("分组名称：")]),
          _c("el-input", {
            attrs: {
              size: "medium",
              maxlength: "20",
              "show-word-limit": "",
              placeholder: "请输入分组名称"
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.handleSave }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }