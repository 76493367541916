var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "formRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
        size: "medium"
      }
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "活动名称：", prop: "title" }
        },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "16",
              "show-word-limit": "",
              placeholder: "请输入活动名称"
            },
            model: {
              value: _vm.form.title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "结束时间：", prop: "end_time" }
        },
        [
          _c("el-date-picker", {
            attrs: {
              type: "datetime",
              placeholder: "请选择结束时间",
              "picker-options": _vm.endDatePickerOptions,
              "value-format": "yyyy-MM-dd HH:mm:ss"
            },
            model: {
              value: _vm.form.end_time,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "end_time",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.end_time"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "活动首图：", prop: "bg_url" }
        },
        [
          _c("div", { staticClass: "q-info text-small" }, [
            _vm._v(
              " 建议尺寸:680*800像素，大小不超过2MB，格式为JPG/GIF/PNG/JPEG "
            )
          ]),
          _c("ly-upload", {
            attrs: {
              img: _vm.form.bg_url,
              tipText: "活动首图",
              accept: ".png,.jpg,.jpeg,.gif"
            },
            on: {
              getImg: function($event) {
                return _vm.fn_getImg($event, "bg_url")
              }
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "活动封面图：", prop: "show_url" }
        },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm._v("活动封面图 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    placement: "top",
                    "popper-class": "q-tooltip"
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 活动封面图将展示在小程序活动中心“ 首页” 进行推广 ")
                  ]),
                  _c("i", {
                    staticClass: "iconfont icon-info-fill",
                    staticStyle: { position: "relative", top: "1px" }
                  })
                ]
              ),
              _vm._v("： ")
            ],
            1
          ),
          _c("div", { staticClass: "q-info text-small" }, [
            _vm._v(" 建议尺寸:680*450像素，大小不超过2MB，格式为JPG/PNG/JPEG ")
          ]),
          _c("ly-upload", {
            attrs: {
              img: _vm.form.show_url,
              tipText: "活动封面图",
              accept: ".png,.jpg,.jpeg"
            },
            on: {
              getImg: function($event) {
                return _vm.fn_getImg($event, "show_url")
              }
            }
          })
        ],
        2
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "直播预告：", prop: "vn_id" }
        },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "60",
              "show-word-limit": "",
              placeholder: "请输入视频号ID"
            },
            model: {
              value: _vm.form.vn_id,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "vn_id",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.vn_id"
            }
          }),
          _c(
            "p",
            {
              staticClass: "q-info text-small",
              staticStyle: { "line-height": "1.6", "margin-bottom": "0px" }
            },
            [
              _vm._v(" 视频号ID必须与小程序主体一致，否则将无法跳转 "),
              _c(
                "span",
                {
                  staticClass: "q-primary",
                  staticStyle: { "margin-left": "24px", cursor: "help" },
                  on: { click: _vm.fn_toDoc }
                },
                [_vm._v("如何获取视频号ID？")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item",
          attrs: { label: "预约按钮：", prop: "btn_title" }
        },
        [
          _c("el-input", {
            staticClass: "act-form__input",
            attrs: {
              maxlength: "8",
              "show-word-limit": "",
              placeholder: "请输入预约按钮文字"
            },
            model: {
              value: _vm.form.btn_title,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "btn_title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.btn_title"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "basic-form__item is-long",
          attrs: { label: "更多直播预告：" }
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "12px", "margin-bottom": "12px" },
              attrs: {
                round: "",
                type: "primary",
                size: "small",
                icon: "iconfont icon-plus"
              },
              on: {
                click: function($event) {
                  return _vm.fn_openEditNotice(null)
                }
              }
            },
            [_vm._v("新增")]
          ),
          _c("span", { staticClass: "q-info text-small" }, [
            _vm._v("新增更多直播预告，增加直播曝光率")
          ]),
          _vm.form.notice.length
            ? _c(
                "el-table",
                {
                  staticClass: "group-table",
                  attrs: { data: _vm.form.notice, "max-height": "380" }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "排序", width: "68" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "直播间名称",
                      "min-width": "90"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "open_time",
                      label: "开播时间",
                      width: "98"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$day(scope.row.open_time)
                                      .format("YYYY-MM-DD HH:mm")
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1034524620
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "poster_url",
                      label: "预告首图",
                      "min-width": "80"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("ly-upload", {
                                attrs: { img: scope.row.poster_url, mini: "" },
                                on: {
                                  getImg: function($event) {
                                    return _vm.fn_changeRow(
                                      $event,
                                      scope.row,
                                      "poster_url"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1990362657
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "qrcode_url",
                      label: "预告二维码",
                      "min-width": "80"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("ly-upload", {
                                attrs: {
                                  img: scope.row.qrcode_url,
                                  mini: "",
                                  cropper: ""
                                },
                                on: {
                                  getImg: function($event) {
                                    return _vm.fn_changeRow(
                                      $event,
                                      scope.row,
                                      "qrcode_url"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1630485982
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "80",
                      "class-name": "operation-cell"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-dropdown",
                                {
                                  staticClass: "el-dropdown-more",
                                  attrs: { placement: "bottom-start" },
                                  on: {
                                    command: function(e) {
                                      return _vm.fn_changeGroup(
                                        scope.$index,
                                        e,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [_c("i", { staticClass: "el-icon-more" })]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "edit" } },
                                        [_vm._v("编辑")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            disabled: scope.$index === 0,
                                            command: "up"
                                          }
                                        },
                                        [_vm._v("上移")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: "down",
                                            disabled:
                                              scope.$index ===
                                              _vm.form.notice.length - 1
                                          }
                                        },
                                        [_vm._v("下移 ")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "del" } },
                                        [_vm._v("移除")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1912407556
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("add-notice", {
        ref: "addNoticeRef",
        on: { noticeEdit: _vm.fn_noticeEdit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }