<!--
 * @Author: zhanln
 * @Date: 2021-10-12 11:54:44
 * @LastEditTime: 2021-12-16 10:31:04
 * @LastEditors: zhanln
 * @Description: 渠道分析
-->

<template>
  <alys-card :title="title">
    <div
      v-if="!actId"
      class="ly-table-empty flex flex-center"
      style="height: 336px; box-sizing: border-box"
    >
      <img src="@assets/svg/default/no_data.svg" alt="" />
      暂无数据
    </div>

    <div class="alys-channel" v-else>
      <div class="alys-channel__row">
        <div class="alys-channel__item">
          <div class="alys-channel__header">
            <div class="alys-channel__title">
              <i class="badge"></i>渠道入群用户
            </div>
          </div>

          <!-- 渠道饼图 -->
          <c-pie :option="pieOption" ref="pieRef" :colorLoop="true"></c-pie>
        </div>
        <div class="alys-channel__item">
          <div class="alys-channel__header">
            <div class="alys-channel__title">
              <i class="badge"></i>渠道留存用户
            </div>
          </div>

          <!-- bar -->
          <c-bar :option="stayOptions" ref="barRef" :colorLoop="true"></c-bar>
        </div>
      </div>
      <div class="alys-channel__item">
        <div class="alys-channel__header mb-16">
          <div class="alys-channel__title">
            <i class="badge"></i>渠道详情数据
          </div>
          <ly-export
            :exportData="exportData"
            :exportName="exportName"
            :exportSheetName="exportSheetName"
            dateFormat="YYYY年MM月DD日HH时mm分"
            v-if="tableData.length > 0"
          ></ly-export>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" style="width: 100%" max-height="500">
          <el-table-column
            :prop="item.prop"
            v-for="(item, index) in tableHead"
            :min-width="item.width || ''"
            :fixed="item.fixed"
            :key="index"
          >
            <template slot="header">
              {{ item.label }}
              <el-tooltip
                v-if="item.tip"
                class="item"
                effect="dark"
                placement="top"
                :content="item.tip"
                popper-class="q-tooltip"
              >
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty" class="ly-table-empty">
            <slot name="empty">
              <img src="@assets/svg/default/no_data.svg" alt="" />
              暂无数据
            </slot>
          </div>
        </el-table>
      </div>
    </div>
  </alys-card>
</template>

<script>
import alysCard from './card'
import cPie from '@/components/lyChart/pie'
import cBar from '@/components/lyChart/bar'
import lyExport from '@/components/lyExport' // 本地导出excel
import { analyse } from '../http'
export default {
  name: 'alysChannel',

  props: {
    actId: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    actName: {
      type: String,
      default: ''
    },
    actIndex: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      COMM_HTTP: analyse,
      pieOption: {
        tooltip: {
          formatter: function (params) {
            return `渠道: ${params.name}<br/>${params.marker}入群用户：${params.value}<br/>占比：${params.percent}%`
          }
        },
        series:
        {
          radius: ['40%', '70%'],
          label: {
            formatter: '{name|{b}}\n{rate|占比：}{time|{d}%}',
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            rich: {
              name: {
                fontSize: 14,
                lineHeight: 22,
                fontWeight: 500,
                color: '#212b36'
              },
              rate: {
                fontSize: 14,
                lineHeight: 22,
                fontWeight: 400,
                color: '#858585'
              },
              time: {
                fontSize: 14,
                lineHeight: 22,
                fontWeight: 500,
                color: '#212b36'
              }
            }
          }
        }
      },
      stayOptions: {
        yAxis: {
          minInterval: 1
        },
        tooltip: {
          formatter: function (params) {
            return `渠道：${params[0].axisValue}<br/>${params[0].marker}留存用户：${params[0].value}`
          }
        },
        series: {
          radius: ['20%', '50%']
        }
      },

      tableHead: [
        {
          label: '渠道',
          prop: 'title',
          width: 140,
          fixed: true
        },
        {
          label: '访问用户',
          prop: 'visitor_num',
          width: 120
        }, {
          label: '入群用户',
          prop: 'in_group_num',
          width: 120
        }, {
          label: '退群用户',
          prop: 'out_group_num',
          width: 120
        }, {
          label: '入群率',
          tip: '入群率=入群用户/访问用户',
          prop: 'in_group_rate',
          width: 120
        }, {
          label: '有效推广用户',
          tip: '渠道助力人数>1的有效用户',
          prop: 'active_num',
          width: 140
        }, {
          label: '裂变层级',
          prop: 'max_lv',
          width: 120
        }, {
          label: '裂变率',
          tip: '裂变率=有效推广用户/入群用户',
          prop: 'fission_rate',
          width: 120
        }, {
          label: '一阶完成任务人数',
          tip: '完成第一阶段任务的用户数',
          prop: 'first_num',
          width: 160
        }, {
          label: '一阶任务完成率',
          tip: '一阶完成用户数/参与用户数',
          prop: 'first_rate',
          width: 140
        }, {
          label: '二阶完成任务人数',
          tip: '完成第二阶段任务的用户数',
          prop: 'second_num',
          width: 160
        }, {
          label: '二阶任务完成率',
          tip: '二阶完成用户数/参与用户数',
          prop: 'second_rate',
          width: 140
        }, {
          label: '三阶完成任务人数',
          tip: '完成第三阶段任务的用户数',
          prop: 'third_num',
          width: 160
        }, {
          label: '三阶任务完成率',
          tip: '三阶完成用户数/参与用户数',
          prop: 'third_rate',
          width: 140
        }
      ],
      tableData: [],
      exportName: '',
      exportSheetName: '',
      exportData: []
    }
  },

  watch: {
    actIndex (val) {
      if (val) {
        this.fn_getData()
      }
    }
  },

  methods: {

    async fn_getData () {
      const data = await this.COMM_HTTP.channel({
        activity_id: this.actId
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      if (!data) return false

      // 按参与用户排序
      this.tableData = data.list.map(item => {
        item.in_group_rate = item.in_group_rate === '0.00' ? 0 : item.in_group_rate + '%'
        item.fission_rate = item.fission_rate === '0.00' ? 0 : item.fission_rate + '%'
        item.first_rate = item.first_rate === '0.00' ? 0 : item.first_rate + '%'
        item.second_rate = item.second_rate === '0.00' ? 0 : item.second_rate + '%'
        item.third_rate = item.third_rate === '0.00' ? 0 : item.third_rate + '%'
        return item
      }).sort(function (a, b) {
        return b.account_num - a.account_num
      })

      this.fn_setData(this.tableData)
      this.fn_initExport()
    },

    // 获取数据
    fn_setData (data) {
      const barObj = []
      const pieObj = {
        series: [
          {
            data: []
          }
        ]
      }
      data.forEach(item => {
        barObj.push({
          name: item.title,
          value: item.keep_num
        })
        pieObj.series[0].data.push({
          value: item.in_group_num,
          name: item.title
        })
      })

      this.$refs.barRef.setOption(barObj)
      this.$refs.pieRef.setOption(pieObj)
    },

    // 导出数据处理
    fn_initExport () {
      this.exportData = [this.tableHead.map(item => item.label)]

      this.tableData.forEach((item, index) => {
        const tbody = []
        this.tableHead.forEach(tItem => {
          tbody.push(item[tItem.prop])
        })
        this.exportData.push(tbody)
      })

      this.exportSheetName = this.actName + '-渠道详情'
      this.exportName = this.actName + '-渠道详情-'
    }
  },

  components: {
    alysCard,
    cPie,
    cBar,
    lyExport
  }
}
</script>

<style lang="scss" scoped>
.alys {
  &-channel {
    &__row {
      display: flex;
    }

    &__item {
      flex: 1;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
      color: #1d2a36;

      .badge {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(51, 139, 255, 0.3);
        margin-right: 8px;
      }
    }
  }
}
</style>
