<template>
  <el-main class="page-warp">
    <div class="edit-main">
      <div class="edit-card">
        <div class="edit-card__content">
          <el-form
            :model="addRoleForm"
            :rules="rules"
            ref="addRoleForm"
            label-width="120px"
            size="medium"
          >
            <el-form-item label="角色名称" prop="name">
              <div class="flex">
                <div class="flex-left">
                  <el-input
                    v-model="addRoleForm.name"
                    placeholder="请输入角色名称"
                    maxlength="10"
                    show-word-limit
                  ></el-input>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="数据范围" prop="mask">
              <template slot="label">
                数据范围
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="maskTips"
                  placement="top-start"
                  popper-class="q-tooltip"
                >
                  <i class="iconfont icon-info-fill"></i>
                </el-tooltip>
              </template>
              <div class="flex">
                <div class="flex-left">
                  <el-select
                    v-model="addRoleForm.mask"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="editType === 'edit'"
                    @change="fn_maskChange"
                  >
                    <el-option
                      v-for="item in maskOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="角色描述" prop="desc">
              <div class="flex">
                <div class="flex-left">
                  <el-input
                    type="textarea"
                    v-model="addRoleForm.desc"
                    placeholder="请输入角色描述，留空则不显示"
                    rows="4"
                    maxlength="100"
                    resize="none"
                    show-word-limit
                  ></el-input>
                </div>
              </div>
            </el-form-item>
            <div class="form-title">
              <span>功能权限</span>
            </div>
            <el-form-item
              label="已有角色"
              style="margin-bottom: 12px"
              v-if="editType !== 'edit'"
            >
              <div class="flex">
                <div class="flex-left">
                  <el-cascader
                    :options="roleList"
                    :props="roleListProps"
                    ref="roleCascader"
                    style="width: 100%"
                    placeholder="可根据现有角色快速设置新角色的功能权限"
                    filterable
                    @change="fn_changeCascader"
                  ></el-cascader>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="app_ids" label-width="32px">
              <div class="flex">
                <div class="flex-left is-block">
                  <el-tree
                    :data="allAsyncRoutes"
                    show-checkbox
                    default-expand-all
                    :props="defaultProps"
                    ref="tree"
                    node-key="id"
                    @check="fn_handleCheck"
                    @check-change="fn_handleCheckChange"
                    class="role-tree"
                    v-loading="treeLoading"
                  >
                    <div
                      :class="{ 'custom-tree-node': data.kind === 2 }"
                      slot-scope="{ node, data }"
                    >
                      <div>
                        {{ node.label }}
                        <span
                          class="q-info"
                          style="margin-left: 8px"
                          v-if="
                            (node.label === '客户管理' && !node.checked) ||
                            (node.label === '群管理' && !node.checked)
                          "
                          ><span class="q-danger">*</span
                          >未勾选此基础功能可能导致其他问题</span
                        >
                      </div>
                      <el-checkbox-group
                        v-model="checkList"
                        class="role-checkbox"
                      >
                        <el-checkbox
                          :label="item.id"
                          v-for="item in data.actionList"
                          :key="item.id"
                          :data-pid="data.id"
                          :data-id="item.id"
                          @change="fn_changeRoleCheckbox(data, item)"
                          >{{ item.name }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                  </el-tree>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="edit-footer">
      <el-button
        type="primary"
        @click="fn_submitForm('addRoleForm')"
        :loading="addState"
        round
        v-if="editType !== 'edit'"
        >立即创建</el-button
      >
      <el-button
        type="primary"
        round
        @click="fn_submitForm('addRoleForm')"
        :loading="addState"
        v-else
        >保存</el-button
      >
      <el-button
        round
        @click="fn_resetForm('addRoleForm')"
        v-if="editType !== 'edit'"
        >重置</el-button
      >
    </div>
  </el-main>
</template>

<script>
import allAsyncRoutes from '@/router/routes'
export default {
  data () {
    return {
      addRoleForm: {
        name: '',
        desc: '',
        app_ids: [],
        mask: 0
      },
      maskOptions: [
        {
          value: 0,
          label: '个人'
        }, {
          value: 2,
          label: '部门'
        }, {
          value: 1,
          label: '全局'
        }
      ],
      maskTips: '可查看自己的客户、活动和群信息',
      addState: false,
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 0, max: 10, message: '长度在 0 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ],
        app_ids: [
          { required: true, message: '请选择功能权限', trigger: 'change' }
        ],
        mask: [
          { required: true, message: '请选择数据权限', trigger: 'change' }
        ]
      },
      checkList: [],
      apps: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      roleId: '',
      editType: '',
      roleList: [],
      roleListProps: {
        multiple: true,
        label: 'name',
        value: 'id',
        emitPath: false
      },
      treeLoading: true,
      checkRoleIds: [],
      actions: []
    }
  },
  computed: {
    allAsyncRoutes () {
      return allAsyncRoutes.map(e => {
        e.children.map(m => {
          m.children = m.children.filter(j => j.is_menu !== 0)
          return m
        })
        return e
      })
    }
  },
  async mounted () {
    await this.fn_getApps()
    const _id = this.$route.query.id
    if (_id) {
      this.editType = 'edit'
      this.roleId = _id
      const data = await this.fn_getRoleInfo(_id)
      this.addRoleForm.name = data.name
      this.addRoleForm.desc = data.desc
      this.addRoleForm.mask = data.mask
      this.fn_setTreeCheck(data.app_ids)
    } else {
      this.fn_getRoleList()
    }
    this.treeLoading = false
  },
  methods: {
    // 应用列表
    async fn_getApps () {
      const data = await this.axios.get('apps', {
        params: {
          platform: 3
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.reload = false
        return
      }

      if (data) {
        const _aciontList = []
        const _apps = []
        const _actions = []

        for (let i = 0; i < data.length; i++) {
          _apps.push({
            id: data[i].id,
            name: data[i].name
          })
          if (data[i].children) {
            for (let j = 0; j < data[i].children.length; j++) {
              _apps.push({
                id: data[i].children[j].id,
                name: data[i].children[j].name
              })
              if (data[i].children[j].children) {
                for (let k = 0; k < data[i].children[j].children.length; k++) {
                  _apps.push({
                    id: data[i].children[j].children[k].id,
                    name: data[i].children[j].children[k].name
                  })
                  if (data[i].children[j].children[k].children) {
                    for (let n = 0; n < data[i].children[j].children[k].children.length; n++) {
                      _actions.push({
                        name: data[i].children[j].children[k].children[n].name,
                        id: data[i].children[j].children[k].children[n].id
                      })
                    }
                    data[i].children[j].children[k].actionList = data[i].children[j].children[k].children
                    _aciontList.push(data[i].children[j].children[k].children)
                    delete data[i].children[j].children[k].children
                  }
                }
              }
            }
          }
        }

        this.apps = _apps
        this.actions = _actions

        const _list = _aciontList.flat()

        this.actionList = _list.map(item => {
          return item.id
        })

        this.treeData = data
      }
    },

    // 获取角色列表
    async fn_getRoleList () {
      this.loading = true
      const data = await this.axios.get('rolesList', {
        params: {
          page: 1,
          per_page: 9999
        }
      })

      // 列表中移除超级管理员
      const _list = []
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].id !== 4) {
          _list.push(data.data[i])
        }
      }

      this.roleList = _list
    },

    // 范围选择监听
    fn_maskChange (val) {
      if (val === 0) {
        this.maskTips = '可查看自己的客户、活动和群信息'
      } else if (val === 1) {
        this.maskTips = '可查看所有的客户、活动和群信息'
      } else if (val === 2) {
        this.maskTips = '可查看部门内的客户、活动和群信息'
      }
    },

    // 选择已有角色
    async fn_changeCascader (val) {
      const { id, type } = this.fn_intersection(val, this.checkRoleIds)
      const _idList = await this.fn_getAppIds(id)

      if (type === 'add') {
        this.fn_setTreeCheck(_idList)
      } else if (type === 'remove') {
        this.fn_setTreeCheck(_idList, false)
      }

      this.checkRoleIds = val
    },

    // 获取选中已有角色权限
    async fn_getAppIds (ids) {
      if (ids.length > 0) {
        const data = await this.axios.get('rolesAppIds', {
          params: {
            ids: ids.join(',')
          }
        })

        if (data) {
          return data.ids
        }
      } else {
        return []
      }
    },

    // 添加选中节点
    fn_setTreeCheck (data, add = true) {
      let _list = []
      const $tree = this.$refs.tree
      const _checkList = $tree.getCheckedKeys(true)

      // 设置操作功能
      const _data = []
      for (let i = 0; i < data.length; i++) {
        const _index = this.actionList.indexOf(data[i])
        if (_index > -1) {
          this.checkList.push(data[i])
        } else {
          _data.push(data[i])
        }
      }

      const _data2 = []
      // 移除已选节点
      for (let i = 0; i < _data.length; i++) {
        const _index = _checkList.indexOf(_data[i])
        if (add) {
          if (_index === -1) {
            _data2.push(_data[i])
          }
        } else {
          if (_index > -1) {
            _data2.push(_data[i])
          }
        }
      }

      // 仅设置叶子节点
      for (let i = 0; i < data.length; i++) {
        const _node = $tree.getNode(data[i])
        if (_node && _node.isLeaf) {
          _list.push(_node.key)
        }
      }

      if (add) {
        _list = _list.concat(_checkList)
        for (let i = 0; i < _list.length; i++) {
          this.fn_setTreeData(_list)
        }
      } else {
        for (let i = 0; i < _list.length; i++) {
          $tree.setChecked(_list[i], false)
        }
        this.fn_handleCheckChange(_list, false)
      }
    },

    // 数组交集，return 添加或删除的角色id
    fn_intersection (a, b) {
      const _a = new Set(a)
      const _b = new Set(b)

      const id = []
      let type = null

      if (a.length > b.length) {
        type = 'add'

        for (const item of _a) {
          if (!_b.has(item)) {
            id.push(item)
          }
        }
      } else {
        type = 'remove'

        for (const item of _b) {
          if (!_a.has(item)) {
            id.push(item)
          }
        }
      }

      return { id, type }
    },

    // 树赋值
    fn_setTreeData (data, add = true) {
      for (let i = 0; i < data.length; i++) {
        this.$refs.tree.setChecked(data[i], true, false)
      }
      this.fn_handleCheck()
    },

    // 应用选择监听
    fn_handleCheck (data, node) {
      const _list = this.$refs.tree.getCheckedNodes(false, true)
      const _checkList = []
      if (_list.length > 0) {
        for (let i = 0; i < _list.length; i++) {
          _checkList.push(_list[i].id)
        }
      }
      this.addRoleForm.app_ids = _checkList
      this.$refs.addRoleForm.validateField('app_ids')
    },

    // 数选项选中监听（页面取消选择，则操作菜单全部取消
    fn_handleCheckChange (data, check) {
      if (!check) {
        if (data.name === '参与客户' || data.name === '奖品记录') {
          for (let i = 0; i < this.actions.length; i++) {
            if (data.name === this.actions[i].name && this.checkList.indexOf(this.actions[i].id) > -1) {
              const _index = this.checkList.indexOf(this.actions[i].id)
              this.checkList.splice(_index, 1)
              break
            }
          }
        }

        const _checkNodes = document.querySelectorAll(`[data-pid="${data.id}"]`)
        for (let i = 0; i < _checkNodes.length; i++) {
          const _index = this.checkList.indexOf(+_checkNodes[i].dataset.id)
          if (_index > -1) {
            this.checkList.splice(_index, 1)
            i--
          }
        }
      }
    },

    // 表单验证
    fn_submitForm (formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addState = true
          if (this.editType === 'edit') {
            _this.fn_saveRole()
          } else {
            _this.fn_addRole()
          }
        } else {
          return false
        }
      })
    },

    // 添加角色
    async fn_addRole () {
      this.addRoleForm.app_ids = this.addRoleForm.app_ids.concat(this.checkList)
      const data = await this.axios.post('roles', {
        ...this.addRoleForm
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.addState = false
        return false
      }

      this.fn_goBack()
      this.$message.success('添加角色成功')

      this.addState = false
    },

    // 选中操作功能默认勾选其父级页面
    fn_changeRoleCheckbox (data, item) {
      if (item.name === '参与客户' || item.name === '奖品记录') {
        for (let i = 0; i < this.apps.length; i++) {
          if (this.apps[i].name === item.name) {
            this.fn_setTreeData([this.apps[i].id])
          }
        }
      }
      const _node = this.$refs.tree.getNode(data.id)
      if (!_node.checked) {
        this.fn_setTreeData([data.id])
      }
    },

    // 获取角色信息
    async fn_getRoleInfo (id) {
      const data = await this.axios.get('roles', {
        params: {
          id
        }
      })
      return data
    },

    // 保存信息
    async fn_saveRole () {
      this.addRoleForm.app_ids = this.addRoleForm.app_ids.concat(this.checkList)
      this.addRoleForm.role_id = +this.roleId
      await this.axios.put('roles', this.addRoleForm)
      this.fn_goBack()
      this.$message.success('保存成功')
    },

    // 表单重置
    fn_resetForm (formName) {
      this.$refs.tree.setCheckedKeys([])
      if (this.editType !== 'edit') {
        this.$refs.roleCascader.panel.clearCheckedNodes()
      }
      this.actionList = []
      this.$refs[formName].resetFields()
    },

    // 返回列表
    fn_goBack () {
      this.$refs.tree.setCheckedKeys([])
      this.fn_resetForm('addRoleForm')
      this.$router.push('/enterprise/employees/auth')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";
@import "@/assets/scss/layout/table.scss";
.page-warp {
  padding-bottom: 75px;
}
.edit-card__content {
  padding: 0 !important;
  height: 100%;
  background: #fff;
}
.edit-card {
  height: 100%;
}
.edit-main {
  margin-top: 0;
  height: auto;
  box-shadow: 0px 16px 32px -4px rgb(145 158 171 / 24%),
    0px 0px 2px 0px rgb(145 158 171 / 24%);
  border-radius: 16px;
  ::v-deep {
    .el-input {
      width: 100%;
    }
  }
}
.el-form {
  padding: 20px 0;
  ::v-deep {
    .el-form-item:last-of-type {
      margin-bottom: 0;
    }
  }
}
.role-tree {
  ::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
    margin-top: 6px;
    margin-left: -6px;
  }

  ::v-deep .el-tree-node__content {
    height: auto;
    align-items: flex-start;
  }
}

.role-checkbox {
  ::v-deep .el-checkbox {
    float: left;
    width: 40%;
  }
}

.flex-left {
  width: 418px;

  &.is-block {
    border: 1px solid #dfe1e8;
    width: 508px;
    height: 420px;
    overflow: auto;
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;
  }
}

.flex-right {
  margin-left: 16px;
}

.form-title {
  margin-bottom: 12px;

  span {
    display: inline-block;
    width: 100px;
    margin-left: 2em;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    color: $--color-text-primary;
  }
}
</style>
