<!--
 * @Author: zhanln
 * @Date: 2022-03-21 11:47:26
 * @LastEditTime: 2022-04-19 16:12:01
 * @LastEditors: zhanln
 * @Description: 编辑素材
-->

<template>
  <el-dialog
    :title="title"
    :width="width"
    :top="top"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="fn_cancle"
    @closed="fn_closed"
    append-to-body
  >
    <div
      class="edit-form"
      v-if="type"
      :is="`ed-${type}`"
      :ref="`${type}Ref`"
      :hasChange.sync="hasChange"
      :groupList="groupList"
      :currentGroupId="currentGroupId"
      @ready="fn_ready"
      @setting="fn_setting"
    ></div>

    <span slot="footer" class="dialog-footer">
      <el-button
        round
        @click="fn_cancle(null)"
        :disabled="loading || btnLoading"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        @click="fn_submit"
        :disabled="loading"
        :loading="btnLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import edText from './forms/edText'
import edImage from './forms/edImage'
import edVideo from './forms/edVideo'
import edFile from './forms/edFile'
import edApplet from './forms/edApplet'
import edVlog from './forms/edVlog'
import edLive from './forms/edLive'
import edArticle from './forms/edArticle'
import { saveApi, groupApi, meterialApi } from './http'
export default {

  components: {
    edText, edImage, edVideo, edFile, edApplet, edVlog, edLive, edArticle
  },

  props: ['currentGroupId'],

  inject: ['category', 'fn_getData', 'fn_changeGroup'],

  data () {
    return {
      saveApi: saveApi,
      groupApi: groupApi,
      meterialApi: meterialApi,
      groupList: [],
      loading: true,
      visible: false,
      btnLoading: false,
      type: '',
      title: '',
      width: '',
      top: '15vh',
      form: null,
      hasChange: false
    }
  },

  methods: {

    // 打开弹框
    async fn_open (opt) {
      this.type = opt.type
      const { title, width, top } = this.fn_getTypeName(opt)
      this.title = title
      this.width = width
      this.top = top

      await this.fn_getGroupData()

      if (opt.material_id) {
        this.form = await this.fn_getDetail(opt.type, opt.material_id)
        this.form.material_id = opt.material_id
      }

      this.visible = true

      this.$nextTick(() => {
        if (this.$refs[`${this.type}Ref`]) {
          this.$refs[`${this.type}Ref`].fn_init(this.form)
        } else {
          this.fn_lyMsg('warning', `未找到 ${this.type} 类型！`)
        }
      })
    },

    // 分组数据
    async fn_getGroupData (id) {
      const data = await this.groupApi.groupList({
        category: this.category
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.groupList = data.list.map(item => {
        item.label = item.name || '默认分组'
        item.value = item.group_id
        return item
      })
    },

    // 设置loading
    fn_ready () {
      this.loading = false
    },

    // 标题, 宽度
    fn_getTypeName ({ material_id, type }) {
      let typeName = ''
      let width = ''
      let top = '15vh'
      switch (type) {
        case 'text':
          typeName = '文本'
          width = '672px'
          break
        case 'image':
          typeName = '图片'
          width = '512px'
          break
        case 'video':
          typeName = '视频'
          width = '512px'
          break
        case 'article':
          typeName = '文章'
          width = '672px'
          break
        case 'file':
          typeName = '文件'
          width = '512px'
          break
        case 'applet':
          typeName = '小程序'
          width = '636px'
          top = '5vh'
          break
        case 'vlog':
          typeName = '视频号'
          width = '512px'
          break
        case 'live':
          typeName = '视频号直播'
          width = '672px'
          break
      }
      if (material_id) {
        return {
          title: `编辑${typeName}`,
          width,
          top
        }
      }
      return {
        title: `添加${typeName}`,
        width,
        top
      }
    },

    // 获取详情
    async fn_getDetail (type, material_id) {
      let typeApi = 'textDetail'
      if (type === 'image' || type === 'video' || type === 'file') {
        typeApi = 'fileDetail'
      }

      if (type === 'applet') {
        typeApi = 'appletDetail'
      }

      if (type === 'article') {
        typeApi = 'articleDetail'
      }

      if (type === 'vlog') {
        typeApi = 'vlogDetail'
      }

      const data = await this.meterialApi[typeApi]({
        material_id
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      return data
    },

    // 弹框设置
    fn_setting (opt) {
      this.width = opt.width || this.width
      this.top = opt.top || this.top
    },

    // 提交表单
    async fn_submit () {
      const form = this.$refs[`${this.type}Ref`].fn_verify()
      if (!form) return
      this.btnLoading = true

      form.category = this.category

      let typeApi = 'text'
      if (form.type === 2 || form.type === 3 || form.type === 4) {
        typeApi = 'file'
      }

      if (form.type === 5) {
        typeApi = 'applet'
      }

      if (form.type === 6) {
        typeApi = 'article'
      }

      if (form.type === 7) {
        typeApi = 'vlog'
      }

      const data = await this.saveApi[typeApi](form)
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.btnLoading = false
        return false
      }

      this.fn_lyMsg('success', '素材保存成功！')

      // 如果添加的素材是当前所在分组则只需要刷新tab数据，否则刷新全部
      const typeArr = ['text', 'image', 'video', 'file', 'applet', 'article', 'vlog', 'live']
      if (this.currentGroupId === form.group_id) {
        this.fn_changeGroup(this.currentGroupId, typeArr[form.type - 1])
      } else {
        this.fn_getData(this.currentGroupId, typeArr[form.type - 1])
      }
      this.btnLoading = false
      this.visible = false
    },

    // 关闭弹框确认
    fn_cancle (done) {
      if (!this.hasChange) {
        this.visible = false
        return
      }
      this.$lyConfirm({
        title: '确认离开此页面？',
        text: '取消当前页面编辑后，数据将不会保存，是否确认取消？'
      }).then(() => {
        if (done) {
          done()
        } else {
          this.visible = false
        }
      }).catch(() => { })
    },

    // 关闭弹框，清空数据
    fn_closed () {
      this.type = ''
      this.form = null
      this.hasChange = false
    }
  }

}
</script>

<style lang="scss" scoped>
.edit-form {
  ::v-deep .el-form-item {
    margin-bottom: 28px;
  }

  ::v-deep &__input {
    width: 340px;
  }

  ::v-deep &__editor {
    width: 478px;
  }
}
</style>
