var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      staticClass: "normalTable-view normalTable-view-disabled",
      attrs: {
        prop: _vm.normalTable.model,
        label: _vm.formIndex + 1 + "." + _vm.normalTable.name
      }
    },
    [
      _vm.normalTable.type == "input"
        ? [
            _vm.normalTable.options.dataType == "number"
              ? _c("el-input", {
                  attrs: {
                    type: "number",
                    placeholder: _vm.normalTable.options.placeholder,
                    disabled: _vm.normalTable.options.disabled,
                    readonly: ""
                  },
                  model: {
                    value: _vm.dataModel,
                    callback: function($$v) {
                      _vm.dataModel = _vm._n($$v)
                    },
                    expression: "dataModel"
                  }
                })
              : _c("el-input", {
                  attrs: {
                    type: _vm.normalTable.options.dataType,
                    disabled: _vm.normalTable.options.disabled,
                    placeholder: _vm.normalTable.options.placeholder,
                    readonly: ""
                  },
                  model: {
                    value: _vm.dataModel,
                    callback: function($$v) {
                      _vm.dataModel = $$v
                    },
                    expression: "dataModel"
                  }
                })
          ]
        : _vm._e(),
      _vm.normalTable.type == "textarea"
        ? [
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 5,
                disabled: _vm.normalTable.options.disabled,
                placeholder: _vm.normalTable.options.placeholder,
                readonly: ""
              },
              model: {
                value: _vm.dataModel,
                callback: function($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel"
              }
            })
          ]
        : _vm._e(),
      _vm.normalTable.type == "number"
        ? [
            _c("el-input-number", {
              attrs: {
                step: _vm.normalTable.options.step,
                "controls-position": "right",
                disabled: _vm.normalTable.options.disabled,
                min: _vm.normalTable.options.min,
                max: _vm.normalTable.options.max,
                readonly: ""
              },
              model: {
                value: _vm.dataModel,
                callback: function($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel"
              }
            })
          ]
        : _vm._e(),
      _vm.normalTable.type == "radio"
        ? [
            _c(
              "el-radio-group",
              {
                attrs: { disabled: _vm.normalTable.options.disabled },
                model: {
                  value: _vm.dataModel,
                  callback: function($$v) {
                    _vm.dataModel = $$v
                  },
                  expression: "dataModel"
                }
              },
              _vm._l(_vm.normalTable.options.options, function(item, index) {
                return _c(
                  "el-radio",
                  {
                    key: index,
                    style: {
                      display: _vm.normalTable.options.inline
                        ? "inline-block"
                        : "block"
                    },
                    attrs: { label: item.value }
                  },
                  [[_vm._v(_vm._s(item.value))]],
                  2
                )
              }),
              1
            )
          ]
        : _vm._e(),
      _vm.normalTable.type == "checkbox"
        ? [
            _c(
              "el-checkbox-group",
              {
                attrs: { disabled: _vm.normalTable.options.disabled },
                model: {
                  value: _vm.dataModel,
                  callback: function($$v) {
                    _vm.dataModel = $$v
                  },
                  expression: "dataModel"
                }
              },
              _vm._l(_vm.normalTable.options.options, function(item, index) {
                return _c(
                  "el-checkbox",
                  {
                    key: index,
                    style: {
                      display: _vm.normalTable.options.inline
                        ? "inline-block"
                        : "block"
                    },
                    attrs: { label: item.value }
                  },
                  [[_vm._v(_vm._s(item.value))]],
                  2
                )
              }),
              1
            )
          ]
        : _vm._e(),
      _vm.normalTable.type == "select"
        ? [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.normalTable.options.disabled,
                  multiple: _vm.normalTable.options.multiple,
                  clearable: _vm.normalTable.options.clearable,
                  placeholder: _vm.normalTable.options.placeholder,
                  filterable: _vm.normalTable.options.filterable
                },
                model: {
                  value: _vm.dataModel,
                  callback: function($$v) {
                    _vm.dataModel = $$v
                  },
                  expression: "dataModel"
                }
              },
              _vm._l(_vm.normalTable.options.options, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { value: item.value, label: item.value }
                })
              }),
              1
            )
          ]
        : _vm._e(),
      _vm.normalTable.type == "area"
        ? [
            _c(
              "el-select",
              {
                staticStyle: { "margin-bottom": "8px" },
                attrs: {
                  placeholder: _vm.normalTable.options.placeholder,
                  disabled: _vm.normalTable.options.disabled
                },
                model: {
                  value: _vm.normalTable.options.pro,
                  callback: function($$v) {
                    _vm.$set(_vm.normalTable.options, "pro", $$v)
                  },
                  expression: "normalTable.options.pro"
                }
              },
              [_c("el-option", { attrs: { label: "北京", value: "北京" } })],
              1
            ),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 5,
                    disabled: _vm.normalTable.options.disabled,
                    placeholder: _vm.normalTable.options.descplaceholder,
                    readonly: ""
                  },
                  model: {
                    value: _vm.normalTable.options.desc,
                    callback: function($$v) {
                      _vm.$set(_vm.normalTable.options, "desc", $$v)
                    },
                    expression: "normalTable.options.desc"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }