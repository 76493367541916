/*
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-12 19:55:13
 * @LastEditTime: 2021-06-25 10:34:04
 */
import axios from '@/common/ajax.js'

export const groupManagement = {
  reqQuery: (params) => axios.get('/wecom/robot/group', { params }),
  getManagementAccount: (params) => axios.get('/wecom/robot/list', { params }),
  getTopic: (params) => axios.get('/wecom/robot/group/topic', { params }),
  addTopic: (params) => axios.post('/wecom/robot/group/topic', params), // 添加分组
  delTopic: (params) => axios.delete('/wecom/robot/group/topic', { data: params }), // 删除
  editTopic: (params) => axios.put('/wecom/robot/group/topic', params), // 修改
  doGroup: (params) => axios.post('/wecom/robot/group/topic/more', params) // 添加或者删除群到分组
}

export const groupDetail = {
  reqQuery: (params) => axios.get('/wecom/robot/group/info/userlist', { params }), // 群详情-群成员
  changeStatus: (params) => axios.post('/wecom/robot/group/user/open', params) // 开启关闭群成员统计
}

export const account = {
  reqQuery: (params) => axios.get('/wecom/robot/group/info/robotlist', { params }), // 群详情-管理群的账号
  unFocus: (params) => axios.post('/wecom/robot/group/work', params) // 群详情-取消管理
}

export const addManagement = {
  reqQuery: (params) => axios.get('/wecom/robot/group/notworks', { params }),
  robotlist: (params) => axios.get('/wecom/robot/group/work/static', { params }),
  doWork: (params) => axios.post('/wecom/robot/group/work', params),
  robotSync: (params) => axios.post('/wecom/robot/group/sync', params)
}
