<template>
  <div class="ly-table page-warp">
    <!-- searchBar -->
    <div class="ly-search">
      <el-form
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
        size="medium"
      >
        <el-form-item style="margin-left: 0">
          <el-button-group>
            <el-button
              :type="btnState === 'day' ? 'primary' : ''"
              @click="getAnalyse('day')"
              >昨日</el-button
            >
            <el-button
              :type="btnState === 'week' ? 'primary' : ''"
              @click="getAnalyse('week')"
              >近7日</el-button
            >
            <el-button
              :type="btnState === 'month' ? 'primary' : ''"
              @click="getAnalyse('month')"
              >近30日</el-button
            >
          </el-button-group>
        </el-form-item>
        <el-form-item label="自定义：">
          <el-date-picker
            v-model="dates"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="changeTime"
            style="width: 260px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择员工：">
          <ly-emp-select
            :showLength="1"
            :checkList="checkProp"
            @getEmpId="getEmpId"
          ></ly-emp-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- searchBar End -->

    <!-- card -->
    <el-row :gutter="20" style="margin: 4px -10px 20px !important">
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'new_apply_cnt' ? 'active' : ''"
          @click="changeCard('new_apply_cnt')"
        >
          <div class="icon primary">
            <img src="@/assets/images/icon-sq.png" />
          </div>
          <div class="info">
            <div class="num">
              <span class="all">{{ summary.new_apply_cnt.curr }}</span> /
              {{ summary.new_apply_cnt.prev }}
            </div>
            <div class="title">
              发起申请数
              <el-tooltip
                class="item"
                effect="dark"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <div slot="content">
                  统计周期内，员工通过「搜索手机号」、「扫一扫」、<br />「从微信好友中添加」等渠道主动向客户发起的好友申请数量。
                </div>
                <i class="icon-info-fill iconfont"></i>
              </el-tooltip>
            </div>
            <!-- <div class="rate">
              <template v-if="summary.new_apply_cnt.rate === 0">
                <span class="rate-num q-info">
                  <i class="el-icon-caret-top"></i>
                  {{ summary.new_apply_cnt.rate }}%
                </span>
              </template>
              <template
                v-else-if="
                  summary.new_apply_cnt.rate.toString().indexOf('-') === -1
                "
              >
                <span class="rate-num q-success">
                  <i class="el-icon-caret-top"></i>
                  {{ summary.new_apply_cnt.rate }}%
                </span>
              </template>
              <template v-else>
                <span class="rate-num q-danger">
                  <i class="el-icon-caret-bottom"></i>
                  {{ summary.new_apply_cnt.rate }}%
                </span>
              </template>
              相比上期
            </div> -->
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'new_contact_cnt' ? 'active' : ''"
          @click="changeCard('new_contact_cnt')"
        >
          <div class="icon success">
            <img src="@/assets/images/icon-xz.png" />
          </div>
          <div class="info">
            <div class="num">
              <span class="all">{{ summary.new_contact_cnt.curr }}</span> /
              {{ summary.new_contact_cnt.prev }}
            </div>
            <div class="title">
              新增客户数
              <el-tooltip
                class="item"
                effect="dark"
                content="统计周期内，所有员工新添加的客户数量。"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </div>
          </div>
          <!-- <div class="rate">
            <template v-if="summary.new_contact_cnt.rate === 0">
              <span class="rate-num q-info">
                <i class="el-icon-caret-top"></i>
                {{ summary.new_contact_cnt.rate }}%
              </span>
            </template>
            <template
              v-else-if="
                summary.new_contact_cnt.rate.toString().indexOf('-') === -1
              "
            >
              <span class="rate-num q-success">
                <i class="el-icon-caret-top"></i>
                {{ summary.new_contact_cnt.rate }}%
              </span>
            </template>
            <template v-else>
              <span class="rate-num q-danger">
                <i class="el-icon-caret-bottom"></i>
                {{ summary.new_contact_cnt.rate }}%
              </span>
            </template>
            相比上期
          </div> -->
        </div>
      </el-col>
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'all_contact_cnt' ? 'active' : ''"
          @click="changeCard('all_contact_cnt')"
        >
          <div class="icon warning">
            <img src="@/assets/images/icon-kh.png" />
          </div>
          <div class="info">
            <div class="num">
              <span class="all">{{ clientTotal }}</span> / {{ clientTotal }}
            </div>
            <div class="title">
              客户总数
              <el-tooltip
                class="item"
                effect="dark"
                content="统计周期内，实时累计所有客户数量。"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </div>
          </div>
          <!-- <div class="rate">
            <span class="rate-num q-info">
              <i class="el-icon-caret-top"></i>0%
            </span>
            相比上期
          </div> -->
        </div>
      </el-col>
      <el-col :span="6">
        <div
          class="q-analyse-card"
          :class="cardName === 'negative_feed_back_cnt' ? 'active' : ''"
          @click="changeCard('negative_feed_back_cnt')"
        >
          <div class="icon brown">
            <img src="@/assets/images/icon-lh.png" />
          </div>
          <div class="info">
            <div class="num">
              <span class="all">{{ summary.negative_feed_back_cnt.curr }}</span>
              /
              {{ summary.negative_feed_back_cnt.prev }}
            </div>
            <div class="title">
              删除/拉黑客户数
              <el-tooltip
                class="item"
                effect="dark"
                content="统计周期内，删除/拉黑员工的客户数量。"
                placement="top-start"
                popper-class="q-tooltip"
              >
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </div>
          </div>
          <!-- <div class="rate">
            <template v-if="summary.negative_feed_back_cnt.rate === 0">
              <span class="rate-num q-info">
                <i class="el-icon-caret-top"></i>
                {{ summary.negative_feed_back_cnt.rate }}%
              </span>
            </template>
            <template
              v-else-if="
                summary.negative_feed_back_cnt.rate.toString().indexOf('-') ===
                -1
              "
            >
              <span class="rate-num q-success">
                <i class="el-icon-caret-top"></i>
                {{ summary.negative_feed_back_cnt.rate }}%
              </span>
            </template>
            <template v-else>
              <span class="rate-num q-danger">
                <i class="el-icon-caret-bottom"></i>
                {{ summary.negative_feed_back_cnt.rate }}%
              </span>
            </template>
            相比上期
          </div> -->
        </div>
      </el-col>
    </el-row>
    <!-- card End -->
    <div class="chart-area ly-shadow">
      <div class="title">数据趋势</div>
      <!-- toolBar -->
      <div class="ly-tool">
        <el-button-group>
          <el-button
            size="medium"
            :type="analyseState === 'chart' ? 'primary' : ''"
            @click="analyseState = 'chart'"
            >图表</el-button
          >
          <el-button
            size="medium"
            :type="analyseState === 'list' ? 'primary' : ''"
            @click="analyseState = 'list'"
            >列表</el-button
          >
        </el-button-group>
      </div>
      <!-- toolBar End -->

      <!-- chart -->
      <ly-chart
        :option="chartOption"
        height="400px"
        v-show="analyseState === 'chart' && showEmpty"
      ></ly-chart>
      <div
        v-show="analyseState === 'chart' && !showEmpty"
        class="flex flex-center q-info"
        style="height: 240px"
      >
        暂无分析数据
      </div>
      <!-- chart End -->

      <!-- table -->
      <div class="ly-table__main" v-show="analyseState === 'list'">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="stat_time" label="日期"> </el-table-column>
          <el-table-column
            prop="new_apply_cnt"
            label="发起申请数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="new_contact_cnt"
            label="新增客户数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="all_contact_cnt"
            label="客户总数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="negative_feed_back_cnt"
            label="删除/拉黑客户数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="lost_contact_cnt"
            label="流失客户占比"
            align="center"
          >
          </el-table-column>
          <div slot="empty" class="ly-table-empty">
            <slot name="empty">
              <img src="@assets/svg/default/no_data.svg" alt="" />
              暂无数据
            </slot>
          </div>
        </el-table>
      </div>
    </div>
    <!-- table End -->
  </div>
</template>

<script>
import lyChart from '@/components/lyChart'
export default {
  data () {
    return {
      btnState: 'week',
      dates: [],
      allEmpsData: [],
      filterText: '',
      chooseList: [],
      searchForm: {
        begin_at: '',
        end_at: '',
        user_ids: null
      },
      searchClientForm: {
        name: null,
        tag_ids: null,
        begin_at: null,
        end_at: null,
        wecom_user_id: null,
        order_by: null,
        direction: null,
        status: -1
      },
      pickerOptions: {
        disabledDate: (time) => {
          return this.disabledDate(time)
        },
        onPick: ({ maxDate, minDate }) => {
          return this.onPick({ maxDate, minDate })
        }
      },
      showPlaceholder: true,
      cardName: 'new_apply_cnt',
      curLabel: '发起申请数',
      preLabel: '上期发起申请数',
      analyseState: 'chart',
      summary: {
        new_apply_cnt: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        new_contact_cnt: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        all_contact_cnt: {
          curr: 0,
          prev: 0,
          rate: 0
        },
        negative_feed_back_cnt: {
          curr: 0,
          prev: 0,
          rate: 0
        }
      },
      current: [],
      previous: [],
      summaryCurr: 0,
      chart: null,
      chartOption: {
        color: ['rgb(43, 124, 255)', 'rgb(0, 199, 128)'],
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(38, 38, 38, 0.9)',
          extraCssText: 'border-radius: 16px',
          formatter: function (params) {
            const text = `<div style="line-height: 2; padding: 4px 12px; color: #fff;">
                            <div>同比增长 ${params[0].data.range}</div>
                            <span style="min-width: 80px;display: inline-block;">${params[0].data.time}</span>
                            ${params[0].marker}${params[0].seriesName}：${params[0].data.value}<br/>
                            <span style="min-width: 80px;display: inline-block;">${params[1].data.time}</span>
                            ${params[1].marker}${params[1].seriesName}：${params[1].data.value}
                          </div>`

            return text
          }
        },
        grid: {
          top: '60',
          left: '0',
          right: '0',
          containLabel: true
        },
        legend: {
          left: 'center',
          bottom: '0%',
          itemGap: 24,
          icon: 'circle',
          itemWidth: 12,
          itemHeight: 12
        },
        xAxis: {
          boundaryGap: ['2%', '20%'],
          axisLine: {
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          axisLabel: {
            color: '#6E788A'
          },
          data: []
        },
        yAxis: {
          name: '人数',
          nameTextStyle: {
            align: 'left',
            color: '#6E788A'
          },
          minInterval: 1,
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#6E788A'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          }
        },
        series: [
          {
            type: 'line',
            name: '发起申请数',
            symbol: 'circle',
            data: [],
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#EBF3FF' // 0% 处的颜色
                }, {
                  offset: 1, color: '#fff' // 100% 处的颜色
                }]
              }
            }
          },
          {
            type: 'line',
            name: '上期发起申请数',
            symbol: 'circle',
            data: [],
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#E0F8F1' // 0% 处的颜色
                }, {
                  offset: 1, color: '#fff' // 100% 处的颜色
                }]
              }
            }
          }
        ]
      },
      tableData: [],
      checkProp: null,
      clientTotal: 0,
      showEmpty: true
    }
  },
  created () {
    const _id = this.$route.query.id
    if (_id) {
      this.searchForm.user_ids = _id
      this.checkProp = [_id]
      this.$router.replace({ query: {} })
    }
    this.getWecomExternalUsers()
    this.getAnalyse('week')
  },
  watch: {
    filterText (val) {
      this.$refs.transfer.filter(val)
    },
    chooseList () {
      this.chooseList.length === 0 ? this.showPlaceholder = true : this.showPlaceholder = false
    },
    cardName (newVal, oldVal) {
      this.initChartOptions()
    }
  },
  methods: {

    // 获取客户列表
    async getWecomExternalUsers () {
      this.loading = true
      const data = await this.axios.get('clientAll', {
        params: {
          page: this.page,
          per_page: this.per_page,
          ...this.searchClientForm
        }
      })

      this.clientTotal = data.total
    },

    // 设置时间范围
    disabledDate (time) {
      const timeOptionRange = this.timeOptionRange
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _time = _date.getTime()
      if (timeOptionRange) {
        const secondNum = 30 * 8.64e7
        const _maxTime = timeOptionRange.getTime() + secondNum < (_time - 1 * 8.64e7) ? timeOptionRange.getTime() + secondNum : (_time - 1 * 8.64e7)
        const _minTime = timeOptionRange.getTime() - secondNum > (_time - 151 * 8.64e7) ? timeOptionRange.getTime() - secondNum : (_time - 151 * 8.64e7)
        return time.getTime() >= _maxTime || time.getTime() < _minTime
      } else {
        return time.getTime() >= _time - 1 * 8.64e7 || time.getTime() < _time - 151 * 8.64e7
      }
    },

    // 点击时设置
    onPick (time) {
      // 当第一时间选中才设置禁用
      if (time.minDate && !time.maxDate) {
        this.timeOptionRange = time.minDate
      }
      if (time.maxDate) {
        this.timeOptionRange = null
      }
    },

    // 切换时间
    changeTime (val) {
      if (val) {
        this.searchForm.begin_at = this.util.timeTofmt(val[0])
        this.searchForm.end_at = this.util.timeTofmt(val[1])
        this.btnState = ''
        this.getAnalyse()
      } else {
        this.searchForm.begin_at = null
        this.searchForm.end_at = null
        this.getAnalyse('week')
      }
    },

    // 切换卡片
    changeCard (val) {
      this.cardName = val
      switch (val) {
        case 'new_apply_cnt':
          this.curLabel = '发起申请数'
          this.preLabel = '上期发起申请数'
          break
        case 'new_contact_cnt':
          this.curLabel = '新增客户数'
          this.preLabel = '上期新增客户数'
          break
        case 'all_contact_cnt':
          this.curLabel = '客户总数'
          this.preLabel = '上期客户总数'
          break
        case 'negative_feed_back_cnt':
          this.curLabel = '删除/拉黑客户数'
          this.preLabel = '上期删除/拉黑客户数'
          break
      }
    },

    getEmpId (ids) {
      this.searchForm.user_ids = ids && ids.length > 0 ? ids.join(',') : null
      this.getAnalyse()
    },

    // 获取数据
    async getAnalyse (type) {
      let beginAt, endAt
      if (type) {
        this.btnState = type
        let time = await this.axios.get('getTime')
        time *= 1000
        endAt = this.util.timeTofmt(time - 1 * 8.64e7)
        switch (type) {
          case 'day':
            beginAt = this.util.timeTofmt(time - 2 * 8.64e7)
            break
          case 'week':
            beginAt = this.util.timeTofmt(time - 7 * 8.64e7)
            break
          case 'month':
            beginAt = this.util.timeTofmt(time - 30 * 8.64e7)
            break
        }
        this.$nextTick(() => {
          this.dates = [beginAt, endAt]
        })
        this.searchForm.begin_at = beginAt
        this.searchForm.end_at = endAt
      }

      const data = await this.axios.get('chatAnalysis', {
        params: {
          ...this.searchForm
        }
      })
      Object.assign(this.summary, data.summary)
      this.current = data.current

      const arr = []
      if (this.current) {
        for (let i = 0; i < data.current.length; i++) {
          data.current[i].stat_time = this.util.timeTofmt(data.current[i].stat_time)
          data.current[i].all_contact_cnt = this.clientTotal

          if (data.current[i].negative_feed_back_cnt) {
            if (this.clientTotal === 0) {
              data.current[i].lost_contact_cnt = '100%'
            } else {
              data.current[i].lost_contact_cnt = (data.current[i].negative_feed_back_cnt / this.clientTotal * 100).toFixed(2) + '%'
            }
          } else {
            data.current[i].lost_contact_cnt = '0%'
          }
          arr.push(data.current[i])
        }
        this.tableData = arr
        this.previous = data.previous
        this.initChartOptions()
      } else {
        this.showEmpty = false
      }
    },

    initChartOptions () {
      const curArr = this.current || []
      const preArr = this.previous || []
      const labelData = []
      const curData = []
      const preData = []
      const all = this.clientTotal
      for (let i = 0; i < curArr.length; i++) {
        const _curTime = this.util.timeTofmt(curArr[i].stat_time, 'd')
        const _toolCurTime = this.util.timeTofmt(curArr[i].stat_time, 'M月d日')
        const _toolPreTime = this.util.timeTofmt(preArr[i].stat_time, 'M月d日')
        labelData.push(_curTime + '日')
        if (this.cardName === 'all_contact_cnt') {
          curData.push({
            value: all,
            time: _toolCurTime,
            range: '0%'
          })
          preData.push({
            value: all,
            time: _toolPreTime,
            range: '0%'
          })
        } else if (curArr[i][this.cardName] === undefined) {
          curData.push({
            value: 0,
            time: _toolCurTime,
            range: '0%'
          })
          preData.push({
            value: 0,
            time: _toolPreTime,
            range: '0%'
          })
        } else {
          const _rangeNum = curArr[i][this.cardName] - preArr[i][this.cardName]
          let _range = 0
          if (preArr[i][this.cardName] === 0) {
            _range = curArr[i][this.cardName] * 100 + '%'
          } else {
            if (curArr[i][this.cardName] === 0) {
              _range = -(parseInt(preArr[i][this.cardName] * 100)) + '%'
            } else {
              _range = parseInt(_rangeNum / preArr[i][this.cardName] * 100) + '%'
            }
          }
          curData.push({
            value: curArr[i][this.cardName],
            time: _toolCurTime,
            range: _range
          })
          preData.push({
            value: preArr[i][this.cardName],
            time: _toolPreTime
          })
        }
      }
      // 配置图表x轴字段
      this.chartOption.xAxis.data = labelData
      // 配置图表本期数据
      this.chartOption.series[0].data = curData
      this.chartOption.series[0].name = this.curLabel
      // 配置图表上期数据
      this.chartOption.series[1].data = preData
      this.chartOption.series[1].name = this.preLabel
    },

    clearId () {
      this.checkProp = []
    }
  },
  components: {
    lyChart
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/table.scss";
@import "@/assets/scss/analyse.scss";
.ly-search {
  background: transparent;
  padding: 0;
  box-shadow: unset;
  ::v-deep {
    .el-button-group > .el-button:first-child {
      border-radius: 8px 0 0 8px;
    }
    .el-button-group > .el-button:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
}
.ly-tool {
  padding: 0;
}
.chart-area {
  height: calc(100% - 247px);
  min-height: 482px;
}
.icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
  }
}
</style>
