<!--
 * @Author: zhan
 * @Date: 2022-08-05 17:21:59
 * @LastEditTime: 2022-09-09 14:22:30
 * @LastEditors: zhanln
-->
<template>
  <ly-form
    ref="lyFormRef"
    class="act-form"
    :formObj="formObj"
    :form="formData"
    :rules="rules"
    @setData="fn_setData"
  >
    <template #show_stock_bottom>
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
        popper-class="q-tooltip"
      >
        <div slot="content">
          1.关闭：活动页显示“库存告急”。<br />
          2.开启：展示任务设置的奖品剩余库存，如果奖品类型为兑换码，<br />
          则展示兑换码的真实库存。
        </div>
        <i class="ri-question-line ml-8"></i>
      </el-tooltip>
    </template>

    <template #reward_detail>
      <el-form-item prop="reward_detail" v-if="formData.reward_type === 1">
        <div class="detail">
          <ly-editor
            id="detail_diy"
            title="自定义奖品内容"
            :content="formData.reward_detail"
            @getContent="fn_setData($event, 'reward_detail')"
            @focus="fn_setFocus(true)"
            @blur="fn_setFocus(false)"
          >
          </ly-editor>
          <div class="detail-foot">
            <ly-upload
              :img="formData.reward_detail_img"
              @getImg="fn_setData($event, 'reward_detail_img', 'reward_detail')"
              mini
              canRemove
            ></ly-upload>
          </div>
        </div>
      </el-form-item>

      <template v-else>
        <el-form-item prop="reward_type_id" class="detail-code">
          <el-select
            v-model="formData.reward_type_id"
            placeholder="请选择"
            clearable
            class="act-form__input"
            @change="fn_changeCode"
            :disabled="eType === 1"
          >
            <el-option
              v-for="item in codeList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div class="text-small">
          <span class="q-info mr-8">选择要关联活动的兑换码奖品</span>
          <el-button type="text" size="mini" @click="fn_jumpToCode('code')"
            >去创建兑换码</el-button
          >
          <div data-v-3cefa108="" class="el-divider el-divider--vertical"></div>
          <el-button
            type="text"
            size="mini"
            v-lydebounce="['button', fn_getCodeList]"
            >刷新</el-button
          >
        </div>
        <ly-editor
          id="detail_code"
          style="width: 485px"
          title="兑换规则"
          :content="formData.reward_detail_code"
          @getContent="fn_setDetail($event)"
          @focus="fn_setFocus(true)"
          @blur="fn_setFocus(false)"
        >
        </ly-editor>
      </template>
    </template>

    <template #total_bottom v-if="eType === 1">
      <div class="q-info">
        <span style="margin-right: 24px"
          >已使用：<b>{{ formData.give_num }}</b></span
        >
        <span :class="{ 'q-danger': remain <= 0 }"
          >剩余库存：<b>{{ remain }}</b></span
        >
      </div>
    </template>
  </ly-form>
</template>

<script>
// store
import { mapState } from 'vuex'
export default {
  data () {
    // 人数校验
    const checkTargetCount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入任务人数'))
      }

      const check = this.fn_checkCount()

      if (check) {
        return callback(new Error(check))
      }
      callback()
    }

    // 虚拟人数校验
    const checkUnreal = (rule, value, callback) => {
      if (isNaN(value)) {
        return callback(new Error('请输入任务人数'))
      }

      const check = this.fn_checkUnreal()

      if (check) {
        return callback(new Error(check))
      }
      callback()
    }

    // 自定义内容校验
    const checkContent = async (rule, value, callback) => {
      if (!this.formData.reward_detail && !this.formData.reward_detail_img) {
        return callback(new Error('请输入奖品内容（文本或图片至少一项）'))
      }

      callback()
    }

    // 奖品库存校验
    const checkTotal = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入库存数量'))
      }

      if (this.eType === 1 && value <= this.formData.give_num) {
        return callback(new Error('请确保剩余库存大于0'))
      }
      callback()
    }

    return {
      formData: {
        id: 0,
        reward_number: 1,
        target_count: 0,
        reward_title: '',
        reward_cover: '',
        virtual_count: 0,
        reward_type: null,
        reward_type_id: null,
        reward_detail: '',
        reward_detail_code: '',
        reward_detail_img: '',
        total: 0,
        show_stock: 1
      },
      rules: {
        target_count: [
          { validator: checkTargetCount, required: true, trigger: ['change', 'blur'] }
        ],
        reward_title: [
          { required: true, message: '请输入奖品名称', trigger: 'change' }
        ],
        reward_cover: [
          { required: true, message: '请上传奖品封面', trigger: 'change' }
        ],
        reward_detail: [
          { validator: checkContent, required: true, trigger: 'change' }
        ],
        reward_type_id: [
          { required: true, message: '请选择兑换码', trigger: 'change' }
        ],
        virtual_count: [
          { validator: checkUnreal, required: true, trigger: ['change', 'blur'] }
        ],
        total: [
          { validator: checkTotal, required: true, trigger: ['change', 'blur'] }
        ],
        reward_type: [
          { required: true, trigger: 'change' }
        ]
      },
      codeList: []
    }
  },

  computed: {
    formObj () {
      return {
        labelWidth: '160px',
        item: [
          {
            type: 'inputNumber',
            label: '任务人数',
            prop: 'target_count',
            attr: {
              controlsPosition: 'right',
              min: 1,
              max: 100,
              precision: 0,
              disabled: this.eType === 1
            }
          },
          {
            type: 'text',
            label: '奖品名称',
            prop: 'reward_title',
            attr: {
              maxlength: '10',
              showWordLimit: true
            }
          },
          {
            type: 'upload',
            label: '奖品封面',
            prop: 'reward_cover'
          },
          {
            type: 'radio',
            label: '奖品内容',
            prop: 'reward_type',
            attr: {
              disabled: this.eType === 1
            },
            options: [
              {
                name: '自定义',
                value: 1
              },
              {
                name: '兑换码',
                value: 2
              }
            ],
            bottomSlot: 'reward_detail'
          },
          {
            type: 'inputNumber',
            label: '虚拟完成人数',
            prop: 'virtual_count',
            attr: {
              controlsPosition: 'right',
              min: 0,
              max: 99999,
              precision: 0
            }
          },
          {
            type: 'inputNumber',
            label: '库存',
            prop: 'total',
            attr: {
              controlsPosition: 'right',
              min: 1,
              max: 99999,
              precision: 0
            },
            bottomSlot: 'total_bottom'
          },
          {
            type: 'switch',
            label: '真实库存展示',
            prop: 'show_stock',
            key: [1, 2],
            switchTipSlot: 'show_stock_bottom'
          }
        ]
      }
    },
    remain () {
      return this.eType === 1 ? this.formData.total - this.formData.give_num : false
    },
    ...mapState({
      eType: state => state.reserveTask.eType,
      target_count_1: state => state.reserveTask.target_count_0,
      target_count_2: state => state.reserveTask.target_count_1,
      target_count_3: state => state.reserveTask.target_count_2,
      virtual_count_1: state => state.reserveTask.virtual_count_0,
      virtual_count_2: state => state.reserveTask.virtual_count_1,
      virtual_count_3: state => state.reserveTask.virtual_count_2
    })
  },

  watch: {
    'formData.target_count' (val) {
      this.fn_setStore('target_count', val)
    },
    'formData.reward_title' (val) {
      this.fn_setStore('reward_title', val)
    },
    'formData.reward_cover' (val) {
      this.fn_setStore('reward_cover', val)
    },
    'formData.virtual_count' (val) {
      this.fn_setStore('virtual_count', val)
    },
    'formData.total' (val) {
      this.fn_setStore('total', val)
    },
    'formData.show_stock' (val) {
      this.fn_setStore('show_stock', val)
    },
    'formData.reward_detail' (val) {
      this.fn_setStore('reward_detail', val)
    },
    'formData.reward_detail_img' (val) {
      this.fn_setStore('reward_detail_img', val)
    },
    'formData.reward_type' (val) {
      if (val === 1) {
        this.$refs.lyFormRef.clearValidate('reward_type_id')
      } else {
        this.$refs.lyFormRef.clearValidate('reward_detail')
      }
      this.fn_setStore('reward_type', val)
    }
  },

  methods: {

    fn_setForm (data, hasForm) {
      this.formData = data
      this.$nextTick(() => {
        hasForm ? this.$refs.lyFormRef.verify() : this.$refs.lyFormRef.clearValidate()
      })
      this.fn_setStore('target_count', this.formData.target_count)
      this.fn_setStore('reward_type', this.formData.reward_type)
      this.fn_setStore('total', this.formData.total)
      this.fn_setStore('virtual_count', this.formData.virtual_count)
      this.fn_getCodeList()
    },

    // 获取兑换码列表
    async fn_getCodeList () {
      const data = await this.axios.get('taskCodeList', { params: { code_id: this.formData.reward_type_id } })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.codeList = data
    },

    // 选择/切换兑换码
    fn_changeCode (val) {
      if (val) {
        this.formData.total = this.codeList.filter(item => item.id === val)[0].wait
      }
    },

    // 跳转兑换码 / 表单列表
    fn_jumpToCode (type) {
      const path = type === 'code' ? '/material/code/list' : '/material/formTable/list'
      const routeData = this.$router.resolve({
        path: path
      })
      window.open(routeData.href, '_blank')
    },

    // 兑换规则回调设置
    fn_setDetail (val) {
      this.formData.reward_detail_code = val
      this.fn_setStore('reward_detail_code', val)
    },

    // 组件内容回调及校验
    fn_setData (val, name, validname) {
      this.formData[name] = val
      this.$refs.lyFormRef.verify(validname || name)
    },

    fn_setStore (name, val) {
      this.$store.commit('reserveTask/SET_DATA', {
        name: name + `_${this.formData.reward_number - 1}`,
        val
      })
    },

    // 任务人数校验
    fn_checkCount () {
      // 阶段
      const index = this.formData.reward_number
      const currentCount = this.formData.target_count
      const prevCount = this[`target_count_${index - 1}`]
      const nextCount = this[`target_count_${index + 1}`]

      if (prevCount && currentCount <= prevCount) {
        return '需大于上一阶段任务人数'
      }

      if (nextCount && currentCount >= nextCount) {
        return '需小于下一阶段任务人数'
      }
    },

    // 虚拟人数校验
    fn_checkUnreal () {
      // 阶段
      const index = this.formData.reward_number
      const currentUnreal = this.formData.virtual_count
      const prevUnreal = this[`virtual_count_${index - 1}`]
      const nextUnreal = this[`virtual_count_${index + 1}`]

      if (prevUnreal && currentUnreal > prevUnreal) {
        return '需小于等于上一阶段虚拟完成人数：' + prevUnreal
      }

      if (nextUnreal && currentUnreal < nextUnreal) {
        return '需大于等于下一阶段虚拟完成人数：' + nextUnreal
      }
    },

    // 奖品内容是否聚焦
    fn_setFocus (type) {
      this.$store.commit('reserveTask/SET_DATA', {
        name: 'is_focus',
        val: type
      })
    },

    // 表单校验，通过则返回 form
    async verify () {
      const formValid = await this.$refs.lyFormRef.verify()

      if (!formValid) return false

      const form = this.util.extend(true, {}, this.formData)
      form.reward_type_id = form.reward_type_id + ''

      console.log('form', form)

      return form
    }
  }
}
</script>

<style lang="scss" scoped>
.act-form {
  ::v-deep {
    .el-input,
    .act-form__input {
      max-width: 320px;
    }
  }
}

.detail {
  width: 485px;
  border: 1px solid #dfe1e8;
  border-radius: 8px;

  &:hover {
    border-color: $--color-primary;
  }

  .is-error & {
    border-color: $--color-danger;
  }

  .ly-editor {
    border: none;
  }

  &-foot {
    border-top: 1px solid #dfe1e8;
    padding: 16px 18px;
  }
}

.detail-code {
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-form-item__error {
    position: relative;
    margin-left: 16px;
    padding-top: 0;
  }
}
</style>
