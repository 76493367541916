<!--
 * @Descripttion:扣费记录
 * @version: 1.0.0
 * @Author: lw
 * @Date: 2021-03-16 16:32:41
 * @LastEditTime: 2021-12-30 14:31:18
-->
<template>
  <div class="page-warp records-warp">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <!-- 列插槽 -->
      <template #user_name_slot="{row}">
        <template v-if="row.WecomUserID != 0">
          <el-image lazy class="avatar" :src="row.user_avatar"></el-image>
          <!-- <span class="other-icon">外</span> -->
          <span>{{ row.user_name }}</span>
        </template>
        <template v-else>
          <span class="other-icon">外</span>
          <span class="other-name">非本企业账号</span>
        </template>
      </template>
    </ly-table>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { recordsApi } from './http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: recordsApi, // 表格内所有接口配置
      DATA_LOCATION: 'list' // 接口中表格数据所在的位置 默认为list
    }
  },
  computed: {
    tableOpt () {
      return {
        // layout: 'prev, pager, next',
        operation: 'hide',
        column: [
          {
            label: '登录账号',
            prop: 'user_name',
            search: true,
            placeholder: '请输入账号名称',
            slot: 'user_name_slot'
          },
          {
            label: '登录时间',
            prop: 'login_at',
            search: true,
            type: 'daterange',
            key: ['begin_at', 'end_at'], // 自定义搜索条件的key
            formatter: (row, column, cellValue, index) => {
              return row.login_at === 0 ? '-' : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '下线时间',
            prop: 'login_out_at',
            formatter: (row, column, cellValue, index) => {
              return row.login_out_at === 0 ? '-' : this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '扣除云豆',
            prop: 'bean',
            columnProps: {
              'class-name': 'cost'
            },
            width: '100px',
            align: 'center'
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep{
    .avatar{
      width: 32px;
      height: 32px;
      margin-right: 10px;
      border-radius: 50%;
      & + span{
        vertical-align: top;
      }
    }
    .el-table__body-wrapper .cost{
      color: #FAAD14;
      font-weight: 500
    }
    .other-icon{
      display: inline-block;
      line-height: 32px;
      text-align: center;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background: #2B7CFF;
      color: #fff;
      margin-right: 10px;
    }
    .other-name{
      vertical-align: top;
    }
    .ly-table-main{
      padding-top: 20px!important;
    }
    .cost .cell{
      padding-right: 24px!important;
    }
  }
</style>
