<!--
* 奖品记录
-->
<template>
  <div class="prize">
    <ly-table ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :pageSize="per_page" :total="total"
      @searchChange="searchChange" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange">
      <template #reward_title_slot="{ row }">
        <div class="avatar-name">
          <img v-if="row.reward_cover" :src="row.reward_cover" alt=""
            style="margin-right: 8px; max-height: 24px; max-width: 24px;" />
          <span class="name">{{ row.reward_title }}</span>
        </div>
      </template>

      <template #operation="{ row }">
        <template v-if="row.reward_type === 5">
          <el-button v-if="row.reward_value" type="text" size="small" @click="getRewardDetail(row)">查看奖品内容</el-button>
          <span v-else>-</span>
        </template>
        <template v-else>
          <el-button type="text" size="small" @click="getRewardDetail(row)">查看奖品内容</el-button>
        </template>
      </template>
    </ly-table>

    <!-- dialog 奖品内容 -->
    <el-dialog title="奖品内容" append-to-body :visible.sync="dialogVisible" custom-class="q-dialog" width="34%">
      <div class="reward">
        <template v-if="rewardType === 1">
          <el-input type="textarea" v-model="rewardDetail" :autosize="{ minRows: 6, maxRows: 10 }" resize="none"
            readonly>
          </el-input>
          <div class="q-dialog__img" v-if="rewardDetailImg">
            <div class="flex flex-center">
              <img :src="rewardDetailImg" alt="" />
            </div>
          </div>
        </template>
        <div class="q-dialog-block" v-else-if="rewardType === 2">
          <div class="reward-code">{{ rewardDetail }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { prize } from './http.js'
import { fDetails } from '../http'
export default {
  mixins: [lyTable],
  props: ['inviterId', 'activityId'],
  data () {
    return {
      COMM_HTTP: prize,
      rewardLoading: true,
      per_page: 5,
      dialogVisible: false,
      rewardType: null,
      rewardDetail: null,
      rewardDetailImg: '',
      exOptions: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '自定义'
      }, {
        value: 2,
        label: '兑换码'
      }],
      defaultImg: require('@/assets/images/gift.png')
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        levitate: false,
        layout: 'prev, pager, next',
        column: [
          {
            label: '奖品名称',
            prop: 'reward_title',
            width: 200,
            slot: 'reward_title_slot',
            search: true
          },
          {
            label: '奖品类型',
            prop: 'reward_type',
            search: true,
            source: this.exOptions,
            type: 'select',
            formatter (row, column, cellValue, index) {
              let text = '-'
              switch (cellValue) {
                case 1:
                  text = '自定义'
                  break
                case 2:
                  text = '兑换码'
                  break
                default:
                  break
              }
              return text
            }
          },
          {
            label: '中奖时间',
            prop: 'created_date',
            formatter (row, column, cellValue, index) {
              return _this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          }
        ]
      }
    }
  },
  watch: {
    inviterId (val) {
      this.queryData()
    }
  },
  methods: {
    formatterParams (params) {
      console.log('params', params)
      return {
        ...params,
        book_lottery_id: this.activityId,
        account_id: this.inviterId
      }
    },

    // 查看奖品详情
    async getRewardDetail (data) {
      this.rewardType = data.reward_type
      if (this.rewardType === 1) {
        this.rewardDetail = data.reward_detail
        this.rewardDetailImg = data.reward_detail_img
      } else if (this.rewardType === 2) {
        this.rewardDetail = data.reward_value
      }

      this.dialogVisible = true
    },

    // 判断JSON
    fn_isJson (str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    },

    async fn_getFormReward (data) {
      this.rewardLoading = true
      const res = await fDetails.getOneDetail(
        {
          formId: data.reward_type_id + '',
          requestId: data.reward_value + ''
        }
      )

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.rewardDetail = res
      this.rewardLoading = false
    },

    // 修改奖品发送状态
    async updateRewardStatus (id) {
      const data = this.axios.put('updateRewardStatus', {
        id: id,
        status: 1
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$message.success('修改奖品发送状态成功')
      this.getRewardStatistic()
      this.getRewardRecords()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-icon-warning {
  color: #faad14;
  font-size: 14px;
}

.notice-status-succ,
.notice-status-error {
  position: relative;
  padding-left: 15px;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #52c41a;
    position: absolute;
    left: 0;
    top: 4px;
  }
}

.notice-status-succ {
  color: #52c41a;
}

.notice-status-error::before {
  background: #f55945;
}

.avatar-name .name {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行数
  -webkit-box-orient: vertical;
}

::v-deep {
  .xl-ly-search {
    margin-bottom: 0;
  }

  .q-dialog {
    p {
      margin: 0;
    }

    a {
      color: #2b7cff;
      pointer-events: none;
    }

    &__img {
      margin-top: 16px;

      img {
        max-height: 300px;
        max-width: 300px;
      }
    }

    &__body {
      height: 240px;
    }
  }
}

.reward {
  max-height: 50vh;
  overflow: auto;

  &-list {
    &__item {
      padding: 14px 0;
      border-bottom: 1px solid #f0f1f2;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }

      .title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .val {
        color: #6e6f73;
      }
    }
  }
}
</style>
