<template>
  <el-form-item
    class="normalTable-view drag-normalTable"
    v-if="element && element.key"
    :class="{
      active: selectnormalTable.key == element.key,
      is_req: element.options.required
    }"
    :label="index + 1 + '.' + element.name"
    @click.native.stop="handleSelectnormalTable(index)"
  >
    <template v-if="element.type == 'input'">
      <el-input
        v-model="element.options.defaultValue"
        :style="{ width: element.options.width }"
        :placeholder="element.options.placeholder"
        :disabled="element.options.disabled"
        :type="element.options.type"
        readonly
      ></el-input>
    </template>

    <template v-if="element.type == 'textarea'">
      <el-input
        type="textarea"
        :rows="5"
        v-model="element.options.defaultValue"
        :style="{ width: element.options.width }"
        :disabled="element.options.disabled"
        :placeholder="element.options.placeholder"
        readonly
      ></el-input>
    </template>

    <template v-if="element.type == 'number'">
      <el-input-number
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled"
        :controls-position="element.options.controlsPosition"
        readonly
        :style="{ width: element.options.width }"
      ></el-input-number>
    </template>

    <template v-if="element.type == 'radio'">
      <el-radio-group
        v-model="element.options.defaultValue"
        :style="{ width: element.options.width }"
      >
        <el-radio
          style="display: block"
          :label="item.value"
          v-for="(item, index) in element.options.options"
          :key="item.value + index"
        >
          {{ item.value }}
        </el-radio>
      </el-radio-group>
    </template>

    <template v-if="element.type == 'checkbox'">
      <el-checkbox-group
        v-model="element.options.defaultValue"
        :style="{ width: element.options.width }"
      >
        <el-checkbox
          style="display: block"
          :label="item.value"
          v-for="(item, index) in element.options.options"
          :key="item.value + index"
        >
          {{ item.value }}
        </el-checkbox>
      </el-checkbox-group>
    </template>

    <template v-if="element.type == 'select'">
      <el-select
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled"
        :multiple="element.options.multiple"
        :clearable="element.options.clearable"
        :placeholder="element.options.placeholder"
        :style="{ width: element.options.width }"
      >
        <el-option
          v-for="item in element.options.options"
          :key="item.value"
          :value="item.value"
          :label="item.value"
        ></el-option>
      </el-select>
    </template>

    <template v-if="element.type == 'area'">
      <el-select
        v-model="element.options.pro"
        :placeholder="element.options.placeholder"
        :style="{ width: element.options.width }"
        style="margin-bottom: 8px"
        :disabled="element.options.disabled"
      >
        <el-option label="北京" value="北京"></el-option>
      </el-select>
      <div>
        <el-input
          type="textarea"
          :rows="5"
          v-model="element.options.desc"
          :disabled="element.options.disabled"
          :placeholder="element.options.descplaceholder"
          readonly
        ></el-input>
      </div>
    </template>

    <div
      class="normalTable-view-action"
      v-if="selectnormalTable.key == element.key"
    >
      <i
        class="el-icon-document-copy"
        @click.stop="handlenormalTableClone(index)"
      ></i>
      <i
        class="el-icon-delete"
        v-if="!selectnormalTable.options.isDelete"
        @click.stop="handlenormalTableDelete(index)"
      ></i>
    </div>
  </el-form-item>
</template>

<script>
import area from './area.js'
export default {
  props: ['element', 'select', 'index', 'data', 'selectIndex'],
  components: {
    // FmUpload,
  },
  data () {
    function getArea () {
      const json = {}

      for (const [a, b] of Object.entries(area.province_list)) {
        const _startWith = a.substr(0, 2)
        json[_startWith] = {
          value: a,
          label: b,
          children: {}
        }
      }

      for (const [a, b] of Object.entries(area.city_list)) {
        const _cityStartWith = a.substr(0, 2)
        const _citymiddleWite = a.substr(2, 2)
        json[_cityStartWith].children[_citymiddleWite] = {
          value: a,
          label: b,
          children: []
        }
      }
      for (const [a, b] of Object.entries(area.county_list)) {
        const _cityStartWith = a.substr(0, 2)
        const _citymiddleWite = a.substr(2, 2)
        json[_cityStartWith].children[_citymiddleWite].children.push({
          value: a,
          label: b
        })
      }

      return json
    }
    return {
      areaJSON: getArea(),
      prolist: [],
      selectnormalTable: this.select
    }
  },
  mounted () {

  },
  methods: {
    handleSelectnormalTable (index) {
      this.selectnormalTable = this.data.list[index]
      this.$emit('update:selectIndex', index)
      this.$store.commit('_SET', {
        'client.formTableComTitle': this.selectnormalTable.comName || '组件名字'
      })
    },
    handlenormalTableDelete (index) {
      let _title = '确认删除该组件吗？'
      let _content = null
      if (this.$route.query.id) {
        _title = '确认删除该组件吗？'
        _content = '确定删除后已提交的用户数据将无法找回，您确定要删除吗？'
      }
      this.$lyConfirm({
        title: _title,
        text: _content,
        type: 'warning'
      })
        .then(() => {
          if (this.data.list.length - 1 === index) {
            if (index === 0) {
              this.selectnormalTable = {}
            } else {
              this.selectnormalTable = this.data.list[index - 1]
            }
          } else {
            this.selectnormalTable = this.data.list[index + 1]
          }

          this.$store.commit('_SET', {
            'client.formTableComTitle': this.selectnormalTable.comName || '组件名字'
          })

          this.$nextTick(() => {
            this.data.list.splice(index, 1)
            this.$emit('update:selectIndex', this.data.list.length - 1)
          })
        })
        .catch(() => { })
    },
    handlenormalTableClone (index) {
      let cloneData = {
        ...this.data.list[index],
        options: { ...this.data.list[index].options },
        key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
      }
      if (this.data.list[index].type === 'radio' || this.data.list[index].type === 'checkbox' || this.data.list[index].type === 'select') {
        cloneData = {
          ...cloneData,
          options: {
            ...cloneData.options,
            options: cloneData.options.options.map(item => ({ ...item }))

          }
        }
      }

      this.data.list.splice(index, 0, cloneData)
      this.$set(this.data.list[index + 1].options, 'isDelete', false)

      this.$nextTick(() => {
        this.selectnormalTable = this.data.list[index + 1]

        this.$store.commit('_SET', {
          'client.formTableComTitle': this.selectnormalTable.comName || '组件名字'
        })
      })
    },

    initProlist () {
      if (this.prolist.length) return
      this.prolist = Object.values(this.areaJSON)
    }
  },
  watch: {
    select (val) {
      this.selectnormalTable = val
    },
    selectnormalTable: {
      handler (val) {
        this.$emit('update:select', val)
      },
      deep: true
    },
    areaJSON: {
      handler (newVal) {
        this.initProlist()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
