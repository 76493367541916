<!--
 * @Descripttion: 用户详情助力列表
 * @version: 1.0.0
-->
<template>
  <ly-table
    ref="lyTable"
    :data="tableData"
    :option="tableOpt"
    :page="page"
    :pageSize="per_page"
    :total="total"
    @searchChange="searchChange"
    @sizeChange="handleSizeChange"
    @currentChange="handleCurrentChange"
    @selectionChange="handleSelectionChange"
  >
    <template #handler>
      <span class="total"
        >邀请了 {{ total }} 名用户，{{ countSuccess }} 名助力成功</span
      >
    </template>

    <template #name_slot="{ row }">
      <div class="avatar-name">
        <el-image
          class="avatar"
          :src="row.avatar"
          fit="cover"
          lazy
        ></el-image>
        <span class="name">{{ row.nickname }}</span>
      </div>
    </template>

    <template #help_status_slot="{ row }">
      <ly-status type="success" icon="iconfont icon-success" v-if="row.help_status === 1">
        助力成功
      </ly-status>
      <ly-status type="danger" icon="iconfont icon-close-fill" v-else>
        {{ formatterText(row.help_status) + ' 助力无效' }}
      </ly-status>
    </template>
  </ly-table>
</template>
<script>
import { userDetail } from '../http.js'
import lyTable from '@/components/mixins/ly-table.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: userDetail,
      per_page: 5
    }
  },
  computed: {
    tableOpt () {
      return {
        levitate: false,
        operation: 'hide',
        layout: 'prev, pager, next',
        column: [
          {
            label: '用户昵称',
            prop: 'nickname',
            search: true,
            slot: 'name_slot'
          },
          {
            label: '助力时间',
            prop: 'join_date'
          },
          {
            label: '助力情况',
            prop: 'help_status',
            slot: 'help_status_slot'
          }
        ]
      }
    },
    countSuccess () {
      return this.listData.success_total
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.$route.query.id * 1,
        invitor_id: this.$attrs.id
      }
    },
    formatterText (code) {
      let str = ''
      switch (code) {
        case 2:
          str = '老用户'
          break
        case 3:
          str = '删除员工'
          break
        case 4:
          str = '新用户没有上级'
          break
        default:
          break
      }
      return str
    }
  }
}
</script>
