var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "选择群聊",
            visible: _vm.dialogVisible,
            width: "1000px",
            "custom-class": "q-dialog has-table"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleSubmit
          }
        },
        [
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "group-left" },
              [
                _c("ly-table", {
                  ref: "lyTable",
                  attrs: {
                    data: _vm.tableData,
                    option: _vm.tableOpt,
                    page: _vm.page,
                    pageSize: _vm.per_page,
                    total: _vm.total
                  },
                  on: {
                    searchChange: _vm.searchChange,
                    currentChange: _vm.handleCurrentChange,
                    selectionChange: _vm.handleSelectionChange
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "handler",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-small q-bg q-info flex" },
                              [
                                _vm.type === 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "16px" },
                                        attrs: {
                                          loading: _vm.loading,
                                          icon: "iconfont icon-shuaxin1",
                                          round: ""
                                        },
                                        on: { click: _vm.fn_querySync }
                                      },
                                      [_vm._v("同步")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  [
                                    _c("i", {
                                      staticClass: "icon-warning-fill iconfont",
                                      staticStyle: {
                                        "margin-right": "2px",
                                        "vertical-align": "middle"
                                      }
                                    }),
                                    _vm.type === 1
                                      ? [
                                          _vm._v(
                                            " 仅展示云端账号在线且未用于其他裂变活动的群聊，无数据时，请发送任意消息激活群聊后尝试同步数据 "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: _vm.fn_jumpCloudPage
                                              }
                                            },
                                            [_vm._v("如何登录云端?")]
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "若该群已被用于其他裂变活动，则不可选择。"
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "name_slot",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "flex flex-start" }, [
                              _c("div", { staticClass: "ly-table-tearm" }, [
                                _c("i", { staticClass: "iconfont icon-weixin" })
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "8px",
                                    flex: "1"
                                  }
                                },
                                [_vm._v(_vm._s(row.name ? row.name : "群聊"))]
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "robot_list",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "more-popover",
                              {
                                attrs: {
                                  title: "企业成员",
                                  list: _vm.sortRobot(row.robot_list || [])
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "reference",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getOnlineCount(
                                                row.robot_list
                                              ) + "人 "
                                            )
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "ly-team small" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-s-custom",
                                                staticStyle: {
                                                  "font-size": "16px"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "m-group_name text-ellipsis"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.member_name) +
                                                  " "
                                              ),
                                              item.online_status
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#00a86e"
                                                      }
                                                    },
                                                    [_vm._v("(在线)")]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#909399"
                                                      }
                                                    },
                                                    [_vm._v("(离线)")]
                                                  )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_vm._v("在线 ")]
                            )
                          ]
                        }
                      },
                      {
                        key: "robot_list_head",
                        fn: function() {
                          return [
                            _vm._v(" 云端账号 "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "刷新云端账号状态",
                                  placement: "top"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-shuaxin1",
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.queryData }
                                })
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "empty",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "q-bg q-info" },
                              [
                                _vm.type === 1
                                  ? [
                                      _vm._v(
                                        " 暂无数据，请同步云端账号且保持在线。"
                                      ),
                                      _c(
                                        "a",
                                        { on: { click: _vm.fn_jumpCloudPage } },
                                        [_vm._v("登录云端>>")]
                                      )
                                    ]
                                  : [_vm._v(" 暂无数据 ")]
                              ],
                              2
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1874702496
                  )
                })
              ],
              1
            ),
            _c("div", { staticClass: "group-right" }, [
              _c("div", { staticClass: "group-right__title" }, [
                _vm._v("已选群（" + _vm._s(_vm.selectList.length) + "）")
              ]),
              _vm.selectList.length === 0
                ? _c("div", { staticClass: "group-right__empty" }, [
                    _vm._v(" 请选择群聊 ")
                  ])
                : _c(
                    "div",
                    { staticClass: "group-right__list" },
                    _vm._l(_vm.selectList, function(item, index) {
                      return _c("div", { key: item.id, staticClass: "item" }, [
                        _c("div", { staticClass: "flex flex-start" }, [
                          _c("div", { staticClass: "ly-table-tearm" }, [
                            _c("i", { staticClass: "iconfont icon-weixin" })
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: { "margin-right": "8px", flex: "1" }
                            },
                            [_vm._v(_vm._s(item.name || "群聊"))]
                          )
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "remove",
                            on: {
                              click: function($event) {
                                return _vm.fn_removeGroup(index, item)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        )
                      ])
                    }),
                    0
                  )
            ])
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.hasCheck
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "medium", round: "" },
                      on: { click: _vm.handleCancle }
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "", size: "medium" },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(_vm._s(_vm.btnText))]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }