var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "活码详情",
        visible: _vm.visible,
        "custom-class": "q-dialog",
        width: "880px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _vm.did
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dataLoading,
                  expression: "dataLoading"
                }
              ],
              staticClass: "detail"
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.posterReady,
                      expression: "posterReady"
                    }
                  ],
                  staticClass: "detail-left"
                },
                [
                  _vm.detailData.linkStatus &&
                  _vm.detailData.link.linkType === 2
                    ? _c("img", {
                        staticClass: "detail-poster",
                        attrs: { src: _vm.poster, alt: "" }
                      })
                    : _c("img", {
                        staticClass: "detail-code",
                        attrs: { src: _vm.detailData.liveCodeUrl, alt: "" }
                      }),
                  _c("div", { staticClass: "detail-title" }, [
                    _vm._v(_vm._s(_vm.detailData.liveCodeName))
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", round: "" },
                      on: {
                        click: function($event) {
                          return _vm.fn_download("code")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "iconfont icon-download" }),
                      _vm._v(" 下载活码")
                    ]
                  ),
                  _vm.detailData.linkStatus &&
                  _vm.detailData.link.linkType === 2
                    ? _c(
                        "el-button",
                        {
                          attrs: { icon: "iconfont icon-picture", round: "" },
                          on: {
                            click: function($event) {
                              return _vm.fn_download("poster")
                            }
                          }
                        },
                        [_vm._v("下载海报")]
                      )
                    : _vm._e(),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: "活码已失效",
                        disabled: !_vm.isEnd,
                        placement: "top"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: {
                                icon: "iconfont icon-edit",
                                disabled: _vm.isEnd,
                                round: ""
                              },
                              on: { click: _vm.fn_edit }
                            },
                            [_vm._v("修改活码")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "detail-right" }, [
                _c("div", { staticClass: "detail-right__items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("创建时间：")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.util.timeTofmt(
                              _vm.createTime,
                              "yyyy-MM-dd hh:mm"
                            )
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("所属分组：")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.detailData.groupName))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("涨粉账号：")]),
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("ly-drow", {
                          attrs: {
                            list: _vm.detailData.users,
                            name: "name",
                            size: 999999
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("失效时间：")
                      ]),
                      !!_vm.detailData.expireTime && _vm.detailData.expireStatus
                        ? [_vm._v("已失效 ")]
                        : [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !!_vm.detailData.expireTime
                                    ? _vm.util.timeTofmt(
                                        _vm.detailData.expireTime,
                                        "yyyy-MM-dd hh:mm"
                                      )
                                    : "永不失效"
                                ) +
                                " "
                            )
                          ]
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "detail-right__items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("用户设置")]),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("用户标签：")]),
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _vm.detailData.tagStatus
                          ? _c("ly-drow", {
                              attrs: {
                                list: _vm.detailData.tags,
                                name: "name",
                                dep: "",
                                size: 999999
                              }
                            })
                          : [_vm._v("无")]
                      ],
                      2
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("昵称备注：")
                      ]),
                      _vm.detailData.nicknameRemark
                        ? _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "flex cus-flex" }, [
                              _c("span", { staticClass: "cus-view" }, [
                                _c("i", {
                                  staticClass: "el-icon-s-custom ly-tag_icon"
                                }),
                                _c("span", { staticClass: "suffix" }, [
                                  _vm._v(
                                    "微信昵称-" +
                                      _vm._s(_vm.detailData.nicknameRemark)
                                  )
                                ])
                              ])
                            ])
                          ])
                        : [_vm._v("无")]
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "detail-right__items" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("链接设置")]),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("活码链接：")]),
                    _vm.detailData.linkStatus
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "copy_url",
                                attrs: {
                                  size: "small",
                                  round: "",
                                  type: "primary",
                                  "data-clipboard-text":
                                    _vm.detailData.link.liveCodeLink
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_copy("copy_url")
                                  }
                                }
                              },
                              [_vm._v("复制链接")]
                            )
                          ],
                          1
                        )
                      : _c("div", [_vm._v("无")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("联系账号：")
                      ]),
                      _vm.detailData.contactStatus
                        ? _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c("ly-drow", {
                                attrs: {
                                  list: _vm.contactUsers,
                                  name: "name",
                                  size: 999999
                                }
                              })
                            ],
                            1
                          )
                        : [_vm._v("无")]
                    ],
                    2
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }