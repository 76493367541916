/*
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-12 19:55:13
 * @LastEditTime: 2021-12-14 20:09:06
 */
import axios from '@/common/ajax.js'

// const apiD = '/scrm-api/live'

// 活动
export const activity = {
  save: params => axios.post('/scrm-api/live/code/create', params), // 创建活码
  update: params => axios.put(`/scrm-api/live/code/update/${params.id}`, params.form), // 修改活码
  simpleRecordOverview: params => axios.get('/scrm-api/live/code/analysis/recordOverview', { params }), // 单渠道获取数据概览
  simpleGrowthTrend: params => axios.get('/scrm-api/live/code/analysis/growthTrend', { params }) // 单渠道获取增长趋势

}
// 渠道分析
export const analysis = {
  simpleRecordOverview: params => axios.get('/scrm-api/live/code/analysis/recordOverview', { params }), // 单渠道数据概览
  simpleGrowthTrend: params => axios.get('/scrm-api/live/code/analysis/growthTrend', { params }), // 单渠道增长趋势
  multimediaRecordOverview: params => axios.post('/scrm-api/live/code/analysis/multimedia/recordOverview', params), // 多渠道数据概览
  multimediaGrowthTrend: params => axios.post('/scrm-api/live/code/analysis/multimedia/growthTrend', params), // 多渠道增长趋势
  searchAll: params => axios.get('/scrm-api/live/code/group/searchAll', { params }), // 分组列表
  fuzzySearch: params => axios.get('/scrm-api/live/code/group/fuzzySearch', { params }), // 分组列表详情
  contrast: params => axios.post('/scrm-api/live/code/analysis/multimedia/contrast', params), // 多渠道渠道对比
  getCodeStartTime: params => axios.post('/scrm-api/live/code/analysis/startTime', params)// 活码最早时间
}

// 列表
export const list = {
  reqQuery: params => axios.post('/scrm-api/live/code/list', params), // 列表
  detail: params => axios.get(`/scrm-api/live/code/${params.id}`), // 活码详情
  delete: params => axios.delete(`/scrm-api/live/code/delete/${params.id}`), // 删除活码
  downLoad: params => axios.get(`/scrm-api/live/code/downLoad?${params.idList}`, {
    responseType: 'blob'
  }) // 批量下载活码
}

// 分组管理
export const groupManagement = {
  getTopic: (params) => axios.get('/scrm-api/live/code/group/searchAll', { params }), // 分组列表
  getTopicFilter: (params) => axios.get('/scrm-api/live/code/group/searchList', { params }), // 分组列表
  addTopic: (params) => axios.post('/scrm-api/live/code/group/create', params), // 添加分组
  editTopic: (params) => axios.put('/scrm-api/live/code/group/update', params), // 修改分组名称
  delTopic: (params) => axios.delete(`/scrm-api/live/code/group/delete?id=${params.id}`), // 删除分组
  doGroup: (params) => axios.post('/scrm-api/live/code/group/change', params) // 调整分组
}

// 通用
export const common = {
  urlInfo: params => axios.get('/wecom/system/urlInfo', { params }) // 通过url获取信息
}
