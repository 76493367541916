<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-14 15:19:56
 * @LastEditTime: 2021-09-07 16:59:20
-->
<template>
  <el-dialog
    :title="isEdit ? '修改分组' : '添加分组'"
    :visible.sync="visible"
    @close="handleClose"
    width="480px"
  >
    <div class="form-box">
      <label>分组名称：</label>
      <el-input
        size="medium"
        v-model.trim="name"
        maxlength="20"
        show-word-limit
        placeholder="请输入分组名称"
      ></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="medium" @click="handleSave"
        >保 存</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { groupManagement } from '../http'
import * as lyCheck from '@/common/checkSpecificKey'
export default {
  data () {
    const checkName = (rule, value, callback) => {
      if (value !== null && value !== '') {
        if (!lyCheck.checkSpecificKey(value)) {
          callback(new Error('名称中请勿包含特殊字符'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请填写兑换码名称'))
      }
    }
    return {
      visible: false,
      isEdit: false,
      name: '',
      editId: '',
      msgBox: null
    }
  },
  methods: {

    // 保存分组
    async handleSave () {
      if (this.name === '') {
        this.msgBox = this.$message.info('分组名称不能为空！')
        return false
      }

      const method = this.isEdit ? 'editTopic' : 'addTopic'
      const params = this.isEdit ? { id: this.editId, groupName: this.name } : { groupName: this.name }
      const res = await groupManagement[method](params)
      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }
      this.visible = false
      this.$emit('change')
    },

    // 关闭弹框
    handleClose () {
      this.isEdit = false
      this.editId = ''
      this.name = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.form-box {
  display: flex;
  align-items: center;
  label {
    color: #212b36;
  }
  .el-input {
    width: 248px;
  }
}
</style>
