var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-card" }, [
    _c("div", { staticClass: "edit-card__header" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _c(
      "div",
      { staticClass: "edit-card__content" },
      [
        _c(
          "el-form",
          {
            ref: "otherFormRef",
            staticStyle: { width: "60%", "min-width": "560px" },
            attrs: {
              model: _vm.otherForm,
              rules: _vm.rules,
              "label-width": "150px",
              size: "medium"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "show_title", label: "活动标题" } },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "15",
                    "show-word-limit": "",
                    placeholder: "请输入活动标题"
                  },
                  on: {
                    change: function($event) {
                      return _vm.fn_setOther("show_title", $event)
                    }
                  },
                  model: {
                    value: _vm.otherForm.show_title,
                    callback: function($$v) {
                      _vm.$set(_vm.otherForm, "show_title", $$v)
                    },
                    expression: "otherForm.show_title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "bg_title", label: "封面标题" } },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "16",
                    "show-word-limit": "",
                    placeholder: "请输入封面标题"
                  },
                  on: {
                    change: function($event) {
                      return _vm.fn_setOther("bg_title", $event)
                    }
                  },
                  model: {
                    value: _vm.otherForm.bg_title,
                    callback: function($$v) {
                      _vm.$set(_vm.otherForm, "bg_title", $$v)
                    },
                    expression: "otherForm.bg_title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "rule_desc", label: "活动规则" } },
              [
                _c("ly-editor", {
                  attrs: {
                    title: "活动规则",
                    id: "redRule",
                    content: _vm.otherForm.rule_desc,
                    recoverContent: _vm.defaultRules,
                    maxLength: 2000
                  },
                  on: { getContent: _vm.fn_getRules }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }