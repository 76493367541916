<!--
 * @Descripttion: 参与用户页面
 * @version: 1.0.0
-->
<template>
  <div class="gf-users page-warp-bg">
    <div class="select-box">
      <label style="color: rgba(0, 0, 0, 0.85)">选择活动：</label>
      <el-select
        size="medium"
        v-model="activity_id"
        @change="handleChange"
        filterable
        placeholder="请选择活动"
      >
        <el-option
          v-for="item in activities"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          type="primary"
          icon="iconfont icon-download"
          @click="handleExport"
          >导出数据</el-button
        >
      </template>

      <template #nickname="{ row }">
        <div class="avatar-name">
          <el-image
            class="avatar"
            :src="row.avatar"
            fit="cover"
            lazy
          ></el-image>
          <div class="name">
            <span>{{ row.nickname }}</span>
          </div>
        </div>
      </template>

      <template #group_name="{ row }">
        <!-- <more-popover title="所在群聊" :list="row.group_name">
          <template #reference>{{`${row.group_name.length}个 `}}</template>群聊
          <template #item="{item}">
            <div class="ly-team small">
              <i class="icon qy-icon-group"></i>
            </div>
            <span class="m-group_name text-ellipsis">{{item}}</span>
          </template>
        </more-popover> -->
        <!-- <span>{{row.group_name && row.group_name.join(',')}}</span> -->
        <ly-tool-tip
          :line="2"
          :content="row.group_name ? row.group_name.join(',') : '-'"
        ></ly-tool-tip>
      </template>

      <template #group_status="{ row }">
        <ly-status type="success" v-if="row.group_status === 1">在群</ly-status>
        <ly-status type="danger" v-if="row.group_status === 2">退群</ly-status>
      </template>

      <template #invitor_name="{ row }">
        <ly-tag :content="row.invitor_name" :size="3" only>
          <i class="iconfont icon-superior" slot="left"></i>
        </ly-tag>
      </template>

      <template #step="{ row }">
        <div class="step-box">
          <div
            v-if="row.first_finish !== 0"
            class="item"
            :class="{ 'is-done': row.first_finish === 1 }"
          >
            一阶：<span>{{
              row.first_finish === 1 ? "已完成" : "未完成"
            }}</span>
          </div>
          <div
            v-if="row.second_finish !== 0"
            class="item"
            :class="{ 'is-done': row.second_finish === 1 }"
          >
            二阶：<span>{{
              row.second_finish === 1 ? "已完成" : "未完成"
            }}</span>
          </div>
          <div
            v-if="row.third_finish !== 0"
            class="item"
            :class="{ 'is-done': row.third_finish === 1 }"
          >
            三阶：<span>{{
              row.third_finish === 1 ? "已完成" : "未完成"
            }}</span>
          </div>
        </div>
      </template>

      <template #channel_id="{ row }">
        {{ row.channel_name || "-" }}
      </template>

      <template #add_user_name="{ row }">
        <ly-drow :list="row.add_user_name"></ly-drow>
      </template>

      <template #add_status="{ row }">
        <ly-status type="success" v-if="row.add_status === 1">已添加</ly-status>
        <ly-status type="danger" v-else-if="row.add_status === 2"
          >已删除</ly-status
        >
        <span v-else>-</span>
      </template>
    </ly-table>
    <!--  用户详情 -->
    <user-detail ref="userDetail"></user-detail>
  </div>
</template>
<script>
import { user } from './http.js'
import lyTable from '@/components/mixins/ly-table.js'
import userDetail from './components/userDetail.vue'
import lyDrow from '@/components/lyDrow'
import lyTag from '@/components/lyTag'
export default {
  components: {
    userDetail, lyDrow, lyTag
  },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: user,
      activity_id: '',
      activity: {
        activity_name: ''
      },
      exportLoading: false,
      activities: [],
      channelOps: [{ label: '全部', value: '' }],
      groupOps: []
      // board: {
      //   visitor_num: { label: '活动访问人数', tips: '活动页访问用户数', value: 0 },
      //   in_num: { label: '入群用户数', tips: '活动期间，通过本活动新增入群用户数', value: 0 },
      //   out_num: { label: '退群用户数', tips: '通过本活动新增入群又退群的用户数', value: 0 },
      //   finish_task_num: { label: '任务完成率', tips: '最少完成一阶的用户数/入群用户数', value: '0%' }
      // }
    }
  },
  provide () {
    return {
      activity: this.activity
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        scrollEl: '.page-warp-bg',
        operation: [{
          label: '详情',
          handler (row) {
            _this.$refs.userDetail.visible = true
            _this.$refs.userDetail.userInfo = row
          }
        }],
        operationWidth: 75,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname',
            key: 'nick_name',
            slot: 'nickname',
            search: true,
            columnProps: {
              minWidth: 120
            }
          },
          {
            label: '所在群聊',
            prop: 'group_name',
            slot: 'group_name',
            key: 'group_id',
            width: 100,
            search: true,
            type: 'select',
            source: this.groupOps
          },
          {
            label: '群内状态',
            prop: 'group_status',
            width: 90,
            slot: 'group_status',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: 0 },
              { label: '在群', value: 1 },
              { label: '退群', value: 2 }
            ]
          },
          {
            label: '直接上级',
            prop: 'invitor_name',
            slot: 'invitor_name',
            width: 120
          },
          {
            label: '任务进度',
            prop: 'step',
            slot: 'step',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: 0 },
              { label: '完成一阶任务', value: 1 },
              { label: '完成二阶任务', value: 2 },
              { label: '完成三阶任务', value: 3 }
            ],
            columnProps: {
              minWidth: 120
            }
          },
          {
            label: '来源渠道',
            prop: 'channel_id',
            width: 100,
            slot: 'channel_id',
            search: true,
            type: 'select',
            source: this.channelOps
          },
          {
            label: '参与时间',
            prop: 'join_date',
            key: ['join_start', 'join_end'],
            width: 110,
            search: true,
            type: 'daterange'
          },
          {
            label: '有效邀请人数',
            prop: 'invite_num',
            sortable: true,
            width: 140,
            align: 'center'
          },
          {
            label: '添加账号',
            prop: 'add_user_name',
            key: 'add_user_id',
            width: 220,
            slot: 'add_user_name',
            search: true,
            type: 'emp'
          },
          {
            label: '添加状态',
            prop: 'add_status',
            width: 100,
            slot: 'add_status',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: 0 },
              { label: '已添加', value: 1 },
              { label: '已删除', value: 2 }
            ]
          },
          {
            label: '用户类型',
            prop: 'user_type',
            width: 95,
            search: true,
            type: 'select',
            align: 'center',
            source: [
              { label: '全部', value: null },
              { label: '新用户', value: 1 },
              { label: '老用户', value: 2 }
            ],
            formatter (row, column, cellValue, inde) {
              if (cellValue === 1) {
                return '新用户'
              } else if (cellValue === 2) {
                return '老用户'
              } else {
                return '-'
              }
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.activity_id = this.$route.query.id * 1
    this.getActsOps()
    this.getGroupOps()
    this.channelSel()
    // this.boardQuery()
  },
  methods: {
    // 获取活动选项
    getActsOps () {
      this.COMM_HTTP.selectList().then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.activities = res.list || []
        this.activity.activity_name = this.getActName()
      })
    },
    /**
     * @description: 获取所在群聊的群名
     * @param {*}
     * @return {*}
     */
    getGroupOps () {
      this.COMM_HTTP.groupSelect({
        activity_id: this.activity_id
      }).then(res => {
        this.groupOps = [{ label: '全部', value: '' }]
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        const result = res.list || []
        result.map(e => {
          this.groupOps.push({
            label: e.group_name,
            value: e.group_id
          })
        })
      })
    },
    // 获取渠道选项
    channelSel () {
      this.COMM_HTTP.channelSel({
        activity_id: this.activity_id,
        page: 1,
        per_page: 1000
      }).then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        this.channelOps = [{ label: '全部', value: '' }]
        const result = res.list || []
        result.map((e, i) => {
          this.channelOps.push({
            label: e.channel_name,
            value: e.id
          })
        })
      })
    },
    /**
     * @description: 数据看板信息
     * @param {*}
     * @return {*}
     */
    boardQuery () {
      this.COMM_HTTP.boardQuery({ activity_id: this.activity_id }).then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        for (const key in res) {
          if (key === 'finish_task_num') {
            this.board[key].value = `${res[key] || 0}%`
          } else this.board[key].value = res[key] || 0
        }
      })
    },
    /**
     * @description: 格式化参数
     * @param {*}
     * @return {*}
     */
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activity_id
      }
    },
    /**
     * @description: 下载回调
     * @param {*}
     * @return {*}
     */
    async handleExport () {
      this.exportLoading = true
      const params = this.formatterParams
        ? this.formatterParams(this.params)
        : this.params
      const res = await this.COMM_HTTP.reqExport({
        page: 1,
        per_page: 9999,
        ...params
      })

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        this.exportLoading = false
        return false
      }
      this.handleDownload(res.url)
      this.exportLoading = false
    },
    /**
     * @description: 获取活动名称
     * @param {*}
     * @return {*}
     */
    getActName () {
      const _obj = this.activities.find(n => {
        return n.id === this.activity_id
      })
      return _obj.title
    },
    // 获取Excel名称
    getFileName () {
      let _name = ''
      let actName = ''
      if (!this.activity.activity_name) {
        actName = this.getActName()
      } else {
        actName = this.activity.activity_name
      }
      _name = `[${actName}]-参与用户-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },
    /**
     * @description: 下载url
     * @param {*} url
     * @return {*}
     */
    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    },
    /**
     * @description: 改变活动
     * @param {*}
     * @return {*}
     */
    handleChange () {
      this.$router.replace({ path: this.$route.path, query: { id: this.activity_id } })
      this.activity.activity_name = this.getActName()
      this.$nextTick(() => {
        this.channelSel()
        this.getGroupOps()
        this.queryData()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.gf-users {
  display: flex;
  flex-direction: column;
}
.select-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  .el-select {
    width: 320px;
  }
}
.board {
  margin-bottom: 24px;
}
.board-item {
  height: 100px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 16px 24px;
  box-sizing: border-box;
  align-items: center;
  background: #fdfdfd;
  box-shadow: 0px 16px 32px -4px rgb(145 158 171 / 24%),
    0px 0px 2px 0px rgb(145 158 171 / 24%);
  .desc {
    display: flex;
    align-items: center;
    width: 100%;
    span {
      flex: 1;
    }
    i {
      width: 20px;
    }
  }
  .value {
    font-size: 26px;
    font-weight: bold;
    align-self: flex-start;
  }
}
.step-box {
  span {
    color: #909399;
  }
  .is-done span {
    color: #00a86e;
  }
}
.icon-superior {
  font-size: 14px;
  color: #2b7cff;
}
::v-deep {
  .m-group_name {
    flex: 1;
  }
}
</style>
