<!--
 * @Author: zhanln
 * @Date: 2021-11-18 16:17:41
 * @LastEditTime: 2021-12-07 16:24:21
 * @LastEditors: Please set LastEditors
 * @Description: 无库存规则
-->

<template>
  <el-form :model="form" label-width="160px" class="act-form" size="medium">
    <el-form-item label="无库存规则：">
      <el-radio-group v-model="form.stop_type" class="rule-form__radio">
        <el-radio :label="2" :disabled="isEdit && !isCopy"
          >所有阶段库存为0时，活动自动暂停
          <el-tooltip class="item" placement="top" popper-class="q-tooltip">
            <div slot="content">
              选择此选项，开启多阶段任务时，其中一个阶段奖品被用户领取完后，<br />活动仍然继续，直至所有阶段库存被领取完，才自动暂停。<br />奖品无库存后，此处追加库存，可以在「奖品记录」将奖品重新发放给用户。
            </div>
            <i class="ri-question-line"></i>
          </el-tooltip>
        </el-radio>
        <el-radio :label="1" :disabled="isEdit && !isCopy"
          >任意阶段库存为0时，活动自动暂停
          <el-tooltip class="item" placement="top" popper-class="q-tooltip">
            <div slot="content">
              选择此选项，开启多阶任务时，其中一个阶段奖品被用户领取完后，活动就自动暂停。
            </div>
            <i class="ri-question-line"></i> </el-tooltip
        ></el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
// store
import { mapState } from 'vuex'
export default {
  name: 'taskStockRule',
  data () {
    return {
      form: {
        stop_type: 2
      }
    }
  },
  computed: {
    ...mapState({
      isEdit: state => state.task.isEdit,
      isCopy: state => state.task.isCopy
    })
  }
}
</script>

<style lang="scss" scoped>
.rule-form__radio {
  .el-radio {
    display: block;
    line-height: 36px;
  }
}
</style>
