var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reward-detail" },
    [
      _c(
        "el-radio-group",
        {
          attrs: { disabled: _vm.allowEdit },
          model: {
            value: _vm.type,
            callback: function($$v) {
              _vm.type = $$v
            },
            expression: "type"
          }
        },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "el-tooltip",
            {
              key: index,
              staticClass: "item",
              attrs: {
                placement: "top",
                content: "功能准备上线中，敬请期待",
                disabled: !item.isDisable
              }
            },
            [
              _c(
                "el-radio",
                { attrs: { label: item.value, disabled: item.isDisable } },
                [_vm._v(_vm._s(item.name))]
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === 1,
              expression: "type === 1"
            }
          ],
          ref: "diyFormRef",
          staticClass: "act-form",
          attrs: { model: _vm.diyForm, rules: _vm.diyRule, size: "medium" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "content" } },
            [
              _c(
                "div",
                { staticClass: "editor act-form__editor" },
                [
                  _c("wang-editor", {
                    staticClass: "editor-content",
                    attrs: {
                      refName: "diyEdit",
                      content: _vm.diyForm.content,
                      menus: ["link"],
                      getText: _vm.fn_getDiyText,
                      placeholder: "支持输入文本，链接",
                      setCont: function(html) {
                        return _vm.fn_setDiyContent(html)
                      }
                    },
                    on: {
                      eFocus: _vm.fn_detailFocus,
                      eBlur: _vm.fn_detailBlurs
                    }
                  }),
                  _c("div", { staticClass: "editor-count" }, [
                    _vm._v(_vm._s(_vm.diyForm.diy_rule_length) + " / 1000")
                  ])
                ],
                1
              ),
              _vm.urlTips
                ? _c(
                    "p",
                    {
                      staticClass: "q-info text-small",
                      staticStyle: {
                        "line-height": "1.6",
                        "margin-bottom": "0"
                      }
                    },
                    [
                      _vm._v(
                        " 请确保奖品链接页面无法复制分享，或使用人工审核的表单类链接，避免奖品内容被分享传播，造成损失或用户投诉。 "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    placement: "top",
                    effect: "light",
                    "popper-class": "q-tooltip is-light"
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "24px 24px 16px",
                          width: "528px"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "https://image.01lb.com.cn//uploads/wecom/22/0209/1644377152kY49oBxr.png",
                            width: "528",
                            alt: ""
                          }
                        })
                      ]
                    )
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "q-primary text-small inline-flex",
                      staticStyle: {
                        "line-height": "1.6",
                        "margin-bottom": "0"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-help1",
                        staticStyle: { "margin-right": "2px" }
                      }),
                      _vm._v(" 用户点击抽奖后，如何获得奖品 ")
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === 2,
              expression: "type === 2"
            }
          ],
          ref: "codeFormRef",
          attrs: { model: _vm.codeForm, rules: _vm.codeRule, size: "medium" }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "reward-detail__form-item",
              attrs: { prop: "codeId" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "act-form__input",
                  attrs: {
                    placeholder: "请选择",
                    clearable: "",
                    disabled: _vm.allowEdit
                  },
                  on: { change: _vm.fn_changeCode },
                  model: {
                    value: _vm.codeForm.codeId,
                    callback: function($$v) {
                      _vm.$set(_vm.codeForm, "codeId", $$v)
                    },
                    expression: "codeForm.codeId"
                  }
                },
                _vm._l(_vm.codeList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          !_vm.allowEdit
            ? _c(
                "div",
                { staticClass: "reward-detail__tool" },
                [
                  _vm._v(" 选择要关联活动的兑换码奖品 "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.fn_jumpToCode("code")
                        }
                      }
                    },
                    [_vm._v("去创建兑换码")]
                  ),
                  _c("div", {
                    staticClass: "el-divider el-divider--vertical",
                    attrs: { "data-v-3cefa108": "" }
                  }),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "lydebounce",
                          rawName: "v-lydebounce",
                          value: ["button", _vm.fn_getCodeList],
                          expression: "['button', fn_getCodeList]"
                        }
                      ],
                      attrs: { type: "text", size: "mini" }
                    },
                    [_vm._v("刷新")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { prop: "rule" } }, [
            _c(
              "div",
              { staticClass: "editor act-form__editor" },
              [
                _c("wang-editor", {
                  attrs: {
                    refName: "codeEdit",
                    content: _vm.codeForm.rule,
                    menus: ["link"],
                    getText: _vm.fn_getCodeText,
                    placeholder: "请输入兑换规则",
                    setCont: function(html) {
                      return _vm.fn_setCodeRule(html)
                    }
                  },
                  on: { eFocus: _vm.fn_detailFocus, eBlur: _vm.fn_detailBlurs }
                }),
                _c("div", { staticClass: "editor-count" }, [
                  _c(
                    "span",
                    {
                      staticClass: "editor-regain",
                      on: {
                        click: function($event) {
                          return _vm.fn_regain("code")
                        }
                      }
                    },
                    [_vm._v("恢复默认文本")]
                  ),
                  _vm._v(_vm._s(_vm.codeForm.code_rule_length) + " / 1000 ")
                ])
              ],
              1
            )
          ]),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                placement: "top",
                effect: "light",
                "popper-class": "q-tooltip is-light"
              }
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c(
                  "div",
                  { staticStyle: { margin: "24px 24px 16px", width: "528px" } },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "https://image.01lb.com.cn//uploads/wecom/22/0209/1644375191990z9GDl.png",
                        width: "528",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "q-primary text-small inline-flex",
                  staticStyle: { "line-height": "1.6", "margin-bottom": "0" }
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-help1",
                    staticStyle: { "margin-right": "2px" }
                  }),
                  _vm._v("用户点击抽奖后，如何获得奖品 ")
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === 3,
              expression: "type === 3"
            }
          ],
          ref: "formRef",
          attrs: { model: _vm.form, rules: _vm.formRule, size: "medium" }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "reward-detail__form-item",
              attrs: { prop: "formId" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "act-form__input",
                  attrs: {
                    placeholder: "请选择",
                    clearable: "",
                    disabled: _vm.allowEdit
                  },
                  model: {
                    value: _vm.form.formId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "formId", $$v)
                    },
                    expression: "form.formId"
                  }
                },
                _vm._l(_vm.formList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          !_vm.allowEdit
            ? _c(
                "div",
                { staticClass: "reward-detail__tool" },
                [
                  _vm._v(" 选择要关联活动的表单 "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.fn_jumpToCode("form")
                        }
                      }
                    },
                    [_vm._v("去创建表单")]
                  ),
                  _c("div", { staticClass: "el-divider el-divider--vertical" }),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "lydebounce",
                          rawName: "v-lydebounce",
                          value: ["button", _vm.fn_getFormList],
                          expression: "['button', fn_getFormList]"
                        }
                      ],
                      attrs: { type: "text", size: "mini" }
                    },
                    [_vm._v("刷新")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { prop: "rule" } }, [
            _c(
              "div",
              { staticClass: "editor act-form__editor" },
              [
                _c("div", { staticClass: "editor-header" }, [
                  _vm._v("奖品规则：")
                ]),
                _c("wang-editor", {
                  staticClass: "no-tool",
                  attrs: {
                    refName: "formEdit",
                    content: _vm.form.rule,
                    menus: [],
                    getText: _vm.fn_getFormText,
                    placeholder: "请输入奖品规则",
                    setCont: function(html) {
                      return _vm.fn_setFormRule(html)
                    }
                  },
                  on: { eFocus: _vm.fn_detailFocus, eBlur: _vm.fn_detailBlurs }
                }),
                _c("div", { staticClass: "editor-count" }, [
                  _c(
                    "span",
                    {
                      staticClass: "editor-regain",
                      on: {
                        click: function($event) {
                          return _vm.fn_regain("form")
                        }
                      }
                    },
                    [_vm._v("恢复默认文本")]
                  ),
                  _vm._v(_vm._s(_vm.form.form_rule_length) + " / 1000 ")
                ])
              ],
              1
            )
          ]),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                placement: "top",
                effect: "light",
                "popper-class": "q-tooltip is-light"
              }
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c(
                  "div",
                  { staticStyle: { margin: "24px 24px 16px", width: "528px" } },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "https://image.01lb.com.cn//uploads/wecom/22/0209/1644375232EMN84R1J.png",
                        width: "528",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "q-primary text-small inline-flex",
                  staticStyle: { "line-height": "1.6", "margin-bottom": "0" }
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-help1",
                    staticStyle: { "margin-right": "2px" }
                  }),
                  _vm._v("用户点击抽奖后，如何获得奖品 ")
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === 4,
              expression: "type === 4"
            }
          ],
          ref: "redFormRef",
          attrs: { model: _vm.redForm, rules: _vm.redFormRule, size: "medium" }
        },
        [
          _c("el-form-item", { attrs: { prop: "reward_max_cash" } }, [
            _c(
              "div",
              { staticClass: "q-bg is-default rule" },
              [
                _vm._v(" 每抽中一次领取现金红包"),
                _c("el-input-number", {
                  staticClass: "number-small no-error",
                  attrs: {
                    "controls-position": "right",
                    min: 0.31,
                    max: this.redForm.reward_max_cash,
                    precision: 2
                  },
                  on: {
                    blur: function($event) {
                      return _vm.fn_handleBlur($event, "reward_min_cash", 0.31)
                    }
                  },
                  model: {
                    value: _vm.redForm.reward_min_cash,
                    callback: function($$v) {
                      _vm.$set(_vm.redForm, "reward_min_cash", $$v)
                    },
                    expression: "redForm.reward_min_cash"
                  }
                }),
                _vm._v(" ~ "),
                _c("el-input-number", {
                  staticClass: "number-small",
                  attrs: {
                    "controls-position": "right",
                    min: _vm.redForm.reward_min_cash,
                    max: 5,
                    precision: 2
                  },
                  on: {
                    blur: function($event) {
                      return _vm.fn_handleBlur($event, "reward_max_cash", 0.31)
                    }
                  },
                  model: {
                    value: _vm.redForm.reward_max_cash,
                    callback: function($$v) {
                      _vm.$set(_vm.redForm, "reward_max_cash", $$v)
                    },
                    expression: "redForm.reward_max_cash"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }