<!--
 * @Author: zhanln
 * @Date: 2021-12-06 15:18:49
 * @LastEditTime: 2022-02-24 14:31:19
 * @LastEditors: zhanln
 * @Description:
-->
<template>
  <div class="form-config-container">
    <el-form
      label-position="top"
      @submit.native.prevent
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
    >
      <el-form-item label="表单名称" prop="title">
        <el-input
          v-model.trim="ruleForm.title"
          placeholder="请输入表单名称"
          maxlength="20"
          show-word-limit
          @input="inputTitle"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as lyCheck from '@/common/checkSpecificKey'
export default {
  props: ['data', 'formName'],
  model: {
    prop: 'formName',
    event: 'change'
  },
  data () {
    const checkName = (rule, value, callback) => {
      if (value !== null && value !== '') {
        if (!lyCheck.checkSpecificKey(value)) {
          callback(new Error('名称中请勿包含特殊字符'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入表单名称'))
      }
    }
    return {
      ruleForm: {
        title: ''
      },
      rules: {
        title: [
          { validator: checkName, required: true, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    formName (newVal) {
      this.ruleForm.title = newVal
    }
  },
  // computed: {
  //   title: {
  //     set (val) {
  //       this.$emit('change', val)
  //     },
  //     get () {
  //       return this.formName
  //     }
  //   }
  // },
  methods: {
    inputTitle () {
      this.$emit('change', this.ruleForm.title)
    },
    submitForm () {
      let verify = true
      this.$refs.ruleForm.validate((valid) => {
        this.$emit('change', this.ruleForm.title)
        if (valid) {
          verify = true
        } else {
          verify = false
        }
      })

      return verify
    }
  }
}
</script>
