var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "applet" }, [
    _c("div", { staticClass: "app-title" }, [
      _vm.appletInfo.weappiconurl
        ? _c("img", {
            staticClass: "app-icon",
            attrs: { src: _vm.appletInfo.weappiconurl, alt: "" }
          })
        : _vm._e(),
      _vm._v(
        " " + _vm._s(_vm.appletInfo.title || _vm.appletInfo.appTitle) + " "
      )
    ]),
    _c("div", { staticClass: "app-desc" }, [
      _vm._v(_vm._s(_vm.appletInfo.des_1))
    ]),
    _c("img", {
      staticClass: "applet-img",
      attrs: {
        src:
          _vm.appletInfo.cover_image ||
          _vm.cover ||
          require("@/assets/images/applet.png")
      }
    }),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-sys" }, [
      _c("img", { attrs: { src: require("@/assets/svg/xiaochengxu.svg") } }),
      _vm._v("小程序")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }