var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.cardLoading,
          expression: "cardLoading"
        }
      ],
      ref: "linkFormRef",
      staticClass: "act-form",
      attrs: {
        model: _vm.linkForm,
        rules: _vm.linkFormRules,
        "label-width": "125px",
        size: "medium"
      }
    },
    [
      _c("el-form-item", { attrs: { label: "活码链接" } }, [
        _c(
          "div",
          { staticClass: "flex link-flex" },
          [
            _c("el-switch", {
              attrs: { "active-value": true, "inactive-value": false },
              on: {
                change: function($event) {
                  return _vm.fn_setForm($event, "link_open")
                }
              },
              model: {
                value: _vm.linkForm.link_open,
                callback: function($$v) {
                  _vm.$set(_vm.linkForm, "link_open", $$v)
                },
                expression: "linkForm.link_open"
              }
            }),
            _c("span", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v("开启后将会为该活码生成一个链接页")
            ])
          ],
          1
        )
      ]),
      _vm.linkForm.link_open
        ? [
            _c(
              "el-form-item",
              { attrs: { label: "链接页风格" } },
              [
                _c(
                  "el-radio-group",
                  {
                    on: {
                      change: function($event) {
                        return _vm.fn_setForm($event, "link_type")
                      }
                    },
                    model: {
                      value: _vm.linkForm.link_type,
                      callback: function($$v) {
                        _vm.$set(_vm.linkForm, "link_type", $$v)
                      },
                      expression: "linkForm.link_type"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("简约风格")
                    ]),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("自定义风格")
                    ])
                  ],
                  1
                ),
                _c("span", { staticClass: "q-info" }, [
                  _vm._v("（选择自定义风格会生成最终的宣传海报）")
                ])
              ],
              1
            ),
            _vm.linkForm.link_type === 1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "引导说明", prop: "guide" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        maxlength: "20",
                        resize: "none",
                        "show-word-limit": "",
                        placeholder:
                          "引导用户扫码，例如：添加老师领取独家私域资料"
                      },
                      on: {
                        change: function($event) {
                          return _vm.fn_setForm($event, "guide")
                        }
                      },
                      model: {
                        value: _vm.linkForm.guide,
                        callback: function($$v) {
                          _vm.$set(_vm.linkForm, "guide", $$v)
                        },
                        expression: "linkForm.guide"
                      }
                    })
                  ],
                  1
                )
              : [
                  _c(
                    "el-form-item",
                    {
                      ref: "poster",
                      attrs: { label: "链接页背景", prop: "link_bg" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploadFile",
                              class: [
                                "upload",
                                _vm.linkForm.link_bg.length === 0
                                  ? ""
                                  : "upload-empty"
                              ],
                              attrs: {
                                action: "#",
                                accept: ".png, .jpg",
                                "http-request": _vm.requestQiniu,
                                "on-remove": _vm.removePoster,
                                "list-type": "picture-card",
                                limit: 1,
                                "file-list": _vm.linkForm.link_bg
                              }
                            },
                            [
                              _c("div", { staticClass: "default" }, [
                                _c("i", {
                                  staticClass: "iconfont icon-picture"
                                }),
                                _c("span", [_vm._v("上传图片")])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "q-info text-small",
                              staticStyle: {
                                "line-height": "22px",
                                "margin-left": "24px"
                              }
                            },
                            [
                              _vm._v(" 背景设计须知："),
                              _c("br"),
                              _vm._v(
                                " (1) 建议图片尺寸：750px*1334px，分辨率72 "
                              ),
                              _c("br"),
                              _vm._v(" (2) 支持JPG、PNG格式，图片大小3MB以内 "),
                              _c("br"),
                              _vm._v(" (3) 二维码可在预览区域内移动展示位置。 ")
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "expire-item",
                      attrs: { label: "虚拟剩余时间", error: _vm.expireError }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex link-flex" },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": true,
                              "inactive-value": false
                            },
                            on: {
                              change: function($event) {
                                return _vm.fn_setForm(
                                  $event,
                                  "unreal_expire_open"
                                )
                              }
                            },
                            model: {
                              value: _vm.linkForm.unreal_expire_open,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.linkForm,
                                  "unreal_expire_open",
                                  $$v
                                )
                              },
                              expression: "linkForm.unreal_expire_open"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.linkForm.unreal_expire_open
                        ? _c("div", { staticClass: "link-expire" }, [
                            _c(
                              "div",
                              { staticClass: "link-expire__item" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "link-expire__label" },
                                  [
                                    _c("span", { staticClass: "q-danger" }, [
                                      _vm._v("*")
                                    ]),
                                    _vm._v(" 剩余时间文案：")
                                  ]
                                ),
                                _c("el-input", {
                                  staticClass: "link-expire__text",
                                  class: [_vm.textError ? "item-error" : ""],
                                  attrs: {
                                    maxlength: "8",
                                    "show-word-limit": ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.fn_setForm(
                                        $event,
                                        "unreal_expire_text"
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.linkForm.unreal_expire_text,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.linkForm,
                                        "unreal_expire_text",
                                        $$v
                                      )
                                    },
                                    expression: "linkForm.unreal_expire_text"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "link-expire__item" }, [
                              _c(
                                "span",
                                { staticClass: "link-expire__label" },
                                [
                                  _c("span", { staticClass: "q-danger" }, [
                                    _vm._v("*")
                                  ]),
                                  _vm._v(" 时间设置：")
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  [
                                    _c("el-input-number", {
                                      staticClass: "link-expire__input",
                                      class: [
                                        _vm.timeError ? "item-error" : ""
                                      ],
                                      attrs: {
                                        precision: 0,
                                        min: 0,
                                        max: 100,
                                        controls: false
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.fn_changeTime(
                                            "unreal_expire_day"
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.linkForm.unreal_expire_day,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.linkForm,
                                            "unreal_expire_day",
                                            $$v
                                          )
                                        },
                                        expression: "linkForm.unreal_expire_day"
                                      }
                                    }),
                                    _vm._v("天 "),
                                    _c("el-input-number", {
                                      staticClass: "link-expire__input",
                                      class: [
                                        _vm.timeError ? "item-error" : ""
                                      ],
                                      attrs: {
                                        precision: 0,
                                        min: 0,
                                        max: 23,
                                        controls: false
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.fn_changeTime(
                                            "unreal_expire_hour"
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.linkForm.unreal_expire_hour,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.linkForm,
                                            "unreal_expire_hour",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "linkForm.unreal_expire_hour"
                                      }
                                    }),
                                    _vm._v("时 "),
                                    _c("el-input-number", {
                                      staticClass: "link-expire__input",
                                      class: [
                                        _vm.timeError ? "item-error" : ""
                                      ],
                                      attrs: {
                                        precision: 0,
                                        min: 0,
                                        max: 59,
                                        controls: false
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.fn_changeTime(
                                            "unreal_expire_minute"
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.linkForm.unreal_expire_minute,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.linkForm,
                                            "unreal_expire_minute",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "linkForm.unreal_expire_minute"
                                      }
                                    }),
                                    _vm._v("分 "),
                                    _c("el-input-number", {
                                      staticClass: "link-expire__input",
                                      class: [
                                        _vm.timeError ? "item-error" : ""
                                      ],
                                      attrs: {
                                        precision: 0,
                                        min: 0,
                                        max: 59,
                                        controls: false
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.fn_changeTime(
                                            "unreal_expire_second"
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.linkForm.unreal_expire_second,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.linkForm,
                                            "unreal_expire_second",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "linkForm.unreal_expire_second"
                                      }
                                    }),
                                    _vm._v("秒 ")
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "q-info",
                                    staticStyle: {
                                      "line-height": "1.6",
                                      "margin-top": "4px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " 时间为虚拟时间，最小1分钟，活码创建后开始倒计时，"
                                    ),
                                    _c("br"),
                                    _vm._v("结束会开始新一轮倒计时 ")
                                  ]
                                )
                              ])
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ],
            _c(
              "el-form-item",
              { attrs: { label: "联系主办方" } },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _vm._v("联系主办方 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "light",
                          placement: "right",
                          "popper-class": "q-tooltip is-light"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "24px 24px 16px",
                                  width: "464px"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://image.01lb.com.cn//uploads/wecom/21/0909/1631160179e6PuX3lX.png",
                                    width: "464",
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c("i", { staticClass: "iconfont icon-info-fill" })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex link-flex" },
                  [
                    _c("el-switch", {
                      attrs: { "active-value": true, "inactive-value": false },
                      on: {
                        change: function($event) {
                          return _vm.fn_setForm($event, "sevice_open")
                        }
                      },
                      model: {
                        value: _vm.linkForm.sevice_open,
                        callback: function($$v) {
                          _vm.$set(_vm.linkForm, "sevice_open", $$v)
                        },
                        expression: "linkForm.sevice_open"
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "q-info",
                        staticStyle: { "margin-left": "10px" }
                      },
                      [
                        _vm._v(
                          "开启后，若客户无法通过活码添加好友，可通过该方式联系到你"
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              2
            ),
            _vm.linkForm.sevice_open
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系账号", error: _vm.userError } },
                    [
                      _c("template", { slot: "label" }, [
                        _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
                        _vm._v(" 联系账号")
                      ]),
                      _c("ly-emp-btn", {
                        attrs: {
                          btnText: "选择联系账号",
                          max: 1,
                          isReal: "",
                          datafilter: "",
                          checkList: _vm.linkForm.sevice_ids
                        },
                        on: { getEmpIdArr: _vm.fn_getEmpId }
                      })
                    ],
                    2
                  )
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }