<template>
  <el-form
    :model="rewardForm"
    :rules="rules"
    ref="rewardFormRef"
    label-width="125px"
    size="medium"
    v-loading="cardLoading"
  >
    <el-form-item
      label="任务状态"
      prop="status"
      class="reward-form__item"
      v-if="order !== 'first'"
    >
      <div class="flex">
        <el-switch
          v-model="rewardForm.status"
          :active-value="1"
          :inactive-value="0"
          :disabled="isEdit && !isCopy && !isDaft"
          @change="fn_changeStatus"
        ></el-switch>
        <span style="margin-left: 8px">{{
          rewardForm.status === 0 ? '关闭' : '开启'
        }}</span>
      </div>
    </el-form-item>
    <template v-if="rewardForm.status === 1">
      <el-form-item
        label="任务人数"
        :error="targetCountError"
        class="reward-form__item"
        required
      >
        <el-input-number
          v-model="rewardForm.target_count"
          controls-position="right"
          :min="1"
          :max="100"
          :precision="0"
          class="number-big"
          placeholder="目标邀请人数"
          :disabled="isEdit && !isCopy && !isDaft"
          @change="fn_checkTarget"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="任务达成标签" class="reward-form__item">
        <div class="flex" style="height: 36px">
          <el-switch
            v-model="rewardForm.tag_open"
            :active-value="1"
            :inactive-value="0"
            @change="fn_handleTagStatus"
          ></el-switch>
          <span class="q-info" style="line-height: 20px; margin-left: 10px"
            >开启后，可自动为任务达成用户打标签</span
          >
        </div>
        <div v-show="rewardForm.tag_open">
          <tag-select-v2
            @getTagId="fn_getFinishIds"
            :checked="rewardForm.finish_task_tags"
          ></tag-select-v2>
        </div>
      </el-form-item>
      <el-form-item
        label="奖品信息"
        prop="reward_title"
        class="reward-form__item"
      >
        <div class="flex reward">
          <el-upload
            action="#"
            accept=".png, .jpg, .jpeg"
            :http-request="requestCover"
            :on-remove="removeCover"
            list-type="picture-card"
            :limit="1"
            :file-list="rewardForm.reward_coverList"
            :class="[
              'upload',
              rewardForm.reward_coverList.length === 0 ? '' : 'upload-empty'
            ]"
          >
            <div class="default">
              <i class="iconfont icon-picture"></i>
              <span>奖品封面</span>
            </div>
          </el-upload>
          <div class="flex flex-column reward-info">
            <el-input
              v-model="rewardForm.reward_title"
              :ref="'rewardTitleRef' + order"
              placeholder="奖品名称，必填"
              maxlength="10"
              show-word-limit
              class="reward-info__title"
              style="width: 100%"
              @change="fn_setForm($event, 'reward_title')"
            ></el-input>
            <el-input
              v-model="rewardForm.reward_desc"
              type="textarea"
              resize="none"
              placeholder="奖品简介，选填"
              maxlength="40"
              show-word-limit
              class="reward-info__desc"
              @change="fn_setForm($event, 'reward_desc')"
            ></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="虚拟完成人数"
        :error="unrealError"
        class="reward-form__item"
        required
      >
        <el-input-number
          v-model="rewardForm.unreal"
          controls-position="right"
          :min="0"
          :max="99999"
          :precision="0"
          class="number-big"
          placeholder="虚拟完成人数"
          @change="fn_checkUnreal"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        label="奖品内容"
        required
        :error="detailError"
        class="reward-form__item"
      >
        <div style="margin-bottom: 16px">
          <el-radio-group
            v-model="rewardForm.reward_type"
            :disabled="isEdit && !isCopy && !isDaft"
            @change="fn_changeDetailRadio"
          >
            <el-radio :label="1">自定义</el-radio>
            <el-radio :label="2">兑换码</el-radio>
            <el-radio :label="3">表单</el-radio>
            <el-tooltip
              class="item"
              placement="top"
              content="功能准备上线中，敬请期待"
              popper-class="q-tooltip"
            >
              <el-radio :label="7" disabled>核销码</el-radio>
            </el-tooltip>
          </el-radio-group>
        </div>
        <div v-show="rewardForm.reward_type === 1">
          <div :class="['reward-detail', detailError !== '' ? 'error' : '']">
            <wang-editor
              class="wangEditor"
              :refName="'detailRef_' + order"
              :content="rewardForm.reward_detail"
              :menus="editorMenus"
              @eFocus="fn_detailFocus"
              @eBlur="fn_detailBlur"
              @getText="fn_getText1"
              placeholder="支持输入文本，复制链接"
              :setCont="
                (html) => {
                  fn_setDetail(html, 'diy')
                }
              "
            />
            <div class="reward-detail__count">
              {{ detailText.length }}/{{ detailLength }}
            </div>
            <div class="reward-detail__upload">
              <el-upload
                action="#"
                accept=".png, .jpg, .jpeg"
                :http-request="requestContentImg"
                :on-remove="removeContentImg"
                list-type="picture-card"
                :limit="1"
                :file-list="rewardForm.reward_detail_imgList"
                :class="[
                  'upload',
                  rewardForm.reward_detail_imgList.length === 0
                    ? ''
                    : 'upload-empty'
                ]"
              >
                <div
                  class="flex flex-column flex-center"
                  style="line-height: 22px; height: 100%"
                >
                  <i class="iconfont icon-plus" style="font-size: 20px"></i>
                  <span class="text q-info text-small">上传图片</span>
                </div>
              </el-upload>
            </div>
          </div>
          <el-tooltip
            class="item"
            placement="top"
            popper-class="q-tooltip is-light"
          >
            <div slot="content">
              <div style="margin: 24px 24px 16px; width: 528px">
                <img
                  src="https://image.01lb.com.cn//uploads/wecom/22/0209/1644375062Jh5fIxJa.png"
                  width="528"
                  alt=""
                />
              </div>
            </div>
            <span
              class="q-primary text-small inline-flex"
              style="line-height: 1.6; margin-bottom: 0"
            >
              <i class="iconfont icon-help1" style="margin-right: 2px"></i>
              用户完成任务后，如何领取奖品
            </span>
          </el-tooltip>
          <p
            class="q-info text-small"
            style="line-height: 1.6; margin-bottom: 0"
            v-if="urlTips"
          >
            请确保奖品链接页面无法复制分享，或使用人工审核的表单类链接，避免奖品内容被分享传播，造成损失或用户投诉。
          </p>
        </div>

        <div v-show="rewardForm.reward_type === 2">
          <div style="position: relative">
            <el-select
              v-model="rewardForm.reward_type_id"
              placeholder="请选择"
              clearable
              :disabled="isEdit && !isCopy && !isDaft"
              class="reward-type__select"
              @change="fn_codeChange"
            >
              <el-option
                v-for="item in codeList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span class="reward-code__error" v-if="!rewardForm.reward_type_id"
              >请选择兑换码</span
            >
            <div
              class="q-info text-small"
              style="line-height: 24px; margin-bottom: 16px"
            >
              <template v-if="!isEdit || isCopy">
                <span style="margin-right: 16px"
                  >选择要关联活动的兑换码奖品</span
                >
                <span class="flex" style="display: inline-flex">
                  <el-button
                    type="text"
                    size="mini"
                    @click="fn_jumpToCode('code')"
                    >去创建兑换码</el-button
                  >
                  <div
                    data-v-3cefa108=""
                    class="el-divider el-divider--vertical"
                  ></div>
                  <el-button
                    type="text"
                    size="mini"
                    v-lydebounce="['button', fn_getCodeList]"
                    >刷新</el-button
                  >
                </span>
              </template>
            </div>
          </div>
          <div class="rules reward-type__select" style="border: none">
            <div :class="['reward-detail', detailError !== '' ? 'error' : '']">
              <wang-editor
                class="wangEditor wangEditor-code"
                :refName="'codeRulesRef' + order"
                :content="rewardForm.codeRules"
                :menus="['link']"
                @eFocus="fn_detailFocus"
                @eBlur="fn_detailBlur"
                @getText="fn_getText2"
                placeholder="请输入兑换规则"
                :setCont="
                  (html) => {
                    fn_setDetail(html, 'code')
                  }
                "
              />
              <div class="rules-footer">
                <div
                  class="rules-footer-inset default"
                  @click="resetRules('code')"
                >
                  恢复默认文本
                </div>
                {{ ruleText.length }}/1000
              </div>
            </div>
          </div>
          <el-tooltip
            class="item"
            placement="top"
            popper-class="q-tooltip is-light"
          >
            <div slot="content">
              <div style="margin: 24px 24px 16px; width: 528px">
                <img
                  src="https://image.01lb.com.cn//uploads/wecom/22/0209/1644375128Qt849l2k.png"
                  width="528"
                  alt=""
                />
              </div>
            </div>
            <span
              class="q-primary text-small inline-flex"
              style="line-height: 1.6; margin-bottom: 0"
            >
              <i class="iconfont icon-help1" style="margin-right: 2px"></i>
              用户完成任务后，如何领取奖品
            </span>
          </el-tooltip>
        </div>

        <div v-show="rewardForm.reward_type === 3">
          <div style="position: relative">
            <el-select
              v-model="rewardForm.form_id"
              placeholder="请选择"
              clearable
              class="reward-type__select"
              :disabled="isEdit && !isCopy && !isDaft"
              @focus="fn_detailFocus"
              @blur="fn_detailBlur"
              @change="fn_formChange"
            >
              <el-option
                v-for="item in formList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span class="reward-code__error" v-if="!rewardForm.form_id"
              >请选择表单</span
            >
            <div
              class="q-info text-small"
              style="line-height: 24px; margin-bottom: 16px"
            >
              <template v-if="!isEdit || isCopy">
                <span style="margin-right: 16px">选择要关联活动的表单</span>
                <span class="flex" style="display: inline-flex">
                  <el-button
                    type="text"
                    size="mini"
                    @click="fn_jumpToCode('form')"
                    >去创建表单</el-button
                  >
                  <div
                    data-v-3cefa108=""
                    class="el-divider el-divider--vertical"
                  ></div>
                  <el-button
                    type="text"
                    size="mini"
                    v-lydebounce="['button', fn_getFormList]"
                    >刷新</el-button
                  >
                </span>
              </template>
            </div>
          </div>
          <div class="rules reward-type__select" style="border: none">
            <div :class="['reward-detail', detailError !== '' ? 'error' : '']">
              <div class="rules-header">
                <div>奖品规则：</div>
              </div>
              <wang-editor
                class="wangEditor wangEditor-code no-tool"
                :refName="'formRulesRef' + order"
                :content="rewardForm.formRules"
                :menus="codeMenus"
                @eFocus="fn_detailFocus"
                @eBlur="fn_detailBlur"
                :getText="fn_getText3"
                placeholder="请输入奖品规则"
                :setCont="
                  (html) => {
                    fn_setDetail(html, 'form')
                  }
                "
              />
              <div class="rules-footer">
                <div
                  class="rules-footer-inset default"
                  @click="resetRules('form')"
                >
                  恢复默认文本
                </div>
                {{ formText.length }}/1000
              </div>
            </div>
          </div>
          <el-tooltip
            class="item"
            placement="top"
            popper-class="q-tooltip is-light"
          >
            <div slot="content">
              <div style="margin: 24px 24px 16px; width: 528px">
                <img
                  src="https://image.01lb.com.cn//uploads/wecom/21/0922/16322925175DqeoK5C.png"
                  width="528"
                  alt=""
                />
              </div>
            </div>
            <span
              class="q-primary text-small inline-flex"
              style="line-height: 1.6; margin-bottom: 0"
            >
              <i class="iconfont icon-help1" style="margin-right: 2px"></i>
              用户完成任务后，如何领取奖品
            </span>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item
        label="奖品库存"
        :error="totalError"
        class="reward-form__item"
        required
      >
        <el-input-number
          v-model="rewardForm.total"
          controls-position="right"
          :min="1"
          :max="99999"
          :precision="0"
          class="number-big"
          placeholder="奖品库存"
          @change="fn_checkTotal"
        ></el-input-number>
        <div class="q-info" v-if="isEdit && !isCopy && !isDaft">
          <span style="margin-right: 24px"
            >已使用：<b>{{ rewardForm.give_num }}</b></span
          ><span
            >剩余库存：<b>{{ rewardForm.remain }}</b></span
          >
        </div>
        <p
          class="q-bg is-primary text-small"
          style="line-height: 1.6; margin-bottom: 0"
          v-if="showRiskTips"
        >
          温馨提示：账号被动添加好友建议值：新企微号每天200~400人左右，高权重老号每天800~1000人左右。当前奖品库存/涨粉账号={{
            showRiskNum
          }}人，请谨慎设置库存
        </p>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import qiniuUpload from '@/common/mixins/qiniuUpload'
import wangEditor from '@/components/wangEditor'
import isImg from '@/common/isImg'
import tagSelectV2 from '@/components/wecomTags'
import { formList } from '../http'
export default {
  name: 'rewardForm',
  props: {
    order: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  mixins: [qiniuUpload],
  data () {
    return {
      cardLoading: true,
      used_count: 0,
      rewardForm: {
        id: 0,
        status: 1,
        target_count: 2,
        reward_title: '',
        reward_desc: '',
        reward_coverList: [],
        unreal: 1000,
        reward_type: 1,
        reward_detail: '',
        reward_detail_imgList: [],
        total: 9999,
        remain: 9999,
        reward_type_id: null,
        codeRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
        formRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
        tag_open: false,
        finish_task_tags: '',
        reward_detail_text: '',
        reward_rule_text: '',
        give_num: 0
      },
      rules: {
        reward_title: [
          { required: true, message: '请输入奖品信息（奖品名称为必填）', trigger: 'change' }
        ]
      },
      detailError: '',
      maxTotal: 99999,
      maxCount: 100,
      urlTips: false,
      showRiskTips: false,
      showRiskNum: 0,
      detailLength: 1000,
      editorMenus: ['link'],
      codeMenus: [],
      editCount: '',
      initRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
      codeReady: false,
      codeList: [],
      codeError: false,
      targetCountError: '',
      unrealError: '',
      totalError: '',
      detailText: '',
      ruleText: '',
      formList: [],
      formText: '',
      formReady: false,
      contentReady: false
    }
  },

  computed: {
    isEdit () {
      return this.$store.getters['groupfission/GET_TYPE']
    },
    isCopy () {
      return this.$store.state.groupfission.isCopy
    },
    isDaft () {
      return this.$store.state.groupfission.isDaft
    },
    isReady () {
      return this.$store.getters['groupfission/GET_READY']
    },
    status () {
      return this.$store.getters['groupfission/GET_TASK'](this.order).status
    },
    firstCount () {
      return this.$store.getters['groupfission/GET_TASK']('first').target_count
    },
    firstStatus () {
      return this.$store.getters['groupfission/GET_TASK']('first').status
    },
    firstUnreal () {
      return this.$store.getters['groupfission/GET_TASK']('first').unreal
    },
    secondCount () {
      return this.$store.getters['groupfission/GET_TASK']('second').target_count
    },
    secondStatus () {
      return this.$store.getters['groupfission/GET_TASK']('second').status
    },
    secondUnreal () {
      return this.$store.getters['groupfission/GET_TASK']('second').unreal
    },
    thirdCount () {
      return this.$store.getters['groupfission/GET_TASK']('third').target_count
    },
    thirdStatus () {
      return this.$store.getters['groupfission/GET_TASK']('third').status
    },
    thirdUnreal () {
      return this.$store.getters['groupfission/GET_TASK']('third').unreal
    }
  },

  watch: {
    isReady (val) {
      if (val) {
        const isEdit = this.$store.getters['groupfission/GET_TYPE']
        if (isEdit) {
          this.rewardForm = this.$store.getters['groupfission/GET_TASK'](this.order)
          this.rewardForm.reward_type_id = this.rewardForm.reward_type_id === 0 ? null : this.rewardForm.reward_type_id
          if (this.rewardForm.reward_type === 2) {
            this.fn_getCodeList(true)
          }
          if (this.rewardForm.reward_type === 3) {
            this.fn_getFormList(true)
          }
          this.rewardForm.formRules = this.rewardForm.formRules || this.initRules
        }

        this.cardLoading = false
        this.formReady = false
      }

      setTimeout(() => {
        this.contentReady = true
      }, 100)
    },
    order (val) {
      this.rewardForm.finish_task_tags = ''
      const isEdit = this.$store.getters['groupfission/GET_TYPE']
      if (isEdit) {
        this.rewardForm = this.$store.getters['groupfission/GET_TASK'](val)
        this.rewardForm.reward_type_id = this.rewardForm.reward_type_id === 0 ? null : this.rewardForm.reward_type_id
        if (this.isCopy) {
          this.fn_setCount(val)
        }
      } else {
        this.fn_setCount(val)
      }
      if (this.rewardForm.reward_type === 2) {
        this.fn_getCodeList(true)
      }
      if (this.rewardForm.reward_type === 3) {
        this.fn_getFormList(true)
      }
    },
    status (val) {
      if (val === 1) {
        this.$nextTick(() => {
          this.fn_checkTarget(this.rewardForm.target_count)
          this.fn_checkUnreal(this.rewardForm.unreal)
          setTimeout(() => {
            this.detailError = ''
          }, 40)
        })
      }
    },
    'rewardForm.total' (val) {
      if (this.isEdit && !this.isCopy && !this.isDaft && this.formReady) {
        this.rewardForm.remain = val - this.rewardForm.give_num
      }
    }
  },

  methods: {

    // 设置默认人数及虚拟人数
    fn_setCount (val) {
      const tabArr = ['first', 'second', 'third']
      const tabIndex = tabArr.indexOf(val)
      const prevOrder = tabArr[tabIndex - 1]
      this.rewardForm = this.$store.getters['groupfission/GET_TASK'](val)
      if (this.rewardForm.reward_detail === '') {
        this.fn_getText1('')
      }
      if (this.rewardForm.status === 0 && prevOrder) {
        const prevCount = this.$store.getters['groupfission/GET_TASK'](prevOrder).target_count
        this.rewardForm.target_count = prevCount < 100 ? prevCount + 1 : 100
        const prevUnreal = this.$store.getters['groupfission/GET_TASK'](prevOrder).unreal
        this.rewardForm.unreal = prevUnreal
        this.fn_setForm(this.rewardForm.target_count, 'target_count')
      }
    },

    // 配置
    fn_setForm (val, type) {
      this.$store.commit('groupfission/SET_FORM', {
        form: this.order,
        name: type,
        val: val
      })
    },

    // 任务状态
    fn_changeStatus (val) {
      if (val === 1) {
        this.fn_setForm(val, 'status')
      } else {
        const thirdStatus = this.$store.getters['groupfission/GET_TASK']('third').status
        if (this.order === 'second' && thirdStatus === 1) {
          this.fn_lyMsg('info', '请先关闭三阶任务')
          this.rewardForm.status = 1
          this.fn_setForm(1, 'status')
        } else {
          this.rewardForm.status = val
          this.fn_setForm(val, 'status')
        }
      }
    },

    // 任务人数校验
    fn_checkTarget (value) {
      let error = ''
      if (!value) {
        error = '请输入任务人数'
      } else {
        const orderList = ['first', 'second', 'third']
        const orderIndex = orderList.indexOf(this.order)

        // 一阶，如果有二阶需小于二阶
        if (orderIndex === 0) {
          if (this.secondStatus === 1 && +value >= +this.secondCount) {
            error = '任务人数需小于二阶任务人数'
          }
        }

        // 二阶，需大于一阶，如果有三阶需小于三阶
        if (orderIndex === 1) {
          if (+value <= +this.firstCount) {
            error = '任务人数需大于一阶任务人数，最多可设置100人'
          }

          if (this.thirdStatus === 1 && +value >= +this.thirdCount) {
            error = '任务人数需小于三阶任务人数'
          }
        }

        // 三阶，需大于二阶
        if (orderIndex === 2) {
          if (+value <= +this.secondCount) {
            error = '任务人数需大于二阶任务人数，最多可设置100人'
          }
        }
      }
      this.targetCountError = error
      this.fn_setForm(value, 'target_count')
      return error
    },

    // 虚拟人数校验
    fn_checkUnreal (value) {
      let error = ''
      if (value === undefined) {
        this.unrealError = '请输入虚拟完成人数'
        return false
      }

      const orderList = ['first', 'second', 'third']
      const orderIndex = orderList.indexOf(this.order)

      // 一阶，如果有二阶需大于二阶
      if (orderIndex === 0) {
        if (this.secondStatus === 1 && +value < +this.secondUnreal) {
          error = `需大于等于二阶虚拟完成人数（${this.secondUnreal}）`
        }
      }

      // 二阶，需小于一阶，如果有三阶需大于三阶
      if (orderIndex === 1) {
        if (+value > +this.firstUnreal) {
          error = `需小于等于一阶虚拟完成人数（${this.firstUnreal}）`
        }

        if (this.thirdStatus === 1 && +value < +this.thirdUnreal) {
          error = `需大于等于三阶虚拟完成人数（${this.thirdUnreal}）`
        }
      }

      // 三阶，需小于二阶
      if (orderIndex === 2) {
        if (+value > +this.secondUnreal) {
          error = `需小于等于二阶虚拟完成人数（${this.secondUnreal}）`
        }
      }
      this.unrealError = error
      this.fn_setForm(value, 'unreal')
      return error
    },

    // 奖品库存校验
    fn_checkTotal (value) {
      let error = ''
      if (!value || value === '') {
        error = '请输入奖品库存'
      } else if (value <= this.used_count) {
        error = '库存不能小于已使用'
      } else {
        this.fn_setForm(value, 'total')
        if (this.isEdit && !this.isCopy) {
          this.fn_setForm(parseInt(value) - this.used_count, 'remain')
        }
      }
      this.totalError = error
      return error
    },

    // 打标签
    fn_handleTagStatus (val) {
      this.fn_setForm(val, 'tag_open')
    },

    // 完成任务客户标签
    fn_getFinishIds (tags) {
      const tagStr = tags ? tags.join(',') : ''
      this.rewardForm.finish_task_tags = tagStr
      this.fn_setForm(tagStr, 'finish_task_tags')
    },

    // 获取兑换码列表
    async fn_getCodeList (refresh = false) {
      const ready = refresh && this.codeReady && this.codeList.find(n => n.id === this.rewardForm.reward_type_id)
      if (ready) return
      this.codeReady = true

      const data = await this.axios.get('taskCodeList', { params: { code_id: this.rewardForm.reward_type_id } })
      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data) {
        this.codeList = data
      } else {
        this.codeList = []
      }
    },

    // 获取兑换码列表
    async fn_getFormList (refresh = false) {
      const ready = refresh && this.formReady
      if (ready) return
      this.formReady = true
      const data = await formList.reqQuery({
        page: 1,
        per_page: 9999
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.records) {
        this.formList = data.records
      } else {
        this.formList = []
      }
    },

    // 跳转兑换码列表
    fn_jumpToCode (type) {
      const path = type === 'code' ? '/material/code/list' : '/material/formTable/list'
      const routeData = this.$router.resolve({
        path: path
      })
      window.open(routeData.href, '_blank')
    },

    // 温馨提醒
    // fn_setTotalTips () {
    //   if (this.empLength && this.empLength.length > 0 && this.rewardForm.total) {
    //     this.showRiskNum = (this.rewardForm.total / this.empLength.length).toFixed()
    //     this.showRiskTips = this.showRiskNum >= 200
    //   }
    // },

    // 上传信息封面
    async requestCover (opts) {
      this.rewardForm.reward_coverList = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.rewardForm.reward_coverList = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.fn_setForm(this.rewardForm.reward_coverList, 'reward_coverList')
          },
          null,
          {
            type: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
        )
      }
    },

    // 删除信息封面
    removeCover () {
      this.rewardForm.reward_coverList = []
      this.fn_setForm(this.rewardForm.reward_coverList, 'reward_coverList')
    },

    // 奖品内容
    fn_changeDetailRadio (val) {
      this.fn_setForm(val, 'reward_type')
      this.detailError = ''
      if (val === 2) {
        this.fn_getCodeList(true)
      }

      if (val === 3) {
        this.fn_getFormList(true)
      }
    },

    // 奖品内容配置
    fn_setDetail (html, type) {
      // 1:自定义，2：兑换码
      if (type === 'diy') {
        this.rewardForm.reward_detail = html
        this.fn_urlValid(html)
      }

      if (type === 'code') {
        this.rewardForm.codeRules = html
        this.fn_setForm(html, 'codeRules')
      }

      if (type === 'form') {
        this.rewardForm.formRules = html
        this.fn_setForm(html, 'formRules')
      }
    },

    fn_getText1 (txt) {
      this.detailText = txt
      if (this.contentReady) {
        this.fn_checkDetail()
      }
    },

    fn_getText2 (txt) {
      this.ruleText = txt
      if (this.contentReady) {
        this.fn_checkDetail()
      }
    },

    fn_getText3 (txt) {
      this.formText = txt
      if (this.contentReady) {
        this.fn_checkDetail()
      }
    },

    // 奖品内容校验
    fn_checkDetail () {
      this.detailError = ''
      let verify = true

      const type = this.rewardForm.reward_type
      // 1：自定义；2：兑换码

      if (type === 1) {
        const dtext = this.detailText
        const imgLen = this.rewardForm.reward_detail_imgList.length

        if (dtext === '' && imgLen === 0) {
          this.detailError = '请输入奖品内容（文本或图片至少一项）'
          verify = false
        } else {
          if (dtext.length > this.detailLength) {
            this.detailError = `最多可输入${this.detailLength}字`
            verify = false
          }
        }
      }

      if (type === 2) {
        const ctext = this.ruleText
        if (!this.rewardForm.reward_type_id) {
          this.codeError = true
          verify = false
        }

        if (ctext.length > this.detailLength) {
          this.detailError = `最多可输入${this.detailLength}字`
          verify = false
        }
      }

      if (type === 3) {
        const ctext = this.formText
        if (!this.rewardForm.form_id) {
          this.codeError = true
          verify = false
        }

        if (ctext.length > this.detailLength) {
          this.detailError = `最多可输入${this.detailLength}字`
          verify = false
        }
      }

      return verify
    },

    // 上传内容图片
    async requestContentImg (opts) {
      this.fn_detailFocus()
      this.rewardForm.reward_detail_imgList = []
      const _varify = await isImg(opts, ['png', 'jpg'])

      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this.rewardForm.reward_detail_imgList = [
              {
                name: 'pic-' + id,
                uid: id,
                url: path
              }
            ]
            this.fn_setForm(this.rewardForm.reward_detail_imgList, 'reward_detail_imgList')
          },
          null,
          {
            type: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
        )
      }
      this.detailError = ''
    },

    // 删除内容图片
    removeContentImg () {
      this.rewardForm.reward_detail_imgList = []
      this.fn_setForm([], 'reward_detail_imgList')
      if (this.rewardForm.reward_detail_text === '') {
        this.detailError = '请输入奖品内容（文本或图片至少一项）'
      }
    },

    // 奖品内容focus
    fn_detailFocus () {
      this.$store.commit('_SET', {
        'groupfission.defaultFocus': true
      })
    },

    // 奖品内容blur
    fn_detailBlur () {
      this.$store.commit('_SET', {
        'groupfission.defaultFocus': false
      })
    },

    // 匹配粘贴的内容是否为链接
    fn_urlValid (val) {
      if (val.indexOf('<a href="') > -1) {
        this.urlTips = true
      } else {
        this.urlTips = false
      }
      this.fn_setForm(val, 'reward_detail')
    },

    // 恢复默认文本
    // resetRules () {
    //   this.$lyConfirm({
    //     title: '确定将兑换规则恢复为默认文本吗？',
    //     text: ''
    //   }).then(() => {
    //     this.rewardForm.codeRules = this.initRules
    //     this.fn_setForm(this.initRules, 'codeRules')
    //   }).catch(() => { })
    // },

    // 恢复默认文本
    resetRules (type) {
      const objKey = type === 'code' ? 'codeRules' : 'formRules'
      const name = type === 'code' ? '兑换规则' : '奖品规则'
      this.$lyConfirm({
        title: `确定将${name}恢复为默认文本吗？`,
        text: ''
      }).then(async () => {
        this.rewardForm[objKey] = this.initRules
        this.fn_setStore(this.initRules, objKey)
      }).catch(() => { })
    },

    // 切换兑换码
    fn_codeChange (val) {
      this.fn_setForm(val, 'reward_type_id')
      this.detailError = ''
      const obj = this.codeList
      let hostValue = 0
      if (obj.length === 0) return false
      obj.forEach(item => {
        if (val === item.id) {
          hostValue = item.wait
        }
      })
      this.rewardForm.total = hostValue
      this.fn_setForm(hostValue, 'total')
      if (this.isEdit && !this.isCopy && !this.isDaft) {
        this.fn_checkTotal(hostValue)
      }
      this.fn_detailFocus()
    },

    fn_formChange (val) {
      this.fn_setForm(val, 'form_id')
    },

    // 表单验证
    fn_verify () {
      let formValid = false

      // 任务人数
      const _checkTarget = this.fn_checkTarget(this.rewardForm.target_count)

      this.$refs.rewardFormRef.validate((valid) => {
        if (valid) {
          formValid = true
        } else {
          formValid = false
        }
      })

      let _valid = true

      if (this.rewardForm.status === 1) {
        // 虚拟人数
        const _checkUnreal = this.fn_checkUnreal(this.rewardForm.unreal)
        // 奖品内容
        const _checkDetail = this.fn_checkDetail()
        // 奖品库存
        const _checkTotal = this.fn_checkTotal(this.rewardForm.total)

        _valid = formValid && _checkDetail && _checkTarget === '' && _checkUnreal === '' && _checkTotal === ''
      } else {
        _valid = formValid
      }

      return _valid
    }
  },
  components: {
    [wangEditor.name]: wangEditor,
    tagSelectV2
  }
  // destroyed () {
  //   this.fn_detailBlurAll()
  // }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/edit.scss';
@import '@/assets/scss/var.scss';

.reward {
  &-form {
    &__item {
      width: 60%;
      min-width: 560px;
    }
  }

  &-code__error {
    display: inline-block;
    color: #ed5c49;
    font-size: 12px;
    line-height: 1;
    padding-left: 4px;
  }
}

.rules {
  border-radius: 4px;
  border: 1px solid #eaebf2;
  overflow: hidden;

  &-header {
    background-color: #f7f8fc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #dfe1e8;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 3px;

    &-inset {
      border-right: 2px;
      font-size: 12px;
      padding: 0 8px;
      height: 34px;
      line-height: 34px;
      margin-right: 16px;
      cursor: pointer;
      border-radius: 17px;
      color: #454d5b;
      &:hover {
        background-color: #f0f1f8;
      }
    }
  }

  &-inset {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;
    background: #f2f5fe;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: $--transition;
    font-size: 12px;

    &:hover {
      background-color: $--color-primary;
      color: #fff;
    }

    &.default {
      background-color: transparent;
      color: $--color-text-regular;
      padding: 0;

      &:hover {
        color: $--color-primary;
      }
    }

    & + & {
      margin-left: 12px;
    }
  }

  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 200px !important;
  }
}

.upload {
  line-height: 1;
}

.upload-empty {
  line-height: 1.6;
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}

::v-deep .form_link {
  .el-form-item__label {
    width: 80px !important;
  }
  .el-form-item__content {
    margin-left: 80px !important;
  }
}

.link-preview {
  background-color: #fff;
  width: 245px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #eaebf2;
  line-height: 1.6;

  &__content {
    align-items: flex-start;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__cover {
    width: 48px;
    height: 48px;
    font-size: 48px;
    line-height: 1.6;
    margin-left: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.reward {
  &-info {
    flex: 1;
    margin-left: 16px;

    &__title {
      margin-bottom: 8px;
    }

    &__desc {
      ::v-deep .el-textarea__inner {
        height: 60px;
        border-color: #dfe1e8 !important;
      }
    }
  }

  &-detail {
    border: 1px solid #dfe1e8;
    border-radius: 5px;
    overflow: hidden;

    &.error {
      border-color: $--color-danger;
    }

    ::v-deep .el-textarea__inner {
      border: none;
      height: 78px;
    }

    &__upload {
      padding: 16px 18px;
      border-top: 1px solid #dfe1e8;

      ::v-deep .el-upload--picture-card,
      ::v-deep .el-upload-list--picture-card .el-upload-list__item {
        width: 72px;
        height: 72px;
      }

      ::v-deep .el-upload--picture-card {
        line-height: 72px;
      }
    }

    &__count {
      text-align: right;
      padding: 0 12px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

::v-deep .el-upload-list__item {
  margin: 0;
}
::v-deep .upload-empty {
  line-height: 1;
}

.wangEditor {
  ::v-deep .w-e-text-container {
    height: 78px !important;
  }
}

.wangEditor-code {
  &.no-tool {
    ::v-deep .w-e-toolbar {
      display: none;
    }
  }
  ::v-deep .w-e-text-container {
    height: 200px !important;
  }
}

.reward-type__select {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .reward-type__select {
    width: 420px;
  }
}

.number-big {
  width: 200px;
}
</style>
