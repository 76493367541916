var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      attrs: {
        size: _vm.btnSize,
        round: "",
        type: _vm.btnType,
        disabled: _vm.disabled,
        icon: "iconfont icon-download",
        loading: _vm.loading
      },
      on: { click: _vm.fn_exportExcel }
    },
    [_vm._v(_vm._s(_vm.btnText))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }