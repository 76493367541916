var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drafts" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "has-table",
          attrs: {
            title: "草稿箱",
            visible: _vm.draftsVisible,
            width: "584px"
          },
          on: {
            "update:visible": function($event) {
              _vm.draftsVisible = $event
            }
          }
        },
        [
          !_vm.$attrs.isEmpty
            ? _c("ly-table", {
                ref: "lyTable",
                attrs: {
                  data: _vm.tableData,
                  option: _vm.tableOpt,
                  page: _vm.page,
                  pageSize: _vm.per_page,
                  total: _vm.total
                },
                on: {
                  events: _vm.handleEvents,
                  searchChange: _vm.searchChange,
                  sizeChange: _vm.handleSizeChange,
                  currentChange: _vm.handleCurrentChange,
                  selectionChange: _vm.handleSelectionChange
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "name",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "poster-name" }, [
                            _c("span", { staticClass: "aname" }, [
                              _vm._v(_vm._s(row.name || "-"))
                            ])
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1299901261
                )
              })
            : _c(
                "div",
                { staticClass: "ly-nodata" },
                [
                  _c(
                    "el-empty",
                    {
                      attrs: {
                        image: require("@assets/svg/default/no_drafts.svg"),
                        description: "暂无草稿"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { round: "", type: "primary", size: "medium" },
                          on: { click: _vm.creatAct }
                        },
                        [_vm._v("创建表单")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }