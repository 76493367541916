var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp qr" },
    [
      _c(
        "div",
        { staticClass: "form-area" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.mainForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活码名称" } },
                [
                  _c("el-input", {
                    attrs: { clear: "", placeholder: "请输入活码名称" },
                    model: {
                      value: _vm.mainForm.liveCodeName,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "liveCodeName", $$v)
                      },
                      expression: "mainForm.liveCodeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "default-time": ["00:00:00", "23:59:59"],
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.mainForm.createTime,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "createTime", $$v)
                      },
                      expression: "mainForm.createTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        round: "",
                        icon: "iconfont icon-search",
                        type: "primary"
                      },
                      on: { click: _vm.handlerSearch }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { round: "", icon: "iconfont icon-data" },
                      on: { click: _vm.fn_reset }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main-area" },
        [
          _c(
            "div",
            { staticClass: "hander-area" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    type: "primary",
                    icon: "iconfont icon-plus",
                    size: "medium"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("edit")
                    }
                  }
                },
                [_vm._v("新建活码")]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "tooltip",
                  attrs: {
                    content: "请选择要调整的活码",
                    effect: "dark",
                    placement: "top",
                    disabled: _vm.selected.length > 0
                  }
                },
                [
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            round: "",
                            disabled: _vm.selected.length === 0
                          },
                          on: { click: _vm.handleGroup }
                        },
                        [_vm._v("调整分组")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "tooltip",
                  attrs: {
                    content: "请选择要下载的活码",
                    effect: "dark",
                    placement: "top",
                    disabled: _vm.selected.length > 0
                  }
                },
                [
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            round: "",
                            disabled: _vm.selected.length === 0
                          },
                          on: {
                            click: function($event) {
                              _vm.$refs.downloadList.visible = true
                            }
                          }
                        },
                        [_vm._v("批量下载二维码")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "temp-container" }, [
            _c("div", { staticClass: "qr-list" }, [
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.groupList, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "list-item flex",
                      class: { active: _vm.topicId === item.id },
                      on: {
                        click: function($event) {
                          return _vm.handleTopic(item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "temp flex" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                disabled: _vm.toolDisable,
                                effect: "dark",
                                content: item.groupName,
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ell name",
                                  on: { mouseenter: _vm.fn_groupEnter }
                                },
                                [_vm._v(_vm._s(item.groupName))]
                              )
                            ]
                          ),
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s("(" + item.count + ")"))
                          ])
                        ],
                        1
                      ),
                      item.id !== "1" && item.id !== "0"
                        ? _c(
                            "div",
                            { staticClass: "btn" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom-end",
                                    trigger: "click",
                                    "popper-class": "add-group-popover"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleEdit(item)
                                        }
                                      },
                                      slot: "reference"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-edit"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "add-group" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          placeholder: "请输入分组名称",
                                          maxlength: "20",
                                          "show-word-limit": ""
                                        },
                                        model: {
                                          value: _vm.form.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "name", $$v)
                                          },
                                          expression: "form.name"
                                        }
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { round: "", size: "small" },
                                          on: { click: _vm.handleCancel }
                                        },
                                        [_vm._v("取消")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            round: "",
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: { click: _vm.handleSave }
                                        },
                                        [_vm._v("确定")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              item.count === 0
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleDelete(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-delete"
                                      })
                                    ]
                                  )
                                : _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "请移除或删除分组下的活码，再删除分组",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "q-info",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-delete"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.id === "1"
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-end",
                                trigger: "click",
                                "popper-class": "add-group-popover"
                              },
                              on: {
                                show: function($event) {
                                  _vm.form.name = ""
                                  _vm.form.editId = ""
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-plus-fill",
                                attrs: { slot: "reference" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleCancel($event)
                                  }
                                },
                                slot: "reference"
                              }),
                              _c(
                                "div",
                                { staticClass: "add-group" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      placeholder: "请输入分组名称",
                                      maxlength: "20",
                                      "show-word-limit": ""
                                    },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name"
                                    }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { round: "", size: "small" },
                                      on: { click: _vm.handleCancel }
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        round: "",
                                        size: "small",
                                        type: "primary"
                                      },
                                      on: { click: _vm.handleSave }
                                    },
                                    [_vm._v("确定")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c(
              "div",
              { staticClass: "right-area" },
              [
                _vm.ready
                  ? _c(
                      "el-table",
                      {
                        ref: "table",
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.tableData, height: _vm.tableHeight },
                        on: { "selection-change": _vm.handleSelectionChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "liveCodeName",
                            label: "活码名称",
                            "min-width": "120"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "liveCodeUrl",
                            label: "二维码",
                            width: "85"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "p-qrcode-box" },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              width: "200",
                                              trigger: "hover"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%"
                                              },
                                              attrs: {
                                                src: scope.row.liveCodeUrl
                                              }
                                            }),
                                            _c("i", {
                                              staticClass: "iconfont icon-Code",
                                              staticStyle: {
                                                "font-size": "18px",
                                                "margin-right": "10px"
                                              },
                                              attrs: { slot: "reference" },
                                              slot: "reference"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { padding: "0" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleDownload(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("下载")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1345863411
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "totalUser",
                            label: "总用户数",
                            align: "center",
                            width: "100"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "yesUser",
                            label: "昨日新增用户数",
                            align: "center",
                            "min-width": "140"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "4px" }
                                      },
                                      [_vm._v("昨日新增用户数")]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          placement: "top",
                                          "popper-class": "q-tooltip"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _vm._v(
                                              " 昨日扫码添加（包含昨日已删除）的用户总数，"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "此前通过该活码添加再删除的用户不纳入计算 "
                                            )
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass: "iconfont icon-info-fill"
                                        })
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3341033677
                          )
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "todayUser",
                              label: "今日新增用户数",
                              align: "center",
                              "min-width": "140"
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "4px" } },
                                  [_vm._v("今日新增用户数")]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      "popper-class": "q-tooltip"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      [
                                        _vm._v(
                                          " 今日扫码添加（包含已删除）的用户总数，"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "此前通过该活码添加再删除的用户不纳入计算 "
                                        )
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont icon-info-fill"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            prop: "expireTime",
                            label: "失效时间",
                            "min-width": "100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "ly-table__main-time" },
                                      [
                                        scope.row.expireTime
                                          ? [
                                              scope.row.expireStatus
                                                ? [_vm._v("已失效")]
                                                : [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.util.timeTofmt(
                                                          scope.row.expireTime,
                                                          "yyyy-MM-dd hh:mm"
                                                        )
                                                      )
                                                    )
                                                  ]
                                            ]
                                          : [_vm._v("永不失效")]
                                      ],
                                      2
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2490597040
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "createTime",
                            label: "创建日期",
                            "min-width": "100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "ly-table__main-time" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.util.timeTofmt(
                                                scope.row.createTime,
                                                "yyyy-MM-dd hh:mm"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2031087903
                          )
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作", width: "180" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "ly-action",
                                      {
                                        key: Date.now(),
                                        attrs: { display: 2 }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  path: "simpleAnalysis",
                                                  query: {
                                                    id: scope.row.id,
                                                    name: scope.row.liveCodeName
                                                  }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("数据分析")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fn_showDetail({
                                                  id: scope.row.id,
                                                  createTime:
                                                    scope.row.createTime
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("详情")]
                                        ),
                                        !(
                                          scope.row.expireTime &&
                                          scope.row.expireStatus
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  icon: "iconfont icon-edit",
                                                  disabled:
                                                    scope.row.expireTime &&
                                                    scope.row.expireStatus
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$router.push({
                                                      path: "edit",
                                                      query: {
                                                        id: scope.row.id
                                                      }
                                                    })
                                                  }
                                                }
                                              },
                                              [_vm._v("修改")]
                                            )
                                          : _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "活码已失效",
                                                  placement: "top"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "is-disabled",
                                                    attrs: {
                                                      type: "text",
                                                      icon: "iconfont icon-edit"
                                                    }
                                                  },
                                                  [_vm._v("修改")]
                                                )
                                              ],
                                              1
                                            ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              icon: "iconfont icon-copy"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  path: "edit",
                                                  query: {
                                                    id: scope.row.id,
                                                    type: "copy"
                                                  }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("复制")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              icon: "iconfont icon-delete"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.activityDel(
                                                  scope.row.id,
                                                  scope.row.liveCodeName
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            650813921
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "ly-table-empty",
                            attrs: { slot: "empty" },
                            slot: "empty"
                          },
                          [
                            _vm._t("empty", [
                              _vm.flag
                                ? [
                                    _vm.isCustomerSeach
                                      ? [
                                          _c("img", {
                                            attrs: {
                                              src: require("@assets/svg/default/no_res.svg"),
                                              alt: ""
                                            }
                                          }),
                                          _vm._v(" 无搜索结果 ")
                                        ]
                                      : [
                                          _c("img", {
                                            attrs: {
                                              src: require("@assets/svg/default/no_data.svg"),
                                              alt: ""
                                            }
                                          }),
                                          _vm._v(" 暂无数据 ")
                                        ]
                                  ]
                                : _vm._e()
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "el-pagination",
            {
              attrs: {
                background: "",
                "current-page": _vm.page,
                "page-sizes": [10, 20, 50, 100],
                "page-size": _vm.per_page,
                layout: "slot, sizes, prev, pager, next, jumper",
                total: _vm.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            },
            [
              _c(
                "div",
                { staticClass: "pagination-slot" },
                [
                  _c("span", { staticClass: "total" }, [
                    _vm._v("共" + _vm._s(_vm.total) + "条记录")
                  ]),
                  _vm._t("pagination")
                ],
                2
              )
            ]
          )
        ],
        1
      ),
      _c("grouping", { ref: "grouping", on: { change: _vm.handleGrouped } }),
      _c("detail", {
        ref: "detail",
        attrs: { did: _vm.detailId, createTime: _vm.detailCreateTime }
      }),
      _c("download-list", {
        ref: "downloadList",
        attrs: { list: _vm.selected },
        on: { fn_clearSelect: _vm.fn_clearSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }