<!--
 * @Author: zhanln
 * @Date: 2021-11-09 16:34:36
 * @LastEditTime: 2022-08-29 12:02:10
 * @LastEditors: zhan
 * @Description: 预览- 活动信息
-->

<template>
  <ly-mobile :msgList="msgList"></ly-mobile>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'taskMobileBasic',

  data () {
    return {
      defaultPoster: require('@/assets/images/poster-default.png'),
      msgList: [{
        type: 'text',
        content: ''
      }, {
        type: 'image',
        isPoster: true,
        defaultText: '海报图片',
        image: ''
      }]
    }
  },

  computed: {
    ...mapState({
      mode: state => state.reserveTask.mode,
      welcom_msg: state => state.reserveTask.welcom_msg,
      poster_img: state => state.reserveTask.poster_img
    })
  },

  watch: {
    welcom_msg (val) {
      this.msgList[0].content = val
    },
    poster_img (val) {
      this.msgList[1].image = val
    }
  },

  mounted () {
    console.log('mode', this.mode)
    this.msgList[0].content = this.$store.state.reserveTask.welcom_msg
    this.msgList[1].image = this.$store.state.reserveTask.poster_img
  }
}
</script>
