var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gf-record page-warp-bg" },
    [
      _c(
        "div",
        { staticClass: "select-box" },
        [
          _c("label", { staticStyle: { color: "rgba(0, 0, 0, 0.85)" } }, [
            _vm._v("选择活动：")
          ]),
          _c(
            "el-select",
            {
              attrs: {
                size: "medium",
                filterable: "",
                placeholder: "请选择活动"
              },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.activity_id,
                callback: function($$v) {
                  _vm.activity_id = $$v
                },
                expression: "activity_id"
              }
            },
            _vm._l(_vm.activities, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.title, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "iconfont icon-download"
                    },
                    on: { click: _vm.handleExport }
                  },
                  [_vm._v("导出数据")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "reward_title",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "avatar-name" }, [
                  _c("img", {
                    staticStyle: { "margin-right": "8px" },
                    attrs: {
                      src: require("../../../assets/svg/reward.svg"),
                      alt: ""
                    }
                  }),
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(row.reward_title))
                  ])
                ])
              ]
            }
          },
          {
            key: "nickname",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-tag", { attrs: { content: row.nickname, only: "" } }, [
                  _c("i", {
                    staticClass: "icon el-icon-s-custom ly-tag_icon",
                    attrs: { slot: "left" },
                    slot: "left"
                  })
                ])
              ]
            }
          },
          {
            key: "group_status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.group_status === 1
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("在群")
                    ])
                  : _vm._e(),
                row.group_status === 2
                  ? _c("ly-status", { attrs: { type: "danger" } }, [
                      _vm._v("退群")
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "add_status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.add_status === 1
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("已添加")
                    ])
                  : row.add_status === 2
                  ? _c("ly-status", { attrs: { type: "danger" } }, [
                      _vm._v("已删除")
                    ])
                  : _c("span", [_vm._v("-")])
              ]
            }
          },
          {
            key: "give_status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.give_status === 1
                  ? _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("ly-status", { attrs: { type: "danger" } }, [
                          _vm._v("未发放")
                        ]),
                        _c(
                          "el-popover",
                          {
                            staticClass: "item",
                            attrs: {
                              placement: "top",
                              "popper-class": "q-tooltip",
                              trigger: "hover"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "line-height": "40px",
                                  "text-align": "center",
                                  "font-size": "14px"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.reward_type === 2
                                        ? "兑换码不足"
                                        : "库存不足，请前往活动修改页面调整库存"
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-center" },
                              [
                                row.reward_type === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          round: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleResend(row)
                                          }
                                        }
                                      },
                                      [_vm._v("重新发送")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          round: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleResend(row)
                                          }
                                        }
                                      },
                                      [_vm._v("重新发送")]
                                    )
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass: "icon-warning-fill iconfont",
                              staticStyle: { "margin-left": "5px" },
                              attrs: { slot: "reference" },
                              slot: "reference"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : row.give_status === 2
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("已发放")
                    ])
                  : [_vm._v(" - ")]
              ]
            }
          },
          {
            key: "add_user_name",
            fn: function(ref) {
              var row = ref.row
              return [_c("ly-drow", { attrs: { list: row.add_user_name } })]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                row.reward_type === 2 && !row.reward_value
                  ? [_vm._v(" - ")]
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.getRewardDetail(row)
                          }
                        }
                      },
                      [_vm._v("查看奖品内容")]
                    )
              ]
            }
          }
        ])
      }),
      _c("resend", { ref: "resend" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品内容",
            visible: _vm.dialogVisible,
            "custom-class": "q-dialog",
            width: "34%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "reward" }, [
            _vm.rewardInfo.reward_type === 1
              ? _c("div", { staticClass: "q-dialog-block q-dialog__img" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.rewardInfo.reward_detail)
                    }
                  }),
                  _c("img", {
                    staticClass: "reward-img",
                    attrs: { src: _vm.rewardInfo.reward_detail_img }
                  })
                ])
              : _vm.rewardInfo.reward_type === 2
              ? _c("div", { staticClass: "q-dialog-block" }, [
                  _c("div", { staticClass: "reward-code" }, [
                    _vm._v(_vm._s(_vm.rewardInfo.reward_value))
                  ])
                ])
              : _vm.rewardInfo.reward_type === 3
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.rewardLoading,
                        expression: "rewardLoading"
                      }
                    ],
                    staticClass: "reward-list"
                  },
                  _vm._l(_vm.rewardDetail, function(i, v) {
                    return _c(
                      "div",
                      { key: v, staticClass: "reward-list__item" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(i.name))
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(_vm._s(i.data))
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }