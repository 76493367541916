var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "分组", visible: _vm.visible, width: "420px" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.fn_close
      }
    },
    [
      _c("div", { staticClass: "group-count" }, [
        _vm._v(" 已选择（"),
        _c("span", { staticClass: "q-primary" }, [_vm._v(_vm._s(_vm.count))]),
        _vm._v("）个素材 ")
      ]),
      _c(
        "el-scrollbar",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          staticClass: "group-scroll"
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.group,
                callback: function($$v) {
                  _vm.group = $$v
                },
                expression: "group"
              }
            },
            _vm._l(_vm.groups, function(item) {
              return _c(
                "el-radio",
                { key: item.group_id, attrs: { label: item.group_id } },
                [_vm._v(_vm._s(item.name || "默认分组"))]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                disabled: _vm.dataLoading,
                loading: _vm.btnLoading
              },
              on: { click: _vm.fn_confirm }
            },
            [_vm._v("确定分组")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }