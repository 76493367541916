<template>
    <div class="page-warp">
      <ly-table
        ref="lyTable"
        :data="tableData"
        :option="tableOpt"
        :page="page"
        :pageSize="per_page"
        :total="total"
        @searchChange="searchChange"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selection-change="handleSelectionChange"
      >
        <template #handler>
          <el-button
            icon="iconfont icon-download"
            round
            size="medium"
            type="primary"
            @click="exportHandle"
            >导出数据</el-button
          >
          <!-- <el-button size="medium">导出记录</el-button> -->
        </template>
        <template #wecom_user_id_slot="{ row }">
          <div class="flex flex-start">
            <img
              v-if="row.avatarUrl"
              :src="row.avatarUrl"
              alt=""
              class="ly-table__main-avatar"
            />
            <img
              v-else
              src="../../../assets/images/default_avatar.png"
              alt=""
              class="ly-table__main-avatar"
            />
            <div>
              {{ row.userName || "-" }}
            </div>
          </div>
        </template>

        <template #activityName_slot="{ row }">
          <div class="flex flex-start">
            {{ row.activityName }}
          </div>
        </template>

        <template #source_slot="{ row }">
          <template v-if="row.source === 0"> 裂变活动-任务宝 </template>
          <template v-if="row.source === 1"> 裂变活动-群裂变 </template>
          <template v-if="row.source === 2"> 推广链接 </template>
          <template v-if="row.source === 3"> 推广二维码 </template>
          <template v-if="row.source === 4"> 裂变活动-抽奖 </template>
        </template>

        <template #activityStage_slot="{ row }" class="test">
          <template v-if="row.activityStage === 1"> 一阶 </template>
          <template v-if="row.activityStage === 2"> 二阶 </template>
          <template v-if="row.activityStage === 3"> 三阶 </template>
          <template v-if="row.activityStage === 4"> 四阶 </template>
          <template v-if="row.activityStage === 5"> 五阶 </template>
          <template v-if="row.activityStage === 6"> 六阶 </template>
          <template v-if="row.activityStage === 7"> 七阶 </template>
          <template v-if="row.activityStage === 8"> 八阶 </template>
        </template>

        <template #create_time_text_slot="{ row }">
          <div>
            {{ util.timeTofmt(row.createTime, "yyyy-MM-dd hh:mm") }}
          </div>
        </template>
      </ly-table>
      <!-- pagination End -->
      <detail-com ref="detailCom" :userInfo="detailInfo"></detail-com>
    </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import dataPanel from './components/dataPanel.vue'

import detailCom from './components/detailCom'
import { fDetails, tables } from './http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: fDetails,
      visible: false,
      detailInfo: {},
      DATA_LOCATION: 'records'
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {

        operation: [
          // 是否显示操作栏 为空或者不传时隐藏  自带 'detail', 'edit', 'delete' 字符串形式传入
          {
            label: '详情',
            handler (row) {
              _this.lookDetail(row)
            }
          }
        ],
        column: [
          {
            label: '用户',
            slot: 'wecom_user_id_slot'
          },
          {
            label: '填写来源',
            search: true,
            type: 'select',
            prop: 'source',
            slot: 'source_slot',
            default: '',
            source: [
              { label: '全部', value: '' },
              {
                value: 0,
                label: '裂变活动-任务宝'
              }, {
                value: 1,
                label: '裂变活动-群裂变'
              }, {
                value: 4,
                label: '裂变活动-抽奖'
              }, {
                value: 2,
                label: '推广链接'
              }, {
                value: 3,
                label: '推广二维码'
              }]
          },
          {
            label: '活动名称',
            search: true,
            type: 'select',
            prop: 'activityId',
            slot: 'activityName_slot',
            source: this.getCreateList,
            filterable: true,
            sourceField: {
              value: 'activityId',
              label: 'activityName'
            }
          },
          {
            label: '活动阶段',
            prop: 'activityStage',
            slot: 'activityStage_slot'
          },
          {
            label: '提交时间',
            prop: 'createTime',
            slot: 'create_time_text_slot',
            key: ['startDate', 'endDate'],
            search: true,
            placeholder: '请选择提交时间',
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss'
            // formItemProps: {
            //   clearable: false,
            //   'default-time': ['00:00:00', '00:00:00']
            // },
            // pickerOptions: {
            //   disabledDate: (time) => {
            //     if (this.timeOptionRange) {
            //       const day1 = 29 * 8.64e7
            //       const maxTime = this.timeOptionRange + day1
            //       const minTime = this.timeOptionRange - day1
            //       return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
            //     } else {
            //       return time.getTime() > Date.now()
            //     }
            //   },
            //   onPick: (time) => {
            //     if (time.minDate && !time.maxDate) {
            //       this.timeOptionRange = new Date(time.minDate).getTime()
            //     }
            //     if (time.maxDate) {
            //       this.timeOptionRange = null
            //     }
            //   }
            // }
          }]
      }
    }
  },
  created () {
    this._iniFormTable()
  },
  mounted () {
    this.$nextTick(() => {
      // this.$refs.detailCom.visible = true
    })
  },
  methods: {
    // 导出
    async exportHandle () {
      const data = await fDetails.exportDeatil({
        formId: this.$route.query.formId,

        page: this.page,
        per_page: this.per_page,
        ...this.sort,
        ...this.params

      })
      const a = document.createElement('a')
      a.href = data
      a.download = '我是文件名'
      a.click()
    },
    // 获取活动列表
    async getCreateList () {
      const data = await fDetails.getActivityList({
        formId: this.$route.query.formId
      })
      // this.createList = data
      return data
    },
    async _iniFormTable () {
      const data = await tables.getOneForm(this.$route.query.id)
      // console.log(data)
      this.$store.commit('_SET', {
        'global.cusTitle': ` - ${data.name}`
      })
    },
    lookDetail (row) {
      this.detailInfo = row
      this.$nextTick(() => {
        // this.$message.info('调整')
        this.$refs.detailCom.show()
      })
    },

    formatterParams (params) {
      return {
        ...params,
        id: this.$route.query.formId
      }
    }

  },
  components: {
    dataPanel,
    detailCom
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/utils.scss";
@import "@assets/scss/layout.scss";
</style>
