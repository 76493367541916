<!--
 * @Author: zhanln
 * @Date: 2021-08-19 17:47:32
 * @LastEditTime: 2022-06-09 10:04:38
 * @LastEditors: zhan
 * @Description: 个人活码数据分析
-->

<template>
  <div class="qr-analysis">
    <div class="qr-analysis-wrap">
      <el-form ref="form" inline size="medium">
        <el-form-item label="渠道：">
          <div class="flex">
            <el-radio-group v-model="channelType" @change="changeChannelType">
              <el-radio label="mkdir">按分组查看</el-radio>
              <el-radio label="file">按活码查看</el-radio>
            </el-radio-group>
            <tree-select @getEmpId="getEmpId" :treeData="groupList" :max="100" :empTotal="empTotal"
              :chooseType="channelType" class="ml-24" ref="treeSelect" :inputPlaceholder="
                channelType == 'file' ? '请输入活码' : '请输入分组名称'
              " :placeholder="channelType == 'file' ? '请选择活码' : '请选择分组'"
              :title="channelType == 'file' ? '请选择活码' : '请选择分组'" :empAll="channelType == 'file' ? '全部活码' : '全部分组'">
            </tree-select>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="navTabs">
      <el-row type="flex" justify="space-between">
        <el-col :span="12">
          <el-tabs v-model="navIndex" @tab-click="fn_setTab" class="ly-tabs tabs-step mb-0">
            <el-tab-pane :label="item.name" :name="item.name" :index="item.name" v-for="(item, index) in tabs"
              :key="index"></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
    <div class="qr-analysis-panel grey-wrapper" id="dataPanel">
      <analysis-panel :panelInfo="panelInfo" />
    </div>
    <div class="time-wrapper grey-wrapper" id="growthTrendPanel">
      <timeSelect @emitInfo="timeSelectEmit" :startTime="startTime" />
    </div>

    <div class="grey-wrapper card-wrapper">
      <el-card>
        <div class="main-title">增长趋势</div>
        <div>
          <el-radio-group v-model="radiosIndex" @change="initMapChart">
            <el-radio :label="3">看渠道增长</el-radio>
            <el-radio :label="6">看指标增长</el-radio>
          </el-radio-group>
        </div>

        <!-- <el-row v-if="isShowEmpty">
          <el-col
            :span="24"
            class="flex flex-center mt-20"
            style="text-align: center"
          >
            <el-empty :image-size="200" description="请选择渠道码"></el-empty>
          </el-col>
        </el-row> -->

        <div class="mt-20">
          <el-row class="flex flex-between">
            <div class="flex flex_item">
              <el-radio-group v-model="radioButtonCharts" @change="radioButtonChartsChange">
                <el-radio-button label="用户数"></el-radio-button>
                <el-radio-button label="新增用户数"></el-radio-button>
                <el-radio-button label="新增留存率"></el-radio-button>
              </el-radio-group>

              <div class="flex remarksInfo ml-18">
                统计说明
                <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
                  <div slot="content">
                    用户数：当天留存的用户数<br />
                    新增用户数：扫码添加（包含当天已删除）的用户总数，此前通过该活码添加再删除的用户不纳入计算；合计：每日新增用户数之和<br />
                    新增留存率：新增且当天未删除的用户数/当天新增用户数；合计：新增且该段时间内未删除的用户数/该段时间内新增用户数
                  </div>
                  <i class="iconfont icon-info-fill" style="color: #b4bbcc"></i>
                </el-tooltip>
              </div>
            </div>
            <el-button @click="exportExcel" type="primary" round icon="iconfont icon-download"
              v-if="tableData.length > 0">导出图表</el-button>
          </el-row>

          <lyChart :option="chartOption" height="450px" />

          <!-- 渠道增长table -->
          <template v-if="tableData.length > 0">
            <el-table v-if="radiosIndex == 3" :data="tableData" max-height="600" border style="width: 100%"
              class="mt-40" ref="tableRef" :show-summary="radioButtonCharts == '用户数' ? false : true"
              :summary-method="getSummaries">
              <el-table-column align="center" :prop="item.prop" :label="item.label" v-for="(item, ind) in tableHeader"
                :key="ind">
              </el-table-column>
              <div slot="empty" class="ly-table-empty">
                <slot name="empty">
                  <img src="@assets/svg/default/no_data.svg" alt="" />
                  暂无数据
                </slot>
              </div>
            </el-table>

            <!-- /end 指标增长table -->
            <el-table v-else :data="contrastTableData" show-summary border style="width: 100%" class="mt-40"
              max-height="600" ref="contrastTableRef" :summary-method="getContrastSummaries">
              <el-table-column label="时间" prop="time"></el-table-column>
              <el-table-column label="用户数" prop="aliveUserCount"></el-table-column>
              <el-table-column label="新增用户数" prop="addUserCount"></el-table-column>
              <el-table-column label="新增留存率" prop="addRetentionRate"></el-table-column>
              <div slot="empty" class="ly-table-empty">
                <slot name="empty">
                  <img src="@assets/svg/default/no_data.svg" alt="" />
                  暂无数据
                </slot>
              </div>
            </el-table>
          </template>
        </div>
      </el-card>
    </div>

    <div class="grey-wrapper card-wrapper mt-16" id="channelPanel">
      <el-card>
        <div class="flex flex-between mb-40">
          <div class="main-title">渠道对比</div>
          <el-button v-if="!contrastEmpty" @click="exportContrast" type="primary" round icon="iconfont icon-download">
            导出图表</el-button>
        </div>

        <!-- <el-row v-if="isShowEmpty">
          <el-col
            :span="24"
            class="flex flex-center"
            style="text-align: center"
          >
            <el-empty :image-size="200" description="请选择渠道码"></el-empty>
          </el-col>
        </el-row> -->

        <el-row>
          <el-col :span="12">
            <lyChart :option="customerData" height="400px" v-if="!contrastEmpty" />
            <template v-else>
              <div class="emptytitle">用户数</div>
              <div class="emptyCir"></div>
            </template>
          </el-col>
          <el-col :span="12">
            <lyChart :option="addCustomerData" height="400px" v-if="!contrastEmpty" />
            <template v-else>
              <div class="emptytitle">新增用户数</div>
              <div class="emptyCir"></div>
            </template>
          </el-col>
        </el-row>

        <el-table :data="channelData" max-height="600" border style="width: 100%" class="mt-40" ref="contrastTable"
          v-if="channelData.length > 0">
          <el-table-column align="center" prop="name" label="渠道">
          </el-table-column>
          <el-table-column align="center" prop="aliveUserCount" label="用户数">
            <template #header>
              用户数
              <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
                <div slot="content">留存的用户数</div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="addUserCount" label="新增用户数">
            <template #header>
              新增用户数
              <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
                <div slot="content">
                  扫码添加（包含当天已删除）的用户总数，此前通过活码添加再删除的用户不纳入计算；合计：每日新增用户数之和
                </div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="addRetentionRate" label="新增留存率">
            <template #header>
              新增留存率
              <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
                <div slot="content">
                  新增且当天未删除的用户数/当天新增用户数；合计：新增且该段时间内未删除的用户数/该段时间内新增用户数
                </div>
                <i class="iconfont icon-info-fill"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty" class="ly-table-empty">
            <slot name="empty">
              <img src="@assets/svg/default/no_data.svg" alt="" />
              暂无数据
            </slot>
          </div>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import lyChart from '@components/lyChart'
import analysisPanel from './components/analysisPanel.vue'
import treeSelect from './components/treeSelect.vue'
import timeSelect from './components/timeSelect.vue'
import { analysis } from './http.js'
import { exportExcelHandle } from '@/common/excel.js'
export default {
  data () {
    return {
      groupList: [],
      panelInfo: {},
      tabs: [{
        name: '数据概览'
      }, {
        name: '增长趋势'
      }, {
        name: '渠道对比'
      }],
      isShowEmpty: true,
      channelType: 'mkdir',
      navIndex: '数据概览',
      radiosIndex: 3,
      empTotal: 0,
      radioButtonCharts: '用户数',
      _multimediaData: [],
      chartOption: {},
      tableData: [],
      tableHeader: [],
      tableFooter: [],
      customerData: {},
      addCustomerData: {},
      channelData: [],
      contrastTableData: [],
      startTime: '',
      contrastEmpty: false // 渠道对比数据是否为空
    }
  },
  created () {
    // this._pmsId = [{ name: '四偶偶', id: 2 }]
    this.growthTrendIsLoading = false
    this.getContrastIsLoading = false
  },

  mounted () {
    // main主体滚动监测
    this.$nextTick(() => {
      const main = document.querySelector('.qr-analysis')
      main && main.addEventListener('scroll', this.fn_getScroll)
    })
    this.getTopic()
  },
  methods: {
    async getEmpId (pmsId) {
      this._pmsId = pmsId
      // 如果第一个组下面没有任何活码
      if (this.channelType == 'mkdir') {
        const _len = pmsId.filter(_ => _.liveCodeInfoVOList.length > 0).length
        if (!_len) {
          this.resetCharts()
          return
        }
      } else if (this.channelType == 'file') {
        if (!pmsId) {
          this.resetCharts()
          return
        }
      }

      const _arr = pmsId.map(item => { return { name: item.name, id: item.id } })
      const data = await analysis.multimediaRecordOverview({
        list: _arr,
        type: this.channelType == 'mkdir' ? 1 : 0
      })
      this.panelInfo = data

      await this.getCodeStartTime()
      await this.getGrowthTrend()
      await this.getContrast()
      this.fn_iniscroll()
    },
    async getTopic () {
      let res = await analysis.fuzzySearch({
        groupName: ''
      })
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      // !item.count > 0,
      res = res.map(item => { return { ...item, mkdir: true, name: item.groupName } })
      res.sort((a, b) => b.liveCodeInfoVOList.length - a.liveCodeInfoVOList.length)

      // this.empTotal = res.reduce((total, item) => { return total += item.count }, 0)
      this.empTotal = res.reduce((total, item) => { return total += 1 }, 0)

      this._copyRes = JSON.parse(JSON.stringify(res))

      res.forEach(item => {
        item.liveCodeInfoVOList = item.liveCodeInfoVOList.map(_ => { return { ..._, name: _.liveCodeName } })
        // item.liveCodeInfoVOList = []
      })

      this.groupList = res

      this.$nextTick(() => {
        this.$refs.treeSelect.fn_initTree()
      })
    },
    // 获取活码开始时间
    async getCodeStartTime () {
      const _arr = this._pmsId.map(item => item.id)
      const data = await analysis.getCodeStartTime({
        idList: _arr,
        type: this.channelType == 'mkdir' ? 1 : 0
      })

      this.startTime = data
    },
    // 获取多渠道增长趋势
    async getGrowthTrend () {
      if (this.growthTrendIsLoading || !this._pmsId) return
      this.dataMap = {}

      const _arr = this._pmsId.map(item => {
        this.dataMap[item.id] = {
          data: [],
          name: item.name,
          type: 'line'
        }
        return { name: item.name, id: item.id }
      })

      this.growthTrendIsLoading = true

      const data = await analysis.multimediaGrowthTrend({
        endTime: this.util.timeTofmt(this._timerArr[1]),
        startTime: this.util.timeTofmt(this._timerArr[0]),
        list: _arr,
        type: this.channelType == 'mkdir' ? 1 : 0
      })

      this._multimediaData = data

      await this.initMapChart()
      this.growthTrendIsLoading = false
    },
    // 渠道对比
    async getContrast () {
      if (this.getContrastIsLoading || !this._pmsId) return
      const _arr = this._pmsId.map(item => { return { name: item.name, id: item.id } })
      this.getContrastIsLoading = true
      const data = await analysis.contrast({
        endTime: this.util.timeTofmt(this._timerArr[1]),
        startTime: this.util.timeTofmt(this._timerArr[0]),
        list: _arr,
        type: this.channelType == 'mkdir' ? 1 : 0
      })
      const _data = data.length ? data.slice(0, data.length - 1) : []
      // 没有任何信息
      this.getContrastIsLoading = false
      if (_data.length) {
        this.customerData = this.getPieChartSource('用户数', _data.map(_ => { return { ..._, value: _.aliveUserCount } }))
        this.addCustomerData = this.getPieChartSource('新增用户数', _data.map(_ => { return { ..._, value: _.addUserCount } }))
        this.contrastEmpty = false
      } else {
        this.contrastEmpty = true
      }

      if (data.length) {
        data.forEach(item => {
          item.addRetentionRate = item.addRetentionRate ? item.addRetentionRate + '%' : item.addRetentionRate
        })
      }

      this.channelData = data
    },
    // 获取圆饼数据
    getPieChartSource (text, data) {
      return {
        // color: ['#2B7CFF', '#2FC25B', '#FACC14'],
        title: {
          text: text,
          left: 'center'
        },
        legend: {
          icon: 'circle',
          bottom: '1%'
        },
        tooltip: {
          trigger: 'item',
          formatter: function (arg) {
            // arg.forEach(item => {
            const str = `<div>${arg.marker}${arg.name}：${arg.value}人，${arg.percent}%</div>`
            // })
            return str
          }
        },
        grid: {
          top: '0%',
          bottom: '3%',
          left: '4%',
          containLabel: true
        },
        label: {
          alignTo: 'edge',
          formatter: (params) => {
            return `{color1|${params.name}}\n{color2|${params.data.value}人，}{color2|${params.percent}%}`
          },
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            color1: {
              fontSize: 14,
              color: '#212b36'
            },
            color2: {
              fontSize: 14,
              color: '#454D5B',
              padding: [20, 0, 0, 0]
            }
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            // minAngle: 10,
            itemStyle: {
              borderRadius: 5,
              borderWidth: 5,
              borderColor: '#fff'
            },
            data: data
          }
        ],
        color: ['#2B7CFF']
      }
    },

    // 增长趋势
    initMapChart () {
      const _mj = { 用户数: 'aliveUserCount', 新增用户数: 'addUserCount', 新增留存率: 'addRetentionRate' }
      const _keys = _mj[this.radioButtonCharts]
      const _copyData = this._multimediaData.length ? this._multimediaData.slice() : []
      _copyData.forEach(item => {
        item.list.forEach(_ => {
          if (this.dataMap[_.id]) {
            this.dataMap[_.id].data.push(_)
          }
        })
      })

      // 拿到合计信息
      if (_copyData.length <= 1) {
        this.emptyDataLine()
        return
      }

      const _footer = _copyData.pop()
      _footer.list.forEach(item => {
        if (item.addRetentionRate && item.addRetentionRate.toString().indexOf('%') == -1) {
          item.addRetentionRate += '%'
        }
      })
      this.tableFooter = _footer.list

      let legend = []
      let series = []

      if (this.radiosIndex == 3) {
        legend = Object.values(this.dataMap).map(_ => _.name)
        series = Object.values(this.dataMap).map(mp => {
          return {
            ...mp,
            data: mp.data.map(_ => _[_keys]),
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: '#fff'
                },
                {
                  offset: 0,
                  color: '#EBF3FF'
                }
              ])

            }
          }
        })
      } else if (this.radiosIndex == 6) {
        legend = [this.radioButtonCharts]
        series = {
          name: this.radioButtonCharts,
          data: _copyData.map(item => item[_keys] || 0),
          type: 'line',
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: '#fff'
              },
              {
                offset: 0,
                color: '#EBF3FF'
              }
            ])
          }
        }
      }

      let min = 0
      let max = 0

      if (_keys !== 'addRetentionRate') {
        if (this.radiosIndex == 3) {
          series.forEach(item => {
            item.child = JSON.parse(JSON.stringify(item)).data.sort((a, b) => b - a)
            item.max = item.child.shift()
            item.min = item.child.pop()
          })

          series.map(_ => {
            max = Math.max(max, _.max)
            min = Math.max(min, _.min)
          })
        } else {
          series.child = JSON.parse(JSON.stringify(series)).data.sort((a, b) => b - a)
          max = series.child.shift()
          min = series.child.pop()
        }

        max = Math.ceil(max * 1.1)
        min = Math.floor(min * 0.9)
        max = max > 10 ? max : 10
      } else {
        min = 0
        max = 100
      }

      const yAxis = {
        type: 'value',
        minInterval: 1,
        min: 0,
        max: max
      }

      const tooltip = {
        show: true, // 是否显示
        trigger: 'axis'
      }
      if (_keys == 'addRetentionRate') {
        Object.assign(yAxis, {
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %'
          }
        })

        tooltip.formatter = function (arg) {
          let str = `<div>${arg[0].axisValue}</div>`
          arg.forEach(item => {
            str += `<div>${item.marker}${item.seriesName}：${item.data}%</div>`
          })
          return str
        }
      }

      const json = {
        title: {
          text: ''
        },
        tooltip: tooltip,
        legend: {
          icon: 'circle',
          data: legend,
          x: 'center',
          y: 'bottom',
          padding: [50, 0, 0, 0]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: false
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: _copyData.map(_ => _.time)
        },
        yAxis: yAxis,
        color: ['rgb(43, 124, 255)', 'rgb(0, 199, 128)', 'rgb(250, 210, 12)'],
        series: series
      }

      this.chartOption = json

      if (this.radiosIndex == 6) {
        this.initTargetGrow()
      } else {
        this.growthTrednTable(_copyData, _keys)
      }
    },
    // 空数据状态下的空折线图
    emptyDataLine () {
      const json = {
        title: {
          text: ''
        },
        tooltip: {
          show: false
        },
        legend: {
          show: false,
          x: 'center',
          y: 'bottom',
          padding: [50, 0, 0, 0]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: false
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [this.util.timeTofmt(this._timerArr[0])]
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          min: 0,
          max: 10
        },
        color: ['rgb(43, 124, 255)', 'rgb(0, 199, 128)', 'rgb(250, 210, 12)'],
        series: []
      }
      this.tableHeader = []
      this.tableData = []
      this.tableFooter = []

      this.chartOption = json
    },

    // 获取总和
    getSummaries (param) {
      if (!this.tableFooter.length) return param
      const _mj = { 用户数: 'aliveUserCount', 新增用户数: 'addUserCount', 新增留存率: 'addRetentionRate' }
      const _keys = _mj[this.radioButtonCharts]
      const sums = ['合计']
      const { columns, data: [a] } = param

      for (let i = 1; i < columns.length; i++) {
        const _d = this.tableFooter.find(_ => _.id == a['id_' + i])
        sums[i] = _d[_keys]
      }

      return sums
    },

    // 获取指标增长综合
    getContrastSummaries (param) {
      if (!this.tableFooter.length) return param
      const sums = ['合计']
      const { columns, data } = param

      const _end = this._multimediaData[this._multimediaData.length - 1]
      for (let i = 1; i < columns.length; i++) {
        sums[i] = _end[columns[i].property]
        if (columns[i].property == 'addRetentionRate') {
          sums[i] += '%'
        }
      }
      return sums
    },

    // 增长趋势-指标增长表格信息
    initTargetGrow () {
      this.contrastTableData = JSON.parse(JSON.stringify(this._multimediaData.slice(0, this._multimediaData.length - 1)))

      this.contrastTableData.forEach(item => {
        if (item.addRetentionRate && item.addRetentionRate.toString().indexOf('%') == -1) {
          item.addRetentionRate += '%'
        }
      })
    },

    // 构建增长趋势-渠道增长表格信息
    growthTrednTable (_copyData, _keys) {
      const _arr = []
      const _header = [{
        prop: 'time',
        label: '日期'
      }]
      // 构建页面需要的table数据
      // 由于数据是横向的，需要构造成纵向的
      // [{list: [{}, {}]},{list: [{}, {}]}]
      // 转换成：
      // [{...list[0], ...list[1]}, {...list[0], ...list[1]}]
      // 又因为字段名会一模一样，因此在每个字段后面新增了个索引值
      _copyData.forEach((_m, _mk) => {
        const _json = { time: _m.time }
        _m.list.forEach((li, _likey) => {
          if (_mk < 1) {
            _header.push({
              prop: _keys + '_' + (_likey + 1),
              label: li.name
            })
          }
          Object.entries(li).map((kys, _kysindex) => {
            let [_k, _v] = kys

            if (_k == 'addRetentionRate' && _v) {
              _v += '%'
            }
            _json[_k + '_' + (_likey + 1)] = _v
          })
        })
        _arr.push(_json)
      })

      if (_copyData.length > 0) {
        this.tableHeader = _header
        this.tableData = _arr
      }
    },

    // 渠道切换
    changeChannelType () {
      if (this.channelType == 'mkdir') {
        this.empTotal = this.groupList.reduce((total, item) => { return total += 1 }, 0)
      } else {
        this.empTotal = this.groupList.reduce((total, item) => { return total += item.count }, 0)
      }

      // let _copyarr = this._copyRes.slice(0, this._copyRes.length - 1)
      // _copyarr.forEach(item => {
      //   if (this.channelType == 'mkdir') {
      //     item.liveCodeInfoVOList = []
      //   } else {
      //     item.liveCodeInfoVOList = item.liveCodeInfoVOList.map(_ => { return { ..._, name: _.liveCodeName } })
      //   }
      // })
      // this.groupList = _copyarr
      // this.$nextTick(() => {

      this.$refs.treeSelect.fn_initTree()
      // })
    },

    exportExcel () {
      const _mj = { 用户数: 'aliveUserCount', 新增用户数: 'addUserCount', 新增留存率: 'addRetentionRate' }
      const _keys = _mj[this.radioButtonCharts]

      let totalData = []

      if (this.radiosIndex == 3) {
        if (_keys !== 'aliveUserCount') {
          totalData = ['合计']
          this.tableFooter.forEach(item => {
            totalData.push(item[_keys] || null)
          })
        }
        exportExcelHandle({ tableEl: this.$refs.tableRef, xlsxName: `${this.radioButtonCharts}的增长明细.xlsx`, totalData })
      } else {
        const _end = this._multimediaData[this._multimediaData.length - 1]

        totalData = ['合计', null, _end.addUserCount, _end.addRetentionRate + '%']

        exportExcelHandle({ tableEl: this.$refs.contrastTableRef, xlsxName: '各指标的增长明细.xlsx', totalData })
      }
    },

    timeSelectEmit (timeArr) {
      this._timerArr = timeArr
      this.getGrowthTrend()
      this.getContrast()
    },
    radioButtonChartsChange () {
      this.initMapChart()
    },
    // 渠道对比导出
    exportContrast () {
      exportExcelHandle({ tableEl: this.$refs.contrastTable, xlsxName: '各渠道的对比明细.xlsx' })
    },
    fn_setTab () {
      if (this.navIndex == '增长趋势') {
        this.scrollEl.scrollTo(0, this.growthTrendPanel - 150)
      } else if (this.navIndex == '渠道对比') {
        this.scrollEl.scrollTo(0, this.channelPanel - 160)
      } else {
        this.scrollEl.scrollTo(0, this.dataPanel - 140)
      }
    },
    fn_iniscroll () {
      this.scrollEl = document.querySelector('.qr-analysis')
      this.growthTrendPanel = document.querySelector('#growthTrendPanel').getBoundingClientRect().top
      this.channelPanel = document.querySelector('#channelPanel').getBoundingClientRect().top
      this.dataPanel = document.querySelector('#dataPanel').getBoundingClientRect().top
    },

    // 页面刷新
    fn_beforeunload (e) {
      e = e || window.event
      if (e) {
        e.returnValue = ''
      }
      return ''
    },

    fn_getScroll (e) {
      const main = e.target || e.srcElement || e.path[0]

      const scrollList = [Math.floor(this.dataPanel - 150), Math.floor(this.growthTrendPanel - 150), Math.floor(this.channelPanel - 160)]
      const scrollMap = {
        0: '数据概览',
        1: '增长趋势',
        2: '渠道对比'
      }

      let _md = 0
      for (let i = 0; i <= scrollList.length; i++) {
        if (main.scrollTop >= scrollList[i]) {
          _md = i
        }
      }

      this.navIndex = scrollMap[_md]
    },

    // 空状态逻辑
    resetCharts () {
      this.emptyDataLine()
      this.contrastEmpty = true
      this.contrastTableData = []
      this.channelData = []
      this._multimediaData = []
      this.startTime = this.util.timeTofmt(this._timerArr[1])
      this.panelInfo = {}
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.fn_beforeunload, false)
  },
  destroyed () {
    window.removeEventListener('scroll', this.fn_getScroll)
  },
  components: {
    analysisPanel,
    lyChart,
    treeSelect,
    timeSelect
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
@import "@assets/scss/utils.scss";
@import "@assets/scss/layout.scss";

.qr-analysis {
  height: 100%;
  overflow: auto;
  background-color: #f8f9fb;

  .qr-analysis-wrap {
    padding: 20px 20px 0;
    background: #fff;
  }

  ::v-deep {
    .el-card {
      border-radius: 16px;
    }

    .el-form-item__label {
      padding: 0;
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-form-item {
      margin-bottom: 20px;
    }

    .el-table {
      border-radius: 8px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .grey-wrapper {
    background-color: #f8f9fb;
  }

  .qr-analysis-panel {
    padding: 20px;
  }

  .time-wrapper {
    padding: 0 20px;

    // margin-left: 16px;
  }

  .navTabs {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
    padding-left: 24px;
  }

  .card-wrapper {
    padding: 0 20px;

    .card-title {
      font-size: 16px;
      font-weight: 500;
      color: #212b36;
    }
  }

  .main-title {
    font-size: 16px;
    font-weight: 500;
    color: #212b36;
    margin-bottom: 16px;
    padding-left: 10px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 5px;
      height: 12px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #2b7cff;
      border-radius: 2.5px;
    }
  }

  .remarksInfo {
    color: #454d5b;
    font-size: 12px;
  }

  .emptyCir {
    border: 1px solid #ddd;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    margin: 0 auto;
    background: #ccc;
  }

  .emptytitle {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
