<!--
  选择小程序组件
-->
<template>
  <el-dialog
    title="从聊天记录中选择小程序"
    append-to-body
    :visible.sync="visible"
    @open="handleSearch"
    width="800px"
  >
    <div class="left">
      <div class="title">
        聊天好友
        <el-button round type="primary" @click="handleSearch">同步</el-button>
      </div>
      <el-input
        v-model="name"
        clearable
        placeholder="请输入好友昵称，按回车搜索"
        suffix-icon="el-icon-search"
        @keyup.enter.native="handleSearchByName"
      ></el-input>
      <el-scrollbar class="list">
        <div
          class="item"
          :class="{
            active:
              item.external_user_id === current.external_user_id &&
              item.user_id === current.user_id,
          }"
          v-for="item in list"
          :key="item.external_user_id + '_' + item.user_id"
          @click="getMessageRecord(item)"
        >
          <el-avatar
            shape="square"
            :size="32"
            :src="item.avatar_url"
          ></el-avatar>
          <div class="temp">
            <span class="name">{{
              `${item.name} ${item.corp_name ? "| " + item.corp_name : ""}`
            }}</span>
            <span class="account"
              >所属账号：{{ item.wecom_user_name.join("，") }}</span
            >
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="right">
      <div class="title">包含小程序聊天记录</div>
      <div class="applet-list">
        <div
          class="applet-item"
          :class="{ 'by-robot': !item.is_send_by_client }"
          v-for="item in messageRecord"
          :key="item.msg_id"
        >
          <div class="user" v-if="item.is_send_by_client">
            <el-avatar
              shape="square"
              :size="32"
              :src="current.avatar_url"
            ></el-avatar>
            <div class="temp">
              <span class="name">{{ current.name }}</span>
              <span class="date">{{
                $day.unix(item.send_at).format("YYYY年MM月DD日 HH:mm:ss")
              }}</span>
            </div>
          </div>
          <div class="user" v-else>
            <el-avatar
              shape="square"
              :size="32"
              :src="robotInfo.avatar"
            ></el-avatar>
            <div class="temp">
              <span class="name">{{ robotInfo.name }}</span>
              <span class="date">{{
                $day.unix(item.send_at).format("YYYY年MM月DD日 HH:mm:ss")
              }}</span>
            </div>
          </div>
          <app-poster :applet="item.content" :cover="item.cover_image" />
          <el-button
            type="primary"
            @click="handleChoseApp(item.content, item.cover_image)"
            >选择小程序</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AppPoster from '@/components/AppPoster.vue'
import { common } from '../http'
export default {
  components: {
    AppPoster
  },
  data () {
    return {
      visible: false,
      name: '',
      list: [], // 好友列表
      current: {}, // 当前好友
      robotInfo: {}, // 当前机器人信息
      messageRecord: [] // 消息列表
    }
  },
  methods: {
    /**
     * @description: 通过昵称搜索
     * @param {*}
     * @return {*}
     */
    handleSearchByName () {
      this.handleSearch({ search_name: this.name })
    },
    /**
     * @description: 搜索带小程序聊天记录的好友
     * @param {*} params
     * @return {*}
     */
    handleSearch (params) {
      common.containsMini(params).then(res => {
        this.list = res.list || []
        this.getMessageRecord(this.current)
      })
    },
    /**
     * @description: 通过好友id查询聊天记录
     * @param {*} client_id
     * @return {*}
     */
    async getMessageRecord (current) {
      this.current = current
      const res = await common.messageRecord({
        external_user_id: current.external_user_id,
        user_id: current.user_id,
        msg_type: 2013,
        per_page: 9999
      })
      this.messageRecord = res.list || []
      this.robotInfo = res.robot_info || {}
      setTimeout(() => {
        const items = document.querySelectorAll('.applet-item')
        if (items.length) {
          items[items.length - 1].scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      })
    },
    /**
     * @description: 选择小程序
     * @param {*} info 小程序信息
     * @return {*}
     */
    handleChoseApp (info, cover_image) {
      if (cover_image) {
        info.cover_image = cover_image
      }
      this.visible = false
      this.$emit('chose', info)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.06);
  height: 32px;
  line-height: 32px;
  padding: 8px 0;
  margin: 0 20px;
  color: #212b36;
  .el-button {
    float: right;
  }
}
.left,
.right {
  background: #f7f8fc;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.left {
  width: 280px;
  .el-input {
    margin: 14px 20px 0 20px;
    width: 240px;
  }
  .list {
    margin-top: 14px;
    flex: 1;
    overflow: hidden;
    .item {
      display: flex;
      align-items: center;
      height: 64px;
      padding: 0 20px;
      cursor: pointer;
      &.active {
        background: #f0f1f8;
      }
      &:hover {
        background: #f0f1f8;
      }
      .temp {
        margin-left: 8px;
        height: 32px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        line-height: 1;
      }
      .name {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
      }
      .account {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
.right {
  flex: 1;
  margin-left: 16px;
  .applet-list {
    padding: 14px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .applet-item {
      margin-bottom: 20px;
    }
  }
  .user {
    display: flex;
    .temp {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .name,
    .date {
      line-height: 1;
      color: rgba(0, 0, 0, 0.85);
    }
    .date {
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
    }
  }
  .applet {
    margin: 16px 0 0 40px;
  }
  .by-robot {
    align-self: flex-end;
    .user {
      flex-direction: row-reverse;
    }
    .temp {
      align-items: flex-end;
      margin-right: 8px;
    }
  }
  .el-button {
    margin: 10px 0 0 68px;
    padding: 9px 40px;
  }
}
::v-deep {
  .el-dialog__body {
    display: flex;
    height: 545px;
  }
}
</style>
