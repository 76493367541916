<template>
  <div class="im">
    <slot name="im_header"></slot>

    <div class="chatWindow">
      <el-scrollbar ref="chatWindow_scrollbar" style="height: 100%">
        <msgBubbles
          v-for="item in msgList"
          :key="item.msg.id"
          :msgData="item"
        />
      </el-scrollbar>
    </div>
    <slot name="im_footer"></slot>
  </div>
</template>

<script>
import msgBubbles from './msgBubbles.vue'
export default {
  props: {
    msgList: {
      type: Array,
      default: () => []
    },
    hasScroll: {
      type: Boolean,
      default: true
    }
  },
  inheritAttrs: false,
  data () {
    return {
      textarea: '',
      accept: '',
      isTop: false
    }
  },
  components: {
    msgBubbles
  },
  methods: {
    /**
     * 将当前聊天窗口滚动到顶部
     */
    async messageViewToTop () {
      await this.$nextTick()
      const im_messgae = this.$refs.chatWindow_scrollbar
      if (im_messgae) {
        im_messgae.wrap.scrollTop = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.im {
  background: #fff;
  position: relative;
  flex: 1;
  .chatWindow{
    height: calc(100% - 95px);
  }
  ::v-deep{
    .el-scrollbar__view{
      padding: 0 10px;
    }
  }
}
</style>
