export const useCom = [
  {
    type: 'input',
    icon: 'icon-input',
    name: '手机号码',
    comName: '手机号码',
    svgIcon: 'phone',
    presetType: 0,
    mainType: 5,
    dataType: 1,
    options: {
      defaultValue: '',
      required: false,
      placeholder: '请输入',
      disabled: false,
      maxLength: 11,
      type: 'mobile',
      isCanAddOption: false,
      isShowMaxLength: false,
      isEdit: false
    }
  },

  {
    type: 'area',
    icon: 'icon-diy-com-textarea',
    svgIcon: 'address',
    name: '地址',
    comName: '地址',
    presetType: 1,
    mainType: 5,
    dataType: 1,
    options: {
      pro: '',
      city: '',
      area: '',
      desc: '',
      cityList: [],
      areaList: [],
      defaultValue: '',
      required: false,
      disabled: false,
      placeholder: '省份/城市/区县',
      descplaceholder: '详细地址',
      isShowPlaceholder: true,
      isEdit: false
    }
  },
  {
    type: 'select',
    icon: 'icon-select',
    name: '性别',
    comName: '性别',
    svgIcon: 'sex',
    presetType: 2,
    mainType: 5,
    dataType: 2,
    options: {
      defaultValue: '',
      disabled: false,
      placeholder: '请选择',
      required: false,
      isEdit: false,
      width: '',
      options: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        },
        {
          value: '保密',
          label: '保密'
        }
      ],
      props: {
        value: 'value',
        label: 'label'
      },
      isCanAddOption: true,
    }
  },
  {
    type: 'input',
    icon: 'icon-input',
    name: '微信号',
    comName: '微信号',
    svgIcon: 'wx',
    presetType: 3,
    mainType: 5,
    dataType: 1,
    options: {
      isEdit: false,
      defaultValue: '',
      required: false,
      placeholder: '请输入',
      disabled: false,
      maxLength: 20,
      isShowMaxLength: false
    }
  },
  {
    type: 'textarea',
    icon: 'icon-diy-com-textarea',
    name: '备注',
    comName: '备注',
    svgIcon: 'remarks',
    presetType: 4,
    mainType: 5,
    dataType: 1,
    options: {
      isEdit: false,
      defaultValue: '',
      required: false,
      disabled: false,
      placeholder: '请输入',
      maxLength: 100,
    }
  },


]
export const currencyCom = [
  {
    type: 'input',
    icon: 'icon-input',
    name: '单行文本',
    comName: '单行文本',
    svgIcon: 'input',
    mainType: 0,
    dataType: 1,
    options: {
      defaultValue: '',
      required: false,
      placeholder: '请输入',
      disabled: false,
      maxLength: 30,
      isShowMaxLength: true,
      valueMin: 0,
      valueMax: 100,
      isEdit: true
    }
  },
  {
    type: 'textarea',
    icon: 'icon-diy-com-textarea',
    name: '多行文本',
    comName: '多行文本',
    svgIcon: 'multiinput',
    mainType: 1,
    dataType: 1,
    options: {
      defaultValue: '',
      required: false,
      disabled: false,
      placeholder: '请输入',
      maxLength: 100,
      isShowMaxLength: true,
      valueMin: 0,
      valueMax: 200,
      isEdit: true
    }
  },
  {
    type: 'select',
    icon: 'icon-select',
    name: '下拉框',
    comName: '下拉框',
    svgIcon: 'select',
    mainType: 2,
    dataType: 1,
    options: {
      defaultValue: '',
      disabled: false,
      placeholder: '请选择',
      required: false,
      isCanAddOption: true,
      options: [
        {
          value: '选项一'
        }
      ],
      props: {
        value: 'value',
        label: 'label'
      },
      isEdit: true

    }
  },
  {
    type: 'radio',
    icon: 'icon-radio-active',
    name: '单选',
    comName: '单选',
    svgIcon: 'radio',
    mainType: 3,
    dataType: 2,
    options: {
      defaultValue: '',
      options: [
        {
          value: '选项一',
          label: '选项一'
        }
      ],
      required: false,
      // width: '',
      props: {
        value: 'value',
        label: 'label'
      },
      disabled: false,
      isCanAddOption: true,
      isEdit: true
    }
  },
  {
    type: 'checkbox',
    icon: 'icon-check-box',
    name: '多选',
    comName: '多选',
    svgIcon: 'multiradio',
    mainType: 4,
    dataType: 2,
    options: {
      defaultValue: [],
      options: [
        {
          value: '选项一',
          label: '选项一'
        }
      ],
      required: false,
      width: '',
      props: {
        value: 'value',
        label: 'label'
      },
      disabled: false,
      isCanAddOption: true,
      isEdit: true
    }
  },
]