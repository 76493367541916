var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ly-table-group page-warp" },
    [
      _c(
        "div",
        { staticClass: "ly-search" },
        [
          _c(
            "el-form",
            { attrs: { inline: true, size: "medium" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择活动：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "320px" },
                      attrs: { filterable: "" },
                      on: { change: _vm.fn_selectAct },
                      model: {
                        value: _vm.activityId,
                        callback: function($$v) {
                          _vm.activityId = $$v
                        },
                        expression: "activityId"
                      }
                    },
                    _vm._l(_vm.activityList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          "page-size": _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "medium",
                      round: "",
                      disabled:
                        _vm.loading || (!_vm.hasCloudBean && _vm.msg_mode === 1)
                    },
                    on: { click: _vm.fn_openAddGroup }
                  },
                  [_vm._v("新增群聊")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "groupMaster",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ly-tag", { attrs: { content: row.group_master, size: 12 } })
              ]
            }
          },
          {
            key: "countHeaderSlot",
            fn: function() {
              return [
                _c("span", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v("自动切群人数")
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      "popper-class": "q-tooltip"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " 每个群入群人数达到「自动切换人数」后，自动切换下个群，"
                      ),
                      _c("br"),
                      _vm._v(" 当群的总人数达到190人后，不再参与分配。 ")
                    ]),
                    _c("i", { staticClass: "icon-info-fill iconfont" })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "idheaderSlot",
            fn: function() {
              return [
                _c("span", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v("云端账号")
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      "popper-class": "q-tooltip"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("刷新云端账号状态")
                    ]),
                    _c("i", {
                      staticClass: "iconfont icon-shuaxin",
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.queryData }
                    })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "idSlot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "more-popover",
                  {
                    attrs: { title: "企业成员", list: row.yun_account || [] },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "reference",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.getOnlineCount(row.yun_account) + "人 "
                                )
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", { staticClass: "ly-team small" }, [
                                _c("i", {
                                  staticClass: "el-icon-s-custom",
                                  staticStyle: { "font-size": "16px" }
                                })
                              ]),
                              _c(
                                "span",
                                { staticClass: "m-group_name text-ellipsis" },
                                [
                                  _vm._v(" " + _vm._s(item.member_name) + " "),
                                  item.online_status
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#00a86e" } },
                                        [_vm._v("(在线)")]
                                      )
                                    : _c(
                                        "span",
                                        { staticStyle: { color: "#909399" } },
                                        [_vm._v("(离线)")]
                                      )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_vm._v("在线 ")]
                )
              ]
            }
          },
          {
            key: "countSlot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    row.edit_count
                      ? [
                          row.edit_count
                            ? _c("el-input-number", {
                                staticClass: "number-small",
                                attrs: {
                                  "controls-position": "right",
                                  min: 1,
                                  max: 190,
                                  size: "small"
                                },
                                model: {
                                  value: row.max_account,
                                  callback: function($$v) {
                                    _vm.$set(row, "max_account", $$v)
                                  },
                                  expression: "row.max_account"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "group-edit__btn",
                              on: {
                                click: function($event) {
                                  return _vm.fn_changeMax(row)
                                }
                              }
                            },
                            [_c("i", { staticClass: "iconfont icon-success" })]
                          )
                        ]
                      : [
                          _vm._v(" " + _vm._s(row.max_account) + " "),
                          _c(
                            "span",
                            {
                              staticClass: "group-edit__btn",
                              on: {
                                click: function($event) {
                                  row.edit_count = true
                                }
                              }
                            },
                            [_c("i", { staticClass: "iconfont icon-edit" })]
                          )
                        ]
                  ],
                  2
                )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              var index = ref.index
              return [
                _vm.msg_mode === 1
                  ? _c(
                      "ly-action",
                      { key: Date.now(), attrs: { display: 2 } },
                      [
                        _vm.hasCloudBean
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_msgAccount(row)
                                  }
                                }
                              },
                              [_vm._v("消息号")]
                            )
                          : _vm._e(),
                        _vm.hasCloudBean
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled: row.can_change_master === 0
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.fn_groupTransfer(row)
                                  }
                                }
                              },
                              [_vm._v(" 转让群主")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "iconfont icon-delete",
                              disabled: _vm.tableData.length === 1
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_delete(row)
                              }
                            }
                          },
                          [_vm._v(" 移除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "iconfont icon-up",
                              disabled: index === 0
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_changeSort(index, row, "up")
                              }
                            }
                          },
                          [_vm._v("上移")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "iconfont icon-down",
                              disabled: index === _vm.tableData.length - 1
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_changeSort(index, row, "down")
                              }
                            }
                          },
                          [_vm._v("下移")]
                        )
                      ],
                      1
                    )
                  : _c(
                      "ly-action",
                      { key: Date.now() },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: _vm.tableData.length === 1
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_delete(row)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", disabled: index === 0 },
                            on: {
                              click: function($event) {
                                return _vm.fn_changeSort(index, row, "up")
                              }
                            }
                          },
                          [_vm._v("上移")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: index === _vm.tableData.length - 1
                            },
                            on: {
                              click: function($event) {
                                return _vm.fn_changeSort(index, row, "down")
                              }
                            }
                          },
                          [_vm._v(" 下移")]
                        )
                      ],
                      1
                    )
              ]
            }
          }
        ])
      }),
      _c("msg-account", {
        ref: "msgAccountRef",
        on: { fn_changeMsgAccount: _vm.fn_changeMsgAccount }
      }),
      _c("group-transfer", {
        ref: "groupTransferRef",
        attrs: { gid: _vm.gid },
        on: {
          success: function($event) {
            return _vm.fn_selectAct(_vm.activityId)
          }
        }
      }),
      _c("select-group", {
        ref: "selectGroupRef",
        attrs: { hasCheck: true, selectList: _vm.selectList },
        on: { selectClose: _vm.fn_selectClose }
      }),
      _c("add-group", {
        ref: "addGroupRef",
        attrs: { activityId: _vm.activityId },
        on: { addSuccess: _vm.fn_addSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }