var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-warp groupManagement" }, [
    _c(
      "div",
      { staticClass: "form-area" },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { inline: "", model: _vm.mainForm } },
          [
            _c("div", { staticClass: "top-tips" }, [
              _c("p", [
                _vm._v(
                  " 1. 这里展示云端账号托管的群聊，每个云端账号最多能托管30个群。"
                ),
                _c("br"),
                _vm._v(
                  "2. 云端账号可在托管的群内进行无限制群发、数据统计等操作。"
                ),
                _c("br"),
                _vm._v(
                  " 3. 请确保相关的云端账号处于在线状态，否则将无法使用相关功能或者造成数据不精确。 "
                )
              ])
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "群名称" } },
              [
                _c("el-input", {
                  attrs: { clear: "", placeholder: "请输入群名称" },
                  model: {
                    value: _vm.mainForm.group_name,
                    callback: function($$v) {
                      _vm.$set(_vm.mainForm, "group_name", $$v)
                    },
                    expression: "mainForm.group_name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "群主" } },
              [
                _c("el-input", {
                  attrs: { clear: "", placeholder: "请输入群主名称" },
                  model: {
                    value: _vm.mainForm.owner_name,
                    callback: function($$v) {
                      _vm.$set(_vm.mainForm, "owner_name", $$v)
                    },
                    expression: "mainForm.owner_name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "托管账号" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.mainForm.robot_admin_id,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "robot_admin_id", $$v)
                      },
                      expression: "mainForm.robot_admin_id"
                    }
                  },
                  _vm._l(_vm.aliasOption, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "", "label-width": "0" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      icon: "iconfont icon-search",
                      type: "primary"
                    },
                    on: { click: _vm.handlerSearch }
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { round: "", icon: "iconfont icon-data" },
                    on: { click: _vm.fn_reset }
                  },
                  [_vm._v("重置")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "main-area" },
      [
        _c(
          "div",
          { staticClass: "hander-area" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  round: "",
                  icon: "iconfont icon-plus",
                  type: "primary",
                  size: "medium"
                },
                on: { click: _vm.handleAddManage }
              },
              [_vm._v("添加托管的群")]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "tooltip",
                attrs: {
                  content: "请先选择要分组的群",
                  effect: "dark",
                  placement: "top",
                  disabled: _vm.selected.length > 0
                }
              },
              [
                _c(
                  "span",
                  { staticStyle: { "margin-left": "10px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          round: "",
                          plain: "",
                          icon: "iconfont icon-edit",
                          size: "medium",
                          disabled: _vm.selected.length === 0
                        },
                        on: { click: _vm.handleGroup }
                      },
                      [_vm._v("分组")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "tooltip",
                attrs: {
                  content: "请先选择要移出分组的群",
                  effect: "dark",
                  placement: "top",
                  disabled: _vm.selected.length > 0
                }
              },
              [
                _c(
                  "span",
                  { staticStyle: { "margin-left": "10px" } },
                  [
                    _vm.topicId > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              plain: "",
                              size: "medium",
                              disabled: _vm.selected.length === 0
                            },
                            on: { click: _vm.handleRemoveGroup }
                          },
                          [_vm._v("移出分组")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "temp-container" },
          [
            _c("div", { staticClass: "group-list" }, [
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.groupList, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "list-item flex",
                      class: { active: _vm.topicId === item.id },
                      on: {
                        click: function($event) {
                          return _vm.handleTopic(item, $event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "temp flex" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                disabled: _vm.toolDisable,
                                effect: "dark",
                                content: item.name,
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ell name",
                                  on: { mouseenter: _vm.fn_groupEnter }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ]
                          ),
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s("(" + item.count + ")"))
                          ])
                        ],
                        1
                      ),
                      item.id > 0
                        ? _c(
                            "div",
                            { staticClass: "btn" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom-end",
                                    trigger: "click",
                                    "popper-class": "add-group-popover"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEdit(item)
                                        }
                                      },
                                      slot: "reference"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-edit"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "add-group" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          placeholder: "请输入分组名称",
                                          maxlength: "20",
                                          "show-word-limit": ""
                                        },
                                        model: {
                                          value: _vm.groupName,
                                          callback: function($$v) {
                                            _vm.groupName = $$v
                                          },
                                          expression: "groupName"
                                        }
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { round: "", size: "small" },
                                          on: { click: _vm.handleCancel }
                                        },
                                        [_vm._v("取消")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            round: "",
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: { click: _vm.handleSave }
                                        },
                                        [_vm._v("确定")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              item.count === 0
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleDelete(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-delete"
                                      })
                                    ]
                                  )
                                : _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "请移除或删除分组下的群，再删除分组",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "q-info",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-delete"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.id === -1
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-end",
                                trigger: "click",
                                "popper-class": "add-group-popover"
                              },
                              on: {
                                show: function($event) {
                                  _vm.groupName = ""
                                  _vm.groupId = ""
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-plus-fill",
                                attrs: { slot: "reference" },
                                slot: "reference"
                              }),
                              _c(
                                "div",
                                { staticClass: "add-group" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      placeholder: "请输入分组名称",
                                      maxlength: "20",
                                      "show-word-limit": ""
                                    },
                                    model: {
                                      value: _vm.groupName,
                                      callback: function($$v) {
                                        _vm.groupName = $$v
                                      },
                                      expression: "groupName"
                                    }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { round: "", size: "small" },
                                      on: { click: _vm.handleCancel }
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        round: "",
                                        size: "small",
                                        type: "primary"
                                      },
                                      on: { click: _vm.handleSave }
                                    },
                                    [_vm._v("确定")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c(
              "div",
              { staticClass: "right-area" },
              [
                _vm.ready
                  ? _c(
                      "el-table",
                      {
                        ref: "table",
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.tableData, height: _vm.tableHeight },
                        on: { "selection-change": _vm.handleSelectionChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "liveCodeName",
                            label: "群名称",
                            "min-width": "150"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-start" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "ly-table-tearm" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-weixin"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "8px",
                                              flex: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.name
                                                  ? scope.row.name
                                                  : "群聊"
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2427849136
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "liveCodeName",
                            label: "群分组",
                            "min-width": "120"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("ly-drow", {
                                      attrs: {
                                        list: scope.row.topics,
                                        name: "name",
                                        dep: "",
                                        empty: "未分组"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4125600981
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "user_total",
                            label: "群成员数",
                            align: "center",
                            "min-width": "120"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "owner",
                            label: "群主",
                            align: "center",
                            "min-width": "120"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.owner
                                      ? _c("ly-tag", {
                                          attrs: {
                                            content: scope.row.owner.name
                                          }
                                        })
                                      : [_vm._v(" - ")]
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3182896068
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "liveCodeName",
                            label: "托管账号",
                            align: "center",
                            width: "220"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.work_robots &&
                                    scope.row.work_robots.length
                                      ? _c("ly-drow", {
                                          attrs: {
                                            list: scope.row.work_robots,
                                            avatarField: "avatar",
                                            name: "name"
                                          }
                                        })
                                      : [_vm._v(" - ")]
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            235226307
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "right",
                            width: "120"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$router.push({
                                              path: "detail",
                                              query: {
                                                id: scope.row.id,
                                                name: scope.row.name
                                                  ? scope.row.name
                                                  : "群聊"
                                              }
                                            })
                                          }
                                        }
                                      },
                                      [_vm._v("群详情")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1002202301
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "ly-table-empty",
                            attrs: { slot: "empty" },
                            slot: "empty"
                          },
                          [
                            _vm._t("empty", [
                              _vm.flag
                                ? [
                                    _vm.isCustomerSeach
                                      ? [
                                          _c("img", {
                                            attrs: {
                                              src: require("@assets/svg/default/no_res.svg"),
                                              alt: ""
                                            }
                                          }),
                                          _vm._v(" 无搜索结果 ")
                                        ]
                                      : [
                                          _c("img", {
                                            attrs: {
                                              src: require("@assets/svg/default/no_data.svg"),
                                              alt: ""
                                            }
                                          }),
                                          _vm._v(" 暂无数据 ")
                                        ]
                                  ]
                                : _vm._e()
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "el-pagination",
              {
                attrs: {
                  background: "",
                  "current-page": _vm.page,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.per_page,
                  layout: "slot, sizes, prev, pager, next, jumper",
                  total: _vm.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "pagination-slot" },
                  [
                    _c("span", { staticClass: "total" }, [
                      _vm._v("共" + _vm._s(_vm.total) + "条记录")
                    ]),
                    _vm._t("pagination")
                  ],
                  2
                )
              ]
            )
          ],
          1
        ),
        _c("add-management", {
          ref: "addManage",
          on: { change: _vm.queryData }
        }),
        _c("grouping", { ref: "grouping", on: { change: _vm.handleGrouped } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }