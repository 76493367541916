  <template>
  <div class="mobile mobile-poster">
    <div class="mobile-container" style="height: 100%">
      <div class="mobile-poster-bg" v-if="mobiPoster.img_url">
        <img :src="mobiPoster.img_url" alt="" />
      </div>
      <div class="mobile-poster-edit" style="overflow: hidden">
        <!-- 头像 -->
        <vue-draggable-resizable
          class="dragWrap dragAvatar"
          :x="Number(mobiPoster.avatar_x)"
          :y="Number(mobiPoster.avatar_y)"
          :w="Number(mobiPoster.avatar_width)"
          :h="Number(mobiPoster.avatar_height)"
          :parent="true"
          :handles="['br']"
          :lock-aspect-ratio="true"
          @dragging="(x, y) => onDrag(x, y, 'avatar')"
          @resizing="(x, y, w, h) => onResize(w, h, 'avatar')"
          v-if="mobiPoster.avatar_status === 1"
        >
          <div
            class="poster-avatar"
            :class="{ 'is-radius': mobiPoster.avatar_sharp == 2 }"
          >
            头像
          </div>
        </vue-draggable-resizable>

        <!-- 昵称 -->
        <vue-draggable-resizable
          class="dragWrap"
          :x="Number(mobiPoster.nickname_x)"
          :y="Number(mobiPoster.nickname_y)"
          :w="mobiPoster.nickname_size * name_length"
          :grid="[1, 1]"
          h="auto"
          :parent="true"
          :handles="[]"
          :lock-aspect-ratio="true"
          @dragging="(x, y) => onDrag(x, y, 'nickname')"
          v-if="mobiPoster.nickname_status === 1"
        >
          <div
            :style="{
              color: mobiPoster.nickname_color,
              'font-size': mobiPoster.nickname_size + 'px',
              'text-align': mobiPoster.nickname_align,
            }"
          >
            用户昵称
          </div>
        </vue-draggable-resizable>

        <!-- 二维码 -->
        <vue-draggable-resizable
          class="dragWrap dragQrcode"
          :x="Number(mobiPoster.qrcode_x)"
          :y="Number(mobiPoster.qrcode_y)"
          :w="Number(mobiPoster.qrcode_width)"
          :h="Number(mobiPoster.qrcode_height)"
          :parent="true"
          :handles="['br']"
          :lock-aspect-ratio="true"
          @dragging="(x, y) => onDrag(x, y, 'qrcode')"
          @resizing="(x, y, w, h) => onResize(w, h, 'qrcode')"
        >
          <img src="@assets/images/contact_me_qr.png" />
        </vue-draggable-resizable>
      </div>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {

  computed: {
    mobiPoster () {
      return this.$store.state.rede.poster
    }
  },

  watch: {
    mobiPoster: {
      handler (val) {
        // 控制宽度，防止撑出屏幕
        const _w = val.nickname_x + val.nickname_size * this.name_length
        if (_w > 345) {
          val.nickname_x = 345 - val.nickname_size * this.name_length
        }
      },
      deep: true,
      immediate: true
    }
  },

  data () {
    return {
      name_length: 6
    }
  },

  methods: {
    // 拖动定位
    onDrag (x, y, name) {
      this.fn_setPoster([`${name}_x`], x)
      this.fn_setPoster([`${name}_y`], y)
      if (name === 'nickname') {
        if (this.mobiPoster.nickname_align === 'center') {
          this.fn_setPoster([`${name}_offsetX`], (x + this.mobiPoster.nickname_size * this.name_length / 2).toFixed() - 2)
        } else if (this.mobiPoster.nickname_align === 'right') {
          this.fn_setPoster([`${name}_offsetX`], (x + this.mobiPoster.nickname_size * this.name_length).toFixed() - 2)
        } else {
          this.fn_setPoster([`${name}_offsetX`], 0)
        }
      }
    },

    // 尺寸
    onResize (w, h, name) {
      this.fn_setPoster(`${name}_width`, w)
      this.fn_setPoster(`${name}_height`, h)
    },

    // 设置海报缓存
    fn_setPoster (type, data) {
      const POSTER = this.$store.state.rede.poster
      POSTER[type] = data
      this.$store.commit('_SET', {
        'rede.poster': POSTER
      })
    }
  },

  components: {
    [VueDraggableResizable.name]: VueDraggableResizable
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";
.mobile-poster {
  width: 345px;
  height: 613.64px;
  .mobile-poster-bg {
    padding: 0;
  }
}
</style>
