<!--
 * @Author: zhanln
 * @Date: 2021-08-26 17:19:55
 * @LastEditTime: 2022-04-19 11:17:00
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <el-dialog
    title="添加附件"
    :visible.sync="visible"
    custom-class="add-content"
    :close-on-click-modal="false"
    @close="handleDialogClose"
    width="560px"
  >
    <div class="type-list">
      <span
        class="item"
        :class="{ active: type === 2002 }"
        @click="handleClick(2002)"
        >图片</span
      >
      <span
        class="item"
        :class="{ active: type === 2005 }"
        @click="handleClick(2005)"
        >链接</span
      >
      <span
        class="item"
        :class="{ active: type === 2013 }"
        @click="handleClick(2013)"
        >小程序</span
      >
    </div>
    <div class="content">
      <!-- 图片 -->
      <template v-if="type === 2002">
        <el-upload
          class="uploader"
          ref="pic"
          action="#"
          accept=".png, .jpg"
          :file-list="picImgList"
          :show-file-list="false"
          :http-request="(opts) => requestQiniu(opts, 'pic')"
          :limit="1"
        >
          <div v-if="pic.msg_content" class="thum">
            <img :src="pic.msg_content" class="img" />
            <i class="el-icon-closeplus" @click.stop="handleRemove('pic')"
              ><i class="el-icon-close"></i
            ></i>
          </div>
          <template v-else>
            <div class="upload-box flex">
              <i class="iconfont icon-plus avatar-uploader-icon"></i>
              <span>上传图片</span>
            </div>
            <div class="desc q-info text-small" @click.stop>
              支持JPG、PNG格式，图片小于2M
            </div>
          </template>
        </el-upload>
      </template>
      <!-- 链接 -->
      <template v-else-if="type === 2005">
        <el-form
          size="medium"
          ref="linkForm"
          class="link-form"
          :show-message="false"
          :rules="linkRules"
          :model="link"
          label-width="0px"
        >
          <el-form-item prop="href">
            <el-input
              v-model="link.href"
              placeholder="请输入链接"
              v-lydebounce="['input', getUrlInfo]"
            ></el-input>
          </el-form-item>
          <el-form-item prop="title">
            <el-input
              :rows="1"
              show-word-limit
              maxlength="40"
              v-model="link.title"
              placeholder="链接标题"
            ></el-input>
          </el-form-item>
          <el-form-item prop="desc" class="desc">
            <el-input
              type="textarea"
              show-word-limit
              maxlength="170"
              v-model="link.desc"
              placeholder="快来参加活动吧"
            ></el-input>
          </el-form-item>
          <el-form-item class="poster">
            <el-upload
              ref="link"
              action="#"
              accept=".png, .jpg"
              :on-success="handleSuccess"
              :http-request="(opts) => requestQiniu(opts, 'link')"
              :limit="1"
              :file-list="linkImgList"
              :show-file-list="false"
            >
              <div v-if="link.msg_content" class="thum">
                <img :src="link.msg_content" class="img link-img" />
                <i class="el-icon-closeplus" @click.stop="handleRemove('link')"
                  ><i class="el-icon-close"></i
                ></i>
              </div>
              <div v-else class="upload-box flex">
                <i class="iconfont icon-plus avatar-uploader-icon"></i>
                <span>上传封面</span>
              </div>
            </el-upload>
          </el-form-item>
          <span class="desc q-info text-small"
            >封面图片支持JPG、PNG格式，小于2M，建议尺寸500*500px</span
          >
        </el-form>
      </template>
      <!-- 小程序 -->
      <template v-else-if="type === 2013">
        <div class="q-danger" style="margin: 16px">
          注：请填写企业微信后台绑定的小程序id和路径
        </div>
        <el-form
          size="medium"
          ref="appletForm"
          class="applet-form"
          :show-message="false"
          :rules="appletRules"
          :model="applet"
          label-width="120px"
        >
          <el-form-item label="小程序标题" prop="title">
            <el-input
              v-model="applet.title"
              placeholder="请输入小程序标题"
              show-word-limit
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序appid" prop="id">
            <el-input
              v-model="applet.id"
              placeholder="请输入小程序appid"
              maxlength="32"
              show-word-limit
            ></el-input>
            <div class="q-primary text-small" style="text-align: right">
              <span style="cursor: pointer" @click="fn_jumpToLink(1)">
                如何获取小程序appid？</span
              >
            </div>
          </el-form-item>
          <el-form-item label="小程序路径" prop="path">
            <el-input
              v-model="applet.path"
              placeholder="请输入小程序路径"
              maxlength="250"
              show-word-limit
            ></el-input>
            <div class="text-small" style="text-align: right">
              <span
                class="q-primary"
                style="cursor: pointer"
                @click="fn_jumpToLink(2)"
                >如何获取小程序路径？</span
              >
            </div>
          </el-form-item>
          <el-form-item label="小程序封面" prop="title">
            <el-upload
              class="uploader"
              ref="applet"
              action="#"
              accept=".png, .jpg"
              :http-request="(opts) => requestQiniu(opts, 'applet')"
              :limit="1"
              :file-list="appletImgList"
              :show-file-list="false"
            >
              <div v-if="applet.msg_content" class="thum">
                <img :src="applet.msg_content" class="img" />
                <i
                  class="el-icon-closeplus"
                  @click.stop="handleRemove('applet')"
                  ><i class="el-icon-close"></i
                ></i>
              </div>
              <template v-else>
                <div class="upload-box flex">
                  <i class="iconfont icon-plus avatar-uploader-icon"></i>
                  <span>上传图片</span>
                </div>
              </template>
            </el-upload>
            <div class="desc q-info text-small" @click.stop>
              支持JPG、PNG格式，图片小于2M，尺寸建议520*416px
            </div>
          </el-form-item>
        </el-form>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" round @click="handlePushMsg">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Base64 } from 'js-base64'
import qiniuUpload from '@/common/mixins/qiniuUpload'
import { common } from '../http'
import isImg from '@/common/isImg'
export default {
  mixins: [qiniuUpload],
  data () {
    const checkUrl = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('链接不能为空'))
      }
      if (!this.util.isUrl(value)) {
        callback(new Error('链接格式错误'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      edit: false,
      // 2001文字（支持传入系统表情，暂不支持emoji表情）；
      // 2002图片（支持jpg/gif格式）；2003语音（支持amr/silk格式）；
      // 2004视频；2005图文链接；2006好友名片；2010 文件；2013 小程序
      type: 2002, // 内容类型
      text: { // 文本类型文本
        msg_content: ''
      },
      pic: { // 图片类型
        msg_content: ''
      },
      video: {
        href: '',
        voice_time: 0,
        msg_content: '' // 视频封面图地址：视频地址+?vframe/jpg/offset/3
      },
      file: {
        title: '',
        msg_content: '',
        href: ''
      },
      link: { // 链接类型表单
        title: '',
        desc: '',
        msg_content: '',
        href: ''
      },
      applet: {
        title: '',
        id: '',
        path: '',
        msg_content: ''
      },
      appletRules: {
        title: [{ required: true }],
        path: [{ required: true }],
        id: [{ required: true }]
      },
      linkRules: {
        title: [{ required: true }, { max: 50 }],
        desc: [{ max: 170 }],
        href: [{ validator: checkUrl }]
      },
      typeDic: {
        2001: 'text',
        2002: 'pic',
        2004: 'video',
        2005: 'link',
        2010: 'file',
        2013: 'applet'
      },
      appletInfo: {}, // 所选小程序信息
      picImgList: [],
      linkImgList: [],
      appletImgList: []
    }
  },
  methods: {

    handleDialogClose () {
      this.pic = { // 图片类型
        msg_content: ''
      }
      this.video = {
        href: '',
        voice_time: 0,
        msg_content: '' // 视频封面图地址：视频地址+?vframe/jpg/offset/3
      }
      this.file = {
        title: '',
        msg_content: '',
        href: ''
      }
      this.link = { // 链接类型表单
        title: '',
        desc: '',
        msg_content: '',
        href: ''
      }
      this.applet = {
        title: '',
        id: '',
        path: '',
        msg_content: ''
      }
      this.edit = false
      this.picImgList = []
      this.linkImgList = []
      this.appletImgList = []
      this.$refs.linkForm && this.$refs.linkForm.clearValidate()
      this.$refs.appletForm && this.$refs.appletForm.clearValidate()
    },
    /**
     * @description: 切换类型
     * @param {*} type
     * @return {*}
     */
    handleClick (type) {
      this.$refs.pic && this.$refs.pic.clearFiles()
      this.$refs.applet && this.$refs.applet.clearFiles()
      this.$refs.link && this.$refs.link.clearFiles()
      this.type = type
      this.$nextTick(() => {
        this.$refs.linkForm && this.$refs.linkForm.clearValidate()
        this.$refs.appletForm && this.$refs.appletForm.clearValidate()
      })
    },
    // 上传
    async requestQiniu (opts, type) {
      const _varify = await isImg(opts, ['png', 'jpg'])
      let rule = {}
      let key = 'msg_content'
      switch (type) {
        case 'pic':
          rule = {
            types: ['jpg', 'png', 'jpeg'],
            size: '2MB'
          }
          break
        case 'video':
          key = 'href'
          rule = {
            types: ['mp4'],
            size: '10MB'
          }
          break
        case 'file':
          key = 'href'
          rule = {
            size: '10MB'
          }
          break
        case 'link':
          rule = {
            size: '2MB'
          }
          break
        case 'applet':
          rule = {
            size: '2MB'
          }
          break
      }
      this[type + 'ImgList'] = []
      // 判断图片类型是否正确
      if (_varify) {
        await this.qiniuUpload2(
          opts.file,
          (path, id) => {
            this[type][key] = path
            this[type + 'ImgList'] = [
              {
                name: 'pic-' + type,
                uid: id,
                url: path
              }
            ]
          },
          null,
          rule
        )
      }
    },
    // 获取视频时长
    getDuration () {
      this.video.voice_time = this.$refs.videoDom.duration.toFixed(0) * 1 || 0
      this.video.msg_content = this.video.href + '?vframe/jpg/offset/3'
    },
    handleSuccess () {
      this.$refs.link.clearFiles()
    },
    // 移除
    handleRemove (key) {
      this.imgList = []
      this.$refs[key].clearFiles && this.$refs[key].clearFiles()
      // this[key].title && (this[key].title = '')
      // this[key].href && (this[key].href = '')
      this[key].msg_content && (this[key].msg_content = '')
      // this[key].desc && (this[key].desc = '')
      // this[key].voice_time && (this[key].voice_time = 0)
      // key === 'applet' && (this.appletInfo = {})
    },
    getUrlInfo () {
      if (this.util.https(this.link.href)) {
        common.urlInfo({ url: this.link.href }).then(res => {
          this.link.title = res.title
          this.link.desc = res.description
          this.link.msg_content = res.img_url
        })
      }
    },
    /**
     * @description: 获取小程序
     * @param {*} app
     * @return {*}
     */
    handleChoseApp (app) {
      this.appletInfo = app
      this.applet.content = app
      this.applet.title = app.title
      this.applet.msg_content = Base64.encode(JSON.stringify(app))
    },
    /**
     * @description: 添加消息
     * @param {*}
     * @return {*}
     */
    handlePushMsg () {
      // if (!this[this.typeDic[this.type]].msg_content) {
      //   this.$message.error('请填写附件内容')
      //   return false
      // }
      if (this.type === 2002) {
        if (!this[this.typeDic[this.type]].msg_content) {
          this.fn_lyMsg('info', '请填写附件内容')
          return false
        }
      }
      if (this.type === 2005) {
        let res = false
        this.$refs.linkForm.validate(valid => {
          res = valid
        })
        if (!res) {
          this.fn_lyMsg('info', '请填写链接相关信息')
          return false
        }
      }
      if (this.type === 2013) {
        let res = false
        this.$refs.appletForm.validate(valid => {
          res = valid
        })

        if (!this[this.typeDic[this.type]].msg_content) {
          res = false
        }

        if (!res) {
          this.fn_lyMsg('info', '请填写小程序相关信息')
          return false
        }
      }
      this.visible = false
      if (this.edit) {
        this.$emit('push', {
          temp: this.edit,
          msg_type: this.type,
          ...this[this.typeDic[this.type]]
        })
      } else {
        this.$emit('push', {
          msg_type: this.type,
          ...this[this.typeDic[this.type]]
        })
      }
      Object.assign(this.$data, this.$options.data.call(this))
      this.$refs.pic && this.$refs.pic.clearFiles()
      this.picImgList = []
      this.linkImgList = []
      this.appletImgList = []
    },
    handleEdit (msg) {
      this.visible = true
      this.edit = msg.temp
      this.type = msg.msg_type
      const obj = this[this.typeDic[msg.msg_type]]
      for (const key in obj) {
        obj[key] = msg[key]
      }
    },

    // 指引文档
    fn_jumpToLink (type) {
      if (type === 1) {
        window.open('https://www.yuque.com/docs/share/dbd084fc-33e9-45a7-ac66-6710855e2745?#', '_blank')
      } else {
        window.open('https://www.yuque.com/docs/share/32544cd6-6726-4101-8c57-bd08fe9f71c4?#', '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-content {
  .type-list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
    .item {
      color: rgba($color: #000000, $alpha: 0.65);
      font-size: 16px;
      cursor: pointer;
      &.active {
        color: #2b7cff;
      }
    }
  }
  .text-input {
    ::v-deep {
      .el-textarea__inner {
        resize: none;
        border-color: #eaebf2;
        height: 164px;
      }
    }
  }
  .content {
    border-radius: 8px;
    border: 1px solid #eaebf2;
    .upload-box {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 22px 0;
      box-sizing: border-box;
      i {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .uploader {
      min-height: 164px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px 0 10px;
      box-sizing: border-box;

      .upload-box {
        height: 104px;
        width: 104px;
        border: 1px dashed #eaebf2;
        border-radius: 8px;
      }
    }
    .thum {
      position: relative;
      height: 104px;
      width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-icon-closeplus {
        background: #454d5b;
        width: 14px;
        height: 14px;
        color: #fff;
        border-radius: 50%;
        position: absolute;
        right: -5px;
        top: -5px;
        line-height: 13px;
      }
      .el-icon-close {
        font-size: 12px;
      }
    }
    .img {
      max-height: 104px;
      max-width: 104px;
      display: block;
    }
    .link-img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
    .applet-form {
      margin: 8px 12px;
      position: relative;
      .el-form-item {
        margin-bottom: 8px;
      }
      .uploader {
        align-items: flex-start;
        min-height: auto;
        padding: 0;
      }
      ::v-deep .el-upload {
        width: auto;
        margin: 0;
      }
    }
    .link-form {
      margin: 8px 12px;
      position: relative;
      .el-form-item {
        margin-bottom: 8px;
        &:nth-of-type(2),
        &:nth-of-type(3) {
          width: 350px;
        }
      }
      .poster {
        border: 1px solid #eaebf2;
        position: absolute;
        right: 0;
        top: 46px;
        border-radius: 8px;
        ::v-deep {
          .el-upload {
            width: 122px;
            height: 122px;
          }
        }
      }
      ::v-deep {
        .desc {
          .el-textarea__inner {
            height: 80px;
          }
        }
        .el-textarea__inner {
          resize: none;
        }
      }
    }
    .applet-temp {
      cursor: pointer;
      width: 104px;
      height: 104px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 22px 0;
      box-sizing: border-box;
      margin: 29px auto;
      i {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .applet {
      margin: 20px auto;
      & ~ .el-icon-closeplus {
        right: 120px;
      }
    }
    .warp-desc {
      padding: 0 8px;
      text-align: center;
      line-height: 1.5;
      margin-top: 6px;
    }
    .file-temp {
      width: 190px;
      height: 56px;
      background: #ffffff;
      border: 1px solid #dfe1e8;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      box-sizing: border-box;
      .file-title {
        flex: 1;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: rgba(0, 0, 0, 0.65);
      }
      .file-icon {
        width: 30px;
        height: 30px;
        margin-left: 20px;
      }
    }
  }
}
::v-deep {
  .el-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    height: 100%;
    color: rgba(0, 0, 0, 0.65);
    &:focus {
      border-color: #eaebf2;
      color: rgba(0, 0, 0, 0.65);
    }

    .desc {
      line-height: 36px;
    }
  }
}
</style>
