<!--
 * @Descripttion: 红包充值
 * @version: 1.0.0
-->
<template>
  <el-dialog
    title="账户充值"
    :visible.sync="rechargeVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleOpen"
    @close="handleClose"
    width="560px"
  >
    <div class="re-suss" v-if="reSucc">
      <img class="succ-icon" src="@assets/svg/paySucc.svg" alt="" />
      <div class="p1">充值成功</div>
      <div class="p2">
        你已成功充值<span style="color: #faad14">{{ `${succAmount}元` }}</span>
      </div>
      <div class="p3">账户可用余额：{{ balance }}</div>
    </div>

    <div class="re-fail" v-else-if="reSucc === false">
      <i class="el-icon-error"></i>
      <div class="p1">充值失败</div>
      <el-button type="primary" round size="medium" @click="reSucc = null"
        >重新充值</el-button
      >
    </div>

    <div class="recharge" v-else>
      <div class="list">
        <div
          class="list-item"
          @click="handleSelect(item)"
          :class="{ active: item == form.amount }"
          v-for="item in list"
          :key="item"
        >
          <span class="currency">￥</span>
          <span class="amount">{{ item }}</span>
        </div>
      </div>
      <div class="input">
        <el-form
          :inline="true"
          ref="form"
          :model="form"
          :rules="rules"
          @submit.native.prevent
        >
          <el-form-item label="充值金额：" prop="amount">
            <el-input
              size="medium"
              type="number"
              v-model="form.amount"
              @input="handleInput($event)"
              placeholder="请输入充值金额"
            ></el-input>
            <span class="unit">元</span>
          </el-form-item>
        </el-form>
      </div>
      <!-- <p class="desc">充值后金额可以提现，三个工作日内到账。</p> -->
      <ly-wechat-qr
        :loading="loading"
        :qr="qrCode"
        :total="qrAmount"
        :error="error"
        @refresh="getQr"
      />
      <div class="re-tips">提示：平台仅提供技术服务，暂不支持开发票</div>
    </div>
  </el-dialog>
</template>
<script>
import { debounce } from 'throttle-debounce'
import qs from 'qs'
export default {
  data () {
    const checkAmount = (rule, value, callback) => {
      if (value < 0.01) {
        callback(new Error('不能小于0.01元'))
      } else if (value > 10000000) {
        callback(new Error('不能大于10000000元'))
      } else {
        callback()
      }
    }
    return {
      rechargeVisible: false,
      list: [100, 500, 1000],
      form: {
        amount: 100 // 充值金额
      },
      rules: {
        amount: [
          { required: true, message: '请输入金额' },
          { validator: checkAmount }
        ]
      },
      qrAmount: 0, // 二维码金额
      succAmount: '', // 成功充值金额
      balance: '', // 充值后余额
      loading: false,
      qrCode: '',
      error: false, // 请求二位是否错误
      debounceGetQr: null,
      qrTime: null,
      timer: null,
      reSucc: null // null：初始状态  true：充值成功   false：充值失败
    }
  },
  watch: {
    'form.amount' (n) {
      this.debounceGetQr()
    }
  },
  beforeDestroy () {
    this.handleClose()
  },
  methods: {
    handleOpen () {
      this.form.amount = 100
      this.reSucc = null
      this.debounceGetQr = debounce(800, this.getQr)
      this.getQr()
    },
    async getQr () {
      this.$nextTick(() => {
        this.$refs.form.validate(valid => {
          valid && qrCodeFn()
        })
      })

      const qrCodeFn = async () => {
        // 清除上次的定时器
        clearTimeout(this.timer)
        this.loading = true
        this.error = false
        const res = await this.axios.post('/wecom/redpacket-activity/recharge', { amount: this.form.amount * 1 })
        if ((res && res.code) || (res && res.code === 0)) {
          this.error = true
          this.$message.error(res.msg)
          return
        }
        this.qrCode = await this.util.qrCode(res.url)
        this.queryResult(res.order_num)
        this.qrAmount = res.amount
        this.loading = false
      }
    },
    /**
     * @Descripttion: 查询充值结果
     * @param {out_trade_no:订单号（必填）}
     * @return {*}
     */
    // eslint-disable-next-line
    queryResult (out_trade_no) {
      if (!this.rechargeVisible) {
        return false
      }
      // if (this.qrTime && (Date.now() - this.qrTime > 15 * 1000)) {
      //   // 获得充值结果超时
      //   // this.$message.info('获取充值结果超时，请稍后查询充值记录')
      //   return false
      // }
      const params = {
        out_trade_no
      }
      this.timer = setTimeout(() => {
        // 记录第一次轮询的时间
        !this.qrTime && (this.qrTime = Date.now())
        // eslint-disable-next-line
        this.axios.post('paymentOrder', qs.stringify(params), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(({ trade_state, total, trade_state_desc }) => {
          // eslint-disable-next-line
          if (trade_state === 1) {
            this.reSucc = true
            // eslint-disable-next-line
            this.succAmount = total
            this.getRdBalance()
            this.$emit('success')
          } else if (trade_state === 2 || trade_state === 3 || trade_state === 4) {
            this.$message.error(trade_state_desc)
            this.reSucc = false
          } else {
            this.queryResult(out_trade_no)
          }
        })
      }, 3000)
    },
    // 获取红包余额
    async getRdBalance () {
      const res = await this.axios.get('/wecom/redpacket-activity/current-info')
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.balance = (res.available_amount - res.freeze_amount).toFixed(2)
    },
    handleInput (e) {
      this.form.amount = e && e.toString().match(/^\d+(?:\.\d{0,2})?/)[0]
    },
    handleBlur () {
      if (this.form.amount < 0.01) {
        this.form.amount = 0.01
      }
      this.debounceGetQr()
    },
    handleEnter (e) {
      e.target.blur()
      this.handleBlur()
    },
    handleSelect (item) {
      clearTimeout(this.timer)
      this.form.amount = item
    },
    handleClose () {
      clearTimeout(this.timer)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    margin-top: 25vh !important;
  }
  .el-form-item__label {
    margin-right: 0;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
.recharge,
.re-suss,
.re-fail {
  min-height: 360px;
}
.recharge {
  color: #212b36;
  .list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 24px;
  }
  .list-item {
    width: 160px;
    height: 90px;
    border: 2px solid #eaebf2;
    border-radius: 9px;
    text-align: center;
    line-height: 90px;
    color: #faad14;
    cursor: pointer;
    &.active {
      background: #fffcf5;
      border-color: #ecab0e;
    }
  }
  .currency {
    font-size: 20px;
    vertical-align: top;
  }
  .amount {
    font-size: 30px;
  }
  .input {
    display: flex;
    align-items: center;
    position: relative;
    .el-input {
      width: 266px;
    }
    .unit {
      background: #f5f5f5;
      border-left: 1px solid #eaebf2;
      border-radius: 0 4px 4px 0;
      height: 34px;
      width: 34px;
      line-height: 34px;
      text-align: center;
      position: absolute;
      right: 1px;
      top: 1px;
    }
  }
  .el-input {
    flex: 1;
  }
  .desc {
    color: #6e788a;
    font-size: 12px;
  }
  .wx-qr {
    margin-top: 24px;
  }
}
.re-tips {
  color: #454d5b;
  margin-top: 16px;
  font-size: 12px;
  text-align: center;
}
.el-icon-error {
  font-size: 65px;
  color: red;
  margin-top: 60px;
}
.re-suss,
.re-fail {
  display: flex;
  flex-direction: column;
  align-items: center;
  .succ-icon {
    width: 68px;
    height: 68px;
    margin-top: 60px;
  }
  .p1 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-weight: bold;
  }
  .p3 {
    margin-bottom: 30px;
  }
}
.re-suss {
  .p1 {
    margin: 10px 0 18px 0;
  }
}
.re-fail {
  .p1 {
    margin: 10px 0 32px 0;
  }
}
</style>
