<!-- desc: 重发奖品 -->
<template>
  <el-dialog
    title="重新发送兑换码"
    :visible.sync="visible"
    append-to-body
    width="500px"
    @open="queryData"
  >
    <div v-show="tableData && tableData.length">
      <div class="search" :image-size="100">
        <label>兑换码:</label>
        <el-input
          size="medium"
          placeholder="请输入兑换码"
          clearable
          :maxlength="32"
          v-model="title"
        ></el-input>
        <el-button type="primary" size="medium" @click="handleQuery"
          >查询</el-button
        >
      </div>
      <ly-table
        ref="lyTable"
        :data="tableData"
        :option="tableOpt"
        :page="page"
        :pageSize="per_page"
        :total="total"
        @searchChange="searchChange"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
      >
      </ly-table>
    </div>
    <el-empty
      v-show="!(tableData && tableData.length)"
      :image-size="100"
      description="暂无可用兑换码"
    >
      <el-button
        type="primary"
        size="small"
        round
        @click="$router.push('/material/code/list')"
        >追加兑换码</el-button
      >
      <el-button type="text" size="medium" @click="queryData"
        >已经追加？刷新</el-button
      >
    </el-empty>
  </el-dialog>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { exchange } from '../http.js'
export default {
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: exchange,
      IMMEDIATE: false,
      visible: false,
      per_page: 5,
      id: '', // 奖品记录ID
      code_id: '', // 上级页面reward_type_id（兑换码组id）
      title: ''
    }
  },
  inject: {
    activity: {
      default () { }
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        showIndex: true,
        levitate: false,
        layout: 'total, prev, pager, next',
        operation: [
          {
            label: '标记已发送',
            handler ({ id }) {
              _this.$lyConfirm({
                text: '此操作会重新发放奖品，是否继续？',
                title: '确认标记已发送吗？'
              }).then(() => {
                _this.COMM_HTTP.resendReward({
                  id: _this.id,
                  reward_value_id: id
                }).then(res => {
                  if ((res && res.code) || (res && res.code === 0)) {
                    _this.$message.error(res.msg)
                    return
                  }
                  _this.$message.success('发送成功！')
                  _this.visible = false
                  _this.$parent.queryData()
                })
              }).catch(() => { })
            }
          }
        ],
        column: [
          {
            label: '兑换码',
            prop: 'title'
          }
        ]
      }
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        send_status: 1,
        code_id: +this.code_id,
        title: this.title
      }
    },
    handleQuery () {
      this.page = 1
      this.queryData()
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .el-input {
    flex: 1;
    margin: 0 15px 0 5px;
  }
}
.el-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
