var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "字段名称" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10" },
                    model: {
                      value: _vm.data.name,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "name", $$v)
                      },
                      expression: "data.name"
                    }
                  })
                ],
                1
              ),
              !_vm.data.options.isShowPlaceholder &&
              _vm.data.options.placeholder
                ? _c(
                    "el-form-item",
                    { attrs: { label: "默认提示语" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20" },
                        model: {
                          value: _vm.data.options.placeholder,
                          callback: function($$v) {
                            _vm.$set(_vm.data.options, "placeholder", $$v)
                          },
                          expression: "data.options.placeholder"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.options.descplaceholder
                ? _c(
                    "el-form-item",
                    { attrs: { label: "默认提示语" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20" },
                        model: {
                          value: _vm.data.options.descplaceholder,
                          callback: function($$v) {
                            _vm.$set(_vm.data.options, "descplaceholder", $$v)
                          },
                          expression: "data.options.descplaceholder"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.options.options
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选项" } },
                    [
                      [
                        _vm.data.type == "radio" || _vm.data.type == "select"
                          ? [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.data.options.defaultValue,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.data.options,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "data.options.defaultValue"
                                  }
                                },
                                _vm._l(_vm.data.options.options, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "mb-8" },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: { label: item.value }
                                        },
                                        [
                                          _c("el-input", {
                                            style: {
                                              width: "180px"
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "i",
                                        {
                                          staticClass: "drag-item",
                                          staticStyle: {
                                            "font-size": "16px",
                                            margin: "0 5px"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-icon_bars"
                                          })
                                        ]
                                      ),
                                      _vm.data.options.isCanAddOption &&
                                      index !== 0
                                        ? _c("el-button", {
                                            staticStyle: {
                                              padding: "4px",
                                              "margin-left": "5px"
                                            },
                                            attrs: {
                                              circle: "",
                                              plain: "",
                                              type: "danger",
                                              size: "mini",
                                              icon: "el-icon-minus"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleOptionsRemove(
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          : _vm._e(),
                        _vm.data.type == "checkbox"
                          ? [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.data.options.defaultValue,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.data.options,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "data.options.defaultValue"
                                  }
                                },
                                _vm._l(_vm.data.options.options, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    { key: index },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: { label: item.value }
                                        },
                                        [
                                          _c("el-input", {
                                            style: {
                                              width: "180px"
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "i",
                                        {
                                          staticClass: "drag-item",
                                          staticStyle: {
                                            "font-size": "16px",
                                            margin: "0 5px"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-icon_bars"
                                          })
                                        ]
                                      ),
                                      _vm.data.options.isCanAddOption &&
                                      index !== 0
                                        ? _c("el-button", {
                                            staticStyle: {
                                              padding: "4px",
                                              "margin-left": "5px"
                                            },
                                            attrs: {
                                              circle: "",
                                              plain: "",
                                              type: "danger",
                                              size: "mini",
                                              icon: "el-icon-minus"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleOptionsRemove(
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          : _vm._e(),
                        _vm.data.options.isCanAddOption
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "22px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.handleAddOption }
                                  },
                                  [_vm._v("添加选项")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ],
                    2
                  )
                : _vm._e(),
              (_vm.data.type == "input" || _vm.data.type == "textarea") &&
              _vm.data.options.isShowMaxLength
                ? _c(
                    "el-form-item",
                    { attrs: { label: "默认字符限制" } },
                    [
                      _vm.data.type == "input"
                        ? _c("el-input", {
                            attrs: { maxlength: "100" },
                            on: {
                              blur: function($event) {
                                return _vm.maxLengthBlur(_vm.data.options)
                              }
                            },
                            model: {
                              value: _vm.data.options.maxLength,
                              callback: function($$v) {
                                _vm.$set(_vm.data.options, "maxLength", $$v)
                              },
                              expression: "data.options.maxLength"
                            }
                          })
                        : _c("el-input", {
                            attrs: { maxlength: "200" },
                            on: {
                              blur: function($event) {
                                return _vm.maxLengthBlur(_vm.data.options)
                              }
                            },
                            model: {
                              value: _vm.data.options.maxLength,
                              callback: function($$v) {
                                _vm.$set(_vm.data.options, "maxLength", $$v)
                              },
                              expression: "data.options.maxLength"
                            }
                          })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "必填项" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.data.options.required,
                        callback: function($$v) {
                          _vm.$set(_vm.data.options, "required", $$v)
                        },
                        expression: "data.options.required"
                      }
                    },
                    [_vm._v("是否必填")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }