var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rd-users" },
    [
      _c(
        "div",
        { staticClass: "select-box", staticStyle: { padding: "0 20px" } },
        [
          _c("label", { staticStyle: { color: "#212b36" } }, [
            _vm._v("选择活动：")
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "320px" },
              attrs: { filterable: "" },
              on: { change: _vm.changeActivity },
              model: {
                value: _vm.activity_id,
                callback: function($$v) {
                  _vm.activity_id = $$v
                },
                expression: "activity_id"
              }
            },
            _vm._l(_vm.activityList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.activeName === "users"
        ? _c("user-pane", {
            ref: "userPane",
            attrs: {
              activity_id: _vm.activity_id,
              activities: _vm.activityList,
              type: "1",
              channelOps: _vm.channelOps
            },
            on: { detail: _vm.fn_openDetail }
          })
        : _vm._e(),
      _c("user-detail", {
        ref: "userDetailRef",
        attrs: { userInfo: _vm.detailInfo }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }