<!--
 * @Author: zhanln
 * @Date: 2021-06-25 15:17:30
 * @LastEditTime: 2022-01-13 21:16:05
 * @LastEditors: zhanln
 * @Description: 用户详情
-->

<template>
  <el-dialog
    title="表单详情"
    :visible.sync="visible"
    custom-class="q-dialog"
    width="560px"
    @closed="closeDialog"
  >
    <div class="user-info flex">
      <el-avatar
        :size="54"
        :src="
          userInfo.avatarUrl ||
          'http://wx.qlogo.cn/mmhead/XCopLcwfzedShSmWhma2kPnUa4rs3yJtabpichHEtP90lwhuTVqovLA/0'
        "
      >
      </el-avatar>
      <div class="info">
        <span class="name">{{ userInfo.userName || "-" }}</span>
      </div>
    </div>
    <el-tabs v-model="activeName" type="card" class="ly-tabs">
      <el-tab-pane label="基本信息" name="invite" class="invite-wrapper">
        <el-scrollbar style="height: 383px">
          <div class="list-panel">
            <span class="names">提交时间：</span>
            <span class="values">{{ userInfo.createTime || "-" }}</span>
          </div>

          <div class="list-panel">
            <span class="names">填写来源：</span>
            <span class="values">
              <template v-if="userInfo.source === 0">裂变活动-任务宝 </template>
              <template v-if="userInfo.source === 1">裂变活动-群裂变 </template>
              <template v-if="userInfo.source === 2">推广链接 </template>
              <template v-if="userInfo.source === 3">推广二维码 </template>
              <template v-if="userInfo.source === 4">裂变活动-抽奖 </template>
            </span>
          </div>

          <div class="list-panel" v-if="userInfo.source < 2">
            <span class="names">活动名称：</span>
            <span class="values">{{ userInfo.activityName || "-" }}</span>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="表单信息" name="records">
        <el-scrollbar style="height: 323px">
          <formPreviewEdit :isEdit="isEdit" ref="formPreviewEdit" />
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" v-if="activeName == 'records'">
      <div class="flex_item"></div>
      <el-button round type="primary" @click.stop="updateEdit()" v-if="!isEdit">
        修改
      </el-button>
      <el-button
        round
        type="primary"
        @click.stop="updateHandle()"
        v-if="isEdit"
      >
        确认修改
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import formPreviewEdit from './formPreviewEdit.vue'
import { fDetails, tables } from '../http'
export default {
  name: 'detailCom',
  props: {
    userInfo: {
      type: Object,
      default: () => (
        {
          avatarUrl: '',
          title: '',
          sex: 0
        }
      )
    }
  },
  components: {
    formPreviewEdit
  },
  computed: {
  },
  data () {
    return {
      visible: false,
      activeName: 'invite',
      isEdit: false
    }
  },
  methods: {
    show () {
      this.isEdit = false
      this.visible = true
      this.activeName = 'invite'
      this.getSourceTable()
    },
    closeDialog () {
      this.$refs.formPreviewEdit._clearTable()
    },
    async getSourceTable () {
      const data = await fDetails.getOneDetail({
        formId: this.$route.query.formId,
        requestId: this.userInfo.requestId
      })

      this.$refs.formPreviewEdit._initFromTable(data)
    },
    updateEdit () {
      this.isEdit = !this.isEdit
    },
    updateHandle () {
      this.$lyConfirm({
        text: '确认修改表单信息吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const formTable = this.$refs.formPreviewEdit.getFormTableValue()

          if (!formTable.length) {
            return
          }

          let _list = formTable.map(item => {
            return {
              componentId: item.id,
              data: item.postValue
            }
          })

          _list = _list.filter(_ => !!_.data)
          // console.log(_list)

          fDetails.putDetail({
            requestId: this.userInfo.requestId,
            formId: this.$route.query.formId,
            list: _list
          }).then(() => {
            this.$message.success('提交成功')
            this.updateEdit()
          })
        })
        .catch(() => { })
    },
    cancel () {
      this.visible = false
      this.isEdit = false
    },
    submit () {
      this.visible = false
      this.isEdit = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: unset;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-left: 1px solid #e4e7ed;
  }
  .el-tabs__item {
    border: 1px solid #e4e7ed;
    height: 40px;
    width: 162px;
    text-align: center;
    line-height: 40px;
    margin-right: 4px;
    background: rgba(0, 0, 0, 0.02);
    &.is-active {
      background: #fff;
    }
  }
}
.user-info {
  height: 40px;
  margin-bottom: 24px;
  display: flex;
  .info {
    margin-left: 12px;
    padding-top: 5px;
    .name {
      display: block;
      line-height: 1;
      font-size: 18px;
    }
    .sex {
      font-size: 12px;
      color: #6e788a;
    }
  }
  .el-avatar {
    background: unset;
  }
}
.invite-wrapper {
  .list-panel {
    margin-bottom: 20px;
    line-height: 1;
  }
  .names {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6e788a;
  }
  .values {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #212b36;
  }
}
::v-deep {
  .el-tabs__header {
    margin-bottom: 24px;
  }
  // .el-tabs__item {
  //   background: rgba(0, 0, 0, 0.02);
  // }
  // .el-tabs__item.is-active {
  //   background: #fff;
  // }
  // .el-form-item {
  //   margin-bottom: 16px;
  // }
  .xl-ly-setting .handler-left,
  .xl-ly-setting .handler-right {
    margin-bottom: 16px;
  }
}
</style>
