<template>
  <div class="ly-table page-warp">
    <!-- searchBar -->
    <el-form
      :inline="true"
      size="medium"
      class="demo-form-inline"
      style="padding: 0 20px"
    >
      <el-form-item label="选择活动：" class="label-padding">
        <el-select
          v-model="activityId"
          filterable
          style="width: 320px"
          @change="changeActivity"
        >
          <el-option
            v-for="item in activityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- searchBar end -->

    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @sortChange="handleSortChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <!-- <template #handler>
        <el-button type="primary" round icon="iconfont icon-download" v-lydebounce="['button', fn_export]"
          :loading="exportLoading" :disabled="!tableData || tableData.length === 0">导出数据</el-button>
      </template> -->
      <!-- 列插槽 -->
      <template #reward_title_slot="{ row }">
        <div class="flex">
          <img
            v-if="row.reward_cover || '../../../../assets/svg/reward.svg'"
            :src="row.reward_cover"
            alt=""
            style="margin-right: 8px; max-height: 24px; max-width: 24px"
          />
          <span>{{ row.reward_title }}</span>
        </div>
      </template>

      <template #reward_type_slot="{ row }">
        <template v-if="row.reward_type === 1">自定义</template>
        <template v-else-if="row.reward_type === 2">兑换码</template>
      </template>

      <template #nickname_slot="{ row }">
        <lyTag :content="row.nickname" :avatar="row.avatar_url"></lyTag>
      </template>

      <template #send_status_slot="{ row }">
        <ly-status
          type="success"
          icon="iconfont icon-success"
          v-if="row.send_status === 1"
          >已发放</ly-status
        >
        <template v-else-if="row.send_status === 2">
          <ly-status type="danger" icon="iconfont icon-close-fill"
            >未发放</ly-status
          >
          <el-tooltip
            class="item"
            effect="light"
            placement="top-start"
            popper-class="q-tooltip"
          >
            <div slot="content">
              <div
                style="line-height: 40px; text-align: center; font-size: 14px"
              >
                {{
                  row.reward_type === 2
                    ? '兑换码不足'
                    : '库存不足，请前往活动修改页面调整库存'
                }}
              </div>
              <div class="flex flex-center">
                <el-button
                  type="primary"
                  size="mini"
                  round
                  @click="fn_openCodeList(row)"
                  v-if="row.reward_type === 2"
                >
                  重新发送</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  round
                  @click="updateRewardStatus(row)"
                  v-else
                  >重新发送</el-button
                >
              </div>
            </div>
            <i class="iconfont icon-warning-fill"></i>
          </el-tooltip>
        </template>
        <template v-else> - </template>
      </template>

      <template #get_status_slot="{ row }">
        <template v-if="row.get_status === 1">未领取</template>
        <template v-else-if="row.get_status === 2">已领取</template>
        <template v-else> - </template>
      </template>

      <template #operation="{ row }">
        <el-button type="text" @click="getRewardDetail(row)"
          >查看奖品内容</el-button
        >
      </template>
    </ly-table>
    <!-- dialog 奖品内容 -->
    <el-dialog
      title="奖品内容"
      :visible.sync="dialogVisible"
      custom-class="q-dialog"
      width="34%"
    >
      <div class="reward">
        <template v-if="rewardType === 1">
          <el-input
            type="textarea"
            v-model="rewardDetail"
            :autosize="{ minRows: 6, maxRows: 10 }"
            resize="none"
            readonly
          >
          </el-input>
          <div class="q-dialog__img" v-if="rewardDetailImg">
            <div class="flex flex-center">
              <img :src="rewardDetailImg" alt="" />
            </div>
          </div>
        </template>
        <div class="q-dialog-block" v-else-if="rewardType === 2">
          <div class="reward-code">{{ rewardDetail }}</div>
        </div>
      </div>
    </el-dialog>

    <!-- 兑换码列表 -->
    <code-list
      ref="codeListRef"
      @success="queryData"
      :rewardId="rewardId"
      :userId="userId"
    ></code-list>
  </div>
</template>

<script>
import codeList from './components/codeList'
import lyTag from '@/components/lyTag'
import lyTable from '@/components/mixins/ly-table.js'
import { activity, reward, stepsApi } from './http'
import util from '@/common/util.js'
export default {
  components: {
    codeList,
    lyTag
  },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: reward,
      IMMEDIATE: false,
      loading: true,
      activityList: [],
      activityId: '',
      activityValue: '',
      exportLoading: false,
      exOptions: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '自定义'
      }, {
        value: 2,
        label: '兑换码'
      }],
      rewardOption: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '未发放'
      }, {
        value: 2,
        label: '已发放'
      }],
      addOptions: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '已添加'
      }, {
        value: 2,
        label: '已删除'
      }],
      sendOptions: [{
        value: '-1',
        label: '全部'
      }, {
        value: '0',
        label: '等待发送'
      }, {
        value: '2',
        label: '发送失败'
      }, {
        value: '1',
        label: '发送成功'
      }],
      rewardTypeOption: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '未领取'
      }, {
        value: 2,
        label: '已领取'
      }],
      stepsOps: [],
      dialogVisible: false,
      rewardType: null,
      rewardDetail: null,
      rewardDetailImg: '',
      rewardLoading: true,
      rewardId: null,
      userId: null
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 140,
        column: [
          {
            label: '奖品名称',
            prop: 'reward_title',
            slot: 'reward_title_slot',
            search: true,
            width: 200,
            placeholder: '请输入奖品名称'
          },
          {
            label: '任务阶段',
            prop: 'reward_number',
            key: 'reward_number',
            search: true,
            type: 'select',
            source: this.stepsOps,
            default: null,
            width: 200
          },
          {
            label: '奖品类型',
            prop: 'reward_type',
            slot: 'reward_type_slot',
            align: 'center',
            search: true,
            source: this.exOptions,
            type: 'select',
            columnProps: {
              'min-width': 100
            }
          },
          {
            label: '领取用户',
            prop: 'nickname',
            search: true,
            placeholder: '请输入用户昵称',
            slot: 'nickname_slot',
            width: 170
          },
          {
            label: '完成任务时间',
            prop: 'created_date',
            search: true,
            sortable: true,
            key: ['start', 'end'],
            type: 'daterange',
            timeFormat: 'yyyy-MM-dd HH:mm:ss',
            columnProps: {
              'min-width': 150
            },
            formatter ({ created_date }) {
              return util.timeTofmt(created_date, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '发放状态',
            prop: 'send_status',
            slot: 'send_status_slot',
            // search: true,
            type: 'select',
            source: this.rewardOption,
            default: null,
            align: 'center',
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '奖品状态',
            prop: 'get_status',
            slot: 'get_status_slot',
            search: true,
            type: 'select',
            source: this.rewardTypeOption,
            default: null,
            align: 'center',
            columnProps: {
              'min-width': 120
            }
          }
        ]
      }
    }
  },
  async created () {
    await this.taskList()
    await this.getId()
    console.log('this.activityId, this.activityId', this.activityId)
    this.getSteps()
    this.queryData()
  },
  methods: { // 阶段选项
    getSteps () {
      stepsApi.reqQuery({
        book_task_id: this.activityId
      }).then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        console.log('res', res)
        this.stepsOps = [{ label: '全部', value: '' }]
        const result = res || []
        result.map(e => {
          this.stepsOps.push({
            label: e.title,
            value: e.id
          })
        })
      })
    },
    formatterParams (params) {
      return {
        ...params,
        book_task_id: this.activityId
      }
    },
    // 打开兑换码列表
    fn_openCodeList (row) {
      this.$refs.codeListRef.codeVisable = true
      this.rewardId = +row.reward_type_id
      this.userId = +row.id
    },

    // 获取活动列表
    async taskList () {
      const data = await activity.reqList()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return
      }

      this.loading = false
      if (data) {
        this.activityList = data.map((i, v) => {
          return {
            value: i.id,
            label: i.title
          }
        })
      }
    },

    // 当前活动id
    getId () {
      const _id = this.$route.query.id
      if (_id) {
        this.activityId = +_id
      } else {
        this.activityId = this.activityList[0] ? this.activityList[0].value : null
      }

      for (let i = 0; i < this.activityList.length; i++) {
        if (+_id === +this.activityList[i].value) {
          this.activityValue = this.activityList[i].label
        }
      }
    },

    // 切换活动
    changeActivity (val) {
      this.page = 1
      this.activityId = val
      this.queryData()
      this.$store.commit('_SET', {
        'task.id': val
      })
      this.$router.replace({
        query: {
          id: val
        }
      })
    },

    // 修改奖品发送状态
    async updateRewardStatus ({ id }) {
      const data = await reward.resend({
        reward_id: id
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.$message.success('发放成功！')
      this.queryData()
    },

    // 查看奖品详情
    async getRewardDetail (data) {
      this.rewardType = data.reward_type
      if (this.rewardType === 1) {
        this.rewardDetail = data.reward_detail
        this.rewardDetailImg = data.reward_detail_img
      } else if (this.rewardType === 2) {
        this.rewardDetail = data.reward_value
      }

      this.dialogVisible = true
    },

    // 判断JSON
    fn_isJson (str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    },

    // 获取奖品内容为表单的数据
    async fn_getFormReward (data) {
      this.rewardLoading = true
      const res = await this.COMM_HTTP.getOneDetail(
        {
          formId: data.reward_type_id + '',
          requestId: data.reward_value + ''
        }
      )

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.rewardDetail = res
      this.rewardLoading = false
    },

    // 获取活动名称
    getFileName () {
      let _name = ''
      const _obj = this.activityList.find(n => {
        return +n.value === +this.activityId
      })
      _name = `[${_obj.label}]-奖品记录-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },

    // 导出
    async fn_export () {
      this.exportLoading = true
      if (this.params.step === '') {
        this.params.step = null
      }
      const data = await this.axios.get('taskRewerdExport', {
        params: {
          activity_id: this.activityId,
          page: 1,
          per_page: 9999,
          ...this.params
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.exportLoading = false
        this.fn_lyMsg('error', data.msg)
        return false
      }

      this.handleDownload(data.url)
      this.exportLoading = false
    },

    // 下载二维码
    async handleDownload (url) {
      const fileName = this.getFileName()
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.csv'
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/table.scss';
@import '@/assets/scss/components/card.scss';
@import '@/assets/scss/components/gap.scss';

.ly-table {
  ::v-deep {
    .ly-table-main {
      padding-top: 20px;
    }
  }
}

.ly-tool {
  background: #fff;
  border-radius: 16px 16px 0 0;
}

::v-deep .q-dialog {
  p {
    margin: 0;
  }

  a {
    color: $--color-primary;
    pointer-events: none;
  }

  &__img {
    margin-top: 16px;

    img {
      max-height: 300px;
      max-width: 300px;
    }
  }

  &__body {
    height: 240px;
  }
}

.reward {
  max-height: 50vh;
  min-width: 360px;
  overflow: auto;

  &-list {
    &__item {
      padding: 14px 0;
      border-bottom: 1px solid #f0f1f2;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }

      .title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .val {
        color: #6e6f73;
      }
    }
  }
}
</style>
