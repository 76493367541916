<!--
 * @Author: zhanln
 * @Date: 2021-12-31 14:08:59
 * @LastEditTime: 2022-01-21 15:49:08
 * @LastEditors: zhanln
 * @Description: lottery
-->
<template>
  <act-layout
    :cards="cards"
    ref="cards"
    @setKey="fn_setKey"
    v-loading="pageLoading"
    class="act-layout"
  >
    <!-- 表单区域 -->
    <!-- 活动信息 -->
    <template #basic>
      <view-basic ref="basic" v-show="true"></view-basic>
    </template>

    <!-- 抽奖规则 -->
    <template #rule>
      <view-rule ref="rule" v-show="true"></view-rule>
    </template>

    <!-- 裂变海报 -->
    <template #poster>
      <view-poster ref="poster" v-show="true"></view-poster>
    </template>

    <!-- 活动页设置 -->
    <template #setting>
      <view-setting ref="setting" v-show="true"></view-setting>
    </template>

    <!-- 奖品设置 -->
    <template #reward>
      <view-reward ref="reward" v-show="true"></view-reward>
    </template>

    <!-- 消息通知 -->
    <template #notice>
      <view-notice ref="notice" v-show="true"></view-notice>
    </template>

    <!-- 预览区域 -->
    <template #mobile>
      <mobi-basic v-if="tabKey === 'basic'"></mobi-basic>
      <mobi-poster v-if="tabKey === 'poster'"></mobi-poster>
      <mobi-reward
        v-if="tabKey === 'rule' || tabKey === 'setting' || tabKey === 'reward'"
        :tabKey="tabKey"
      ></mobi-reward>
      <mobi-notice v-if="tabKey === 'notice'"></mobi-notice>
    </template>

    <!-- footer -->
    <template #footer v-if="!pageLoading">
      <edit-footer @submit="fn_submit" :btnLoading="btnLoading"></edit-footer>
    </template>
  </act-layout>
</template>

<script>
// layout
import actLayout from '@/components/actLayout'
import editFooter from './activity/editFooter'
// forms
import viewBasic from './activity/basic'
import viewRule from './activity/rule'
import viewPoster from './activity/poster'
import viewSetting from './activity/setting'
import viewReward from './activity/reward'
import viewNotice from './activity/notice'

// mobiles
import mobiBasic from './mobile/basic'
import mobiPoster from './mobile/poster'
import mobiReward from './mobile/reward'
import mobiNotice from './mobile/notice'

// store
import { mapState } from 'vuex'

// api
import { act } from './http'
export default {
  name: 'lotteryEdit',

  data () {
    return {
      COMM_HTTP: act,
      pageLoading: true,
      btnLoading: false,
      cards: [
        {
          label: '活动信息',
          name: 'basic'
        },
        {
          label: '抽奖规则',
          name: 'rule'
        },
        {
          label: '裂变海报',
          name: 'poster'
        },
        {
          label: '活动页设置',
          name: 'setting'
        },
        {
          label: '奖品设置',
          name: 'reward'
        },
        {
          label: '消息通知',
          name: 'notice'
        }
      ],
      actId: null,
      backComfirm: true
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.lottery.isEdit,
      isCopy: state => state.lottery.isCopy
    })
  },

  async mounted () {
    // 判断是创建、编辑、复制
    const editId = this.$route.query.id
    if (editId) {
      this.$store.commit('lottery/SET_EDIT')
      const edit = this.$route.query.edit
      if (edit === 'copy') {
        this.$store.commit('lottery/SET_COPY')
      }

      // 详情
      await this.fn_getAct(editId)
    } else {
      this.fn_ready()
    }
  },

  methods: {

    // 获取表单详情
    async fn_getAct (id) {
      const data = await this.COMM_HTTP.show({ id })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.actId = data.id

      this.$store.commit('lottery/SET_INFO', {
        type: 'actId',
        val: data.id
      })

      if (data.type === 2) {
        this.$store.commit('lottery/SET_DRAFT')
      }

      if (data.status === 2) {
        this.$store.commit('lottery/SET_END')
      }

      this.fn_ready(data)
    },

    // 导航监听
    fn_setKey (key) {
      this.tabKey = key
    },

    // 通知各表单组件初始化
    fn_ready (data) {
      let basicForm = null// 活动信息
      let ruleForm = null
      let posterForm = null
      let settingForm = null
      let rewardForm = null
      let noticeForm = null

      // 如果有传入详情
      if (data) {
        // 已结束
        if (data.status === 4 && !this.isCopy) {
          this.$store.state.lottery.isEnd = true
        }

        const {
          background_conf,
          code_fail_date,
          code_fail_mode,
          end_msg,
          end_time,
          help_msg,
          invite_lottery_num,
          link_desc,
          link_logo,
          link_title,
          link_type,
          need_invite,
          old_num,
          open_tags,
          poster_conf,
          poster_url,
          prizes,
          rule,
          show_title,
          tag_ids,
          title,
          total_num,
          wecom_user_ids,
          welcome_msg,
          open_packet,
          activity_amount,
          max_amount,
          use_amount,
          fee_rate
        } = data

        basicForm = {
          title,
          end_time,
          wecom_user_ids,
          code_fail_mode,
          code_fail_date,
          welcome_msg,
          link_type,
          link_logo,
          link_title,
          link_desc,
          open_tags,
          tag_ids
        }

        ruleForm = {
          old_num,
          need_invite,
          invite_lottery_num,
          total_num,
          open_packet,
          activity_amount,
          max_amount,
          use_amount,
          fee_rate
        }

        posterForm = {
          ...JSON.parse(poster_conf),
          img_url: poster_url
        }

        settingForm = {
          ...JSON.parse(background_conf),
          rule,
          show_title
        }

        rewardForm = prizes

        noticeForm = {
          help_msg,
          end_msg
        }
      }
      this.$refs.basic.fn_setForm(basicForm)
      this.$refs.rule.fn_setForm(ruleForm)
      this.$refs.poster.fn_setForm(posterForm)
      this.$refs.setting.fn_setForm(settingForm)
      this.$refs.reward.fn_setForm(rewardForm)
      this.$refs.notice.fn_setForm(noticeForm)
      this.pageLoading = false
    },

    // 提交校验
    async fn_submit (type) {
      // 各表单组件校验回调，如果检验通过，返回的是该表单的form对象

      // 活动信息
      const basic = await this.$refs.basic.verify(type === 'draft')
      if (!basic) {
        this.fn_formScroll(this.$refs.cards.$refs.basic[0].$el)
        return false
      }

      // 抽奖规则
      const rule = await this.$refs.rule.verify(type === 'draft')
      if (!rule) {
        this.fn_formScroll(this.$refs.cards.$refs.rule[0].$el)
        return false
      }

      // 裂变海报
      const poster = await this.$refs.poster.verify(type === 'draft')
      if (!poster) {
        this.fn_formScroll(this.$refs.cards.$refs.poster[0].$el)
        return false
      }

      // 活动页设置
      const setting = await this.$refs.setting.verify(type === 'draft')
      if (!setting) {
        this.fn_formScroll(this.$refs.cards.$refs.setting[0].$el)
        return false
      }

      // 奖品设置
      const reward = await this.$refs.reward.verify(type === 'draft')
      if (!reward) {
        this.fn_formScroll(this.$refs.cards.$refs.reward[0].$el)
        return false
      }

      // 消息通知
      const notice = await this.$refs.notice.verify(type === 'draft')
      if (!notice) {
        this.fn_formScroll(this.$refs.cards.$refs.notice[0].$el)
        return false
      }

      this.subForm = { ...basic, ...rule, ...notice }
      this.subForm.poster_url = poster.img_url
      delete poster.img_url
      this.subForm.poster_conf = JSON.stringify(poster)

      this.subForm.prizes = reward.map(item => {
        item.id = this.isCopy ? 0 : item.id
        return item
      })

      this.subForm.rule = setting.rule
      this.subForm.show_title = setting.show_title
      this.subForm.background_conf = JSON.stringify({
        cover_title: setting.cover_title,
        lottery_type: setting.lottery_type
      })

      // 判断是创建还是编辑
      this.btnLoading = true
      if (this.isEdit && !this.isCopy) {
        this.fn_edit(type)
      } else {
        this.fn_create(type)
      }
    },

    // 创建活动
    async fn_create (type) {
      this.subForm.id = 0
      this.subForm.type = type === 'draft' ? 2 : 1
      const data = await this.COMM_HTTP.save(this.subForm)

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_errorSubmit(data.code, data.msg)
        this.btnLoading = false
        return false
      }

      this.backComfirm = false
      this.$router.push('list')
      this.$message.success(type === 'draft' ? '草稿保存成功!' : '活动创建成功!')
    },

    // 修改活动
    async fn_edit (type) {
      this.subForm.type = type === 'draft' ? 2 : 1
      this.subForm.id = this.actId
      const data = await this.COMM_HTTP.save(this.subForm)

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_errorSubmit(data.code, data.msg)
        this.btnLoading = false
        return false
      }

      this.backComfirm = false
      this.$router.push('list')
      this.$message.success(type === 'draft' ? '草稿保存成功!' : '活动修改成功!')
    },

    // 提交失败结果判断
    fn_errorSubmit (code, msg) {
      if (code === 206) {
        this.$store.state.buy.remainDay = 0
        this.fn_lyMsg('info', '应用已过期')
      } else if (code === 205) {
        this.$store.state.buy.remainDay = null
        this.fn_lyMsg('info', msg)
      } else {
        this.fn_lyMsg('info', msg)
      }
    },

    // 滚动到表单报错位置
    fn_formScroll (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    // 重置缓存
    fn_resetForm () {
      this.$store.commit('lottery/RESET')
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === '/login') {
      next()
    } else {
      if (this.backComfirm) {
        // 离开页面确认
        this.$lyConfirm({
          title: '确认离开此页面吗？',
          text: '当前页面尚未保存，是否离开此页面？'
        }).then(async () => {
          this.fn_resetForm()
          this.pageLoading = true
          next()
        }).catch(() => { })
      } else {
        this.fn_resetForm()
        this.pageLoading = true
        next()
      }
    }
  },

  components: {
    actLayout,
    editFooter,
    viewBasic,
    viewRule,
    viewPoster,
    viewSetting,
    viewReward,
    viewNotice,
    mobiBasic,
    mobiPoster,
    mobiReward,
    mobiNotice
  }
}
</script>

<style lang="scss" scoped>
.act-layout {
  ::v-deep .el-main .act-main__right {
    width: 450px;
  }
}
</style>
