<!--
 * @Author: zhanln
 * @Date: 2021-11-03 17:09:31
 * @LastEditTime: 2022-07-18 16:25:02
 * @LastEditors: zhan
 * @Description: 任务设置 - 表单
-->

<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-width="140px" class="act-form" size="medium">
    <!-- 奖品类型 -->
    <!-- <el-form-item label="奖品类型：" prop="type">
      <el-radio-group v-model="form.type" :disabled="isEdit">
        <el-radio :label="1">实物/虚拟奖品</el-radio>
        <el-radio :label="2">谢谢参与</el-radio>
      </el-radio-group>
      <div class="q-info text-small">奖品类型必须存在一个谢谢参与</div>
    </el-form-item> -->

    <!-- 奖品名称 -->
    <el-form-item label="奖品名称：" prop="reward_title">
      <el-input class="act-form__input" v-model.trim="form.reward_title" maxlength="8" show-word-limit
        placeholder="请输入奖品名称"></el-input>
    </el-form-item>

    <!-- 中奖概率 -->
    <el-form-item label="中奖概率：" prop="rate">
      <div class="q-bg is-default rule">
        <el-input-number v-model="form.rate" controls-position="right" :min="0" :max="100" :precision="0"
          class="number-small" @blur="fn_handleBlur($event, 'rate')"></el-input-number>
        %
        <span class="q-info text-small" style="margin-left: 12px">已设置：<span
            :class="[all_rate > 100 ? 'q-danger' : 'q-success']">{{ all_rate }}%</span></span>
        <span class="q-info text-small" style="margin-left: 12px">剩余可设置：<span
            :class="[remain_rate > 0 ? 'q-success' : '']">{{ remain_rate >= 0 ? remain_rate : 0 }}%</span></span>
        <div class="q-info text-small" style="line-height: 1.6; margin-top: 12px">
          1.概率越大中奖概率越大，概率为0时，则不会中奖，建议填写在1-100之间<br />
          2.中奖概率总和应等于100%
        </div>
      </div>
    </el-form-item>

    <!-- 奖品图片 -->
    <el-form-item label="奖品图片：" prop="reward_cover">
      <div class="flex">
        <ly-upload :img="form.reward_cover" @getImg="fn_getRewardCover" size="1MB"></ly-upload>
        <div class="q-info text-small act-form__info" style="flex: 1">
          奖品图片设计须知：<br />
          1. 建议图片尺寸：260px*260px，分辨率72 <br />
          2. 支持JPG、PNG格式，大小1M以内
        </div>
      </div>
    </el-form-item>

    <!-- 兑换方式 -->
    <el-form-item>
      <template slot="label"><span class="q-danger">*</span> 兑换方式：
      </template>
      <reward-detail ref="rewardDetailRef" @changeTotal="fn_changeTotal"></reward-detail>
    </el-form-item>

    <!-- 奖品库存 -->
    <el-form-item label="奖品库存：" prop="total">
      <el-input-number v-model="form.total" controls-position="right" :min="0" :max="99999" :precision="0"
        class="number-big" placeholder="奖品库存" @blur="fn_handleBlur($event, 'total')"></el-input-number>
      <div class="q-info" v-if="isEdit">
        <span style="margin-right: 24px">已使用：<b>{{ form.user_count }}</b></span>
        <span>剩余库存：<b>{{ form.total - form.user_count }}</b></span>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
// components
import rewardDetail from './rewardDetail'
// store
import { mapState } from 'vuex'
export default {
  name: 'taskRewardItem',

  data () {
    // 奖品库存校验
    const checkTotal = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入奖品库存'))
      } else {
        if (this.isEdit && value - this.form.user_count <= 0) {
          callback(new Error('请确保剩余库存大于0'))
        } else {
          callback()
        }
      }
    }
    return {
      form: {},
      rules: {
        type: [
          { required: true, message: '', trigger: 'change' }
        ],
        reward_title: [
          { required: true, message: '请输入奖品名称', trigger: 'change' }
        ],
        reward_cover: [
          { required: true, message: '请上传奖品图片', trigger: 'change' }
        ],
        rate: [
          { required: true, message: '请输入中奖概率', trigger: 'change' }
        ],
        total: [
          { validator: checkTotal, required: true, trigger: 'change' }
        ]
      },
      uploadSetting: {
        limit: null,
        'show-file-list': false
      },
      ready: false
    }
  },

  computed: {
    ...mapState({
      isEdit: state => state.livelottery.editType === 'edit',
      current_step: state => state.livelottery.current_step || 0,
      all_rate: state => {
        let rate = 0
        for (let i = 0; i < 4; i++) {
          rate += state.livelottery[`reward_rate_${i}`] || 0
        }
        return rate
      }
    }),
    remain_rate () {
      return 100 - this.all_rate
    }
  },

  watch: {
    'form.total' () {
      this.$nextTick(() => {
        this.$refs.formRef.validateField('total')
      })
    },
    'form.type' (val) {
      this.fn_setStore('reward_type', val)
    },
    'form.reward_title' (val) {
      this.fn_setStore('reward_title', val)
    },
    'form.reward_cover' (val) {
      this.fn_setStore('reward_cover', val)
    },
    'form.rate' (val) {
      this.fn_setStore('reward_rate', val)
      this.$refs.formRef.validateField('rate')
    }
  },

  methods: {

    // 初始化表单
    fn_setForm (obj) {
      if (obj.reward_type === 2) {
        obj.code_id = obj.code_id || obj.reward_type_id
        obj.code_rule = obj.code_rule || obj.reward_detail
        obj.reward_detail = ''
      }

      if (obj.reward_type === 3) {
        obj.form_id = obj.form_id || obj.reward_type_id
        obj.form_rule = obj.form_rule || obj.reward_detail
        obj.reward_detail = ''
      }

      this.form = obj

      // 切换阶段，清空校验
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate()
        if (obj.type === 1) {
          this.$refs.rewardDetailRef.fn_setDetail(this.form)
        }
        this.ready = true
      })
    },

    // 概率置零
    fn_handleBlur (val, type) {
      if (this.form[type] === undefined) {
        this.form[type] = 0
      }
    },

    // 奖品封面回调
    fn_getRewardCover (img) {
      this.form.reward_cover = img
    },

    // 兑换码库存
    fn_changeTotal (val) {
      this.form.total = val
    },

    // 保存到 vuex
    fn_setStore (type, val) {
      this.$store.commit('livelottery/SET_INFO', {
        name: `${type}_${this.current_step}`,
        val
      })
    },

    // 奖品内容 回调配置
    fn_setRewardContent (data) {
      this.form.reward_type = data.type
      this.form.reward_detail = data.diy.content
      this.form.reward_detail_img = data.diy.img
      this.form.diy_rule_length = data.diy.diy_rule_length
      this.form.code_id = data.code.codeId
      this.form.code_rule = data.code.rule
      this.form.code_rule_length = data.code.code_rule_length
      this.form.form_id = data.form.formId
      this.form.form_rule = data.form.rule
      this.form.form_rule_length = data.form.form_rule_length
      this.form.reward_min_cash = data.reward_min_cash
      this.form.reward_max_cash = data.reward_max_cash
    },

    // 校验
    verify (type) {
      let itemValid = true

      if (!type) {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            itemValid = false
          }
        })
      }

      // 校验奖品内容，通过则设置到form
      if (this.form.type === 1) {
        const { detailValid, form } = this.$refs.rewardDetailRef.verify(type)

        if (!detailValid) {
          itemValid = false
        }

        this.fn_setRewardContent(form)
      }

      return {
        itemValid,
        form: this.form
      }
    }
  },

  components: {
    rewardDetail
  }
}
</script>

<style lang="scss" scoped>
.reward {
  &-info {
    margin-left: 8px;

    &__title {
      margin-bottom: 8px;
    }

    &__desc {
      ::v-deep .el-textarea__inner {
        height: 60px;
        border-color: #dfe1e8 !important;
      }
    }
  }
}

.number-big {
  width: 200px;
}

.rule {
  width: 100%;
  max-width: 560px;
  line-height: inherit;
  padding: 12px 20px;
  box-sizing: border-box;

  &+& {
    margin-top: 8px;
  }
}

.number-small {
  width: 120px;
}

.q-bg {

  ::v-deep.el-icon-arrow-up,
  ::v-deep.el-icon-arrow-down {
    margin-right: 0;
  }
}

.reward-cover {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
    }

    &:hover {
      .tips-opacity {
        opacity: 1;
      }
    }
  }

  &__change {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 12px;
    line-height: 28px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    border-radius: 0 0 4px 4px;
  }

  &__icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }

  &__tips {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
    line-height: 72px;

    i {
      color: #fff;
    }
  }
}
</style>
