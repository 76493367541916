var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-mobile",
    { attrs: { showWel: false, hasFooter: false, sub: "", title: "" } },
    [
      _c(
        "div",
        {
          staticClass: "qrlink",
          class: [
            _vm.linkType === 2 && _vm.link_unreal_expire_open ? "is-high" : ""
          ]
        },
        [
          _c("img", {
            staticClass: "bg-right",
            attrs: { src: require("@assets/images/qr_link_bg_fi.png"), alt: "" }
          }),
          _c("img", {
            staticClass: "bg-left",
            attrs: {
              src: require("@assets/images/qr_link_bg_fi_left.png"),
              alt: ""
            }
          }),
          _c("div", { staticClass: "qrlink-carousel" }, [
            _c("img", {
              staticClass: "avatar",
              attrs: { src: require("@assets/images/avatar.jpg"), alt: "" }
            }),
            _vm._v(" 用户 192****1274 已添加好友 ")
          ]),
          _c(
            "div",
            { staticClass: "qrlink-body" },
            [
              _vm.linkType === 1
                ? [
                    _c("div", { staticClass: "bg" }, [
                      _c("div", {
                        staticClass: "title",
                        domProps: {
                          innerHTML: _vm._s(_vm.guide || "请输入引导说明")
                        }
                      }),
                      _c("img", {
                        staticClass: "code",
                        attrs: {
                          src: require("@assets/images/contact_me_qr.png"),
                          alt: ""
                        }
                      }),
                      _c("div", { staticClass: "check" }, [
                        _c("img", {
                          attrs: {
                            src: require("@assets/svg/protect.svg"),
                            alt: ""
                          }
                        }),
                        _vm._v("二维码已通过检测，请放心扫码 ")
                      ]),
                      _c("div", { staticClass: "tip" }, [
                        _vm._v("长按识别二维码")
                      ])
                    ])
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "qrlink-poster" },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "qrlink-poster__bg",
                            attrs: { src: _vm.linkBg, fit: "cover" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("i", { staticClass: "el-icon-back" }),
                                _vm._v("请上传链接页背景 ")
                              ]
                            )
                          ]
                        ),
                        _c(
                          "vue-draggable-resizable",
                          {
                            staticClass: "dragWrap dragQrcode",
                            attrs: {
                              x: Number(_vm.qrcode_x),
                              y: Number(_vm.qrcode_y),
                              w: Number(_vm.qrcode_width),
                              h: Number(_vm.qrcode_height),
                              parent: true,
                              handles: ["br"],
                              "lock-aspect-ratio": true
                            },
                            on: {
                              dragging: function(x, y) {
                                return _vm.onDrag(x, y, "qrcode")
                              },
                              resizing: function(x, y, w, h) {
                                return _vm.onResize(w, h, "qrcode")
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@assets/images/contact_me_qr.png")
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          ),
          _c("div", { staticClass: "qrlink-footer" }, [
            _vm.sevice_open
              ? _c("div", { staticClass: "contact" }, [
                  _vm._v(" 若以上二维码无法添加， "),
                  _c(
                    "span",
                    { staticClass: "flex", staticStyle: { color: "#2d67cf" } },
                    [
                      _vm._v("请"),
                      _c("img", {
                        staticClass: "msg",
                        attrs: {
                          src: require("@assets/svg/message.svg"),
                          alt: ""
                        }
                      }),
                      _vm._v("联系主办方")
                    ]
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "copyright", staticStyle: { color: "#2d67cf" } },
              [
                _c("img", {
                  attrs: {
                    src: require("@assets/images/01logo_circle.webp"),
                    alt: ""
                  }
                }),
                _vm._v("零一裂变 | 仅提供技术支持 "),
                _c("i", { staticClass: "el-icon-arrow-right" })
              ]
            )
          ])
        ]
      ),
      _c("div", { staticClass: "qrlink-fixed" }, [
        _vm.linkType === 2 && _vm.expire > 0 && _vm.link_unreal_expire_open
          ? _c(
              "div",
              { staticClass: "qrlink-expire" },
              [
                _c("img", {
                  attrs: {
                    src: require("@assets/svg/alarm-clock.svg"),
                    alt: ""
                  }
                }),
                _c(
                  "span",
                  {
                    staticClass: "q-info",
                    staticStyle: { "margin-right": "4px" }
                  },
                  [_vm._v(_vm._s(_vm.link_unreal_expire_text))]
                ),
                _vm.link_unreal_expire_day > 0
                  ? [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.link_unreal_expire_day))
                      ]),
                      _vm._v("天 ")
                    ]
                  : _vm._e(),
                _vm.link_unreal_expire_day + _vm.link_unreal_expire_hour > 0
                  ? [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.link_unreal_expire_hour))
                      ]),
                      _vm._v("时 ")
                    ]
                  : _vm._e(),
                _vm.link_unreal_expire_day +
                  _vm.link_unreal_expire_hour +
                  _vm.link_unreal_expire_minute >
                0
                  ? [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.link_unreal_expire_minute))
                      ]),
                      _vm._v("分 ")
                    ]
                  : _vm._e(),
                _vm.link_unreal_expire_day +
                  _vm.link_unreal_expire_hour +
                  _vm.link_unreal_expire_minute +
                  _vm.link_unreal_expire_second >
                0
                  ? [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.link_unreal_expire_second))
                      ]),
                      _vm._v("秒 ")
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ]),
      _vm.linkType === 2 && _vm.expire > 0 && _vm.link_unreal_expire_open
        ? _c("div", { staticClass: "qrlink-fixed__place" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }