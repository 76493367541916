<!--
 * @Descripttion: 群托管
 * @version:
 * @Author: lw
 * @Date: 2021-05-12 16:38:03
 * @LastEditTime: 2022-04-19 15:57:02
-->
<template>
  <div class="page-warp groupManagement">
    <div class="form-area">
      <el-form ref="form" inline :model="mainForm">
        <div class="top-tips">
          <p>
            1. 这里展示云端账号托管的群聊，每个云端账号最多能托管30个群。<br />2.
            云端账号可在托管的群内进行无限制群发、数据统计等操作。<br />
            3.
            请确保相关的云端账号处于在线状态，否则将无法使用相关功能或者造成数据不精确。
          </p>
        </div>
        <el-form-item label="群名称">
          <el-input
            v-model="mainForm.group_name"
            clear
            placeholder="请输入群名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="群主">
          <el-input
            v-model="mainForm.owner_name"
            clear
            placeholder="请输入群主名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="托管账号">
          <el-select v-model="mainForm.robot_admin_id" placeholder="请选择">
            <el-option
              v-for="item in aliasOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="0">
          <el-button
            round
            icon="iconfont icon-search"
            type="primary"
            @click="handlerSearch"
            >查询</el-button
          >
          <el-button round icon="iconfont icon-data" @click="fn_reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="main-area">
      <div class="hander-area">
        <el-button
          round
          icon="iconfont icon-plus"
          type="primary"
          size="medium"
          @click="handleAddManage"
          >添加托管的群</el-button
        >
        <el-tooltip
          content="请先选择要分组的群"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              round
              plain
              icon="iconfont icon-edit"
              size="medium"
              @click="handleGroup"
              :disabled="selected.length === 0"
              >分组</el-button
            ></span
          >
        </el-tooltip>
        <el-tooltip
          content="请先选择要移出分组的群"
          effect="dark"
          class="tooltip"
          placement="top"
          :disabled="selected.length > 0"
        >
          <span style="margin-left: 10px">
            <el-button
              round
              v-if="topicId > 0"
              plain
              size="medium"
              @click="handleRemoveGroup"
              :disabled="selected.length === 0"
              >移出分组</el-button
            ></span
          >
        </el-tooltip>
      </div>
      <div class="temp-container">
        <div class="group-list">
          <div class="list">
            <div
              class="list-item flex"
              :class="{ active: topicId === item.id }"
              @click="handleTopic(item, $event)"
              v-for="item in groupList"
              :key="item.id"
            >
              <div class="temp flex">
                <el-tooltip
                  :disabled="toolDisable"
                  effect="dark"
                  :content="item.name"
                  placement="top"
                >
                  <span class="ell name" @mouseenter="fn_groupEnter">{{
                    item.name
                  }}</span>
                </el-tooltip>
                <span class="num">{{ `(${item.count})` }}</span>
              </div>
              <div class="btn" v-if="item.id > 0">
                <el-popover
                  placement="bottom-end"
                  trigger="click"
                  popper-class="add-group-popover"
                >
                  <span @click="handleEdit(item)" slot="reference">
                    <i class="iconfont icon-edit"></i>
                  </span>
                  <div class="add-group">
                    <el-input
                      size="small"
                      placeholder="请输入分组名称"
                      maxlength="20"
                      show-word-limit
                      v-model="groupName"
                    ></el-input>
                    <el-button round size="small" @click="handleCancel"
                      >取消</el-button
                    >
                    <el-button
                      round
                      size="small"
                      type="primary"
                      @click="handleSave"
                      >确定</el-button
                    >
                  </div>
                </el-popover>
                <span @click.stop="handleDelete(item)" v-if="item.count === 0"
                  ><i class="iconfont icon-delete"></i
                ></span>
                <el-tooltip
                  effect="dark"
                  content="请移除或删除分组下的群，再删除分组"
                  placement="top"
                  v-else
                >
                  <span class="q-info" @click.stop
                    ><i class="iconfont icon-delete"></i
                  ></span>
                </el-tooltip>
              </div>
              <el-popover
                v-if="item.id === -1"
                placement="bottom-end"
                trigger="click"
                popper-class="add-group-popover"
                @show="
                  groupName = '';
                  groupId = '';
                "
              >
                <i class="iconfont icon-plus-fill" slot="reference"></i>
                <div class="add-group">
                  <el-input
                    size="small"
                    placeholder="请输入分组名称"
                    maxlength="20"
                    show-word-limit
                    v-model="groupName"
                  ></el-input>
                  <el-button round size="small" @click="handleCancel"
                    >取消</el-button
                  >
                  <el-button
                    round
                    size="small"
                    type="primary"
                    @click="handleSave"
                    >确定</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="right-area">
          <el-table
            ref="table"
            v-if="ready"
            :data="tableData"
            :height="tableHeight"
            @selection-change="handleSelectionChange"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"> </el-table-column>

            <el-table-column prop="liveCodeName" label="群名称" min-width="150">
              <template slot-scope="scope">
                <div class="flex flex-start">
                  <div class="ly-table-tearm">
                    <i class="iconfont icon-weixin"></i>
                  </div>
                  <span style="margin-right: 8px; flex: 1">{{
                    scope.row.name ? scope.row.name : "群聊"
                  }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="liveCodeName" label="群分组" min-width="120">
              <template slot-scope="scope">
                <ly-drow
                  :list="scope.row.topics"
                  name="name"
                  dep
                  empty="未分组"
                ></ly-drow>
              </template>
            </el-table-column>

            <el-table-column
              prop="user_total"
              label="群成员数"
              align="center"
              min-width="120"
            >
            </el-table-column>

            <el-table-column
              prop="owner"
              label="群主"
              align="center"
              min-width="120"
            >
              <template slot-scope="scope">
                <ly-tag
                  :content="scope.row.owner.name"
                  v-if="scope.row.owner"
                ></ly-tag>
                <template v-else> - </template>
              </template>
            </el-table-column>

            <el-table-column
              prop="liveCodeName"
              label="托管账号"
              align="center"
              width="220"
            >
              <template slot-scope="scope">
                <ly-drow
                  v-if="scope.row.work_robots && scope.row.work_robots.length"
                  :list="scope.row.work_robots"
                  avatarField="avatar"
                  name="name"
                ></ly-drow>
                <template v-else> - </template>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right" width="120">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="
                    $router.push({
                      path: 'detail',
                      query: {
                        id: scope.row.id,
                        name: scope.row.name ? scope.row.name : '群聊',
                      },
                    })
                  "
                  >群详情</el-button
                >
              </template>
            </el-table-column>

            <div slot="empty" class="ly-table-empty">
              <slot name="empty">
                <template v-if="flag">
                  <template v-if="isCustomerSeach">
                    <img src="@assets/svg/default/no_res.svg" alt="" />
                    无搜索结果
                  </template>
                  <template v-else>
                    <img src="@assets/svg/default/no_data.svg" alt="" />
                    暂无数据
                  </template>
                </template>
              </slot>
            </div>
          </el-table>
        </div>

        <el-pagination
          background
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="per_page"
          layout="slot, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <div class="pagination-slot">
            <span class="total">共{{ total }}条记录</span>
            <slot name="pagination"></slot>
          </div>
        </el-pagination>
      </div>

      <add-management ref="addManage" @change="queryData"></add-management>
      <grouping ref="grouping" @change="handleGrouped"></grouping>
    </div>
  </div>
</template>
<script>
import lyTable from '@/components/mixins/ly-table.js'
import { groupManagement } from './http.js'
import addManagement from './components/addManagement'
import grouping from './components/grouping'
import lyTag from '@/components/lyTag'
import lyDrow from '@/components/lyDrow'
export default {
  mixins: [lyTable],
  components: {
    addManagement,
    grouping,
    lyTag,
    lyDrow
  },
  data () {
    const disabledDate = (time) => {
      let _date = new Date()
      if (_date.toString().indexOf('GMT-08:00') > -1) {
        _date = new Date((_date.getTime() + (1 / 24 * 16 * 8.64e7)))
      }
      const _times = this.$day(this.$day(_date).format('YYYY-MM-DD') + ' 23:59:59')
      return time.getTime() > _times.valueOf()
    }
    return {
      COMM_HTTP: groupManagement,
      aliasOption: [],
      groupList: [],
      topicId: -1,
      topicName: '',
      toolDisable: true,
      leftTip: '',
      groupName: '', // 分组名
      groupId: '', // 保存编辑的分组id
      tableHeight: 0,
      form: {
        editId: '',
        name: ''
      },
      mainForm: {
        type: 1,
        group_name: '',
        owner_name: '',
        robot_admin_id: 0
      },
      pickerOptions: {
        disabledDate: (time) => {
          return disabledDate(time)
        }
      },
      ready: false,
      selected: [],
      flag: false,
      isCustomerSeach: false
    }
  },
  computed: {
    tableOpt () {
      return {
        showSelection: true,
        scrollEl: '.groupManagement',
        operationWidth: 120,
        column: [
          // 通过searchSlot来写top-tips 方便布局
          {
            label: '搜索插槽',
            prop: 'tips',
            search: true,
            searchSlot: 'tips_slot',
            hide: true
          },
          {
            label: '群名称',
            prop: 'name',
            key: 'group_name',
            search: true,
            placeholder: '请输入群名称',
            slot: 'name_slot',
            columnProps: {
              'min-width': 150
            }
          },
          {
            label: '群分组',
            prop: 'topics',
            slot: 'topics_slot',
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '群成员数',
            prop: 'user_total',
            align: 'center',
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '群主',
            prop: 'owner',
            key: 'owner_name',
            slot: 'owner_slot',
            formatter (row, column, cellValue, index) {
              return cellValue.name
            },
            search: true,
            placeholder: '请输入群主名称',
            align: 'center',
            columnProps: {
              'min-width': 120
            }
          },
          {
            label: '托管账号',
            prop: 'work_robots',
            slot: 'work_robots_slot',
            search: true,
            key: 'robot_admin_id',
            showOverflowTooltip: false,
            type: 'select',
            default: 0,
            clearable: 0,
            align: 'center',
            placeholder: '全部账号',
            source: this.aliasOption,
            width: 220
          }
        ]
      }
    }
  },
  beforeMount () {
    this.getManagementAccount()
    this.getTopic()
    this.queryData()
  },

  mounted () {
    window.onresize = () => {
      this.getTableHeight()
    }
    setTimeout(() => {
      this.getTableHeight()
    })
  },
  methods: {
    handlerSearch () {
      this.isCustomerSeach = true
      this.queryData()
    },
    async queryData () {
      this.$store.dispatch('ADDLOADING')
      const params = {
        ...this.mainForm,
        topic_id: this.topicId,
        page: this.page,
        per_page: this.per_page
      }
      const res = await this.COMM_HTTP.reqQuery(params)
      this.$store.dispatch('REMOVELOADING')
      this.flag = false
      this.tableData = res.list
      this.total = res.total
    },

    fn_reset () {
      this.mainForm = {
        type: 1,
        group_name: '',
        owner_name: '',
        robot_admin_id: 0
      }
      this.queryData()
    },
    fn_groupEnter (e, max) {
      const ev = e.target
      const ev_width = ev.offsetWidth
      if (ev_width >= (max || 78)) {
        this.toolDisable = false // 文本的实际高度)
      } else {
        this.toolDisable = true
      }
    },
    handleAddManage () {
      this.$refs.addManage.robotFilter = ''
      this.$refs.addManage.visible = true
      this.$refs.addManage.page = 1
    },
    handleGroup () {
      // this.$refs.grouping.group = []
      this.$refs.grouping.visible = true
    },
    // 获取最新分组数据 并清空选择
    handleGrouped () {
      this.getTopic()
      this.queryData()
      this.$refs.table.clearSelection()
    },
    async handleRemoveGroup () {
      this.$lyConfirm({
        title: `是否将选中的群聊移出（${this.topicName}）？`,
        text: '',
        confirmButtonText: '确定'
      }).then(async () => {
        const ids = this.selected.map(e => {
          return e.id
        })
        const res = await this.COMM_HTTP.doGroup({
          group_ids: ids,
          topic_ids: [this.topicId],
          type: 1
        })

        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return false
        }

        this.$message.success('移出分组成功!')
        this.handleGrouped()
      }).catch(() => { })
    },
    handleAddGroup () {
      this.$refs.addGroup.visible = true
    },
    async handleSave () {
      if (this.msgBox) {
        this.msgBox.close()
      }
      if (this.groupName === '') {
        this.msgBox = this.$message.info('分组名称不能为空！')
        return false
      }
      const method = this.groupId ? 'editTopic' : 'addTopic' // 存在groupId 表示编辑 不存在表示新增
      const params = this.groupId ? { id: this.groupId, name: this.groupName } : { name: this.groupName }
      const res = await groupManagement[method](params)
      if ((res && res.code) || (res && res.code === 0)) {
        this.msgBox = this.$message.warning(res.msg)
        return false
      }
      this.groupName = ''
      this.groupId = ''
      this.getTopic()
      this.handleCancel()
    },
    async getManagementAccount () {
      this.aliasOption = [{ label: '全部账号', value: 0 }]
      const res = await this.COMM_HTTP.getManagementAccount({ page: 1, per_page: 999, type: 1 })
      res.list.map((e, i) => {
        this.$set(this.aliasOption, i + 1, { label: e.name, value: e.id })
      })
    },
    async getTopic () {
      const res = await this.COMM_HTTP.getTopic({ type: 1 })
      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return
      }
      this.groupList = res
    },
    handleTopic (item, e) {
      this.isCustomerSeach = false
      if (e.target.tagName === 'I') {
        return false
      }
      this.page = 1
      this.topicId = item.id
      this.topicName = item.name
      this.$refs.table.clearSelection()
      this.queryData()
    },
    async handleDelete ({ id, name }) {
      this.$lyConfirm({
        title: `确认要删除分组 ${name} 吗？`,
        text: '分组删除后将无法找回, 是否继续?',
        confirmButtonText: '删除'
      }).then(async () => {
        await this.COMM_HTTP.delTopic({ id })
        this.topicId = -1
        this.getTopic()
        this.queryData()
      }).catch(() => { })
    },
    async handleEdit ({ id, name }) {
      this.groupId = id
      this.groupName = name
    },
    handleCancel () {
      document.body.click()
    },

    getTableHeight () {
      this.tableHeight = document.querySelector('.temp-container').offsetHeight
      this.ready = true
    },
    handleSizeChange (v) {
      this.per_page = v
      this.page = 1
      this.queryData()
    },
    handleCurrentChange (v) {
      this.page = v
      this.queryData()
    },
    handleSelectionChange (select) {
      this.selected = select
    }
  },
  destroyed () {
    window.onresize = null
  }
}
</script>
<style lang="scss" scoped>
@import "@assets/scss/components/tags.scss";
@import "@assets/scss/utils.scss";
.qy-tag {
  @include ellipsis;
  margin-left: 10px;
  margin-right: 0;
  max-width: 120px;
}
.top-tips {
  border-radius: 8px;
  background: #ebf3ff;
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 20px;
  p {
    margin: 0;
    line-height: 24px;
  }
}
.groupManagement {
  display: flex;
  .form-area {
    padding: 20px 20px 0 20px;
    border-radius: 16px;
    box-shadow: $--shadow;
    background: #fff;
    margin-bottom: 20px;
    ::v-deep {
      .el-form-item {
        margin-bottom: 20px;
      }
      .el-input--medium .el-input__inner {
        width: 160px;
      }
      .el-date-editor--daterange.el-input__inner {
        width: 240px;
      }
    }
  }
  .main-area {
    flex: 1;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 16px;
    box-shadow: $--shadow;
    background: #fff;
    margin-bottom: 80px;
    overflow: hidden;
    .group-list {
      width: 240px;
      overflow-y: scroll;
      background: #f7f8fc;
      border-right: 1px solid #dfe1e8;
      box-sizing: border-box;
      .title {
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212b36;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        padding: 0 20px;
      }
      .add-group {
        display: inline-block;
        width: 95px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        background: #ececec;
        border-radius: 4px;
        font-size: 14px;
      }
      .list {
        padding: 8px 0;
        max-height: calc(616px - 48px - 6px);
        overflow: auto;
        margin-bottom: 16px;
      }
      .list-item {
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        cursor: pointer;
        &.active {
          .temp {
            color: #2b7cff;
          }
        }
        &:hover {
          background-color: #eaebf2;
          .btn {
            display: inline-block;
          }
        }
        .temp {
          width: 115px;
          overflow: hidden;
          flex: 1;
        }
        .name {
          max-width: 100px;
          @include ellipsis;
        }
        .num {
          margin: 0 10px;
        }
        .btn {
          width: 70px;
          display: none;
          text-align: right;
          line-height: 22px;
          font-size: 16px;
          span {
            color: #454d5b;
            cursor: pointer;
            &:nth-of-type(2) {
              margin-left: 10px;
            }

            &.q-info {
              color: #b5b5b5;
              cursor: not-allowed;
            }
          }
        }
        .icon-plus-fill {
          color: $--color-primary;
          font-size: 22px;
          margin-left: 50px;
          &:hover {
            color: #5596ff;
          }
        }
      }
    }
    .right-area {
      flex: 1;
      overflow: hidden;
    }
    .hander-area {
      margin-bottom: 20px;
    }
    .temp-container {
      flex: 1;
      display: flex;
      border: 1px solid #dfe1e8;
      border-radius: 8px;
      overflow: hidden;
    }
    ::v-deep {
      .el-button--text {
        padding: 0;
      }
    }
    .el-pagination {
      width: calc(100% - 260px) !important;
      box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
      background: #fff;
      position: fixed;
      bottom: 0;
      height: 61px;
      text-align: right;
      right: 0;
      box-sizing: border-box;
      padding: 16px 20px 0 20px;
      z-index: 99;
      ::v-deep {
        .pagination-slot {
          float: left;
          font-weight: normal;
        }
        .el-pagination__total {
          display: none;
        }
      }
    }
  }
}
.add-group {
  .el-input {
    width: 220px;
    margin-right: 24px;
  }
}
</style>
<style lang="scss">
.add-group-popover {
  padding: 12px;
  box-shadow: 0px 12px 24px 0px rgba(145, 158, 171, 0.24),
    0px 0px 2px 0px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  .popper__arrow {
    display: none;
  }
}
</style>
