<!--
 * @Author: zhan
 * @Date: 2022-06-01 14:09:59
 * @LastEditTime: 2022-10-24 16:28:02
 * @LastEditors: zhanln
-->
<template>
  <div class="page-warp" v-loading="loading">
    <div class="applet-box ly-shadow">
      <div class="q-bg is-primary">
        1、确保小程序和企业微信、视频号认证主体保持一致；<br />
        2、使用微信小程序管理员帐号扫码进行授权，授权过程中请勾选所有权限以确保小程序功能完整性；<br />
        3、扫码授权后，等待3秒过程中不可提前关闭页面，授权成功后将自动跳转回此页面；<br />
        4、授权完成后，请登录企业微信后台，将该小程序绑定在企微公众平台中，否则无法与企微客户进行匹配；<br />
        5、小程序发版上线后，将覆盖原小程序内容，建议使用全新小程序。
      </div>
    </div>

    <div class="applet-wrap applet-box ly-shadow">
      <div class="applet-info" v-if="appletInfo">
        <div class="applet-name">
          <img :src="appletInfo.head_image" alt="" class="applet-name__icon" />
          {{ appletInfo.nick_name }}
        </div>
        <div class="applet-container">
          <div class="left">
            <div class="applet-list">
              <div class="applet-list__title">当前版本</div>
              <div class="applet-list__item">
                <div class="applet-list__info">
                  <div class="applet-list__label">AppId：</div>
                  {{ appletInfo.app_id }}
                </div>
                <div class="applet-list__info">
                  <div class="applet-list__label">认证状态：</div>

                  <span
                    class="applet-list__text q-success"
                    v-if="appletInfo.verify_type === 1"
                  >
                    <i class="el-icon-success"></i> 已认证
                  </span>
                  <span class="applet-list__text q-warning" v-else>
                    <i class="iconfont icon-info-fill"></i> 未认证
                  </span>
                </div>
                <div class="applet-list__info">
                  <div class="applet-list__label">版本号：</div>
                  {{ vers.release_version || '-' }}
                </div>
                <div class="applet-list__info">
                  <div class="applet-list__label">上线时间：</div>
                  {{
                    vers.publish_time
                      ? $day(vers.publish_time * 1000).format(
                          'YYYY-MM-DD HH:mm'
                        )
                      : '-'
                  }}
                </div>
              </div>

              <div class="applet-list__title" style="margin-top: 24px">
                待上线版本
              </div>
              <div class="applet-list__item">
                <div class="applet-list__info">
                  <div class="applet-list__label">版本号：</div>
                  {{ vers.audit_ver || '-' }}
                </div>
                <div class="applet-list__info">
                  <div class="applet-list__label">审核状态：</div>
                  <span
                    class="applet-list__text q-success"
                    v-if="vers.audit_status === 1"
                  >
                    审核中
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="小程序微信官方审核中，预约需要1-2个工作日，请耐心等候"
                      placement="top"
                    >
                      <i class="iconfont icon-info-fill"></i>
                    </el-tooltip>
                  </span>
                  <span
                    class="applet-list__text q-danger"
                    v-else-if="vers.audit_status === 2"
                  >
                    审核未通过
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="vers.audit_msg"
                      placement="top"
                    >
                      <i class="iconfont icon-info-fill"></i>
                    </el-tooltip>
                  </span>
                  <span
                    class="applet-list__text q-warning"
                    v-else-if="vers.audit_status === 3"
                  >
                    审核延后
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="服务商系统升级，请耐心等待审核"
                      placement="top"
                    >
                      <i class="iconfont icon-info-fill"></i>
                    </el-tooltip>
                  </span>
                  <span
                    class="applet-list__text q-warning"
                    v-else-if="vers.audit_status === 4"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="服务商发布上线中，请耐心等待"
                      placement="top"
                    >
                      <i class="el-icon-s-promotion"></i>
                    </el-tooltip>
                    待发布
                  </span>
                  <template v-else>-</template>
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div>
              <img :src="appletInfo.qr_code_url" alt="" class="applet-code" />
            </div>
            <el-button
              v-if="appletInfo.qr_code_url"
              type="primary"
              round
              @click="handleDownload"
              ><i class="iconfont icon-download"></i> 下载</el-button
            >
          </div>
        </div>
      </div>

      <div class="applet-auth" v-if="!appletInfo && !loading">
        <img
          src="@assets/images/app_empty.png"
          alt=""
          class="applet-auth__icon"
        />
        <div class="q-info text-small">暂未授权小程序</div>
        <el-button
          round
          type="primary"
          class="applet-auth__btn"
          @click="fn_auth"
          >授权小程序</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from './http'
import apis from '@/config'
export default {
  name: 'applet',
  data () {
    return {
      loading: true,
      appletInfo: null,
      vers: {
        audit_msg: '-',
        audit_status: 0,
        audit_ver: '-',
        publish_time: null,
        release_version: '-'
      },
      authUrl: `${apis.host}/wecom/open-platform/auth-url`
    }
  },

  async mounted () {
    const wecom_id = localStorage.getItem('companyId')
    this.authUrl += `?wecom_id=${wecom_id}`

    const info = await auth.authMpInfo()
    if (info) {
      this.appletInfo = info
    }

    const vers = await auth.authMpVer()
    if (vers) {
      this.vers = vers
    }

    this.loading = false
  },

  methods: {
    fn_auth () {
      window.location.href = this.authUrl
    },

    // 下载
    async handleDownload () {
      const x = new XMLHttpRequest()
      x.open('GET', this.appletInfo.qr_code_url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = 'appQrCode'
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/var.scss';
@import '@/assets/scss/utils.scss';

.applet {
  display: flex;
  flex-direction: column;

  &-box {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 16px;
    background-color: #fff;

    .q-bg {
      padding: 12px;
    }
  }

  &-wrap {
    flex: 1;
    padding: 42px 40px;
  }

  &-name {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 42px;

    &__icon {
      width: 32px;
      margin-right: 9px;
      border-radius: 50%;
    }
  }

  &-container {
    display: flex;
    padding-right: 152px;

    .left {
      min-width: 600px;
    }

    .right {
      text-align: center;
    }
  }

  &-list {
    &__title {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      padding-left: 12px;
      margin-bottom: 16px;
      flex: 1;

      &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        width: 4px;
        height: 16px;
        background-color: #2b7cff;
        border-radius: 2px;
        transform: translateY(-50%);
      }
    }

    &__item {
      display: flex;
      flex-wrap: wrap;
    }

    &__info {
      width: 50%;
      display: flex;
      padding-bottom: 16px;
      align-items: center;
    }

    &__label {
      color: #6e788a;
    }

    &__text {
      display: flex;
      align-items: center;
      line-height: 1;

      i {
        margin: 0 4px;
        font-size: 20px;
      }
    }
  }

  &-code {
    width: 160px;
    margin-bottom: 8px;
  }

  &-auth {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 112px;
    border-radius: 12px;

    &__icon {
      width: 200px;
    }

    &__btn {
      margin-top: 48px;
      height: 44px;
      width: 210px;
    }
  }
}
</style>
