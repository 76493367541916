<!--
 * @Descripttion: 用户详情红包记录列表
 * @version: 1.0.0
-->
<template>
  <div>
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #reward_title="{ row }">
        <div class="avatar-name">
          <img src="@/assets/images/redenvelopes.png" alt="" style="width: 20px; margin-right: 8px" v-if="row.reward_type === 4"/>
          <img src="@/assets/svg/reward.svg" alt="" style="width: 20px; margin-right: 8px" v-else/>
          <span class="name">{{ row.reward_title }}</span>
        </div>
      </template>

      <template #get_status_slot="{ row }">
        <template v-if="row.get_status === 1">未领取</template>
        <template v-else-if="row.get_status === 2">已领取</template>
        <template v-else-if="row.get_status === 3">未填写</template>
        <template v-else-if="row.get_status === 4">已填写</template>
        <template v-else-if="row.get_status === 5">发放成功</template>
        <template v-else-if="row.get_status === 6">
          发放失败
          <el-tooltip
            effect="dark"
            :content="row.fail_msg"
            placement="top"
          >
            <i class="iconfont icon-warning-fill"></i>
          </el-tooltip>
        </template>
        <template v-else>-</template>
      </template>

      <template #operation="{ row }">
        <el-button
          v-if="row.reward_type === 4"
          type="text"
          :disabled="row.get_status === 5"
          @click="handleReSend(row)"
          >重新发放</el-button
        >
        <el-divider direction="vertical" v-if="row.reward_type === 4"></el-divider>
        <template v-if="row.reward_type === 2 && !row.reward_value || row.get_status === 3">
          -
        </template>
        <el-button type="text" @click="getRewardDetail(row)" v-else
          >查看奖品内容</el-button
        >
      </template>
    </ly-table>
    <!-- dialog 奖品内容 -->
    <el-dialog
      title="奖品内容"
      :visible.sync="dialogVisible"
      append-to-body
      custom-class="q-dialog"
      width="34%"
    >
      <rd-detail v-if="rewardInfo.reward_type === 4" :rewardValue="rewardInfo.reward_value"></rd-detail>
      <div class="reward" v-else>
        <div
          class="q-dialog-block q-dialog__img"
          v-if="rewardInfo.reward_type === 1"
        >
          <div v-html="rewardInfo.reward_detail"></div>
          <img :src="rewardInfo.reward_detail_img" class="reward-img" />
        </div>
        <div class="q-dialog-block" v-else-if="rewardInfo.reward_type === 2">
          <div class="reward-code">{{ rewardInfo.reward_value }}</div>
        </div>
        <div
          class="reward-list"
          v-loading="rewardLoading"
          v-else-if="rewardInfo.reward_type === 3"
        >
          <div
            class="reward-list__item"
            v-for="(i, v) in rewardDetail"
            :key="v"
          >
            <div class="title">{{ i.name }}</div>
            <div class="val">{{ i.data }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { record } from '../http.js'
import lyTable from '@/components/mixins/ly-table.js'
import rdDetail from './rdDetail.vue'
export default {
  components: {
    rdDetail
  },
  mixins: [lyTable],
  data () {
    return {
      COMM_HTTP: record,
      dialogVisible: false,
      rewardLoading: true,
      rewardInfo: {},
      rewardDetail: null,
      per_page: 5
    }
  },
  computed: {
    tableOpt () {
      return {
        levitate: false,
        layout: 'prev, pager, next',
        operationWidth: 220,
        column: [
          {
            label: '奖品名称',
            prop: 'reward_title',
            slot: 'reward_title',
            showOverflowTooltip: true
          },
          {
            label: '兑换方式',
            prop: 'reward_type', // （ 1-自定义，2-兑换码，3-表单，4-核销码）
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '自定义'
              } else if (cellValue === 2) {
                return '兑换码'
              } else if (cellValue === 3) {
                return '表单'
              } else if (cellValue === 4) {
                return '现金红包'
              }
            }
          },
          {
            label: '中奖时间',
            prop: 'created_date'
          },
          {
            label: '奖品状态',
            prop: 'get_status',
            slot: 'get_status_slot',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: null },
              { label: '未领取', value: 1 },
              { label: '已领取', value: 2 },
              { label: '未填写', value: 3 },
              { label: '已填写', value: 4 },
              { label: '发放成功', value: 5 },
              { label: '发放失败', value: 6 }
            ]
          }
        ]
      }
    },
    sendFailNum () {
      return (this.listData && this.listData.expand && this.listData.expand.send_fail_num) || 0
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.$route.query.id,
        account_id: this.$attrs.id
      }
    },
    /**
     * @description: 重新发放奖品
     * @param {*} id
     * @param {*} reward_value
     * @return {*}
     */
    async handleReSend ({ id }) {
      const res = await this.COMM_HTTP.resend({ id })
      if (res.status === 1) {
        this.$message.success('发送成功！')
        this.queryData()
      } else {
        this.$message.error(res.fail_msg)
      }
    },
    /**
     * @description: 查看奖品详情
     * @param {*} data
     * @return {*}
     */
    async getRewardDetail (data) {
      // 奖励类型 1-自定义，2-兑换码，3-表单，4-核销码
      this.rewardInfo = data
      if (data.reward_type === 3) {
        await this.fn_getFormReward(data)
      }
      this.dialogVisible = true
    },

    // 表单内容
    async fn_getFormReward (data) {
      this.rewardLoading = true
      const res = await this.COMM_HTTP.getOneDetail(
        {
          formId: data.reward_type_id,
          requestId: data.reward_value
        }
      )

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.rewardDetail = res
      this.rewardLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .xl-ly-search{
    margin-bottom: 20px!important;
  }
  .handler-left {
    color: #212b36;
  }
  .el-divider {
    top: -1px;
  }
  .fail {
    font-weight: normal;
  }
  .send-succ {
    color: #52c41a;
  }
  .send-succ,
  .send-error {
    position: relative;
    padding-left: 15px;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: #52c41a;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .send-error {
    &::before {
      background: #ed5c49;
    }
  }
  .el-icon-warning {
    font-size: 16px;
    color: #faad14;
  }
}
.reward-code {
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
  background: #d2d4d4;
  line-height: 2;
}
.reward-img {
  display: block;
  margin: 15px auto 0;
  height: 120px;
}
.el-avatar {
  background: transparent;
}
.reward {
  max-height: 50vh;
  overflow: auto;

  &-list {
    &__item {
      padding: 14px 0;
      border-bottom: 1px solid #f0f1f2;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }

      .title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .val {
        color: #6e6f73;
      }
    }
  }
}
</style>
