var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prize" },
    [
      _c(
        "ly-table",
        {
          ref: "lyTable",
          attrs: {
            data: _vm.tableData,
            option: _vm.tableOpt,
            page: _vm.page,
            pageSize: _vm.per_page,
            total: _vm.total
          },
          on: {
            searchChange: _vm.searchChange,
            sizeChange: _vm.handleSizeChange,
            currentChange: _vm.handleCurrentChange,
            selectionChange: _vm.handleSelectionChange
          },
          scopedSlots: _vm._u([
            {
              key: "reward_title_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "avatar-name" }, [
                    _c("img", {
                      staticStyle: { "margin-right": "8px" },
                      attrs: {
                        src: require("../../../../assets/svg/reward.svg"),
                        alt: ""
                      }
                    }),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(row.reward_title))
                    ])
                  ])
                ]
              }
            },
            {
              key: "notice_status_slot",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.notice_status === 0
                    ? _c("ly-status", { attrs: { type: "info" } }, [
                        _vm._v("等待发送")
                      ])
                    : row.notice_status === 1
                    ? _c("ly-status", { attrs: { type: "success" } }, [
                        _vm._v("发送成功")
                      ])
                    : [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              placement: "top-start",
                              "popper-class": "q-tooltip"
                            }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      padding: "10px 8px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " 当前用户本月接收群发消息已超过4次，如果您已手动发送了奖品消息，请变更为发送成功 "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex flex-center" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                          round: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateRewardStatus(
                                              row.id
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("变更为发送成功")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("ly-status", { attrs: { type: "danger" } }, [
                              _vm._v("发送失败")
                            ])
                          ],
                          1
                        )
                      ]
                ]
              }
            },
            {
              key: "operation",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.reward_type === 5
                    ? [
                        row.reward_value
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.getRewardDetail(row)
                                  }
                                }
                              },
                              [_vm._v("查看奖品内容")]
                            )
                          : _c("span", [_vm._v("-")])
                      ]
                    : [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.getRewardDetail(row)
                              }
                            }
                          },
                          [_vm._v("查看奖品内容")]
                        )
                      ]
                ]
              }
            }
          ])
        },
        [_vm._v(" --> ")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品内容",
            "append-to-body": "",
            visible: _vm.dialogVisible,
            "custom-class": "q-dialog",
            width: "34%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "reward" }, [
            _vm.rewardType === 1 || _vm.rewardType === 6
              ? _c("div", { staticClass: "q-dialog-block" }, [
                  _vm._v(" " + _vm._s(_vm.rewardDetail) + " ")
                ])
              : _vm.rewardType === 2
              ? _c("div", { staticClass: "q-dialog__img" }, [
                  _c("div", { staticClass: "flex flex-center" }, [
                    _c("img", { attrs: { src: _vm.rewardDetail, alt: "" } })
                  ])
                ])
              : _vm.rewardType === 3
              ? _c("div", [_vm._v(" " + _vm._s(_vm.rewardDetail) + " ")])
              : _vm.rewardType === 4
              ? _c("div", {
                  staticClass: "q-dialog-block q-dialog__img",
                  domProps: { innerHTML: _vm._s(_vm.rewardDetail) }
                })
              : _vm.rewardType === 5
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.rewardLoading,
                        expression: "rewardLoading"
                      }
                    ],
                    staticClass: "reward-list"
                  },
                  _vm._l(_vm.rewardDetail, function(i, v) {
                    return _c(
                      "div",
                      { key: v, staticClass: "reward-list__item" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(i.name))
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(_vm._s(i.data))
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }