var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp activity-list" },
    [
      _c("ly-table", {
        ref: "lyTable",
        staticClass: "act-list",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          sortChange: _vm.handleSortChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "iconfont icon-plus",
                      size: "medium"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("edit")
                      }
                    }
                  },
                  [_vm._v("创建活动")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      icon: "iconfont icon-edit",
                      size: "medium"
                    },
                    on: {
                      click: function($event) {
                        _vm.$refs.drafts.draftsVisible = true
                      }
                    }
                  },
                  [_vm._v("草稿箱(" + _vm._s(_vm.draft) + ")")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "title",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "poster-name" },
                  [
                    _c("el-image", {
                      staticClass: "poster",
                      staticStyle: { width: "36px", height: "66px" },
                      attrs: { src: row.poster_url, fit: "cover", lazy: "" }
                    }),
                    _c("span", {
                      staticClass: "aname",
                      domProps: {
                        innerHTML: _vm._s(
                          row.title.length > 8
                            ? row.title.slice(0, 8) +
                                "<br />" +
                                row.title.slice(8, row.title.length)
                            : row.title
                        )
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "stock_header",
            fn: function() {
              return [
                _c("span", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v("剩余库存")
                ]),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      placement: "top",
                      "popper-class": "q-tooltip"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " 活动库存为0时将自动暂停活动，活动暂停后，新添加用"
                      ),
                      _c("br"),
                      _vm._v(
                        "户不再自动发送活动规则和海报，同时不作为本次参与"
                      ),
                      _c("br"),
                      _vm._v(
                        "用户统计，也不统计上级邀请数量。您可以添加库存或"
                      ),
                      _c("br"),
                      _vm._v("者结束活动。 ")
                    ]),
                    _c("i", { staticClass: "iconfont icon-info-fill" })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "stock_num",
            fn: function(ref) {
              var row = ref.row
              return _vm._l(row.group_activity_task, function(item, i) {
                return _c(
                  "div",
                  { key: i, class: [item.remain === 0 ? "q-danger" : ""] },
                  [
                    item.step === 1
                      ? [
                          _vm._v(" 一阶：" + _vm._s(item.remain) + " "),
                          item.reward_type === 2 &&
                          item.remain > item.type_remain
                            ? _c("span", { staticClass: "q-danger" }, [
                                _vm._v(
                                  " （兑换码：" +
                                    _vm._s(item.type_remain) +
                                    "） "
                                )
                              ])
                            : _vm._e()
                        ]
                      : item.step === 2
                      ? [
                          _vm._v(" 二阶：" + _vm._s(item.remain) + " "),
                          item.reward_type === 2 &&
                          item.remain > item.type_remain
                            ? _c("span", { staticClass: "q-danger" }, [
                                _vm._v(
                                  " （兑换码：" +
                                    _vm._s(item.type_remain) +
                                    "） "
                                )
                              ])
                            : _vm._e()
                        ]
                      : item.step === 3
                      ? [
                          _vm._v(" 三阶：" + _vm._s(item.remain) + " "),
                          item.reward_type === 2 &&
                          item.remain > item.type_remain
                            ? _c("span", { staticClass: "q-danger" }, [
                                _vm._v(
                                  " （兑换码：" +
                                    _vm._s(item.type_remain) +
                                    "） "
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              })
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return [
                row.status === 1
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("进行中")
                    ])
                  : row.status === 2
                  ? _c("ly-status", { attrs: { type: "info" } }, [
                      _vm._v("已结束")
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "ly-action",
                  { key: Date.now() },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "analysis",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("数据分析")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "users",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("参与用户")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: row.status === 2 },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "group",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("活动群")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "iconfont icon-jiangpin", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "record",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("奖品记录")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-Code" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "popularize",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("渠道推广")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.status === 2,
                          icon: "iconfont icon-send"
                        },
                        on: {
                          click: function($event) {
                            _vm.$refs.aide.inviterData.activity_id = row.id
                            _vm.$refs.aide.inviterVisible = true
                          }
                        }
                      },
                      [_vm._v("群发助手")]
                    ),
                    _c("el-divider"),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "edit",
                              query: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-copy" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              path: "edit",
                              query: { id: row.id, edit: "copy" }
                            })
                          }
                        }
                      },
                      [_vm._v("复制")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.status === 2,
                          icon: "iconfont icon-ending"
                        },
                        on: {
                          click: function($event) {
                            return _vm.activityStop(row.id)
                          }
                        }
                      },
                      [_vm._v("结束")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "iconfont icon-delete" },
                        on: {
                          click: function($event) {
                            return _vm.activityDel(row.id, row.status)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("drafts", { ref: "drafts" }),
      _c("gs-aide", { ref: "aide", attrs: { "custom-link": false } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }