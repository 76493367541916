<template>
  <div class="mobile">
    <div class="mobile-topbar">
      <div>{{ $day().format('HH:mm')}}</div>
      <div class="flex">
        <i class="iconfont icon-a-CellularConnection"></i>
        <i class="iconfont icon-Wifi"></i>
        <i class="iconfont icon-Battery"></i>
      </div>
    </div>
    <div class="mobile-titlebar">
      <i class="iconfont icon-left1"></i>
      <div class="flex flex-column">
        <span>张三</span>
        <span style="font-size: 12px">零一裂变（深圳）科技有限公司</span>
      </div>
      <i class="el-icon-more"></i>
    </div>
    <div class="mobile-container">
      <div
        class="flex flex-column q-info text-small"
        style="line-height: 2; margin-top: 12px"
      >
        <span>您已添加了张三，现在可以开始聊天了。</span>
        <span
          >对方为企业微信用户，<span style="color: #000">了解更多。</span></span
        >
      </div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <el-input
            type="textarea"
            v-model="way_words"
            autosize
            resize="none"
            readonly
          ></el-input>
        </div>
      </div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="~@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <div class="link-preview">
            <div class="ellipsis2 link-preview__title">点击领取你的奖励👇</div>
            <div class="flex flex-between flex-center link-preview__content">
              <div class="ellipsis3" style="flex: 1">领取奖励，再接再厉！</div>
              <div class="link-preview__cover">
                <img
                  src="https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobiWay',

  computed: {
    way_words () {
      return this.$store.getters['groupfission/GET_WAY'].words
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";

.link-preview {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;

  &__content {
    align-items: flex-start;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__cover {
    height: 48px;
    font-size: 48px;
    line-height: 1;
    margin-left: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}
</style>
