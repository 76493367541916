<template>
  <div class="mobile">
    <div class="mobile-topbar">
      <div>{{ $day().format('HH:mm')}}</div>
      <div class="flex">
        <i class="iconfont icon-a-CellularConnection"></i>
        <i class="iconfont icon-Wifi"></i>
        <i class="iconfont icon-Battery"></i>
      </div>
    </div>
    <div class="mobile-titlebar">
      <i class="iconfont icon-left1"></i>
      <div class="flex flex-column">
        <span>张三</span>
        <span class="text-small">零一裂变（深圳）科技有限公司</span>
      </div>
      <i class="el-icon-more"></i>
    </div>
    <div class="mobile-container">
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <el-input
            type="textarea"
            v-model="basic.welcome_msg"
            autosize
            resize="none"
            readonly
            ref="refRule"
          ></el-input>
        </div>
      </div>
      <div class="mobile-contact">
        <div class="mobile-contact__avatar">
          <img src="@assets/images/avatar.jpg" alt="" />
        </div>
        <div class="mobile-contact__dialog">
          <div class="link-preview">
            <div class="ellipsis2 link-preview__title">
              {{
                basic.link_type === 2
                  ? basic.link_title
                  : "点击链接立即领取红包👇"
              }}
            </div>
            <div class="flex flex-between flex-center link-preview__content">
              <div class="ellipsis2" style="flex: 1; font-size: 14px">
                {{
                  basic.link_type === 2
                    ? basic.link_desc
                    : "红包实时到账微信零钱"
                }}
              </div>
              <div class="link-preview__cover">
                <template v-if="basic.link_type === 2">
                  <img
                    v-if="basic.link_logo.length > 0"
                    :src="basic.link_logo[0].url"
                    alt=""
                  />
                </template>
                <img
                  v-else
                  src="https://image.01lb.com.cn//uploads/wecom/21/1008/1633687472W18cbF1A.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobiBasic',

  computed: {
    basic () {
      return this.$store.state.rede.basic
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mobile.scss";

.link-preview {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;

  &__content {
    align-items: flex-start;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__cover {
    width: 48px;
    height: 48px;
    font-size: 48px;
    line-height: 1;
    margin-left: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}
.mobile-container {
  height: calc(613.64px - 64px);
  overflow: auto;
}
</style>
