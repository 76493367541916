var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "act" },
    [
      _c("el-main", [
        _c(
          "div",
          { staticClass: "act-tabs" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.fn_setTab },
                model: {
                  value: _vm.tabKey,
                  callback: function($$v) {
                    _vm.tabKey = $$v
                  },
                  expression: "tabKey"
                }
              },
              _vm._l(
                _vm.cards.filter(function(item) {
                  return !item.hidden
                }),
                function(item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.label, name: item.name }
                  })
                }
              ),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "act-main" },
          [
            _c(
              "el-scrollbar",
              { ref: "main", staticClass: "act-main__left" },
              [
                _vm._l(
                  _vm.cards.filter(function(item) {
                    return !item.hidden
                  }),
                  function(item, index) {
                    return [
                      _c(
                        "act-card",
                        {
                          key: index,
                          ref: item.name,
                          refInFor: true,
                          attrs: { title: item.label }
                        },
                        [_vm._t(item.name)],
                        2
                      )
                    ]
                  }
                )
              ],
              2
            ),
            _c("el-scrollbar", { staticClass: "act-main__right" }, [
              _c("div", { staticClass: "act-mobile" }, [_vm._t("mobile")], 2)
            ])
          ],
          1
        )
      ]),
      _c("el-footer", { staticClass: "act-footer" }, [_vm._t("footer")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }