var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vlog" }, [
    _c(
      "div",
      { staticClass: "vlog-cover" },
      [
        _c(
          "el-image",
          {
            staticClass: "mcard-img",
            attrs: { src: _vm.info.thumburl_1, lazy: "" }
          },
          [
            _c("img", {
              staticClass: "mcard-imgerror",
              attrs: {
                slot: "error",
                src: require("@/assets/images/vlog_default.png"),
                alt: ""
              },
              slot: "error"
            })
          ]
        ),
        _c("i", { staticClass: "iconfont icon-play" })
      ],
      1
    ),
    _c("div", { staticClass: "vlog-title" }, [
      _c("img", {
        staticClass: "vlog-icon",
        attrs: { src: _vm.info.icon, alt: "" }
      }),
      _c("span", [_vm._v(" " + _vm._s(_vm.info.title) + " ")])
    ]),
    _c("div", { staticClass: "vlog-desc" }, [
      _vm._v(" " + _vm._s(_vm.info.des) + " ")
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vlog-footer" }, [
      _c("i", { staticClass: "iconfont icon-shipinhao q-warning" }),
      _vm._v("视频号 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }