<template>
  <div class="rd-users">
    <div class="select-box" style="padding: 0 20px">
      <label style="color: #212b36">选择活动：</label>
      <el-select v-model="activity_id" filterable style="width: 320px" @change="changeActivity">
        <el-option v-for="item in activityList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <user-pane v-if="activeName === 'users'" ref="userPane" :activity_id="activity_id" :activities="activityList"
      type="1" :channelOps="channelOps" @detail="fn_openDetail" />
    <!-- <el-tabs v-model="activeName" type="card" class="ly-tabs user-list">
      <el-tab-pane label="参与用户" name="users">
        <user-pane
          v-if="activeName === 'users'"
          ref="userPane"
          :activity_id="activity_id"
          :activities="activityList"
          type="1"
          :channelOps="channelOps"
          @detail="fn_openDetail"
        />
      </el-tab-pane>
      <el-tab-pane label="黑名单" name="blacklist">
        <user-pane
          v-if="activeName === 'blacklist'"
          ref="userPane"
          :activity_id="activity_id"
          :activities="activityList"
          type="2"
          :channelOps="channelOps"
          @detail="fn_openDetail"
        />
      </el-tab-pane>
    </el-tabs> -->
    <user-detail ref="userDetailRef" :userInfo="detailInfo"></user-detail>
  </div>
</template>

<script>
import mx_role from '@/components/mixins/role'
import lyTable from '@/components/mixins/ly-table.js'
import userPane from './components/userPane'
import userDetail from './components/userDetail'
import { activity, user } from './http'
export default {
  mixins: [lyTable, mx_role],
  components: {
    userPane,
    userDetail
  },
  data () {
    return {
      COMM_HTTP: user,
      activity_id: '',
      activityList: [],
      activeName: 'users',
      channelOps: [{ label: '全部', value: '' }],
      detailInfo: {}
    }
  },
  async mounted () {
    this.activity_id = this.$route.query.id * 1
    this.actList()
    this.channelSel()
  },
  methods: {

    // 获取活动列表
    async actList () {
      const data = await activity.reqList()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        this.reload = false
        return
      }

      if (data) {
        this.activityList = data.map((i, v) => {
          return {
            value: i.id,
            label: i.title
          }
        })
      } else {
        this.activityList = []
      }
      return true
    },

    // 当前活动id
    getId () {
      const _id = this.$route.query.id
      if (_id) {
        this.activity_id = +_id
      } else if (this.activityList.length > 0) {
        this.activity_id = this.activityList[0].value
        for (let i = 0; i < this.activityList.length; i++) {
          if (+_id === +this.activityList[i].value) {
            this.activityValue = this.activityList[i].label
          }
        }
      }
    },

    // 获取渠道选项
    channelSel () {
      this.COMM_HTTP.channelSel({
        book_lottery_id: this.activity_id,
        page: 1,
        per_page: 1000
      }).then(res => {
        if ((res && res.code) || (res && res.code === 0)) {
          this.$message.error(res.msg)
          return
        }
        console.log('res', res)
        this.channelOps = [{ label: '全部', value: '' }]
        const result = res.list || []
        result.map(e => {
          this.channelOps.push({
            label: e.title,
            value: e.id
          })
        })
      })
    },

    // 切换活动
    changeActivity (val) {
      this.$router.replace({ path: this.$route.path, query: { id: this.activity_id } })
      this.$nextTick(() => {
        this.channelSel()
        this.$refs.userPane.queryData()
      })
    },

    // 获取活动名称
    getFileName () {
      let _name = ''
      const _obj = this.activityList.find(n => {
        return +n.value === +this.activity_id
      })
      _name = `[${_obj.label}]-参与用户-${this.$day(new Date()).format('YYYY年MM月DD日HH时mm分ss秒')}`
      return _name
    },

    // 渠道列表
    async fn_getChannelList () {
      const data = await this.axios.get('taskChannel', {
        params: {
          page: 1,
          per_page: 9999,
          activity_id: this.activity_id
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        return false
      }

      this.channelList = [
        {
          value: null,
          label: '全部'
        }
      ]

      if (data.data) {
        data.data.map((i, v) => {
          this.channelList.push({
            value: i.id,
            label: i.channel_name
          })
        })
      }
    },

    // 查看详情
    fn_openDetail (row) {
      this.detailInfo = row
      this.$refs.userDetailRef.visible = true
      this.$refs.userDetailRef.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.rd-users {
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .xl-ly-table {
    width: 100%;
  }

  .ly-tabs::after {
    content: unset;
  }
}

.select-box {
  display: flex;
  align-items: center;
  margin: 20px 0;

  .el-select {
    width: 353px;
  }
}

.rd-users {
  ::v-deep {
    .ly-table-main {
      padding-top: 20px;
    }
  }
}

.user-list {
  flex: 1;
  display: flex;
  flex-direction: column;

  ::v-deep {
    .el-tabs__header {
      background: #fff;
      padding: 20px 20px 0;
      margin: 0 20px;
      border-radius: 16px 16px 0 0;
      box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24),
        0px 0px 2px 0px rgba(145, 158, 171, 0.24);
    }

    .el-tabs__header {
      position: relative;
      overflow: unset;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 40px;
        background: #fff;
        position: absolute;
        bottom: -1px;
      }

      &::before {
        left: 0px;
      }

      &::after {
        right: 0px;
      }
    }

    .el-tabs__content {
      flex: 1;

      .el-tab-pane {
        min-height: 100%;
        display: flex;
      }
    }

    .ly-table-main {
      margin: 0 20px 80px;
    }

    .xl-ly-search {
      margin: 0 20px 20px;
      border-radius: 0 0 16px 16px;
    }
  }
}

::v-deep {
  .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: unset;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item:first-child {
    border-left: 1px solid #e4e7ed;
  }

  .el-tabs__item {
    border: 1px solid #e4e7ed;
    height: 40px;
    padding: 0 !important;
    width: 88px;
    text-align: center;
    line-height: 40px;
    margin-right: 4px;
    background: rgba(0, 0, 0, 0.02);

    &.is-active {
      background: #fff;
    }
  }

  .add_account {
    display: inline-block;
    padding: 0 5px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    // height: 22px;
    line-height: 22px;
    margin: 0 5px 5px 0;
  }

  .el-icon-s-custom,
  .qy-icon-organization {
    color: rgb(51 139 255 / 60%);
  }

  .el-icon-success {
    color: #52c41a;
    margin-right: 5px;
  }

  .el-icon-error {
    color: #f55945;
    margin-right: 5px;
  }

  .step {
    &__item {
      display: flex;
    }

    &__line {
      position: relative;
      width: 20px;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #f2f3f5;
        transform: translate(-50%);
      }

      .circle {
        position: absolute;
        left: 50%;
        top: 50%;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.25);
        transform: translate(-50%, -50%);
        z-index: 1;

        &.success {
          background-color: #52c41a;
        }
      }
    }
  }
}
</style>
