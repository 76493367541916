var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "form-style" },
    [
      _c(
        "el-container",
        { staticClass: "fm2-container" },
        [
          _c(
            "el-main",
            { staticClass: "fm2-main" },
            [
              _c(
                "el-container",
                [
                  _c("el-aside", { attrs: { width: "288px" } }, [
                    _c(
                      "div",
                      { staticClass: "components-list" },
                      [
                        _c("div", { staticClass: "normalTable-cate" }, [
                          _vm._v("常用组件")
                        ]),
                        _c(
                          "draggable",
                          _vm._b(
                            {
                              attrs: {
                                tag: "ul",
                                list: _vm.useCom,
                                move: _vm.handleMove
                              },
                              on: { end: _vm.MoveEndFn, start: _vm.MoveStartFn }
                            },
                            "draggable",
                            {
                              group: {
                                name: "formItem",
                                pull: "clone",
                                put: false
                              },
                              sort: false,
                              ghostClass: "handleIcon"
                            },
                            false
                          ),
                          _vm._l(_vm.useCom, function(item, index) {
                            return _c(
                              "el-button",
                              {
                                key: index,
                                staticClass: "form-edit-normalTable-label",
                                on: {
                                  click: function($event) {
                                    return _vm.handleClick(item)
                                  }
                                }
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { iconClass: item.svgIcon }
                                }),
                                _c("div", [_vm._v(_vm._s(item.name))])
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        [
                          _c("div", { staticClass: "normalTable-cate" }, [
                            _vm._v("通用组件")
                          ]),
                          _c(
                            "draggable",
                            _vm._b(
                              {
                                attrs: {
                                  tag: "ul",
                                  list: _vm.currencyCom,
                                  move: _vm.handleMove
                                },
                                on: {
                                  end: _vm.MoveEndFn,
                                  start: _vm.MoveStartFn
                                }
                              },
                              "draggable",
                              {
                                group: {
                                  name: "formItem",
                                  pull: "clone",
                                  put: false
                                },
                                sort: false,
                                ghostClass: "handleIcon"
                              },
                              false
                            ),
                            _vm._l(_vm.currencyCom, function(item, index) {
                              return _c(
                                "el-button",
                                {
                                  key: index,
                                  staticClass: "form-edit-normalTable-label",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleClick(item)
                                    }
                                  }
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { iconClass: item.svgIcon }
                                  }),
                                  _c("div", [_vm._v(_vm._s(item.name))])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ]
                      ],
                      2
                    )
                  ]),
                  _c(
                    "el-container",
                    {
                      staticClass: "center-container",
                      attrs: { direction: "vertical" }
                    },
                    [
                      _c(
                        "el-main",
                        {
                          class: {
                            "normalTable-empty":
                              _vm.normalTableForm.list.length == 0
                          }
                        },
                        [
                          _c("normalTable-form", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.isEndLoading,
                                expression: "isEndLoading"
                              }
                            ],
                            ref: "normalTableForm",
                            attrs: {
                              data: _vm.normalTableForm,
                              select: _vm.normalTableFormSelect,
                              formName: _vm.formName,
                              selectParentIndex: _vm.selectParentIndex
                            },
                            on: {
                              "update:select": function($event) {
                                _vm.normalTableFormSelect = $event
                              },
                              "update:selectParentIndex": function($event) {
                                _vm.selectParentIndex = $event
                              },
                              "update:select-parent-index": function($event) {
                                _vm.selectParentIndex = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-aside",
                    {
                      staticClass: "normalTable-config-container",
                      attrs: { width: "320px" }
                    },
                    [
                      _c(
                        "el-container",
                        [
                          _c("el-header", { attrs: { height: "54px" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "config-tab",
                                class: {
                                  active: _vm.configTab == "normalTable"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleNavUpdate("normalTable")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.formTableComTitle) + " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "config-tab",
                                class: { active: _vm.configTab == "form" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleNavUpdate("form")
                                  }
                                }
                              },
                              [_vm._v(" 表单设置 ")]
                            )
                          ]),
                          _c(
                            "el-main",
                            { staticClass: "config-content" },
                            [
                              _c("normalTable-config", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.configTab == "normalTable",
                                    expression: "configTab == 'normalTable'"
                                  }
                                ],
                                attrs: { data: _vm.normalTableFormSelect }
                              }),
                              _c("form-config", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.configTab == "form",
                                    expression: "configTab == 'form'"
                                  }
                                ],
                                ref: "formConfig",
                                model: {
                                  value: _vm.formName,
                                  callback: function($$v) {
                                    _vm.formName =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "formName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "edit-footer",
              staticStyle: { "justify-content": "space-between" }
            },
            [
              _c("div", {
                staticClass: "flex flex-justify-start text-regular",
                staticStyle: { flex: "1.2" }
              }),
              _c(
                "div",
                {
                  staticClass: "flex flex-center",
                  staticStyle: { flex: "0.7" }
                },
                [
                  _vm.isShowDrafts
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            round: "",
                            loading: _vm.btnLoading,
                            icon: "icon-edit iconfont"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleSave(0)
                            }
                          }
                        },
                        [_vm._v(" 保存草稿 ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { round: "", icon: "iconfont icon-yulan" },
                      on: { click: _vm.handlePreview }
                    },
                    [_vm._v(" 预览 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        round: "",
                        icon: "iconfont icon-send-fill",
                        type: "primary",
                        loading: _vm.submitLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleSave(1)
                        }
                      }
                    },
                    [_vm._v(" 发布表单 ")]
                  )
                ],
                1
              ),
              _c("div", { staticStyle: { flex: "1.2" } })
            ]
          )
        ],
        1
      ),
      _c(
        "mobile-dialog",
        {
          ref: "normalTablePreview",
          staticClass: "dialogWrapper",
          attrs: {
            visible: _vm.previewVisible,
            width: "423px",
            form: "",
            title: "预览"
          },
          on: {
            "on-close": function($event) {
              _vm.previewVisible = false
            }
          }
        },
        [
          _vm.previewVisible
            ? _c("normal-form", {
                attrs: { data: _vm.normalTableForm, formName: _vm.formName }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }