var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp key-word" },
    [
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "temp",
            fn: function() {
              return [
                _c("div", { staticClass: "top-tips" }, [
                  _vm._v(" 添加关键词后，可以统计群内回复关键词的人数和次数。 ")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      size: "medium",
                      icon: "iconfont icon-plus"
                    },
                    on: { click: _vm.handleAddKey }
                  },
                  [_vm._v("添加关键词")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("add-word", { ref: "addWordRef", on: { change: _vm.queryData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }