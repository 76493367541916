<!--
 * @Author: zhan
 * @Date: 2022-05-19 15:38:51
 * @LastEditTime: 2022-08-23 11:33:04
 * @LastEditors: zhan
-->
<template>
  <div class="edit-footer">
    <el-button type="primary" round :loading="saveLoading" :icon="isEnd ? '' : 'iconfont icon-send-fill'"
      @click="fn_save(1)" :disabled="isEnd || draftLoading">{{ isEnd ? "活动已结束" : "发布活动" }}</el-button>
    <el-button round icon="iconfont icon-edit" v-if="eType !== 1" @click="fn_save(2)" :loading="draftLoading"
      :disabled="type === 1 && saveLoading">保存草稿
    </el-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {

  props: {
    saveLoading: {
      type: Boolean,
      default: false
    },
    draftLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      eType: state => state.reserveTask.eType
    }),
    isEnd () {
      return this.eType === 1 && this.$store.state.reserveTask.isEnd
    }
  },

  data () {
    return {
      type: null
    }
  },

  methods: {
    fn_save (type) {
      this.type = type

      if (type === 1) {
        this.$emit('submit')
        return
      }

      this.$emit('draft')
    }
  }
}
</script>
