<template>
  <div class="edit-card" v-loading="pageLoading">
    <div class="edit-card__header flex flex-between">
      <div class="title">{{ title }}</div>
      <div class="amount">
        账户可用余额：<span class="num">{{
          redForm.account.account_amount_fmt
        }}</span>
        <el-button type="text" @click="$refs.recharge.rechargeVisible = true"
          >立即充值</el-button
        >
      </div>
    </div>

    <div class="edit-card__content">
      <el-form
        :model="redForm"
        ref="redFormRef"
        :rules="rules"
        label-width="150px"
        style="width: 100%; max-width: 760px"
        size="medium"
      >
        <!-- 活动金额 -->
        <el-form-item label="活动金额" prop="activity_amount">
          <el-input-number
            v-model="redForm.activity_amount"
            controls-position="right"
            :min="redForm.give_amount"
            :precision="2"
            @change="fn_setRede('activity_amount', $event)"
            @blur="fn_validAmount($event)"
            style="width: 120px"
          ></el-input-number>
          <div v-if="!isCopy && isEdit && status === 1" style="margin-top: 8px">
            已发放金额：<span class="q-primary" style="margin-right: 16px">{{
              give_amount
            }}</span>
            剩余活动金额：<span class="q-primary">{{ remain }}</span>
            <span
              :class="[calculate >= 0 ? 'q-success' : 'q-danger']"
              v-if="calculate !== 0"
              >（{{ calculate >= 0 ? "+" : ""
              }}{{ calculate.toFixed(2) }}）</span
            >
          </div>
          <p
            class="is-primary text-small"
            style="line-height: 24px; margin: 8px 0 0; color: #6e788a"
          >
            1. 活动发布后，暂时从红包账户内冻结对应的活动金额。<br />
            2. 活动发布后，活动金额支持修改。<br />
            3. 每次向用户发放红包，平台收取{{
              redForm.account.fee_rate
            }}%手续费，手续费不足0.01元时，按0.01元计算。<br />
            4. 活动金额发放完后，活动自动结束。
          </p>
          <template v-slot:error="{ error }">
            <div :class="['el-form-item__error', 'customErrorClass']">
              {{ error }}
            </div>
          </template>
        </el-form-item>

        <!-- 虚拟人均获得 -->
        <el-form-item label="虚拟人均获得" required>
          <el-input-number
            v-model="redForm.virtual_average"
            controls-position="right"
            :min="0"
            :max="999"
            :precision="2"
            @change="fn_setRede('virtual_average', $event)"
            style="width: 120px"
          ></el-input-number>
        </el-form-item>

        <!-- 红包规则 -->
        <el-form-item label="红包规则" required>
          <div class="q-bg is-default rules">
            <div>
              用户首拆红包，领取
              <el-input-number
                v-model="redForm.first_give_min"
                size="small"
                controls-position="right"
                :min="0.31"
                :max="redForm.first_give_max"
                :precision="2"
                class="number-small"
                :disabled="!redForm.activity_amount"
                @change="fn_setRede('first_give_min', $event)"
              ></el-input-number>
              ~
              <el-input-number
                v-model="redForm.first_give_max"
                size="small"
                controls-position="right"
                :min="redForm.first_give_min"
                :max="redmax"
                :precision="2"
                class="number-small"
                :disabled="!redForm.activity_amount"
                @change="fn_setRede('first_give_max', $event)"
              ></el-input-number>
            </div>
            <div style="margin-top: 4px">
              <el-switch
                v-model="redForm.is_give_old_user"
                :active-value="1"
                :inactive-value="0"
                active-text="企业老用户也享有首拆"
                :disabled="ruleDisable"
                @change="fn_setRede('is_give_old_user', $event)"
              ></el-switch>
            </div>
          </div>
          <div class="q-bg is-default rules">
            <div>
              每邀请
              <el-input-number
                v-model="redForm.invite_num"
                size="small"
                controls-position="right"
                :min="1"
                :max="99"
                :precision="0"
                class="number-small"
                :disabled="ruleDisable"
                @change="fn_setRede('invite_num', $event)"
              ></el-input-number>
              人，领取
              <el-input-number
                v-model="redForm.give_min"
                size="small"
                controls-position="right"
                :min="0.31"
                :max="redForm.give_max"
                :precision="2"
                class="number-small"
                :disabled="!redForm.activity_amount"
                @change="fn_setRede('give_min', $event)"
              ></el-input-number>
              ~
              <el-input-number
                v-model="redForm.give_max"
                size="small"
                controls-position="right"
                :min="redForm.give_min"
                :max="redmax"
                :precision="2"
                class="number-small"
                :disabled="!redForm.activity_amount"
                @change="fn_setRede('give_max', $event)"
              ></el-input-number>
              元
            </div>
            <div style="margin-top: 12px">
              每人单场活动最多领取
              <el-input-number
                v-model="redForm.give_limit"
                size="small"
                controls-position="right"
                :min="1"
                :max="99"
                :precision="0"
                class="number-small"
                :disabled="ruleDisable"
                @change="fn_setRede('give_limit', $event)"
              ></el-input-number>
              个红包
            </div>
          </div>
          <div
            class="q-danger text-small"
            style="line-height: 24px; margin-top: 8px"
          >
            *
            发布活动后，老用户是否享有首拆、邀请人数、红包最多领取个数不允许修改
          </div>
        </el-form-item>

        <!-- 自动打标签 -->
        <el-form-item label="自动打标签">
          <div class="flex" style="height: 36px">
            <el-switch
              v-model="redForm.is_auto_tags"
              :active-value="1"
              :inactive-value="0"
              active-text="开启后，可自动为活动用户打标签"
              @change="fn_setRede('is_auto_tags', $event)"
            ></el-switch>
          </div>
          <div v-show="redForm.is_auto_tags">
            <!-- 新增客户自动打标签 -->
            <div>为本次活动新增客户自动打标签</div>
            <tag-select-v2
              @getTagId="fn_setTags(addTags.key, $event)"
              :checked="addTags.tags"
              class="tag-select"
            >
            </tag-select-v2>

            <!-- 按邀请人数自动为客户打标签 -->
            <div style="margin-top: 16px">按邀请人数自动为客户打标签</div>
            <div class="border-temp">
              <template v-for="(item, index) of inviteTags">
                <tag-select-v2
                  @getTagId="fn_setTags(item.key, $event)"
                  :checked="item.tags"
                  class="tag-select"
                  :key="item.key"
                >
                  <template #prefix>
                    邀请人数为
                    <el-input-number
                      v-model="item.invite_num"
                      size="small"
                      controls-position="right"
                      :min="1"
                      :max="999"
                      :precision="0"
                      class="number-small number-mn"
                      @change="fn_setTagsNum(item.key, $event)"
                    ></el-input-number>
                    时，自动打标签
                  </template>
                  <template #suffix>
                    <el-button
                      icon="el-icon-minus"
                      circle
                      size="mini"
                      type="danger"
                      class="tag-minus"
                      :disabled="inviteTags.length === 1"
                      @click="fn_removeInviteTag(index)"
                    ></el-button>
                  </template>
                </tag-select-v2>
              </template>
            </div>
            <div class="tag-select__add">
              <el-tooltip
                effect="dark"
                content="最多可设置10条规则"
                placement="top-start"
                v-if="inviteTags.length >= 10"
              >
                <span>
                  <el-button icon="iconfont icon-plus-fill" type="text" disabled
                    ><i class="iconfont icon-plus-fill"></i>
                    新增邀请人数设置</el-button
                  >
                </span>
              </el-tooltip>
              <el-button
                icon="iconfont icon-plus-fill"
                type="text"
                @click="fn_addInviteTag"
                v-else
                >新增邀请人数设置</el-button
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 充值 -->
    <rd-recharge ref="recharge" @success="fn_paySuccess" />
  </div>
</template>

<script>
import rdRecharge from '@/components/LyRecharge'
import tagSelectV2 from '@/components/wecomTags'
export default {

  props: {
    title: {
      type: String,
      defualt: ''
    }
  },

  data () {
    const checkCount = (rules, value, callback) => {
      if (!value || value === 0 || value === '') {
        callback(new Error('请输入活动金额'))
      } else if (value < this.minAmount) {
        callback(new Error('活动金额须大于等于' + this.minAmount + '元'))
      } else if (value > this.redForm.account.max_amount) {
        if (this.msgBox) {
          this.msgBox.close()
        }
        this.msgBox = this.$message.info('账户余额不足，已为您设置为最大可用金额')
        this.fn_setRede('activity_amount', this.redForm.account.max_amount)
      } else {
        callback()
      }
    }
    return {
      rules: {
        activity_amount: [
          { validator: checkCount, required: true, trigger: 'change' }
        ]
      },
      amount: null,
      pageLoading: true,
      get_user: [0, 0],
      get_user_amount: [0, 0],
      calculate: 0,
      remain: null,
      addTags: null,
      inviteTags: [],
      minAmount: null,
      maxAmount: 1,
      msgBox: null,
      redmax: 5
    }
  },

  computed: {
    isEdit () {
      return this.$store.state.rede.isEdit
    },
    isCopy () {
      return this.$store.state.rede.isCopy
    },
    status () {
      return this.$store.state.rede.status
    },
    account_amount () {
      return this.$store.state.rede.account_amount
    },
    account_amount_fmt () {
      return this.$store.state.rede.account_amount_fmt
    },
    redForm () {
      const REDENVELOPE = this.$store.state.rede.redenvelope
      return REDENVELOPE
    },
    ruleDisable () {
      const valid = (this.isEdit && this.status === 1 && !this.isCopy) || !this.redForm.activity_amount || this.redForm.activity_amount < this.minAmount
      return valid
    }
  },

  watch: {
    redForm: {
      handler (val) {
        // 领取红包最大值限制，红包金额 - 已发放
        this.give_amount = (val.give_amount).toFixed(2)
        if (val.activity_amount && !this.remain) {
          this.remain = (val.activity_amount - val.give_amount).toFixed(2)
        }
        if (!this.amount) {
          this.amount = val.activity_amount
        } else {
          if (val.activity_amount) {
            this.calculate = (val.activity_amount - val.give_amount) - (this.amount - val.give_amount)
          }
        }
        this.get_user[0] = (val.activity_amount / (val.give_min * (val.invite_num + 1))).toFixed(2)
        this.get_user[1] = (val.activity_amount / (val.give_max * (val.invite_num + 1))).toFixed(2)
        this.get_user_amount[0] = (val.give_min * (val.invite_num + 1)).toFixed(2)
        this.get_user_amount[1] = (val.give_max * (val.invite_num + 1)).toFixed(2)
        this.minAmount = Math.max(val.first_give_max, val.give_max)
        this.fn_getTags(val.auto_tags)
        this.pageLoading = false
      },
      deep: true,
      immediate: true
    },
    'redForm.first_give_max' (val) {
      if (val) {
        this.$refs.redFormRef.validateField('activity_amount')
      }
    },
    'redForm.give_max' (val) {
      if (val) {
        this.$refs.redFormRef.validateField('activity_amount')
      }
    }
  },

  mounted () {
    this.$refs.redFormRef.validateField('activity_amount')
  },

  methods: {

    // 充值成功
    fn_paySuccess () {
      this.$refs.recharge.rechargeVisible = false
      this.fn_getAmount()
    },

    // 获取账户余额
    async fn_getAmount () {
      const data = await this.axios.get('redPacketInfo')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      const account = {
        account_amount: (data.available_amount * 1000 - data.freeze_amount * 1000) / 1000,
        account_amount_fmt: this.fn_toThousandFilter(data.available_amount - data.freeze_amount)
      }

      if (this.isEdit && !this.isCopy && this.status === 1) {
        account.max_amount = account.account_amount + this.redForm.amount
      } else {
        account.max_amount = account.account_amount
      }

      const REDE = this.$store.state.rede.redenvelope
      REDE.account = account
      this.$store.commit('_SET', {
        'rede.redenvelope': REDE
      })
    },

    // 设置余额格式
    fn_toThousandFilter (num) {
      return (+num || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
    },

    // 获取新增客户标签
    fn_getTags (data) {
      const _inviteTags = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].type === 1) {
          this.addTags = data[i]
        } else {
          _inviteTags.push(data[i])
        }
      }
      this.inviteTags = _inviteTags
    },

    fn_setTags (key, tags) {
      const REDE = this.$store.state.rede.redenvelope
      const TAGS = REDE.auto_tags
      for (let i = 0; i < TAGS.length; i++) {
        if (TAGS[i].key === key) {
          if (tags) {
            TAGS[i].tags = tags.join(',')
          } else {
            TAGS[i].tags = ''
          }
        }
      }
      this.fn_setRede('auto_tags', TAGS)
    },

    fn_setTagsNum (key, num) {
      const REDE = this.$store.state.rede.redenvelope
      const TAGS = REDE.auto_tags
      for (let i = 0; i < TAGS.length; i++) {
        if (TAGS[i].key === key) {
          TAGS[i].invite_num = num
        }
      }
      this.fn_setRede('auto_tags', TAGS)
    },

    // 添加邀请人数标签行
    fn_addInviteTag () {
      const REDE = this.$store.state.rede.redenvelope
      const TAGS = REDE.auto_tags
      const MAX = Math.max.apply(Math, TAGS.map(item => { return item.key }))
      const newTagArr = {
        id: 0,
        key: MAX + 1,
        invite_num: 2,
        tags: '',
        type: 2
      }
      TAGS.push(newTagArr)
      this.fn_setRede('auto_tags', TAGS)
    },

    // 删除邀请人数标签行
    fn_removeInviteTag (index) {
      this.$lyConfirm({
        title: '确认删除此条设置吗？',
        text: ''
      }).then(() => {
        const REDE = this.$store.state.rede.redenvelope
        const TAGS = REDE.auto_tags
        TAGS.splice(index + 1, 1)
        this.fn_setRede('auto_tags', TAGS)
      }).catch(() => { })
    },

    // 设置红包信息
    fn_setRede (type, data) {
      const REDE = this.$store.state.rede.redenvelope
      REDE[type] = data
      this.$store.commit('_SET', {
        'rede.redenvelope': REDE
      })
    },

    // 验证活动金额
    fn_validAmount (event) {
      this.$refs.redFormRef.validateField('activity_amount')
    },

    // 表单验证
    verify () {
      let formValid = false
      this.$refs.redFormRef.validate((valid) => {
        if (valid) {
          formValid = true
        } else {
          formValid = false
        }
      })

      return formValid
    }
  },

  components: {
    tagSelectV2,
    rdRecharge
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/edit.scss";

.amount {
  font-size: 16px;
  color: #212b36;
  font-weight: 400;

  .num {
    margin-right: 16px;
  }

  .el-button {
    font-size: 16px;
  }
}

.rules {
  line-height: inherit;
  padding: 12px 20px;

  & + & {
    margin-top: 8px;
  }
}

.number-small {
  width: 120px;
  margin: 0 4px;
  ::v-deep .el-input__inner {
    padding-left: 4px;
    padding-right: 36px;
  }
  ::v-deep {
    .el-input-number__decrease,
    .el-input-number__increase {
      height: 15px;
    }
  }
}

.number-mn {
  width: 100px;
}

.q-bg {
  ::v-deep.el-icon-arrow-up,
  ::v-deep.el-icon-arrow-down {
    margin-right: 0;
  }
}

.tag {
  &-select {
    margin: 10px 0;
    ::v-deep .tags-btn {
      padding: 10px 16px;
    }
  }
  &-select__add {
    ::v-deep {
      .icon-plus-fill {
        font-size: 18px;
      }
      .el-button span {
        vertical-align: text-top;
      }
    }
  }
  &-minus {
    padding: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
  }
}

.customErrorClass {
  left: 130px;
  top: 0;
  padding-top: 0;
  line-height: 36px;
}
.border-temp {
  border: 1px solid #dfe1e8;
  border-radius: 8px;
  padding: 0 10px;
}
</style>
