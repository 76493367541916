<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-14 10:28:24
 * @LastEditTime: 2021-12-14 00:28:29
-->
<template>
  <el-dialog title="分组" :visible.sync="visible" width="420px">
    <div class="selected">
      已选择<span style="color: #2B7CFF"> ({{ selected.length }}) </span>个群
    </div>
    <div class="group-list">
      <el-scrollbar v-if="groupList.length">
        <el-checkbox-group v-model="group">
          <el-checkbox
            :label="item.id"
            v-for="item in groupList"
            :key="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-scrollbar>
      <div v-else class="no-group flex flex-column flex-center">
        <img src="@assets/svg/default/no_data.svg" alt="" />
        <div class="desc">暂无分组</div>
        <!-- <el-button round icon="iconfont icon-plus" type="primary" @click="handleAddGroup"
          >添加分组</el-button
        > -->
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="groupList.length">
      <el-button round type="primary" size="medium" @click="handleDoGroup"
        >确定分组</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { groupManagement } from '../http'
export default {
  data () {
    return {
      visible: false,
      group: []
    }
  },
  computed: {
    selected () {
      return this.$parent.selected
    },
    groupList () {
      return this.$parent.groupList.filter(e => {
        return e.id > 0
      })
    }
  },
  methods: {
    // handleChange (v) {
    //   console.log(v)
    //   this.group = [v[v.length - 1]]
    // },
    handleAddGroup () {
      this.visible = false
      this.$emit('showGroupPopover')
    },
    async handleDoGroup () {
      if (this.group.length === 0) {
        this.fn_lyMsg('info', '请选择分组！')
        return false
      }
      const group_ids = this.selected.map(e => {
        return e.id
      })
      const params = { topic_ids: this.group, type: 0, group_ids }
      const res = await groupManagement.doGroup(params)

      if ((res && res.code) || (res && res.code === 0)) {
        this.$message.error(res.msg)
        return false
      }

      this.$message.success('分组成功!')
      this.visible = false
      this.group = []
      this.$emit('change')
    }
  }
}
</script>
<style lang="scss" scoped>
.selected {
  color: #212b36;
  font-size: 16px;
}
.group-list {
  margin: 8px 0;
  .el-scrollbar {
    height: 280px;
  }
  .desc {
    margin: 10px 0;
    color: #6E788A;
  }
  .el-checkbox {
    margin: 14px 0;
  }
}
.no-group {
  padding-top: 20px;
  .el-button {
    margin-bottom: 90px;
  }
}
::v-deep {
  .el-checkbox {
    display: flex;
    align-items: center;
  }
  .el-checkbox__label {
    max-width: 285px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
