<template>
  <el-dialog
    title="添加消息"
    :visible.sync="visible"
    custom-class="add-content"
    width="560px"
    @open="handleOpen"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="text">
        <template slot="label">
          <el-tooltip
            v-if="hasText"
            effect="dark"
            content="因企微官方限制，文本消息仅可发一条"
            placement="top-start"
          >
            <span style="cursor: not-allowed; color: #c0c4cc" @click.stop
              >文本</span
            >
          </el-tooltip>
          <span v-else>文本</span>
        </template>
        <ly-editor
          title="客服欢迎语"
          id="basicCustomerWel"
          :content="text.welcomeContent"
          :recoverContent="defaultContent"
          :error="text.welcomeContent === ''"
          :insetMenu="['用户昵称']"
          :emoji="true"
          :maxLength="800"
          @getContent="fn_getCusWelWords"
        ></ly-editor>
        <transition name="fade">
          <div class="error-desc" v-if="!text.welcomeContent">
            请输入文本消息
          </div>
        </transition>
      </el-tab-pane>
      <el-tab-pane label="图片" name="pic" class="content">
        <el-upload
          class="uploader"
          ref="pic"
          action="#"
          accept=".png, .jpg"
          :http-request="(opts) => requestQiniu(opts, 'pic')"
          :limit="1"
          :show-file-list="false"
        >
          <div v-if="pic.imageUrl" class="thum">
            <img :src="pic.imageUrl" class="img" />
            <i class="el-icon-closeplus" @click.stop="handleRemove('pic')"
              ><i class="el-icon-close"></i
            ></i>
          </div>
          <template v-else>
            <div class="upload-box flex">
              <i class="iconfont icon-plus avatar-uploader-icon"></i>
              <span>上传图片</span>
            </div>
            <div class="desc" @click.stop>支持JPG、PNG格式，图片小于2M</div>
          </template>
        </el-upload>
        <transition name="fade">
          <div class="error-desc" v-if="picError">请上传图片</div>
        </transition>
      </el-tab-pane>
      <el-tab-pane label="小程序" name="applet" class="content">
        <div class="tips">注：请填写企业微信后台绑定的小程序id和路径</div>
        <el-form
          size="medium"
          ref="appletForm"
          class="applet-form"
          :rules="appletRules"
          :model="applet"
          label-width="120px"
        >
          <el-form-item prop="appTitle" label="小程序标题：">
            <el-input
              v-model="applet.appTitle"
              show-word-limit
              maxlength="32"
              placeholder="请输入小程序标题"
              v-lydebounce="['input', getUrlInfo]"
            ></el-input>
          </el-form-item>
          <el-form-item prop="appId" label="小程序appid：" class="p-relative">
            <el-input
              show-word-limit
              maxlength="32"
              v-model="applet.appId"
              placeholder="请输入小程序appid"
            ></el-input>
            <a
              href="https://www.yuque.com/docs/share/dbd084fc-33e9-45a7-ac66-6710855e2745?#"
              class="helper"
              target="_blank"
              >如何获取小程序appid？</a
            >
          </el-form-item>
          <el-form-item
            prop="appPage"
            class="desc p-relative"
            label="小程序路径："
          >
            <el-input
              show-word-limit
              maxlength="250"
              v-model="applet.appPage"
              placeholder="请输入小程序路径"
            ></el-input>
            <a
              href="https://www.yuque.com/docs/share/32544cd6-6726-4101-8c57-bd08fe9f71c4?#"
              class="helper"
              target="_blank"
              >如何获取小程序路径？</a
            >
          </el-form-item>
          <el-form-item
            prop="appPicMediaUrl"
            class="poster"
            label="小程序封面："
          >
            <div class="flex">
              <ly-upload
                :img="applet.appPicMediaUrl"
                @getImg="fn_changeCode($event, 'applet')"
              ></ly-upload>
            </div>
            <div class="q-info text-small">
              支持JPG、PNG格式，图片小于2M，尺寸建议520*416px
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="链接" name="link" class="content">
        <el-form
          size="medium"
          ref="linkForm"
          class="link-form"
          :rules="linkRules"
          :model="link"
          label-width="0px"
        >
          <el-form-item prop="linkUrl">
            <el-input
              v-model="link.linkUrl"
              placeholder="请输入链接"
              v-lydebounce="['input', getUrlInfo]"
            ></el-input>
          </el-form-item>
          <el-form-item prop="linkTitle">
            <el-input
              type="textarea"
              :rows="1"
              show-word-limit
              maxlength="40"
              v-model="link.linkTitle"
              placeholder="链接标题"
            ></el-input>
          </el-form-item>
          <el-form-item prop="linkDsc" class="desc">
            <el-input
              type="textarea"
              show-word-limit
              maxlength="170"
              v-model="link.linkDsc"
              placeholder="快来参加活动吧"
            ></el-input>
          </el-form-item>
          <el-form-item class="poster">
            <ly-upload
              :img="link.linkPicUrl"
              canRemove
              @getImg="fn_changeCode($event, 'link')"
            ></ly-upload>
          </el-form-item>
          <div style="color: #6e788a" class="text-small">
            支持JPG、PNG格式，图片小于2M，尺寸建议500*500px
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" round @click="handlePushMsg">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import qiniuUpload from '@/common/mixins/qiniuUpload'
import lyEditor from '@/components/lyEditor'
import { editApi } from '../http'
export default {
  components: {
    lyEditor
  },
  mixins: [qiniuUpload],
  props: ['hasText'],
  data () {
    const checkUrl = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('链接不能为空'))
      }
      if (!this.util.isUrl(value)) {
        callback(new Error('链接格式错误'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      edit: false,
      activeName: 'text',
      defaultContent: '#用户昵称#，你好哇！等你好久啦～',
      // type: 2001, // 内容类型
      picError: false, // 图片错误变量
      text: { // 文本类型文本
        messageType: 4,
        welcomeContent: '#用户昵称#，你好哇！等你好久啦～'
      },
      pic: { // 图片类型
        messageType: 1,
        imageUrl: ''
      },
      link: { // 链接类型表单
        messageType: 2,
        linkTitle: '',
        linkPicUrl: '',
        linkDsc: '',
        linkUrl: ''
      },
      applet: {
        messageType: 3,
        appTitle: '',
        appPicMediaUrl: '',
        appId: '',
        appPage: ''
      },
      appletRules: {
        appTitle: [
          { required: true, message: '请输入小程序标题' }
        ],
        appId: [
          { required: true, message: '请输入小程序appid' }
        ],
        appPage: [
          { required: true, message: '请输入小程序路径' }
        ],
        appPicMediaUrl: [
          { required: true, message: '请上传小程序封面' }
        ]
      },
      linkRules: {
        linkTitle: [{ required: true, message: '请填写标题' }],
        linkUrl: [{ validator: checkUrl }, { required: true, message: '请输入链接' }]
      },
      typeDic: {
        pic: 1,
        link: 2,
        applet: 3,
        text: 4
      },
      uploadSetting: {
        limit: null,
        'show-file-list': false
      }
    }
  },
  methods: {
    handleOpen () {
      if (!this.edit) {
        this.activeName = this.hasText ? 'pic' : 'text'
      }
      this.$refs.pic && this.$refs.pic.clearFiles()
    },
    /**
     * @description: 切换类型
     * @param {*}
     * @return {*}
     */
    handleClick () {
      this.$refs.pic && this.$refs.pic.clearFiles()
      this.$refs.link && this.$refs.link.clearFiles()
      this.$refs.applet && this.$refs.applet.clearFiles()
      this.$refs.linkForm && this.$refs.linkForm.clearValidate()
      this.$refs.appletForm && this.$refs.appletForm.clearValidate()
    },
    // 上传
    async requestQiniu (opts, type) {
      const rule = {
        types: ['jpg', 'png'],
        size: '2MB'
      }
      const res = await this.qiniuUpload2(
        opts.file,
        path => {
          if (type === 'pic') {
            this.pic.imageUrl = path
            this.picError = false
          } else if (type === 'link') {
            this.link.linkPicUrl = path
            this.$refs.linkForm.validateField('linkPicUrl')
          } else if (type === 'applet') {
            this.applet.appPicMediaUrl = path
            this.$refs.appletForm.validateField('appPicMediaUrl')
          }
        },
        null,
        rule
      )
      if (res) {
        this.$refs.pic.clearFiles()
      }
    },

    fn_changeCode (img, type) {
      if (type === 'applet') {
        this[type].appPicMediaUrl = img
        this.$refs.appletForm.validateField('appPicMediaUrl')
      }

      if (type === 'link') {
        this.link.linkPicUrl = img
        this.$refs.linkForm.validateField('linkPicUrl')
      }
    },
    // 获取欢迎语
    fn_getCusWelWords (content) {
      this.text.welcomeContent = content
    },
    handleSuccess () {
      this.$refs.link.clearFiles()
    },
    // 移除
    handleRemove (key) {
      this.$refs[key].clearFiles && this.$refs[key].clearFiles()
      if (key === 'pic') {
        this.pic.imageUrl = ''
      } else if (key === 'link') {
        this.link.linkPicUrl = ''
      } else if (key === 'applet') {
        this.applet.appPicMediaUrl = ''
      }
    },
    getUrlInfo () {
      if (this.util.https(this.link.linkUrl)) {
        editApi.urlInfo({ url: this.link.linkUrl }).then(res => {
          this.link.linkTitle = res.title
          this.link.linkDsc = res.description
          this.link.linkPicUrl = res.img_url
        })
      }
    },
    /**
     * @description: 添加消息
     * @param {*}
     * @return {*}
     */
    handlePushMsg () {
      if (this.activeName === 'text' && !this.text.welcomeContent) {
        return false
      }
      if (this.activeName === 'pic') {
        if (!this.pic.imageUrl) {
          this.picError = true
          return false
        }
      }
      if (this.activeName === 'link') {
        let res = false
        this.$refs.linkForm.validate(valid => {
          res = valid
        })
        if (!res) {
          return false
        }
      }
      if (this.activeName === 'applet') {
        let res = false
        this.$refs.appletForm.validate(valid => {
          res = valid
        })
        if (!res) {
          return false
        }
      }
      this.visible = false
      if (this.edit) {
        this.$emit('push', {
          temp: this.edit,
          ...this[this.activeName]
        })
      } else {
        this.$emit('push', {
          ...this[this.activeName]
        })
        let str = ''
        switch (this.activeName) {
          case 'text':
            str = '文本'
            break
          case 'pic':
            str = '图片'
            break
          case 'applet':
            str = '小程序'
            break
          case 'link':
            str = '链接'
            break
          default:
            break
        }
        this.$message.success(`${str}添加成功`)
      }
      Object.assign(this.$data, this.$options.data.call(this))
    },
    handleEdit (msg) {
      this.visible = true
      this.edit = msg.temp
      this.activeName = Object.keys(this.typeDic)[msg.messageType - 1]
      const obj = this[this.activeName]
      for (const key in obj) {
        obj[key] = msg[key]
      }
      if (msg.messageType === 3) {
        setTimeout(() => {
          this.$refs.appletForm.validateField('appPicMediaUrl')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-content {
  .type-list {
    display: flex;
    margin-bottom: 5px;
    .item {
      color: rgba($color: #000000, $alpha: 0.65);
      font-size: 16px;
      cursor: pointer;
      width: 70px;
      text-align: center;
      &.active {
        color: #2b7cff;
      }
    }
  }
  .text-input {
    ::v-deep {
      .el-textarea__inner {
        resize: none;
        border-color: #eaebf2;
        height: 164px;
      }
    }
  }
  .content {
    border-radius: 4px;
    border: 1px solid #eaebf2;
    .upload-box {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 22px 0;
      box-sizing: border-box;
      i {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .uploader {
      min-height: 164px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px 0 10px;
      box-sizing: border-box;

      .upload-box {
        height: 104px;
        width: 104px;
        border: 1px dashed #eaebf2;
      }
    }
    .thum {
      position: relative;
      .el-icon-closeplus {
        background: #454d5b;
        width: 14px;
        height: 14px;
        color: #fff;
        border-radius: 50%;
        position: absolute;
        right: -5px;
        top: -5px;
        line-height: 13px;
      }
      .el-icon-close {
        font-size: 12px;
      }
    }
    .img {
      height: 104px;
      display: block;
    }
    .link-img {
      width: 130px;
      height: 110px;
      object-fit: cover;
    }
    .applet-img {
      width: 110px;
      height: 110px;
      object-fit: cover;
    }
    .tips {
      color: #f55945;
      padding: 0 16px;
    }
    .applet-form {
      margin: 8px 12px;
      .el-form-item {
        margin-bottom: 18px;
      }
    }
    .link-form {
      margin: 8px 12px;
      position: relative;
      .el-form-item {
        margin-bottom: 18px;
        &:nth-of-type(2),
        &:nth-of-type(3) {
          width: 270px;
        }
      }
      .poster {
        position: absolute;
        left: 286px;
        top: 54px;
        border-radius: 4px;
        ::v-deep {
          .ly-upload .ly-upload__body {
            width: 132px;
            height: 132px;
          }
        }
      }
      ::v-deep {
        .desc {
          .el-textarea__inner {
            height: 80px;
          }
        }
      }
    }
    .warp-desc {
      padding: 0 8px;
      text-align: center;
      line-height: 1.5;
      margin-top: 6px;
    }
    .p-relative {
      position: relative;
      margin-bottom: 30px !important;
      .helper {
        position: absolute;
        right: 0;
        top: 32px;
        font-size: 12px;
        color: #2b7cff;
      }
    }
  }
}
::v-deep {
  .el-tabs__content {
    overflow: unset;
  }
  .el-tab-pane {
    position: relative;
    .error-desc {
      color: #f55945;
      position: absolute;
      bottom: -20px;
      line-height: 1;
    }
  }
  .el-tabs__header {
    margin-bottom: 5px;
  }
  .el-tabs__nav-wrap::after {
    content: unset;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__item {
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    color: #454d5b;
  }
  .el-tabs__item.is-active {
    color: #2b7cff;
  }
  .el-textarea__inner {
    min-height: 36px !important;
    resize: none;
    border-color: #eaebf2;
  }
  .el-form-item__label {
    padding-right: 5px;
  }
  .el-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    height: 100%;
    color: rgba(0, 0, 0, 0.65);
    &:focus {
      border-color: #eaebf2;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.group {
  &-upload {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
    }
    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }

      &:hover {
        .tips-opacity {
          opacity: 1;
        }
      }
    }

    &__icon {
      width: 70px;
      height: 70px;
      line-height: 70px;
    }

    &__tips {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      opacity: 0;
      transition: opacity 0.3s;
      line-height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: #fff;
      }
    }
  }
}
</style>
