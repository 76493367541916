var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp", attrs: { id: "cloudList" } },
    [
      _c("div", { staticClass: "top-area" }, [
        _c(
          "div",
          { staticClass: "cloud-tips ly-shadow" },
          [
            _c("div", { staticClass: "tips-title" }, [_vm._v("云端说明")]),
            _c("div", { staticClass: "tips-content" }, [
              _vm._v(
                " 企业可借助云端账号向用户自动推送通知，如任务完成通知、奖品通知等；登录后，企业无需手动推送消息，且不占用企业每月群发30次的次数，便于企业管理及多次触达客户。云端账号更多应用场景，敬请期待... "
              )
            ]),
            _c("el-popover", { attrs: { trigger: "hover" } }, [
              _c(
                "p",
                { style: { textAlign: "center", margin: "0 0 10px 0" } },
                [_vm._v("扫码添加客服好友")]
              ),
              _c("img", {
                staticClass: "qr-code",
                style: { width: "172px", height: "172px" },
                attrs: {
                  src: require("@assets/images/contact_me_qr.png"),
                  alt: ""
                }
              }),
              _c(
                "div",
                {
                  staticClass: "tips-customer-service",
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [_vm._v("如有疑问，请联系客服")]
              )
            ])
          ],
          1
        ),
        _c("div", { staticClass: "balance ly-shadow" }, [
          _c("img", {
            staticClass: "bean",
            attrs: { src: require("@assets/images/bean.png"), alt: "云豆" }
          }),
          _c("div", { staticClass: "bal" }, [
            _c("div", { staticClass: "desc" }, [
              _vm._v(_vm._s(_vm.beanBalance))
            ]),
            _c("div", { staticClass: "label" }, [_vm._v("云豆余额")])
          ]),
          _c("div")
        ])
      ]),
      _c("ly-table", {
        ref: "lyTable",
        attrs: {
          data: _vm.tableData,
          option: _vm.tableOpt,
          page: _vm.page,
          pageSize: _vm.per_page,
          total: _vm.total
        },
        on: {
          searchChange: _vm.searchChange,
          sizeChange: _vm.handleSizeChange,
          currentChange: _vm.handleCurrentChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "handler",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      icon: "icon-enwechat1 iconfont"
                    },
                    on: {
                      click: function($event) {
                        _vm.loginVisible = true
                      }
                    }
                  },
                  [_vm._v(" 扫码登录账号 ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "name_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "flex flex-start" }, [
                  _c("img", {
                    staticClass: "avatar",
                    attrs: { src: row.avatar }
                  }),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(_vm._s(row.name))
                  ])
                ])
              ]
            }
          },
          {
            key: "status_slot",
            fn: function(ref) {
              var row = ref.row
              return [
                row.status === 0
                  ? _c("ly-status", { attrs: { type: "danger" } }, [
                      _vm._v("离线")
                    ])
                  : _vm._e(),
                row.status === 1
                  ? _c("ly-status", { attrs: { type: "success" } }, [
                      _vm._v("在线")
                    ])
                  : row.status === 2
                  ? _c(
                      "ly-status",
                      { attrs: { type: "warning" } },
                      [
                        _vm._v("同步数据中，账号请勿进行操作 "),
                        _vm.time
                          ? _c("ly-count-down", {
                              key: row.serial_no,
                              attrs: { t: row.login_at, st: _vm.time },
                              on: {
                                done: function($event) {
                                  return _vm.handleDone(row)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : row.status === 3
                  ? _c("ly-status", [_vm._v("不可用")])
                  : _vm._e()
              ]
            }
          },
          {
            key: "login_day_slot",
            fn: function(ref) {
              var row = ref.row
              return [_c("span", [_vm._v(_vm._s(row.login_day + "天"))])]
            }
          }
        ])
      }),
      _c("ly-scan-login", {
        model: {
          value: _vm.loginVisible,
          callback: function($$v) {
            _vm.loginVisible = $$v
          },
          expression: "loginVisible"
        }
      }),
      _c("ly-recharge", {
        on: {
          success: function($event) {
            _vm.loginVisible = true
          }
        },
        model: {
          value: _vm.rechargeVisible,
          callback: function($$v) {
            _vm.rechargeVisible = $$v
          },
          expression: "rechargeVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }