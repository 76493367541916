<!--
* 奖品记录
-->
<template>
  <div class="prize">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #reward_title_slot="{ row }">
        <div class="avatar-name">
          <img
            src="../../../../assets/svg/reward.svg"
            alt=""
            style="margin-right: 8px"
          />
          <span class="name">{{ row.reward_title }}</span>
        </div>
      </template>

      <template #notice_status_slot="{ row }">
        <ly-status v-if="row.notice_status === 0" type="info"
          >等待发送</ly-status
        >
        <ly-status v-else-if="row.notice_status === 1" type="success"
          >发送成功</ly-status
        >
        <template v-else>
          <el-tooltip
            class="item"
            effect="light"
            placement="top-start"
            popper-class="q-tooltip"
          >
            <div slot="content">
              <div style="width: 200px; padding: 10px 8px">
                当前用户本月接收群发消息已超过4次，如果您已手动发送了奖品消息，请变更为发送成功
              </div>
              <div class="flex flex-center">
                <el-button
                  type="primary"
                  size="mini"
                  round
                  @click="updateRewardStatus(row.id)"
                  >变更为发送成功</el-button
                >
              </div>
            </div>
            <ly-status type="danger">发送失败</ly-status>
          </el-tooltip>
        </template>
      </template>
      -->

      <template #operation="{ row }">
        <template v-if="row.reward_type === 5">
          <el-button
            v-if="row.reward_value"
            type="text"
            size="small"
            @click="getRewardDetail(row)"
            >查看奖品内容</el-button
          >
          <span v-else>-</span>
        </template>
        <template v-else>
          <el-button type="text" size="small" @click="getRewardDetail(row)"
            >查看奖品内容</el-button
          >
        </template>
      </template>
    </ly-table>

    <!-- dialog 奖品内容 -->
    <el-dialog
      title="奖品内容"
      append-to-body
      :visible.sync="dialogVisible"
      custom-class="q-dialog"
      width="34%"
    >
      <div class="reward">
        <div class="q-dialog-block" v-if="rewardType === 1 || rewardType === 6">
          {{ rewardDetail }}
        </div>
        <div class="q-dialog__img" v-else-if="rewardType === 2">
          <div class="flex flex-center">
            <img :src="rewardDetail" alt="" />
          </div>
        </div>
        <div v-else-if="rewardType === 3">
          {{ rewardDetail }}
        </div>
        <div
          class="q-dialog-block q-dialog__img"
          v-else-if="rewardType === 4"
          v-html="rewardDetail"
        ></div>
        <div
          class="reward-list"
          v-loading="rewardLoading"
          v-else-if="rewardType === 5"
        >
          <div
            class="reward-list__item"
            v-for="(i, v) in rewardDetail"
            :key="v"
          >
            <div class="title">{{ i.name }}</div>
            <div class="val">{{ i.data }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { prize } from './http.js'
import { fDetails } from '../http'
export default {
  mixins: [lyTable],
  props: ['inviterId', 'activityId'],
  data () {
    return {
      COMM_HTTP: prize,
      rewardLoading: true,
      per_page: 5,
      dialogVisible: false,
      rewardType: null,
      rewardDetail: null,
      defaultImg: require('@/assets/images/gift.png')
    }
  },
  computed: {
    tableOpt () {
      const _this = this
      return {
        levitate: false,
        layout: 'prev, pager, next',
        column: [
          {
            label: '奖品名称',
            prop: 'reward_title',
            width: 200,
            slot: 'reward_title_slot'
          },
          {
            label: '奖品类型',
            prop: 'reward_type',
            formatter (row, column, cellValue, index) {
              let text = '-'
              switch (cellValue) {
                case 1:
                  text = '文本'
                  break
                case 2:
                  text = '图片'
                  break
                case 3:
                  text = '链接'
                  break
                case 4:
                  text = '自定义'
                  break
                case 5:
                  text = '表单'
                  break
                case 6:
                  text = '兑换码'
                  break
                case 7:
                  text = '核销码'
                  break
                default:
                  break
              }
              return text
            }
          },
          {
            label: '完成任务时间',
            prop: 'notice_at',
            formatter (row, column, cellValue, index) {
              return _this.util.timeTofmt(cellValue, 'yyyy-MM-dd hh:mm')
            }
          },
          {
            label: '发放状态',
            prop: 'give_status',
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '未发放'
              } else if (cellValue === 2) {
                return '已发放'
              } else {
                return '-'
              }
            }
          },
          {
            label: '奖品状态',
            prop: 'get_status',
            search: true,
            type: 'select',
            source: [
              { label: '全部', value: null },
              { label: '未领取', value: 1 },
              { label: '已领取', value: 2 },
              { label: '未填写', value: 3 },
              { label: '已填写', value: 4 }
            ],
            formatter (row, column, cellValue, index) {
              if (cellValue === 1) {
                return '未领取'
              } else if (cellValue === 2) {
                return '已领取'
              } else if (cellValue === 3) {
                return '未填写'
              } else if (cellValue === 4) {
                return '已填写'
              } else {
                return '-'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    inviterId (val) {
      this.queryData()
    }
  },
  methods: {
    formatterParams (params) {
      return {
        ...params,
        activity_id: this.activityId,
        account_id: this.inviterId,
        add_status: -1,
        send_status: -1,
        receive_status: -1
      }
    },
    // 查看奖品详情
    async getRewardDetail (data) {
      this.rewardType = data.reward_type
      if (this.rewardType === 3) {
        this.rewardDetail = JSON.parse(data.reward_detail).link_url
      } else if (this.rewardType === 4) {
        const isJson = this.fn_isJson(data.reward_detail)
        if (isJson) {
          const _info = JSON.parse(data.reward_detail)
          this.rewardDetail = _info.text
          if (_info.img_url !== '') {
            this.rewardDetail += `<br/><img src="${_info.img_url}"/>`
          }
        } else {
          this.rewardDetail = data.reward_detail
          if (data.reward_detail_img) {
            this.reward_detail += `<br/><img src="${data.reward_detail_img}"/>`
          }
        }
      } else if (this.rewardType === 6) {
        this.rewardDetail = data.reward_value
      } else if (this.rewardType === 5) {
        await this.fn_getFormReward(data)
      } else {
        this.rewardDetail = data.reward_detail
      }

      this.dialogVisible = true
    },

    // 判断JSON
    fn_isJson (str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    },

    async fn_getFormReward (data) {
      this.rewardLoading = true
      const res = await fDetails.getOneDetail(
        {
          formId: data.reward_type_id + '',
          requestId: data.reward_value + ''
        }
      )

      if ((res && res.code) || (res && res.code === 0)) {
        this.fn_lyMsg('info', res.msg)
        return false
      }

      this.rewardDetail = res
      this.rewardLoading = false
    },

    // 修改奖品发送状态
    async updateRewardStatus (id) {
      const data = this.axios.put('updateRewardStatus', {
        id: id,
        status: 1
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$message.success('修改奖品发送状态成功')
      this.getRewardStatistic()
      this.getRewardRecords()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-icon-warning {
  color: #faad14;
  font-size: 14px;
}
.notice-status-succ,
.notice-status-error {
  position: relative;
  padding-left: 15px;
  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #52c41a;
    position: absolute;
    left: 0;
    top: 4px;
  }
}
.notice-status-succ {
  color: #52c41a;
}
.notice-status-error::before {
  background: #f55945;
}
.avatar-name .name {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行数
  -webkit-box-orient: vertical;
}
::v-deep {
  .xl-ly-search {
    margin-bottom: 0;
  }
  .q-dialog {
    p {
      margin: 0;
    }

    a {
      color: #2b7cff;
      pointer-events: none;
    }

    &__img {
      img {
        max-width: 300px;
      }
    }

    &__body {
      height: 240px;
    }
  }
}

.reward {
  max-height: 50vh;
  overflow: auto;

  &-list {
    &__item {
      padding: 14px 0;
      border-bottom: 1px solid #f0f1f2;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }

      .title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .val {
        color: #6e6f73;
      }
    }
  }
}
</style>
