<!--
 * @Author: zhanln
 * @Date: 2021-06-24 16:00:45
 * @LastEditTime: 2022-08-29 15:00:28
 * @LastEditors: zhan
 * @Description: 渠道推广
-->

<template>
  <div class="page-warp">
    <el-form :inline="true" style="padding: 0 20px">
      <el-form-item label="选择活动：" class="label-padding">
        <el-select v-model="activityId" filterable style="width: 320px" @change="fn_changeAct">
          <el-option v-for="item in activityList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <ly-table ref="lyTable" :data="tableData" :option="tableOpt" :page="page" :pageSize="per_page" :total="total"
      @searchChange="searchChange" @sizeChange="handleSizeChange" @sortChange="handleSortChange"
      @currentChange="handleCurrentChange" @selectionChange="handleSelectionChange">
      <!-- 搜索插槽 -->
      <!-- 功能区插槽 -->
      <template #handler>
        <el-button type="primary" icon="iconfont icon-plus" round @click="fn_openPop(null)">添加渠道</el-button>
      </template>
      <!-- 列插槽 -->
      <template #link_url_slot="{ row }">
        {{ row.link_url }}
        <span :class="['copy', 'copy_url_' + row.id]" @click="fn_copy('copy_url_' + row.id)"
          :data-clipboard-text="row.link_url">复制</span>
      </template>

      <template #code_slot="{ row }">
        <div class="code-block">
          <el-popover trigger="hover" placement="right-start" :close-delay="0">
            <div>
              <img :src="row.poster_url" alt="" width="250" height="445" />
              <div class="flex" style="justify-content: space-around; margin-top: 8px">
                <el-button type="text" @click="handleDownload(row, 'poster')">下载海报</el-button>
                <el-button type="text" @click="handleDownload(row, 'code')">下载二维码</el-button>
              </div>
            </div>
            <i slot="reference" class="iconfont icon-Code" style="font-size: 18px; margin-right: 10px"></i>
          </el-popover>
          <el-button type="text" class="copy" @click="handleDownload(row, 'code')">下载</el-button>
        </div>
      </template>

      <template #operation="{ row }">
        <el-button type="text" @click="fn_openPop(row)">编辑</el-button>
        <el-button type="text" :disabled="row.status === 2 || row.status === 3" @click="fn_handleDel(row)">删除
        </el-button>
      </template>
    </ly-table>

    <edit-channel ref="editChannelRef" :popForm="channelItem" :actId="activityId" :type="editType" @success="queryData">
    </edit-channel>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import editChannel from './components/editChannel.vue'
import lyTable from '@/components/mixins/ly-table.js'
import { activity, popularize } from './http.js'
export default {
  name: 'popularize',
  mixins: [lyTable],
  components: {
    editChannel
  },
  data () {
    return {
      IMMEDIATE: false,
      COMM_HTTP: popularize,
      activityId: null,
      activityList: [],
      channelItem: {},
      editType: 'add'
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 120,
        column: [
          {
            label: '渠道名称',
            prop: 'title',
            search: true,
            key: 'title',
            placeholder: '请输入渠道名称',
            columnProps: {
              'min-width': 200
            }
          },
          {
            label: '渠道二维码',
            prop: 'code',
            slot: 'code_slot',
            columnProps: {
              'min-width': 180
            }
          }
        ]
      }
    }
  },
  created () {
    this.activityId = this.$route.query.id * 1
    this.actList()
  },

  methods: {
    formatterParams (params) {
      return {
        ...params,
        book_task_id: this.activityId
      }
    },
    // 获取活动列表
    async actList () {
      const data = await activity.reqList()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        return false
      }

      console.log('data', data)

      this.activityList = data.map((i, v) => {
        return {
          value: i.id,
          label: i.title
        }
      })

      const actId = +this.$route.query.id
      this.activityId = actId || this.activityList[0].value
      await this.getWecomTags()
    },

    // 切换活动
    fn_changeAct (id) {
      this.page = 1
      this.activityId = id
      this.$router.replace({
        query: { id }
      })
      this.queryData()
    },

    // 编辑
    fn_openPop (item) {
      this.channelItem = item
      this.editType = item ? 'edit' : 'add'
      this.$refs.editChannelRef.visible = true
    },

    // 删除
    async fn_handleDel ({ id }) {
      this.$lyConfirm({
        title: '确认要删除此渠道吗？',
        text: '渠道删除后将无法找回, 是否继续?',
        confirmButtonText: '删除'
      }).then(async () => {
        const data = await popularize.delete({
          id
        })

        if ((data && data.code) || (data && data.code === 0)) {
          this.fn_lyMsg('error', data.msg)
          this.popLoading = false
          return false
        }

        this.fn_lyMsg('success', '渠道已删除！')

        this.page = 1
        this.queryData()
      }).catch(() => { })
    },

    // 复制
    fn_copy (el) {
      const clipboard = new Clipboard(`.${el}`)
      clipboard.on('success', () => {
        clipboard.destroy()
        this.fn_lyMsg('success', '复制成功')
      })
      clipboard.on('error', (e) => {
        this.fn_lyMsg('error', '复制失败，请手动复制')
        console.error('E:', e)
      })
    },

    // 下载二维码
    async handleDownload ({ title, qrcode_url, poster_url }, type) {
      const x = new XMLHttpRequest()
      x.open('GET', type === 'poster' ? poster_url : qrcode_url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = type === 'poster' ? title + '-poster' : title + '-qr'
        a.click()
      }
      x.send()
    },

    // 获取标签列表
    async getWecomTags () {
      const data = await this.axios.get('wecomTagsV2')

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('error', data.msg)
        return false
      }

      const tags = []
      if (data && data.length > 0) {
        for (const i in data) {
          tags.push(data[i].tagList)
        }

        this.$store.commit('_SET', {
          'tag.list': tags.flat()
        })
      }
      this.queryData()
    },

    // 获取渠道标签名
    fn_getTagName (tagId) {
      const tagArr = tagId.split(',').map(item => +item)
      const tagList = this.$store.state.tag.list
      const _tagNames = []
      for (let i = 0; i < tagList.length; i++) {
        if (tagArr.indexOf(tagList[i].tagId) > -1) {
          _tagNames.push(tagList[i].tagName)
          continue
        }
      }
      return _tagNames
    }
  }
}
</script>

<style lang="scss" scoped>
.code-block {
  display: flex;
  align-items: center;
}

::v-deep .copy {
  color: $--color-primary;
  cursor: pointer;
}
</style>
