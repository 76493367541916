var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warp" },
    [
      _c(
        "layout-table",
        {
          attrs: {
            pageTotal: _vm.total,
            loading: _vm.loading,
            page: _vm.page,
            searchForm: _vm.searchForm
          },
          on: {
            pageChange: _vm.pageChange,
            getList: _vm.fn_getData,
            resetList: function($event) {
              return _vm.fn_getData(true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "searchBar",
              fn: function() {
                return [
                  _c(
                    "el-form-item",
                    { attrs: { label: "群聊搜索：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入群聊名称", clearable: "" },
                        model: {
                          value: _vm.searchForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "name", $$v)
                          },
                          expression: "searchForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "群主：" } },
                    [
                      _c("ly-emp-select", {
                        ref: "lyEmpSelectRef",
                        attrs: {
                          placeholder: "选择群主",
                          showLength: 1,
                          checkList: _vm.searchForm.wecom_user_ids
                        },
                        on: { getEmpId: _vm.getEmpId }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间：" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "360px" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "~",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions
                        },
                        on: { change: _vm.changeTime },
                        model: {
                          value: _vm.timeModel,
                          callback: function($$v) {
                            _vm.timeModel = $$v
                          },
                          expression: "timeModel"
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "toolBar",
              fn: function() {
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          class: ["btn-reload", _vm.reload ? "active" : ""],
                          staticStyle: { "margin-right": "16px" },
                          attrs: {
                            round: "",
                            type: "primary",
                            disabled: _vm.reload
                          },
                          on: { click: _vm.fn_reload }
                        },
                        [
                          _c("i", { staticClass: "icon qy-icon-reload" }),
                          _vm._v(_vm._s(_vm.reload ? "同步中..." : "同步"))
                        ]
                      ),
                      _vm._v(
                        "共 " +
                          _vm._s(_vm.total) +
                          " 个群聊, " +
                          _vm._s(_vm.total_user_count) +
                          " 个群成员，去重后 " +
                          _vm._s(_vm.userTotal) +
                          " 个群成员 "
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData },
              on: { "sort-change": _vm.tableSort }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  fixed: "",
                  width: "200",
                  label: "全部群聊"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "flex flex-start" }, [
                          _c("div", { staticClass: "ly-table__main-tearm" }, [
                            _c("i", { staticClass: "iconfont icon-weixin" })
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "8px" } },
                            [
                              _vm._v(
                                _vm._s(scope.row.name ? scope.row.name : "群聊")
                              )
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "user_count",
                  label: "群总人数",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "today_added_count",
                  label: "今日入群",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "today_quited_count",
                  label: "今日退群",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "owner_name", label: "群主", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("ly-tag", {
                          attrs: { content: scope.row.owner_name }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "group_created_at",
                  sortable: "custom",
                  label: "创建时间"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "ly-table__main-time" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.util.timeTofmt(
                                  scope.row.group_created_at,
                                  "yyyy-MM-dd hh:mm"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "80",
                  align: "right",
                  "class-name": "operation-cell"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.jumpToDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "ly-table-empty",
                  attrs: { slot: "empty" },
                  slot: "empty"
                },
                [
                  _vm._t("empty", [
                    _c("img", {
                      attrs: {
                        src: require("@assets/svg/default/no_data.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" 暂无数据 ")
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }