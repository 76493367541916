<!--
 * @Description: 无限制群发
-->
<template>
  <div class="page-warp send">
    <ly-table
      ref="lyTable"
      :data="tableData"
      :option="tableOpt"
      :page="page"
      :pageSize="per_page"
      :total="total"
      @searchChange="searchChange"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    >
      <template #handler>
        <el-button
          round
          size="medium"
          type="primary"
          icon="iconfont icon-plus"
          @click="$router.push('edit')"
          >{{ type === 'announcement' ? '批量发群公告': '群发到群'}}</el-button
        >
      </template>

      <template #task_name_slot="{ row }">
        <span class="task-name">{{ row.task_title || "-" }}</span>
      </template>

      <template #status_slot="{ row }">
        <span :style="getStatusStyle(row.status)">{{
          formatterStatus(row.status)
        }}</span>
      </template>

      <template #sended_slot="{ row }">
        <el-button
          type="text"
          @click="fn_showDetail(row)"
          style="color: #454D5B"
        >
          <span style="color: #2B7CFF">{{ row.success_send_count+'/'+row.total_send_count }}</span>
        </el-button>
      </template>

      <template #operation="{ row }">
        <el-button type="text" @click="fn_edit(row)">修改</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" @click="fn_copy(row)">复制</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" @click="fn_del(row)" @keydown.enter.native.prevent>删除</el-button>
      </template>
    </ly-table>

    <!-- 发送详情弹框 -->
    <send-detail ref="sendDetailRef"></send-detail>
  </div>
</template>

<script>
import lyTable from '@/components/mixins/ly-table.js'
import { fsend } from './http.js'
import sendDetail from './components/sendDetail'
export default {
  name: 'fsendList',
  mixins: [lyTable],
  props: ['type', 'http'],
  components: {
    sendDetail
  },
  data () {
    return {
      COMM_HTTP: fsend,
      task_id: null
    }
  },
  watch: {
    type (n) {
      if (n === 'announcement') {
        this.COMM_HTTP = this.http
      }
    }
  },
  computed: {
    tableOpt () {
      return {
        operationWidth: 200,
        column: [
          {
            label: this.type === 'announcement' ? '群公告名称' : '群发名称',
            prop: 'task_title',
            search: true,
            slot: 'task_name_slot'
          },
          {
            label: '发送方式',
            prop: 'send_time_type',
            formatter: (row, column, cellValue, index) => {
              let value = '-'
              switch (cellValue) {
                case 1:
                  value = '立即发送'
                  break
                case 2:
                  value = '定时发送'
                  break
                default:
                  break
              }
              return value
            }
          },
          {
            label: '发送时间',
            prop: 'created_at',
            formatter: (row, column, cellValue, index) => {
              return this.$day.unix(row.send_time_type === 1 ? cellValue : row.book_time).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            label: '发送状态',
            prop: 'status',
            slot: 'status_slot',
            search: true,
            type: 'select',
            align: 'center',
            source: [
              {
                value: 0,
                label: '全部'
              },
              {
                value: 1,
                label: '待发送'
              },
              {
                value: 2,
                label: '发送中'
              },
              {
                value: 3,
                label: '已发送'
              }
            ]
          },
          {
            label: '触达数量(成功/预计)',
            prop: 'sended',
            slot: 'sended_slot',
            align: 'center'
          }
        ]
      }
    }
  },
  methods: {
    // 修改
    fn_edit ({ task_id }) {
      const path = this.type === 'announcement' ? '/cloudService/batchAnnouncement/edit' : '/cloudService/batchMessage/edit'
      this.$router.push({ path, query: { task_id } })
    },

    // 复制
    fn_copy ({ task_id }) {
      const name = this.type === 'announcement' ? 'announcementEdit' : 'messageEdit'
      this.$router.push({ name, params: { task_id } })
    },

    // 删除
    fn_del ({ task_id }) {
      this.$lyConfirm({
        text: '确认要删除吗？'
      }).then(() => {
        this.COMM_HTTP.reqDelete({ task_id }).then(res => {
          if (res && res.code === 0) {
            this.$message.error(res.msg)
            return false
          }
          this.$message.success('删除成功！')
          this.queryData()
        })
      }).catch(() => {})
    },

    // 打开详情
    fn_showDetail ({ task_id, status }) {
      this.$refs.sendDetailRef.task_id = task_id
      this.$refs.sendDetailRef.type = this.type
      this.$refs.sendDetailRef.status = status
      this.$refs.sendDetailRef.visible = true
    },

    getStatusStyle (val) {
      const style = {}
      switch (val) {
        case 1:
          style.color = '#FAAD14'
          break
        case 2:
          style.color = '#2B7CFF'
          break
      }
      return style
    },

    formatterStatus (val) {
      let text = '-'
      switch (val) {
        case 1:
          text = '待发送'
          break
        case 2:
          text = '发送中'
          break
        case 3:
          text = '已发送'
          break
        case 4:
          text = '发送失败'
          break
      }
      return text
    },
    formatterParams (params) {
      return {
        ...params,
        message_type: this.type === 'announcement' ? 2 : 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.send {
  ::v-deep {
    .task-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
