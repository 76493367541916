var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "调整分组", visible: _vm.visible, width: "420px" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "selected" }, [
        _vm._v(" 已选择"),
        _c("span", { staticStyle: { color: "#2B7CFF" } }, [
          _vm._v(" (" + _vm._s(_vm.selected.length) + ") ")
        ]),
        _vm._v("个活码 ")
      ]),
      _c(
        "div",
        { staticClass: "group-list" },
        [
          _vm.groupList.length
            ? _c(
                "el-scrollbar",
                _vm._l(_vm.groupList, function(item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "group-list__radio" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: item.id },
                          model: {
                            value: _vm.group,
                            callback: function($$v) {
                              _vm.group = $$v
                            },
                            expression: "group"
                          }
                        },
                        [_vm._v(_vm._s(item.groupName))]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                { staticClass: "no-group flex flex-column flex-center" },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/svg/null.svg"),
                      alt: "",
                      srcset: ""
                    }
                  }),
                  _c("div", { staticClass: "desc" }, [_vm._v("暂无分组")]),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "primary", size: "small" },
                      on: { click: _vm.handleAddGroup }
                    },
                    [_vm._v("添加分组")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.groupList.length
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "", size: "medium" },
                  on: { click: _vm.handleDoGroup }
                },
                [_vm._v("确定分组")]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }