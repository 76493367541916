var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ly-form", {
    ref: "lyFormRef",
    staticClass: "act-form",
    attrs: { formObj: _vm.formObj, form: _vm.formData, rules: _vm.rules },
    on: { setData: _vm.fn_setData },
    scopedSlots: _vm._u(
      [
        {
          key: "show_stock_bottom",
          fn: function() {
            return [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    placement: "top",
                    "popper-class": "q-tooltip"
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 1.关闭：活动页显示“库存告急”。"),
                    _c("br"),
                    _vm._v(
                      " 2.开启：展示任务设置的奖品剩余库存，如果奖品类型为兑换码，"
                    ),
                    _c("br"),
                    _vm._v(" 则展示兑换码的真实库存。 ")
                  ]),
                  _c("i", { staticClass: "ri-question-line ml-8" })
                ]
              )
            ]
          },
          proxy: true
        },
        {
          key: "reward_detail",
          fn: function() {
            return [
              _vm.formData.reward_type === 1
                ? _c("el-form-item", { attrs: { prop: "reward_detail" } }, [
                    _c(
                      "div",
                      { staticClass: "detail" },
                      [
                        _c("ly-editor", {
                          attrs: {
                            id: "detail_diy",
                            title: "自定义奖品内容",
                            content: _vm.formData.reward_detail
                          },
                          on: {
                            getContent: function($event) {
                              return _vm.fn_setData($event, "reward_detail")
                            },
                            focus: function($event) {
                              return _vm.fn_setFocus(true)
                            },
                            blur: function($event) {
                              return _vm.fn_setFocus(false)
                            }
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "detail-foot" },
                          [
                            _c("ly-upload", {
                              attrs: {
                                img: _vm.formData.reward_detail_img,
                                mini: "",
                                canRemove: ""
                              },
                              on: {
                                getImg: function($event) {
                                  return _vm.fn_setData(
                                    $event,
                                    "reward_detail_img",
                                    "reward_detail"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "detail-code",
                        attrs: { prop: "reward_type_id" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "act-form__input",
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              disabled: _vm.eType === 1
                            },
                            on: { change: _vm.fn_changeCode },
                            model: {
                              value: _vm.formData.reward_type_id,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "reward_type_id", $$v)
                              },
                              expression: "formData.reward_type_id"
                            }
                          },
                          _vm._l(_vm.codeList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.title, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text-small" },
                      [
                        _c("span", { staticClass: "q-info mr-8" }, [
                          _vm._v("选择要关联活动的兑换码奖品")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.fn_jumpToCode("code")
                              }
                            }
                          },
                          [_vm._v("去创建兑换码")]
                        ),
                        _c("div", {
                          staticClass: "el-divider el-divider--vertical",
                          attrs: { "data-v-3cefa108": "" }
                        }),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "lydebounce",
                                rawName: "v-lydebounce",
                                value: ["button", _vm.fn_getCodeList],
                                expression: "['button', fn_getCodeList]"
                              }
                            ],
                            attrs: { type: "text", size: "mini" }
                          },
                          [_vm._v("刷新")]
                        )
                      ],
                      1
                    ),
                    _c("ly-editor", {
                      staticStyle: { width: "485px" },
                      attrs: {
                        id: "detail_code",
                        title: "兑换规则",
                        content: _vm.formData.reward_detail_code
                      },
                      on: {
                        getContent: function($event) {
                          return _vm.fn_setDetail($event)
                        },
                        focus: function($event) {
                          return _vm.fn_setFocus(true)
                        },
                        blur: function($event) {
                          return _vm.fn_setFocus(false)
                        }
                      }
                    })
                  ]
            ]
          },
          proxy: true
        },
        _vm.eType === 1
          ? {
              key: "total_bottom",
              fn: function() {
                return [
                  _c("div", { staticClass: "q-info" }, [
                    _c("span", { staticStyle: { "margin-right": "24px" } }, [
                      _vm._v("已使用："),
                      _c("b", [_vm._v(_vm._s(_vm.formData.give_num))])
                    ]),
                    _c("span", { class: { "q-danger": _vm.remain <= 0 } }, [
                      _vm._v("剩余库存："),
                      _c("b", [_vm._v(_vm._s(_vm.remain))])
                    ])
                  ])
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }