<!--
 * @Author: zhanln
 * @Date: 2022-03-08 19:15:56
 * @LastEditTime: 2022-04-27 15:04:59
 * @LastEditors: zhanln
 * @Description: 账号密码
-->

<template>
  <div>
    <div class="agrForm-title" v-if="type === 'new'">设置客服账号</div>
    <el-form ref="formRef" :model="form" :rules="rules" class="agrForm">
      <el-form-item label="客服账号：" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入客服账号名称，支持中文、字母、数字"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="type !== 'new' ? '新密码：' : '登录密码：'"
        prop="password"
      >
        <el-input
          v-model.trim="form.password"
          :placeholder="type !== 'new' ? '请设置新密码' : '请设置账号登录密码'"
          maxlength="30"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="验证密码："
        prop="repeatPassword"
        maxlength="30"
        v-if="type !== 'new'"
      >
        <el-input
          v-model.trim="form.repeatPassword"
          placeholder="请再次输入新密码"
          maxlength="30"
          type="password"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="agrForm-footer" v-if="type !== 'new'">
      <el-button round @click="$emit('close')">取消</el-button>
      <el-button round type="primary" @click="fn_verify">确定</el-button>
    </div>
  </div>
</template>

<script>
import { agrs } from '../http.js'
export default {
  name: 'account',

  props: {
    type: {
      type: String,
      default: ''
    }
  },

  data () {
    const checkName = async (rule, value, callback) => {
      if (!value) {
        callback(new Error('请设置客服账号名称'))
        return
      }

      const exp = /^[A-Za-z0-9\u4e00-\u9fa5]+$/

      if (!exp.test(value)) {
        callback(new Error('客服账号名称支持中文、字母、数字'))
        return
      }

      callback()
    }

    const checkPassWord = async (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.type === 'new' ? '请设置密码' : '请设置新密码'))
        return
      }

      const exp = /^[A-Za-z0-9]+$/

      if (!exp.test(value)) {
        callback(new Error('密码长度最少为 6 个字符，支持数字、大小写字母'))
        return
      }
      callback()
    }

    const checkRepeatWord = async (rule, value, callback) => {
      if (!this.form.repeatPassword) {
        callback(new Error('请再次输入新密码'))
        return
      }
      if (this.form.password !== value) {
        callback(new Error('两次输入的密码不一致'))
        return
      }
      callback()
    }

    return {
      id: null,
      form: {
        name: '',
        password: '',
        repeatPassword: ''
      },
      rules: {
        name: [{ validator: checkName, required: true, trigger: 'change' }],
        password: [{ validator: checkPassWord, required: true, trigger: 'change' }, { min: 6, max: 30, message: '密码长度最少为 6 个字符，支持数字、大小写字母', trigger: 'change' }],
        repeatPassword: [{ validator: checkRepeatWord, required: true, trigger: 'change' }]
      }
    }
  },

  watch: {
    'form.password' (val) {
      if (this.type !== 'new') {
        this.$refs.formRef.validateField('repeatPassword')
      }
    }
  },

  methods: {
    fn_init (item) {
      this.id = item.id
      this.form.name = item.account
    },

    // 表单校验
    fn_verify (type = true) {
      let verify = true
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          verify = true
        } else {
          verify = false
        }
      })

      if (!verify) return false

      if (type) {
        this.fn_submit()
        return
      }

      return {
        name: this.form.name,
        password: this.form.password
      }
    },

    // 修改账号密码
    async fn_submit () {
      const data = await agrs.changePasswd({
        account: this.form.name,
        confirm_pwd: this.form.repeatPassword,
        id: this.id,
        passwd: this.form.password
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.fn_lyMsg('success', '修改账号/密码成功！')
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.agrForm ::v-deep .el-form-item__label {
  display: block;
}

.agrForm-title {
  font-size: 16px;
  font-weight: 500;
  margin: 116px auto 40px auto;
  text-align: center;
}

.agrForm-footer {
  text-align: right;
}
</style>
