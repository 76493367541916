<!--
 * @Author: zhan
 * @Date: 2022-08-15 10:28:47
 * @LastEditTime: 2022-09-16 17:55:01
 * @LastEditors: zhanln
-->
<template>
  <div
    :class="['progress', type]"
    :style="{
      height: type === 'vertical' ? 'calc(100% - 26px)' : 'auto',
      '--innerWidth': innerWidth,
      '--dotTop': dotTop
    }"
  >
    <div class="progress-bar">
      <div class="progress-inner"></div>
      <div class="progress-dot"></div>
    </div>
    <div class="progress-count" v-if="!type">{{ stepNum }}/{{ count }}</div>
  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 1
    },
    stepNum: {
      type: Number,
      default: 1
    },
    rewards: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  computed: {
    innerWidth () {
      return this.stepNum / this.count * 100 + '%'
    },
    dotTop () {
      if (this.stepNum === 0 || !this.rewards.length) {
        return '0%'
      }

      if (this.stepNum >= this.rewards[this.rewards.length - 1].count) {
        return 'calc(100%)'
      }

      const steps = 1 / (this.rewards.length * 2 - 1) * 100
      let topH = 0.5
      for (let i = 0; i < this.rewards.length; i++) {
        if (this.stepNum === this.rewards[i].count) {
          if (i === 0) {
            topH += 0.5
          } else {
            topH += 1 * i
          }
        }

        if (this.stepNum > this.rewards[i].count) {
          if (i === 0) {
            topH += 1.5
          } else {
            topH += 2
          }
        }
      }

      const top = topH * steps + '%'
      return top
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
  display: flex;
  align-items: center;

  &.vertical {
    display: block;
    width: 8px;
    height: 100%;
  }

  &-bar {
    width: 100%;
    position: relative;
    flex: 1;
    height: 8px;
    background-color: #ffe4f7;
    border-radius: 4px;
    margin-right: 12px;

    .vertical & {
      width: 8px;
      height: 100%;
      margin-right: 0;
    }
  }

  &-dot {
    position: absolute;
    top: -2px;
    left: var(--innerWidth);
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 3px 0px rgba(224, 51, 173, 0.24);
    background-color: #ff70c6;
    box-sizing: border-box;
    transform: translateX(-50%);

    .vertical & {
      left: -2px;
      top: var(--dotTop);
      transform: translateY(-50%);
    }
  }

  &-inner {
    height: 8px;
    width: var(--innerWidth);
    background-color: #ff70c6;
    border-radius: 4px;

    .vertical & {
      width: 8px;
      height: var(--dotTop);
    }
  }

  &-count {
    font-size: 12px;
    color: #e95bb1;
  }
}
</style>
